import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import { isProjectItemNameValid } from 'utils/validation';

export const ERROR_INVALID_FOLDER_NAME = 'file-browser.create-folder-modal.name-error';
export const ERROR_INVALID_DUPLICATE_FOLDER_NAME = 'file-browser.create-folder-modal.duplicate-error';

class CreateFolderModal extends Component {
  static propTypes = {
    intl: intlShape,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = { folderName: '', errMsg: undefined };
  }

  onCreateClicked = e => {
    e.preventDefault();

    const { onSubmit, onCancel } = this.props;
    const { folderName } = this.state;
    this.blurInput();

    if (!isProjectItemNameValid(folderName)) {
      this.setState({ errMsg: ERROR_INVALID_FOLDER_NAME });
    } else {
      this.setState({ errMsg: undefined });
      onSubmit(folderName)
        .then(() => {
          onCancel();
        })
        .catch(error => {
          this.setState({ errMsg: ERROR_INVALID_DUPLICATE_FOLDER_NAME });
          if (this.input) {
            this.input.focus();
          }
        });
    }
  };

  onFolderNameUpdate = e => {
    this.setState({ folderName: e.target.value });
  };

  blurInput = () => {
    if (this.input) {
      this.input.blur();
    }
  };

  render() {
    const { onCancel, intl: { formatMessage } } = this.props;
    const { errMsg } = this.state;

    return (
      <div className="modal__content">
        <form onSubmit={this.onCreateClicked}>
          <h3>
            <FormattedMessage id="file-browser.create-folder-modal.title" />
          </h3>
          <Button type="button" size="icon" className="modal__close_x icon-button" onClick={onCancel}>
            <Icon name="special-cross-black" width={18} height={18} />
          </Button>
          <div className="modal__body">
            <label className="modal__label">
              <FormattedMessage id="file-browser.create-folder-modal.label" />
            </label>
            <input
              autoFocus
              className={`modal__input ${errMsg ? 'modal__input-invalid' : ''}`}
              id="folder-name"
              type="text"
              placeholder={formatMessage({ id: 'file-browser.create-folder-modal.placeholder' })}
              maxLength="140"
              onChange={this.onFolderNameUpdate}
              ref={elem => (this.input = elem)}
            />
            {errMsg !== undefined && (
              <div className="modal__error-message">
                <FormattedMessage id={errMsg} />
              </div>
            )}
          </div>
          <div className="modal__footer">
            <Button type="button" className="btn btn-secondary" onClick={onCancel}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button type="submit" className="btn btn-primary">
              <FormattedMessage id="common.create" />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default injectIntl(CreateFolderModal);
