import { createAction, createReducer } from 'utils/redux-utils';
import socket, { rooms } from 'utils/socket';

import { User } from 'models/user';
import * as api from 'store/api';
import history from 'utils/history';

export const ACTIONS = {
  CURRENT_USER_LOADING: 'argus/ui/CURRENT_USER_LOADING',
  CURRENT_USER_LOADED: 'argus/ui/CURRENT_USER_LOADED',
  CURRENT_USER_ERROR: 'argus/ui/CURRENT_USER_ERROR'
};

export const currentUserLoading = createAction(ACTIONS.CURRENT_USER_LOADING);
export const currentUserLoaded = createAction(ACTIONS.CURRENT_USER_LOADED);
export const currentUserError = createAction(ACTIONS.CURRENT_USER_ERROR);

// Contains the logic for subscribing/unsubscribing to updates from the socket
export const { subscribe, unsubscribe } = (socket => {
  let room = null;
  let onUserLogout = null;
  let onUserUpdate = null;

  const subscribe = userId => dispatch => {
    if (!userId) {
      return;
    }

    const nextRoom = rooms.user(userId);

    if (room !== nextRoom) {
      socket.leave(room);
      room = nextRoom;
      socket.join(nextRoom);
    }

    // Handle user log out event
    if (!onUserLogout) {
      onUserLogout = response => {
        api.authContextLogout();
      };

      socket.on('user.logout', onUserLogout);
    }

    // Handle user updated event
    if (!onUserUpdate) {
      onUserUpdate = response => {
        if (response.room === room) {
          //The user reponse object here is often incomplete.  Perform a refresh to get the correct object
          dispatch(getCurrentUser());
        }
      };

      socket.on('update', onUserUpdate);
    }
  };

  const unsubscribe = () => {
    socket.leave(room);
    room = null;

    if (onUserLogout) {
      socket.off('user.logout', onUserLogout);
      onUserLogout = null;
    }

    if (onUserUpdate) {
      socket.off('update', onUserUpdate);
      onUserUpdate = null;
    }
  };

  return {
    subscribe,
    unsubscribe
  };
})(socket);

export const getCurrentUser = () => dispatch => {
  dispatch(currentUserLoading());
  return api
    .getCurrentUser()
    .then(response => {
      dispatch(subscribe(response.data.userId));
      dispatch(currentUserLoaded(response.data));
    })
    .catch(error => {
      if (error.request && error.request.status === 403) history.push('/unauthorized');
      dispatch(currentUserError(error));
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new User();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.CURRENT_USER_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.CURRENT_USER_LOADED]: (state, action) => {
    return state.setLoaded(action.payload);
  },

  [ACTIONS.CURRENT_USER_ERROR]: (state, action) => {
    return state.setError(action.payload);
  }
});
