import constants from 'utils/constants';
import { ITEM_STATUSES } from 'models/project-item';
import dateUtil from 'utils/dateUtil';
import track from './track';
import updateDataLayer from './data-layer';

/** Utils to scrap and clean up data to send to analytics  */
const PAGE = constants.Pages;

/** Object table listing the different pages on Argus to be displayed on Analytics Page */
const LOCATION_METADATA = {
  extractionfield: {
    pathTemplate: '/region/:regionId/project/:projectId/extractionfield',
    pageName: PAGE.EDIT_EXTRACTIONS
  },

  creation: {
    pathTemplate: '/region/:regionId/project/:projectId?/creation',
    pageName: PAGE.PROJECT_CREATION
  },

  settings: {
    pathTemplate: 'region/:regionId/project/:projectId/settings',
    pageName: PAGE.PROJECT_SETTINGS
  },

  copy: {
    pathTemplate: '/region/:regionId/project/:projectId/copy',
    pageName: PAGE.PROJECT_COPY
  },

  folder: {
    pathTemplate: '/region/:regionId/project/:projectId/folder/:folderId',
    pageName: PAGE.DOCUMENT_LIST
  },

  document: {
    pathTemplate: '/region/:regionId/project/:projectId/document/:documentId',
    pageName: PAGE.DOCUMENT_VIEWER
  },

  visuals: {
    pathTemplate: '/region/:regionId/project/:projectId/visuals',
    pageName: PAGE.VISUALS
  },

  analytics: {
    pathTemplate: '/region/:regionId/project/:projectId/analytics',
    pageName: PAGE.ANALYTICS
  },

  'user-management': {
    pathTemplate: '/region/:regionId/user-management',
    pageName: PAGE.USER_MANAGEMENT
  },

  'activation-settings': {
    pathTemplate: '/region/:regionId/activation-settings',
    pageName: PAGE.USER_ACTIVATION
  },

  reports: {
    pathTemplate: '/region/:regionId/reports',
    pageName: PAGE.REPORTS
  },

  compare: {
    pathTemplate: '/region/:regionId/project/:projectId/compare/:compareId/:documentId?',
    pageName: PAGE.COMPARISON_VIEWER
  },

  groups: {
    pathTemplate: '/region/:regionId/groups',
    pageName: PAGE.ADMIN
  },

  translation: {
    pathTemplate: '/translation',
    pageName: PAGE.TRANSLATION
  },

  'translation#translate-document': {
    pathTemplate: 'translation#translate-document',
    pageName: PAGE.TRANSLATION
  },

  'translation#translate-text': {
    pathTemplate: '/translation#translate-text',
    pageName: PAGE.TRANSLATE_TEXT
  },

  'translation#translation-queue': {
    pathTemplate: '/translation#translation-queue',
    pageName: PAGE.TRANSLATION_QUEUE
  },

  'project-list': {
    pathTemplate: '/project-list',
    pageName: PAGE.PROJECT_LIST
  },

  'extraction-overview': {
    pathTemplate: '/region/:regionId/project/:projectId/extraction-overview',
    pageName: PAGE.EXTRACTION_OVERVIEW
  },

  'review-status': {
    pathTemplate: '/region/:regionId/project/:projectId/review-status',
    pageName: PAGE.REVIEW_STATUS
  },

  tags: {
    pathTemplate: '/region/:regionId/project/:projectId/tags',
    pageName: PAGE.TAGS
  }
};

/** Convert the pathname to the corresponding page name */
const checkInput = (path, hash = '') => {
  let match = null;
  const input = path + hash;
  Object.keys(LOCATION_METADATA).forEach(keyword => {
    if (input.includes(keyword)) {
      match = LOCATION_METADATA[keyword];
    }
  });

  if (!match && input === '/') {
    match = { pageName: PAGE.HOMEPAGE };
  }

  return match ? match : { pageName: 'Argus' };
};

/** Clean up email helper, removed '@deloitte.com' */
export const cleanEmail = email => {
  if (typeof email === 'string') {
    return { email: email.slice(0, -13) };
  }
  return { email: '' };
};

/** Get project id from the url path */
export const getProjectId = pathname => {
  let id = null;
  if (pathname.includes('/project')) {
    id = pathname.substring(pathname.indexOf('/project')).split('/')[2];
  }
  return id ? { projectId: parseInt(id, 10) } : null;
};

/** Get location page name helper function to be merged into data layer */
export const getLocation = (path, obj) => {
  const copy = obj;
  return Object.assign(copy, checkInput(path, obj.hash));
};

/** Get project helper function to be merged into data layer */
export const getProject = obj => {
  return { projectId: obj.projectId, projectName: obj.projectName, clientId: obj.engagementId };
};

/** Get document helper function to be merged into data layer */
export const getDocument = doc => {
  return {
    documentId: doc.projectItemId,
    documentStatus: doc.time ? getDocumentStatusGroup(doc.time) : doc.status
  };
};

/** Get document status timing group, depending on the elapsed time from the moment it was uploaded */
const getDocumentStatusGroup = time => {
  if (time <= 60) {
    return 'processed <= 1Min';
  } else if (time <= 600) {
    return 'processed <= 10Min';
  } else {
    return 'processed >= 15Min';
  }
};

/** Status Id to status group name converter helper function */
const getDocumentStatus = statusId => {
  if (statusId === ITEM_STATUSES.UPLOADED) {
    return 'uploaded';
  }
  if (statusId === ITEM_STATUSES.PREUPLOAD || statusId === ITEM_STATUSES.CONTENTPROCESSINGCOMPLETE) {
    return 'pre-processing';
  }

  if (statusId === ITEM_STATUSES.PROCESSING) {
    return 'processing';
  }

  if (statusId === ITEM_STATUSES.PROCESSED) {
    return 'processed';
  }

  if (statusId === ITEM_STATUSES.PENDINGDELETION || statusId === ITEM_STATUSES.DELETED) {
    return 'deleted';
  }

  if (statusId === ITEM_STATUSES.PARTIALLYEXTRACTED || statusId === ITEM_STATUSES.ALLEXTRACTIONERROR) {
    return 'extraction error';
  }

  return 'general error';
};

/** Status tracking class that basically tracks and calculates elapsed time from uploaded state to processed state */
export class StatusTracker {
  constructor() {
    this.reduxState = {};
    this.documents = [];
    this.actionType = 'argus/ui/ITEMS_UPDATED';

    // Time tiers are set to 1Min, 10Min, 15Min in seconds
    this.timeTiers = {
      first: 60,
      second: 600,
      third: 900
    };
  }

  /** Private function to get the file's dto  */
  _getFileItem(data) {
    return this.documents.find(doc => doc.projectItemId === data.projectItemId);
  }

  /** Private function to send DCR to Adobe Analytics  */
  _trackStatus(data) {
    updateDataLayer(this.actionType, data, this.reduxState);
    track(this.actionType);
  }

  /** Redux state is populated, as well as the documents inside the current folder  */
  initialize(data, state) {
    this.reduxState = state;
    this.documents = data.children;
  }

  /** It receives an action in order determine the elapsed time and track it  */
  checkStatus(action) {
    const id = action.payload[0].projectItemId;
    const status = getDocumentStatus(action.payload[0].itemStateId);

    if (status === 'processed') {
      return this.trackProcessed(action);
    }

    if (status === 'processing' || status === 'pre-processing') {
      return this.checkElapsedTime();
    }

    return this._trackStatus({ projectItemId: id, status });
  }

  /** When a file is processed, the elapsed time is calculated and sent to AA with the corresponding time group */
  trackProcessed(action) {
    const startTime =
      action.payload[0] && action.payload[0].lastUpdatedDate
        ? this._getFileItem(action.payload[0]).lastUpdatedDate
        : null;
    return this._trackStatus({
      projectItemId: action.payload[0].projectItemId,
      status: 'processed',
      time: dateUtil.getElapsedTime(startTime)
    });
  }

  /** This checks the elapsed time of the documents and determines if files are still in processing state after the third time tier */
  checkElapsedTime() {
    if (!this.documents || !this.documents.length) {
      return;
    }

    this.documents.forEach(file => {
      const status = getDocumentStatus(file.itemStateId);
      const elapsed = dateUtil.getElapsedTime(file.lastUpdatedDate);

      // If the third tier time has passed and files are still in processing state, we track it as unfinished
      if (elapsed >= this.timeTiers.third && (status === 'processing' || status === 'pre-processing')) {
        return this._trackStatus({ projectItemId: file.projectItemId, status: 'processing unfinished > 15Min' });
      }
    });
  }
}

export const statusTrack = new StatusTracker();
