import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import NameInputField from 'components/project-creation/project-details/shared/name-input-field';
import LockedInputField from 'components/project-creation/project-details/project-details-form/locked-input-field';
import enhanceWithClickOutside from 'react-click-outside';
import constants from 'utils/constants';
const { DateFormats } = constants;

class DateFormatTooltip extends Component {
  static propTypes = {
    showInnerTooltip: PropTypes.bool,
    closeInnerPopup: PropTypes.func
  };

  closeInnerPopup = () => {
    this.props.closeInnerPopup();
  };

  handleClickOutside = () => {
    this.closeInnerPopup();
  };

  render() {
    const { showInnerTooltip } = this.props;

    return showInnerTooltip ? (
      <div className="project-date-format-selection__Copy-Carry-tooltip">
        <div className="project-date-format-selection__tooltip-close">
          <Button className="icon-button" onClick={this.closeInnerPopup}>
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <FormattedMessage id="project-details-project-date-format-selection.toottip-content" />
      </div>
    ) : null;
  }
}
const InnerTooltip = enhanceWithClickOutside(DateFormatTooltip);

class ProjectDetailsForm extends Component {
  static propTypes = {
    allowEditing: PropTypes.bool,
    engagementInfo: PropTypes.any,
    intl: PropTypes.any,
    project: PropTypes.any,
    projectState: PropTypes.any,
    countryName: PropTypes.string,

    onEngagementNameChange: PropTypes.func,
    onProjectNameChange: PropTypes.func,

    setEngagementId: PropTypes.func,
    setEngagementInfo: PropTypes.func,
    setEngagementValidation: PropTypes.func,

    setNameResolved: PropTypes.func,
    setNameValidation: PropTypes.func,

    updateProjectEngagement: PropTypes.func,
    updateProjectName: PropTypes.func,
    customHeader: PropTypes.node,
    setClientValidation: PropTypes.func,
    onDateFormatChange: PropTypes.func,
    setWBSCodeResolved: PropTypes.any
  };

  state = {
    engagementInfo: {
      engagementName: '',
      externalEngagementId: '',
      clientName: '',
      clientId: '',
      engagementFiscalYearEnd: '',
      selectedDateFormat: 'MM/dd/yyyy',
      wbsCode: ''
    },
    error: '',
    previousWBSCode: ''
  };
  closeInnerPopup = () => {
    this.setState({ showInnertoolTip: false });
  };
  showInnertoolTip = () => {
    this.setState({ showInnertoolTip: true });
  };

  componentDidMount() {
    const { engagementInfo, setEngagementInfo } = this.props;
    this.validateEngagementInfo(engagementInfo);

    if (engagementInfo) {
      this.setState({ engagementInfo, previousWBSCode: engagementInfo.wbsCode });
      if (engagementInfo.wbsCode && engagementInfo.wbsCode.length > 0) this.props.setWBSCodeResolved(true);
    } else {
      setEngagementInfo(this.state.engagementInfo, true);
    }
  }

  componentWillUnmount() {
    this.setState({ showInnertoolTip: false });
  }

  onInput = (field, value) => {
    var { engagementInfo, error } = this.state;
    const { setEngagementInfo } = this.props;
    if (field != 'wbsCode' || (field == 'wbsCode' && (error == undefined || error == ''))) {
      engagementInfo[field] = value;
      this.setState({ engagementInfo });
    }

    if (field == 'wbsCode' && value && value.length > 0) {
      if (value.length > 20) {
        this.props.setWBSCodeResolved(false);
        this.setState({ error: this.props.intl.formatMessage({ id: 'engagement-details.body.wbsCode-error' }) });
      } else {
        this.props.setWBSCodeResolved(true);
        this.setState({ error: '' });
      }
    } else if (field == 'wbsCode') this.props.setWBSCodeResolved(false);

    setEngagementInfo(engagementInfo, true);
    this.validateEngagementInfo(engagementInfo);
  };

  onBlur = () => {
    const { SETTINGS } = this.props.projectState;
    const { engagementInfo, error, previousWBSCode } = this.state;

    if (error) {
      engagementInfo.wbsCode = previousWBSCode;
      this.setState({ engagementInfo });
    } else {
      this.setState({ previousWBSCode: engagementInfo.wbsCode });
    }

    if (SETTINGS) {
      this.props.updateProjectEngagement();
    }
  };

  validateEngagementInfo(engagementInfo) {
    // Nothing is required!
    this.props.setClientValidation(true);
    return this.props.setEngagementValidation(true);
  }

  render = () => {
    const {
      allowEditing,
      countryName,
      engagementInfo,
      intl,
      project,
      projectState,
      onProjectNameChange,
      setNameResolved,
      setNameValidation,
      updateProjectName,
      customHeader,
      selectedProjectDateFormat,
      onDateFormatChange
    } = this.props;
    let { showInnertoolTip, error } = this.state;
    const { SETTINGS } = projectState;
    const selectedDateFormat = localStorage.getItem('userSelectedDateFormat');
    const dateFormat =
      project.projectId == null
        ? ''
        : selectedProjectDateFormat != null
        ? selectedProjectDateFormat
        : selectedDateFormat;
    const formats = DateFormats;

    return (
      <div className="project-details__container manual-project-details-form mat-search-form">
        <div className="project-details__body-left project-details__upper-form-height">
          <div className="project-details__heading">
            <span className="project-details__heading-title">
              <FormattedMessage id="project-details.heading.title" />
            </span>
            {customHeader}
          </div>
          <div className="project-details__content">
            <div className="project-details__content-left">
              <NameInputField
                allowEditing={allowEditing}
                checkValid={setNameValidation}
                intl={intl}
                project={project}
                projectState={projectState}
                onChange={onProjectNameChange}
                updateProjectName={updateProjectName}
                setNameResolved={setNameResolved}
              />

              <div className="valid-textarea">
                <span className="valid-textarea__heading">
                  <FormattedMessage id="engagement-details.country" />
                </span>
                <div className="valid-textarea__uneditable">
                  <span className="valid-textarea__uneditable-text">{countryName}</span>
                </div>
              </div>
            </div>

            
          </div>
        </div>
        <div className="project-details__body-right project-details__lower-form-height">
          <div className="engagement-details__heading">
            <span className="engagement-details__heading-title">
              <FormattedMessage id="engagement-details.heading.title" />
            </span>
          </div>
          <div className="engagement-details__body">
            <div className="engagement-details__sub-body-left">
              <LockedInputField
                id="engagementName"
                value={engagementInfo !== null ? engagementInfo.engagementName : ''}
                onBlur={event => this.onBlur()}
                onChange={value => this.onInput('engagementName', value)}
                readOnly={!allowEditing}
                showLock={SETTINGS}
              />

              <LockedInputField
                id="externalEngagementId"
                value={engagementInfo !== null ? engagementInfo.externalEngagementId : ''}
                onBlur={event => this.onBlur()}
                onChange={value => this.onInput('externalEngagementId', value)}
                readOnly={!allowEditing}
                showLock={SETTINGS}
              />

              <LockedInputField
                id="clientId"
                value={engagementInfo !== null ? engagementInfo.clientId : ''}
                onBlur={event => this.onBlur()}
                onChange={value => this.onInput('clientId', value)}
                readOnly={!allowEditing}
                showLock={SETTINGS}
                type={'number'}
              />

              <LockedInputField
                id="wbsCode"
                value={engagementInfo !== null ? engagementInfo.wbsCode : ''}
                onBlur={event => this.onBlur()}
                onChange={value => this.onInput('wbsCode', value)}
                readOnly={!allowEditing}
                showLock={SETTINGS}
                error={error}
                isAsteriskRequired = "true"
              />
            </div>

            <div className="engagement-details__sub-body-right">
              <LockedInputField
                id="clientName"
                value={engagementInfo !== null ? engagementInfo.clientName : ''}
                onBlur={event => this.onBlur()}
                onChange={value => this.onInput('clientName', value)}
                readOnly={!allowEditing}
                showLock={SETTINGS}
              />

              <LockedInputField
                id="fiscalYearEnd"
                value={engagementInfo !== null ? engagementInfo.engagementFiscalYearEnd : ''}
                onBlur={event => this.onBlur()}
                onChange={value => this.onInput('engagementFiscalYearEnd', value)}
                readOnly={!allowEditing}
                showLock={SETTINGS}
                type={'year'}
              />

              <LockedInputField
                id="fiscalEnd"
                value={engagementInfo !== null ? engagementInfo.engagementFiscalYearEnd : ''}
                onBlur={event => this.onBlur()}
                onChange={value => this.onInput('engagementFiscalYearEnd', value)}
                readOnly={!allowEditing}
                showLock={SETTINGS}
                type={'month-day'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default ProjectDetailsForm;
