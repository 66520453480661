import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import emptyStateImage from 'images/empty_state_extraction_fields.svg';
import EmptyState from 'components/file-browser/file-list/empty-state';
import TemplateExtractionsGroupList from 'components/create-template/templates/template-steps/template-extractions-list/template-extractions-group-list';
import ValidatedTextField from 'components/shared/form/validated-text-field';
import Constants from 'utils/constants';

/** Guided template viewer is when the user is first prompted to create a template */
class GuidedTemplateViewer extends Component {
  static propTypes = {
    changeIsFormValid: PropTypes.func,
    extractions: PropTypes.any,
    intl: intlShape.isRequired,
    isGroupExtLoading: PropTypes.bool,
    isOtherTemplate: PropTypes.bool,
    isResolved: PropTypes.bool,
    isUpdate: PropTypes.bool,
    onAdd: PropTypes.func,
    onChange: PropTypes.func,
    onCheckboxClick: PropTypes.func,
    onExtractionChange: PropTypes.func,
    onUpdate: PropTypes.func,
    selectedType: PropTypes.any,
    templateTypes: PropTypes.any,
    value: PropTypes.string
  };

  state = {
    isFormValid: false
  };

  setFormRef = elem => {
    this.formElem = elem;
  };

  validateForm = () => {
    const { changeIsFormValid } = this.props;
    return (
      this.formElem &&
      this.setState({ isFormValid: this.formElem.checkValidity() }, () =>
        changeIsFormValid(this.formElem.checkValidity())
      )
    );
  };

  componentDidMount() {
    this.validateForm();
  }

  //Prevent page refresh when the form is submitted on Edge browser
  onSubmit = e => {
    e.preventDefault();
    return false;
  };

  onAdd = e => {
    const { onAdd } = this.props;
    e.preventDefault();
    onAdd();
  };

  render() {
    const {
      onChange,
      onCheckboxClick,
      onExtractionChange,
      // onDelete,
      value,
      extractions,
      intl,
      isResolved,
      isOtherTemplate,
      isUpdate,
      templateTypes,
      isGroupExtLoading,
      selectedType
    } = this.props;
    const { isFormValid } = this.state;
    const ignoreSort = selectedType?.templateCategoryId === Constants.TemplateCategories.DETAIL_TESTING;
    return (
      <form ref={this.setFormRef} className="guided-template" onSubmit={this.onSubmit}>
        <ValidatedTextField
          className="guided-template__name-input"
          debounce={true}
          errors={!isFormValid && <FormattedMessage id="guided-template.template-name-error" />}
          headingId="guided-template.template-name"
          maxLength="400"
          onChange={onChange}
          onValid={this.validateForm}
          placeholder={intl.formatMessage({ id: 'create-template.untitled' })}
          value={value}
        />
        {!isOtherTemplate ? (
          <div className="guided-template__extractions">
            {!extractions || !isResolved ? (
              <div className="guided-template__add">
                <Icon className="spinner spinner--centered" name="loader" width={80} />
              </div>
            ) : isUpdate ? (
              <TemplateExtractionsGroupList
                extractions={extractions}
                onExtractionChange={onExtractionChange}
                onCheckboxClick={onCheckboxClick}
                isGroupExtLoading={isGroupExtLoading}
                ignoreSort={ignoreSort}
              />
            ) : (
              <TemplateExtractionsGroupList
                templateTypes={templateTypes}
                extractions={extractions}
                onExtractionChange={onExtractionChange}
                onCheckboxClick={onCheckboxClick}
                isGroupExtLoading={isGroupExtLoading}
                ignoreSort={ignoreSort}
              />
            )}
          </div>
        ) : (
          <div className="guided-template__empty-state">
            <EmptyState
              title={<FormattedMessage id="template-viewer.empty-state.title" />}
              description={<FormattedMessage id="template-viewer.empty-state.description" />}
              img={emptyStateImage}
            />
            <Button
              className="guided-template__add-btn btn btn-no-margin btn-primary"
              onClick={this.onAdd}
              disabled={!isFormValid}
            >
              <FormattedMessage id="guided-template.add-to-empty-template" />
            </Button>
          </div>
        )}
        {!isOtherTemplate ? (
          <div className="guided-template__create-footer">
            <Button className="guided-template__add-extraction" onClick={this.onAdd}>
              <FormattedMessage id="guided-template.add-extraction" />
            </Button>
          </div>
        ) : null}
      </form>
    );
  }
}

export default injectIntl(GuidedTemplateViewer);
