import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';

import CONSTANTS from 'utils/constants';
import { STEPS, DEFAULT_STEPS, SETTINGS_STEPS } from 'components/project-creation/constants';
import ProgressBar from 'components/project-creation/progress-bar/progress-bar';
import ProjectDetails from 'components/project-creation/project-details/project-details';
import TeamMembers from 'containers/project-creation/team-members-container';
import { FormattedMessage } from 'react-intl';
import Templates from 'containers/create-template/templates-container';

class ProjectCreation extends Component {
  static propTypes = {
    clearProjectData: PropTypes.func,
    createProjectTrack: PropTypes.func,
    currentProject: PropTypes.object,
    currentUser: PropTypes.object,
    history: PropTypes.object,
    loadProjectTemplates: PropTypes.func,
    location: PropTypes.object,
    projectId: PropTypes.any,
    projectTemplates: PropTypes.object,
    updateProjectEngagement: PropTypes.func,
    updateProjectName: PropTypes.func
  };

  state = {
    projectState: {
      SETTINGS: false,
      NEW: false,
      INPROGRESS: false,
      COPY: false,
      CARRY: false,
      ARCHIVE: false
    },
    isLoading: false,
    step: null
  };

  // Set initial state helper fn
  setInitialState = (option, step) => {
    const { projectState } = this.state;
    projectState[option] = true; // Make the page we're on true
    this.setState({ projectState, step, isLoading: false }); // Set step to navigate to based on that page
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname != nextProps.location.pathname) {
      const { projectId, currentProject, location, loadProjectTemplates } = nextProps;
      if (!projectId) {
        // New Project
        return this.setInitialState('NEW', STEPS.PROJECT_DETAILS);
      }
      else if (currentProject.projectStateId == CONSTANTS.ProjectStateIds.inCreation && currentProject.projectTypeId == CONSTANTS.ProjectTypes.WORKSPACE_PROJECT){
        return this.setInitialState('NEW', STEPS.WORKFLOWS);
      }     
      else if (currentProject.STATES.Started() && location.pathname.includes('settings')) {
        // Settings page
        return this.setInitialState('SETTINGS', STEPS.PROJECT_DETAILS);
      } else if (location.pathname.includes('project-details')) {
        return this.setInitialState('SETTINGS', STEPS.PROJECT_DETAILS);
      } else if (location.pathname.includes('team-members')) {
        return this.setInitialState('SETTINGS', STEPS.TEAM_MEMBERS);
      } else if (currentProject.STATES.Creation() && location.pathname.includes('copy')) {
        // Project copy workflow
        if (currentProject.engagementId && currentProject.sourceType !== CONSTANTS.ProjectSourceTypes.UserEntered) {
          // Project already has engament id
          return this.setInitialState('COPY', STEPS.TEAM_MEMBERS);
        } else {
          // Project has no engament id or is a user entered engagement
          return this.setInitialState('COPY', STEPS.PROJECT_DETAILS);
        }
      } else if (currentProject.STATES.ReadOnly()) {
        // Project archive
        return this.setInitialState('ARCHIVE', STEPS.PROJECT_DETAILS);
      } else if (currentProject.STATES.InProgress() && location.pathname.includes('creation')) {
        // Project creation in progress
        loadProjectTemplates({ projectId }).then(result => {
          const { projectTemplates } = this.props;
          if (currentProject.users.filter(v => v.userDetail.isPPD).length >= 1 && projectTemplates.templates.length) {
            // Project already has a PPD
            return this.setInitialState('INPROGRESS', STEPS.TEAM_MEMBERS);
          } 
          else if (currentProject.engagementId) {
            // Project already has an engagement id
            return this.setInitialState('INPROGRESS', STEPS.TEAM_MEMBERS);
          } else {
            // Project has no engament id
            return this.setInitialState('INPROGRESS', STEPS.PROJECT_DETAILS);
          } 
        });
      }
    }
  }
  componentDidUpdate(prevProps) {
    //Reinitialize on state change
    if (this.props.currentProject.projectStateId !== prevProps.currentProject.projectStateId) {
      this.initialize();
    }
  }

  initialize() {
    const { projectId, currentProject, location, loadProjectTemplates } = this.props;

    // Setting initial views for project creation/settings/copy page
    if (!projectId) {
      // New Project
      return this.setInitialState('NEW', STEPS.PROJECT_DETAILS);
    } 
    else if (currentProject.projectStateId == CONSTANTS.ProjectStateIds.inCreation && currentProject.projectTypeId == CONSTANTS.ProjectTypes.WORKSPACE_PROJECT){
      return this.setInitialState('NEW', STEPS.WORKFLOWS);
    } 
    else if (location.state && location.state.grantingAccess) {
      return this.setInitialState(location.state.inCreation ? 'INPROGRESS' : 'SETTINGS', STEPS.TEAM_MEMBERS);
    } 
    else if (currentProject.STATES.Started() && location.pathname.includes('settings')) {
      // Settings page
      return this.setInitialState('SETTINGS', STEPS.PROJECT_DETAILS);
    } else if (location.pathname.includes('project-details')) {
      return this.setInitialState('SETTINGS', STEPS.PROJECT_DETAILS);
    } else if (location.pathname.includes('team-members')) {
      return this.setInitialState('SETTINGS', STEPS.TEAM_MEMBERS);
    } else if (currentProject.STATES.Creation() && location.pathname.includes('copy')) {
      // Project copy workflow
      if (currentProject.engagementId && currentProject.sourceType !== CONSTANTS.ProjectSourceTypes.UserEntered) {
        // Project already has engament id
        return this.setInitialState('COPY', STEPS.TEAM_MEMBERS);
      } else {
        // Project has no engament id or is a user entered engagement
        return this.setInitialState('COPY', STEPS.PROJECT_DETAILS);
      }
    } else if (currentProject.STATES.ReadOnly()) {
      // Project archive
      return this.setInitialState('ARCHIVE', STEPS.PROJECT_DETAILS);
    } else if (currentProject.STATES.InProgress() && location.pathname.includes('creation')) {
      // Project creation in progress
      loadProjectTemplates({ projectId }).then(result => {
        const { projectTemplates } = this.props;
        if (projectTemplates.templates.length) {
          // Project already has a PPD
          return this.setInitialState('INPROGRESS', STEPS.TEAM_MEMBERS);
        }   
        else if (currentProject.engagementId) {
          // Project already has an engagement id
          return this.setInitialState('INPROGRESS', STEPS.TEAM_MEMBERS);
        } else {
          // Project has no engament id
          return this.setInitialState('INPROGRESS', STEPS.PROJECT_DETAILS);
        }
      });
    }
  }

  onChangeStep = newStepIndex => {
    const { step } = this.state;

    if (newStepIndex !== step) {
      this.setState({
        step: newStepIndex
      });
    }
  };

  renderStep = steps => {
    const { step, projectState } = this.state;
    const {
      currentProject,
      createProjectTrack,
      updateProjectName,
      updateProjectEngagement,
      currentUser,
      projectId,
      history
    } = this.props;

    switch (step) {
      case STEPS.PROJECT_DETAILS:
        return (
          <ProjectDetails
            onChangeStep={this.onChangeStep}
            step={step}
            steps={steps}
            history={history}
            project={currentProject}
            projectId={projectId}
            projectState={projectState}
            createProjectTrack={createProjectTrack}
            updateProjectName={updateProjectName}
            updateProjectEngagement={updateProjectEngagement}
            user={currentUser}
            {...this.props}
          />
        );
      case STEPS.TEAM_MEMBERS:
        return (
          <TeamMembers
            history={history}
            project={currentProject}
            onChangeStep={this.onChangeStep}
            step={step}
            steps={steps}
            projectState={projectState}
            user={currentUser}
            {...this.props}
          />
        );
      case STEPS.WORKFLOWS:
        return <Templates project={currentProject} onChangeStep={this.onChangeStep} step={2} steps={[0, 1, 2]} />;
      default:
        return null;
    }
  };

  render() {
    const { step, projectState } = this.state;

    const isSettings = projectState.SETTINGS || projectState.COPY || projectState.ARCHIVE;
    const steps = isSettings ? SETTINGS_STEPS : DEFAULT_STEPS;
    const { currentProject, location } = this.props;
    const hideProgressBar =
      location.pathname.includes('project-details') || location.pathname.includes('team-members') ? true : false;

    if (step === null) {
      return (
        <div className="project-creation">
          <ProgressBar step={step} steps={[]} projectState={projectState} />
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        </div>
      );
    }

    return (
      <div className="project-creation-content">
        <div className="project-creation-header">
          <div className="project-creation-header content">
            <span className="project-creation-breadcum">
              <FormattedMessage id="project-creations.breadcum" />
            </span>
            &nbsp; / &nbsp;
            <span>
              <FormattedMessage id="project-creations.progress-bar.title" />
            </span>
            &nbsp; / &nbsp;
            {currentProject.projectName}
          </div>
        </div>
        {!hideProgressBar ? (
          <div className="project-creation">
            <ProgressBar
              step={step}
              steps={steps}
              projectState={projectState}
              onProgressTextClick={step => this.setState({ step })}
            />
            {this.renderStep(steps)}
          </div>
        ) : (
          <div className="project-creation-view">{this.renderStep(steps)}</div>
        )}
      </div>
    );
  }
}

export default ProjectCreation;
