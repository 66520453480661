import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import SearchMenu from './shared/search-menu';
import DocumentSearch from 'containers/search/document-search/document-search';
import ProjectListSearch from 'containers/search/project-list-search/project-list-search-container';
import GroupsSearch from 'containers/search/groups-search/groups-search-container';
import UserSearch from 'containers/search/user-search/user-search-container';
import FileListSearch from 'containers/search/file-list-search/file-list-search-container';
import CompareSearch from 'containers/search/compare-search/compare-search-container';
import AnalyticsSearch from 'containers/search/analytics-search/analytics-search';

import Constants from 'utils/constants';

/** Main search component that changes the search function based on the page user is on */
class UniversalSearch extends Component {
  static propTypes = {
    compareData: PropTypes.shape({
      base: PropTypes.array,
      compare: PropTypes.array
    }),
    compareSetEmphasis: PropTypes.func,
    compareSetSearch: PropTypes.func,
    customValue: PropTypes.any,
    documentViewerSetSearchResults: PropTypes.func,
    history: PropTypes.object,
    isSearchResultsOpen: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    projectId: PropTypes.string,
    fromProjectListPage : PropTypes.bool,
    fromQuickStudyPage : PropTypes.bool,
  };

  static defaultProps = {
    compareData: {
      base: [],
      compare: []
    }
  };

  state = {
    searchType: ''
  };

  setSearchType = type => {
    this.setState({ searchType: type });
  };

  render() {
    const { documentViewerSetSearchResults, location, match, customValue, isSearchResultsOpen,fromProjectListPage ,fromQuickStudyPage,fromUserManagementPage} = this.props;
    const { searchType } = this.state;
    const TYPE = Constants.SearchTypes;

    return (
      <div className="search-container" style={{ flex: 1, minWidth: 0 }}>
        <div className="search-bar">         
           <SearchMenu
            customValue={customValue}
            location={location}
            match={match}
            setType={this.setSearchType}
            isSearchResultsOpen={isSearchResultsOpen}
            fromProjectListPage ={fromProjectListPage}
            fromQuickStudyPage = {fromQuickStudyPage}
            fromUserManagementPage = {fromUserManagementPage}
          />
          
          {searchType === TYPE.PROJECT_LIST_SEARCH ? <ProjectListSearch {...this.props}/> : null}

          {searchType === TYPE.GROUPS_SEARCH ? <GroupsSearch {...this.props} /> : null}

          {searchType === TYPE.USERS_SEARCH ? <UserSearch {...this.props} /> : null}

          {searchType === TYPE.FILE_LIST_SEARCH ? <FileListSearch {...this.props} /> : null}

          {searchType === TYPE.COMPARE_VIEWER_SEARCH ? <CompareSearch {...this.props} /> : null}

          {searchType === TYPE.ANALYTICS_SEARCH ? (
            <AnalyticsSearch {...this.props} fileListSearchProps={this.props} />
          ) : null}

          {searchType === TYPE.DOCUMENT_VIEWER_SEARCH ? (
            <DocumentSearch setSearchResults={documentViewerSetSearchResults} match={match} />
          ) : null}
          
        </div>
      </div>
    );
  }
}

export default withRouter(UniversalSearch);
