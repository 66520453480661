import { connect } from 'react-redux';

import { loadCurrentFolder } from 'store/current-folder';
import AddToCompareSidebar from 'components/shared/add-to-compare-sidebar/add-to-compare-sidebar';

const mapStateToProps = ({ data: { currentFolder } }) => ({
  currentFolder
});

const mapDispatchToProps = {
  loadCurrentFolder
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCompareSidebar);
