import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Confirm from 'components/shared/confirm';

class ExportCompareModal extends Component {
  static propTypes = {
    disableSubmit: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  render() {
    const { disableSubmit, onCancel, onSubmit } = this.props;

    return (
      <Confirm
        disableSubmit={disableSubmit}
        onCancel={onCancel}
        onSubmit={onSubmit}
        title={<FormattedMessage id="compare-browser.export.title" />}
        message={<FormattedMessage id="compare-browser.export.question" />}
        cancelText={<FormattedMessage id="compare-browser.export.cancel-button" />}
        confirmText={<FormattedMessage id="compare-browser.export.export-button" />}
      />
    );
  }
}

export default ExportCompareModal;
