import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import { FormattedMessage } from 'react-intl';

class DocumentControls extends React.Component {
  static propTypes = {
    changePageFunction: PropTypes.func,
    changeZoomFunction: PropTypes.func,
    currentPage: PropTypes.any,
    numPages: PropTypes.any,
    zoomLevel: PropTypes.number
  };

  state = {
    page: 1,
    zoomLevel: 1
  };

  zoomLevels = [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 4.0];

  componentDidMount() {
    this.setState({ page: Math.floor(this.props.currentPage) });
    this.setState({ zoomLevel: Math.floor(this.props.zoomLevel * 100) });
  }

  componentDidUpdate(prevProps) {
    var { currentPage, zoomLevel } = this.props;
    if (currentPage !== prevProps.currentPage) this.resetPage();
    if (zoomLevel !== prevProps.zoomLevel) this.resetZoom();
  }

  pageTextChange = e => {
    if (/^(\s*|\d+)$/.test(e.target.value)) {
      this.setState({ page: e.target.value });
    }
  };

  zoomTextChange = e => {
    if (/^(\s*|\d+)$/.test(e.target.value)) {
      this.setState({ zoomLevel: e.target.value });
    }
  };

  pageKeyDown = e => {
    var { page } = this.state;
    var { numPages } = this.props;

    if (e.keyCode === 13) {
      if (0 < page && page <= numPages) {
        this.props.changePageFunction(page);
      } else {
        this.setState({
          pageError: page
        });
        this.resetPage();
      }
    } else if (e.keyCode === 27) {
      this.resetPage();
    }
  };

  zoomKeyDown = e => {
    var { zoomLevel } = this.state;

    if (e.keyCode === 13) {
      if (this.zoomLevels[0] <= zoomLevel / 100 && zoomLevel / 100 <= this.zoomLevels[this.zoomLevels.length - 1]) {
        this.props.changeZoomFunction(zoomLevel / 100);
      } else {
        this.setState({
          zoomError: zoomLevel
        });
        this.resetZoom();
      }
    } else if (e.keyCode === 27) {
      this.resetZoom();
    }
  };

  resetPage = () => {
    this.setState({ page: this.props.currentPage });
  };

  resetZoom = () => {
    this.setState({ zoomLevel: this.props.zoomLevel * 100 });
  };

  clearErrors = () => {
    this.setState({ pageError: false, zoomError: false });
  };

  previousZoomLevel = () => {
    var rzoom = this.zoomLevels.slice().reverse();
    var index = rzoom.findIndex(n => n < this.props.zoomLevel);
    if (index !== -1) this.props.changeZoomFunction(rzoom[index]);
  };

  nextZoomLevel = () => {
    var index = this.zoomLevels.findIndex(n => n > this.props.zoomLevel);
    if (index !== -1) this.props.changeZoomFunction(this.zoomLevels[index]);
  };

  render() {
    var { currentPage, numPages, changePageFunction } = this.props;
    var { page, pageError, zoomLevel, zoomError } = this.state;
    var min = this.zoomLevels[0] * 100;
    var max = this.zoomLevels[this.zoomLevels.length - 1] * 100;

    return numPages && numPages > 0 && currentPage ? (
      <div className="document-controls">
        <div className="page-controls">
          <button
            id="next-page-button"
            disabled={currentPage >= numPages || numPages <= 1}
            onClick={() => {
              changePageFunction(parseInt(currentPage, 10) + 1);
            }}
          >
            <Icon className="next-page" name="special-arrow-down-white" width={10} height={10} />
          </button>
          <input
            type="text"
            onBlur={this.resetPage}
            onChange={this.pageTextChange}
            onKeyDown={this.pageKeyDown}
            value={page}
          />
          <span className="page-count">/ {numPages}</span>
          <button
            id="previous-page-button"
            disabled={currentPage <= 1}
            onClick={() => {
              changePageFunction(parseInt(currentPage, 10) - 1);
            }}
          >
            <Icon className="last-page" name="special-arrow-down-white" width={10} height={10} />
          </button>
        </div>

        <div className="zoom-controls">
          <button
            id="zoom-out-button"
            disabled={zoomLevel / 100 === this.zoomLevels[0]}
            onClick={this.previousZoomLevel}
          >
            -
          </button>
          <input
            type="text"
            onBlur={this.resetZoom}
            onChange={this.zoomTextChange}
            onKeyDown={this.zoomKeyDown}
            value={zoomLevel}
          />%
          <button
            id="zoom-in-button"
            disabled={zoomLevel / 100 === this.zoomLevels[this.zoomLevels.length - 1]}
            onClick={this.nextZoomLevel}
          >
            +
          </button>
        </div>

        {pageError && (
          <Modal>
            <div className="modal__content">
              <h3>
                <FormattedMessage id="document-controls.modal.page-error" />
              </h3>
              <p>
                <FormattedMessage id="document-controls.modal.page-error-description" values={{ page: pageError }} />
              </p>
              <div className="modal__footer">
                <button className="btn btn-primary" onClick={this.clearErrors}>
                  <FormattedMessage id="document-controls.modal.page-error-button" />
                </button>
              </div>
            </div>
          </Modal>
        )}

        {zoomError && (
          <Modal>
            <div className="modal__content">
              <h3>
                <FormattedMessage id="document-controls.modal.zoom-error" />
              </h3>
              <p>
                <FormattedMessage
                  id="document-controls.modal.zoom-error-description"
                  values={{ min, max, target: zoomError }}
                />
              </p>
              <div className="modal__footer">
                <button className="btn btn-primary" onClick={this.clearErrors}>
                  <FormattedMessage id="document-controls.modal.zoom-error-button" />
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    ) : null;
  }
}
export default DocumentControls;
