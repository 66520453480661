import { connect } from 'react-redux';

import { deleteNotification, subscribeToGlobalErrors, unSubscribeToGlobalErrors } from 'store/notifications';
import Notifications from 'components/shared/notifications';

const mapStateToProps = ({ ui: { notifications } }) => ({
  notifications
});

const mapDispatchToProps = {
  deleteNotification,
  subscribeToGlobalErrors,
  unSubscribeToGlobalErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
