/**
 * Natural (alphanumeric) sort a list of items by a key
 * @param {string} key - the key to sort the items by
 * @param {array} items - list of items to sort
 * @returns {array} - sorted list of items
 */
export function sortByKey(key, items) {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  return items.sort((a, b) => collator.compare(a[key], b[key]));
}

/**
 * Escape delimiters in filter strings to avoid errors
 * @param {string} query - search query string
 * @returns {string} - escaped search query string
 */
export function cleanQuery(query) {
  // List of delimiters to escape in a string
  const DELIMITERS = ['\\', ';', '&', ':', '='];
  if (!query) {
    return '';
  }
  DELIMITERS.forEach(d => {
    // replace the query containing a delimiter with a backslash
    query = query.replace(d, '\\' + d);
  });
  return query;
}
