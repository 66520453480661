import React, { Component } from 'react';
import ProgressBar from 'components/project-creation/progress-bar/progress-bar';
import Header from 'containers/header/header';
import Icon from 'components/shared/icon';


export class DefaultLoader extends Component {
  render() {
    return (
      <div className="page">
        <Header {...this.props} inputDisabled fromLandingPage />
        <Icon className="spinner spinner--centered" name="loader" width={80} />
      </div>
    );
  }
}

export class ProjectLoader extends Component {
  render() {  
    return (
      <div className="page">
        <Header {...this.props} inputDisabled currentPage="global.subheader.extraction" />
        <div className="project-creation">
          <ProgressBar
            step={null}
            steps={[]}
            projectState={{
              SETTINGS: false,
              NEW: false,
              INPROGRESS: false,
              COPY: false,
              CARRY: false,
              ARCHIVE: false
            }}
          />
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        </div>
      </div>
    );
  }
}
