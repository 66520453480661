import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import YearPicker from 'components/shared/form/year-picker';
import MonthDayPicker from 'components/shared/form/month-day-picker';

class LockedInputField extends Component {
  static propTypes = {
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    showLock: PropTypes.bool,
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    error: PropTypes.string,
    isAsteriskRequired: PropTypes.bool
  };

  state = {
    unlocked: false
  };

  onBlur = () => {
    const { id,error } = this.props;
    if(id == 'wbsCode' && error)
      this.setState({ unlocked: true });
    else{
      this.setState({ unlocked: false });
      this.props.onBlur();
    }
  };

  onChange = newValue => {
    this.props.onChange(newValue);
  };

  onNumberChange = newValue => {
    const { onChange } = this.props;
    const regex = /^[0-9]*$/;

    if (newValue === '' || regex.test(newValue)) {
      onChange(newValue);
    }
  };

  render = () => {
    const { id, onChange, readOnly, showLock, value, type, error, isAsteriskRequired } = this.props;

    const { unlocked } = this.state;

    const displayValue =
      value && type && type === 'year' ? (
        <FormattedDate value={value} year="numeric" />
      ) : value && type && type === 'month-day' ? (
        <FormattedDate value={value} month="numeric" day="numeric" />
      ) : value !== null ? (
        value
      ) : (
        ''
      );
      
    return (
      <div className="locked-input-field">
        <label className={id == 'wbsCode' && error ? 'engagement-details__sub-heading-invalid' : 'engagement-details__sub-heading'}   htmlFor={id}>
          <FormattedMessage id={`engagement-details.${id}`} />
          {isAsteriskRequired && (<span className="project-details__required-field">*</span>)}
        </label>
        {readOnly ? (
          <div className="engagement-details__value">{displayValue}</div>
        ) : showLock && !unlocked ? (
          <div className="engagement-details__value locked">
            {displayValue}
            <Button
              size="icon"
              className="valid-textarea__uneditable-button"
              onClick={() => this.setState({ unlocked: true })}
            >
              <Icon name="special-edit" width={24} />
            </Button>
          </div>
        ) : type && type === 'year' ? (
          <div>
            <YearPicker
              disabled={readOnly}
              value={value}
              onChange={value => {
                onChange(value);
                setTimeout(() => this.onBlur(), 500);
              }}
            />
          </div>
        ) : type && type === 'month-day' ? (
          <div className="month-day-wrapper">
            <MonthDayPicker
              disabled={readOnly}
              value={value}
              onChange={value => {
                onChange(value);
                setTimeout(() => this.onBlur(), 500);
              }}
            />
          </div>
        ) : type && type === 'number' ? (
          <input
            className="engagement-details__input"
            type={'text'}
            id={id}
            name={id}
            onChange={event => this.onNumberChange(event.target.value)}
            onBlur={this.onBlur}
            value={value !== null ? value : ''}
          />
        ) : (
          <div>
          <input
            className={classNames('engagement-details__input', id == 'wbsCode' && error ? 'invalid' : '')}
            type={type ? type : 'text'}
            id={id}
            name={id}
            onChange={event => this.onChange(event.target.value)}
            onBlur={this.onBlur}
            defaultValue={value !== null ? value : ''}
          />
          {id == 'wbsCode' && error &&
          <span className='valid-textarea__error invalid'>
          {error}
        </span>}</div>
        )}
      </div>
    );
  };
}

export default LockedInputField;
