import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { ComparisonSet } from 'models/comparison-sets';

export const ACTIONS = {
  ACTIVE_COMPARISON_SET_LOADING: 'argus/ui/ACTIVE_COMPARISON_SET_LOADING',
  ACTIVE_COMPARISON_SET_LOADED: 'argus/ui/ACTIVE_COMPARISON_SET_LOADED',
  ACTIVE_COMPARISON_SET_ERROR: 'argus/ui/ACTIVE_COMPARISON_SET_ERROR'
};

export const activeComparisonSetLoading = createAction(ACTIONS.ACTIVE_COMPARISON_SET_LOADING);
export const activeComparisonSetLoaded = createAction(ACTIONS.ACTIVE_COMPARISON_SET_LOADED);
export const activeComparisonSetError = createAction(ACTIONS.ACTIVE_COMPARISON_SET_ERROR);

export const loadActiveComparisonSet = ({ projectId, compareId, compareType }) => dispatch => {
  dispatch(activeComparisonSetLoading());
  return Promise.all([
    api.getComparisonSetDetails({ projectId, compareId, compareType }),
    api.getComparisonBaseDocument({ projectId, compareId, compareType }),
    api.getComparisonSummary({ projectId, compareId, compareType })
  ])
    .then(([comparisonResponse, baseDocumentResponse, comparisonSummary]) =>
      enhanceComparisonSummary({
        comparison: comparisonResponse.data,
        baseDocument: baseDocumentResponse.data,
        comparisonSummary: comparisonSummary.data
      })
    )
    .then(response => dispatch(activeComparisonSetLoaded(response)))
    .catch(err => {
      dispatch(activeComparisonSetError({ err }));
    });
};

const enhanceComparisonSummary = response => {
  const { comparison, baseDocument, comparisonSummary } = response;

  if (
    !baseDocument ||
    !Array.isArray(baseDocument) ||
    !comparison ||
    !comparison.comparisonDetails ||
    !comparison.comparisonDetails.length
  ) {
    return response;
  }

  const enhancedComparisonSummary = comparison.comparisonDetails.map(currentDocument => {
    const { compareEntity } = currentDocument;

    let currentSummary = comparisonSummary.find(filt => filt.projectItemId === compareEntity.projectItemId);

    return {
      documentId: compareEntity.projectItemId,
      documentName: compareEntity.itemName,
      projectItemId: compareEntity.projectItemId,

      adds: currentSummary ? currentSummary.adds : null,
      deletes: currentSummary ? currentSummary.deletes : null,
      edits: currentSummary ? currentSummary.edits : null,
      documentChanges: currentSummary ? currentSummary.documentChanges : null,
      totalChanges: currentSummary ? currentSummary.documentChanges : null
    };
  });

  return { ...response, comparisonSummary: enhancedComparisonSummary };
};

/*
 * Reducer
 */
export const INITIAL_STATE = new ComparisonSet();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.ACTIVE_COMPARISON_SET_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.ACTIVE_COMPARISON_SET_LOADED]: (state, action) => {
    return state.setLoaded(action.payload);
  },

  [ACTIONS.ACTIVE_COMPARISON_SET_ERROR]: (state, action) => {
    return state.setError(action.payload.err);
  }
});
