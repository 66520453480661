import model from 'models/base-model';

export class Document extends model({
  // Control Properties
  isLoading: false,
  isLoaded: false,
  error: null,

  // Usefull Properties
  assignments: null,
  characters: null,
  documentId: null,
  extractionFieldRecords: null,
  extractionFields: null,
  itemName: null,
  itemStateId: null,
  next: null,
  pageCount: null,
  pages: null,
  parentHierarchy: null,
  prev: null,
  projectId: null,
  projectItemId: null,
  projectItemStatus: null,
  projectTemplate: null, // I don't think this one is used
  projectTemplates: null,
  reviewers: null,
  tags: null,

  // Unnessesary Properties
  createdBy: null,
  createdDate: null,
  entityUrl: null,
  fileExtension: null,
  itemType: null,
  itemTypeId: null, // Check this one
  lastUpdatedBy: null,
  lastUpdatedData: null,
  parentFolderDocumentCount: null, // Check this one
  parentId: null, // Check this one

  // File organizer
  sections: null,
  filterById: null,
  sectionLoading: null,
  selectedSection: null,
  isSelectedSectionClassified: false
}) {
  setLoading() {
    return this.merge({
      error: null,
      isLoaded: false,
      isLoading: true
    });
  }

  setError(error) {
    return this.merge({
      error: error,
      isLoaded: false,
      isLoading: false
    });
  }

  setDeleted() {
    return this.merge({
      extractionFields: [],
      extractionFieldRecords: [],
      reviewers: [],
      projectTemplates: []
    });
  }

  clear() {
    return new Document({
      error: null,
      isLoaded: false,
      isLoading: false
    });
  }

  invalidate() {
    return this.merge({
      error: null,
      isLoaded: false,
      isLoading: false
    });
  }

  setLoaded(data) {
    return Document.fromData(data);
  }

  static fromData(data) {
    var enhanced = enhanceRecords(data);

    return new Document({
      error: null,
      isLoaded: true,
      isLoading: false,
      filterById: {
        extractionFieldGroupIds: [],
        selectedSection: null,
        isSelectedSectionClassified: false,
        isPage: true
      },
      ...enhanced
    });
  }

  // Manipulation functions
  tagAdd(data) {
    var tags = this.tags.slice(0);
    if (!tags.find(tag => tag.tagId === data.tagId)) {
      tags.push(data);
    }
    return this.merge({ tags });
  }

  tagRemove(data) {
    var tags = this.tags.slice(0);
    tags = tags.filter(tag => tag.tagId !== data.tagId);
    return this.merge({ tags });
  }

  reviewerAdd(data) {
    var reviewers = this.reviewers.slice(0);
    if (!reviewers.find(reviewer => reviewer.userId === data.userId)) {
      reviewers.push(data);
    }
    return this.merge({ reviewers });
  }

  reviewerRemove(data) {
    var reviewers = this.reviewers.slice(0);
    reviewers = reviewers.filter(reviewer => reviewer.userId !== data.userId);
    return this.merge({ reviewers });
  }

  efAllRecordsRemove(extractionFieldId) {
    var extractionFieldRecords = this.extractionFieldRecords.slice(0);
    extractionFieldRecords = extractionFieldRecords.filter(efr => efr.extractionFieldId !== extractionFieldId);

    // If the current document is reviewed when this action happens, decrement the reviewDocumentCount
    if (this.reviewers.length > 0) {
      var fields = this.extractionFields.slice(0);

      fields.forEach(field => {
        if (field.extractionFieldId === extractionFieldId && field.reviewDocumentCount > 0) {
          field.reviewDocumentCount--;
        }
      });

      this.merge({ extractionFields: fields });
    }

    return this.merge({ extractionFieldRecords });
  }

  efAdd(data) {
    const extractionField = this.extractionFields.find(ef => ef.extractionFieldId === data.extractionFieldId);
    if (!extractionField) {
      const extractionFields = this.extractionFields.slice(0);
      const extractionFieldGroups = this.extractionFieldGroups.slice(0);
      if (data?.groups?.length) {
        const extractionFieldGroup = {
          groupId: data.groups[0].extractionFieldGroupId,
          groupName: data.groups[0].extractionFieldGroupName,
          groupDisplayOrder: data.groups[0].groupDisplayOrder
        };
        const exists = extractionFieldGroups.find(efg => efg.groupId === extractionFieldGroup.groupId);

        if (!exists) extractionFieldGroups.push(extractionFieldGroup);
      }

      extractionFields.push(data);

      return this.merge({ extractionFields, extractionFieldGroups });
    }

    if (!extractionField.extractionFieldGroupId && data.extractionFieldGroupId) {
      extractionField.extractionFieldGroupId = data.extractionFieldGroupId;
    }

    return this;
  }

  efrAdd(data) {
    //Prevent still pending efr creations from being added to documents after switching documents
    if (!data.documentId || (data.documentId && data.documentId === this.documentId)) {
      if (data.documentExtractionFieldInstanceId) data = fixSocketFormatEFR(data);

      if (
        !this.extractionFieldRecords.some(efr => efr.extractionFieldRecordId === data.extractionFieldRecordId) &&
        this.extractionFields.some(ef => ef.extractionFieldId === data.extractionFieldId)
      ) {
        var enhanced = enhanceRecord(data, this.extractionFields);
        var extractionFieldRecords = this.extractionFieldRecords.slice(0);

        enhanced.tags = data.tags ?? [];

        extractionFieldRecords.push(enhanced);

        // Check if document is already reviewed, and if so set the reviewedDocumentCount to 1 if this is the first efr added for that field.
        var count = extractionFieldRecords.filter(efr => efr.extractionFieldId === data.extractionFieldId).length;

        if (count === 1 && this.reviewers.length > 0) {
          var fields = this.extractionFields.slice(0);

          fields.forEach(field => {
            if (field.extractionFieldId === data.extractionFieldId && field.reviewDocumentCount === 0) {
              field.reviewDocumentCount = 1;
            }
          });

          this.merge({ extractionFields: fields });
        }

        return this.merge({
          extractionFieldRecords
        });
      }
    }

    return this;
  }

  efrEdit(data) {
    var extractionFieldRecords = this.extractionFieldRecords.slice(0);

    if (data.extractionFieldRecordId) {
      var index = extractionFieldRecords.findIndex(efr => efr.extractionFieldRecordId === data.extractionFieldRecordId);

      if (index !== -1) {
        extractionFieldRecords[index].extractFieldInstanceData = data.extractFieldInstanceData;
      }
    }

    return this.merge({
      extractionFieldRecords
    });
  }

  efrRemove(extractionFieldRecordId) {
    var extractionFieldRecords = this.extractionFieldRecords.slice(0);

    extractionFieldRecords = extractionFieldRecords.filter(
      efr => efr.extractionFieldRecordId !== extractionFieldRecordId
    );

    return this.merge({
      extractionFieldRecords
    });
  }

  efrTagAdd(data) {
    var extractionFieldRecords = this.extractionFieldRecords.slice(0);

    if (data.extractionFieldRecordId && data.tag) {
      var index = extractionFieldRecords.findIndex(efr => efr.extractionFieldRecordId === data.extractionFieldRecordId);

      if (!extractionFieldRecords[index].tags.find(tag => tag.tagId === data.tag.tagId)) {
        extractionFieldRecords[index].tags = [
          ...extractionFieldRecords[index].tags,
          {
            projectId: data.tag.projectId,
            tagId: data.tag.tagId,
            tagName: data.tag.tagName,
            tagTypeId: data.tag.tagTypeId
          }
        ];
      }
    }

    return this.merge({
      extractionFieldRecords
    });
  }

  efrTagRemove(data) {
    var extractionFieldRecords = this.extractionFieldRecords.slice(0);
    var index = extractionFieldRecords.findIndex(efr => efr.extractionFieldRecordId === data.extractionFieldRecordId);

    if (index !== -1) {
      extractionFieldRecords[index].tags = extractionFieldRecords[index].tags.filter(tag => tag.tagId !== data.tagId);
    }

    return this.merge({
      extractionFieldRecords
    });
  }

  updateSections({ sections }) {
    const selectedSection = sections.find(section => section.start === this.filterById.selectedSection);
    if (selectedSection) {
      const filter = {
        extractionFieldGroupIds: selectedSection.extractionFieldGroupIds,
        selectedSection: this.filterById.selectedSection,
        isSelectedSectionClassified: selectedSection.isClassified,
        currentPage: this.filterById.currentPage,
        isPage: this.filterById.isPage,
        sectionLength: this.filterById.sectionLength
      };
      return this.merge({ sections: sections, filterById: filter });
    }

    return this.merge({ sections: sections });
  }

  sampleNumberLoaded(data) {
    return this.merge({ sections: data.sections, sectionLoading: null });
  }

  sampleNumberLoading(data) {
    return this.merge({ sectionLoading: data });
  }

  DocTypeRemove(data) {
    return this.merge({ sections: data });
  }

  setEfFilter(selectedSection) {
    const filter = {
      extractionFieldGroupIds: selectedSection.extractionFieldGroupIds,
      selectedSection: selectedSection.sectionId ? selectedSection.sectionId : selectedSection.start,
      isSelectedSectionClassified: selectedSection.isClassified,
      currentPage: selectedSection.currentPage,
      isPage: selectedSection.isPage,
      sectionLength: selectedSection.length
    };
    return this.merge({ filterById: filter });
  }
}

// Helper Functions
function enhanceRecords(data) {
  if (data.extractionFieldRecords && data.extractionFieldRecords.length > 0) {
    data.extractionFieldRecords.forEach(efr => {
      enhanceRecord(efr, data.extractionFields);
    });
  }

  return data;
}

function enhanceRecord(efr, extractionFields) {
  var matchingExtractionFeild = extractionFields.find(ef => ef.extractionFieldId === efr.extractionFieldId);

  if (matchingExtractionFeild) {
    efr.colorIndex = matchingExtractionFeild.colorIndex;
    efr.extractionFieldName = matchingExtractionFeild.extractionFieldName;
  } else {
    efr.colorIndex = 0;
    efr.extractionFieldName = '';
  }

  return efr;
}

function fixSocketFormatEFR(data) {
  return {
    extractFieldInstanceData: data.extractFieldInstanceData,
    extractionFieldId: data.extractionFieldId,
    extractionFieldRecordId: data.documentExtractionFieldInstanceId,
    location: data.location,
    pageNumber: data.pageNumber,
    tags: data.tags
  };
}
