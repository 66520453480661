import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import Header from 'containers/header/header';
import NotificationsContainer from 'containers/shared/notifications-container';
import TrainingRequestsContainer from 'containers/training-requests/training-requests-container';
import Permissions from 'permissions/permissions';
import history from 'utils/history';

class TrainingRequestsPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    if (!Permissions.Global.User.canviewAdminDashboard()) {
      history.push('/');
    }
  }

  render() {
    return (
      <div className="page">
        <Header {...this.props} fromTrainingPage currentPage="global.subheader.admin-dashboard" />
        <TrainingRequestsContainer {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default TrainingRequestsPage;
