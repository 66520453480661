import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import TemplatesList from 'components/shared/templates-list/templates-list';
import Icon from 'components/shared/icon';
import { chain } from 'underscore';
import Button from 'components/shared/single-click-button';

class ExportSidebarTemplatesList extends Component {
  static propTypes = {
    onBackClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedFieldsByTemplateId: PropTypes.object.isRequired,
    templates: PropTypes.array.isRequired
  };

  state = {
    selectedFieldsByTemplateId: chain(this.props.selectedFieldsByTemplateId)
      .mapObject(val => new Set(val))
      .value()
  };

  componentDidUpdate(prevProps) {
    if (prevProps.templates !== this.props.templates) {
      // select all extraction fields in new incoming templates
      const selectedFieldsByTemplateId = chain(this.props.templates)
        .map(template => {
          const { projectTemplateId } = template;
          const selectedExtractionFields = this.state.selectedFieldsByTemplateId[projectTemplateId];

          return [
            projectTemplateId,
            selectedExtractionFields || new Set(template.extractionFields.map(field => field.extractionFieldId))
          ];
        })
        .object()
        .value();

      this.setState({ selectedFieldsByTemplateId });
    }
  }

  setSelectedFields = selectedFieldsByTemplateId => {
    this.setState({
      selectedFieldsByTemplateId
    });
  };

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { selectedFieldsByTemplateId } = this.state;
    onSubmit(selectedFieldsByTemplateId);
  };

  render() {
    const { onBackClick, onCloseClick, templates } = this.props;
    const { selectedFieldsByTemplateId } = this.state;

    // Total number of selected extraction fields across all templates
    const numSelectedFields = Object.values(selectedFieldsByTemplateId).reduce(
      (sum, selectedExtractionFields) => sum + selectedExtractionFields.size,
      0
    );

    return (
      <div className="export-sidebar-templates-list">
        <div className="export-sidebar-templates-list__header">
          <Button size="icon" onClick={onBackClick} className="export-sidebar-templates-list__back-button icon-button">
            <Icon name="special-back" width={14} />
          </Button>
          <FormattedMessage id="export-sidebar.create.templates.title" />
          <Button size="icon" onClick={onCloseClick} className="export-sidebar-templates-list__close-button icon-button">
            <Icon name="special-cross" width={14} />
          </Button>
        </div>
        {!numSelectedFields && (
          <div className="export-sidebar-templates-list__validation-message">
            <FormattedMessage id="export-sidebar.create.templates.validation-message" />
          </div>
        )}
        <TemplatesList
          selectedFieldsByTemplateId={selectedFieldsByTemplateId}
          setSelectedFields={this.setSelectedFields}
          templates={templates}
          showTrainingProgress={true}
        />
        <div className="export-sidebar-templates-list__footer">
          <Button
            type="button"
            className="btn btn-primary btn-no-margin"
            onClick={this.onSubmit}
            disabled={!numSelectedFields}
          >
            <FormattedMessage id="common.submit" />
          </Button>
        </div>
      </div>
    );
  }
}

export default ExportSidebarTemplatesList;
