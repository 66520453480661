import model from 'models/base-model';
import dateUtil from 'utils/dateUtil';
import { isNumeric } from 'utils/number-utils';

export const COMPARISON_STATUSES = {
  INITIATED: 1,
  IN_PROGRESS: 2,
  AGGREGATING: 3,
  COMPLETED: 4,
  DELETED: 5,
  REPROCRESS: 6,
  ERROR: 7
};

export class ComparisonSets extends model({
  isLoading: false,
  error: null,
  comparisons: null,
  pagination: null
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null,
      pagination: null
    });
  }

  setError(error) {
    return this.merge({
      isLoading: false,
      error: error,
      comparisons: null,
      pagination: null
    });
  }

  setLoaded(response) {
    return ComparisonSets.fromData(response);
  }

  updateComparison(updatedComparison) {
    if (!this.comparisons) {
      return this;
    }

    const comparisons = this.comparisons.map(comparison => {
      return comparison.comparisonId === updatedComparison.comparisonId
        ? comparison.merge({
            comparisonStateId: updatedComparison.comparisonStateId,
            totalEntities: isNumeric(updatedComparison.totalEntities)
              ? updatedComparison.totalEntities
              : comparison.totalEntities
          })
        : comparison;
    });

    return this.merge({
      comparisons
    });
  }

  deleteComparison(deletedComparison) {
    if (!this.comparisons) {
      return this;
    }

    const comparisons = this.comparisons.filter(
      comparison => comparison.comparisonId !== deletedComparison.comparisonId
    );
    return this.merge({
      comparisons
    });
  }

  static fromData(response) {
    return new ComparisonSets({
      isLoading: false,
      error: null,
      pageInfo: response.pageInfo,
      comparisons: response.data.map(data => ComparisonSet.fromData(data))
    });
  }
}

export class ComparisonSet extends model({
  isLoading: false,
  error: null,
  baseEntity: null,
  baseEntityId: null,
  comparisonName: null,
  comparisonStateId: null,
  completedDate: null,
  createdBy: null,
  createdDate: null,
  comparisonDetails: null,
  comparisonId: null,
  lastUpdatedBy: null,
  lastUpdatedDate: null,
  projectId: null,
  startedDate: null,
  totalEntities: null
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }

  setError(error) {
    return this.merge({
      isLoading: false,
      error: error,
      comparisons: null
    });
  }

  setLoaded(model) {
    return ComparisonSet.fromData(model);
  }

  static fromData(model) {
    return new ComparisonSet({
      ...model,
      isLoading: false,
      error: null,
      baseDocument: model.baseDocument,
      completedDate: dateUtil.fromServerDate(model.completedDate),
      createdDate: dateUtil.fromServerDate(model.createdDate),
      lastUpdatedDate: dateUtil.fromServerDate(model.lastUpdatedDate),
      startedDate: dateUtil.fromServerDate(model.startedDate)
    });
  }
}
