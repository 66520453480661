import model from 'models/base-model';
import { sortBy } from 'underscore';
import dateUtil from 'utils/dateUtil';

export class ProjectMembers extends model({
  isLoading: false,
  isLoaded: false,
  error: null,
  members: null
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      isLoaded: false,
      error: null
    });
  }

  setError(error) {
    return this.merge({
      isLoading: false,
      isLoaded: false,
      error: error,
      members: null
    });
  }

  // Get data returned from the API
  setLoaded(members) {
    return ProjectMembers.fromData(members);
  }

  setAdded(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      members:
        state.members !== null
          ? state.members.concat(Member.fromData(action.payload))
          : Member.fromData(action.payload),
      added: Member.fromData(action.payload)
    });
  }

  setUpdated(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      error: null,
      members: state.members.map(item => {
        if (item.userId !== action.payload.userId) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          role: {
            ...item.role,
            roleDisplayName: action.payload.roleDisplayName,
            roleId: action.payload.roleId
          }
        };
      })
    });
  }

  setDeleted(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      error: null,
      members: state.members.filter(v => v.userId !== action.payload.userId)
    });
  }

  static fromData(model) {
    const data = model.map(member => Member.fromData(member));
    const creator = data.filter(v => v.role.roleId === 8);
    const other = data.filter(v => v.role.roleId !== 8);

    return new ProjectMembers({
      isLoading: false,
      error: null,
      members: creator.concat(sortBy(other, v => !v.userDetail.isPPD))
    });
  }
}

export class Member extends model({
  isLoading: false,
  error: null,
  projectId: null,
  role: null,
  userDetail: null,
  userId: null,
  lastUpdatedDate: null,
  lastUpdatedBy: null,
  createdDate: null,
  createdBy: null
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }

  setError(error) {
    return this.merge({
      isLoading: false,
      error: error
    });
  }

  // Get data returned from the API
  setLoaded(members) {
    return Member.fromData(members);
  }

  static fromData(model) {
    return new Member({
      ...model,
      isLoading: false,
      error: null,
      createdDate: dateUtil.fromServerDate(model.createdDate)
    });
  }
}
