import { connect } from 'react-redux';
import { getData, clear } from 'store/review-status/user-progress';
import { getDocumentResults } from 'store/search/search-results';
import { onSelectCategory } from 'store/search/search-category';
import UserProgress from 'components/review-status/tiles/user-progress/user-progress';

const mapStateToProps = ({ data: { userProgress } }) => ({ data: userProgress });
const mapDispatchToProps = { getData, clear, getDocumentResults, onSelectCategory };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProgress);
