/* Data clean up helper for redux models */
/* Basically takes an array or object, and determines whether it exists in the other array, and if
not, push it to the new array, to make a unique list of data */

function checkIfArrayHasValue(item, array) {
  return array.includes(item);
}

function getUniqueValues(array1, array2, key) {
  return array1.filter(v => !array2.includes(v[key]));
}

function getNewValues(original, array1, array2, filterKey) {
  let arr = original;

  array1.forEach(value => {
    if (!checkIfArrayHasValue(value[filterKey], array2)) {
      arr.push(value);
    }
  });

  return arr;
}

function updateOriginalArray(array1, array2, filterKey) {
  return array1.map(item => {
    if (
      !checkIfArrayHasValue(
        item[filterKey],
        array2.map(e => e[filterKey])
      )
    ) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      disabled: true
    };
  });
}

export function getTransformedValues(selectionArray, addedElement, filterKey, cleanFunction) {
  const data = Array.isArray(addedElement) ? addedElement : [addedElement];
  const ids = selectionArray.map(e => e[filterKey]);
  const uniqueValues = getUniqueValues(data, ids, filterKey);

  const cleanedData =
    uniqueValues.length > 0 ? uniqueValues.map(e => cleanFunction(e)) : data.map(e => cleanFunction(e));
  const newValues = getNewValues(selectionArray, cleanedData, ids, filterKey); // For original array

  return {
    original: updateOriginalArray(newValues, data, filterKey),
    addedElement: cleanedData
  };
}
