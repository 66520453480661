import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BLOCK } from './constants';
import Button from 'components/shared/single-click-button';
import TemplateDropdown from 'components/edit-extractions/template-viewer/template-dropdown/template-dropdown';
import GridDropdown from 'components/edit-extractions/template-viewer/grid-dropdown/grid-dropdown';
import ExportSidebarContainer from 'containers/extraction-overview/export-sidebar-container';
import ColumnOptions from './column-options';
import Icon from 'components/shared/icon';
import ReactTooltip from 'react-tooltip';
import SaveGridView from './save-grid-view';
import DeleteGridView from './delete-grid-view';
import * as api from 'store/api';
import Switch from 'components/shared/form/switch';

class ExtractionOverviewHeader extends Component {
  static propTypes = {
    currentFolder: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    extractionOverview: PropTypes.object.isRequired,
    projectId: PropTypes.string.isRequired,
    setTemplate: PropTypes.func.isRequired,
    updateColumnOptions: PropTypes.func.isRequired,
    customizedVisibility: PropTypes.func,
    isReadOnly:PropTypes.bool,
    isDocumentToggleSwitchOnOff:PropTypes.bool,
    changeDocumentToggle:PropTypes.func
  };

  state = {
    customGrid: false,
    isExportSidebarShown: false,
    gridDefaultSelection: true,
    visibility: this.props.extractionOverview.columnOptions?.visibility,
    showSavedGridView: false,
    deleteGridView:false,
    extractionFieldObj: {},
    selectedGridId: 0,
    selectedGridName: 'Default',
    newGridData: {},
    customizedVisibility: {},
    isColumnOptionsLoaded: false,
    displaydeleteicon:false,
    displaySaveIcon:false,
    closeDeleteGridPopup:false,
    applyDeleteGridPopup:false,
    isGridChanged:false,
  };

 componentDidMount(){
  this.setState({displaydeleteicon:false})
 }

  onTemplateSelect = value => {
    const { currentProject, setTemplate, extractionOverview } = this.props;
    sessionStorage.setItem('selected-template', value.projectTemplateId);
    sessionStorage.setItem('selected-template-name', value.name);
    sessionStorage.setItem('selected-template-projectId', currentProject.projectId);
    this.setState({ displaySaveIcon: false });
    if (extractionOverview.selectedTemplate.projectTemplateId !== value.projectTemplateId) {
      setTemplate({ projectId: currentProject.projectId, template: value });
    }
   
    this.onDefaultSelection();
    //  window.location.reload(false);
  };

  columnOptionSelected = () => {
    this.setState({ customGrid: true });
    this.setState({displaydeleteicon:false})
  };

  columnOptionDeSelected = () => {
    this.setState({ customGrid: false });
    this.setState({ gridDefaultSelection: false });
  };

  renderExportPanel = () => {
    this.setState({ isExportSidebarShown: true });
  };
  closeExportSidebar = () => {
    this.setState({ isExportSidebarShown: false });
  };
  onDefaultSelection = () => {
    this.setState({ isColumnOptionsLoaded: false });
    const { extractionOverview } = this.props;
    let visibility = extractionOverview.columnOptions?.visibility;
    let visibilityObj = {};
    for (var key in visibility) {
      visibilityObj += JSON.stringify({ ...visibility, [visibility.key]: true });
    }
    window.location.reload(false);
    this.setState({ gridDefaultSelection: true });
  };
  updateGridName= (gridName) => {
 
   // this.setState({selectedGridId: gridIdName.gridId});
    this.setState({selectedGridName: gridName});
  }
  updateGridID =(gridID) => {
    this.setState({selectedGridId: gridID});
  }
  
  updateGridChange = () => {
    this.setState({isGridChanged:false},() => {      
      this.forceUpdate();
    });
  }


  saveGrid = gridName => {
    const { projectId, extractionOverview } = this.props;
    const { visibility,selectedGridId } = this.state;
    const templateId = extractionOverview.selectedTemplate.projectTemplateId;
    let fields = [];
    this.setState({ selectedGridName: gridName });
    this.setState({displaydeleteicon:true});
    for (var key in extractionOverview.columnOptions.visibility) {
 
      if (isNaN(key)) {
        fields.push({
          FieldId: key === 'DOCUMENT_NAME' ? -1 : -2,
          FieldName: key,
          FieldOrder: 0,
          Visible: extractionOverview.columnOptions.visibility[key],
          Hidden: false,
          Frozen: false
        });
      }
    }
    for (let r = 0; r < extractionOverview.templateExtractionFields.length; r++) {
      fields.push({
        FieldId: extractionOverview.templateExtractionFields[r].extractionFieldId,
        FieldName: extractionOverview.templateExtractionFields[r].extractionFieldName,
        FieldOrder: 0,
        Visible:
          visibility[extractionOverview.templateExtractionFields[r].extractionFieldId] !== undefined
            ? visibility[extractionOverview.templateExtractionFields[r].extractionFieldId]
            : true,
        Hidden: false,
        Frozen: false
      });
    }
    let extObj = {
      extractionFieldOverviewId: selectedGridId!==0?selectedGridId:0,
      extractionFieldOverviewName: gridName,
      fields: fields
    };

    api
      .saveGridView(projectId, templateId, extObj)
      .then(res => {
        this.setState({ customGrid: false });
        this.setState({ selectedGridId: res.data.extractionFieldOverviewId });
        this.setState({ selectedGridName: res.data.extractionFieldOverviewName });
        this.setState({
          newGridData: { gridId: res.data.extractionFieldOverviewId, gridName: res.data.extractionFieldOverviewName }
        });
        this.setState({displaySaveIcon:false})
      })
      .catch(() => {});
  };

  loadColumnOptionsFromGrid = selectedGridId => {
    const { projectId, extractionOverview, customizedVisibility } = this.props;
    const templateId = extractionOverview.selectedTemplate.projectTemplateId;
    let visibilityObj = [];
    let newVisibilityObj = {};
    this.setState({displaydeleteicon:true})
    this.setState({customGrid: false });
    this.setState({ gridDefaultSelection: false });
    this.setState({ displaySaveIcon: false });
    this.setState({isGridChanged:true});
    // console.log(extractionOverview.templateExtractionFields);
    api.getSavedExtractionField(projectId, templateId, selectedGridId).then(response => {
      this.setState({ isColumnOptionsLoaded: false });
      let res = response.data.fields;
      for (let s = 0; s < res.length; s++) {
        if (res[s].fieldId === -1) {
          newVisibilityObj = { ...newVisibilityObj, DOCUMENT_NAME: res[s].visible };
        } else if (res[s].fieldId === -2) {
          newVisibilityObj = { ...newVisibilityObj, SAMPLE_NUMBER: res[s].visible };
        } else {
          newVisibilityObj = { ...newVisibilityObj, [res[s].fieldId]: res[s].visible };
        }
      }
      this.setState({ customizedVisibility: newVisibilityObj, visibility:  newVisibilityObj});
      customizedVisibility(newVisibilityObj);
    });
  };
updateCustomizedVisibility = (obj) => {
  this.setState({ customizedVisibility: obj },() => {      
    this.forceUpdate();
  });
}
  onDefaultUnSelection = () => {
    this.setState({ gridDefaultSelection: false });
  };
  openSaveViewModel = () => {
    let {customGrid,selectedGridName} =this.state;
    if(customGrid!==false) {
    this.setState({ showSavedGridView: true });
    }
    else {
      this.saveGrid(selectedGridName);
    }
  };
  closeSaveGridPopup = () => {
    this.setState({ showSavedGridView: false });
  };

  openDeleteViewModel = () => {
    this.setState({ deleteGridView: true });
    this.setState({ closeDeleteGridPopup:true });
    this.setState({ applyDeleteGridPopup:true });
  };

  closeDeleteGridPopup = () => {
    this.setState({ deleteGridView: false  });
  };

  applyDeleteGridPopup = () =>{ 
    const { projectId,extractionOverview} = this.props;
    const { selectedGridId } = this.state;
    const templateId = extractionOverview.selectedTemplate.projectTemplateId;
    api
    .deleteGridView(projectId, templateId,selectedGridId)
    .then(res => {
      this.closeDeleteGridPopup()
      this.onDefaultSelection()
    })
    .catch(() => {});  
  };

  getAllExtractionFieldsData = ef => {
    const {selectedGridId}=this.state;
    this.setState({ visibility: ef });
    this.props.customizedVisibility(ef);
   
    this.setState({displaySaveIcon:true});
    if(selectedGridId===0) {
      this.setState({ customGrid: true });
    }
    else {
      this.setState({ customGrid: false });
    }
  };
 
  render() {
    const { currentProject, extractionOverview, currentFolder, projectId, updateColumnOptions,isReadOnly, changeDocumentToggle, isDocumentToggleSwitchOnOff} = this.props;
    const {
      isExportSidebarShown,
      customGrid,
      gridDefaultSelection,
      selectedGridId,
      selectedGridName,
      newGridData,
      customizedVisibility,
      displaydeleteicon,
      displaySaveIcon,
      isGridChanged,
      visibility
    } = this.state;
    let { showSavedGridView,deleteGridView,closeDeleteGridPopup,applyDeleteGridPopup,isColumnOptionsLoaded } = this.state;
    let gridData = [{ gridId: 0, gridName: 'Default view' }];
    const documentCount = extractionOverview.templateExtractions?.length > 0 ? true : false;

    return (
      <div className={`${BLOCK}__header`}>
        <div className={`${BLOCK}__header-left`}>
          <div className={`${BLOCK}__header-title`}>
            <h1 className={`${BLOCK}__header-extraction-overview`}>
              <FormattedMessage id="extraction-overview.title" />
            </h1>
            <span className={`${BLOCK}__header-project-name`}>{currentProject.projectName}</span>
          </div>
        </div>
        <div className={`${BLOCK}__header-options`}>
          <div className={`${BLOCK}__header-document ${BLOCK}__header-document-switch`}>
            <span className={`${BLOCK}__header-toggle-label`}>
              <FormattedMessage id="extraction-overview.documentToggle"/>
            </span>
            <div className={documentCount==true ? " " : `${BLOCK}__header-toggleSwitch`}>
            <Switch
              isOn={documentCount==true && isDocumentToggleSwitchOnOff ? isDocumentToggleSwitchOnOff : false}
              onChange={changeDocumentToggle}
              disabled={!documentCount}
              id="document-toggle"
            />
            </div>
          </div>
          <div className={`${BLOCK}__header-dropdown ${BLOCK}__header-dropdown-template`}>
            <span className={`${BLOCK}__header-dropdown-label`}>
              <FormattedMessage id="extraction-overview.template" /> :
            </span>
            <TemplateDropdown
              readOnly
              templateList={extractionOverview.templates}
              selectedId={extractionOverview.selectedTemplate?.projectTemplateId}
              selectedName={extractionOverview.selectedTemplate?.name}
              onTemplateClick={value => this.onTemplateSelect(value)}
            />
          </div>
          <div className={`${BLOCK}__header-dropdown`}>
            <span className={`${BLOCK}__header-dropdown-label`}>
              <FormattedMessage id="extraction-overview.grid-view" /> :
            </span>

            <GridDropdown
              gridViewList={gridData}
              selectedId={selectedGridId}
              selectedName={selectedGridName}
              customGrid={customGrid}
              deselectGrid={this.columnOptionDeSelected}
              defaultSelection={this.onDefaultSelection}
              selectedTemplateId={extractionOverview.selectedTemplate?.projectTemplateId}
              projectId={projectId}
              newGridData={newGridData}
              loadColumnOptionsFromGrid={ID => this.loadColumnOptionsFromGrid(ID)}
              updateGridName={gridName=>this.updateGridName(gridName)}
              updateGridID={gridID=>this.updateGridID(gridID)}
            />
          </div>

           <DeleteGridView
             deleteGridView={deleteGridView}  
             closeDeleteGridPopup={this.closeDeleteGridPopup}  
             applyDeleteGridPopup={this.applyDeleteGridPopup}
             selectedId={selectedGridId}  
             TemplateId={extractionOverview.selectedTemplate?.projectTemplateId}     
            /> 
            <div className= "column-options">
            {!isReadOnly && !customGrid && displaydeleteicon ? (
              <Button
                size="icon"
                onClick={this.openDeleteViewModel}
                className="icon-button critical-icon"
                data-tip
                data-for="extraction-overview.Delete-grid-view"
              >
                <Icon width={17} name="bin" />
                <ReactTooltip id="extraction-overview.Delete-grid-view" effect="solid" place="top">
                  <FormattedMessage id="extraction-overview.Delete-grid-view" />
                </ReactTooltip>
              </Button>
            ) : null} 
          </div>
          
  
         
          <SaveGridView
            showSavedGridView={showSavedGridView}
            closeSaveGridPopup={this.closeSaveGridPopup}
            projectId={projectId}
            applySaveGridPopup={e => {
              this.saveGrid(e);
            }}
          />

          <div>
            {!isReadOnly && (customGrid || displaySaveIcon)? (
              <Button
                size="icon"
                onClick={this.openSaveViewModel}
                className="icon-button extraction-overview__save-icon"
                data-tip
                data-for="extraction-overview.save-grid-view"
              >
                <Icon width={18} name="save" />
                <ReactTooltip id="extraction-overview.save-grid-view" effect="solid" place="top">
                  <FormattedMessage id="extraction-overview.save-grid-view" />
                </ReactTooltip>
              </Button>
            ) : null}
          </div>

          <ColumnOptions
            updateColumnOptions={updateColumnOptions}
            columnOptions={extractionOverview.columnOptions}
            templateExtractionFields={extractionOverview.templateExtractionFields}
            columnOptionSelected={this.columnOptionSelected}
            defaultSelection={gridDefaultSelection}
            onDefaultUnSelection={this.onDefaultUnSelection}
            getAllExtractionFieldsData={ef => this.getAllExtractionFieldsData(ef)}
            customizedVisibility={customizedVisibility}
            isColumnOptionsLoaded={isColumnOptionsLoaded}
            isGridChanged={isGridChanged}
            updateGridChange={this.updateGridChange}
            updateCustomizedVisibility={obj=>this.updateCustomizedVisibility(obj)}
            selectedGridId={selectedGridId}
            isDocumentToggleSwitchOnOff={isDocumentToggleSwitchOnOff}
          />
          <Button
            className="btn btn-primary extraction-overview__header-button"
            onClick={() => this.renderExportPanel()}
          >
            <FormattedMessage id="extraction-overview.export" />
          </Button>
        </div>

        {isExportSidebarShown && (
          <ExportSidebarContainer
            onCloseClick={this.closeExportSidebar}
            projectId={projectId}
            currentFolder={currentFolder}
            extractionOverview={extractionOverview}
            templates={extractionOverview.templates}
            columnOptions={extractionOverview.columnOptions}
            customizedVisibility={visibility}
          />
        )}
      </div>
    );
  }
}
export default ExtractionOverviewHeader;
