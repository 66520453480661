import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/shared/modal';
import { respondArchiveRequest } from 'store/api';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';

import Permissions from 'permissions/permissions';

/*
  Approve/Reject archival header + modal
  when a PPMD navigates and sees approve button to approve archival
*/
export default class PPMDApproval extends Component {
  static propTypes = {
    history: PropTypes.object,
    projectId: PropTypes.any
  };

  state = {
    disabled: false,
    isModalOpen: false
  };

  // We can either reject or approve archive request
  // Send boolean property depending on which button user clicked on
  onReject = () => {
    const { projectId, history } = this.props;
    this.setState({ disabled: true });
    return respondArchiveRequest({ projectId, isApprove: false })
      .then(() => {
        this.setState({ isModalOpen: false });
        return history.push(`/project-list`);
      })
      .catch(() => {
        this.setState({ isModalOpen: false });
        return history.push(`/project-list`);
      });
  };

  onSubmit = () => {
    const { projectId, history } = this.props;
    this.setState({ disabled: true });
    return respondArchiveRequest({ projectId, isApprove: true })
      .then(() => {
        this.setState({ isModalOpen: false });
        return history.push(`/project-list`);
      })
      .catch(() => {
        this.setState({ isModalOpen: false });
        return history.push(`/project-list`);
      });
  };

  render() {
    const { isModalOpen, disabled } = this.state;
    return Permissions.Project._States.isReadOnly() &&
      (Permissions.Project.canRejectArchive() || Permissions.Project.canApproveArchive()) ? (
      <div className="project-details__PPMD">
        <Button
          size="large"
          className="btn btn-primary btn-no-margin"
          onClick={() => this.setState({ isModalOpen: true })}
        >
          <FormattedMessage id="global.banner.read-only.button" />
        </Button>
        {isModalOpen ? (
          <Modal>
            <div className="modal__content modal__content--small">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>
                  <FormattedMessage id="project-details.archive.title" />
                </h3>
                <Button className="icon-button" onClick={() => this.setState({ isModalOpen: false })}>
                  <Icon name="special-cross-black" width={14} />
                </Button>
              </div>
              <p className="modal__body">
                <FormattedMessage id="project-details.archive.body" />
              </p>
              <div className="modal__footer">
                {Permissions.Project.canRejectArchive() ? (
                  <Button className="btn btn-large btn-secondary" disabled={disabled} onClick={this.onReject}>
                    <FormattedMessage id="project-details.archive.reject" />
                  </Button>
                ) : null}
                {Permissions.Project.canApproveArchive() ? (
                  <Button className="btn btn-large btn-primary" disabled={disabled} onClick={this.onSubmit}>
                    <FormattedMessage id="project-details.archive.approve" />
                  </Button>
                ) : null}
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    ) : null;
  }
}
