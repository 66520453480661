import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { ProjectTemplates } from 'models/project-templates';

export const ACTIONS = {
  // Extractions
  ENABLE_EXTRACTIONS: 'argus/ui/ENABLE_EXTRACTIONS',
  GROUPS_LOADING: 'argus/ui/GROUPS_LOADING',
  GROUPS_LOADED: 'argus/ui/GROUPS_LOADED',
  GROUPS_EXTRACTIONS_LOADING: 'argus/ui/GROUPS_EXTRACTIONS_LOADING',
  GROUPS_EXTRACTIONS_LOADED: 'argus/ui/GROUPS_EXTRACTIONS_LOADED',
  ALL_EXTRACTIONS_LOADING: 'argus/ui/ALL_EXTRACTIONS_LOADING',
  ALL_EXTRACTIONS_LOADED: 'argus/ui/ALL_EXTRACTIONS_LOADED',
  TEMPLATE_EXTRACTIONS_LOADING: 'argus/ui/TEMPLATE_EXTRACTIONS_LOADING',
  TEMPLATE_EXTRACTIONS_LOADED: 'argus/ui/TEMPLATE_EXTRACTIONS_LOADED',
  MOVE_EXTRACTION: 'argus/ui/MOVE_EXTRACTION',
  ADD_EXTRACTION: 'argus/ui/ADD_EXTRACTION',
  DELETE_EXTRACTION: 'argus/ui/DELETE_EXTRACTION',
  SELECT_EXTRACTION: 'argus/ui/SELECT_EXTRACTION',
  // Data fetching
  PROJECT_TEMPLATES_LOADING: 'argus/ui/PROJECT_TEMPLATES_LOADING',
  PROJECT_TEMPLATES_LOADED: 'argus/ui/PROJECT_TEMPLATES_LOADED',
  // Data posting
  ADD_PROJECT_TEMPLATE: 'argus/ui/ADD_PROJECT_TEMPLATE',
  // Data update
  UPDATE_PROJECT_TEMPLATE: 'argus/ui/UPDATE_PROJECT_TEMPLATE',
  // Data select
  SELECT_PROJECT_TEMPLATE: 'argus/ui/SELECT_PROJECT_TEMPLATE',
  // User selected group
  SELECT_PROJECT_GROUP: 'argus/ui/SELECT_PROJECT_GROUP',
  // User change template name
  CHANGE_TEMPLATE_NAME: 'argus/ui/CHANGE_TEMPLATE_NAME',
  PROJECT_TEMPLATES_RESET_STATE: 'argus/ui/PROJECT_TEMPLATES_RESET_STATE'
};

export const enableExtractonsAction = createAction(ACTIONS.ENABLE_EXTRACTIONS);
export const groupsLoading = createAction(ACTIONS.GROUPS_LOADING);
export const groupsLoaded = createAction(ACTIONS.GROUPS_LOADED);
export const groupExtractionsLoading = createAction(ACTIONS.GROUPS_EXTRACTIONS_LOADING);
export const groupExtractionsLoaded = createAction(ACTIONS.GROUPS_EXTRACTIONS_LOADED);
export const allExtractionsLoading = createAction(ACTIONS.ALL_EXTRACTIONS_LOADING);
export const allExtractionsLoaded = createAction(ACTIONS.ALL_EXTRACTIONS_LOADED);
export const templateExtractionsLoading = createAction(ACTIONS.TEMPLATE_EXTRACTIONS_LOADING);
export const templateExtractionsLoaded = createAction(ACTIONS.TEMPLATE_EXTRACTIONS_LOADED);

export const moveExtractionsTemplate = createAction(ACTIONS.MOVE_EXTRACTION);
export const addExtractionsTemplate = createAction(ACTIONS.ADD_EXTRACTION);
export const deleteExtractionsTemplate = createAction(ACTIONS.DELETE_EXTRACTION);
export const selectExtractionFromTemplate = createAction(ACTIONS.SELECT_EXTRACTION);

export const projectTemplatesLoading = createAction(ACTIONS.PROJECT_TEMPLATES_LOADING);
export const projectTemplatesLoaded = createAction(ACTIONS.PROJECT_TEMPLATES_LOADED);

export const addProjectTemplate = createAction(ACTIONS.ADD_PROJECT_TEMPLATE);

export const modifyProjectTemplate = createAction(ACTIONS.UPDATE_PROJECT_TEMPLATE);

export const selProjectTemplate = createAction(ACTIONS.SELECT_PROJECT_TEMPLATE);

export const selectProjectGroup = createAction(ACTIONS.SELECT_PROJECT_GROUP);

export const modifyTemplateName = createAction(ACTIONS.CHANGE_TEMPLATE_NAME);

export const resetState = createAction(ACTIONS.PROJECT_TEMPLATES_RESET_STATE);

/* EXTRACTIONS */
export const enableExtractons = () => dispatch => {
  return dispatch(enableExtractonsAction());
};

export const loadGroups = () => dispatch => {
  dispatch(groupsLoading());
  return api.getProjectCreationExtractionGroups().then(res => {
    dispatch(groupsLoaded(res.data));
  });
};

export const loadGroupExtraction = (groupId, templateTypeId = null) => dispatch => {
  dispatch(groupExtractionsLoading());
  if (groupId !== 'Other') {
    return api.getProjectCreationExtractionForGroup(groupId).then(res => {
      const response = templateTypeId
        ? res.data
            .filter(ef => ef.templateTypeIdDisplayOrders.filter(tt => tt.templateTypeId === templateTypeId).length)
            .sort((ef1, ef2) => {
              const templateTypeDisplayOrder1 = ef1.templateTypeIdDisplayOrders.find(
                tt => tt.templateTypeId === templateTypeId
              );
              const templateTypeDisplayOrder2 = ef2.templateTypeIdDisplayOrders.find(
                tt => tt.templateTypeId === templateTypeId
              );

              return (
                templateTypeDisplayOrder1.templateExtractionFieldDisplayorder -
                templateTypeDisplayOrder2.templateExtractionFieldDisplayorder
              );
            })
        : res.data;
      dispatch(groupExtractionsLoaded(response.map(ef => ({ ...ef, extractionFieldGroupId: groupId }))));
    });
  }
  return dispatch(groupExtractionsLoaded([]));
};

export const loadAllExtraction = (projectId,templateCategoryID) => dispatch => {
  dispatch(allExtractionsLoading(templateCategoryID));
  return api.getExtractionFieldsByCategoryID(templateCategoryID).then(res => dispatch(allExtractionsLoaded(res.data)));
};

export const loadTemplateExtraction = (projectId, templateId) => dispatch => {
  dispatch(templateExtractionsLoading());
  return api
    .getProjectTemplateExtractionFields(projectId, templateId)
    .then(res => {
      return dispatch(templateExtractionsLoaded(res.data));
    })
    .catch(err => err);
};

export const moveExtraction = data => dispatch => {
  return dispatch(moveExtractionsTemplate(data));
};

export const deleteExtraction = data => dispatch => {
  return data.callAPI
    ? api.deleteExtraction(data).then(() => dispatch(deleteExtractionsTemplate(data)))
    : dispatch(deleteExtractionsTemplate(data));
};

export const addExtraction = data => dispatch => {
  return dispatch(addExtractionsTemplate(data));
};

export const selectExtraction = data => dispatch => {
  return dispatch(selectExtractionFromTemplate(data));
};

/* PROJECT TEMPLATES */
// Load project templates
export const loadProjectTemplates = data => dispatch => {
  dispatch(projectTemplatesLoading());
  return api.getTemplates(data).then(res => dispatch(projectTemplatesLoaded(res.data)));
};

// Create project template for first time
export const createProjectTemplate = data => dispatch => {
  return api.createTemplate(data).then(res => dispatch(addProjectTemplate(res.data)));
};

// Update exisiting project template
export const updateProjectTemplate = data => dispatch => {
  return api.updateTemplate(data).then(res => dispatch(modifyProjectTemplate(res.data)));
};

// User selects a project template
export const selectProjectTemplate = data => dispatch => {
  dispatch(selProjectTemplate(data));
};

// Selection of template group
export const selectGroup = data => dispatch => {
  return dispatch(selectProjectGroup(data));
};

// User changes template name
export const changeTemplateName = data => dispatch => {
  return dispatch(modifyTemplateName(data));
};

export const clearTemplatesData = () => dispatch => {
  return dispatch(resetState());
};

/*
 * Reducer
 */
export const INITIAL_STATE = new ProjectTemplates();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.ENABLE_EXTRACTIONS]: (state, action) => {
    return state.enableAllExtractons(state);
  },

  [ACTIONS.GROUPS_LOADING]: (state, action) => {
    return state.setGroupsLoading();
  },

  [ACTIONS.GROUPS_EXTRACTIONS_LOADING]: (state, action) => {
    return state.setGroupsExtLoading();
  },

  [ACTIONS.TEMPLATE_EXTRACTIONS_LOADING]: (state, action) => {
    return state.setTemplateExtLoading();
  },

  [ACTIONS.ALL_EXTRACTIONS_LOADING]: (state, action) => {
    return state.setAllExtLoading();
  },

  [ACTIONS.GROUPS_LOADED]: (state, action) => {
    return state.setGroupsLoaded(state, action);
  },

  [ACTIONS.GROUPS_EXTRACTIONS_LOADED]: (state, action) => {
    return state.setGroupsExtLoaded(state, action);
  },

  [ACTIONS.TEMPLATE_EXTRACTIONS_LOADED]: (state, action) => {
    return state.setTemplateExtLoaded(state, action);
  },

  [ACTIONS.ALL_EXTRACTIONS_LOADED]: (state, action) => {
    return state.setAllExtLoaded(state, action);
  },

  [ACTIONS.DELETE_EXTRACTION]: (state, action) => {
    return state.setDeleted(state, action);
  },

  [ACTIONS.SELECT_EXTRACTION]: (state, action) => {
    return state.toggleSelected(state, action);
  },

  [ACTIONS.MOVE_EXTRACTION]: (state, action) => {
    return state.setMoved(state, action);
  },

  [ACTIONS.ADD_EXTRACTION]: (state, action) => {
    return state.setAdded(state, action);
  },

  [ACTIONS.PROJECT_TEMPLATES_LOADING]: (state, action) => {
    return state.setTemplatesLoading();
  },

  [ACTIONS.PROJECT_TEMPLATES_LOADED]: (state, action) => {
    return state.setTemplatesLoaded(state, action);
  },

  [ACTIONS.ADD_PROJECT_TEMPLATE]: (state, action) => {
    return state.setCreated(state, action);
  },

  [ACTIONS.UPDATE_PROJECT_TEMPLATE]: (state, action) => {
    return state.setUpdated(state, action);
  },

  [ACTIONS.ADD_OR_UPDATE_PROJECT_TEMPLATE]: (state, action) => {
    return state.addOrUpdate(state, action);
  },

  [ACTIONS.SELECT_PROJECT_TEMPLATE]: (state, action) => {
    return state.setTempSelected(state, action);
  },

  [ACTIONS.NEW_PROJECT_TEMPLATE]: (state, action) => {
    return state.setNewTemplate(state, action);
  },

  [ACTIONS.SELECT_PROJECT_GROUP]: (state, action) => {
    return state.setSelectedGroup(state, action);
  },

  [ACTIONS.CHANGE_TEMPLATE_NAME]: (state, action) => {
    return state.setChangedName(state, action);
  },

  [ACTIONS.PROJECT_TEMPLATES_RESET_STATE]: (state, action) => {
    return INITIAL_STATE;
  }
});
