import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import Checkbox from 'components/shared/form/checkbox';

class AnalyticsSettings extends Component {
  static propTypes = {
    apply: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    extractions: PropTypes.array.isRequired,
    setSortAscending: PropTypes.func.isRequired,
    visibleExtractionIdsSet: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { selectedExtractionIdsSet: new Set(props.visibleExtractionIdsSet) };
  }

  getAllColumnsCheckedStatus = () => {
    const { selectedExtractionIdsSet } = this.state;
    const { extractions } = this.props;

    if (extractions?.length === selectedExtractionIdsSet?.size) {
      return 'checked';
    } else if (selectedExtractionIdsSet?.size === 0) {
      return 'unchecked';
    } else {
      return 'indeterminate';
    }
  };

  toggleExtraction = extraction => {
    const selectedExtractionIdsSet = new Set(this.state.selectedExtractionIdsSet);
    if (selectedExtractionIdsSet.has(extraction.extractionFieldId)) {
      selectedExtractionIdsSet.delete(extraction.extractionFieldId);
    } else {
      selectedExtractionIdsSet.add(extraction.extractionFieldId);
    }

    this.setState({ selectedExtractionIdsSet });
  };

  toggleAllExtractions = () => {
    if (this.getAllColumnsCheckedStatus() === 'unchecked') {
      const extractionIds = this.props.extractions?.map(e => e.extractionFieldId);
      this.setState({ selectedExtractionIdsSet: new Set(extractionIds) });
    } else {
      this.setState({ selectedExtractionIdsSet: new Set() });
    }
  };

  setSortOrder = ascending => {
    this.props.setSortAscending(ascending);
    this.props.cancel();
  };

  render() {
    const { cancel, apply, extractions } = this.props;
    const { selectedExtractionIdsSet } = this.state;

    return (
      <div className="analytics-settings">
        <div className="analytics-settings__overlay" onClick={cancel} />
        <div className="analytics-settings__content">
          <div className="analytics-settings__sort-section">
            <div className="analytics-settings__title">
              <FormattedMessage id="analytics.settings.sorting" />
            </div>
            <div className="analytics-settings__sort-option icon-button" onClick={() => this.setSortOrder(true)}>
              <Icon name="sorted-az" width={15} /> <FormattedMessage id="analytics.settings.sort-a-z" />
            </div>
            <div className="analytics-settings__sort-option icon-button" onClick={() => this.setSortOrder(false)}>
              <Icon name="sorted-za" width={15} /> <FormattedMessage id="analytics.settings.sort-z-a" />
            </div>
          </div>
          <div className="analytics-settings__extraction-section">
            <ul>
              <li className="analytics-settings__extraction-item" onClick={this.toggleAllExtractions}>
                <Checkbox
                  checked={this.getAllColumnsCheckedStatus()}
                  id="all-columns"
                  onChange={this.toggleAllExtractions}
                />
                <div className="analytics-settings__extraction-label">
                  <FormattedMessage id="analytics.settings.all-columns" />
                </div>
              </li>
              {extractions?.map(extraction => (
                <li
                  className="analytics-settings__extraction-item"
                  key={extraction.extractionFieldId}
                  onClick={() => this.toggleExtraction(extraction)}
                >
                  <Checkbox
                    checked={selectedExtractionIdsSet.has(extraction.extractionFieldId) ? 'checked' : 'unchecked'}
                    id="all-columns"
                    onChange={() => this.toggleExtraction(extraction)}
                  />
                  <div className="analytics-settings__extraction-label">{extraction.extractionFieldName}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="analytics-settings__footer-section">
            <button onClick={cancel} className="btn btn-secondary btn-xsmall">
              <FormattedMessage id="analytics.settings.cancel" />
            </button>
            <button
              onClick={() => apply(selectedExtractionIdsSet)}
              className="btn btn-primary btn-no-margin btn-xsmall"
            >
              <FormattedMessage id="analytics.settings.apply" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AnalyticsSettings;
