import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';
import ExtractionRecordTagsDropdown from 'containers/extractions/extraction-record-tags-dropdown';
import Button from 'components/shared/single-click-button';
import { FormattedMessage } from 'react-intl';
import { sortBy } from 'underscore';

import Permissions from 'permissions/permissions';

class ExtractionRecordTags extends Component {
  static propTypes = {
    clearUndoStack: PropTypes.func,
    documentId: PropTypes.number,
    extractionFieldRecordId: PropTypes.number,
    projectId: PropTypes.number,
    readOnly: PropTypes.bool,
    refreshFunction: PropTypes.func,
    removeExtractionFieldRecordTag: PropTypes.func,
    tags: PropTypes.array
  };

  state = {
    recordTags: [],
    showDropdown: false
  };

  componentDidMount() {
    this.setRecordTags();
  }

  componentDidUpdate = prevProps => {
    if (this.props.tags !== prevProps.tags) {
      this.setRecordTags();
    }
  };

  setRecordTags = () => {
    var { tags } = this.props;

    var sortedTags = [];
    if (tags) {
      sortedTags = tags.sort((a, b) => {
        return a.tagName > b.tagName ? 1 : a.tagName < b.tagName ? -1 : 0;
      });
    }

    this.setState({ recordTags: sortedTags });
  };

  toggleDropdown = () => {
    var { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown, tagText: '' });
  };

  removeTag = tag => {
    var { removeExtractionFieldRecordTag, projectId, documentId, extractionFieldRecordId } = this.props;

    removeExtractionFieldRecordTag(projectId, documentId, extractionFieldRecordId, tag);
  };

  render() {
    var { projectId, documentId, extractionFieldRecordId, refreshFunction, readOnly, clearUndoStack } = this.props;
    var { showDropdown, recordTags } = this.state;
    recordTags = sortBy(recordTags, tag => tag.tagName.toLowerCase());

    return (
      <div className="extraction-record-tags">
        <div className="wrapper">
          <div className="add-tag">
            {Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canTag() && !readOnly ? (
              <Button onClick={this.toggleDropdown}>
                <Icon name="special-plus" width={16} height={16} />
                {!recordTags || recordTags.length === 0 ? (
                  <FormattedMessage id="extraction-record-tags.add-button-text" />
                ) : null}
              </Button>
            ) : null}

            {showDropdown ? (
              <ExtractionRecordTagsDropdown
                clearUndoStack={clearUndoStack}
                projectId={projectId}
                documentId={documentId}
                extractionFieldRecordId={extractionFieldRecordId}
                closeFunction={refresh => {
                  this.setState({ showDropdown: false, tagText: '' });
                  if (refresh) refreshFunction();
                }}
              />
            ) : null}
          </div>

          {recordTags.map(tag => (
            <div className="tag" key={tag.tagId}>
              {tag.tagName}
              {Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canTag() && !readOnly ? (
                <Button className="remove" onClick={() => this.removeTag(tag)}>
                  <Icon name="special-c-cross" width={16} height={16} />
                </Button>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ExtractionRecordTags;
