import { connect } from 'react-redux';
import { getData, clear } from 'store/review-status/project-status';
import { getDocumentResults } from 'store/search/search-results';
import { onSelectCategory } from 'store/search/search-category';
import ProjectStatus from 'components/review-status/tiles/project-status/project-status';

// const mapDispatchToProps = { getDocumentResults, onSelectCategory };
const mapStateToProps = ({ data: { projectStatus } }) => ({
  projectStatus: projectStatus.data,
  data: projectStatus,
  isLoading: projectStatus.isLoading
});
const mapDispatchToProps = { getData, clear, getDocumentResults, onSelectCategory };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectStatus);
