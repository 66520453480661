import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCK, validFileExtensions, MAX_AMOUNT_OF_FILES, MAX_FILE_SIZE, ERROR_KEYS } from '../constants';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import Dropzone from 'dropzone';
import classNames from 'classnames';
import Button from 'components/shared/single-click-button';
import Modal from 'components/shared/modal';
import { addNotification } from 'store/notifications.js';
import store from 'store/store';
import ReactTooltip from 'react-tooltip';

class FileSelector extends Component {
  static propTypes = {
    addFilesToUpload: PropTypes.func.isRequired,
    filesToUpload: PropTypes.array.isRequired,
    removeFilesToUpload: PropTypes.func.isRequired,
    selectingFiles: PropTypes.bool.isRequired,
    setSelectingFiles: PropTypes.func.isRequired,
    uploadingFiles: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.dropzoneElem = null;
    this.fileInputElem = React.createRef();
    this.dragEnterElem = null;
  }

  state = {
    isDraggingFile: false,
    showConfirmationModal: false,
    invalidDragging: false,
    isFileSelectorOpen: false,
    isNotificationSend: false
  };

  componentDidMount() {
    // Initialize dropzone for file upload by drag and drop
    const dropzone = new Dropzone(this.dropzoneElem, {
      autoProcessQueue: false,
      acceptedFiles: validFileExtensions,
      clickable: false,
      previewTemplate: '<span/>', // required but unused
      uploadMultiple: true,
      url: '/files' // required but unused
    });

    dropzone.on('addedfile', file => {
      const { invalidDragging } = this.state;
      if (!invalidDragging) {
        this.checkFilesToUpload([file], false);
      }
    });

    dropzone.on('addedfiles', files => {
      const { filesToUpload } = this.props;
      let isInvalid = files.length > MAX_AMOUNT_OF_FILES || filesToUpload.length + files.length > MAX_AMOUNT_OF_FILES;
      this.setState(
        {
          invalidDragging: isInvalid
        },
        () => (isInvalid && !this.state.isNotificationSend ? this.displayNotification(ERROR_KEYS['maxAmount']) : null)
      );
    });

    dropzone.on('dragenter', event => {
      this.dragEnterElem = event.target;
      if (event.dataTransfer.types.indexOf('Files') === -1) {
        return;
      }
      this.setState({ isDraggingFile: true });
    });

    dropzone.on('dragleave', event => {
      // If the drag leave event is on the last element that was entered,
      // mark dragging as done
      if (this.dragEnterElem === event.target) {
        this.setState({ isDraggingFile: false });
      }
    });

    dropzone.on('drop', event => {
      this.setState({ isDraggingFile: false });
      this.setState({isNotificationSend: false});
    });
  }

  setDropzoneElem = elem => {
    this.dropzoneElem = elem;
  };

  displayNotification = type => {
    store.dispatch(
      addNotification({
        message: <FormattedMessage id={type} />,
        clearOnPageChange: true,
        autohide: 5
      })
    );
  };

  checkFilesToUpload = (files, fromInput = true) => {
    const { addFilesToUpload, filesToUpload } = this.props;
    const { maxFilesReached } = this.state;
    const fileExtensionValidationRegex = /\.(docx|doc|xlsx|xls|pptx|ppt|pdf|txt|xline)$/i;

    this.setState({ isFileSelectorOpen: true });

    if (files.length > MAX_AMOUNT_OF_FILES || files.length + filesToUpload.length > MAX_AMOUNT_OF_FILES) {
      if(!this.state.isNotificationSend)
      {
        this.displayNotification(ERROR_KEYS['maxAmount']);
        this.setState({isNotificationSend: true});
      }
    } else {
      const validFiles = files
        .filter(file => {
          const invalidSize = file.size > MAX_FILE_SIZE;
          const invalidFormat = !fileExtensionValidationRegex.test(file.name);

          if (fromInput) file.accepted = true;
          if (invalidSize) this.displayNotification(ERROR_KEYS['maxSize']);
          if (invalidFormat) this.displayNotification(ERROR_KEYS['fileFormat']);

          return !invalidSize && !invalidFormat;
        })
        .slice(0, MAX_AMOUNT_OF_FILES - filesToUpload.length);

      if (!maxFilesReached && filesToUpload.length < MAX_AMOUNT_OF_FILES) {
        addFilesToUpload(validFiles);
      }

      if (filesToUpload.length === MAX_AMOUNT_OF_FILES) {
        this.setState({ maxFilesReached: true });
      }
    }
    this.fileInputElem.current.value = '';
    this.setState({ isFileSelectorOpen: false });
  };

  removeSelectedFiles = () => {
    const { removeFilesToUpload, setSelectingFiles } = this.props;
    removeFilesToUpload();
    setSelectingFiles(false);
    this.setState({ showConfirmationModal: false });
  };

  renderConfirmationPopup = () => (
    <Modal>
      <div className="modal__content wide">
        <h3>
          <FormattedMessage id="translation.confirmation-modal-header" />
        </h3>
        <p>
          <FormattedMessage id="translation.confirmation-modal-body" />
        </p>
        <div className="modal__footer">
          <Button className="btn btn-secondary" onClick={() => this.setState({ showConfirmationModal: false })}>
            <FormattedMessage id="translation.confirmation-modal-cancel-button" />
          </Button>
          <Button className="btn btn-primary" onClick={this.removeSelectedFiles}>
            <FormattedMessage id="translation.confirmation-modal-delete-button" />
          </Button>
        </div>
      </div>
    </Modal>
  );

  render() {
    const { filesToUpload, selectingFiles, uploadingFiles } = this.props;
    const { isDraggingFile, showConfirmationModal, isFileSelectorOpen } = this.state;

    const block = `${BLOCK}__file-selector`;
    const maxFilesReached = filesToUpload.length >= MAX_AMOUNT_OF_FILES;

    return (
      <div className={block}>
        <div
          className={classNames(`${block}-dropzone`, isDraggingFile && `${block}-dropzone--dragging`)}
          ref={this.setDropzoneElem}
        >
          <div className={`${block}-header`}>
            <Icon className={`${block}-icon`} name="file-uploader" width={41} />
            <FormattedMessage id="translation.file-selector" />
          </div>
          <div>
            <label
              className={classNames(
                'btn btn-primary btn-no-margin',
                `${block}-dropzone-button`,
                (maxFilesReached || selectingFiles || uploadingFiles || isFileSelectorOpen) && 'disabled'
              )}
              onFocus={() => this.setState({ isFileSelectorOpen: false })}
              onClick={() => this.setState({ isFileSelectorOpen: true })}
              htmlFor="argus-translation-file-input"
              tabIndex="0"
              disabled={false}
              data-tip data-for="tooltip-select-files-button"
            >
              {!selectingFiles && !isFileSelectorOpen ? (
                <FormattedMessage id="translation.select-documents" />
              ) : (
                <Icon className="spinner spinner--centered" name="spinner" width={18} />
              )}
            </label>
            <input
              hidden
              multiple
              type="file"
              ref={this.fileInputElem}
              accept={validFileExtensions}
              onChange={event => this.checkFilesToUpload(Array.from(event.target.files))}
              id="argus-translation-file-input"
              disabled={maxFilesReached || selectingFiles || uploadingFiles}
            />
            {!selectingFiles && !isFileSelectorOpen ?
             <ReactTooltip id="tooltip-select-files-button" effect="solid" place="bottom" >
              <FormattedMessage id="extraction-translation.DCRM-warning" />
            </ReactTooltip>
            :""}
          </div>
        </div>
        {filesToUpload.length ? (
          <div className={`${block}-file-list`}>
            <div className={`${block}-file-list--left`}>
              <Icon className={`${block}-icon`} name="documents" width={20} />
              <span className="amount">
                {filesToUpload.length}
                <FormattedMessage id="translation.files-selected" />
              </span>
            </div>
            <Button
              size="icon"
              className={`${block}-icon-button critical-icon`}
              onClick={() => this.setState({ showConfirmationModal: true })}
            >
              <Icon name="bin" width={24} />
            </Button>
          </div>
        ) : null}
        {showConfirmationModal && this.renderConfirmationPopup()}
      </div>
    );
  }
}

export default FileSelector;
