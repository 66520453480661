import React from 'react';
import PropTypes from 'prop-types';
import ColorHelper from 'components/extractions/helpers/colorHelper';

import { throttle } from 'underscore';

class Minimap extends React.Component {
  static propTypes = {
    scrollArea: PropTypes.any,
    extractionFieldRecords: PropTypes.any,
    layout: PropTypes.any,
    characters: PropTypes.any,
    zoomLevel: PropTypes.any
  };

  state = {
    scrollTop: 0,
    scrollHeight: 0,
    offsetHeight: 0,
    markers: [],
    documentHeight: 0
  };

  minimap = null;
  mouseDown = false;

  componentDidMount() {
    const { scrollArea, extractionFieldRecords, layout, characters } = this.props;

    if (scrollArea && scrollArea !== null && extractionFieldRecords && layout && characters) {
      this.initialize();
    }

    window.addEventListener('mouseup', this.mouseUp, false);
    window.addEventListener('mousemove', this.mouseMove, false);
  }

  componentDidUpdate(prevProps) {
    const { scrollArea, extractionFieldRecords, layout, characters, zoomLevel } = this.props;

    if (
      scrollArea &&
      scrollArea !== null &&
      extractionFieldRecords &&
      layout &&
      characters &&
      (prevProps.scrollArea !== scrollArea ||
        prevProps.extractionFieldRecords !== extractionFieldRecords ||
        prevProps.layout !== layout ||
        prevProps.characters !== characters ||
        prevProps.zoomLevel !== zoomLevel)
    ) {
      this.initialize();
    }
  }

  initialize() {
    const { scrollArea } = this.props;

    scrollArea.addEventListener('scroll', this.scrollChange);

    var markers = this.setupMarkers();

    this.setState({
      scrollHeight: scrollArea.scrollHeight,
      offsetHeight: scrollArea.offsetHeight,
      markers: markers
    });
  }

  setupMarkers = () => {
    const { extractionFieldRecords, layout, characters, zoomLevel } = this.props;

    var markers = [];
    var pageMargins = 30;
    var bottomMargin = 50;
    var pageOffsets = [];
    var totalHeight = 0;

    layout.forEach(page => {
      var SCALE_ALTERATION = 0.5 * (150 / page.dpiX);
      var alteredMargin = pageMargins / SCALE_ALTERATION / zoomLevel;
      var start = totalHeight + alteredMargin;
      var end = start + page.height + alteredMargin;

      pageOffsets.push({
        start,
        end
      });

      totalHeight = end;
      if (pageOffsets.length === layout.length) totalHeight += bottomMargin / SCALE_ALTERATION / zoomLevel;
    });

    if (characters.length > 0) {
      extractionFieldRecords.forEach(record => {
        if (
          record.indicatorPosition &&
          record.indicatorPosition !== null &&
          record.indicatorPosition.start.page > 0 &&
          record.indicatorPosition.end.page > 0
        ) {
          var start =
            record.indicatorPosition.start.offset + pageOffsets[record.indicatorPosition.start.page - 1].start;
          var end = record.indicatorPosition.end.offset + pageOffsets[record.indicatorPosition.end.page - 1].start;

          var top = start <= end ? start : end;
          var bottom = start <= end ? end : start;
          var height = bottom - top;

          markers.push({
            id: record.extractionFieldRecordId,
            top,
            height,
            color: ColorHelper.highlight_colors[record.colorIndex]
          });
        } else {
          var first_char = characters.find(character => character.i === record.location[0]);
          var last_char = characters.find(character => character.i === record.location[1]);

          if (first_char && last_char) {
            var _start = first_char.y1 + pageOffsets[first_char.page - 1].start;
            var _end = last_char.y2 + pageOffsets[last_char.page - 1].start;

            var _top = _start <= _end ? _start : _end;
            var _bottom = _start <= _end ? _end : _start;
            var _height = _bottom - _top;

            markers.push({
              id: record.extractionFieldRecordId,
              _top,
              _height,
              color: ColorHelper.highlight_colors[record.colorIndex]
            });
          }
        }
      });
    }

    this.setState({ documentHeight: totalHeight });

    return markers;
  };

  scrollChange = () => {
    this.setState({ scrollTop: this.props.scrollArea.scrollTop });
  };

  getY = event => {
    return event.clientY - this.minimap.getBoundingClientRect().top;
  };

  scrollPage = throttle(y => {
    if (this.mouseDown) {
      var scroll = y * (this.state.scrollHeight / this.state.offsetHeight);
      var height = this.state.offsetHeight;
      this.props.scrollArea.scrollTop = scroll - height / 2;
    }
  }, 50);

  mouseUp = () => {
    this.mouseDown = false;
  };

  mouseMove = event => {
    if (this.mouseDown) {
      var y = this.getY(event);
      this.scrollPage(y);
    }
  };

  render() {
    const { scrollTop, scrollHeight, offsetHeight, markers, documentHeight } = this.state;

    var scrollIndicatorStyle = {};

    if (scrollTop !== undefined && scrollHeight !== undefined && offsetHeight !== undefined) {
      var scale = offsetHeight / scrollHeight;
      var t = scrollTop * scale;
      var h = offsetHeight * scale;

      scrollIndicatorStyle = {
        top: t + 'px',
        height: h + 'px'
      };
    }

    return (
      <div
        className="minimap"
        ref={element => (this.minimap = element)}
        onMouseDown={event => {
          this.mouseDown = true;
          var y = this.getY(event);
          this.scrollPage(y);
        }}
      >
        {offsetHeight !== scrollHeight && <div className="scroll-indicator" style={scrollIndicatorStyle} />}
        {markers
          ? markers.map(marker => {
              if (!marker.top || !marker.height) {
                return null;
              }

              var markerScale = offsetHeight / documentHeight;
              var extractionIndicatorStyle = {
                top: Math.round(marker.top * markerScale),
                height: Math.ceil(marker.height * markerScale),
                backgroundColor: marker.color
              };

              return (
                <div
                  id={`marker-${marker.id}`}
                  key={marker.id}
                  className="extraction-indicator"
                  style={extractionIndicatorStyle}
                />
              );
            })
          : null}
      </div>
    );
  }
}
export default Minimap;
