import { createAction, createReducer } from 'utils/redux-utils';
import { throttle } from 'underscore';

import { ACTIONS as PROJECT_ACTIONS } from 'store/current-project';
import { ProjectItem } from 'models/project-item';
import * as api from 'store/api';
import socket, { rooms } from 'utils/socket';

export const ACTIONS = {
  CURRENT_FOLDER_INVALIDATED: 'argus/ui/CURRENT_FOLDER_INVALIDATED',
  CURRENT_FOLDER_LOADED: 'argus/ui/CURRENT_FOLDER_LOADED',
  CURRENT_FOLDER_LOADING: 'argus/ui/CURRENT_FOLDER_LOADING',
  ITEM_NAME_UPDATED: 'argus/ui/ITEM_NAME_UPDATED',
  ITEMS_DELETED: 'argus/ui/ITEMS_DELETED',
  ITEMS_UPDATED: 'argus/ui/ITEMS_UPDATED',
  TAG_ADDED: 'argus/ui/TAG_ADDED',
  TAG_REMOVED: 'argus/ui/TAG_REMOVED',
  REVIEWER_ADDED: 'argus/ui/REVIEWER_ADDED',
  REVIEWER_REMOVED: 'argus/ui/REVIEWER_REMOVED'
};

export const currentFolderInvalidated = createAction(ACTIONS.CURRENT_FOLDER_INVALIDATED);
export const currentFolderLoaded = createAction(ACTIONS.CURRENT_FOLDER_LOADED);
export const currentFolderLoading = createAction(ACTIONS.CURRENT_FOLDER_LOADING);
export const itemNameUpdated = createAction(ACTIONS.ITEM_NAME_UPDATED);
export const itemsDeleted = createAction(ACTIONS.ITEMS_DELETED);
export const itemsUpdated = createAction(ACTIONS.ITEMS_UPDATED);
export const tagAdded = createAction(ACTIONS.TAG_ADDED);
export const tagRemoved = createAction(ACTIONS.TAG_REMOVED);
export const reviewerAdded = createAction(ACTIONS.REVIEWER_ADDED);
export const reviewerRemoved = createAction(ACTIONS.REVIEWER_REMOVED);

// Closure for abstracting the logic of subscribing/unsubscribing to updates from the socket
const { subscribe, unsubscribe } = (socket => {
  let projectRoom = null;
  let folderRoom = null;
  let onDelete = null;
  let onUpdateState = null;
  let onDocumentRenamed = null;
  let onFolderRenamed = null;
  let onUpdatePageCount = null;
  let onDocumentTagAdded = null;
  let onDocumentTagRemoved = null;
  let onDocumentReviewerAdded = null;
  let onDocumentReviewerRemoved = null;
  let onDocumentTemplateUpdated = null;

  const subscribe = (projectId, folderId) => dispatch => {
    if (!projectId || !folderId) {
      return;
    }

    const nextProjectRoom = rooms.project(projectId);
    const nextFolderRoom = rooms.folder(projectId, folderId);

    if (projectRoom !== nextProjectRoom) {
      socket.leave(projectRoom);
      projectRoom = nextProjectRoom;
      socket.join(projectRoom);
    }

    if (folderRoom !== nextFolderRoom) {
      socket.leave(folderRoom);
      folderRoom = nextFolderRoom;
      socket.join(folderRoom);
    }

    if (!onDelete) {
      onDelete = response => {
        dispatch(itemsDeleted([response.data]));
        dispatch(currentFolderInvalidate());
      };
      socket.on('document.delete', onDelete);
      socket.on('folder.delete', onDelete);
    }

    if (!onUpdateState) {
      onUpdateState = response => {
        dispatch(itemsUpdated([{ ...response.data }]));
      };

      socket.on('document.update.state', onUpdateState);
    }

    if (!onDocumentRenamed) {
      onDocumentRenamed = response => {
        dispatch(itemsUpdated([{ ...response.data }]));
      };
      socket.on('document.renamed', onDocumentRenamed);
    }

    if (!onFolderRenamed) {
      onFolderRenamed = response => {
        dispatch(itemsUpdated([{ ...response.data }]));
      };
      socket.on('folder.renamed', onFolderRenamed);
    }

    if (!onUpdatePageCount) {
      onUpdatePageCount = response => {
        dispatch(itemsUpdated([{ ...response.data }]));
      };
      socket.on('document.page.count.updated', onUpdatePageCount);
    }

    if (!onDocumentTagAdded) {
      onDocumentTagAdded = response => {
        dispatch(tagAdded(response.data));
      };
      socket.on('document.tag.added', onDocumentTagAdded);
    }

    if (!onDocumentTagRemoved) {
      onDocumentTagRemoved = response => {
        dispatch(tagRemoved(response.data));
      };
      socket.on('document.tag.removed', onDocumentTagRemoved);
    }

    if (!onDocumentReviewerAdded) {
      onDocumentReviewerAdded = response => {
        dispatch(reviewerAdded(response.data));
      };
      socket.on('document.reviewer.added', onDocumentReviewerAdded);
    }

    if (!onDocumentReviewerRemoved) {
      onDocumentReviewerRemoved = response => {
        dispatch(reviewerRemoved(response.data));
      };
      socket.on('document.reviewer.removed', onDocumentReviewerRemoved);
    }

    if (!onDocumentTemplateUpdated) {
      onDocumentTemplateUpdated = response => {
        dispatch(
          itemsUpdated([
            {
              projectItemId: response.data.document.projectItemId,
              projectTemplates: response.data.templates
            }
          ])
        );
      };
      socket.on('document.template.updated', onDocumentTemplateUpdated);
    }
  };

  const unsubscribe = () => {
    socket.leave(projectRoom);
    socket.leave(folderRoom);
    projectRoom = null;
    folderRoom = null;

    if (onDelete) {
      socket.off('document.delete', onDelete);
      socket.off('folder.delete', onDelete);
      onDelete = null;
    }

    if (onUpdateState) {
      socket.off('document.update.state', onUpdateState);
      onUpdateState = null;
    }

    if (onUpdatePageCount) {
      socket.off('document.page.count.updated', onUpdatePageCount);
      onUpdatePageCount = null;
    }

    if (onDocumentRenamed) {
      socket.off('document.renamed', onDocumentRenamed);
      onDocumentRenamed = null;
    }

    if (onFolderRenamed) {
      socket.off('folder.renamed', onFolderRenamed);
      onFolderRenamed = null;
    }

    if (onDocumentTagAdded) {
      socket.off('document.tag.added', onDocumentTagAdded);
      onDocumentTagAdded = null;
    }

    if (onDocumentTagRemoved) {
      socket.off('document.tag.removed', onDocumentTagRemoved);
      onDocumentTagRemoved = null;
    }

    if (onDocumentReviewerAdded) {
      socket.off('document.reviewer.added', onDocumentReviewerAdded);
      onDocumentReviewerAdded = null;
    }

    if (onDocumentReviewerAdded) {
      socket.off('document.reviewer.removed', onDocumentReviewerAdded);
      onDocumentReviewerAdded = null;
    }

    if (onDocumentTemplateUpdated) {
      socket.off('document.template.changed', onDocumentTemplateUpdated);
      onDocumentTemplateUpdated = null;
    }
  };

  return {
    subscribe,
    unsubscribe
  };
})(socket);

export const loadCurrentFolder = ({
  projectId,
  folderId,
  pageNum = 1,
  pageSize,
  forceFetch = false,
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = '',
  filterIds = ''
}) => (dispatch, getState) => {
  const currentState = getState().data.currentFolder;
  if (forceFetch || (!currentState.isLoading && !currentState.isLoaded)) {
    dispatch(currentFolderLoading());
    return api
      .getFolder({ projectId, folderId, pageNum, pageSize, sortBy, sortOrder, filterBy, filterString, filterIds })
      .then(response => {
      localStorage.setItem("templateCategoryId",response.data.templateCategoryID)
        // Subscribe to updates on this folder
        dispatch(subscribe(projectId, folderId));
        // Update the state
        dispatch(currentFolderLoaded(response));
      });
  }

  return Promise.resolve(currentState);
};

export const createFolder = ({ folderName, projectId, parentId }) => dispatch => {
  return api.createFolder({ folderName, projectId, parentId }).then(() => {
    dispatch(currentFolderInvalidated());
  });
};

export const renameItem = ({ projectId, newName, oldName, itemTypeId, projectItemId }) => (dispatch, getState) => {
  // Set the name of the item immediately
  dispatch(itemNameUpdated({ projectItemId, itemName: newName }));
  return api.renameItem({ projectId, itemName: newName, itemTypeId, projectItemId }).catch(error => {
    dispatch(itemNameUpdated({ projectItemId, itemName: oldName }));
    throw error;
  });
};

export const moveItems = ({ projectId, folderId, itemIds,projectItemsIdList,templateId }) => dispatch => {
  return api.moveItems({ projectId, folderId, itemIds,projectItemsIdList,templateId });
};

export const deleteItems = ({ projectId, items }) => dispatch => {
  // Separate folders and documents since they rely on different API calls
  // Todo: discuss to combine them into a single API
  const folders = items.filter(child => child.itemTypeId === 1);
  const documents = items.filter(child => child.itemTypeId === 2);

  const promises = [];

  if (documents.length) {
    promises.push(api.deleteAllItems({ projectId, ids: documents.map(e => e.projectItemId) }));
  }

  if (folders.length) {
    promises.push(api.deleteItems({ projectId, items: folders }));
  }

  return Promise.all(promises);
};

export const assignTemplates = ({ projectId, items, templateId }) => dispatch => {
  return api.assignTemplates({ projectId, templateId, items });
};

const throttledFolderInvalidate = throttle(dispatch => {
  // Invalidate the data
  dispatch(currentFolderInvalidated());
}, 10000);

export const currentFolderInvalidate = () => throttledFolderInvalidate;

export const unsubscribeCurrentFolder = () => dispatch => {
  unsubscribe();
};

/*
 * Reducer
 */
export const INITIAL_STATE = new ProjectItem();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.CURRENT_FOLDER_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.CURRENT_FOLDER_LOADED]: (state, action) => {
    return state.setLoaded(action.payload);
  },

  [ACTIONS.CURRENT_FOLDER_INVALIDATED]: (state, action) => {
    return state.invalidate();
  },

  [ACTIONS.ITEM_NAME_UPDATED]: (state, action) => {
    return state.setChildName(action.payload);
  },

  [ACTIONS.ITEMS_DELETED]: (state, action) => {
    if (action.payload.find(item => item.projectItemId === state.projectItemId)) {
      return state.invalidate();
    } else {
      return state.deleteChildren(action.payload);
    }
  },

  [ACTIONS.ITEMS_UPDATED]: (state, action) => {
    return state.updateChildren(action.payload);
  },

  [ACTIONS.TAG_ADDED]: (state, action) => {
    return state.addChildTag(action.payload.documentId, action.payload.tag);
  },

  [ACTIONS.TAG_REMOVED]: (state, action) => {
    return state.removeChildTag(action.payload.documentId, action.payload.tagId);
  },

  [ACTIONS.TAGS_UPDATED]: (state, action) => {
    return state.invalidate();
  },

  [ACTIONS.REVIEWER_ADDED]: (state, action) => {
    return state.addChildReviewer(action.payload.projectId, action.payload.documentId, action.payload.reviewer);
  },

  [ACTIONS.REVIEWER_REMOVED]: (state, action) => {
    return state.removeChildReviewer(action.payload.documentId, action.payload.reviewerId);
  },

  [PROJECT_ACTIONS.CLEAR_PROJECT_DATA]: (state, action) => {
    return INITIAL_STATE;
  }
});
