import { createAction, createReducer } from 'utils/redux-utils';

import { ACTIONS as CURRENT_FOLDER_ACTIONS } from 'store/current-folder';
import { ACTIONS as SEARCH_FOLDER_ACTIONS } from 'store/search/search-results';

export const ACTIONS = {
  FILE_BROWSER_SELECT_ITEM: 'argus/ui/FILE_BROWSER_SELECT_ITEM',
  FILE_BROWSER_DESELECT_ITEM: 'argus/ui/FILE_BROWSER_DESELECT_ITEM',
  FILE_BROWSER_SELECT_ALL_ITEMS: 'argus/ui/FILE_BROWSER_SELECT_ALL_ITEMS',
  FILE_BROWSER_DESELECT_ALL_ITEMS: 'argus/ui/FILE_BROWSER_DESELECT_ALL_ITEMS',
  FILE_BROWSER_MOUSE_DOWN_ITEM: 'argus/ui/FILE_BROWSER_MOUSE_DOWN_ITEM'
};

export const fileBrowserSelectItem = createAction(ACTIONS.FILE_BROWSER_SELECT_ITEM);
export const fileBrowserDeselectItem = createAction(ACTIONS.FILE_BROWSER_DESELECT_ITEM);
export const fileBrowserSelectAllItems = createAction(ACTIONS.FILE_BROWSER_SELECT_ALL_ITEMS);
export const fileBrowserDeselectAllItems = createAction(ACTIONS.FILE_BROWSER_DESELECT_ALL_ITEMS);
export const fileBrowserMouseDownItem = createAction(ACTIONS.FILE_BROWSER_MOUSE_DOWN_ITEM);

/*
 * Reducer
 */
export const INITIAL_STATE = {};

export default createReducer(INITIAL_STATE, {
  [CURRENT_FOLDER_ACTIONS.CURRENT_FOLDER_LOADED]: (state, action) => {
    return action.payload.data.children.reduce((selectedItems, item) => {
      selectedItems[item.projectItemId] = state[item.projectItemId] || false;
      return selectedItems;
    }, {});
  },

  // When an item is deleted it must be removed from the set of selected items
  [CURRENT_FOLDER_ACTIONS.ITEMS_DELETED]: (state, action) => {
    const updatedState = {
      ...state
    };

    const getId = deletedItem => (deletedItem.projectItemId ? deletedItem.projectItemId : deletedItem.folderId);

    action.payload.forEach(deletedItem => {
      delete updatedState[getId(deletedItem)];
    });

    return updatedState;
  },

  [SEARCH_FOLDER_ACTIONS.SHALLOW_RESULTS_DOCUMENTS_LOADED]: (state, action) => {
    return action.payload.data.reduce((selectedItems, item) => {
      selectedItems[item.projectItemId] = state[item.projectItemId] || false;
      return selectedItems;
    }, {});
  },

  [SEARCH_FOLDER_ACTIONS.SHALLOW_RESULTS_FOLDERS_LOADED]: (state, action) => {
    return action.payload.data.reduce((selectedItems, item) => {
      selectedItems[item.projectItemId] = state[item.projectItemId] || false;
      return selectedItems;
    }, {});
  },

  [SEARCH_FOLDER_ACTIONS.SHALLOW_RESULTS_CURRENT_FOLDER_LOADED]: (state, action) => {
    return action.payload.data.children.reduce((selectedItems, item) => {
      selectedItems[item.projectItemId] = state[item.projectItemId] || false;
      return selectedItems;
    }, {});
  },

  [SEARCH_FOLDER_ACTIONS.ADVANCED_RESULTS_LOADED]: (state, action) => {
    return action.payload.data.reduce((selectedItems, item) => {
      selectedItems[item.projectItemId] = state[item.projectItemId] || false;
      return selectedItems;
    }, {});
  },

  // Logic for mousedown of a row of the file browser
  // If the row is already selected do nothing
  // If the row is not yet selected, it becomes the only row selected
  [ACTIONS.FILE_BROWSER_MOUSE_DOWN_ITEM]: (state, action) => {
    if (state[action.payload]) {
      return state;
    }

    const updatedState = Object.keys(state).reduce((selectedItems, id) => {
      selectedItems[id] = false;
      return selectedItems;
    }, {});

    updatedState[action.payload] = true;

    return updatedState;
  },

  [ACTIONS.FILE_BROWSER_SELECT_ITEM]: (state, action) => {
    return {
      ...state,
      [action.payload]: true
    };
  },

  [ACTIONS.FILE_BROWSER_DESELECT_ITEM]: (state, action) => {
    return {
      ...state,
      [action.payload]: false
    };
  },

  [ACTIONS.FILE_BROWSER_SELECT_ALL_ITEMS]: (state, action) => {
    return Object.keys(action.payload ? action.payload : state).reduce((selectedItems, id) => {
      selectedItems[id] = true;
      return selectedItems;
    }, {});
  },

  [ACTIONS.FILE_BROWSER_DESELECT_ALL_ITEMS]: (state, action) => {
    return Object.keys(state).reduce((selectedItems, id) => {
      selectedItems[id] = false;
      return selectedItems;
    }, {});
  }
});
