import { connect } from 'react-redux';

import {
  searchAll,
  onSearchChange,
  onAdvancedSearchChange,
  clearSearchData,
  onSelectCategory
} from 'store/search/search-category';
import {
  clearResultsData,
  getCurrentSearchFolder,
  getDocumentResults,
  getFolderResults,
  getAdvancedSearchResults
} from 'store/search/search-results';
import FileListSearch from 'components/search/file-list-search/file-list-search';

const mapStateToProps = ({ data: { search, currentSearchFolder, currentProject }, router }) => ({
  search,
  currentSearchFolder,
  currentProject,
  router
});

const mapDispatchToProps = {
  searchAll,
  onSearchChange,
  onAdvancedSearchChange,
  clearSearchData,
  clearResultsData,
  getCurrentSearchFolder,
  getDocumentResults,
  getFolderResults,
  getAdvancedSearchResults,
  onSelectCategory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileListSearch);
