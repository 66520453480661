import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Dropdown from 'components/shared/form/dropdown';
import moment from 'moment';

class MonthDayPicker extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    intl: intlShape.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any
  };

  state = {
    translatedList: moment.months().reduce(
      (acc, cur) => {
        acc.push(this.props.intl.formatMessage({ id: `fiscal-year-End.months_${cur}` }));
        return acc;
      },
      ['-']
    )
  };

  setMonth = month => {
    const { onChange, value } = this.props;
    const { translatedList } = this.state;

    if (month === '-') return onChange(null);

    var currentMoment = value ? moment(value) : moment();
    let monthIndex = translatedList.indexOf(month);
    return onChange(currentMoment.month(monthIndex - 1).format('YYYY-MM-DDT00:00:01'));
  };

  setDay = day => {
    const { onChange, value } = this.props;

    if (day === '-') return onChange(null);

    var currentMoment = value ? moment(value) : moment();

    return onChange(currentMoment.date(day).format('YYYY-MM-DDT00:00:01'));
  };

  render() {
    const { value } = this.props;
    const { translatedList } = this.state;

    const currentMoment = value ? moment(value) : moment();
    const currentMonth = currentMoment.month();
    const currentDay = currentMoment.date();
    const selectedMonth = translatedList[currentMonth + 1];

    const days = ['-'].concat(
      Array(currentMoment.daysInMonth())
        .fill(0)
        .map((e, i) => i + 1)
    );

    return (
      <React.Fragment>
        <Dropdown
          className="month-day-picker__month"
          options={translatedList}
          renderOption={option => option}
          selectedOption={value ? selectedMonth : '-'}
          onSelection={this.setMonth}
        />
        <Dropdown
          className="month-day-picker__day"
          options={days}
          renderOption={option => option}
          selectedOption={value && days.includes(currentDay) ? currentDay : value ? 1 : '-'}
          onSelection={this.setDay}
        />
      </React.Fragment>
    );
  }
}

export default injectIntl(MonthDayPicker);
