import { connect } from 'react-redux';

import { loadActiveComparisonSet } from 'store/active-comparison-set';
import { loadComparisonSets } from 'store/comparison-sets';
import Compare from 'components/compare/compare';

const mapStateToProps = ({
  data: { search, activeComparisonSet, comparisonSets, currentProject },
  ui: { compareType }
}) => ({
  search,
  activeComparisonSet,
  comparisonSets,
  currentProject,
  compareType
});

const mapDispatchToProps = {
  loadActiveComparisonSet,
  loadComparisonSets
};

export default connect(mapStateToProps, mapDispatchToProps)(Compare);
