import { connect } from 'react-redux';
import ReviewStatus from 'components/review-status/review-status';

const mapStateToProps = ({ data: { currentProject } }) => ({
  currentProject
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewStatus);
