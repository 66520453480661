import { connect } from 'react-redux';

import {
  loadCurrentFolder,
  createFolder,
  currentFolderInvalidate,
  unsubscribeCurrentFolder
} from 'store/current-folder';
import { upload } from 'store/uploads';
import FileBrowser from 'components/file-browser/file-browser';
import { addNotification } from 'store/notifications.js';

const mapStateToProps = ({ data: { currentFolder, currentProject, uploads }, ui: { fileBrowserSelectedItems } }) => ({
  currentFolder,
  currentProject,
  selectedItems: fileBrowserSelectedItems,
  isUploadInProgress: !!Object.keys(uploads).length
});

const mapDispatchToProps = {
  addNotification,
  createFolder,
  loadCurrentFolder,
  upload,
  currentFolderInvalidate,
  unsubscribeCurrentFolder
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileBrowser);
