import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Constants from 'utils/constants';

import Icon from 'components/shared/icon';
import ComplicatedIcon from '../complicated-icon/complicated-icon';
import ColorHelper from '../helpers/colorHelper';
import Checkbox from 'components/shared/form/checkbox';
import ReactTooltip from 'react-tooltip';
import { Radio, RadioGroup } from 'components/shared/form/radio';

class ExtractionList extends Component {
  static propTypes = {
    data: PropTypes.any,
    groupByTemplateType: PropTypes.bool,
    groupKey: PropTypes.string,
    iconSize: PropTypes.string,
    nullGroupName: PropTypes.string,
    onCheckAll: PropTypes.func,
    onExtractionSelect: PropTypes.func,
    onSelectTemplateType: PropTypes.func,
    openByDefault: PropTypes.any,
    pageViewer: PropTypes.string, // Specifies which page its being reused on
    projectId: PropTypes.any,
    readOnly: PropTypes.bool,
    selectFunction: PropTypes.func,
    selectedExtractions: PropTypes.any,
    template: PropTypes.any,
    templateTypes: PropTypes.array,
    templateCategoryId: PropTypes.number
  };

  state = {
    checkedGroups: {},
    openGroups: {}, //Controls groups expanding/collapsing
    openTemplateTypes: {},
    selectedType: null
  };

  MAXIMUM_EXTRACTIONS_PER_LIMITED_GROUP = 50;

  getGroupedExtractions = data => {
    var { groupKey, nullGroupName } = this.props;
    var extractions = [];
    data.forEach(extraction => {
      if (extraction[groupKey] === null) {
        extraction[groupKey] = 0;
      }

      if (extraction[groupKey] !== null) {
        if (!extractions[extraction[groupKey]]) {
          extractions[extraction[groupKey]] = {
            extractions: [],
            title: extraction.group ? extraction.group : nullGroupName,
            displayOrder: extraction.groupDisplayOrder
          };
        }

        extractions[extraction[groupKey]].extractions.push({
          ...extraction,
          displayOrder: extraction.groupMapping
            ? extraction.groupMapping.find(gm => gm.extractionFieldGroupId === extraction[groupKey])
                ?.extractionFieldDisplayOrder
            : 0
        });
      }
    });

    return extractions;
  };

  componentDidUpdate(prevProps) {
    const { groupByTemplateType } = this.props;
    if (prevProps.groupByTemplateType !== groupByTemplateType) {
      this.setState({ openGroups: {}, openTemplateTypes: {}, selectedType: null });
    }
  }

  getUngroupedExtractions = () => {
    var { data, groupKey } = this.props;
    var extractions = [];
    data.forEach(extraction => {
      if (extraction[groupKey] === null) extractions.push(extraction);
    });
    return extractions;
  };

  getExtraction = (i, k, templateId = null) => {
    var {
      selectedExtractions,
      pageViewer,
      onExtractionSelect,
      selectFunction,
      projectId,
      groupByTemplateType,
      readOnly,
      templateCategoryId

    } = this.props;
    const { selectedType } = this.state;    
    var style = {
      background: ColorHelper.highlight_colors[i.color]
    };
    const disableExtraction = groupByTemplateType
      ? i.disabled ||
        !selectedType ||
        (selectedType && templateId !== selectedType.type.templateTypeID) ||
        (selectedType &&
          selectedType.type.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING)
      : i.disabled;

    return (
      <div
        className={`item padded--grouped ${i.disabled ? 'disabled' : ''}  ${
          pageViewer !== 'DocumentPage' ? 'padded' : ''
        }`}
        key={`${i.id}-${k}`}
        onClick={() => (!i.hasOwnProperty('disabled') ? selectFunction(i.id) : {})}
      >
        {i.hasOwnProperty('disabled') && !readOnly ? (
          <Checkbox
            className="extraction-checkbox"
            checked={
              i.disabled && !groupByTemplateType
                ? 'checked'
                : selectedExtractions.some(e => e.id === i.id) || i.selected
                ? 'checked'
                : 'unchecked'
            }
            disabled={disableExtraction}
            id="extraction-select"
            onChange={() => onExtractionSelect(i)}
            width={20}
          />
        ) : (
          <span className="color-marker" style={style} />
        )}
        <span className="text" data-tip data-for={`extraction.description.tooltip.${i.id}.${k}`}>
          {i.name}
        </span>

        <span className="spacer">
          <ReactTooltip
            id={`extraction.description.tooltip.${i.id}.${k}`}
            className="template-extraction__tooltip"
            effect="solid"
            place="right"
            data-multiline
            delayShow={1300}
            delayHide={i.description && i.description.length > 400 ? 500 : 0}
          >
            <p className="template-extraction__tooltip-paragraph"> {i.name}</p>

            <p className="template-extraction__tooltip-description">
              <FormattedMessage id="template-extractions.tooltip.description" />
            </p>

            {i.description.length > 400 ? (
              <div className="template-extraction__tooltip-box">
                <p className="template-extraction__tooltip-paragraph">{i.description}</p>
              </div>
            ) : (
              <p className="template-extraction__tooltip-paragraph">{i.description}</p>
            )}
          </ReactTooltip>
        </span>
        <div className="icons">
          <ComplicatedIcon
            projectId={projectId}
            extractionId={i.id}
            accessTypeId={i.AccessTypeId}
            isTrained={i.isTrained}
            isAutomaticExtraction={i.isAutomaticExtraction}
            trainingCount={i.trainingCount}
            trainingTarget={i.trainingTarget}
            iconSize={pageViewer !== 'DocumentPage' ? 22 : 30}
            templateCategoryId={templateCategoryId && templateCategoryId}
          />
        </div>
      </div>
    );
  };

  getAccordion = (group, key, templateId = null) => {
    var { openGroups, selectedType } = this.state;
    var {
      openByDefault,
      pageViewer,
      selectedExtractions,
      onCheckAll,
      nullGroupName,
      groupByTemplateType,
      readOnly
    } = this.props;

    var state = openGroups[key] !== undefined ? openGroups[key] : openByDefault !== undefined ? openByDefault : false;
    if (pageViewer !== 'DocumentPage') {
      var exts = group.extractions.filter(v => !v.disabled);
      var disabled = group.extractions.filter(v => v.disabled);
      var allSelected = group.extractions.every(e => selectedExtractions.find(v => v.id === e.id)); // When a user has selected all
      var fewSelected = group.extractions.filter(e => selectedExtractions.find(v => v.id === e.id)); // When a user has selected some
      var checkAllState =
        disabled.length === group.extractions.length
          ? 'checked'
          : allSelected
          ? 'checked'
          : fewSelected.length > 0 && fewSelected.length < group.extractions.length
          ? 'indeterminate'
          : 'unchecked';
    }

    var limitFields = 0;
    var extractionElements = group.extractions.map((extraction, index) =>
      this.getExtraction(extraction, index, templateId)
    );
    const disableGroup = groupByTemplateType
      ? !selectedType ||
        (selectedType && templateId !== selectedType.type.templateTypeID) ||
        (disabled && disabled.length === group.extractions.length) ||
        (selectedType &&
          selectedType.type.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING)
      : disabled && disabled.length === group.extractions.length;

    if (key === nullGroupName && group.extractions.length > this.MAXIMUM_EXTRACTIONS_PER_LIMITED_GROUP) {
      limitFields = group.extractions.length;
      extractionElements = extractionElements.slice(0, this.MAXIMUM_EXTRACTIONS_PER_LIMITED_GROUP);
    }
    
    // If we're not in document page, we can allow the user to select all group extraction fields at once
    return (
      <div className={`accordion ${state ? 'open' : ''}`} key={key}>
        <div className={`title ${groupByTemplateType ? 'group-section' : ''}`}>
          {pageViewer !== 'DocumentPage' && !readOnly ? (
            <span className="selectall-icon">
              <Checkbox
                className="extraction-checkbox"
                checked={checkAllState}
                disabled={disableGroup}
                id={`check-all ${key}`}
                onChange={() => onCheckAll({ [key]: exts }, key)}
                width={20}
              />
            </span>
          ) : null}
          <div
            className="wrapper"
            onClick={e => {
              e.stopPropagation();
              openGroups[key] = !state;
              this.setState({ openGroups: openGroups });
            }}
          >
            <h3>{group.title}</h3>
            <span className="icons">
              <Icon name="special-arrow-down-blue" width={20} height={20} rotate={state ? 180 : 0} />
            </span>
          </div>
        </div>
        {state ? extractionElements : null}
        {limitFields > 0 ? (
          <div className="item disabled extraction-limit-message">
            <FormattedMessage
              id="template-extractions.limit-message"
              values={{ limit: this.MAXIMUM_EXTRACTIONS_PER_LIMITED_GROUP, total: limitFields }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  sortByDisplayOrder = groupedExtractions => {
    for (var i in groupedExtractions) {
      groupedExtractions[i].extractions.sort((ef1, ef2) => ef1.displayOrder - ef2.displayOrder);
    }

    return groupedExtractions.sort((g1, g2) => g1.displayOrder - g2.displayOrder);
  };

  getAccordions = (data, templateId = null) => {
    var accordions = [];
    var groupedExtractions = this.sortByDisplayOrder(this.getGroupedExtractions(data));

    for (var i in groupedExtractions) {
      accordions.push(this.getAccordion(groupedExtractions[i], i, templateId));
    }

    return accordions;
  };

  getTemplateAccordions = () => {
    const { templateTypes, data, onSelectTemplateType } = this.props;
    const { openTemplateTypes, selectedType } = this.state;
    const templateAccordions = [];

    templateTypes.forEach(type => {
      const groupIds = type.groups.map(({ extractionFieldGroupId }) => extractionFieldGroupId);
      const templateData = data.filter(({ groupId }) => groupIds.includes(groupId));
      const templateGroups = this.getAccordions(templateData, type.templateTypeID);
      const state =
        openTemplateTypes[type.templateTypeID] !== undefined ? openTemplateTypes[type.templateTypeID] : false;
      const template = (
        <div className={`accordion ${state ? 'open' : ''}`} key={type.templateTypeID}>
          <div className="title">
            <span className="radio-icon">
              <Radio
                className="template-type__radio"
                id={`type-${type.templateTypeID}`}
                value={{ type, extractionFieldIds: templateData.map(({ id }) => id) }}
              />
            </span>
            <div
              className="wrapper"
              onClick={e => {
                e.stopPropagation();
                openTemplateTypes[type.templateTypeID] = !state;
                this.setState({ openTemplateTypes: openTemplateTypes });
              }}
            >
              <h2 className="template-title">
                {type.templateTypeID ? (
                  <FormattedMessage id={`project-creation-templateType_${type.templateTypeID}`} />
                ) : (
                  type.templateTypeName
                )}
              </h2>
              <span className="icons">
                <Icon name="special-arrow-down-blue" width={20} height={20} rotate={state ? 180 : 0} />
              </span>
            </div>
          </div>
          {state ? templateGroups : null}
        </div>
      );

      templateAccordions.push(template);
    });

    return (
      <RadioGroup
        name="template-type__radio-group"
        selectedValue={selectedType}
        onChange={selectedType =>
          this.setState({ selectedType: selectedType }, () => onSelectTemplateType(selectedType))
        }
      >
        {templateAccordions}
      </RadioGroup>
    );
  };

  sortByOrder = data => {
    const { nullGroupName } = this.props;

    const ORDER = [
      'General Terms',
      'Leases',
      'Interest Rate Derivatives',
      'Preferred Stock Agreements',
      'Reinsurance Agreements',
      'Debt Agreements'
    ];

    // We are adding a custom sort logic for the existing groups
    let GROUPS = data
      .filter(v => ORDER.indexOf(v.key) > -1)
      .sort((a, b) => ORDER.indexOf(a.key) > ORDER.indexOf(b.key));

    // Just sort by alphabetical the private groups
    let OTHERS = data
      .filter(v => ORDER.indexOf(v.key) === -1 && v.key !== nullGroupName)
      .sort((a, b) => {
        if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
        if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
        return 0;
      });

    const PUBLIC = GROUPS.concat(OTHERS);
    const PRIVATE = data.filter(v => v.key === nullGroupName);
    return PUBLIC.concat(PRIVATE);
  };

  render() {
    var { data, nullGroupName, template, groupByTemplateType } = this.props;
    if (!data || !data.length || !data.length > 0) return null;
    var ungroupedExtractions = this.getUngroupedExtractions();

    return (
      <div className="extraction-list">
        {template && <div className="extraction-list__template-name">{template.name}</div>}
        {groupByTemplateType ? this.getTemplateAccordions() : this.getAccordions(data)}
        {nullGroupName ? null : ungroupedExtractions.map(this.getExtraction)}
      </div>
    );
  }
}

export default ExtractionList;
