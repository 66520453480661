import React, { PureComponent } from 'react';

class ProgressLoader extends PureComponent {
  render() {
    return (
      <div className={'progress-loader'}>
        <div className={'progress-loader__filler'}></div>
      </div>
    );
  }
}

export default ProgressLoader;
