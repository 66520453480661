import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { debounce } from 'underscore';
import SearchInputField from 'containers/search/search-inputfield-container';

/** Project list page search bar */
class ProjectListSearch extends Component {
  static propTypes = {
    location: PropTypes.object,
    onSearchChange: PropTypes.func,
    projectList: PropTypes.object,
    search: PropTypes.object,
    searchProjectList: PropTypes.func,
    fromLandingPage : PropTypes.bool,
    fromProjectListPage : PropTypes.bool,
    intl: intlShape.isRequired
  };

  componentDidMount() {
    if (this.props.search.query.length > 0) {
      this.props.onSearchChange('');
    }
  }

  componentWillUnmount() {
    if (this.props.search.query.length > 0) {
      this.props.onSearchChange('');
    }
  }

  // Input bool represents whether we are searching or clearing out the filter
  searchProjects = bool => {
    const { searchProjectList, search } = this.props;
    return searchProjectList(bool ? search.query : '');
  };

  onKeyUp = debounce(() => {
    this.searchProjects(true);
  }, 650);

  render() {
    const { location, projectList,fromProjectListPage,intl } = this.props;

    return (
      <SearchInputField
        location={location}
        inputDisabled={!projectList.isLoaded}
        onEnter={() => this.searchProjects(true)}
        onExit={() => this.searchProjects(false)}
        onKeyUp={this.onKeyUp}
        fromLandingPage = {this.fromLandingPage}
        fromProjectListPage ={fromProjectListPage}
        intl = {intl}
      />
    );
  }
}

export default injectIntl(ProjectListSearch);
