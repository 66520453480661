import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { OCRQualityTags } from 'models/tags/ocr-quality-tags';
import { ACTIONS as PROJECT_ACTIONS } from 'store/current-project';

export const ACTIONS = {
  OCR_QUALITY_TAGS_LOADING: 'argus/ui/OCR_QUALITY_TAGS_LOADING',
  OCR_QUALITY_TAGS_LOADED: 'argus/ui/OCR_QUALITY_TAGS_LOADED'
};

export const ocrQualityTagsLoading = createAction(ACTIONS.OCR_QUALITY_TAGS_LOADING);
export const ocrQualityTagsLoaded = createAction(ACTIONS.OCR_QUALITY_TAGS_LOADED);

export const getData = projectId => dispatch => {
  dispatch(ocrQualityTagsLoading());
  return api.getOCRQualityTags(projectId).then(res => {
    dispatch(ocrQualityTagsLoaded(res.data));
  });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new OCRQualityTags();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.OCR_QUALITY_TAGS_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.OCR_QUALITY_TAGS_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [PROJECT_ACTIONS.CLEAR_PROJECT_DATA]: (state, action) => {
    return INITIAL_STATE;
  }
});
