import { connect } from 'react-redux';

import { onSearchChange } from 'store/search/search-category';
import UserSearch from 'components/search/user-search/user-search';

const mapStateToProps = ({ data: { search } }) => ({
  search
});
const mapDispatchToProps = {
  onSearchChange
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSearch);
