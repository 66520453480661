import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { DocumentTags } from 'models/tags/document-tags';
import { ACTIONS as PROJECT_ACTIONS } from 'store/current-project';

export const ACTIONS = {
  DOCUMENT_TAGS_LOADING: 'argus/ui/DOCUMENT_TAGS_LOADING',
  DOCUMENT_TAGS_LOADED: 'argus/ui/DOCUMENT_TAGS_LOADED'
};

export const documentTagsLoading = createAction(ACTIONS.DOCUMENT_TAGS_LOADING);
export const documentTagsLoaded = createAction(ACTIONS.DOCUMENT_TAGS_LOADED);

export const getData = projectId => dispatch => {
  dispatch(documentTagsLoading());
  return api.getDocumentTags(projectId).then(res => {
    dispatch(documentTagsLoaded(res.data));
  });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new DocumentTags();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.DOCUMENT_TAGS_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.DOCUMENT_TAGS_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [PROJECT_ACTIONS.CLEAR_PROJECT_DATA]: (state, action) => {
    return INITIAL_STATE;
  }
});
