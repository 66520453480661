import { connect } from 'react-redux';

import { loadProjectList } from 'store/project-list';
import { updateProjectState } from 'store/project-list';
import ProjectList from 'components/project-list/project-list';
import { loadTranslationsList } from 'store/translations';
import { onSearchChange } from 'store/search/search-category';

const mapStateToProps = ({ data: { projectList, currentProject, translations } }) => ({
  projectList,
  currentProject,
  translations
});

const mapDispatchToProps = {
  loadProjectList,
  updateProjectState,
  loadTranslationsList,
  onSearchChange
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
