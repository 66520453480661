import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';
import Checkbox from 'components/shared/form/checkbox';
import { ITEM_TYPES } from 'models/project-item';
import { FormattedMessage } from 'react-intl';
import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';
import { ITEM_STATUSES } from 'models/project-item';
import Button from 'components/shared/single-click-button';
import Pagination from 'components/shared/pagination/pagination';
import LoadingOverlay from 'components/shared/loading/light';

export default class AddToCompareSidebar extends Component {
  static propTypes = {
    currentFolder: PropTypes.object.isRequired,
    loadCurrentFolder: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired
  };

  state = {
    selectedFileIds: new Set(),
    allChildren: [],
    folderId: null
  };

  scrollArea = null;

  onAdd = () => {
    const selectedItems = Array.from(this.state.selectedFileIds).map(id =>
      this.state.allChildren.find(c => c.projectItemId === id)
    );

    this.props.onSubmit(selectedItems);
  };

  componentDidMount() {
    const { loadCurrentFolder, projectId } = this.props;

    //Load the root folder
    loadCurrentFolder({ projectId, forceFetch: true });
  }

  componentDidUpdate(prevProps) {
    const { currentFolder } = this.props;

    if (currentFolder.children !== prevProps.currentFolder.children) {
      var { allChildren } = this.state;

      currentFolder.children.forEach(child => {
        if (!allChildren.some(cac => cac.projectItemId === child.projectItemId)) {
          allChildren.push(child);
        }
      });

      this.setState({ allChildren });
    }
  }

  loadCurrentFolder;

  navigateToFolder = folderId => {
    this.props.loadCurrentFolder({
      projectId: this.props.projectId,
      folderId,
      forceFetch: true
    });

    this.setState({ folderId });
  };

  toggleSelectedFile(projectItem) {
    if (this.state.selectedFileIds.has(projectItem.projectItemId)) {
      this.state.selectedFileIds.delete(projectItem.projectItemId);
    } else {
      this.state.selectedFileIds.add(projectItem.projectItemId);
    }

    this.setState({ selectedFileIds: this.state.selectedFileIds });
  }

  onPageChange = pageNum => {
    const { loadCurrentFolder, projectId } = this.props;
    const { folderId } = this.state;

    this.scrollArea.scrollTo(0, 0);

    var params = {
      projectId,
      pageNum,
      forceFetch: true
    };

    if (folderId !== null) {
      params.folderId = folderId;
    }

    loadCurrentFolder(params);
  };

  selectedOnPageCount = () => {
    var { currentFolder } = this.props;
    var { selectedFileIds } = this.state;
    var children = currentFolder.children || [];

    return children.filter(child => selectedFileIds.has(child.projectItemId)).length;
  };

  selectAllItemsOnPage = addItem => {
    var { currentFolder } = this.props;
    var { selectedFileIds } = this.state;
    var children = currentFolder.children || [];

    children.forEach(child => {
      if (addItem) {
        if (
          !selectedFileIds.has(child.projectItemId) &&
          (child.itemStateId === ITEM_STATUSES.PROCESSED || child.itemTypeId === ITEM_TYPES.FOLDER)
        ) {
          selectedFileIds.add(child.projectItemId);
        }
      } else {
        if (selectedFileIds.has(child.projectItemId)) {
          selectedFileIds.delete(child.projectItemId);
        }
      }
    });

    this.setState({ selectedFileIds });
  };

  isAsSelectedAsPossible = () => {
    const { currentFolder } = this.props;
    const childItems = currentFolder.children || [];

    return (
      childItems.filter(
        child => child.itemStateId === ITEM_STATUSES.PROCESSED || child.itemTypeId === ITEM_TYPES.FOLDER
      ).length === this.selectedOnPageCount()
    );
  };

  render() {
    const { onClose, currentFolder } = this.props;
    const { selectedFileIds } = this.state;

    const childItems = currentFolder.children || [];

    let breadcrumbs = [];
    if (currentFolder && currentFolder.parentHierarchy) {
      breadcrumbs = currentFolder.parentHierarchy.slice(1, currentFolder.parentHierarchy.length).reverse();
    }

    var selectedOnPageCount = this.selectedOnPageCount();

    return (
      <div>
        <div className="add-to-compare-sidebar__overlay" />
        <div className="add-to-compare-sidebar">
          <div className="add-to-compare-sidebar__header">
            <span>
              <FormattedMessage id="compare-browser.add-to-compare.title" />
            </span>
            <Button size="icon" onClick={onClose} className="add-to-compare-sidebar__close-button icon-button">
              <Icon name="special-cross" width={14} />
            </Button>
          </div>
          <div className="add-to-compare-sidebar__breadcrumbs">
            <div
              className={
                'add-to-compare-sidebar__breadcrumb-home ' +
                (currentFolder.itemName ? 'add-to-compare-sidebar__breadcrumb-home-clickable' : '')
              }
              onClick={() => this.navigateToFolder('root')}
            >
              <FormattedMessage id="compare-browser.create-form.breadcrumb-root" />
            </div>
            {!!currentFolder.itemName && <div className="add-to-compare-sidebar__breadcrumb-angle-bracket">&gt;</div>}
            {breadcrumbs.length > 0 && (
              <div className="add-to-compare-sidebar__breadcrumbs-dropdown-container">
                <MenuWrapper className="add-to-compare-sidebar__breadcrumb-menu" onSelection={this.navigateToFolder}>
                  <MenuButton className="form-dropdown add-to-compare-sidebar__breadcrumb-menu-button">...</MenuButton>
                  <Menu className="add-to-compare-sidebar__breadcrumb-menu-content">
                    <ul>
                      {breadcrumbs.map(folder => {
                        return (
                          <li key={folder.projectItemId}>
                            <MenuItem
                              value={folder.projectItemId}
                              className="form-dropdown__item add-to-compare-sidebar__breadcrumb-menu-item"
                            >
                              {folder.itemName}
                            </MenuItem>
                          </li>
                        );
                      })}
                    </ul>
                  </Menu>
                </MenuWrapper>
              </div>
            )}
            {breadcrumbs.length > 0 && <div className="add-to-compare-sidebar__breadcrumb-angle-bracket">&gt;</div>}
            <div className="add-to-compare-sidebar__breadcrumb-current-folder">{currentFolder.itemName}</div>
          </div>

          <div className="add-to-compare-sidebar__content">
            {currentFolder.isLoading ? <LoadingOverlay text={false} /> : null}

            {childItems && childItems.length > 0 ? (
              <div className="list-header">
                <Checkbox
                  id={`file-checkbox-all`}
                  onChange={() => {
                    this.selectAllItemsOnPage(!this.isAsSelectedAsPossible());
                  }}
                  checked={
                    selectedOnPageCount === 0
                      ? 'unchecked'
                      : this.isAsSelectedAsPossible()
                        ? 'checked'
                        : 'indeterminate'
                  }
                  className="add-to-compare-sidebar__item-checkbox"
                />
                <FormattedMessage id="file-list.heading.name" />
              </div>
            ) : null}

            <div className="scroll-area" ref={element => (this.scrollArea = element)}>
              <ul className="add-to-compare-sidebar__project-item-list">
                {childItems.map(item => (
                  <li key={item.projectItemId} className="add-to-compare-sidebar__project-item">
                    {item.itemTypeId === ITEM_TYPES.FOLDER && (
                      <div className="add-to-compare-sidebar__folder">
                        <Checkbox
                          id={`folder-checkbox-${item.projectItemId}`}
                          onChange={() => this.toggleSelectedFile(item)}
                          checked={selectedFileIds.has(item.projectItemId) ? 'checked' : 'unchecked'}
                          className="add-to-compare-sidebar__item-checkbox"
                        />
                        <Icon name="special-folder" width={18} className="add-to-compare-sidebar__folder-icon" />
                        <span
                          className="add-to-compare-sidebar__folder-name"
                          onClick={() => this.navigateToFolder(item.projectItemId)}
                          title={item && item.itemName && item.itemName.length > 30 ? item.itemName : ''}
                        >
                          {item.itemName}
                        </span>
                      </div>
                    )}
                    {item.itemTypeId === ITEM_TYPES.DOCUMENT && (
                      <div
                        className="add-to-compare-sidebar__file"
                        onClick={() => {
                          if (item.itemStateId === ITEM_STATUSES.PROCESSED) this.toggleSelectedFile(item);
                        }}
                      >
                        {item.itemStateId === ITEM_STATUSES.PROCESSED ? (
                          <Checkbox
                            id={`file-checkbox-${item.projectItemId}`}
                            onChange={() => {}}
                            checked={selectedFileIds.has(item.projectItemId) ? 'checked' : 'unchecked'}
                            className="add-to-compare-sidebar__item-checkbox"
                          />
                        ) : (
                          <div className="add-to-compare-sidebar__item-checkbox__placeholder" />
                        )}
                        <span title={item && item.itemName && item.itemName.length > 30 ? item.itemName : ''}>
                          {item.itemName}
                        </span>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            {currentFolder && currentFolder.pagination !== null ? (
              <Pagination onPageChange={this.onPageChange} pagination={currentFolder.pagination} />
            ) : null}
          </div>

          <div className="add-to-compare-sidebar__footer">
            <Button className="btn btn-primary btn-no-margin" onClick={() => this.onAdd()}>
              <FormattedMessage id="common.add" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
