import { chain } from 'underscore';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import Icon from 'components/shared/icon';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { createExport, getExports, getItemTemplates, deleteExport } from 'store/api';
import { EXPORT_FORMATS, EXPORT_LAYOUTS } from 'components/extraction-overview/export-sidebar/constants';
import { sortByKey } from 'utils/string-utils';
import Button from 'components/shared/single-click-button';
import Create from 'components/extraction-overview/export-sidebar/export-sidebar-create';
import Progress from 'components/shared/export/export-sidebar-progress';
import socket, { rooms } from 'utils/socket';
import dateUtil from 'utils/dateUtil';

const TABS = {
  CREATE: 'CREATE',
  PROGRESS: 'PROGRESS'
};

const TAG_OPTIONS = {
  NONE: 0,
  ALL: 1,
  DOCUMENT_TAGS: 2,
  EXTRACTION_FIELD_TAGS: 3
};

class ExportSidebar extends Component {
  static propTypes = {
    currentFolder: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    intl: intlShape,
    onCloseClick: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.array.isRequired,
    extractionOverview: PropTypes.object.isRequired,
    columnOptions: PropTypes.object,
    customizedVisibility: PropTypes.object
  };

  state = {
    currentTab: TABS.CREATE,
    exports: [],
    format: EXPORT_FORMATS.EXCEL,
    isLoadingExports: false,
    layout: EXPORT_LAYOUTS.COLUMN,
    isDownloadPerDocument: false,
    selectedFieldsByTemplateId: {},
    selectedTags: [],
    tags: [
      {
        id: TAG_OPTIONS.DOCUMENT_TAGS,
        name: this.props.intl.formatMessage({ id: 'export-sidebar.create.tags.type.documents' })
      },
      {
        id: TAG_OPTIONS.EXTRACTION_FIELD_TAGS,
        name: this.props.intl.formatMessage({ id: 'export-sidebar.create.tags.type.extractions' })
      }
    ],
    templates: []
  };

  componentDidMount() {
    const { currentUser, projectId } = this.props;
    this.getItemTemplates();
    this.getExports('extractionOverview');

    // Join the exports room on the socket connection
    if (currentUser.isLoaded) {
      socket.join(rooms.exports(projectId, currentUser.userId));
    }

    // Subscribe to export updates from the socket connection
    socket.on('export.update', this.onExportUpdate);
  }

  componentDidUpdate(prevProps) {
    // If the current user changes, join a new room on the socket connection
    if (this.props.currentUser.isLoaded) {
      const prevRoom = rooms.exports(prevProps.projectId, prevProps.currentUser.userId);
      const currRoom = rooms.exports(this.props.projectId, this.props.currentUser.userId);
      if (prevRoom !== currRoom) {
        socket.leave(prevRoom);
        socket.join(currRoom);
      }
    }
  }

  componentWillUnmount() {
    const { currentUser, projectId } = this.props;
    socket.off('export.update', this.onExportUpdate);
    socket.leave(rooms.exports(projectId, currentUser.userId));
  }

  getItemTemplates = () => {
    const { projectId, selectedItems, columnOptions, customizedVisibility } = this.props;

    if (!selectedItems?.length) {
      return;
    }
    let customVisibility = false;
    if (Object.keys(customizedVisibility).length > 0) {
      customVisibility = true;
    } else {
      customVisibility = false;
    }
    let selectedFieldsByTemplateId = null;
    getItemTemplates({ projectId, items: selectedItems.map(i => i.projectItemId) }).then(responses => {
      // select all extraction fields in new incoming templates
      if(customVisibility == false){
        selectedFieldsByTemplateId = chain(responses.data)
        .map(template => {
          const { projectTemplateId } = template;
          const selectedExtractionFields = this.state.selectedFieldsByTemplateId[projectTemplateId];
          const xfs =  template.extractionFields.filter(field => columnOptions.visibility[field.extractionFieldId] !== false);
          return [
            projectTemplateId,
            selectedExtractionFields || new Set(xfs.map(field => field.extractionFieldId))
          ];
        })
        .object()
        .value();
      }
      else{
        selectedFieldsByTemplateId = chain(responses.data)
        .map(template => {
          const { projectTemplateId } = template;
          const selectedExtractionFields = this.state.selectedFieldsByTemplateId[projectTemplateId];
          const xfs =  template.extractionFields.filter(field => customizedVisibility[field.extractionFieldId] !== false);
          return [
            projectTemplateId,
            selectedExtractionFields || new Set(xfs.map(field => field.extractionFieldId))
          ];
        })
        .object()
        .value();
      }
        
      this.setState({
        templates: sortByKey('name', Object.values(responses.data)),
        selectedFieldsByTemplateId
      });
    });
  };

  onExportUpdate = event => {
    const { exportDetailId, exportStateId } = event.data;

    if (!exportDetailId || !exportStateId) {
      return;
    }

    const updatedExports = this.state.exports.map(item => {
      if (item.exportDetailID === exportDetailId) {
        item.exportStateId = exportStateId;
      }
      return item;
    });

    this.setState({
      exports: updatedExports
    });
  };

  showCreateTab = () => {
    this.setState({
      currentTab: TABS.CREATE
    });
  };

  showProgressTab = () => {
    this.setState({
      currentTab: TABS.PROGRESS
    });
  };

  setSelectedTags = selectedTags => {
    this.setState({ selectedTags });
  };

  setSelectedFieldsByTemplateId = selectedFieldsByTemplateId => {
    this.setState({
      selectedFieldsByTemplateId
    });
  };

  onFormatChange = format => {
    this.setState({
      format
    });
  };

  onLayoutChange = layout => {
    this.setState({
      layout
    });
  };

  toggleIsDownloadPerDocument = (id, isDownloadPerDocument) => {
    this.setState({
      isDownloadPerDocument
    });
  };

  getExports = exportType => {
    const { projectId } = this.props;
    this.setState({ isLoadingExports: true });
    getExports({ projectId, exportType }).then(result => {
      this.setState({
        exports: result.data,
        isLoadingExports: false
      });
    });
  };
  
  createExport = () => {
    const { currentProject, projectId, selectedItems, intl } = this.props;
    const {
      format: type,
      isDownloadPerDocument,
      layout,
      quality: exportQuality,
      selectedFieldsByTemplateId,
      selectedTags    
    } = this.state;

    const storedTemplate = this.state.templates[0].name;

    const tagsOptions = selectedTags.length
      ? selectedTags.length > 1
        ? TAG_OPTIONS.ALL
        : selectedTags[0]
      : TAG_OPTIONS.NONE;
     
    const currentDate = moment();
    const payload = {
      projectId,
      exportOptions: {
        exportFileName: `${currentProject.projectName}_${intl.formatMessage({
            id: 'extraction-overview.fileName'
        })}_${storedTemplate}_${dateUtil.formatTimestamp(currentDate)}`,
        exportOption: type,
        exportType: 5, //5: extractionOverview
        layout,
        tagsOptions: tagsOptions,
        templates: Object.entries(selectedFieldsByTemplateId).map(([projectTemplateId, selectedExtractionFields]) => ({
          projectTemplateId,
          extractionFields: Array.from(selectedExtractionFields.keys()).map(id => ({
            extractionFieldId: id
          }))
        })),
        items: selectedItems,
        isDownloadPerDocument,
        exportQuality
      }
    };

    createExport(payload).then(() => {
      this.getExports('extractionOverview');
      this.showProgressTab();
    });
  };

  deleteExport = exportId => {
    const { projectId } = this.props;
    // Optimistically delete
    this.setState({
      exports: this.state.exports.filter(item => item.exportDetailID !== exportId)
    });
    deleteExport({ projectId, exportId })
      // Re fetch on error
      .catch(this.getExports('extractionOverview'));
  };

  render() {
    const { currentFolder, currentProject, onCloseClick, projectId, selectedItems } = this.props;
    const {
      currentTab,
      exports,
      format,
      isLoadingExports,
      layout,
      isDownloadPerDocument,
      quality,
      selectedFieldsByTemplateId,
      selectedTags,
      tags,
      templates
    } = this.state;

    if (!currentProject.isLoaded) {
      return null;
    }
    return (
      <div className="export-sidebar">
        <div className="export-sidebar__header">
          <h2 className="export-sidebar__title">
            <FormattedMessage id="export-sidebar.title" />
          </h2>
          <Button size="icon" onClick={onCloseClick} className="export-sidebar__close-button icon-button">
            <Icon name="special-cross" width={14} />
          </Button>
        </div>
        <div className="export-sidebar__tabs">
          <Button
            className={classNames(
              'export-sidebar__tab-button',
              currentTab === TABS.CREATE && 'export-sidebar__tab-button--selected'
            )}
            onClick={this.showCreateTab}
          >
            <FormattedMessage id="export-sidebar.tab.create" />
          </Button>
          <Button
            className={classNames(
              'export-sidebar__tab-button',
              currentTab === TABS.PROGRESS && 'export-sidebar__tab-button--selected'
            )}
            onClick={this.showProgressTab}
          >
            <FormattedMessage id="export-sidebar.tab.progress" />
          </Button>
        </div>
        <div className="export-sidebar__main">
          {currentTab === TABS.CREATE && (
            <Create
              currentFolder={currentFolder}
              currentProject={currentProject}
              format={format}
              layout={layout}
              onCloseClick={onCloseClick}
              isDownloadPerDocument={isDownloadPerDocument}
              onFormatChange={this.onFormatChange}
              onLayoutChange={this.onLayoutChange}
              onSubmit={this.createExport}
              projectId={projectId}
              quality={quality}
              selectedFieldsByTemplateId={selectedFieldsByTemplateId}
              selectedItems={selectedItems?.map(i => i.projectItemId)}
              selectedTags={selectedTags}
              setSelectedFieldsByTemplateId={this.setSelectedFieldsByTemplateId}
              setSelectedTags={this.setSelectedTags}
              tags={tags}
              templates={templates}
              toggleIsDownloadPerDocument={this.toggleIsDownloadPerDocument}
            />
          )}
          {currentTab === TABS.PROGRESS &&
            (isLoadingExports ? (
              <Icon className="spinner spinner--centered" name="loader" width={80} />
            ) : (
              <Progress projectId={projectId} exports={exports} deleteExport={this.deleteExport} />
            ))}
        </div>
      </div>
    );
  }
}

export default injectIntl(ExportSidebar);
