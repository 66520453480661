import { DragSource, DropTarget } from 'react-dnd';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';

import { COLUMN_OPTIONS, DEFAULT_COLUMN_ROOTSHOW, COLUMN_OPTIONS_DOCSHOW, DEFAULT_COLUMN_ROOTSHOWForProjects } from 'store/file-browser-column-options';
import { getCurrentRegion } from 'store/api';
import { ITEM_STATUS_NAMES, ITEM_STATUS_CLASSNAMES } from 'components/file-browser/file-list/constants';
import { ProjectItem, ITEM_TYPES } from 'models/project-item';
import AssignCell from 'components/file-browser/file-list/assign-cell';
import Checkbox from 'components/shared/form/checkbox';
import dateUtil from 'utils/dateUtil';
import FileListRecordItem from 'components/file-browser/file-list/file-list-record-item';
import ItemList from 'components/file-browser/file-list/item-list';
import NameCell from 'components/file-browser/file-list/name-cell';
import Permissions from 'permissions/permissions';
import TagsCell from 'components/file-browser/file-list/tags-cell';
import constants from 'utils/constants';
import { FormattedMessage } from 'react-intl';
/*
 A single row of the file list table

 The class is wrapped by react dnd to allow drag and drop
*/
class FileListRow extends PureComponent {
  static propTypes = {
    collapseExpandedRows: PropTypes.func.isRequired,
    columnOptions: PropTypes.object.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    currentFolder: PropTypes.object.isRequired,
    deselectItem: PropTypes.func.isRequired,
    expand: PropTypes.func.isRequired,
    invalidate: PropTypes.func.isRequired,
    isDraggingOver: PropTypes.bool,
    isExpanded: PropTypes.bool.isRequired,
    isSearchResultsPage: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool,
    moveItems: PropTypes.func.isRequired,
    onNameClick: PropTypes.func,
    onRowMouseDown: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    projectItem: PropTypes.instanceOf(ProjectItem),
    readOnly: PropTypes.bool,
    renameItem: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    setIsDraggingValidItem: PropTypes.func.isRequired,
    currentProject: PropTypes.object
  };

  onCheckboxChange = (id, checked) => {
    const { selectItem, deselectItem, projectItem } = this.props;

    if (checked === 'checked') {
      selectItem(projectItem.projectItemId);
    } else {
      deselectItem(projectItem.projectItemId);
    }
  };

  // Check if the target or a parent of the target has the data-ignore-mouse-down attribute
  // This means we should ignore the mousedown event
  shouldIgnoreMouseDown = elem => {
    if (this.props.readOnly) {
      return true;
    }
    while (elem.parentNode) {
      if (elem.getAttribute('data-ignore-mouse-down')) {
        return true;
      }

      elem = elem.parentNode;
    }

    return false;
  };

  onRowMouseDown = event => {
    const { onRowMouseDown, projectItem, setIsDraggingValidItem } = this.props;

    if (!this.shouldIgnoreMouseDown(event.target)) {
      setIsDraggingValidItem(true);
      onRowMouseDown(projectItem.projectItemId);
      return;
    }

    setIsDraggingValidItem(false);
  };

  handleClickOutside = () => {
    const { isExpanded, collapseExpandedRows } = this.props;

    if (isExpanded) {
      collapseExpandedRows();
    }
  };

  getItemLink = (projectItemId, itemTypeId) => {
    const { projectId } = this.props;
    const itemType = itemTypeId === 1 ? 'folder' : 'document';

    return `/region/${getCurrentRegion()}/project/${projectId}/${itemType}/${projectItemId}`;
  };

  render() {
    const {
      columnOptions,
      connectDragSource,
      connectDropTarget,
      expand,
      isDraggingOver,
      isExpanded,
      isSelected,
      projectId,
      projectItem,
      renameItem,
      isSearchResultsPage,
      onNameClick,
      invalidate,
      readOnly,
      currentFolder,
      currentProject
    } = this.props;
    
    const {
      assignments,
      extractionFieldInstanceDetails,
      itemName,
      itemStateId,
      itemTypeId,
      lastProcessedDate,
      lastUpdatedDate,
      pageCount,
      projectItemId,
      projectTemplates,
      reviewers,
      tags,
      dateFormatPreference,
      autoDeleteDays
    } = projectItem;
    
    let defaultRootColumnsToShow = null;
    if(!isSearchResultsPage)
      defaultRootColumnsToShow = currentProject.projectTypeId == constants.ProjectTypes.WORKSPACE_PROJECT ?
        DEFAULT_COLUMN_ROOTSHOW : DEFAULT_COLUMN_ROOTSHOWForProjects;

    const TABLE_ROWS = {
      [COLUMN_OPTIONS.NAME]: (
        <td key={COLUMN_OPTIONS.NAME} className="file-list__td-name">
          <NameCell
            invalidate={invalidate}
            link={this.getItemLink(projectItemId, itemTypeId)}
            projectItemId={projectItemId}
            itemTypeId={itemTypeId}
            itemStateId={itemStateId}
            itemName={itemName}
            renameItem={renameItem}
            extractionFieldInstanceDetails={extractionFieldInstanceDetails}
            projectId={projectId}
            isSearchResultsPage={isSearchResultsPage}
            onNameClick={() => onNameClick(projectItemId, itemTypeId, extractionFieldInstanceDetails)}
            readOnly={readOnly || !Permissions.Project.Document.canModify()}
          />
        </td>
      ),
      [COLUMN_OPTIONS.ID]: (
        <td key={COLUMN_OPTIONS.ID} className="file-list__td-id">
          {projectItemId}
        </td>
      ),
      [COLUMN_OPTIONS.PAGES]: (        
        <td key={COLUMN_OPTIONS.PAGES} className="file-list__td-pages">
          {pageCount ? pageCount : '-'}
        </td>        
      ),
        [COLUMN_OPTIONS.ASSIGNEES]: (
        <td key={COLUMN_OPTIONS.ASSIGNEES} className="file-list__td-assignees">
          <AssignCell
            assignments={assignments}
            expand={expand}
            invalidate={invalidate}
            isExpanded={isExpanded}
            projectId={projectId}
            projectItemId={projectItemId}
            readOnly={readOnly || !Permissions.Project.Document.canTag()}
          />
      </td>
     ),
        [COLUMN_OPTIONS.REVIEWERS]: (
        <td key={COLUMN_OPTIONS.REVIEWERS} className="file-list__td-reviewers">
          <ItemList
            items={reviewers
              .sort((a, b) => {
                if (a.lastProcessedDate && b.lastProcessedDate) {
                  if (a.lastProcessedDate > b.lastProcessedDate) return -1;
                  if (a.lastProcessedDate < b.lastProcessedDate) return 1;
                  return 0;
                } else {
                  if (a.lastUpdatedDate > b.lastUpdatedDate) return -1;
                  if (a.lastUpdatedDate < b.lastUpdatedDate) return 1;
                  return 0;
                }
              })
              .map(reviewer => reviewer.user.preferredFullName)}
            name={`reviewers-${projectItemId}`}
            isExpanded={isExpanded}
            expand={expand}
          />
        </td>
       ),
        [COLUMN_OPTIONS.TAGS]: (
        <td key={COLUMN_OPTIONS.TAGS} className="file-list__td-tags">
          <TagsCell
            expand={expand}
            invalidate={invalidate}
            isExpanded={isExpanded}
            projectId={projectId}
            projectItemId={projectItemId}
            readOnly={readOnly || !Permissions.Project.Document.canTag()}
            tags={tags}
          />
        </td>
      ),
        [COLUMN_OPTIONS.TEMPLATE]: (
            <td key={COLUMN_OPTIONS.TEMPLATE} className="file-list__td-template">
                <ItemList
                    items={projectTemplates.map(template => template.name)}
                    name={`template-${projectItemId}`}
                    isExpanded={isExpanded}
                    expand={expand}
                />
            </td>
        ),
        [COLUMN_OPTIONS.STATUS]:
            itemTypeId === ITEM_TYPES.DOCUMENT ? (
          <td key={COLUMN_OPTIONS.STATUS} className="file-list__td-status">
            {!!ITEM_STATUS_CLASSNAMES[itemStateId] && (
              <div
                className={classNames(
                  'file-list__status-icon',
                  `file-list__status-icon--${ITEM_STATUS_CLASSNAMES[itemStateId]}`
                )}
              />
            )}
            {ITEM_STATUS_NAMES[itemStateId]}
          </td>      
            ) : (
                <td key={COLUMN_OPTIONS.STATUS} className="file-list__td-status" />
            ),
        [COLUMN_OPTIONS.DATEFORMATPREFERENCE]:  
          <td key={COLUMN_OPTIONS.DATEFORMATPREFERENCE} className="file-list__td-dateformat">
            {dateFormatPreference == null? '' : dateFormatPreference.toUpperCase()}
          </td>,
      [COLUMN_OPTIONS.PROCESSED_DATE]: (
        <td key={COLUMN_OPTIONS.PROCESSED_DATE} className="file-list__td-processed">
          {dateUtil.formatDateTime(lastProcessedDate && itemTypeId !== 1 ? lastProcessedDate : lastUpdatedDate)}
        </td>),
        [COLUMN_OPTIONS.AUTO_DELETE]: (
          <td key={COLUMN_OPTIONS.AUTO_DELETE} className={autoDeleteDays < 8 && autoDeleteDays > 3 ? 'file-list__td-autodelete-yellow':
          autoDeleteDays < 4 ? 'file-list__td-autodelete-red' : 'file-list__td-autodelete'}>
            {currentProject?.projectTypeId == constants.ProjectTypes.WORKSPACE_PROJECT && <FormattedMessage id="file-list.item.auto-delete-daysLeft" values={ {autoDeleteDays} } /> }          
          </td>      
     )
    };

    const hasExtractionFieldInstanceDetails = extractionFieldInstanceDetails && !!extractionFieldInstanceDetails.length;

    const row = (
      <tr
        className={classNames(
          'file-list__tr',
          isDraggingOver && itemTypeId === ITEM_TYPES.FOLDER && 'file-list__tr--dragover',
          hasExtractionFieldInstanceDetails && 'file-list__tr--has-details',
          isExpanded && 'file-list__tr--expanded',
          isSelected && 'file-list__tr--selected'
        )}
        onMouseDown={this.onRowMouseDown}
      >
        <td className="file-list__td-checkbox" data-ignore-mouse-down>
          <Checkbox
            checked={isSelected ? 'checked' : 'unchecked'}
            className="file-list__checkbox file-list_tr-action-item"
            id={`check-${projectItemId}`}
            onChange={this.onCheckboxChange}
          />
        </td>
        {columnOptions.order.filter(option => columnOptions.visibility[option]).map(option => 
          currentFolder.projectItemId == 0 && defaultRootColumnsToShow.indexOf(option) != -1 ? TABLE_ROWS[option] :
          currentFolder.projectItemId != 0 && COLUMN_OPTIONS_DOCSHOW.indexOf(option) != -1 && TABLE_ROWS[option])}
        <td className="file-list__td-options" />
      </tr>
    );

    return (
      <Fragment>
        {connectDropTarget(connectDragSource(row))}
        {hasExtractionFieldInstanceDetails && (
          <tr>
            <td />
            <td colSpan={7}>
              <div className="file-list__record-data-container">
                {extractionFieldInstanceDetails
                  ?.reduce((groupedRecords, currentRecordData) => {
                    // group tags of the same record
                    let existingIndex = -1;
                    if (
                      (existingIndex = groupedRecords.findIndex(
                        ({ documentExtractionFieldInstanceId }) =>
                          documentExtractionFieldInstanceId === currentRecordData.documentExtractionFieldInstanceId
                      )) === -1
                    ) {
                      groupedRecords.push({
                        ...currentRecordData,
                        tags: currentRecordData.tagName ? [currentRecordData.tagName] : []
                      });
                    } else if (currentRecordData.tagName) {
                      groupedRecords[existingIndex].tags.push(currentRecordData.tagName);
                    }

                    return groupedRecords;
                  }, [])
                  .map(recordData => (
                    <FileListRecordItem
                      projectId={projectId}
                      recordData={recordData}
                      key={recordData.documentExtractionFieldInstanceId}
                    />
                  ))}
              </div>
            </td>
            <td />
          </tr>
        )}
      </Fragment>
    );
  }
}

// Connect the row component to react dnd as both a drag source and drop target
export default DropTarget(
  'file-list-row',
  {
    drop(props, monitor, component) {
      const distanceTravelled = monitor.getDifferenceFromInitialOffset();
      const hasPastThresholdDistance = Math.abs(distanceTravelled ? distanceTravelled.y : 0) > 8;

      if (hasPastThresholdDistance) {
        props.moveItems(props.projectItem);
      }
    }
  },
  (connect, monitor) => {
    return {
      isDraggingOver: monitor.isOver({ shallow: true }),
      connectDropTarget: connect.dropTarget()
    };
  }
)(
  DragSource(
    'file-list-row',
    {
      beginDrag(props, monitor) {
        props.itemDragStart(monitor);
        return {
          projectItem: props.projectItem
        };
      },
      canDrag: props => {
        return Permissions.Project.Document.canMove() && props.isDraggingValidItem;
      },
      endDrag(props) {
        props.itemDragEnd();
      }
    },
    (connect, monitor) => {
      return {
        connectDragSource: connect.dragSource()
      };
    }
  )(enhanceWithClickOutside(FileListRow))
);
