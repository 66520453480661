import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentRegion } from 'store/api';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getTemplates} from 'store/api';

class ProjectStatus extends Component {
  static propTypes = {
    clear: PropTypes.func,
    getData: PropTypes.func,
    getDocumentResults: PropTypes.func,
    isLoading: PropTypes.bool,
    onSelectCategory: PropTypes.func,
    projectId: PropTypes.any,
    projectStatus: PropTypes.any,
    intl: PropTypes.any
  };

  componentDidMount = () => {
    const { projectId, getData } = this.props;
    getTemplates({ projectId }).then(response => {
      const data = response.data;
      if (response.data.length > 0 && getData && projectId)
      { 
        getData(projectId);
      }
    });
  };

  componentDidUpdate() {
    var { data, getData, projectId } = this.props;
    getTemplates({ projectId }).then(response => {
    if (response.data.length > 0 && data && !data.isLoading && !data.isLoaded && !data.error && data.error !== null) {
      getData(projectId);
    }
  });
  }

  componentWillUnmount() {
    this.props.clear();
  }

  onChartKeyClick = (e, chartKey) => {
    e.preventDefault();
    const { projectId, getDocumentResults, onSelectCategory } = this.props;
    onSelectCategory({ key: chartKey.apiKey, data: chartKey.apiValue });
    getDocumentResults({
      projectId,
      pageNum: 1,
      [chartKey.apiKey]: chartKey.apiValue
    });
  };

  renderChartKeys = (chartKey, index) => {
    const { projectId } = this.props;
    return (
      <li key={index} className="project-status-tile__keys-item">
        {chartKey.apiKey ? (
          <button
            onClick={e => this.onChartKeyClick(e, chartKey)}
            className={`project-status-tile__keys-link project-status-tile__keys-link--${chartKey.key}`}
          >
            <FormattedMessage id={`project-status-status.chart-keys.${chartKey.key}`} />
          </button>
        ) : (
          <Link
            to={`/region/${getCurrentRegion()}/project/${projectId}/folder/root`}
            className={`project-status-tile__keys-link project-status-tile__keys-link--${chartKey.key}`}
          >
            <FormattedMessage id={`project-status-status.chart-keys.${chartKey.key}`} />
          </Link>
        )}
        <span className="project-status-tile__keys-count">{chartKey.count}</span>
      </li>
    );
  };

  renderChartRows = (statusType, index) => {
    const oppositeType = this.props.projectStatus.filter(({ key }) => key === `not-${statusType.key}`)[0];
    if (!oppositeType) return null;
    const hasStatusCount = statusType.count > 0;
    const hasOppositeStatusCount = oppositeType.count > 0;
    const isRowEmpty = statusType.totalCount === 0 || (!hasStatusCount && !hasOppositeStatusCount);
    const { intl } = this.props;
    return (
      <div
        key={index}
        className={`project-status-tile__chart-item ${
          !isRowEmpty ? `project-status-tile__chart-item--${statusType.key}` : ''
        }`}
      >
        <span
          title={intl.formatMessage({ id: `project-status-status.chart-keys.${statusType.key}` })}
          className="project-status-tile__chart-item__key"
        >
          <FormattedMessage id={`project-status-status.chart-keys.${statusType.key}`} />
        </span>
        {!isRowEmpty && hasStatusCount ? (
          <span
            className={`project-status-tile__chart-item__value project-status-tile__chart-item__value--${
              statusType.key
            }`}
            style={{ width: `${(statusType.count / statusType.totalCount) * 100}%` }}
          >
            {statusType.count}
          </span>
        ) : null}
        {!isRowEmpty && hasOppositeStatusCount ? (
          <span
            className={`project-status-tile__chart-item__value project-status-tile__chart-item__value--${
              oppositeType.key
            }`}
            style={{ width: `${(oppositeType.count / oppositeType.totalCount) * 100}%` }}
          >
            {oppositeType.count}
          </span>
        ) : null}
      </div>
    );
  };

  render = () => {
    const { projectId, projectStatus, isLoading } = this.props;

    if (!projectId || !projectStatus) return null;

    const chartRows = projectStatus.filter(({ isChartRow }) => isChartRow);
    const totalDocumentsCount = projectStatus[0] ? projectStatus[0].totalCount : 0;

    return (
      <div className={`project-status-tile review-status-tile ${isLoading ? 'loading' : ''}`}>
        <h2 className="project-status-tile__title">
          <FormattedMessage id="project-status-status.title" />
        </h2>
        <div className="tile-row">
          <div className="project-status-tile__chart">
            {chartRows.map(this.renderChartRows)}
            <span className="project-status-tile__chart-middle-line" />
            <span className="project-status-tile__chart-num-key project-status-tile__chart-num-key--min">0</span>
            <span className="project-status-tile__chart-num-key project-status-tile__chart-num-key--avg">
              {Math.ceil(totalDocumentsCount / 2)}
            </span>
            <span className="project-status-tile__chart-num-key project-status-tile__chart-num-key--max">
              {totalDocumentsCount}
            </span>
          </div>
          <ul className="project-status-tile__keys">{projectStatus.map(this.renderChartKeys)}</ul>
        </div>
      </div>
    );
  };
}

export default injectIntl(ProjectStatus);
