import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCK, TranslationType } from '../constants';
import LanguageSelector from '../shared/language-selector/language-selector';
import TranslationDisclaimer from '../shared/translations-disclaimer/translations-disclaimer';
import InputText from './input-text';
import TargetText from './target-text';
import Icon from 'components/shared/icon';
import store from 'store/store';
import { addNotification } from 'store/notifications.js';
import { FormattedMessage } from 'react-intl';

class TranslateText extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    loadTranslationLanguagePairs: PropTypes.func.isRequired,
    translateText: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired
  };

  state = {
    sourceLanguage: JSON.parse(localStorage.getItem(`preferredSourceLanguage`)) || null,
    targetLanguage: JSON.parse(localStorage.getItem(`preferredTargetLanguage`)) || null,
    text: '',
    translatedText: '',
    haveAcceptedTerms: 'unchecked',
    canTranslate: false,
    translationType: '',
  };

  checkFormValidity = () => {
    const { sourceLanguage, targetLanguage, haveAcceptedTerms } = this.state;
    const validLanguagePair = sourceLanguage !== null && targetLanguage !== null;
    const validTermsAndConditions = haveAcceptedTerms !== 'unchecked';

    return validLanguagePair && validTermsAndConditions;
  };

  setSourceLanguage = sourceLanguage => {
    this.setState({ sourceLanguage: sourceLanguage, targetLanguage: null, haveAcceptedTerms: 'unchecked' }, () =>
      this.setState({ canTranslate: this.checkFormValidity() })
    );
  };

  setTargetLanguage = targetLanguage => {
    this.setState({ targetLanguage: targetLanguage, haveAcceptedTerms: 'unchecked' }, () =>
      this.setState({ canTranslate: this.checkFormValidity() })
    );
  };

  setTranslationType = type => {
    this.setState({ translationType: type }, () =>
      this.setState({ canTranslate: this.checkFormValidity() })
    );
  };

  onTermsAcceptance = value => {
    this.setState({ haveAcceptedTerms: value }, () => this.setState({ canTranslate: this.checkFormValidity() }));
  };

  setText = value => {
    this.setState({ text: value, translatedText: '' });
  };

  onTranslate = () => {
    const { translateText } = this.props;
    const { text, sourceLanguage, targetLanguage, translationType } = this.state;
    const payload = {
      sourceLanguageId: sourceLanguage.sourceLanguage.id,
      targetLanguageId: targetLanguage.id,
      translationType : translationType === ''? TranslationType[1] : translationType,
      text: text
    };
    return translateText(payload)
      .then(data => {
        this.setState({ translatedText: data.payload, haveAcceptedTerms: 'unchecked' }, () =>
          this.setState({ canTranslate: this.checkFormValidity() })
        );
        localStorage.setItem('preferredSourceLanguage', JSON.stringify(sourceLanguage));
        localStorage.setItem('preferredTargetLanguage', JSON.stringify(targetLanguage));
      })
      .catch(() =>
        store.dispatch(
          addNotification({
            message: <FormattedMessage id="translation.api-error" />,
            clearOnPageChange: true,
            autohide: 5
          })
        )
      );
  };

  componentDidMount() {
    const { loadTranslationLanguagePairs } = this.props;
    loadTranslationLanguagePairs();
  }

  render() {
    const { translations, currentUser } = this.props;
    const { sourceLanguage, targetLanguage, haveAcceptedTerms, canTranslate, translatedText, translationType } = this.state;

    const BLOCK_TEXT = `${BLOCK}-text`;
    const disclaimerKeys = [
      'document-translation-disclaimer.first-parragraph',
      'document-translation-disclaimer.second-parragraph',
      'document-translation-disclaimer.third-parragraph',
      'document-translation-disclaimer.fourth-parragraph'
    ];
    return (
      <div className={`${BLOCK_TEXT}`}>
        {translations.isLanguagePairsLoading ? (
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        ) : (
          <>
            <LanguageSelector
              translations={translations}
              setSourceLanguage={this.setSourceLanguage}
              setTargetLanguage={this.setTargetLanguage}
              setTranslationType={this.setTranslationType}
              sourceLanguage={sourceLanguage}
              targetLanguage={targetLanguage}
              translationType={translationType}
            />
            <div className={`${BLOCK_TEXT}__text-areas`}>
              <InputText block={BLOCK_TEXT} setText={this.setText} />
              <TargetText block={BLOCK_TEXT} text={translatedText} />
            </div>
            <TranslationDisclaimer
              disclaimerKeys={disclaimerKeys}
              onTranslate={this.onTranslate}
              haveAcceptedTerms={haveAcceptedTerms}
              onTermsAcceptance={this.onTermsAcceptance}
              canTranslate={canTranslate}
              currentUser={currentUser}
              disclaimerFor="text"
            />
          </>
        )}
      </div>
    );
  }
}

export default TranslateText;
