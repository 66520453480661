import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  getCurrentRegion,
  getOrganizationCodes,
  getEnabledOrganizationCodes,
  enableOrganizationCode,
  disableOrganizationCode
} from 'store/api';

import Checkbox from 'components/shared/form/checkbox';
import Header from 'containers/header/header';
import NotificationsContainer from 'containers/shared/notifications-container';

import Permissions from 'permissions/permissions';
import history from 'utils/history';

const getCurrentRegionData = () => {
  const codes = getCurrentRegion().split('_');
  return {
    geoCode: codes[0],
    memberFirmCode: codes[1],
    containerCode: codes[2]
  };
};

const getIdFromOrganizationCode = code => `${code.memberFirmCode}_${code.containerCode}_${code.organizationCode}`;

class UserActivatonSettingsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    currentUser : PropTypes.object
  };

  state = {
    organizationCodes: [],
    enabledOrganizationCodes: new Set(),
    selectedOrganizationCodes: new Set()
  };

  componentDidMount() {
    if (
      !Permissions.Global.User.canViewUserManagementLink() ||
      !Permissions.Global.User.canViewUserManagementPageRegion()
    ) {
      history.push('/');
    }

    this.getOrganizationCodes();
    this.getEnabledOrganizationCodes();
  }

  getOrganizationCodes = () => {
    const { memberFirmCode } = getCurrentRegionData();
    return getOrganizationCodes(memberFirmCode).then(response => {
      this.setState({
        organizationCodes: response
      });
    });
  };

  getEnabledOrganizationCodes = () => {
    const { memberFirmCode } = getCurrentRegionData();
    return getEnabledOrganizationCodes(memberFirmCode).then(response => {
      this.setState({
        enabledOrganizationCodes: new Set(response.map(getIdFromOrganizationCode)),
        selectedOrganizationCodes: new Set(response.map(getIdFromOrganizationCode))
      });
    });
  };

  onSelectAll = () => {
    const { organizationCodes, selectedOrganizationCodes } = this.state;
    if (selectedOrganizationCodes.size) {
      this.setState({ selectedOrganizationCodes: new Set() });
    } else {
      this.setState({
        selectedOrganizationCodes: new Set(organizationCodes.map(getIdFromOrganizationCode))
      });
    }
  };

  onSelect = code => {
    const { selectedOrganizationCodes } = this.state;
    const id = getIdFromOrganizationCode(code);

    if (selectedOrganizationCodes.has(id)) {
      selectedOrganizationCodes.delete(id);
    } else {
      selectedOrganizationCodes.add(id);
    }

    this.setState({ selectedOrganizationCodes });
  };

  onApply = () => {
    const {
      history: { push }
    } = this.props;
    const { organizationCodes, enabledOrganizationCodes, selectedOrganizationCodes } = this.state;
    const idsToRemove = new Set([...enabledOrganizationCodes].filter(x => !selectedOrganizationCodes.has(x)));
    const idsToAdd = new Set([...selectedOrganizationCodes].filter(x => !enabledOrganizationCodes.has(x)));

    const codesToRemove = organizationCodes.filter(code => idsToRemove.has(getIdFromOrganizationCode(code)));
    const codesToAdd = organizationCodes.filter(code => idsToAdd.has(getIdFromOrganizationCode(code)));

    const promises = [...codesToRemove.map(disableOrganizationCode), codesToAdd.map(enableOrganizationCode)];

    return Promise.all(promises).then(() => {
      push(`/region/${getCurrentRegion()}/user-management`);
    });
  };

  render() {
    const { organizationCodes, selectedOrganizationCodes } = this.state;

    const {currentUser} = this.props;
    
    const allSelectedState =
      selectedOrganizationCodes.size === 0
        ? 'unchecked'
        : organizationCodes.length === selectedOrganizationCodes.size
          ? 'checked'
          : 'indeterminate';

    return (
      <div className="page activation-settings">
        <Header {...this.props} fromUserActivationPage currentPage="global.subheader.admin-dashboard" getOrganizationCodes={this.getOrganizationCodes}
        getEnabledOrganizationCodes={this.getEnabledOrganizationCodes}/>
        <div className="activation-settings__header">
          <h1 className="activation-settings__title">
            <FormattedMessage id="activation-settings.title" />
          </h1>

          

          <div style={{ flex: '1 1 auto' }} />
          <div className="activation-settings__user-management">
          <Link to={`/region/${getCurrentRegion()}/user-management`} className="btn btn-secondary btn-large">
            <FormattedMessage id="user-management.title" />
          </Link>
          </div>
        </div>

        <div className="activation-settings__content" ref={area => (this.area = area)}>
          <table className="activation-settings__table">
            <thead>
              <tr>
                <th>
                  <Checkbox
                    id="all-organizations-checkbox"
                    onChange={() => this.onSelectAll()}
                    checked={allSelectedState}
                  />
                </th>
                <th>
                  <FormattedMessage id="activation-settings.function" />
                </th>
              </tr>
            </thead>
            <tbody>
              {organizationCodes.map(organization => (
                <tr key={organization.organizationCode}>
                  <td>
                    <Checkbox
                      id={`organization-checkbox-${organization.organizationCode}`}
                      onChange={() => this.onSelect(organization)}
                      checked={
                        selectedOrganizationCodes.has(getIdFromOrganizationCode(organization)) ? 'checked' : 'unchecked'
                      }
                    />
                  </td>
                  <td>{organization.organizationCode}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="activation-settings__footer">
            <button onClick={this.onApply} className="activation-settings__activate-button btn btn-primary btn-medium">
              <FormattedMessage id="activation-settings.apply" />
            </button>
          </div>
        </div>

        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default withRouter(UserActivatonSettingsPage);
