import { FormattedMessage } from 'react-intl';
import { getCurrentRegion } from 'store/api';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { ITEM_STATUSES } from 'models/project-item';
import { TOOLBAR_OPTIONS } from 'store/file-browser-toolbar-options';
import CompareSidebarContainer from 'containers/file-browser/compare-sidebar-container';
import emptySearchImage from 'images/empty_state_no_result_found.svg';
import EmptyState from 'components/file-browser/file-list/empty-state';
import ExportSidebarContainer from 'containers/file-browser/export-sidebar-container';
import Icon from 'components/shared/icon';
import SearchResultsContainer from 'containers/file-browser/file-list-container';
import SearchResultsToolbarContainer from 'containers/shared/file-browser-toolbar-container';

class SearchResults extends Component {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    category: PropTypes.string,
    clearResultsData: PropTypes.func,
    currentSearchFolder: PropTypes.object,
    currentProject: PropTypes.object,
    data: PropTypes.array,
    folderId: PropTypes.any,
    getAdvancedSearchResults: PropTypes.func,
    getCurrentSearchFolder: PropTypes.func,
    getDocumentResults: PropTypes.func,
    getFolderResults: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    onAdvancedSearchChange: PropTypes.func,
    onRouteChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    onSortOrder: PropTypes.func,
    projectId: PropTypes.any,
    readOnly: PropTypes.bool,
    search: PropTypes.object,
    selected: PropTypes.any,
    selectedItems: PropTypes.object.isRequired,
    closeFunction: PropTypes.func
  };

  state = {
    pageNum: 1,
    activeToolbarOption: {},
    isFiltering: false
  };

  // When you want to get search documents only
  getNextDocuments = data => {
    const params = Object.assign(
      {},
      {
        projectId: this.props.projectId,
        [this.props.category]: this.props.selected
      },
      data
    );

    return this.props.getDocumentResults(params);
  };

  // When you want to search through folders only
  getNextFolders = data => {
    const params = Object.assign(
      {},
      {
        projectId: this.props.projectId,
        folderId: this.props.selected
      },
      data
    );
    return this.props.getFolderResults(params);
  };

  // When you want to get the contents within a corresponding folder
  getCurrentFolder = (folderId, data, forceFetch) => {
    const params = Object.assign(
      {},
      {
        projectId: this.props.projectId,
        folderId,
        forceFetch
      },
      data
    );
    return this.props.getCurrentSearchFolder(params);
  };

  // Get advanced search results
  getAdvancedResults = data => {
    const params = Object.assign(
      {},
      {
        projectId: this.props.projectId,
        payload:
          this.props.search.payload && this.props.search.payload.length > 0
            ? this.props.search.payload
            : this.props.selected
      },
      data
    );

    return this.props.getAdvancedSearchResults(params);
  };

  // Get advanced search results that queries across all categories
  getGlobalResults = data => {
    const params = Object.assign(
      {},
      {
        projectId: this.props.projectId,
        payload: this.props.search.selectedData
      },
      data
    );
    return this.props.getAdvancedSearchResults(params);
  };

  getCategoryName = () => {
    const { currentSearchFolder } = this.props;
    return (
      <span>
        <FormattedMessage
          id="search.categories.documentAll"
          values={{ total: currentSearchFolder.pagination && currentSearchFolder.pagination.totalRecordCount }}
        />
      </span>
    );
  };

  onNameClick = (projectItemId, itemTypeId, extractionFieldInstanceDetails) => {
    if (itemTypeId === 1) {
      //Folder
      return this.getCurrentFolder(projectItemId, this.state.pageNum, true);
    } else {
      //File
      var pageQuery =
        extractionFieldInstanceDetails && extractionFieldInstanceDetails.length > 0
          ? '?page=' + extractionFieldInstanceDetails[0].pageNumber
          : '';

      this.props.onRouteChange();
      this.props.history.push({
        pathname: `/region/${getCurrentRegion()}/project/${this.props.projectId}/document/${projectItemId}`,
        search: pageQuery
      });
    }
  };

  onSearchExit = () => {
    this.props.onSearchChange('');
    this.props.onAdvancedSearchChange({ filter: [], payload: [] });
  };

  setActiveToolbarOption = option => {
    this.setState({
      activeToolbarOption: option || {}
    });
  };

  clearActiveToolbarOption = () => {
    this.setActiveToolbarOption(null);
  };

  onSubmitCreateComparison = () => {
    const { history, projectId } = this.props;
    this.clearActiveToolbarOption();
    history.push(`/region/${getCurrentRegion()}/project/${projectId}/compare`);
  };

  onSubmitAddToComparison = selectedComparisonId => {
    const { addNotification, projectId } = this.props;

    this.clearActiveToolbarOption();
    addNotification({
      message: (
        <FormattedMessage
          id="compare.document-added-notification"
          values={{
            link: (
              <Link
                to={`/region/${getCurrentRegion()}/project/${projectId}/compare${
                  selectedComparisonId ? `/${selectedComparisonId}` : ''
                }`}
              >
                <FormattedMessage id="compare.comparison-created-notification.link-text" />
              </Link>
            )
          }}
        />
      ),
      clearOnPageChange: true,
      autohide: 10
    });
  };

  onPageChange = pageNum => {
    this.refreshData({ pageNum });
  };

  // When a user selects a different page from search results or invalidates data
  refreshData = payload => {
    const {
      category,
      selected,
      currentSearchFolder: { projectItemId },
      search
    } = this.props;

    let data;
    let page = {
      pageNum: payload.pageNum ? payload.pageNum : this.state.pageNum
    };
    data = page;

    if ((payload.sortBy && payload.sortOrder) || (search.sortBy && search.sortOrder)) {
      data = Object.assign(
        {},
        page,
        { sortBy: payload.sortBy ? payload.sortBy : search.sortBy },
        { sortOrder: payload.sortOrder ? payload.sortOrder : search.sortOrder }
      );
    }

    if (
      category === 'documentId' ||
      category === 'extractionFieldId' ||
      category === 'templateId' ||
      category === 'documentTagId' ||
      category === 'extractionFieldInstanceTagId' ||
      category === 'documentStateIds' ||
      category === 'isReviewed' ||
      category === 'isAssigned' ||
      category === 'userId' ||
      category === 'reviewerUserIds'
    ) {
      return this.getNextDocuments(data);
    }

    if (category === 'userDocumentSearch' || category === 'analyticsSeach') {
      return this.getNextDocuments(Object.assign({}, data, selected));
    }

    if (category === 'folderIds') {
      return this.getNextFolders(data);
    }

    if (category === 'advanced') {
      return this.getAdvancedResults(data);
    }

    if (category === 'global') {
      return this.getGlobalResults(data);
    }

    return this.getCurrentFolder(projectItemId, data, true);
  };

  render() {
    const { activeToolbarOption, isFiltering } = this.state;
    const {
      currentSearchFolder: { parentHierarchy, projectItemId },
      projectId,
      currentSearchFolder,
      folderId,
      readOnly,
      selectedItems,
      closeFunction,
      currentProject
    } = this.props;

    // Loading state
    if (currentSearchFolder.isLoading) {
      return (
        <div className="search-results">
          <div className="search-results-inner">
            <div className="search-results__empty-state">
              <Icon className="spinner spinner--centered" name="loader" width={80} />
            </div>
          </div>
        </div>
      );
    }

    // Loaded, but no results state
    if (
      currentSearchFolder.isLoaded &&
      currentSearchFolder &&
      currentSearchFolder.children &&
      currentSearchFolder.children.length <= 0 &&
      !isFiltering
    ) {
      return (
        <div className="search-results">
          <div className="search-results-inner">
            <button className="close-search icon-button" onClick={closeFunction}>
              <Icon name="special-cross" width={16} />
            </button>
            <div className="search-results__empty-state">
              <EmptyState
                title={<FormattedMessage id="search-results.empty-state-title" />}
                description={<FormattedMessage id="search-results.empty-state-description" />}
                img={emptySearchImage}
              />
            </div>
          </div>
        </div>
      );
    }

    // Search results page sits as a portal in order to not be affected by any styles from document or file list
    return ReactDOM.createPortal(
      <div className={`search-results ${readOnly ? 'readOnly' : ''}`}>
        <div className="search-results-inner">
          <button className="close-search icon-button" onClick={closeFunction}>
            <Icon name="special-cross" width={16} />
          </button>
          <SearchResultsToolbarContainer
            key="search-results"
            activeToolbarOption={activeToolbarOption}
            currentFolder={currentSearchFolder}
            folderId={projectItemId ? projectItemId.toString() : folderId}
            category={this.getCategoryName()}
            invalidate={() => this.refreshData({ pageNum: this.state.pageNum })}
            isSearchResultsPage={true}
            onFilesSelected={this.onFilesSelected}
            parents={parentHierarchy}
            projectId={projectId}
            setActiveToolbarOption={this.setActiveToolbarOption}
            readOnly={currentProject.STATES.ReadOnly()}
          />
          <div className="search-results__scroll-container">
            <SearchResultsContainer
              currentFolder={currentSearchFolder}
              projectId={projectId}
              onPageChange={this.onPageChange}
              pagination={currentSearchFolder.pagination}
              invalidate={() => this.refreshData({ pageNum: this.state.pageNum })}
              isSearchResultsPage={true}
              readOnly={currentProject.STATES.ReadOnly()}
              onNameClick={this.onNameClick}
              loadSearchResults={options => {
                this.props.onSortOrder(options);

                this.refreshData(options);
              }}
              loadCurrentFolder={() => {}}
              isFiltering={value => this.setState({ isFiltering: value })}
            />
            {activeToolbarOption === TOOLBAR_OPTIONS.EXPORT && (
              <ExportSidebarContainer
                onCloseClick={this.clearActiveToolbarOption}
                onSubmit={this.clearActiveToolbarOption}
                projectId={projectId}
                currentFolder={currentSearchFolder}
              />
            )}
            {activeToolbarOption === TOOLBAR_OPTIONS.COMPARE && (
              <CompareSidebarContainer
                selectedItems={currentSearchFolder.children.filter(
                  projectItem =>
                    selectedItems[projectItem.projectItemId] && projectItem.itemStateId === ITEM_STATUSES.PROCESSED
                )}
                onCloseClick={this.clearActiveToolbarOption}
                onSubmitAddToComparison={this.onSubmitAddToComparison}
                onSubmitCreateComparison={this.onSubmitCreateComparison}
                projectId={projectId}
              />
            )}
          </div>
        </div>
      </div>,
      document.getElementById('searching-root')
    );
  }
}

export default SearchResults;
