import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import EmptyState from 'components/file-browser/file-list/empty-state';
import Header from 'containers/header/header';
import Footer from 'containers/footer/footer';
import emptyAccessDeniedImage from 'images/empty_state_access_denied.svg';

class GenericError extends Component {
  link = <a href="mailto:argusauditssupport@deloitte.com">argusauditssupport@deloitte.com</a>;

  static propTypes = {
    descriptionMessageId: PropTypes.string,
    titleMessageId: PropTypes.string
  };

  static defaultProps = {
    descriptionMessageId: 'error-page.generic.description',
    titleMessageId: 'error-page.generic.title'
  };

  render() {
    const { descriptionMessageId, titleMessageId } = this.props;

    return (
      <div className="page">
        <Header currentPage="global.subheader.landing-page"/>
        <EmptyState
          title={<FormattedMessage id={titleMessageId} />}
          description={
            <FormattedMessage
              id={descriptionMessageId}
              values={{
                link: this.link
              }}
            />
          }
          img={emptyAccessDeniedImage}
        />
        <Footer />
      </div>
    );
  }
}

export default GenericError;
