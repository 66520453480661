import { connect } from 'react-redux';

import { loadLanguageData } from 'store/intl/languageData';
import { setLanguage } from 'store/intl/currentLanguage';
import LanguageSelect from 'components/header/account-dropdown/language-select/language-select';

const mapStateToProps = ({ data: { languages, currentLanguage } }) => ({
  languages,
  currentLanguage
});
 
const mapDispatchToProps = {
  loadLanguageData,
  setLanguage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelect);

