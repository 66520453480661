import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';
import 'moment/min/locales';

import CONSTANTS from 'utils/constants';
import PropTypes from 'prop-types';
import { deleteTranslation } from 'store/api';

import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Button from 'components/shared/single-click-button';
import DocumentDownload from 'components/shared/document-download';
import GenericDeleteModal from 'components/shared/delete-modal/delete-modal';
import ReactTooltip from 'react-tooltip';

class TranslationQueueTable extends Component {
  static propTypes = {
    block: PropTypes.string.isRequired,
    currentLanguage: PropTypes.object,
    documentStatus: PropTypes.object,
    loadTranslationListLocal: PropTypes.func.isRequired,
    loadTranslationsList: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired
  };

  state = {
    showDeleteModal: false,
    translationToDelete: null,
    loadingDocument: false
  };

  getTranslationStatus = translationStaus => {
    const status = Object.keys(CONSTANTS.TranslationStatus).find(
      key => CONSTANTS.TranslationStatus[key] === translationStaus
    );

    return translationStaus ? status : 'NoStatus';
  };

  getExportFileName = (fileName, targetLanguage, fileExtension) => {
    const timestamp = moment.utc().format('YYYY-MM-DDTHH_mm_ss');
    return `${fileName}__${targetLanguage}__${timestamp}${fileExtension}`;
  };

  handleDeleteSubmit = () => {
    const { translationToDelete } = this.state;
    const { translations, loadTranslationListLocal } = this.props;
    deleteTranslation(
      translationToDelete.userTranslationDetailId,
      translationToDelete.memberFirmCode,
      translationToDelete.containerCode
    ).then(data => {
      if (data) {
        loadTranslationListLocal(
          translations.translations.filter(
            translation => translation.userTranslationDetailId !== translationToDelete.userTranslationDetailId
          )
        );
        this.setState({ showDeleteModal: false, translationToDelete: null });
      }
    });
  };

  handleDeleteClick = translationToDelete => {
    this.setState({ showDeleteModal: true, translationToDelete: translationToDelete });
  };

  handleDeleteCancel = () => {
    this.setState({ showDeleteModal: false, translationToDelete: null });
  };

  getErrorText = (errorCode) =>{
    if(errorCode === '10003')
      return 'IsProtected';
    else if(errorCode === '10002' || errorCode === '10001')
    return 'TimedOut';
  }

  render() {
    const { block, currentLanguage, translations, documentStatus } = this.props;
    const { loadingDocument, showDeleteModal } = this.state;
    const tableHeaders = ['document', 'language', 'submitted', 'status', 'action'];
    moment.locale(currentLanguage.localeCode);
    return (
      <div className={`${block}__table-container`}>
        {showDeleteModal && (
          <Modal>
            <GenericDeleteModal
              singleMessages={true}
              onCancel={this.handleDeleteCancel}
              onSubmit={this.handleDeleteSubmit}
            />
          </Modal>
        )}

        {translations.translations.length > 1 && translations.translations.filter(x => x.translationStateId == 5).length >= 1 ?  (
            <DocumentDownload
              linkUrl={'content/translations/getAllDoucmentsBinary'}
              className="translations__download-all"
              linkBody={
                <>                 
                  <div className=" translations__download-all">
                    <Icon className="translations__download-Icon" name="download-arrow" width={18} />
                    <FormattedMessage id="translation-download-all" />
                  </div>
                </>
              }
              target="_self"
              documentFilename={'Translated documents'}
            />
          ): null}

        <table className={`${block}__table`}>
          <thead>
            <tr>
              {tableHeaders.map((heading, index) => {
                return (
                  <th className={`${block}__table__header`} key={index}>
                    <FormattedMessage id={`translation-queue.table-heading.${heading}`} />
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {translations.translations.map((translation, index) => {
              const translationStatusId =
                documentStatus[translation.userTranslationDetailId]?.statusId || translation.translationStateId;
              const errorCode = documentStatus[translation.userTranslationDetailId]?.errorCode || translation.errorCode;
              const translationStatus = this.getTranslationStatus(translationStatusId);
              const icon =
                loadingDocument === translation.userTranslationDetailId ? (
                  <Icon className={`${block}__table__download-icon spinner`} name="loader" width={25} />
                ) : (
                  <Icon className={`${block}__table__download-icon`} name="download-arrow" width={18} />
                );

              return (
                <tr key={index}>
                  {translationStatusId === 5 ? (
                    <td className={`${block}__table__file-name ${block}__table__file-name--download`}>
                      <DocumentDownload
                        className={`${block}__table__download-link ${block}__table__download-link--active`}
                        linkUrl={`/content/translations/${translation.userTranslationDetailId}/binary`}
                        apiCallHeaders={{
                          ...(translation.containerCode && { 'X-CNTR-CTX': translation.containerCode }),
                          'X-LANG-CTX': currentLanguage.localeCode,
                          ...(translation.memberFirmCode && { 'X-MF-CTX': translation.memberFirmCode })
                        }}
                        linkBody={
                          <>
                            {icon}
                            <span>{translation.sourceFileName}</span>
                          </>
                        }
                        disable={loadingDocument === translation.userTranslationDetailId}
                        target="_self"
                        title={translation.sourceFileName}
                        documentFilename={this.getExportFileName(
                          translation.sourceFileName,
                          translation.targetLanguageName,
                          translation.sourceFileExtension
                        )}
                        documentLoading={() => this.setState({ loadingDocument: translation.userTranslationDetailId })}
                        documentLoaded={() => this.setState({ loadingDocument: false })}
                      />
                    </td>
                  ) : (
                    <td className={`${block}__table__file-name`}>
                      <span title={translation.sourceFileName} className={`${block}__table__download-link`}>
                        {translation.sourceFileName}
                      </span>
                    </td>
                  )}
                  <td>
                    <span>
                      {translation.sourceLanguageName}
                      <Icon className={`${block}__table__language-icon`} name="arrow-right" width={28} />
                      {translation.targetLanguageName}{` (`}
                      {translation.translationType && <FormattedMessage id= {`translation.language-selector-${translation.translationType.trim()}`}/>}
                      {`)`}
                    </span>
                  </td>
                  <td>
                    <span>{moment.utc(translation.submittedDate).fromNow()}</span>
                  </td>
                  {(translationStatusId === 7) && (errorCode === "10003" || errorCode === "10002" || errorCode === "10001" )?
                    (<td>
                      <div>
                      <div
                        className={`${block}__table__status-icon ${block}__table__status-icon--${translationStatus}`}
                      />
                      <FormattedMessage id={`translation-queue.status.${translationStatus}`} />
                      <span data-tip data-for={`translation-queue.status.${errorCode}`}>
                        &nbsp;
                        <Icon name="Warning-Argyle" width={24} className="translations-queue__warningIcon" />
                        <ReactTooltip id={`translation-queue.status.${errorCode}`} effect="solid" place="left" >
                          <FormattedMessage id={`translation-queue.status.errorCode.${this.getErrorText(errorCode)}`} />
                        </ReactTooltip>
                      </span>
                      </div>
                    </td>)
                    : (<td>
                      <div
                        className={`${block}__table__status-icon ${block}__table__status-icon--${translationStatus}`}
                      />
                      <FormattedMessage id={`translation-queue.status.${translationStatus}`} />
                    </td>)
                  }
                  <td>
                    <Button
                      onClick={() => this.handleDeleteClick(translation)}
                      size="icon"
                      className={`${block}__table__delete critical-icon`}
                    >
                      <Icon name="bin" width={25} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TranslationQueueTable;
