import React from 'react';

import { createAction, createReducer } from 'utils/redux-utils';
import socket, { rooms } from 'utils/socket';

import { Project } from 'models/project';
import { FormattedMessage } from 'react-intl';
import history from 'utils/history';
import * as api from 'store/api';

export const ACTIONS = {
  CURRENT_PROJECT_LOADING: 'argus/ui/CURRENT_PROJECT_LOADING',
  CURRENT_PROJECT_LOADED: 'argus/ui/CURRENT_PROJECT_LOADED',
  CURRENT_PROJECT_ERROR: 'argus/ui/CURRENT_PROJECT_ERROR',
  CURRENT_PROJECT_UPDATED: 'argus/ui/CURRENT_PROJECT_UPDATED',

  CREATE_CURRENT_PROJECT_LOADING: 'argus/ui/CREATE_CURRENT_PROJECT_LOADING',
  CREATE_CURRENT_PROJECT_LOADED: 'argus/ui/CREATE_CURRENT_PROJECT_LOADED',
  UPDATE_CURRENT_PROJECT_NAME: 'argus/ui/UPDATE_CURRENT_PROJECT_NAME',
  UPDATE_CURRENT_PROJECT_ENGAGEMENT: 'argus/ui/UPDATE_CURRENT_PROJECT_ENGAGEMENT',
  UPDATE_CURRENT_PROJECT_STATE: 'argus/ui/UPDATE_CURRENT_PROJECT_STATE',
  UPDATE_CURRENT_PROJECT_UPDATE_ROLE: 'argus/ui/UPDATE_CURRENT_PROJECT_UPDATE_ROLE',

  CLEAR_PROJECT_DATA: 'argus/ui/CLEAR_PROJECT_DATA',
  NOTIFICATION_ADD: 'argus/ui/NOTIFICATION_ADD',
  // Enable support team access
  TOGGLE_SUPPORT_ACCESS: 'argus/ui/TOGGLE_SUPPORT_ACCESS'
};

export const currentProjectLoading = createAction(ACTIONS.CURRENT_PROJECT_LOADING);
export const currentProjectLoaded = createAction(ACTIONS.CURRENT_PROJECT_LOADED);
export const currentProjectError = createAction(ACTIONS.CURRENT_PROJECT_ERROR);
export const currentProjectUpdated = createAction(ACTIONS.CURRENT_PROJECT_UPDATED);

export const currentProjectCreateLoading = createAction(ACTIONS.CREATE_CURRENT_PROJECT_LOADING);
export const currentProjectCreateLoaded = createAction(ACTIONS.CREATE_CURRENT_PROJECT_LOADED);

export const currentProjectUpdateName = createAction(ACTIONS.UPDATE_CURRENT_PROJECT_NAME);
export const currentProjectUpdateEngagement = createAction(ACTIONS.UPDATE_CURRENT_PROJECT_ENGAGEMENT);
export const currentProjectUpdateState = createAction(ACTIONS.UPDATE_CURRENT_PROJECT_STATE);
export const currentProjectUpdateRole = createAction(ACTIONS.UPDATE_CURRENT_PROJECT_UPDATE_ROLE);
export const currentProjectClear = createAction(ACTIONS.CLEAR_PROJECT_DATA);

export const addNotification = createAction(ACTIONS.NOTIFICATION_ADD);
export const projectToggleAccess = createAction(ACTIONS.TOGGLE_SUPPORT_ACCESS);

export const createProjectTrack = data => dispatch => {
  dispatch(currentProjectCreateLoaded(data));
};

export const getCurrentProject = ({ projectId }) => (dispatch, getState) => {
  if (!projectId) return;

  dispatch(currentProjectLoading());

  unsubscribe();
  dispatch(subscribe(projectId));
  
  return api
    .getProject({ projectId })
    .then(response => {
      localStorage.setItem("isDateFormatSelected",response.data.isDateFormatSelected);
      localStorage.setItem('userSelectedDateFormat', response.data.dateFormatPreference);
      //if by mistake dateformat was selected as true but preference was still null then below case will help to display the popup
      if(response.data.dateFormatPreference==""|| response.data.dateFormatPreference==null) {
        localStorage.setItem("isDateFormatSelected",false) ;
      }
      // response.data.myRole.roleId = 4;
      dispatch(currentProjectLoaded(response.data));
      dispatch(subscribe(projectId));
    })
    .catch(error => {
      if (error.response.status === 404 && error.response.data.messages[0].code === "Unauthorized" ) {
        history.push('/');
      }
      else if (error.response.status === 404) {
        const message = <FormattedMessage id="global.error.Project.Not.Found" />;
        dispatch(addNotification({ message, clearOnPageChange: false, autohide: 10 }));
        history.push('/');
      }

      dispatch(currentProjectError(error));
      unsubscribe();
    });
};

export const refreshCurrentProject = ({ projectId }) => (dispatch, getState) => {
  return api
    .getProject({ projectId })
    .then(response => {
      dispatch(currentProjectLoaded(response.data));
    })
    .catch(error => {
      if (error.response.status === 404) {
        const message = <FormattedMessage id="global.error.Project.Not.Found" />;
        dispatch(addNotification({ message, clearOnPageChange: false, autohide: 10 }));
        history.push('/');
      }

      dispatch(currentProjectError(error));
      unsubscribe();
    });
};

export const updateProjectName = data => dispatch => {
  return api.updateProjectName(data).then(res => {
    dispatch(currentProjectUpdateName(data));
  });
};

export const updateEngagementManually = data => dispatch => {
  return api.updateEngagementManually(data);
};

export const updateProjectEngagementManually = data => dispatch => {
  return api.updateProjectEngagementManually(data);
};

export const updateProjectEngagement = data => dispatch => {
  return api.updateProjectEngagement(data).then(res => {
    dispatch(currentProjectUpdateEngagement(data));
  });
};

export const updateProjectState = data => dispatch => {
  return api.updateProjectState(data).then(res => {
    dispatch(currentProjectUpdateState(data));
  });
};

export const projectUserRoleChanged = data => (dispatch, getState) => {
  const currentProject = getState().data.currentProject;
  const currentUser = getState().data.currentUser;

  if (!currentProject.isLoaded || currentProject.projectId !== data.projectId) {
    return;
  }

  dispatch(
    currentProjectUpdateRole({
      data,
      updateMyRole: currentUser.userId === data.userId
    })
  );
};

// Enabling support team access
export const toggleSupportAccess = data => dispatch => {
  return api.enableSupportAccess(data).then(res => {
    dispatch(projectToggleAccess({ projectId: data.projectId, allowAccess: data.allowAccess }));
    return res;
  });
};

export const clearProjectData = () => dispatch => {
  unsubscribe();
  dispatch(currentProjectClear());
};

/*
 * Reducer
 */
export const INITIAL_STATE = new Project();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.CURRENT_PROJECT_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.CURRENT_PROJECT_LOADED]: (state, action) => {
    return state.setLoaded(action.payload);
  },

  [ACTIONS.CREATE_CURRENT_PROJECT_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.CREATE_CURRENT_PROJECT_LOADED]: (state, action) => {
    return state.setCreated(state, action);
  },

  [ACTIONS.CURRENT_PROJECT_ERROR]: (state, action) => {
    return state.setError(action.payload);
  },

  [ACTIONS.CURRENT_PROJECT_UPDATED]: (state, action) => {
    return state.socketUpdate(action.payload);
  },

  [ACTIONS.UPDATE_CURRENT_PROJECT_NAME]: (state, action) => {
    return state.setUpdatedName(state, action);
  },

  [ACTIONS.UPDATE_CURRENT_PROJECT_ENGAGEMENT]: (state, action) => {
    return state.setUpdatedEngagement(state, action);
  },

  [ACTIONS.UPDATE_CURRENT_PROJECT_STATE]: (state, action) => {
    return state.setUpdatedState(state, action);
  },

  [ACTIONS.UPDATE_CURRENT_PROJECT_UPDATE_ROLE]: (state, action) => {
    return state.setUpdatedRole(state, action);
  },

  [ACTIONS.CLEAR_PROJECT_DATA]: (state, action) => {
    return INITIAL_STATE;
  },
  [ACTIONS.TOGGLE_SUPPORT_ACCESS]: (state, action) => {
    return state.toggleSupportAccess(state, action);
  }
});

/*
 * SOCKETS
 */
const { subscribe, unsubscribe } = (socket => {
  let projectRoom = null;
  let onProjectUpdated = null;
  let onProjectUserRoleChanged = null;
  let onProjectDeleted = null;

  const subscribe = projectId => dispatch => {
    if (!projectId) {
      return;
    }

    const nextProjectRoom = rooms.project(projectId);

    if (projectRoom !== nextProjectRoom) {
      socket.leave(projectRoom);
      projectRoom = nextProjectRoom;
      socket.join(projectRoom);
    }

    if (!onProjectUpdated) {
      onProjectUpdated = response => {
        if (response.room === projectRoom) {
          dispatch(refreshCurrentProject({ projectId: response.data.project.projectId }));
        }
      };
      socket.on('update', onProjectUpdated);
    }

    if (!onProjectUserRoleChanged) {
      onProjectUserRoleChanged = event => {
        dispatch(projectUserRoleChanged(event.data));
      };
      socket.on('project.user.role.updated', onProjectUserRoleChanged);
    }

    if (!onProjectDeleted) {
      onProjectDeleted = event => {
        const message = <FormattedMessage id="global.error.Project.Not.Found" />;
        dispatch(addNotification({ message, clearOnPageChange: false, autohide: 10 }));
        history.push('/');
      };
      socket.on('project.deleted', onProjectDeleted);
    }
  };

  const unsubscribe = () => {
    socket.leave(projectRoom);
    projectRoom = null;

    if (onProjectUpdated) {
      socket.off('update', onProjectUpdated);
      onProjectUpdated = null;
    }

    if (onProjectUserRoleChanged) {
      socket.off('project.user.role.updated', onProjectUserRoleChanged);
      onProjectUserRoleChanged = null;
    }

    if (onProjectDeleted) {
      socket.off('project.deleted', onProjectDeleted);
      onProjectDeleted = null;
    }
  };

  return {
    subscribe,
    unsubscribe
  };
})(socket);
