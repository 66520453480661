import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';
import LoadingOverlay from 'components/shared/loading/light';
import { Link } from 'react-router-dom';

import enhanceWithClickOutside from 'react-click-outside';
import { getCurrentRegion, getFolderSummary } from 'store/api';

import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';

const mapStateToProps = ({ data: { currentUser } }) => ({
  currentUser
});

class NavDropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    itemName: PropTypes.any,
    onTextChange: PropTypes.func,
    pages: PropTypes.any,
    text: PropTypes.string
  };

  state = {
    expanded: false
  };

  handleClickOutside() {
    this.setState({ expanded: false });
  }
  render() {
    const { expanded } = this.state;
    const { itemName, disabled, pages, isLoading, text, onTextChange } = this.props;
    const truncate = (itemName) =>
    itemName?.length > 30 ? `${itemName.substring(0, 23)}...` : itemName;

    return (
      <div className="document-navigation-dropdown breadcrumb" title={truncate(itemName)}>
        <Button
          className="inner"
          size="icon"
          disabled={disabled}
          onClick={() => this.setState({ expanded: !expanded })}
        >
          {truncate(itemName)}
          {disabled ? null : (
            <Icon className={expanded ? 'rotate' : ''} name="special-arrow-down" width={10} height={10} />
          )}
        </Button>

        {!disabled && expanded ? (
          <div className="navigation-dropdown">
            <div className="search-controls">
              <FormattedMessage id="document-viewer.toolbar.dropdown-title" />
              <FormattedMessage id="document-viewer.toolbar.dropdown-placeholder">
                {placeholder => (
                  <input
                    type="text"
                    placeholder={placeholder}
                    onChange={e => onTextChange(e.target.value)}
                    value={truncate(text)}
                  />
                )}
              </FormattedMessage>
            </div>

            <div className="document-navigation-search-results">
              {pages}
              {!isLoading ? null : <LoadingOverlay text={false} diameter={40} />}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export const NavigationDropdown = enhanceWithClickOutside(NavDropdown);

class DocumentNavDropdown extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    disabled: PropTypes.bool,
    documentData: PropTypes.any
  };

  state = {
    documents: [],
    filterText: ''
  };

  componentDidMount() {
    this.setUpPages();
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.documentData.documentId !== prevProps.documentData.documentId ||
      this.props.documentData.reviewers !== prevProps.documentData.reviewers
    ) {
      this.setUpPages();
    }
  };

  setUpPages() {
    var { projectId } = this.props.documentData;
    var folderId = this.getCurrentFolder();

    getFolderSummary({ projectId, folderId }).then(docs => {
      this.setState({
        documents: docs
      });
    });
  }

  getCurrentFolder() {
    var { parentHierarchy } = this.props.documentData;
    var folderId;

    if (parentHierarchy && parentHierarchy.length) {
      var i = 0;
      do {
        if (parentHierarchy[i].itemTypeId === 1) folderId = parentHierarchy[i].projectItemId;
        i++;
      } while (!folderId && i < parentHierarchy.length);
    }

    return folderId ? folderId : 'root';
  }

  getPageData = () => {
    var { documents, filterText } = this.state;
    var { documentData, currentUser } = this.props;
    var { documentId, projectId } = documentData;

    return (
      <div className="result-page page-0">
        <div className="result-row-wrapper">
          {documents
            .filter(doc => doc.itemName.toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
            .map((doc, j) => {
              var isReviewed = doc.reviewerIds && doc.reviewerIds.length > 0;
              var isSelfReviewed = doc.reviewerIds && doc.reviewerIds.some(id => id === currentUser.userId);
              var isCurrentDoc = doc.projectItemId === documentId;

              return (
                <Link
                  key={j}
                  className={`result-row ${isCurrentDoc ? 'current-document' : ''}`}
                  to={
                    isCurrentDoc
                      ? `/region/${getCurrentRegion()}/project/${projectId}/document/${documentId}`
                      : `/region/${getCurrentRegion()}/project/${projectId}/document/${doc.projectItemId}`
                  }
                >
                  <span title={doc.itemName}>{doc.itemName}</span>

                  <div className={`reviewers ${isSelfReviewed ? 'self' : ''}`}>
                    {isReviewed > 0 ? (
                      <Icon
                        name={isSelfReviewed ? 'special-checked-green' : 'special-checked-black'}
                        width={16}
                        height={16}
                      />
                    ) : null}
                    {isReviewed ? doc.reviewerIds && doc.reviewerIds.length : null}
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    );
  };

  render() {
    return (
      <NavigationDropdown
        disabled={this.props.disabled}
        isLoading={this.state.loadingPage}
        itemName={this.props.documentData.itemName}
        onTextChange={value => this.setState({ filterText: value })}
        pages={this.getPageData()}
        text={this.state.filterText}
      />
    );
  }
}

export default connect(mapStateToProps, null)(DocumentNavDropdown);
