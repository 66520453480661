import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import CompareContainer from 'containers/compare/compare-container';
import NotificationsContainer from 'containers/shared/notifications-container';

import Permissions from 'permissions/permissions';
import history from 'utils/history';

class ComparePage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    if (!Permissions.Project.Comparison.canView()) {
      history.push('/');
    }
  }

  render() {
    const { compareId, documentId } = this.props.match.params;

    return (
      <div className="page">
        <CompareContainer compareId={compareId} documentId={documentId} {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default ComparePage;
