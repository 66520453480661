import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'components/shared/single-click-button';
import { getExports, deleteExport } from 'store/api';

import Progress from 'components/shared/export/export-sidebar-progress';
import socket, { rooms } from 'utils/socket';

export default class ExportSidebar extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    exportType: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired
  };

  state = {
    isLoading: false,
    exports: []
  };

  componentDidMount() {
    const { currentUser, projectId } = this.props;

    this.getExports();

    // Join the exports room on the socket connection
    if (currentUser.isLoaded) {
      socket.join(rooms.exports(projectId, currentUser.userId));
    }
    // Subscribe to export updates from the socket connection
    socket.on('export.update', this.onExportUpdate);
  }

  componentDidUpdate(prevProps) {
    // If the current user changes, join a new room on the socket connection
    if (this.props.currentUser.isLoaded) {
      const prevRoom = rooms.exports(prevProps.projectId, prevProps.currentUser.userId);
      const currRoom = rooms.exports(this.props.projectId, this.props.currentUser.userId);
      if (prevRoom !== currRoom) {
        socket.leave(prevRoom);
        socket.join(currRoom);
      }
    }
  }

  componentWillUnmount() {
    const { currentUser, projectId } = this.props;
    socket.off('export.update', this.onExportUpdate);
    socket.leave(rooms.exports(projectId, currentUser.userId));
  }

  deleteExport = exportId => {
    const { projectId } = this.props;
    // Optimistically delete
    this.setState({
      exports: this.state.exports.filter(item => item.exportDetailID !== exportId)
    });
    deleteExport({ projectId, exportId })
      // Re fetch on error
      .catch(this.getExports);
  };

  getExports = () => {
    const { exportType, projectId } = this.props;
    this.setState({ isLoading: true });
    getExports({ projectId, exportType: exportType })
      .then(result => {
        this.setState({
          exports: result.data,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  onExportUpdate = event => {
    const { exportDetailId, exportStateId } = event.data;

    if (!exportDetailId || !exportStateId) {
      return;
    }

    const updatedExports = this.state.exports.map(item => {
      if (item.exportDetailID === exportDetailId) {
        item.exportStateId = exportStateId;
      }
      return item;
    });

    this.setState({
      exports: updatedExports
    });
  };

  render() {
    const { onCloseClick, projectId } = this.props;
    const { exports, isLoading } = this.state;

    return (
      <div className="export-sidebar">
        <div className="export-sidebar__header">
          <h2 className="export-sidebar__title">
            <FormattedMessage id="export-sidebar.title" />
          </h2>
          <Button size="icon" onClick={onCloseClick} className="export-sidebar__close-button icon-button">
            <Icon name="special-cross" width={14} />
          </Button>
        </div>
        <div className="export-sidebar__main">
          {isLoading ? (
            <Icon className="spinner spinner--centered" name="loader" width={80} />
          ) : (
            <Progress
              projectId={projectId}
              exports={exports}
              deleteExport={this.deleteExport}
            />
          )}
        </div>
      </div>
    );
  }
}
