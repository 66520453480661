import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { debounce } from 'underscore';
import SearchInputField from 'containers/search/search-inputfield-container';

/** User management page search bar */
class UserSearch extends Component {
  static propTypes = {
    inputDisabled: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onSearchChange: PropTypes.func,
    search: PropTypes.object,
    fromUserManagementPage : PropTypes.bool,
    intl : intlShape.isRequired
  };

  componentDidMount() {
    if (this.props.search.query.length > 0) {
      this.props.onSearchChange('');
    }
  }

  onKeyUp = debounce(() => {
    this.onEnter();
  }, 500);

  onEnter = () => {
    // Search param options
    this.props.onEnter();
  };

  render() {
    const { location, inputDisabled, onExit ,fromUserManagementPage,intl} = this.props;

    return (
      <SearchInputField
        inputDisabled={inputDisabled}
        location={location}
        onEnter={this.onEnter}
        onExit={onExit}
        onKeyUp={this.onKeyUp}
        fromUserManagementPage = {fromUserManagementPage}
        intl = {intl}
      />
    );
  }
}

export default injectIntl(UserSearch);
