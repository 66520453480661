import React from 'react';
import PropTypes from 'prop-types';
import CustomScrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import './_scrollbars.scss';

/**
 * @group Other
 * Custom scrollbars used in Omnia
 * */

const Scrollbars = ({ children, className, id, showScrollbarWithoutHover }) => (
  <CustomScrollbars
    hideTracksWhenNotNeeded
    className={classNames('customScrollbarContainer', className)}
    id={id}
    renderThumbVertical={(props) => (
      <div
        {...props}
        className={showScrollbarWithoutHover ? "thumbVerticalHelpmenu" :"thumbVertical"}
      />
    )}
    renderThumbHorizontal={(props) => (
      <div
        {...props}
        className="thumbHorizontal"
      />
    )}
  >
    {children}
  </CustomScrollbars>
);

Scrollbars.defaultProps = {
  children: null,
  className: '',
};

Scrollbars.propTypes = {
  /** Nodes that are transpiled into the Scrollbars */
  children: PropTypes.node,
  /** A custom className for the scrollable area */
  className: PropTypes.string,
};

export default Scrollbars;