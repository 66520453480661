import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

import ProjectDocumentExtractionField from 'permissions/sections/project-document-extraction-field';

const ProjectDocumentPermissions = {
  ExtractionField: ProjectDocumentExtractionField,

  canUpload: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canDelete: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canModify: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canMove: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canTag: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canUntag: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canAssign: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canSetTemplate: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canReview: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canHighlight: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canCompare: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canReprocess: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canView: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canExport: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  }
};

export default ProjectDocumentPermissions;
