import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Icon from 'components/shared/icon';
import { getCurrentRegion } from 'store/api';
import { Link } from 'react-router-dom';

export function Breadcrumbs({ parentProject, firstFolder, extraFolders, document, currentDocumentName }) {
  return (
    <div className="breadcrumbs">
      {parentProject ? (
        <div className="breadcrumb project-name">
          <div className="inner">{parentProject}</div>
        </div>
      ) : null}

      {firstFolder ? (
        <Fragment>
          <div className="breadcrumb breadcrumb-spacer">
            <Icon name="special-arrow-right" width={10} height={10} />
          </div>
          <div className="breadcrumb first-folder">
            <div className="inner">{firstFolder}</div>
          </div>
        </Fragment>
      ) : null}
      {extraFolders ? (
        <Fragment>
          <div className="breadcrumb breadcrumb-spacer">
            <Icon name="special-arrow-right" width={10} height={10} />
            <span className="tooltip-wrapper">{extraFolders}</span>
            <span className="elipses">{'...'}</span>
          </div>
        </Fragment>
      ) : null}
      {document ? (
        <Fragment>
          <div className="breadcrumb breadcrumb-spacer">
            <Icon name="special-arrow-right" width={10} height={10} />
          </div>

          {document}
        </Fragment>
      ) : null}
    </div>
  );
}

Breadcrumbs.propTypes = {
  currentDocumentName: PropTypes.string,
  document: PropTypes.node,
  extraFolders: PropTypes.node,
  firstFolder: PropTypes.node,
  parentProject: PropTypes.node
};

export class DocumentBreadcrumbs extends Component {
  static propTypes = {
    documentData: PropTypes.object,
    dropdown: PropTypes.node,
    projectId: PropTypes.any
  };

  getFormattedBreadcrumbs = () => {
    var { parentHierarchy } = this.props.documentData;
    var breadcrumbs = {
      extraFolders: []
    };

    for (var i = parentHierarchy.length - 1; i >= 0; i--) {
      var currentItem = {
        name: parentHierarchy[i].itemName,
        id: parentHierarchy[i].projectItemId,
        type: parentHierarchy[i].itemTypeId
      };

      if (currentItem.type === 2) {
        breadcrumbs.doc = currentItem;
      } else if (currentItem.type === 1 && breadcrumbs.firstFolder === undefined) {
        breadcrumbs.firstFolder = currentItem;
      } else if (currentItem.type === 1 && breadcrumbs.firstFolder !== undefined) {
        breadcrumbs.extraFolders.push(currentItem);
      } else if (currentItem.type === 0 && breadcrumbs.project === undefined) {
        breadcrumbs.project = currentItem;
      }
    }

    return breadcrumbs;
  };

  render() {
    var { currentDocumentName, dropdown, projectId } = this.props;
    var data = this.getFormattedBreadcrumbs();
    var region = getCurrentRegion();

    return (
      <Breadcrumbs
        parentProject={
          data.project && (
            <Link to={`/region/${region}/project/${projectId}/folder/root`} title={data.project.name}>
              {data.project.name}
            </Link>
          )
        }
        firstFolder={
          data.firstFolder && (
            <Link
              to={`/region/${region}/project/${projectId}/folder/${data.firstFolder.id}`}
              title={data.firstFolder.name}
            >
              {data.firstFolder.name}
            </Link>
          )
        }
        extraFolders={
          data.extraFolders.length !== 0 && (
            <div className="tooltip">
              {data.extraFolders.map((folder, i) => (
                <Link key={i} to={`/region/${region}/project/${projectId}/folder/${folder.id}`} className="row">
                  {folder.name}
                </Link>
              ))}
            </div>
          )
        }
        document={data.doc && dropdown}
        currentDocumentName={currentDocumentName}
      />
    );
  }
}

export default DocumentBreadcrumbs;
