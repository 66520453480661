import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';

/* Reusable search input field component across pages */
// Uses search redux component; connects to the store in search
class SearchInputField extends Component {
  static propTypes = {
    inputDisabled: PropTypes.bool,
    location: PropTypes.object,
    onBlur: PropTypes.func,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onKeyUp: PropTypes.func,
    onSearchChange: PropTypes.func,
    search: PropTypes.object,
    fromLandingPage : PropTypes.bool,
    fromProjectListPage : PropTypes.bool,
    fromUserManagementPage : PropTypes.bool,
    fromQuickStudyPage : PropTypes.bool,
    intl : PropTypes.any
  };

  // Perform enter search
  onKeyPress = e => {
    const { onEnter, search } = this.props;
    if (e.key === 'Enter') {
      return search.query.length > 0 ? onEnter : {};
    }
  };

  onChange = value => {
    const { onSearchChange, onExit } = this.props;
    onSearchChange(value);
    if (value.length <= 0) {
      onExit();
    }
  };

  render() {
    const { search, location, onSearchChange, onKeyUp, onEnter, inputDisabled, onExit, 
      onBlur,fromProjectListPage ,fromQuickStudyPage,fromUserManagementPage,intl} = this.props;
    const disabled =
      location.pathname.includes('creation') ||
      location.pathname.includes('copy') ||
      location.pathname.includes('visuals');



    return (
      <div className={classNames('search-bar-wrapper', (fromProjectListPage ||fromQuickStudyPage ||fromUserManagementPage) && 'search-bar-wrapper--admin')}>
        {(fromProjectListPage ||fromQuickStudyPage ||fromUserManagementPage) &&
        <div className="search-bar__button-wrapper">
           <Button
            size="icon"
            className="search-bar__input-search--admin"
            disabled={search.query.length <= 0}
            onFocus={() => onEnter()}
          >
            <Icon name="special-search-gray" width={13} />
          </Button>
          </div>
        }
        <div className="search-bar__input-field">
          <input
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            name="search"
            id="search"
            className="search-bar__input"
            disabled={disabled || inputDisabled}
            value={search.query}
            onChange={e => this.onChange(e.target.value)}
            onKeyUp={onKeyUp}
            onKeyPress={this.onKeyPress}
            onBlur={onBlur}
            placeholder = {fromProjectListPage ? intl?.formatMessage({ id: 'search-Bar.placeholder.project' }) : (fromQuickStudyPage ? intl?.formatMessage({ id: 'search-Bar.placeholder.extraction-field' }) : (fromUserManagementPage ? intl?.formatMessage({ id: 'search-Bar.placeholder.user' }):null ))}
          />
          {search.query.length > 0 ? (
            <Button
              size="icon"
              className="search-bar__input-cancel icon-button"
              onClick={() => {
                onSearchChange('');
                onExit();
              }}
            >
              <Icon name="special-cross-black" width={10} />
            </Button>
          ) : null}
        </div>
        {!fromProjectListPage && !fromQuickStudyPage && !fromUserManagementPage &&
        <div className="search-bar__button-wrapper">
          <Button
            size="icon"
            className="search-bar__input-search"
            disabled={search.query.length <= 0}
            onFocus={() => onEnter()}
          >
            <Icon name="special-search-gray" width={13} />
          </Button>
        </div>
        }
      </div>
    );
  }
}

export default SearchInputField;
