var helper = {
  //colors used for text/range selection
  select: {
    color: 'rgba(150,210,255,0.5)',
    bookends: 'rgba(150,210,255,1)'
  },

  //colors used for extraction fields
  highlight_colors: {
    0: 'rgba(160,220,255,0.45)',
    1: 'rgba(0,163,224,0.45)',
    2: 'rgba(0,118,168,0.45)',
    3: 'rgba(0,85,165,0.45)',
    4: 'rgba(1,35,105,0.45)',
    5: 'rgba(4,30,66,0.45)',
    6: 'rgba(0,79,89,0.45)',
    7: 'rgba(0,118,128,0.45)',
    8: 'rgba(0,151,169,0.45)',
    9: 'rgba(0,171,171,0.45)',
    10: 'rgba(111,194,180,0.45)',
    11: 'rgba(4,106,56,0.45)',
    12: 'rgba(0,154,68,0.45)',
    13: 'rgba(67,176,42,0.45)',
    14: 'rgba(134,188,37,0.45)',
    15: 'rgba(227,6,19,0.45)',
    16: 'rgba(160,220,255,0.30)',
    17: 'rgba(0,163,224,0.30)',
    18: 'rgba(0,118,168,0.30)',
    19: 'rgba(0,85,165,0.30)',
    20: 'rgba(1,35,105,0.30)',
    21: 'rgba(4,30,66,0.30)',
    22: 'rgba(0,79,89,0.30)',
    23: 'rgba(0,118,128,0.30)',
    24: 'rgba(0,151,169,0.30)',
    25: 'rgba(0,171,171,0.30)',
    26: 'rgba(111,194,180,0.30)',
    27: 'rgba(4,106,56,0.30)',
    28: 'rgba(0,154,68,0.30)',
    29: 'rgba(67,176,42,0.30)',
    30: 'rgba(134,188,37,0.30)',
    31: 'rgba(227,6,19,0.30)',
    32: 'rgba(160,220,255,0.20)',
    33: 'rgba(0,163,224,0.20)',
    34: 'rgba(0,118,168,0.20)',
    35: 'rgba(0,85,165,0.20)',
    36: 'rgba(1,35,105,0.20)',
    37: 'rgba(4,30,66,0.20)',
    38: 'rgba(0,79,89,0.20)',
    39: 'rgba(0,118,128,0.20)',
    40: 'rgba(0,151,169,0.20)',
    41: 'rgba(0,171,171,0.20)',
    42: 'rgba(111,194,180,0.20)',
    43: 'rgba(4,106,56,0.20)',
    44: 'rgba(0,154,68,0.20)',
    45: 'rgba(67,176,42,0.20)',
    46: 'rgba(134,188,37,0.20)',
    47: 'rgba(227,6,19,0.20)',
    search_inactive: 'rgba(255,255,0,0.6)',
    search_active: 'rgba(255,104,0,0.6)'
  }
};

module.exports = helper;
