import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCK } from '../constants';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import TranslationQueueTable from './translation-queue-table';
import socket, { rooms } from 'utils/socket';
import DocumentDownload from 'components/shared/document-download';
import moment from 'moment';
import 'moment/min/locales';

class TranslationQueue extends Component {
  static propTypes = {
    currentLanguage: PropTypes.object,
    currentUser: PropTypes.object.isRequired,
    loadTranslationListLocal: PropTypes.func.isRequired,
    loadTranslationsList: PropTypes.func.isRequired,
    translations: PropTypes.object
  };

  state = {
    documentStatus: {}
  };

  componentDidMount() {
    const {
      loadTranslationsList,
      currentUser: { userId }
    } = this.props;
    loadTranslationsList();

    socket.on('content.translation.state.update', this.onStatusChange);
    socket.join(rooms.translationStatus(userId));
  }

  onStatusChange = payload => {
    const { documentStatus } = this.state;
    const { translations, loadTranslationListLocal } = this.props;

    if (translations.translations.length > 0) {
      const document = translations.translations.find(
        translation => translation.userTranslationDetailId === payload.data.contentTranslationId
      );
      if (document !== undefined) {
        this.setState({
          documentStatus: {
            ...documentStatus,
            [payload.data.contentTranslationId]: {
              statusId: payload.data.contentTranslationState,
              errorCode: payload.data.errorCode
            }
          }
        },() => {this.forceUpdate();});
      
        // loadTranslationListLocal(
        //   translations.translations.map(translation =>
        //     translation.userTranslationDetailId === payload.data.contentTranslationId
        //       ? { ...translation, translationStateId: payload.data.contentTranslationState }
        //       : translation
        //   )
        // );
      }
    }    
  };

  componentWillUnmount() {
    const {
      currentUser: { userId }
    } = this.props;

    socket.leave(rooms.translationStatus(userId));
  }
  getExportFileName = (fileName, targetLanguage, fileExtension) => {
    const timestamp = moment.utc().format('YYYY-MM-DDTHH_mm_ss');
    return `${fileName}__${targetLanguage}__${timestamp}${fileExtension}`;
  };

  render() {
    const { documentStatus } = this.state;
    const { translations } = this.props;

    const icon = <Icon className={`${BLOCK}__table__download-icon`} name="download-arrow" width={30} />;

     const BLOCK_QUEUE = `${BLOCK}-queue`;
    return (
      <div className={`${BLOCK_QUEUE}`}>
        <div>
          <div>
            <h2 className={`${BLOCK_QUEUE}__title`}>
              <FormattedMessage id="translation-queue.title" />
            </h2>
            <span className={`${BLOCK_QUEUE}__subtitle`}>
              <FormattedMessage id="translation-queue.subtitle" />
            </span>
          </div>
        </div>
        <div className={`${BLOCK_QUEUE}__list`}>
          {translations.isTranslationsLoading ? (
            <Icon className="spinner spinner--centered" name="loader" width={100} />
          ) : translations.translations.length > 0 ? (
            <TranslationQueueTable {...this.props} documentStatus={documentStatus} block={BLOCK_QUEUE} />
          ) : (
            <span className={`${BLOCK_QUEUE}__no-documents`}>
              <FormattedMessage id="translation-queue.no-documents" />
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default TranslationQueue;
