import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Icon from 'components/shared/icon';
import ItemList from 'components/file-browser/file-list/item-list';
import AssignPopover from 'components/file-browser/toolbar/assign-popover';

/*
 The assign cell of the file list table

 Handles showing and hiding the add/remove assign popover
*/
class AssignCell extends Component {
  static propTypes = {
    assignments: PropTypes.array,
    expand: PropTypes.func,
    intl: intlShape,
    invalidate: PropTypes.func,
    isExpanded: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    projectItemId: PropTypes.number,
    readOnly: PropTypes.bool
  };

  state = {
    isHovering: false,
    isEditing: false
  };

  onEditClick = () => {
    this.setState({ isEditing: true });
  };

  onPopoverClose = () => {
    this.setState({ isHovering: false, isEditing: false });
  };

  onMouseEnter = () => {
    this.setState({
      isHovering: true
    });
  };

  onMouseLeave = () => {
    this.setState({
      isHovering: false
    });
  };

  renderName = assignment => {
    return assignment.user.preferredFullName;
  };

  render() {
    const { expand, isExpanded, projectItemId, projectId, assignments, readOnly, invalidate } = this.props;
    const { isHovering, isEditing } = this.state;

    return readOnly ? (
      <div className="file-list__editable-cell">
        <ItemList
          items={assignments.map(assignment => this.renderName(assignment))}
          name={`assignments-${projectItemId}`}
          isExpanded={isExpanded}
          expand={expand}
        />
      </div>
    ) : (
      <div className="file-list__editable-cell" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {(!isHovering && !isEditing) || assignments.length ? (
          <ItemList
            items={assignments.map(assignment => this.renderName(assignment))}
            name={`assignments-${projectItemId}`}
            isExpanded={isExpanded}
            expand={expand}
          />
        ) : null}
        <div className="file-list__edit-icon-container" data-ignore-mouse-down>
          {(isHovering || isEditing) && (
            <div className="file-list__edit-icon" onClick={this.onEditClick}>
              <Icon name={assignments.length ? 'special-edit' : 'special-add'} width={24} />
            </div>
          )}
          {isEditing && (
            <AssignPopover
              invalidate={invalidate}
              onClose={this.onPopoverClose}
              projectId={projectId}
              selectedItems={{ [projectItemId]: true }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(AssignCell);
