import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Header from 'containers/header/header';
import NotificationsContainer from 'containers/shared/notifications-container';
import AdminDashboardContainer from 'containers/admin-dashboard/admin-dashboard-container';

import { getHomeRegion, setCurrentRegion } from 'store/api';
import Permissions from 'permissions/permissions';
import history from 'utils/history';

class AdminAccessPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setCurrentRegion(getHomeRegion());
  }

  componentDidMount() {
    if (!Permissions.Global.User.canviewAdminDashboard() || !Permissions.Global.canViewAdminAccess()) {
      history.push('/unauthorized');
    }
  }

  render() {
    return (
      <div className="page">
        <Header {...this.props} fromAdminPage currentPage="global.subheader.admin-dashboard"/>
        <AdminDashboardContainer showAdminAccessWidget={true} {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default AdminAccessPage;
