import moment from 'moment';
import PQueue from 'utils/p-queue/p-queue';
import { CancelToken } from 'axios';

import { createReducer } from 'utils/redux-utils';
import { currentFolderInvalidate } from 'store/current-folder';
import { LOCATION_CHANGE } from 'connected-react-router';
import { Upload, UPLOAD_STATES } from 'models/uploads';
import * as api from 'store/api';
import { ERROR_CODES, hasError } from 'utils/errors';

const requestQueue = new PQueue({ concurrency: 5 });

export const ACTIONS = {
  UPLOAD_START: 'argus/ui/UPLOAD_START',
  UPLOAD_PROGRESS: 'argus/ui/UPLOAD_PROGRESS',
  UPLOAD_SUCCESS: 'argus/ui/UPLOAD_SUCCESS',
  UPLOAD_FAIL: 'argus/ui/UPLOAD_FAIL',
  UPLOAD_CANCEL: 'argus/ui/UPLOAD_CANCEL',
  UPLOAD_REMOVE: 'argus/ui/UPLOAD_REMOVE'
};

/*
 * This is a cache for storing references to the cancellable of each upload request
 * This allows for the upload to be cancelled at a later time
 */
const uploadCancellables = {};

const fileExtensionValidationRegex = /\.(zip|doc|docx|dotx|pdf|bmp|jpg|jpeg|png|tif|tiff|txt)$/i;

const validateFile = file => {
  const isInvalidExtension = !fileExtensionValidationRegex.test(file.name);
  const isFileTooLarge = file.size >= 1024 ** 3;

  if (isInvalidExtension) {
    return {
      errorCode: ERROR_CODES.FILE_FORMAT_NOT_SUPPORTED,
      isValid: false
    };
  }

  if (isFileTooLarge) {
    return {
      errorCode: ERROR_CODES.MAX_SIZE_EXCEEDED,
      isValid: false
    };
  }

  return { isValid: true, errorCode: null };
};

export const cancelUpload = ({ id }) => (dispatch, getState) => {
  const upload = getState().data.uploads[id];

  if (upload.state === UPLOAD_STATES.PENDING || upload.state === UPLOAD_STATES.UPLOADING) {
    // If the upload is in progress, cancel it
    uploadCancellables[id].cancel();
  } else {
    // If the upload had already failed, completed, or been canceled, remove it
    dispatch({ type: ACTIONS.UPLOAD_REMOVE, payload: { id } });
  }
};

export const upload = ({ projectId, parentId, name, path, file, projectTemplateId }) => (dispatch, getState) => {
  const timestamp = moment().format();
  const id = `${path}-${timestamp}`;

  const onSuccess = (data) => {
    delete uploadCancellables[id];

    
    //Mark the Docuement as Infected
    if(data.isInfected) {
      var errorCode = "Document.Infected"
      if(data.infectedDocumentList && data.infectedDocumentList.length >= 1) {
        dispatch({ type: ACTIONS.UPLOAD_SUCCESS, payload: { id, projectId, folderId: parentId } });
        dispatch(currentFolderInvalidate());   
        data.infectedDocumentList.forEach(file => {
          var id = `${file}-${timestamp}`;
          name = file
          dispatch({
            type: ACTIONS.UPLOAD_START,
            payload: {
              id,
              timestamp,
              name
            }
          });
          dispatch({ type: ACTIONS.UPLOAD_FAIL, payload: { id, errorCode } });
        })
      }
      else {
        dispatch({ type: ACTIONS.UPLOAD_FAIL, payload: { id, errorCode } });
      }
    }
    else {
      // Set the upload state to success
      dispatch({ type: ACTIONS.UPLOAD_SUCCESS, payload: { id, projectId, folderId: parentId } });

      // Mark the current folder as invalidated
      dispatch(currentFolderInvalidate());   
    } 

      // Remove the upload after a timeout
      setTimeout(() => {
        dispatch({ type: ACTIONS.UPLOAD_REMOVE, payload: { id } });
      }, 15000);
  };

  const onFail = error => {
    delete uploadCancellables[id];

    const hasErrorCode = [ERROR_CODES.MAX_SIZE_EXCEEDED, ERROR_CODES.FILE_FORMAT_NOT_SUPPORTED].some(errorCode => {
      if (hasError(error, errorCode)) {
        dispatch({ type: ACTIONS.UPLOAD_FAIL, payload: { id, errorCode } });
        return true;
      }

      return false;
    });

    if (!hasErrorCode) {
      dispatch({ type: ACTIONS.UPLOAD_FAIL, payload: { id } });
    }
  };

  const onCancel = () => {
    delete uploadCancellables[id];

    // Set the upload state to cancelled
    dispatch({ type: ACTIONS.UPLOAD_CANCEL, payload: { id } });

    // Remove the upload after a timeout
    setTimeout(() => {
      dispatch({ type: ACTIONS.UPLOAD_REMOVE, payload: { id } });
    }, 10000);
  };

  const onProgress = ({ loaded, total }) => {
    window.dispatchEvent(new Event('argus.upload.progress'));
    dispatch({ type: ACTIONS.UPLOAD_PROGRESS, payload: { id, loaded, total } });
  };

  // Add the pending upload to the state
  dispatch({
    type: ACTIONS.UPLOAD_START,
    payload: {
      id,
      timestamp,
      name
    }
  });

  const { isValid, errorCode } = validateFile(file);

  if (isValid) {
    var cancellable = CancelToken.source();
    uploadCancellables[id] = cancellable;

    // Send the upload request
    requestQueue.add(() =>
      api.upload({
        cancelToken: cancellable.token,
        file,
        onCancel,
        onFail,
        onProgress,
        onSuccess,
        parentId,
        path,
        projectId,
        projectTemplateId
      })
    );
  } else {
    // The file is not valid, so set the upload to failed
    dispatch({ type: ACTIONS.UPLOAD_FAIL, payload: { id, errorCode } });
  }
};

/*
 * Reducer
 */
export const INITIAL_STATE = {};

export default createReducer(INITIAL_STATE, {
  [ACTIONS.UPLOAD_START]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: Upload.fromData(action.payload)
    };
  },

  [ACTIONS.UPLOAD_PROGRESS]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: state[action.payload.id].setProgress(action.payload)
    };
  },

  [ACTIONS.UPLOAD_SUCCESS]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: state[action.payload.id].setCompleted()
    };
  },

  [ACTIONS.UPLOAD_FAIL]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: state[action.payload.id].setFailed(action.payload.errorCode)
    };
  },

  [ACTIONS.UPLOAD_CANCEL]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: state[action.payload.id].setCancelled()
    };
  },

  [ACTIONS.UPLOAD_REMOVE]: (state, action) => {
    const nextState = {
      ...state
    };

    delete nextState[action.payload.id];

    return nextState;
  },

  [LOCATION_CHANGE]: (state, action) => {
    const nextState = {
      ...state
    };
    Object.entries(nextState).forEach(([key, value]) => {
      if (value.state === UPLOAD_STATES.FAILED || value.state === UPLOAD_STATES.CANCELLED) {
        delete nextState[key];
      }
    });

    return nextState;
  }
});
