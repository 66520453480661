import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';

class UserProgress extends Component {
  static propTypes = {
    clear: PropTypes.func,
    data: PropTypes.any,
    getData: PropTypes.func,
    projectId: PropTypes.any,
    getDocumentResults: PropTypes.func,
    onSelectCategory: PropTypes.func
  };

  state = {
    pageSize: 10,
    page: 0,
    sortDirection: true
  };

  componentDidMount() {
    var { getData, projectId } = this.props;
    if (getData) getData(projectId);
  }

  componentDidUpdate() {
    var { data, getData, projectId } = this.props;
    if (data && !data.isLoading && !data.isLoaded && !data.error && data.error !== null) {
      getData(projectId);
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  getInitials(userName) {
    var tokens = userName.split(' ');
    return tokens && tokens.length >= 2 ? tokens[1].substring(0, 1) + tokens[0].substring(0, 1) : '';
  }

  changeSortDirection = currentDirection => {
    this.setState({ sortDirection: !currentDirection });
  };

  sortFunction = (a, b) => {
    var { sortDirection } = this.state;
    return a.userName === b.userName ? 0 : a.userName < b.userName ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1;
  };

  //Click functions

  onAssignedClick = user => {
    if (user.assignedDocumentCount === 0) return;

    var { projectId, getDocumentResults, onSelectCategory } = this.props;

    onSelectCategory({
      key: 'userDocumentSearch',
      data: {
        assigneeUserId: user.userID,
        isAssigned: true
      }
    });

    getDocumentResults({
      projectId,
      pageNum: 1,
      assigneeUserId: user.userID,
      isAssigned: true
    });
  };

  onAssignedAndReviewedClick = user => {
    if (user.assignedAndReviewedDocumentCount === 0) return;

    var { projectId, getDocumentResults, onSelectCategory } = this.props;

    onSelectCategory({
      key: 'userDocumentSearch',
      data: {
        assigneeUserId: user.userID,
        reviewerUserIds: user.userID
      }
    });

    getDocumentResults({
      projectId,
      pageNum: 1,
      assigneeUserId: user.userID,
      reviewerUserIds: user.userID
    });
  };

  onAssignedAndNotReviewedClick = user => {
    if (user.notReviewedDocumentCount === 0) return;

    var { projectId, getDocumentResults, onSelectCategory } = this.props;

    onSelectCategory({
      key: 'userDocumentSearch',
      data: {
        projectId,
        pageNum: 1,
        assigneeUserId: user.userID,
        isReviewed: false
      }
    });

    getDocumentResults({
      projectId,
      pageNum: 1,
      assigneeUserId: user.userID,
      isReviewed: false
    });
  };

  onReviewedClick = user => {
    if (user.reviewedDocumentCount === 0) return;

    var { projectId, getDocumentResults, onSelectCategory } = this.props;

    onSelectCategory({ key: 'reviewerUserIds', data: [user.userID] });
    getDocumentResults({
      projectId,
      pageNum: 1,
      reviewerUserIds: [user.userID]
    });
  };

  render = () => {
    var { projectId, data } = this.props;
    var { page, pageSize, sortDirection } = this.state;

    var pageStart = pageSize * page;
    var pageEnd = pageSize * page + pageSize;
    var userCount = data ? data.users.length : 0;
    var pageCount = Math.floor((userCount > 0 ? userCount - 1 : 0) / pageSize);

    if (!projectId) return null;
    return (
      <div className="user-progress-tile review-status-tile">
        <h2>
          <FormattedMessage id="review-status.user-progress.title" />
        </h2>

        <div className="user-progress-tile-body">
          <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th className="table-head-icon" width="60" />
                <th className="table-head-name" width="200">
                  <span onClick={() => this.changeSortDirection(sortDirection)}>
                    <FormattedMessage id="review-status.user-progress.table.name" /> &nbsp;
                    {sortDirection ? <span className="icon">▲</span> : <span className="icon">▼</span>}
                  </span>
                </th>
                <th className="table-head-assigned" width="80">
                  <FormattedMessage id="review-status.user-progress.table.assigned" />
                </th>
                <th className="table-head-assigned-reviewed" width="80">
                  <FormattedMessage id="review-status.user-progress.table.assigned-and-reviewed" />
                </th>
                <th className="table-head-not-reviewed" width="80">
                  <FormattedMessage id="review-status.user-progress.table.not-reviewed" />
                </th>
                <th className="table-head-progress" width="200">
                  <FormattedMessage id="review-status.user-progress.table.progress" />
                </th>
                <th className="table-head-reviewed" width="80">
                  <FormattedMessage id="review-status.user-progress.table.reviewed" />
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.users && data.users.length > 0
                ? data.users
                    .sort(this.sortFunction)
                    .slice(pageStart, pageEnd)
                    .map((user, i) => (
                      <tr key={`user-${user.userID}`} className={`user-${user.userID}`}>
                        <td className="table-body-icon">
                          <span className="initials">{this.getInitials(user.userName)}</span>
                        </td>
                        <td className="table-body-name">{user.preferredFullName}</td>
                        <td
                          className={`table-body-assigned ${
                            user.assignedDocumentCount && user.assignedDocumentCount > 0 ? 'active' : null
                          }`}
                          onClick={() => this.onAssignedClick(user)}
                        >
                          {user.assignedDocumentCount}
                        </td>
                        <td
                          className={`table-body-assigned-reviewed ${
                            user.assignedAndReviewedDocumentCount && user.assignedAndReviewedDocumentCount > 0
                              ? 'active'
                              : null
                          }`}
                          onClick={() => this.onAssignedAndReviewedClick(user)}
                        >
                          {user.assignedAndReviewedDocumentCount}
                        </td>
                        <td
                          className={`table-body-not-reviewed ${
                            user.notReviewedDocumentCount && user.notReviewedDocumentCount > 0 ? 'active' : null
                          }`}
                          onClick={() => this.onAssignedAndNotReviewedClick(user)}
                        >
                          {user.notReviewedDocumentCount}
                        </td>
                        <td className="table-body-progress">
                          {user.assignedDocumentCount > 0 ? (
                            <div>
                              {user.assignedAndReviewedDocumentCount} <FormattedMessage id="common.of" />{' '}
                              {user.assignedDocumentCount}
                              <div className="progress-bar">
                                <div
                                  className="progress-fill"
                                  style={{
                                    width: `${(user.assignedAndReviewedDocumentCount / user.assignedDocumentCount) *
                                      100}%`
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <FormattedMessage id="review-status.user-progress.table.no-documents-message" />
                            </div>
                          )}
                        </td>
                        <td
                          className={`table-body-reviewed ${
                            user.reviewedDocumentCount && user.reviewedDocumentCount > 0 ? 'active' : null
                          }`}
                          onClick={() => this.onReviewedClick(user)}
                        >
                          {user.reviewedDocumentCount}
                        </td>
                      </tr>
                    ))
                : null}
            </tbody>
          </table>

          <div className="pagination">
            <span>
              {pageStart + 1}-{pageEnd < userCount ? pageEnd : userCount} <FormattedMessage id="common.of" />{' '}
              <b>{userCount}</b>
            </span>
            <button className="previous-page" disabled={page === 0} onClick={() => this.setState({ page: page - 1 })}>
              <Icon name="special-arrow-right" width={10} height={14} rotate={180} />
            </button>
            <button
              className="next-page"
              disabled={page >= pageCount}
              onClick={() => this.setState({ page: page + 1 })}
            >
              <Icon name="special-arrow-right" width={10} height={14} />
            </button>
          </div>
        </div>
      </div>
    );
  };
}

export default UserProgress;
