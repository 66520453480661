import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from 'components/shared/icon';
import ReactTooltip from 'react-tooltip';
import emptyStateImage from 'images/empty_state_comp_01.svg';

const SORT_FUNCTIONS = {
  NAME_ASCENDING: (a, b) => (a.tagName < b.tagName ? -1 : a.tagName > b.tagName ? 1 : 0),
  NAME_DESCENDING: (a, b) => (a.tagName > b.tagName ? -1 : a.tagName < b.tagName ? 1 : 0),
  COUNT_ASCENDING: (a, b) => a.count - b.count,
  COUNT_DESCENDING: (a, b) => b.count - a.count
};

class Tile extends Component {
  static propTypes = {
    className: PropTypes.any,
    data: PropTypes.object,
    getData: PropTypes.func,
    onClick: PropTypes.func,
    projectId: PropTypes.any,
    titleKey: PropTypes.any
  };

  state = {
    page: 0,
    pageSize: 5,
    sortFunction: SORT_FUNCTIONS.NAME_ASCENDING
  };

  componentDidMount() {
    var { getData, projectId } = this.props;
    if (getData) getData(projectId);
  }

  componentDidUpdate(prevProps) {}

  onClick = id => {
    if (this.props.onClick) {
      this.props.onClick(id);
    }
  };

  setSorting = sort => {
    this.setState({ sortFunction: sort });
  };

  render = () => {
    var { className, titleKey, data, intl } = this.props;
    var { page, pageSize, sortFunction } = this.state;

    var pageStart = pageSize * page;
    var pageEnd = pageSize * page + pageSize;
    var tagCount = data ? data.tags.length : 0;
    var pageCount = Math.floor((tagCount > 0 ? tagCount - 1 : 0) / pageSize);

    return (
      <div className={`tags-tile-wrapper ${className}`}>
        <div className="tags-tile placeholder-tags">
          <h3 title={intl.formatMessage({ id: titleKey })}>
            <FormattedMessage id={titleKey} />
          </h3>
          {!data || !data.tags || tagCount === 0 ? (
            <div className="tags-tile-empty-state">
              <img alt="No tags applied" src={emptyStateImage} />
              <div className="empty-state-title">
                <FormattedMessage id="tags-page.tiles.empty-message" />
              </div>
              <div className="empty-state-body" />
            </div>
          ) : (
            <Fragment>
              <div className="tags-tile-body">
                <table cellPadding="0" cellSpacing="0" border="0">
                  <thead>
                    <tr>
                      <th className="table-head-name" width="auto">
                        <button
                          title={intl.formatMessage({ id: 'tags-page.tiles.table-header.name' })}
                          onClick={() =>
                            this.setSorting(
                              sortFunction === SORT_FUNCTIONS.NAME_ASCENDING
                                ? SORT_FUNCTIONS.NAME_DESCENDING
                                : SORT_FUNCTIONS.NAME_ASCENDING
                            )
                          }
                        >
                          <FormattedMessage id="tags-page.tiles.table-header.name" />&nbsp;
                          {sortFunction === SORT_FUNCTIONS.NAME_ASCENDING ? (
                            <span className="icon">▲</span>
                          ) : sortFunction === SORT_FUNCTIONS.NAME_DESCENDING ? (
                            <span className="icon">▼</span>
                          ) : null}
                        </button>
                      </th>
                      <th className="table-head-count" width="100">
                        <button
                          title={intl.formatMessage({ id: 'tags-page.tiles.table-header.count' })}
                          onClick={() =>
                            this.setSorting(
                              sortFunction === SORT_FUNCTIONS.COUNT_ASCENDING
                                ? SORT_FUNCTIONS.COUNT_DESCENDING
                                : SORT_FUNCTIONS.COUNT_ASCENDING
                            )
                          }
                        >
                          <FormattedMessage id="tags-page.tiles.table-header.count" />&nbsp;
                          {sortFunction === SORT_FUNCTIONS.COUNT_ASCENDING ? (
                            <span className="icon">▲</span>
                          ) : sortFunction === SORT_FUNCTIONS.COUNT_DESCENDING ? (
                            <span className="icon">▼</span>
                          ) : null}
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.tags
                      .sort(sortFunction)
                      .slice(pageStart, pageEnd)
                      .map((tag, index) => (
                        <tr key={tag.tagId + '-' + index}>
                          <td
                            className={`table-body-name ${this.props.onClick ? 'active' : ''}`}
                            data-tip
                            data-for={`tag-tooltip-${tag.tagName}-${tag.tagId}`}
                            onClick={() => {
                              this.onClick(tag.tagId);
                            }}
                          >
                            {tag.tagName}
                            <ReactTooltip
                              id={`tag-tooltip-${tag.tagName}-${tag.tagId}`}
                              className="tag-tooltip"
                              effect="solid"
                              place="bottom"
                              delayShow={1300}
                              delayHide={300}
                            >
                              {tag.tagName}
                            </ReactTooltip>
                          </td>
                          <td className="table-body-count">{tag.count}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className="pagination">
                <span>
                  <FormattedMessage
                    id="tags-page.tiles.pagination"
                    values={{ start: pageStart + 1, end: pageEnd < tagCount ? pageEnd : tagCount, max: tagCount }}
                  />
                </span>
                <button
                  className="previous-page"
                  disabled={page === 0}
                  onClick={() => this.setState({ page: page - 1 })}
                >
                  <Icon name="special-arrow-right" width={10} height={14} rotate={180} />
                </button>
                <button
                  className="next-page"
                  disabled={page >= pageCount}
                  onClick={() => this.setState({ page: page + 1 })}
                >
                  <Icon name="special-arrow-right" width={10} height={14} />
                </button>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  };
}

export default injectIntl(Tile);
