import TRACKING_ACTIONS from './actions';

// Main satellite function that updates the digital data layer and calls the DCRs
const satelliteTrack = eventType => {
  // ensure the variable is set
  if (window._satellite) {
    window._satellite.track(TRACKING_ACTIONS[eventType].dcr);
  }
};

const track = (...args) => {
  satelliteTrack(...args);
};

export default track;
