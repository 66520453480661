import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import TextArea from 'components/shared/form/textarea';

/*
* Valid Text Area
* When a user types in a non valid text
*/
export default class ValidTextArea extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    errors: PropTypes.string,
    headingId: PropTypes.string,
    isEditing: PropTypes.bool,
    isOwner: PropTypes.bool,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEditClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any
  };

  state = {
    isHovering: false
  };

  onMouseEnter = e => {
    const { isOwner } = this.props;
    if (isOwner) {
      this.setState({ isHovering: true });
    } else {
      e.preventDefault();
    }
  };

  onMouseLeave = e => {
    const { isOwner } = this.props;
    if (isOwner) {
      this.setState({ isHovering: false });
    } else {
      e.preventDefault();
    }
  };

  onEditClick = () => {
    const { onEditClick } = this.props;
    this.setState({ isHovering: false });
    onEditClick();
  };

  render() {
    const {
      autoFocus,
      className,
      errors,
      headingId,
      isEditing,
      maxLength,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyUp,
      placeholder,
      value,
      clientInfo,
      isAsteriskRequired,
    } = this.props;

    const { isHovering } = this.state;

    return (
      <div className={classNames('valid-textarea', className)}>
        <span className={classNames('valid-textarea__heading', errors ? 'invalid' : '')}>
          <FormattedMessage id={headingId} />
          { isAsteriskRequired && ( <span className="project-details__required-field">*</span>)}
        </span>
        {isEditing ? (
          <div>
            <TextArea
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              autoFocus={autoFocus}
              className={classNames('valid-textarea__input', errors ? 'invalid' : '')}
              maxLength={maxLength}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              spellCheck="false"
              value={value}
              disabled={headingId == 'project-details.body.engagement' && clientInfo == null? true : false}
              clientInfo={clientInfo}
            />
          </div>
        ) : (
          <div className="valid-textarea__uneditable" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
            <span className="valid-textarea__uneditable-text">{value}</span>
            {isHovering && (
              <Button size="icon" className="valid-textarea__uneditable-button" onClick={this.onEditClick}>
                <Icon name="special-edit" width={24} />
              </Button>
            )}
          </div>
        )}
        <span className={classNames('valid-textarea__error', errors ? 'invalid' : 'placeholder')}>
          {`${errors ? errors : 'placeholder'}`}
        </span>
      </div>
    );
  }
}
