import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import dateUtil from 'utils/dateUtil';

import Icon from 'components/shared/icon';
import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import Checkbox from 'components/shared/form/checkbox';
import Button from 'components/shared/single-click-button';

class ScheduledReports extends Component {
  static propTypes = {
    loadScheduledReports: PropTypes.func,
    onCheckAllChange: PropTypes.func,
    onSchedule: PropTypes.func,
    onSelectChange: PropTypes.func,
    reports: PropTypes.object,
    selectedReports: PropTypes.any
  };

  state = {
    pageNum: 1
  };

  componentDidMount() {
    const { reports, loadScheduledReports } = this.props;
    if (!reports.scheduled) {
      return loadScheduledReports();
    }
  }
  onPageChange = pageNum => {
    this.setState({ pageNum });
  };

  getReportFrequency = (frequency, unit) => {
    let str = '';

    // Formatter to display corresponding string i.e. every day for frequency = 1 and unit = 1
    if (frequency === 1) {
      if (unit === 1) {
        str = <FormattedMessage id="report.interval.everyday" />;
      } else if (unit === 2) {
        str = <FormattedMessage id="report.interval.everyweek" />;
      } else if (unit === 3) {
        str = <FormattedMessage id="report.interval.everymonth" />;
      } else if (unit === 4) {
        str = <FormattedMessage id="report.interval.everyyear" />;
      } else if (unit === 5) {
        str = <FormattedMessage id="report.interval.minutes" values={{ number: frequency }} />;
      } else if (unit === 6) {
        str = <FormattedMessage id="report.interval.hours" values={{ number: frequency }} />;
      } else {
        str = <FormattedMessage id="report.interval.other" values={{ number: frequency }} />;
      }
    } else {
      if (unit === 1) {
        str = <FormattedMessage id="report.interval.days" values={{ number: frequency }} />;
      } else if (unit === 2) {
        str = <FormattedMessage id="report.interval.weeks" values={{ number: frequency }} />;
      } else if (unit === 3) {
        str = <FormattedMessage id="report.interval.months" values={{ number: frequency }} />;
      } else if (unit === 4) {
        str = <FormattedMessage id="report.interval.years" values={{ number: frequency }} />;
      } else if (unit === 5) {
        str = <FormattedMessage id="report.interval.minutes" values={{ number: frequency }} />;
      } else if (unit === 6) {
        str = <FormattedMessage id="report.interval.hours" values={{ number: frequency }} />;
      } else {
        str = <FormattedMessage id="report.interval.other" values={{ number: frequency }} />;
      }
    }

    return str;
  };

  render() {
    const { selectedReports, onCheckAllChange, onSelectChange, onSchedule, reports } = this.props;

    if (!reports.scheduled || reports.isScheduledLoading) {
      return (
        <div className="reports-page__loading">
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        </div>
      );
    }

    let allSelectedStatus;

    if (reports.scheduled.length !== 0 && reports.scheduled.length === selectedReports.size) {
      allSelectedStatus = 'checked';
    } else if (selectedReports.size === 0) {
      allSelectedStatus = 'unchecked';
    } else {
      allSelectedStatus = 'indeterminate';
    }

    return (
      <div className="reports-page__content">
        <FixedTable className="reports-page__list">
          <table>
            <thead>
              <tr>
                <FixedHeader className="reports-page__row-checkbox">
                  <Checkbox
                    checked={allSelectedStatus}
                    id={`reports-scheduled-checkbox-all`}
                    className="reports-page__checkbox"
                    onChange={() => onCheckAllChange(reports.scheduled)}
                  />
                </FixedHeader>

                <FixedHeader className="reports-page__row-id">
                  <FormattedMessage id="reports-table.id" />
                </FixedHeader>

                <FixedHeader className="reports-page__row-name">
                  <FormattedMessage id="reports-table.name" />
                </FixedHeader>

                <FixedHeader className="reports-page__row-frequency">
                  <FormattedMessage id="reports-table.frequency" />
                </FixedHeader>

                <FixedHeader className="reports-page__row-date">
                  <FormattedMessage id="reports-table.date" />
                </FixedHeader>
                <th className="reports-page__row-icon" />
              </tr>
            </thead>
            <tbody>
              {reports.scheduled.map((report, index) => {
                return (
                  <tr key={index} className={`reports-page__row ${selectedReports.has(report) ? '--selected' : ''}`}>
                    <td className="reports-page__row-checkbox">
                      <Checkbox
                        checked={selectedReports.has(report) ? 'checked' : 'unchecked'}
                        id={`reports-checkbox-${index}`}
                        onChange={() => onSelectChange(report)}
                        className="reports-page__checkbox"
                      />
                    </td>
                    <td className="reports-page__row-id">{report.scheduledReportId} </td>
                    <td className="reports-page__row-name"> {report.reportName} </td>
                    <td className="reports-page__row-frequency">
                      {this.getReportFrequency(report.frequencyInterval, report.frequencyIntervalUnits)}
                    </td>
                    <td className="reports-page__row-date">{dateUtil.formatDate(report.startDate)}</td>
                    <td className="reports-page__row-icon">
                      <Button className="icon-button" onClick={() => onSchedule(report)} size="icon">
                        <Icon name="special-calendar" width={23} />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FixedTable>
      </div>
    );
  }
}

export default ScheduledReports;
