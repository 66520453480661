import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import * as api from 'store/api';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import moment from 'moment';
import Button from 'components/shared/single-click-button';
import CreateMaintenanceMessagePopup from 'components/maintenance-messages/create-maintenance-message-popup';
import EditMaintenanceMessagePopup from 'components/maintenance-messages/edit-maintenance-message-popup';
import DeleteMaintenanceMessagePopup from 'components/maintenance-messages/delete-maintenance-message-popup';

function MaintenanceMessages() {
  const [loading, changeLoadingStatus] = useState(true);
  const [maintenanceMessages, changemaintenanceMessages] = useState([]);
  const [isCreateMessageOpen, setModelOpen] = useState(false);
  const [isEditMessageOpen, setEditModelOpen] = useState(false);
  const [isDeleteMessageOpen, setDeleteModelOpen] = useState(false);
  const [selectedMessageID, setMessageID] = useState();

  let selectedMessageId = 0;
  let GeoCode = 0;
  let MessageText = '';
  let StartDate = '';
  let EndDate = '';
  let TimeZone = {};

  const CurrentDate = moment();
  const currentDateAndTime = useState(new Date());

  useEffect(() => {
    loadMessages();
  }, []);

  function CreateMessageWindowClose() {
    setModelOpen(false);
    localStorage.removeItem("region");
    localStorage.removeItem("timezoneId");
    localStorage.removeItem("message");
  }
  function EditMessageConfirmationWindowClose() {
    setEditModelOpen(false);
  }

  function CreateMessageWindowOpen() {
    setModelOpen(true);
  }
  function openEditPopup(e) {
    selectedMessageId = e.getAttribute('data-messageid');
    setMessageID(selectedMessageId);
    for (let i = 0; i < maintenanceMessages.length; i++) {
      if (maintenanceMessages[i].messageId == selectedMessageId) {
        GeoCode = maintenanceMessages[i].geoCode;
        MessageText = maintenanceMessages[i].messageText;
        StartDate = new Date(maintenanceMessages[i].startDate);
        EndDate = new Date(maintenanceMessages[i].endDate);
        TimeZone = maintenanceMessages[i].timeZone;
      }
    }

    setEditModelOpen(true);
  }
  function OpenDeletePopup(e) {
    selectedMessageId = e.getAttribute('data-messageid');
    setDeleteModelOpen(true);
    setMessageID(selectedMessageId);
  }

  function DeleteMessageConfirmationWindowClose() {
    setDeleteModelOpen(false);
  }

  const loadMessages = () => {
    api
      .getMaintenanceMessages()
      .then(res => {
        changeLoadingStatus(false);
        changemaintenanceMessages(res.data);
      })
      .catch(() => {
        changeLoadingStatus(false);
        changemaintenanceMessages([]);
      });
  };

  return (
    <div className="maintenance-message__container">
      <div className="maintenance-message__header">
        <h1 className="maintenance-message__title">
          <FormattedMessage id="maintenance-messages.title" />
          &nbsp;
          {maintenanceMessages.length > 0 && (
            <div className="maintenance-message__message-count">{maintenanceMessages.length}</div>
          )}
        </h1>
        <div style={{ flex: '1 1 auto' }} />
        <Button type="button" className="btn btn-primary" onClick={CreateMessageWindowOpen}>
          <FormattedMessage id="maintenance-messages.add-message-button" />
        </Button>
      </div>

      
        
          <div className="maintenance-message__content">
            {!loading && (
              <table className="maintenance-message__users-table">
                <thead>
                  <tr>
                    <th />
                    <th>
                      <FormattedMessage id="table-view.heading-maintenance-messages.Region" />
                    </th>
                    <th>
                      <FormattedMessage id="table-view.heading-maintenance-messages.MessageText" />
                    </th>
                    <th>
                      <FormattedMessage id="table-view.heading-maintenance-messages.StartDateAndTime" />
                    </th>
                    <th>
                      <FormattedMessage id="table-view.heading-maintenance-messages.EndDateAndTime" />
                    </th>
                    <th>
                      <FormattedMessage id="table-view.heading-maintenance-messages.TimeZone" />
                    </th>
                    <th></th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {maintenanceMessages &&
                    maintenanceMessages.map(message => {
                      const status = message.status == 1 ? 'active' : message.status == 2 ? 'upcoming' : 'expired';
                      return (
                        <tr key={message.messageId}>
                          <td>
                            <div
                              className={`maintenance-message__users-table__status-icon maintenance-message__users-table__status-icon--${status}`}
                            />
                          </td>
                          <td>{message.geoCode}</td>
                          <td>{message.messageText}</td>
                          <td>{moment(message.startDate).format('MM/DD/YYYY hh:mm A')}</td>
                          <td>{moment(message.endDate).format('MM/DD/YYYY hh:mm A')}</td>
                          <td>{message.timeZone.name}</td>
                          <td>
                            {status == 'expired' ? (
                              <div className="maintenance-message__disabled" disabled>
                                <Button className="maintenance-message__edit-icon">
                                  <Icon name="edit" width={24} height={24} />
                                </Button>
                                <Button className="maintenance-message__delete-icon">
                                  <Icon name="Remove" width={18} height={18} />
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <Button
                                  className="maintenance-message__edit-icon"
                                  data-messageid={message.messageId}
                                  onClick={e => openEditPopup(e.target)}
                                >
                                  <Icon name="edit" width={24} height={24} />
                                </Button>
                                <Button
                                  className="maintenance-message__delete-icon"
                                  data-messageid={message.messageId}
                                  onClick={e => OpenDeletePopup(e.target)}
                                >
                                  <Icon name="Remove" width={18} height={18} />
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          {loading ? (
          <div className="absolute-center maintenance-message__spinner">
            <Icon name="loader" width={80} />
          </div>
          ) : null}
        </div>
        
      
      {isCreateMessageOpen ? (
        <div>
          <CreateMaintenanceMessagePopup isOpen="true" closeMaintenancePopup={CreateMessageWindowClose} loadMessages={loadMessages} />
        </div>
      ) : null}
      {isEditMessageOpen ? (
        <EditMaintenanceMessagePopup
          isEditWindowOpen="true"
          closeMaintenanceEditPopup={EditMessageConfirmationWindowClose}
          messageId={selectedMessageID}
          GeoCode={GeoCode}
          MessageText={MessageText}
          StartDate={StartDate}
          EndDate={EndDate}
          TimeZone={TimeZone}
          maintenanceMessages={maintenanceMessages}
          loadMessages={loadMessages}
        />
      ) : null}
      {isDeleteMessageOpen ? (
        <DeleteMaintenanceMessagePopup
          isDeleteWindowOpen="true"
          closeMaintenanceDeletePopup={DeleteMessageConfirmationWindowClose}
          loadMessages={loadMessages}
          messageId={selectedMessageID}
          maintenanceMessages={maintenanceMessages}
        />
      ) : null}
    </div>
  );
}

export default withRouter(MaintenanceMessages);
