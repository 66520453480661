import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCK } from './constants';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';

class TranslationDownMessage extends Component {
  static propTypes = {
    machineTranslationDownTimeDuration: PropTypes.string,
    machineTranslationDownTimeDurationUnitKey: PropTypes.string
  };

  render() {
    const { machineTranslationDownTimeDuration, machineTranslationDownTimeDurationUnitKey } = this.props;

    return (
      <div className={`${BLOCK}__down-message`}>
        <div className={`${BLOCK}__warning-icon`}>
          <Icon name="warning" width={20} />
        </div>
        <span className={`${BLOCK}__down-message__msg`}>
          <FormattedMessage id="global.banner.machine-translation-downtime.title" />
          &nbsp;
          {machineTranslationDownTimeDuration}&nbsp;
          <FormattedMessage id={machineTranslationDownTimeDurationUnitKey} />
        </span>
      </div>
    );
  }
}

export default TranslationDownMessage;
