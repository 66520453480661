import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import LanguageSelect from 'containers/header/language-select';
import FormatSelect from 'containers/header/format-select';
import DateSelect from 'containers/header/Date-select';
import Button from 'components/shared/single-click-button';
import { postPrefferedFormat } from 'store/api';
import { postPrefferedDateFormat } from 'store/api';
import OutsideClickHandler from 'react-outside-click-handler';
import Switch from 'components/shared/form/switch';
import { postDsEnabledForUser, getDataSnipperEnableforUser, getExtendedFeaturesforContainer } from 'store/api';

function AccountSettingsPopup({ isOpen, closePopup, handleFormatSuccessSubmit, handleFormatErrorSubmit, setLanguage, userId, IsDsEnabled, onChangeDSEnabled, currentUser }) {
  const [userDsValue, setUserDSValue] = useState(false);
  const [isDsValueLoading, setisDsValueLoading] = useState(true);
  const [isDataSnipperContainerSettingEnable, setDataSnipperContainerSetting] = useState(false);
  useEffect(() => {
    getDataSnipperEnableforUser().then(res => {
      if (res.userPreferencesString == null) {
        setUserDSValue(false)
      }
      else {
        setUserDSValue(parseStringToBoolean(res.userPreferencesString));
      }
      setisDsValueLoading(false);
    })

    getExtendedFeaturesforContainer(false).then(res => {
      var datasnipperresponse = res.find(r => r.containerApplicationSettingName == "DataSnipperAccess").containerApplicationSettingValue;
      var dscontainersettingvalue = (datasnipperresponse === "true") ? true : false;
      setDataSnipperContainerSetting(dscontainersettingvalue);
    });
  }, [])

  const changeUserDSValue = () => {
    setUserDSValue(!userDsValue);
  }

  const parseStringToBoolean = (stringValue) => {
    // Check if the string value is 'true' or 'false'
    if (stringValue.toLowerCase() === 'true') {
      return true;
    } else if (stringValue.toLowerCase() === 'false') {
      return false;
    } else {
      // If the string value is not 'true' or 'false', return null
      return null;
    }
  };

  const formatSelected = data => {
    localStorage.setItem('userSelectedFormat', data);
  };

  const DateformatSelected = data => {
    localStorage.setItem('userSelectedDateFormat', data);
  };

  const [language, changeLanguage] = useState(0);
  const saveFormatsAndLanguage = () => {
    let selectedDateFormat = localStorage.getItem('userSelectedDateFormat');
    if (selectedDateFormat == 'MM/DD/YYYY' || selectedDateFormat == 'MM/dd/yyyy') {
      selectedDateFormat = 'MM/dd/yyyy';
    } else if (selectedDateFormat == 'DD/MM/YYYY' || selectedDateFormat == 'dd/MM/yyyy') {
      selectedDateFormat = 'dd/MM/yyyy';
    } else if (selectedDateFormat == 'YYYY/MM/DD' || selectedDateFormat == 'yyyy/MM/dd') {
      selectedDateFormat = 'yyyy/MM/dd';
    }

    closePopup();
    if (localStorage.getItem('userSelectedFormat') == null || localStorage.getItem('userSelectedFormat') == "null") {
      localStorage.setItem('userSelectedFormat', localStorage.getItem('defaultFormat'));
    }
    if (localStorage.getItem('userSelectedDateFormat') == null || localStorage.getItem('userSelectedDateFormat') == "null") {
      localStorage.setItem('userSelectedDateFormat', localStorage.getItem('defaultdateFormat'));
    }
    postPrefferedFormat('NumberFormatPreference', localStorage.getItem('userSelectedFormat'), userId).then(
      response => handleFormatSuccessSubmit?.(response),
      error => handleFormatErrorSubmit?.(error)
    );
    postPrefferedDateFormat('DateFormatPreference', selectedDateFormat, userId).then(
      response => handleFormatSuccessSubmit?.(response),
      error => handleFormatErrorSubmit?.(error)
    );

    postDsEnabledForUser('enableDataSnipperForUser', userDsValue.toString(), userId).then(
      response => handleFormatSuccessSubmit?.(response),
      error => handleFormatErrorSubmit?.(error)
    );

    localStorage.setItem('currentFormat', localStorage.getItem('userSelectedFormat'));
    localStorage.setItem('currentDateFormat', localStorage.getItem('userSelectedDateFormat'));
    setLanguage(language);
  };

  const openCookiePrivacyCenter = () => {
    closePopup();
    document.getElementById('ot-sdk-btn').click();
  }

  return isOpen ? (
    <Modal>
      <OutsideClickHandler
        onOutsideClick={() => {
          closePopup();
        }}
      >
        <div className="modal__content rate-application-dialog">
          <div className="rate-application-dialog__header">
            <h4>
              <FormattedMessage id="global.header.home-settings" />
            </h4>
            <Button type="button" size="icon" className="modal__close_x icon-button" onClick={closePopup}>
              <Icon name="special-cross-black" width={18} height={18} />
            </Button>
          </div>

          <div className="modal__body">
            <h5>
              <FormattedMessage id="global.header.home-settings.notes" />
            </h5><br />

            <div className="language-select">
              <LanguageSelect handlelanguageSubmit={data => changeLanguage(data)} />
            </div>
            <br />
            <div id="group1">
              <div className="format-select">
                <FormatSelect handleSuccessSubmit={data => formatSelected(data)} />
              </div>
            </div>

            <br />
            {/* <button id="ot-sdk-btn" onClick = {openCookiePrivacyCenter}>
              <FormattedMessage id ="global.footer.cookie-setting"/>
            </button>
            <br/> */}
            <div id="group2">
              <div className="Date-select">
                <DateSelect handleSuccessSubmit={data => DateformatSelected(data)} />
              </div>
            </div>
            {currentUser.allowDataSnipper && isDataSnipperContainerSettingEnable ? (
              <>
                <h5>
                  <FormattedMessage id="global.header.home-settings.dsexport" />
                </h5>
                <br />
                {isDsValueLoading ? (
                  <Icon className="spinner" name="loader" width={40} />
                ) :
                  <Switch
                    className="dsExport-toggle"
                    isOn={userDsValue}
                    onChange={changeUserDSValue}
                    id="dsExportToggle"
                  />
                }
              </>
            ) : null}
          </div>

          <div className="modal__footer">
            <Button className="btn btn-secondary" onClick={closePopup}>
              <FormattedMessage id="global.header.format-cancel" />
            </Button>

            <Button className="btn btn-primary" onClick={saveFormatsAndLanguage}>
              <FormattedMessage id="global.header.format-confirm" />
            </Button>
          </div>
        </div>
      </OutsideClickHandler>
    </Modal>
  ) : null;
}

export default AccountSettingsPopup;
