import { connect } from 'react-redux';

import { onSearchChange } from 'store/search/search-category';
import GroupsSearch from 'components/search/groups-search/groups-search';

const mapStateToProps = ({ data: { search } }) => ({
  search
});

const mapDispatchToProps = {
  onSearchChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsSearch);
