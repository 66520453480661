import { PropTypes } from 'prop-types';
import Autocomplete from 'react-autocomplete';
import classNames from 'classnames';
import React, { Component } from 'react';

class GroupsAutocomplete extends Component {
  static propTypes = {
    groupName: PropTypes.string.isRequired,
    groupNames: PropTypes.array.isRequired,
    onGroupChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
  };

  renderAutocompleteInput = props => {
    const { placeholder } = this.props;
    return <input {...props} autoFocus maxLength="400" placeholder={placeholder} type="text" />;
  };

  renderAutocompleteMenu = items => <div className="add-group-popover__autocomplete-menu" children={items} />;

  renderAutocompleteItem = (item, isHighlighted) => (
    <div
      className={classNames(
        'add-group-popover__autocomplete-item',
        isHighlighted && 'add-group-popover__autocomplete-item--highlighted'
      )}
      key={item}
    >
      {item}
    </div>
  );

  render() {
    const { groupName, groupNames, onGroupChange } = this.props;

    return (
      <Autocomplete
        autoHighlight={true}
        getItemValue={item => item}
        items={groupNames}
        onChange={event => onGroupChange(event.target.value)}
        onSelect={onGroupChange}
        renderInput={this.renderAutocompleteInput}
        renderItem={this.renderAutocompleteItem}
        renderMenu={this.renderAutocompleteMenu}
        shouldItemRender={(item, value) => value && item.toLowerCase().indexOf(value.toLowerCase()) > -1}
        value={groupName}
        wrapperStyle={{ display: 'block' }}
      />
    );
  }
}

export default GroupsAutocomplete;
