import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';

class TargetText extends Component {
  static propTypes = {
    block: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  };

  state = {
    textCounter: 0
  };

  textareaRef = React.createRef();

  render() {
    const { block, text } = this.props;

    return (
      <div className={`${block}__target-text`}>
        {!text ? (
          <Fragment>
          <div className={`${block}__placeholder`}>
            <FormattedMessage id={`translate-text.target-text.placeholder`} />
          </div>
          <div className={`${block}__placeholder-1`}>
            <FormattedMessage id={`translate-text.target-text.placeholder-1`} />
          </div>
          </Fragment>
        ) : (
          <div className={`${block}__translated-text`}>{text}</div>
        )}
        <div className={`${block}__text-counter`}>
          <Icon className={`${block}__text-counter__icon`} name="file-copy" width={28} />
        </div>
      </div>
    );
  }
}

export default TargetText;
