import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'components/shared/single-click-button';

import ProcessingPausedBanner from 'components/header/paused-banner/processing-paused-banner';

// A wrapper for all the sections
export default class Container extends Component {
  static propTypes = {
    children: PropTypes.node,
    customCloseButton: PropTypes.node,
    customHeader: PropTypes.node,
    customNextButton: PropTypes.node,
    customPrevButton: PropTypes.node,
    disableBothButtons: PropTypes.bool,
    disabled: PropTypes.bool,
    hideSteps: PropTypes.bool,
    onChangeStep: PropTypes.func,
    onCustomNext: PropTypes.func,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    project: PropTypes.any,
    projectState: PropTypes.object,
    step: PropTypes.number,
    steps: PropTypes.array
  };

  // Step controllers
  previousPage = () => this.props.onChangeStep(this.props.step - 1);
  nextPage = () => {
   this.props.onChangeStep(this.props.step + 1);
  }

  render() {
    const {
      project,
      onPrev,
      onNext,
      steps,
      disabled,
      customHeader,
      customNextButton,
      customPrevButton,
      step,
      disableBothButtons,
      onCustomNext,
      projectState,
      hideSteps,
      customCloseButton
    } = this.props;
    const disableButton = disabled ? 'disabled' : 'enabled';
    return (
      <div className="main-section">
        {/* {!project.isDocumentProcessingEnabled && step === 2 && (
          <ProcessingPausedBanner currentProject={project} inCreationPage={true} />
        )} */}
        <div className="main-section__content">{this.props.children}</div>
        {disableBothButtons ? null : (
          <div className="main-section__footer">
            <div className="main-section__footer-prev-button">
              {customPrevButton ? (
                customPrevButton
              ) : (
                <Button
                  size="medium"
                  className="btn btn-secondary"
                  onClick={() => (onPrev ? onPrev().then(() => this.previousPage()) : this.previousPage())}
                >
                  <FormattedMessage id="common.previous" />
                </Button>
              )}
            </div>
            <div className="main-section__footer-next-button">
              {step + 1 >= steps.length &&
              !customNextButton &&
              !projectState.COPY &&
              !projectState.CARRY ? null : customNextButton ? (
                customNextButton
              )
               :
                (
                <Button
                  className={`main-section-project-next-button main-section-project-next-button-${disableButton}`}
                  size="medium"
                  disabled={disabled}
                  onClick={e => {
                    e.preventDefault();
                    if (onNext) {
                      return onNext().then(() => this.nextPage());
                    }
                    if (onCustomNext) {
                      return onCustomNext();
                    }
                    return;
                  }}
                >
                  <FormattedMessage
                    id={`${
                      step === 1 && (projectState.COPY || projectState.CARRY)
                        ? 'main-section.footer-confirm-button'
                        : 'main-section.footer-next-button'
                    }`}
                  />
                </Button>
              )}
            </div>
          </div>
        )}

      </div>
    );
  }
}
