import React, { Component } from 'react';
import { getTrainingRequestsSummary, getCurrentRegion } from 'store/api';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Permissions from 'permissions/permissions';

const REQUEST_TYPES = {
  PENDING_REQUESTS: 'PendingRequest',
  PENDING_REVIEW: 'PendingReview'
};

class AdminDashboard extends Component {
  static propTypes = {
    setTrainingRequestType: PropTypes.func,
    showAdminAccessWidget: PropTypes.bool,
    currentUser : PropTypes.object,
    loadProjectList : PropTypes.func,
    onSearchChange: PropTypes.func
  };

  state = {
    summary: []
  };

  componentDidMount() {
    this.getTrainingSummary();
  }

  getTrainingSummary = () => {
    getTrainingRequestsSummary().then(result => {
      this.setState({
        summary: result.data
      });
    });
  };

  onTrainingRequestRedirect = type => {
    const { setTrainingRequestType } = this.props;
    setTrainingRequestType(type);
  };


  render() {
    const { summary } = this.state;
    const { showAdminAccessWidget,currentUser} = this.props;
    const pendingRequests = summary.pendingRequestsCount;
    const pendingReview = summary.pendingReviewsCount;

    return (
      <div className="admin-dashboard">
        <div className="admin-dashboard__header-container">
          <div>
          <h2 className="admin-dashboard__header">  
            <FormattedMessage id="admin-dashboard.header" />
          </h2>
          </div>
          
        </div>
        {Permissions.Global.canViewTrainingRequests() && <div className="admin-dashboard__card">
          <div className="admin-dashboard__card-top">
            <span>
              <FormattedMessage id="admin-dashboard.tile.trainingRequests" />
            </span>
          </div>
          <div className="admin-dashboard__card-mid">
            <span>
              <Link
                className="admin-dashboard__card-statusTitle"
                onClick={() => this.onTrainingRequestRedirect(REQUEST_TYPES.PENDING_REQUESTS)}
                to={`/region/${getCurrentRegion()}/training-requests`}
              >
                <FormattedMessage id="admin-dashboard.tile.pending-requests" />
              </Link>
            </span>
            <span>{pendingRequests}</span>
          </div>
          <div className="admin-dashboard__card-mid">
            <span>
              <Link
                className="admin-dashboard__card-statusTitle"
                onClick={() => this.onTrainingRequestRedirect(REQUEST_TYPES.PENDING_REVIEW)}
                to={`/region/${getCurrentRegion()}/training-requests`}
              >
                <FormattedMessage id="admin-dashboard.tile.pending-review" />
              </Link>
            </span>
            <span>{pendingReview}</span>
          </div>
          <div className="admin-dashboard__card-mid">
            <span className="admin-dashboard__card-status">
              <div className="admin-dashboard__card-status-icon success"></div>
              <span>
                <FormattedMessage id="admin-dashboard.tile.success" />
              </span>
            </span>
            <span>{summary.successCount}</span>
          </div>
          <div className="admin-dashboard__card-mid">
            <span className="admin-dashboard__card-status">
              <div className="admin-dashboard__card-status-icon failed"></div>
              <span>
                <FormattedMessage id="admin-dashboard.tile.failed" />
              </span>
            </span>
            <span>{summary.failedCount}</span>
          </div>
        </div>}

        {showAdminAccessWidget ? (
          <div className="admin-dashboard__card">
            <div className="admin-dashboard__card-top">
              <span>
                <FormattedMessage id="admin-dashboard.tile.admin-access" />
              </span>
            </div>
            {Permissions.Global.User.canViewUserManagementLink() ? (
              <div className="admin-dashboard__card-mid">
                <span>
                  <Link
                    className="admin-dashboard__card-statusTitle"
                    onClick={() => this.onTrainingRequestRedirect(REQUEST_TYPES.PENDING_REQUESTS)}
                    to={`/region/${getCurrentRegion()}/user-management`}
                  >
                    <FormattedMessage id="global.header.user-management" />
                  </Link>
                </span></div>
            ) : null}
            {Permissions.Global.ExtractionField.canViewQuickStudyPageLink() && !Permissions.Global.canHaveGlobalAdminAccess() ? (
              <div className="admin-dashboard__card-mid">
                <span>
                  <Link
                    className="admin-dashboard__card-statusTitle"
                    onClick={() => this.onTrainingRequestRedirect(REQUEST_TYPES.PENDING_REQUESTS)}
                    to={`/region/${getCurrentRegion()}/groups`}
                  >
                    <FormattedMessage id="global.header.manage-extraction-fields" />
                  </Link>
                </span></div>
            ) : null}
            {Permissions.Global.canViewReports() ? (
              <div className="admin-dashboard__card-mid">
                <span>
                  <Link
                    className="admin-dashboard__card-statusTitle"
                    onClick={() => this.onTrainingRequestRedirect(REQUEST_TYPES.PENDING_REQUESTS)}
                    to={`/region/${getCurrentRegion()}/reports`}
                  >
                    <FormattedMessage id="global.header.reports" />
                  </Link>
                </span>
              </div>
            ) : null}
            {Permissions.Global.User.canViewMaintenanceMessagesLink() ? (
              <div className="admin-dashboard__card-mid">
                <span>
                  <Link
                      className="admin-dashboard__card-statusTitle"
                      onClick={() => this.onTrainingRequestRedirect(REQUEST_TYPES.PENDING_REQUESTS)}
                      to={`/maintenance-messages`}
                    >
                      <FormattedMessage id="global.header.maintenance-messages" />
                    </Link>
                </span>
              </div>
            ) : null}
          </div>
        ) : null}
      </div >
    );
  }
}

export default AdminDashboard;
