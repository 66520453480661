import store from 'store/store';
import constants from 'utils/constants';

const StatesHelper = {
  isProjectReadOnly: project => {
    if (!project) project = store.getState().data.currentProject;
    return (
      project &&
      //Project is pending archive, archived or my project role is visitor (read only)
      (project.projectStateId === constants.ProjectStateIds.pendingArchive ||
        project.projectStateId === constants.ProjectStateIds.archived ||
        (project.myRole && project.myRole !== null && project.myRole.roleId === constants.UserRoles.PROJECT_VISITOR))
    );
  },
  isProjectArchived: project => {
    if (!project) project = store.getState().data.currentProject;
    return (
      project &&
      //Project is pending archive, archived or my project role is visitor (read only)
      (project.projectStateId === constants.ProjectStateIds.pendingArchive ||
        project.projectStateId === constants.ProjectStateIds.archived)
    );
  }
};

export default StatesHelper;
