import React, { Component } from 'react';
import { debounce } from 'underscore';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import { FormattedMessage } from 'react-intl';
import Constants from 'utils/constants';

class DocumentSearch extends Component {
  static propTypes = {
    currentDocument: PropTypes.object,
    currentDocumentLayout: PropTypes.object,
    getCurrentDocumentCompleteLayout: PropTypes.func,
    match: PropTypes.object,
    setSearchResults: PropTypes.func
  };

  state = {
    results: [],
    loadingPageData: false,
    searchQuery: '',
    emphasis: 0,
    isKira: true,
    characters: [],
    documentChanged: false
  };

  PADDING_CHARACTERS_LEAD = 40;
  PADDING_CHARACTERS_TRAIL = 70;

  UNSAFE_componentWillReceiveProps(nextProps) {
    //Document Id Change
    if (this.props.match.params.documentId !== nextProps.match.params.documentId) {
      this.setState({ results: [], searchQuery: '', emphasis: 0 });
      this.props.setSearchResults([], 0, false);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentDocument: { documentId }
    } = this.props;
    if (prevProps.currentDocument.documentId !== documentId) {
      this.setState({ documentChanged: true });
    }
  }

  clearSearch() {
    this.setState({ emphasis: 0, results: [], searchQuery: '' });
    this.props.setSearchResults();
  }

  prepareData() {
    var { currentDocument, currentDocumentLayout } = this.props;
    var { projectId, documentId } = currentDocument;
    var { loadingPageData, documentChanged } = this.state;

    if (
      !loadingPageData &&
      (currentDocumentLayout.loadedPages.length !== currentDocumentLayout.layout.length || documentChanged)
    ) {
      this.setState({ loadingPageData: true });
      this.props.getCurrentDocumentCompleteLayout(projectId, documentId).then(() => {
        this.setState({ loadingPageData: false, documentChanged: false });
        this.checkDocumentProcessType();
      });
    }
  }

  setSearchResults(results, emphasis, hide) {
    results.forEach((result, i) => (result.emphasis = i === emphasis));

    this.setState({ results: results, emphasis: emphasis }, () => {
      this.props.setSearchResults(
        results.map(result => {
          return { characters: result.characters, emphasis: result.emphasis };
        })
      );

      if (results && results.length) this.goToPage(results[emphasis].characters[0].page, hide);
    });
  }

  searchQueryChange = e => {
    this.setState({ searchQuery: e.target.value }, this.performSearch(e.target.value));
  };

  // used to check if a document was processed by kira or abbyy
  checkDocumentProcessType = () => {
    var {
      currentDocumentLayout: { characters }
    } = this.props;
    var isKira = true;

    for (let i = 0; i < characters.length; i++) {
      if (characters[i].u.length > 1) {
        isKira = false;
        break;
      } else if (Constants.TextSelectionBreakCharacters.indexOf(characters[i].u[0]) !== -1) {
        break;
      }
    }

    this.setState({ isKira }, this.setState({ characters: this.getCharacters(isKira) }));
  };

  getCharacters = isKira => {
    const {
      currentDocumentLayout: { characters }
    } = this.props;

    if (isKira) {
      return characters;
    }

    const formatedCharacters = [];

    characters.forEach(character => {
      character.u.forEach((c, j) => {
        formatedCharacters.push({
          ...character,
          u: [c],
          i: character.i + j,
          start: character.i,
          end: character.i + character.u.length - 1
        });
      });
      formatedCharacters.push({
        ...character,
        u: [32],
        i: character.i + character.u.length,
        end: character.i + character.u.length
      });
    });
    return formatedCharacters;
  };

  performSearch = debounce(query => {
    const { characters } = this.state;
    var matches = [];
    var possibleMatch = false;

    characters.forEach((character, i) => {
      //Burndown
      if (possibleMatch) {
        if (String.fromCharCode(character.u).toLowerCase() === possibleMatch.burndown.slice(0, 1).toLowerCase()) {
          if (possibleMatch.burndown.length > 0) {
            possibleMatch.burndown = possibleMatch.burndown.substr(1);
          }

          if (possibleMatch.burndown.length === 0) {
            possibleMatch.lead = characters.slice(
              possibleMatch.startIndex - this.PADDING_CHARACTERS_LEAD > 0
                ? possibleMatch.startIndex - this.PADDING_CHARACTERS_LEAD
                : 0,
              possibleMatch.startIndex
            );

            possibleMatch.characters = characters.slice(possibleMatch.startIndex, i + 1);

            possibleMatch.trail = characters.slice(
              i + 1,
              i + 1 + this.PADDING_CHARACTERS_TRAIL < characters.length
                ? i + 1 + this.PADDING_CHARACTERS_TRAIL
                : characters.length - 1
            );

            matches.push(possibleMatch);
            possibleMatch = false;
          }
        } else {
          possibleMatch = false;
        }
      }

      if (!possibleMatch) {
        if (String.fromCharCode(character.u).toLowerCase() === query.slice(0, 1).toLowerCase()) {
          possibleMatch = {
            burndown: query.substr(1),
            startIndex: i
          };
        }
      }
    });

    this.setSearchResults(matches, 0, false);
  }, 500);

  hideDropdown = hide => {
    this.setState({ hideDropdown: hide });
  };

  handleClickOutside() {
    this.hideDropdown(true);
  }

  goToPage = (pageNumber, hide) => {
    this.setState({ hideDropdown: hide });
    window.postMessage({ document_page_change: pageNumber },window.location.href);
  };

  setEmphasis = index => {
    this.setSearchResults(this.state.results, index, true);
  };

  setEmphasisNext = () => {
    var { emphasis, results } = this.state;
    this.setEmphasis(emphasis + 1 < results.length ? emphasis + 1 : 0);
  };

  setEmphasisPrevious = () => {
    var { emphasis, results } = this.state;
    this.setEmphasis(emphasis - 1 >= 0 ? emphasis - 1 : results.length - 1);
  };

  handleKeyDown = event => {
    if (event.keyCode === 27) {
      this.hideDropdown(true);
    }
  };

  render() {
    const { currentDocumentLayout } = this.props;
    const { results, searchQuery, hideDropdown, emphasis, loadingPageData } = this.state;

    return (
      <div className="document-search">
        <div className="controls">
          {loadingPageData ? (
            <div className="loading-placeholder">
              <FormattedMessage id="search-results.placeholder" />

              

              <span className="loading-message">
                <FormattedMessage id="search-results.loader" />
                <Icon className="spinner" name="loader" width={18} />
              </span>
            </div>
          ) : (
            <FormattedMessage id="search-results.placeholder">
              {placeholder => (
                <input
                  type="text"
                  placeholder={placeholder}
                  value={searchQuery}
                  onChange={e => this.searchQueryChange(e)}
                  onClick={() => {
                    this.prepareData();
                    this.hideDropdown(false);
                  }}
                  onKeyDown={this.handleKeyDown}
                  disabled={!currentDocumentLayout.isLoaded}
                />
              )}
            </FormattedMessage>
          )}
          {results && results.length ? (
            <div className="result-controls">
              <span>
                <FormattedMessage
                  id="search-results.controls"
                  values={{ current: emphasis + 1, total: results.length }}
                />
              </span>
              <Button size="icon" className="rotate" onClick={() => this.setEmphasisPrevious()}>
                <Icon name="special-arrow-right" width={12} />
              </Button>
              <Button size="icon" onClick={() => this.setEmphasisNext()}>
                <Icon name="special-arrow-right" width={12} />
              </Button>
              <Button size="icon" className="icon-button" onClick={() => this.clearSearch()}>
                <Icon name="special-cross-black" width={12} />
              </Button>
            </div>
          ) : null}
        </div>

        {results && results.length && !hideDropdown ? (
          <div className="results-dropdown">
            <div className="results-frame">
              <div className="results-list">
                {results.map((result, k) => (
                  <div className="result-row" key={k} onClick={() => this.setEmphasis(k)}>
                    <div className="excerpt-wrapper">
                      <div className="excerpt">
                        {result.startIndex > this.PADDING_CHARACTERS_LEAD ? '...' : ''}
                        {result.lead.reduce((a, c) => a + String.fromCharCode(c.u), '')}
                        <span>{result.characters.reduce((a, c) => a + String.fromCharCode(c.u), '')}</span>
                        {result.trail.reduce((a, c) => a + String.fromCharCode(c.u), '')}
                        {'...'}
                      </div>
                      <div className="page-number">
                        <FormattedMessage
                          id="search-results.page"
                          values={{ number: `${result.characters[0].page} / ${currentDocumentLayout.layout.length}` }}
                        />
                      </div>
                    </div>
                    <Icon name="special-arrow-right" width={12} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default enhanceWithClickOutside(DocumentSearch);
