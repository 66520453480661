import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { COMPARISON_STATUSES } from 'models/comparison-sets';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { getCurrentRegion } from 'store/api';
import { Link } from 'react-router-dom';
import Checkbox from 'components/shared/form/checkbox';
import dateUtil from 'utils/dateUtil';

export default class CompareItem extends Component {
  static propTypes = {
    comparisonSet: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onSelectedChange: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired
  };

  statusText = {
    [COMPARISON_STATUSES.INITIATED]: 'compare-item.status.initiated',
    [COMPARISON_STATUSES.IN_PROGRESS]: 'compare-item.status.progress',
    [COMPARISON_STATUSES.AGGREGATING]: 'compare-item.status.aggregating',
    [COMPARISON_STATUSES.COMPLETED]: 'compare-item.status.completed',
    [COMPARISON_STATUSES.DELETED]: 'compare-item.status.deleted',
    [COMPARISON_STATUSES.REPROCRESS]: 'compare-item.status.reprocess',
    [COMPARISON_STATUSES.ERROR]: 'compare-item.status.error'
  };

  render() {
    const { comparisonSet, id, isSelected, onSelectedChange, projectId } = this.props;
    const hasError = comparisonSet.comparisonStateId === COMPARISON_STATUSES.ERROR;
    return (
      <tr className="compare-item">
        <td>
          <Checkbox
            checked={isSelected ? 'checked' : 'unchecked'}
            id={`compare-checkbox-${id}`}
            className="file-list__checkbox"
            onChange={onSelectedChange}
          />
        </td>
        <td>
          {!hasError && comparisonSet.totalEntities > 0 ? (
            <Link
              to={`/region/${getCurrentRegion()}/project/${projectId}/compare/${comparisonSet.comparisonId}`}
              className="compare-browser__compare-link"
            >
              {comparisonSet.comparisonName}
            </Link>
          ) : (
            comparisonSet.comparisonName
          )}
        </td>
        <td>{comparisonSet.baseEntityName}</td>
        <td>
          <span
            className={`compare-browser__status-dot compare-browser__status-complete ${
              hasError ? 'compare-browser__status-dot--error' : ''
            }`}
          />
          <FormattedMessage id={this.statusText[comparisonSet.comparisonStateId]} />
        </td>
        <td>{dateUtil.formatDate(comparisonSet.createdDate)}</td>
        <td className="compare-browser__right-align-cell">
          {!hasError && <FormattedNumber value={comparisonSet.totalEntities} />}
        </td>
      </tr>
    );
  }
}
