import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import { getCurrentRegion, updateExtractionFieldGroup } from 'store/api';
import Sorting from 'components/shared/sorting/sorting';
import { isNumeric } from 'utils/number-utils';
import Checkbox from 'components/shared/form/checkbox';
import EditableText from 'components/shared/form/editable-text';
import Permissions from 'permissions/permissions';

class GroupItem extends Component {
  static propTypes = {
    group: PropTypes.object.isRequired,
    hideCheckboxes: PropTypes.bool,
    isSelected: PropTypes.bool.isRequired,
    onSelectedChange: PropTypes.func.isRequired
  };

  state = {
    groupName: this.props.group.extractionFieldGroupName
  };

  componentDidUpdate(prevProps) {
    if (this.props.group.extractionFieldGroupName !== prevProps.group.extractionFieldGroupName) {
      this.setState({
        groupName: this.props.group.extractionFieldGroupName
      });
    }
  }

  onChangeName = groupName => {
    if (!groupName || groupName === this.state.groupName) {
      return;
    }

    this.setState(
      {
        groupName
      },
      this.updateGroup
    );
  };

  updateGroup = () => {
    const { group } = this.props;
    const { groupName } = this.state;
    updateExtractionFieldGroup({
      groupId: group.extractionFieldGroupId,
      groupName
    })
      .then(() => {})
      .catch(() => {
        this.setState({
          groupName: group.extractionFieldGroupName
        });
      });
  };

  render() {
    const { group, hideCheckboxes, isSelected, onSelectedChange } = this.props;
    const { groupName } = this.state;
    const isAllFieldsGroup = group.extractionFieldGroupId === 'all';
    return (
      <tr className={classNames('group-list__tr', isSelected && 'group-list__tr--selected')}>
        {!hideCheckboxes && (
          <td className="group-list__td group-list__td-checkbox">
            {!isAllFieldsGroup ? (
              <Checkbox
                checked={isSelected ? 'checked' : 'unchecked'}
                id={`group-checkbox-${group.extractionFieldGroupId}`}
                className="file-list__checkbox"
                onChange={onSelectedChange}
              />
            ) : null}
          </td>
        )}
        <td className="group-list__td group-list__td-name">
          <EditableText
            disableEdit={isAllFieldsGroup || !Permissions.Global.ExtractionField.ExtractionFieldGroup.canEdit()}
            value={groupName}
            onSubmit={this.onChangeName}
            renderValue={() => (
              <Link
                className="group-list__group-name"
                title={groupName}
                to={`/region/${getCurrentRegion()}/groups/${group.extractionFieldGroupId}/extraction-fields`}
              >
                {groupName}
              </Link>
            )}
          />
        </td>
        <td className="group-list__td group-list__td-total">
          {isNumeric(group.totalFieldCount) ? <FormattedNumber value={group.totalFieldCount} /> : '-'}
        </td>
        <td className="group-list__td group-list__td-trained">
          {isNumeric(group.trainedFieldCount) ? <FormattedNumber value={group.trainedFieldCount} /> : '-'}
        </td>
        <td className="group-list__td group-list__td-untrained">
          {isNumeric(group.untrainedFieldCount) ? <FormattedNumber value={group.untrainedFieldCount} /> : '-'}
        </td>
      </tr>
    );
  }
}

export default class GroupList extends Component {
  static propTypes = {
    getGroups: PropTypes.func,
    groups: PropTypes.array,
    hideCheckboxes: PropTypes.bool,
    onCheckAllChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSelectedGroupChange: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    selectedGroups: PropTypes.object.isRequired
  };

  state = {
    sorting: {
      key: 'name',
      ascending: true
    },
    search: {
      key: undefined,
      query: undefined
    }
  };

  /////////////////////// SORTING FILTERING FUNCTIONS START ///////////////////////
  reloadCurrentGroups() {
    const { filtering, sorting, search } = this.state;

    const groupOptions = {
      pageNum: 1
    };

    if (sorting && sorting.key) {
      groupOptions.sortBy = sorting.key;
      groupOptions.sortOrder = sorting.ascending ? 'ascending' : 'descending';
    }

    if (filtering && filtering.key) {
      groupOptions.filterBy = filtering.key;
      groupOptions.filterIds = filtering.ids;
    }

    if (search && search.key) {
      groupOptions.filterBy = search.key;
      groupOptions.filterString = search.query;
    }

    this.props.getGroups(groupOptions);
  }

  setSorting = (key, ascending) => {
    const { sorting } = this.state;
    sorting.key = key;
    sorting.ascending = ascending;
    this.setState({ sorting: sorting }, this.reloadCurrentGroups());
  };

  setSearch = (key, query) => {
    const { search } = this.state;
    search.key = query.length > 0 ? key : undefined;
    search.query = query.length > 0 ? query : undefined;
    this.setState({ search: search }, this.reloadCurrentGroups());
  };
  /////////////////////// SORTING FILTERING FUNCTIONS END ///////////////////////

  render() {
    const {
      groups,
      hideCheckboxes,
      onCheckAllChange,
      onPageChange,
      onSelectedGroupChange,
      pagination,
      selectedGroups
    } = this.props;
    const { search, sorting } = this.state;

    if (!groups) {
      return null;
    }

    let allSelectedStatus;
    if (groups.length !== 0 && groups.length === selectedGroups.size) {
      allSelectedStatus = 'checked';
    } else if (selectedGroups.size === 0) {
      allSelectedStatus = 'unchecked';
    } else {
      allSelectedStatus = 'indeterminate';
    }

    return (
      <FixedTable className="group-list" pagination={pagination} onPageChange={onPageChange}>
        <table>
          <thead>
            <tr className="group-list__tr">
              {!hideCheckboxes && (
                <FixedHeader className="group-list__th group-list__th-checkbox">
                  <Checkbox
                    checked={allSelectedStatus}
                    className="file-list__checkbox"
                    id="check-all"
                    onChange={onCheckAllChange}
                  />
                </FixedHeader>
              )}
              <FixedHeader className="group-list__th group-list__th-name">
                <FormattedMessage id="quick-study.group-list.table-heading.name" />
                <Sorting
                  searchFunction={query => this.setSearch('name', query)}
                  searchQuery={search.key === 'name' ? search.query : undefined}
                  sortFunction={ascending => this.setSorting('name', ascending)}
                  sortDirection={sorting.key === 'name' ? sorting.ascending : undefined}
                  toggleFunction={() => {}}
                />
              </FixedHeader>
              <FixedHeader className="group-list__th group-list__th-total">
                <FormattedMessage id="quick-study.group-list.table-heading.total" />
              </FixedHeader>
              <FixedHeader className="group-list__th group-list__th-trained">
                <FormattedMessage id="quick-study.group-list.table-heading.trained" />
              </FixedHeader>
              <FixedHeader className="group-list__th group-list__th-untrained">
                <FormattedMessage id="quick-study.group-list.table-heading.untrained" />
              </FixedHeader>
            </tr>
          </thead>
          <tbody>
            {groups.length > 0 ? (
              groups.map((group, index) => {
                return (
                  <GroupItem
                    group={group}
                    isSelected={selectedGroups.has(group.extractionFieldGroupId)}
                    key={index}
                    onSelectedChange={onSelectedGroupChange.bind(this, group)}
                    hideCheckboxes={hideCheckboxes}
                  />
                );
              })
            ) : (
              <tr />
            )}
          </tbody>
        </table>
      </FixedTable>
    );
  }
}
