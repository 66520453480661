import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as d3 from 'd3';

import Switch from 'components/shared/form/switch';

export default class VisualsExtractionFields extends Component {
  static propTypes = {
    activeExtraction: PropTypes.object,
    documents: PropTypes.array.isRequired,
    extractionMap: PropTypes.object.isRequired,
    noExtractions: PropTypes.bool.isRequired,
    setExtractionField: PropTypes.func.isRequired,
    toggleNoExtractions: PropTypes.func.isRequired
  };

  d3State = {
    clientWidth: undefined,
    clientHeight: undefined,
    width: undefined,
    height: undefined,
    minRadius: undefined,
    rootGroup: undefined,
    bars: undefined,
    x: undefined,
    backgroundX: undefined,
    xAxis: undefined,
    y: undefined,
    yAxis: undefined,
    extractionBarData: undefined
  };

  componentDidMount() {
    this.createChart();
    this.updateChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  createChart = () => {
    const { extractionMap } = this.props;

    const oldElem = document.querySelector('.visuals-extraction-fields__chart g');
    if (oldElem) {
      oldElem.parentNode.removeChild(oldElem);
    }

    const root = d3.select('.visuals-extraction-fields__chart');

    this.d3State.clientWidth = root[0][0].clientWidth;
    this.d3State.clientHeight = root[0][0].clientHeight;
    this.d3State.width = Math.max(extractionMap.size * 8.2, this.d3State.clientWidth);
    this.d3State.height = this.d3State.clientHeight;

    const orderedExtractionIds = this.getOrderedExtractionIds(extractionMap);

    this.d3State.x = d3.scale
      .ordinal()
      .rangeBands([0, this.d3State.width], 0.2)
      .domain(orderedExtractionIds);

    this.d3State.backgroundX = d3.scale
      .ordinal()
      .rangeBands([0, this.d3State.width])
      .domain(orderedExtractionIds);

    this.d3State.xAxis = d3.svg
      .axis()
      .orient('bottom')
      .tickSize(0)
      .tickFormat('')
      .scale(this.d3State.x);

    this.d3State.y = d3.scale.linear().range([this.d3State.height, 0]);

    this.d3State.yAxis = d3.svg
      .axis()
      .orient('left')
      .ticks(3);

    root.attr('width', `${this.d3State.width}px`);
    root.attr('viewBox', `0 0 ${this.d3State.width + 30} ${this.d3State.height + 10}`);

    this.d3State.rootGroup = root.append('g').attr('transform', `translate(30,5)`);

    this.d3State.rootGroup
      .append('g')
      .attr('class', 'visuals-extraction-fields__y-axis')
      .call(this.d3State.yAxis);

    this.d3State.rootGroup
      .append('g')
      .attr('class', 'visuals-extraction-fields__x-axis')
      .attr('transform', 'translate(0,' + this.d3State.height + ')')
      .call(this.d3State.xAxis);

    this.d3State.barGroupContainer = this.d3State.rootGroup
      .append('g')
      .attr('class', 'visuals-extraction-fields__bar-group-container')
      .attr('clip-path', 'url(#clip)');

    this.d3State.barGroup = this.d3State.barGroupContainer
      .append('g')
      .attr('class', 'visuals-extraction-fields__bar-group');
  };

  updateChart() {
    const self = this; // :(
    const { setExtractionField, extractionMap } = this.props;

    this.updateExtractionBarData();

    const tooltip = d3.select('.visuals-extraction-fields__chart-tooltip');

    let instanceCounts = this.d3State.extractionBarData.map(e => e.instanceCount);
    let maxInstanceCount = Math.max(...instanceCounts);
    this.d3State.y.domain([0, maxInstanceCount]);

    this.d3State.yAxis.scale(this.d3State.y);

    this.d3State.rootGroup.selectAll('.visuals-extraction-fields__y-axis').call(this.d3State.yAxis);

    const barBackgrounds = this.d3State.barGroup
      .selectAll('.visuals-extraction-fields__bar-background')
      .data(this.d3State.extractionBarData, e => e.id);

    barBackgrounds
      .enter()
      .append('rect')
      .attr('class', 'visuals-extraction-fields__bar-background')
      .attr('y', '0')
      .attr('height', this.d3State.height)
      .attr('width', this.d3State.backgroundX.rangeBand())
      .attr('fill', '#fff')
      .style('cursor', 'pointer')
      .attr('x', e => {
        return this.d3State.backgroundX(e.id);
      })
      .on('mouseover', function(e) {
        const backgroundElem = this;
        const containerBoundingRect = document.body.getBoundingClientRect();
        const elemBoundingRect = backgroundElem.getBoundingClientRect();
        const elemTop = elemBoundingRect.top - containerBoundingRect.top;
        const elemLeft = elemBoundingRect.left - containerBoundingRect.left;

        tooltip
          .transition()
          .duration(200)
          .style('opacity', 0.9);
        tooltip
          .text(e.name)
          .style('left', elemLeft + 'px')
          .style('top', elemTop - 2 + 'px');

        d3.select(this).style('fill', '#ededed');

        d3.select('#visuals-extraction-fields__bar-' + e.id).style('fill', self.getExtractionColor(e, true));
      })
      .on('mouseout', function(e) {
        tooltip
          .transition()
          .duration(500)
          .style('opacity', 0);

        d3.select(this).style('fill', '#fff');

        d3.select('#visuals-extraction-fields__bar-' + e.id).style('fill', e => self.getExtractionColor(e));
      })
      .on('click', e => {
        if (d3.event.defaultPrevented) return;

        setExtractionField(extractionMap.get(e.id));
        d3.event.stopPropagation();
      });

    this.d3State.bars = this.d3State.barGroup
      .selectAll('.visuals-extraction-fields__bar')
      .data(this.d3State.extractionBarData, e => e.id);

    this.d3State.bars
      .enter()
      .append('rect')
      .attr('class', 'visuals-extraction-fields__bar')
      .attr('id', e => 'visuals-extraction-fields__bar-' + e.id)
      .attr('width', this.d3State.x.rangeBand())
      .attr('x', e => {
        return this.d3State.x(e.id);
      })
      .style('fill', e => this.getExtractionColor(e))
      .attr('y', this.d3State.height)
      .attr('height', 0);

    this.d3State.bars
      .transition()
      .duration(300)
      .delay(function(d, i) {
        return i * 1;
      })
      .attr('y', e => {
        return this.d3State.y(e.instanceCount);
      })
      .attr('height', e => {
        return this.d3State.height - this.d3State.y(e.instanceCount);
      })
      .style('fill', e => this.getExtractionColor(e));
  }

  updateExtractionBarData = () => {
    const { documents, extractionMap } = this.props;

    let extractionBarDataMap = new Map();
    for (let extraction of extractionMap.values()) {
      extractionBarDataMap.set(extraction.id, {
        id: extraction.id,
        name: extraction.extractionFieldName,
        isPrivate: extraction.accessTypeId === 2,
        isTrained: extraction.isTrained,
        instanceCount: 0
      });
    }

    documents.forEach(doc => {
      for (let extractionId of Object.keys(doc.extractionRecords || {})) {
        const extraction = extractionBarDataMap.get(Number(extractionId));
        extraction && (extraction.instanceCount += doc.extractionRecords[extractionId]);
      }
    });

    this.d3State.extractionBarData = Array.from(extractionBarDataMap.values());
  };

  getOrderedExtractionIds(extractionMap) {
    const publicExtractions = [];
    const privateExtractions = [];
    for (let e of extractionMap.values()) {
      e.accessTypeId === 2 ? privateExtractions.push(e) : publicExtractions.push(e);
    }
    const orderedExtractionIds = [];
    publicExtractions.forEach(e => orderedExtractionIds.push(e.id));
    privateExtractions.forEach(e => orderedExtractionIds.push(e.id));

    return orderedExtractionIds;
  }

  getExtractionColor(extraction, highlight = false) {
    const { activeExtraction } = this.props;

    if (activeExtraction && extraction.id === activeExtraction.id) {
      return '#ffe872';
    } else if (extraction.isTrained === false) {
      return highlight ? '#b4b6b8x' : '#989a9c';
    } else if (extraction.isPrivate) {
      return highlight ? '#6fc9fc' : '#63b5e5';
    } else {
      return highlight ? '#7fdbca' : '#70c2b4';
    }
  }

  render() {
    const { noExtractions, toggleNoExtractions, activeExtraction } = this.props;

    return (
      <div className="visuals-extraction-fields visuals-extraction-fields__root">
        <div className="visuals-extraction-fields__chart-tooltip" />
        <div className="visuals-extraction-fields__header">
          <div>
            <h2 className="visuals-extraction-fields__title">
              <FormattedMessage id="visuals.extraction-fields.title" />
            </h2>
            <div className="visuals-extraction-fields__sub-text">
              <FormattedMessage id="visuals.extraction-fields.sub-text" />
            </div>
          </div>
          <div style={{ flexGrow: 1 }} />
          <label className="visuals-extraction-fields__no-extractions-label">
            {activeExtraction && <FormattedMessage id="visuals.extraction-fields.no-selected-extractions" />}
            {!activeExtraction && <FormattedMessage id="visuals.extraction-fields.no-extractions" />}
            <Switch
              isOn={noExtractions}
              id={'visuals-extraction-fields-no-extractions'}
              onChange={toggleNoExtractions}
            />
          </label>
        </div>
        <div className="visuals-extraction-fields__chart-container">
          <svg width="100%" height="100%" preserveAspectRatio="none" className="visuals-extraction-fields__chart" />
        </div>

        <div className="visuals-extraction-fields__ledgend">
          <div className="visuals-extraction-fields__ledgend-item">
            <div className="visuals-extraction-fields__ledgend-color-public" />
            <div className="visuals-extraction-fields__ledgend-label">
              <FormattedMessage id="visuals.extraction-fields.public" />
            </div>
          </div>
          <div className="visuals-extraction-fields__ledgend-item">
            <div className="visuals-extraction-fields__ledgend-color-private" />
            <div className="visuals-extraction-fields__ledgend-label">
              <FormattedMessage id="visuals.extraction-fields.private" />
            </div>
          </div>
          <div className="visuals-extraction-fields__ledgend-item">
            <div className="visuals-extraction-fields__ledgend-color-untrained" />
            <div className="visuals-extraction-fields__ledgend-label">
              <FormattedMessage id="visuals.extraction-fields.untrained" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
