import { connect } from 'react-redux';

import { moveItems } from 'store/current-folder';
import MovePopover from 'components/file-browser/toolbar/move-popover';

const mapStateToProps = ({ ui: { fileBrowserSelectedItems } }) => ({
  selectedItems: fileBrowserSelectedItems
});

const mapDispatchToProps = {
  moveItems
};

export default connect(mapStateToProps, mapDispatchToProps)(MovePopover);
