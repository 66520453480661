import { connect } from 'react-redux';

import { moveItems, renameItem } from 'store/current-folder';
import { getColumnOptions, updateColumnOptions } from 'store/file-browser-column-options';
import {
  fileBrowserSelectItem,
  fileBrowserDeselectItem,
  fileBrowserSelectAllItems,
  fileBrowserDeselectAllItems,
  fileBrowserMouseDownItem
} from 'store/file-browser-selected-items';
import FileList from 'components/file-browser/file-list/file-list';

const mapStateToProps = ({ ui: { fileBrowserColumnOptions, fileBrowserSelectedItems } }) => ({
  selectedItems: fileBrowserSelectedItems,
  columnOptions: fileBrowserColumnOptions
});

const mapDispatchToProps = {
  deselectAllItems: fileBrowserDeselectAllItems,
  deselectItem: fileBrowserDeselectItem,
  getColumnOptions,
  mouseDownItem: fileBrowserMouseDownItem,
  moveItems,
  renameItem,
  selectAllItems: fileBrowserSelectAllItems,
  selectItem: fileBrowserSelectItem,
  updateColumnOptions
};

export default connect(mapStateToProps, mapDispatchToProps)(FileList);
