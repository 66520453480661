import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { KEYSTROKE_MAX_INTERVAL } from './constants';
import { debounce } from 'underscore';

/**
 * ValidTextField
 * Renders a text area with it's own title and handles the validation to
 * display an error message.
 * @extends Component
 **/
export default class ValidatedTextField extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    debounce: PropTypes.bool,
    errors: PropTypes.any,
    headingId: PropTypes.string.isRequired,
    isValid: PropTypes.any,
    maxLength: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onValid: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string
  };

  state = {
    value: this.props.value || '',
    isFirstTime: !this.props.value,
    isDirty: false
  };

  /**
   * Updates the value if it's valid and triggers the onValid function
   */
  updateValue = () => {
    const { onChange, maxLength, onValid } = this.props;
    const currentValue = this.state.value;
    if (currentValue && currentValue.length && currentValue.length <= maxLength) {
      onChange(currentValue);
    }

    onValid();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value, isFirstTime: false, isDirty: this.props.value !== '' });
    } else if (prevState.value !== this.state.value) {
      const shouldDebounce = this.props.debounce;
      if (shouldDebounce) {
        //underscore's debounce returns a debounceable fucntion, that's the one being invoked
        debounce(this.updateValue, KEYSTROKE_MAX_INTERVAL)();
      } else {
        this.updateValue(this.state.value);
      }
    }
  };

  /** Handles the change event of the input
   * @param {event} e onChange event
   * @param {object} e.target event target
   */
  onChange = ({ target }) => {
    this.setState({ value: target.value, isFirstTime: false, isDirty: target.value !== '' });
  };

  onBlur = () => {
    this.setState({ isFirstTime: false, isDirty: this.state.value !== '' }, this.updateValue);
  };

  onInvalid = e => e.preventDefault();

  render() {
    const { id, className, errors, headingId, maxLength, placeholder, isValid } = this.props;
    const { isFirstTime, value } = this.state;
    const isFieldValid = !errors || isFirstTime || (!isFirstTime && !!value && isValid);
    return (
      <div className={classNames('validated-textfield', className)}>
        <span className={classNames('validated-textfield__heading', !isFieldValid ? 'invalid' : '')}>
          <FormattedMessage id={headingId} />
        </span>
        <input
          id={id}
          className={classNames('validated-textfield__input', !isFieldValid ? 'invalid' : '')}
          type="text"
          onChange={this.onChange}
          onBlur={this.onBlur}
          onInvalid={this.onInvalid}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          required={true} //So it plays well within a html5 form for validation
        />
        <span className={classNames('validated-textfield__error', !isFieldValid ? 'invalid' : '')}>
          {!isFieldValid ? errors : ''}
        </span>
      </div>
    );
  }
}
