import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import { undoDocumentActions } from 'store/api';

class UndoButton extends Component {
  static propTypes = {
    documentViewerUndo: PropTypes.object,
    intl: intlShape,
    pages: PropTypes.array,
    popUndoStack: PropTypes.func
  };

  state = {
    disabled: false
  };

  getLastAction = () => {
    const { documentViewerUndo } = this.props;
    if (documentViewerUndo.stack.length > 0) {
      // Get latest action from the stack
      return documentViewerUndo.stack.slice(-1)[0].ids;
    }
  };

  goToPage = page => {
    window.postMessage({ document_page_change: page }, window.location.href);
  };

  onClick = () => {
    const { popUndoStack } = this.props;
    const id = this.getLastAction();
    this.setState({ disabled: true });
    // Call undo here
    popUndoStack();
    return undoDocumentActions(id.undoId).then(() => {
      this.setState({ disabled: false });
      // If we have a pagenumber stored, we can navigate to that page when a user clicks undo
      if (id.pageNumber) {
        this.goToPage(id.pageNumber);
      }
    });
  };

  render() {
    const { documentViewerUndo, intl } = this.props;
    const { disabled } = this.state;

    if (documentViewerUndo.stack.length <= 0) {
      return <div />;
    }

    if(!disabled){   
    return (  
      <Button size="icon" className="icon-button"  onClick={this.onClick} title={intl.formatMessage({ id: 'common.undo' })}>
      <Icon name="undo-black" width={15} />
      </Button>
    );
  }
  else {
    return (
    <Button size="icon" className="icon-button-disabled" disabled onClick={this.onClick} title={intl.formatMessage({ id: 'common.undo' })}>
    <Icon name="undo-black" width={15} />
    </Button>
    )
  }
  }
}

export default injectIntl(UndoButton);
