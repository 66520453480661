export const SEARCH_CATEGORIES = {
  DOCUMENT_NAME: 1,
  DOCUMENT_CONTENT: 2,
  EXTRACTION_FIELD_NAME: 3,
  FOLDER_NAME: 4,
  TEMPLATE_NAME: 5,
  DOCUMENT_TAG: 6,
  REVIEWER_NAME: 7,
  EXTRACTION_FIELD_GROUP_NAME: 8,
  EXTRACTION_FIELD_INSTANCE_TAG: 9,
  // This is a virutal category that only exists on the front end
  // It will be resolved to either a document tag or extraction field instance tag
  TAG: 100
};

export const SEARCH_OPERATORS = {
  AND: 1,
  OR: 2
};

export const SEARCH_MODIFIERS = {
  INCLUDE: 1,
  EXCLUDE: 2
};

export const SEARCH_RECORD_MODIFIERS = {
  INCLUDE: 3,
  EXCLUDE: 4
};

export const TAG_TYPE_MODIFIERS = {
  DOCUMENT: 6,
  RECORD: 9
};

export const GLOBAL_SEARCH_CATEGORIES = [
  SEARCH_CATEGORIES.DOCUMENT_NAME,
  SEARCH_CATEGORIES.EXTRACTION_FIELD_NAME,
  SEARCH_CATEGORIES.FOLDER_NAME,
  SEARCH_CATEGORIES.TEMPLATE_NAME,
  SEARCH_CATEGORIES.DOCUMENT_TAG,
  SEARCH_CATEGORIES.REVIEWER_NAME,
  SEARCH_CATEGORIES.EXTRACTION_FIELD_GROUP_NAME,
  SEARCH_CATEGORIES.EXTRACTION_FIELD_INSTANCE_TAG
];

export const SEARCH_CATEGORY_DISPLAY_NAMES = {
  [SEARCH_CATEGORIES.DOCUMENT_NAME]: 'search.categories.DocumentTitle',
  [SEARCH_CATEGORIES.DOCUMENT_CONTENT]: 'search.categories.DocumentContent',
  [SEARCH_CATEGORIES.FOLDER_NAME]: 'search.categories.Folders',
  [SEARCH_CATEGORIES.EXTRACTION_FIELD_NAME]: 'search.categories.ExtractionFields',
  [SEARCH_CATEGORIES.TEMPLATE_NAME]: 'search.categories.Templates',
  [SEARCH_CATEGORIES.DOCUMENT_TAG]: 'search.categories.DocumentTags',
  [SEARCH_CATEGORIES.REVIEWER_NAME]: 'search.categories.Reviewers',
  [SEARCH_CATEGORIES.EXTRACTION_FIELD_INSTANCE_TAG]: 'search.categories.ExtractionFieldInstanceTag',
  [SEARCH_CATEGORIES.TAG]: 'search.categories.Tags'
};

export const SEARCH_CATEGORY_VERBOSE_NAMES = {
  [SEARCH_CATEGORIES.DOCUMENT_NAME]: 'search.categories.DocumentTitle',
  [SEARCH_CATEGORIES.DOCUMENT_CONTENT]: 'search.categories.DocumentContent',
  [SEARCH_CATEGORIES.FOLDER_NAME]: 'search.categories.Folders',
  [SEARCH_CATEGORIES.EXTRACTION_FIELD_NAME]: 'search.categories.ExtractionFields',
  [SEARCH_CATEGORIES.TEMPLATE_NAME]: 'search.categories.Templates',
  [SEARCH_CATEGORIES.DOCUMENT_TAG]: 'search.categories.DocumentTags.verbose',
  [SEARCH_CATEGORIES.REVIEWER_NAME]: 'search.categories.Reviewers',
  [SEARCH_CATEGORIES.EXTRACTION_FIELD_INSTANCE_TAG]: 'search.categories.ExtractionFieldInstanceTag.verbose',
  [SEARCH_CATEGORIES.TAG]: 'search.categories.Tags'
};

export const SEARCH_OPERATOR_DISPLAY_NAMES = {
  [SEARCH_OPERATORS.AND]: 'search-filter.and',
  [SEARCH_OPERATORS.OR]: 'search-filter.or'
};

export const SEARCH_MODIFIER_DISPLAY_NAMES = {
  [SEARCH_MODIFIERS.INCLUDE]: 'search-filter.include',
  [SEARCH_MODIFIERS.EXCLUDE]: 'search-filter.exclude'
};

export const SEARCH_RECORD_MODIFIER_DISPLAY_NAMES = {
  [SEARCH_RECORD_MODIFIERS.INCLUDE]: 'search-filter.records',
  [SEARCH_RECORD_MODIFIERS.EXCLUDE]: 'search-filter.no-records'
};

export const TAG_TYPE_DISPLAY_NAMES = {
  [TAG_TYPE_MODIFIERS.DOCUMENT]: 'search-filter.document-tag',
  [TAG_TYPE_MODIFIERS.RECORD]: 'search-filter.record-tag'
};

// Search Categories
export const RESULT_CATEGORIES = [
  SEARCH_CATEGORIES.DOCUMENT_NAME,
  SEARCH_CATEGORIES.FOLDER_NAME,
  SEARCH_CATEGORIES.EXTRACTION_FIELD_NAME,
  SEARCH_CATEGORIES.TEMPLATE_NAME,
  SEARCH_CATEGORIES.DOCUMENT_TAG,
  SEARCH_CATEGORIES.REVIEWER_NAME,
  SEARCH_CATEGORIES.EXTRACTION_FIELD_INSTANCE_TAG
];

export const RESULT_CATEGORY_KEYS = {
  [SEARCH_CATEGORIES.DOCUMENT_NAME]: 'documents',
  [SEARCH_CATEGORIES.FOLDER_NAME]: 'folders',
  [SEARCH_CATEGORIES.EXTRACTION_FIELD_NAME]: 'extractions',
  [SEARCH_CATEGORIES.TEMPLATE_NAME]: 'templates',
  [SEARCH_CATEGORIES.DOCUMENT_TAG]: 'documentTags',
  [SEARCH_CATEGORIES.REVIEWER_NAME]: 'reviewers',
  [SEARCH_CATEGORIES.EXTRACTION_FIELD_INSTANCE_TAG]: 'extractionFieldInstanceTags'
};

export const RESULT_CATEGORY_ID_KEYS = {
  [SEARCH_CATEGORIES.DOCUMENT_NAME]: 'documentId',
  [SEARCH_CATEGORIES.FOLDER_NAME]: 'folderId',
  [SEARCH_CATEGORIES.EXTRACTION_FIELD_NAME]: 'extractionFieldId',
  [SEARCH_CATEGORIES.TEMPLATE_NAME]: 'templateId',
  [SEARCH_CATEGORIES.DOCUMENT_TAG]: 'documentTagId',
  [SEARCH_CATEGORIES.REVIEWER_NAME]: 'userId',
  [SEARCH_CATEGORIES.EXTRACTION_FIELD_INSTANCE_TAG]: 'extractionFieldInstanceTagId'
};
