import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import FileBrowserContainer from 'containers/file-browser/file-browser-container';
import Header from 'containers/header/header';
import NotificationsContainer from 'containers/shared/notifications-container';
import ProjectsLeftMenu from 'components/project-list/project-menu-bar/project-left-menu';
import Toolbar from 'components/project-list/toolbar/toolbar';

import Permissions from 'permissions/permissions';
import history from 'utils/history';

class FileBrowserPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    projectList: PropTypes.object,
    currentProject: PropTypes.object,
    currentUser: PropTypes.object
  };

  state = {
    auth: false
  };

  UNSAFE_componentWillMount() {
    if (!Permissions.Project.Document.canView()) {
      history.push('/');
    } else {
      this.setState({ auth: true });
    }
  }

  render() {
    const { folderId } = this.props.match.params;

    return this.state.auth ? (
      <div className="page">
         <Header {...this.props} currentPage="global.subheader.extraction" />
        
        {/* <Toolbar  {...this.props}/> */}
        <ProjectsLeftMenu {...this.props} />
        <FileBrowserContainer folderId={folderId} {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    ) : null;
  }
}

export default FileBrowserPage;
