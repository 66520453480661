import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Redirect } from 'react-router';
import Constants from 'utils/constants';

import Container from 'components/project-creation/shared/container';
import Icon from 'components/shared/icon';
import Permissions from 'permissions/permissions';
import PPMDApproval from 'components/project-creation/shared/ppmd-approval';

import MatSearchForm from 'components/project-creation/project-details/mat-search-form/mat-search-form';
import ProjectDetailsForm from 'components/project-creation/project-details/project-details-form/project-details-form';
import * as api from 'store/api';
import {
  createProject,
  createProjectManually,
  getEngagementDetails,
  getCurrentRegion,
  getCurrentAPI,
  getProject
} from 'store/api';

/*
 * Project Details component
 * Allows user to edit the project name and select a MAT engagement
 */
class ProjectDetails extends Component {
  static propTypes = {
    createProjectTrack: PropTypes.func,
    history: PropTypes.object,
    intl: intlShape.isRequired,
    onChangeStep: PropTypes.func,
    project: PropTypes.any,
    projectId: PropTypes.any,
    projectState: PropTypes.object,
    step: PropTypes.number,
    steps: PropTypes.array,
    updateEngagementManually: PropTypes.func,
    updateClientManually: PropTypes.func,
    updateProjectEngagement: PropTypes.func,
    updateProjectName: PropTypes.func,
    user: PropTypes.object,
    updateProjectEngagementManually: PropTypes.func
  };

  state = {
    projectName: this.props.project.projectName,
    engagementId: '',
    engagementName: '',
    clientId: '',
    clientName: '',
    countryCode: '',
    countryName: '',
    isLoading: true,
    isLoadingDate: false,
    isValid: false,
    isNameValid: null,
    isNameChanged: false,
    isNameResolved: false,
    isWBSResolved: false,
    isMatChanged: false,
    isDateFormatChanged: false,
    engagementInfo: null,
    isEngagementInfoChanged: false,
    isClientChanged: false,
    clientInfo: null,
    isClientInfoChanged: false,
    selectedProjectDateFormat: null,
    selectedDateFormat: 'MM/dd/yyyy'
  };

  componentDidMount() {
    const {
      project: { engagementId },
      projectState: { SETTINGS, INPROGRESS, COPY, ARCHIVE }
    } = this.props;

    this.setCountryInfo();

    if (engagementId && (SETTINGS || INPROGRESS || ARCHIVE || COPY)) {
      return this.getEngagementDetails(engagementId);
    } else {
      this.setState({ isLoading: false });
    }
  }

  UNSAFE_componentWillMount() {
    const {
      project,
      projectState: { SETTINGS, INPROGRESS, COPY, ARCHIVE }
    } = this.props;

    if (project.projectId && (SETTINGS || INPROGRESS || ARCHIVE)) {
      const projectId = project.projectId;
      this.setState({ isLoadingDate: true });
      getProject({ projectId }).then(res => {
        localStorage.setItem('userSelectedDateFormat', res.data.dateFormatPreference);
        this.setState({ selectedProjectDateFormat: res.data.dateFormatPreference, isLoadingDate: false });
      });
    }
  }

  doesProjectSupportMATSearch = () => {
    const { project } = this.props;

    if (project && project.sourceType) {
      return (
        project.sourceType === Constants.ProjectSourceTypes.MAT ||
        project.sourceType === Constants.ProjectSourceTypes.MATCloud
      );
    }

    var currentAPI = getCurrentAPI();

    if (!currentAPI || currentAPI === null) return false;

    var { memberFirmCode, containerCode } = currentAPI;
    var { user } = this.props;

    var memberFirmContainer = user.memberFirmContainers.filter(
      container => container.containerCode === containerCode && container.memberFirmCode === memberFirmCode
    );

    memberFirmContainer = memberFirmContainer[0];

    return memberFirmContainer && !memberFirmContainer.isUserEnteredEngagementSupported;
  };

  setCountryInfo = () => {
    const region = getCurrentAPI();
    const { memberFirmContainers } = this.props.user;
    const { memberFirmCode, containerCode, countryCode } = region;

    if (countryCode) {
      memberFirmContainers.forEach(mfc => {
        if (mfc.countryCode === countryCode && mfc.containerCode == containerCode) {
          this.setState({ countryCode: mfc.countryCode, countryName: mfc.countryName });
        }
      });
    } else {
      //If no country code is specified, get first matching country name for region code combo
      memberFirmContainers.forEach(mfc => {
        if (mfc.memberFirmCode === memberFirmCode && mfc.containerCode === containerCode) {
          this.setState({ countryCode: mfc.countryCode, countryName: mfc.countryName });
        }
      });
    }
  };

  // Fetch the MAT engagement details
  getEngagementDetails = engagementId => {
    this.setState({ isLoading: true });
    return getEngagementDetails(engagementId).then(res => {
      return this.setState({ engagementInfo: res.data, isLoading: false, clientInfo: res.data });
    });
  };
  onDateFormatChange = e => {
    this.setState({ selectedDateFormat: e });
    this.setState({ isDateFormatChanged: true });
  };
  updateProjectDateFormatSelection(projectId, selectedDateFormat) {
    this.setState({ isDateFormatChanged: true });
    localStorage.setItem('userSelectedDateFormat', selectedDateFormat);
    api
      .updateProjectDatePreference(projectId, selectedDateFormat)
      .then(res => {})
      .catch(() => {});
  }
  // Update project name
  updateProjectName = () => {
    const {
      project: { projectId },
      updateProjectName
    } = this.props;

    const { projectName } = this.state;
    return updateProjectName({
      projectId,
      projectName
    });
  };

  // Update MAT engagement
  updateProjectEngagement = value => {
    const { project, updateProjectEngagement, updateProjectEngagementManually } = this.props;
    const { engagementName, engagementInfo, countryCode } = this.state;

    if (value && value?.sourceType === Constants.ProjectSourceTypes.MATCloud && value?.engagementId == 0)
      return updateProjectEngagementManually({
        projectId: project.projectId,
        projectName: project.projectName,
        engagementName: value.engagementName,
        externalEngagementId: value.externalEngagementId,
        clientName: value.clientName,
        clientId: value.clientId,
        fiscalYearEnd: value.engagementFiscalYearEnd,
        countryCode,
        sourceType: value.sourceType
      });
    else if(value && value.wbsCode){
      return updateProjectEngagementManually({
        projectId: project.projectId,
        projectName: project.projectName,
        engagementName: value.engagementName,
        externalEngagementId: value.externalEngagementId,
        clientName: value.clientName,
        clientId: value.clientId,
        fiscalYearEnd: value.engagementFiscalYearEnd,
        countryCode,
        sourceType: null,
        wbsCode: value.wbsCode
      });
    }
    else
      return updateProjectEngagement({
        projectId: project.projectId,
        engagementId: value.engagementId,
        engagementName: engagementName
      });
  };

  updateProjectEngagementManually = () => {
    const { project, updateEngagementManually } = this.props;
    const { engagementInfo, isEngagementInfoChanged } = this.state;

    const canSearchMAT = this.doesProjectSupportMATSearch();

    if (!canSearchMAT && isEngagementInfoChanged && engagementInfo.wbsCode) {
      updateEngagementManually({
        projectId: project.projectId,
        engagementId: project.engagementId,
        engagementName: engagementInfo.engagementName,
        externalEngagementId: engagementInfo.externalEngagementId,
        clientName: engagementInfo.clientName,
        clientId: engagementInfo.clientId,
        fiscalYearEnd: engagementInfo.engagementFiscalYearEnd,
        wbsCode: engagementInfo.wbsCode
      }).then(res => {
        getEngagementDetails(project.engagementId).then(res => {
          this.setState({ engagementInfo: res.data });
        });
      });
    }
  };

  // Create a new project
  createNewProject = () => {
    const { createProjectTrack } = this.props;
    const {
      projectName,
      engagementId,
      engagementInfo,
      countryCode,
      isDateFormatChanged,
    } = this.state;
    const canSearchMAT = this.doesProjectSupportMATSearch();
    const projectTypeId = Constants.ProjectTypes.EXTRACTION_PROJECT;

    if (projectName && engagementId && canSearchMAT) {
      return createProject({ projectName, countryCode, engagementId, projectTypeId}).then(res => {
        createProjectTrack(res.data);
        this.setState({ newProjectId: res.data.projectId });
      });
    } else if (
      engagementInfo &&
      (!canSearchMAT || engagementInfo.sourceType === Constants.ProjectSourceTypes.MATCloud)
    ) {
      const {
        externalEngagementId,
        engagementName,
        clientName,
        clientId,
        engagementFiscalYearEnd,
        sourceType,
        wbsCode
      } = engagementInfo;

      if (engagementInfo.sourceType === Constants.ProjectSourceTypes.MATCloud)
        return createProjectManually({
          projectName,
          countryCode,
          engagementName,
          externalEngagementId,
          clientName,
          clientId,
          fiscalYearEnd: engagementFiscalYearEnd,
          wbsCode: null,
          sourceType,
          projectTypeId 
        }).then(res => {
          createProjectTrack(res.data);
          this.setState({ newProjectId: res.data.projectId });
        });
      else
        return createProjectManually({
          projectName,
          countryCode,
          engagementName,
          externalEngagementId,
          clientName,
          clientId,
          fiscalYearEnd: engagementFiscalYearEnd,
          wbsCode,
          sourceType: null,
          projectTypeId 
        }).then(res => {
          createProjectTrack(res.data);
          this.setState({ newProjectId: res.data.projectId });
        });
    }
  };

  // Update an existing project
  updateProject = () => {
    const { onChangeStep, step, project, updateEngagementManually, projectId } = this.props;
    const {
      isNameChanged,
      engagementId,
      engagementInfo,
      isEngagementInfoChanged,
      isDateFormatChanged,
      selectedDateFormat
    } = this.state;
    const canSearchMAT = this.doesProjectSupportMATSearch();
    const promises = [];

    if (isNameChanged) {
      promises.push(this.updateProjectName());
    }

    if (
      canSearchMAT &&
      (engagementId || engagementInfo?.sourceType === Constants.ProjectSourceTypes.MATCloud) &&
      engagementId !== project.engagementId
    ) {
      promises.push(this.updateProjectEngagement(engagementInfo));
    }

    if (!canSearchMAT && isEngagementInfoChanged) {
      if(engagementId && engagementId != 0){
        promises.push(
          updateEngagementManually({
            projectId: project.projectId,
            engagementId: project.engagementId,
            engagementName: engagementInfo.engagementName,
            externalEngagementId: engagementInfo.externalEngagementId,
            clientName: engagementInfo.clientName,
            clientId: engagementInfo.clientId,
            fiscalYearEnd: engagementInfo.engagementFiscalYearEnd,
            wbsCode: engagementInfo.wbsCode
          })
        );
      }
      else
        promises.push(this.updateProjectEngagement(engagementInfo)
      );
    }

    if (isDateFormatChanged) {
      promises.push(this.updateProjectDateFormatSelection(projectId, selectedDateFormat));
    }

    return Promise.all(promises).then(() => {
      onChangeStep(step + 1);
    });
  };

  // Handle clicking the next button
  onNext = () => {
    const { isEngagementValid, isNameValid, isClientValid } = this.state;
    const { projectId } = this.props;

    // If there are pending promises then we have to catch it here to make sure user doesnt create an invalid project
    if (!isEngagementValid || !isNameValid || !isClientValid) {
      return;
    }

    this.setState({ isEngagementValid: false, isNameValid: false, isClientValid: false });

    if (!projectId) {
      // If there is no projectId it means we are creating a new project
      return this.createNewProject();
    } else {
      // Otherwise update the existing project
      this.updateProject();
    }
  };

  // Update the engagement id
  setEngagementId = engagementId => this.setState({ engagementId });

  // Update the client id
  setClientId = clientId => this.setState({ clientId });

  // Set the engagement info after its fetched
  setEngagementInfo = (engagementInfo, manual) => this.setState({ engagementInfo, isEngagementInfoChanged: manual });

  // Set the client info after its fetched
  setClientInfo = (clientInfo, manual) => this.setState({ clientInfo, isClientInfoChanged: manual });

  // Update the engagement name
  onEngagementNameChange = engagementName => this.setState({ engagementName });

  // Update the client name
  onClientNameChange = clientName => {
    this.setState({ clientName });
  };

  // Update the project name
  onProjectNameChange = projectName => this.setState({ projectName, isNameChanged: true });

  // Update validation for the engagement
  setEngagementValidation = isEngagementValid => this.setState({ isEngagementValid });

  // Update validation for the client
  setClientValidation = isClientValid => this.setState({ isClientValid });

  // Update validation for the project name
  setNameValidation = isNameValid => this.setState({ isNameValid });

  // Update whether ths name is resolved
  setNameResolved = isNameResolved => this.setState({ isNameResolved });

  // Update whether ths wbsCode is resolved
  setWBSCodeResolved = isWBSResolved => this.setState({ isWBSResolved });

  render() {
    const {
      engagementInfo,
      clientInfo,
      newProjectId,
      isEngagementValid,
      isClientValid,
      isNameValid,
      isLoading,
      isNameResolved,
      countryName,
      selectedProjectDateFormat,
      isLoadingDate,
      isWBSResolved
    } = this.state;
    const { step, steps, intl, onChangeStep, project, projectState, projectId } = this.props;
     
    const projectName = {
      projectName:
        projectState.SETTINGS || projectState.COPY || projectState.ARCHIVE || (project.projectTypeId === Constants.ProjectTypes.WORKSPACE_PROJECT)
          ? project.projectName
          : intl.formatMessage({ id: 'project-details.heading' })
    };

    const allowEditing =
      (Permissions.Project.Settings.canEdit(project) && !Permissions.Project._States.isReadOnly(project)) ||
      (Permissions.Project.Settings.canEditAsAdmin(project) && !project.STATES.ReadOnly());

    if (newProjectId && !projectId) {
      return <Redirect to={{ pathname: `/region/${getCurrentRegion()}/project/${newProjectId}/creation` }} />;
    }

    const canSearchMAT = this.doesProjectSupportMATSearch();

    return (
      <Container
        customHeader={<PPMDApproval {...this.props} />}
        customPrevButton={<div style={{ display: 'none' }} />}
        onCustomNext={this.onNext}
        disabled={!isClientValid || !isEngagementValid || !isNameValid || !isNameResolved || !isWBSResolved}
        project={projectName}
        step={step}
        steps={steps}
        onChangeStep={onChangeStep}
        projectState={projectState}
        disableBothButtons={projectState.SETTINGS || projectState.ARCHIVE ? true : false}
      >
        {isLoading || isLoadingDate ? (
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        ) : canSearchMAT ? (
          <MatSearchForm
            allowEditing={allowEditing}
            engagementInfo={engagementInfo}
            clientInfo={clientInfo}
            selectedProjectDateFormat={selectedProjectDateFormat}
            intl={intl}
            project={project}
            projectState={projectState}
            onEngagementNameChange={this.onEngagementNameChange}
            onClientNameChange={this.onClientNameChange}
            onProjectNameChange={this.onProjectNameChange}
            setEngagementId={this.setEngagementId}
            setEngagementInfo={this.setEngagementInfo}
            setEngagementValidation={this.setEngagementValidation}
            setClientId={this.setClientId}
            setClientInfo={this.setClientInfo}
            setClientValidation={this.setClientValidation}
            setNameResolved={this.setNameResolved}
            setNameValidation={this.setNameValidation}
            updateProjectEngagement={this.updateProjectEngagement}
            updateProjectName={this.updateProjectName}
            countryName={project.countryName ? project.countryName : countryName}
            customHeader={<PPMDApproval {...this.props} />}
            onDateFormatChange={e => this.onDateFormatChange(e)}
            setWBSCodeResolved={this.setWBSCodeResolved}
          />
        ) : (
          <ProjectDetailsForm
            allowEditing={allowEditing || projectId === undefined}
            engagementInfo={engagementInfo}
            clientInfo={clientInfo}
            selectedProjectDateFormat={selectedProjectDateFormat}
            intl={intl}
            project={project}
            projectState={projectState}
            onEngagementNameChange={this.onEngagementNameChange}
            onClientNameChange={this.onClientNameChange}
            onProjectNameChange={this.onProjectNameChange}
            setEngagementId={this.setEngagementId}
            setEngagementInfo={this.setEngagementInfo}
            setEngagementValidation={this.setEngagementValidation}
            setClientId={this.setClientId}
            setClientInfo={this.setClientInfo}
            setClientValidation={this.setClientValidation}
            setNameResolved={this.setNameResolved}
            setNameValidation={this.setNameValidation}
            updateProjectEngagement={this.updateProjectEngagementManually}
            updateProjectName={this.updateProjectName}
            countryName={project.countryName ? project.countryName : countryName}
            onDateFormatChange={e => this.onDateFormatChange(e)}
            setWBSCodeResolved={this.setWBSCodeResolved}
            customHeader={<PPMDApproval {...this.props}
            setClientValidation={this.setClientValidation}
            
          />}
          />
        )}
      </Container>
    );
  }
}

export default injectIntl(ProjectDetails);
