import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

import ProjectDocumentExtractionFieldRecord from 'permissions/sections/project-document-extraction-field-record';

const ProjectDocumentExtractionFieldPermissions = {
  ExtractionFieldRecord: ProjectDocumentExtractionFieldRecord,

  canDelete: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canView: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  }
};

export default ProjectDocumentExtractionFieldPermissions;
