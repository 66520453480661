import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ArgusExtraction from './argus-extraction';
import RecentProjects from './recent-projects';
import ArgusTranslation from './argus-translation';
import TranslationQueue from './translation-queue';

class LandingPage extends Component {
  static propTypes = {
    currentLanguage: PropTypes.object,
    currentProject: PropTypes.object,
    currentUser: PropTypes.object,
    history: PropTypes.object,
    loadProjectList: PropTypes.func.isRequired,
    loadTranslationsList: PropTypes.func.isRequired,
    projectList: PropTypes.object,
    translations: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      isAllowed:
        props.currentUser && props.currentUser.accessibleFeatures
          ? props.currentUser.accessibleFeatures.reduce((obj, item) => {
              return {
                ...obj,
                [item['featureId']]: item
              };
            }, {})
          : {}
    };
  }

  componentDidMount() {
    const { isAllowed } = this.state;
    const { loadProjectList, loadTranslationsList } = this.props;

    if (isAllowed.hasOwnProperty('0')) {
      loadProjectList();
    }

    if (isAllowed.hasOwnProperty('1')) {
      loadTranslationsList();
    }
  }

  render() {
    const { currentProject, currentUser, projectList, translations, currentLanguage } = this.props;
    const { isAllowed } = this.state;

    return (
      <div className="landing-page__wrapper">        
        <div className="landing-page__grid">
          <div className="landing-page__tile">
            <ArgusExtraction currentUser={currentUser} isAllowed={isAllowed.hasOwnProperty('0')} />
          </div>
          <div className="landing-page__tile">
            <RecentProjects
              projectList={projectList}
              currentUser={currentUser}
              currentLanguage={currentLanguage}
              currentProject={currentProject}
              isAllowed={isAllowed.hasOwnProperty('0')}
            />
          </div>
          <div className="landing-page__tile">
            <ArgusTranslation isAllowed={isAllowed.hasOwnProperty('1')} />
          </div>
          <div className="landing-page__tile">
            <TranslationQueue
              translationList={translations}
              isAllowed={isAllowed.hasOwnProperty('1')}
              currentLanguage={currentLanguage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
