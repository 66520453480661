import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component } from 'react';

import { getUsers } from 'store/api';
import { ROLES, ROLE_DISPLAY_NAMES } from 'models/user';
import Avatar from 'components/shared/avatar';
import Button from 'components/shared/single-click-button';
import Dropdown from 'components/shared/form/dropdown';
import Icon from 'components/shared/icon';
import UserAutocomplete from 'components/shared/user-autocomplete/user-autocomplete';

/**
 * Pending user
 * One row of the list of pending users to share the field or group with
 * Displays a dropdown for changing their role and a remove button
 */
class PendingUser extends Component {
  static propTypes = {
    removePendingUser: PropTypes.func.isRequired,
    role: PropTypes.number.isRequired,
    updatePendingUserRole: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  removePendingUser = () => {
    const { removePendingUser, user } = this.props;
    removePendingUser(user);
  };

  updatePendingUserRole = role => {
    const { updatePendingUserRole, user } = this.props;
    updatePendingUserRole(user, role);
  };

  render() {
    const { user, role } = this.props;
    return (
      <div className="share-popover__pending-user-item">
        <Avatar className="share-popover__pending-user-avatar" initials={user.firstName[0] + user.lastName[0]} />
        <div className="share-popover__pending-user-name">{user.displayName}</div>
        <Dropdown
          className="share-popover__pending-user-role"
          options={[ROLES.EXTRACTION_FIELD_OWNER, ROLES.EXTRACTION_FIELD_CONTRIBUTOR]}
          renderOption={roleId => <FormattedMessage id={ROLE_DISPLAY_NAMES[roleId]} />}
          selectedOption={role}
          onSelection={this.updatePendingUserRole}
        />
        <Button size="icon" className="share-popover__pending-user-close icon-button" onClick={this.removePendingUser}>
          <Icon name="special-cross-black" width={10} />
        </Button>
      </div>
    );
  }
}

/**
 * Share Popover
 * Allows a field or group to be shared with a list of users
 */
class SharePopover extends Component {
  static propTypes = {
    intl: intlShape,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    selectedRole: ROLES.EXTRACTION_FIELD_OWNER,
    selectedUser: null,
    selectedUserName: '',
    pendingUsers: [],
    isValidMember: false
  };

  /**
   * Close the popover when clicking outside
   */
  handleClickOutside() {
    const { onClose } = this.props;
    onClose();
  }

  /**
   * Submit the modal
   */
  onSubmit = event => {
    const { onSubmit } = this.props;
    const { pendingUsers } = this.state;
    event.preventDefault();
    onSubmit(pendingUsers);
  };

  /**
   * Handle the role selected event
   */
  onRoleSelected = role => {
    this.setState({ selectedRole: role });
  };

  /**
   * Handle a change event on the user autocomplete
   */
  onUserNameChange = event => {
    const value = event.target.value;
    this.setState({ selectedUserName: event.target.value, isValidMember: false });
    if (!value) {
      this.onUserSelected('', null);
    }
  };

  /**
   * Handle a select event on the user autocomplete
   */
  onUserSelected = (userName, user) => {
    this.setState({
      selectedUserName: userName,
      selectedUser: user,
      isValidMember: user ? true : false
    });
  };

  /**
   * Handle the add user button click
   */
  onAddUserClick = event => {
    const { selectedUser, selectedRole, pendingUsers } = this.state;
    event.preventDefault();

    // Only add the user if they are not already in the list of pending users
    if (!pendingUsers.find(pendingUser => pendingUser.user === selectedUser)) {
      this.setState({
        pendingUsers: [
          ...pendingUsers,
          {
            user: selectedUser,
            role: selectedRole
          }
        ],
        selectedUserName: '',
        selectedUser: null,
        selectedRole: ROLES.EXTRACTION_FIELD_OWNER
      });
    }
  };

  /**
   * Handle removing a pending user
   */
  removePendingUser = user => {
    this.setState({ pendingUsers: this.state.pendingUsers.filter(pendingUser => pendingUser.user !== user) });
  };

  /**
   * Handle updating the role of a pending user
   */
  updatePendingUserRole = (user, role) => {
    const { pendingUsers } = this.state;
    const pendingUser = pendingUsers.find(pendingUser => pendingUser.user === user);
    pendingUser.role = role;
    this.setState({ pendingUsers: [...pendingUsers] });
  };

  searchUsers = keyword => {
    return getUsers(keyword, true).then(res => res.data);
  };

  render() {
    const {
      onClose,
      intl: { formatMessage }
    } = this.props;

    const { selectedRole, selectedUser, pendingUsers, selectedUserName, isValidMember } = this.state;

    return (
      <div className="share-popover popover">
        <div className="share-popover__header popover__header">
          <h3 className="popover__title">
            <FormattedMessage id="quick-study.toolbar.share.title" />
          </h3>
          <Button onClick={onClose} className="btn btn-no-margin share-popover__clear-button icon-button">
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className="share-popover__list popover__section">
          <form className="share-popover__add-user-form">
            <div className="share-popover__add-user-form-field form-field">
              <label>
                <FormattedMessage id="quick-study.toolbar.share.user-label" />
              </label>
              <UserAutocomplete
                keyword={selectedUserName}
                onChange={this.onUserNameChange}
                onSelect={this.onUserSelected}
                placeholder={formatMessage({ id: 'quick-study.toolbar.share.user-placeholder' })}
                searchUsers={this.searchUsers}
                selectedUserName={selectedUserName}
              />
            </div>
            <div className="share-popover__add-user-form-field form-field">
              <label>
                <FormattedMessage id="quick-study.toolbar.share.role-label" />
              </label>
              <Dropdown
                className="share-popover__role-dropdown"
                options={[ROLES.EXTRACTION_FIELD_OWNER, ROLES.EXTRACTION_FIELD_CONTRIBUTOR]}
                renderOption={roleId => <FormattedMessage id={ROLE_DISPLAY_NAMES[roleId]} />}
                selectedOption={selectedRole}
                onSelection={this.onRoleSelected}
                defaultMessage={formatMessage({
                  id: 'quick-study.toolbar.share.role-placeholder'
                })}
              />
            </div>
            <div className="share-popover__add-user-form-field form-field">
              <Button
                className="btn share-popover__add-user-button"
                disabled={!selectedUser || !selectedRole || !isValidMember}
                onClick={this.onAddUserClick}
                type="submit"
              >
                <FormattedMessage id="quick-study.toolbar.share.add-button" />
              </Button>
            </div>
          </form>
          {!!pendingUsers.length && (
            <div className="share-popover__pending-users-section">
              <label>
                <FormattedMessage id="quick-study.toolbar.share.access-label" />:
              </label>
              <div className="share-popover__pending-users-list">
                {pendingUsers.map(({ user, role }) => (
                  <PendingUser
                    key={user.userId}
                    user={user}
                    role={role}
                    removePendingUser={this.removePendingUser}
                    updatePendingUserRole={this.updatePendingUserRole}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="share-popover__footer popover__footer">
          <Button size="xsmall" onClick={onClose} className="btn btn-secondary">
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="xsmall"
            disabled={!pendingUsers.length}
            onClick={this.onSubmit}
            className="btn btn-primary btn-no-margin"
          >
            <FormattedMessage id="common.share" />
          </Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(SharePopover));
