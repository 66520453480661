import { FormattedMessage, FormattedDate } from 'react-intl';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import { MAT_ENGAGEMENT_CATEGORIES } from 'components/project-creation/constants';

/*
 * Highlighter
 * Used to determine which text matches the keyword so we can mark/highlight it
 */
const Highlighted = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }

  const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  const regex = new RegExp(`(${escapeRegExp(highlight.trim())})`, 'gi');
  const parts = String(text).split(regex);
  return (
    <span>
      {parts
        .filter(part => part)
        .map((part, i) => (regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>))}
    </span>
  );
};

Highlighted.propTypes = {
  highlight: PropTypes.any,
  text: PropTypes.any
};

/*
 * Engagement Details Dropdown
 * The dropdown for handling user selection of engagement detailst
 */
export default enhanceWithClickOutside(
  class EngagementDetailsDropdown extends Component {
    static propTypes = {
      data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      keyword: PropTypes.string,
      onClickOutside: PropTypes.func,
      onMATClick: PropTypes.func
    };

    handleClickOutside = () => {
      if (this.props.onClickOutside) {
        this.props.onClickOutside();
      }
    };

    render() {
      const { onMATClick, keyword, data } = this.props;

      return (
        <div className="engagement-dropdown">
          <div className="engagement-dropdown__fix-table-wrapper">
            <FixedTable className="engagement-dropdown__fix-table">
              <table className="engagement-dropdown__table">
                <thead className="engagement-dropdown__heading">
                  <tr>
                    {MAT_ENGAGEMENT_CATEGORIES.map((category, i) => {
                      return (
                        <FixedHeader key={i} className={`engagement-dropdown__heading-item mat-${category.value}`}>
                          <FormattedMessage id={`engagement-details.${category.value}`} />
                        </FixedHeader>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="engagement-dropdown__value">
                  {data &&
                    data.length > 0 &&
                    data.map((v, i) => (
                      <tr className="engagement-dropdown__value-item" key={i} onClick={() => onMATClick(v)}>
                        <td>
                          <Highlighted text={v.engagementName ? v.engagementName : ''} highlight={keyword} />
                        </td>
                        <td>
                          <Highlighted
                            text={v.externalEngagementId ? v.externalEngagementId : ''}
                            highlight={keyword}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </FixedTable>
          </div>
          <div className="engagement-dropdown__results-footer">
            <FormattedMessage id="engagement-details.results" values={{ numResults: data && data.length }} />
          </div>
        </div>
      );
    }
  }
);
