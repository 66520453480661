import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from 'components/shared/single-click-button';
import Switch from 'components/shared/form/switch';
import Icon from 'components/shared/icon';
import SortByDropdown from './sort/sort';

import Permissions from 'permissions/permissions';
import constants from 'utils/constants';

class Toolbar extends Component {
  static propTypes = {
    iconValue: PropTypes.string,
    isSelected: PropTypes.bool,
    loadState: PropTypes.object,
    onCreate: PropTypes.func,
    onIconClick: PropTypes.func,
    onSortClick: PropTypes.func,
    showIcons: PropTypes.bool,
    sortValue: PropTypes.string,
    userMode: PropTypes.bool,
    onUserModeChange: PropTypes.func,
    currentUser: PropTypes.object,
    loadProjectList: PropTypes.func,
    onSearchChange: PropTypes.func,
    getContainerGEO : PropTypes.func,
    getCurrentMemberFirmCode : PropTypes.func,
    getCurrentContainer : PropTypes.func,
    isWorkspaceButtonHidden : PropTypes.bool
  };

  // Pass in value of either tile or table so parent knows which one its being toggled
  onIconClick = value => {
    this.props.onIconClick(value);
  };

  componentDidMount() {
    this.props.getCurrentContainer();
    this.props.getContainerGEO();
    this.props.getCurrentMemberFirmCode();
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  onCreateProjectType = type => {
    const { onCreate } = this.props;
    onCreate(type);
  };

  render() {
    const {
      showIcons,
      onSortClick,
      sortValue,
      loadState,
      onUserModeChange,
      userMode,
      currentUser,
      getCurrentContainer,
      onContainerChange,
      isWorkspaceButtonHidden
    } = this.props;
    
    const { isOpen } = this.state;
    return (
      <div className="project-list-toolbar">
        {/* <h2 className="project-list-toolbar__title">
          <FormattedMessage id="project-list.toolbar.title" />
          {loadState.isPartialLoading ? <Icon className="spinner spinner--centered" name="loader" width={25} /> : null}
        </h2> */}

        {Permissions.Global.canToggleAdmin() ? (
          <div className="project-filter">
            <span className="my-projects-label">
              <FormattedMessage id="project-list.toolbar.admin-toggle.my-projects" />
            </span>
            <Switch
              className="projects-list-toggle"
              onChange={() => onUserModeChange(!userMode)}
              isOn={userMode}
              id="adminModeToggle"
            />
          </div>
        ) : null}
        {Permissions.Global.canViewAdminContainerDropdown() && !userMode ? (
          <div className="container-dropdown">
            <select onChange={(e)=>{onContainerChange(e.target.value)}}>
              {currentUser?.adminContainers?.map(
                  (item) => <option key={item.containerCode} value={JSON.stringify(item)} selected={item.containerCode === getCurrentContainer() ? true :false}>{item.containerName}</option>
              )}   
            </select>
          </div>
       ):null}
        
        {showIcons ? (
          <div className="project-list-toolbar__icons">
            <SortByDropdown onSortClick={onSortClick} sortValue={sortValue} />
            {this.props.iconValue === 'table' ? (
              <Button className="project-list-toolbar__tile-icon project-list-toolbar__button-sort  icon-button" onClick={() => this.onIconClick('tile')}>
                <Icon name="options" width={18} />
              </Button>
            ) : (
              <Button
               
                className="project-list-toolbar__table-icon project-list-toolbar__button-sort icon-button"
                onClick={() => this.onIconClick('table')}
              >
                <Icon name="list-bulleted" width={18} />
              </Button>
            )}
          </div>
        ) : null}

        {Permissions.Project.canCreate() && !loadState.error && currentUser.allowProjectCreation ? (
          <div>
          <Button size="large" className="btn btn-secondary project-list__create-button" onClick = {() => this.onCreateProjectType(constants.ProjectTypes.EXTRACTION_PROJECT)}>
            <FormattedMessage id="project-list.toolbar.create-button" />
          </Button>  
          {isWorkspaceButtonHidden && currentUser?.allowWorkspaceCreation && (
          <Button className=" btn btn-secondary project-list__create-Workspace" onClick={this.toggleDropdown}>
             <Icon name = "down-arrow" width={10} ></Icon>   
          </Button> 
          )}
          {isOpen && isWorkspaceButtonHidden && currentUser?.allowWorkspaceCreation &&(
            <div className="project-list__dropdown-menu" >
              <Button className="project-list__link" onClick = {() => this.onCreateProjectType(constants.ProjectTypes.WORKSPACE_PROJECT)}>
              <FormattedMessage id="project-list.toolbar.create-workspace" />
              </Button>    
             </div> 
          )} 
          </div>    
        ) : null}
      </div>
    );
  }
}

export default Toolbar;
