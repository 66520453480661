import { debounce } from 'underscore';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import Autocomplete from 'react-autocomplete';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Avatar from 'components/shared/avatar';

const WRAPPER_STYLE = {
  borderBottom: '1px solid rgb(235, 235, 235)',
  position: 'relative',
  width: '100%'
};
const WRAPPER_STYLE_TEAMMEMBERS = {
  position: 'relative',
  width: '95%',
  padding: '5px'
};

const INPUT_PROPS = {
  autoCapitalize: 'off',
  autoComplete: 'off',
  autoCorrect: 'off',
  className: 'team-members__search-name-input',
  maxLength: '80',
  spellCheck: 'false',
  style: {
    width: '100%',
    type: 'text'
  }
};

const INPUT_PROPS_TEAMMEMBERS = {
  autoCapitalize: 'off',
  autoComplete: 'off',
  autoCorrect: 'off',
  className: 'team-members__search-name-input',
  maxLength: '80',
  spellCheck: 'false',
  style: {
    width: '100%',
    type: 'text',
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: '400',
    font: 'Open Sans',
    fontWeight: 'normal'
  }
};

// This is auto complete form when user is searching for members
class UserAutocomplete extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    intl: intlShape,
    keyword: PropTypes.string,
    maxHeight: PropTypes.string,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    searchOnFocus: PropTypes.bool,
    searchUsers: PropTypes.func.isRequired,
    selectedUserName: PropTypes.string,
    showAllOnEmptySearch: PropTypes.bool,
    teammembersPage: PropTypes.bool
  };

  static defaultProps = {
    autoFocus: true,
    maxHeight: '256px',
    searchOnFocus: false,
    showAllOnEmptySearch: false
  };

  state = {
    isOpen: false,
    searchUsers: []
  };

  // Get props for the input element
  getInputProps = () => {
    const {
      autoFocus,
      intl: { formatMessage },
      keyword,
      onKeyDown,
      placeholder,
      teammembersPage
    } = this.props;

    return teammembersPage
      ? {
          ...INPUT_PROPS_TEAMMEMBERS,
          autoFocus,
          value: keyword,
          onFocus: this.onInputFocus,
          onKeyUp: this.onKeyUp,
          onKeyDown: onKeyDown,
          placeholder: placeholder || formatMessage({ id: 'team-members.search-placeholder' })
        }
      : {
          ...INPUT_PROPS,
          autoFocus,
          value: keyword,
          onFocus: this.onInputFocus,
          onKeyUp: this.onKeyUp,
          onKeyDown: onKeyDown,
          placeholder: placeholder || formatMessage({ id: 'team-members.search-placeholder' })
        };
  };

  // Debounced search to prevent excessive calls
  searchUsers = debounce(() => {
    const { searchUsers, keyword } = this.props;

    searchUsers(keyword).then(searchUsers => {
      this.setState({ searchUsers });
    });
  }, 500);

  // Handle key up
  onKeyUp = () => {
    if (this.props.keyword.length > 2) {
      this.searchUsers();
    }
  };

  // Handle input focus
  onInputFocus = () => {
    const { searchOnFocus } = this.props;

    if (!searchOnFocus) {
      return;
    }

    this.setState({ isOpen: true });
    this.searchUsers();
  };

  // Handle toggling the menu visibility
  onMenuVisibilityChange = isOpen => {
    this.setState({ searchUsers: [], isOpen });
  };

  // Handle onChange from the input element
  onChange = event => {
    const { onChange } = this.props;
    onChange(event);
  };

  // Handle selecting a search result
  onSelect = (val, item) => {
    const { onSelect } = this.props;
    onSelect(val, item);
  };

  // Filter items by search value
  shouldItemRender = (item, value) => {
    const { showAllOnEmptySearch } = this.props;
    if (!value) {
      return showAllOnEmptySearch;
    } else {
      return (
        item.displayName.toLowerCase().indexOf(value.trim().toLowerCase()) > -1 ||
        item.email.toLowerCase().indexOf(value.trim().toLowerCase()) > -1 ||
        item.lastName.toLowerCase().indexOf(value.trim().toLowerCase()) > -1 ||
        item.firstName.toLowerCase().indexOf(value.trim().toLowerCase()) > -1
      );
    }
  };

  renderAutocompleteMenu = items => {
    const { maxHeight } = this.props;
    return <div style={{ maxHeight }} className="user-autocomplete__menu" children={items} />;
  };

  renderAutocompleteItem = (item, isHighlighted) => (
    <div
      className={classNames('user-autocomplete__item', isHighlighted && 'user-autocomplete__item--highlighted')}
      key={item.userId}
    >
      <Avatar className="user-autocomplete__avatar" initials={item.firstName[0] + item.lastName[0]} />
      <span className="user-autocomplete__wrapper">
        <div className="table-view__body-creator-text">{item.displayName}</div>
        <div className="team-list__body-email">{item.email}</div>
      </span>
    </div>
  );

  render() {
    const { selectedUserName, teammembersPage } = this.props;

    return (
      <Autocomplete
        autoHighlight={true}
        getItemValue={item => item.displayName}
        inputProps={this.getInputProps()}
        items={this.state.searchUsers}
        onChange={this.onChange}
        onMenuVisibilityChange={this.onMenuVisibilityChange}
        onSelect={this.onSelect}
        renderItem={this.renderAutocompleteItem}
        renderMenu={this.renderAutocompleteMenu}
        shouldItemRender={this.shouldItemRender}
        value={selectedUserName}
        wrapperStyle={teammembersPage ? WRAPPER_STYLE_TEAMMEMBERS : WRAPPER_STYLE}
      />
    );
  }
}

export default injectIntl(UserAutocomplete);
