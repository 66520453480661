import { connect } from 'react-redux';
import { loadMaintenanceBannerMessage } from 'store/maintenance-message-banner'
import { loadAboutInfo } from 'store/about-argus';
import Header from 'components/header/header';
import { loadProjectList } from 'store/project-list';
import { loadReports,loadScheduledReports } from 'store/reports';
import { loadTrainingRequestList } from 'store/training-request-type';
import { onSearchChange } from 'store/search/search-category';
import { loadTranslationsList } from 'store/translations';

const mapStateToProps = ({
  data: { currentDocument, currentDocumentLayout, currentUser, currentProject, aboutArgus, currentLanguage, maintenanaceBannerMessage, translations }
}) => ({
  currentDocument,
  currentDocumentLayout,
  currentUser,
  currentProject,
  aboutArgus,
  currentLanguage,
  maintenanaceBannerMessage,
  translations
});

const mapDispatchToProps = {
  loadAboutInfo,
  loadMaintenanceBannerMessage,
  loadProjectList,
  loadReports,
  loadScheduledReports,
  loadTrainingRequestList,
  onSearchChange,
  loadTranslationsList
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
