import { connect } from 'react-redux';

import Page from 'components/document-viewer/document-renderer/page/page';
import { getCurrentDocumentLayoutPage } from 'store/current-document-layout';

const mapStateToProps = ({ data: { currentDocument } }) => ({
  currentDocument
});

const mapDispatchToProps = {
  getCurrentDocumentLayoutPage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);
