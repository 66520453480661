import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DocumentDownload from 'components/shared/document-download';
import Icon from 'components/shared/icon';
import emptyStateImage from 'images/empty_state_comp_02.svg';
import dateUtil from 'utils/dateUtil';
import { getCurrentBaseUrl, getCurrentApiInfo, getExportToken } from 'store/api';

const EXPORT_STATUSES = {
  STARTED: 1,
  PROGRESS: 2,
  COMPLETED: 3,
  DOWNLOADED: 4,
  ERROR: 5
};

const EXPORT_STATUS_NAMES = {
  [EXPORT_STATUSES.STARTED]: 'started',
  [EXPORT_STATUSES.PROGRESS]: 'progress',
  [EXPORT_STATUSES.COMPLETED]: 'completed',
  [EXPORT_STATUSES.DOWNLOADED]: 'downloaded',
  [EXPORT_STATUSES.ERROR]: 'error'
};

class Progress extends Component {
  static propTypes = {
    deleteExport: PropTypes.func.isRequired,
    exports: PropTypes.array.isRequired,
    projectId: PropTypes.any
  };

  state = {
    exports: [],
    downloadingDocument: false
  };

  componentDidMount() {
    this.getExportTokens();
  }

  componentDidUpdate(prevProps) {
    if (this.props.exports !== prevProps.exports) {
      this.getExportTokens();
    }
  }

  getExportTokens = () => {
    var { exports } = this.props;

    //Discarding previous array, but keeping exisiting download tokens
    exports.forEach(e => {
      var existing = this.state.exports.find(_e => _e.exportDetailID === e.exportDetailID);
      e.downloadLink = existing ? existing.downloadLink : null;
    });

    this.setState({ exports: exports });

    //Get download tokens for new exports
    exports.forEach(e => {
      if (e.downloadLink === null) this.getExportToken(e);
    });
  };

  getExportToken = item => {
    getExportToken({
      projectId: this.props.projectId,
      exportId: item.exportDetailID
    }).then(response => {
      var { exports } = this.state;
      var api = getCurrentApiInfo();

      exports.forEach(e => {
        if (e.exportDetailID === item.exportDetailID)
          e.downloadLink = `${getCurrentBaseUrl()}/projects/${this.props.projectId}/exports/download/${
            response.data
          }?X-MF-CTX=${api.memberFirmCode}&X-CNTR-CTX=${api.containerCode}`;
      });

      this.setState({ exports });
    });
  };

  renderErrorMessage = stateId => {
    if (stateId === EXPORT_STATUSES.ERROR) {
      return <FormattedMessage id="export-sidebar.status.problem" />;
    }
  };

  render() {
    const { exports, downloadingDocument } = this.state;

    if (!exports.length) {
      return (
        <div className="export-sidebar-empty">
          <img className="export-sidebar-empty__img" alt="no exports" src={emptyStateImage} />
          <h2 className="export-sidebar-empty__title">
            <FormattedMessage id="export-sidebar.no-exports.title" />
          </h2>
          <p className="export-sidebar-empty__description">
            <FormattedMessage id="export-sidebar.no-exports.description" />
          </p>
        </div>
      );
    }

    return (
      <div className="export-sidebar-progress">
        {exports.map(item => {
          const statusName = EXPORT_STATUS_NAMES[item.exportStateId];
          var enabled =
            item.downloadLink !== null &&
            (item.exportStateId === EXPORT_STATUSES.COMPLETED || item.exportStateId === EXPORT_STATUSES.DOWNLOADED);

          return (
            <div
              className={classNames('export-sidebar-progress__item', `export-sidebar-progress__item--${statusName}`)}
              tabIndex="0"
              key={item.exportDetailID}
            >
              <div className="export-sidebar-progress__item-header">
                {enabled ? (
                  downloadingDocument === item.exportDetailID ? (
                    <>
                      <div className="export-sidebar-progress__item-document-name export-sidebar-progress__item-link">
                        {item.exportFileName}
                      </div>
                      <div className="export-sidebar-progress__item-download">
                        <Icon className="spinner" name="loader" width={18} />
                      </div>
                    </>
                  ) : (

                    <DocumentDownload
                      linkUrl={item.downloadLink}
                      className="export-sidebar-progress__item-link"
                      linkBody={
                        <>
                          <div className="export-sidebar-progress__item-document-name">{item.exportFileName}</div>
                          <div className="export-sidebar-progress__item-download">
                            <Icon
                              className="export-sidebar-progress__download-enabled"
                              name="download-arrow"
                              width={18}
                            />
                          </div>
                        </>
                      }
                      target="_self"
                      documentFilename={item.exportFileName}
                      documentLoading={() => this.setState({ downloadingDocument: item.exportDetailID })}
                      documentLoaded={() => this.setState({ downloadingDocument: false })}
                    />
                  )
                ) : (
                  <>
                    <div className="export-sidebar-progress__item-document-name">{item.exportFileName}</div>
                    <div className="export-sidebar-progress__item-download">
                      <Icon className="export-sidebar-progress__download-disabled" name="download-arrow" width={18} />
                    </div>
                  </>
                )}
              </div>
              <div className="export-sidebar-progress__item-date">
                {dateUtil.formatDateShort(dateUtil.fromServerDate(item.exportStateDate))}
                <div className="export-sidebar-progress__item-dot" />
                {dateUtil.formatTime(dateUtil.fromServerDate(item.exportStateDate))}
              </div>
              <div className="export-sidebar-progress__item-footer">
                <FormattedMessage
                  id="export-sidebar.progress.retention"
                  values={{ hours: item.exportRetentionInHours }}
                />
              </div>
              <div className="export-sidebar-progress__item-body">
                <div className="export-sidebar-progress__bar-background">
                  <div className={`export-sidebar-progress__bar-fill--${statusName}`}></div>
                </div>
              </div>
              <div className="export-sidebar-progress__item-error">{this.renderErrorMessage(item.exportStateId)}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Progress;
