export default function model(defaultProps = {}) {
  return class BaseModel {
    constructor(props) {
      // Merge in the props and default props
      Object.assign(this, defaultProps, props);
    }

    // Return a new instance of the Model, with the new props merged in
    merge(mergeProps) {
      return new this.constructor({
        ...this,
        ...mergeProps
      });
    }
  };
}
