import { connect } from 'react-redux';

import ExtractionFieldDropdown from 'components/extractions/extraction-record-list/extraction-field-dropdown';

import { removeAllExtractionFieldRecords } from 'store/current-document';

const mapDispatchToProps = {
  removeAllExtractionFieldRecords
};

export default connect(null, mapDispatchToProps)(ExtractionFieldDropdown);
