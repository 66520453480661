import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

import ProjectTemplateExtractionField from 'permissions/sections/project-template-extraction-field';

const ProjectTemplatePermissions = {
  TemplateExtractionField: ProjectTemplateExtractionField,

  canCreate: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canDuplicate: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canDelete: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canEdit: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canApply: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canView: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  }
};

export default ProjectTemplatePermissions;
