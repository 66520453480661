import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import { updateProjectDatePreference } from 'react-intl';
import * as api from 'store/api';
import ReactTooltip from 'react-tooltip';
import enhanceWithClickOutside from 'react-click-outside';

class DateFormatTooltip extends Component {
  static propTypes = {
    showInnerTooltip: PropTypes.bool,
    closeInnerPopup: PropTypes.func
  };

  onCheckboxChange = () => {
    const { onChange, option } = this.props;
    onChange(option);
  };
  closeInnerPopup = () => {
    this.props.closeInnerPopup();
  };

  handleClickOutside = () => {
    this.closeInnerPopup();
  };

  render() {
    const { showInnerTooltip } = this.props;

    return showInnerTooltip ? (
      <div className="project-date-format-selection__tooltip">
        <div className="project-date-format-selection__tooltip-close">
          <Button className="icon-button" onClick={this.closeInnerPopup}>
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <FormattedMessage id="project-date-format-selection.model-tooltip" />
      </div>
    ) : null;
  }
}
const InnerTooltip = enhanceWithClickOutside(DateFormatTooltip);

class ProjectDateFormatSelection extends Component {
  static propTypes = {
    projectId: PropTypes.number,
    showProjectDateFormatSelection: PropTypes.bool,
    closeDateFormatPopUp: PropTypes.func,
    processToUploadFiles: PropTypes.func,
    postSuccessCallback: PropTypes.func,
    isMoveModel: PropTypes.bool,
    currentFolder: PropTypes.object
  };

  state = {
    selectedDateFormat: 'MM/dd/yyyy',
    showInnertoolTip: false,
    disableApply: true,
    userChangedFormatValue: ''
  };

  closeProjectDateFormatSelectionPopup = () => {
    this.props.closeDateFormatPopUp();
    this.setState({ showInnertoolTip: false });
  };
  closeInnerPopup = () => {
    this.setState({ showInnertoolTip: false });
  };
  showInnertoolTip = () => {
    this.setState({ showInnertoolTip: true });
  };

  formatSelected = e => {
    let selectedValue = e.currentTarget.value;
    // we need to convert this in this way because this is the standart we are following in Argus for validation
    if (e.currentTarget.value == 'MM/DD/YYYY') {
      selectedValue = 'MM/dd/yyyy';
    } else if (e.currentTarget.value == 'DD/MM/YYYY') {
      selectedValue = 'dd/MM/yyyy';
    } else if (e.currentTarget.value == 'YYYY/MM/DD') {
      selectedValue = 'yyyy/MM/dd';
    }

    this.setState({
      selectedDateFormat: selectedValue,
      userChangedFormatValue: e.currentTarget.value
    });
    this.setState({ disableApply: false });
  };

  saveDateFormat = () => {
    let { selectedDateFormat, userChangedFormatValue } = this.state;
    let { currentFolder, projectId } = this.props;
    let templateId = currentFolder?.parentHierarchy[0]?.projectTemplateId || currentFolder?.projectTemplateId;
    this.setState({ showProjectDateFormatSelection: false });
    this.props.closeDateFormatPopUp();
    this.props.postSuccessCallback('progress');
    localStorage.setItem('isDateFormatSelected', 'true');
    api
      .updateTemplateDatePreference(projectId, templateId, selectedDateFormat)
      .then(res => {
        this.props.postSuccessCallback('success');
      })
      .catch(() => {
        this.props.postSuccessCallback('failed');
      });
  };

  render() {
    let { closeSaveGridPopup, applySaveGridPopup, showProjectDateFormatSelection, isMoveModel } = this.props;
    let { showInnertoolTip, disableApply } = this.state;

    return showProjectDateFormatSelection ? (
      <Modal id={`ProjectDateFormatSelectionModal-${isMoveModel !== null && isMoveModel === true ? 'move-model' : ''}`}>
        <div className="project-date-format-selection">
          <div className="project-date-format-selection__content modal__content">
            <div className="project-date-format-selection__title">
              <h4>
                <FormattedMessage id="project-date-format-selection.select-date-format" />
                <Button
                  data-tip
                  data-for="project-date-format-selection.model-tooltip"
                  onMouseOver={this.showInnertoolTip}
                >
                  {<Icon name="light-bulb" width={24} />}
                </Button>

                <InnerTooltip showInnerTooltip={showInnertoolTip} closeInnerPopup={this.closeInnerPopup} />
              </h4>
              <Button
                type="button"
                size="icon"
                className="modal__close_x icon-button"
                onClick={this.closeProjectDateFormatSelectionPopup}
              >
                <Icon name="special-cross-black" width={18} height={18} />
              </Button>
            </div>
            <br />
            <div className="project-date-format-selection__modal-internal-content">
              <FormattedMessage id="workflow-date-format-selection.model-content" />
            </div>
            <br />
            <div className="project-date-format-selection__date-formats">
              <input
                type="radio"
                id="group1"
                name="radio"
                value="MM/DD/YYYY"
                defaultChecked
                onChange={this.formatSelected}
              />{' '}
              <span>MM/DD/YYYY</span>
              <span className="project-date-format-selection__default-label">
                (<FormattedMessage id="global.header.help-default" />)
              </span>{' '}
              <br /> <br />
              <input type="radio" id="group1" name="radio" value="DD/MM/YYYY" onChange={this.formatSelected} />{' '}
              <span>DD/MM/YYYY</span> <br /> <br />
              <input type="radio" id="group1" name="radio" value="YYYY/MM/DD" onChange={this.formatSelected} />{' '}
              <span>YYYY/MM/DD</span> <br /> <br />
            </div>
            <br />
            <div className="project-date-format-selection__save-cancel-buttons">
              <Button className="btn btn-secondary" onClick={this.closeProjectDateFormatSelectionPopup}>
                <FormattedMessage id="global.header.format-cancel" />
              </Button>
              <Button className="btn btn-primary" onClick={this.saveDateFormat}>
                <FormattedMessage id="global.header.format-confirm" />
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    ) : null;
  }
}

export default ProjectDateFormatSelection;
