import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Scrollbars from '../shared/scrollbars/scrollbars';
import Checkbox from 'components/shared/form/checkbox';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import { COLUMN_OPTIONS, COLUMN_OPTION_DEFAULTS } from 'store/extraction-overview';

class InnerOption extends Component {
  static propTypes = {
    displayName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    option: PropTypes.string.isRequired,
    isExtractionField: PropTypes.bool,
    disabled: PropTypes.bool,
    isDocumentToggleSwitchOnOff: PropTypes.bool
  };

  onCheckboxChange = () => {
    const { onChange, option } = this.props;
    onChange(option);
  };

  render() {
    const { displayName, option, isChecked, index, isExtractionField } = this.props;

    return (
      <div className={classNames('column-options__option')} key={index}>
        <Checkbox
          checked={isChecked ? 'checked' : 'unchecked'}
          className="column-options__option-checkbox"
          disabled={!isExtractionField ? COLUMN_OPTION_DEFAULTS[option].alwaysVisible : false}
          id={`check2-${option}`}
          onChange={this.onCheckboxChange}
        />
        <label className="column-options__option-label" htmlFor={`check-${option}`}>
          <FormattedMessage id={displayName} />
        </label>
      </div>
    );
  }
}

export class ColumnOptions extends Component {
  static propTypes = {
    updateColumnOptions: PropTypes.func.isRequired,
    columnOptions: PropTypes.object.isRequired,
    templateExtractionFields: PropTypes.array,
    columnOptionSelected: PropTypes.func,
    defaultSelection: PropTypes.bool,
    onDefaultUnSelection: PropTypes.func,
    getAllExtractionFieldsData: PropTypes.func,
    customizedVisibility: PropTypes.object,
    isColumnOptionsLoaded: PropTypes.bool,
    isGridChanged:PropTypes.bool,
    updateGridChange:PropTypes.func,
    updateCustomizedVisibility:PropTypes.func,
    selectedGridId: PropTypes.any,
    isDocumentToggleSwitchOnOff: PropTypes.bool
  };

  state = {
    visibility: this.props.columnOptions?.visibility || {},
    isMenuOpen: false,
    isCheckboxChecked: true,
    defaultSelcted: this.props.defaultSelection,
    allExtractionFields: [],
    customizedVisibilityState: this.props.customizedVisibility || {},
    defaultVisibilityState: {},
    checkboxChange:false,
    prevVisibility:{}

  };

  componentDidMount() {
    let {updateCustomizedVisibility} =this.props; 
    let visibilityObj = this.props.columnOptions?.visibility;
    let {customChangeObj} ={};
    for (var key in visibilityObj) {
      visibilityObj[key] = true;
    }
    this.setState({ visibility: visibilityObj });
    this.setState({ customizedVisibilityState: this.props.customizedVisibility });
    
    const templateExtractionFields = this.props.templateExtractionFields;
    let columnOptions=COLUMN_OPTIONS;
    let defaultVisibilityObj = {};
    for (var key in columnOptions) {
      defaultVisibilityObj[key] = true;
    }
   
    for (let s = 0; s < templateExtractionFields?.length; s++) {
      defaultVisibilityObj = { ...defaultVisibilityObj, [templateExtractionFields[s].extractionFieldId]: true };
    }       
    this.setState({ defaultVisibilityState: defaultVisibilityObj, prevVisibility: defaultVisibilityObj});
    const documentToggle = sessionStorage.getItem('isDocumentToggleSwitchOnOff')== "true" ? true : false;
    if(documentToggle)
    {
      customChangeObj = defaultVisibilityObj;
      customChangeObj = { ...customChangeObj, [COLUMN_OPTIONS.DOCUMENT_NAME]: !defaultVisibilityObj[COLUMN_OPTIONS.DOCUMENT_NAME]};
      this.setState({ defaultVisibilityState: customChangeObj, prevVisibility: customChangeObj},() => {      
        this.forceUpdate();
      });
      updateCustomizedVisibility(customChangeObj);
    }
  }
  componentWillReceiveProps(prevProps) {
    let {isGridChanged,customizedVisibility,isDocumentToggleSwitchOnOff, updateCustomizedVisibility} =this.props; 
    let {checkboxChange,defaultVisibilityState}=this.state;
    let {customChangeObj} ={};

    //set prevVisibility to retain the visibilty of dropdown values before clicked on apply
    if(prevProps.selectedGridId != this.props.selectedGridId){
      this.setState({prevVisibility :customizedVisibility });
    }
    if(isGridChanged) {
      this.setState({visibility :customizedVisibility, prevVisibility :customizedVisibility });
    }
    else if(this.props.selectedGridId == 0){
        this.setState({visibility :defaultVisibilityState });
      }
    else{
        this.setState({visibility :customizedVisibility });
      }

    if (checkboxChange!=true && Object.keys(customizedVisibility).length > 0) {
      this.setState({ customizedVisibilityState: customizedVisibility });
    }
    
    if(prevProps.isDocumentToggleSwitchOnOff != isDocumentToggleSwitchOnOff && checkboxChange!=true )
    {
      customChangeObj = customizedVisibility;
      customChangeObj = { ...customChangeObj, [COLUMN_OPTIONS.DOCUMENT_NAME]: !customizedVisibility[COLUMN_OPTIONS.DOCUMENT_NAME]};
      this.setState({ defaultVisibilityState: customChangeObj, prevVisibility: customChangeObj },() => {      
        this.forceUpdate();
      });
      updateCustomizedVisibility(customChangeObj);
    }
}

  openMenu = () => {
    const { isMenuOpen } = this.state;
    // if (isMenuOpen) {
    //   this.onCancel();
    // } else {
      this.setState({ isMenuOpen: !isMenuOpen });
    // }
  };

  onCancel = () => {
    const { updateCustomizedVisibility }=this.props;
    const {prevVisibility}= this.state;
    if(Object.keys(prevVisibility).length > 0){
      this.setState({isMenuOpen: false, defaultVisibilityState: prevVisibility });
      updateCustomizedVisibility(prevVisibility);
    }
    else
      this.setState({ isMenuOpen: false });

  };

  onApply = () => {
    this.props.columnOptionSelected();
    const { updateColumnOptions, columnOptions, getAllExtractionFieldsData, templateExtractionFields } = this.props;
    const { visibility } = this.state;
    this.setState({ isMenuOpen: false,  prevVisibility:visibility });
										   
    getAllExtractionFieldsData(visibility);
    updateColumnOptions({ visibility, frozen: columnOptions.frozen });
  };

  onCheckboxChange = option => {
    const {updateGridChange,isGridChanged,updateCustomizedVisibility,defaultSelection,customizedVisibility  } = this.props;
    let {  customizedVisibilityState, defaultVisibilityState } = this.state;
    this.setState({ defaultSelcted: false });
    updateGridChange();
    this.setState({checkboxChange:true})

    let customChangeObj = {};
    customChangeObj = customizedVisibility;
    customChangeObj = { ...customChangeObj, [option]: !customizedVisibility[option] };
    this.setState({ customizedVisibilityState: customChangeObj },() => {      
      this.forceUpdate();
    });
    
    if (Object.keys(customizedVisibilityState).length > 0) {
      updateCustomizedVisibility(customChangeObj);
      this.setState({ visibility: customChangeObj },() => {      
        this.forceUpdate();
      });
    }

    if(defaultSelection===true && isGridChanged==false) {
    let defaultChangeObj = {};
    defaultChangeObj = this.state.defaultVisibilityState;
    defaultChangeObj = { ...defaultChangeObj, [option]: !defaultVisibilityState[option] };
    this.setState({ defaultVisibilityState: defaultChangeObj }, () => {
      this.setState({ defaultSelection: true });
      this.forceUpdate();
    });   
    this.setState({ visibility: defaultChangeObj });
  }
  };

  handleClickOutside = () => {
    this.onCancel();
  };

  render() {
    const { isMenuOpen, defaultVisibilityState } = this.state;
    const {
      intl,
      templateExtractionFields,
      defaultSelection,
      customizedVisibility,
      isColumnOptionsLoaded,
    } = this.props;
    const { columnOptions } = this.props;
    const extractionField = true;
    const notExtractionField = true;
    let customVisibility = false;
    if (Object.keys(customizedVisibility).length > 0) {
      customVisibility = true;
    }
     
    return (
      <div className="column-options">
        <Button
          size="icon"
          onClick={this.openMenu}
          className="btn file-browser-toolbar__control icon-button"
          data-tip
          data-for="file-browser.column-options.tooltip"
        >
          <Icon width={18} name="special-cog" />
          <ReactTooltip id="file-browser.column-options.tooltip" effect="solid" place="top">
            <FormattedMessage id="file-browser.column-options.tooltip" />
          </ReactTooltip>
        </Button>
        {isMenuOpen && templateExtractionFields != undefined && (
          <div className="column-options__content popover">
            <h3 className="column-options__title">
              <FormattedMessage id="file-browser.column-options.title" />
            </h3>
            <div className="column-options__content-list">
              <Scrollbars>
                <div className="column-options__options-list popover__section">
                  {Object.values(COLUMN_OPTIONS).length == 1 ? null : Object.values(COLUMN_OPTIONS).map((option, index) => (
                    <InnerOption
                      displayName={COLUMN_OPTION_DEFAULTS[option]?.displayName}
                      index={index}
                      //  isChecked={defaultSelection === false ? isColumnOptionsLoaded? customizedVisibility[option]:visibility[option.extractionFieldId] : true}
                          isChecked={
                         defaultSelection === false 
                          ? isColumnOptionsLoaded
                            ? customizedVisibility[option]
                            : customizedVisibility[option]
                          : defaultVisibilityState[option]
                      }
                      key={option}
                      onChange={this.onCheckboxChange}
                      option={option}
                      isExtractionField={notExtractionField}
                    />
                  ))}
                  {Object.values(templateExtractionFields).map((option, index) => (
                    <InnerOption
                      displayName={option.extractionFieldName}
                      index={index}
                      //   isChecked={defaultSelection === false ? isColumnOptionsLoaded? customizedVisibility[option]:visibility[option.extractionFieldId] : true}
                      isChecked={
                        defaultSelection === false
                          ? isColumnOptionsLoaded
                            ? customizedVisibility[option]
                            : customizedVisibility[option.extractionFieldId]
                          : defaultVisibilityState[option.extractionFieldId]
                      }
                      key={option}
                      onChange={this.onCheckboxChange}
                      option={option.extractionFieldId}
                      isExtractionField={extractionField}
                    />
                  ))}
                </div>
              </Scrollbars>
            </div>
            <div className="column-options__footer">
              <Button
                title={intl.formatMessage({ id: 'common.cancel' })}
                size="xsmall"
                className="btn btn-secondary"
                onClick={this.onCancel}
                tabIndex="0"
              >
                <FormattedMessage id="common.cancel" />
              </Button>

              <Button
                title={intl.formatMessage({ id: 'common.apply' })}
                size="xsmall"
                className="btn btn-primary btn-no-margin"
                onClick={this.onApply}
                tabIndex="0"
              >
                <FormattedMessage id="common.apply" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(ColumnOptions));
