import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/shared/form/dropdown';
import { FormattedMessage } from 'react-intl';
import { Radio, RadioGroup } from 'components/shared/form/radio';
import Constants from 'utils/constants';
 
/** List of all the templates, when user has finished creating at least one template in the project creation section */
export default function TemplateTypeAI({templateTypes}) {
 const compareTemplatesOrder = (template1, template2) => {
    return template1.displayOrder - template2.displayOrder;
  };
  return(
    <div className="template-type__extraction-genai-workflows">
    <h4 className="template-type__sub-title-genai">
      <FormattedMessage id="template-type.extraction-genai-workflow-title-pilot" />
    </h4>
    <p>
     <FormattedMessage id="template-type.extraction-genai-workflow-description" />
    </p>
  
    {templateTypes &&
      templateTypes.isLoaded &&
      templateTypes.types.templateTypes
        .filter(templates => (templates.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_GENAI_DETAIL_TESTING || templates.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_GENAI_NONDETAIL_TESTING || 
          templates.templateCategoryId === Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING
        )) 
        .sort(compareTemplatesOrder)
        .map((type, templates) => (
          <div key={templates} className="template-type__row">
            <Radio className="template-type__radio" id={`templateType-${type.templateTypeID}`} value={type} />
            <label className="template-type__label" htmlFor={`templateType-${type.templateTypeID}`}>
              {type.templateTypeID ? (
                <span>
                  <FormattedMessage id={`project-creation-templateType_${type.templateTypeID}`} />
                  {type.trainedExtractionFieldCount ? ` (${type.trainedExtractionFieldCount})` : ' (0)'}
                </span>
              ) : (
                type.templateTypeName
              )}
            </label>
          </div>
        ))}
  </div>
  )
}