import { connect } from 'react-redux';

import DocumentSearch from 'components/search/document-search/document-search';
import { getCurrentDocumentCompleteLayout } from 'store/current-document-layout';

const mapStateToProps = ({ data: { currentDocument, currentDocumentLayout } }) => ({
  currentDocument,
  currentDocumentLayout
});

const mapDispatchToProps = {
  getCurrentDocumentCompleteLayout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentSearch);
