import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import { TABLE_HEADERS } from './constants';
import PropTypes from 'prop-types';
import CONSTANTS from 'utils/constants';
import dateUtil from 'utils/dateUtil';

import moment from 'moment';
import 'moment/min/locales';

import Permissions from 'permissions/permissions';
import Icon from 'components/shared/icon';

class LandingPageTable extends React.Component {
  static propTypes = {
    currentLanguage: PropTypes.object,
    data: PropTypes.array.isRequired,
    forTable: PropTypes.string.isRequired,
    linkTo: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    // This will give us ref to the cell that needs a title on hover
    this.nameRefs = [];
    this.MATRefs = [];
    this.state = {
      // Determine whether to show the full name on hover or not
      isOverflowed: []
    };
  }

  // Overflow ellipsis checker so we know how to display long project names
  checkOverflow = (ref, hoveredIndex) => {
    const isOverflowed = this[ref][hoveredIndex].scrollWidth > this[ref][hoveredIndex].clientWidth;

    if (isOverflowed !== this.state.isOverflowed) {
      this.setState({ isOverflowed: { [hoveredIndex]: isOverflowed } });
    }
  };

  // Get Project Status
  getProjectStatus = project => {
    const status = Object.keys(CONSTANTS.ProjectStateIds).find(
      key => CONSTANTS.ProjectStateIds[key] === project.projectStateId
    );

    return project.projectStateId ? status : 'NoStatus';
  };

  // Get Project Status
  getTranslationStatus = translation => {
    const status = Object.keys(CONSTANTS.TranslationStatus).find(
      key => CONSTANTS.TranslationStatus[key] === translation.translationStateId
    );

    return translation.translationStateId ? status : 'NoStatus';
  };

  // Project link class name for enabling and disabling links in project card
  getProjectLinkClassname = project => {
    const status = this.getProjectStatus(project);

    if (
      status === 'softDelete' ||
      status === 'copying' ||
      (status === 'inCreation' && !Permissions.Project.Settings.canEditCreationState(project))
    ) {
      return `link-disabled`;
    }
    return ``;
  };

  render() {
    const { isOverflowed } = this.state;
    const { data, forTable, linkTo, currentLanguage } = this.props;
    const tableHeaders = forTable === 'projects' ? TABLE_HEADERS.recentProjects : TABLE_HEADERS.translationQueue;
    moment.locale(currentLanguage.localeCode);
    return (
      <FixedTable className="table-view">
        <table className="table-view__table">
          <thead>
            <tr>
              {tableHeaders.map((heading, index) => {
                return (
                  <FixedHeader className={`table-view__heading ${heading}`} key={index}>
                    <div className="table-view__heading-text">
                      {heading === 'Status' ? <div className="table-view__heading-status-icon" /> : null}
                      {/* Represents the full heading name without case or spaces */}
                      <FormattedMessage id={`landing-page.argus-translation.${forTable}-table-heading.${heading}`} />
                    </div>
                  </FixedHeader>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data &&
              forTable === 'projects' &&
              data.map((project, index) => {
                const globalCode = `${project.geoCode}_${project.memberFirmCode}_${project.containerCode}`;
                const projectStatus = this.getProjectStatus(project);
                const linkClass = this.getProjectLinkClassname(project);
                const projectLink = linkTo(project, globalCode);

                return (
                  <tr key={index}>
                    <td
                      ref={element => (this.nameRefs[index] = element)}
                      title={isOverflowed[index] ? project.projectName : ''}
                      onMouseEnter={() => this.checkOverflow('nameRefs', index)}
                      onMouseLeave={() => this.setState({ isOverflowed: { [index]: false } })}
                    >
                      <Link
                        className={`table-view__body-name ${projectStatus ? projectStatus : 'grey'} ${linkClass}`}
                        to={projectLink}
                      >
                        {project.projectName || 'Untitled Name'}
                      </Link>
                    </td>
                    <td>{project.clientName ? project.clientName : ''}</td>
                    <td>{project.lastUpdatedDate ? dateUtil.formatDate(project.lastUpdatedDate) : '-'}</td>
                    <td>
                      <div className={`table-view__body-status-icon ${projectStatus}`} />
                      <FormattedMessage id={`project-state.${projectStatus}`} />
                    </td>
                  </tr>
                );
              })}
            {data &&
              forTable === 'translations' &&
              data.map((translation, index) => {
                const translationStatus = this.getTranslationStatus(translation);
                const timeFromNow = moment.utc(translation.submittedDate).fromNow();

                return (
                  <tr key={index}>
                    <td
                      ref={element => (this.nameRefs[index] = element)}
                      title={translation.sourceFileName}
                      onMouseEnter={() => this.checkOverflow('nameRefs', index)}
                      onMouseLeave={() => this.setState({ isOverflowed: { [index]: false } })}
                    >
                      {translation.translationStateId === 5 ? (
                        <span className="landing-page__translation-link">{translation.sourceFileName}</span>
                      ) : (
                        translation.sourceFileName
                      )}
                    </td>
                    <td>
                      {translation.sourceLanguageName}
                      <Icon className={`table-view__language-icon`} name="arrow-right" width={28} />
                      {translation.targetLanguageName}
                    </td>
                    <td>{timeFromNow}</td>
                    <td>
                      <div className={`table-view__body-status-icon ${translationStatus}`} />
                      <FormattedMessage id={`translation-queue.status.${translationStatus}`} />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </FixedTable>
    );
  }
}
export default LandingPageTable;
