import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';
import Icon from 'components/shared/icon';

class LanguageSelect extends Component {
  static propTypes = {
    languages: PropTypes.object,
    setLanguage: PropTypes.func,
    handlelanguageSubmit: PropTypes.func
  };

  state = {
    isMenuOpen: false,
    selectedLanguage: this.props.currentLanguage    
  };

  languageSelectedByUser = language => {
    this.setState({
      selectedLanguage:language     
    }, this.props.handlelanguageSubmit(language)) 
    
  };


  onMenuToggle = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  render() {
    const { languages, currentLanguage } = this.props;
    const { selectedLanguage } = this.state;
    return (     
      <MenuWrapper className="language-select" onMenuToggle={this.onMenuToggle} onSelection={this.languageSelectedByUser}>
        <div className="label language-select-label">
          <FormattedMessage id="global.header.language-select-label" />
        </div>

        <MenuButton className="form-dropdown btn" tag="button" type="button">
          <div className="dropdown-value">{selectedLanguage.nativeName}</div>

          <div className="dropdown-chevron">
            <Icon name="special-arrow-down-blue" width={14} height={8} rotate={this.state.isMenuOpen ? 180 : 0} />
          </div>
        </MenuButton>
        <Menu className="language-select-body">
         {languages && languages.languages && languages.languages.length
            ? languages.languages.map(language => {
                var selected = language.localCode === selectedLanguage.localeCode;
                return (
                  <MenuItem
                    key={`role-dropdown.${language.localeCode}`}
                    className={`language-select-item ${selected ? 'selected' : ''}`}
                    value={language}
                  >
                    <span>{language.nativeName}</span>

                    {selected ? (
                      <Icon className="check-icon" name="special-checked-black" width={14} height={8} />
                    ) : null}
                  </MenuItem>
                );
              })
            : null}
        </Menu>
      </MenuWrapper>
    );
  }
}

export default LanguageSelect;
