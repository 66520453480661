import { connect } from 'react-redux';

import SearchToolbar from 'components/search/file-list-search/search-toolbar';
import { getCurrentDocument } from 'store/current-document';
import { popUndoStack, clearUndoStack } from 'store/document-viewer-undo';

const mapStateToProps = ({
  ui: { documentViewerUndo },
  data: { currentDocument, currentDocumentLayout, currentProject, currentSearchFolder, search, currentUser }
}) => ({
  documentViewerUndo,
  currentDocument,
  currentDocumentLayout,
  currentProject,
  currentSearchFolder,
  search,
  currentUser
});

const mapDispatchToProps = {
  getCurrentDocument,
  popUndoStack,
  clearUndoStack
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchToolbar);
