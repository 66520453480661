import { connect } from 'react-redux';
import { getData } from 'store/tags/ocr-quality-tags';
import Tile from 'components/tags/tile';

const mapStateToProps = ({ data: { ocrQualityTags } }) => ({ data: ocrQualityTags });
const mapDispatchToProps = { getData };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tile);
