import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

const GlobalExtractionFieldGroupPermissions = {
  canView: extractionField => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canCreate: extractionField => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canDelete: extractionField => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canEdit: extractionField => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  }
};

export default GlobalExtractionFieldGroupPermissions;
