import { connect } from 'react-redux';

import ExtractionOverviewDocumentPanel from 'components/extraction-overview/extraction-overview-document-panel';
import { getCurrentDocument } from 'store/current-document';

const mapStateToProps = ({ data: { currentDocument, currentDocumentLayout, currentProject } }) => ({
  currentDocument,
  currentDocumentLayout,
  currentProject
});

const mapDispatchToProps = {
  getCurrentDocument
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtractionOverviewDocumentPanel);