import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import {
  SEARCH_CATEGORIES,
  SEARCH_CATEGORY_DISPLAY_NAMES,
  SEARCH_MODIFIER_DISPLAY_NAMES,
  SEARCH_MODIFIERS,
  SEARCH_OPERATOR_DISPLAY_NAMES,
  SEARCH_OPERATORS,
  SEARCH_RECORD_MODIFIER_DISPLAY_NAMES,
  SEARCH_RECORD_MODIFIERS,
  TAG_TYPE_MODIFIERS,
  TAG_TYPE_DISPLAY_NAMES
} from 'components/search/constants';
import Button from 'components/shared/single-click-button';
import Dropdown from 'components/shared/form/dropdown';
import Icon from 'components/shared/icon';

const CATEGORY_OPTIONS = [
  SEARCH_CATEGORIES.DOCUMENT_NAME,
  SEARCH_CATEGORIES.DOCUMENT_CONTENT,
  SEARCH_CATEGORIES.FOLDER_NAME,
  SEARCH_CATEGORIES.EXTRACTION_FIELD_NAME,
  SEARCH_CATEGORIES.TEMPLATE_NAME,
  SEARCH_CATEGORIES.TAG,
  SEARCH_CATEGORIES.REVIEWER_NAME
];

const AND_OR_OPTIONS = [SEARCH_OPERATORS.AND, SEARCH_OPERATORS.OR];

const TAG_TYPE_OPTIONS = [TAG_TYPE_MODIFIERS.DOCUMENT, TAG_TYPE_MODIFIERS.RECORD];

const INCLUDE_EXCLUDE_OPTIONS = [SEARCH_MODIFIERS.INCLUDE, SEARCH_MODIFIERS.EXCLUDE];

const RECORD_OPTIONS = [SEARCH_RECORD_MODIFIERS.INCLUDE, SEARCH_RECORD_MODIFIERS.EXCLUDE];

// Advanced Search Item represents a single query row with the required dropdowns
export class AdvancedSearchFilterItem extends Component {
  static propTypes = {
    counter: PropTypes.number,
    index: PropTypes.number,
    inputValue: PropTypes.string,
    intl: intlShape,
    isFirstItem: PropTypes.bool,
    onAndOr: PropTypes.func,
    onCancel: PropTypes.func,
    onCategorySelect: PropTypes.func,
    onIncludeExclude: PropTypes.func,
    onQueryChange: PropTypes.func,
    onRecord: PropTypes.func,
    onTagTypeSelect: PropTypes.func,
    values: PropTypes.any
  };

  state = {
    isFocused: false
  };

  onSearchStringChange = event => {
    const { index, onQueryChange } = this.props;
    onQueryChange(index, event.target.value);
  };

  renderPlaceholder = () => {
    const { intl } = this.props;
    return intl.formatMessage({ id: 'search-filter.placeholder' });
  };

  onAndOrOptionSelect = option => {
    const { index, onAndOr } = this.props;
    return onAndOr(index, option);
  };

  renderAndOrOption = option => {
    const { intl } = this.props;
    return intl.formatMessage({ id: SEARCH_OPERATOR_DISPLAY_NAMES[option] });
  };

  renderAndOrDropdown = () => {
    const { index, values } = this.props;
    const query = values[index] || {};
    const andOrValue = query['andOr'];

    return (
      <Dropdown
        className="advanced-search__item-andOr"
        customStyle="advanced-search__item-style"
        defaultMessage={this.renderPlaceholder()}
        key="andOr"
        onSelection={this.onAndOrOptionSelect}
        options={AND_OR_OPTIONS}
        renderOption={this.renderAndOrOption}
        selectedOption={andOrValue}
      />
    );
  };

  onCategoryOptionSelect = option => {
    const { index, onCategorySelect } = this.props;
    return onCategorySelect(index, option);
  };

  renderCategoryOption = option => {
    const { intl } = this.props;
    return intl.formatMessage({ id: SEARCH_CATEGORY_DISPLAY_NAMES[option] });
  };

  renderCategoryDropdown = () => {
    const { counter, index, isFirstItem, values } = this.props;
    const query = values[index] || {};
    const categoryValue = query['category'];

    return (
      <Dropdown
        className={`advanced-search__item-category ${isFirstItem && counter > 1 ? 'padded' : ''}`}
        customStyle="advanced-search__item-style"
        defaultMessage={this.renderPlaceholder()}
        key="category"
        onSelection={this.onCategoryOptionSelect}
        options={CATEGORY_OPTIONS}
        renderOption={this.renderCategoryOption}
        selectedOption={categoryValue}
      />
    );
  };

  onTagTypeSelect = option => {
    const { index, onTagTypeSelect } = this.props;
    return onTagTypeSelect(index, option);
  };

  renderTagTypeOption = option => {
    const { intl } = this.props;
    return intl.formatMessage({ id: TAG_TYPE_DISPLAY_NAMES[option] });
  };

  renderTagTypeDropdown = () => {
    const { index, values } = this.props;
    const query = values[index] || {};
    const tagTypeValue = query['tagType'];

    return (
      <Dropdown
        className="advanced-search__item-tag-type"
        customStyle="advanced-search__item-style"
        defaultMessage={this.renderPlaceholder()}
        key="tagType"
        onSelection={this.onTagTypeSelect}
        options={TAG_TYPE_OPTIONS}
        renderOption={this.renderTagTypeOption}
        selectedOption={tagTypeValue}
      />
    );
  };

  onIncludeExcludeOptionSelect = option => {
    const { index, onIncludeExclude } = this.props;
    return onIncludeExclude(index, option);
  };

  renderIncludeExcludeOption = option => {
    const { intl } = this.props;
    return intl.formatMessage({ id: SEARCH_MODIFIER_DISPLAY_NAMES[option] });
  };

  renderIncludeExcludeDropdown = () => {
    const { index, values } = this.props;
    const query = values[index] || {};
    const includeValue = query['include'];

    return (
      <Dropdown
        className="advanced-search__item-include"
        customStyle="advanced-search__item-style"
        defaultMessage={this.renderPlaceholder()}
        key="includeExclude"
        onSelection={this.onIncludeExcludeOptionSelect}
        options={INCLUDE_EXCLUDE_OPTIONS}
        renderOption={this.renderIncludeExcludeOption}
        selectedOption={includeValue}
      />
    );
  };

  onRecordOptionSelect = option => {
    const { index, onRecord } = this.props;
    return onRecord(index, option);
  };

  renderRecordOption = option => {
    const { intl } = this.props;
    return intl.formatMessage({ id: SEARCH_RECORD_MODIFIER_DISPLAY_NAMES[option] });
  };

  renderRecordDropdown = () => {
    const { index, values } = this.props;
    const query = values[index] || {};
    const recordValue = query['record'];

    return (
      <Dropdown
        className="advanced-search__item-record"
        customStyle="advanced-search__item-style"
        defaultMessage={this.renderPlaceholder()}
        key="record"
        onSelection={this.onRecordOptionSelect}
        options={RECORD_OPTIONS}
        renderOption={this.renderRecordOption}
        selectedOption={recordValue}
      />
    );
  };

  renderSearchInput = () => {
    const { index, values } = this.props;
    const query = values[index] || {};
    const searchStringValue = query['searchString'] || '';

    return (
      <input className="advanced-search__item-text" value={searchStringValue} onChange={this.onSearchStringChange} />
    );
  };

  render() {
    const { index, isFirstItem, onCancel, values } = this.props;

    const query = values[index] || {};
    const andOrValue = query['andOr'];
    const categoryValue = query['category'];
    const includeValue = query['include'];
    const recordValue = query['record'];
    const tagTypeValue = query['tagType'];

    return (
      <div className="advanced-search__item">
        {/* And / Or dropdown (not present on first item) */}
        {!isFirstItem && this.renderAndOrDropdown()}

        {/* Category dropdown */}
        {(isFirstItem || andOrValue) && this.renderCategoryDropdown()}

        {/* The following options are slightly different depending on the category type */}
        {categoryValue === SEARCH_CATEGORIES.TAG ? (
          <Fragment>
            {this.renderTagTypeDropdown()}
            {tagTypeValue && this.renderIncludeExcludeDropdown()}
          </Fragment>
        ) : categoryValue === SEARCH_CATEGORIES.EXTRACTION_FIELD_NAME ? (
          this.renderRecordDropdown()
        ) : categoryValue ? (
          this.renderIncludeExcludeDropdown()
        ) : null}

        {/* Search input */}
        {(recordValue || includeValue) && this.renderSearchInput()}

        {/* X icon */}
        {!isFirstItem && (
          <Button size="icon" className="search-bar__input-cancel icon-button" onClick={() => onCancel(index)}>
            <Icon name="special-cross-black" width={10} />
          </Button>
        )}
      </div>
    );
  }
}

export default injectIntl(AdvancedSearchFilterItem);
