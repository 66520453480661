import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCK } from './constants';
import TranslationTabs from './translation-tabs';
import TranslateDocument from './translate-document/translate-document';
import TranslateText from './translate-text/translate-text';
import TranslationQueue from './translation-queue/translation-queue';
import TranslationDownMessage from './translation-down-message';
import { FormattedMessage } from 'react-intl';
import CONSTANTS from 'utils/constants';
import { UPLOAD_STATES, TranslationType } from './constants';
import UploadProgress from './shared/upload-progress/upload-progress';
import store from 'store/store';
import { addNotification } from 'store/notifications.js';
import ProjectsLeftMenu from '../project-list/project-menu-bar/project-left-menu';
import { ERROR_CODES } from '../../utils/errors';

class Translation extends Component {
  static propTypes = {
    currentLanguage: PropTypes.object,
    currentUser: PropTypes.object,
    isMachineTranslationAvailable: PropTypes.bool,
    loadTranslationLanguagePairs: PropTypes.func.isRequired,
    loadTranslationListLocal: PropTypes.func.isRequired,
    loadTranslationsList: PropTypes.func.isRequired,
    location: PropTypes.object,
    machineTranslationDownTimeDuration: PropTypes.string,
    machineTranslationDownTimeDurationUnitKey: PropTypes.string,
    translateDocuments: PropTypes.func.isRequired,
    translateText: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
    aboutArgus: PropTypes.object
  };

  state = {
    selectedTab: 0,
    filesOnProgress: []
  };

  setSelectedTab = tab => {
    this.setState({ selectedTab: tab });
  };

  clearUploads = () => {
    this.setState({ filesOnProgress: [] });
  };

  clearFilesOnProgress = () => {
    this.setState({ filesOnProgress: [] });
  };

  setFileOnProgress = (fileStatus, index, name) => {
    const { filesOnProgress } = this.state;
    const tempFileStatus = {
      loaded: fileStatus.loaded,
      total: fileStatus.total,
      state: UPLOAD_STATES.UPLOADING,
      id: index,
      name: name
    };
    const fileExists = filesOnProgress.find(file => file.id === index);
    const updatedFilesOnProgress = fileExists
      ? filesOnProgress.map(file => {
          if (file.id === index) {
            file.loaded = fileStatus.loaded;
            file.state = fileStatus.loaded === file.total ? UPLOAD_STATES.COMPLETED : UPLOAD_STATES.UPLOADING;
          }
          return file;
        })
      : filesOnProgress.concat(tempFileStatus);

    this.setState({ filesOnProgress: updatedFilesOnProgress });
  };

  setFileError = index => {
    const { filesOnProgress } = this.state;
    const updatedFilesOnProgress = filesOnProgress.map(file => {
      if (file.id === index) {
        file.state = UPLOAD_STATES.FAILED;
      }
      return file;
    });

    this.setState({ filesOnProgress: updatedFilesOnProgress });
  };

  setFileInfected = name => {
    const { filesOnProgress } = this.state;
    const updatedFilesOnProgress = filesOnProgress.map(file => {
      if (file.name === name) {
        file.state = UPLOAD_STATES.FAILED;
        file.errorCode = ERROR_CODES.DOCUMENT_INFECTED
      }
      return file;
    });

    this.setState({ filesOnProgress: updatedFilesOnProgress });
  };

  componentDidMount() {
    const { location } = this.props;
    const selectedTab = Object.values(CONSTANTS.TRANSLATION_TABS).indexOf(location.hash.replace('#', ''));
    if (selectedTab >= 0) {
      this.setSelectedTab(selectedTab);
    }
  }

  translateDocuments = (files, sourceLanguage, targetLanguage,translationType, memberFirmCode, containerCode, setUploadingFiles) => {
    const { translateDocuments, loadTranslationsList } = this.props;

    Promise.all(
      files.map((document, index) => {
        const payload = {
          sourceLanguageId: sourceLanguage.sourceLanguage.id,
          targetLanguageId: targetLanguage.id,
          translationType : translationType === '' ? TranslationType[1] : translationType,
          document: document,
          onUploadProgress: fileStatus => this.setFileOnProgress(fileStatus, index, document.name),
          onUploadError: () => this.setFileError(index),
          memberFirmCode: memberFirmCode,
          containerCode: containerCode
        };
        return translateDocuments(payload);
      })
    )
      .then((res) => {
        localStorage.setItem('preferredSourceLanguage', JSON.stringify(sourceLanguage));
        localStorage.setItem('preferredTargetLanguage', JSON.stringify(targetLanguage));
        
        var infectedFiles = res.filter(file => file.payload[0].translationStateId == 6).map(file => file.payload[0].sourceFileName)
        if (infectedFiles.length != 0) {
          infectedFiles.map(name => this.setFileInfected(name));
          loadTranslationsList();
          this.setSelectedTab(2);
        }
        else {
          this.setState({ filesToUpload: [], haveAcceptedTerms: 'unchecked', canTranslate: false }, () => {
            setUploadingFiles(false);
            loadTranslationsList();
            this.setSelectedTab(2);
            setTimeout(() => {
              this.clearFilesOnProgress();
            }, 1500);
          });
        }        
      })
      .catch(() =>
        store.dispatch(
          addNotification({
            message: <FormattedMessage id="translation.api-error" />,
            clearOnPageChange: true,
            autohide: 5
          })
        )
      );
  };

  render() {
    const {
      currentLanguage,
      currentUser,
      loadTranslationLanguagePairs,
      translations,
      loadTranslationsList,
      loadTranslationListLocal,
      aboutArgus,
      translateText
    } = this.props;
    const { selectedTab, filesOnProgress } = this.state;
    const down = !translations.isMachineTranslationAvailable;
    const translationPageInfo = aboutArgus?.data?.translationPage
    
    const tabsContent = [
      <TranslateDocument
        loadTranslationLanguagePairs={loadTranslationLanguagePairs}
        translations={translations}
        translateDocuments={this.translateDocuments}
        setSelectedTab={this.setSelectedTab}
        setFileOnProgress={this.setFileOnProgress}
        setFileError={this.setFileError}
        clearFilesOnProgress={this.clearFilesOnProgress}
        currentUser={currentUser}
        translationPageInfo={translationPageInfo}
      />,
      <TranslateText
        loadTranslationLanguagePairs={loadTranslationLanguagePairs}
        translations={translations}
        currentUser={currentUser}
        translateText={translateText}
      />,
      <TranslationQueue
        currentLanguage={currentLanguage}
        currentUser={currentUser}
        translations={translations}
        loadTranslationsList={loadTranslationsList}
        loadTranslationListLocal={loadTranslationListLocal}
      />
    ];
    return (
      <div className={`page ${BLOCK}`}>
        {down && selectedTab < 2 && (
          <TranslationDownMessage
            machineTranslationDownTimeDuration={translations.machineTranslationDownTimeDuration}
            machineTranslationDownTimeDurationUnitKey={translations.machineTranslationDownTimeDurationUnitKey}
          />
        )}
        <ProjectsLeftMenu {...this.props} selectedTab={selectedTab} setSelectedTab = {this.setSelectedTab}/>
        <TranslationTabs
          children={tabsContent}
          loadTranslationsList={loadTranslationsList}
          translations={translations}
          selectedTab={selectedTab}
          setSelectedTab={this.setSelectedTab}
          down={down}
        />
        <UploadProgress clearUploads={this.clearUploads} uploads={filesOnProgress} />
      </div>
    );
  }
}

export default Translation;
