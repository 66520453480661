import React from 'react';
import PropTypes from 'prop-types';

import { getDocumentPageBinary } from 'store/api';
import { ITEM_STATUSES } from 'models/project-item';
import LoadingOverlay from 'components/shared/loading/light';
import Overlay from 'containers/document-viewer/document-renderer/overlay-container';
import Bookmarks from 'containers/document-viewer/document-renderer/bookmarks-container';

class Page extends React.Component {
  static propTypes = {
    currentDocument: PropTypes.object,
    currentSelection: PropTypes.any,
    page: PropTypes.object,
    pushUndoStack: PropTypes.func,
    readOnly: PropTypes.bool,
    searchResults: PropTypes.any,
    selection: PropTypes.any,
    selectionFunction: PropTypes.func,
    setPageImageLoaded: PropTypes.func,
    shiftKeyDown: PropTypes.bool,
    showPage: PropTypes.bool,
    zoomLevel: PropTypes.number,
    hideBookmark: PropTypes.bool
  };

  state = {
    loadingPageImage: true,
    loadingPageData: true,
    pageImage: undefined
  };

  // Scale Alteration is a value that is used to scale down the images that come from KIRA
  // This allows some resolution to be reserved for zooming in
  SCALE_ALTERATION = 0.5;

  BOTTOM_OFFSET = '40%';

  UNSAFE_componentWillMount() {
    this.setScaleAlteration();
  }

  componentDidMount = () => {
    this.getPageImage();
    this.getPageData();
  };

  componentDidUpdate = prevProps => {
    if (this.props.showPage !== prevProps.showPage) {
      this.getPageImage();
      this.getPageData();
    }

    // If document changes
    if (this.props.currentDocument.documentId !== prevProps.currentDocument.documentId) {
      this.setScaleAlteration();

      this.setState({ pageImage: undefined, loadingPageImage: true, loadingPageData: true }, () => {
        this.getPageImage();
        this.getPageData();
      });
    }
  };

  // Sets the scale alteration value based on the DPI of the KIRA images
  // This is generally 300dpi or 150dpi
  setScaleAlteration() {
    if (this.props.page) {
      this.SCALE_ALTERATION = 0.5 * (150 / this.props.page.dpiX);
    }
  }

  getPageData = () => {
    if (this.state.loadingPageData && this.props.showPage && !this.props.page.characterDataLoaded) {
      var { projectId, documentId } = this.props.currentDocument;
      var { page, getCurrentDocumentLayoutPage } = this.props;

      if (projectId && projectId !== null && documentId && documentId !== null) {
        getCurrentDocumentLayoutPage(projectId, documentId, page.pageNumber).then(() => {
          this.setState({ loadingPageData: false });
        });
      }
    } else if (this.state.loadingPageData && this.props.page.characterDataLoaded) {
      this.setState({ loadingPageData: false });
    }
  };

  getPageImage = () => {
    var { showPage, setPageImageLoaded } = this.props;
    var { projectId, documentId } = this.props.currentDocument;
    var { pageNumber } = this.props.page;

    if (showPage && projectId !== null && documentId !== null) {
      if (!this.state.pageImage) {
        getDocumentPageBinary(projectId, documentId, pageNumber).then(data => {
          this.setState({ pageImage: data, loadingPageImage: false }, () => setPageImageLoaded());

          if (this.image) {
            this.image.src = URL.createObjectURL(new Blob([data]));
          }
        });
      } else {
        this.setState({ loadingPageImage: false }, () => {
          if (this.image) this.image.src = URL.createObjectURL(new Blob([this.state.pageImage]));
          setPageImageLoaded();
        });
      }
    }
  };

  render() {
    var {
      currentDocument,
      page,
      readOnly,
      searchResults,
      selection,
      currentSelection,
      selectionFunction,
      shiftKeyDown,
      showPage,
      zoomLevel,
      pushUndoStack,
      hideBookmark
    } = this.props;

    var { loadingPageImage } = this.state;
    const { characterDataLoaded } = page;

    var imageClass = characterDataLoaded ? '' : 'loading';
    var isProcessing = currentDocument.itemStateId !== ITEM_STATUSES.PROCESSED;



     const pageClassName = hideBookmark ? "renderer-page extractionoverview-docview" : "renderer-page";
    // Page element
    return (
      <div id={'page-' + page.pageNumber} className={pageClassName} ref={div => (this.wrapper = div)}>
        <div
          className="page-shadow"
          style={{
            width: page.width * zoomLevel * this.SCALE_ALTERATION,
            height: page.height * zoomLevel * this.SCALE_ALTERATION
          }}
        >
          {loadingPageImage || !characterDataLoaded ? <LoadingOverlay text={false} /> : null}

          {showPage && !loadingPageImage && characterDataLoaded  ? (
            <React.Fragment>
            {!hideBookmark &&
             ( <Bookmarks 
                page={page}
                scale={zoomLevel * this.SCALE_ALTERATION}
                readOnly={readOnly}
                pushUndoStack={pushUndoStack}
              />
             )}

              {!isProcessing && (
                <Overlay
                  hideBookmark = {hideBookmark}
                  page={page}
                  scale={zoomLevel * this.SCALE_ALTERATION}
                  searchResults={searchResults}
                  selection={selection}
                  currentSelection={currentSelection}
                  selectionFunction={selectionFunction}
                  shiftKeyDown={shiftKeyDown}
                  readOnly={readOnly}
                />
              )}
            </React.Fragment>
          ) : null}

          {showPage && !loadingPageImage ? <img className={imageClass} ref={img => (this.image = img)} alt="" /> : null}
        </div>
      </div>
    );
  }
}

export default Page;
