import { connect } from 'react-redux';
import { onSearchChange } from 'store/search/search-category';
import UserManagement from 'components/user-management/user-management-page';

const mapStateToProps = ({ data: { search,currentUser } }) => ({
  search,currentUser
});
const mapDispatchToProps = {
  onSearchChange
};
export default connect(mapStateToProps,mapDispatchToProps)(UserManagement);
