import { connect } from 'react-redux';

import Toolbar from 'components/document-viewer/toolbar/toolbar';
import { getCurrentDocument, getNextDocument, getPreviousDocument } from 'store/current-document';
import { popUndoStack, clearUndoStack } from 'store/document-viewer-undo';

const mapStateToProps = ({
  ui: { documentViewerUndo },
  data: { currentDocument, currentDocumentLayout, currentProject }
}) => ({
  documentViewerUndo,
  currentDocument,
  currentDocumentLayout,
  currentProject
});

const mapDispatchToProps = {
  clearUndoStack,
  getCurrentDocument,
  getNextDocument,
  getPreviousDocument,
  popUndoStack
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
