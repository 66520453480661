import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import DocumentTags from 'containers/tags/tiles/document-tags';
import DocumentTypeTags from 'containers/tags/tiles/document-type-tags';
import ExtractionFieldRecordTags from 'containers/tags/tiles/extraction-field-record-tags';
import LanguageTags from 'containers/tags/tiles/language-tags';
import OCRQualityTags from 'containers/tags/tiles/ocr-quality-tags';

class Tags extends Component {
  static propTypes = {
    currentProject: PropTypes.object,
    getDocumentResults: PropTypes.func,
    onSelectCategory: PropTypes.func,
    projectId: PropTypes.any
  };

  state = {};

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  documentTagsOnClick = id => {
    var { projectId, getDocumentResults, onSelectCategory } = this.props;

    onSelectCategory({ key: 'documentTagId', data: [id] });
    getDocumentResults({
      projectId,
      pageNum: 1,
      documentTagId: [id]
    });
  };

  documentTypeTagsOnClick = id => {
    var { projectId, getDocumentResults, onSelectCategory } = this.props;

    onSelectCategory({ key: 'documentTagId', data: [id] });
    getDocumentResults({
      projectId,
      pageNum: 1,
      documentTagId: [id]
    });
  };

  extractionFieldRecordTagsOnClick = id => {
    var { projectId, getDocumentResults, onSelectCategory } = this.props;

    onSelectCategory({ key: 'extractionFieldInstanceTagId', data: [id] });
    getDocumentResults({
      projectId,
      pageNum: 1,
      extractionFieldInstanceTagId: [id]
    });
  };

  languageTagsOnClick = id => {
    var { projectId, getDocumentResults, onSelectCategory } = this.props;

    onSelectCategory({ key: 'documentTagId', data: [id] });
    getDocumentResults({
      projectId,
      pageNum: 1,
      documentTagId: [id]
    });
  };

  ocrQualityTagsOnClick = id => {
    var { projectId, getDocumentResults, onSelectCategory } = this.props;

    onSelectCategory({ key: 'documentTagId', data: [id] });
    getDocumentResults({
      projectId,
      pageNum: 1,
      documentTagId: [id]
    });
  };

  render = () => {
    var { currentProject, projectId } = this.props;

    if (!projectId) return null;
    return (
      <div className="tags-container">
        <div className="tags-container__header-title">
          <h2>
            <FormattedMessage id="tags-page.title" />
          </h2>
          <div className="tags-container__subtitle">{currentProject.projectName}</div>
        </div>

        <div className="tags-tiles">
          <ExtractionFieldRecordTags
            titleKey="tags-page.tiles.titles.efr"
            projectId={projectId}
            onClick={this.extractionFieldRecordTagsOnClick}
          />
          <DocumentTags
            titleKey="tags-page.tiles.titles.documents"
            projectId={projectId}
            onClick={this.documentTagsOnClick}
          />
          <DocumentTypeTags
            titleKey="tags-page.tiles.titles.document-types"
            projectId={projectId}
            onClick={this.documentTypeTagsOnClick}
          />
          <OCRQualityTags
            titleKey="tags-page.tiles.titles.ocr-quality"
            projectId={projectId}
            onClick={this.ocrQualityTagsOnClick}
          />
          <LanguageTags
            titleKey="tags-page.tiles.titles.language"
            projectId={projectId}
            onClick={this.languageTagsOnClick}
          />
        </div>
      </div>
    );
  };
}

export default Tags;
