import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import { getCurrentRegion } from 'store/api';
import {
  RESULT_CATEGORIES,
  RESULT_CATEGORY_ID_KEYS,
  RESULT_CATEGORY_KEYS,
  SEARCH_CATEGORIES,
  SEARCH_CATEGORY_DISPLAY_NAMES
} from 'components/search/constants';

// Displays category results in dropdown
export default class CategoryContainer extends Component {
  static propTypes = {
    getSearchDocument: PropTypes.func,
    getSearchFolder: PropTypes.func,
    history: PropTypes.object,
    onBlur: PropTypes.func,
    onSearch: PropTypes.func,
    onViewMore: PropTypes.func,
    projectId: PropTypes.string,
    results: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    showAdvancedSearch: PropTypes.func
  };

  onResultClick = (category, res) => {
    const { onSearch, history, getSearchDocument, getSearchFolder, onBlur, projectId } = this.props;

    const resultKey = RESULT_CATEGORY_ID_KEYS[category];
    const resultValue = res[resultKey];

    if (category === SEARCH_CATEGORIES.DOCUMENT_NAME) {
      onBlur();
      return history.push({
        pathname: `/region/${getCurrentRegion()}/project/${projectId}/document/${res.documentId}`
      });
    } else if (category === SEARCH_CATEGORIES.FOLDER_NAME) {
      onSearch(resultKey, resultValue);
      return getSearchFolder({ folderId: res.folderId });
    } else {
      onSearch(resultKey, resultValue);
      return getSearchDocument({ [resultKey]: resultValue });
    }
  };

  renderResult = (category, result, index) => (
    <div className="search-category__body-box" key={index}>
      <div
        className="search-category__body-title"
        title={result.name}
        onClick={() => this.onResultClick(category, result)}
      >
        {result.name}
      </div>
    </div>
  );

  renderCategories = () => {
    const { results, onViewMore } = this.props;

    return RESULT_CATEGORIES.map(categoryId => {
      const categoryData = results.categories[RESULT_CATEGORY_KEYS[categoryId]].data;
      // Filtered results so we only show first three
      const filteredResults = categoryData.length >= 3 ? categoryData.slice(0, 3) : categoryData;
      // Numbers to show how many results we are showing
      const current = Math.min(3, categoryData.length);
      const total = categoryData.length;

      // Show results if there is more than one
      if (categoryData && categoryData.length) {
        return (
          <div key={categoryId} className="search-category__wrapper">
            <span className="search-category__title">
              <FormattedMessage id={SEARCH_CATEGORY_DISPLAY_NAMES[categoryId]} />
            </span>
            <div className="search-category__body">
              {/* Results */}
              {filteredResults.map((result, index) => this.renderResult(categoryId, result, index))}

              {/* View more Button */}
              <div className="search-category__view-more">
                <FormattedMessage id="search-category.view-more" values={{ current, total }} />
                {categoryData.length >= 2 && (
                  <Button
                    size="icon"
                    className="search-category__view-more-button btn"
                    onClick={() => onViewMore(categoryId)}
                  >
                    <FormattedMessage id="search-category.view-more-button" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  render() {
    const { results, showAdvancedSearch } = this.props;

    let content;

    if (!results.isLoaded) {
      content = (
        <div className="search-category__loader">
          <Icon className="spinner spinner--centered" name="loader" width={20} />
        </div>
      );
    } else if (results.isNullResults) {
      content = (
        <div className="search-category__loader">
          <FormattedMessage id="search-results.empty-state-title" />
        </div>
      );
    } else {
      content = this.renderCategories();
    }

    return (
      <div className="search-category">
        <div className="search-category__content">{content}</div>
        <div className="search-category__footer">
          <Button className="search-category__advanced-search-button btn btn-no-margin" onClick={showAdvancedSearch}>
            <FormattedMessage id="search.dropdown.advanced-search" />
          </Button>
        </div>
      </div>
    );
  }
}
