import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { PropTypes } from 'prop-types';
import Autocomplete from 'react-autocomplete';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component } from 'react';

import { getProjectTags, createProjectTag, getItemTags, addItemTag, removeItemTag } from 'store/api';
import { sortByKey } from 'utils/string-utils';
import { TAG_TYPES } from 'models/project-item';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import Confirm from 'components/shared/confirm';
import Constants from 'utils/constants';
import store from 'store/store';
import { addNotification } from 'store/notifications.js';
import socket from 'utils/socket';

class TagsPopover extends Component {
  static propTypes = {
    intl: intlShape,
    invalidate: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.object.isRequired,
    visualsPageMode: PropTypes.bool
  };

  state = {
    isLoading: true,
    isProcessing: false,
    itemTags: [],
    projectTags: [],
    tagName: '',
    showConfirmationModal: false
  };

  componentDidMount() {
    const { visualsPageMode } = this.props;

    /* Disabled until delete issue is fixed  */
    socket.on('document.tag.added', this.onTagAddedToDocument);
    socket.on('document.tag.removed', this.onTagRemovedFromDocument);

    let getItemTags;
    if (visualsPageMode) {
      getItemTags = Promise.resolve();
      this.setState({ itemTags: [] });
    } else {
      getItemTags = this.getItemTags();
    }

    Promise.all([this.getProjectTags(), getItemTags]).then(() => {
      this.setState({ isLoading: false });
    });
  }

  componentWillUnmount() {
    /* Disabled until delete issue is fixed  */
    socket.off('document.tag.added', this.onTagAddedToDocument);
    socket.off('document.tag.removed', this.onTagRemovedFromDocument);
  }

  get selectedItemIds() {
    const { selectedItems } = this.props;

    return Object.keys(selectedItems).filter(id => selectedItems[id]);
  }

  getProjectTags() {
    const { projectId } = this.props;
    return getProjectTags({ projectId }).then(response => {
      this.setState({
        projectTags: sortByKey('tagName', Object.values(response.data.tags))
      });
    });
  }

  getItemTags = () => {
    const { projectId } = this.props;

    return getItemTags({ projectId, items: this.selectedItemIds }).then(responses => {
      const itemTagsById = {};
      responses.data.tags.forEach(tag => {
        itemTagsById[tag.tagId] = tag;
      });

      this.setState({
        itemTags: sortByKey('tagName', Object.values(itemTagsById))
      });
    });
  };

  handleClickOutside() {
    const { onClose } = this.props;
    const { showConfirmationModal } = this.state;

    if (!showConfirmationModal) {
      onClose();
    }
  }

  onSubmit = event => {
    const { visualsPageMode } = this.props;

    event.preventDefault();

    if (visualsPageMode) {
      this.setState({ showConfirmationModal: true });
    } else {
      this.addTag();
    }
  };

  addTag = () => {
    const { projectId, visualsPageMode, onClose, invalidate } = this.props;
    const { tagName, projectTags } = this.state;

    const existingTag = projectTags
      .filter(({ tagTypeId }) => tagTypeId === TAG_TYPES.USER)
      .find(tag => tag.tagName.toLowerCase() === tagName.toLowerCase());

    let promise = existingTag
      ? Promise.resolve(existingTag)
      : createProjectTag({ projectId, tagName }).then(response => response.data);

    promise.then(newOrExistingTag => {
      return addItemTag({ projectId, items: this.selectedItemIds, tag: newOrExistingTag }).then(() => {
        invalidate && invalidate();

        let itemTags = this.state.itemTags;
        let projectTags = this.state.projectTags;

        if (!itemTags.find(tag => tag.tagId === newOrExistingTag.tagId)) {
          itemTags = sortByKey('tagName', [...this.state.itemTags, newOrExistingTag]);
        }

        if (!projectTags.find(tag => tag.tagId === newOrExistingTag.tagId)) {
          projectTags = sortByKey('tagName', [...this.state.projectTags, newOrExistingTag]);
        }

        this.setState({
          itemTags,
          projectTags,
          isProcessing: false,
          tagName: ''
        });
      });
    });

    if (visualsPageMode) {
      promise.then(() => {
        onClose();
        store.dispatch(
          addNotification({
            message: <FormattedMessage id="file-browser.tags-popover.tag-added" />,
            clearOnPageChange: true,
            autohide: 10
          })
        );
      });
      this.setState({ showConfirmationModal: false });
    }

    promise.catch(() => {
      this.setState({ isProcessing: false });
    });
  };

  removeItemTag = tag => {
    const { projectId, invalidate } = this.props;
    removeItemTag({ projectId, items: this.selectedItemIds, tag }).then(() => {
      invalidate && invalidate();

      this.setState({
        itemTags: this.state.itemTags.filter(value => value.tagId !== tag.tagId)
      });
    });
  };

  onTagNameChange = newValue => {
    this.setState({
      tagName: newValue
    });
  };

  renderTagName = tag => {
    // const { intl: { formatMessage } } = this.props;
    // switch (tag.tagTypeId) {
    //   case TAG_TYPES.SYSTEM_DUPLICATE:
    //     return `${formatMessage({ id: 'file-browser.tags-popover.duplicate-tag' })} - ${tag.tagName}`;
    //   case TAG_TYPES.USER:
    //   default:
    //     return tag.tagName;
    // }
    return tag.tagName;
  };

  /*
   Handle a tag being added to a document
  */
  onTagAddedToDocument = response => {
    let itemTags = this.state.itemTags;
    let projectTags = this.state.projectTags;
    let documentId = response.data.documentId;
    let updatedTag = response.data.tag;

    // If tag was added to a document that is not part of the selected items, ignore
    if (!this.selectedItemIds.some(id => id === documentId.toString())) {
      return;
    }

    const itemTag = itemTags.find(tag => tag.tagId === updatedTag.tagId);

    if (!itemTag) {
      // If that tag was not already present on one of the selecte items
      // create a new entry for it
      itemTags = sortByKey('tagName', [
        ...this.state.itemTags,
        { ...updatedTag, tagProjectItems: [{ projectItemId: documentId }] }
      ]);
    } else {
      // If the tag is already present on one of the selected items,
      // just mark that the tag was added to the document
      itemTag.tagProjectItems.push({ projectItemId: documentId });
    }

    // Add the tag to projectTags if necessary
    if (!projectTags.find(tag => tag.tagId === updatedTag.tagId)) {
      projectTags = sortByKey('tagName', [...this.state.projectTags, updatedTag]);
    }

    this.setState({ itemTags, projectTags });
  };

  /*
   Handle a tag being removed from a document
  */
  onTagRemovedFromDocument = response => {
    let { itemTags } = this.state;
    let documentId = response.data.documentId;

    // If tag was removed from a document that is not part of the selected items, ignore
    if (!this.selectedItemIds.some(id => id === documentId.toString())) {
      return;
    }

    const tag = itemTags.find(tag => tag.tagId === response.data.tagId);

    if (!tag) {
      return;
    }

    // TODO: may need to filter any tagProject items that are not part of selectedItemIds
    tag.tagProjectItems = tag.tagProjectItems.filter(tagProjectItem => tagProjectItem.projectItemId !== documentId);

    if (!tag.tagProjectItems.length) {
      itemTags = itemTags.filter(itemTag => itemTag.tagId !== tag.tagId);
    }

    this.setState({ itemTags });
  };

  renderAutocompleteInput = props => {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <input
        {...props}
        autoFocus={true}
        maxLength="400"
        placeholder={formatMessage({ id: 'file-browser.tags-popover.tags-input' })}
        type="text"
      />
    );
  };

  renderAutocompleteMenu = items => <div className="tags-popover__autocomplete-menu" children={items} />;

  renderAutocompleteItem = (item, isHighlighted) => (
    <div
      className={classNames(
        'tags-popover__autocomplete-item',
        isHighlighted && 'tags-popover__autocomplete-item--highlighted'
      )}
      key={item}
    >
      {item}
    </div>
  );

  render() {
    const { visualsPageMode, onClose } = this.props;
    const { tagName, projectTags, itemTags, isProcessing, isLoading, showConfirmationModal } = this.state;

    if (isLoading) {
      return (
        <div className="tags-popover popover">
          <Icon className="spinner spinner--centered" name="loader" width={70} />
        </div>
      );
    }

    const menuItems = projectTags
      .filter(({ tagTypeId }) => tagTypeId === TAG_TYPES.USER)
      .map(tag => this.renderTagName(tag));

    const filteredItemTags = itemTags.filter(
      tag =>
        tag.tagTypeId !== Constants.DocumentTagTypes.LANGUAGE &&
        tag.tagTypeId !== Constants.DocumentTagTypes.DOCUMENT_TYPE
    );

    return (
      <div className="tags-popover popover">
        <div className="tags-popover__header popover__header">
          <h3 className="tags-popover__title popover__title">
            <FormattedMessage id="file-browser.tags-popover.tags-label" />
          </h3>
          <Button onClick={onClose} className="btn btn-no-margin icon-button">
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className="popover__section tags-popover__section">
          <form className="tags-popover__add-tag-form" onSubmit={this.onSubmit}>
            <div className="tags-popover__add-tag-form-field form-field">
              <Autocomplete
                autoHighlight={true}
                getItemValue={item => item}
                items={menuItems}
                onChange={event => this.onTagNameChange(event.target.value)}
                onSelect={this.onTagNameChange}
                renderInput={this.renderAutocompleteInput}
                renderItem={this.renderAutocompleteItem}
                renderMenu={this.renderAutocompleteMenu}
                shouldItemRender={(item, value) => value && item.toLowerCase().indexOf(value.toLowerCase()) > -1}
                value={tagName}
                wrapperStyle={{ display: 'block' }}
              />
            </div>
            <div className="tags-popover__add-tag-form-field form-field">
              <Button
                type="submit"
                className="btn btn-primary btn-no-margin"
                disabled={!tagName.length || isProcessing}
              >
                <FormattedMessage id="file-browser.tags-popover.add-button" />
              </Button>
            </div>
          </form>
        </div>
        {!visualsPageMode && (
          <div className="popover__section tags-popover__section">
            <div className="form-field">
              <label>
                <FormattedMessage id="file-browser.tags-popover.document-tags-label" />
              </label>
              <div className="tags-popover__pills">
                {filteredItemTags.map(tag => (
                  <div
                    className={classNames(
                      'tags-popover__tag-pill',
                      tag.tagTypeId !== TAG_TYPES.USER && 'tags-popover__tag-pill--system-tag'
                    )}
                    key={tag.tagId}
                  >
                    <span className="tags-popover__tag-pill-text">{this.renderTagName(tag)}</span>
                    {tag.tagTypeId === TAG_TYPES.USER && (
                      <Button className="tags-popover__tag-pill-close" onClick={() => this.removeItemTag(tag)}>
                        <Icon name="special-c-cross" width={18} />
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {showConfirmationModal && (
          <Confirm
            onCancel={() => this.setState({ showConfirmationModal: false })}
            onSubmit={this.addTag}
            title={<FormattedMessage id="file-browser.tags-popover.confirmation-title" />}
            message={<FormattedMessage id="file-browser.tags-popover.confirmation" />}
            cancelText={<FormattedMessage id="common.no" />}
            confirmText={<FormattedMessage id="common.yes" />}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(TagsPopover));
