import { createAction, createReducer } from 'utils/redux-utils';
import { ExtractionOverviewTemplates } from 'models/extraction-overview';
import * as api from 'store/api';

export const ACTIONS = {
  EXTRACTION_OVERVIEW_TEMPLATES_LOADING: 'argus/ui/EXTRACTION_OVERVIEW_TEMPLATES_LOADING',
  EXTRACTION_OVERVIEW_TEMPLATES_LOADED: 'argus/ui/EXTRACTION_OVERVIEW_TEMPLATES_LOADED',
  EXTRACTION_OVERVIEW_SELECT_TEMPLATE_LOADING: 'argus/ui/EXTRACTION_OVERVIEW_SELECT_TEMPLATE_LOADING',
  EXTRACTION_OVERVIEW_SELECT_TEMPLATE_LOADED: 'argus/ui/EXTRACTION_OVERVIEW_SELECT_TEMPLATE_LOADED',
  EXTRACTION_OVERVIEW_UPDATE_COLUMN_OPTIONS: 'argus/ui/EXTRACTION_OVERVIEW_UPDATE_COLUMN_OPTIONS'
};

export const COLUMN_OPTIONS = {
  DOCUMENT_NAME: 'DOCUMENT_NAME',
  SAMPLE_NUMBER: 'SAMPLE_NUMBER'
};

export const COLUMN_OPTION_DEFAULTS = {
  [COLUMN_OPTIONS.DOCUMENT_NAME]: {
    displayName: 'extraction-overview.document-column'
  },
  [COLUMN_OPTIONS.SAMPLE_NUMBER]: {
    displayName: 'extraction-overview.sample-column'
  }
};

export const DEFAULT_COLUMN_FROZEN = {
  [COLUMN_OPTIONS.DOCUMENT_NAME]: false,
  [COLUMN_OPTIONS.SAMPLE_NUMBER]: false
};

export const DEFAULT_COLUMN_VISIBILITY = {
  [COLUMN_OPTIONS.DOCUMENT_NAME]: true,
  [COLUMN_OPTIONS.SAMPLE_NUMBER]: true
};

export const projectTemplatesLoading = createAction(ACTIONS.EXTRACTION_OVERVIEW_TEMPLATES_LOADING);
export const projectTemplatesLoaded = createAction(ACTIONS.EXTRACTION_OVERVIEW_TEMPLATES_LOADED);
export const selectProjectTemplateLoading = createAction(ACTIONS.EXTRACTION_OVERVIEW_SELECT_TEMPLATE_LOADING);
export const selectProjectTemplateLoaded = createAction(ACTIONS.EXTRACTION_OVERVIEW_SELECT_TEMPLATE_LOADED);
export const updateColumnOptions = createAction(ACTIONS.EXTRACTION_OVERVIEW_UPDATE_COLUMN_OPTIONS);

export const loadProjectTemplates = data => dispatch => {
  dispatch(projectTemplatesLoading());
  return api.getTemplates(data)?.then(templates => {
    let storedTemplate = Number(sessionStorage.getItem('selected-template'));
    let storedTemplateProject = Number(sessionStorage.getItem('selected-template-projectId')); 
    let templateToFetch =  templates.data[0]?.projectTemplateId;;
   if(storedTemplateProject && storedTemplate)
   {
     if(storedTemplateProject == data.projectId)
     {
      templateToFetch = storedTemplate
     }
  }

    if (templates.data.length) {
      return Promise.all([
        api.getProjectTemplateExtractionFields(data.projectId, templateToFetch),
        api.getTemplateExtractions({ projectId: data.projectId, templateId: templateToFetch }),
        api.getExtractionOverviewColumnOptions()
      ]).then(([templateExtractionFieldsResponse, templateExtractionsResponse, columnOptionsResponse]) =>
        dispatch(
          projectTemplatesLoaded({
            templates: templates.data,
            templateExtractionFields: templateExtractionFieldsResponse.data,
            templateExtractions: templateExtractionsResponse.data,
            columnOptions: columnOptionsResponse.fields
              ? fromAPIFormat(columnOptionsResponse.fields)
              : { visibility: { ...DEFAULT_COLUMN_VISIBILITY }, frozen: { ...DEFAULT_COLUMN_FROZEN } }
          })
        )
      );
    }
    dispatch(projectTemplatesLoaded(templates.data));
  });
};

const fromAPIFormat = fields => {
  const visibility = {};
  const frozen = {};

  fields.forEach(field => {
    visibility[field.fieldName] = field.visible;
    frozen[field.fieldName] = field.frozen;
  });

  return { visibility, frozen };
};

const toAPIFormat = columnOptions => {
  return Object.values(COLUMN_OPTIONS).map(fieldName => ({
    fieldName,
    visible: columnOptions.visibility[fieldName],
    frozen: columnOptions.frozen[fieldName]
  }));
};

export const updateExtractionOverviewColumnOptions = columnOptions => dispatch => {
  const payload = toAPIFormat(columnOptions);
  dispatch(updateColumnOptions(columnOptions));

  api.setExtractionOverviewColumnOptions(payload);
};

export const setTemplate = data => dispatch => {
  dispatch(selectProjectTemplateLoading());
  return Promise.all([
    api.getProjectTemplateExtractionFields(data.projectId, data.template.projectTemplateId),
    api.getTemplateExtractions({ projectId: data.projectId, templateId: data.template.projectTemplateId })
  ]).then(([templateExtractionFieldsResponse, templateExtractionsResponse]) =>
    dispatch(
      selectProjectTemplateLoaded({
        selectedTemplate: data.template,
        templateExtractionFields: templateExtractionFieldsResponse.data,
        templateExtractions: templateExtractionsResponse.data
      })
    )
  );
};

export const INITIAL_STATE = new ExtractionOverviewTemplates();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.EXTRACTION_OVERVIEW_TEMPLATES_LOADING]: (state, action) => {
    return state.setTemplatesLoading();
  },

  [ACTIONS.EXTRACTION_OVERVIEW_TEMPLATES_LOADED]: (state, action) => {
    return state.setTemplatesLoaded(state, action);
  },

  [ACTIONS.EXTRACTION_OVERVIEW_SELECT_TEMPLATE_LOADING]: (state, action) => {
    return state.setTemplatesLoading();
  },

  [ACTIONS.EXTRACTION_OVERVIEW_SELECT_TEMPLATE_LOADED]: (state, action) => {
    return state.setSelectedTemplateLoaded(state, action);
  },

  [ACTIONS.EXTRACTION_OVERVIEW_UPDATE_COLUMN_OPTIONS]: (state, action) => {
    return state.setColumnOptions(state, action);
  }
});
