import model from 'models/base-model';

export class ExtractionOverviewTemplates extends model({
  error: null,
  isTemplatesLoading: false,
  selectedTemplate: {},
  templateExtractions: [],
  templateExtractionFields: [],
  templates: [],
  columnOptions: {}
}) {
  /* LOADERS */
  setTemplatesLoading() {
    return this.merge({
      isTemplatesLoading: true,
      error: null
    });
  }

  /* ERROR */
  setError(error) {
    return this.merge({
      error: error
    });
  }

  /* TEMPLATES LOADED */
  setTemplatesLoaded(state, action) {
    var storedTemplateID = Number(sessionStorage.getItem('selected-template'));
    var storedTemplate =action.payload?.templates?.[0];
    if(storedTemplateID)
    {
     if(action.payload.templates?.find(temp => temp.projectTemplateId == storedTemplateID))
     {
      storedTemplate = action.payload.templates.find(temp => temp.projectTemplateId == storedTemplateID);
     };
    }

    return this.merge({
      ...state,
      isTemplatesLoading: false,
      templates: action.payload.templates,
      selectedTemplate: storedTemplate,
      templateExtractionFields: action.payload.templateExtractionFields?.groups
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .flatMap(({ extractionFields }) => extractionFields),
      templateExtractionGroups: action.payload.templateExtractionFields?.groups
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(({ extractionFieldGroupId, extractionFieldGroupName, extractionFields }) => ({
          extractionFieldGroupId,
          extractionFieldGroupName,
          extractionFieldsCount: extractionFields.length,
          extractionFieldIds: extractionFields.map(ef => ef.extractionFieldId)
        })),
      templateExtractions: action.payload.templateExtractions,
      columnOptions: action.payload.columnOptions
    });
  }

  setColumnOptions(state, action) {
    return this.merge({
      columnOptions: action.payload
    });
  }

  setSelectedTemplateLoaded(state, action) {
    return this.merge({
      ...state,
      isTemplatesLoading: false,
      selectedTemplate: action.payload.selectedTemplate,
      templateExtractionFields: action.payload.templateExtractionFields.groups
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .flatMap(({ extractionFields }) => extractionFields),
      templateExtractionGroups: action.payload.templateExtractionFields.groups
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(({ extractionFieldGroupId, extractionFieldGroupName, extractionFields }) => ({
          extractionFieldGroupId,
          extractionFieldGroupName,
          extractionFieldsCount: extractionFields.length,
          extractionFieldIds: extractionFields.map(ef => ef.extractionFieldId)
        })),
      templateExtractions: action.payload.templateExtractions
    });
  }
}
