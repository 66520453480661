import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { debounce } from 'underscore';
import { getCurrentRegion } from 'store/api';

import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

import Button from 'components/shared/single-click-button';
import SearchResults from 'containers/search/file-list-search/search-results-container';
import Icon from 'components/shared/icon';
import TagsPopover from 'components/file-browser/toolbar/tags-popover';
import { Breadcrumbs } from 'components/document-viewer/toolbar/breadcrumbs';
import { NavigationButtons } from 'components/document-viewer/toolbar/navigation-buttons';
import { NavigationDropdown } from 'components/document-viewer/toolbar/navigation-dropdown';
import ReviewButton from 'containers/document-viewer/toolbar/review-button-container';
import Constants from 'utils/constants';
import UndoButton from 'components/document-viewer/toolbar/undo-button';

import Permissions from 'permissions/permissions';

class DropdownData extends Component {
  static propTypes = {
    advancedSearch: PropTypes.object,
    currentSearchFolder: PropTypes.object,
    currentUser: PropTypes.object,
    disabled: PropTypes.bool,
    docMetadata: PropTypes.object,
    documentId: PropTypes.any,
    history: PropTypes.object,
    projectId: PropTypes.any,
    selected: PropTypes.any
  };

  state = {
    text: ''
  };

  onDropdownItemClick = docId => {
    this.props.history.push({
      pathname: `/region/${getCurrentRegion()}/project/${this.props.projectId}/document/${docId}`,
      state: { isSearching: true }
    });
  };

  // Code taken from doc-viewer nav dropdown; expected to have similar high level funcs
  getPageData = () => {
    const { text } = this.state;
    const { currentSearchFolder, currentUser, documentId } = this.props;
    const filtered =
      currentSearchFolder &&
      currentSearchFolder.children.filter(child => child.itemName.toLowerCase().indexOf(text.toLowerCase()) !== -1);

    return filtered.map((child, i) => {
      if (!child) return null;
      const isSelfReviewed =
        child && currentUser.isLoaded
          ? child.reviewers.find(reviewer => reviewer.userId === currentUser.userId)
          : false;
      const isCurrentDoc = child.projectItemId === documentId;

      return (
        <div className={'result-page page-' + i} key={i}>
          <div className="result-row-wrapper">
            {child && child.itemTypeId === 2 ? (
              <span
                className={`result-row ${isCurrentDoc ? 'current-document' : ''}`}
                onClick={() => this.onDropdownItemClick(child.projectItemId)}
              >
                <span>{child.itemName}</span>

                <div className={`reviewers ${isSelfReviewed ? 'self' : ''}`}>
                  {child.reviewers.length > 0 ? (
                    <Icon
                      name={isSelfReviewed ? 'special-checked-green' : 'special-checked-black'}
                      width={16}
                      height={16}
                    />
                  ) : null}
                  {child.reviewers.length > 1 ? child.reviewers.length : null}
                </div>
              </span>
            ) : null}
          </div>
        </div>
      );
    });
  };

  render() {
    const itemName = this.props.docMetadata.itemName;
    return (
      <NavigationDropdown
        disabled={this.props.disabled}
        isLoading={this.state.isLoading}
        itemName={itemName}
        onTextChange={value => this.setState({ text: value })}
        pages={this.getPageData()}
        text={this.state.text}
      />
    );
  }
}

/** Search toolbar functions like the document viewer toolbar, with different data inside */
class SearchToolbar extends Component {
  static propTypes = {
    clearUndoStack: PropTypes.func,
    currentDocument: PropTypes.any,
    currentSearchFolder: PropTypes.object,
    currentUser: PropTypes.object,
    documentStateErrors: PropTypes.any,
    documentViewerUndo: PropTypes.object,
    getCurrentDocument: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    openSearchResults: PropTypes.func,
    popUndoStack: PropTypes.func,
    projectId: PropTypes.any,
    readOnly: PropTypes.bool,
    refreshData: PropTypes.func,
    search: PropTypes.object
  };

  state = {
    isSearchResultsOpen: false,
    editTags: false,
    text: '',
    isLoading: false
  };

  toggleEditTags = debounce(() => {
    const { editTags } = this.state;
    this.setState({ editTags: !editTags });
  }, 16);

  // Navigate to search results page
  getBreadcrumb = () => {
    return (
      <span
        className="search-toolbar__breadcrumbs"
        onClick={() => {
          this.setState({ isSearchResultsOpen: true });
          this.props.openSearchResults(true);
        }}
      >
        <FormattedMessage id="search-results.heading" />
      </span>
    );
  };

  // Get the index for the current document in current search folder
  getCurrentDocument = () => {
    const { documentId } = this.props.currentDocument;

    const { currentSearchFolder } = this.props;
    return currentSearchFolder.children.findIndex(v => v.projectItemId === documentId);
  };

  onPreviousClick = () => {
    let prevId;
    const currentId = this.getCurrentDocument();
    if (currentId === 0) {
      prevId = this.props.currentSearchFolder.children[this.props.currentSearchFolder.children.length - 1]
        .projectItemId;
    } else {
      prevId = this.props.currentSearchFolder.children[currentId - 1].projectItemId;
    }

    this.props.history.push({
      pathname: `/region/${getCurrentRegion()}/project/${this.props.match.params.projectId}/document/${prevId}`,
      state: { isSearching: true }
    });
  };

  onNextClick = () => {
    let nextId;
    const currentId = this.getCurrentDocument();
    if (currentId === this.props.currentSearchFolder.children.length - 1) {
      nextId = this.props.currentSearchFolder.children[0].projectItemId;
    } else {
      nextId = this.props.currentSearchFolder.children[currentId + 1].projectItemId;
    }

    this.props.history.push({
      pathname: `/region/${getCurrentRegion()}/project/${this.props.match.params.projectId}/document/${nextId}`,
      state: { isSearching: true }
    });
  };

  onSearchInputCancelClick = () => {
    this.setState({ isSearchResultsOpen: false });
    this.props.openSearchResults(false);
  };

  render() {
    const { projectId } = this.props.match.params;
    const {
      currentSearchFolder,
      currentDocument,
      refreshData,
      history,
      match,
      search,
      currentUser,
      readOnly,
      location,
      clearUndoStack,
      documentStateErrors,
      documentViewerUndo,
      popUndoStack
    } = this.props;
    const { documentId } = this.props.currentDocument;
    const { editTags, isSearchResultsOpen } = this.state;

    if (currentSearchFolder.isLoading) {
      return (
        <div className="search-results">
          <div className="search-results__empty-state">
            <Icon className="spinner spinner--centered" name="loader" width={80} />
          </div>
        </div>
      );
    }
    return (
      <div className="document-viewer-toolbar">
        <div className="toolbar-flex-area">
          <div className="toolbar-group-left">
            <Breadcrumbs
              parentProject={this.getBreadcrumb()}
              firstFolder={currentSearchFolder.children.length === 1 ? currentSearchFolder.children[0].itemName : null}
              document={
                currentSearchFolder.children.length > 1 ? (
                  <DropdownData
                    advancedSearch={search}
                    currentSearchFolder={currentSearchFolder}
                    currentUser={currentUser}
                    disabled={currentSearchFolder.children.length === 1}
                    docMetadata={currentDocument}
                    documentId={documentId}
                    history={history}
                    projectId={projectId}
                    selected={search.selectedData}
                  />
                ) : null
              }
            />

            <NavigationButtons
              disabled={currentSearchFolder.children.length === 1}
              onPrev={this.onPreviousClick}
              onNext={this.onNextClick}
            />
          </div>

          <div className="toolbar-group-right">
            {!Permissions.Project.Document.canTag() || readOnly ? null : (
              <div className="bookmark">
                <UndoButton documentViewerUndo={documentViewerUndo} popUndoStack={popUndoStack} />
                <Button size="icon" className="icon-button" onClick={this.toggleEditTags} title="Tags">
                  <Icon name="tag-act" width={24} height={24} />
                </Button>

                {editTags ? (
                  <TagsPopover
                    onClose={this.toggleEditTags}
                    selectedItems={{ [documentId]: true }}
                    projectId={projectId.toString()}
                    tagsUpdated={() => {}}
                    invalidate={() => clearUndoStack()}
                  />
                ) : null}
              </div>
            )}
            <ReviewButton
              reviewers={currentDocument.reviewers}
              refreshData={refreshData}
              documentId={documentId}
              projectId={projectId}
              readOnly={readOnly || !Permissions.Project.Document.canReview()}
              disabled={
                documentStateErrors && documentStateErrors.indexOf(Constants.Document_Error_States.LAYOUT404) !== -1
              }
              clearUndoStack={clearUndoStack}
            />
          </div>
          {isSearchResultsOpen ? (
            <SearchResults
              category={search.selectedCategory}
              location={location}
              history={history}
              projectId={match.params.projectId}
              selected={search.selectedData}
              onRouteChange={() => {
                this.setState({ isSearchResultsOpen: false });
                this.props.openSearchResults(false);
              }}
              readOnly={readOnly}
              closeFunction={this.onSearchInputCancelClick}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(SearchToolbar);
