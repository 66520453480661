import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

const GlobalUserPermissions = {
  canViewTab: () => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) ||
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_SYSTEM)
    );
  },

  canViewUserManagementLink: () => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canViewMaintenanceMessagesLink: () => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_SYSTEM)
    );
  },

  canViewUserManagementPageRegion: () => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) &&
      RolesHelper.isUserFromProjectRegion('current')
    );
  },

  canEnable: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canDisable: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canPromoteToSystemAdmin: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_SYSTEM);
  },

  canPromoteToGeneralAdmin: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canviewAdminDashboard: () => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) ||
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_SYSTEM)
    );
  }
};

export default GlobalUserPermissions;
