import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Container from 'components/project-creation/shared/container';
import Icon from 'components/shared/icon';
import Permissions from 'permissions/permissions';
import PPMDApproval from 'components/project-creation/shared/ppmd-approval';
import TeamList from 'components/project-creation/team-members/team-list';
import SearchMembers from 'components/project-creation/team-members/search-members';
import Checkbox from 'components/shared/form/checkbox';
import Button from 'components/shared/single-click-button';
import { getCurrentRegion } from 'store/api';

class TeamMembers extends Component {
  static propTypes = {
    addProjectMember: PropTypes.func,
    clearProjectMember: PropTypes.func,
    currentProject: PropTypes.object,
    deleteProjectMember: PropTypes.func,
    history: PropTypes.object,
    loadProjectMembers: PropTypes.func,
    onChangeStep: PropTypes.func,
    project: PropTypes.any,
    projectList: PropTypes.object,
    projectMembers: PropTypes.object,
    projectState: PropTypes.object,
    step: PropTypes.number,
    steps: PropTypes.array,
    toggleSupportAccess: PropTypes.func,
    updateProjectMember: PropTypes.func,
    updateProjectState: PropTypes.func,
    user: PropTypes.object
  };

  state = {
    disableNext: false,
    member: '',
    selected: null,
    selectedProjectMember: null,
    newMemberSelectedRole: null,
    projectMemberSelectedRole: null,
    isValidMember: false,
    isSupportAccessEnabled: this.props.project.isAdminAccessEnabled,
    isLoading: false
  };

  componentDidMount() {
    const { project, projectMembers, loadProjectMembers } = this.props;

    if (project && project.projectId && !projectMembers.isLoaded) {
      return loadProjectMembers({ projectId: project.projectId });
    }
  }

  componentWillUnmount() {
    const { clearProjectMember, history } = this.props;

    // Clear any location state
    history.replace({ state: {} });
    clearProjectMember();
  }

  getRoleIdFromName = role => {
    if (role.key === 'owner') {
      return 6;
    } else if (role.key === 'contributor') {
      return 5;
    } else if (role.key === 'read-only') {
      return 4;
    }
  };

  // Disable add button if no user selected, no role selected and if user tries adding same person
  disableAddButton = () => {
    const { newMemberSelectedRole, member, selected, isValidMember } = this.state;
    const { projectMembers, user } = this.props;

    // restrict current users to add themselves to projects
    if (selected?.userId === user.userId) {
      return true;
    }

    if (projectMembers && projectMembers.members && selected) {
      const isSameMember = projectMembers.members.find(v => v.userDetail.userId === selected.userId);
      if (isSameMember !== undefined) {
        const isTemporaryUser = isSameMember.role.isTemporaryUser;
        return !newMemberSelectedRole || member.length <= 0 || !isValidMember || !isTemporaryUser;
      }
      return !newMemberSelectedRole || member.length <= 0 || isSameMember || !isValidMember;
    }
    return !newMemberSelectedRole || member.length <= 0 || !isValidMember;
  };

  updateCopiedProject = () => {
    const { project, updateProjectState } = this.props;
    this.setState({ disableNext: true });
    return updateProjectState({
      projectId: project.projectId,
      projectStateId: 2
    }).then(() => this.props.history.push(`/project-list`));
  };

  // Add a new member to the project
  onAddNewMember = () => {
    const { addProjectMember, project } = this.props;
    const { newMemberSelectedRole, selected } = this.state;

    addProjectMember({
      projectId: project.projectId,
      userId: selected && selected.userId,
      roleId: this.getRoleIdFromName(newMemberSelectedRole),
      roleDisplayName: newMemberSelectedRole
    });

    this.setState({ member: '', newMemberSelectedRole: null, isValidMember: false });
  };

  // Change the text in the team members search
  onNewMemberChange = e => {
    this.setState({ member: e.target.value, isValidMember: false });
  };

  // Select a new team member
  onNewMemberSelect = (val, item) => {
    this.setState({ selected: item, member: val, isValidMember: !!val });
  };

  // Change the role of a new team member
  onNewMemberRoleChange = newMemberSelectedRole => {
    this.setState({ newMemberSelectedRole });
  };

  // Handle closing the change role modal
  onChangeRoleClose = () => {
    this.setState({ projectMemberSelectedRole: null });
  };

  // Handle selecting a role from the change role modal
  onProjectMemberRoleChange = projectMemberSelectedRole => {
    this.setState({ projectMemberSelectedRole });
  };

  // Update the role of a team member
  updateProjectMember = () => {
    const { selectedProjectMember, projectMemberSelectedRole } = this.state;
    const { project, updateProjectMember } = this.props;

    if (selectedProjectMember === null) {
      return;
    }

    updateProjectMember({
      projectId: project.projectId,
      userId: selectedProjectMember.userId,
      roleId: this.getRoleIdFromName(projectMemberSelectedRole),
      roleDisplayName: projectMemberSelectedRole
    });

    this.setState({ selectedProjectMember: null });
  };

  // Handle selecting a member from the team list
  onProjectMemberRoleSelect = selectedProjectMember => {
    this.setState({ selectedProjectMember, projectMemberSelectedRole: null });
  };

  // Handle deleting a member from the team list
  deleteProjectMember = () => {
    const { selectedProjectMember } = this.state;
    const { project, deleteProjectMember } = this.props;

    deleteProjectMember({ projectId: project.projectId, userId: selectedProjectMember.userId });
    this.setState({ selectedProjectMember: null });
  };

  // Handle clicking on the next button
  onNext = () => {
    const { currentProject, projectState, onChangeStep, step } = this.props;

    // if (projectState.NEW || projectState.INPROGRESS) {
    //   this.setState({ disableNext: true });
    //   return this.props.history.push(
    //     `/region/${getCurrentRegion()}/project/${currentProject.projectId}/create-template`
    //   );
    // } else {
    //   return this.updateCopiedProject();
    // }

    if (projectState.COPY || projectState.CARRY) {
      return this.updateCopiedProject();
    } else {
      onChangeStep(step + 1);
    }
  };

  onToggleSupportAccess = () => {
    const { toggleSupportAccess, project } = this.props;
    this.setState({ isLoading: true });
    toggleSupportAccess({ projectId: project.projectId, allowAccess: this.state.isSupportAccessEnabled }).then(() =>
      this.setState({ isLoading: false })
    );
  };

  render() {
    const { step, steps, onChangeStep, project, projectMembers, projectState, user, currentProject } = this.props;
    const {
      disableNext,
      projectMemberSelectedRole,
      member,
      newMemberSelectedRole,
      selected,
      selectedProjectMember,
      isSupportAccessEnabled,
      isLoading
    } = this.state;

    const canEditTeamMembers =
      // Project is in correct state
      (projectState.NEW || projectState.INPROGRESS || projectState.SETTINGS || projectState.COPY) &&
      // Project is not archived
      !Permissions.Project._States.isProjectArchived(project) &&
      // User has privileges to edit project
      Permissions.Project.User.canEdit(project, 'current');

    const canDisableAccess = currentProject.myRole
      ? currentProject.myRole.isTemporaryUser && currentProject.isAdminAccessEnabled
      : false;

    return (
      <Container
        project={project}
        step={step}
        steps={steps}
        onChangeStep={onChangeStep}
        projectState={projectState}
        disabled={disableNext}
        disableBothButtons={projectState.SETTINGS || projectState.ARCHIVE}
        customNextButton={
          <Button className="btn btn-primary" disabled={disableNext} onClick={this.onNext}>
            <FormattedMessage
              id={`${projectState.COPY || projectState.CARRY ? 'main-section.footer-confirm-button' : 'common.next'}`}
            />
          </Button>
        }
        customHeader={<PPMDApproval {...this.props} />}
      >
        <div className="team-members__container">
          <div className="team-members__header">
            <div className="team-members__header-title">
              <span className="team-members__header-title-text">
                <FormattedMessage id="team-members.select-title" />
              </span>
            </div>
            <div className="team-members__header-description">
              <FormattedMessage id="team-memberspage.select-description" />
            </div>

            {canEditTeamMembers && (
              <SearchMembers
                disabled={this.disableAddButton()}
                keyword={member}
                onAdd={this.onAddNewMember}
                onMemberChange={this.onNewMemberChange}
                onMemberSelect={this.onNewMemberSelect}
                onRoleChange={this.onNewMemberRoleChange}
                role={newMemberSelectedRole}
                value={member}
                visibleOptions={['owner', 'contributor', 'read-only']}
              />
            )}
          </div>
          <div className="team-members__lower-height">
            {projectMembers.isLoading ? (
              <Icon className="spinner spinner--centered" name="loader" width={80} />
            ) : (
              <>
                <TeamList
                  onChangeProjectMemberRole={this.onProjectMemberRoleChange}
                  onRemoveProjectMember={this.deleteProjectMember}
                  onRoleModalClose={this.onChangeRoleClose}
                  onSelectProjectMember={this.onProjectMemberRoleSelect}
                  onSubmitRoleUpdate={this.updateProjectMember}
                  projectMembers={projectMembers}
                  projectState={projectState}
                  readOnly={projectState.ARCHIVE === true}
                  role={projectMemberSelectedRole}
                  selected={selectedProjectMember}
                  user={user}
                />
              </>
            )}
            {Permissions.Project.User.canEnableAccess(project, 'current') || canDisableAccess ? (
              <div className="team-members__support-team">
                <div className="team-members__support-team-checkbox">
                  <Checkbox
                    className="team-members__support-team-checkbox-icon"
                    disabled={Permissions.Project._States.isProjectArchived(project) ? true : false}
                    checked={isSupportAccessEnabled ? 'checked' : 'unchecked'}
                    id="enable-support"
                    onChange={() => this.setState({ isSupportAccessEnabled: !isSupportAccessEnabled })}
                    width={18}
                  />
                  <FormattedMessage id="menu.options.enable-support" />
                </div>
                {isSupportAccessEnabled && (
                  <div className="team-members__support-team-info">
                    <Icon className="team-members__support-team-info-icon" name="special-info-outline" width={18} />
                    <FormattedMessage id="guided-template.save-support-access-info" />
                  </div>
                )}
                <div className="team-members__save-wrapper">
                  <Button
                    className="btn btn-primary"
                    disabled={isSupportAccessEnabled === project.isAdminAccessEnabled}
                    onClick={() => this.onToggleSupportAccess()}
                  >
                    {isLoading ? (
                      <Icon className="spinner spinner--centered" name="spinner" width={18} />
                    ) : (
                      <FormattedMessage id="guided-template.save-support-access" />
                    )}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    );
  }
}

export default TeamMembers;
