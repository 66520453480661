import React, { useState, useReducer, useMemo } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Button from 'components/shared/single-click-button';
import TextArea from 'components/shared/form/textarea';
import { postNewRating } from 'store/api';

const MAX_HEIGHT = 55;
const initialState = { rating: null, feedback: '' };

function reducer(state, action) {
  switch (action.type) {
    case 'change_rating':
      return { ...state, rating: action.payload };
    case 'change_feedback':
      return { ...state, feedback: action.payload };
    default:
      throw new Error();
  }
}

function RateApplication({ currentUser, location, isOpen, handleSuccessSubmit, handleErrorSubmit, onCancel, intl }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChangeRating = newRating => dispatch({ type: 'change_rating', payload: newRating });
  const handleChangeFeedback = newRating => dispatch({ type: 'change_feedback', payload: newRating });
  const handleSubmit = () => {
    postNewRating(
      currentUser.userId,
      state.rating,
      state.feedback,
      window.location.origin + location.pathname,
      currentUser.email
    ).then(
      response => handleSuccessSubmit?.(response),
      error => handleErrorSubmit?.(error),
      state.rating = null,
      state.feedback = ''
    );
  };

  const handleCancel = () => {
    state.rating = null;
    onCancel();
  };

  const rating = useMemo(() => {
    const stars = new Array(5).fill(null).map((value, index) => (
      <span key={index} onClick={() => handleChangeRating(index + 1)}>
        <Icon name={index < state.rating ? 'rating-star-filled' : 'rating-star'} width={25} />
      </span>
    ));
    const ratingDescription =
      state.rating > 0 && state.rating < 6 ? (
        <div className="rate-application-dialog__rating-description">
          {<FormattedMessage id={`global.rate-app.rating-description-${state.rating}`} />}
        </div>
      ) : null;
    return (
      <div>
        <div className="rate-application-dialog__rating-stars">{stars}</div>
        {ratingDescription}
      </div>
    );
  }, [state.rating]);

  return isOpen ? (
    <Modal>
      <div className="modal__content rate-application-dialog">
        <div className="rate-application-dialog__header">
          <Icon name="rate-app" width={28} />
          <h4>
            <FormattedMessage id="global.rate-app.title" />
          </h4>
        </div>

        <div className="modal__body">
          <p>
            <FormattedMessage id="global.rate-app.label-rating" />
          </p>
          <div>{rating}</div>
          <label className="rate-application-dialog__label">
            <p>
              <FormattedMessage id="global.rate-app.label-feedback" />
            </p>
          </label>
          <TextArea
            className={`rate-application-dialog__feedback`}
            placeholder={intl.formatMessage({ id: 'global.rate-app.placeholder-feedback' })}
            onChange={handleChangeFeedback}
            value={state.feeedback}
            maxLength="1000"
            maxHeight={MAX_HEIGHT}
            calculateCustomStyles={domRef => {
              return domRef.scrollHeight > MAX_HEIGHT + 5 ? { overflow: 'auto' } : {};
            }}
          />
        </div>

        <div className="modal__footer">
          <Button type="button" className="btn btn-secondary" onClick={() => handleCancel()}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={state.rating === null && state.feedback?.trim?.().length === 0}
          >
            <FormattedMessage id="global.rate-app.submit-review" />
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
}

RateApplication.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  handleSuccessSubmit: PropTypes.func,
  handleErrorSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  intl: intlShape
};

export default injectIntl(RateApplication);
