import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';

/*
 * Text area
 * Use this when you need dynamic text area fields
 * Updated based on scroll height
 */
export default class TextArea extends Component {
  static propTypes = {
    isDragging: PropTypes.bool,
    maxHeight: PropTypes.number,
    minHeight: PropTypes.number,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    calculateCustomStyles: PropTypes.func,
    clientInfo: PropTypes.object
  };

  state = {
    internalValue: ''
  };

  static defaultProps = {
    maxHeight: 80,
    minHeight: 20
  };

  UNSAFE_componentWillReceiveProps(prevProps,nextProps) {
    // This is necessary due to a bug while using drag and drop, because the previous scroll height is persisted.
    // Trigger a re-render to calculate its new height in its new position
    if (this.props.isDragging && this.props.isDragging !== nextProps.isDragging) {
      this.setState({});
    }

    if(this.props.placeholder === 'Search for your engagement' && this.props.className === 'valid-textarea__input' && (this.props.clientInfo == null || this.props.clientInfo?.clientId != prevProps.clientInfo?.clientId) && this.state.internalValue != "")
      this.setState({internalValue : ""})
  }

  computeScrollHeight() {
    const currentHeight = this.field.style.height;
    // Must reset height to 0 to have a useful scrollHeight measure
    this.field.style.height = 0;
    const newHeight = this.field.scrollHeight;
    // Restore height
    this.field.style.height = currentHeight;
    // Return new heigth, derived from scrollHeight
    return newHeight;
  }

  computeStyle() {
    const { minHeight, maxHeight, calculateCustomStyles } = this.props;

    // We need the ref to compute height
    if (!this.field) {
      return { minHeight, height: minHeight };
    }
    // Compute height according to DOM element's scrollHeight
    const height = this.computeScrollHeight();

    const customStyles = calculateCustomStyles?.(this.field) ?? {};

    return { height: height > maxHeight ? maxHeight : height, minHeight, ...customStyles };
  }

  computeStyleForMATTextArea() {
    const { minHeight, maxHeight, calculateCustomStyles } = this.props;

    // We need the ref to compute height
    if (!this.field) {
      return { minHeight, height: minHeight };
    }
    // Compute height according to DOM element's scrollHeight
    const height = this.computeScrollHeight();

    const customStyles = calculateCustomStyles?.(this.field) ?? {};
    return { height: height > maxHeight ? maxHeight - 4 : height - 4, minHeight, ...customStyles };
  }

  computeStyleForClientTextArea() {
    const { minHeight, maxHeight, calculateCustomStyles } = this.props;

    // We need the ref to compute height
    if (!this.field) {
      return { minHeight, height: minHeight };
    }
    // Compute height according to DOM element's scrollHeight
    const height = this.computeScrollHeight();

    const customStyles = calculateCustomStyles?.(this.field) ?? {};
    return { height: height > maxHeight ? maxHeight - 4 : height - 4, minHeight, ...customStyles };
  }

  onChange = e => {
    let value = e.target.value;
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    // Set state to trigger re-render (and use re-computed height / style)
    this.setState({ internalValue: value });
  };

  onRef = element => {
    this.field = element;
    // Trigger 2nd render since we now have the ref and can correctly compute height
    // (so there's no visible "jump" in height to the end-user)
    this.setState({});
  };

  render() {
    const {
      onChange,
      placeholder,
      value,
      minHeight,
      maxHeight,
      isDragging,
      calculateCustomStyles,
      className,
      clientInfo,
      ...otherProps
    } = this.props;
    
    return placeholder === 'Search for your engagement' && className === 'valid-textarea__input' ? (
      <div className="valid-textarea-mat__input" style={this.computeStyle()}>
        <div>
          <textarea
            style={this.computeStyleForMATTextArea()}
            placeholder={' ' + placeholder}
            onChange={e => this.onChange(e)}
            value={value || (clientInfo != null ? this.state.internalValue : "")}
            className={'valid-textarea__MATInputTexArea'}
            ref={this.onRef}
            {...otherProps}
          />
        </div>
        <span className="valid-textarea__searchIcon">
          <Icon name="search-omnia" width={12} />
        </span>
      </div>
    ) : placeholder === 'Search for your client' && className === 'valid-textarea__input' ? (
      <div className="valid-textarea-client__input" style={this.computeStyle()}>
        <div>
          <textarea
            style={this.computeStyleForClientTextArea()}
            placeholder={' ' + placeholder}
            onChange={e => this.onChange(e)}
            value={value || this.state.internalValue}
            className={'valid-textarea__ClientInputTexArea'}
            ref={this.onRef}
            {...otherProps}
          />
        </div>
        <span className="valid-textarea__searchIcon">
          <Icon name="search-omnia" width={12} />
        </span>
      </div>
    ) : (
      <textarea
        style={this.computeStyle()}
        placeholder={placeholder}
        onChange={e => this.onChange(e)}
        value={value || this.state.internalValue}
        className={className}
        ref={this.onRef}
        {...otherProps}
      />
    );
  }
}
