import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import Header from 'containers/header/header';
import { withRouter } from 'react-router';
import VisualsHeader from 'components/visuals/visuals-header';
import VisualsDocumentList from 'components/visuals/visuals-document-list';
import VisualsDocuments from 'components/visuals/visuals-documents';
import VisualsExtractionFields from 'components/visuals/visuals-extraction-fields';
import * as api from 'store/api';
import { FormattedMessage } from 'react-intl';
import EmptyState from '../file-browser/file-list/empty-state';
import emptyStateImage from 'images/empty_state_visuals.svg';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'components/shared/loading/light';
import dateUtil from 'utils/dateUtil';
import socket, { rooms } from 'utils/socket';

import setUtil from 'utils/setUtil';

import Permissions from 'permissions/permissions';

const ALL_TEMPLATES_OPTION = {
  projectTemplateId: -1,
  name: <FormattedMessage id="visuals.all-templates" />
};

const CLUSTER_STATE_IDS = {
  STARTED: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  ERROR: 4
};

class VisualsPage extends Component {
  static propTypes = {
    currentProject: PropTypes.object,
    projectId: PropTypes.string.isRequired
  };

  defaultFilters = {
    selectedDocumentIdSet: new Set(),
    additionalSelectedDocumentIdSet: new Set(),
    activeExtraction: undefined,
    thresholdRange: { min: 0, max: 1 },
    activeTemplate: ALL_TEMPLATES_OPTION,
    searchText: '',
    noExtractions: false
  };

  state = {
    ...this.defaultFilters,
    documents: [],
    templates: [],
    loading: true,
    extractionMap: new Map(),
    extractionIdsByTemplate: new Map(),
    lastUpdatedDate: undefined,
    stateId: null,
    projectHasDocuments: false
  };

  visualsRoom = null;

  componentDidMount() {
    const { projectId } = this.props;

    this.visualsRoom = rooms.visuals(projectId);
    socket.join(this.visualsRoom);
    socket.on('update', this.handleVisualsUpdatedEvent);

    Promise.all([this.requestDocuments(), api.getTemplates({ projectId })])
      .then(([docData, { data: templates }]) => {
        var processedDocuments =
          docData.clusters === null
            ? []
            : docData.clusters.map(doc => ({
                ...doc,
                extractionRecords: doc.extractionRecords || []
              }));
        var convertedDate = docData.projectClusterId ? dateUtil.fromServerDate(docData.lastUpdatedDateTime) : undefined;

        this.setState({
          documents: processedDocuments,
          stateId: docData.projectClusterStateId,
          lastUpdatedDate: convertedDate,
          templates: templates,
          projectHasDocuments: docData && docData.totalDocuments > 0
        });

        this.getExtractionMaps();
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillUnmount() {
    socket.leave(this.visualsRoom);
    socket.off('update', this.handleVisualsUpdatedEvent);
  }

  handleVisualsUpdatedEvent = msg => {
    if (msg.room === this.visualsRoom) {
      const stateId = msg.data.stateId;

      this.setState({
        stateId
      });

      if (stateId === CLUSTER_STATE_IDS.COMPLETED) {
        this.requestDocuments().then(data =>
          this.setState(
            {
              documents: data.clusters?.map(doc => ({ ...doc, extractionRecords: doc.extractionRecords || [] })),
              lastUpdatedDate: dateUtil.fromServerDate(data.lastUpdatedDateTime),
              stateId: data.projectClusterStateId
            },
            () => {
              this.getExtractionMaps();
            }
          )
        );
      }
    }
  };

  getExtractionMaps = () => {
    const { projectId } = this.props;
    const { templates } = this.state;

    Promise.all(templates.map(t => api.getProjectTemplateExtractionFields(projectId, t.projectTemplateId))).then(
      templateDetailsResponses => {
        const extractionMap = new Map();
        const extractionIdsByTemplate = new Map();
        templateDetailsResponses.forEach(({ data: templateDetails }) => {
          const extractionIds = new Set();
          extractionIdsByTemplate.set(templateDetails.templates[0].projectTemplateId, extractionIds);
          templateDetails.fields.forEach(extraction => {
            extractionIds.add(extraction.extractionFieldId);
            extractionMap.set(extraction.extractionFieldId, extraction);
          });
        });

        this.setState({ extractionIdsByTemplate, extractionMap, loading: false });
      }
    );
  };

  requestDocuments = () => api.getClusterDocuments(this.props.projectId);

  regenerateVisuals = () => {
    const { projectId } = this.props;

    this.setState({
      stateId: CLUSTER_STATE_IDS.STARTED
    });
    api.processCluster(projectId);
  };

  filterDocumentsBySearch(documents, searchText) {
    return documents.filter(d => !searchText || d.name.toLowerCase().includes(searchText.toLowerCase()));
  }

  setSelectedDocumentIdSet = documentIdSet => {
    this.setState({
      selectedDocumentIdSet: documentIdSet
    });
  };

  setAdditionalSelectedDocumentIdSet = documentIdSet => {
    this.setState({
      additionalSelectedDocumentIdSet: documentIdSet
    });
  };

  setSearchText = searchText => {
    this.setState({ searchText });
  };

  setTemplate = activeTemplate => {
    this.setState({ activeTemplate });
  };

  setThresholdRange = thresholdRange => {
    this.setState({ thresholdRange });
  };

  setExtractionField = extraction => {
    const { activeExtraction } = this.state;
    const selectExtraction = activeExtraction !== extraction;

    if (selectExtraction) {
      this.setState({
        activeExtraction: extraction
      });
    } else {
      this.setState({
        activeExtraction: undefined
      });
    }
  };

  toggleNoExtractions = () => {
    this.setState({ noExtractions: !this.state.noExtractions });
  };

  resetFilters = () => {
    this.setState(this.defaultFilters);
  };

  getFilteredDocs = () => {
    const {
      documents,
      selectedDocumentIdSet,
      additionalSelectedDocumentIdSet,
      activeExtraction,
      activeTemplate,
      thresholdRange,
      searchText,
      noExtractions
    } = this.state;

    const allSelectedDocumentIdSet = setUtil.union(selectedDocumentIdSet, additionalSelectedDocumentIdSet);

    const filterBySelection = !!allSelectedDocumentIdSet.size;
    const filterByExtraction = !!activeExtraction;
    const filterByTemplate = activeTemplate !== ALL_TEMPLATES_OPTION;

    let filteredDocs = documents || [];

    if (filterByTemplate) {
      filteredDocs = filteredDocs.filter(d => d.projectTemplateId === activeTemplate.projectTemplateId);
    }

    const showEmptyState = filteredDocs.length === 0;

    if (!showEmptyState) {
      if (filterBySelection) {
        filteredDocs = filteredDocs.filter(d => allSelectedDocumentIdSet.has(d.id));
      }

      if (noExtractions) {
        if (filterByExtraction) {
          filteredDocs = filteredDocs.filter(d => !d.extractionRecords[activeExtraction.id]);
        } else {
          filteredDocs = filteredDocs.filter(d => Object.keys(d.extractionRecords).length === 0);
        }
      } else {
        if (filterByExtraction) {
          filteredDocs = filteredDocs.filter(d => d.extractionRecords[activeExtraction.id]);
        }
      }

      filteredDocs = filteredDocs.filter(d => d.density <= thresholdRange.max && d.density >= thresholdRange.min);

      filteredDocs = this.filterDocumentsBySearch(filteredDocs, searchText);
    }

    return { filteredDocs, showEmptyState };
  };

  render() {
    const { currentProject, projectId } = this.props;

    const {
      activeExtraction,
      activeTemplate,
      additionalSelectedDocumentIdSet,
      documents,
      extractionMap,
      lastUpdatedDate,
      loading,
      noExtractions,
      searchText,
      selectedDocumentIdSet,
      templates,
      thresholdRange,
      stateId,
      projectHasDocuments
    } = this.state;

    let allDocuments = documents || [];
    let { filteredDocs, showEmptyState } = this.getFilteredDocs();

    const allSelectedDocumentIdSet = setUtil.union(selectedDocumentIdSet, additionalSelectedDocumentIdSet);
    const visualsRegenerating = stateId === CLUSTER_STATE_IDS.STARTED || stateId === CLUSTER_STATE_IDS.IN_PROGRESS;

    return (
      <div className="page visuals">
        <Header {...this.props} fromVisualsPage currentPage="global.subheader.extraction"/>
        {loading && <LoadingOverlay className="visuals__loading" text={false} />}
        {!loading && (
          <div className="visuals__container">
            <VisualsHeader
              currentProject={currentProject}
              templates={[ALL_TEMPLATES_OPTION, ...templates]}
              setTemplate={this.setTemplate}
              activeTemplate={activeTemplate}
              searchFilteredDocuments={filteredDocs}
              searchText={searchText}
              setSearchText={this.setSearchText}
              setThresholdRange={this.setThresholdRange}
              thresholdRange={thresholdRange}
              resetView={this.resetFilters}
              regenerateVisuals={this.regenerateVisuals}
              visualsRegenerating={visualsRegenerating}
              lastUpdatedDate={lastUpdatedDate}
              projectId={projectId}
            />
            <div className="visuals__content">
              <div className="visuals__left-panel">
                {!showEmptyState && (
                  <div className="visuals__left-panel-content">
                    <VisualsDocuments
                      documents={allDocuments}
                      searchFilteredDocuments={filteredDocs}
                      setSelectedDocumentIdSet={this.setSelectedDocumentIdSet}
                      setAdditionalSelectedDocumentIdSet={this.setAdditionalSelectedDocumentIdSet}
                      searchText={searchText}
                      selectedDocumentIdSet={allSelectedDocumentIdSet}
                    />
                    <VisualsExtractionFields
                      extractionMap={extractionMap}
                      setExtractionField={this.setExtractionField}
                      documents={filteredDocs}
                      activeExtraction={activeExtraction}
                      noExtractions={noExtractions}
                      toggleNoExtractions={this.toggleNoExtractions}
                    />
                  </div>
                )}
                {showEmptyState && (
                  <div className="visuals__left-panel-content">
                    <EmptyState
                      title={
                        <FormattedMessage id={projectHasDocuments ? 'visuals.no-visuals' : 'visuals.no-documents'} />
                      }
                      description={''}
                      img={emptyStateImage}
                    >
                      <span>
                        <Link
                          to={`/region/${api.getCurrentRegion()}/project/${projectId}/folder/root`}
                          className="visuals__link"
                        >
                          <FormattedMessage id="visuals.go-to-documents" />
                        </Link>

                        {Permissions.Project.Visualization.canEdit() &&
                          !visualsRegenerating && (
                            <React.Fragment>
                              {' '}
                              <FormattedMessage id="visuals.empty-state.or" />{' '}
                              <span
                                className="visuals__link"
                                onClick={() => !visualsRegenerating && this.regenerateVisuals()}
                              >
                                <FormattedMessage id="visuals.header.regenerate-tooltip" />
                              </span>
                            </React.Fragment>
                          )}
                      </span>
                    </EmptyState>
                  </div>
                )}
              </div>
              <div className="visuals__right-panel">
                <VisualsDocumentList projectId={projectId} documents={filteredDocs} />
              </div>
            </div>
          </div>
        )}
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default withRouter(VisualsPage);
