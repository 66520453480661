import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class Icon extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
    // Note - the name of the icon corresponds to the filename of the svg with
    // the leading '{number}_' removed and all '_' replaced with '-'
    name: PropTypes.string.isRequired,
    rotate: PropTypes.number,
    width: PropTypes.number
  };

  render() {
    const { className, height, name, width, rotate } = this.props;

    const style = rotate ? { transform: `rotate(${rotate}deg)` } : null;

    return (
      <svg width={width} height={height || width} className={classNames('icon', className)} style={style}>
        <use xlinkHref={`#${name}`} />
      </svg>
    );
  }
}

export default Icon;
