import { connect } from 'react-redux';

import DocumentRenderer from 'components/document-viewer/document-renderer/document-renderer';
import { clearUndoStack } from 'store/document-viewer-undo';
import {
  organizeDocuments,
  setExtractionFieldFilter,
  updateSampleNumber,
  removeDocType,
  getCurrentDocument
} from 'store/current-document';

const mapStateToProps = ({ data: { currentDocument, currentDocumentLayout, currentProject } }) => ({
  currentDocument,
  currentDocumentLayout,
  currentProject
});

const mapDispatchToProps = {
  organizeDocuments,
  updateSampleNumber,
  setExtractionFieldFilter,
  removeDocType,
  getCurrentDocument,
  clearUndoStack
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRenderer);
