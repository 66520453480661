import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';
import { FormattedMessage } from 'react-intl';
import { TOOLBAR_LABELS } from 'store/file-browser-toolbar-options';
import Icon from 'components/shared/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

class KebabMenu extends Component {
  static propTypes = {
    addOptionToToolbar: PropTypes.func.isRequired,
    fileBrowserToolbarOptions: PropTypes.object.isRequired,
    onItemSelected: PropTypes.func.isRequired,
    isReprocessDisabled: PropTypes.func.isRequired
  };

  onItemSelected = option => {
    const { addOptionToToolbar, onItemSelected } = this.props;
    addOptionToToolbar(option);
    onItemSelected(option);
  };

  render() {
    const { fileBrowserToolbarOptions,isReprocessDisabled } = this.props; 
    return (
      <MenuWrapper className="kebab-menu" onSelection={this.onItemSelected}>
        <MenuButton
          className="form-dropdown btn file-browser-toolbar__control file-browser-toolbar__menu-button"
          tag="button"
          data-tip
          data-for="tooltip-more-options"
          data-place="bottom"
        >
          <Icon className="file-browser-toolbar__inactive-icon" name="menu" width={20} />
          <Icon className="file-browser-toolbar__active-icon" name="menu" width={20} />
          <ReactTooltip id="tooltip-more-options" effect="solid" place="bottom">
            <FormattedMessage id="file-browser.toolbar.more-options" />
          </ReactTooltip>
        </MenuButton>
        <Menu className="kebab-menu__content">
          <ul>
            {fileBrowserToolbarOptions.kebabOptions.map(option => {
             
              if(option==="REPROCESS" && isReprocessDisabled) {
                return null;
                }
                else {
                  return (
                    <li key={option}>                
                      <MenuItem value={option} className="form-dropdown__item kebab-menu__item">
                        <FormattedMessage id={TOOLBAR_LABELS[option]} />
                      </MenuItem>
                    </li>
                  );
                }
            })}
          </ul>
        </Menu>
      </MenuWrapper>
    );
  }
}

export default KebabMenu;
