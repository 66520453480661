import PropTypes from 'prop-types';
import React, { Component } from 'react';

import classNames from 'classnames';
import Icon from 'components/shared/icon';

/*
* Numeric input
* Use this when you need a numeric input field with up and down arrow keys
*/
export default class NumericInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    step: PropTypes.number,
    value: PropTypes.any
  };

  numDigits = x => {
    return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
  };

  getPattern = () => {
    const { max, min } = this.props;
    // Get the regex pattern to be used in validator
    return max && min ? `[0-9]{${this.numDigits(min)},${this.numDigits(max)}}` : `[0-9]*`;
  };

  onChange = e => {
    if (e.target.validity.valid) {
      this.props.onChange(e.target.value);
    }
  };

  onUp = () => {
    this.props.onChange(Math.min(parseInt(this.props.value, 10) + 1, this.props.max));
  };

  onDown = () => {
    this.props.onChange(Math.max(parseInt(this.props.value, 10) - 1, this.props.min));
  };

  onBlur = () => {
    if (this.props.value < this.props.min) {
      this.props.onChange(Math.max(this.props.value, this.props.min));
    }
  };

  render() {
    const { placeholder, value, className } = this.props;

    return (
      <div className={classNames(className, 'numeric-input')}>
        <input
          className="numeric-input__numberfield"
          type="text"
          placeholder={placeholder}
          onChange={this.onChange}
          value={value}
          pattern={this.getPattern()}
          onBlur={this.onBlur}
        />
        <div className="numeric-input__block">
          <button onClick={this.onUp}>
            <Icon name="special-arrow-up-blue" width={10} />
          </button>
          <button onClick={this.onDown}>
            <Icon name="special-arrow-down-blue" width={10} />
          </button>
        </div>
      </div>
    );
  }
}
