import { createAction, createReducer } from 'utils/redux-utils';

import { ProjectMembers } from 'models/project-members';
import * as api from 'store/api';
import socket, { rooms } from 'utils/socket';

export const ACTIONS = {
  // Data fetching
  PROJECT_MEMBERS_LOADING: 'argus/ui/PROJECT_MEMBERS_LOADING',
  PROJECT_MEMBERS_LOADED: 'argus/ui/PROJECT_MEMBERS_LOADED',
  PROJECT_MEMBERS_ERROR: 'argus/ui/PROJECT_MEMBERS_ERROR',
  CLEAR_PROJECT_MEMBER: 'argus/ui/CLEAR_PROJECT_MEMBER',
  // Data posting
  ADD_PROJECT_MEMBER: 'argus/ui/ADD_PROJECT_MEMBER',
  // Data update
  UPDATE_PROJECT_MEMBER: 'argus/ui/UPDATE_PROJECT_MEMBER',
  // Data delete
  DELETE_PROJECT_MEMBER: 'argus/ui/DELETE_PROJECT_MEMBER'
};

export const projectMembersLoading = createAction(ACTIONS.PROJECT_MEMBERS_LOADING);
export const projectMembersLoaded = createAction(ACTIONS.PROJECT_MEMBERS_LOADED);
export const projectMembersError = createAction(ACTIONS.PROJECT_MEMBERS_ERROR);
export const projectMembersClear = createAction(ACTIONS.CLEAR_PROJECT_MEMBER);

export const postProjectMember = createAction(ACTIONS.ADD_PROJECT_MEMBER);

export const modifyProjectMember = createAction(ACTIONS.UPDATE_PROJECT_MEMBER);

export const delProjectMember = createAction(ACTIONS.DELETE_PROJECT_MEMBER);

// Contains the logic for subscribing/unsubscribing to updates from the socket
export const { subscribe, unsubscribe } = (socket => {
  let room = null;
  let onProjectUserRoleChanged = null;

  const subscribe = projectId => dispatch => {
    if (!projectId) {
      return;
    }

    const nextRoom = rooms.project(projectId);

    if (room !== nextRoom) {
      socket.leave(room);
      room = nextRoom;
      socket.join(nextRoom);
    }

    // Handle project user role changed
    if (!onProjectUserRoleChanged) {
      onProjectUserRoleChanged = event => {
        dispatch(modifyProjectMember(event.data));
      };
      socket.on('project.user.role.updated', onProjectUserRoleChanged);
    }
  };

  const unsubscribe = () => {
    socket.leave(room);
    room = null;

    if (onProjectUserRoleChanged) {
      socket.off('project.user.role.updated', onProjectUserRoleChanged);
      onProjectUserRoleChanged = null;
    }
  };

  return {
    subscribe,
    unsubscribe
  };
})(socket);

// Data fetching
export const loadProjectMembers = ({ projectId }) => dispatch => {
  dispatch(projectMembersLoading());
  return api
    .getProjectUsers({ projectId })
    .then(response => {
      dispatch(projectMembersLoaded(response.data));
      dispatch(subscribe(projectId));
      return response;
    })
    .catch(err => dispatch(projectMembersError({ err })));
};

// Data posting
export const addProjectMember = data => dispatch => {
  dispatch(projectMembersLoading());
  api.addProjectUser(data).then(response => dispatch(postProjectMember(response.data)));
};

// Data updating
export const updateProjectMember = data => dispatch => {
  api.updateProjectUser(data);
  dispatch(modifyProjectMember(data));
};

// Data deleting
export const deleteProjectMember = data => dispatch => {
  dispatch(projectMembersLoading());
  api.deleteProjectUser(data).then(() => dispatch(delProjectMember(data)));
};

// Data clearing
export const clearProjectMember = data => dispatch => {
  unsubscribe();
  return dispatch(projectMembersClear());
};

/*
 * Reducer
 */
export const INITIAL_STATE = new ProjectMembers();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.PROJECT_MEMBERS_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.PROJECT_MEMBERS_LOADED]: (state, action) => {
    return state.setLoaded(action.payload);
  },

  [ACTIONS.PROJECT_MEMBERS_ERROR]: (state, action) => {
    return state.setError(action.payload.err);
  },

  [ACTIONS.ADD_PROJECT_MEMBER]: (state, action) => {
    return state.setAdded(state, action);
  },

  [ACTIONS.UPDATE_PROJECT_MEMBER]: (state, action) => {
    return state.setUpdated(state, action);
  },

  [ACTIONS.DELETE_PROJECT_MEMBER]: (state, action) => {
    return state.setDeleted(state, action);
  },

  [ACTIONS.CLEAR_PROJECT_MEMBER]: (state, action) => {
    return INITIAL_STATE;
  }
});
