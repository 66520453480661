import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { translateTextMaxCharacters } from '../constants';
import Icon from 'components/shared/icon';

class InputText extends Component {
  static propTypes = {
    block: PropTypes.string.isRequired,
    setText: PropTypes.func.isRequired
  };

  state = {
    textCounter: 0
  };

  textareaRef = React.createRef();

  handleChange = ({ target }) => {
    const { setText } = this.props;
    setText(target.value);
    this.setState({ textCounter: target.value.length });
  };

  handlePlaceholderClick = () => {
    this.textareaRef.focus();
  };

  render() {
    const { block } = this.props;
    const { textCounter } = this.state;

    return (
      <div className={`${block}__input-text`}>
        {textCounter === 0 && (
          <Fragment>
          <div className={`${block}__placeholder`} onClick={this.handlePlaceholderClick}>
            <FormattedMessage id={`translate-text.input-text.placeholder`} />            
          </div>
          <div className={`${block}__placeholder-1`} onClick={this.handlePlaceholderClick}>
            <FormattedMessage id={`translate-text.input-text.placeholder-1`} />            
          </div>
          </Fragment>
        )}
        <textarea
          ref={ref => (this.textareaRef = ref)}
          maxLength={translateTextMaxCharacters}
          className={`${block}__textarea`}
          onChange={this.handleChange}
        />
        <div className={`${block}__text-counter`}>
          <Icon className={`${block}__text-counter__icon`} name="file-paste" width={28} />
          {textCounter}/{translateTextMaxCharacters}
        </div>
      </div>
    );
  }
}

export default InputText;
