import model from 'models/base-model';

export class CurrentLanguage extends model({
  //Default in case of fallback scenario
  englishCountryName: 'United States',
  englishName: 'English (United State)',
  language: 'English (United States)',
  localeCode: 'en-US',
  nativeCountryName: 'United States',
  nativeName: 'English (United States)'
}) {
  setLanguage(state, action) {   
    var fields = Object.assign({}, state, action.payload);

    return this.merge({
      ...fields
    });
  }
}
