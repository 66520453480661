import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'components/shared/single-click-button';
import Checkbox from 'components/shared/form/checkbox';
import { getCurrentRegion } from 'store/api';
import {
  RESULT_CATEGORY_ID_KEYS,
  RESULT_CATEGORY_KEYS,
  SEARCH_CATEGORIES,
  SEARCH_CATEGORY_DISPLAY_NAMES
} from 'components/search/constants';

// Displays selectable result options
export default class ResultsContainer extends Component {
  static propTypes = {
    categoryId: PropTypes.number,
    checked: PropTypes.any,
    getFoldersSelected: PropTypes.func,
    getSearchDocument: PropTypes.func,
    getSearchFolder: PropTypes.func,
    history: PropTypes.object,
    onBlur: PropTypes.func,
    onCheckAllChange: PropTypes.func,
    onCheckChange: PropTypes.func,
    onSearchAll: PropTypes.func,
    projectId: PropTypes.string,
    results: PropTypes.object
  };

  // When a user selects multiple results or a single result
  onSearchSelected = () => {
    const {
      categoryId,
      checked,
      getFoldersSelected,
      getSearchDocument,
      getSearchFolder,
      onBlur,
      onSearchAll,
      projectId
    } = this.props;

    const selected = [...checked];
    const categoryKey = RESULT_CATEGORY_ID_KEYS[categoryId];

    if (categoryId === SEARCH_CATEGORIES.DOCUMENT_NAME) {
      // Documents selected
      if (selected.length === 1) {
        // If one document is selected, go to the document viewer
        onBlur();
        return this.props.history.push({
          pathname: `/region/${getCurrentRegion()}/project/${projectId}/document/${selected[0]}`
        });
      } else {
        onSearchAll(categoryKey, selected);
        return getSearchDocument({ documentId: selected });
      }
    } else if (categoryId === SEARCH_CATEGORIES.FOLDER_NAME) {
      if (selected.length === 1) {
        // Handle 1 folder selcted
        onSearchAll(categoryKey, selected);
        return getSearchFolder({ folderId: selected[0] });
      } else {
        // Handle multiple folders selected
        onSearchAll(categoryKey, selected);
        // Use the api that returns all the folders in a list and no docs
        return getFoldersSelected();
      }
    } else {
      onSearchAll(categoryKey, selected);
      return getSearchDocument({ [categoryKey]: selected });
    }
  };

  render() {
    const { categoryId, results, checked, onCheckChange, onCheckAllChange } = this.props;
    // Find where user selected view more option from category container
    const resultList = results && results[RESULT_CATEGORY_KEYS[categoryId]].data;

    const ids = resultList.map(v => v[RESULT_CATEGORY_ID_KEYS[categoryId]]);
    const checkAllState = ids.every(e => checked.has(e)) ? 'checked' : checked.size ? 'indeterminate' : 'unchecked';

    return (
      <div className="search-category-results">
        <div className="search-category-results__content">
          <div className="search-category-results__checkbox">
            <Checkbox checked={checkAllState} id="search-check-all" onChange={() => onCheckAllChange(ids)} />
            <span className="search-category-results__name-bold">
              <FormattedMessage id={SEARCH_CATEGORY_DISPLAY_NAMES[categoryId]} />
            </span>
          </div>
          <div className="search-category-results__checkbox-other">
            {resultList &&
              resultList.map((result, idx) => {
                return (
                  <div className="search-category-results__checkbox" key={idx}>
                    <Checkbox
                      checked={checked.has(result[RESULT_CATEGORY_ID_KEYS[categoryId]]) ? 'checked' : 'unchecked'}
                      id={`check-search-${idx}`}
                      onChange={() => onCheckChange(result[RESULT_CATEGORY_ID_KEYS[categoryId]])}
                    />
                    <span className="search-category-results__name">{result.name}</span>
                  </div>
                );
              })}
            {resultList.length === 100 && (
              <div className="search-category-results__name-friendly">
                <FormattedMessage id="search-scroll.friendly-message" />
              </div>
            )}
          </div>
        </div>
        <div className="search-category-results__footer">
          <Button className="btn btn-primary" onClick={this.onSearchSelected} disabled={checked.size <= 0}>
            <FormattedMessage id="search.dropdown.shallow-search" />
          </Button>
        </div>
      </div>
    );
  }
}
