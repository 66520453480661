import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';

import Header from 'containers/header/header';
import EmptyState from 'components/file-browser/file-list/empty-state';

import emptyAccessDeniedImage from 'images/empty_state_access_denied.svg';

class NotFoundErrorPage extends Component {
  link = <a href="mailto:argusauditssupport@deloitte.com">argusauditssupport@deloitte.com</a>;

  render() {
    return (
      <div className="page">
        <Header {...this.props} currentPage="global.subheader.landing-page"/>
        <EmptyState
          title={<FormattedMessage id="error-page.404.title" />}
          description={
            <FormattedMessage
              id="error-page.404.description"
              values={{
                link: this.link
              }}
            />
          }
          img={emptyAccessDeniedImage}
        />
      </div>
    );
  }
}

export default NotFoundErrorPage;
