import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import { FormattedMessage } from 'react-intl';
import { getCurrentRegion } from 'store/api';
import Button from 'components/shared/single-click-button';

import Permissions from 'permissions/permissions';

class PPMDBanner extends PureComponent {
  static propTypes = {
    currentProject: PropTypes.object,
    history: PropTypes.object,
    projectId: PropTypes.any,
    showButton: PropTypes.bool
  };

  onApproveClick = () => {
    this.props.history.push(`/region/${getCurrentRegion()}/project/${this.props.projectId}/settings`);
  };

  render() {
    const { currentProject } = this.props;

    const isArchive = currentProject.STATES.ReadOnly() && currentProject.STATES.Archived();
    const isPending = currentProject.STATES.ReadOnly() && !currentProject.STATES.Archived();

    // When a user is a read only member, don't display the PPMD banner,
    // but user is stil limited to do actions just as if the project is pending archive or archived
    if (!Permissions.Project._States.isReadOnly(currentProject) && !Permissions.Project.canViewApprovalBanner()) {
      return <span />;
    }

    return (
      <div className="ppmd-banner">
        <Icon className="templates-step__guided-icon" name="special-info-outline" width={16} />
        <span className="ppmd-banner__title">
          <FormattedMessage
            id={`${
              isArchive && !isPending ? 'global.banner.read-only.archived.title' : 'global.banner.read-only.title'
            }`}
          />
        </span>
        <span className="ppmd-banner__msg">
          <FormattedMessage id="global.banner.read-only.message" values={{ projectName: currentProject.projectName }} />
        </span>
        {Permissions.Project.canApproveArchive(currentProject) ? (
          <Button className="btn btn-no-margin ppmd-banner__button" onClick={this.onApproveClick}>
            <FormattedMessage id="global.banner.read-only.button" />
          </Button>
        ) : null}
      </div>
    );
  }
}

export default PPMDBanner;
