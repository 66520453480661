import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Confirm from 'components/shared/confirm';

const fileExtensionValidationRegex = /\.zip$/i;
const checkFileExtension = file => fileExtensionValidationRegex.test(file.name);

/**
 * Import modal
 */
class ImportModal extends Component {
  static propTypes = {
    disableSubmit: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    hasFileError: false,
    isImportInProgress: false,
    selectedFile: null
  };

  /**
   * Select a file and check if it has a valid extension
   */
  onFilesSelected = event => {
    const files = Array.from(event.target.files);

    if (files.length) {
      const file = files[0];

      this.setState({
        selectedFile: file,
        hasFileError: !checkFileExtension(file)
      });
    }
  };

  /**
   * Submit the modal
   */
  onSubmit = () => {
    this.setState({ isImportInProgress: true });
    return this.props.onSubmit(this.state.selectedFile);
  };

  render() {
    const { disableSubmit, onCancel } = this.props;
    const { selectedFile, isImportInProgress, hasFileError } = this.state;

    const body = (
      <Fragment>
        <div className="quick-study-import-modal__message">
          <FormattedMessage id="quick-study.toolbar.import.message" />
        </div>
        <div className="quick-study-import-modal__input">
          <label className="btn btn-link" htmlFor="quick-study-import-file" tabIndex="0">
            <FormattedMessage id="quick-study.toolbar.import.file-select" />
          </label>
          <input
            hidden
            type="file"
            onChange={this.onFilesSelected}
            id="quick-study-import-file"
            accept=".zip, application/zip"
          />
          <span className="quick-study-import-modal__file-name">
            {selectedFile ? selectedFile.name : <FormattedMessage id="quick-study.toolbar.import.no-file" />}
          </span>
        </div>
        {hasFileError && (
          <div className="quick-study-import-modal--error">
            <FormattedMessage id="quick-study.toolbar.import.file-format-error" />
          </div>
        )}
      </Fragment>
    );

    return (
      <Confirm
        className="quick-study-import-modal"
        disableSubmit={disableSubmit || isImportInProgress || !selectedFile || hasFileError}
        onCancel={onCancel}
        onSubmit={this.onSubmit}
        title={<FormattedMessage id="common.import" />}
        body={body}
        cancelText={<FormattedMessage id="common.cancel" />}
        confirmText={<FormattedMessage id="common.import" />}
      />
    );
  }
}

export default ImportModal;
