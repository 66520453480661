import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';

class Dateselect extends Component {
  state = {
    userChangedDateFormat: ''
  };

  componentDidMount() {
    this.props.setDateFormat();
    this.props.loadDateFormat();
    this.props.Dateformats.map(e => {
      if (e.IsDefault) {
        localStorage.setItem('defaultdateFormat', e.DateCode);
      }
    });
  }

  static propTypes = {
    currentDateFormat: PropTypes.string,
    Dateformats:PropTypes.object
  };

  DateformatSelected = e => {
    this.setState({
      userChangedDateFormat: e.currentTarget.value
    });
    this.props.handleSuccessSubmit(e.currentTarget.value);
    localStorage.setItem('userSelectedDateFormat', e.currentTarget.value);
  };

  render() {
    const { Dateformats, isLoading, currentDateFormat } = this.props;

    
    const items = Dateformats.map(e => {
      if (currentDateFormat !== null && currentDateFormat !== "null") {
        return (
          <div className="Date-select__DisplayDate" key={e.DateCode}>
            {e.DateCode.toUpperCase() === currentDateFormat.toUpperCase() ? (
              <input type="radio" name="radio1" value={e.DateCode} onChange={this.DateformatSelected} defaultChecked />
            ) : (
              <input type="radio" name="radio1" value={e.DateCode} onChange={this.DateformatSelected} />
            )}
             <span className="Date-select__Textdisplay">
              {e.DisplayText}
              {e.IsDefault && ' ('}
              {e.IsDefault && <FormattedMessage id="global.header.help-default" />}
              {e.IsDefault && ')'}
            </span>
            <br />
          </div>
        );
      } else {
        return (
          <div className="Date-select__DisplayDate"  key={e.DateCode}>
            {e.IsDefault ? (
              <input type="radio" name="radio1" value={e.DateCode} onChange={this.DateformatSelected} defaultChecked />
            ) : (
              <input type="radio" name="radio1" value={e.DateCode} onChange={this.DateformatSelected} />
            )}
             <span className="Date-select__Textdisplay">
              {e.DisplayText}
              {e.IsDefault && ' ('}
              {e.IsDefault && <FormattedMessage id="global.header.help-default" />}
              {e.IsDefault && ')'}
            </span>
            <br />
          </div>
        );
      }
    });

    return (
      <div className="Date-select">
        <div className="label">
          <FormattedMessage id="global.header.settings.preferred-date-formats" />
        </div>
        {isLoading ? (
          <Icon className="spinner spinner--centered" name="loader" width={50} />
        ) : (
        <div className="Date-select__dateselectors">{items}</div>
        )}
      </div>
    );
  }
}
export default Dateselect;
