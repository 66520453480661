import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import Header from 'containers/header/header';
import NotificationsContainer from 'containers/shared/notifications-container';
import ReviewStatusContainer from 'containers/review-status/review-status';
import Permissions from 'permissions/permissions';
import history from 'utils/history';

class ReviewStatusPage extends Component {
  UNSAFE_componentWillMount() {
    if (!Permissions.Project.ReviewStatus.canView()) {
      history.push('/unauthorized');
    }
  }

  render() {
    return (
      <div className="review-status-page page">
        <Header {...this.props} currentPage="global.subheader.extraction"/>
        <ReviewStatusContainer {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default ReviewStatusPage;
