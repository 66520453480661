import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Header from 'containers/header/header';
import TranslationContainer from 'containers/translation/translation-container';
import NotificationsContainer from 'containers/shared/notifications-container';
import EmptyState from 'components/file-browser/file-list/empty-state';
import { getHomeRegion, setCurrentRegion } from 'store/api';
import Permissions from 'permissions/permissions';
import history from 'utils/history';
import { FormattedMessage } from 'react-intl';
import emptyAccessDeniedImage from 'images/empty_state_access_denied.svg';

class Translation extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    currentUser: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    setCurrentRegion(getHomeRegion());

    if (!Permissions.Global.canViewProjects()) {
      history.push('/unauthorized');
    }
   
  }

  render() {
    return (
      <div className="page translation-page">
        <Header {...this.props} fromTranslationPage currentPage="global.subheader.translation" />
        {this.props.currentUser && this.props.currentUser.accessibleFeatures.filter(feature => feature.featureId === 1).length > 0 ? 
          <TranslationContainer {...this.props} /> :
          <EmptyState
          title={<FormattedMessage id="error-page.unauthorized.title" />}
          description={
            <FormattedMessage
              id="error-page.unauthorized.description"
              values={{
                link: this.link
              }}
            />
          }
          img={emptyAccessDeniedImage}
        /> }          
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default Translation;
