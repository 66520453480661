import { connect } from 'react-redux';
import {
  loadCurrentFolder,
  createFolder,
  currentFolderInvalidate,
  unsubscribeCurrentFolder
} from 'store/current-folder';
import ExtractionOverview from 'components/extraction-overview/extraction-overview';
import { loadProjectTemplates, setTemplate, updateExtractionOverviewColumnOptions } from 'store/extraction-overview';

const mapStateToProps = ({ data: { currentProject, extractionOverview, currentFolder } }) => ({
  currentProject,
  extractionOverview,
  currentFolder,
  selectedItems: extractionOverview.templateExtractions?.map(i => i.projectItemId)
});

const mapDispatchToProps = {
  loadProjectTemplates,
  setTemplate,
  createFolder,
  loadCurrentFolder,
  currentFolderInvalidate,
  unsubscribeCurrentFolder,
  updateExtractionOverviewColumnOptions
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtractionOverview);
