import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import ProjectDateFormatSelection from 'components/project-creation/shared/project-date-fromat-selection';
import CONSTANTS from 'utils/constants';

class UploadButton extends Component {
  static propTypes = {
    onFilesSelected: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    projectId: PropTypes.number,
    currentFolder: PropTypes.object
  };
  state = {
    showDateSelectionPopup: false
  };

  validateDateFormatSelection = e => {
    const {currentProject} = this.props
    let dateFormatPreference = this.props.currentFolder?.dateFormatPreference;
    const isDateFormatSaved = dateFormatPreference  != null ? true : sessionStorage.getItem('isDateFormatSaved-'+this.props.currentFolder.projectItemId);
    let templateCategoryID = this.props.currentFolder?.templateCategoryID;
    if ((templateCategoryID == CONSTANTS.TemplateCategories.DETAIL_TESTING || templateCategoryID == CONSTANTS.TemplateCategories.NON_EDITABLE_DETAIL_TESTING) && (!isDateFormatSaved && (dateFormatPreference == null || dateFormatPreference == '')) && ((CONSTANTS.RestrictDateFormatForSpecificContainer.indexOf(currentProject.containerCode) == -1 ))) {
      this.setState({ showDateSelectionPopup: true });
      e.preventDefault();
    } else {
      this.props.onClick();
    }
  };
  handleSuccessCallback = status => {
    if (status === 'progress') {
      this.setState({ showDateSelectionPopup: false });
      sessionStorage.setItem('isDateFormatSaved-'+this.props.currentFolder.projectItemId, true);
      this.inputElement.click();
      this.props.onClick();
    }
  };
  closeDateFormatPopUp = () => {
    this.setState({ showDateSelectionPopup: false });
  };

  render() {
    const { onFilesSelected, disabled, onClick, projectId, currentFolder } = this.props;
    const { showDateSelectionPopup } = this.state;

    return (
      <div>
        <label
          className="btn btn-primary btn-no-margin file-browser__upload-button"
          htmlFor="file-browser-toolbar-file-input"
          style={{ cursor: 'pointer', pointerEvents: disabled ? 'none' : 'all' }}
          tabIndex="0"
          disabled={disabled}
          onClick={e => this.validateDateFormatSelection(e)}
          data-tip
          data-for="tooltip-upload-button"
        >
          <FormattedMessage id="file-browser.toolbar.upload-button" />
        </label>
        <input
          hidden
          multiple
          type="file"
          onChange={onFilesSelected}
          ref={input => this.inputElement = input}
          id="file-browser-toolbar-file-input"
          disabled={disabled}
        />
        <ReactTooltip id="tooltip-upload-button" effect="solid" place="left">
          <FormattedMessage id="extraction-translation.DCRM-warning" />
        </ReactTooltip>

        <ProjectDateFormatSelection
          showProjectDateFormatSelection={showDateSelectionPopup}
          projectId={projectId}
          closeDateFormatPopUp={this.closeDateFormatPopUp}
          postSuccessCallback={status => this.handleSuccessCallback(status)}
          currentFolder={currentFolder}
        />
      </div>
    );
  }
}

export default UploadButton;
