import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import enhanceWithClickOutside from 'react-click-outside';
import { getMessages } from 'utils/errors';

import Button from 'components/shared/single-click-button';
import Dropdown from 'components/shared/form/dropdown';
import Modal from 'components/shared/modal';
import Icon from 'components/shared/icon';
import TemplateDropdown from './template-dropdown/template-dropdown';
import TemplateExtractions from './template-extractions/template-extractions';
import constants from '../../../utils/constants';

// Ability to delete or duplicate template
class Options extends Component {
  static propTypes = {
    currentProject: PropTypes.object,
    isLastTemplate: PropTypes.bool, // If it's last template, disable delete functionality
    onDelete: PropTypes.func,
    onDuplicate: PropTypes.func,
    onEdit: PropTypes.func
  };
  state = {
    isOpen: false
  };
  handleClickOutside = () => {
    this.setState({ isOpen: false });
  };
  render() {
    return (
      <div>
        <Button
          size="icon"
          className="template-viewer__template-selector-menu icon-button"
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <Icon name="special-menu" width={18} />
        </Button>
        {this.state.isOpen  ? (
          <div className="template-options__wrapper" onClick={this.handleClickOutside}>
            <Button className="template-options__edit" onClick={this.props.onEdit}>
              <FormattedMessage id="template-options.edit" />
            </Button>
            <Button className="template-options__duplicate" onClick={this.props.onDuplicate}>
              <FormattedMessage id="template-options.duplicate" />
            </Button>
            { this.props.isLastTemplate && this.props.currentProject.projectTypeId != constants.ProjectTypes.WORKSPACE_PROJECT ? null : (
              <Button className="template-options__delete" onClick={this.props.onDelete}>
                <FormattedMessage id="template-options.delete" />
              </Button>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

const TemplateOptions = enhanceWithClickOutside(Options);

// Ability to create a new template
class CreateTemplate extends Component {
  static propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClickOutside: PropTypes.func,
    onSave: PropTypes.func,
    templateName: PropTypes.string
  };

  handleClickOutside = () => {
    if (this.props.onClickOutside) {
      this.props.onClickOutside();
    }
  };

  render() {
    const { onChange, onSave, templateName, onBlur } = this.props;
    return (
      <div className="create-template">
        <div className="create-template__title">
          <FormattedMessage id="create-template.title" />
        </div>
        <div className="create-template__wrapper">
          <FormattedMessage id="create-template.placeholder">
            {placeholder => (
              <input
                autoFocus
                className="create-template__name-input"
                onChange={onChange}
                value={templateName}
                placeholder={placeholder}
                maxLength="140"
                onKeyUp={onSave}
                onBlur={onBlur}
                onFocus={e => e.target.select()}
              />
            )}
          </FormattedMessage>
        </div>
      </div>
    );
  }
}

class DeleteTemplateModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  render() {
    const { onCancel, onSubmit } = this.props;

    return (
      <Modal>
        <div className="modal__content modal__content--small">
          <h3>
            <FormattedMessage id="delete-file.modal.title" />
          </h3>
          <Button className="modal__close_x icon-button" onClick={onCancel}>
            <Icon name="special-cross-black" width={16} />
          </Button>
          <div className="modal__body">
            <p className="modal__text">
              <FormattedMessage id="template-viewer.delete-modal.message" />
            </p>
          </div>
          <div className="modal__footer">
            <Button type="button" className="btn btn-secondary" onClick={onCancel}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => onSubmit() }
            >
              <FormattedMessage id="template-viewer.delete-modal.button" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default class TemplateViewer extends Component {
  static propTypes = {
    addedExtractions: PropTypes.array,
    api: PropTypes.object,
    createNew: PropTypes.bool,
    createNewTemplate: PropTypes.func,
    currentProject: PropTypes.object,
    editExtractions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    groupByTemplateType: PropTypes.bool,
    isResolved: PropTypes.bool,
    newTemplateMoveExtraction: PropTypes.func,
    newTemplateName: PropTypes.string,
    newTemplateRemoveExtraction: PropTypes.func,
    onCreateNameChange: PropTypes.func,
    onEditTemplate: PropTypes.func,
    pageViewer: PropTypes.string,
    readOnly: PropTypes.bool,
    selectedTemplateType: PropTypes.object,
    templateNameUpdateErr: PropTypes.bool,
    currentFolder: PropTypes.object,
  };

  state = {
    showDeleteModal: false
  };

  // Delete template function
  // Takes a replacement id if there is one.
  // Checks whether we need to open a modal based on server error response code
  deleteTemplate = () => {
    const { onTemplateDelete } = this.props.api;
    const { currentFolder } = this.props;
    this.setState({ showDeleteModal: false });
    return onTemplateDelete(currentFolder.projectItemId)
      .then()
      .catch(err => {
          getMessages(err).map(message => {
          if (message.code === 'Error.ReprocessingDisabled') {
            window.location.reload();
          }
        });
      });
  };

  showDeleteModal = () => {
    this.setState({ showDeleteModal: true })
  } 

  render() {
    const {
      currentProject,
      editExtractions,
      readOnly,
      onEditTemplate,
      createNew,
      groupByTemplateType,
      onCreateNameChange,
      newTemplateName,
      addedExtractions,
      newTemplateMoveExtraction,
      newTemplateRemoveExtraction,
      selectedTemplateType,
      pageViewer,
      createNewTemplate,
      templateNameUpdateErr
    } = this.props;
    const {
      onExtractionDelete,
      onNameChange,
      onTemplateDuplicate,
      onExtractionChange,
      onUpdate,
      onTemplateClick
    } = this.props.api;

    return (
      <div className="template-viewer">
        {!groupByTemplateType && (
          <div className="template-viewer__template-top">
            <span className="template-viewer__select-projects">
              <FormattedMessage id="template-viewer.select-projects" />
            </span>
            <div className="template-viewer__template-selector">
              <div className="template-viewer__template-selector-wrapper">
                <TemplateDropdown
                  readOnly={readOnly}
                  templateList={editExtractions.templates}
                  selectedId={editExtractions.selectedId}
                  selectedName={editExtractions.selectedName}
                  onTemplateClick={value => {
                    onTemplateClick(value);
                    onEditTemplate(false);
                  }}
                  errClassName={templateNameUpdateErr ? "template-dropdown__name-input--error" : ''}
                />
              </div>

              {createNew || readOnly || editExtractions.templates.length == 0 ? null : (
                <TemplateOptions
                  currentProject={currentProject}
                  isLastTemplate={editExtractions.templates && editExtractions.templates.length <= 1 ? true : false}
                  onEdit={() => onEditTemplate(true)}
                  onDelete={() => this.showDeleteModal()}
                  onDuplicate={() => onTemplateDuplicate()}
                />
              )}
            </div>
          </div>
        )}
        {createNew &&
          (groupByTemplateType ? (
            <CreateTemplate onChange={e => onCreateNameChange(e)} templateName={newTemplateName} />
          ) : (
            <CreateTemplate
              onChange={e => onNameChange(e)}
              templateName={editExtractions.selectedName}
              onBlur={() => onUpdate(true).then(() => onEditTemplate(false))}
            />
          ))}
          {templateNameUpdateErr && (
              <div className="create-template__name-input-error">
                <FormattedMessage id="file-browser.create-folder-modal.duplicate-error" />
            </div>
          )}
        <div className="template-viewer__template-bottom">
          {editExtractions && this.props.isResolved ? (
            <TemplateExtractions
              readOnly={readOnly}
              extractions={groupByTemplateType ? { templateExtractions: addedExtractions } : editExtractions}
              groupKey={'groupId'}
              onDelete={value => onExtractionDelete(value)}
              onExtractionChange={updatedExtractions => onExtractionChange(updatedExtractions)}
              onMouseUp={() => (readOnly ? {} : onUpdate())}
              openByDefault={true}
              template={editExtractions.selectedName}
              groupByTemplateType={groupByTemplateType}
              newTemplateMoveExtraction={newTemplateMoveExtraction}
              newTemplateRemoveExtraction={newTemplateRemoveExtraction}
              selectedTemplateType={selectedTemplateType}
            />
          ) : (
            <div className="template-viewer__template-bottom-spinner">
              <Icon className="spinner spinner--centered" name="loader" width={80} />
            </div>
          )}
        </div>
        <div className={`add-extraction ${pageViewer}__footer upload-files`}>
          {groupByTemplateType && (
            <Button
              id="add-extraction-create-button"
              disabled={addedExtractions.length === 0 || newTemplateName === ''}
              className={`add-extraction ${pageViewer}__add-button btn btn-primary`}
              onClick={createNewTemplate}
            >
              <FormattedMessage id="edit-extractions.options.finish-creation" />
            </Button>
          )}
        </div>
        {this.state.showDeleteModal ? (
          <DeleteTemplateModal
            onCancel={() => this.setState({ showDeleteModal: false })}
            onSubmit={()=> this.deleteTemplate()}
          />
        ) : null}
      </div>
    );
  }
}
