import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

const ProjectSettingsPermissions = {
  canCreate: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canRemove: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canEdit: project => {
    return (
      (RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)) &&
      project.projectTypeId != constants.ProjectTypes.WORKSPACE_PROJECT
    );
  },

  canEditCreationState: project => {
    return RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR);
  },

  canEditAsAdmin: project => {
    // User is admin for the project container, but is not a user on the project
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) &&
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canView: (project, region) => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  }
};

export default ProjectSettingsPermissions;
