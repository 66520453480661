export function flattenAddExtractionData(extractionFields, currentExtractionIds) {
  var flatArray = [];

  extractionFields.forEach(extractionField => {
    if (extractionField.groups && extractionField.groups.length > 0) {
      //Grouped extraction field
      extractionField.groups.forEach(group => {
        flatArray.push({
          id: extractionField.extractionFieldId,
          disabled: currentExtractionIds.indexOf(extractionField.extractionFieldId) !== -1,
          externalSystemId: extractionField.externalSystemId,
          group: group.extractionFieldGroupName,
          groupId: group.extractionFieldGroupId,
          groupMapping: extractionField.extractionFieldDisplayOrderMappings,
          name: extractionField.extractionFieldName,
          description: extractionField.extractionFieldDescription,
          color: extractionField.colorIndex,
          trainingCount: extractionField.reviewDocumentCount,
          trainingTarget: extractionField.minDocumentsToBeConsideredTrained,
          isTrained: extractionField.isTrained,
          isAutomaticExtraction: extractionField.isAutomaticExtraction,
          AccessTypeId: extractionField.accessTypeId
        });
      });
    } else {
      //Ungrouped Extraction Field
      flatArray.push({
        id: extractionField.extractionFieldId,
        disabled: currentExtractionIds.indexOf(extractionField.extractionFieldId) !== -1,
        externalSystemId: extractionField.externalSystemId,
        group: null,
        groupId: null,
        groupMapping: [],
        name: extractionField.extractionFieldName,
        description: extractionField.extractionFieldDescription,
        color: extractionField.colorIndex,
        trainingCount: extractionField.reviewDocumentCount,
        trainingTarget: extractionField.minDocumentsToBeConsideredTrained,
        isTrained: extractionField.isTrained,
        isAutomaticExtraction: extractionField.isAutomaticExtraction,
        AccessTypeId: extractionField.accessTypeId
      });
    }
  });

  return flatArray;
}

export function processAddExtractionData(rawData, currentExtractionIds) {
  var flatArray = [];
  var ids = [];

  rawData.groups.forEach(group => {
    group.extractionFields.forEach(extractionField => {
      ids.push(extractionField.extractionFieldId);

      flatArray.push({
        id: extractionField.extractionFieldId,
        disabled: currentExtractionIds.indexOf(extractionField.extractionFieldId) !== -1,
        group: group.extractionFieldGroupName,
        name: extractionField.extractionFieldName,
        description: extractionField.extractionFieldDescription,
        color: extractionField.colorIndex,
        trainingCount: extractionField.reviewDocumentCount,
        trainingTarget: extractionField.minDocumentsToBeConsideredTrained,
        isTrained: extractionField.isTrained,
        isAutomaticExtraction: extractionField.isAutomaticExtraction,
        AccessTypeId: extractionField.accessTypeId
      });
    });
  });

  rawData.fields.forEach(field => {
    if (ids.indexOf(field.extractionFieldId) === -1) {
      ids.push(field.extractionFieldId);
      flatArray.push({
        id: field.extractionFieldId,
        disabled: currentExtractionIds.indexOf(field.extractionFieldId) !== -1,
        group: null,
        name: field.extractionFieldName,
        description: field.extractionFieldDescription,
        color: field.colorIndex,
        trainingCount: field.reviewDocumentCount,
        trainingTarget: field.minDocumentsToBeConsideredTrained,
        isTrained: field.isTrained,
        isAutomaticExtraction: field.isAutomaticExtraction,
        AccessTypeId: field.accessTypeId
      });
    }
  });

  return flatArray;
}

// Used for data clean up of group based add extraction component
export function processGroupAddExtraction(data, ids) {
  if (!data || data.length <= 0) return [];
  const modifiedData = data =>
    data.map(group => {
      return group.extractionFields.map(value => {
        return {
          id: value.extractionFieldId,
          disabled: ids.indexOf(value.extractionFieldId) !== -1,
          group: group.extractionFieldGroupName,
          name: value.extractionFieldName,
          description: value.extractionFieldDescription,
          color: value.colorIndex,
          trainingCount: value.reviewDocumentCount,
          trainingTarget: value.minDocumentsToBeConsideredTrained,
          isTrained: value.isTrained,
          isAutomaticExtraction: value.isAutomaticExtraction,
          AccessTypeId: value.accessTypeId
        };
      });
    });

  // Let's flatten the modfied arr
  return [].concat.apply([], modifiedData(data));
}
