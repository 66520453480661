import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { PropTypes } from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component } from 'react';
import store from 'store/store';
import { addNotification } from 'store/notifications.js';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import { getExtractionFieldGroups, createExtractionFieldGroup } from 'store/api';
import GroupsAutocomplete from 'components/quick-study/shared/groups-autocomplete';
import constants from 'utils/constants';

class AddGroupPopover extends Component {
  static propTypes = {
    expectedExternalSystemId: PropTypes.number,
    intl: intlShape,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    isLoading: false,
    groupName: '',
    allGroups: []
  };

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      getExtractionFieldGroups({ pageSize: 10000 }).then(response => {
        this.setState({ allGroups: response.data, isLoading: false });
      });
    });
  }

  handleClickOutside() {
    const { onClose } = this.props;
    onClose();
  }

  onSubmit = event => {
    const { onSubmit } = this.props;
    const { groupName } = this.state;
    const selectedGroup = this.selectedGroup;
    event.preventDefault();

    if (selectedGroup && !this.isExpectedGroup(selectedGroup)) {
      return store.dispatch(
        addNotification({
          message: <FormattedMessage id="quick-study.add-group.abby-error" />,
          clearOnPageChange: true,
          autohide: 10
        })
      );
    }

    if (selectedGroup) {
      onSubmit(selectedGroup);
    } else {
      createExtractionFieldGroup({ name: groupName }).then(response => {
        onSubmit(response.data);
      });
    }
  };

  isExpectedGroup = group => {
    const { expectedExternalSystemId } = this.props;

    return group.externalSystemId === expectedExternalSystemId;
  };

  onGroupChange = groupName => {
    this.setState({
      groupName
    });
  };

  get selectedGroup() {
    const { allGroups, groupName } = this.state;
    return allGroups
      .filter(group => group.extractionFieldGroupName)
      .find(group => group.extractionFieldGroupName.toLowerCase() === groupName.toLowerCase());
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    const { allGroups, groupName, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="add-group-popover popover">
          <div className="popover__section add-group-popover__section">
            <Icon className="spinner spinner--centered" name="loader" width={50} />
          </div>
        </div>
      );
    }
    const groupNames = allGroups
      .filter(group => group.extractionFieldGroupName && this.isExpectedGroup(group))
      .map(group => group.extractionFieldGroupName);

    return (
      <div className="add-group-popover popover">
        <div className="popover__section add-group-popover__section">
          <form className="add-group-popover__add-group-form" onSubmit={this.onSubmit}>
            <div className="add-group-popover__add-group-form-field form-field">
              <label>
                <FormattedMessage id="quick-study.extraction-field-details.groups-tab.popover.label" />
              </label>
              <GroupsAutocomplete
                groupNames={groupNames}
                groupName={groupName}
                onGroupChange={this.onGroupChange}
                placeholder={formatMessage({ id: 'quick-study.extraction-field-details.groups-tab.popover.input' })}
              />
            </div>
            <div className="add-group-popover__add-group-form-field form-field">
              <Button type="submit" className="btn btn-primary btn-no-margin" disabled={!groupName}>
                <FormattedMessage id="common.add" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(AddGroupPopover));
