class SetUtil {
  toggle(set, item) {
    if (set.has(item)) {
      set.delete(item);
    } else {
      set.add(item);
    }
  }

  immutableToggle(set, item) {
    const newSet = new Set(set);
    this.toggle(newSet, item);
    return newSet;
  }

  //Taken from https://gist.github.com/clohr/44d4aa6fb749b3abd4e7fcde82e03d29
  intersect(set1, set2) {
    return [...set1].filter(num => set2.has(num));
  }

  difference(set1, set2) {
    return [...set1].filter(num => !set2.has(num));
  }

  union(set1, set2) {
    return new Set([...set1, ...set2]);
  }
}

export default new SetUtil();
