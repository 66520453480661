import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import constants from 'utils/constants';
import Icon from 'components/shared/icon';

const {
  SELECT_TEMPLATE_TYPE,
  SELECT_DOC_TYPE,
  CREATE_TEMPLATE,
  ADD_EF,
  TEMPLATE_LIST,
  CUSTOMIZE_TEMPLATE,
  ADD_EF_CUSTOM_TEMPLATE
} = constants.TemplateSteps;

const TemplateStepDescription = ({ templateStep, showCreateExtraction }) => (
  <div className="templates-step__right">
    <span className="templates-step__guided-heading">
      <Icon className="templates-step__guided-icon" name="special-info-outline" width={18} />
      <span className="templates-step__guided-text">
        {templateStep === SELECT_TEMPLATE_TYPE && <FormattedMessage id="template-type.tutorial-title" />}
        {templateStep === SELECT_DOC_TYPE && <FormattedMessage id="tips.title.documentType" />}
        {(templateStep === CREATE_TEMPLATE || templateStep === CUSTOMIZE_TEMPLATE) && (
          <FormattedMessage id="tips.title.templateSelected" />
        )}
        {(templateStep === ADD_EF || templateStep === ADD_EF_CUSTOM_TEMPLATE) &&
          (!showCreateExtraction ? (
            <FormattedMessage id="tips.title.addExtraction" />
          ) : (
            <FormattedMessage id="tips.title.createExtraction" />
          ))}
        {templateStep === TEMPLATE_LIST && <FormattedMessage id="tips.title.finished" />}
      </span>
    </span>
    <div className="templates-step__guided-body">
      {templateStep === SELECT_TEMPLATE_TYPE && (
        <div>
          <div className="templates-step__guided-body__sub-title">
            <FormattedMessage id="template-type.tutorial-sub-title.use-ai" />
          </div>
          <div className="templates-step__guided-body__description">
            <FormattedMessage id="template-type.tutorial-description.use-ai-1" />
          </div>
          <div className="templates-step__guided-body__description">
            <FormattedMessage id="template-type.tutorial-description.use-ai-2" />
          </div>
          <div className="templates-step__guided-body__sub-title">
            <FormattedMessage id="template-type.tutorial-sub-title.workbench-mode" />
          </div>
          <div className="templates-step__guided-body__description">
            <FormattedMessage id="template-type.tutorial-description.workbench-mode-1" />
          </div>
          <div className="templates-step__guided-body__description">
            <FormattedMessage id="template-type.tutorial-description.workbench-mode-2" />
          </div>
        </div>
      )}
      {templateStep === SELECT_DOC_TYPE && <FormattedMessage id="tips.body1.documentType" />}
      {(templateStep === CREATE_TEMPLATE || templateStep === CUSTOMIZE_TEMPLATE) && (
        <div>
          <FormattedMessage id="tips.body1.templateSelected" />
          <br />
          <br />
          <FormattedMessage id="tips.body2.templateSelected" />
          <br />
          <br />
          <FormattedMessage
            id="tips.ai-fields.templateSelected"
            values={{ icon: <Icon name="public-trained" width={18} /> }}
          />
          <br />
          <br />
          <FormattedMessage
            id="tips.manual-fields.templateSelected"
            values={{ icon: <Icon name="public-grey" width={18} /> }}
          />
        </div>
      )}
      {(templateStep === ADD_EF || templateStep === ADD_EF_CUSTOM_TEMPLATE) &&
        (!showCreateExtraction ? (
          <FormattedMessage id="tips.body1.addExtraction" />
        ) : (
          <div>
            <FormattedMessage id="tips.body1.createExtraction" />
            <br />
            <br />
            <FormattedMessage id="tips.body2.createExtraction" />
          </div>
        ))}
      {templateStep === TEMPLATE_LIST && (
        <div>
          <FormattedMessage id="tips.body1.finished" />
          <br />
          <br />
          <FormattedMessage id="tips.body2.finished" />
        </div>
      )}
    </div>
  </div>
);

TemplateStepDescription.propTypes = {
  showCreateExtraction: PropTypes.bool,
  templateStep: PropTypes.number
};

export default TemplateStepDescription;
