import { createAction, createReducer } from 'utils/redux-utils';
import * as api from 'store/api';
import { Translations } from 'models/translations';

export const ACTIONS = {
  // Translations list
  TRANSLATIONS_LIST_LOADING: 'argus/ui/TRANSLATIONS_LIST_LOADING',
  TRANSLATIONS_LIST_LOADED: 'argus/ui/TRANSLATIONS_LIST_LOADED',
  TRANSLATIONS_LIST_ERROR: 'argus/ui/TRANSLATIONS_LIST_ERROR',
  // Language pairs
  LANGUAGE_PAIRS_LOADING: 'argus/ui/LANGUAGE_PAIRS_LOADING',
  LANGUAGE_PAIRS_LOADED: 'argus/ui/LANGUAGE_PAIRS_LOADED',
  LANGUAGE_PAIRS_ERROR: 'argus/ui/LANGUAGE_PAIRS_ERROR',
  SERVICE_DOWN: 'argus/ui/SERVICE_DOWN',
  // Document translation
  DOCUMENT_TRANSLATION_LOADING: 'argus/ui/DOCUMENT_TRANSLATION_LOADING',
  DOCUMENT_TRANSLATION_LOADED: 'argus/ui/DOCUMENT_TRANSLATION_LOADED',
  DOCUMENT_TRANSLATION_ERROR: 'argus/ui/DOCUMENT_TRANSLATION_ERROR',
  // Text translation
  TEXT_TRANSLATION_LOADING: 'argus/ui/TEXT_TRANSLATION_LOADING',
  TEXT_TRANSLATION_LOADED: 'argus/ui/TEXT_TRANSLATION_LOADED',
  TEXT_TRANSLATION_ERROR: 'argus/ui/TEXT_TRANSLATION_ERROR'
};

// Translations: Document list
export const translationsListLoading = createAction(ACTIONS.TRANSLATIONS_LIST_LOADING);
export const translationsListLoaded = createAction(ACTIONS.TRANSLATIONS_LIST_LOADED);
export const translationsListError = createAction(ACTIONS.TRANSLATIONS_LIST_ERROR);

export const loadTranslationsList = () => dispatch => {
  dispatch(translationsListLoading());
  return api.getTranslations().then(response => {
    dispatch(translationsListLoaded(response.data));
  });
};

export const loadTranslationListLocal = data => dispatch => {
  return dispatch(translationsListLoaded(data));
};

// Translations: Language pairs
export const languagePairsLoading = createAction(ACTIONS.LANGUAGE_PAIRS_LOADING);
export const languagePairsLoaded = createAction(ACTIONS.LANGUAGE_PAIRS_LOADED);
export const languagePairsError = createAction(ACTIONS.LANGUAGE_PAIRS_ERROR);
export const serviceDown = createAction(ACTIONS.SERVICE_DOWN);

export const loadTranslationLanguagePairs = () => dispatch => {
  dispatch(languagePairsLoading());
  return api.getLanguagePairs().then(response => {
    response.data.machineTranslationSettings.isMachineTranslationAvailable
      ? dispatch(languagePairsLoaded(response.data.languagePairs))
      : dispatch(serviceDown(response.data.machineTranslationSettings));
  });
};

// Translations: Translate document
export const documentTranslationLoading = createAction(ACTIONS.DOCUMENT_TRANSLATION_LOADING);
export const documentTranslationLoaded = createAction(ACTIONS.DOCUMENT_TRANSLATION_LOADED);
export const documentTranslationError = createAction(ACTIONS.DOCUMENT_TRANSLATION_ERROR);

export const translateDocuments = data => dispatch => {
  dispatch(documentTranslationLoading());
  return api.translateDocuments(data).then(response => dispatch(documentTranslationLoaded(response.data)));
};

// Translations: Translate text
export const textTranslationLoading = createAction(ACTIONS.TEXT_TRANSLATION_LOADING);
export const textTranslationLoaded = createAction(ACTIONS.TEXT_TRANSLATION_LOADED);
export const textTranslationError = createAction(ACTIONS.TEXT_TRANSLATION_ERROR);

export const translateText = data => dispatch => {
  dispatch(textTranslationLoading());
  return api.translateText(data).then(response => dispatch(textTranslationLoaded(response.data)));
};

/*
 * Reducer
 */
export const INITIAL_STATE = new Translations();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.TRANSLATIONS_LIST_LOADING]: (state, action) => {
    return state.setTranslationsListLoading();
  },

  [ACTIONS.TRANSLATIONS_LIST_LOADED]: (state, action) => {
    return state.setTranslationsListLoaded(state, action);
  },

  [ACTIONS.TRANSLATIONS_LIST_ERROR]: (state, action) => {
    return state.setTranslationsListError(action.payload);
  },

  [ACTIONS.LANGUAGE_PAIRS_LOADING]: (state, action) => {
    return state.setLanguagePairsLoading();
  },

  [ACTIONS.LANGUAGE_PAIRS_LOADED]: (state, action) => {
    return state.setLanguagePairsLoaded(state, action);
  },

  [ACTIONS.LANGUAGE_PAIRS_ERROR]: (state, action) => {
    return state.setLanguagePairsError(action.payload);
  },

  [ACTIONS.SERVICE_DOWN]: (state, action) => {
    return state.setServiceDown(action.payload);
  }
});
