import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button from 'components/shared/single-click-button';

import Icon from 'components/shared/icon';
import ReactTooltip from 'react-tooltip';
import Avatar from 'components/shared/avatar';
import Menu from 'containers/project-list/menu-container';
import dateUtil from 'utils/dateUtil';
import CONSTANTS from 'utils/constants';

// Project Cards in Tile View
function ProjectCard({
  clientName,
  creationDate,
  creator,
  geoCode,
  intl,
  isLegalHold,
  isMenuActive,
  label,
  lastEditDate,
  linkClassname,
  linkTo,
  name,
  onDeselect,
  onMenuClick,
  project,
  projectId,
  status,
  user,
  wbsCode,
  onCopied
}) { 
 return (
    <div className={`tile-view__card ${status === null ? 'grey' : status}`}>
      <div className="tile-view__card-top">
        <div className="tile-view__card-first-line">
          <div className="tile-view__card-first-line-wrapper">
            {project.isAdminAccessEnabled && (
              <span data-tip data-for="tile-view.card-shield.tooltip-wrapper">
                <Icon data-tip className="icon" name="shield" width={16} />
                <ReactTooltip id="tile-view.card-shield.tooltip-wrapper" effect="solid" place="right">
                  <FormattedMessage id="tile-view.card-shield.tooltip" />
                </ReactTooltip>
              </span>
            )}
            <span className={`tile-view__card-name-${status}`} title={name ? (name.length > 31 ? name : null) : null}>
              <Link className={`tile-view__card-name ${linkClassname} `} to={linkTo}>
                {name}
              </Link>
            </span>
          </div>
          <Menu
            viewer="tile-view"
            projectState={status}
            isOpen={isMenuActive}
            onMenuClick={onMenuClick}
            onClose={onDeselect}
            selected={projectId}
            user={user}
            projectDetails={project}
            onCopied = {data => onCopied(data)}
          />
        </div>
        <span className="tile-view__card-client">{clientName}</span>
      </div>
      <div className="tile-view__card-mid">
        <span className="tile-view__card-wbs" title={wbsCode ? (wbsCode.length > 31 ? wbsCode : null) : null}>
          {wbsCode}
        </span>
        <span className="tile-view__card-status">
          {isLegalHold ? (
            <span className="tile-view__card-legalhold">
              <Icon name="Flag-SPECIAL" width={14} />
            </span>
          ) : (
            ''
          )}
          <div className={`table-view__body-status-icon ${status}`} />
          <FormattedMessage id={`project-state.${status}`} />
        </span>
      </div>

      <div className="tile-view__card-region">
        <FormattedMessage id="table-view.heading.region" />: {label ? label : geoCode}
      </div>

      <div className="tile-view__card-mid-lower">
        <Avatar initials={creator.initials} />
        <div className="tile-view__card-creator">
          <div className="tile-view__card-creator-name">{creator.preferredFullName}</div>
          <div className="tile-view__card-creator-email">{creator.email}</div>
        </div>
      </div>
      <div className="tile-view__card-bottom">
        <span className="tile-view__card-creation">
          <span
            className="tile-view__card-creation-heading"
            title={intl.formatMessage({ id: 'tile-view.card-creation.heading' })}
          >
            <FormattedMessage id="tile-view.card-creation.heading" />
          </span>
          <span className="tile-view__card-creation-date">{dateUtil.formatDate(creationDate)}</span>
        </span>
        <span className="tile-view__card-lastedit">
          <span
            className="tile-view__card-lastedit-heading"
            title={intl.formatMessage({ id: 'tile-view.card-lastedit.heading' })}
          >
            <FormattedMessage id="tile-view.card-lastedit.heading" />
          </span>
          <span className="tile-view__card-lastedit-date">{dateUtil.formatDate(lastEditDate)}</span>
        </span>
      </div>
    </div>
  );
}

//Transaltion queue card in Tile View
function TranslationQueueCard(
  translations
) {
  const getTranslationStatus = (translation) => {
    const status = Object.keys(CONSTANTS.TranslationStatus).find(
      key => CONSTANTS.TranslationStatus[key] === translation.translationStateId
    );

    return translation.translationStateId ? status : 'NoStatus';
  };

  return (
    <div className={`tile-view__card ${translations.TranslationQueueCardStatus === null ? 'grey' : translations.TranslationQueueCardStatus}`}>
      <div className="translationCard" >
      <div className="tile-view__card-top">
        <div className="tile-view__card-first-line">
          <div className="tile-view__card-first-line-wrapper">
            <span className={`tile-view__card-name-started`}>
              <Link className= "tile-view__card-name" to="translation#translation-queue" >
                <FormattedMessage id="argus.translation.queue" />
              </Link>
            </span>
          </div>
        </div>      
        <span className="tile-view__card-document"> 
            <FormattedMessage id="file-browser.toolbar.title" />
        </span>
      </div>
      {translations.translations && translations.translations.map((t, index) => {
        const translationStatus = getTranslationStatus(t);
        return (<div className="tile-view__card-doclist">
          <span className="tile-view__card-docname" title={t.sourceFileName ? (t.sourceFileName.length > 31 ? t.sourceFileName : null) : null}>
            {t.sourceFileName}
          </span>
          <span className="tile-view__card-docstatus">
            <div className={`table-view__body-status-icon ${translationStatus}`} />
            <FormattedMessage id={`translation-queue.status.${translationStatus}`} /> 
          </span></div>
      )})}
      <Link to="translation" >
      <Button size="large" className="btn btn-secondary translatebutton">
        <FormattedMessage id="translation.translate" />
      </Button>
      </Link>
      </div>
    </div>
  )
} 

ProjectCard.propTypes = {
  clientName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  containerCode: PropTypes.any,
  creationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  creator: PropTypes.any,
  geoCode: PropTypes.any,
  intl: intlShape.isRequired,
  isLegalHold: PropTypes.bool,
  isMenuActive: PropTypes.bool,
  label: PropTypes.any,
  lastEditDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  linkClassname: PropTypes.any,
  linkTo: PropTypes.any,
  memberFirmCode: PropTypes.any,
  name: PropTypes.string,
  onDeselect: PropTypes.func,
  onMenuClick: PropTypes.func,
  project: PropTypes.any,
  projectId: PropTypes.number,
  status: PropTypes.any,
  user: PropTypes.object,
  wbsCode: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onCopied : PropTypes.func
};

TranslationQueueCard.propTypes = {
  translations: PropTypes.any,
  TranslationQueueCardStatus: PropTypes.any
}

class TileView extends Component {
  static propTypes = {
    // Project List data loaded from reducer
    creator: PropTypes.any,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    intl: intlShape.isRequired,
    isMenuOpen: PropTypes.array,
    linkClassname: PropTypes.any,
    linkTo: PropTypes.func,
    onDeselect: PropTypes.func,
    onSelect: PropTypes.func,
    status: PropTypes.any,
    user: PropTypes.object,
    onCopied: PropTypes.func
  };

  getTranslationStatus = translation => {
    const status = Object.keys(CONSTANTS.TranslationStatus).find(
      key => CONSTANTS.TranslationStatus[key] === translation.translationStateId
    );

    return translation.translationStateId ? status : 'NoStatus';
  };

  getTranslationQueueCardStatus= (translations) =>{    
    if (translations.every(s => s.translationStateId === 2))
    return "inCreation"
    if (translations.some(s => s.translationStateId === 4))
    return "legalHold"
    if (translations.every(s => s.translationStateId === 5))
    return "started"
    if (translations.some(s => s.translationStateId === 7 || s.translationStateId === 6 ))
    return "failed"
  }

  render() {
    const { data, onSelect, onDeselect, isMenuOpen, user, status, creator, linkClassname, linkTo, intl, onUpdateStatus } = this.props;
    return (
      <div className="tile-view">
        {data &&
          data.map((project, index) => {
            if(project.translations)
            {
              return (<TranslationQueueCard key= "translationQueueCard" translations= {project.translations} TranslationQueueCardStatus={this.getTranslationQueueCardStatus(project.translations)} />)
            }
            else{
            const globalCode = `${project.geoCode}_${project.memberFirmCode}_${project.containerCode}`;
            const projectStatus = status(project);
            const linkClass = linkClassname(project);
            const projectCreator = creator(project.creator, 'tile');
            const projectLink = linkTo(project, globalCode);
            const projectUID = `${project.geoCode}-${project.memberFirmCode}-${project.containerCode}-${project.projectId}`;

            return (
              <ProjectCard
                key={projectUID}
                projectId={project.projectId}
                name={project.projectName || 'Untitled Name'}
                clientName={project.clientName ? project.clientName : ''}
                wbsCode={project.engagementName ? project.engagementName : ''}
                creator={projectCreator}
                creationDate={project.createdDate ? project.createdDate : '-'}
                lastEditDate={project.lastUpdatedDate ? project.lastUpdatedDate : '-'}
                status={projectStatus}
                isLegalHold={project.legalHold}
                isMenuActive={isMenuOpen[projectUID]}
                onMenuClick={() => onSelect(projectUID)}
                onDeselect={() => onDeselect(projectUID)}
                user={user}
                geoCode={project.geoCode}
                memberFirmCode={project.memberFirmCode}
                containerCode={project.containerCode}
                label={project.label}
                linkClassname={linkClass}
                linkTo={projectLink}
                project={project}
                intl={intl}
                onCopied = {(data) => onUpdateStatus(data)}
              />
            );
            }
          })}
      </div>
    );
  }
}

export default injectIntl(TileView);
