import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

class PublicRoute extends React.Component {
  static propTypes = {
    component: PropTypes.func
  };

  innerRender = props => {
    const { component: Component, ...rest } = this.props;
    return <Component {...props} {...rest} />;
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return <Route {...rest} render={this.innerRender} />;
  }
}

export default PublicRoute;
