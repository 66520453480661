import React from 'react';
import PropTypes from 'prop-types';
import ColorHelper from 'components/extractions/helpers/colorHelper';
import ComplicatedIcon from 'components/extractions/complicated-icon/complicated-icon';
import { getCurrentRegion } from 'store/api';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const FileListRecordItem = ({ recordData, projectId }) => {
  const className = 'file-list-record-item';
  const region = getCurrentRegion();
  const target = `/region/${region}/project/${projectId}/document/${recordData.projectItemId}?page=${recordData.pageNumber}`;

  return (
    <Link to={target} className={className}>
      <div className={`${className}__wrapper`}>
        <div className={`${className}__header`}>
          <div className={`${className}__title`} title={recordData.extractionFieldName}>
            <span
              className={`${className}__color-marker`}
              style={{ background: ColorHelper.highlight_colors[recordData.colorIndex] }}
            />
            {recordData.extractionFieldName}
          </div>
          <ComplicatedIcon
            disableInteraction={true}
            extractionId={recordData.extractionFieldId}
            accessTypeId={recordData.accessType}
            isTrained={recordData.isTrained}
            isAutomaticExtraction={recordData.isAutomaticExtraction}
            trainingCount={recordData.trainingCount}
            trainingTarget={recordData.trainingTarget}
            iconSize={22}
          />
        </div>

        <div className={`${className}__body`} title={recordData.extractFieldInstanceData}>
          {recordData.extractFieldInstanceData}
        </div>
        <div className={`${className}__footer`}>
          {recordData.tags?.map(tagName => (
            <div className={`${className}__tag`} title={tagName} key={tagName}>
              {tagName}
            </div>
          ))}
          <div className={`${className}__page-number`}>
            <FormattedMessage id="common.page" /> {recordData.pageNumber}/{recordData.pageCount}
          </div>
        </div>
      </div>
    </Link>
  );
};

FileListRecordItem.propTypes = {
  recordData: PropTypes.object.isRequired
};

export default FileListRecordItem;
