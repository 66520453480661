import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Footer from 'containers/footer/footer';
import Header from 'containers/header/header';
import LandingPageContainer from 'containers/landing-page/landing-page-container';
import NotificationsContainer from 'containers/shared/notifications-container';

import { getHomeRegion, setCurrentRegion } from 'store/api';
import Permissions from 'permissions/permissions';
import history from 'utils/history';

class LandingPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    setCurrentRegion(getHomeRegion());

    if (!Permissions.Global.canViewProjects()) {
      history.push('/unauthorized');
    }
  }

  render() {
    return (
      <div className="page landing-page">
        <Header {...this.props} fromLandingPage currentPage="global.subheader.landing-page"/>
        <LandingPageContainer {...this.props} />
        <Footer extended />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default LandingPage;
