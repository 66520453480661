import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentRegion } from 'store/api';

export default class VisualsDocumentList extends Component {
  static propTypes = {
    documents: PropTypes.array.isRequired,
    projectId: PropTypes.string.isRequired
  };

  state = {
    maxVisibleDocuments: 40
  };

  render() {
    const { documents, projectId } = this.props;
    const { maxVisibleDocuments } = this.state;

    const limitedDocuments = documents.slice(0, maxVisibleDocuments);

    return (
      <div className="visuals-document-list visuals-document-list__root">
        <h2 className="visuals-document-list__title">
          <FormattedMessage id="visuals.document-list.title" />
        </h2>
        <ul className="visuals-document-list__list">
          {limitedDocuments.map(doc => (
            <li className="visuals-document-list__list-item" key={doc.id}>
              <Link to={`/region/${getCurrentRegion()}/project/${projectId}/document/${doc.id}`}>{doc.name}</Link>
            </li>
          ))}
          {documents.length > maxVisibleDocuments && (
            <li
              onClick={() => this.setState({ maxVisibleDocuments: maxVisibleDocuments * 2 })}
              className="visuals-document-list__show-more"
            >
              <FormattedMessage id="visuals.document-list.show-more" />
            </li>
          )}
        </ul>
      </div>
    );
  }
}
