import { connect } from 'react-redux';

import MainPanel from 'components/document-viewer/panels/main-panel';
import { getCurrentDocument } from 'store/current-document';

const mapStateToProps = ({ data: { currentDocument, currentDocumentLayout, currentProject } }) => ({
  currentDocument,
  currentDocumentLayout,
  currentProject
});

const mapDispatchToProps = {
  getCurrentDocument
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPanel);
