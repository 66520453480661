import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BLOCK } from './constants';
import moment from 'moment';
import EmptyState from 'components/file-browser/file-list/empty-state';
import CONSTANTS from 'utils/constants';
import { SORT_FUNCTIONS } from 'components/project-list/constants';
import Permissions from 'permissions/permissions';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import LandingPageTable from 'components/landing-page/landing-page-table';
import emptyAccessDeniedImage from 'images/empty_state_access_denied.svg';

class RecentProjects extends React.Component {
  static propTypes = {
    currentLanguage: PropTypes.object,
    currentProject: PropTypes.object,
    currentUser: PropTypes.object,
    isAllowed: PropTypes.bool.isRequired,
    projectList: PropTypes.object
  };

  state = {
    sortKey: 'projectName',
    sortAsc: true
  };

  // Filter deleted projects for those who cannot view delete projects
  filterDeletedProjects = () => {
    const { projectList } = this.props;

    return projectList.projects.filter(
      project =>
        Permissions.Project.canViewDeletedProjects({
          geoCode: project.geoCode,
          memberFirmCode: project.memberFirmCode,
          containerCode: project.containerCode
        }) || project.projectStateId !== CONSTANTS.ProjectStateIds.softDelete
    );
  };

  // multiColumnSort can sort a list of values by multiple columns in different orders
  multiColumnSort = (values, columns, orders) => {
    return values.slice().sort((a, b) => {
      return columns.reduce((compared, key, idx) => {
        // Stop when we have a non-zero comparison result (i.e: previous columns were not equal)
        if (compared !== 0) {
          return compared;
        }

        // Compare function
        return orders[idx] * SORT_FUNCTIONS[key](a[key], b[key]);
      }, 0);
    });
  };

  // Normal section that can be organized by sorting keys
  getNormalProjects = () => {
    const { sortKey, sortAsc } = this.state;
    const projects = this.filterDeletedProjects();

    // The order in which to sort our columns (1 is asc, -1 is desc)
    const order = sortAsc ? [1, 1] : [-1, 1];

    return this.multiColumnSort(projects, [sortKey, 'projectName'], order);
  };

  // Load the list of recent projects from localStorage
  getRecentProjects = projectList => {
    const { currentUser } = this.props;
    // Load the list from localStorage
    const cachedRecentProjects = JSON.parse(localStorage.getItem(`recentProjects-${currentUser.userId}`)) || [];
    return (
      cachedRecentProjects
        // Filter out any projects were accessed more than 7 days ago
        .filter(({ timestamp }) => !moment(timestamp).isBefore(moment().subtract(7, 'days')))
        // Map each of the cached project keys to one of the projects loaded from the API
        .map(({ projectKey: recentProjectKey }) =>
          projectList.find(({ projectKey }) => projectKey === recentProjectKey)
        )
        // Filter out any projects that can't be found
        .filter(project => !!project)
    );
  };

  // Get Project Status
  getProjectStatus = project => {
    const status = Object.keys(CONSTANTS.ProjectStateIds).find(
      key => CONSTANTS.ProjectStateIds[key] === project.projectStateId
    );

    return project.projectStateId ? status : 'NoStatus';
  };

  isActionRequired = project => {
    return Permissions.Project.canApproveArchive(project);
  };

  getLinkTo = project => {
    const globalCode = `${project.geoCode}_${project.memberFirmCode}_${project.containerCode}`;
    const status = this.getProjectStatus(project);
    var pathname = '';

    const projectRegion = {
      geoCode: project.geoCode,
      memberFirmCode: project.memberFirmCode,
      containerCode: project.containerCode
    };

    if (status === 'started' || status === 'pendingArchive' || status === 'archived') {
      if (Permissions.Project.Document.canView(project)) {
        pathname = `/region/${globalCode}/project/${project.projectId}/folder/root`;
      } else if (Permissions.Project.Settings.canView(project, projectRegion)) {
        pathname = `/region/${globalCode}/project/${project.projectId}/settings`;
      }
    } else if (status === 'inCreation') {
      pathname =
        project.sourceProjectId !== null
          ? `/region/${globalCode}/project/${project.projectId}/copy`
          : `/region/${globalCode}/project/${project.projectId}/creation`;
    }

    return {
      pathname: pathname
    };
  };

  render() {
    const { currentUser, isAllowed, projectList, currentLanguage } = this.props;

    if (!isAllowed) {
      return (
        <EmptyState
          title={<FormattedMessage id="error-page.unauthorized.title" />}
          description={
            <FormattedMessage
              id="error-page.unauthorized.description"
              values={{
                link: this.link
              }}
            />
          }
          img={emptyAccessDeniedImage}
        />
      );
    }

    if (projectList.error !== null && projectList.projects.length <= 0) {
      return (
        <div className={`${BLOCK}`}>
          <FormattedMessage id={`${BLOCK}.error`} />
        </div>
      );
    }

    if (projectList.isLoading || !projectList.projects) {
      return (
        <div className={`${BLOCK}__spinner-wrapper`}>
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        </div>
      );
    }

    const otherProjects = this.getNormalProjects();
    const recentProjects = this.getRecentProjects(otherProjects).slice(0, 4);
    const actionRequiredProjects = projectList.projects.filter(p => this.isActionRequired(p));
    const showActionRequiredProjects = currentUser.isPPD && actionRequiredProjects.length > 0;

    return (
      projectList.projects &&
      !projectList.error && (
        <div className={`${BLOCK}__recent-projects`}>
          <p className={`${BLOCK}__heading border-bottom`}>
            {showActionRequiredProjects ? (
              <FormattedMessage id={`${BLOCK}.action-required-projects`} />
            ) : (
              <FormattedMessage id={`${BLOCK}.recent-projects`} />
            )}
          </p>
          {(recentProjects && recentProjects.length > 0) || showActionRequiredProjects ? (
            <LandingPageTable
              forTable="projects"
              data={showActionRequiredProjects ? actionRequiredProjects : recentProjects}
              linkTo={this.getLinkTo}
              currentLanguage={currentLanguage}
            />
          ) : (
            <div className={`${BLOCK}__empty-state`} />
          )}
        </div>
      )
    );
  }
}

export default RecentProjects;
