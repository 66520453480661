import { debounce } from 'underscore';
import { FormattedMessage } from 'react-intl';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  GLOBAL_SEARCH_CATEGORIES,
  SEARCH_CATEGORY_VERBOSE_NAMES,
  SEARCH_MODIFIER_DISPLAY_NAMES,
  SEARCH_RECORD_MODIFIER_DISPLAY_NAMES,
  SEARCH_OPERATOR_DISPLAY_NAMES
} from 'components/search/constants';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import SearchDropdown from 'containers/search/file-list-search/search-dropdown-container';
import SearchResults from 'containers/search/file-list-search/search-results-container';

class FileListSearch extends Component {
  static propTypes = {
    className: PropTypes.string,
    clearResultsData: PropTypes.func,
    clearSearchData: PropTypes.func,
    currentProject: PropTypes.object,
    currentSearchFolder: PropTypes.object,
    getAdvancedSearchResults: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    onAdvancedSearch: PropTypes.func,
    onAdvancedSearchChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    onSelectCategory: PropTypes.func,
    projectId: PropTypes.any,
    search: PropTypes.object,
    searchAll: PropTypes.func
  };

  state = {
    isDropdownOpen: false,
    isFocused: false,
    isMenuOpen: false,
    isFilterView: false, // This shows advanced search filters
    isAdvancedView: false, // This shows advanced search results
    isSearchResultsOpen: false, // Overlay for the search results page
    externalSearch: false // Used when external components trigger a search
  };

  // Determine whether we need to display search results in doc viewer or the normal toolbar view
  componentDidMount() {
    const { match, currentSearchFolder, search, onSearchChange } = this.props;
    const { documentId } = match.params;

    if (!documentId && currentSearchFolder.children && currentSearchFolder.children.length > 0) {
      this.onSearchExit();
    }

    if (search.query.length > 0) {
      onSearchChange('');
    }
  }

  componentDidUpdate = prevProps => {
    var { currentSearchFolder } = this.props;
    var { isSearchResultsOpen } = this.state;

    if (
      currentSearchFolder.children !== null &&
      prevProps.currentSearchFolder.children === null &&
      !isSearchResultsOpen
    ) {
      this.setState({ externalSearch: true });
    }
  };

  // This allows user to navigate away from search results page from the menu dropdown
  // When they have the same url, and clicks on the same url, it doesnt disappear
  // But we can compare the keys and exit search; was added due to a bug filed by QA
  // TODO: Suggest paging or better ui/ux for exiting
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, match } = this.props;
    const { location: nextLocation } = nextProps;
    const { documentId } = match.params;

    if (
      nextLocation.key !== location.key &&
      nextLocation.pathname &&
      !documentId &&
      ((nextLocation.state && !nextLocation.state.isSearching) || !nextLocation.state)
    ) {
      this.onSearchExit();
      this.setState({ isAdvancedView: false, isFilterView: false, disableSearch: true });
    }
  }

  componentWillUnmount() {
    const { search, onSearchChange } = this.props;

    if (search.query.length > 0) {
      onSearchChange('');
    }
  }

  // Logic for when to show dropdown (ie when user starts typing)
  onSearchInputChange = event => {
    const { onSearchChange, clearSearchData } = this.props;
    const value = event.target.value;

    onSearchChange(value);

    if (value.length > 0) {
      this.setState({ isDropdownOpen: true, isFilterView: false, disableSearch: true });
    } else {
      clearSearchData();
      this.setState({ isDropdownOpen: false, isFilterView: false, disableSearch: true });
    }
  };

  // Perform global search
  onSearchInputKeyPress = e => {
    const { search } = this.props;
    if (e.key === 'Enter' && (search.query.length > 0 || search.filters.length > 0)) {
      return search.filters.length > 0 ? this.onAdvancedSearch() : this.getGlobalSearchResults();
    }
  };

  // Handle a key up on the search input
  onSearchInputKeyUp = debounce(() => {
    const { projectId, search, searchAll } = this.props;
    const query = search.query.trim();

    if (!query.length) return;

    searchAll({ projectId, keyword: query, pageNum: 1 });
  }, 300);

  onSearchInputCancelClick = () => {
    this.onSearchExit();
    this.setState({ isDropdownOpen: false });
  };

  onSearchInputFocus = () => {
    const { search } = this.props;

    if (search.query.length > 0) {
      this.setState({ isDropdownOpen: true, isFocused: true });
    }
  };

  onSearchInputBlur = () => {
    this.setState({ isFocused: false });
  };

  onAdvancedSearchButtonClick = () => {
    this.setState({ isFilterView: true, isDropdownOpen: true });
  };

  onSearchButtonClick = () => {
    const { isFilterView } = this.state;

    if (isFilterView) {
      return this.onAdvancedSearchClick();
    }
    return this.getGlobalSearchResults();
  };

  // When a user either clicks the search icon or search from filter dropdown
  onAdvancedSearchClick = data => {
    const { search, onSelectCategory } = this.props;

    onSelectCategory({ key: 'advanced', data });

    if (data) {
      // incoming payload data
      this.onAdvancedSearch(data);
    } else {
      // otherwise null use default redux value
      this.onAdvancedSearch(search.payload);
    }

    this.setState({
      disableSearch: true,
      isDropdownOpen: false,
      isFilterView: false,
      isAdvancedView: true,
      advancedPayload: data
    });
  };

  onAdvancedSearch = data => {
    const { projectId, getAdvancedSearchResults } = this.props;

    this.setState({ isSearchResultsOpen: true });
    return getAdvancedSearchResults({ payload: data, pageNum: 1, projectId });
  };

  onAdvancedSearchCancelClick = () => {
    this.onSearchExit();
    this.setState({ isAdvancedView: false, isFilterView: false, disableSearch: true });
  };

  // Search exit button (gets out of search results overlay)
  onSearchExit = () => {
    const { clearResultsData, clearSearchData } = this.props;
    this.setState({ isSearchResultsOpen: false, externalSearch: false });
    clearSearchData();
    clearResultsData();
  };

  // Search dropdown blur
  onSearchDropdownBlur = () => {
    const { isSearchResultsOpen } = this.state;
    const { clearSearchData } = this.props;

    if (isSearchResultsOpen) {
      this.setState({ isDropdownOpen: false });
      return;
    }

    this.setState({ isDropdownOpen: false });
    clearSearchData();
  };

  onSearchDropdownClickOutside = () => {
    const { isFocused } = this.state;
    if (isFocused) {
      this.setState({ isDropdownOpen: true });
    } else {
      this.setState({ isDropdownOpen: false });
    }
  };

  onSearchDropdownSearch = (key, selected) => {
    const { onSelectCategory } = this.props;

    onSelectCategory({ key, data: selected });
    this.setState({ isDropdownOpen: false, key, isSearchResultsOpen: true });
  };

  onSearchDropdownSearchAll = (key, selected) => {
    const { onSelectCategory } = this.props;

    onSelectCategory({ key, data: selected });
    this.setState({ isDropdownOpen: false, isSearchResultsOpen: true });
  };

  onSearchDropdownReset = () => {
    this.setState({ disableSearch: true });
  };

  goToAdvancedSearch = () => {
    this.setState({ isFilterView: true });
  };

  handleClickOutside = () => {
    const { isDropdownOpen, isFilterView } = this.state;

    if (isDropdownOpen || isFilterView) {
      this.setState({ isDropdownOpen: false, isFilterView: false });
    }
  };

  getGlobalSearchResults = () => {
    const { search, getAdvancedSearchResults, projectId, onSelectCategory } = this.props;

    const filters = GLOBAL_SEARCH_CATEGORIES.map(categoryNum => ({
      andOrId: categoryNum === 1 ? '' : 2,
      fieldTypeId: categoryNum,
      includeExcludeId: categoryNum === 3 ? 3 : 1,
      keyword: search.query
    }));

    const params = { projectId, payload: { filters } };
    onSelectCategory({ key: 'global', data: params.payload });
    this.setState({ isSearchResultsOpen: true, isDropdownOpen: false });
    return getAdvancedSearchResults(params);
  };

  renderAdvancedSearchQueryItem = ([key, value]) => {
    const { search } = this.props;

    const andOrValue = value.andOr && <FormattedMessage id={SEARCH_OPERATOR_DISPLAY_NAMES[value.andOr]} />;
    const categoryValue = value.category && (
      <FormattedMessage id={SEARCH_CATEGORY_VERBOSE_NAMES[value.tagType || value.category]} />
    );
    const includeExcludeValue = value.include && <FormattedMessage id={SEARCH_MODIFIER_DISPLAY_NAMES[value.include]} />;
    const recordValue = value.record && <FormattedMessage id={SEARCH_RECORD_MODIFIER_DISPLAY_NAMES[value.record]} />;

    return (
      <div key={key} className="search-bar__advanced-search-query">
        <span className="search-bar__advanced-search-query-item">
          <i>{andOrValue}</i>
          <span className="search-bar__advanced-search-query--bold">
            {categoryValue}({includeExcludeValue || recordValue})
          </span>
        </span>
        : <span className="search-bar__advanced-search-query-item">{value.searchString || search.query};</span>
      </div>
    );
  };

  // Render an andvanced search query
  renderAdvancedSearchQuery = () => {
    const { search } = this.props;

    let queryItems = Object.entries(search.filters).map(this.renderAdvancedSearchQueryItem);

    if (queryItems.length <= 0) {
      this.onSearchExit();
      return this.setState({ isAdvancedView: false, isFilterView: false, disableSearch: true });
    }

    return (
      <div className="search-bar__advanced-search-container">
        {queryItems}
        {queryItems.length && (
          <Button size="icon" className="search-bar__advanced-cancel icon-button" onClick={this.onAdvancedSearchCancelClick}>
            <Icon name="special-cross-black" width={10} />
          </Button>
        )}
      </div>
    );
  };

  // Normal search bar input field with cancel button
  renderSearchInput = () => {
    const { search, location } = this.props;
    const isDisabled = location.pathname.includes('visuals') || location.pathname.includes('settings');

    return (
      <div className="search-bar__input-field">
        <input
          className="search-bar__input"
          disabled={isDisabled}
          id="search"
          name="search"
          onBlur={this.onSearchInputBlur}
          onChange={this.onSearchInputChange}
          onFocus={this.onSearchInputFocus}
          onKeyPress={this.onSearchInputKeyPress}
          onKeyUp={this.onSearchInputKeyUp}
          value={search.query}
        />
        {search.query.length > 0 || this.state.externalSearch ? (
          <Button size="icon" className="search-bar__input-cancel icon-button" onClick={this.onSearchInputCancelClick}>
            <Icon name="special-cross-black" width={10} />
          </Button>
        ) : null}
      </div>
    );
  };

  render() {
    const {
      className,
      onAdvancedSearchChange,
      projectId,
      location,
      search,
      onSearchChange,
      history,
      match,
      currentProject
    } = this.props;

    const { isAdvancedView, isFilterView, isDropdownOpen, isSearchResultsOpen, externalSearch } = this.state;

    const isDisabled = location.pathname.includes('visuals') || location.pathname.includes('settings');
    const showSearchResults = externalSearch || (isSearchResultsOpen && (search.query.length > 0 || search.payload));

    const advancedSearchButton = (
      <Button
        className="search-bar__input-search-advanced icon-button"
        disabled={isDisabled}
        onClick={this.onAdvancedSearchButtonClick}
        size="icon"
      >
        <Icon name="advanced-search" width={13} />
      </Button>
    );

    const searchButton = (
      <Button
        className="search-bar__input-search icon-button"
        disabled={search.query.length <= 0}
        onClick={this.onSearchButtonClick}
        size="icon"
      >
        <Icon name="special-search-gray" width={13} />
      </Button>
    );

    return (
      <div className={`search-bar-wrapper ${className ? className : null}`}>
        {isAdvancedView ? this.renderAdvancedSearchQuery() : this.renderSearchInput()}
        <div className="search-bar__button-wrapper">
          {advancedSearchButton}
          {searchButton}
        </div>

        {isDropdownOpen && (
          <SearchDropdown
            autocomplete={search}
            goToAdvancedSearch={this.goToAdvancedSearch}
            inputChange={onSearchChange}
            inputValue={search.query}
            isAdvancedSearch={isFilterView}
            onAdvancedSearch={this.onAdvancedSearchClick}
            onAdvancedSearchChange={onAdvancedSearchChange}
            onBlur={this.onSearchDropdownBlur}
            onClickOutside={this.onSearchDropdownClickOutside}
            onReset={this.onSearchDropdownReset}
            onSearch={this.onSearchDropdownSearchAll}
            onSearchAll={this.onSearchDropdownSearch}
            projectId={projectId}
          />
        )}

        {showSearchResults && (
          <SearchResults
            category={search.selectedCategory}
            history={history}
            location={location}
            match={match}
            onRouteChange={() => this.setState({ isSearching: false })}
            projectId={projectId}
            readOnly={currentProject.STATES.ReadOnly()}
            selected={search.selectedData}
            closeFunction={this.onSearchInputCancelClick}
          />
        )}
      </div>
    );
  }
}

export default enhanceWithClickOutside(FileListSearch);
