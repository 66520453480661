import { PropTypes } from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import { getMessages } from 'utils/errors';
import { getTemplates } from 'store/api';
import { Radio, RadioGroup } from 'components/shared/form/radio';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';

class TemplatesPopover extends Component {
  static propTypes = {
    assignTemplates: PropTypes.func.isRequired,
    currentFolder: PropTypes.object.isRequired,
    invalidate: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.object.isRequired
  };

  state = {
    isLoading: true,
    selectedTemplate: null,
    templates: [],
    isProcessing: false
  };

  componentDidMount() {
    const { projectId } = this.props;

    getTemplates({ projectId }).then(response => {
      this.setState({ templates: response.data, isLoading: false });
    });
  }

  handleClickOutside() {
    const { onClose } = this.props;
    onClose();
  }

  onTemplateChange = template => {
    this.setState({
      selectedTemplate: template
    });
  };

  onSubmit = event => {
    const { currentFolder, selectedItems, projectId, assignTemplates, onClose, invalidate } = this.props;
    const { selectedTemplate } = this.state;
    this.setState({ isProcessing: true });
    event.preventDefault();

    return assignTemplates({
      projectId,
      items: currentFolder.children.filter(projectItem => selectedItems[projectItem.projectItemId]),
      templateId: selectedTemplate.projectTemplateId
    })
      .then(() => {
        invalidate();
        onClose();
        this.setState({ isProcessing: false });
      })
      .catch(err => {
        getMessages(err).map(message => {
          if (message.code === 'Error.ReprocessingDisabled') {
            window.location.reload();
          }
        });
      });
  };

  render() {
    const { onClose } = this.props;
    const { isLoading, templates, selectedTemplate,isProcessing } = this.state;

    if (isLoading) {
      return (
        <div className="templates-popover popover">
          <Icon className="spinner spinner--centered" name="loader" width={70} />
        </div>
      );
    }

    return (
      <div className="templates-popover popover">
        <div className="templates-popover__header popover__header">
          <h3 className="popover__title">
            <FormattedMessage id="file-browser.templates-popover.title" />
          </h3>
          <Button onClick={onClose} className="btn btn-no-margin templates-popover__clear-button icon-button">
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className="templates-popover__instructions">
          <FormattedMessage id="file-browser.templates-popover.instructions" />
        </div>
        <div className="templates-popover__list popover__section">
          <RadioGroup name="comparison-select" selectedValue={selectedTemplate} onChange={this.onTemplateChange}>
            {templates.map(template => (
              <div className="templates-popover__list-item" key={template.projectTemplateId}>
                <Radio
                  className="templates-popover__radio-button"
                  id={`templates-popover__radio-${template.projectTemplateId}`}
                  value={template}
                />
                <label htmlFor={`templates-popover__radio-${template.projectTemplateId}`}>{template.name}</label>
              </div>
            ))}
          </RadioGroup>
        </div>
        <div className="popover__footer">
          <Button size="xsmall" type="button" className="btn btn-secondary" onClick={onClose}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="xsmall"
            type="submit"
            disabled={!selectedTemplate || isProcessing}
            className="btn btn-primary btn-no-margin"
            onClick={this.onSubmit}
          >
            <FormattedMessage id="common.apply" />
          </Button>
        </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(TemplatesPopover);
