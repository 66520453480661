import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'underscore';
import Page from 'containers/document-viewer/document-renderer/page-container';
import DocumentControls from 'components/document-viewer/document-renderer/document-controls/document-controls';

function ExtractionOverviewDocumentPanel({ getCurrentDocument, currentDocument, currentDocumentLayout, projectId, documentId, pageNumber }) {
    const [pageImageLoaded, updatePageImageLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [zoomLevel, setZoomLevel] = useState(1.0);
    const [pagesInViewport, setPagesInViewport] = useState([]);
    const [pageBoundaries, setPageBoundaries] = useState([]);
    const scrollArea = useRef(null);

    useEffect(() => {
      scrollToPage(parseInt(pageNumber));
    }, [pageNumber])
    
    useEffect(() => {
        getCurrentDocument(projectId, documentId);
        // calculatePageBoundaries();
        initialize()
    }, [documentId])

    useEffect(() => {
      scrollToPage(parseInt(pageNumber));
  }, [currentDocumentLayout.isLoaded])

    useEffect(() => {
      scrollArea.current.addEventListener('scroll', scrollChange);
    }, [scrollArea])

    const initialize = () => {
      setTimeout(() => {
        calculatePageBoundaries();
        calculatePagesInViewport();
      }, 400); //Slight delay added to ensure this fires after render completes (boundary calculation needs to be done after DOM is available)
    };

    const setPageImageLoaded = () => {
        updatePageImageLoaded(true);
    };

    const scrollChange = debounce(event => {
        calculatePagesInViewport();
        calculateCurrentPage();
    }, 100);

    const calculatePagesInViewport = () => {
      let boundaries = calculatePageBoundaries();
        if (!scrollArea || !scrollArea.current) return;
    
        var scrollInfo = {
          scrollHeight: scrollArea.current.scrollHeight,
          offsetHeight: scrollArea.current.offsetHeight,
          scrollTop: scrollArea.current.scrollTop
        };
    
        var top = scrollInfo.scrollTop;
        var bottom = top + scrollInfo.offsetHeight;
        var pagesInViewportTmp = [];
    
        boundaries.forEach((page, i) => {
          if (
            (top < page.top && page.top < bottom) ||
            (top < page.bottom && page.bottom < bottom) ||
            (page.top < top && page.bottom > bottom)
          ) {
            pagesInViewportTmp.push({
              index: i,
              pageNumber: i + 1,
              page: page
            });
          }
        });
    
        setPagesInViewport(pagesInViewportTmp);
        return pagesInViewportTmp;
    };

    const calculatePageBoundaries = () => {
      var pageBoundariesTmp = [];
      var elements = [...document.getElementsByClassName('renderer-page')];
  
      elements.forEach(element => {
        pageBoundariesTmp.push({
          top: element.offsetTop,
          bottom: element.offsetTop + element.offsetHeight
        });
      });
      
      setPageBoundaries(pageBoundariesTmp);
      return pageBoundariesTmp;
    };

    const calculateCurrentPage = () => {    
      let viewPort = calculatePagesInViewport();
      if (!scrollArea || !scrollArea.current) return;
        var scrollInfo = {
          scrollHeight: scrollArea.current.scrollHeight,
          offsetHeight: scrollArea.current.offsetHeight,
          scrollTop: scrollArea.current.scrollTop
        };
    
        var currentPageNumber = 0;
        var shortestDistance = Number.MAX_SAFE_INTEGER;
        var middleOfScrollArea = Math.round(scrollArea.current.scrollTop + scrollArea.current.offsetHeight / 2);
    
        viewPort.forEach(page => {
          var middleOfPage = page.page.top + (page.page.bottom - page.page.top) / 2;
          var distance = Math.abs(middleOfScrollArea - middleOfPage);
    
          if (distance < shortestDistance) {
            shortestDistance = distance;
            currentPageNumber = page.index + 1; //Convert 0 index to page numbers
          }
        });
    
        sessionStorage.setItem(`document-${documentId}-page`, currentPageNumber);
        setCurrentPage(currentPageNumber);
    };
        
    const scrollToPage = i => {
        i = parseInt(i, 10);
    
        if (currentDocumentLayout.isLoaded && 0 < i && i <= currentDocumentLayout.layout.length) {
          var element = document.getElementById(`page-${i}`);
    
          if (element && scrollArea) {
            var top = element.offsetTop - 100;
            scrollArea.current.scrollTop = top;
            scrollChange();
          }
        }
    };

    const setLastVisitedPage = () => {
      var page = sessionStorage.getItem(`document-${documentId}-page`);
      scrollToPage(page);
    };

    const changeZoom = ratio => {
      sessionStorage.setItem('zoomLevel', ratio);
      setZoomLevel(ratio, () => {
        calculatePageBoundaries();
        setLastVisitedPage();
      });
    }

    return (
        <div className="extraction-overview__docviewer" ref={scrollArea}>
            {currentDocumentLayout.isLoaded && currentDocument?.pageCount == currentDocumentLayout?.layout.length
                ? currentDocumentLayout.layout.map((page, i) => {
                    return (
                        <Page
                            hideBookmark ={true}
                            key={i + 1}
                            page={page}
                            pushUndoStack={''}
                            searchResults={''}
                            // Only pass selection to pages that are shown
                            // Prents all pages from updating when the selection changes
                            selection={null}//{showPage ? selection : null}
                            currentSelection={null}//{showPage ? currentSelection : null}
                            selectionFunction={null}//{selectionFunction}
                            shiftKeyDown={null}//{shiftKeyDown}
                            showPage={true}//{showPage}
                            zoomLevel={zoomLevel}
                            readOnly={null}//{readOnly}
                            setPageImageLoaded={setPageImageLoaded}//{this.setPageImageLoaded}
                        />
                    );
                }
            ) : null}
            {currentDocumentLayout.isLoaded && <div className="extraction-doc"><DocumentControls
                changePageFunction={scrollToPage}
                changeZoomFunction={changeZoom}
                currentPage={currentPage}
                numPages={currentDocumentLayout.layout.length}
                zoomLevel={zoomLevel}
            /></div>}
        </div>
    )

}

export default ExtractionOverviewDocumentPanel;