import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import { COMPARISON_STATUSES } from 'models/comparison-sets';
import * as api from 'store/api';
import AddToCompareSidebarContainer from 'containers/shared/add-to-compare-sidebar-container';
import Button from 'components/shared/single-click-button';
import Checkbox from 'components/shared/form/checkbox';
import CompareItem from './compare-item';
import CreateCompareSidebarContainer from 'containers/shared/create-compare-sidebar-container';
import DeleteModal from 'components/shared/delete-modal/delete-modal';
import EmptyState from 'components/file-browser/file-list/empty-state';
import emptyStateImage from 'images/empty_state_comp_01.svg';
import ExportCompareModal from 'components/compare-browser/export-compare-modal';
import ExportSidebarContainer from 'containers/shared/export-sidebar-container';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Pagination from 'components/shared/pagination/pagination';
import Sorting from 'components/shared/sorting/sorting';
import dateUtil from 'utils/dateUtil';
import { addNotification } from 'store/notifications.js';
import store from 'store/store';

import Permissions from 'permissions/permissions';

const COMPARE_TABS = {
  DOCUMENTS: 'document',
  EXTRACTION_FIELDS: 'extractionfield'
};

export default class CompareBrowser extends Component {
  static propTypes = {
    compareType: PropTypes.string.isRequired,
    comparisonSets: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    loadComparisonSets: PropTypes.func.isRequired,
    location: PropTypes.object,
    projectId: PropTypes.string.isRequired,
    setCompareType: PropTypes.func.isRequired
  };

  state = {
    isAddToCompareSidebarShown: false,
    isCreateCompareSidebarShown: false,
    isDeleteModalShown: false,
    isExportModalShown: false,
    isExportProcessing: false,
    isExportSidebarShown: false,
    isLoading: false,
    selectedCompares: new Set(),
    sorting: {
      key: 'name',
      ascending: true
    },
    filtering: {
      key: undefined,
      ids: undefined,
      tagOptions: []
    },
    search: {
      key: undefined,
      query: undefined
    },
    isFiltering: false
  };

  componentDidMount() {
    const { loadComparisonSets, projectId, compareType } = this.props;

    loadComparisonSets({
      projectId,
      pageNum: 1,
      compareType: compareType
    });
  }

  /////////////////////// SORTING FILTERING FUNCTIONS START ///////////////////////

  reloadCurrentFolder() {
    var { loadComparisonSets, projectId, compareType } = this.props;
    var { filtering, sorting, search } = this.state;

    var folderOptions = {
      projectId,
      pageNum: 1,
      compareType: compareType
    };

    if (sorting && sorting.key) {
      folderOptions.sortBy = sorting.key;
      folderOptions.sortOrder = sorting.ascending ? 'ascending' : 'descending';
    }

    if (filtering && filtering.key) {
      folderOptions.filterBy = filtering.key;
      folderOptions.filterIds = filtering.ids;
    }

    if (search && search.key) {
      folderOptions.filterBy = search.key;
      folderOptions.filterString = search.query;
    }

    this.setState({ selectedCompares: new Set() });

    loadComparisonSets(folderOptions);
  }

  setSorting = (key, ascending) => {
    var { sorting } = this.state;
    sorting.key = key;
    sorting.ascending = ascending;
    this.setState({ sorting: sorting }, this.reloadCurrentFolder());
  };

  setSearch = (key, query) => {
    var { filtering, search } = this.state;
    filtering.key = undefined;
    filtering.ids = undefined;
    search.key = query.length > 0 ? key : undefined;
    search.query = query.length > 0 ? query : undefined;
    this.setState({ filtering: filtering, search: search, isFiltering: true }, this.reloadCurrentFolder());
  };

  setFiltering = (key, ids) => {
    var { filtering, search } = this.state;
    filtering.key = ids.length > 0 ? key : undefined;
    filtering.ids = ids.length > 0 ? ids : undefined;
    search.key = undefined;
    search.query = undefined;
    this.setState({ filtering: filtering, search: search, isFiltering: true }, this.reloadCurrentFolder());
  };
  /////////////////////// SORTING FILTERING FUNCTIONS END ///////////////////////

  onNewCompareClick = () => {
    this.setState({ isCreateCompareSidebarShown: true });
  };

  onCloseNewCompareClick = () => {
    this.setState({ isCreateCompareSidebarShown: false });
  };

  onSubmitNewCompareClick = ({ comparisonName, baseDocument, selectedFiles }) => {
    const { loadComparisonSets, projectId, comparisonSets } = this.props;
    const pageNum = comparisonSets.pageInfo ? comparisonSets.pageInfo.pageNumber : 1;

    this.setState({ isCreateCompareSidebarShown: false });

    api
      .createComparison({
        baseEntityId: baseDocument.projectItemId,
        comparisonName,
        documentIds: selectedFiles
          .map(document => document.projectItemId)
          .filter(projectItemId => projectItemId !== baseDocument.projectItemId),
        projectId
      })
      .then(response => {
        if (response.data.processingEntitiesCount) {
          store.dispatch(
            addNotification({
              message: <FormattedMessage id="compare.comparison-created-contains-processing" />,
              clearOnPageChange: true,
              autohide: 10
            })
          );
        }
        loadComparisonSets({ projectId, pageNum });
      });
  };

  onAddToCompareClick = () => {
    this.setState({ isAddToCompareSidebarShown: true });
  };

  onCloseAddToCompareClick = () => {
    this.setState({ isAddToCompareSidebarShown: false });
  };

  onSubmitAddToCompareClick = selectedItems => {
    const { comparisonSets } = this.props;
    const pageNum = comparisonSets.pageInfo ? comparisonSets.pageInfo.pageNumber : 1;

    this.setState({ isAddToCompareSidebarShown: false });

    const selectedCompareId = Array.from(this.state.selectedCompares)[0];
    const comparison = this.props.comparisonSets.comparisons.find(c => c.comparisonId === selectedCompareId);

    api.updateComparison({ projectId: this.props.projectId, comparison, comparedDocuments: selectedItems }).then(() => {
      this.props.loadComparisonSets({ projectId: this.props.projectId, pageNum });
    });
  };

  onDeleteClick = () => {
    this.setState({ isDeleteModalShown: true });
  };

  cancelDeleteModal = () => {
    this.setState({ isDeleteModalShown: false });
  };

  submitDeleteModal = () => {
    const { comparisonSets, compareType } = this.props;
    const pageNum = comparisonSets.pageInfo.pageNumber;

    this.setState({ isDeleteModalShown: false, selectedCompares: new Set() });

    Promise.all(
      Array.from(this.state.selectedCompares).map(compareId =>
        api.deleteComparisonSet({ projectId: this.props.projectId, compareId, compareType })
      )
    ).then(() => this.props.loadComparisonSets({ projectId: this.props.projectId, pageNum, compareType }));
  };

  cancelExportModal = () => {
    this.setState({ isExportModalShown: false });
  };

  submitExportModal = () => {
    const { projectId, currentProject } = this.props;

    const currentDate = moment();

    this.setState({ isExportProcessing: true });

    api
      .createExport({
        projectId,
        exportOptions: {
          exportType: 2,
          exportFileName: `${currentProject.projectName}_comparisons_${dateUtil.formatTimestamp(currentDate)}`,
          comparisons: this.getSelectedCompletedComparisons().map(comparison => ({
            comparisonId: comparison.comparisonId
          }))
        }
      })
      .then(() => {
        this.setState({
          isExportModalShown: false,
          isExportSidebarShown: true,
          isExportProcessing: false
        });
      })
      .catch(() => {
        this.setState({
          isExportModalShown: false,
          isExportProcessing: false
        });
      });
  };

  onExportClick = () => {
    const { selectedCompares } = this.state;
    if (selectedCompares.size) {
      this.setState({ isExportModalShown: true });
    } else {
      this.setState({ isExportSidebarShown: true });
    }
  };

  closeExportSidebar = () => {
    this.setState({ isExportSidebarShown: false });
  };

  onSelectedCompareChange = compareSet => {
    const id = compareSet.comparisonId;

    if (this.state.selectedCompares.has(id)) {
      this.state.selectedCompares.delete(id);
    } else {
      this.state.selectedCompares.add(id);
    }

    this.setState({ selectedCompares: this.state.selectedCompares });
  };

  onCheckAllChange = () => {
    if (this.state.selectedCompares.size === this.props.comparisonSets.comparisons.length) {
      this.setState({ selectedCompares: new Set() });
    } else {
      const allIds = this.props.comparisonSets.comparisons.map(c => c.comparisonId);
      this.setState({ selectedCompares: new Set(allIds) });
    }
  };

  goToPage = pageNum => {
    const { loadComparisonSets, projectId, compareType } = this.props;
    loadComparisonSets({ projectId, pageNum, compareType: compareType }).then(() => {
      this.setState({ selectedCompares: new Set() });
    });
  };

  getSelectedCompletedComparisons = () => {
    const { comparisonSets } = this.props;
    const { selectedCompares } = this.state;

    if (!comparisonSets.comparisons) {
      return [];
    }

    return comparisonSets.comparisons.filter(
      comparison =>
        comparison.comparisonStateId === COMPARISON_STATUSES.COMPLETED && selectedCompares.has(comparison.comparisonId)
    );
  };

  doesSelectedSetContainError = () => {
    const { comparisonSets } = this.props;
    const { selectedCompares } = this.state;

    if (!comparisonSets || selectedCompares.size === 0) return false;

    return comparisonSets.comparisons
      .filter(comparison => selectedCompares.has(comparison.comparisonId))
      .some(comparison => comparison.comparisonStateId === COMPARISON_STATUSES.ERROR);
  };

  onDocumentTabClick = () => {
    const { loadComparisonSets, projectId, setCompareType } = this.props;
    const resetFilter = {
      key: undefined,
      ids: undefined,
      tagOptions: []
    };
    setCompareType(COMPARE_TABS.DOCUMENTS);
    this.setState({ isLoading: true });
    loadComparisonSets({
      projectId,
      pageNum: 1,
      compareType: COMPARE_TABS.DOCUMENTS
    }).then(response =>
      this.setState({ isLoading: false, selectedCompares: new Set(), isFiltering: false, filtering: resetFilter })
    );
  };

  onExtractionFieldTabClick = () => {
    const { loadComparisonSets, projectId, setCompareType } = this.props;
    const resetFilter = {
      key: undefined,
      ids: undefined,
      tagOptions: []
    };
    setCompareType(COMPARE_TABS.EXTRACTION_FIELDS);
    this.setState({ isLoading: true });
    loadComparisonSets({
      projectId,
      pageNum: 1,
      compareType: COMPARE_TABS.EXTRACTION_FIELDS
    }).then(response =>
      this.setState({ isLoading: false, selectedCompares: new Set(), isFiltering: false, filtering: resetFilter })
    );
  };

  render() {
    const { compareType, comparisonSets, currentProject, projectId } = this.props;
    const {
      filtering,
      isAddToCompareSidebarShown,
      isCreateCompareSidebarShown,
      isDeleteModalShown,
      isExportModalShown,
      isExportProcessing,
      isExportSidebarShown,
      isFiltering,
      isLoading,
      search,
      selectedCompares,
      sorting
    } = this.state;
    const readOnly = currentProject.STATES.ReadOnly();

    let allSelectedStatus;

    if (comparisonSets.comparisons) {
      if (comparisonSets.length !== 0 && comparisonSets.comparisons.length === selectedCompares.size) {
        allSelectedStatus = 'checked';
      } else if (selectedCompares.size === 0) {
        allSelectedStatus = 'unchecked';
      } else {
        allSelectedStatus = 'indeterminate';
      }
    }
    const errorSelected = this.doesSelectedSetContainError();

    if (!comparisonSets.comparisons || !currentProject.isLoaded) {
      return (
        <div className="compare-browser">
          <div className="compare-browser__empty-state">
            <Icon className="spinner spinner--centered" name="loader" width={80} />
          </div>
        </div>
      );
    }

    return (
      <div className="compare-browser">
        <div className="compare-browser__header">
          <div className="compare-browser__header-title">
            <h2>
              <FormattedMessage id="compare-browser.toolbar.title" />
            </h2>
            <div className="compare-browser__header-subtitle">{currentProject.projectName}</div>
          </div>

          <div className="compare-browser__icon-button-container">
            {Permissions.Project.Comparison.canEdit() &&
            compareType === COMPARE_TABS.DOCUMENTS &&
            !readOnly &&
            selectedCompares.size === 1 &&
            !errorSelected ? (
              <Button
                size="icon"
                className="btn toolbar__icon-button icon-button"
                onClick={this.onAddToCompareClick}
                data-tip
                data-for="compare-browser.toolbar.add.tooltip"
              >
                <Icon className="toolbar__inactive-icon" name="plus" width={20} />
                <Icon className="toolbar__active-icon" name="plus" width={20} />
                <ReactTooltip id="compare-browser.toolbar.add.tooltip" effect="solid" place="bottom">
                  <FormattedMessage id="compare-browser.toolbar.add.tooltip" />
                </ReactTooltip>
              </Button>
            ) : null}

            {Permissions.Project.Comparison.canExport() && compareType === COMPARE_TABS.DOCUMENTS ? (
              <Button
                size="icon"
                className="btn toolbar__icon-button icon-button"
                onClick={this.onExportClick}
                data-tip
                data-for="compare-browser.toolbar.export.tooltip"
              >
                <Icon className="toolbar__inactive-icon" name="export" width={20} />
                <Icon className="toolbar__active-icon" name="export" width={20} />
                <ReactTooltip id="compare-browser.toolbar.export.tooltip" effect="solid" place="bottom">
                  <FormattedMessage id="compare-browser.toolbar.export.tooltip" />
                </ReactTooltip>
              </Button>
            ) : null}

            {Permissions.Project.Comparison.canDelete() && !readOnly && selectedCompares.size > 0 ? (
              <Button
                size="icon"
                className="btn toolbar__icon-button icon-button critical-icon"
                onClick={this.onDeleteClick}
                data-tip
                data-for="compare-browser.toolbar.delete.tooltip"
              >
                <Icon className="toolbar__inactive-icon" name="bin" width={20} />
                <Icon className="toolbar__active-icon" name="bin" width={20} />
                <ReactTooltip id="compare-browser.toolbar.delete.tooltip" effect="solid" place="bottom">
                  <FormattedMessage id="compare-browser.toolbar.delete.tooltip" />
                </ReactTooltip>
              </Button>
            ) : null}
          </div>

          {Permissions.Project.Comparison.canCreate() && !readOnly && compareType === COMPARE_TABS.DOCUMENTS ? (
            <div className="compare-browser__toolbar-button-container">
              <Button size="large" onClick={this.onNewCompareClick} className="btn btn-secondary">
                <FormattedMessage id="compare-browser.toolbar.new-comparison-button" />
              </Button>
            </div>
          ) : null}
        </div>
        <div className="compare-browser__content">
          <div className="compare-browser__tabs">
            <Button
              className={classNames(
                'compare-browser__tab-button',
                compareType === COMPARE_TABS.DOCUMENTS && 'compare-browser__tab-button--selected'
              )}
              onClick={this.onDocumentTabClick}
            >
              <FormattedMessage id="compare-browser.tabs.document-comparisons" />
            </Button>
            <Button
              className={classNames(
                'compare-browser__tab-button',
                compareType === COMPARE_TABS.EXTRACTION_FIELDS && 'compare-browser__tab-button--selected'
              )}
              onClick={this.onExtractionFieldTabClick}
            >
              <FormattedMessage id="compare-browser.tabs.extraction-fields-comparisons" />
            </Button>
          </div>
          {!isLoading && comparisonSets.comparisons && comparisonSets.comparisons.length === 0 && !isFiltering ? (
            <div className="compare-browser__empty-state">
              <FormattedMessage
                id={
                  compareType === COMPARE_TABS.DOCUMENTS
                    ? 'compare-browser.document-empty-state.description'
                    : 'compare-browser.extraction-field-empty-state.description'
                }
              >
                {description => (
                  <EmptyState
                    title={<FormattedMessage id="compare-sidebar.empty.title" />}
                    description={description}
                    img={emptyStateImage}
                  />
                )}
              </FormattedMessage>
            </div>
          ) : (
            <div className="compare-browser__table">
              {isLoading ? (
                <div className="compare-browser__loading">
                  <Icon className="spinner spinner--centered" name="loader" width={80} />
                </div>
              ) : (
                <table className="compare-browser__compare-table">
                  <thead>
                    <tr>
                      <th>
                        <Checkbox
                          checked={allSelectedStatus}
                          className="file-list__checkbox"
                          id="check-all"
                          onChange={this.onCheckAllChange}
                        />
                      </th>
                      <th>
                        {compareType === COMPARE_TABS.DOCUMENTS && (
                          <FormattedMessage id="compare-browser.table-heading.title" />
                        )}
                        {compareType === COMPARE_TABS.EXTRACTION_FIELDS && (
                          <FormattedMessage id="compare-browser.table-heading.extraction-field-title" />
                        )}

                        <Sorting
                          searchFunction={query => this.setSearch('name', query)}
                          searchQuery={search.key === 'name' ? search.query : undefined}
                          sortFunction={ascending => this.setSorting('name', ascending)}
                          sortDirection={sorting.key === 'name' ? sorting.ascending : undefined}
                        />
                      </th>
                      <th>
                        <FormattedMessage id="compare-browser.table-heading.base-documents" />
                        <Sorting
                          sortFunction={ascending => this.setSorting('basename', ascending)}
                          sortDirection={sorting.key === 'basename' ? sorting.ascending : undefined}
                          filterFunction={ids => this.setFiltering('basename', ids)}
                          getFilterOptions={() => {
                            return api.getComparisonSetFilterBaseDocumentOptions({
                              projectId: projectId,
                              compareType: compareType
                            });
                          }}
                          filters={filtering.key === 'basename' ? filtering.ids : undefined}
                        />
                      </th>
                      <th>
                        <span className="compare-browser__status-dot compare-browser__status-header" />{' '}
                        <FormattedMessage id="compare-browser.table-heading.status" />
                      </th>
                      <th>
                        <FormattedMessage id="compare-browser.table-heading.creation-date" />
                      </th>
                      <th className="compare-browser__right-align-cell">
                        {compareType === COMPARE_TABS.DOCUMENTS && (
                          <FormattedMessage id="compare-browser.table-heading.number-of-documents" />
                        )}
                        {compareType === COMPARE_TABS.EXTRACTION_FIELDS && (
                          <FormattedMessage id="compare-browser.table-heading.number-of-extraction-fields" />
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {comparisonSets.comparisons.map((comp, index) => {
                      return (
                        <CompareItem
                          isSelected={selectedCompares.has(comp.comparisonId)}
                          comparisonSet={comp}
                          key={index}
                          id={comp.comparisonId}
                          onSelectedChange={this.onSelectedCompareChange.bind(this, comp)}
                          projectId={projectId}
                        />
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          )}
          {!isLoading && !!comparisonSets.pageInfo && (
            <div className="compare-browser__pagination">
              <Pagination
                onPageChange={pageNum => this.goToPage(pageNum)}
                pagination={{
                  pageNumber: comparisonSets.pageInfo.pageNumber,
                  pageSize: comparisonSets.pageInfo.pageSize,
                  totalPageCount: comparisonSets.pageInfo.totalPageCount,
                  totalRecordCount: comparisonSets.pageInfo.totalRecordCount
                }}
              />
            </div>
          )}
        </div>

        {isDeleteModalShown && (
          <Modal>
            <DeleteModal onCancel={this.cancelDeleteModal} onSubmit={this.submitDeleteModal} />
          </Modal>
        )}
        {isExportModalShown && (
          <ExportCompareModal
            onCancel={this.cancelExportModal}
            onSubmit={this.submitExportModal}
            disableSubmit={isExportProcessing || !this.getSelectedCompletedComparisons().length}
          />
        )}
        {isCreateCompareSidebarShown && (
          <CreateCompareSidebarContainer
            projectId={projectId}
            onClose={this.onCloseNewCompareClick}
            onSubmit={this.onSubmitNewCompareClick}
          />
        )}
        {isAddToCompareSidebarShown && (
          <AddToCompareSidebarContainer
            projectId={projectId}
            onClose={this.onCloseAddToCompareClick}
            onSubmit={this.onSubmitAddToCompareClick}
          />
        )}
        {isExportSidebarShown && (
          <ExportSidebarContainer
            onCloseClick={this.closeExportSidebar}
            projectId={projectId}
            exportType="Comparison"
          />
        )}
      </div>
    );
  }
}
