import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/*
 Generic component for rendering a list of items in a cell, such as tags or reviwers

 If there are is than one item the additional items are hidden and a 'plus' buttons is used
 to reveal the additional items
*/
export default class ItemList extends Component {
  static propTypes = {
    expand: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired
  };

  render() {
    let { items, name, isExpanded, expand } = this.props;

    if (!items.length) {
      return '-';
    }

    return (
      <div className="file-list__item-list">
        {isExpanded ? (
          <div>
            {items.map((item, i) => (
              <div className="file-list__multi-item" key={i}>
                {item}
              </div>
            ))}
          </div>
        ) : (
          <div className="file-list__item">{items[0]}</div>
        )}
        {items.length > 1 &&
          !isExpanded && (
            <ReactTooltip id={name} effect="solid" place="bottom">
              {items.slice(1).map(item => <div key={item}>{item}</div>)}
            </ReactTooltip>
          )}
        {items.length > 1 &&
          !isExpanded && (
            <div data-tip data-for={name} className="file-list__more-items-indicator" onClick={expand}>
              +{items.length - 1}
            </div>
          )}
      </div>
    );
  }
}
