import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as api from 'store/api';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Button from 'components/shared/single-click-button';
import 'react-dropdown/style.css';
import DateTimePicker from 'react-datetime-picker';

function EditMaintenanceMessagePopup({
  isEditWindowOpen,
  closeMaintenanceEditPopup,
  messageId,
  maintenanceMessages,
  loadMessages
}) {
  const [loading, changeLoadingStatus] = useState(true);
  const [selectedTimezone, onTimeZoneChange] = useState();
  const [timeZones, setTimezone] = useState([]);
  const [selectedRegion, onRegionChange] = useState();
  const [selectedTimezoneId, onTimeZoneIdChange] = useState('');
  const [selectedTimezoneName, onTimeZoneNameChange] = useState('');
  const [selectedTimezoneOffset, onTimezoneOffSet] = useState('');
  const [maintenanceMessage, maintenanceMessageText] = useState();
  const [startDate, startDateOnChange] = useState();
  const [endDate, endDateOnChange] = useState();
  const [disableButton, disableButtonFunc] = useState(false);
  const [dateValidation, dateValidationFailed] = useState(false);
  const [endDateValidation, endDateValidationFailed] = useState(false);
  let isButtonDisabled = true;
  let selectedTimezoneObj = {};
  let timezoneId;
  let timezoneName;
  let timezoneOffset;
  let userSelectedTimezoneOffset;
  let region;
  let maintenanceMessageEntered;
  let datevalidationCheck;

  useEffect(() => {
    loadTimezones();
  }, []);

  const loadTimezones = () => {
    localStorage.removeItem('region');
    localStorage.removeItem('timezoneId');
    localStorage.removeItem('message');
    localStorage.removeItem('startDateValidationCheck');
    localStorage.removeItem('dateValidationCheck');
    localStorage.removeItem('endDateValidationCheck');
    api
      .getTimeZones()
      .then(res => {
        changeLoadingStatus(false);
        setTimezone(res.data);
      })
      .catch(() => {
        changeLoadingStatus(false);
        setTimezone([]);
      });
  };

  const options = [
    { value: 'AME', label: 'AME' },
    { value: 'APA', label: 'APA' },
    { value: 'EMA', label: 'EMA' }
  ];

  let GeoCode = '';
  let MessageText = '';
  let StartDate = '';
  let EndDate = '';
  let TimeZone = {};
  let Timezoneobj = {};
  let selectedTimezoneobj = {
    Id: selectedTimezoneId,
    Name: selectedTimezoneName,
    OffsetValue: selectedTimezoneOffset
  };

  for (let i = 0; i < maintenanceMessages.length; i++) {
    if (maintenanceMessages[i].messageId == messageId) {
      GeoCode = maintenanceMessages[i].geoCode;
      MessageText = maintenanceMessages[i].messageText;
      StartDate = new Date(maintenanceMessages[i].startDate);
      EndDate = new Date(maintenanceMessages[i].endDate);
      TimeZone = maintenanceMessages[i].timeZone.id;
      Timezoneobj = maintenanceMessages[i].timeZone;
    
    }
  }
  let maintenanceObj = {
    MessageId:messageId,
    GeoCode: selectedRegion ? selectedRegion : GeoCode,
    MessageText: maintenanceMessage ? maintenanceMessage : MessageText,
    UTCStartDate: startDate != null && startDate!= "" ? new Date(startDate) :  new Date(StartDate),
    UTCEndDate: endDate != "" && endDate != null ? new Date(endDate) : new Date(EndDate),
    StartDate: startDate != null && startDate!= ""  ? new Date(startDate).toString() : new Date(StartDate).toString(),
    EndDate: endDate != "" && endDate != null ? new Date(endDate).toString() : new Date(EndDate).toString(), 
    TimeZone: selectedTimezoneobj.Id != "" && selectedTimezoneobj.Name != "" && selectedTimezoneobj.OffsetValue != ""  ? selectedTimezoneobj : Timezoneobj
  };

  function startDateOnChangeEvent(e) {
    startDateOnChange(e);
    handleValidationInAddClick();
    if(!validateDateTime(e)){
      localStorage.setItem('startDateValidationCheck',true);
      return disableButtonFunc(true);
    } else {
      localStorage.removeItem('startDateValidationCheck');
      disableButtonFunc(false);
    }
    
    if (e >= maintenanceObj.UTCEndDate) {
      dateValidationFailed(true);
      datevalidationCheck = true;
      disableButtonFunc(true);
      localStorage.setItem('dateValidationCheck',true);
    } else {
      dateValidationFailed(false);
      datevalidationCheck = false;
      disableButtonFunc(false);
      localStorage.removeItem('dateValidationCheck');
      
    }
 }

  function endDateOnChangeEvent(e) {
    endDateOnChange(e);
    handleValidationInAddClick();
    if(!validateDateTime(e)){
      localStorage.setItem('endDateValidationCheck',true);
      return disableButtonFunc(true);
    } else {
      disableButtonFunc(false);
      localStorage.removeItem('endDateValidationCheck');
    }

    if (e < new Date()) {
      endDateValidationFailed(true);
      localStorage.setItem('endDateValidationCheck',true);
      disableButtonFunc(true);
    } else {
      localStorage.removeItem('endDateValidationCheck');
      endDateValidationFailed(false);
      disableButtonFunc(false);
    }

    if (e <= maintenanceObj.UTCStartDate) {
      dateValidationFailed(true);
      datevalidationCheck = true;
      disableButtonFunc(true);
      localStorage.setItem('dateValidationCheck',true);
    } else {
      dateValidationFailed(false);
      datevalidationCheck = false;
      disableButtonFunc(false);
      localStorage.removeItem('dateValidationCheck');
    }
  }

  function validateDateTime(input) {
    let isValid = false;
    let inputMomentObj = new Date(input);
    let validStartDate = new Date('01/01/1999 00:00:00');
    let validEndDate = new Date('01/01/2999 00:00:00')
    if(validStartDate < inputMomentObj && validEndDate > inputMomentObj)
      isValid = true;
      
    return isValid;
  }

  function onRegionDropdownChange(e) {
    onRegionChange(e);
    region = e;
    localStorage.setItem('region', e);
  }

  function maintenanceMessageTextOnChange(e) {
    maintenanceMessageText(e);
    maintenanceMessageEntered = e;
    localStorage.setItem('message', e);
    handleValidationInAddClick();
  }
  function onTimeZoneOnChangeEvent(e) {
    selectedTimezoneObj = timeZones.filter(a => a.id === e.Id);
    timezoneId = selectedTimezoneObj[0].id;
    timezoneName = selectedTimezoneObj[0].name;
    timezoneOffset = selectedTimezoneObj[0].offsetValue;
    onTimeZoneIdChange(timezoneId);
    onTimeZoneNameChange(timezoneName);
    onTimezoneOffSet(timezoneOffset);
    localStorage.setItem('timezoneId', timezoneId);
  }

  function handleValidationInAddClick() {

    let message = localStorage.getItem('message');
    let dateValidationCheck = localStorage.getItem('dateValidationCheck');
    let endDateValidationCheck = localStorage.getItem('endDateValidationCheck');
    let startDateValidationCheck = localStorage.getItem('startDateValidationCheck');

    if (        
     (message && (message !== null &&
      message !== '')) &&
      startDate !== null &&
      endDate !== null &&
      dateValidationCheck == null &&
      endDateValidationCheck == null &&
      startDateValidationCheck == null
    ) {
      disableButtonFunc(false);
    } else {
      disableButtonFunc(true);
    }
  }


  let MessageID = Number(messageId);
  function onSubmit(event) {
    if(maintenanceObj.GeoCode == null && maintenanceObj.TimeZone && maintenanceObj.MessageText == null)
      return disableButtonFunc(true);

    if (maintenanceObj.UTCStartDate < maintenanceObj.UTCEndDate) {
      localStorage.removeItem('region');
      localStorage.removeItem('timezoneId');
      localStorage.removeItem('message');
      api
        .updateMaintenanceMessages(MessageID, maintenanceObj)
        .then(res => {
          changeLoadingStatus(true);
          closeMaintenanceEditPopup();
          loadMessages();
        })
        .catch(() => {
          changeLoadingStatus(true);
          closeMaintenanceEditPopup();
          loadMessages();
        });
    } else {
      dateValidationFailed(true);
    }
  
  }

  return isEditWindowOpen ? (
    <Modal>
      <div className="modal__content edit-maintenance-messagese-dialog">
        <div className="edit-maintenance-messagese-dialog__header">
          <h4>
            <FormattedMessage id="maintenance-message.edit-message" />
       
          </h4>
          <Button type="button" size="icon" className="modal__close_x icon-button" onClick={closeMaintenanceEditPopup}>
            <Icon name="special-cross-black" width={18} height={18} />
          </Button>
        </div>
        <div className="edit-maintenance-messagese-dialog__content">
          <div className="edit-maintenance-messagese-dialog__region-section">
            <div>
              <span className="edit-maintenance-messagese-dialog__required-field">*</span>
              <FormattedMessage id="maintenance-message.region" />
            </div>
            <div className="edit-maintenance-messagese-dialog__template-type-drop-down">
              <select
                className="edit-maintenance-messagese-dialog__template-type-dropdown"
                onChange={e => {
                  onRegionDropdownChange(e.target.value);
                }}
              >
                <option value="" disabled>
                  Select
                </option>

                {options.map(item => (
                  <option key={item.value} value={item.label} selected={item.value === GeoCode ? true : false}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />

          <div className="edit-maintenance-messagese-dialog__message-section">
            <div>
              <span className="edit-maintenance-messagese-dialog__required-field">*</span>
              <FormattedMessage id="maintenance-message.Message-text" />
            </div>
            <div>
              <input
                className="edit-maintenance-messagese-dialog__message-tex-box"
                placeholder="Type message"
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                onChange={e => {
                  maintenanceMessageTextOnChange(e.target.value);
                }}
                type="text"
                defaultValue={MessageText}
                maxLength="250"
              ></input>
            </div>
          </div>
          <br />
          <div className="edit-maintenance-messagese-dialog__date-section">
            <div className="edit-maintenance-messagese-dialog__start-date">
              <div className="edit-maintenance-messagese-dialog__start-date-lable">
                <span className="edit-maintenance-messagese-dialog__required-field">*</span>
                <FormattedMessage id="maintenance-message.Start-date" />
              </div>

              {dateValidation ? (
                <div className="edit-maintenance-messagese-dialog__date-picker-error">
                  <DateTimePicker
                    minDate={new Date()}
                    closeWidgets={true}
                    onChange={startDateOnChangeEvent}
                    value={maintenanceObj.UTCStartDate}
                  />
                </div>
              ) : (
                <div className="edit-maintenance-messagese-dialog__date-picker">
                  <DateTimePicker
                    minDate={new Date()}
                    closeWidgets={true}
                    onChange={startDateOnChangeEvent}
                    value={maintenanceObj.UTCStartDate}
                  />
                </div>
              )}
            </div>
            <div className="edit-maintenance-messagese-dialog__end-date">
              <div className="edit-maintenance-messagese-dialog__start-date-lable">
                <span className="edit-maintenance-messagese-dialog__required-field">*</span>
                <FormattedMessage id="maintenance-message.End-date" />
              </div>

              {dateValidation || endDateValidation ? (
                <div className="create-maintenance-messagese-dialog__date-picker-error">
                  <DateTimePicker minDate={new Date()} onChange={endDateOnChangeEvent} value={maintenanceObj.UTCEndDate} />
                </div>
              ) : (
                <div className="edit-maintenance-messagese-dialog__date-picker">
                  <DateTimePicker minDate={new Date()} onChange={endDateOnChangeEvent} value={maintenanceObj.UTCEndDate} />{' '}
                </div>
              )}
            </div>
          </div>

          <div className="create-maintenance-messagese-dialog__error-validation-div">
            {dateValidation && (
              <div className="create-maintenance-messagese-dialog__error-validation">
                <FormattedMessage id="maintenance-message.Datevalidation" />
              </div>
            )}
            {endDateValidation && (
              <div className="create-maintenance-messagese-dialog__error-validation">
                <FormattedMessage id="maintenance-message.EndDatevalidation" />
              </div>
            )}
          </div>
          <div className="edit-maintenance-messagese-dialog__timezone-section">
            <div>
              <span className="edit-maintenance-messagese-dialog__required-field">*</span>
              <FormattedMessage id="maintenance-message.Timezone" />
            </div>
            <div>
              <select
                className="edit-maintenance-messagese-dialog__template-type-dropdown"
                placeholder="Select"
                onChange={e => onTimeZoneOnChangeEvent({ Id: e.target.value, Name: '' })}
              >
                <option value="" disabled>
                  Select
                </option>
                {timeZones?.map(item => (
                  <option key={item.id} value={item.id} selected={item.id === TimeZone ? true : false}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div></div>
          </div>
        </div>

        <div className="edit-maintenance-messagese-dialog__footer">
          <Button className="btn btn-secondary" onClick={closeMaintenanceEditPopup}>
            <FormattedMessage id="global.header.format-cancel" />
          </Button>
          <Button onClick={onSubmit} disabled={disableButton} className="btn btn-primary">
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
}

export default EditMaintenanceMessagePopup;
