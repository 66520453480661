import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { hasError, ERROR_CODES } from 'utils/errors';

import Permissions from 'permissions/permissions';

import {
  addExtractionFieldToGroup,
  addUserToExtractionField,
  createExtractionField,
  createExtractionFieldExport,
  deleteExtractionField,
  getAllExtractionFields,
  getCurrentRegion,
  getExtractionFieldDetails,
  getExtractionFieldsForGroup,
  trainExtractionFields
} from 'store/api';

import Breadcrumbs from 'components/shared/breadcrumbs';
import constants from 'utils/constants';
import ExtractionFieldDetails from 'components/quick-study/details/extraction-field-details';
import ExtractionFieldList from 'components/quick-study/extraction-field-browser/extraction-field-list';
import Header from 'containers/header/header';
import Icon from 'components/shared/icon';
import setUtil from 'utils/setUtil';
import socket, { rooms } from 'utils/socket';
import Toolbar, { TOOLBAR_OPTIONS } from 'components/quick-study/toolbar/toolbar';

const { ExtractionAccessTypeIds } = constants;

const DEFAULT_TOOLBAR_OPTIONS = [TOOLBAR_OPTIONS.EXPORT, TOOLBAR_OPTIONS.IMPORT, TOOLBAR_OPTIONS.CREATE];

const EXPANDED_TOOLBAR_OPTIONS = [
  TOOLBAR_OPTIONS.SHARE,
  TOOLBAR_OPTIONS.GROUP,
  TOOLBAR_OPTIONS.LEARN,
  TOOLBAR_OPTIONS.DELETE,
  TOOLBAR_OPTIONS.EXPORT,
  TOOLBAR_OPTIONS.IMPORT,
  TOOLBAR_OPTIONS.CREATE
];

const LIMITED_TOOLBAR_OPTIONS = [
  TOOLBAR_OPTIONS.SHARE,
  TOOLBAR_OPTIONS.GROUP,
  TOOLBAR_OPTIONS.LEARN,
  TOOLBAR_OPTIONS.EXPORT,
  TOOLBAR_OPTIONS.IMPORT,
  TOOLBAR_OPTIONS.CREATE
];

/**
 * Extraction Field Browser
 * Handles data fetching and state updates for extraction field data
 * Wraps the ExtractionFieldList and Toolbar Components
 */
export class ExtractionFieldBrowser extends Component {
  static propTypes = {
    condensed: PropTypes.bool,
    currentUser: PropTypes.object.isRequired,
    extractionFieldId: PropTypes.string,
    groupId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    search: PropTypes.object,
    searchView: PropTypes.bool,
    submitImport: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func
  };

  state = {
    fields: null,
    groupName: null,
    pagination: null,
    requestedPage: 1,
    searchOptions: null,
    searchQuery: null,
    selectedFields: new Set(),
    abbyFields: new Set(),
    loadingSerach: false
  };

  constructor(props) {
    super(props);

    this.loadExtractionFields = this.loadExtractionFields.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.props;

    socket.on('extractionfield.history.update', this.onHistoryUpdated);
    socket.on('extractionfield.training.revert', this.onTrainingReverted);
    socket.on('extractionfield.updated', this.onFieldUpdated);
    socket.on('extractionfield.removed', this.onFieldDeleted);
    socket.on('extractionfield.import', this.onFieldsImported);
    socket.on('extractionfield.publish', this.onFieldsPublished);

    socket.join(rooms.extractionFields());
    socket.join(rooms.extractionFieldImports(currentUser.userId));

    if (this.props.searchView) {
      return this.getAllExtractionFields({
        pageNum: 1,
        filterBy: 'name',
        filterString: this.props.search.query
      });
    }
    this.componentDidUpdate({}, {});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.groupId !== this.props.groupId) {
      // Either the groupId has changed, or a new page of data is requested
      this.loadExtractionFields();
    } else if (prevState.requestedPage !== this.state.requestedPage) {
      this.loadExtractionFields({ pageNum: this.state.requestedPage });
    }

    if (!prevProps.extractionFieldId && this.props.extractionFieldId) {
      // If viewing extraction field details, clear the selected fields.
      this.setState({ selectedFields: new Set() });
    }

    // If the list of fields changes, ensure that there are no orpans in the set of selected fields
    if (!prevState.fields !== this.state.fields) {
      const allFieldIds = new Set((this.state.fields || []).map(field => field.extractionFieldId));
      const interection = new Set(setUtil.intersect(allFieldIds, this.state.selectedFields));
      if (interection.size !== this.state.selectedFields.size) {
        this.setState({
          selectedFields: interection
        });
      }
    }
  }

  componentWillUnmount() {
    const { currentUser } = this.props;
    socket.off('extractionfield.history.update', this.onHistoryUpdated);
    socket.off('extractionfield.training.revert', this.onTrainingReverted);
    socket.off('extractionfield.updated', this.onFieldUpdated);
    socket.off('extractionfield.removed', this.onFieldDeleted);
    socket.off('extractionfield.import', this.onFieldsImported);
    socket.off('extractionfield.publish', this.onFieldsPublished);

    socket.leave(rooms.extractionFields());
    socket.leave(rooms.extractionFieldImports(currentUser.userId));
  }

  /**
   * Fetch the data for a given page of the extraction field list from the API
   */
  loadExtractionFields = options => {
    const { history } = this.props;
    const params = this.getParams(options);
    // The endpoint is different if requesting all extraction fields
    (this.props.groupId === 'all'
      ? this.getAllExtractionFields(params)
      : this.getExtractionFieldsForGroup(params)
    ).catch(error => {
      if (hasError(error, ERROR_CODES.XFGROUP_NOTFOUND)) {
        this.setState({ loadingSerach: false });
        history.push(`/region/${getCurrentRegion()}/groups`);
      }
    });
  };

  /**
   * Get the parameters for the api request, such as search filters and options
   */
  getParams(options) {
    const { requestedPage, searchOptions, searchQuery } = this.state;
    const page = { pageNum: requestedPage };
    let params = page;

    if (searchOptions) {
      params = Object.assign(params, searchOptions);
    }

    if (searchQuery) {
      params = Object.assign(params, searchQuery);
    }

    if (options) {
      params = Object.assign(params, options);
    }

    return params;
  }

  /**
   * Fetch the data for a given page of the list of all extraction fields
   */
  getAllExtractionFields = options => {
    return getAllExtractionFields(options).then(response => {
      this.setState({
        pagination: response.pageInfo,
        groupName: <FormattedMessage id="quick-study.group-list.all-extraction-fields" />,
        fields: response.data,
        loadingSerach: false
      });
    });
  };

  /**
   * Fetch the data for a given page of the list of extraction fields for a group
   */
  getExtractionFieldsForGroup = options => {
    const { groupId } = this.props;

    return getExtractionFieldsForGroup(groupId, options).then(response => {
      this.setState({
        pagination: response.pageInfo,
        groupName: response.data.extractionFieldGroupName,
        fields: response.data.extractionFields,
        loadingSerach: false
      });
    });
  };

  /**
   * Handle a page change
   */
  onPageChange = requestedPage => {
    this.setState({
      requestedPage
    });
  };

  /**
   * Handle a row of the extraction fields list being selected or deselected
   */
  onSelectedFieldChange = field => {
    const { selectedFields, abbyFields } = this.state;
    const id = field.extractionFieldId;

    if (selectedFields.has(id)) {
      selectedFields.delete(id);
      if (field.externalSystemId === constants.ExternalSystemIds.ABBY) {
        abbyFields.delete(id);
      }
    } else {
      selectedFields.add(id);
      if (field.externalSystemId === constants.ExternalSystemIds.ABBY) {
        abbyFields.add(id);
      }
    }

    this.setState({ selectedFields: selectedFields, abbyFields: abbyFields });
  };

  /**
   * Handle all rows of the extraction fields list being selected or deselected
   */
  onCheckAllChange = () => {
    const { fields, selectedFields } = this.state;

    if (selectedFields.size === fields.length) {
      this.setState({ selectedFields: new Set(), abbyFields: new Set() });
    } else {
      const allIds = fields.map(field => field.extractionFieldId);
      const abbyFieldsIds = fields
        .filter(({ externalSystemId }) => externalSystemId === constants.ExternalSystemIds.ABBY)
        .map(({ extractionFieldId }) => extractionFieldId);
      this.setState({ selectedFields: new Set(allIds), abbyFields: new Set(abbyFieldsIds) });
    }
  };

  /**
   * Create a new extraction field then add that field to the current group
   */
  createExtractionField = ({ name, description }) => {
    const { groupId } = this.props;
    createExtractionField(name, description)
      .then(response => {
        if (groupId !== 'all') {
          return addExtractionFieldToGroup({
            extractionFieldId: response.data.extractionFieldId,
            groupId: groupId
          });
        }
      })
      .then(() => this.loadExtractionFields({ pageNum: this.state.requestedPage }));
  };

  /**
   * Add the selected fields to a group
   */
  addFieldsToGroup = group => {
    const { fields, selectedFields } = this.state;

    return Promise.all(
      fields
        .filter(({ extractionFieldId }) => selectedFields.has(extractionFieldId))
        .map(field =>
          addExtractionFieldToGroup({
            extractionFieldId: field.extractionFieldId,
            groupId: group.extractionFieldGroupId
          })
        )
    );
  };

  /**
   * Share the selected fields with a list of users
   */
  addUsersToFields = pendingUsers => {
    return Promise.all(
      this.nonPublicSelectedFields.map(field =>
        Promise.all(
          pendingUsers.map(pendingUser =>
            addUserToExtractionField({
              extractionFieldId: field.extractionFieldId,
              userId: pendingUser.user.userId,
              roleId: pendingUser.role
            })
          )
        )
      )
    );
  };

  /**
   * Delete the selected fields
   */
  deleteExtractionFields = () => {
    return Promise.all(this.nonKiraFields.map(field => deleteExtractionField(field.extractionFieldId))).then(() => {
      this.loadExtractionFields({ pageNum: this.state.requestedPage });
    });
  };

  /**
   * Submit an export request for the selected fields
   */
  submitExport = () => {
    const { fields, selectedFields } = this.state;

    return createExtractionFieldExport(
      fields
        .filter(({ extractionFieldId }) => selectedFields.has(extractionFieldId))
        .map(field => field.extractionFieldId)
    );
  };

  /**
   * Submit an import request with a file
   */
  submitImport = file => {
    const { groupId, submitImport } = this.props;
    return submitImport({
      groupId: groupId === 'all' ? null : groupId,
      file
    });
  };

  /**
   * Submit learning of fields
   */
  submitLearn = () => {
    return trainExtractionFields({
      extractionFieldIds: this.nonKiraFields.map(field => field.extractionFieldId),
      isGroup: false
    });
  };

  /**
   * Respond to a history updated event
   */
  onHistoryUpdated = msg => {
    const extractionFieldId = msg.data.extractionFieldId;

    // Ignore if the event is from a different room
    if (msg.room !== rooms.extractionFields()) {
      return;
    }

    this.getUpdatedFieldData(extractionFieldId);
  };

  /**
   * Respond to a training reverted event
   */
  onTrainingReverted = msg => {
    const extractionFieldId = msg.data.extractionFieldId;

    // Ignore if the event is from a different room
    if (msg.room !== rooms.extractionFields()) {
      return;
    }

    this.getUpdatedFieldData(extractionFieldId);
  };

  /**
   * Get updated data for a specific field (in response to an event)
   */
  getUpdatedFieldData = extractionFieldId => {
    getExtractionFieldDetails(extractionFieldId).then(response => {
      const { fields } = this.state;

      this.setState({
        fields: fields.map(field =>
          field.extractionFieldId === response.data.extractionFieldId
            ? {
                ...field,
                ...response.data
              }
            : field
        )
      });
    });
  };

  /**
   * Respond to a field updated event
   */
  onFieldUpdated = msg => {
    const { fields } = this.state;
    const updatedField = msg.data;

    if (!updatedField) {
      return;
    }

    this.setState({
      fields: fields.map(field =>
        field.extractionFieldId === updatedField.extractionFieldId
          ? {
              ...field,
              // Only update name and description
              extractionFieldName:
                updatedField.extractionFieldName != null ? updatedField.extractionFieldName : field.extractionFieldName,
              extractionFieldDescription:
                updatedField.extractionFieldDescription != null
                  ? updatedField.extractionFieldDescription
                  : field.extractionFieldDescription,
              trainingStateId:
                updatedField.trainingStateId != null ? updatedField.trainingStateId : field.trainingStateId
            }
          : field
      )
    });
  };

  /**
   * Respond to a field deleted event
   */
  onFieldDeleted = response => {
    const { fields } = this.state;
    const deletedFieldId = response.data.extractionFieldId;

    if (!deletedFieldId) {
      return;
    }

    const updatedFields = fields.filter(field => field.extractionFieldId !== deletedFieldId);

    this.setState({
      fields: updatedFields
    });

    if (!updatedFields.length) {
      this.loadExtractionFields({ pageNum: this.state.requestedPage });
    }
  };

  /**
   * Respond to a field imported event
   */
  onFieldsImported = response => {
    const { groupId } = this.props;
    const succeededExtractionFields = response.data.succeededExtractionFields;

    // If there were successful imports and this is the 'all extraction fields list' then reload the list
    if (!succeededExtractionFields.length || groupId !== 'all') {
      return;
    }

    this.loadExtractionFields({ pageNum: this.state.requestedPage });
  };

  /**
   * Respond to a fields published event
   */
  onFieldsPublished = msg => {
    const { groupId } = this.props;

    // If this is the 'all extraction fields list' then reload the list
    if (msg.room !== rooms.extractionFields() || groupId !== 'all') {
      return;
    }

    this.loadExtractionFields({ pageNum: this.state.requestedPage });
  };

  /**
   * Handle a search from the header search bar
   */
  onSearch = options => {
    this.setState({ searchQuery: options, requestedPage: 1, loadingSerach: true });
    return options ? this.loadExtractionFields(options) : this.loadExtractionFields();
  };

  /**
   * Return the list of selected extraction fields that are not public
   */
  get nonPublicSelectedFields() {
    const { fields, selectedFields } = this.state;

    return (fields || []).filter(
      ({ accessTypeId, extractionFieldId }) =>
        selectedFields.has(extractionFieldId) && accessTypeId !== ExtractionAccessTypeIds.public
    );
  }

  /**
   * Return a list of the selected are deletable or learnable fields aka non kira fields
   */
  get nonKiraFields() {
    const { fields, selectedFields } = this.state;

    return (fields || []).filter(field => selectedFields.has(field.extractionFieldId) && !field.isReadOnly);
  }

  get generalSelectedFields() {
    const { fields, selectedFields } = this.state;

    return (fields || []).filter(field => selectedFields.has(field.extractionFieldId));
  }

  checkOption = option => {
    return (
      option !== TOOLBAR_OPTIONS.LEARN &&
      option !== TOOLBAR_OPTIONS.SHARE &&
      option !== TOOLBAR_OPTIONS.GROUP &&
      option !== TOOLBAR_OPTIONS.IMPORT &&
      option !== TOOLBAR_OPTIONS.CREATE
    );
  };

  isAbbyGroup = () => {
    const { fields } = this.state;
    return fields && fields.some(field => field.externalSystemId === constants.ExternalSystemIds.ABBY);
  };

  /**
   * Return the list of visible toolbar options depending on whether the field is read only or not
   */
  getVisibleOptions = () => {
    const { abbyFields } = this.state;
    const isAbbyGroup = this.isAbbyGroup();
    const nonShareLimited = LIMITED_TOOLBAR_OPTIONS.filter(
      option =>
        option !== TOOLBAR_OPTIONS.SHARE &&
        (abbyFields.size > 0 ? this.checkOption(option) : true) &&
        (isAbbyGroup ? option !== TOOLBAR_OPTIONS.IMPORT : true)
    );
    const nonShareExpanded = EXPANDED_TOOLBAR_OPTIONS.filter(
      option =>
        option !== TOOLBAR_OPTIONS.SHARE &&
        (abbyFields.size > 0 ? this.checkOption(option) : true) &&
        (isAbbyGroup ? option !== TOOLBAR_OPTIONS.IMPORT : true)
    );

    if (this.generalSelectedFields.length <= 0) {
      return isAbbyGroup ? DEFAULT_TOOLBAR_OPTIONS.filter(this.checkOption) : DEFAULT_TOOLBAR_OPTIONS;
    }

    // If there are non public selected fields, we won't hide the share button
    if (this.nonPublicSelectedFields.length > 0) {
      if (this.nonPublicSelectedFields.every(field => field.isReadOnly)) {
        return abbyFields.size > 0
          ? LIMITED_TOOLBAR_OPTIONS.filter(this.checkOption)
          : LIMITED_TOOLBAR_OPTIONS.filter(option => (isAbbyGroup ? option !== TOOLBAR_OPTIONS.IMPORT : true));
      }
      return abbyFields.size > 0
        ? EXPANDED_TOOLBAR_OPTIONS.filter(this.checkOption)
        : EXPANDED_TOOLBAR_OPTIONS.filter(option => (isAbbyGroup ? option !== TOOLBAR_OPTIONS.IMPORT : true));
    }

    // If all the selected fields are public, hide the share button
    if (this.generalSelectedFields.every(field => field.isReadOnly)) {
      return nonShareLimited;
    }

    return nonShareExpanded;
  };

  render() {
    const { condensed, currentUser, groupId, extractionFieldId, searchView } = this.props;
    const { fields, selectedFields, groupName, pagination, loadingSerach } = this.state;
    if (!groupName && !searchView) {
      return (
        <div className="quick-study">
          <div
            className={classNames(
              'quick-study-browser',
              'quick-study__browser',
              condensed && 'quick-study__browser--condensed'
            )}
          >
            <Header   {...this.props} fromQuickStudyPage inputDisabled loadExtractionFields={this.loadExtractionFields} />
            <Icon className="spinner" name="loader" width={80} />
          </div>
        </div>
      );
    }

    const breadcrumbs = [
      { name: <FormattedMessage id="quick-study.breadcrumbs.groups" />, link: `/region/${getCurrentRegion()}/groups` },
      { name: groupName ? groupName : 'All Extraction Fields' }
    ];

    const toolbarOptions = this.getVisibleOptions();

    return (
      <Fragment>
        <Header 
          {...this.props}
          fromQuickStudyPage
          currentPage="global.subheader.admin-dashboard" 
          customValue={groupName}
          onEnter={this.onSearch}
          onExit={() => {
            this.setState({ requestedPage: 1, searchOptions: null, searchQuery: null }, () =>
              this.loadExtractionFields({ pageNum: 1 })
            );
          }}
          loadExtractionFields={this.loadExtractionFields}
        />
        <div className="quick-study">
          <Fragment>
            <div
              className={classNames(
                'quick-study-browser',
                'quick-study__browser',
                condensed && 'quick-study__browser--condensed'
              )}
            >
              <div
                className={classNames(
                  'quick-study-browser__header',
                  condensed && 'quick-study-browser__header--condensed'
                )}
              >
                <div className="quick-study-browser__breadcrumbs-container">
                  <h2 className="quick-study-browser__title">
                    <FormattedMessage id="quick-study.toolbar.title" />
                  </h2>
                  



                  {/* {Permissions.Global.ExtractionField.canEdit() && (
                    <div className="quick-study__container-indicator">
                      <FormattedMessage
                        id="quick-study.toolbar.container-indicator"
                        values={{ containerName: currentUser.containerName }}
                      />
                    </div>
                  )} */}
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                </div>
                {!condensed && Permissions.Global.ExtractionField.canEdit() ? (
                  <Toolbar
                    fields={fields}
                    visibleOptions={toolbarOptions}
                    selectedFields={Array.from(selectedFields.values())}
                    createExtractionField={this.createExtractionField}
                    onSubmitGroupPopover={this.addFieldsToGroup}
                    onSubmitSharePopover={this.addUsersToFields}
                    onSubmitDeleteModal={this.deleteExtractionFields}
                    onSubmitExportModal={this.submitExport}
                    onSubmitImportModal={this.submitImport}
                    onSubmitLearn={this.submitLearn}
                  />
                ) : null}

              </div>
              {loadingSerach ? (
                <Icon className="spinner" name="loader" width={80} />
              ) : (
                <div className="quick-study-browser__content">
                  <ExtractionFieldList
                    condensed={condensed}
                    fields={fields ? fields : []}
                    groupId={groupId}
                    onCheckAllChange={this.onCheckAllChange}
                    onPageChange={this.onPageChange}
                    onSelectedFieldChange={this.onSelectedFieldChange}
                    pagination={pagination}
                    selectedFields={selectedFields}
                    isAdmin={Permissions.Global.ExtractionField.canEdit()}
                    getExtractionGroup={options => {
                      this.setState({ searchOptions: options, requestedPage: 1 }, () =>
                        this.loadExtractionFields(options)
                      );
                    }}
                  />
                </div>
              )}
            </div>
            {extractionFieldId && (
              <ExtractionFieldDetails
                groupId={groupId}
                extractionFieldId={extractionFieldId}
                currentUser={currentUser}
                reloadExtractionFields={this.loadExtractionFields}
              />
            )}
          </Fragment>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ExtractionFieldBrowser);
