import model from 'models/base-model';

export class Translations extends model({
  error: null,
  isTranslationsLoading: false,
  translations: [],
  isLanguagePairsLoading: false,
  languagePairs: [],
  isMachineTranslationAvailable: true,
  machineTranslationDownTimeDuration: '',
  machineTranslationDownTimeDurationUnitKey: ''
}) {
  /* LOADERS */
  setTranslationsListLoading() {
    return this.merge({
      isTranslationsLoading: true,
      error: null
    });
  }

  setLanguagePairsLoading() {
    return this.merge({
      isLanguagePairsLoading: true,
      error: null
    });
  }

  /* ERROR */
  setTranslationsListError(error) {
    return this.merge({
      error: error
    });
  }

  setLanguagePairsError(error) {
    return this.merge({
      error: error
    });
  }

  /* TEMPLATES LOADED */
  setTranslationsListLoaded(state, action) {
    return this.merge({
      ...state,
      isTranslationsLoading: false,
      translations: action.payload ?? []
    });
  }

  setLanguagePairsLoaded(state, action) {
    return this.merge({
      ...state,
      isLanguagePairsLoading: false,
      languagePairs: action.payload ?? []
    });
  }

  setServiceDown(state, action) {
    return this.merge({
      ...state,
      isLanguagePairsLoading: false,
      isTranslationsLoading: false,
      languagePairs: []
    });
  }
}
