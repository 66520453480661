import model from 'models/base-model';
import moment from 'moment';

var storedFormat = localStorage.getItem('format');
var lastListUpdate = localStorage.getItem('format-list-last-updated');

export class LoadFormat extends model({
  isLoading: false,
  isLoaded: false,
  error: null,
  formats: storedFormat ? JSON.parse(storedFormat) : [],
  timestamp: lastListUpdate
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }

  /* ERROR */
  setError(error) {
    return this.merge({
      isLoading: false,
      isLoaded: false,
      error: error
    });
  }

  /* LOADED */
  setLoaded(state, action) {
    var timestamp = moment().toISOString();
    var formats = JSON.parse(action.payload);

    localStorage.setItem('format-list-last-updated', timestamp);
    localStorage.setItem('format', action.payload);

    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      formats: formats,
      timestamp: timestamp
    });
  }
}
