import { connect } from 'react-redux';
import { getData, clear } from 'store/review-status/document-processing';
import { getDocumentResults } from 'store/search/search-results';
import { onSelectCategory } from 'store/search/search-category';
import DocumentProcessing from 'components/review-status/tiles/document-processing/document-processing';

const mapStateToProps = ({ data: { documentProcessing } }) => ({ data: documentProcessing });
const mapDispatchToProps = { getData, clear, getDocumentResults, onSelectCategory };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentProcessing);
