import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { BLOCK } from '../constants';
import TranslationDisclaimer from '../shared/translations-disclaimer/translations-disclaimer';
import LanguageSelector from '../shared/language-selector/language-selector';
import FileSelector from './file-selector';

import Icon from 'components/shared/icon';
import DocumentDownload from 'components/shared/document-download';
import { getCurrentBaseUrl } from 'store/api';

class TranslateDocument extends Component {
  static propTypes = {
    clearFilesOnProgress: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    loadTranslationLanguagePairs: PropTypes.func.isRequired,
    setFileError: PropTypes.func.isRequired,
    setFileOnProgress: PropTypes.func.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    translateDocuments: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
    translationPageInfo: PropTypes.object
  };

  state = {
    sourceLanguage: JSON.parse(localStorage.getItem(`preferredSourceLanguage`)) || null,
    targetLanguage: JSON.parse(localStorage.getItem(`preferredTargetLanguage`)) || null,
    filesToUpload: [],
    haveAcceptedTerms: 'unchecked',
    canTranslate: false,
    selectingFiles: false,
    uploadingFiles: false,
    translationType: '',
  };

  addFilesToUpload = files => {
    const { filesToUpload } = this.state;

    this.setSelectingFiles(true);

    this.setState({ filesToUpload: [...filesToUpload, ...files] }, () =>
      this.setState({ canTranslate: this.checkFormValidity() }, () => this.setSelectingFiles(false))
    );
  };

  setSourceLanguage = sourceLanguage => {
    this.setState({ sourceLanguage: sourceLanguage, targetLanguage: null }, () =>
      this.setState({ canTranslate: this.checkFormValidity() })
    );
  };

  setTargetLanguage = targetLanguage => {
    this.setState({ targetLanguage: targetLanguage }, () => this.setState({ canTranslate: this.checkFormValidity() }));
  };

  setTranslationType = type => {
    this.setState({ translationType: type }, () => this.setState({ canTranslate: this.checkFormValidity() }));
  }

  removeFilesToUpload = () => {
    this.setState({ filesToUpload: [] }, () => this.setState({ canTranslate: this.checkFormValidity() }));
  };

  onTermsAcceptance = value => {
    this.setState({ haveAcceptedTerms: value }, () => this.setState({ canTranslate: this.checkFormValidity() }));
  };

  setSelectingFiles = value => this.setState({ selectingFiles: value });

  setUploadingFiles = value => this.setState({ uploadingFiles: value });

  checkFormValidity = () => {
    const { sourceLanguage, targetLanguage, filesToUpload, haveAcceptedTerms } = this.state;
    const validLanguagePair = sourceLanguage !== null && targetLanguage !== null;
    const validFilesToUpload = filesToUpload.length !== 0;
    const validTermsAndConditions = haveAcceptedTerms !== 'unchecked';

    return validLanguagePair && validFilesToUpload && validTermsAndConditions;
  };

  onTranslate = async (memberFirmCode, containerCode) => {
    const { filesToUpload, sourceLanguage, targetLanguage, translationType } = this.state;
    const { translateDocuments } = this.props;

    translateDocuments(
      filesToUpload,
      sourceLanguage,
      targetLanguage,
      translationType,
      memberFirmCode,
      containerCode,
      this.setUploadingFiles
    );
  };

  componentDidMount() {
    const { loadTranslationLanguagePairs } = this.props;
    loadTranslationLanguagePairs();
  }

  render() {
    const { translations, currentUser, translationPageInfo } = this.props;
    const {
      filesToUpload,
      sourceLanguage,
      targetLanguage,
      haveAcceptedTerms,
      canTranslate,
      selectingFiles,
      uploadingFiles,
      translationType
    } = this.state;
    const block = `${BLOCK}__translate-document`;
    const disclaimerKeys = [
      'document-translation-disclaimer.first-parragraph',
      'document-translation-disclaimer.second-parragraph',
      'document-translation-disclaimer.third-parragraph',
      'document-translation-disclaimer.fourth-parragraph'
    ];

    return (
      <div className={block}>
        {translations.isLanguagePairsLoading ? (
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        ) : (
          <>
            <LanguageSelector
              translations={translations}
              setSourceLanguage={this.setSourceLanguage}
              setTargetLanguage={this.setTargetLanguage}
              setTranslationType={this.setTranslationType}
              sourceLanguage={sourceLanguage}
              targetLanguage={targetLanguage}
              translationType={translationType}
            />
            {translationPageInfo?.quickReferenceGuide && (
              <DocumentDownload
                className="btn btn-link link-qrg"
                linkBody={
                  <>
                    <Icon name="special-info-outline" width={24} />
                    <FormattedMessage id="translation.quick-reference-guide-link" />
                  </>
                }
                linkUrl={`${getCurrentBaseUrl()}/${translationPageInfo.quickReferenceGuide}`}
                target="_blank"
              />
            )}
            <FileSelector
              addFilesToUpload={this.addFilesToUpload}
              filesToUpload={filesToUpload}
              removeFilesToUpload={this.removeFilesToUpload}
              selectingFiles={selectingFiles}
              setSelectingFiles={this.setSelectingFiles}
              uploadingFiles={uploadingFiles}
            />
            <TranslationDisclaimer
              disclaimerKeys={disclaimerKeys}
              onTranslate={this.onTranslate}
              haveAcceptedTerms={haveAcceptedTerms}
              onTermsAcceptance={this.onTermsAcceptance}
              canTranslate={canTranslate}
              currentUser={currentUser}
              uploadingFiles={uploadingFiles}
              setUploadingFiles={this.setUploadingFiles}
              disclaimerFor="document"
            />
          </>
        )}
      </div>
    );
  }
}

export default TranslateDocument;
