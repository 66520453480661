import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PAGE_SIZE = 50;

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

const stringSortFunction = (a, b) => {
  return collator.compare((a || '').trim().toLowerCase(), (b || '').trim().toLowerCase());
};

const dateSortFunction = (a, b) => {
  if (a === b) {
    return 0;
  }

  if (a === null) {
    return 1;
  }

  if (b === null) {
    return -1;
  }

  return a < b ? -1 : 1;
};

const numberSortFunction = dateSortFunction;

export const SORT_KEYS = {
  PROJECT_NAME: 'projectName',
  CLIENT_NAME: 'clientName',
  CREATED_DATE: 'createdDate',
  LAST_UPDATED_DATE: 'lastUpdatedDate',
  PROJECT_STATE_ID: 'projectStateId'
};

export const SORT_HEADERS = [
  {
    sortName: SORT_KEYS.PROJECT_NAME,
    displayName: <FormattedMessage id="sort.heading.project" />
  },
  {
    sortName: SORT_KEYS.CLIENT_NAME,
    displayName: <FormattedMessage id="sort.heading.client" />
  },
  {
    sortName: SORT_KEYS.CREATED_DATE,
    displayName: <FormattedMessage id="sort.heading.creation" />
  },
  {
    sortName: SORT_KEYS.LAST_UPDATED_DATE,
    displayName: <FormattedMessage id="sort.heading.lastedit" />
  },
  {
    sortName: SORT_KEYS.PROJECT_STATE_ID,
    displayName: <FormattedMessage id="sort.heading.status" />
  }
];

export const SORT_FUNCTIONS = {
  [SORT_KEYS.PROJECT_NAME]: stringSortFunction,
  [SORT_KEYS.CLIENT_NAME]: stringSortFunction,
  [SORT_KEYS.CREATED_DATE]: dateSortFunction,
  [SORT_KEYS.LAST_UPDATED_DATE]: dateSortFunction,
  [SORT_KEYS.PROJECT_STATE_ID]: numberSortFunction
};
