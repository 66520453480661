import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import PPMDBanner from 'components/header/banner/banner';
import ProcessingPausedBanner from 'components/header/paused-banner/processing-paused-banner';
import Icon from 'components/shared/icon';
import logoImage from 'images/deloitte_logo_black.svg';
import OmniaLogo from 'images/Omnia.svg';
import AccountDropdown from './account-dropdown/account-dropdown';
import ProjectsLeftMenu from 'components/project-list/project-menu-bar/project-left-menu';
import Menu from './menu/menu';
import UniversalSearch from 'components/search/search';
import HelpDropdown from 'components/header/help/help';
import SubheaderDropdown from 'components/header//subheader-dropdown/subheader-dropdown';
import MaintenanceMessageBanner from 'components/header/maintenance-message-banner/maintenance-message-banner';
import LandingPageTabs from 'components/landing-page/landing-page-tabs';
import { getHomeRegion } from 'store/api';
import GenAIAdminBanner from './banner/genai-admin-banner'
import Permissions from 'permissions/permissions';

class Header extends Component {
  static propTypes = {
    aboutArgus: PropTypes.object,
    currentLanguage: PropTypes.object,
    currentProject: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    fromLandingPage: PropTypes.bool,
    fromTranslationPage: PropTypes.bool,
    hardRefresh: PropTypes.bool,
    loadAboutInfo: PropTypes.func,
    location: PropTypes.object,
    projectId: PropTypes.string,
    currentPage: PropTypes.string,
    maintenanaceBannerMessage: PropTypes.object,
    loadMaintenanceBannerMessage: PropTypes.func,
    fromProjectListPage: PropTypes.bool,
    fromReportsPage: PropTypes.bool,
    fromMaintenancePage: PropTypes.bool,
    fromAdminPage: PropTypes.bool,
    fromQuickStudyPage: PropTypes.bool,
    fromTrainingPage: PropTypes.bool,
    fromUserManagementPage: PropTypes.bool,
    fromUserActivationPage: PropTypes.bool,
    fromVisualsPage: PropTypes.bool,
    history: PropTypes.object,
    onSortingChange: PropTypes.func,
    projectList: PropTypes.object,
    onUserModeChange: PropTypes.func,
    setTableOrTileView: PropTypes.func,
    loadProjectList: PropTypes.func,
    loadUsers: PropTypes.func,
    loadReports: PropTypes.func,
    loadScheduledReports: PropTypes.func,
    loadExtractionFields: PropTypes.func,
    getExtractionFieldGroups: PropTypes.func,
    loadTrainingRequestList: PropTypes.func,
    translations: PropTypes.object,
    onSearchChange: PropTypes.func,
    loadTranslationsList: PropTypes.func,
    isWorkspaceButtonHidden : PropTypes.bool
  };

  state = {
    aboutInfo: null,
    isMenuShown: false
  };

  componentDidMount() {
    const { maintenanaceBannerMessage, loadMaintenanceBannerMessage } = this.props;
    if (!maintenanaceBannerMessage.isLoaded && !maintenanaceBannerMessage.isLoading) {
      return loadMaintenanceBannerMessage();
    }
  }

  toggleMenu = () => {
    this.setState({
      isMenuShown: !this.state.isMenuShown
    });
  };

  render() {
    const {
      currentUser,
      currentProject,
      projectId,
      loadAboutInfo,
      aboutArgus,
      location,
      hardRefresh,
      fromLandingPage,
      fromTranslationPage,
      currentLanguage,
      maintenanaceBannerMessage,
      loadMaintenanceBannerMessage,
      fromProjectListPage,
      fromReportsPage,
      fromMaintenancePage,
      fromAdminPage,
      fromTrainingPage,
      fromUserActivationPage,
      fromVisualsPage,
      projectList
    } = this.props;

    const { isMenuShown } = this.state;

    const omniaUrl = aboutArgus?.data?.omniaURLs?.[currentUser?.geoCode];
    const maintenanceBannerMessages = maintenanaceBannerMessage?.data;
    return (
      <div className="header-container">
        <div className="header">
          <img src={logoImage} className="header__deloitte-logo" alt="deloitte" />
          <a href={omniaUrl} rel="noopener noreferrer" target="_blank">
            <img src={OmniaLogo} className="header__omnia-logo" alt="Omnia" />
          </a>

          {fromLandingPage ||
          fromTranslationPage ||
          fromReportsPage ||
          fromVisualsPage ||
          fromMaintenancePage ||
          fromAdminPage ||
          fromTrainingPage ||
          fromUserActivationPage ? (
            <span className="header__search-space"></span>
          ) : (
            <UniversalSearch {...this.props} />
          )}
          <span className="header__omnia-pipe"></span>
          <HelpDropdown
            loadAboutInfo={loadAboutInfo}
            aboutArgus={aboutArgus}
            fromExtractionPage={!fromLandingPage}
            fromTranslationPage={fromTranslationPage && !fromLandingPage}
            currentLanguage={currentLanguage}
            currentUser={currentUser}
            location={location}
          />
          <AccountDropdown user={currentUser} />
        </div>
        {fromLandingPage ||
        fromTranslationPage ||
        (fromProjectListPage &&
          currentUser &&
          currentUser.accessibleFeatures.filter(feature => feature.featureId === 0).length === 0) ? null : (
          <ProjectsLeftMenu currentProject {...this.props} />
        )}
        <div className="subheader-container">
          <div className="header__subheader">
            <div className="header__display-text">
              <span>
                <FormattedMessage id="global.subheader.document-AI" />
                &nbsp;
              </span>
              <span className="header__argus-text">
              (<FormattedMessage id="global.subheader.landing-page" />)
              </span>
            </div>
          </div>
        </div>
        <div className="landing-page-tabs-container">
          <LandingPageTabs {...this.props} />
        </div>
        {projectId && currentProject.STATES.ReadOnly() && !location.pathname.includes('settings') && (
          <PPMDBanner {...this.props} />
        )}
        {/* {projectId &&
          !currentProject.isDocumentProcessingEnabled &&
          !currentProject.STATES.ReadOnly() &&
          (location.pathname.includes('folder') || location.pathname.includes('extractionfield')) && (
            <ProcessingPausedBanner {...this.props} />
          )} */}
        {fromAdminPage && Permissions.Global.canViewGenAIBannerMessage() && <GenAIAdminBanner />}
        {maintenanceBannerMessages && maintenanceBannerMessages.length > 0 && (
          <MaintenanceMessageBanner
            maintenanceBannerMessages={JSON.stringify(maintenanceBannerMessages)}
            currentUser={currentUser}
          />
        )}
      </div>
    );
  }
}

export default Header;
