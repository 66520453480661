import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Constants from 'utils/constants';
import { getProjectCreationExtractionForGroup, getCurrentRegion } from 'store/api';

import Container from 'components/project-creation/shared/container';
import Icon from 'components/shared/icon';
import NextButton from 'components/create-template/templates/next-button';
import Button from 'components/shared/single-click-button';
import TemplateSteps from 'components/create-template/templates/template-steps/template-steps';
import { STEPS } from 'components/project-creation/constants';
import KiraProcessingPausedModal from 'components/shared/kira-processing-paused-modal';

const {
  SELECT_TEMPLATE_TYPE,
  TEMPLATE_LIST,
  CREATE_TEMPLATE,
  SELECT_DOC_TYPE,
  CUSTOMIZE_TEMPLATE,
  ADD_EF_CUSTOM_TEMPLATE,
  ADD_EF
} = Constants.TemplateSteps;

class Templates extends Component {
  static propTypes = {
    addExtraction: PropTypes.func,
    changeTemplateName: PropTypes.func,
    clearTemplatesData: PropTypes.func,
    createProjectTemplate: PropTypes.func,
    deleteExtraction: PropTypes.func,
    enableExtractons: PropTypes.func,
    history: PropTypes.object,
    intl: intlShape,
    loadAllExtraction: PropTypes.func,
    loadGroupExtraction: PropTypes.func,
    loadGroups: PropTypes.func,
    loadProjectTemplates: PropTypes.func,
    loadTemplateExtraction: PropTypes.func,
    loadTypes: PropTypes.func,
    location: PropTypes.object,
    moveExtraction: PropTypes.func,
    onChangeStep: PropTypes.func,
    project: PropTypes.object,
    projectTemplates: PropTypes.object,
    selectExtraction: PropTypes.func,
    selectGroup: PropTypes.func,
    selectProjectTemplate: PropTypes.func,
    step: PropTypes.any,
    steps: PropTypes.array,
    templateTypes: PropTypes.object,
    updateProjectState: PropTypes.func,
    updateProjectTemplate: PropTypes.func
  };

  state = {
    templateStep: null,
    selectedType: null,
    selectedGroups: [],
    selectedTemplates: [],
    latestTemplateFolder: 0,
    disableCreateButton: false,
    isResolved: true,
    isFormValid: false,
    isAddExtractionButtonDisabled: true,
    addExtractionClicked: false,
    uploadingFiles: false,
    KiraProcessingModalOpen : false
  };

  componentDidMount() {
    const {
      project,
      loadGroups,
      loadProjectTemplates,
      loadTypes,
      history,
      location: { state: locationState }
    } = this.props;
    if (project && project.projectId) {
      // Load project templates on mount
      loadProjectTemplates({ projectId: project.projectId });
      loadGroups();
      loadTypes();
    }

    history.replace(); // clean location state
    if (locationState?.filesToUpload?.length > 0) {
      this.setState({
        uploadingFiles: { filesToUpload: locationState.filesToUpload, folderId: locationState.folderId }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { projectTemplates, project } = this.props;
    if (projectTemplates.templates != null) {
      if (prevProps.projectTemplates.templates !== projectTemplates.templates) {
        if (projectTemplates.templates.length > 0 && project.STATES.Creation()) {
          return this.setState({ templateStep: TEMPLATE_LIST });
        }
        return this.setState({ templateStep: SELECT_TEMPLATE_TYPE });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearTemplatesData();
  }

  // Create detailed testing template to the server
  createDetailedTemplate = (templateName, templateCategoryId, extractionFields, templateTypeID = null) => {
    const { project, createProjectTemplate } = this.props;
    return createProjectTemplate({
      projectId: project.projectId,
      templateName: templateName,
      templateCategoryId: templateCategoryId,
      templateTypeID,
      extractionFields: extractionFields
    }).then(response => {
      this.setState({ latestTemplateFolder: response.payload.templateFolderId });
      if (!project.STATES.Creation()) {
        let state;
        let folderId = response.payload.templateFolderId;
        if (this.state.uploadingFiles?.folderId) {
          state = {
            filesToUpload: this.state.uploadingFiles.filesToUpload,
            projectTemplateId: response.payload.projectTemplateId
          };
        }

        this.props.history.push(`/region/${getCurrentRegion()}/project/${project.projectId}/folder/${folderId}`, state);
      }
    });
  };

  // Create template to the server
  createTemplate = () => {
    const {
      project,
      createProjectTemplate,
      projectTemplates,
      templateTypes,
      projectTemplates: { templateExtractions }
    } = this.props;
    const { selectedTemplates, selectedType } = this.state;

    const templateCategoryId = projectTemplates.templateCategoryId
      ? projectTemplates.templateCategoryId
      : selectedType.templateCategoryId;
    const templateTypeID = projectTemplates.templateTypeID
      ? projectTemplates.templateTypeID
      : templateTypes.types.templateTypes.find(
          tmp =>
            tmp.templateCategoryId === templateCategoryId &&
            tmp.groups.length === 1 &&
            tmp.groups[0].extractionFieldGroupId === projectTemplates.selectedGroup.Id
        )?.templateTypeID;

    return createProjectTemplate({
      projectId: project.projectId,
      templateName: projectTemplates.templateName,
      templateCategoryId: templateCategoryId,
      templateTypeID: templateTypeID,
      extractionFields: this.getExtIds(templateExtractions)
    }).then(response => {
      this.setState({ latestTemplateFolder: response.payload.templateFolderId });
      if (!project.STATES.Creation() && selectedTemplates.length === 0) {
        let state;
        let folderId = response.payload.templateFolderId;
        if (this.state.uploadingFiles?.folderId) {
          state = {
            filesToUpload: this.state.uploadingFiles.filesToUpload,
            projectTemplateId: response.payload.projectTemplateId
          };
        }

        this.props.history.push(`/region/${getCurrentRegion()}/project/${project.projectId}/folder/${folderId}`, state);
      }
    });
  };

  // Update the existing to the server
  updateTemplate = () => {
    const {
      project,
      updateProjectTemplate,
      projectTemplates: { templateExtractions },
      projectTemplates
    } = this.props;

    return updateProjectTemplate({
      projectId: project.projectId,
      templateName: projectTemplates.templateName,
      templateCategoryId: projectTemplates.templateCategoryId,
      templateId: projectTemplates.templateId,
      extractionFields: this.getExtIds(templateExtractions)
    });
  };

  // Delete specified extraction
  deleteExtraction = (extId, callAPI) => {
    const { project, deleteExtraction, projectTemplates } = this.props;
    return deleteExtraction({
      projectId: project.projectId,
      templateId: projectTemplates.templateId,
      extractionFieldId: extId,
      callAPI: callAPI
    });
  };

  getExtIds = data => {
    return data.reduce((accumulator, currentValue) => {
      if (currentValue.isSelected) {
        accumulator.push({
          extractionFieldId: currentValue.id,
          extractionFieldGroupId: currentValue.groupId
        });
      }
      return accumulator;
    }, []);
  };

  toggleSelected = data => {
    return this.props.selectExtraction(data);
  };

  // Final API & method handlers
  getHandlers = () => {
    return {
      addExtraction: this.props.addExtraction,
      loadAllExtraction: this.props.loadAllExtraction,
      loadTemplateExtraction: this.props.loadTemplateExtraction,
      loadGroupExtraction: this.props.loadGroupExtraction,
      onCreate: this.createTemplate,
      onCreateDetailed: this.createDetailedTemplate,
      onExtractionChange: extractions => this.props.moveExtraction(extractions),
      onDocumentTypeChange: group =>
        this.props.selectGroup({
          name: group.name,
          Id: group.id,
          templateCategoryId: group.templateCategoryId,
          untitled: '',
          templateSuffix: this.props.intl.formatMessage({ id: 'create-template.templateSuffix' }),
          templateTypeID: group.templateTypeID
        }),
      onExtractionDelete: value => this.deleteExtraction(value),
      onNameChange: this.props.changeTemplateName,
      onTemplateClick: value => this.props.selectProjectTemplate(value),
      onGetTemplates: () => this.props.loadProjectTemplates({ projectId: this.props.project.projectId }),
      onUpdate: this.updateTemplate,
      setIsSelected: value => this.toggleSelected(value)
    };
  };

  onStepChange = val => this.setState({ templateStep: val });

  createNonEditableDetailTestingTemplate = async (type, api) => {
    const templateTypes = this.props;
    var payload = [];
    var templateName;
    const accountName = templateTypes.templateTypes.types.templateTypeGroups
      .filter(index => index.templateTypeGroupID === type.templateTypeGroupID[0])
      .map(index => index.templateTypeGroupName);

    this.onStepChange(null);

    for (let i = 0; i < type.groups.length; i++) {
      const currentGroup = type.groups[i];
      if (!templateName) {
        templateName = currentGroup.extractionFieldGroupName;
      }
      const extractionFields = await getProjectCreationExtractionForGroup(currentGroup.extractionFieldGroupId);
      payload = [
        ...payload,
        ...extractionFields.data
          .filter(ef => ef.templateTypeIdDisplayOrders.filter(tt => tt.templateTypeId === type.templateTypeID).length)
          .sort((ef1, ef2) => this.compareExtractionFieldTemplateOrder(ef1, ef2, type.templateTypeID))
          .map(({ extractionFieldId }) => ({
            extractionFieldId: extractionFieldId,
            extractionFieldGroupId: currentGroup.extractionFieldGroupId
          }))
      ];
    }
    api
      .onCreateDetailed(
        `${accountName} ${type.templateTypeName}`,
        type.templateCategoryId,
        payload,
        type.templateTypeID
      )
      .then(() => {
        api.onGetTemplates();
        this.onStepChange(TEMPLATE_LIST);
      });
  };

  compareExtractionFieldTemplateOrder = (ef1, ef2, templateTypeId) => {
    const templateTypeDisplayOrder1 = ef1.templateTypeIdDisplayOrders.find(tt => tt.templateTypeId === templateTypeId);
    const templateTypeDisplayOrder2 = ef2.templateTypeIdDisplayOrders.find(tt => tt.templateTypeId === templateTypeId);

    return (
      templateTypeDisplayOrder1.templateExtractionFieldDisplayorder -
      templateTypeDisplayOrder2.templateExtractionFieldDisplayorder
    );
  };

  // Template type functions start
  onTemplateTypesSubmit = api => {
    const { selectedType } = this.state;
    const {project} = this.props;
    if(!project.isDocumentProcessingEnabled && selectedType.templateCategoryId === Constants.TemplateCategories.NON_DETAIL_TESTING )
    {
      this.setState({ selectedType: selectedType,KiraProcessingModalOpen:true }, () => {
        if (
          (selectedType.templateCategoryId === Constants.TemplateCategories.NON_DETAIL_TESTING ||
            selectedType.templateCategoryId === Constants.TemplateCategories.DETAIL_TESTING) &&
          selectedType.groups.length > 1
        ) {
          this.onStepChange(SELECT_DOC_TYPE);
        } else if (selectedType.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING || selectedType.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_GENAI_DETAIL_TESTING || selectedType.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_GENAI_NONDETAIL_TESTING || selectedType.templateCategoryId === Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING) {
          this.createNonEditableDetailTestingTemplate(selectedType, api);
        } else {
          this.setState({ isTemplateExtLoading: true });
          if (selectedType) {
            api.onDocumentTypeChange({
              id: selectedType.groups[0].extractionFieldGroupId,
              name: selectedType.groups[0].extractionFieldGroupName,
              templateCategoryId: selectedType.templateCategoryId,
              templateTypeID: selectedType.templateTypeID
            });
            api
              .loadGroupExtraction(
                selectedType.groups[0].extractionFieldGroupId,
                selectedType.templateCategoryId === Constants.TemplateCategories.DETAIL_TESTING
                  ? selectedType.templateTypeID
                  : null
              )
              .then(() => this.setState({ isTemplateExtLoading: false }));
          } else {
            api.onDocumentTypeChange({ id: 'Other' });
            api.loadGroupExtraction(0);
          }
          this.onStepChange(CREATE_TEMPLATE);
        }
      });
	  }
	   else
	   {
      this.setState({ selectedType: selectedType }, () => {
        if (
          (selectedType.templateCategoryId === Constants.TemplateCategories.NON_DETAIL_TESTING ||
            selectedType.templateCategoryId === Constants.TemplateCategories.DETAIL_TESTING) &&
          selectedType.groups.length > 1
        ) {
          this.onStepChange(SELECT_DOC_TYPE);
        } else if (selectedType.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING || (selectedType.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_GENAI_DETAIL_TESTING || selectedType.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_GENAI_NONDETAIL_TESTING || selectedType.templateCategoryId === Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING)) {
          this.createNonEditableDetailTestingTemplate(selectedType, api);
        } else {
          this.setState({ isTemplateExtLoading: true });
          if (selectedType) {
            api.onDocumentTypeChange({
              id: selectedType.groups[0].extractionFieldGroupId,
              name: selectedType.groups[0].extractionFieldGroupName,
              templateCategoryId: selectedType.templateCategoryId,
              templateTypeID: selectedType.templateTypeID
            });
            api
              .loadGroupExtraction(
                selectedType.groups[0].extractionFieldGroupId,
                selectedType.templateCategoryId === Constants.TemplateCategories.DETAIL_TESTING
                  ? selectedType.templateTypeID
                  : null
              )
              .then(() => this.setState({ isTemplateExtLoading: false }));
          } else {
            api.onDocumentTypeChange({ id: 'Other' });
            api.loadGroupExtraction(0);
          }
          this.onStepChange(CREATE_TEMPLATE);
        }
      });
	   }
   

  };
  // Template type functions end

  // Document type function start
  onDocumentTypeSubmit = event => {
    event.preventDefault();

    const { selectedGroups } = this.state;

    this.setState({ selectedTemplates: selectedGroups.reverse(), disableCreateButton: false }, () => {
      this.startNextTemplateCreation();
    });
  };

  startNextTemplateCreation = () => {
    const api = this.getHandlers();
    var selectedTemplates = this.state.selectedTemplates.slice(0);
    var nextTemplate = selectedTemplates.pop();
    this.setState({ selectedTemplates });

    // Set current template to create (first of array)
    api.onDocumentTypeChange(nextTemplate);
    api.loadGroupExtraction(nextTemplate.id);

    this.onStepChange(CREATE_TEMPLATE);
  };

  // Handle clicking on the back button
  onBackButtonClick = () => {
    const { projectTemplates } = this.props;
    const { templateStep } = this.state;

    switch (templateStep) {
      case CUSTOMIZE_TEMPLATE:
        this.props.enableExtractons();
        return this.onStepChange(TEMPLATE_LIST);
      case SELECT_TEMPLATE_TYPE:
        this.props.enableExtractons();
        return this.onStepChange(SELECT_TEMPLATE_TYPE);
      case CREATE_TEMPLATE:
      case SELECT_DOC_TYPE:
        this.props.enableExtractons();
        this.setState({ selectedTemplates: [], selectedType: null });
        return this.onStepChange(SELECT_TEMPLATE_TYPE);
      case ADD_EF:
        return this.onStepChange(CREATE_TEMPLATE);
      case ADD_EF_CUSTOM_TEMPLATE:
        return this.onStepChange(CUSTOMIZE_TEMPLATE);
      default:
        return this.onStepChange(SELECT_TEMPLATE_TYPE);
    }
  };

  hasSelectedFields = isDisabled => {
    const { projectTemplates } = this.props;
    const { isFormValid } = this.state;

    const externalValidation = !isDisabled && isFormValid;
    const extractionExist = projectTemplates && projectTemplates.templateExtractions.length;

    if (externalValidation && extractionExist)
      return projectTemplates.templateExtractions.reduce((acc, cur) => {
        if (cur.isSelected) acc = true;
        return acc;
      }, false);
    return false;
  };

  onCreateTemplate = e => {
    const { project } = this.props;
    const { templateStep, selectedTemplates } = this.state;
    const { onCreate, onGetTemplates, onUpdate } = this.getHandlers();

    e.preventDefault();
    this.setState({ disableCreateButton: true });

    if (templateStep === CUSTOMIZE_TEMPLATE) {
      // If we're updating, we're going to update a template
      return onUpdate().then(() => {
        this.setState({ disableCreateButton: false, selectedType: null });
        onGetTemplates();
        this.onStepChange(TEMPLATE_LIST);
      });
    } else {
      // Otherwise create a new one
      return onCreate().then(() => {
        if (selectedTemplates.length > 0) {
          this.setState({ disableCreateButton: false }, () => this.startNextTemplateCreation());
        } else {
          this.setState({ disableCreateButton: false, selectedType: null });
          onGetTemplates();
          if (project.STATES.Creation()) {
            this.onStepChange(TEMPLATE_LIST);
          }
        }
      });
    }
  };

  closeKiraProcessingPausedModal = () =>
  {  
    if(!this.props.project.isDocumentProcessingEnabled) 
    {
      this.setState({KiraProcessingModalOpen: false});
    }
  }

  render() {
    const { project, step, steps, onChangeStep, projectTemplates, templateTypes, updateProjectState } = this.props;
    const {
      templateStep,
      latestTemplateFolder,
      selectedType,
      selectedGroups,
      selectedTemplates,
      disableCreateButton,
      isResolved,
      isAddExtractionButtonDisabled,
      addExtractionClicked,
      KiraProcessingModalOpen
    } = this.state;
    const apiHandler = this.getHandlers();
    const isUpdate = templateStep === CUSTOMIZE_TEMPLATE ? true : false;
    const isDisabled =
      disableCreateButton || !isResolved || projectTemplates.isGroupExtLoading || projectTemplates.isTemplateExtLoading;
      if(selectedType!==null && selectedType!== undefined) {
        const templateCategoryId=selectedType.templateCategoryId;
        localStorage.setItem("templateCategoryId",templateCategoryId)
      }
    return (
      <Container
        project={project}
        step={step}
        steps={steps}
        onChangeStep={onChangeStep}
        hideSteps={true}
        customPrevButton={
          templateStep === CREATE_TEMPLATE ||
          templateStep === CUSTOMIZE_TEMPLATE ||
          templateStep === SELECT_DOC_TYPE ||
          templateStep === ADD_EF ||
          templateStep === ADD_EF_CUSTOM_TEMPLATE  ? (
            <button className="btn btn-secondary btn-medium" onClick={this.onBackButtonClick}>
              <FormattedMessage id="common.previous" />
            </button>
          ) :  step === STEPS.WORKFLOWS && project.projectStateId === 1 ? false : (
                <div/>
              )
        }
        customCloseButton={
          <Button
            size="icon"
            className="templates-step__back-button icon-button"
            onClick={() => {
              let newLocation = `/region/${getCurrentRegion()}/project/${project.projectId}/`;
              let state;
              if (this.state.uploadingFiles?.folderId) {
                newLocation += `folder/${this.state.uploadingFiles.folderId}`;
                state = { filesToUpload: this.state.uploadingFiles.filesToUpload };
              } else {
                newLocation += project.STATES.Creation() ? 'creation' : 'extractionfield';
              }
              this.props.history.push(newLocation, state);
            }}
          >
            <Icon name="special-cross-black" width={16} />
          </Button>
        }
        customNextButton={
          !projectTemplates.isTemplatesLoading && templateStep === 4 ? (
            <NextButton
              project={project}
              step={templateStep}
              latestTemplateFolder={latestTemplateFolder}
              isGuided={projectTemplates.templates && projectTemplates.templates.length <= 0}
              updateProject={updateProjectState}
            />
          ) : templateStep === 0 ? (
            <button
              disabled={selectedType === null}
              className="btn btn-primary btn-no-margin btn-medium"
              onClick={() => this.onTemplateTypesSubmit(apiHandler)}
            >
              {selectedType &&
              (selectedType.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING || (selectedType.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_GENAI_DETAIL_TESTING || selectedType.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_GENAI_NONDETAIL_TESTING
                  || selectedType.templateCategoryId === Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING
              )) ? (
                <FormattedMessage id="guided-template.create-template" />
              ) : (
                <FormattedMessage id="common.next" />
              )}
            </button>
          ) : templateStep === 1 ? (
            <Button
              type="button"
              disabled={selectedGroups.length === 0}
              className="document-type__next-button btn btn-primary"
              onClick={this.onDocumentTypeSubmit}
            >
              <FormattedMessage id="document-type.next-button" />
            </Button>
          ) : templateStep === 2 || templateStep === 5 ? (
            <Button
              className="guided-template__create-template btn btn-no-margin btn-primary"
              onClick={this.onCreateTemplate}
              disabled={!this.hasSelectedFields(isDisabled)}
            >
              {isUpdate ? (
                <FormattedMessage id="guided-template.update-template" />
              ) : (
                <FormattedMessage id="guided-template.create-template" />
              )}
            </Button>
          ) : templateStep === ADD_EF || templateStep === ADD_EF_CUSTOM_TEMPLATE ? (
            <Button
              id="add-extraction-add-button"
              disabled={isAddExtractionButtonDisabled}
              className={`btn btn-primary`}
              onClick={() => this.setState({ addExtractionClicked: true })}
            >
              <FormattedMessage id="add-extraction.add-button" />
            </Button>
          ) : (
            <div />
          )
        }
      >
        {projectTemplates.isTemplatesLoading || projectTemplates.isGroupsLoading || templateStep === null ? (
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        ) : (
          <TemplateSteps
            projectId={project.projectId}
            api={apiHandler}
            templateStep={templateStep}
            selectedType={selectedType}
            selectedGroups={selectedGroups}
            changeSelectedGroups={selectedGroups => this.setState({ selectedGroups: selectedGroups })}
            changeSelectedType={selectedType => this.setState({ selectedType: selectedType })}
            changeIsResolved={isResolved => this.setState({ isResolved: isResolved })}
            changeIsFormValid={isFormValid => this.setState({ isFormValid: isFormValid })}
            changeIsAddExtractionButtonDisabled={val => {
              this.setState({ isAddExtractionButtonDisabled: val });
            }}
            changeAddExtractionClicked={val => this.setState({ addExtractionClicked: val })}
            changeDisableCreateButton={disableCreateButton =>
              this.setState({ disableCreateButton: disableCreateButton })
            }
            changeStep={this.onStepChange}
            projectTemplates={projectTemplates}
            templateTypes={templateTypes}
            selectedTemplates={selectedTemplates}
            isUpdate={isUpdate}
            isAddExtractionButtonDisabled={isAddExtractionButtonDisabled}
            addExtractionClicked={addExtractionClicked}
            startNextTemplateCreation={this.startNextTemplateCreation}
          />
        )}

      {KiraProcessingModalOpen && !project.isDocumentProcessingEnabled   && selectedType.templateCategoryId ===0 &&
         <KiraProcessingPausedModal closeKiraProcessingPausedModal={this.closeKiraProcessingPausedModal} templates={selectedType.groups[0]?.extractionFieldGroupName + "  Template"} />}

      </Container>
    );
  }
}

export default withRouter(injectIntl(Templates));
