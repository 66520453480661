import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

import history from 'utils/history';

import App from 'components/app/app';

// Microsoft Application Insights Config
var appInsightsKey = window.ARGUS.ENV.APP_INSIGHTS_INSTRUMENTATION_KEY
  ? window.ARGUS.ENV.APP_INSIGHTS_INSTRUMENTATION_KEY
  : '';
var appInsightsPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appInsightsKey,
    extensions: [appInsightsPlugin],
    extensionConfig: {
      [appInsightsPlugin.identifier]: { history: history }
    }
  }
});

appInsights.loadAppInsights();

export default withAITracking(appInsightsPlugin, App);
