import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

import { getCurrentRegion } from 'store/api';
import { TOOLBAR_OPTIONS, TOOLBAR_LABELS, TOOLBAR_ICONS } from 'store/file-browser-toolbar-options';
import Breadcrumbs from 'components/shared/breadcrumbs';
import DeleteModalContainer from 'containers/file-browser/delete-modal-container';
import Icon from 'components/shared/icon';
import KebabMenu from 'components/file-browser/toolbar/kebab';
import Modal from 'components/shared/modal';
import AssignPopover from 'components/file-browser/toolbar/assign-popover';
import MovePopoverContainer from 'containers/file-browser/move-popover-container';
import CurrentFolderTemplate from 'components/file-browser/toolbar/current-folder-template';
import ReprocessPopover from 'components/file-browser/toolbar/reprocess-popover';
import ReviewPopover from 'components/file-browser/toolbar/review-popover';
import TagsPopover from 'components/file-browser/toolbar/tags-popover';
import TemplatesPopoverContainer from 'containers/file-browser/templates-popover-container';
import UploadButton from 'components/file-browser/upload-button/upload-button';
import Button from 'components/shared/single-click-button';

import Permissions from 'permissions/permissions';

const TOOLBAR_PERMISSIONS = {
  [TOOLBAR_OPTIONS.ASSIGN]: Permissions.Project.Document.canAssign,
  // [TOOLBAR_OPTIONS.TEMPLATES]: Permissions.Project.Document.canSetTemplate,
  [TOOLBAR_OPTIONS.DELETE]: Permissions.Project.Document.canDelete,
  [TOOLBAR_OPTIONS.TAG]: Permissions.Project.Document.canTag,
  [TOOLBAR_OPTIONS.REPROCESS]: Permissions.Project.Document.canReprocess,
  [TOOLBAR_OPTIONS.MOVE]: Permissions.Project.Document.canMove,
  [TOOLBAR_OPTIONS.REVIEW]: Permissions.Project.Document.canReview,
  [TOOLBAR_OPTIONS.EXPORT]: Permissions.Project.Document.canExport
};

class ToolbarButton extends Component {
  static propTypes = {
    children: PropTypes.node,
    isActive: PropTypes.bool,
    onToolbarOptionClick: PropTypes.func.isRequired,
    option: PropTypes.string,
    isDelete:PropTypes.bool
  };

  onClick = () => {
    const { onToolbarOptionClick, option } = this.props;
    onToolbarOptionClick(option);
  };

  render() {
    const { option, children, isActive,isDelete } = this.props;

    return (
      <Button
        size="icon"
        className= {classNames(
          'btn',
          'file-browser-toolbar__control',
          isActive && 'file-browser-toolbar__control--active',
          (!isDelete? 'icon-button':'critical-icon')
        )}
        onClick={this.onClick}
        data-tip
        data-for={`tooltip-${TOOLBAR_LABELS[option]}`}
        data-place="bottom"
      >
        {children}
        <ReactTooltip id={`tooltip-${TOOLBAR_LABELS[option]}`} effect="solid" place="bottom">
          <FormattedMessage id={TOOLBAR_LABELS[option]} />
        </ReactTooltip>
      </Button>
    );
  }
}

class Toolbar extends Component {
  static propTypes = {
    activeToolbarOption: PropTypes.any,
    addOptionToToolbar: PropTypes.func.isRequired,
    category: PropTypes.any,
    currentFolder: PropTypes.object,
    currentProject: PropTypes.object,
    deleteNotification: PropTypes.func,
    goToEditTemplate: PropTypes.func,
    fileBrowserDeselectAllItems: PropTypes.func,
    fileBrowserSelectedItems: PropTypes.object,
    fileBrowserToolbarOptions: PropTypes.object,
    folderId: PropTypes.string,
    invalidate: PropTypes.func,
    isSearchResultsPage: PropTypes.bool,
    moveItems: PropTypes.func,
    notifications: PropTypes.array,
    onFilesSelected: PropTypes.func,
    parents: PropTypes.array,
    projectId: PropTypes.string,
    readOnly: PropTypes.bool,
    setActiveToolbarOption: PropTypes.func,
    showUploadButton: PropTypes.bool,
    onUploadClick: PropTypes.func,
    openKiraProcessingModel: PropTypes.func
  };

  state = {
    hideReprocess: true,
    fileBrowserToolbarOptions: {
      kebabOptions: [],
      quickOptions: [],
      staticOptions: []
    }
  };

  static defaultProps = {
    isSearchResultsPage: false
  };

  componentDidMount() {
    this.filterOptionsByPermissions();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.fileBrowserToolbarOptions !== prevProps.fileBrowserToolbarOptions ||
      this.props.currentFolder !== prevProps.currentFolder
    ) {
      this.filterOptionsByPermissions();
    }
  }

  getselectedKiraTemplates() {
    const { currentFolder, fileBrowserSelectedItems } = this.props;
    let result = currentFolder.children.filter(item => item.templateCategoryId == 0);
    let kiraTemplates = [];
    result.forEach(item => {
      if (fileBrowserSelectedItems.hasOwnProperty(item.projectItemId)) {
        if (fileBrowserSelectedItems[item.projectItemId] === true) {
          if (item.projectTemplates[0]?.name) {
            kiraTemplates.push(item.projectTemplates[0]?.name);
          }
        }
      }
    });
    return [...new Set(kiraTemplates)];
  }

  filterOptionsByPermissions() {
    var { fileBrowserToolbarOptions } = this.props;
    var options = {
      kebabOptions: [],
      quickOptions: [],
      staticOptions: []
    };

    Object.keys(fileBrowserToolbarOptions).forEach(section => {
      fileBrowserToolbarOptions[section].forEach(option => {
        if (this.props.currentFolder.projectItemId === 0) {
          if (TOOLBAR_PERMISSIONS[option]() && option !== 'MOVE' && option !== 'DELETE') {
            options[section].push(option);
          }
        } else {
          if (!this.props.currentProject.isDocumentProcessingEnabled) {
            if (this.props.currentFolder.templateCategoryId === 0) {
              if (TOOLBAR_PERMISSIONS[option]() && option !== 'REPROCESS' && option !== 'TEMPLATES') {
                options[section].push(option);
              }
            } else {
              if (TOOLBAR_PERMISSIONS[option]()) {
                options[section].push(option);
              }
            }
          } else {
            if (TOOLBAR_PERMISSIONS[option]()) {
              options[section].push(option);
            }
          }
        }
      });
    });

    this.setState({ fileBrowserToolbarOptions: options });
  }

  onToolbarOptionClick = option => {
    const { setActiveToolbarOption, openKiraProcessingModel, currentProject } = this.props;
    setActiveToolbarOption(option);
    if (option === TOOLBAR_OPTIONS.REPROCESS && !currentProject.isDocumentProcessingEnabled) {
      var kiraTemplates = this.getselectedKiraTemplates();
      if (kiraTemplates.length > 0) {
        openKiraProcessingModel(option, kiraTemplates);
      }
    }
  };

  closeActiveOption = () => {
    const { setActiveToolbarOption } = this.props;
    setActiveToolbarOption(null);
  };

  renderActiveOption = (isSearchResultsPage) => {
    const {
      activeToolbarOption,
      currentFolder,
      projectId,
      folderId,
      fileBrowserSelectedItems,
      moveItems,
      invalidate,
      currentProject
    } = this.props;

    const { hideReprocess } = this.state;

    if (activeToolbarOption === 'ASSIGN') {
      return (
        <AssignPopover
          invalidate={invalidate}
          onClose={this.closeActiveOption}
          projectId={projectId}
          selectedItems={fileBrowserSelectedItems}
        />
      );
    }

    if (activeToolbarOption === 'DELETE') {
      return (
        <Modal>
          <DeleteModalContainer
            currentFolder={currentFolder}
            invalidate={invalidate}
            onClose={this.closeActiveOption}
            projectId={projectId}
          />
        </Modal>
      );
    }

    if (activeToolbarOption === 'TEMPLATES') {
      return (
        <TemplatesPopoverContainer
          currentFolder={currentFolder}
          projectId={projectId}
          invalidate={invalidate}
          onClose={this.closeActiveOption}
        />
      );
    }

    if (activeToolbarOption === 'TAG') {
      return (
        <TagsPopover
          onClose={this.closeActiveOption}
          selectedItems={fileBrowserSelectedItems}
          projectId={projectId}
          invalidate={invalidate}
        />
      );
    }

    if (activeToolbarOption === 'MOVE') {
      return (
        <MovePopoverContainer
          initialFolder={currentFolder}
          invalidate={invalidate}
          moveItems={moveItems}
          onClose={this.closeActiveOption}
          projectId={projectId}
          currentProject={currentProject}
        />
      );
    }

    if (activeToolbarOption === 'REPROCESS' && hideReprocess) {
      return (
        <ReprocessPopover
          currentFolder={currentFolder}
          onClose={this.closeActiveOption}
          projectId={projectId}
          folderId={folderId}
          selectedItems={fileBrowserSelectedItems}
          invalidate={invalidate}
          isSearchResultsPage={isSearchResultsPage}
          currentProject={currentProject}
        />
      );
    }

    if (activeToolbarOption === 'REVIEW') {
      return (
        <ReviewPopover
          currentFolder={currentFolder}
          onClose={this.closeActiveOption}
          projectId={projectId}
          selectedItems={fileBrowserSelectedItems}
          invalidate={invalidate}
        />
      );
    }
  };

  staticToolbarOptions = () => {
    const { activeToolbarOption,isSearchResultsPage } = this.props;
    const { fileBrowserToolbarOptions } = this.state;
    return fileBrowserToolbarOptions.staticOptions.map(option => {
      const isActive = activeToolbarOption === option;
      return (
        option !== undefined && (
          <div className="file-browser-toolbar__control-container" key={option}>
            <ToolbarButton onToolbarOptionClick={this.onToolbarOptionClick} option={option} isActive={isActive}>
              <Icon className="file-browser-toolbar__inactive-icon" name={TOOLBAR_ICONS[option]} width={20} />
              <Icon className="file-browser-toolbar__active-icon" name={TOOLBAR_ICONS[option]} width={20} />
            </ToolbarButton>
            {isActive && this.renderActiveOption(isSearchResultsPage)}
          </div>
        )
      );
    });
  };

  quickToolbarOptions = () => {
    const { activeToolbarOption, fileBrowserSelectedItems, folderId, currentFolder,isSearchResultsPage } = this.props;
    const { fileBrowserToolbarOptions } = this.state;
    const itemSelected = Object.values(fileBrowserSelectedItems).includes(true);
    var isFolder = currentFolder.children;
    var allFiles = this.props.currentFolder.children;
    var isReprocessDisabled = false;
    var selectedItemsTypeId=[];

    var selectedTemplateIds = [];
    if (Object.entries(this.props.fileBrowserSelectedItems).filter(i => i[1] == true).length != 0) {
      var allItems = Object.entries(this.props.fileBrowserSelectedItems).filter(i => i[1] == true);
      var selectedFileIds = [];
      var currentTemplateId =
        this.props.currentFolder.parentHierarchy !== null
          ? this.props.currentFolder.parentHierarchy.length != 0
            ? this.props.currentFolder.parentHierarchy[0].projectTemplateId
            : null
          : null;
      for (var r = 0; r < allItems.length; r++) {
        selectedFileIds.push(parseInt(allItems[r][0]));
      }
      for (var a = 0; a < selectedFileIds.length; a++) {
        for (var j = 0; j < allFiles.length; j++) {
          if (allFiles[j].projectTemplates.length > 0 && selectedFileIds[a] === allFiles[j].projectItemId) {
            selectedTemplateIds.push(allFiles[j].projectTemplates[0].projectTemplateId);
          }
          if(selectedFileIds[a] === allFiles[j].projectItemId) {
            selectedItemsTypeId.push(allFiles[j].itemTypeId);
          }
        }
      }

      if (selectedTemplateIds.includes(currentTemplateId)) {
        isReprocessDisabled = false;
      } else {
        if(!isSearchResultsPage){
          isReprocessDisabled = true;
        }
      }
    }

    if (itemSelected)
      isFolder = Object.values(
        isFolder.map(type => type.itemTypeId == 1 && fileBrowserSelectedItems[type.projectItemId] == true)
      ).includes(true);
    return fileBrowserToolbarOptions.quickOptions.map(option => {
      const isActive = activeToolbarOption === option;

      if (option !== undefined && option === 'REPROCESS' && isReprocessDisabled && itemSelected) {
        return null;
      }
      //If both folder and file selected in search window
      else if (option !== undefined && isSearchResultsPage && (option === 'DELETE' ||  option === 'REPROCESS' ||  option === 'MOVE') && selectedItemsTypeId.includes(2) && selectedItemsTypeId.includes(1)) {
        return (
          <div className="file-browser-toolbar__control-container" key={option}>
            <ToolbarButton onToolbarOptionClick={this.onToolbarOptionClick} option={option} isActive={isActive}>
              <Icon className="file-browser-toolbar__inactive-icon" name={TOOLBAR_ICONS[option]} width={20} />
              <Icon className="file-browser-toolbar__active-icon" name={TOOLBAR_ICONS[option]} width={20} />
            </ToolbarButton>
            {isActive && this.renderActiveOption(isSearchResultsPage)}
          </div>
        );
      }
      //If folder selected in search window
      else if(option !== undefined && isSearchResultsPage && (option === 'DELETE' ||  option === 'REPROCESS' ||  option === 'MOVE') && selectedItemsTypeId.includes(1))  {
        return null;
      }
      
      else if (option !== undefined && option !== 'DELETE' && itemSelected) {
      
        return (
          <div className="file-browser-toolbar__control-container" key={option}>
        
            <ToolbarButton onToolbarOptionClick={this.onToolbarOptionClick} option={option} isActive={isActive}>
          
          
              <Icon className="file-browser-toolbar__inactive-icon" name={TOOLBAR_ICONS[option]} width={20} />
              <Icon className="file-browser-toolbar__active-icon" name={TOOLBAR_ICONS[option]} width={20} />
           
            </ToolbarButton>
            {isActive && this.renderActiveOption(isSearchResultsPage)}
          </div>
        );
      }
      
      else if (option !== undefined && itemSelected && option === 'DELETE') {
        
        return (
          <div className="file-browser-toolbar__control-container" key={option}>
            <ToolbarButton onToolbarOptionClick={this.onToolbarOptionClick} option={option} isDelete="true" isActive={isActive}>
              
              <Icon className="file-browser-toolbar__inactive-icon" name={TOOLBAR_ICONS[option]} width={20} />
              <Icon className="file-browser-toolbar__active-icon" name={TOOLBAR_ICONS[option]} width={20} />
            
            </ToolbarButton>
            {isActive && this.renderActiveOption(isSearchResultsPage)}
          </div>
        );
      }
    
    });
  };

  kebabToolbarOptions = () => {
    const { fileBrowserSelectedItems, addOptionToToolbar, currentFolder,isSearchResultsPage } = this.props;
    const { fileBrowserToolbarOptions } = this.state;
    const itemSelected = Object.values(fileBrowserSelectedItems).includes(true);

    var isFolder = currentFolder.children;
    var allFiles = this.props.currentFolder.children;
    var isReprocessDisabled = false;

    var selectedTemplateIds = [];
    if (Object.entries(this.props.fileBrowserSelectedItems).filter(i => i[1] == true).length != 0) {
      var allItems = Object.entries(this.props.fileBrowserSelectedItems).filter(i => i[1] == true);
      var selectedFileIds = [];
      var currentTemplateId =
        this.props.currentFolder.parentHierarchy !== null
          ? this.props.currentFolder.parentHierarchy.length != 0
            ? this.props.currentFolder.parentHierarchy[0].projectTemplateId
            : null
          : null;
      for (var r = 0; r < allItems.length; r++) {
        selectedFileIds.push(parseInt(allItems[r][0]));
      }
      for (var a = 0; a < selectedFileIds.length; a++) {
        for (var j = 0; j < allFiles.length; j++) {
          if (allFiles[j].projectTemplates.length > 0 && selectedFileIds[a] === allFiles[j].projectItemId) {
            selectedTemplateIds.push(allFiles[j].projectTemplates[0].projectTemplateId);
          }
        }
      }

      if (selectedTemplateIds.includes(currentTemplateId)) {
        isReprocessDisabled = false;
      } else {
        if(!isSearchResultsPage) {
          isReprocessDisabled = true;
        }
      }
    }

    return (
      fileBrowserToolbarOptions.kebabOptions.length > 0 &&
      itemSelected && (
        <KebabMenu
          fileBrowserToolbarOptions={fileBrowserToolbarOptions}
          onItemSelected={this.onToolbarOptionClick}
          addOptionToToolbar={addOptionToToolbar}
          isReprocessDisabled={isReprocessDisabled}
        />
      )
    );
  };

  getAllOptions = () => {
    return [this.staticToolbarOptions(), this.quickToolbarOptions(), this.kebabToolbarOptions()];
  };

  render() {
    const {
      category,
      currentProject,
      currentFolder,
      isSearchResultsPage,
      onFilesSelected,
      goToEditTemplate,
      parents,
      projectId,
      showUploadButton,
      readOnly,
      folderId,
      onUploadClick
    } = this.props;

    let breadcrumbs = [
      {
        name: <FormattedMessage id="file-browser.toolbar.breadcrumbs" />,
        link: `/region/${getCurrentRegion()}/project/${projectId}/folder/root`
      }
    ];

    let currentTemplate = null;

    if (parents && !isSearchResultsPage) {
      breadcrumbs = breadcrumbs.concat(
        parents
          .map(parent => ({
            name: parent.itemName,
            link: `/region/${getCurrentRegion()}/project/${projectId}/folder/${parent.projectItemId}`
          }))
          .reverse()
      );

      if (parents.length > 0) {
        const templateData = parents.find(({ projectItemId }) => projectItemId === +folderId);
        if (templateData) {
          currentTemplate = (
            <CurrentFolderTemplate
              templateName={templateData.projectTemplate}
              templateId={templateData.projectTemplateId}
              onEditTemplate={goToEditTemplate}
              readOnly={readOnly}
            />
          );
        }
      }
    }

    return (
      <div className="file-browser-toolbar">
        <div className="file-browser-toolbar__title">
          <h2>
            <FormattedMessage
              id={`${!isSearchResultsPage ? 'file-browser.toolbar.title' : 'search-results.heading'}`}
            />
          </h2>

          <div className="file-browser-toolbar__breadcrumbs-container">
            <span className="project-name">{currentProject.projectName}</span>

            {!isSearchResultsPage ? (
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            ) : (
              <span className="search-results__sub-heading">{category}</span>
            )}
          </div>
        </div>
        {currentTemplate}
        <div className="file-browser-toolbar__controls-container">
          {readOnly
            ? this.staticToolbarOptions()
            : this.getAllOptions().map((v, i) => {
                return (
                  <div className="file-browser-toolbar__control-container" key={i}>
                    {v}
                  </div>
                );
              })}
        </div>
        {readOnly ? null : (
          <div className="file-browser-toolbar__button-container">
            {!Permissions.Project._States.isReadOnly() &&
            folderId != 'root' &&
            (Permissions.Project.Comparison.canCreate() || Permissions.Project.Comparison.canEdit()) ? (
              <Button
                size="large"
                onClick={() => this.onToolbarOptionClick(TOOLBAR_OPTIONS.COMPARE)}
                className="btn btn-secondary file-browser-toolbar__folder-button"
              >
                <FormattedMessage id={TOOLBAR_LABELS[TOOLBAR_OPTIONS.COMPARE]} />
              </Button>
            ) : null}

            {Permissions.Project.Template.canCreate() &&
            !Permissions.Project._States.isReadOnly() &&
            !isSearchResultsPage ? (
              <Link to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/create-template`}>
                <Button
                  size="large"
                  onClick={() => this.onToolbarOptionClick(TOOLBAR_OPTIONS.CREATE_WORKFLOW)}
                  className="btn btn-secondary file-browser-toolbar__folder-button"
                >
                  <FormattedMessage id={TOOLBAR_LABELS[TOOLBAR_OPTIONS.CREATE_WORKFLOW]} />
                </Button>
              </Link>
            ) : null}
            <span>
              {!isSearchResultsPage &&
              showUploadButton &&
              Permissions.Project.Document.canUpload() &&
              folderId != 'root' ? (
                <UploadButton
                  onFilesSelected={onFilesSelected}
                  onClick={onUploadClick}
                  projectId={projectId}
                  currentFolder={currentFolder}
                  currentProject={currentProject}
                />
              ) : null}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default Toolbar;
