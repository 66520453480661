import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import SharedPagination from 'components/shared/pagination/pagination';

/*
  Wrap your regular table element with this and use FixedHeader components
  to create a table with a fixed header
*/
export class FixedTable extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onPageChange: PropTypes.func,
    style: PropTypes.string,
    pagination: PropTypes.shape({
      pageNumber: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      totalPageCount: PropTypes.number.isRequired,
      totalRecordCount: PropTypes.number.isRequired
    }),
    setScrollRef: PropTypes.func
  };

  static childContextTypes = {
    scrollToTop: PropTypes.func.isRequired
  };

  getChildContext = () => {
    return {
      scrollToTop: this.scrollToTop
    };
  };

  scrollToTop = () => {
    if (this.scrollElem) this.scrollElem.scrollTop = 0;
  };

  setScrollRef = elem => {
    const { setScrollRef } = this.props;
    this.scrollElem = elem;
    if (setScrollRef) {
      setScrollRef(elem);
    }
  };

  render() {
    const { className, children, onPageChange, pagination, style } = this.props;

    return (
      <div className={classNames(className, 'fixed-table')}>
        <div className={`fixed-table__scroll-container${style ? style : ''}`} ref={this.setScrollRef}>
          {children}
        </div>
        {pagination ? (
          <div className="fixed-table__pagination">
            <Pagination onPageChange={onPageChange} pagination={pagination} />
          </div>
        ) : null}
      </div>
    );
  }
}

/*
  Use this component instead of regular th elements to have the header fixed
  to the top of the table
*/
export class FixedHeader extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  };

  render() {
    const { className, children } = this.props;

    return (
      <th className={classNames(className)}>
        <div className="fixed-table__header-wrapper">
          <div className="fixed-table__header-content">{children}</div>
        </div>
      </th>
    );
  }
}

/*
  This pagination component extends the regular shared pagination component
  with the ability to scroll the table back to the top when the page changes
*/
export class Pagination extends Component {
  static propTypes = {
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      pageNumber: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      totalPageCount: PropTypes.number.isRequired,
      totalRecordCount: PropTypes.number.isRequired
    }).isRequired
  };

  static contextTypes = {
    scrollToTop: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { scrollToTop } = this.context;
    if (this.props.pagination.pageNumber !== prevProps.pagination.pageNumber) {
      scrollToTop();
    }
  }

  render() {
    return <SharedPagination {...this.props} />;
  }
}

export default FixedTable;
