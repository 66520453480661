import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EmptyState from 'components/file-browser/file-list/empty-state';
import emptyStateImage from 'images/empty_state_comp_01.svg';
import history from 'utils/history';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import { BLOCK, maxWidth } from './constants';
import { Link } from 'react-router-dom';
import { getCurrentRegion, editExtractionFieldRecord } from 'store/api';
import { COLUMN_OPTIONS } from 'store/extraction-overview';
import socket, { rooms } from 'utils/socket';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import { linkVertical } from 'd3';
import { first, object } from 'underscore';
import { sanitize } from "dompurify";
import Parser from "html-react-parser";
import ExtractionOverviewFilter from 'components/extraction-overview/extraction-overview-filter';

class ExtractionOverviewGrid extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    projectTemplateTypeId: PropTypes.number.isRequired,
    templateExtractionFields: PropTypes.array.isRequired,
    templateExtractionGroups: PropTypes.array.isRequired,
    templateExtractions: PropTypes.array.isRequired,
    columnOptions: PropTypes.object.isRequired,
    updateColumnOptions: PropTypes.func.isRequired,
    setTemplate: PropTypes.func.isRequired,
    extractionOverview: PropTypes.object.isRequired,
    customizedVisibility: PropTypes.func,
    openDocument: PropTypes.func,
    isDocumentToggleSwitchOnOff: PropTypes.bool
  };

  state = {
    selectedCell: false,
    editCell: false,
    columnWidths: null,
    columnMinWidths: null,
    selectedColumnPosition: -1,
    selectedColumnIndex: -1,
    extractions: [],
    allExtractions: [],
    editedDocumentId: 0,
    extractionDocumentresult: [],
    firstDocument: 0,
    notFirstLoad:false,
    search: {
      key: undefined,
      query: undefined
    },
    filtering: {
      key: undefined,
      ids: undefined
    }
  };
 
  componentDidMount() {
    const {
      projectId,
      projectTemplateTypeId,
      templateExtractions,
      templateExtractionFields,
      templateExtractionGroups
    } = this.props;
    const {notFirstLoad} = this.state;
    const storedColumnWidths =
      JSON.parse(localStorage.getItem(`extractionOverview-${projectTemplateTypeId}-${projectId}`)) || null;
    const extractions = [];
    let efRecords = [];
    let efs = [];

    templateExtractions.forEach(file => {
      let sections = [];
      file.sections.forEach(section => {
        if (sections.length > 0 && section.sampleNumber) {
          var extSection = sections.filter(x => x.sampleNumber == section.sampleNumber);

          if (extSection.length > 0) {
            section.extractionFieldDetails.forEach(extRecord => {
              var extFieldData = extSection[0].extractionFieldDetails?.filter(
                x => x.extractionFieldId == extRecord.extractionFieldId
              );

              if (extFieldData.length > 0) {
                extFieldData[0].extractionFieldRecordDetails.push(...extRecord.extractionFieldRecordDetails);
              } else {
                extSection[0].extractionFieldDetails.push(extRecord);
              }
            });
          }
          else  {
            if(section.extractionFieldDetails.length>0)
            sections.push(section);
          }
        } else {
          sections.push(section);
             
        }
      });
      sections.forEach(section => {
        let extraction = extractions.find(ex => ex.sampleNumber === section.sampleNumber
           );

        if (section.sampleNumber && extraction) {
          extraction.templateTypeIndex = extraction.templateTypeIndex.map(teg => {
            const extractionFieldRecords = section.extractionFieldDetails
              .filter(efd => teg.extractionFieldIds.includes(efd.extractionFieldId))
              .map(ef => ef.extractionFieldRecordDetails.length);
            const recordsExist = extraction.extractionFieldDetails
              .map(x => section.extractionFieldDetails.filter(z => z.extractionFieldId == x.extractionFieldId))
              .filter(a => a.length);
            return {
              ...teg,
              index: extractionFieldRecords.length && recordsExist.length ? teg.rows : teg.index,
              rows:
                extractionFieldRecords.length
                  ? teg.rows + Math.max(...extractionFieldRecords)
                  : teg.rows
            };
          });
          if(extraction.docNameList === undefined)
          extraction.docNameList = [];      

          var doc = { DocumentName : file.name, DocumentId : file.projectItemId}
          extraction.docNameList.push(doc);
          extraction.extractionFieldDetails = [
            ...extraction.extractionFieldDetails,
            ...section.extractionFieldDetails
          ].reduce((acc, curr) => {
            const prevExtractionField = acc.find(et => et.extractionFieldId === curr.extractionFieldId);
            const extractionFieldGroupIndex = extraction.templateTypeIndex.find(
              tti =>
                tti.groupId ===
                templateExtractionGroups.find(teg => teg.extractionFieldIds.includes(curr.extractionFieldId))
                  .extractionFieldGroupId
            );

            if (prevExtractionField) {
              prevExtractionField.extractionFieldRecordDetails = [
                ...prevExtractionField.extractionFieldRecordDetails,
                ...curr.extractionFieldRecordDetails.map((efr, i) => ({
                  ...efr,
                    offset: prevExtractionField.extractionFieldRecordDetails.length + i
                }))
              ];
              return acc;
            }

            return [
              ...acc,
              {
                ...curr,
                extractionFieldRecordDetails: curr.extractionFieldRecordDetails.map((efrd, i) =>
                  efrd.offset >= 0
                    ? efrd
                    : {
                        ...efrd,
                        offset:i
                      }
                )
              }
            ];
          }, []);
        } else {
          
          extraction = {
            name: file.name,
            projectItemId: file.projectItemId,
            projectTemplateId: file.projectTemplateId,
            sampleNumber: section.sampleNumber,
            docNameList: [{ DocumentName : file.name, DocumentId : file.projectItemId}],
            extractionFieldDetails: section.extractionFieldDetails.map(efd => ({
              ...efd,
              projectItemId : file.projectItemId,
              extractionFieldRecordDetails: efd.extractionFieldRecordDetails.map((efrd, i) => ({ ...efrd, offset: i }))
            })),
            templateTypeIndex: templateExtractionGroups.map(teg => {
              const extractionFieldRecords = section.extractionFieldDetails
                .filter(efd => teg.extractionFieldIds.includes(efd.extractionFieldId))
                .map(ef => ef.extractionFieldRecordDetails.length);
              return {
                groupId: teg.extractionFieldGroupId,
                extractionFieldIds: teg.extractionFieldIds,
                index: 0,
                rows: extractionFieldRecords.length ? Math.max(...extractionFieldRecords) : 0
              };
            })
          };

          efRecords = [...efRecords, ...section.extractionFieldDetails];
          extractions.push(extraction);
        }
      });
    });

    const columnWidths = new Array(templateExtractionFields.length + 2);
    const columnMinWidths = new Array(templateExtractionFields.length + 2);
    templateExtractionFields.forEach((ef, idx) => {
      const minWidth = this.getMinWidth(
        efRecords.filter(er => er.extractionFieldId === ef.extractionFieldId),
        ef.extractionFieldName
      );
      columnMinWidths[idx + 2] = minWidth;
      if (storedColumnWidths && storedColumnWidths[idx + 2]) {
        columnWidths[idx + 2] = storedColumnWidths[idx + 2];
      } else {
        columnWidths[idx + 2] = minWidth;
      }
    });
   
    var extractionsWithSampleNumber = [];
    var extractionsWithoutSampleNumber = [];
    var extractionsdoc =[];
    
    for (let j = 0; j < extractions.length; j++) {
      if (extractions[j].sampleNumber === null) {
        extractionsWithoutSampleNumber.push(extractions[j]);
      } else {
        extractionsWithSampleNumber.push(extractions[j]);
      }
    }
 
    extractionsWithSampleNumber = extractionsWithSampleNumber.sort((s1, s2) => s1.sampleNumber - s2.sampleNumber);
    extractionsWithoutSampleNumber = extractionsWithoutSampleNumber.sort((s1, s2) => s1.name.localeCompare(s2.name));
    extractionsdoc = extractionsWithSampleNumber.concat(extractionsWithoutSampleNumber);
    if(extractionsdoc[0]?.docNameList[0]?.DocumentId != 0)
      this.props.openDocument(extractionsdoc[0]?.docNameList[0]?.DocumentId, 1);
    else
      this.props.openDocument(extractionsdoc[0]?.projectItemId, 1);
    this.setState({ columnWidths, extractions, allExtractions: extractions, columnMinWidths });

    if(notFirstLoad == false)
    {
       if(extractionsdoc[0]?.docNameList[0]?.DocumentId != 0)
          this.setState({firstDocument: extractionsdoc[0]?.docNameList[0]?.DocumentId})
      else
          this.setState({firstDocument: extractionsdoc[0]?.projectItemId})
    }
    document.addEventListener('mousemove', this.setNewColumnWidths);
    document.addEventListener('mouseup', this.saveNewColumnWidths);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.setNewColumnWidths);
    window.removeEventListener('mouseup', this.saveNewColumnWidths);
  }
  
  componentDidUpdate() {
    const table = document.getElementById('extraction-overview-table');
    if (table) {
      this.moveColumn(table.scrollLeft + 'px', table.scrollTop + 'px');
    }
  }

  setNewColumnWidths = e => {
    const { columnWidths, columnMinWidths, selectedColumnIndex, selectedColumnPosition } = this.state;

    if (selectedColumnIndex >= 0 && selectedColumnPosition >= 0) {
      const offSet = e.pageX - selectedColumnPosition;
      const newWidth = columnWidths[selectedColumnIndex] + offSet;
      if (selectedColumnIndex < 2 || newWidth > columnMinWidths[selectedColumnIndex]) {
        columnWidths[selectedColumnIndex] = newWidth;
      }

      this.setState({ columnWidths, selectedColumnPosition: e.pageX });
    }
  };

  reloadCurrentGird (){
    var { filtering, allExtractions, extractions } = this.state;
    var filteredIds = filtering.ids;
    const exts = allExtractions;
    extractions = allExtractions;
    if(filteredIds && filteredIds.length > 0){
      var selecteddocs = [];
      extractions.forEach(e =>{
        if(e.docNameList && e.docNameList.length > 0){
          var selectedExt1 = [];
          var extDetails = [];
          var dlist = [];
          e.docNameList.forEach(a =>{
            if(filteredIds.indexOf(a.DocumentId) !== -1){
              dlist.push(a);
            }});

          e.extractionFieldDetails.forEach(x => {
            var fieldDetails;
            var extRecords = [];
            x.extractionFieldRecordDetails.forEach(h => {
              if (filteredIds.indexOf(h.projectItemId) !== -1) extRecords.push(h);
            });
            fieldDetails = {
              extractionFieldId: x.extractionFieldId,
              extractionFieldRecordDetails:  extRecords,
              numberOfRecords:x.numberOfRecords,
              projectItemId: x.projectItemId
            };
            extDetails.push(fieldDetails);
          });
          selectedExt1 = {
            name: e.name,
            projectItemId: e.projectItemId,
            projectTemplateId: e.projectTemplateId,
            sampleNumber: e.sampleNumber,
            docNameList: dlist,
            extractionFieldDetails: extDetails,
            templateTypeIndex: e.templateTypeIndex
          };
          if(selectedExt1.docNameList.length > 0) selecteddocs.push(selectedExt1);
        } else{
          if(filteredIds.indexOf(e.projectItemId) !== -1){
            selecteddocs.push(e);
          }
        }
      });

      this.setState({extractions: selecteddocs});
    }
    else 
      this.setState({extractions: exts});  
  }

  setFiltering = (key, ids) => {
    var { filtering, search } = this.state;
    filtering.key = ids.length > 0 ? key : undefined;
    filtering.ids = ids.length > 0 ? ids : undefined;
    search.key = undefined;
    search.query = undefined;
    this.setState({ filtering: filtering, search: search }, () => this.reloadCurrentGird());
  };

  getAllDocuments = () =>{
    const { allExtractions } = this.state;
    const DOCUMENTS = [];
    const documentIds = [];

    allExtractions.length 
    && allExtractions.map((section, index) => {
        if(DOCUMENTS.length > 0){
          if(documentIds.indexOf(section.projectItemId) == -1) {
            documentIds.push(section.projectItemId);
            section.docNameList.map((fileName,index) => {
              DOCUMENTS.push({key: fileName.DocumentName, id: fileName.DocumentId })
            });
          }
          else {
            documentIds.push(section.projectItemId);
            section.docNameList.map((fileName,index) => {
              DOCUMENTS.push({key: fileName.DocumentName, id: fileName.DocumentId })
            }); 
          }
        }
        else{
          section.docNameList.map((fileName,index) => {
            DOCUMENTS.push({key: fileName.DocumentName, id: fileName.DocumentId })          
          });
          documentIds.push(section.projectItemId);
        }
      });
       const DistinctDocuments = Array.from(new Set(DOCUMENTS.map(x=>x.id))).map(
         id=>{
           return {
           key: DOCUMENTS.find(s=>s.id==id).key,
           id:id
           };
         });


    return Promise.resolve({ data: DistinctDocuments });
  };

  saveNewColumnWidths = () => {
    const { projectTemplateTypeId, projectId } = this.props;
    const { columnWidths, selectedColumnIndex, selectedColumnPosition } = this.state;
    if (selectedColumnIndex >= 0 && selectedColumnPosition >= 0) {
      this.setState({ selectedColumnIndex: -1, selectedColumnPosition: -1 }, () => {
        document.body.style.cursor = 'default';
        localStorage.setItem(`extractionOverview-${projectTemplateTypeId}-${projectId}`, JSON.stringify(columnWidths));
      });
    }
  };

  moveColumn = (offsetLeft, offsetTop) => {
    const { columnOptions } = this.props;
    const { columnWidths } = this.state;
    const documentNameWidth = columnWidths[0] ? columnWidths[0] : document.getElementById("table-header-document") ? document.getElementById("table-header-document").offsetWidth:0;
    const offsetLeftParsed = parseInt(offsetLeft.replace('px', ''));
    const headerItem = document.getElementsByClassName('fixed-column--header');
    for (let i = 0; i < headerItem.length; i++) {
      headerItem.item(i).style.top = offsetTop;
    }
    const fixedItems = document.getElementsByClassName('fixed-column');
    for (let i = 0; i < fixedItems.length; i++) {
      if (!columnOptions.frozen[COLUMN_OPTIONS.DOCUMENT_NAME] && columnOptions.frozen[COLUMN_OPTIONS.SAMPLE_NUMBER]) {
        if (offsetLeftParsed > documentNameWidth && columnOptions.visibility[COLUMN_OPTIONS.DOCUMENT_NAME]) {
          fixedItems.item(i).style.left = offsetLeftParsed - documentNameWidth  + 'px';
        } else if (!columnOptions.visibility[COLUMN_OPTIONS.DOCUMENT_NAME]) {
          fixedItems.item(i).style.left = offsetLeftParsed + 'px';
        } else {
          fixedItems.item(i).style.left = '';
        }
        
      } else {
        fixedItems.item(i).style.left = offsetLeft;
      }
    }
  };

  onEditExtractionRecordClick = event => {
    const { editCell } = this.state;

    if (!editCell) {
      this.setState({ editCell: !editCell });
      event.target.parentNode.classList.add(`${BLOCK}__button--edit-data`);
      event.target.parentNode.children[1].focus();
    }
  };

  onEditExtractionRecordBlur = (event, documentId, fieldId, recordId) => {
    const { projectId } = this.props;

    this.setState({ editCell: false, editedDocumentId: documentId });
    event.target.parentNode.classList.remove(`${BLOCK}__button--edit-data`);
    socket.join(rooms.document(projectId, documentId));
    socket.on('extractionfieldrecord.updated', this.onExtractionFieldUpdate);
    const rowButton = event.target.parentNode.children[0];
    const newExtractionFieldRecordData = Parser(sanitize(event.currentTarget.textContent));
    if (event.currentTarget.textContent !== '') {
      rowButton.children[0].innerHTML = newExtractionFieldRecordData;
      editExtractionFieldRecord(projectId, documentId, fieldId, recordId, newExtractionFieldRecordData).then(() => {
        rowButton.children[0].innerHTML = newExtractionFieldRecordData;
      });
    } else {
      event.target.parentNode.children[1].innerHTML = rowButton.children[0].innerHTML;
    }
  };

  onExtractionFieldUpdate = () => {
    const value = this.props.extractionOverview.templates.find(
      t => t.projectTemplateId === this.props.projectTemplateTypeId
    );
    socket.leave(rooms.document(this.props.projectId, this.state.editedDocumentId));
    socket.off('extractionfieldrecord.updated', this.onExtractionFieldUpdate);
    this.setState({ editedDocumentId: 0 });
  };
  
  onHighlightRow = event => {
    //Make all the cells to default color on click of a new cell
      event.currentTarget.parentNode.parentNode.parentNode.childNodes.forEach(item => item.style.background = '#ffffff')
    //Highlight the respective row to grey color
      event.currentTarget.parentNode.parentNode.style.background = '#E9E9E9';
  };

  onExtractionRecordClick = event => {
    const { projectId, openDocument, isDocumentToggleSwitchOnOff} = this.props;
    const { selectedCell } = this.state;
    const { docId, pageNum } = event.target.dataset;
    openDocument(docId, pageNum);

    if (isDocumentToggleSwitchOnOff == true) {
      this.setState({notFirstLoad: true});
      this.onHighlightRow(event);
    }
    if (!selectedCell) {
      this.setState({ selectedCell: !selectedCell });
      event.currentTarget.classList.add(`${BLOCK}__button--display-data`);
    } else {
      history.push(`/region/${getCurrentRegion()}/project/${projectId}/document/${docId}?page=${pageNum}`);
    }
  };
  
  onExtractionRecordBlur = event => {
    this.setState({ selectedCell: false });
    event.currentTarget.classList.remove(`${BLOCK}__button--display-data`);
  };
  //Sensitive code. Please do proper unit testing in extraction overview grid if you make any change in the below code
  renderRecords = (currentRow, records, projectItemId, rowindex, sectionRowNumber) => {
    const { templateExtractionFields, columnOptions, customizedVisibility, isDocumentToggleSwitchOnOff} = this.props;
    const {firstDocument, notFirstLoad} = this.state;
    let customVisibility = false;
    if (Object.keys(customizedVisibility).length > 0) {
      customVisibility = true;
    } else {
      customVisibility = false;
    }
     const rowClassName= projectItemId == firstDocument && notFirstLoad == false && rowindex==0 && sectionRowNumber ==0  ? `row-reference-${projectItemId}-0-0 ${BLOCK}__highlighted-row` : `row-reference-${projectItemId}-${rowindex}-${sectionRowNumber}`;
    return (
      <tr key={`${projectItemId}-section-row-${currentRow}`} className={isDocumentToggleSwitchOnOff == false?  `row-reference-${projectItemId}-${rowindex}-${sectionRowNumber} ${BLOCK}__default-row`: rowClassName}>
        {templateExtractionFields.map((extraction, index) => {
          const data = records.find(ef => ef.extractionFieldId === extraction.extractionFieldId);
          
          if (customVisibility === false) {
            if (columnOptions.visibility[extraction.extractionFieldId] !== false) {
              if (data && data.extractionFieldRecordDetails) {
                const extractionRecord = data.extractionFieldRecordDetails.find(d => currentRow === d.offset);
                const prjItemId= extractionRecord ? extractionRecord.projectItemId :projectItemId;
                if (extractionRecord) {
                  return (
                    <td
                      className={`${BLOCK}__cell`}
                      data-doc-id={prjItemId}
                      data-page-num={extractionRecord.pageNumber}
                      key={`${prjItemId}-table-data-${index}-${currentRow}`}
                    >
                      <button
                        className={`${BLOCK}__button cell-record`}
                        onClick={this.onExtractionRecordClick}
                        onBlur={this.onExtractionRecordBlur}
                        data-doc-id={prjItemId}
                        data-page-num={extractionRecord.pageNumber}
                      >
                        <div
                          data-doc-id={prjItemId}
                          data-page-num={extractionRecord.pageNumber}
                          className={`${BLOCK}__cell-record`}
                        >
                          {extractionRecord.extractedData}
                        </div>
                      </button>
                      <div
                        contentEditable
                        suppressContentEditableWarning={true}
                        id={`cell-edit-${prjItemId}-table-data-${index}-${currentRow}`}
                        className={`edit-input`}
                        onBlur={e =>
                          this.onEditExtractionRecordBlur(
                            e, 
                            prjItemId,
                            extraction.extractionFieldId,
                            extractionRecord.extractionFieldRecordId
                          )
                        }
                      >
                        {extractionRecord.extractedData}
                      </div>
                      <div className={`${BLOCK}__edit-button edit-button`} onClick={this.onEditExtractionRecordClick}>
                        <Icon className="regular" width={18} name="edit-record" />
                        <Icon className="active" width={18} name="edit-record-active" />
                      </div>
                    </td>
                  );
                }
              }
              return (
                <td className={`${BLOCK}__cell inactive`} key={`${projectItemId}-empty-record-${index}-${currentRow}`}>
                  &nbsp;
                </td>
              );
            }
          } else {
            if (customizedVisibility[extraction.extractionFieldId] === true) {
              if (data && data.extractionFieldRecordDetails) {
                const extractionRecord = data.extractionFieldRecordDetails.find(d => currentRow === d.offset);
              const prjItemId= extractionRecord ? extractionRecord.projectItemId :projectItemId;
                if (extractionRecord) {
                  return (
                    <td
                      className={`${BLOCK}__cell`}
                      data-doc-id={prjItemId}
                      data-page-num={extractionRecord.pageNumber}
                      key={`${prjItemId}-table-data-${index}-${currentRow}`}
                    >
                      <button
                        className={`${BLOCK}__button cell-record`}
                        onClick={this.onExtractionRecordClick}
                        onBlur={this.onExtractionRecordBlur}
                        data-doc-id={prjItemId}
                        data-page-num={extractionRecord.pageNumber}
                      >
                        <div
                          data-doc-id={prjItemId}
                          data-page-num={extractionRecord.pageNumber}
                          className={`${BLOCK}__cell-record`}
                        >
                          {extractionRecord.extractedData}
                        </div>
                      </button>
                      <div
                        contentEditable
                        suppressContentEditableWarning={true}
                        id={`cell-edit-${prjItemId}-table-data-${index}-${currentRow}`}
                        className={`edit-input`}
                        onBlur={e =>
                          this.onEditExtractionRecordBlur(
                            e,
                            prjItemId,
                            extraction.extractionFieldId,
                            extractionRecord.extractionFieldRecordId
                          )
                        }
                      >
                        {extractionRecord.extractedData}
                      </div>
                      <div className={`${BLOCK}__edit-button edit-button`} onClick={this.onEditExtractionRecordClick}>
                        <Icon className="regular" width={18} name="edit-record" />
                        <Icon className="active" width={18} name="edit-record-active" />
                      </div>
                    </td>
                  );
                }
              }
              return (
                <td className={`${BLOCK}__cell inactive`} key={`${projectItemId}-empty-record-${index}-${currentRow}`}>
                  &nbsp;
                </td>
              );
            }
          }
        })}
      </tr>
    );
  };

  sectionRows = templateTypeIndex => {
    return templateTypeIndex.reduce((acc, curr) => (curr.rows > acc ? curr.rows : acc), 1);
  };

  getMinWidth = (efRecords, templateName) => {
    const records = efRecords
      .reduce((acc, curr) => {
        return [...acc, ...curr.extractionFieldRecordDetails];
      }, [])
      .sort((curr, next) => next.extractedData.length - curr.extractedData.length);

    const text = document.createElement('span');
    text.className = 'extraction-overview__column';
    document.body.appendChild(text);
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';

    if (!records.length) {
      text.innerHTML = templateName.substr(0, Math.floor(templateName.length / 2) + 1);
      const width = Math.ceil(text.clientWidth) + 2;
      document.body.removeChild(text);

      return width;
    }

    text.innerHTML = records[0].extractedData.length > templateName.length ? records[0].extractedData : templateName;
    const width = Math.ceil(text.clientWidth);

    document.body.removeChild(text);
    return Math.min(width, maxWidth);
  };

  frozeColumn = option => {
    const { updateColumnOptions, columnOptions } = this.props;
    const frozen = {
      ...columnOptions.frozen,
      [option]: !columnOptions.frozen[option]
    };

    if (!frozen[option]) {
      this.moveColumn('', '');
    }

    updateColumnOptions({ visibility: columnOptions.visibility, frozen });
  };

  grabColumn = (element, index) => {
    const { columnWidths } = this.state;
    const headerRect = document.getElementById(element).getBoundingClientRect();
    columnWidths[index] = headerRect.right - headerRect.left;

    this.setState(
      {
        selectedColumnIndex: index,
        selectedColumnPosition: headerRect.right,
        columnWidths
      },
      () => (document.body.style.cursor = 'col-resize')
    );
  };
  //Sensitive code. Please do proper unit testing if you make any change in the below code
  render() {
    const {
      projectId,
      templateExtractionFields,
      templateExtractionGroups,
      columnOptions,
      customizedVisibility,
      openDocument,
      isDocumentToggleSwitchOnOff
    } = this.props;
    
    const { columnWidths, filtering } = this.state;
    var { extractions } = this.state;
    let groupIndex = 2;
    let columns = columnOptions.visibility;
    let falseColumns = [];
    let customVisibility = false;
    if (Object.keys(customizedVisibility).length > 0) {
      customVisibility = true;
      for (var key1 in customizedVisibility) {
        if (customizedVisibility.hasOwnProperty(key1) && customizedVisibility[key1] === false) {
          falseColumns.push(key1);
        }
      }
    } else {
      customVisibility = false;
      for (var key2 in columns) {
        if (columns.hasOwnProperty(key2) && columns[key2] === false) {
          falseColumns.push(key2);
        }
      }
    }
    var extractionsWithSampleNumber = [];
    var extractionsWithoutSampleNumber = [];
    for (let j = 0; j < extractions.length; j++) {
      if (extractions[j].sampleNumber === null) {
        extractionsWithoutSampleNumber.push(extractions[j]);
      } else {
        extractionsWithSampleNumber.push(extractions[j]);
      }
    }

 
    extractionsWithSampleNumber = extractionsWithSampleNumber.sort((s1, s2) => s1.sampleNumber - s2.sampleNumber);
    extractionsWithoutSampleNumber = extractionsWithoutSampleNumber.sort((s1, s2) => s1.name.localeCompare(s2.name));
    extractions = extractionsWithSampleNumber.concat(extractionsWithoutSampleNumber);

    return extractions.length ? (
      <div
        className={isDocumentToggleSwitchOnOff ? `${BLOCK}__documentToggle` : `${BLOCK}__table`}
        id="extraction-overview-table"
        onScroll={e => this.moveColumn(e.target.scrollLeft + 'px', e.target.scrollTop + 'px')}
      >
        <table key="extraction-overview-table">
          <thead>
            <tr className="no-border">
              {customVisibility === false &&  isDocumentToggleSwitchOnOff===false
                ? columnOptions.visibility[COLUMN_OPTIONS.DOCUMENT_NAME] && (
                    <th 
                      className={`${BLOCK}__template-group no-border ${columnOptions.frozen[
                        COLUMN_OPTIONS.DOCUMENT_NAME
                      ] && `fixed-column fixed-column--header`}`}
                    ></th>
                  )
                : customizedVisibility[COLUMN_OPTIONS.DOCUMENT_NAME] && (
                    <th
                      className={`${BLOCK}__template-group no-border ${columnOptions.frozen[
                        COLUMN_OPTIONS.DOCUMENT_NAME
                      ] && `fixed-column fixed-column--header`}`}
                    ></th>
                  )}
                  
              {customVisibility === false
                ? columnOptions.visibility[COLUMN_OPTIONS.SAMPLE_NUMBER] && (
                    <th
                      className={`${BLOCK}__template-group no-border ${columnOptions.frozen[
                        COLUMN_OPTIONS.SAMPLE_NUMBER
                      ] && `fixed-column fixed-column--header`}`}
                    ></th>
                  )
                : customizedVisibility[COLUMN_OPTIONS.SAMPLE_NUMBER] && (
                    <th
                      className={`${BLOCK}__template-group no-border ${columnOptions.frozen[
                        COLUMN_OPTIONS.SAMPLE_NUMBER
                      ] && `fixed-column fixed-column--header`}`}
                    ></th>
                  )}
              {templateExtractionGroups.map((group, i) => {
                let groupFieldsCount = group.extractionFieldsCount;

                for (let s = 0; s <= group.extractionFieldIds.length; s++) {
                  for (let e = 0; e <= falseColumns.length; e++) {
                    if (
                      falseColumns[e] == group.extractionFieldIds[s] &&
                      falseColumns[e] !== undefined &&
                      group.extractionFieldIds[s] !== undefined
                    ) {
                      --groupFieldsCount;
                    }
                  }
                }
                let customizedCount=0;
                if(customVisibility == false)
                  customizedCount =1;
                else  
                  group.extractionFieldIds.map((ids) =>{
                    if(customizedVisibility[ids]==true)
                    customizedCount++;
                  });
                if (groupFieldsCount !== 0 && customizedCount >0)  
                {

                  let newGroupIndex = groupIndex + customVisibility == false ? groupFieldsCount:customizedCount;
                  const header = (
                    <th
                      className={`${BLOCK}__template-group ${i === 0 ? 'first-column' : ''}`}
                      key={`template-group-${group.extractionFieldGroupId}`}
                      colSpan={customVisibility == false ? groupFieldsCount : customizedCount}
                    >
                      <div
                        style={{
                          width:
                            columnWidths.slice(groupIndex, newGroupIndex).reduce((acc, curr) => curr + acc, -3) + 'px'
                        }}
                        className={`${BLOCK}__cell-record cell-record`}
                      >
                        {group.extractionFieldGroupName}
                      </div>
                    </th>
                  );
                  groupIndex = newGroupIndex;

                  return header;
                }
              })}
            </tr>
            <tr className="no-border">
              {customVisibility === false &&  isDocumentToggleSwitchOnOff===false
                ? columnOptions.visibility[COLUMN_OPTIONS.DOCUMENT_NAME] && (
                    <th
                      className={`${BLOCK}__column ${BLOCK}__column--header document ${columnOptions.frozen[
                        COLUMN_OPTIONS.DOCUMENT_NAME
                      ] && `fixed-column fixed-column--header fixed-column--header-color`}`}
                      key="0"
                      id="table-header-document"
                      style={{ ...(columnWidths && columnWidths[0] && { minWidth: columnWidths[0] + 'px' }) }}
                    >
                      <Button
                        size="icon"
                        className={`btn btn-froze-column`}
                        onClick={() => this.frozeColumn(COLUMN_OPTIONS.DOCUMENT_NAME)}
                      >
                        {columnOptions.frozen[COLUMN_OPTIONS.DOCUMENT_NAME] ? (
                          <Icon width={25} name="pin-active" />
                        ) : (
                          <Icon width={25} name="pin" />
                        )}
                        <ReactTooltip id="extraction-overview.freeze-column " effect="solid" place="top">
                          <FormattedMessage id="extraction-overview.freeze-column " />
                        </ReactTooltip>
                      </Button>
                      <FormattedMessage id="extraction-overview.document-column" />
                      <div className="resizer" onMouseDown={() => this.grabColumn('table-header-document', 0)} />
                      <ExtractionOverviewFilter
                        filterFunction={ids => this.setFiltering('documents', ids)}
                        getFilterOptions={this.getAllDocuments}
                        filters={filtering.key === 'documents' ? filtering.ids : undefined}
                      />
                    </th>
                  )
                : customizedVisibility[COLUMN_OPTIONS.DOCUMENT_NAME] && (
                    <th
                      className={`${BLOCK}__column ${BLOCK}__column--header document ${columnOptions.frozen[
                        COLUMN_OPTIONS.DOCUMENT_NAME
                      ] && `fixed-column fixed-column--header fixed-column--header-color`}`}
                      key="0"
                      id="table-header-document"
                      style={{ ...(columnWidths && columnWidths[0] && { minWidth: columnWidths[0] + 'px' }) }}
                    >
                      <Button
                        size="icon"
                        className={`btn btn-froze-column`}
                        onClick={() => this.frozeColumn(COLUMN_OPTIONS.DOCUMENT_NAME)}
                      >
                        {columnOptions.frozen[COLUMN_OPTIONS.DOCUMENT_NAME] ? (
                          <Icon width={25} name="pin-active" />
                        ) : (
                          <Icon width={25} name="pin" />
                        )}
                        <ReactTooltip id="extraction-overview.freeze-column " effect="solid" place="top">
                          <FormattedMessage id="extraction-overview.freeze-column " />
                        </ReactTooltip>
                      </Button>
                      <FormattedMessage id="extraction-overview.document-column" />
                      <div className="resizer" onMouseDown={() => this.grabColumn('table-header-document', 0)} />
                      <ExtractionOverviewFilter
                        filterFunction={ids => this.setFiltering('documents', ids)}
                        getFilterOptions={this.getAllDocuments}
                        filters={filtering.key === 'documents' ? filtering.ids : undefined}
                      />
                    </th>
                  )}

              {customVisibility === false
                ? columnOptions.visibility[COLUMN_OPTIONS.SAMPLE_NUMBER] && (
                    <th
                      className={`${BLOCK}__column ${BLOCK}__column--header sample-number ${columnOptions.frozen[
                        COLUMN_OPTIONS.SAMPLE_NUMBER
                      ] && `fixed-column fixed-column--header fixed-column--header-color`}`}
                      key="1"
                      id="table-header-sample-number"
                      style={{ ...(columnWidths && columnWidths[1] && { minWidth: columnWidths[1] + 'px' }) }}
                    >
                      <Button
                        size="icon"
                        className={`btn btn-froze-column`}
                        onClick={() => this.frozeColumn(COLUMN_OPTIONS.SAMPLE_NUMBER)}
                      >
                        {columnOptions.frozen[COLUMN_OPTIONS.SAMPLE_NUMBER] ? (
                          <Icon width={25} name="pin-active" />
                        ) : (
                          <Icon width={25} name="pin" />
                        )}
                        <ReactTooltip id="extraction-overview.freeze-column " effect="solid" place="top">
                          <FormattedMessage id="extraction-overview.freeze-column " />
                        </ReactTooltip>
                      </Button>
                      <FormattedMessage id="extraction-overview.sample-column" />
                      <div className="resizer" onMouseDown={() => this.grabColumn('table-header-sample-number', 1)} />
                    </th>
                  )
                : customizedVisibility[COLUMN_OPTIONS.SAMPLE_NUMBER] && (
                    <th
                      className={`${BLOCK}__column ${BLOCK}__column--header sample-number ${columnOptions.frozen[
                        COLUMN_OPTIONS.SAMPLE_NUMBER
                      ] && `fixed-column fixed-column--header fixed-column--header-color`}`}
                      key="1"
                      id="table-header-sample-number"
                      style={{ ...(columnWidths && columnWidths[1] && { minWidth: columnWidths[1] + 'px' }) }}
                    >
                      <Button
                        size="icon"
                        className={`btn btn-froze-column`}
                        onClick={() => this.frozeColumn(COLUMN_OPTIONS.SAMPLE_NUMBER)}
                      >
                        {columnOptions.frozen[COLUMN_OPTIONS.SAMPLE_NUMBER] ? (
                          <Icon width={25} name="pin-active" />
                        ) : (
                          <Icon width={25} name="pin" />
                        )}
                        <ReactTooltip id="extraction-overview.freeze-column " effect="solid" place="top">
                          <FormattedMessage id="extraction-overview.freeze-column " />
                        </ReactTooltip>
                      </Button>
                      <FormattedMessage id="extraction-overview.sample-column" />
                      <div className="resizer" onMouseDown={() => this.grabColumn('table-header-sample-number', 1)} />
                    </th>
                  )}

              {templateExtractionFields.map((ef, idx) =>
                // Below condition is for initial(default) view load where there is no filter
                customVisibility === false ? (
                  !columnOptions.visibility[ef.extractionFieldId] ? (
                    columnOptions.visibility[ef.extractionFieldId] !== false ? (
                      <th
                        className={`${BLOCK}__column ${BLOCK}__column--header`}
                        key={`${idx}-${ef.extractionFieldId}-sample`}
                        id={`table-header-${idx}-${ef.extractionFieldId}`}
                        style={{
                          minWidth: columnWidths[idx + 2] + 'px'
                        }}
                      >
                        <span>{ef.extractionFieldName}</span>
                        <div
                          className="resizer"
                          onMouseDown={() => this.grabColumn(`table-header-${idx}-${ef.extractionFieldId}`, idx + 2)}
                        />
                      </th>
                    ) : null
                  ) : (
                    <th
                      className={`${BLOCK}__column ${BLOCK}__column--header`}
                      key={`${idx}-${ef.extractionFieldId}-sample`}
                      id={`table-header-${idx}-${ef.extractionFieldId}`}
                      style={{
                        minWidth: columnWidths[idx + 2] + 'px'
                      }}
                    >
                      <span>{ef.extractionFieldName}</span>
                      <div
                        className="resizer"
                        onMouseDown={() => this.grabColumn(`table-header-${idx}-${ef.extractionFieldId}`, idx + 2)}
                      />
                    </th>
                  )
                ) : //customized View based on the grid view chosen
                customizedVisibility[ef.extractionFieldId] === true ? (
                  <th
                    className={`${BLOCK}__column ${BLOCK}__column--header`}
                    key={`${idx}-${ef.extractionFieldId}-sample`}
                    id={`table-header-${idx}-${ef.extractionFieldId}`}
                    style={{
                      minWidth: columnWidths[idx + 2] + 'px',
                      border: "1.1px inset #c9c9c9"
                    }}
                  >
                    <span>{ef.extractionFieldName}</span>
                    <div
                      className="resizer"
                      onMouseDown={() => this.grabColumn(`table-header-${idx}-${ef.extractionFieldId}`, idx + 2)}
                    />
                  </th>
                ) : null
              )}
            </tr>
          </thead>
          <tbody>
            {extractions.length
              ? extractions
              .map((section, index) => {
                  const sectionRows = this.sectionRows(section.templateTypeIndex);
                  const sectionRowsArray = Array.from(Array(sectionRows).keys());
                  const distintFiles = section.docNameList;
                  return (
                    <React.Fragment key={`fragment-${section.projectItemId}-${index}`}>
                      <tr
                        key={`${index}-${section.projectItemId}-section`}
                        id={`section-reference-${section.projectItemId}`}
                      >
                        {customVisibility === false &&  isDocumentToggleSwitchOnOff===false
                          ? columnOptions.visibility[COLUMN_OPTIONS.DOCUMENT_NAME] && (
                              <td
                                rowSpan={sectionRows + 1}
                                className={`${BLOCK}__section-name ${columnOptions.frozen[
                                  COLUMN_OPTIONS.DOCUMENT_NAME
                                ] && `fixed-column`}`}
                              >
                                <Link
                                  className={`link ${BLOCK}__section-name__link`}
                                  to={`/region/${getCurrentRegion()}/project/${projectId}/document/${
                                    section.projectItemId
                                  }`}
                                >
                                  <div
                                    className={`cell-record`}
                                    style={{ WebkitLineClamp: sectionRows === 1 ? 1 : sectionRows * 2 }}
                                  >
                                    {}
                                  </div>
                                </Link>
                                {section.docNameList &&
                                  distintFiles.map((doc, index) => {
                                    return (
                                      <Link
                                        className={`link ${BLOCK}__section-name__link`}
                                        to={`/region/${getCurrentRegion()}/project/${projectId}/document/${
                                          doc.DocumentId
                                        }`}
                                      >
                                        {index === 0 ? doc.DocumentName : ', ' + doc.DocumentName}
                                      </Link>
                                    );
                                  })  }
                              </td>
                            )
                          : customizedVisibility[COLUMN_OPTIONS.DOCUMENT_NAME] && (
                              <td
                                rowSpan={sectionRows + 1}
                                className={`${BLOCK}__section-name ${columnOptions.frozen[
                                  COLUMN_OPTIONS.DOCUMENT_NAME
                                ] && `fixed-column`}`}
                              >
                                <Link
                                  className={`link ${BLOCK}__section-name__link`}
                                  to={`/region/${getCurrentRegion()}/project/${projectId}/document/${
                                    section.projectItemId
                                  }`}
                                >
                                  <div
                                    className={`cell-record`}
                                    style={{ WebkitLineClamp: sectionRows === 1 ? 1 : sectionRows * 2 }}
                                  >
                                    {section.docNameList &&
                                      distintFiles.map((doc, index) => {
                                        return (
                                          <Link
                                            className={`link ${BLOCK}__section-name__link`}
                                            to={`/region/${getCurrentRegion()}/project/${projectId}/document/${
                                              doc.DocumentId
                                            }`}
                                          >
                                            {index === 0 ? doc.DocumentName : ', ' + doc.DocumentName}
                                          </Link>
                                        );
                                      })  }
                                  </div>
                                </Link>
                              </td>
                            )}
                        {customVisibility === false
                          ? columnOptions.visibility[COLUMN_OPTIONS.SAMPLE_NUMBER] && (
                              <td
                                rowSpan={sectionRows + 1}
                                className={`${BLOCK}__sample ${columnOptions.frozen[COLUMN_OPTIONS.SAMPLE_NUMBER] &&
                                  `fixed-column`}`}
                              >
                                {section.sampleNumber}
                              </td>
                            )
                          : customizedVisibility[COLUMN_OPTIONS.SAMPLE_NUMBER] && (
                              <td
                                rowSpan={sectionRows + 1}
                                className={`${BLOCK}__sample ${columnOptions.frozen[COLUMN_OPTIONS.SAMPLE_NUMBER] &&
                                  `fixed-column`}`}
                              >
                                {section.sampleNumber}
                              </td>
                            )}
                      </tr>
                      {sectionRowsArray.map((e, i) => {
                        return this.renderRecords(e, section.extractionFieldDetails, section.projectItemId, index, i);
                      })}
                    </React.Fragment>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    ) : ( 
      <div className={`${BLOCK}__empty-state`}>        
        <FormattedMessage id={'extraction-overview.empty-description'}>
          {description => (
            <EmptyState
              title={<FormattedMessage id="extraction-overview.empty-tile" />}
              description={description}
              img={emptyStateImage}
            />
          )}
        </FormattedMessage>
      </div>
    );
  }
}
export default ExtractionOverviewGrid;
