import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Button from 'components/shared/single-click-button';
import { getCurrentRegion } from 'store/api';
import constants from 'utils/constants';
import ProjectDateFormatSelection from 'components/project-creation/shared/project-date-fromat-selection';

const { ProjectStateIds } = constants;
const { TEMPLATE_LIST } = constants.TemplateSteps;

// Custom next button handler for template page
class ButtonNext extends Component {
  static propTypes = {
    history: PropTypes.object,
    isGuided: PropTypes.bool,
    latestTemplateFolder: PropTypes.number,
    project: PropTypes.any,
    step: PropTypes.number,
    updateProject: PropTypes.func
  };

  state = {
    disabled: false,
  };


  onUploadFiles = () => {
    const { project, updateProject, latestTemplateFolder } = this.props;
    const folder = latestTemplateFolder > 0 ? latestTemplateFolder : 'root';
    this.setState({ disabled: true });
    // Change project state to 'started'
    return updateProject({
      projectId: project.projectId,
      projectStateId: ProjectStateIds.started
    }).then(() => {
      this.props.history.push(`/region/${getCurrentRegion()}/project/${project.projectId}/folder/${folder}`);
    });
  };



  render() {
    const { isGuided, step, project } = this.props;
    const { disabled } = this.state;
    return isGuided ? null : step === TEMPLATE_LIST ? (
      <div>
        <Button
          size="medium"
          className="btn btn-primary btn-no-margin"
          disabled={disabled}
          onClick={this.onUploadFiles}
        >
          <FormattedMessage id="main-section.footer.documents-button" />
        </Button>

      </div>
    ) : null;
  }
}

const NextButton = withRouter(ButtonNext);
export default NextButton;
