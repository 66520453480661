import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { sortBy } from 'underscore';
import Button from 'components/shared/single-click-button';
import Checkbox from 'components/shared/form/checkbox';
import Constants from 'utils/constants';

/** Document type selector in the templates section of a project creation page */
class DocumentType extends Component {
  static propTypes = {
    changeSelectedGroups: PropTypes.func,
    groupData: PropTypes.object,
    submitFunction: PropTypes.func,
    templateType: PropTypes.object
  };

  state = {
    selectedGroups: []
  };

  onGroupChange = group => {
    const { changeSelectedGroups } = this.props;
    var selectedGroups = this.state.selectedGroups.slice(0);

    if (selectedGroups.some(g => g.id === group.extractionFieldGroupId)) {
      selectedGroups = selectedGroups.filter(g => g.id !== group.extractionFieldGroupId);
    } else {
      selectedGroups.push({
        id: group.extractionFieldGroupId,
        name: group.extractionFieldGroupName
      });
    }

    this.setState({ selectedGroups }, () => changeSelectedGroups(selectedGroups));
  };

  render() {
    const { groupData, templateType } = this.props;
    const { selectedGroups } = this.state;

    return (
      <div className="document-type">
        <div className="document-type__groups">
          <div className="document-type__groups-content">
            {sortBy(groupData.groups, v => v.displayOrder).map(group => (
              <div key={group.extractionFieldGroupId} className="document-type__label">
                <Checkbox
                  className="document-type__label-radio"
                  id={`group-${group.extractionFieldGroupId}`}
                  onChange={() => this.onGroupChange(group)}
                  checked={selectedGroups.some(g => g.id === group.extractionFieldGroupId) ? 'checked' : 'unchecked'}
                />
                <label className="document-type__label-text" htmlFor={`group-${group.extractionFieldGroupId}`}>
                  {group.extractionFieldGroupName}
                  {` (${group.trainedFieldCount})`}
                </label>
              </div>
            ))}

            {templateType.templateCategoryId !== Constants.TemplateCategories.DETAIL_TESTING && (
              <div key="group-other" className="document-type__label">
                <Checkbox
                  className="document-type__label-radio"
                  id="group-other"
                  onChange={() =>
                    this.onGroupChange({
                      extractionFieldGroupId: 'Other',
                      extractionFieldGroupName: 'Other'
                    })
                  }
                  checked={selectedGroups.some(g => g.id === 'Other') ? 'checked' : 'unchecked'}
                />
                <label className="document-type__label-text" htmlFor="group-other">
                  <FormattedMessage id="document-type.option-other" />
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentType;
