import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Icon from 'components/shared/icon';
import ItemList from 'components/file-browser/file-list/item-list';
import TagsPopover from 'components/file-browser/toolbar/tags-popover';
import Constants from 'utils/constants';

/*
 The tags cell of the file list table

 Handles showing and hiding the add/remove tags popover
*/
class TagsCell extends Component {
  static propTypes = {
    expand: PropTypes.func,
    intl: intlShape,
    invalidate: PropTypes.func,
    isExpanded: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    projectItemId: PropTypes.number,
    readOnly: PropTypes.bool,
    tags: PropTypes.array
  };

  state = {
    isHovering: false,
    isEditing: false
  };

  onEditClick = () => {
    this.setState({ isEditing: true });
  };

  onPopoverClose = () => {
    this.setState({ isHovering: false, isEditing: false });
  };

  onMouseEnter = () => {
    this.setState({
      isHovering: true
    });
  };

  onMouseLeave = () => {
    this.setState({
      isHovering: false
    });
  };

  renderTagName = tag => {
    // const { intl: { formatMessage } } = this.props;
    // switch (tag.tagTypeId) {
    //   case TAG_TYPES.SYSTEM_DUPLICATE:
    //     return `${formatMessage({ id: 'file-browser.tags-popover.duplicate-tag' })} - ${tag.tagName}`;
    //   case TAG_TYPES.USER:
    //   default:
    //     return tag.tagName;
    // }
    return tag.tagName;
  };

  render() {
    const { expand, isExpanded, projectItemId, projectId, tags, invalidate, readOnly } = this.props;
    const { isHovering, isEditing } = this.state;

    const filteredTags = tags.filter(
      tag =>
        tag.tagTypeId !== Constants.DocumentTagTypes.LANGUAGE &&
        tag.tagTypeId !== Constants.DocumentTagTypes.DOCUMENT_TYPE
    );

    return readOnly ? (
      <div className="file-list__editable-cell">
        <ItemList
          items={filteredTags.map(tag => tag.tagName)}
          name={`tags-${projectItemId}`}
          isExpanded={isExpanded}
          expand={expand}
        />
      </div>
    ) : (
      <div className="file-list__editable-cell" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {(!isHovering && !isEditing) || filteredTags.length ? (
          <ItemList
            items={filteredTags.map(tag => this.renderTagName(tag))}
            name={`tags-${projectItemId}`}
            isExpanded={isExpanded}
            expand={expand}
          />
        ) : null}
        <div className="file-list__edit-icon-container" data-ignore-mouse-down>
          {(isHovering || isEditing) && (
            <div className="file-list__edit-icon" onClick={this.onEditClick}>
              <Icon name={filteredTags.length ? 'special-edit' : 'special-add'} width={24} />
            </div>
          )}
          {isEditing && (
            <TagsPopover
              onClose={this.onPopoverClose}
              selectedItems={{ [projectItemId]: true }}
              projectId={projectId}
              invalidate={invalidate}
            />
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(TagsCell);
