import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getCurrentRegion } from 'store/api';

import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import { isNumeric } from 'utils/number-utils';
import Checkbox from 'components/shared/form/checkbox';
import Sorting from 'components/shared/sorting/sorting';
import EditableText from 'components/shared/form/editable-text';
import { updateExtractionField } from 'store/api';
import dateUtil from 'utils/dateUtil';
import Permissions from 'permissions/permissions';
import constants from 'utils/constants';

class ExtractionFieldItem extends Component {
  static propTypes = {
    condensed: PropTypes.bool,
    field: PropTypes.object.isRequired,
    groupId: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool,
    isSelected: PropTypes.bool.isRequired,
    onSelectedChange: PropTypes.func.isRequired
  };

  state = {
    fieldName: this.props.field.extractionFieldName
  };

  componentDidUpdate(prevProps) {
    if (this.props.field.extractionFieldName !== prevProps.field.extractionFieldName) {
      this.setState({
        fieldName: this.props.field.extractionFieldName
      });
    }
  }

  onChangeName = fieldName => {
    if (!fieldName || fieldName === this.state.fieldName) {
      return;
    }

    this.setState(
      {
        fieldName
      },
      this.updateExtractionField
    );
  };

  updateExtractionField = () => {
    const { field } = this.props;
    const { fieldName } = this.state;
    updateExtractionField({
      extractionFieldId: field.extractionFieldId,
      extractionFieldName: fieldName
    })
      .then(() => {})
      .catch(() => {
        this.setState({
          fieldName: field.extractionFieldName
        });
      });
  };

  render() {
    const { condensed, field, isSelected, onSelectedChange, groupId, isAdmin } = this.props;
    const { fieldName } = this.state;
    let lastTrainingStatus = (
      <FormattedMessage id="quick-study.extraction-field-list.table.last-training-status.never-trained" />
    );
    switch (field.trainingStateId) {
      case 0:
        lastTrainingStatus = (
          <FormattedMessage id="quick-study.extraction-field-list.table.last-training-status.never-trained" />
        );
        break;
      case 1:
        lastTrainingStatus = (
          <FormattedMessage id="quick-study.extraction-field-list.table.last-training-status.learning" />
        );
        break;
      case 2:
        lastTrainingStatus = (
          <FormattedMessage id="quick-study.extraction-field-list.table.last-training-status.success" />
        );
        break;
      case 3:
        lastTrainingStatus = (
          <FormattedMessage id="quick-study.extraction-field-list.table.last-training-status.failed" />
        );
        break;
      default:
        lastTrainingStatus = (
          <FormattedMessage id="quick-study.extraction-field-list.table.last-training-status.never-trained" />
        );
        break;
    }
    return (
      <tr className={classNames(isSelected && 'extraction-field-list__tr-selected')}>
        {!condensed &&
          isAdmin && (
            <td className="extraction-field-list__td-checkbox">
              <Checkbox
                checked={isSelected ? 'checked' : 'unchecked'}
                id={`field-checkbox-${field.extractionFieldId}`}
                className="file-list__checkbox"
                onChange={onSelectedChange}
              />
            </td>
          )}
        {!condensed && <td className="extraction-field-list__td-id">{field.extractionFieldId}</td>}
        <td className="extraction-field-list__td-name">
          <EditableText
            disableEdit={!Permissions.Global.ExtractionField.canEdit()}
            value={fieldName}
            onSubmit={this.onChangeName}
            renderValue={() => (
              <Link
                className="extraction-field-list__name"
                title={fieldName}
                to={`/region/${getCurrentRegion()}/groups/${groupId}/extraction-fields/${field.extractionFieldId}`}
              >
                {fieldName}
              </Link>
            )}
          />
        </td>
        {!condensed &&
          isAdmin && (
            <td className="extraction-field-list__td-precision">
              {isNumeric(field.precision) ? <FormattedNumber value={field.precision} maximumFractionDigits={2} /> : '-'}
            </td>
          )}
        {!condensed &&
          isAdmin && (
            <td className="extraction-field-list__td-recall">
              {isNumeric(field.recall) ? <FormattedNumber value={field.recall} maximumFractionDigits={2} /> : '-'}
            </td>
          )}
        {!condensed &&
          isAdmin && (
            <td className="extraction-field-list__td-f-score">
              {isNumeric(field.fScore) ? <FormattedNumber value={field.fScore} maximumFractionDigits={2} /> : '-'}
            </td>
          )}
        {!condensed && (
          <td className="extraction-field-list__td-documents">
            {isNumeric(field.numberOfDocuments) ? <FormattedNumber value={field.numberOfDocuments} /> : '-'}
          </td>
        )}
        {!condensed && (
          <td className="extraction-field-list__td-extractions">
            {isNumeric(field.numberOfExtractions) ? <FormattedNumber value={field.numberOfExtractions} /> : '-'}
          </td>
        )}
        {isAdmin && (
          <td className="extraction-field-list__td-last-learned">
            {field.trainedDate ? (
              dateUtil.formatDateTime(dateUtil.fromServerDate(field.trainedDate))
            ) : (
              <FormattedMessage id="quick-study.extraction-field-list.table.never-trained" />
            )}
          </td>
        )}
        {isAdmin && <td className="extraction-field-list__td-last-training-status">{lastTrainingStatus}</td>}
        {isAdmin && <td className="extraction-field-list__th-vendor">{ field.externalSystemId == constants.ExternalSystemIds.ABBY ? (
        <FormattedMessage id="quick-study.extraction-field-list.vendor.abby" />) : 
        field.externalSystemId == constants.ExternalSystemIds.KIRA ? (
          <FormattedMessage id="quick-study.extraction-field-list.vendor.kira" />
        ) : (<FormattedMessage id="quick-study.extraction-field-list.vendor.genai" /> 
        )}</td>}
      </tr>
    );
  }
}

export default class ExtractionFieldList extends Component {
  static propTypes = {
    condensed: PropTypes.bool,
    fields: PropTypes.array,
    getExtractionGroup: PropTypes.func,
    groupId: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool,
    onCheckAllChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSelectedFieldChange: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    selectedFields: PropTypes.object.isRequired
  };

  state = {
    sorting: {
      key: 'name',
      ascending: true
    },
    filtering: {
      key: undefined,
      ids: undefined,
      tagOptions: [],
      templateOptions: []
    },
    search: {
      key: undefined,
      query: undefined
    }
  };

  /////////////////////// SORTING FILTERING FUNCTIONS START ///////////////////////
  reloadCurrentGroup() {
    const { filtering, sorting, search } = this.state;

    const groupOptions = {
      pageNum: 1
    };

    if (sorting && sorting.key) {
      groupOptions.sortBy = sorting.key;
      groupOptions.sortOrder = sorting.ascending ? 'ascending' : 'descending';
    }

    if (filtering && filtering.key) {
      groupOptions.filterBy = filtering.key;
      groupOptions.filterIds = filtering.ids;
    }

    if (search && search.key) {
      groupOptions.filterBy = search.key;
      groupOptions.filterString = search.query;
    }

    this.props.getExtractionGroup(groupOptions);
  }

  setSorting = (key, ascending) => {
    const { sorting } = this.state;
    sorting.key = key;
    sorting.ascending = ascending;
    this.setState({ sorting: sorting }, this.reloadCurrentGroup());
  };

  setSearch = (key, query) => {
    const { filtering, search } = this.state;
    filtering.key = undefined;
    filtering.ids = undefined;
    search.key = query.length > 0 ? key : undefined;
    search.query = query.length > 0 ? query : undefined;
    this.setState({ search: search }, this.reloadCurrentGroup());
  };

  /////////////////////// SORTING FILTERING FUNCTIONS END ///////////////////////

  render() {
    const {
      condensed,
      fields,
      groupId,
      isAdmin,
      onCheckAllChange,
      onPageChange,
      onSelectedFieldChange,
      pagination,
      selectedFields
    } = this.props;

    const { search, sorting } = this.state;
    if (!fields) {
      return null;
    }

    let allSelectedStatus;

    if (fields.length !== 0 && fields.length === selectedFields.size) {
      allSelectedStatus = 'checked';
    } else if (selectedFields.size === 0) {
      allSelectedStatus = 'unchecked';
    } else {
      allSelectedStatus = 'indeterminate';
    }
    return (
      <FixedTable className="extraction-field-list" pagination={pagination} onPageChange={onPageChange}>
        <table>
          <thead>
            <tr>
              {!condensed &&
                isAdmin && (
                  <FixedHeader className="extraction-field-list__th-checkbox">
                    <Checkbox
                      checked={allSelectedStatus}
                      className="file-list__checkbox"
                      id="check-all"
                      onChange={onCheckAllChange}
                    />
                  </FixedHeader>
                )}
              {!condensed && (
                <FixedHeader className="extraction-field-list__th-id">
                  <FormattedMessage id="quick-study.extraction-field-list.table-heading.id" />
                  <Sorting
                    sortFunction={ascending => this.setSorting('extractionfieldid', ascending)}
                    sortDirection={sorting.key === 'extractionfieldid' ? sorting.ascending : undefined}
                    searchFunction={query => this.setSearch('extractionfieldid', query)}
                    searchQuery={search.key === 'extractionfieldid' ? search.query : undefined}
                  />
                </FixedHeader>
              )}
              <FixedHeader className="extraction-field-list__th-name">
                <FormattedMessage id="quick-study.extraction-field-list.table-heading.name" />
                <Sorting
                  searchFunction={query => this.setSearch('name', query)}
                  searchQuery={search.key === 'name' ? search.query : undefined}
                  sortFunction={ascending => this.setSorting('name', ascending)}
                  sortDirection={sorting.key === 'name' ? sorting.ascending : undefined}
                />
              </FixedHeader>
              {!condensed &&
                isAdmin && (
                  <FixedHeader className="extraction-field-list__th-precision">
                    <FormattedMessage id="quick-study.extraction-field-list.table-heading.precision" />
                  </FixedHeader>
                )}
              {!condensed &&
                isAdmin && (
                  <FixedHeader className="extraction-field-list__th-recall">
                    <FormattedMessage id="quick-study.extraction-field-list.table-heading.recall" />
                  </FixedHeader>
                )}
              {!condensed &&
                isAdmin && (
                  <FixedHeader className="extraction-field-list__th-f-score">
                    <FormattedMessage id="quick-study.extraction-field-list.table-heading.f-score" />
                  </FixedHeader>
                )}
              {!condensed && (
                <FixedHeader className="extraction-field-list__th-documents">
                  <FormattedMessage id="quick-study.extraction-field-list.table-heading.documents" />
                </FixedHeader>
              )}
              {!condensed && (
                <FixedHeader className="extraction-field-list__th-extractions">
                  <FormattedMessage id="quick-study.extraction-field-list.table-heading.extractions" />
                </FixedHeader>
              )}
              {isAdmin && (
                <FixedHeader className="extraction-field-list__th-last-learned">
                  <FormattedMessage id="quick-study.extraction-field-list.table-heading.last-learned" />
                  <Sorting
                    sortFunction={ascending => this.setSorting('trainedDate', ascending)}
                    sortDirection={sorting.key === 'trainedDate' ? sorting.ascending : undefined}
                  />
                </FixedHeader>
              )}
              {isAdmin && (
                <FixedHeader className="extraction-field-list__th-last-training-status">
                  <FormattedMessage id="quick-study.extraction-field-list.table-heading.last-training-status" />
                  <Sorting
                    sortFunction={ascending => this.setSorting('trainingState', ascending)}
                    sortDirection={sorting.key === 'trainingState' ? sorting.ascending : undefined}
                  />
                </FixedHeader>
              )}
              {isAdmin && (
                  <FixedHeader className="extraction-field-list__th-vendor">
                    <FormattedMessage id="quick-study.extraction-field-list.table-heading.vendor" />
                  </FixedHeader>
                )}
            </tr>
          </thead>
          <tbody>
            {fields.length > 0 ? (
              fields.map((field, index) => {
                return (
                  <ExtractionFieldItem
                    condensed={condensed}
                    field={field}
                    groupId={groupId}
                    isAdmin={isAdmin}
                    isSelected={selectedFields.has(field.extractionFieldId)}
                    key={index}
                    onSelectedChange={onSelectedFieldChange.bind(this, field)}
                  />
                );
              })
            ) : (
              <tr />
            )}
          </tbody>
        </table>
      </FixedTable>
    );
  }
}
