import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component } from 'react';

import { getFolder } from 'store/api';
import { ITEM_TYPES } from 'models/project-item';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import MovePopupConfirmation from 'components/file-browser/toolbar/move-popover-confirmation';
import { getCurrentRegion } from 'store/api';
import ProjectDateFormatSelection from 'components/project-creation/shared/project-date-fromat-selection';

class FolderItem extends Component {
  static propTypes = {
    folder: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onFolderNameClick: PropTypes.func.isRequired,
    onFolderSelected: PropTypes.func.isRequired
  };

  onFolderNameClick = event => {
    const { folder, onFolderNameClick } = this.props;
    this.onFolderSelected();
    event.stopPropagation();
  };

  onFolderSelected = () => {
    const { folder, onFolderSelected } = this.props;
    onFolderSelected(folder);
  };

  render() {
    const { folder, isSelected } = this.props;
    return (
      <div
        onClick={this.onFolderSelected}
        className={classNames('move-popover__folder-item', isSelected && 'move-popover__folder-item--selected')}
      >
        <Icon name="special-folder" width={18} className="move-popover__folder-item-icon" />
        <div className="move-popover__folder-item-name">
          <Button onClick={this.onFolderNameClick}>{folder.itemName}</Button>
        </div>
        {isSelected && <Icon name="special-checked-black" width={12} className="move-popover__folder-item-icon" />}
      </div>
    );
  }
}

class MovePopover extends Component {
  static propTypes = {
    initialFolder: PropTypes.object.isRequired,
    invalidate: PropTypes.func.isRequired,
    moveItems: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.object
  };

  state = {
    currentFolderId: this.props.initialFolder.projectItemId,
    projectItemsById: {},
    isLoadingFolders: false,
    selectedFolder: null,
    isConfirmationWindowOpen: false
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const { projectId } = this.props;
    const { currentFolderId, projectItemsById, isLoadingFolders } = this.state;

    if (!isLoadingFolders && !projectItemsById[currentFolderId]) {
      this.setState({
        isLoadingFolders: true
      });

      getFolder({
        projectId,
        pageSize: 0,
        folderId: currentFolderId === 0 || !currentFolderId ? 'root' : currentFolderId,
        filterKiraFolders: true
      })
        .then(response => {
          this.setState({
            projectItemsById: { ...projectItemsById, [currentFolderId]: response.data },
            isLoadingFolders: false,
            selectedFolder: response.data
          });
        })
        .catch(() => {
          this.setState({
            isLoadingFolders: false
          });
        });
    }
  }

  onFolderNameClick = folder => {
    this.setState({
      currentFolderId: folder.projectItemId
    });
  };

  onFolderSelected = folder => {
    this.setState({
      selectedFolder: folder
    });
  };

  onBack = () => {
    const { currentFolderId, projectItemsById } = this.state;
    const currentFolder = projectItemsById[currentFolderId];

    this.setState({
      currentFolderId: currentFolder.parentId || 'root'
    });
  };

  onApply = () => {
    this.setState({
      isConfirmationWindowOpen: true
    });
  };
  moveTemplate = () => {
    const { projectId, onClose, moveItems, invalidate } = this.props;
    const { selectedFolder } = this.state;
    var selectedTemplateId = this.props.initialFolder.children[0].projectTemplates[0].projectTemplateId;

    moveItems({
      projectId: parseInt(projectId, 10),
      folderId: selectedFolder.projectItemId || 'root',
      projectItemsIdList: this.allowedSelectedItems,
      templateId: selectedTemplateId
    }).then(() => {
      window.location.replace(this.getItemLink());
      invalidate();
   })
    onClose();
  };

  getItemLink = () => {
    const { projectId } = this.props;
    const { selectedFolder } = this.state;

    return `/region/${getCurrentRegion()}/project/${projectId}/folder/${selectedFolder.projectItemId}`;
  };

  closeMovePopupConfirmationWindow = () => {
    this.setState({
      isConfirmationWindowOpen: false
    });
  };

  validateDateFormatSelection = event => {
    this.moveTemplate();

  };

  get selectedItems() {
    const { initialFolder, selectedItems } = this.props;
    return initialFolder.children.filter(projectItem => selectedItems[projectItem.projectItemId]);
  }

  /* Filters the list of selected items, returning only the ones which can be moved into the selected folder */
  get allowedSelectedItems() {
    const { currentFolderId, projectItemsById, selectedFolder } = this.state;
    const currentFolder = projectItemsById[currentFolderId];

    if (!currentFolder) {
      return [];
    }

    return this.selectedItems.filter(
      selectedItem =>
        selectedFolder.projectItemId !== selectedItem.projectItemId && // Can't move a folder into itself
        !currentFolder.parentHierarchy.find(parent => parent.projectItemId === selectedItem.projectItemId) // Can't move a parent into a child
    );
  }

  render() {
    const { onClose, projectId, currentProject } = this.props;
    const {
      currentFolderId,
      projectItemsById,
      selectedFolder,
      isLoadingFolders,
      isConfirmationWindowOpen
    } = this.state;

    const currentFolder = projectItemsById[currentFolderId];

    if (isLoadingFolders || !currentFolder) {
      return (
        <div className="move-popover popover">
          <Icon className="spinner spinner--centered" name="loader" width={70} />
        </div>
      );
    }

    const folders = currentFolder.children.filter(item => item.itemTypeId === ITEM_TYPES.FOLDER);

    return (
      <div className="move-popover popover">
        <div className="move-popover__header popover__header">
          {currentFolder.parentHierarchy && currentFolder.parentHierarchy.length > 0 && (
            <Button onClick={this.onBack} className="btn btn-no-margin icon-button">
              <Icon name="special-back" width={14} />
            </Button>
          )}
          <h3 className="move-popover__title popover__title">
            {currentFolder.itemName ? currentFolder.itemName : <FormattedMessage id="file-browser.move-popover.home" />}
          </h3>
          <Button onClick={onClose} className="btn btn-no-margin icon-button">
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className="move-popover__folders popover__section">
          {folders &&
            folders.map((folder, index) => (
              <FolderItem
                key={index}
                folder={folder}
                onFolderNameClick={this.onFolderNameClick}
                onFolderSelected={this.onFolderSelected}
                isSelected={folder === selectedFolder}
              />
            ))}
        </div>
        <div className="popover__footer">
          <Button
            size="xsmall"
            onClick={this.onApply}
            disabled={!selectedFolder || !this.allowedSelectedItems.length}
            className="btn btn-primary btn-no-margin"
          >
            <FormattedMessage id="file-browser.move-popover.move-here" />
          </Button>
        </div>
        {isConfirmationWindowOpen ? (
          <MovePopupConfirmation
            isConfirmationWindowOpen="true"
            closeMovePopupConfirmation={this.closeMovePopupConfirmationWindow}
            selectedFolder={selectedFolder}
            moveTemplate={this.moveTemplate}
            to={this.getItemLink}
            projectId={projectId}
            currentProject={currentProject}
            validateDateFormatSelection={e => this.validateDateFormatSelection(e)}
          />
        ) : null}
      </div>
    );
  }
}

export default enhanceWithClickOutside(MovePopover);
