import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component } from 'react'; 
import Button from 'components/shared/single-click-button';
import Modal from 'components/shared/modal';
import Icon from 'components/shared/icon';
import * as api from 'store/api';

export class DeleteGridView extends Component {
  static propTypes = {
     deleteGridView: PropTypes.bool,
     closeDeleteGridPopup: PropTypes.func,
     applyDeleteGridPopup: PropTypes.func,
      projectId: PropTypes.string.isRequired,
  };

  handleClickOutside = () => {
    const { closeDeleteGridPopup } = this.props;
    closeDeleteGridPopup();
  };
  
  render() {
     let { deleteGridView,closeDeleteGridPopup,applyDeleteGridPopup } = this.props;
    return deleteGridView && (
      <div className="delete-grid-view">   
        <div className="delete-grid-view__Popup">
        <div className="delete-grid-view__header">
          <span className="delete-grid-view__header">
            <FormattedMessage id="extraction-overview.Delete-grid-view" />
          </span>
          <Button type="button" size="icon" className="modal__close_x icon-button" onClick={closeDeleteGridPopup}>
            <Icon name="special-cross-black" width={18} height={18} />
          </Button>
        </div>
        <br/>
          <div className="delete-grid-view__content">
            <FormattedMessage id="extraction-overview.Display-Delete-Confirmation-Word" />
          </div>
          <br/>
          <div className="delete-grid-view__delete-buttons">
            <Button className="btn btn-primary btn-medium" onClick={closeDeleteGridPopup}>
              <FormattedMessage id="extraction-overview.saveGrid-cancel" />
            </Button>

              <Button className="btn btn-primary btn-medium" onClick={applyDeleteGridPopup}>
                <FormattedMessage id="extraction-overview.deleteGridapply" />
              </Button>    
          </div>
          </div>
          </div>  
    );
  }
}
export default enhanceWithClickOutside(DeleteGridView);
