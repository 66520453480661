const constants = {
  //Access states for extraction fields
  ExtractionAccessTypeIds: {
    public: 1,
    private: 2,
    shared: 3
  },

  //Extraction field group Names
  ExtractionFieldGroupNames: {
    GENERAL_TERMS: 'General Terms',
    LEASES: 'Leases',
    INTEREST_RATE_DERIVATIVES: 'Interest Rate Derivatives',
    PREFERRED_STOCK_AGREEMENTS: 'Preferred Stock Agreements',
    REINSURANCE_AGREEMENTS: 'Reinsurance Agreements',
    DEBT_AGREEMENTS: 'Debt Agreements',
    PRIVATE_EXTRACTION_FIELDS: 'Private Extraction Fields'
  },

  // Document Tag Types
  DocumentTagTypes: {
    USER_CREATED: 2,
    OCR_QUALITY: 3,
    LANGUAGE: 4,
    DOCUMENT_TYPE: 5
  },

  // Local Storage Keys
  LocalStorageKeys: {
    FILE_LIST_PAGE_SIZE: 'file-list-page-size'
  },

  // Project state mappings
  ProjectStateIds: {
    inCreation: 1,
    started: 2,
    legalHold: 3,
    softDelete: 4,
    pendingArchive: 6,
    archived: 7,
    copying: 8
  },

  // Translation state mappings
  TranslationStatus: {
    preUpload: 1,
    uploaded: 2,
    queued: 3,
    inProgress: 4,
    completed: 5,
    uploadError: 6,
    processingFailed: 7,
    canceled: 8
  },

  TRANSLATION_TABS: {
    0: 'translate-document',
    1: 'translate-text',
    2: 'translation-queue'
  },

  ProjectSourceTypes: {
    UserEntered: 'UserEntered',
    MAT: 'MAT',
    MATCloud: 'MATCloud'
  },

  // Search types used in search bar
  SearchTypes: {
    FILE_LIST_SEARCH: 'FILE_LIST_SEARCH',
    COMPARE_VIEWER_SEARCH: 'COMPARE_VIEWER_SEARCH',
    DOCUMENT_VIEWER_SEARCH: 'DOCUMENT_VIEWER_SEARCH',
    PROJECT_LIST_SEARCH: 'PROJECT_LIST_SEARCH',
    GROUPS_SEARCH: 'GROUPS_SEARCH',
    USERS_SEARCH: 'USERS_SEARCH',
    ANALYTICS_SEARCH: 'ANALYTICS_SEARCH'
  },

  // Types of errors in document viewer
  Document_Error_States: {
    REMOVED: 0,
    UNPROCESSED: 1,
    METADATA404: 2,
    LAYOUT404: 3,
    DOCUMENTDELETED: 4
  },

  // Permission levels
  UserRoles: {
    ADMIN_GENERAL: 1,
    ADMIN_SYSTEM: 2,
    ADMIN_ACCESS: 3,
    PROJECT_VISITOR: 4,
    PROJECT_CONTRIBUTOR: 5,
    PROJECT_OWNER: 6,
    // PROJECT_PPMD: 7, //PPMD role was removed in favour of PPM flag on user
    PROJECT_CREATOR: 8,
    EXTRACTION_FIELD_OWNER: 9,
    EXTRACTION_FIELD_CONTRIBUTOR: 10
  },

  // Adobe Analytics DCRs
  EventTypes: {
    PAGE_CHANGE: 'page_load',
    CREATE_PROJECT: 'create_project',
    ITEM_PROCESSED: 'document_processed'
  },

  // Page Names used to map for analytics
  Pages: {
    HOMEPAGE: 'Argus Homepage',
    DOCUMENT_LIST: 'Document List',
    DOCUMENT_VIEWER: 'Document Viewer',
    COMPARISON_LIST: 'Comparison list',
    COMPARISON_VIEWER: 'Comparison Viewer',
    EDIT_EXTRACTIONS: 'Edit Extractions',
    PROJECT_SETTINGS: 'Project Settings',
    PROJECT_CREATION: 'Project Creation',
    PROJECT_COPY: 'Project Copy',
    VISUALS: 'Visuals',
    ANALYTICS: 'Analytics',
    USER_ACTIVATION: 'User Activation',
    USER_MANAGEMENT: 'User Management',
    ADMIN: 'Admin',
    REPORTS: 'Reports',
    TRANSLATION: 'Argus Translate Document',
    TRANSLATE_TEXT: 'Argus Translate Text',
    TRANSLATION_QUEUE: 'Argus Translation queue',
    PROJECT_LIST: 'Argus Extraction',
    EXTRACTION_OVERVIEW: 'Extraction Overview',
    REVIEW_STATUS: 'Review Status',
    TAGS: 'Tags'
  },

  TemplateSteps: {
    SELECT_TEMPLATE_TYPE: 0,
    SELECT_DOC_TYPE: 1,
    CREATE_TEMPLATE: 2,
    ADD_EF: 3,
    TEMPLATE_LIST: 4,
    CUSTOMIZE_TEMPLATE: 5,
    ADD_EF_CUSTOM_TEMPLATE: 6
  },

  TemplateCategories: {
    NON_DETAIL_TESTING: 0,
    NON_EDITABLE_DETAIL_TESTING: 1,
    DETAIL_TESTING: 2,
    NON_EDITABLE_GENAI_DETAIL_TESTING: 3,
    NON_EDITABLE_GENAI_NONDETAIL_TESTING: 4,
    Custom_GENAI_NONDETAIL_TESTING: 5
  },

  ExternalSystemIds: {
    KIRA: 0,
    ABBY: 1,
    GenAI: 2
  },

  SessionTimeout: {
    IDLE_WAIT_TIME_MS: 1500000,
    IDLE_CHECK_INTERVAL_MS: 5000,
    WARNING_TIME_MS: 300000,
    WARNING_COUNTDOWN_INTERVAL_MS: 100
  },

  DateFormats: {
    MMDDYYYY: 'MM/dd/yyyy',
    DDMMYYYY: 'dd/MM/yyyy',
    YYYYMMDD: 'yyyy/MM/dd'
  },

  CustomExtractionFieldGroups :{
    GENAI_CUSTOM_GROUP_SYSTEMNAME : 'GenAI05_Custom'
  },

  TextSelectionBreakCharacters: [32],

  Features: {
    Extraction: 0,
    Translation: 1
  },

  ProjectTypes:{
    EXTRACTION_PROJECT: 1,
    WORKSPACE_PROJECT: 2
  },

  RestrictDateFormatForSpecificContainer : [ "JP" , "IL"],
  
  MyWorkspace: 'My Workspace'

};


export default constants;
