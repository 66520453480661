import { createAction, createReducer } from 'utils/redux-utils';

export const ACTIONS = {
  ADD_OPTION_TO_TOOLBAR: 'argus/ui/ADD_OPTION_TO_TOOLBAR'
};

export const addOptionToToolbar = createAction(ACTIONS.ADD_OPTION_TO_TOOLBAR);

const getCachedOptions = () => {
  const cachedOptions = JSON.parse(sessionStorage.getItem('fileBrowserToolbarOptions'));
  if (
    cachedOptions &&
    Array.isArray(cachedOptions.quickOptions) &&
    Array.isArray(cachedOptions.kebabOptions) &&
    Array.isArray(cachedOptions.staticOptions)
  ) {
    return cachedOptions;
  } else {
    throw new Error('Invalid data from session storage');
  }
};

export const TOOLBAR_OPTIONS = {
  ASSIGN: 'ASSIGN',
  REVIEW: 'REVIEW',
  COMPARE: 'COMPARE',
  CREATE_FOLDER: 'CREATE_FOLDER',
  CREATE_WORKFLOW: 'CREATE_WORKFLOW',
  DELETE: 'DELETE',
  EXPORT: 'EXPORT',
  MOVE: 'MOVE',
  REPROCESS: 'REPROCESS',
  TAG: 'TAG',
  // TEMPLATES: 'TEMPLATES',
  TEMPLATE_CURRENT_FOLDER_LABEL: 'TEMPLATE_CURRENT_FOLDER_LABEL',
  TEMPLATE_CURRENT_FOLDER_DESCRIPTION: 'TEMPLATE_CURRENT_FOLDER_DESCRIPTION',
  EDIT_CURRENT_FOLDER_TEMPLATE: 'EDIT_CURRENT_FOLDER_TEMPLATE'
};

export const TOOLBAR_LABELS = {
  [TOOLBAR_OPTIONS.ASSIGN]: 'file-browser.toolbar.assign-button',
  [TOOLBAR_OPTIONS.COMPARE]: 'file-browser.toolbar.compare-button',
  [TOOLBAR_OPTIONS.CREATE_FOLDER]: 'file-browser.toolbar.create-folder-button',
  [TOOLBAR_OPTIONS.CREATE_WORKFLOW]: 'file-browser.toolbar.create-workflow-button',
  [TOOLBAR_OPTIONS.DELETE]: 'file-browser.toolbar.delete-option',
  [TOOLBAR_OPTIONS.EXPORT]: 'file-browser.toolbar.export-option',
  [TOOLBAR_OPTIONS.MOVE]: 'file-browser.toolbar.move-option',
  [TOOLBAR_OPTIONS.REPROCESS]: 'file-browser.toolbar.reprocess-option',
  [TOOLBAR_OPTIONS.REVIEW]: 'file-browser.toolbar.review-option',
  [TOOLBAR_OPTIONS.TAG]: 'file-browser.toolbar.tags-option',
  // [TOOLBAR_OPTIONS.TEMPLATES]: 'file-browser.toolbar.templates-option',
  [TOOLBAR_OPTIONS.TEMPLATE_CURRENT_FOLDER_LABEL]: 'file-browser.toolbar.template-current-folder.label',
  [TOOLBAR_OPTIONS.TEMPLATE_CURRENT_FOLDER_DESCRIPTION]: 'file-browser.toolbar.template-current-folder.description'
};

export const TOOLBAR_ICONS = {
  [TOOLBAR_OPTIONS.ASSIGN]: 'assign',
  [TOOLBAR_OPTIONS.DELETE]: 'bin',
  [TOOLBAR_OPTIONS.EXPORT]: 'export',
  [TOOLBAR_OPTIONS.MOVE]: 'move',
  [TOOLBAR_OPTIONS.REPROCESS]: 'reprocess',
  [TOOLBAR_OPTIONS.REVIEW]: 'review',
  [TOOLBAR_OPTIONS.TAG]: 'tag',
  // [TOOLBAR_OPTIONS.TEMPLATES]: 'template',
  [TOOLBAR_OPTIONS.EDIT_CURRENT_FOLDER_TEMPLATE]: 'edit'
};

/*
 * Reducer
 */
export const DEFAULT_INITIAL_STATE = {
  // Quick options are visible when a file or folder is selected
  quickOptions: ['DELETE'],
  // Kebab options are visible in the dropdown menu when a file or folder is selected
  kebabOptions: ['ASSIGN', 'TAG', 'REPROCESS', 'MOVE', 'REVIEW'],
  // Static options are always visible
  staticOptions: ['EXPORT']
};

//Lazily create the reducer so that unit tests can prefill sessionStorage with the initial state
export default function() {
  let state;

  try {
    state = getCachedOptions() || DEFAULT_INITIAL_STATE;
  } catch (e) {
    state = DEFAULT_INITIAL_STATE;
  }

  return createReducer(state, {
    [ACTIONS.ADD_OPTION_TO_TOOLBAR]: (state, action) => {
      if (state.kebabOptions.includes(action.payload)) {
        return {
          quickOptions: [action.payload, ...state.quickOptions],
          kebabOptions: state.kebabOptions.filter(o => o !== action.payload),
          staticOptions: state.staticOptions
        };
      } else {
        return state;
      }
    }
  });
}

export function persistFileBrowserToolbarOptions({ ui: { fileBrowserToolbarOptions } }) {
  sessionStorage.setItem('fileBrowserToolbarOptions', JSON.stringify(fileBrowserToolbarOptions));
}
