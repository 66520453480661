import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

const ProjectAnalyticsPermissions = {
  canView: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canExport: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  }
};

export default ProjectAnalyticsPermissions;
