import { connect } from 'react-redux';

import Analytics from 'components/analytics/analytics';

const mapStateToProps = ({ data: { currentProject, search } }) => ({
  currentProject,
  search
});

export default connect(mapStateToProps)(Analytics);
