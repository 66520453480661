import { FormattedMessage } from 'react-intl';
import PropTypes, { func } from 'prop-types';
import React, { Component } from 'react';

import { getItemTemplates, reprocessItem } from 'store/api';
import { getMessages } from 'utils/errors';
import { sortByKey } from 'utils/string-utils';
import Button from 'components/shared/single-click-button';
import enhanceWithClickOutside from 'react-click-outside';
import Icon from 'components/shared/icon';
import TemplatesList from 'components/shared/templates-list/templates-list';
import { ITEM_TYPES } from 'models/project-item';
import socket, { rooms } from 'utils/socket';
import ProjectDateFormatSelection from 'components/project-creation/shared/project-date-fromat-selection';
import CONSTANTS from 'utils/constants';

class ReprocessPopover extends Component {
  static propTypes = {
    currentFolder: PropTypes.object.isRequired,
    invalidate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.object.isRequired,
    isSearchResultsPage: PropTypes.bool.isRequired
  };

  state = {
    isLoading: true,
    isProcessing: false,
    selectedFieldsByTemplateId: {},
    templates: [],
    showDateSelectionPopup: false
  };
  documentRoom = undefined;
  componentDidMount() {
    this.getItemTemplates();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedItems !== prevProps.selectedItems) {
      this.getItemTemplates();
    }
  }

  get selectedItems() {
    const { currentFolder, selectedItems } = this.props;
    return currentFolder.children.filter(projectItem => selectedItems[projectItem.projectItemId]);
  }

  getItemTemplates() {
    const { projectId, isSearchResultsPage } = this.props;
    const selectedItems = this.selectedItems;
    var templateID = !isSearchResultsPage
      ? this.props.currentFolder.parentHierarchy[0].projectTemplateId
      : selectedItems[0].projectTemplates[0].projectTemplateId;

    getItemTemplates({ projectId, items: selectedItems.map(i => i.projectItemId), templateID }).then(response => {
      var templates = response.data;

      //Get all extraction field ids in Sets organised by template id
      //This object is used by the TemplateList component to handle user selection
      var selectedFieldsByTemplateId = {};
      templates.forEach(template => {
        selectedFieldsByTemplateId[template.projectTemplateId] = new Set(
          template.extractionFields.map(field => field.extractionFieldId)
        );
      });

      this.setState({
        isLoading: false,
        templates: sortByKey('name', templates),
        selectedFieldsByTemplateId: selectedFieldsByTemplateId
      });
    });
  }

  setSelectedFields = selectedFieldsByTemplateId => {
    this.setState({
      selectedFieldsByTemplateId
    });
  };


  documentStateUpdated = message => {
    var i = 0;
    for (i = 0; i <= this.props.currentFolder.children.length; i++) {
      if (
        this.props.currentFolder.children[i] &&
        this.props.currentFolder.children[i].projectItemId == message.data.projectItemId
      ) {
        this.props.currentFolder.children[i].itemStateId = message.data.itemStateId;
      }
      }

  };

  closeDateFormatPopUp = () => {
    this.setState({ showDateSelectionPopup: false });
  };
  handleSuccessCallback = status => {
    if (status === 'success') {
      sessionStorage.setItem('isDateFormatSavedWhileReprocess-'+this.props.currentFolder.projectItemId, true);
      this.onSubmit();
    }
  };

  onSubmit = (event) => {
    const { projectId, onClose, currentFolder, selectedItems, isSearchResultsPage, currentProject } = this.props;
    const { selectedFieldsByTemplateId } = this.state;
    const templateCategoryID = currentFolder.templateCategoryID;
    let dateFormatPreference = this.props.currentFolder?.dateFormatPreference;
    let isDateFormatSavedWhileReprocess = dateFormatPreference  != null ? true : sessionStorage.getItem('isDateFormatSavedWhileReprocess-'+this.props.currentFolder.projectItemId);

    if (isSearchResultsPage) {
      var tempCategoryID = 0;
      isDateFormatSavedWhileReprocess = true;
      for (var i = 0; i <= Object.values(selectedItems).length; i++) {
        if (Object.values(selectedItems)[i] === true) {
          tempCategoryID = currentFolder.children.filter(a => a.projectItemId == Object.keys(selectedItems)[i])[0]
            .templateCategoryID;
        }
      }
      if (tempCategoryID === 0) {
        this.setState({ showDateSelectionPopup: false });
        this.setState({ showDateSelectionPopup: false }, () => {
          this.forceUpdate();
        });
      }
      else if ((templateCategoryID == CONSTANTS.TemplateCategories.DETAIL_TESTING || templateCategoryID == CONSTANTS.TemplateCategories.NON_EDITABLE_DETAIL_TESTING) && (!isDateFormatSavedWhileReprocess && (dateFormatPreference == null || dateFormatPreference == '')) && ((CONSTANTS.RestrictDateFormatForSpecificContainer.indexOf(currentProject.containerCode) == -1 ))){
        this.setState({ showDateSelectionPopup: true });
        this.setState({ showDateSelectionPopup: true }, () => {
          this.forceUpdate();
        });
      }
    }

    if (templateCategoryID && (templateCategoryID == CONSTANTS.TemplateCategories.DETAIL_TESTING || templateCategoryID == CONSTANTS.TemplateCategories.NON_EDITABLE_DETAIL_TESTING)&& (!isDateFormatSavedWhileReprocess && (dateFormatPreference == null || dateFormatPreference == '')) && ((CONSTANTS.RestrictDateFormatForSpecificContainer.indexOf(currentProject.containerCode) == -1 ))) {
      this.setState({ showDateSelectionPopup: true });
      this.setState({ showDateSelectionPopup: true }, () => {
        this.forceUpdate();
      });
      this.setState({ isConfirmationWindowOpen: false });
    } else {
      this.setState({ isProcessing: true });

      //Object formatted to be passed to the API
      var apiTemplateSelections = [];
      for (var key in selectedFieldsByTemplateId) {
        var extractionFieldIds = Array.from(selectedFieldsByTemplateId[key]);

        if (extractionFieldIds.length > 0) {
          apiTemplateSelections.push({
            projectTemplateId: parseInt(key, 10),
            extractionFields: extractionFieldIds.map(extractionFieldId => {
              return {
                extractionFieldId: extractionFieldId
              };
            })
          });
        }
      }

      if (!this.documentRoom) {
        var r = 0;
        for (r = 0; r < this.selectedItems.length; r++) {
          this.documentRoom = rooms.document(this.props.projectId, this.selectedItems[r].projectItemId);
          socket.join(this.documentRoom);
          socket.on('document.update.state', this.documentStateUpdated);
        }
      }
      return reprocessItem({
        projectId: projectId,
        items: this.selectedItems
          .filter(item => {
            if (item.itemTypeId === ITEM_TYPES.FOLDER) {
              return true;
            } else if (!item.projectTemplates) {
              return false;
            } else {
              var matchingTemplate = apiTemplateSelections.find(
                template => template.projectTemplateId === item.projectTemplates[0].projectTemplateId
              );
              return matchingTemplate && matchingTemplate.extractionFields.length > 0;
            }
          })
          .map(i => i.projectItemId),
        templates: apiTemplateSelections
      })
        .then(() => {
          socket.on('document.update.state', this.documentStateUpdated);
          this.setState({ isProcessing: false });
          onClose();
        })
        .catch(err => {
          getMessages(err).map(message => {
            if (message.code === 'Error.ReprocessingDisabled') {
              window.location.reload();
            }
          });
        });
    }
  };

  render() {
    const { onClose,projectId,currentFolder } = this.props;
    const { selectedFieldsByTemplateId, templates, isLoading, isProcessing } = this.state;

    if (isLoading) {
      return (
        <div className="reprocess-popover popover">
          <Icon className="spinner spinner--centered" name="loader" width={70} />
        </div>
      );
    }

    // Total number of selected extraction fields across all templates
    const numSelectedFields = Object.values(selectedFieldsByTemplateId).reduce(
      (sum, selectedExtractionFields) => sum + selectedExtractionFields.size,
      0
    );

    return (
      <div className="reprocess-popover popover">
        <div className="reprocess-popover__header popover__header">
          <h3 className="reprocess-popover__title popover__title">
            <FormattedMessage id="file-browser.reprocess-popover.title" />
          </h3>
          <Button onClick={onClose} className="btn btn-no-margin icon-button">
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className="reprocess-popover__instructions">
          <FormattedMessage id="file-browser.reprocess-popover.instructions" />
        </div>
        <div className="reprocess-popover__body popover__section">
          <TemplatesList
            selectedFieldsByTemplateId={selectedFieldsByTemplateId}
            setSelectedFields={this.setSelectedFields}
            templates={templates}
          />
        </div>
        <div className="popover__footer">
          <Button size="xsmall" onClick={onClose} className="btn btn-secondary">
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="xsmall"
            onClick={this.onSubmit}
            className="btn btn-primary btn-no-margin"
            disabled={!numSelectedFields || isProcessing}
          >
            <FormattedMessage id="file-browser.reprocess-popover.reprocess" />
          </Button>
        </div>
        <ProjectDateFormatSelection
          showProjectDateFormatSelection={this.state.showDateSelectionPopup}
          projectId={projectId}
          closeDateFormatPopUp={this.closeDateFormatPopUp}
          postSuccessCallback={status => this.handleSuccessCallback(status)}
          currentFolder = {currentFolder}
        />
      </div>
    );
  }
}

export default enhanceWithClickOutside(ReprocessPopover);
