import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from 'components/shared/single-click-button';
import Avatar from 'components/shared/avatar';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';

export default class ChangeRoleModal extends Component {
  static propTypes = {
    dropdown: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  state = {
    role: this.props.user.roles[0].roleId
  };

  onRoleChange = role => {
    this.setState({
      role
    });
  };

  onSubmit = () => {
    const { onSubmit, user } = this.props;
    const { role } = this.state;
    onSubmit(user, role);
  };

  render() {
    const { dropdown: RoleDropdown, onClose, user } = this.props;
    const { role } = this.state;

    return (
      <Modal id="change-role-modal">
        <div className="change-role-modal__content">
          <div className="change-role-modal__header">
            <span className="change-role-modal__title">
              <FormattedMessage id="quick-study.extraction-field-details.groups-tab.change-role" />
            </span>
            <Button className="icon-button" onClick={onClose}>
              <Icon name="special-cross-black" width={14} />
            </Button>
          </div>
          <div className="change-role-modal__middle">
            <div className="change-role-modal__name-container">
              <Avatar initials={(user.firstName[0] || '') + (user.lastName[0] || '')} />
              <span className="change-role-modal__name">{user.preferredFullName}</span>
            </div>
            <div>
              <span className="change-role-modal__role-label">
                <FormattedMessage id="quick-study.extraction-field-details.groups-tab.users-list.role" />
              </span>
              <RoleDropdown onRoleChange={this.onRoleChange} role={role} />
            </div>
          </div>
          <div className="change-role-modal__footer">
            <Button className="btn btn-secondary" onClick={onClose}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button className="btn btn-primary" onClick={this.onSubmit}>
              <FormattedMessage id="common.submit" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
