import moment from 'moment';
import model from 'models/base-model';
import dateUtil from 'utils/dateUtil';

export const UPLOAD_STATES = {
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  UPLOADING: 'UPLOADING'
};

export class Upload extends model({
  errorCode: null,
  id: null,
  loaded: 0,
  name: null,
  state: UPLOAD_STATES.PENDING,
  timestamp: moment(),
  total: 0
}) {
  setProgress({ loaded, total }) {
    return this.merge({
      state: UPLOAD_STATES.UPLOADING,
      loaded,
      total
    });
  }

  setCompleted() {
    return this.merge({
      state: UPLOAD_STATES.COMPLETED
    });
  }

  setFailed(errorCode) {
    return this.merge({
      errorCode,
      state: UPLOAD_STATES.FAILED
    });
  }

  setCancelled() {
    return this.merge({
      state: UPLOAD_STATES.CANCELLED
    });
  }

  static fromData(model) {
    return new Upload({
      ...model,
      timestamp: dateUtil.fromServerDate(model.timestamp)
    });
  }
}
