import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BLOCK } from './constants';
import { Link } from 'react-router-dom';
import CONSTANTS from 'utils/constants';
import LandingPageTable from 'components/landing-page/landing-page-table';
import PropTypes from 'prop-types';
import EmptyState from 'components/file-browser/file-list/empty-state';
import emptyStateImage from 'images/empty_state_project.svg';
import emptyAccessDeniedImage from 'images/empty_state_access_denied.svg';

class TranslationQueue extends React.Component {
  static propTypes = {
    currentLanguage: PropTypes.object,
    isAllowed: PropTypes.bool.isRequired,
    translationList: PropTypes.object.isRequired
  };
  render() {
    const { isAllowed, translationList, currentLanguage } = this.props;

    if (!isAllowed) {
      const link = (
        <a
          href="https://deloitteus.service-now.com/sp?id=kb_article&sys_id=ed99cd41db1357081bfe7c28bf96190f"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="error-page.unauthorized.description.link-label" />
        </a>
      );
      return (
        <EmptyState
          title={<FormattedMessage id="error-page.unauthorized.title" />}
          description={
            <FormattedMessage
              id="error-page.unauthorized.description"
              values={{
                link: link
              }}
            />
          }
          img={emptyAccessDeniedImage}
          customClass="small"
        />
      );
    }

    return (
      <Link
        className={`${BLOCK}__translation-queue`}
        to={{ pathname: '/translation', hash: `#${CONSTANTS.TRANSLATION_TABS[2]}` }}
      >
        {!translationList.isTranslationsLoading && translationList.translations.length > 0 ? (
          <>
            <p className={`${BLOCK}__heading border-bottom`}>
              <FormattedMessage id={`${BLOCK}.translation-queue`} />
            </p>
            <LandingPageTable
              forTable="translations"
              data={translationList.translations.slice(0, 3)}
              linkTo={() => {}}
              currentLanguage={currentLanguage}
            />
          </>
        ) : (
          <div className={`${BLOCK}__empty-state`}>
            <EmptyState
              title={<FormattedMessage id="translations-list.empty-state.title" />}
              description={<FormattedMessage id="translations-list.empty-state.description" />}
              img={emptyStateImage}
            />
          </div>
        )}
      </Link>
    );
  }
}
export default TranslationQueue;
