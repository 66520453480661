import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import constants from 'utils/constants';

import Button from 'components/shared/single-click-button';
import CreateModal from 'components/quick-study/toolbar/create-modal';
import DeleteModal from 'components/quick-study/toolbar/delete-modal';
import ExportModal from 'components/quick-study/toolbar/export-modal';
import GroupPopover from 'components/quick-study/toolbar/group-popover';
import Icon from 'components/shared/icon';
import ImportModal from 'components/quick-study/toolbar/import-modal';
import SharePopover from 'components/quick-study/toolbar/share-popover';
import ExportSidebar from 'containers/quick-study/export-sidebar-container';

export const TOOLBAR_OPTIONS = {
  SHARE: 'SHARE',
  GROUP: 'GROUP',
  LEARN: 'LEARN',
  DELETE: 'DELETE',
  EXPORT: 'EXPORT',
  IMPORT: 'IMPORT',
  CREATE: 'CREATE'
};

/**
 * Toolbar Button
 */
export const ToolbarButton = ({ activeIconName, inActiveIconName, isActive, onClick, tooltipMessageId }) => (
  <Button
    size="icon"
    className={classNames('btn', 'toolbar__icon-button', isActive && 'toolbar__icon-button--active','quick-study-'+inActiveIconName)}
    onClick={onClick}
    data-tip
    data-for={tooltipMessageId}
  >
    <Icon className="toolbar__inactive-icon" name={inActiveIconName} width={20} />
    <Icon className="toolbar__active-icon" name={inActiveIconName} width={20} />
    <ReactTooltip id={tooltipMessageId} effect="solid" place="bottom">
      <FormattedMessage id={tooltipMessageId} />
    </ReactTooltip>
  </Button>
);

ToolbarButton.propTypes = {
  activeIconName: PropTypes.string.isRequired,
  inActiveIconName: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tooltipMessageId: PropTypes.string.isRequired
};

/**
 * Toolbar
 */
export default class Toolbar extends Component {
  static propTypes = {
    createExtractionField: PropTypes.func,
    onSubmitDeleteModal: PropTypes.func,
    onSubmitExportModal: PropTypes.func,
    onSubmitGroupPopover: PropTypes.func,
    onSubmitImportModal: PropTypes.func,
    onSubmitLearn: PropTypes.func,
    onSubmitSharePopover: PropTypes.func,
    selectedFields: PropTypes.array,
    selectedGroups: PropTypes.array,
    visibleOptions: PropTypes.array.isRequired
  };

  static defaultProps = {
    fields: [],
    selectedFields: [],
    selectedGroups: []
  };

  state = {
    activeOption: null,
    isExportSidebarShown: false
  };

  /**
   * Set the active toolbar option
   */
  setActiveOption = option => {
    this.setState({
      activeOption: option
    });
  };

  /**
   * Clear the active toolbar option
   */
  clearActiveOption = () => {
    this.setState({
      activeOption: null
    });
  };

  /**
   * Submit the group popover
   */
  onSubmitGroupPopover = group => {
    const { onSubmitGroupPopover } = this.props;
    this.clearActiveOption();
    return onSubmitGroupPopover(group);
  };

  /**
   * Submit the share popover
   */
  onSubmitSharePopover = users => {
    const { onSubmitSharePopover } = this.props;
    this.clearActiveOption();
    return onSubmitSharePopover(users);
  };

  /**
   * Submit the create modal
   */
  onSubmitCreateModal = data => {
    const { createExtractionField } = this.props;
    this.clearActiveOption();
    return createExtractionField(data);
  };

  /**
   * Submit the delete modal
   */
  onSubmitDeleteModal = () => {
    const { onSubmitDeleteModal } = this.props;
    this.clearActiveOption();
    return onSubmitDeleteModal();
  };

  /**
   * Handle a click on the export button
   */
  onExportClick = () => {
    const { selectedFields, selectedGroups } = this.props;
    if (selectedFields.length || selectedGroups.length) {
      // If there are row selected, show the export modal
      this.setActiveOption(TOOLBAR_OPTIONS.EXPORT);
    } else {
      // Otherwise show the export sidebar (export progress)
      this.setState({
        isExportSidebarShown: true
      });
    }
  };

  /**
   * Submit the export modal
   */
  onSubmitExportModal = () => {
    const { onSubmitExportModal } = this.props;

    return onSubmitExportModal().then(() => {
      this.clearActiveOption();
      this.setState({ isExportSidebarShown: true });
    });
  };

  /**
   * Hanlde closing the export sidebar
   */
  onCloseExportSidebar = () => {
    this.setState({ isExportSidebarShown: false });
  };

  /**
   * Submit the import modal
   */
  onSubmitImportModal = file => {
    const { onSubmitImportModal } = this.props;
    this.clearActiveOption();
    return onSubmitImportModal(file);
  };

  /**
   * Submit learn
   */
  onSubmitLearn = () => {
    const { onSubmitLearn } = this.props;
    this.setActiveOption(TOOLBAR_OPTIONS.LEARN);
    return onSubmitLearn().then(() => this.clearActiveOption());
  };

  render() {
    const { visibleOptions, selectedFields, selectedGroups } = this.props;
    const { activeOption, isExportSidebarShown } = this.state;

    const toolbarButtons = {
      [TOOLBAR_OPTIONS.SHARE]: (
        <div className="btn toolbar__icon-button-wrapper icon-button" key="share">
          <ToolbarButton
            isActive={activeOption === TOOLBAR_OPTIONS.SHARE}
            activeIconName="share"
            inActiveIconName="share"
            onClick={() => this.setActiveOption(TOOLBAR_OPTIONS.SHARE)}
            tooltipMessageId="quick-study.toolbar.share.tooltip"
          />
          {activeOption === TOOLBAR_OPTIONS.SHARE && (
            <SharePopover onClose={this.clearActiveOption} onSubmit={this.onSubmitSharePopover} />
          )}
        </div>
      ),
      [TOOLBAR_OPTIONS.GROUP]: (
        <div className="btn toolbar__icon-button-wrapper icon-button" key="group">
          <ToolbarButton
            isActive={activeOption === TOOLBAR_OPTIONS.GROUP}
            activeIconName="group"
            inActiveIconName="group"
            onClick={() => this.setActiveOption(TOOLBAR_OPTIONS.GROUP)}
            tooltipMessageId="quick-study.toolbar.group.tooltip"
          />
          {activeOption === TOOLBAR_OPTIONS.GROUP && (
            <GroupPopover
              onClose={this.clearActiveOption}
              onSubmit={this.onSubmitGroupPopover}
              expectedExternalSystemId={constants.ExternalSystemIds.KIRA}
            />
          )}
        </div>
      ),
      [TOOLBAR_OPTIONS.LEARN]: (
        <div className="btn toolbar__icon-button-wrapper icon-button" key="learn">
          <ToolbarButton
            isActive={activeOption === TOOLBAR_OPTIONS.LEARN}
            activeIconName="learn"
            inActiveIconName="learn"
            onClick={this.onSubmitLearn}
            tooltipMessageId="quick-study.toolbar.learn.tooltip"
          />
        </div>
      ),
      [TOOLBAR_OPTIONS.DELETE]: (
        <div className="btn toolbar__icon-button-wrapper critical-icon toolbar__critical-icon" key="delete">
          <ToolbarButton
            isActive={activeOption === TOOLBAR_OPTIONS.DELETE}
            activeIconName="bin"
            inActiveIconName="bin"
            onClick={() => this.setActiveOption(TOOLBAR_OPTIONS.DELETE)}
            tooltipMessageId="quick-study.toolbar.delete.tooltip"
          />
        </div>
      ),
      [TOOLBAR_OPTIONS.EXPORT]: (
        <div className="btn toolbar__icon-button-wrapper icon-button" key="export">
          <ToolbarButton
            isActive={activeOption === TOOLBAR_OPTIONS.EXPORT}
            activeIconName="export"
            inActiveIconName="export"
            onClick={this.onExportClick}
            tooltipMessageId="quick-study.toolbar.export.tooltip"
          />
        </div>
      ),
      [TOOLBAR_OPTIONS.IMPORT]: (
        <div className="btn toolbar__icon-button-wrapper icon-button" key="import">
          <ToolbarButton
            isActive={activeOption === TOOLBAR_OPTIONS.IMPORT}
            activeIconName="import"
            inActiveIconName="import"
            onClick={() => this.setActiveOption(TOOLBAR_OPTIONS.IMPORT)}
            tooltipMessageId="quick-study.toolbar.import.tooltip"
          />
        </div>
      ),
      [TOOLBAR_OPTIONS.CREATE]: (
        <Button
          size="large"
          key="create"
          onClick={() => this.setActiveOption(TOOLBAR_OPTIONS.CREATE)}
          className="btn btn-secondary btn-no-margin "
        >
          <FormattedMessage id="quick-study.toolbar.create-extraction-field-button" />
        </Button>
      )
    };

    return (
      <div className="quick-study-browser__icon-button-container">
        {visibleOptions.map(option => toolbarButtons[option])}
        {activeOption === TOOLBAR_OPTIONS.CREATE && (
          <CreateModal onClose={this.clearActiveOption} onSubmit={this.onSubmitCreateModal} />
        )}
        {activeOption === TOOLBAR_OPTIONS.DELETE && (
          <DeleteModal
            selectedGroups={selectedGroups}
            selectedFields={selectedFields}
            onClose={this.clearActiveOption}
            onSubmit={this.onSubmitDeleteModal}
          />
        )}
        {activeOption === TOOLBAR_OPTIONS.EXPORT && (
          <ExportModal onCancel={this.clearActiveOption} onSubmit={this.onSubmitExportModal} />
        )}
        {activeOption === TOOLBAR_OPTIONS.IMPORT && (
          <ImportModal onCancel={this.clearActiveOption} onSubmit={this.onSubmitImportModal} />
        )}
        {isExportSidebarShown && <ExportSidebar onCloseClick={this.onCloseExportSidebar} />}
      </div>
    );
  }
}
