import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from 'components/shared/icon';

class Breadcrumbs extends Component {
  static propTypes = {
    breadcrumbs: PropTypes.array.isRequired
  };

  render() {
    const { breadcrumbs } = this.props;
    return (
      <nav className="breadcrumbs">
        {breadcrumbs.map(
          (item, i) =>
            i !== breadcrumbs.length - 1 ? (
              <Fragment key={i}>
                <Link className="breadcrumbs__item breadcrumbs__link" to={item.link} title={item.name}>
                  {item.name}
                </Link>
                <div className="breadcrumbs__separator">
                  <Icon name="special-arrow-right" width={6} height={12} />
                </div>
              </Fragment>
            ) : (
              <span title={item.name} className="breadcrumbs__item" key={i}>
                {item.name}
              </span>
            )
        )}
      </nav>
    );
  }
}

export default Breadcrumbs;
