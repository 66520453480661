import { connect } from 'react-redux';

import { pushUndoStack, popUndoStack, clearUndoStack } from 'store/document-viewer-undo';
import DocumentViewer from 'components/document-viewer/document-viewer';
import {
  clearCurrentDocument,
  getCurrentDocument,
  refreshCurrentDocument,
  unsubscribeCurrentDocument,
  createExtractionFieldRecord,
  createExtractionField,
  addExtractionField
} from 'store/current-document';
import { getCurrentDocumentLayout } from 'store/current-document-layout';

const mapStateToProps = ({
  ui: { documentViewerUndo },
  data: { currentSearchFolder, currentProject, currentDocument, currentDocumentLayout }
}) => ({
  documentViewerUndo,
  currentSearchFolder,
  currentProject,
  currentDocument,
  currentDocumentLayout
});

const mapDispatchToProps = {
  popUndoStack,
  pushUndoStack,
  clearUndoStack,
  clearCurrentDocument,
  getCurrentDocument,
  getCurrentDocumentLayout,
  refreshCurrentDocument,
  createExtractionFieldRecord,
  unsubscribeCurrentDocument,
  createExtractionField,
  addExtractionField
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentViewer);
