import { connect } from 'react-redux';

import { loadDateFormat } from 'store/intl/loadDateFormat';
import { setDateFormat } from 'store/intl/currentDateFormat';
import Dateselect from 'components/header/account-dropdown/Date-select/Date-select';

const mapStateToProps = ({ data: { loadDateFormat: { Dateformats,isLoading },currentDateFormat:{currentDateFormat}}}) => ({
  Dateformats,
  isLoading,
  currentDateFormat
});

const mapDispatchToProps = {

  loadDateFormat,
  setDateFormat
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dateselect);
