import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Unauthorized from 'components/pages/errors/unauthorized';

const mapStateToProps = ({ data: { currentUser } }) => ({
  currentUser
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Unauthorized)
);
