import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';

const ArgusTrainingModal = ({ closeFunction, trainingTargetDocumentsNewField }) => (
  <div className="modal__content modal__content--small argus-training-modal">
    <h3>
      <FormattedMessage id="argus-training-modal.title" />
    </h3>
    <ul>
      <li>
        <FormattedMessage id="argus-training-modal.message-line-1" values={{ trainingTargetDocumentsNewField }} />
      </li>
      <li>
        <FormattedMessage id="argus-training-modal.message-line-2" />
      </li>
      <li>
        <FormattedMessage id="argus-training-modal.message-line-3" />
      </li>
      <li>
        <FormattedMessage id="argus-training-modal.message-line-4" />
      </li>
    </ul>
    <div className="modal__footer">
      <Button className="btn btn-primary btn-no-margin" onClick={closeFunction}>
        <FormattedMessage id="argus-training-modal.button" />
      </Button>
    </div>
  </div>
);

ArgusTrainingModal.propTypes = {
  closeFunction: PropTypes.func,
  trainingTargetDocumentsNewField: PropTypes.number
};

export default ArgusTrainingModal;
