import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/shared/single-click-button';
import Modal from 'components/shared/modal';
import Checkbox from 'components/shared/form/checkbox';

class ConfirmModal extends Component {
  static propTypes = {
    body: PropTypes.any,
    cancelText: PropTypes.any,
    checkboxCopy: PropTypes.any,
    className: PropTypes.string,
    confirmText: PropTypes.any,
    disableSubmit: PropTypes.bool,
    message: PropTypes.any,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.any
  };

  state = {
    isChecked: true
  };

  onCheckboxChange = () => this.setState({ isChecked: !this.state.isChecked });

  onSubmit = e => {
    const { onSubmit, checkboxCopy } = this.props;
    if (checkboxCopy) {
      const { isChecked } = this.state;
      return onSubmit(e, isChecked);
    }
    return onSubmit(e);
  };

  onCancel = e => this.props.onCancel(e);

  render() {
    const { className, disableSubmit, title, message, body, cancelText, confirmText, checkboxCopy } = this.props;
    const { isChecked } = this.state;
    const checkboxState = isChecked ? 'checked' : 'unchecked';
    return (
      <Modal>
        <div className={classNames('modal__content', 'modal__content--small', 'confirm-modal', className)}>
          <h3 className="confirm-modal__title">{title}</h3>
          {message && <p>{message}</p>}
          {body && <div className="modal__body">{body}</div>}
          {checkboxCopy && (
            <div className="modal__body__field">
              <Checkbox
                checked={checkboxState}
                className="confirm-modal__checkbox"
                id="confirm-modal__checkbox"
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="confirm-modal__checkbox" className="confirm-modal__checkbox-label">
                {checkboxCopy}
              </label>
            </div>
          )}
          <div className="modal__footer">
            {cancelText && (
              <Button className="btn btn-secondary" onClick={this.onCancel}>
                {cancelText}
              </Button>
            )}
            <Button className="btn btn-primary btn-no-margin" onClick={this.onSubmit} disabled={disableSubmit}>
              {confirmText}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ConfirmModal;
