import model from 'models/base-model';

const fromApiModel = data => {
  const _totalDocumentCount = data.totalDocumentCount || 0;
  const _reviewedCount = data.reviewedCount || 0;
  const _notReviewedCount = data.notReviewedCount || 0;
  const _assignedCount = data.assignedCount || 0;
  const _notAssignedCount = data.notAssignedCount || 0;
  const mapping = [
    {
      key: 'total-documents',
      apiKey: null,
      apiValue: null,
      isChartRow: false,
      count: _totalDocumentCount,
      totalCount: _totalDocumentCount
    },
    {
      key: 'reviewed-documents',
      apiKey: 'isReviewed',
      apiValue: 'true',
      isChartRow: true,
      count: _reviewedCount,
      totalCount: _totalDocumentCount
    },
    {
      key: 'not-reviewed-documents',
      apiKey: 'isReviewed',
      apiValue: 'false',
      isChartRow: false,
      count: _notReviewedCount,
      totalCount: _totalDocumentCount
    },
    {
      key: 'assigned-documents',
      apiKey: 'isAssigned',
      apiValue: 'true',
      isChartRow: true,
      count: _assignedCount,
      totalCount: _totalDocumentCount
    },
    {
      key: 'not-assigned-documents',
      apiKey: 'isAssigned',
      apiValue: 'false',
      isChartRow: false,
      count: _notAssignedCount,
      totalCount: _totalDocumentCount
    }
  ];
  return mapping;
};

export class ProjectStatus extends model({
  isLoading: false,
  isLoaded: false,
  error: false,
  data: fromApiModel({})
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }

  /* ERROR */
  setError(error) {
    return this.merge({
      isLoading: false,
      error: error
    });
  }

  /* LOADED */
  setLoaded(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      data: fromApiModel(action.payload)
    });
  }

  clear() {
    return new ProjectStatus();
  }
}
