import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';
import { FormattedMessage } from 'react-intl';
import enhanceWithClickOutside from 'react-click-outside';
import Button from 'components/shared/single-click-button';

const mapStateToProps = ({ data: { currentUser } }) => ({
  currentUser
});

class ReviewButton extends Component {
  static propTypes = {
    clearUndoStack: PropTypes.func,
    currentUser: PropTypes.any,
    disabled: PropTypes.bool,
    documentId: PropTypes.any,
    markDocumentAsNotReviewed: PropTypes.func,
    markDocumentAsReviewed: PropTypes.func,
    projectId: PropTypes.any,
    readOnly: PropTypes.bool,
    refreshData: PropTypes.func,
    reviewers: PropTypes.any
  };

  state = {
    reviewed: false,
    otherReviewers: [],
    showDropdown: false
  };

  componentDidMount = () => {
    this.separateReviewers();
  };

  componentDidUpdate = prevProps => {
    if (this.props.reviewers !== prevProps.reviewers) {
      this.separateReviewers();
    }
  };

  separateReviewers = () => {
    var { currentUser, reviewers } = this.props;
    var others = [];
    var selfReviewed = false;

    for (var i = 0; i < reviewers.length; i++) {
      if (reviewers[i].userId === currentUser.userId) {
        selfReviewed = true;
      } else {
        others.push(reviewers[i]);
      }
    }

    this.setState({ reviewed: selfReviewed, otherReviewers: others });
  };

  toggleDropdown = () => {
    var { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  };

  handleClickOutside() {
    this.setState({ showDropdown: false });
  }

  toggle = () => {
    var { documentId, projectId, currentUser, markDocumentAsNotReviewed, markDocumentAsReviewed } = this.props;
    var { reviewed } = this.state;

    if (reviewed) {
      markDocumentAsNotReviewed(projectId, documentId, currentUser);
    } else {
      markDocumentAsReviewed(projectId, documentId, currentUser);
    }
  };

  render() {
    var { currentUser, readOnly, disabled } = this.props;
    var { reviewed, otherReviewers, showDropdown } = this.state;

    return (
      <div className={`document-review-button ${reviewed ? 'reviewed' : ''}`}>
        <Button
          className="mark-reviewed-button btn btn-secondary btn-no-margin"
          disabled={readOnly || disabled}
          onClick={this.toggle}
        >
          {reviewed ? (
            <FormattedMessage id="document-viewer.toolbar.mark-button-reviewed" />
          ) : (
            <FormattedMessage id="document-viewer.toolbar.mark-button" />
          )}
        </Button>
        {otherReviewers.length === 0 ? null : (
          <Button
            className={`reviewers-dropdown-button btn btn-secondary ${showDropdown ? 'expanded' : ''}`}
            onClick={this.toggleDropdown}
            disabled={readOnly}
          >
            {reviewed ? (
              <Icon name="special-checked-white" width={16} height={16} />
            ) : (
              <Icon name="special-checked-blue" width={16} height={16} />
            )}
            {reviewed ? otherReviewers.length + 1 : otherReviewers.length > 1 ? otherReviewers.length : null}

            <div className={`dropdown ${showDropdown ? 'show' : ''}`}>
              {!reviewed ? null : (
                <div className="reviewer-row">
                  {currentUser.preferredFullName} <FormattedMessage id="document-viewer.toolbar.mark-you" />
                </div>
              )}

              {otherReviewers.map((reviewer, i) => (
                <div key={i} className="reviewer-row">
                  {reviewer.user.preferredFullName}
                </div>
              ))}
            </div>
          </Button>
        )}
      </div>
    );
  }
}

var enhancedReviewButton = enhanceWithClickOutside(ReviewButton);
export default connect(mapStateToProps, null)(enhancedReviewButton);
