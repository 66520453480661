import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import Button from 'components/shared/single-click-button';
import enhanceWithClickOutside from 'react-click-outside';
import { SORT_HEADERS } from 'components/project-list/constants';

class SortByDropdown extends Component {
  static propTypes = {
    onSortClick: PropTypes.func,
    sortValue: PropTypes.string
  };

  state = {
    showSortBy: false
  };

  handleSortClick = () => {
    this.setState({ showSortBy: !this.state.showSortBy });
  };

  handleClickOutside = () => {
    this.setState({ showSortBy: false });
  };

  render() {
    const { sortValue, onSortClick } = this.props;
    return (
      <div>
        <div>
          {this.state.showSortBy ? (
            <Button size="icon" className="icon-button sortby__button-sort"  onClick={this.handleSortClick}>
              <Icon name="sort-alphabetic-ascending" width={18} />
            </Button>
          ) : (
            <Button
              size="icon"
              className="btn toolbar__icon-button sortby__button-sort icon-button"
              onClick={this.handleSortClick}
              data-tip
              data-for="sort.tooltip.title"
            >
              
              <Icon name="sort-alphabetic-ascending" width={18} />
              <ReactTooltip id="sort.tooltip.title" effect="solid" place="bottom">
                <FormattedMessage id="sort.tooltip.title" />
              </ReactTooltip>
            </Button>
          )}
        </div>
        {this.state.showSortBy ? (
          <div className="sortby__container" onClick={this.handleClickOutside}>
            <div className="sortby__title">
              <FormattedMessage id="sort.heading.sortby" />
            </div>
            <div className="sortby__body-container">
              {SORT_HEADERS.map((heading, index) => {
                const isSelected = sortValue === heading.sortName;

                return (
                  <Button
                    key={index}
                    className={`sortby__item ${isSelected ? 'selected' : ''}`}
                    onClick={value => onSortClick(heading.sortName)}
                  >
                    {heading.displayName}
                    {isSelected ? <Icon className="sortby__icon" name="special-checked-black" width={12} /> : null}
                  </Button>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default enhanceWithClickOutside(SortByDropdown);
