import { createAction, createReducer } from 'utils/redux-utils';
import { getDocumentCharacters, getDocumentLayoutMetadata, getDocumentPageCharacters } from 'store/api';
import { DocumentLayout } from 'models/document-viewer/layout';

export const ACTIONS = {
  CURRENT_DOCUMENT_LAYOUT_LOADING: 'argus/ui/CURRENT_DOCUMENT_LAYOUT_LOADING',
  CURRENT_DOCUMENT_LAYOUT_LOADED: 'argus/ui/CURRENT_DOCUMENT_LAYOUT_LOADED',
  CURRENT_DOCUMENT_LAYOUT_ERROR: 'argus/ui/CURRENT_DOCUMENT_LAYOUT_ERROR',
  CURRENT_DOCUMENT_LAYOUT_PAGES_LOADED: 'argus/ui/CURRENT_DOCUMENT_LAYOUT_PAGES_LOADED'
};

export const currentDocumentLayoutLoading = createAction(ACTIONS.CURRENT_DOCUMENT_LAYOUT_LOADING);
export const currentDocumentLayoutLoaded = createAction(ACTIONS.CURRENT_DOCUMENT_LAYOUT_LOADED);
export const currentDocumentLayoutError = createAction(ACTIONS.CURRENT_DOCUMENT_LAYOUT_ERROR);

export const currentDocumentLayoutPagesLoaded = createAction(ACTIONS.CURRENT_DOCUMENT_LAYOUT_PAGES_LOADED);

export const getCurrentDocumentLayout = (projectId, documentId) => dispatch => {
  dispatch(currentDocumentLayoutLoading());
  return getDocumentLayoutMetadata(projectId, documentId)
    .then(response => {
      dispatch(currentDocumentLayoutLoaded(response.data));
    })
    .catch(error => {
      dispatch(currentDocumentLayoutError(error));
    });
};

export const getCurrentDocumentLayoutPage = (projectId, documentId, pageNumber) => dispatch => {
  return getDocumentPageCharacters(projectId, documentId, pageNumber)
    .then(response => {
      dispatch(currentDocumentLayoutPagesLoaded(response.data));
    })
    .catch(error => {
      dispatch(currentDocumentLayoutError(error))
    });
};

export const getCurrentDocumentCompleteLayout = (projectId, documentId) => dispatch => {
  return getDocumentCharacters(projectId, documentId)
    .then(response => {
      dispatch(currentDocumentLayoutPagesLoaded(response.data));
    })
    .catch(error => {
      dispatch(currentDocumentLayoutError(error))
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new DocumentLayout();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.CURRENT_DOCUMENT_LAYOUT_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.CURRENT_DOCUMENT_LAYOUT_LOADED]: (state, action) => {
    return state.setLoaded(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_LAYOUT_ERROR]: (state, action) => {
    return state.setError(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_LAYOUT_PAGES_LOADED]: (state, action) => {
    return state.setPagesLoaded(action.payload);
  }
});
