import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { getCurrentBaseUrl } from 'store/api';

import DocumentDownload from 'components/shared/document-download';

class Footer extends Component {
  static propTypes = {
    aboutArgus: PropTypes.object,
    currentLanguage: PropTypes.object,
    extended: PropTypes.bool
  };

  render() {
    const { extended, aboutArgus, currentLanguage } = this.props;

    const year = moment().format('YYYY');
    const header = { 'X-LANG-CTX': currentLanguage.localeCode };
    const aboutArgusData=aboutArgus?.data?.footerInfo?.termsOfUse
    
    const privacyLink = (
      <DocumentDownload
        linkUrl={
          aboutArgus.data && aboutArgus.data.footerInfo
            ? `${getCurrentBaseUrl()}/${aboutArgus.data.footerInfo.privacy}`
            : ''
        }
        className="footer__link"
        linkBody={<u><FormattedMessage id="global.footer.privacy-link" /></u>}
        apiCallHeaders={header}
        target="_blank"
      />
    );
    const openCookiePrivacyCenter = () => {
      document.getElementById('ot-sdk-btn').click();
    } 

    const cookiesLink = (
      <u><a onClick={openCookiePrivacyCenter} className="footer__link" rel="noopener noreferrer" target="_blank" style={{cursor:"pointer"}}>
          <FormattedMessage id="global.footer.cookie-setting" />
          </a></u>
    );

    // AKA Notices Link
    const thirdPartyLink = (
      <DocumentDownload
        linkUrl={
          aboutArgus.data && aboutArgus.data.footerInfo
            ? `${getCurrentBaseUrl()}/${aboutArgus.data.footerInfo.notices}`
            : ''
        }
        className="footer__link"
        linkBody={<u><FormattedMessage id="global.footer.third-party" /></u>}
        apiCallHeaders={header}
        target="_blank"
      />
    );

    const aboutLink = (
      <u><a href="https://www.deloitte.com/about" className="footer__link" rel="noopener noreferrer" target="_blank">
        www.deloitte.com
      </a></u>
    );

    return (
      <div className="footer">
        <p className="footer__content">
          <span className="left">
            <FormattedMessage
              id="global.footer.copyright-text.left"
              values={{
                year
              }}
            />
          </span>
          <span className="right">
            <FormattedMessage
              id="global.footer.copyright-text.rights"
              values={{
                privacyLink,
                cookiesLink,
                thirdPartyLink
                
              }}
            />   
          </span> 
          <span className="product-version">   
            <FormattedMessage 
                  id="global.header.help-versions"
                  values={{ version: aboutArgus?.data?.productInfo?.productVersion }}
            />
            </span>
        </p>
        {extended && (
          <p className="footer__content footer__content--extended">
            <FormattedMessage
              id="global.footer.extended-text"
              values={{
                aboutLink
              }}
            />
          </p>
        )}
      </div>
    );
  }
}

export default Footer;
