import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { PropTypes } from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component } from 'react';
import UserAutocomplete from 'components/shared/user-autocomplete/user-autocomplete';

import {
  getProjectUsers,
  createProjectItemAssignments,
  deleteProjectItemAssignments,
  getProjectItemAssignments
} from 'store/api';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';

class AssignPopover extends Component {
  _isMounted = false;

  static propTypes = {
    intl: intlShape,
    invalidate: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.object.isRequired
  };

  state = {
    assignments: [],
    isLoading: true,
    isProcessing: false,
    selectedUser: null,
    userName: '',
    users: null
  };

  static defaultProps = {
    invalidate: () => {}
  };

  componentDidMount() {
    this._isMounted = true;

    Promise.all([this.getAssignments(), this.getProjectUsers()]).then(() => {
      this.setState({
        isLoading: false
      });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get selectedItemIds() {
    const { selectedItems } = this.props;

    return Object.keys(selectedItems).filter(id => selectedItems[id]);
  }

  getProjectUsers = () => {
    const { projectId } = this.props;
    return getProjectUsers({ projectId }).then(response => {
      this.setState({
        users: response.data.map(user => user.userDetail).filter(user => user.email && user.displayName)
      });
    });
  };

  getAssignments = () => {
    const { projectId } = this.props;
    return getProjectItemAssignments({
      projectId,
      projectItemIds: this.selectedItemIds
    }).then(response => {
      if (!this._isMounted) return;

      const allAssignments = response.data;
      const assignments = [];
      const userIds = {};

      // Need to merge all assignments that have the same userId to avoid
      // showing duplicates in the UI
      for (let assignment of allAssignments) {
        if (userIds[assignment.userId]) {
          userIds[assignment.userId].projectItemId.push(assignment.projectItemId);
        } else {
          const modified = { ...assignment, projectItemId: [assignment.projectItemId] };
          assignments.push(modified);
          userIds[assignment.userId] = modified;
        }
      }

      this.setState({ assignments });
    });
  };

  searchUsers = keyword => {
    const { users } = this.state;
    return Promise.resolve(users);
  };

  handleClickOutside = () => {
    const { onClose } = this.props;
    onClose();
  };

  onSubmit = event => {
    event.preventDefault();
    this.addAssignment();
  };

  addAssignment = () => {
    const { projectId, invalidate } = this.props;
    const { selectedUser } = this.state;

    this.setState({ isProcessing: true });
    createProjectItemAssignments({
      projectId,
      projectItemIds: this.selectedItemIds,
      userId: selectedUser.userId
    })
      .then(() => {
        this.setState({ isProcessing: false, userName: '', selectedUser: null });
        this.getAssignments();
        invalidate();
      })
      .catch(() => {
        this.setState({ isProcessing: false, userName: '', selectedUser: null });
      });
  };

  removeAssignment = assignment => {
    const { projectId, invalidate } = this.props;

    deleteProjectItemAssignments({
      projectId,
      projectItemIds: assignment.projectItemId,
      userId: assignment.userId
    }).then(() => {
      this.getAssignments();
      invalidate();
    });
  };

  onUserNameChange = event => {
    const userName = event.target.value;

    if (userName) {
      this.setState({ userName });
    } else {
      this.setState({ userName: '', selectedUser: null });
    }
  };

  onSelectUser = (userName, selectedUser) => {
    this.setState({ userName, selectedUser });
  };

  get isAddEnabled() {
    const { assignments, selectedUser, isProcessing } = this.state;

    // Disallow if no user is selected, or the component is in processing state
    if (!selectedUser || isProcessing) return false;

    const selectedUserAssignment = assignments.find(assignment => assignment.userId === selectedUser.userId);

    // If there is not yet an assignment for this user
    if (!selectedUserAssignment) return true;

    // If this user is already assigned to every selected project item, then don't allow them to click the add button
    return !this.selectedItemIds.every(selectedItemId =>
      selectedUserAssignment.projectItemId.find(projectItemId => parseInt(selectedItemId, 10) === projectItemId)
    );
  }

  render() {
    const {
      intl: { formatMessage },
      onClose
    } = this.props;

    const { assignments, userName, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="assign-popover popover">
          <Icon className="spinner spinner--centered" name="loader" width={70} />
        </div>
      );
    }

    return (
      <div className="assign-popover popover">
        <div className="assign-popover__header popover__header">
          <h3 className="assign-popover__title popover__title">
            <FormattedMessage id="file-browser.assign-popover.assignees-label" />
          </h3>
          <Button onClick={onClose} className="btn btn-no-margin icon-button">
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className="popover__section assign-popover__section">
          <form className="assign-popover__add-assignee-form" onSubmit={this.onSubmit}>
            <div className="assign-popover__add-assignee-form-field form-field">
              <UserAutocomplete
                autoFocus={false}
                keyword={userName}
                memberFirmOnly={true}
                onChange={this.onUserNameChange}
                onSelect={this.onSelectUser}
                placeholder={formatMessage({ id: 'file-browser.assign-popover.assignee-input' })}
                searchOnFocus={true}
                searchUsers={this.searchUsers}
                selectedUserName={userName}
                showAllOnEmptySearch={true}
              />
            </div>
            <div className="assign-popover__add-assignee-form-field form-field">
              <Button type="submit" className="btn btn-primary btn-no-margin" disabled={!this.isAddEnabled}>
                <FormattedMessage id="file-browser.assign-popover.add-button" />
              </Button>
            </div>
          </form>
        </div>
        <div className="popover__section assign-popover__section">
          <div className="form-field">
            <label>
              <FormattedMessage id="file-browser.assign-popover.document-assignee-label" />
            </label>
            <div className="assign-popover__pills">
              {assignments.map(assignment => (
                <div className="assign-popover__pill" key={assignment.userId}>
                  <span className="assign-popover__pill-text">{assignment.user.preferredFullName}</span>
                  <Button className="assign-popover__pill-close" onClick={() => this.removeAssignment(assignment)}>
                    <Icon name="special-c-cross" width={18} />
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(AssignPopover));
