import { connect } from 'react-redux';

import { onSearchChange } from 'store/search/search-category';
import CompareSearch from 'components/search/compare-search/compare-search';

const mapStateToProps = ({ data: { search } }) => ({
  search
});

const mapDispatchToProps = {
  onSearchChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareSearch);
