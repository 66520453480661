import model from 'models/base-model';

export class TemplateTypes extends model({
  isLoading: false,
  isLoaded: false,
  error: null,
  types: []
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }

  /* ERROR */
  setError(error) {
    return this.merge({
      error: error
    });
  }

  /* LOADED */
  setLoaded(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      types: action.payload ?? []
    });
  }
}
