import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { PropTypes } from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component } from 'react';
import store from 'store/store';
import { addNotification } from 'store/notifications.js';

import { getExtractionFieldGroups, createExtractionFieldGroup } from 'store/api';
import Button from 'components/shared/single-click-button';
import GroupsAutocomplete from 'components/quick-study/shared/groups-autocomplete';
import Icon from 'components/shared/icon';

/**
 * Group popover
 * Allows the user to add fields to a group, or add the fields of another group to a group
 */
class GroupPopover extends Component {
  static propTypes = {
    intl: intlShape,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    expectedExternalSystemId: PropTypes.number
  };

  state = {
    allGroups: [],
    groupName: '',
    isRequestPending: false
  };

  componentDidMount() {
    getExtractionFieldGroups({ pageSize: 10000 }).then(response => {
      this.setState({ allGroups: response.data });
    });
  }

  /**
   * Close the popover when clicking outside
   */
  handleClickOutside() {
    const { onClose } = this.props;
    onClose();
  }

  /**
   * Submit the popover
   */
  onSubmit = event => {
    const { onSubmit } = this.props;
    const { groupName } = this.state;
    const selectedGroup = this.selectedGroup;
    event.preventDefault();

    if (selectedGroup && !this.isExpectedGroup(selectedGroup)) {
      return store.dispatch(
        addNotification({
          message: <FormattedMessage id="quick-study.add-group.abby-error" />,
          clearOnPageChange: true,
          autohide: 10
        })
      );
    }

    this.setState({ isRequestPending: true });

    if (selectedGroup) {
      // If the group name matches an existing group, submit the modal
      return onSubmit(selectedGroup);
    }

    // If the group name does not match an existing group, first create one
    // Then submit the modal with that group
    return createExtractionFieldGroup({ name: groupName }).then(response => {
      this.setState({ isRequestPending: false });
      onSubmit(response.data);
    });
  };

  /**
   * Handle a change event from the group autocomplete
   */
  onGroupChange = groupName => {
    this.setState({
      groupName
    });
  };

  isExpectedGroup = group => {
    const { expectedExternalSystemId } = this.props;
    return group && group.externalSystemId === expectedExternalSystemId;
  };

  /**
   * Return a group with a name that matches the name in the autocomplete
   */
  get selectedGroup() {
    const { allGroups, groupName } = this.state;
    return allGroups
      .filter(group => group.extractionFieldGroupName)
      .find(group => group.extractionFieldGroupName.toLowerCase() === groupName.toLowerCase());
  }

  render() {
    const {
      onClose,
      intl: { formatMessage }
    } = this.props;
    const { allGroups, groupName, isRequestPending } = this.state;

    const groupNames = allGroups
      .filter(group => group.extractionFieldGroupName && this.isExpectedGroup(group))
      .map(group => group.extractionFieldGroupName);

    return (
      <form className="group-popover popover" onSubmit={this.onSubmit}>
        <div className="group-popover__header popover__header">
          <h3 className="popover__title">
            <FormattedMessage id="quick-study.toolbar.group.title" />
          </h3>
          <Button type="button" onClick={onClose} className="btn btn-no-margin group-popover__clear-button icon-button">
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className="group-popover__list popover__section">
          <div className="form-field relative">
            <label>
              <FormattedMessage id="quick-study.toolbar.group.label" />
            </label>
            <GroupsAutocomplete
              groupNames={groupNames}
              groupName={groupName}
              onGroupChange={this.onGroupChange}
              placeholder={formatMessage({ id: 'quick-study.toolbar.group.placeholder' })}
            />
          </div>
        </div>
        <div className="popover__footer">
          <Button size="xsmall" type="button" onClick={onClose} className="btn btn-secondary">
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="xsmall"
            type="submit"
            disabled={!groupName || isRequestPending}
            className="btn btn-primary btn-no-margin"
          >
            <FormattedMessage id="common.apply" />
          </Button>
        </div>
      </form>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(GroupPopover));
