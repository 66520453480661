import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import Permissions from 'permissions/permissions';

import {
  addGroupToGroup,
  addUserToGroup,
  createExtractionField,
  createExtractionFieldGroupExport,
  deleteExtractionFieldGroup,
  getCurrentRegion,
  getExtractionFieldGroups,
  trainExtractionFields
} from 'store/api';
import Breadcrumbs from 'components/shared/breadcrumbs';
import GroupsList from 'components/quick-study/groups-browser/groups-list';
import Header from 'containers/header/header';
import Icon from 'components/shared/icon';
import setUtil from 'utils/setUtil';
import socket, { rooms } from 'utils/socket';
import Toolbar, { TOOLBAR_OPTIONS } from 'components/quick-study/toolbar/toolbar';
import constants from '../../../utils/constants';

const DEFAULT_TOOLBAR_OPTIONS = [TOOLBAR_OPTIONS.EXPORT, TOOLBAR_OPTIONS.IMPORT, TOOLBAR_OPTIONS.CREATE];

const EXPANDED_TOOLBAR_OPTIONS = [
  TOOLBAR_OPTIONS.SHARE,
  TOOLBAR_OPTIONS.GROUP,
  TOOLBAR_OPTIONS.LEARN,
  TOOLBAR_OPTIONS.DELETE,
  TOOLBAR_OPTIONS.EXPORT,
  TOOLBAR_OPTIONS.IMPORT,
  TOOLBAR_OPTIONS.CREATE
];

const LIMITED_TOOLBAR_OPTIONS = [
  TOOLBAR_OPTIONS.SHARE,
  TOOLBAR_OPTIONS.GROUP,
  TOOLBAR_OPTIONS.LEARN,
  TOOLBAR_OPTIONS.EXPORT,
  TOOLBAR_OPTIONS.IMPORT,
  TOOLBAR_OPTIONS.CREATE
];

/**
 * Groups Browser
 * Handles data fetching and state updates for groups data
 * Wraps the GroupsList and Toolbar Components
 */
class GroupsBrowser extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    getSearch: PropTypes.func,
    intl: intlShape,
    searchView: PropTypes.bool,
    submitImport: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func
  };

  state = {
    groups: null,
    searchResults: null,
    pagination: null,
    requestedPage: 1,
    searchOptions: null,
    selectedGroups: new Set(),
    abbyGroups: new Set()
  };

  componentDidMount() {
    this.componentDidUpdate({}, {});
    socket.join(rooms.extractionFieldGroups());
    socket.on('extractionfieldgroup.updated', this.onGroupUpdated);
    socket.on('extractionfieldgroup.removed', this.onGroupDeleted);

    this.props.getSearch(false);
  }

  componentDidUpdate(prevProps, prevState) {
    // If the page has changed, request the new page
    if (this.state.requestedPage !== prevState.requestedPage) {
      this.getExtractionFieldGroups({ pageNum: this.state.requestedPage });
    }

    // If the list of groups changes, ensure that there are no orpans in the set of selected groups
    if (!prevState.groups !== this.state.groups) {
      const allGroupIds = new Set((this.state.groups || []).map(group => group.extractionFieldGroupId));
      const interection = new Set(setUtil.intersect(allGroupIds, this.state.selectedGroups));
      if (interection.size !== this.state.selectedGroups.size) {
        this.setState({
          selectedGroups: interection
        });
      }
    }
  }

  componentWillUnmount() {
    socket.off('extractionfieldgroup.updated', this.onGroupUpdated);
    socket.off('extractionfieldgroup.removed', this.onGroupDeleted);

    socket.leave(rooms.extractionFieldGroups());
  }

  /**
   * Fetch the data for a given page of the groups list from the API
   */
  getExtractionFieldGroups = groupOptions => {
    const {
      intl: { formatMessage }
    } = this.props;
    const options = this.getParams(groupOptions);

    return getExtractionFieldGroups(options).then(response => {
      const pagination = response.pageInfo;
      const groups = [...response.data];

      if (groups.length === 0) {
        return this.setState({ groups });
      }
      // The first group returned by the api represents 'All'
      groups[0].extractionFieldGroupId = 'all';
      groups[0].extractionFieldGroupName = formatMessage({ id: 'quick-study.group-list.all-extraction-fields' });
      this.setState({
        pagination,
        groups
      });
    });
  };

  /**
   * Get the parameters for the api request, such as search filters and options
   */
  getParams(options) {
    const { requestedPage, searchOptions } = this.state;
    const page = { pageNum: requestedPage };
    let params = page;

    if (searchOptions) {
      params = Object.assign(params, searchOptions);
    }

    if (options) {
      params = Object.assign(params, options);
    }

    return params;
  }

  /**
   * Handle a page change
   */
  onPageChange = requestedPage => {
    this.setState({ requestedPage });
  };

  isAbbyGroup = group => {
    return group.externalSystemId === constants.ExternalSystemIds.ABBY;
  };

  /**
   * Handle a row of the groups list being selected or deselected
   */
  onSelectedGroupChange = group => {
    const { selectedGroups, abbyGroups } = this.state;

    const id = group.extractionFieldGroupId;
    const isAbbyGroup = this.isAbbyGroup(group);
    if (selectedGroups.has(id)) {
      selectedGroups.delete(id);
      if (isAbbyGroup) {
        abbyGroups.delete(id);
      }
    } else {
      selectedGroups.add(id);
      if (isAbbyGroup) {
        abbyGroups.add(id);
      }
    }

    this.setState({ selectedGroups: selectedGroups, abbyGroups: abbyGroups });
  };

  /**
   * Handle all rows of the groups list being selected or deselected
   */
  onCheckAllChange = () => {
    const { groups, selectedGroups } = this.state;

    if (selectedGroups.size === groups.length) {
      this.setState({ selectedGroups: new Set(), abbyGroups: new Set() });
    } else {
      const allIds = groups.map(group => group.extractionFieldGroupId);
      const abbyGroupsIds = groups.filter(this.isAbbyGroup).map(({ extractionFieldGroupId }) => extractionFieldGroupId);
      this.setState({ selectedGroups: new Set(allIds), abbyGroups: new Set(abbyGroupsIds) });
    }
  };

  /**
   * Create a new extraction field given a name and description
   */
  createExtractionField = ({ name, description }) => {
    createExtractionField(name, description).then(() => {
      this.getExtractionFieldGroups({ pageNum: this.state.requestedPage });
    });
  };

  /**
   * Add all the fields in a the selected groups to another group
   */
  addToGroup = toGroup => {
    return Promise.all(
      this.actionableGroupIds.map(fromGroupId =>
        addGroupToGroup({
          toGroupId: toGroup.extractionFieldGroupId,
          fromGroupId
        })
      )
    ).then(() => {
      this.getExtractionFieldGroups({ pageNum: this.state.requestedPage });
    });
  };

  /**
   * Share all the fields in the selected groups with a list of users
   */
  addUsers = pendingUsers => {
    return Promise.all(
      this.actionableGroupIds.map(groupId =>
        Promise.all(
          pendingUsers.map(pendingUser =>
            addUserToGroup({
              groupId,
              userId: pendingUser.user.userId,
              roleId: pendingUser.role
            })
          )
        )
      )
    );
  };

  /**
   * Delete the selected groups
   */
  deleteGroups = () => {
    return Promise.all(
      this.deletableGroups.map(group => deleteExtractionFieldGroup(group.extractionFieldGroupId))
    ).then(() => {
      this.getExtractionFieldGroups({ pageNum: 1 });
    });
  };

  /**
   * Submit an export request for the selected groups
   */
  submitExport = () => {
    return createExtractionFieldGroupExport(this.actionableGroupIds);
  };

  submitImport = file => {
    const { submitImport } = this.props;

    return submitImport({ file });
  };

  /**
   * Submit learning for groups
   */
  submitLearn = () => {
    return trainExtractionFields({
      extractionFieldIds: this.actionableGroupIds,
      isGroup: true
    });
  };

  /**
   * Handle a search from the header search bar
   */
  onSearch = bool => {
    return this.props.getSearch(bool);
  };

  /**
   * Handle a group updated event
   */
  onGroupUpdated = msg => {
    const { groups } = this.state;
    const updatedGroupId = msg.data.extractionFieldGroupId;
    const updatedGroupName = msg.data.extractionFieldGroupName;

    this.setState({
      groups: groups.map(group =>
        // Only update the group from the event
        group.extractionFieldGroupId === updatedGroupId
          ? {
              ...group,
              // Only update the group name
              extractionFieldGroupName: updatedGroupName
            }
          : group
      )
    });
  };

  /**
   * Handle a group deletion event
   */
  onGroupDeleted = msg => {
    const { groups } = this.state;
    const deletedGroupId = msg.data.extractionFieldGroupId;

    if (!deletedGroupId) {
      return;
    }

    this.setState({
      groups: groups.filter(group => group.extractionFieldGroupId !== deletedGroupId)
    });
  };

  /**
   * Return the list of selected groups that are actionable
   * ( filters out 'all extraction fields' )
   */
  get actionableGroups() {
    const { groups, selectedGroups } = this.state;

    return (groups || []).filter(
      ({ extractionFieldGroupId }) => selectedGroups.has(extractionFieldGroupId) && extractionFieldGroupId !== 'all'
    );
  }

  /**
   * Return a boolean of whether the selected are all read only groups
   */
  get readOnlyGroups() {
    const { groups, selectedGroups } = this.state;

    return (groups || [])
      .filter(group => selectedGroups.has(group.extractionFieldGroupId) && group.extractionFieldGroupId !== 'all')
      .every(group => group.isAllFieldsReadOnly === true);
  }

  /**
   * Return a list of the selected that are deletable
   */
  get deletableGroups() {
    const { groups, selectedGroups } = this.state;

    return (groups || []).filter(
      group =>
        selectedGroups.has(group.extractionFieldGroupId) &&
        group.extractionFieldGroupId !== 'all' &&
        !group.isAllFieldsReadOnly
    );
  }

  get actionableGroupIds() {
    return this.actionableGroups.map(({ extractionFieldGroupId }) => extractionFieldGroupId);
  }

  checkOption = option => {
    return (
      option !== TOOLBAR_OPTIONS.LEARN &&
      option !== TOOLBAR_OPTIONS.SHARE &&
      option !== TOOLBAR_OPTIONS.GROUP &&
      option !== TOOLBAR_OPTIONS.IMPORT &&
      option !== TOOLBAR_OPTIONS.CREATE
    );
  };

  /**
   * Return the list of visible toolbar options depending on whether the group is read only or not
   */
  getVisibleOptions = () => {
    if (this.actionableGroupIds.length <= 0) {
      return DEFAULT_TOOLBAR_OPTIONS;
    }

    const { abbyGroups } = this.state;
    // If it's read only, disable the delete toolbar option
    if (this.readOnlyGroups) {
      return abbyGroups.size > 0 ? LIMITED_TOOLBAR_OPTIONS.filter(this.checkOption) : LIMITED_TOOLBAR_OPTIONS;
    }

    return abbyGroups.size > 0 ? EXPANDED_TOOLBAR_OPTIONS.filter(this.checkOption) : EXPANDED_TOOLBAR_OPTIONS;
  };

  render() {
    const { searchView, currentUser } = this.props;
    const { groups, selectedGroups, pagination } = this.state;
    const visibleOptions = this.getVisibleOptions();

    const breadcrumbs = [
      { name: <FormattedMessage id="quick-study.breadcrumbs.groups" />, link: `/region/${getCurrentRegion()}/groups` }
    ];

    if (!groups) {
      return (
        <div className="quick-study">
          <div className="quick-study__browser quick-study-browser">
            <Header {...this.props} inputDisabled fromQuickStudyPage />
            <Icon className="spinner spinner--centered" name="loader" width={80} />
          </div>
        </div>
      );
    }

    // Redirect function so that when a user types a search keyword it navigates to the all extraction page
    if (searchView) {
      return <Redirect to={{ pathname: `/region/${getCurrentRegion()}/groups/all/extraction-fields` }} />;
    }

    return (
      <Fragment>
        <Header
          {...this.props}
          currentPage="global.subheader.admin-dashboard"
          fromQuickStudyPage
          onEnter={() => this.onSearch(true)}
          onExit={() => this.onSearch(false)}
          onBlur={() => this.onSearch(false)}
          getExtractionFieldGroups={this.getExtractionFieldGroups}
        />
        <div className="quick-study">
          <div className="quick-study__browser quick-study-browser">
            <div className="quick-study-browser__header">
              <div className="quick-study-browser__breadcrumbs-container">
                <h2 className="quick-study-browser__title">
                  <FormattedMessage id="quick-study.toolbar.title" />
                </h2>

                
                {/* {Permissions.Global.ExtractionField.canEdit() && (
                  <div className="quick-study__container-indicator  3">
                    <FormattedMessage
                      id="quick-study.toolbar.container-indicator"
                      values={{ containerName: currentUser.containerName }}
                    />
                  </div>
                )} */}
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>

              {Permissions.Global.ExtractionField.ExtractionFieldGroup.canEdit() ? (
                <Toolbar
                  visibleOptions={visibleOptions}
                  selectedGroups={this.actionableGroupIds}
                  createExtractionField={this.createExtractionField}
                  onSubmitGroupPopover={this.addToGroup}
                  onSubmitSharePopover={this.addUsers}
                  onSubmitDeleteModal={this.deleteGroups}
                  onSubmitExportModal={this.submitExport}
                  onSubmitImportModal={this.submitImport}
                  onSubmitLearn={this.submitLearn}
                />
              ) : null}
            </div>

            <div className="quick-study-browser__content">
              <GroupsList
                groups={groups}
                onCheckAllChange={this.onCheckAllChange}
                onPageChange={this.onPageChange}
                onSelectedGroupChange={this.onSelectedGroupChange}
                pagination={pagination}
                selectedGroups={selectedGroups}
                hideCheckboxes={!Permissions.Global.ExtractionField.ExtractionFieldGroup.canEdit()}
                getGroups={options => {
                  this.setState({ searchOptions: options, requestedPage: 1 }, () =>
                    this.getExtractionFieldGroups(options)
                  );
                }}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(GroupsBrowser);
