import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Radio, RadioGroup } from 'components/shared/form/radio';
import Button from 'components/shared/single-click-button';
import ValidatedTextField from 'components/shared/form/validated-text-field';
import { validateComparisonName } from 'store/api';

export default class SelectBaseCompare extends Component {
  _isMounted = false;

  static propTypes = {
    comparedDocumentsList: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  state = { baseDocument: null, comparisonName: '' };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onBaseDocumentChange = value => {
    const { comparisonName } = this.state;

    this.setState({
      comparisonName: this.nameFieldRef.current.state.isDirty ? comparisonName : value.itemName,
      baseDocument: value
    });
  };

  onComparisonNameChange = newValue => {
    this.setState({
      isValid: false,
      isDuplicated: false,
      comparisonName: newValue
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { baseDocument, comparisonName } = this.state;
    onSubmit({ comparisonName, baseDocument });
    return false;
  };

  setFormRef = elem => {
    this.formElem = elem;
  };

  nameFieldRef = React.createRef();

  validateForm = () => {
    if (this.formElem) {
      let isFormValid = this.formElem.checkValidity ? this.formElem.checkValidity() : true;
      const { projectId } = this.props;
      const { comparisonName } = this.state;
      if (isFormValid) {
        validateComparisonName({ projectId, comparisonName }).then(response => {
          if (!this._isMounted) return;

          const { isNonduplicateName, isValidFormat, isValidLength } = response.data;
          const isDuplicated = !isNonduplicateName;
          isFormValid = isFormValid && isValidFormat && isValidLength && isNonduplicateName;
          this.setState({ isFormValid, isDuplicated });
        });
      } else {
        this.setState({ isFormValid });
      }
    }
  };

  render() {
    const { comparedDocumentsList } = this.props;
    const FilteredDocumentsList = [];
    comparedDocumentsList.forEach( document =>{
      if (!FilteredDocumentsList.some(fdocument => fdocument.projectItemId === document.projectItemId)) 
      {
        FilteredDocumentsList.push(document);
      }
      }); 
    const { baseDocument, comparisonName, isProcessing, isFormValid, isDuplicated } = this.state;

    return (
      <form className="select-base-compare" id="select-base-compare" onSubmit={this.onSubmit} ref={this.setFormRef}>
        <div className="select-base-compare__instructions">
          <p>
            <FormattedMessage id="compare-browser.create-form.instructions" />
          </p>
        </div>
        <label
          className="form-input-label select-base-compare__name-input-label"
          htmlFor="form-label select-base-compare__name"
        >
          <FormattedMessage id="compare-sidebar.form.name" />
        </label>

        <ValidatedTextField
          className="select-base-compare__name-input"
          id="select-base-compare__name"
          debounce={true}
          errors={
            !isFormValid && <FormattedMessage id={`compare-sidebar.form.name-${isDuplicated ? 'error' : 'empty'}`} />
          }
          isValid={!isFormValid && !isDuplicated}
          headingId="compare-browser.table-heading.title"
          maxLength="140"
          onChange={this.onComparisonNameChange}
          onValid={this.validateForm}
          value={comparisonName}
          ref={this.nameFieldRef}
        />

        <div className="select-base-compare__radio-group">
          <RadioGroup name="document-select" selectedValue={baseDocument} onChange={this.onBaseDocumentChange}>
            {FilteredDocumentsList.map((document, index) => (
              <div key={index} className="form-field--inline-label select-base-compare__form-field">
                <Radio
                  className="select-base-compare__radio"
                  id={`document-select-option-${document.projectItemId}`}
                  value={document}
                />
                <label
                  className="select-base-compare__radio-label"
                  htmlFor={`document-select-option-${document.projectItemId}`}
                >
                  {document.itemName}
                </label>
              </div>
            ))}
          </RadioGroup>
        </div>
        <div className="select-base-compare__footer">
          <Button
            type="submit"
            disabled={
              !baseDocument || !isFormValid || isProcessing || !comparisonName || comparedDocumentsList.length <= 1
            }
            className="btn btn-primary btn-no-margin"
          >
            <FormattedMessage id="compare-sidebar.form.submit-button" />
          </Button>
        </div>
      </form>
    );
  }
}
