import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import enhanceWithClickOutside from 'react-click-outside';
import { getCurrentRegion, getExtractionRecordMetadata } from 'store/api';

/** Shows XF records' actual text */
const AnalyticsPopupData = ({ array, link }) => {
  return array.map((data, idx) => {
    return (
      <div className="analytics-chart__popup-record" key={idx}>
        <Link
          className="analytics-chart__popup-link"
          to={`${link}?location[]=${data.location[0]}&location[]=${data.location[1]}`}
        >
          {data.extractFieldInstanceData}
        </Link>
      </div>
    );
  });
};

/** Popup for displaying the XF record */
class Popup extends Component {
  static propTypes = {
    content: PropTypes.any,
    link: PropTypes.string,
    onClickOutside: PropTypes.func
  };

  state = {
    data: this.props.content
  };

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({ data: this.props.content });
    }
  }

  handleClickOutside = () => {
    this.props.onClickOutside && this.props.onClickOutside();
  };

  render() {
    return (
      <div className="analytics-chart__popup" ref={el => (this.popup = el)}>
        <AnalyticsPopupData array={this.state.data} link={this.props.link} />
      </div>
    );
  }
}

const AnalyticsPopup = enhanceWithClickOutside(Popup);

/** Main analytics chart containing table data */
class AnalyticsChart extends Component {
  static propTypes = {
    currentProject: PropTypes.object,
    getDocumentResults: PropTypes.func,
    onSelectCategory: PropTypes.func,
    docReports: PropTypes.array.isRequired,
    extractions: PropTypes.array.isRequired,
    projectId: PropTypes.string.isRequired,
    projectTemplateId: PropTypes.any
  };

  state = {
    selectedColumn: null,
    recordMetadata: null,
    showPopup: null,
    isScrolling: false
  };

  componentDidMount() {
    // If the browser does not support position sticky, we are going to manually translate scroll position
    if (CSS && !CSS.supports('position', 'sticky')) {
      this.onTableScroll();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.extractions !== this.props.extractions && prevProps.docReports !== this.props.docReports) {
      // If the browser does not support position sticky, we are going to manually translate scroll position
      if (CSS && !CSS.supports('position', 'sticky')) {
        this.onTableScroll();
      }
    }
  }

  // Method for non position sticky compatible browsers
  onTableScroll = () => {
    // Get left columns, top headers and container to translate
    const container = document.querySelector('.analytics-chart__table-wrapper');
    const leftColumn = [].concat.apply([], document.querySelectorAll('.analytics-chart__name-cell'));
    const topHeaders = [].concat.apply([], document.querySelectorAll('thead th'));
    const translate = (x, y) => 'translate(' + x + 'px, ' + y + 'px)';

    if (container) {
      // Set to scrolling and change position to relative
      this.setState({ isScrolling: true });
      leftColumn.forEach(left => (left.style.position = 'relative'));
      topHeaders.forEach(top => (top.style.position = 'relative'));

      // Add listener and translate the position of the headers and column as we scroll
      container.addEventListener('scroll', e => {
        let x = container.scrollLeft;
        let y = container.scrollTop;

        leftColumn.forEach(left => (left.style.transform = translate(x, 0)));
        topHeaders.forEach(top => (top.style.transform = translate(0, y)));
      });
    }
  };

  componentWillUnmount() {
    if (this.state.isScrolling) {
      document.querySelector('.analytics-chart__table-wrapper').removeEventListener('scroll', this.onTableScroll);
    }
  }

  onSelectRow = idx => {
    if (idx === this.state.selectedColumn) {
      return this.setState({ selectedColumn: null });
    }
    return this.setState({ selectedColumn: idx });
  };

  getExtractionCount(docReport, extraction) {
    const extractionRecords = docReport.extractionRecords || {};

    if (extractionRecords[extraction.extractionFieldId]) {
      return <div className="analytics-chart__record-link">{extractionRecords[extraction.extractionFieldId]}</div>;

      //If the extraction is private
    } else if (extraction.accessTypeId === 2) {
      return <div className="analytics-chart__private-extraction-count" />;
    } else {
      return <div className="analytics-chart__public-extraction-count" />;
    }
  }

  // Helper function that basically takes a long string and divides into chunks of new lines
  // So we can display tooltip in multilines
  insertNewLines = (text, size) => {
    if (!text || text.length <= size) {
      return text;
    }

    return text
      .match(new RegExp('.{1,' + size + '}', 'g'))
      .map(chunk => chunk.concat('\n'))
      .join('');
  };

  // Meta data helper fn that gets the actual text of the XF record
  getExtractionMetadata = (docReport, extraction) => {
    const { projectId } = this.props;
    const { recordMetadata } = this.state;

    const isRecordPresent = recordMetadata
      ? recordMetadata.find(value =>
          value[docReport.projectItemId] ? value[docReport.projectItemId][extraction.extractionFieldId] : false
        )
      : null;

    if (!docReport.extractionRecords || !docReport.extractionRecords[extraction.extractionFieldId]) {
      return;
    }
    if (isRecordPresent) {
      return this.setState({
        showPopup: {
          [docReport.projectItemId]: {
            [extraction.extractionFieldId]: true
          }
        }
      });
    }

    return getExtractionRecordMetadata({
      projectId,
      documentId: docReport.projectItemId,
      extractionFieldId: extraction.extractionFieldId
    }).then(res => {
      const records = recordMetadata || [];
      this.setState({
        recordMetadata: records.concat({ [docReport.projectItemId]: { [extraction.extractionFieldId]: res.data } }),
        showPopup: {
          [docReport.projectItemId]: {
            [extraction.extractionFieldId]: true
          }
        }
      });
    });
  };

  searchForExtractionField = (extractionField, index) => {
    const { onSelectCategory, getDocumentResults, projectId, projectTemplateId } = this.props;

    onSelectCategory({
      key: 'analyticsSeach',
      data: {
        extractionFieldIds: [extractionField.extractionFieldId],
        templateIds: projectTemplateId
      }
    });
    getDocumentResults({
      projectId,
      pageNum: 1,
      extractionFieldIds: [extractionField.extractionFieldId],
      templateIds: projectTemplateId
    });
  };

  render() {
    const { extractions, docReports, projectId } = this.props;
    const { selectedColumn, recordMetadata, showPopup } = this.state;

    return (
      <div className="analytics-chart">
        {extractions.length > 0 && docReports.length > 0 && (
          <div className="analytics-chart__table-wrapper">
            <table className="analytics-chart__table">
              <thead>
                <tr>
                  <th className="analytics-chart__header-name-cell" />
                  {extractions.map((extraction, i) => (
                    <th
                      className="analytics-chart__header-extraction-cell"
                      key={extraction.extractionFieldId}
                      onClick={() => {
                        this.onSelectRow(i);
                        this.searchForExtractionField(extraction, i);
                      }}
                      data-tip
                      data-for={`extraction-name-${extraction.extractionFieldId}`}
                    >
                      <div className="analytics-chart__header-extraction">{extraction.extractionFieldName}</div>
                      <ReactTooltip
                        id={`extraction-name-${extraction.extractionFieldId}`}
                        effect="solid"
                        place="bottom"
                        delayShow={700}
                      >
                        <span className="analytics-chart__tooltip">
                          {this.insertNewLines(extraction.extractionFieldName, 30)}
                        </span>
                      </ReactTooltip>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {docReports.map(docReport => (
                  <tr key={docReport.projectItemId}>
                    <td className="analytics-chart__name-cell" data-tip data-for={`extraction-name-${docReport.name}`}>
                      <Link
                        className="analytics-chart__document-link"
                        to={`/region/${getCurrentRegion()}/project/${projectId}/document/${docReport.projectItemId}`}
                        title={docReport.name}
                      >
                        {docReport.name}
                      </Link>
                    </td>
                    {extractions.map((extraction, yIndex) => {
                      const isRecordPresent = recordMetadata
                        ? recordMetadata.find(value =>
                            value[docReport.projectItemId]
                              ? value[docReport.projectItemId][extraction.extractionFieldId]
                              : false
                          )
                        : null;
                      const data = isRecordPresent
                        ? isRecordPresent[docReport.projectItemId][extraction.extractionFieldId]
                        : null;
                      const showData =
                        showPopup &&
                        showPopup[docReport.projectItemId] &&
                        showPopup[docReport.projectItemId][extraction.extractionFieldId];

                      const documentLink = `/region/${getCurrentRegion()}/project/${projectId}/document/${
                        docReport.projectItemId
                      }`;
                      return (
                        <td
                          className={`analytics-chart__data-cell ${selectedColumn === yIndex ? 'selected' : ''}`}
                          key={extraction.extractionFieldId}
                          onClick={() => this.getExtractionMetadata(docReport, extraction)}
                        >
                          {this.getExtractionCount(docReport, extraction)}

                          {showData ? (
                            <AnalyticsPopup
                              content={data}
                              link={documentLink}
                              onClickOutside={() =>
                                this.setState({
                                  showPopup: {
                                    [docReport.projectItemId]: { [extraction.extractionFieldId]: false }
                                  }
                                })
                              }
                            />
                          ) : null}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {extractions.length > 0 && docReports.length > 0 && (
          <div className="analytics-chart__ghost-name-cell">
            <FormattedMessage id="analytics.heading.document" />
          </div>
        )}

        {extractions.length > 0 && docReports.length > 0 && <div className="analytics-chart__ghost-header" />}
      </div>
    );
  }
}

export default withRouter(AnalyticsChart);
