import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Confirm from 'components/shared/confirm';

/**
 * Delete Extraction field / Delete Group modal
 * Shows a confirmation message before allowing the user to delete the selected groups / fields
 */
class DeleteModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedFields: PropTypes.array,
    selectedGroups: PropTypes.array
  };

  render() {
    const { onClose, onSubmit, selectedGroups, selectedFields } = this.props;

    var title, body;

    if (selectedGroups.length) {
      // Deleting Groups
      const plurality = selectedGroups.length === 1 ? 'singular' : 'plural';
      title = <FormattedMessage id={`quick-study.modal.delete.title.groups.${plurality}`} />;
      body = <FormattedMessage id={`quick-study.modal.delete.body.groups.${plurality}`} />;
    } else if (selectedFields.length) {
      // Deleting Extraction Fields
      const plurality = selectedFields.length === 1 ? 'singular' : 'plural';
      title = <FormattedMessage id={`quick-study.modal.delete.title.fields.${plurality}`} />;
      body = <FormattedMessage id={`quick-study.modal.delete.body.fields.${plurality}`} />;
    } else {
      return '';
    }

    return (
      <Confirm
        onCancel={onClose}
        onSubmit={onSubmit}
        title={title}
        message={body}
        cancelText={<FormattedMessage id="common.cancel" />}
        confirmText={<FormattedMessage id="common.delete" />}
      />
    );
  }
}

export default DeleteModal;
