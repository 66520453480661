import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Icon from 'components/shared/icon';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import enhanceWithClickOutside from 'react-click-outside';
import Constants from 'utils/constants';
import { isNumeric } from 'utils/number-utils';
import Button from 'components/shared/single-click-button';

// Different search menu visibility between search argus and search doc
class SearchMenu extends Component {
  static propTypes = {
    customValue: PropTypes.any,
    intl: intlShape,
    isSearchResultsOpen: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    setType: PropTypes.func,
    fromProjectListPage : PropTypes.bool,
    fromQuickStudyPage : PropTypes.bool,
    fromUserManagementPage : PropTypes.bool
  };

  state = {
    isDocOrCompareView: false,
    isMenuOpen: false,
    defaultSearchValue: this.props.intl.formatMessage({ id: 'global.header.search-button' })
  };

  onVisibilityChange = (value, id, customValue, isDocOrCompareView) => {
    this.props.setType(value);
    this.setState({
      defaultSearchValue: this.props.intl.formatMessage({ id }, { group: customValue }),
      isDocOrCompareView
    });
  };

  setView = () => {
    const { location, customValue, isSearchResultsOpen ,fromProjectListPage} = this.props;
    const { projectId, documentId, compareId, groupId } = this.props.match.params;

    // Pages with project id
    if (projectId) {
      if (documentId && !compareId && !isSearchResultsOpen) {
        return this.onVisibilityChange('DOCUMENT_VIEWER_SEARCH', 'search-bar.options-document', null, true);
      }
      if (compareId) {
        return this.onVisibilityChange('COMPARE_VIEWER_SEARCH', 'search-bar.options-comparison', null, true);
      }
      if (location.pathname.includes('creation') || location.pathname.includes('copy')) {
        return this.onVisibilityChange('PROJECT_LIST_SEARCH', 'search-bar.options-project', null, false);
      }

      if (location.pathname.includes('analytics')) {
        return this.onVisibilityChange('ANALYTICS_SEARCH', 'search-bar.options-analytics', null, false);
      }

      return this.onVisibilityChange('FILE_LIST_SEARCH', 'global.header.search-button', null, false);
    }

    // Pages without project id
    if (location.pathname.includes('groups')) {
      return this.onVisibilityChange(
        'GROUPS_SEARCH',
        isNumeric(groupId) ? 'search-bar.options-group' : 'search-bar.options-group-all',
        customValue,
        false
      );
    }
    if (location.pathname.includes('user-management')) {
      return this.onVisibilityChange('USERS_SEARCH', 'search-bar.options-users', null, false);
    }

    if ((location.pathname.includes('project') || fromProjectListPage)  && !projectId) {
      return this.onVisibilityChange('PROJECT_LIST_SEARCH', 'search-bar.options-project', null, false);
    }
  };

  componentDidMount() {
    this.setView();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params !== this.props.match.params ||
      this.props.customValue !== prevProps.customValue ||
      this.props.isSearchResultsOpen !== prevProps.isSearchResultsOpen
    ) {
      this.setView();
    }
  }

  handleClickOutside = () => {
    this.setState({ isMenuOpen: false });
  };

  onDropdownClick = () => {
    if (this.state.isDocOrCompareView) {
      this.setState({ isMenuOpen: !this.state.isMenuOpen });
    }
  };

  onOptionClick = value => {
    const { intl, setType, match } = this.props;

    if (value === 'document') {
      if (match.params.compareId) {
        setType(Constants.SearchTypes.COMPARE_VIEWER_SEARCH);
      } else {
        setType(Constants.SearchTypes.DOCUMENT_VIEWER_SEARCH);
      }

      this.setState({
        defaultSearchValue: intl.formatMessage({
          id: match.params.compareId ? 'search-bar.options-comparison' : 'search-bar.options-document'
        }),
        isMenuOpen: false
      });
    }
    if (value === 'argus') {
      setType(Constants.SearchTypes.FILE_LIST_SEARCH);

      this.setState({
        defaultSearchValue: intl.formatMessage({ id: 'global.header.search-button' }),
        isMenuOpen: false
      });
    }
  };

  render() {
    const { isDocOrCompareView } = this.state;
    const { documentId, compareId } = this.props.match.params;
    const { fromProjectListPage,fromQuickStudyPage ,fromUserManagementPage} = this.props;

    return (
      <div className={classNames('search-menu__dropdown-menu', (fromProjectListPage ||fromQuickStudyPage ||fromUserManagementPage )&& 'search-menu__dropdown-menu--admin')}>
        <Button
          className={`search-menu__dropdown-button`}
          type="button"
          disabled={!isDocOrCompareView}
          onClick={this.onDropdownClick}
        >
          <span>{this.state.defaultSearchValue}</span>
          {isDocOrCompareView && (
            <Icon className="search-menu__dropdown-button-caret" name="special-arrow-down" width={10} />
          )}
        </Button>
        {this.state.isMenuOpen ? (
          <div className="search-menu__dropdown-options">
            <Button size="large" className="search-menu__dropdown-text" onClick={() => this.onOptionClick('document')}>
              {documentId && !compareId ? (
                <FormattedMessage id="search-bar.options-document" />
              ) : (
                <FormattedMessage id="search-bar.options-comparison" />
              )}
            </Button>
            <Button size="large" className="search-menu__dropdown-text" onClick={() => this.onOptionClick('argus')}>
              <FormattedMessage id="global.header.search-button" />
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(SearchMenu));
