import model from 'models/base-model';

var currentFormatFromDB = localStorage.getItem('currentFormat');
export class CurrentFormat extends model({
  currentFormat: currentFormatFromDB ? currentFormatFromDB : ''
}) {
  currentSelectedFormat(state, action) {
    var currentFormat = action.payload;
    localStorage.setItem('currentFormat', currentFormat);

    return this.merge({
      ...state,
      currentFormat
    });
  }
}
