import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import { FormattedMessage } from 'react-intl';

import Permissions from 'permissions/permissions';

class ProcessingPausedBanner extends PureComponent {
  static propTypes = {
    currentProject: PropTypes.object,
    projectId: PropTypes.any,
    inCreationPage: PropTypes.bool
  };

  render() {
    const { currentProject, inCreationPage } = this.props;

    // When a user is a read only member, don't display the PPMD banner,
    // but user is stil limited to do actions just as if the project is pending archive or archived
    if (!Permissions.Project._States.isReadOnly(currentProject) && !Permissions.Project.canViewApprovalBanner()) {
      return <span />;
    }

    if (!inCreationPage) {
      return (
        <div className="processing-paused-banner">
          <Icon className="processing-paused-banner__icon" name="special-info-outline" width={16} />
          <span className="processing-paused-banner__title">
            <FormattedMessage id="global.banner.processing-paused.title" />
          </span>
          <span className="processing-paused-banner__msg">
            <FormattedMessage id="global.banner.processing-paused.message" />
          </span>
        </div>
      );
    }

    if (inCreationPage) {
      return (
        <div className="processing-paused-banner__creation">
          <Icon className="processing-paused-banner__icon-creation" name="special-info-outline" width={16} />
          <span className="processing-paused-banner__title-creation">
            <FormattedMessage id="global.banner.processing-paused.title" />
          </span>
          <span className="processing-paused-banner__msg-creation">
            <FormattedMessage id="global.banner.processing-paused.message" />
          </span>
        </div>
      );
    }
  }
}

export default ProcessingPausedBanner;
