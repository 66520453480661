import { createAction, createReducer } from 'utils/redux-utils';

import { getUserProgress } from 'store/api';
import { UserProgress } from 'models/review-status/user-progress';

// used to hook onto clear call from search results store
import { ACTIONS as SearchResultsActions } from 'store/search/search-results';

export const ACTIONS = {
  USER_PROGRESS_LOADING: 'argus/ui/USER_PROGRESS_LOADING',
  USER_PROGRESS_LOADED: 'argus/ui/USER_PROGRESS_LOADED',
  USER_PROGRESS_CLEAR: 'argus/ui/USER_PROGRESS_CLEAR'
};

export const userProgressLoading = createAction(ACTIONS.USER_PROGRESS_LOADING);
export const userProgressLoaded = createAction(ACTIONS.USER_PROGRESS_LOADED);
export const userProgressClear = createAction(ACTIONS.USER_PROGRESS_CLEAR);

export const getData = projectId => dispatch => {
  dispatch(userProgressLoading());
  return getUserProgress(projectId).then(res => {
    dispatch(userProgressLoaded(res.data));
  });
};

export const clear = () => dispatch => {
  dispatch(userProgressClear());
};

/*
 * Reducer
 */
export const INITIAL_STATE = new UserProgress();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.USER_PROGRESS_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.USER_PROGRESS_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [ACTIONS.USER_PROGRESS_CLEAR]: (state, action) => {
    return state.clear();
  },

  [SearchResultsActions.SEARCH_RESULTS_CLEAR]: (state, action) => {
    return state.clear();
  }
});
