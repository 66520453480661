import { createReducer } from 'utils/redux-utils';
import { getDocumentListColumnOptions, setDocumentListColumnOptions } from 'store/api';

export const COLUMN_OPTIONS = {
  ID: 'ID',
  NAME: 'NAME',
  PAGES: 'PAGES',
  ASSIGNEES: 'ASSIGNEES',
  REVIEWERS: 'REVIEWERS',
  TAGS: 'TAGS',
  TEMPLATE: 'TEMPLATE',
  STATUS: 'STATUS',
  PROCESSED_DATE: 'PROCESSED_DATE',
  DATEFORMATPREFERENCE: 'DATEFORMATPREFERENCE',
  AUTO_DELETE: 'AUTO_DELETE',
  LASTUPDATED_DATE: 'LASTUPDATED_DATE'
};

export const COLUMN_OPTION_DEFAULTS = {
  [COLUMN_OPTIONS.ID]: {
    displayName: 'file-list.heading.id',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.NAME]: {
    displayName: 'file-list.heading.name',
    alwaysVisible: true
  },
  [COLUMN_OPTIONS.PAGES]: {
    displayName: 'file-list.heading.pages',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.ASSIGNEES]: {
    displayName: 'file-list.heading.assignees',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.REVIEWERS]: {
    displayName: 'file-list.heading.reviewers',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.TAGS]: {
    displayName: 'file-list.heading.tags',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.TEMPLATE]: {
    displayName: 'file-list.heading.template',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.STATUS]: {
    displayName: 'file-list.heading.status',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.PROCESSED_DATE]: {
    displayName: 'file-list.heading.processed-date',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.LASTUPDATED_DATE]: {
    displayName: 'file-list.heading.lastupdated-date',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.AUTO_DELETE]: {
    displayName: 'file-list.heading.auto-delete',
    alwaysVisible: false
  },
  [COLUMN_OPTIONS.DATEFORMATPREFERENCE]: {
    displayName: 'file-list.heading.date-format',
    alwaysVisible: false
  } 
};

export const DEFAULT_COLUMN_VISIBILITY = {
  [COLUMN_OPTIONS.ID]: false,
  [COLUMN_OPTIONS.NAME]: true,
  [COLUMN_OPTIONS.PAGES]: false,
  [COLUMN_OPTIONS.ASSIGNEES]: true,
  [COLUMN_OPTIONS.REVIEWERS]: true,
  [COLUMN_OPTIONS.TAGS]: true,
  [COLUMN_OPTIONS.TEMPLATE]: true,
  [COLUMN_OPTIONS.STATUS]: true,
  [COLUMN_OPTIONS.PROCESSED_DATE]: true,
  [COLUMN_OPTIONS.DATEFORMATPREFERENCE] : true,
  [COLUMN_OPTIONS.AUTO_DELETE] : true  
};

export const DEFAULT_COLUMN_ORDER = [
  COLUMN_OPTIONS.ID,
  COLUMN_OPTIONS.NAME,
  COLUMN_OPTIONS.PAGES,
  COLUMN_OPTIONS.ASSIGNEES,
  COLUMN_OPTIONS.REVIEWERS,
  COLUMN_OPTIONS.TAGS,
  COLUMN_OPTIONS.TEMPLATE,
  COLUMN_OPTIONS.STATUS,
  COLUMN_OPTIONS.DATEFORMATPREFERENCE,
  COLUMN_OPTIONS.PROCESSED_DATE,
  COLUMN_OPTIONS.AUTO_DELETE
];

export const DEFAULT_COLUMN_ROOTSHOW = [
  COLUMN_OPTIONS.ID,
  COLUMN_OPTIONS.NAME,
  COLUMN_OPTIONS.DATEFORMATPREFERENCE,
  COLUMN_OPTIONS.PROCESSED_DATE,
  COLUMN_OPTIONS.AUTO_DELETE
];

export const DEFAULT_COLUMN_ROOTSHOWForProjects = [
  COLUMN_OPTIONS.ID,
  COLUMN_OPTIONS.NAME,
  COLUMN_OPTIONS.DATEFORMATPREFERENCE,
  COLUMN_OPTIONS.PROCESSED_DATE
];

export const COLUMN_OPTIONS_DOCSHOW = [
  COLUMN_OPTIONS.ID,
  COLUMN_OPTIONS.NAME,
  COLUMN_OPTIONS.PAGES,
  COLUMN_OPTIONS.ASSIGNEES,
  COLUMN_OPTIONS.REVIEWERS,
  COLUMN_OPTIONS.TAGS,
  COLUMN_OPTIONS.TEMPLATE,
  COLUMN_OPTIONS.STATUS, 
  COLUMN_OPTIONS.PROCESSED_DATE  
];

export const ACTIONS = {
  COLUMN_OPTIONS_UPDATE: 'argus/ui/FILE_BROWSER_COLUMN_OPTIONS_UPDATE'
};

const fromAPIFormat = fields => {
  const order = [];
  const visibility = {};

  fields.forEach(field => {
    order[field.fieldOrder] = field.fieldName;
    visibility[field.fieldName] = field.visible;
  });

  return {
    order: order.filter(f => !!f),
    visibility
  };
};

const toAPIFormat = ({ visibility, order }) => {
  return order.map((fieldName, index) => ({
    fieldName,
    fieldOrder: index,
    visible: visibility[fieldName]
  }));
};

const migrateOptions = fields => {
  //Used to migrate user column options from older version to new versions.  User preferences are kept for visibility, but order gets reset
  var visibility = { ...DEFAULT_COLUMN_VISIBILITY };
  var order = [...DEFAULT_COLUMN_ORDER];

  for (let key in visibility) {
    if (visibility.hasOwnProperty(key) && fields.some(f => f.fieldName === key)) {
      visibility[key] = fields.find(f => f.fieldName === key).visible;
    }
  }

  const payload = toAPIFormat({ visibility, order });
  setDocumentListColumnOptions(payload);

  return { visibility, order };
};

export const getColumnOptions = () => dispatch => {
  getDocumentListColumnOptions().then(response => {
    if (response.fields) {
      var formattedData =
        response.fields.length === DEFAULT_COLUMN_ORDER.length
          ? fromAPIFormat(response.fields)
          : migrateOptions(response.fields);

      dispatch({
        type: ACTIONS.COLUMN_OPTIONS_UPDATE,
        payload: formattedData
      });
    } else {
      dispatch({
        type: ACTIONS.COLUMN_OPTIONS_UPDATE,
        payload: {
          visibility: { ...DEFAULT_COLUMN_VISIBILITY },
          order: [...DEFAULT_COLUMN_ORDER]
        }
      });
    }
  });
};

export const updateColumnOptions = ({ visibility, order }) => dispatch => {
  const payload = toAPIFormat({ visibility, order });

  dispatch({
    type: ACTIONS.COLUMN_OPTIONS_UPDATE,
    payload: {
      visibility,
      order
    }
  });

  setDocumentListColumnOptions(payload);
};

export default createReducer(null, {
  [ACTIONS.COLUMN_OPTIONS_UPDATE]: (state, action) => {
    const { visibility, order } = action.payload;

    return {
      visibility: { ...visibility },
      order: [...order]
    };
  }
});
