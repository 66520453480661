import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import enhanceWithClickOutside from 'react-click-outside';

import DocumentDownload from 'components/shared/document-download';
import Button from 'components/shared/single-click-button';
import RateApplication from 'components/header/rate-application/rate-application';
import Icon from 'components/shared/icon';
import { getCurrentBaseUrl } from 'store/api';
import { addNotification } from 'store/notifications.js';
import store from 'store/store';
import Scrollbars from '../../shared/scrollbars/scrollbars';
import ReactTooltip from 'react-tooltip';

class HelpDropdown extends Component {
  static propTypes = {
    aboutArgus: PropTypes.object,
    currentLanguage: PropTypes.object,
    fromExtractionPage: PropTypes.bool,
    fromTranslationPage: PropTypes.bool,
    loadAboutInfo: PropTypes.func,
    currentUser: PropTypes.object,
    location: PropTypes.object
  };

  state = {
    isMenuOpen: false,
    loadingDocument: false,
    isRatingModalOpen: false
  };

  componentDidMount() {
    const { aboutArgus, loadAboutInfo } = this.props;
    if (!aboutArgus.isLoaded && !aboutArgus.isLoading) {
      return loadAboutInfo();
    }
  }

  handleClickOutside = () => {
    !this.state.isRatingModalOpen && this.setState({ isMenuOpen: false });
  };

  handleFeedbackSubmit = (type = 'success') => {
    this.setState({ isRatingModalOpen: false });
  };

  render() {
    const { isMenuOpen, loadingDocument } = this.state;
    const { aboutArgus, currentLanguage, fromExtractionPage, fromTranslationPage, currentUser, location } = this.props;
    if (aboutArgus.isLoading || !aboutArgus.isLoaded) {
      return (
        <div className="header-help">
          <Button size="icon" className="header-help__button icon-button" disabled>
            <Icon name="help-icon-POS" width={28} />
          </Button>
        </div>
      );
    }
    const submitRequest = (
      <a href={aboutArgus.data.support} className="header-help__link" rel="noopener noreferrer" target="_blank">
        <FormattedMessage
          id="global.header.help-create-a-url"
          values={{
            url: <FormattedMessage id="global.header.help-support-request" />
          }}
        />
      </a>
    );

    const submitOmniaIdeaRequest = (
      <a href = {aboutArgus.data.omniaIdeaPortalURL} className="header-help__link" rel="noopener noreferrer" target="_blank">
        <FormattedMessage
          id="global.header.help-create-a-url-OmniaIdeaPortal"
          values={{
            url: <FormattedMessage id="global.header.help-enhancement-request" />
          }}
        />
      </a>
    );

    const link = (_link, messageId) =>
      loadingDocument === messageId ? (
        <div className={`header-help__spinner-wrapper`}>
          <Icon className="spinner header-help__spinner-wrapper__spinner" name="loader" width={80} />
        </div>
      ) : (
        <DocumentDownload
          className="header-help__link"
          linkUrl={_link}
          linkBody={<FormattedMessage id={`global.header.help-${messageId}`} />}
          apiCallHeaders={{ 'X-LANG-CTX': currentLanguage.localeCode }}
          target="_blank"
          documentLoading={() => this.setState({ loadingDocument: messageId })}
          documentLoaded={() => this.setState({ loadingDocument: false, isMenuOpen: false })}
        />
      );
    let pageLinks = [];
    if (aboutArgus.data.extractionPage && aboutArgus.data.translationPage) {
      pageLinks = [
        {
          id: 'learning-material',
          link: [
            link(`${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.faq}`, 'faq'),
            link(`${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.gettingStartedQRG}`, 'getting-started-qrg'),
            link(
              `${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.conductingReviewQRG}`,
              'conducting-review-qrg'
            ),
            link(
              `${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.extractionFieldsQRG}`,
              'extraction-fields-101-qrg'
            ),
            link(`${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.analyticsQRG}`, 'argus-analytics-qrg'),
            link(
              `${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.managingProjectQRG}`,
              'managing-your-project-qrg'
            ),
            link(`${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.detailTestingQRG}`, 'detail-testing-qrg'),
            link(
              `${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.trainingPrivateEFQRG}`,
              'training-private-field-qrg'
            ),
            link(
              `${getCurrentBaseUrl()}/${aboutArgus.data.translationPage.qrg}`,
              'argus-translation-qrg'
            )
          ]
        },
        {
          id: 'other',
          link: [link(`${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.otherDocuments}`, 'other-link-text')]
        }
      ];
    }
    const LINKS = [
      {
        id: 'request-support',
        link: [submitRequest,
          submitOmniaIdeaRequest]
      },
      ...pageLinks
    ];

    const releaseNotes = [
      {
        id: 'releaseNotes',
        link: [link(`${getCurrentBaseUrl()}/${aboutArgus.data.extractionPage.releaseNotes}`, 'releaseNotes')]
      }
    ]
    return (
      <div className="header-help">
        <Button
          className="header-help__button icon-button"
          disabled={aboutArgus.isLoading || !aboutArgus.isLoaded}
          onClick={() => this.setState({ isMenuOpen: !isMenuOpen })}
          data-tip
          data-for="help.tooltip.title"
        >
          {<Icon name="help-icon-POS-black" width={24} />}
          <ReactTooltip id="help.tooltip.title" effect="solid" place="bottom">
          <FormattedMessage id="help.tooltip.title" />
          </ReactTooltip>
        </Button>
        {isMenuOpen ? (
          <div className="header-help__dropdown">
            <div className="header-help__content">
              <Scrollbars showScrollbarWithoutHover={ true }>
                {releaseNotes.map((value) => {
                return ( 
                value.link.map(p => {
                  return <span className="header-help__Releasenoteslink">{p}</span>;
                })
                ); 
                })} 
                 {LINKS.map((value, index) => {
                  return (
                    <div className="header-help__section" key={index}>
                      <span className="header-help__heading">
                        <FormattedMessage id={`global.header.help-${value.id}`} />
                      </span>
                      {value.link.map(l => {
                        return <span className="header-help__Contentlink">{l}</span>;
                      })}
                    </div>
                  );
                })}
              </Scrollbars>
            </div>
            <div
              className="header-help__rate-app icon-button"
              onClick={() => this.setState({ isRatingModalOpen: true })}
            >
              <Icon name="rate-app" width={28} />
              <h4>
                <FormattedMessage id="global.rate-app.title" />
              </h4>
            </div>
            <RateApplication
              currentUser={currentUser}
              location={location}
              isOpen={this.state.isRatingModalOpen}
              handleSuccessSubmit={() => this.handleFeedbackSubmit()}
              handleErrorSubmit={() => this.handleFeedbackSubmit('error')}
              onCancel={() => this.setState({ isRatingModalOpen: false })}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
export default enhanceWithClickOutside(HelpDropdown);
