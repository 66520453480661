import { LOCATION_CHANGE } from 'connected-react-router';
import uuid from 'uuid';
import { createAction, createReducer } from 'utils/redux-utils';
import { eventTarget as apiEventTarget } from 'store/api';
import { getGlobalErrorIntlKeys, hasGlobalError } from 'utils/errors';

export const ACTIONS = {
  NOTIFICATION_ADD: 'argus/ui/NOTIFICATION_ADD',
  NOTIFICATION_DELETE: 'argus/ui/NOTIFICATION_DELETE',
  NOTIFICATION_CLEAR: 'argus/ui/NOTIFICATION_CLEAR'
};

export const _addNotification = createAction(ACTIONS.NOTIFICATION_ADD);
export const deleteNotification = createAction(ACTIONS.NOTIFICATION_DELETE);
export const clearNotification = createAction(ACTIONS.NOTIFICATION_CLEAR);

export const addNotification = notification => dispatch => {
  const _notification = {
    id: uuid(),
    ...notification
  };
  dispatch(_addNotification(_notification));
  if (_notification.autohide) {
    //Check for autohide parameter (notification lifespan in seconds)
    setTimeout(() => dispatch(deleteNotification(_notification)), _notification.autohide * 1000);
  }
};

export const { subscribeToGlobalErrors, unSubscribeToGlobalErrors } = (() => {
  let handleError = null;

  const subscribeToGlobalErrors = () => dispatch => {
    handleError = event => {
      const error = event.detail;

      if (!hasGlobalError(error)) {
        return;
      }

      const errorIntlKeys = getGlobalErrorIntlKeys(error);

      if (errorIntlKeys.length) {
        errorIntlKeys.forEach(intlKey => {
          dispatch(
            addNotification({
              intlKey,
              clearOnPageChange: true
            })
          );
        });
      } else {
        dispatch(
          addNotification({
            intlKey: 'global.error.default',
            intlProps: { status: error.response.status, statusText: error.response.statusText },
            clearOnPageChange: true
          })
        );
      }
    };

    apiEventTarget.addEventListener('error', handleError);
  };

  const unSubscribeToGlobalErrors = () => dispatch => {
    apiEventTarget.removeEventListener('error', handleError);
  };

  return {
    subscribeToGlobalErrors,
    unSubscribeToGlobalErrors
  };
})();

/*
 * Reducer
 */
export const INITIAL_STATE = [];

export default createReducer(INITIAL_STATE, {
  [ACTIONS.NOTIFICATION_ADD]: (state, action) => {
    return [...state, { ...action.payload }];
  },

  [ACTIONS.NOTIFICATION_DELETE]: (state, action) => {
    return state.filter(notification => notification.id !== action.payload.id);
  },

  [ACTIONS.NOTIFICATION_CLEAR]: (state, action) => {
    return INITIAL_STATE;
  },

  [LOCATION_CHANGE]: (state, action) => {
    return state.filter(notification => !notification.clearOnPageChange);
  }
});
