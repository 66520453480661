import React, { Component } from 'react';
import { downloadDocument } from 'store/api';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import store from 'store/store';
import { addNotification } from 'store/notifications.js';

class DocumentDownload extends Component {
  static propTypes = {
    apiCallHeaders: PropTypes.object,
    className: PropTypes.string.isRequired,
    disable: PropTypes.bool,
    documentFilename: PropTypes.string,
    documentLoaded: PropTypes.func,
    documentLoading: PropTypes.func,
    linkBody: PropTypes.object.isRequired,
    linkUrl: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    title: PropTypes.string
  };

  getDocument = event => {
    const { apiCallHeaders, linkUrl, target, documentLoading, documentLoaded, documentFilename } = this.props;
    event.preventDefault();
    if (documentLoading) {
      documentLoading();
    }
    let newWindows;
    if (target === '_blank') {
      newWindows = window.open('/loading');
    }

    return downloadDocument(linkUrl, apiCallHeaders)
      .then(documentUrl => {
        if (target === '_blank') {
          newWindows.location = documentUrl;
        } else {
          var downloadLink = document.createElement('a');
          downloadLink.href = documentUrl;
          downloadLink.download = documentFilename;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
        if (documentLoaded) {
          documentLoaded();
        }
      })
      .catch(err => {
        if (documentLoaded) {
          documentLoaded();
        }
        if (target === '_blank') {
          newWindows.close();
          store.dispatch(
            addNotification({
              message: (
                <>
                  <strong>
                    <FormattedMessage id="error-page.404.title" />
                  </strong>
                  <br />
                  <FormattedMessage
                    id="error-page.404.description"
                    values={{
                      link: <a href="mailto:argusauditssupport@deloitte.com">argusauditssupport@deloitte.com</a>
                    }}
                  />
                </>
              ),
              clearOnPageChange: true,
              autohide: 10
            })
          );
        }
      });
  };

  render() {
    const { className, disable, linkBody, title } = this.props;

    return (
      <a
        href=""
        onClick={
          disable
            ? e => {
                e.preventDefault();
              }
            : this.getDocument
        }
        className={className}
        rel="noopener noreferrer"
        title={title}
      >
        {linkBody}
      </a>
    );
  }
}

export default DocumentDownload;
