import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DragSource, DropTarget } from 'react-dnd';
import Constants from 'utils/constants';

import Button from 'components/shared/single-click-button';
import ComplicatedIcon from 'components/extractions/complicated-icon/complicated-icon';
import EmptyState from 'components/file-browser/file-list/empty-state';
import emptyStateImage from 'images/empty_state_extraction_fields.svg';
import Icon from 'components/shared/icon';
import withDragDropContext from 'utils/dnd-mouse-context';
import ReactTooltip from 'react-tooltip';

// Begin drag and drop functionality for extraction fields
const cardSource = {
  beginDrag(props) {
    return {
      extraction: props.extraction,
      index: props.index
    };
  },
  endDrag(props, monitor, component) {
    // This is a good place to call some Flux action
    return props.finishDrag ? props.finishDrag() : {};
  },
  isDragging(props, monitor) {
    return props.extraction === monitor.getItem().extraction;
  }
};

// See https://github.com/react-dnd/react-dnd/blob/master/examples/04%20Sortable/Simple/Card.js#L24
const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const dragItem = monitor.getItem().extraction;
    const hoverIndex = props.index;
    const hoverItem = props.extraction;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex || hoverItem.groupId !== dragItem.groupId) {
      return;
    }

    // Perform the action
    props.moveExtraction(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

// Connect to drag and drop context
const Extraction = DropTarget('template-extractions', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(
  DragSource('template-extractions', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(function InnerExtraction({
    isDragging,
    connectDragSource,
    connectDropTarget,
    extraction,
    index,
    moveExtraction,
    onExtractionDelete,
    finishDrag,
    readOnly,
    groupByTemplateType,
    selectedTemplateType,
    selectedTemplateCategoryId
  }) {
    return connectDropTarget(
      <div className={`template-extractions__card ${isDragging && 'dragged'}`} key={`${index}-${extraction.id}`}>
        <span className="template-extractions__card-content">
          {connectDragSource(
            readOnly || groupByTemplateType ? null : (
              <button className="template-extractions__card-drag">
                <Icon width={8} height={12} name="handle" />
              </button>
            )
          )}
          <span className="template-extractions__name" data-tip data-for={`extraction.description.tooltip.${index}`}>
            <span>{extraction.name}</span>
          </span>
          <span className="template-extractions__spacer">
            <ReactTooltip
              id={`extraction.description.tooltip.${index}`}
              className="template-extractions__tooltip"
              effect="solid"
              place="right"
              data-multiline
              delayShow={1300}
              delayHide={extraction.description && extraction.description.length > 400 ? 500 : 0}
            >
              <p className="template-extractions__tooltip-paragraph"> {extraction.name}</p>

              <p className="template-extractions__tooltip-description">
                <FormattedMessage id="template-extractions.tooltip.description" />
              </p>

              {extraction.description.length > 400 ? (
                <div className="template-extractions__tooltip-box">
                  <p className="template-extractions__tooltip-paragraph">{extraction.description}</p>
                </div>
              ) : (
                <p className="template-extractions__tooltip-paragraph">{extraction.description}</p>
              )}
            </ReactTooltip>
          </span>
          <span className="template-extractions__icons">
            <ComplicatedIcon
              extractionId={extraction.id}
              accessTypeId={extraction.AccessTypeId}
              isTrained={extraction.isTrained}
              isAutomaticExtraction={extraction.isAutomaticExtraction}
              trainingCount={extraction.trainingCount}
              trainingTarget={extraction.trainingTarget}
              iconSize={22}
              templateCategoryId={selectedTemplateCategoryId}
            />
          </span>
        </span>
        {readOnly ||
        (selectedTemplateType &&
          selectedTemplateType.templateCategoryId ===
            Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING) ? null : (
          <Button
            size="icon"
            className="template-extractions__delete"
            onClick={id => onExtractionDelete(extraction.id)}
          >
            <Icon name="special-cross-grey" width={15} />
          </Button>
        )}
      </div>
    );
  })
);

Extraction.propTypes = {
  data: PropTypes.object
};

// Actual container for template extractions with drag & drop functionality
class TemplateExtractions extends Component {
  static propTypes = {
    extractions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    groupByTemplateType: PropTypes.bool,
    groupKey: PropTypes.string,
    newTemplateMoveExtraction: PropTypes.func,
    newTemplateRemoveExtraction: PropTypes.func,
    nullGroupName: PropTypes.string,
    onDelete: PropTypes.func,
    onExtractionChange: PropTypes.func,
    onMouseUp: PropTypes.func,
    openByDefault: PropTypes.any,
    readOnly: PropTypes.bool,
    selectedTemplateType: PropTypes.object,
    template: PropTypes.any
  };

  state = {
    openGroups: {} //Controls groups expanding/collapsing
  };

  // Method for handling moving functionality
  // Let's debounce it so that every time a user moves, we're not lagging up the nextwork with multiple post reqs
  moveExtraction = (dragIndex, hoverIndex) => {
    const { templateExtractions } = this.props.extractions;
    const dragExtraction = templateExtractions[dragIndex];
    const updatedExtractions = [...templateExtractions];

    updatedExtractions.splice(dragIndex, 1);
    updatedExtractions.splice(hoverIndex, 0, dragExtraction);

    this.props.onExtractionChange(updatedExtractions);
  };

  //render each extraction in the doc type group
  getExtraction = (i, k) => {
    const {
      onDelete,
      onMouseUp,
      readOnly,
      extractions: { templateExtractions },
      groupByTemplateType,
      newTemplateMoveExtraction,
      newTemplateRemoveExtraction,
      selectedTemplateType      
    } = this.props;
    const index = templateExtractions.map(extraction => extraction.id).indexOf(i.id);
    const selectedTemplateCategoryId  = this.props.extractions.selectedTemplateCategoryId
    
    return (
      <Extraction
        key={index}
        index={index}
        extraction={i}
        moveExtraction={groupByTemplateType ? newTemplateMoveExtraction : this.moveExtraction}
        onExtractionDelete={groupByTemplateType ? newTemplateRemoveExtraction : id => onDelete(i.id)}
        selectedTemplateType={selectedTemplateType}
        finishDrag={onMouseUp}
        readOnly={readOnly}
        selectedTemplateCategoryId={selectedTemplateCategoryId}
      />
    );
  };

  //Method for returning extraction list by doc type group
  getGroupedExtractions = () => {
    var { extractions, groupKey, nullGroupName } = this.props;
    var data = extractions.templateExtractions;
    var extractionList = [];
    data.forEach(extraction => {
      if (extraction[groupKey] === null) {
        extraction[groupKey] = 0;
      }

      if (extraction[groupKey] !== null) {
        if (!extractionList[extraction[groupKey]]) {
          extractionList[extraction[groupKey]] = {
            extractions: [],
            title: extraction.group
              ? extraction.group
              : nullGroupName !== undefined
              ? nullGroupName
              : extractions.selectedName,
            displayOrder: extraction.groupDisplayOrder
          };
        }

        extractionList[extraction[groupKey]].extractions.push({
          ...extraction,
          displayOrder: extraction.groupMapping
            ? extraction.groupMapping.find(gm => gm.extractionFieldGroupId === extraction[groupKey])
                ?.extractionFieldDisplayOrder
            : 0
        });
      }
    });
    return extractionList;
  };

  //Method for accordion view by doc type group
  getAccordion = (group, key) => {
    var { openGroups } = this.state;
    var { openByDefault } = this.props;
    var state = openGroups[key] !== undefined ? openGroups[key] : openByDefault !== undefined ? openByDefault : false;
    var extractionElements = group.extractions.map(this.getExtraction);
    
    return (
      <div className={`template-extractions__accordion ${state ? 'open' : ''}`} key={key}>
        <div className="template-extractions__title">
          <div
            className="template-extractions__wrapper"
            onClick={e => {
              e.stopPropagation();
              openGroups[key] = !state;
              this.setState({ openGroups: openGroups });
            }}
          >
            <span className="template-extractions__doc-type-name">{group.title}</span>
            <span className="template-extractions__downn-arrow-icon">
              <Icon name="special-arrow-down-blue" width={15} height={15} rotate={state ? 180 : 0} />
            </span>
          </div>
        </div>
        {state ? extractionElements : null}
      </div>
    );
  };

  sortByDisplayOrder = groupedExtractions => {
    for (var i in groupedExtractions) {
      groupedExtractions[i].extractions.sort((ef1, ef2) => ef1.displayOrder - ef2.displayOrder);
    }

    return groupedExtractions.sort((g1, g2) => g1.displayOrder - g2.displayOrder);
  };

  getAccordions = () => {
    var accordions = [];
    var groupedExtractions = this.sortByDisplayOrder(this.getGroupedExtractions());
    for (var i in groupedExtractions) {
      accordions.push(this.getAccordion(groupedExtractions[i], i));
    }

    return accordions;
  };

  render() {
    const { extractions } = this.props;

    if (extractions && (extractions.isTemplateExtLoading || extractions.isGroupExtLoading)) {
      return (
        <div className="template-extractions">
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        </div>
      );
    }

    return (
      <div className="template-extractions">
        {extractions && extractions.templateExtractions.length > 0 ? (
          <div>{this.getAccordions()}</div>
        ) : (
          <div className="template-extractions__empty-state">
            <EmptyState
              title={<FormattedMessage id="template-viewer.empty-state.title" />}
              description={<FormattedMessage id="template-viewer.empty-state.description" />}
              img={emptyStateImage}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withDragDropContext(TemplateExtractions);
