import Modal from 'components/shared/modal';
import React, { useEffect, useState } from 'react';
import Switch from 'components/shared/form/switch';
import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import { getExtendedFeaturesforContainer, postExtendedFeaturesforContainer } from 'store/api';

export default function UserExtendedfeaturesSettingModel({ openCloseExtendedfeaturesAccessModel, currentUser }) {
    const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [isWorkSpaceToggleSwitchOnOff, setWorkSpaceToggleSwitchOnOff] = useState(false);
    const [isDataSnipperToggleSwitchOnOff, setDataSnipperToggleSwitchOnOff] = useState(false);
    const [isLoading, setLoadding] = useState(true);

    useEffect(() => {
        getExtendedFeaturesforContainer(true).then(res => {
            var workspaceresponse = res.find(r => r.containerApplicationSettingName == "WorkspaceCreationAcess").containerApplicationSettingValue;
            var isworkspacetoggleOn = (workspaceresponse === "true") ? true : false;
            var datasnipperresponse = res.find(r => r.containerApplicationSettingName == "DataSnipperAccess").containerApplicationSettingValue;
            var isdatasnippertoggleOn = (datasnipperresponse === "true") ? true : false;
            setWorkSpaceToggleSwitchOnOff(isworkspacetoggleOn);
            setDataSnipperToggleSwitchOnOff(isdatasnippertoggleOn);
            setLoadding(false);
        });
    }, []);

    const enableDisableWorkspaceSwitch = () => {
        setWorkSpaceToggleSwitchOnOff(!isWorkSpaceToggleSwitchOnOff);
        setSaveButtonDisabled(false);
    };

    const enableDisableDataSnipperSwitch = () => {
        setDataSnipperToggleSwitchOnOff(!isDataSnipperToggleSwitchOnOff);
        setSaveButtonDisabled(false);
    };

    const saveWorkspaceAccess = () => {
        postExtendedFeaturesforContainer(isWorkSpaceToggleSwitchOnOff, isDataSnipperToggleSwitchOnOff);

        openCloseExtendedfeaturesAccessModel(false);
    };

    return (
        <Modal>
            <div className="user-Extended-features-settings-model">
                <div className="user-Extended-features-settings-model__content modal__content">
                    <div className="user-Extended-features-settings-model__tittle">
                        <FormattedMessage id="user-Extended-features.modal-header" />
                        <Button
                            className="icon-button user-Extended-features-settings-model__cross-button"
                            onClick={() => openCloseExtendedfeaturesAccessModel(false)}
                        >
                            <Icon name="special-cross-black" width={14} />
                        </Button>
                    </div>
                    <div className="user-Extended-features-settings-model__body">
                        {isLoading ? (
                            <Icon className="spinner" name="loader" width={60} />
                        ) : (
                            <>
                                <FormattedMessage id="user-workspace.access-message" />
                                <Switch
                                    className="user-Extended-features-settings-model__workspace-toggle"
                                    isOn={isWorkSpaceToggleSwitchOnOff}
                                    onChange={enableDisableWorkspaceSwitch}
                                    id="workspace-toggle"
                                />
                                <div className={!currentUser.allowDataSnipper  ? ("user-Extended-features-settings-model__ds-disable") : ""}>
                                    <FormattedMessage id="user-data-snipper.access-message" />
                                    <Switch
                                        className = {!currentUser.allowDataSnipper ? ("user-Extended-features-settings-model__disable-datasnipper-toggle") : "user-Extended-features-settings-model__datasnipper-toggle"}
                                        isOn={currentUser.allowDataSnipper && isDataSnipperToggleSwitchOnOff}
                                        onChange={enableDisableDataSnipperSwitch}
                                        id="data-snipper-toggle"
                                        disabled={!currentUser.allowDataSnipper}
                                    />
                                </div>
                                <div className='user-Extended-features-settings-model__empty-state'>
                                    {!currentUser.allowDataSnipper ? (
                                        <FormattedMessage id="user-data-snipper.warning-message" />) : null}
                                </div>

                            </>
                        )}
                    </div>
                    <div className="user-Extended-features-settings-model__footer">
                        <div className="user-Extended-features-settings-model__footer-content">
                            <Button className="btn btn-secondary" onClick={() => openCloseExtendedfeaturesAccessModel(false)}>
                                <FormattedMessage id="global.header.format-cancel" />
                            </Button>
                            <Button
                                className={
                                    isSaveButtonDisabled
                                        ? 'btn btn-primary user-Extended-features-settings-model__button-disable'
                                        : 'btn btn-primary'
                                }
                                disabled={isSaveButtonDisabled}
                                onClick={saveWorkspaceAccess}
                            >
                                <FormattedMessage id="global.header.format-save" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
