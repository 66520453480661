import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

import GlobalExtractionFieldGroup from 'permissions/sections/global-extraction-field-group';

const GlobalExtractionFieldPermissions = {
  ExtractionFieldGroup: GlobalExtractionFieldGroup,

  canViewQuickStudyPageLink: () => {
    return true;
  },

  canViewQuickStudyPageRegion: () => {
    return RolesHelper.isUserFromProjectRegion('current');
  },

  canView: extractionField => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) ||
      RolesHelper.doesUserContainExtractionFieldRole(
        undefined,
        extractionField,
        constants.UserRoles.EXTRACTION_FIELD_CONTRIBUTOR
      ) ||
      RolesHelper.doesUserContainExtractionFieldRole(
        undefined,
        extractionField,
        constants.UserRoles.EXTRACTION_FIELD_OWNER
      )
    );
  },

  canCreate: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canDelete: extractionField => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) ||
      RolesHelper.doesUserContainExtractionFieldRole(
        undefined,
        extractionField,
        constants.UserRoles.EXTRACTION_FIELD_OWNER
      )
    );
  },

  canEdit: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canRename: extractionField => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) ||
      RolesHelper.doesUserContainExtractionFieldRole(
        undefined,
        extractionField,
        constants.UserRoles.EXTRACTION_FIELD_OWNER
      )
    );
  },

  canAddRemoveGroup: extractionField => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) ||
      RolesHelper.doesUserContainExtractionFieldRole(
        undefined,
        extractionField,
        constants.UserRoles.EXTRACTION_FIELD_OWNER
      )
    );
  },

  canAddRemoveUser: extractionField => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) ||
      RolesHelper.doesUserContainExtractionFieldRole(
        undefined,
        extractionField,
        constants.UserRoles.EXTRACTION_FIELD_OWNER
      )
    );
  },

  canViewLearningTab: extractionField => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canViewGroupsAndUsersTab: extractionField => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) ||
      RolesHelper.doesUserContainExtractionFieldRole(
        undefined,
        extractionField,
        constants.UserRoles.EXTRACTION_FIELD_CONTRIBUTOR
      ) ||
      RolesHelper.doesUserContainExtractionFieldRole(
        undefined,
        extractionField,
        constants.UserRoles.EXTRACTION_FIELD_OWNER
      )
    );
  },

  canViewMeasuresTab: extractionField => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canViewHistoryTab: extractionField => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },
  canViewVendorTag: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  }
};

export default GlobalExtractionFieldPermissions;
