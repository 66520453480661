import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCK } from './constants';
import TranslationDisclaimer from './shared/translations-disclaimer/translations-disclaimer';

class TranslationTabs extends Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
    down: PropTypes.bool.isRequired,
    loadTranslationsList: PropTypes.func.isRequired,
    selectedTab: PropTypes.number.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    translations: PropTypes.object
  };

  componentDidMount() {
    const { loadTranslationsList } = this.props;
    loadTranslationsList();
  }

  render() {
    const { children, selectedTab } = this.props;

    return (
      <div className={`${BLOCK}__container`}>
        <div className={`${BLOCK}__content`}>{children[selectedTab]}</div>
        {selectedTab === 2 ? (
          <TranslationDisclaimer
            customClass="queue"
            hideButton={true}
            canTranslate={false}
            haveAcceptedTerms=""
            disclaimerKeys={['translation-queue.disclaimer']}
            disclaimerFor="queue"
          />
        ) : null}
      </div>
    );
  }
}

export default TranslationTabs;
