import { createAction, createReducer } from 'utils/redux-utils';

import {
  getProjects,
  deleteProject as _deleteProject,
  enableSupportAccess as _enableSupportAccess,
  restoreProject,
  copyProject,
  carryProject,
  archiveRequestProject,
  archiveCancelRequest,
  legalHoldProject,
  changeFlagNonClientService
} from 'store/api';
import { ProjectList } from 'models/project-list';
import { APIS as GEO_APIS } from 'store/api';
import socket, { rooms } from 'utils/socket';

export const ACTIONS = {
  PROJECT_LIST_PARTIAL_LOAD: 'argus/ui/PROJECT_LIST_PARTIAL_LOAD',
  PROJECT_LIST_PARTIAL_ERROR: 'argus/ui/PROJECT_LIST_PARTIAL_ERROR',
  PROJECT_LIST_LOADING: 'argus/ui/PROJECT_LIST_LOADING',
  PROJECT_LIST_LOADED: 'argus/ui/PROJECT_LIST_LOADED',
  PROJECT_LIST_SEARCHED: 'argus/ui/PROJECT_LIST_SEARCHED',
  PROJECT_LIST_ERROR: 'argus/ui/PROJECT_LIST_ERROR',

  CARRY_PROJECT: 'argus/ui/CARRY_PROJECT',
  COPY_PROJECT: 'argus/ui/COPY_PROJECT',
  ENABLE_SUPPORT_ACCESS: 'argus/ui/ENABLE_SUPPORT_ACCESS',
  HARD_DELETE_PROJECT: 'argus/ui/HARD_DELETE_PROJECT',
  SOFT_DELETE_PROJECT: 'argus/ui/SOFT_DELETE_PROJECT',
  CHG_FLAG_NON_CLIENT_SERVICE: 'argus/ui/CHG_FLAG_NON_CLIENT_SERVICE',
  RESTORE_PROJECT: 'argus/ui/RESTORE_PROJECT',
  REQUEST_ARCHIVE_PROJECT: 'argus/ui/REQUEST_ARCHIVE_PROJECT',
  CANCEL_ARCHIVE_PROJECT: 'argus/ui/CANCEL_ARCHIVE_PROJECT',
  LEGAL_HOLD_PROJECT: 'argus/ui/LEGAL_HOLD_PROJECT',
  COPY_STATUS_UPDATE: 'argus/ui/COPY_STATUS_UPDATE'
};

export const projectListPartialLoad = createAction(ACTIONS.PROJECT_LIST_PARTIAL_LOAD);
export const projectListPartialErr = createAction(ACTIONS.PROJECT_LIST_PARTIAL_ERROR);
export const projectListLoading = createAction(ACTIONS.PROJECT_LIST_LOADING);
export const projectListLoaded = createAction(ACTIONS.PROJECT_LIST_LOADED);
export const projectListSearched = createAction(ACTIONS.PROJECT_LIST_SEARCHED);
export const projectListError = createAction(ACTIONS.PROJECT_LIST_ERROR);

export const projectCarry = createAction(ACTIONS.CARRY_PROJECT);
export const projectCopy = createAction(ACTIONS.COPY_PROJECT);
export const projectEnableAccess = createAction(ACTIONS.ENABLE_SUPPORT_ACCESS);
export const hardDelProject = createAction(ACTIONS.HARD_DELETE_PROJECT);
export const softDelProject = createAction(ACTIONS.SOFT_DELETE_PROJECT);
export const chgFlagNonClientService = createAction(ACTIONS.CHG_FLAG_NON_CLIENT_SERVICE);
export const restoreDelProject = createAction(ACTIONS.RESTORE_PROJECT);
export const projectRequestArchive = createAction(ACTIONS.REQUEST_ARCHIVE_PROJECT);
export const projectArchiveCancel = createAction(ACTIONS.CANCEL_ARCHIVE_PROJECT);
export const projectLegalHold = createAction(ACTIONS.LEGAL_HOLD_PROJECT);
export const projectCopyStatusUpdate = createAction(ACTIONS.COPY_STATUS_UPDATE);

// Contains the logic for subscribing/unsubscribing to updates from the socket
export const { subscribe, unsubscribe } = (socket => {
  let room = null;
  let onProjectStatusUpdate = null;

  const subscribe = projectId => dispatch => {
    if (!projectId) {
      return;
    }

    const nextRoom = rooms.project(projectId);

    if (room !== nextRoom) {
      socket.leave(room);
      room = nextRoom;
      socket.join(nextRoom);
    }

    // Handle project user role changed
    if (!onProjectStatusUpdate) {
      onProjectStatusUpdate = event => {
        dispatch(projectCopyStatusUpdate(event.data.project));
      };
      socket.on('update', onProjectStatusUpdate);
    }
  };

  const unsubscribe = () => {
    socket.leave(room);
    room = null;

    if (onProjectStatusUpdate) {
      socket.off('update', onProjectStatusUpdate);
      onProjectStatusUpdate = null;
    }
  };

  return {
    subscribe,
    unsubscribe
  };
})(socket);

// Initial load data
export const loadProjectList = () => dispatch => {
  dispatch(projectListLoading());
  
  // If there is no geo data to read from, make sure to not make any geo calls
  if (!Array.isArray(GEO_APIS)) {
    dispatch(projectListError({ err: { length: null, finalError: 'Project API unavailable' } }));
  }

  // Make geo calls and display the projects in order they're resolved
  const promises = GEO_APIS.map(geo => {
    return getProjects(geo)
      .then(res => {
        dispatch(projectListPartialLoad(res.data));
        return res;
      })
      .catch(err => dispatch(projectListPartialErr({ geo, err })));
  });

  // Make sure to call the final promise and catch final erro
  return Promise.all(promises)
    .then(res => dispatch(projectListLoaded(res)))
    .catch(err => dispatch(projectListError({ err: { length: GEO_APIS.length, finalError: err } })));
};   
  
export const searchProjectList = data => dispatch => {
  return dispatch(projectListSearched(data));
};

export const deleteProject = data => dispatch => {
  return _deleteProject({ projectId: data.selected }).then(res => {
    if (data.soft) {
      return dispatch(softDelProject({ res, projectId: data.selected }));
    }
    return dispatch(hardDelProject({ projectId: data.selected }));
  });
};

export const restoreDeletedProject = data => dispatch => {
  return restoreProject(data).then(res => dispatch(restoreDelProject({ res, data })));
};

export const copySourceProject = data => dispatch => {
  return copyProject(data).then(res => {
    dispatch(projectCopy({ res, original: data.projectId }));
    dispatch(subscribe(res.data.projectId));
    return res;
  });
};

export const enableSupportAccess = data => dispatch => {
  return _enableSupportAccess(data).then(res => {
    dispatch(projectEnableAccess({ projectId: data.projectId, allowAccess: data.allowAccess }));
    return res;
  });
};

export const carrySourceProject = data => dispatch => {
  return carryProject(data).then(res => {
    dispatch(projectCarry({ res, original: data.projectId }));
    dispatch(subscribe(res.data.projectId));
    return res;
  });
};

export const archiveReqProject = data => dispatch => {
  return archiveRequestProject(data.data).then(res => dispatch(projectRequestArchive(data)));
};

export const cancelArchiveProject = data => dispatch => {
  return archiveCancelRequest(data).then(res => dispatch(projectArchiveCancel({ res, original: data.projectId })));
};

export const initiateLegalHold = data => dispatch => {
  return legalHoldProject(data).then(res => dispatch(projectLegalHold(data)));
};

export const flagNonClientService = data => dispatch => {
  return changeFlagNonClientService({ projectId: data.projectId, isClientService: data.newValue }).then(() =>
    dispatch(chgFlagNonClientService(data))
  );
};

export const updateProjectState = data => dispatch => {
  return dispatch(projectCopyStatusUpdate(data));
};

/*
 * Reducer
 */
export const INITIAL_STATE = new ProjectList();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.PROJECT_LIST_LOADING]: (state, action) => {
    return state.setLoading(state);
  },

  [ACTIONS.PROJECT_LIST_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [ACTIONS.PROJECT_LIST_PARTIAL_LOAD]: (state, action) => {
    return state.setPartialLoad(state, action);
  },

  [ACTIONS.PROJECT_LIST_PARTIAL_ERROR]: (state, action) => {
    return state.setPartialError(state, action);
  },

  [ACTIONS.PROJECT_LIST_SEARCHED]: (state, action) => {
    return state.setSearched(state, action);
  },

  [ACTIONS.PROJECT_LIST_ERROR]: (state, action) => {
    return state.setError(state, action);
  },

  [ACTIONS.HARD_DELETE_PROJECT]: (state, action) => {
    return state.setDeleted(state, action);
  },

  [ACTIONS.SOFT_DELETE_PROJECT]: (state, action) => {
    return state.setSoftDeleted(state, action);
  },

  [ACTIONS.CHG_FLAG_NON_CLIENT_SERVICE]: (state, action) => {
    return state.setFlagClientService(state, action);
  },

  [ACTIONS.RESTORE_PROJECT]: (state, action) => {
    return state.setRestored(state, action);
  },

  [ACTIONS.COPY_PROJECT]: (state, action) => {
    return state.setCopied(state, action);
  },

  [ACTIONS.COPY_STATUS_UPDATE]: (state, action) => {
    return state.setProjectUpdatedStatus(state, action);
  },

  [ACTIONS.ENABLE_SUPPORT_ACCESS]: (state, action) => {
    return state.setEnabledAccess(state, action);
  },

  [ACTIONS.CARRY_PROJECT]: (state, action) => {
    return state.setCarried(state, action);
  },

  [ACTIONS.REQUEST_ARCHIVE_PROJECT]: (state, action) => {
    return state.setArchived(state, action);
  },

  [ACTIONS.CANCEL_ARCHIVE_PROJECT]: (state, action) => {
    return state.setCancelArchived(state, action);
  },

  [ACTIONS.LEGAL_HOLD_PROJECT]: (state, action) => {
    return state.setLegalHold(state, action);
  }
});
