//These functions are used by the document viewer to syncronize the highlighting/underlining
// of extraction records across the bookmarks panel, the document renderer overlay, and the
// extraction field record panel

export function emphasizeHighlight(recordId, fieldId) {
  var record, field, bookmark;

  field = document.getElementById(`extraction-field-${fieldId}`);
  record = document.getElementById(`extraction-field-record-${recordId}`);
  bookmark = document.querySelector(`.extraction-bookmark-${recordId}`);

  if (field && field.classList) field.classList.add('emphasize');
  if (record && record.classList) record.classList.add('emphasize');
  if (bookmark && bookmark.classList) bookmark.classList.add('emphasize');

  window.postMessage({ emphasize_highlight: recordId },  window.location.href);
}

export function deemphasizeHighlight() {
  var elements = Array.from(
    document.querySelectorAll(
      '.extraction-field, .extraction-record, .extraction-bookmark, .extraction-bookmark .extra'
    )
  );

  if (elements && elements.length && elements.length > 0) {
    elements.forEach(element => element.classList.remove('emphasize'));
    window.postMessage({ emphasize_highlight: null },  window.location.href);
  }
}
