import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import DocumentType from './document-type/document-type';
import TemplateList from './template-list/template-list';
import TemplateType from './template-type/template-type';
import AddExtraction from 'components/extractions/add-extraction/add-extraction';
import GuidedTemplateViewer from './template-customization/guided-template-viewer';
import constants from 'utils/constants';
import { createProjectExtractionField } from 'store/api';
import TemplateStepDescription from './template-step-description';

const {
  SELECT_TEMPLATE_TYPE,
  SELECT_DOC_TYPE,
  CREATE_TEMPLATE,
  ADD_EF,
  TEMPLATE_LIST,
  CUSTOMIZE_TEMPLATE,
  ADD_EF_CUSTOM_TEMPLATE
} = constants.TemplateSteps;

/** Different template steps when a user is creating a template for a project */
class TemplateSteps extends Component {
  static propTypes = {
    addExtractionClicked: PropTypes.bool,
    api: PropTypes.object,
    changeAddExtractionClicked: PropTypes.func,
    changeDisableCreateButton: PropTypes.func,
    changeIsAddExtractionButtonDisabled: PropTypes.func,
    changeIsFormValid: PropTypes.func,
    changeIsResolved: PropTypes.func,
    changeSelectedGroups: PropTypes.func,
    changeSelectedType: PropTypes.func,
    changeStep: PropTypes.func,
    isAddExtractionButtonDisabled: PropTypes.bool,
    isUpdate: PropTypes.bool,
    onDocumentTypeSubmit: PropTypes.func,
    onTemplateTypesSubmit: PropTypes.func,
    projectId: PropTypes.any,
    projectTemplates: PropTypes.object,
    selectedTemplates: PropTypes.array,
    selectedType: PropTypes.any,
    startNextTemplateCreation: PropTypes.func,
    templateStep: PropTypes.number,
    templateTypes: PropTypes.object
  };

  state = {
    isResolved: true,
    disableCreateButton: false,
    showCreateExtraction: false,
    isTemplateExtLoading: false,
    selectedType:{}
  };

  // Create a new extraction field
  createExtraction = (name, description) => {
    const { projectId } = this.props;
    return createProjectExtractionField(projectId, name, description);
  };

  // Handle create extraction success
  onCreateExtractionSuccess = extraction => {

      const {
      api: { loadAllExtraction }
    } = this.props;

    loadAllExtraction();
    this.onAddExtractionsSuccess([extraction]);
  };

  // Handle create extraction error
  onCreateExtractionFail = () => this.onHideAddExtraction();

  // Don't need to make an api call here so jump to onAddExtractionsSuccess
  addExtractions = () => Promise.resolve();

  // Handle add extraction success
  onAddExtractionsSuccess = extractions => {
    const { api, templateStep, changeStep } = this.props;
    const { addExtraction } = api;

    addExtraction({ data: extractions });
    this.setState({ showCreateExtraction: false });

    if (templateStep === ADD_EF_CUSTOM_TEMPLATE) {
      return changeStep(CUSTOMIZE_TEMPLATE);
    }
    return changeStep(CREATE_TEMPLATE);
  };

  // Handle add extraction fail
  onAddExtractionsFail = () => this.onHideAddExtraction();

  // Handle hiding the add extraction component
  onHideAddExtraction = () => {
    const { templateStep, changeStep } = this.props;

    this.setState({ showCreateExtraction: false });

    if (templateStep === ADD_EF_CUSTOM_TEMPLATE) {
      return changeStep(CUSTOMIZE_TEMPLATE);
    }
    return changeStep(CREATE_TEMPLATE);
  };

  showCreateExtraction = value => {
    this.setState({ showCreateExtraction: value });
  };

  onCreateNewClick = value => {
    const { changeStep, changeSelectedType } = this.props;
    changeSelectedType(null);
    changeStep(SELECT_TEMPLATE_TYPE);
  };

  onTemplateClick = value => {   
    const { loadTemplateExtraction, onTemplateClick } = this.props.api;
    const { projectId, changeStep } = this.props;
    this.setState({selectedType:value});
    onTemplateClick(value);
    loadTemplateExtraction(projectId, value.projectTemplateId);
    changeStep(CUSTOMIZE_TEMPLATE);
  };

  onTemplateViewerAdd = () => {
    const { changeStep, templateStep, projectTemplates, changeDisableCreateButton, changeIsResolved,projectId } = this.props;
    const { loadAllExtraction } = this.props.api;
    var tempCategoryID = this.props.selectedType ? this.props.selectedType.templateCategoryId :this.state.selectedType.projectTemplateId;
    if ((projectTemplates.allExtractions.length <= 0 )|| 
      (projectTemplates.allExtractions.length > 0 && projectTemplates.allExtractions[0].externalSystemId != tempCategoryID)) {
      projectTemplates.allExtractions = [];
      loadAllExtraction(projectId,tempCategoryID);
    }
    this.setState({ disableCreateButton: false, isResolved: true }, () => {
      changeIsResolved(true);
      changeDisableCreateButton(false);
    });
    if (templateStep === CUSTOMIZE_TEMPLATE) {
      return changeStep(ADD_EF_CUSTOM_TEMPLATE);
    }
    return changeStep(ADD_EF);
  };

  onTemplateViewerSelect = value => {
    this.props.api.setIsSelected(value);
  };

  onTemplateViewerExtractionChange = extractions => {
    const { onExtractionChange } = this.props.api;
    onExtractionChange(extractions);
  };

  getExtractionGroups = extractions => {
    return extractions.reduce((acc, cur) => {
      if (acc.find(ext => ext.groupId === cur.groupId) || cur.groupId === null) {
        return acc;
      } else {
        return acc.concat({
          groupName: cur.group,
          groupId: cur.groupId
        });
      }
    }, []);
  };

  render() {
    const {
      templateStep,
      projectTemplates,
      selectedType,
      templateTypes,
      changeSelectedType,
      onTemplateTypesSubmit,
      changeSelectedGroups,
      isUpdate,
      changeIsFormValid,
      changeAddExtractionClicked,
      changeIsAddExtractionButtonDisabled,
      addExtractionClicked,
      isAddExtractionButtonDisabled
    } = this.props;
    const { isResolved, showCreateExtraction, isTemplateExtLoading } = this.state;
    const { onNameChange } = this.props.api;

    return (
      <div className="templates-step__wrapper">
        {templateStep === SELECT_TEMPLATE_TYPE ? (
          <TemplateType
            templateTypes={templateTypes}
            templateTypeGroups={templateTypes.types.templateTypeGroups}
            changeSelectedType={changeSelectedType}
            submitFunction={onTemplateTypesSubmit}
          />
        ) : (
          <>
            <div className="templates-step__left">
              {templateStep === SELECT_DOC_TYPE ||
              templateStep === CREATE_TEMPLATE ||
              templateStep === CUSTOMIZE_TEMPLATE ? (
                <div className="templates-step__heading">
                  <h3 className="templates-step__title">
                    {templateStep === CUSTOMIZE_TEMPLATE ? (
                      <FormattedMessage id="templates-page.update" />
                    ) : (
                      <FormattedMessage id="templates-page.create" />
                    )}
                  </h3>
                </div>
              ) : null}
              <div className="templates-step__steps">
                {templateStep === SELECT_DOC_TYPE && (
                  <DocumentType
                    templateType={selectedType}
                    groupData={selectedType}
                    changeSelectedGroups={changeSelectedGroups}
                  />
                )}
                {(templateStep === CREATE_TEMPLATE || templateStep === CUSTOMIZE_TEMPLATE) && (
                  <GuidedTemplateViewer
                    disabled={
                      this.state.disableCreateButton ||
                      !isResolved ||
                      projectTemplates.isGroupExtLoading ||
                      projectTemplates.isTemplateExtLoading
                    }
                    templateTypes={templateTypes}
                    onChange={onNameChange}
                    onAdd={this.onTemplateViewerAdd}
                    value={projectTemplates.templateName}
                    onExtractionChange={this.onTemplateViewerExtractionChange}
                    extractions={projectTemplates}
                    onUpdate={this.onTemplateViewerUpdate}
                    isUpdate={isUpdate}
                    isResolved={this.state.isResolved}
                    isOtherTemplate={
                      projectTemplates.templateExtractions.length <= 0 &&
                      isResolved &&
                      !projectTemplates.isGroupExtLoading &&
                      !projectTemplates.isTemplateExtLoading
                    }
                    onCheckboxClick={this.onTemplateViewerSelect}
                    isTemplateExtLoading={isTemplateExtLoading}
                    isGroupExtLoading={projectTemplates.isGroupExtLoading}
                    changeIsFormValid={changeIsFormValid}
                    selectedType={selectedType}
                  />
                )}
                {(templateStep === ADD_EF || templateStep === ADD_EF_CUSTOM_TEMPLATE) && (
                  <AddExtraction
                    addExtractions={this.addExtractions}
                    createExtraction={this.createExtraction}
                    currentTemplateName={projectTemplates.templateName}
                    data={projectTemplates.allExtractions}
                    onAddExtractionsFail={this.onAddExtractionsFail}
                    onAddExtractionsSuccess={this.onAddExtractionsSuccess}
                    onClose={this.onHideAddExtraction}
                    onCreateExtractionFail={this.onCreateExtractionFail}
                    onCreateExtractionSuccess={this.onCreateExtractionSuccess}
                    onHide={this.onHideAddExtraction}
                    pageViewer="ProjectPage"
                    showCreateExtraction={this.showCreateExtraction}
                    hideCreateNewExtractionField={true}
                    extractionFieldGroups={this.getExtractionGroups(projectTemplates.templateExtractions)}
                    changeIsAddExtractionButtonDisabled={changeIsAddExtractionButtonDisabled}
                    changeAddExtractionClicked={changeAddExtractionClicked}
                    addExtractionClicked={addExtractionClicked}
                    isAddExtractionButtonDisabled={isAddExtractionButtonDisabled}
                    fromCreateTemplateView={true}
                  />
                )}

                {templateStep === TEMPLATE_LIST && (
                  <TemplateList
                    templates={projectTemplates.templates}
                    onCreateNewClick={this.onCreateNewClick}
                    onTemplateClick={this.onTemplateClick}
                  />
                )}
              </div>
            </div>
            <TemplateStepDescription templateStep={templateStep} showCreateExtraction={showCreateExtraction} />
          </>
        )}
      </div>
    );
  }
}

export default TemplateSteps;
