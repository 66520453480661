import { createAction, createReducer } from 'utils/redux-utils';

import { getProjectStatus } from 'store/api';
import { ProjectStatus } from 'models/review-status/project-status';

// used to hook onto clear call from search results store
import { ACTIONS as SearchResultsActions } from 'store/search/search-results';

export const ACTIONS = {
  PROJECT_STATUS_LOADING: 'argus/ui/PROJECT_STATUS_LOADING',
  PROJECT_STATUS_LOADED: 'argus/ui/PROJECT_STATUS_LOADED',
  PROJECT_STATUS_CLEAR: 'argus/ui/PROJECT_STATUS_CLEAR'
};

export const projectStatusLoading = createAction(ACTIONS.PROJECT_STATUS_LOADING);
export const projectStatusLoaded = createAction(ACTIONS.PROJECT_STATUS_LOADED);
export const projectStatusClear = createAction(ACTIONS.PROJECT_STATUS_CLEAR);

export const getData = projectId => dispatch => {
  dispatch(projectStatusLoading());
  return getProjectStatus(projectId).then(res => {
    dispatch(projectStatusLoaded(res.data));
  });
};

export const clear = () => dispatch => {
  dispatch(projectStatusClear());
};

/*
 * Reducer
 */
export const INITIAL_STATE = new ProjectStatus();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.PROJECT_STATUS_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.PROJECT_STATUS_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [ACTIONS.PROJECT_STATUS_CLEAR]: (state, action) => {
    return state.clear();
  },

  [SearchResultsActions.SEARCH_RESULTS_CLEAR]: (state, action) => {
    return state.clear();
  }
});
