import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class Switch extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    isOn: PropTypes.bool,
    onChange: PropTypes.func
  };

  onChange = event => {
    const { id, isOn, onChange } = this.props;
    if (onChange) {
      onChange(id, !isOn);
    }
  };

  // Override the label's default click behaviour to prevent bug on Edge where the onChange was sometimes
  // not fired after a click
  onClick = event => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    event.preventDefault();
    this.onChange();
  };

  render() {
    const { id, isOn, className, disabled } = this.props;

    return (
      <div className={classNames('switch', isOn ? 'switch--on' : 'switch--off', className)}>
        <div onClick={this.onClick} className="switch__slider">
          <div className="switch__handle" />
        </div>
        <input
          checked={isOn ? 'checked' : 'unchecked'}
          className="switch__input"
          disabled={disabled}
          id={id}
          onChange={this.onChange}
          type="checkbox"
        />
      </div>
    );
  }
}

export default Switch;
