import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';

import { STEP_NAMES } from 'components/project-creation/constants';

class ProgressBar extends Component {
  static propTypes = {
    onProgressTextClick: PropTypes.func,
    projectState: PropTypes.object,
    step: PropTypes.number,
    steps: PropTypes.array
  };

  // Different class names to represent different css between settings and creation page
  getIndicatorClassname = i => {
    const { projectState, step } = this.props;
    if (projectState.SETTINGS || projectState.ARCHIVE) {
      return `indicator complete`;
    }
    return `indicator ${i === step ? 'current' : i < step ? 'complete' : 'future'}`;
  };

  // Text key css
  getTextClassname = i => {
    const { projectState, step } = this.props;
    if (projectState.SETTINGS || projectState.ARCHIVE) {
      return `textkey ${i === step ? 'complete' : 'future--hovered'}`;
    }
    return `textkey ${i === step ? 'current' : i < step ? 'complete' : 'future'}`;
  };

  // Step key css
  getStepClassname = i => {
    const { projectState, step } = this.props;
    if (projectState.SETTINGS || projectState.ARCHIVE) {
      return `step ${i === step ? 'complete' : 'future--hovered'}`;
    }
    return `step ${i === step ? 'current' : i < step ? 'complete' : ''}`;
  };

  // Icon name
  getIconName = i => {
    const { step } = this.props;
    return `${i === step ? 'ellipse-9' : i < step ? 'success' : ''}`;
  };

  onStepClick = i => {
    console.log(i);
  }

  render() {
    const {
      steps, step,
      projectState: { SETTINGS, COPY, ARCHIVE, NEW, INPROGRESS },
      onProgressTextClick
    } = this.props;

    return ( 
      <div className="progress-bar">
        <div className="steps">
          {steps.map((currentStep, i) => (
            <div className={this.getStepClassname(i)} key={i}>

              <div className="step-wrapper">
                <div
                  className={this.getTextClassname(i)}
                  //onClick={() => (SETTINGS || ARCHIVE ? onProgressTextClick(i) : {})}
                  onClick={() => currentStep <= step ? onProgressTextClick(currentStep): {}}
                >
                  <FormattedMessage id={STEP_NAMES[currentStep]} />                  
                </div>
              </div>
              <Icon name={this.getIconName(i)}  width={15} className="indicator" />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ProgressBar;
