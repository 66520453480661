import store from 'store/store';
import { getCurrentApiInfo } from 'store/api';

const RolesHelper = {
  doesProjectContainRole: (project, roleId) => {
    if (!project || project === 'current') project = store.getState().data.currentProject;
    return project && project.myRole && project.myRole !== null && project.myRole.roleId === roleId;
  },

  doesUserContainExtractionFieldRole: (user, extractionField, roleId) => {
    if (!user) user = store.getState().data.currentUser;
    const fieldUser = extractionField.users.find(fieldUser => fieldUser.userId === user.userId);

    return (
      fieldUser && fieldUser.roles && fieldUser.roles.some(role => role.projectId === null && role.roleId === roleId)
    );
  },

  doesUserHaveRegionalRole: (region, roleId) => {
    const currentUser = store.getState().data.currentUser;

    const homeRegion = {
      geoCode: currentUser.geoCode,
      memberFirmCode: currentUser.memberFirmCode,
      containerCode: currentUser.containerCode
    };

    var currentRegion;

    if (!region || region === 'current') {
      currentRegion = getCurrentApiInfo();
    } else if (region === 'home') {
      currentRegion = homeRegion;
    } else {
      currentRegion = region;
    }

    const currentRegionRoles = (currentUser.roles || []).filter(
      role =>
        role.geoCode === currentRegion.geoCode &&
        role.memberFirmCode === currentRegion.memberFirmCode &&
        role.containerCode === currentRegion.containerCode
    );

    return currentRegionRoles.some(role => role.roleId === roleId);
  },

  isUserPPMD: user => {
    if (!user) user = store.getState().data.currentUser;
    return user.isPPD;
  },

  isUserArchiveRequestor: (project, user) => {
    if (!project) project = store.getState().data.currentProject;
    if (!user) user = store.getState().data.currentUser;
    return (
      project &&
      project.archivalRequest &&
      project.archivalRequest.requestorId &&
      project.archivalRequest.requestorId === user.userId
    );
  },

  isUserSelectedApprover: (project, user) => {
    if (!project) project = store.getState().data.currentProject;
    if (!user) user = store.getState().data.currentUser;
    return (
      project &&
      project.archivalRequest &&
      project.archivalRequest.approverIdList &&
      project.archivalRequest.approverIdList.some(approver => approver === user.userId)
    );
  },

  isUserFromProjectRegion: region => {
    const currentUser = store.getState().data.currentUser;

    if (!region || region === 'current') {
      region = getCurrentApiInfo();
    }

    return (
      region.geoCode === currentUser.geoCode &&
      region.memberFirmCode === currentUser.memberFirmCode &&
      region.containerCode === currentUser.containerCode
    );
  }
};

export default RolesHelper;
