import React from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';

function GenAIAdminBanner(){

    return(
        <div className="genai-admin-banner">
            <span className='genai-admin-banner__message-icon'>
                <Icon name="warning-circle" width={16} />
            </span>
            <span className='genai-admin-banner__message-text'>
                <FormattedMessage id="global.admin.genai-banner" />
            </span>
        </div>
    )
}

export default GenAIAdminBanner;