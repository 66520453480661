import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { sampleNumberTextMaxCharacters } from '../constants';
import { FormattedMessage } from 'react-intl';
import EditableText from 'components/shared/form/editable-text';
import Icon from 'components/shared/icon';

/*
 * Sample number input field
 */
export default class SampleNumberInputField extends Component {
  static propTypes = {
    block: PropTypes.any,
    documentId: PropTypes.number,
    projectId: PropTypes.number,
    readOnly: PropTypes.bool,
    sampleNumber: PropTypes.any,
    section: PropTypes.object,
    sectionLoading: PropTypes.any,
    updateSampleNumber: PropTypes.func
  };

  updateSampleNumber = numberValue => {
    const { updateSampleNumber, section, documentId, projectId } = this.props;

    return updateSampleNumber({
      documentId: documentId,
      projectId: projectId,
      sampleNumber: numberValue,
      start: section.start
    });
  };

  onChangeValue = numberValue => {
    const { sampleNumber } = this.props;
    if (numberValue === sampleNumber) {
      return;
    }
    return this.updateSampleNumber(numberValue);
  };

  render() {
    const { block, sampleNumber, sectionLoading, section, readOnly } = this.props;

    return sectionLoading === section.start ? (
      <Icon name="loader" className="spinner" width={20}></Icon>
    ) : sampleNumber !== '' ? (
      <div className="document-splitter__sample-number-edit">
        <EditableText
          value={`${sampleNumber}`}
          onSubmit={this.onChangeValue}
          rows="1"
          maxLength={sampleNumberTextMaxCharacters}
          disableEdit={readOnly}
        />
      </div>
    ) : (
      <FormattedMessage id="document-splitter-sample-number">
        {placeholder => (
          <input
            id={`sampleValue-${section.start}`}
            type="tel"
            disabled={readOnly}
            className={`${block}__name-input`}
            placeholder={placeholder}
            maxLength={sampleNumberTextMaxCharacters}
            onBlur={e => this.onChangeValue(e.target.value)}
          />
        )}
      </FormattedMessage>
    );
  }
}
