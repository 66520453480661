import React from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Button from 'components/shared/single-click-button';

function KiraProcessingPausedModal(props)
{

  var templateName = props.templates ? props.templates : "selected template" ;
    return (
      <Modal>
      <div className="modal__content">        
          <h3>
          <FormattedMessage id="global.banner.processing-paused.title" />
          </h3>
          <Button size="icon" className="modal__close_x icon-button" onClick={props.closeKiraProcessingPausedModal}>
            <Icon name="special-cross-black" width={18} height={18} />
          </Button>
          <div className="modal__body">
            <p className="modal__text">
               <FormattedMessage
                  id="kira-processing-paused-modal.message"
                  values={{templateName}}
                />
            </p>            
          </div>     
      </div>
    </Modal>
       
    )
}

export default KiraProcessingPausedModal;