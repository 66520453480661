import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import Checkbox from 'components/shared/form/checkbox';
import ComplicatedIcon from 'components/extractions/complicated-icon/complicated-icon';
import ReactTooltip from 'react-tooltip';

// Begin drag and drop functionality for extraction fields
const cardSource = {
  beginDrag(props) {
    return {
      extraction: props.extraction,
      index: props.index
    };
  },
  endDrag(props, monitor, component) {
    // This is a good place to call some Flux action
    return props.finishDrag ? props.finishDrag() : {};
  },
  isDragging(props, monitor) {
    return props.extraction === monitor.getItem().extraction;
  }
};

// See https://github.com/react-dnd/react-dnd/blob/master/examples/04%20Sortable/Simple/Card.js#L24
const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const dragItem = monitor.getItem().extraction;
    const hoverIndex = props.index;
    const hoverItem = props.extraction;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex || hoverItem.groupId !== dragItem.groupId) {
      return;
    }

    // Perform the action
    props.moveExtraction(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

// Connect to drag and drop context
export const Extraction = DropTarget('template-extraction', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(
  DragSource('template-extraction', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(function InnerExtraction({
    isDragging,
    connectDragSource,
    connectDropTarget,
    extraction,
    index,
    moveExtraction,
    onCheckboxClick,
    onExtractionDelete,
    finishDrag,
    readOnly,
    templateCategoryId
  }) {
    return connectDropTarget(
      <div className={`template-extraction__card ${isDragging && 'dragged'}`} key={`${index}-${extraction.id}`}>
        <span className="template-extraction__card-content">
          {connectDragSource(
            <button className="template-extraction__card-drag">
              <Icon width={8} height={12} name="handle" />
            </button>
          )}
          <span className="template-extraction__checkbox">
            <Checkbox
              className="extraction-checkbox"
              disabled={readOnly}
              checked={extraction.isSelected ? 'checked' : 'unchecked'}
              id={`extraction-select-${extraction.id}-${index}`}
              onChange={() => onCheckboxClick(extraction.id)}
              width={20}
            />
          </span>
          <span className="template-extraction__name" data-tip data-for={`extraction.description.tooltip.${index}`}>
            <span>{extraction.name}</span>
          </span>
          <span className="template-extraction__spacer">
            <ReactTooltip
              id={`extraction.description.tooltip.${index}`}
              className="template-extraction__tooltip"
              effect="solid"
              place="right"
              data-multiline
              delayShow={1300}
              delayHide={extraction.description && extraction.description.length > 400 ? 500 : 0}
            >
              <p className="template-extraction__tooltip-paragraph"> {extraction.name}</p>

              <p className="template-extraction__tooltip-description">
                <FormattedMessage id="template-extractions.tooltip.description" />
              </p>

              {extraction.description.length > 400 ? (
                <div className="template-extraction__tooltip-box">
                  <p className="template-extraction__tooltip-paragraph">{extraction.description}</p>
                </div>
              ) : (
                <p className="template-extraction__tooltip-paragraph">{extraction.description}</p>
              )}
            </ReactTooltip>
          </span>
          <span className="template-extraction__icons">
            <ComplicatedIcon
              extractionId={extraction.id}
              accessTypeId={extraction.AccessTypeId}
              isTrained={extraction.isTrained}
              isAutomaticExtraction={extraction.isAutomaticExtraction}
              trainingCount={extraction.trainingCount}
              trainingTarget={extraction.trainingTarget}
              iconSize={22}
              templateCategoryId={templateCategoryId}
            />
          </span>
        </span>
      </div>
    );
  })
);

Extraction.propTypes = {
  data: PropTypes.object
};
