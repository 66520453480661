import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { TemplateTypes } from 'models/project-creation/template-types';

export const ACTIONS = {
  // Extractions
  TYPES_LOADING: 'argus/ui/TYPES_LOADING',
  TYPES_LOADED: 'argus/ui/TYPES_LOADED'
};

export const typesLoading = createAction(ACTIONS.TYPES_LOADING);
export const typesLoaded = createAction(ACTIONS.TYPES_LOADED);

export const loadTypes = () => dispatch => {
  dispatch(typesLoading());
  return api.getProjectCreationTemplateTypes()?.then(res => {
    dispatch(typesLoaded(res.data));
  });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new TemplateTypes();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.TYPES_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.TYPES_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  }
});
