import Global from 'permissions/sections/global';
import Project from 'permissions/sections/project';

// All user permissions should be contained in the sections folder

const Permissions = {
  Global: Global,
  Project: Project
};

export default Permissions;
