import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { debounce } from 'underscore';
import SearchInputField from 'containers/search/search-inputfield-container';

/** Quick study page search bar */
class GroupsSearch extends Component {
  static propTypes = {
    inputDisabled: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onSearchChange: PropTypes.func,
    search: PropTypes.object,
    fromQuickStudyPage : PropTypes.bool,
    intl : intlShape.isRequired
  };

  componentDidMount() {
    const { groupId } = this.props.match.params;
    if (this.props.search.query.length > 0) {
      if (groupId !== 'all' || !groupId) {
        this.props.onSearchChange('');
      }
    }
  }

  onKeyUp = debounce(() => {
    this.onEnter();
  }, 500);

  onEnter = () => {
    // Search param options
    const groupOptions = {
      pageNum: 1,
      filterBy: 'name',
      filterString: this.props.search.query
    };
    this.props.onEnter(groupOptions);
  };

  render() {
    const { location, inputDisabled, onExit,fromQuickStudyPage,intl } = this.props;

    return (
      <SearchInputField
        inputDisabled={inputDisabled}
        location={location}
        onEnter={this.onEnter}
        onExit={onExit}
        onKeyUp={this.onKeyUp}
        fromQuickStudyPage ={fromQuickStudyPage}
        intl={intl}
      />
    );
  }
}

export default injectIntl(GroupsSearch);
