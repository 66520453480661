import { connect } from 'react-redux';

import { setLanguage } from 'store/intl/currentLanguage';
import AccountSettingsPopup from 'components/header/account-dropdown/account-settings-popup/account-settings-popup';

const mapStateToProps = () => {};

const mapDispatchToProps = {
  setLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsPopup);
