import { connect } from 'react-redux';

import { loadProjectMembers } from 'store/project-members';
import { loadProjectTemplates } from 'store/project-templates';

import {
  clearProjectData,
  updateProjectName,
  updateEngagementManually,
  updateProjectEngagement,
  createProjectTrack,
  updateProjectEngagementManually
} from 'store/current-project';
import ProjectCreation from 'components/project-creation/project-creation';

const mapStateToProps = ({ data: { projectTemplates } }) => ({
  projectTemplates
});

const mapDispatchToProps = {
  clearProjectData,
  createProjectTrack,
  loadProjectMembers,
  loadProjectTemplates,
  updateProjectEngagement,
  updateProjectName,
  updateEngagementManually,
  updateProjectEngagementManually
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreation);
