import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { parse as parseQuery } from 'qs';

import socket, { rooms } from 'utils/socket';
import Constants from 'utils/constants';

import Header from 'containers/header/header';
import MainPanel from 'containers/document-viewer/panels/main-panel-container';
import SidePanel from 'containers/document-viewer/panels/side-panel-container';
import { ITEM_STATUSES } from 'models/project-item';

import Modal from 'components/shared/modal';
import ArgusTrainingModal from 'components/shared/argus-training-modal/argus-training-modal';

const CURRENT_SELECTION_INITIAL = {
  startingWord: null,
  endingWord: null
};

class DocumentViewer extends Component {
  static propTypes = {
    addExtractionField: PropTypes.func,
    clearCurrentDocument: PropTypes.func,
    clearUndoStack: PropTypes.func,
    createExtractionField: PropTypes.func,
    createExtractionFieldRecord: PropTypes.func,
    currentDocument: PropTypes.object,
    currentDocumentLayout: PropTypes.object,
    currentProject: PropTypes.object,
    currentSearchFolder: PropTypes.object,
    currentUser: PropTypes.object,
    documentId: PropTypes.any,
    getCurrentDocument: PropTypes.func,
    getCurrentDocumentLayout: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    projectId: PropTypes.any,
    refreshCurrentDocument: PropTypes.func,
    unsubscribeCurrentDocument: PropTypes.func
  };

  state = {
    selection: null,
    currentSelection: CURRENT_SELECTION_INITIAL,
    documentStateErrors: [],
    isMenuOpen: JSON.parse(sessionStorage.getItem('document-splitter-isMenuOpen') ?? true),
    showArgusTrainingModal: false,
    isKira: true,
    trainingTargetDocumentsNewField: 30
  };

  documentRendererFocus = false;

  documentRoom = undefined;
  templateRoom = undefined;

  componentDidMount() {
    this.loadData(true);    
    this.joinDocumentRoom();
    window.addEventListener('message', this.messageListenerFunction, false);
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate(prevProps) {
    const {
      documentId,
      projectId,
      currentDocument,
      currentUser: { geoCode, userId },
      currentDocumentLayout
    } = this.props;
      
    if(currentDocument.isLoaded)
    {
    sessionStorage.setItem('selected-template',currentDocument.projectTemplates[0].projectTemplateId);
    sessionStorage.setItem('selected-template-name',currentDocument.projectTemplates[0].name);
    sessionStorage.setItem('selected-template-projectId',projectId); 
    }

    // When we navigate to the next document, reset values and join the document's socket room
    if (documentId !== prevProps.documentId || projectId !== prevProps.projectId) {
      this.setState(
        {
          selection: null,
          currentSelection: CURRENT_SELECTION_INITIAL,
          showArgusTrainingModal: false
        },
        () => {
          // If the data for the current document is already loaded, don't load it again
          // This is the case when using the next/prev buttons
          if (currentDocument.documentId !== documentId) {
            this.loadData(true);
            this.joinDocumentRoom();
            this.props.clearUndoStack();
          }
        }
      );
    }

    if (currentDocumentLayout.characters?.length !== prevProps.currentDocumentLayout.characters?.length) {
      this.checkDocumentProcessType();
    }

    const openedDocuments = JSON.parse(localStorage.getItem(`openedDocuments-${userId}`)) || [];
    const uniqueId = `${geoCode}-${projectId}-${documentId}`;

    if (openedDocuments.indexOf(uniqueId) < 0) {
      openedDocuments.push(uniqueId);
      localStorage.setItem(`openedDocuments-${userId}`, JSON.stringify(openedDocuments));
    }
  }

  componentWillUnmount() {
    this.props.clearUndoStack();
    // Unsubscribe to socket events
    socket.off('document.update.state', this.loadData);
    socket.off('document.delete', this.setDocumentDeleted);
    if (this.documentRoom) socket.leave(this.documentRoom);

    this.props.unsubscribeCurrentDocument();
    this.props.clearCurrentDocument();
    document.removeEventListener('keydown', this.onKeyDown);
  }

  documentStateUpdated = message => {
    var { projectId, documentId } = this.props;
    if (message && message.data && message.data.projectId === projectId && message.data.projectItemId === documentId) {
      this.loadData(false);
    }
  };

  loadData = documentIdChanged => {
    const { projectId, documentId, getCurrentDocument } = this.props;
    if (!projectId || !documentId) {
      return;
    }

    this.setState({ documentStateErrors: [] }, () => {
      getCurrentDocument(projectId, documentId);      
    });    
  };

  // Document Changes + record changes
  // The majority of socket events for document and template data are handled within the currentDocument store
  joinDocumentRoom() {
    if (!this.documentRoom) {
      this.documentRoom = rooms.document(this.props.projectId, this.props.documentId);
      socket.join(this.documentRoom);
      socket.on('document.update.state', this.documentStateUpdated);
      socket.on('document.delete', this.setDocumentDeleted);
    }
  }

  // Sets document state
  setDocumentDeleted() {
    this.setState({
      documentStateErrors: [Constants.Document_Error_States.DOCUMENTDELETED]
    });
  }

  // Parse url to see where to scroll to from either XF record location or page num query string
  parseQuery = () => {
    const query = parseQuery(this.props.location.search, { ignoreQueryPrefix: true });

    return {
      location: query.location ? query.location.map(i => parseInt(i, 10)) : null,
      page: query.page ? query.page : null
    };
  };

  // This function responds to an event emitted by the create-extraction component
  messageListenerFunction = message => {
    if (message && message.data && message.data.showArgusTrainingModal) {
      this.setState({
        showArgusTrainingModal: true,
        trainingTargetDocumentsNewField: message.data.trainingTargetDocumentsNewField
      });
    }
  };

  // used to check if a document was processed by kira or abbyy
  checkDocumentProcessType = () => {
    var {
      currentDocumentLayout: { characters }
    } = this.props;
    var isKira = true;

    for (let i = 0; i < characters.length; i++) {
      if (characters[i].u.length > 1) {
        isKira = false;
        break;
      } else if (this.isBreak(characters[i].u[0])) {
        break;
      }
    }

    this.setState({ isKira });
  };

  isBreak = unicode => {
    return Constants.TextSelectionBreakCharacters.indexOf(unicode) !== -1;
  };

  // Used to get plain text string from character range (plain text string needs to be sent to api when creating an extraction field record)
  getText = (start, end) => {
    var { characters } = this.props.currentDocumentLayout;
    const { isKira } = this.state;

    if (characters !== null && characters.length > 0) {
      var loc = [start, end].sort((a, b) => (a < b ? -1 : b < a ? 1 : 0));
      var firstIndex = characters.findIndex(c => c.i === loc[0]);
      var secondIndex = characters.findIndex(c => c.i === loc[1]);
      var characterRange = characters.slice(firstIndex, secondIndex + 1);
      var isHebrew = characterRange.map(c=> c.u.some(c => (c>= 1424 && c<= 1535)))
      if (isHebrew.some(ch => ch == true))
      {
        var revertedText = characterRange.reduceRight(
          (a, b) =>
            a + 
            (b.u.length
              ? (b.u.map(c => String.fromCharCode(c)).join('') + ' ') 
              : String.fromCharCode(b.u)),
          ''
        );
        while (!!revertedText.trim().match(/[.,:!?]$/))
        {
          var result = revertedText.charAt(revertedText.length - 1)
          revertedText = result + revertedText;
          revertedText = revertedText.slice(0,-1)
        }
        return  revertedText;
      }
      else 
      {
        var text = characterRange.reduce(
          (a, b) =>
            a + 
            (b.u.length
              ? b.u.map(c => String.fromCharCode(c)).join('') + (b.i === end || isKira ? '' : ' ')
              : String.fromCharCode(b.u)),
          ''
        );
        return text;
      }
    }
    return '';
  };

  // This function is passed down to the overlay component and handles user selection
  selectionFunction = ({ currentSelection } = {}) => {
    if (this.props.currentProject.STATES.ReadOnly()) return;

    var changed = currentSelection !== this.state.currentSelection;

    if (changed) {
      this.setState({ currentSelection });
      const { startingWord, endingWord } = currentSelection;
      var f, l, p, offset;

      if (startingWord) {
        if (endingWord) {
          const direction = startingWord.start.character.i < endingWord.start.character.i;
          f = direction ? startingWord.start.character.i : endingWord.start.character.i;
          l = direction ? endingWord.end.character.i : startingWord.end.character.i;
          offset = direction ? endingWord.end.character.u.length : startingWord.end.character.u.length;
          p = direction ? startingWord.start.page : endingWord.start.page;
        } else {
          f = startingWord.start.character.i;
          l = startingWord.end.character.i;
          offset = startingWord.end.character.u.length;
          p = startingWord.start.page;
        }

        this.setState({
          selection: {
            firstIndex: f,
            lastIndex: l + (offset ? offset - 1 : 0),
            text: this.getText(f, l),
            page: p
          }
        });
      } else {
        this.setState({ selection: null });
      }
    }
  };

  setSearchResults = results => {
    this.setState({ searchResults: results });
  };

  extractionPickerCloseFunction = () => {
    this.setState({
      currentSelection: CURRENT_SELECTION_INITIAL,
      selection: null
    });
  };

  checkErrorState = () => {
    var { currentDocument, currentDocumentLayout } = this.props;
    var { documentStateErrors } = this.state;
    var errors = documentStateErrors.slice(0);

    if (currentDocument.isLoaded && currentDocument.itemStateId !== ITEM_STATUSES.PROCESSED) {
      errors.push(Constants.Document_Error_States.UNPROCESSED);
    }

    if (currentDocument.error !== null) {
      errors.push(Constants.Document_Error_States.METADATA404);
    }

    if (currentDocumentLayout.error !== null) {
      errors.push(Constants.Document_Error_States.LAYOUT404);
    }

    return errors;
  };

  createExtractionField = ({ name, description, templateId, efGroupId }) => {
    const { selection } = this.state;
    const { projectId, documentId } = this.props;

    return this.props.createExtractionField({
      projectId,
      documentId,
      templateId,
      extractionFieldGroupId: efGroupId,
      extractionFieldName: name,
      extractionFieldDescription: description,
      selection
    });
  };

  onCreateExtractionSuccess = () =>
    this.setState({
      selection: null,
      currentSelection: CURRENT_SELECTION_INITIAL
    });

  addExtractionField = ({ extractionField, templateId }) => {
    const { selection } = this.state;
    const { projectId, documentId } = this.props;

    return this.props
      .addExtractionField({
        projectId,
        documentId,
        templateId,
        extractionField,
        selection
      })
      .then(() => {
        this.setState({
          selection: null,
          currentSelection: CURRENT_SELECTION_INITIAL
        });
      });
  };

  createExtractionFieldRecord = extractionFieldId => {
    const { projectId, documentId, createExtractionFieldRecord } = this.props;
    const { selection } = this.state;

    return createExtractionFieldRecord(projectId, documentId, extractionFieldId, selection).then(() => {
      this.setState({
        selection: null,
        currentSelection: CURRENT_SELECTION_INITIAL
      });
    });
  };

  onKeyDown = event => {
    if (
      this.documentRendererFocus &&
      event.target.className !== 'document-splitter__name-input' &&
      event.target.className !== 'editable-text__input' &&
      (event.ctrlKey || event.metaKey)
    ) {
      this.hiddenTextArea.select();
    }
  };

  focusFunction = focus => {
    this.documentRendererFocus = focus;
  };

  updateMenuState = isOpen => {
    sessionStorage.setItem('document-splitter-isMenuOpen', isOpen);
    this.setState({ isMenuOpen: isOpen });
  };

  render() {
    var { selection, currentSelection, showArgusTrainingModal, searchResults, isMenuOpen } = this.state;
    var { currentSearchFolder, currentProject, history } = this.props;    
    var readOnly = currentProject.STATES.ReadOnly();
    var errors = this.checkErrorState();

    return (
      <div className={`document-viewer ${readOnly ? 'readOnly' : ''}`}>
        <Header
          {...this.props}
          currentPage="global.subheader.extraction"
          documentViewerSetSearchResults={this.setSearchResults}
          isSearchResultsOpen={this.state.isSearchResultsOpen}
        />

        <div className="panel-wrapper">
          <MainPanel
            currentSearchFolder={currentSearchFolder}
            documentStateErrors={errors}
            initialLocation={this.parseQuery()}
            readOnly={readOnly}
            searchResults={searchResults}
            selection={selection}
            currentSelection={currentSelection}
            selectionFunction={this.selectionFunction}
            history={history}
            openSearchResults={value => this.setState({ isSearchResultsOpen: value })}
            focusFunction={this.focusFunction}
            isMenuOpen={isMenuOpen}
            updateMenu={this.updateMenuState}
          />
          <SidePanel
            closeFunction={this.extractionPickerCloseFunction}
            createExtractionField={this.createExtractionField}
            addExtractionField={this.addExtractionField}
            createExtractionFieldRecord={this.createExtractionFieldRecord}
            hasSelection={!!selection}
            readOnly={readOnly}
            isMenuOpen={isMenuOpen}
            onCreateExtractionSuccess={this.onCreateExtractionSuccess}
          />
          <textarea
            value={selection ? selection.text : ''}
            className="document-viewer__hidden-textarea"
            ref={elem => (this.hiddenTextArea = elem)}
            readOnly
          />
          <div className="modals">
            {showArgusTrainingModal ? (
              <Modal id="argus-training-modal">
                <ArgusTrainingModal
                  closeFunction={() => {
                    this.setState({ showArgusTrainingModal: false });
                  }}
                  trainingTargetDocumentsNewField={this.state.trainingTargetDocumentsNewField ?? 30}
                />
              </Modal>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentViewer;
