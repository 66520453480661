import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { EditExtractions } from 'models/edit-extractions';
import socket, { rooms } from 'utils/socket';
import { itemsDeleted } from './current-folder';
import { currentFolderInvalidate } from './current-folder';

export const ACTIONS = {
  // Extractions
  PROJECT_EXTRACTIONS_LOADING: 'argus/ui/PROJECT_EXTRACTIONS_LOADING',
  PROJECT_EXTRACTIONS_LOADED: 'argus/ui/PROJECT_EXTRACTIONS_LOADED',
  UPDATE_ALL_EXTRACTIONS: 'argus/ui/UPDATE_ALL_EXTRACTIONS',
  PROJECT_TEMPLATE_EXTRACTIONS_LOADING: 'argus/ui/PROJECT_TEMPLATE_EXTRACTIONS_LOADING',
  PROJECT_TEMPLATE_EXTRACTIONS_LOADED: 'argus/ui/PROJECT_TEMPLATE_EXTRACTIONS_LOADED',
  PROJECT_DELETE_EXTRACTION: 'argus/ui/PROJECT_DELETE_EXTRACTION',
  PROJECT_MOVE_EXTRACTION: 'argus/ui/PROJECT_MOVE_EXTRACTION',
  PROJECT_ADD_EXTRACTION: 'argus/ui/PROJECT_ADD_EXTRACTION',
  // Data fetching
  TEMPLATES_LOADING: 'argus/ui/TEMPLATES_LOADING',
  TEMPLATES_LOADED: 'argus/ui/TEMPLATES_LOADED',
  ADD_OR_UPDATE_PROJECT_TEMPLATE: 'argus/ui/ADD_OR_UPDATE_PROJECT_TEMPLATE',
  // Data duplicating
  DUPLICATE_PROJECT_TEMPLATE: 'argus/ui/DUPLICATE_PROJECT_TEMPLATE',
  // Data select
  SELECTED_PROJECT_TEMPLATE: 'argus/ui/SELECTED_PROJECT_TEMPLATE',
  // Data delete
  DELETE_PROJECT_TEMPLATE: 'argus/ui/DELETE_PROJECT_TEMPLATE',
  // User change template name
  RENAME_TEMPLATE_NAME: 'argus/ui/RENAME_TEMPLATE_NAME',
  EDIT_EXTRACTIONS_RESET_STATE: 'argus/ui/EDIT_EXTRACTIONS_RESET_STATE'
};

export const allExtractionsLoading = createAction(ACTIONS.PROJECT_EXTRACTIONS_LOADING);
export const allExtractionsLoaded = createAction(ACTIONS.PROJECT_EXTRACTIONS_LOADED);
export const modifyAllExtractions = createAction(ACTIONS.UPDATE_ALL_EXTRACTIONS);
export const templateExtractionsLoading = createAction(ACTIONS.PROJECT_TEMPLATE_EXTRACTIONS_LOADING);
export const templateExtractionsLoaded = createAction(ACTIONS.PROJECT_TEMPLATE_EXTRACTIONS_LOADED);
export const delExtractionsTemplate = createAction(ACTIONS.PROJECT_DELETE_EXTRACTION);
export const moveExtractionsTemplate = createAction(ACTIONS.PROJECT_MOVE_EXTRACTION);
export const addExtractionsTemplate = createAction(ACTIONS.PROJECT_ADD_EXTRACTION);

export const projectTemplatesLoading = createAction(ACTIONS.TEMPLATES_LOADING);
export const projectTemplatesLoaded = createAction(ACTIONS.TEMPLATES_LOADED);

export const addOrUpdateProjectTemplate = createAction(ACTIONS.ADD_OR_UPDATE_PROJECT_TEMPLATE);

export const dupeProjectTemplate = createAction(ACTIONS.DUPLICATE_PROJECT_TEMPLATE);

export const selProjectTemplate = createAction(ACTIONS.SELECTED_PROJECT_TEMPLATE);

export const delProjectTemplate = createAction(ACTIONS.DELETE_PROJECT_TEMPLATE);

export const modifyTemplateName = createAction(ACTIONS.RENAME_TEMPLATE_NAME);

export const resetState = createAction(ACTIONS.EDIT_EXTRACTIONS_RESET_STATE);

// Contains the logic for subscribing/unsubscribing to template create/updated/delete updates
const { subscribeToTemplates, unsubscribeToTemplates } = (socket => {
  let room = null;
  let onTemplateAdded = null;
  let onTemplateUpdated = null;
  let onTemplateDeleted = null;
  let onFieldPublished = null;

  const subscribeToTemplates = projectId => dispatch => {
    if (!projectId) {
      return;
    }

    const nextRoom = rooms.templates(projectId);

    if (room !== nextRoom) {
      socket.leave(room);
      room = nextRoom;
      socket.join(nextRoom);
    }

    // Join the extraction fields room since we bind to
    // publish/unpublish updates
    socket.join(rooms.extractionFields());

    if (!onTemplateAdded) {
      onTemplateAdded = response => {
        dispatch(addOrUpdateProjectTemplate(response.data));
      };
      socket.on('project.template.added', onTemplateAdded);
    }

    if (!onTemplateUpdated) {
      onTemplateUpdated = response => {
        dispatch(addOrUpdateProjectTemplate(response.data));
      };
      socket.on('project.template.update', onTemplateUpdated);
    }

    if (!onTemplateDeleted) {
      onTemplateDeleted = response => {
        dispatch(delProjectTemplate(response.data));
      };
      socket.on('project.template.deleted', onTemplateDeleted);
    }

    if (!onFieldPublished) {
      onFieldPublished = response => {
        api.getExtractionFields().then(res => dispatch(allExtractionsLoaded(res.data)));
      };
      socket.on('extractionfield.publish', onFieldPublished);
    }
  };

  const unsubscribeToTemplates = () => {
    socket.leave(room);
    room = null;
    socket.leave(rooms.extractionFields());

    if (onTemplateAdded) {
      socket.off('project.template.added', onTemplateAdded);
      onTemplateAdded = null;
    }

    if (onTemplateUpdated) {
      socket.off('project.template.update', onTemplateUpdated);
      onTemplateUpdated = null;
    }

    if (onTemplateDeleted) {
      socket.off('project.template.deleted', onTemplateDeleted);
      onTemplateDeleted = null;
    }

    if (onFieldPublished) {
      socket.off('extractionfield.publish', onFieldPublished);
      onFieldPublished = null;
    }
  };

  return {
    subscribeToTemplates,
    unsubscribeToTemplates
  };
})(socket);

//Contains the logic for subscribing/unsubscribing to folder delete when template delete
const { subscribeToFolder, unsubscribeToFolder } = (socket => {
  let projectRoom = null;
  let folderRoom = null;
  let onDelete = null;

  const subscribeToFolder = (projectId, folderId) => dispatch =>{
    if (!projectId || !folderId) {
      return;
    }

    const nextProjectRoom = rooms.project(projectId);
    const nextFolderRoom = rooms.folder(projectId, folderId);

    if (projectRoom !== nextProjectRoom) {
      socket.leave(projectRoom);
      projectRoom = nextProjectRoom;
      socket.join(projectRoom);
    }

    if (folderRoom !== nextFolderRoom) {
      socket.leave(folderRoom);
      folderRoom = nextFolderRoom;
      socket.join(folderRoom);
    }

    if (!onDelete) {
      onDelete = response => {
        dispatch(itemsDeleted([response.data]));
        dispatch(currentFolderInvalidate());
      };
      socket.on('document.delete', onDelete);
      socket.on('folder.delete', onDelete);
    }

  };

  const unsubscribeToFolder = () => {
    socket.leave(projectRoom);
    socket.leave(folderRoom);
    projectRoom = null;
    folderRoom = null;

    if (onDelete) {
      socket.off('document.delete', onDelete);
      socket.off('folder.delete', onDelete);
      onDelete = null;
    }
  };

  return{
    subscribeToFolder,
    unsubscribeToFolder
  }
})(socket)


// Contains the logic for subscribing/unsubscribing to xf added/removed from template updates
const { subscribeToTemplateFields, unsubscribeToTemplateFields } = (socket => {
  let room = null;
  let onFieldAdded = null;
  let onFieldRemoved = null;

  const subscribeToTemplateFields = (projectId, templateId) => dispatch => {
    if (!projectId) {
      return;
    }

    const nextRoom = rooms.templateExtractionFields(projectId, templateId);

    if (room !== nextRoom) {
      socket.leave(room);
      room = nextRoom;
      socket.join(nextRoom);
    }

    if (!onFieldRemoved) {
      onFieldRemoved = response => {
        dispatch(delExtractionsTemplate(response.data.extractionField));
      };
      socket.on('delete', onFieldRemoved);
    }
  };

  const unsubscribeToTemplateFields = () => {
    socket.leave(room);
    room = null;

    if (onFieldAdded) {
      socket.off('create', onFieldAdded);
      onFieldAdded = null;
    }

    if (onFieldRemoved) {
      socket.off('remove', onFieldRemoved);
      onFieldRemoved = null;
    }
  };

  return {
    subscribeToTemplateFields,
    unsubscribeToTemplateFields
  };
})(socket);

/* EXTRACTIONS */
export const loadAllExtraction = projectId => dispatch => {
  dispatch(allExtractionsLoading());
  return api.getExtractionFields().then(res => dispatch(allExtractionsLoaded(res.data)));
};

export const loadTemplateExtraction = (projectId, templateId) => dispatch => {
  dispatch(templateExtractionsLoading());
  return api
    .getProjectTemplateExtractionFields(projectId, templateId)
    .then(res => {
      dispatch(templateExtractionsLoaded(res.data));
      dispatch(subscribeToTemplateFields(projectId, templateId));
      return res;
    })
    .catch(err => err);
};

export const moveExtraction = data => dispatch => {
  return dispatch(moveExtractionsTemplate(data));
};

export const deleteExtraction = data => dispatch => {
  return api.deleteExtraction(data);
};

export const updateAllExtractions = data => dispatch => {
  return dispatch(modifyAllExtractions(data));
};

/* PROJECT TEMPLATES */
// Load project templates
export const loadProjectTemplates = (data, defaultTemplate = null) => dispatch => {
  dispatch(projectTemplatesLoading());
  return api.getTemplates(data).then(res => {
    dispatch(projectTemplatesLoaded({ data: res.data, defaultTemplate }));
    dispatch(subscribeToTemplates(data.projectId));
    return res;
  });
};

// Create project template for first time
export const addProjectTemplate = data => dispatch => {
  return api.createTemplate(data).then(res => {
    dispatch(addOrUpdateProjectTemplate(res.data));
    dispatch(selProjectTemplate(res.data));
    return res;
  });
};

// Create a duplicate of project template
export const duplicateProjectTemplate = data => (dispatch, getState) => {
  return api.createTemplate(data).then(res => {
    dispatch(dupeProjectTemplate(res.data));
    return res;
  });
};

// Update exisiting project template
export const updateProjectTemplate = data => dispatch => {
  return api.updateTemplate(data).then(res => {
    dispatch(addOrUpdateProjectTemplate(data));
  });
};

// User selects a project template
export const selectProjectTemplate = data => dispatch => {
  dispatch(selProjectTemplate(data));
};

// Delete Project Template
export const deleteProjectTemplate = data => dispatch => {
  dispatch(subscribeToFolder(data.projectId, data.projectItemId))
  return api.deleteTemplate(data.projectId, data.templateId).then(res => {
    dispatch(loadProjectTemplates(data));
  });
};

// User changes template name
export const changeTemplateName = data => dispatch => {
  return dispatch(modifyTemplateName(data));
};

export const clearTemplatesData = () => dispatch => {
  unsubscribeToTemplates();
    unsubscribeToTemplateFields();
    unsubscribeToFolder();
  dispatch(resetState());
};

/*
 * Reducer
 */
export const INITIAL_STATE = new EditExtractions();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.PROJECT_TEMPLATE_EXTRACTIONS_LOADING]: (state, action) => {
    return state.setTemplateExtLoading();
  },

  [ACTIONS.PROJECT_TEMPLATE_EXTRACTIONS_LOADED]: (state, action) => {
    return state.setTemplateExtLoaded(state, action);
  },

  [ACTIONS.PROJECT_EXTRACTIONS_LOADING]: (state, action) => {
    return state.setAllExtLoading();
  },

  [ACTIONS.PROJECT_EXTRACTIONS_LOADED]: (state, action) => {
    return state.setAllExtLoaded(state, action);
  },

  [ACTIONS.PROJECT_DELETE_EXTRACTION]: (state, action) => {
    return state.setExtDeleted(state, action);
  },

  [ACTIONS.PROJECT_MOVE_EXTRACTION]: (state, action) => {
    return state.setExtMoved(state, action);
  },

  [ACTIONS.UPDATE_ALL_EXTRACTIONS]: (state, action) => {
    return state.setExtAllUpdate(state, action);
  },

  [ACTIONS.PROJECT_ADD_EXTRACTION]: (state, action) => {
    return state.setExtAdded(state, action);
  },

  [ACTIONS.TEMPLATES_LOADING]: (state, action) => {
    return state.setTemplatesLoading();
  },

  [ACTIONS.TEMPLATES_LOADED]: (state, action) => {
    return state.setTemplatesLoaded(state, action);
  },

  [ACTIONS.ADD_OR_UPDATE_PROJECT_TEMPLATE]: (state, action) => {
    return state.setAddedOrUpdated(state, action);
  },

  [ACTIONS.DUPLICATE_PROJECT_TEMPLATE]: (state, action) => {
    return state.setDuplicated(state, action);
  },

  [ACTIONS.SELECTED_PROJECT_TEMPLATE]: (state, action) => {
    return state.setTempSelected(state, action);
  },

  [ACTIONS.DELETE_PROJECT_TEMPLATE]: (state, action) => {
    return state.setDeleted(state, action);
  },

  [ACTIONS.RENAME_TEMPLATE_NAME]: (state, action) => {
    return state.setChangedName(state, action);
  },

  [ACTIONS.EDIT_EXTRACTIONS_RESET_STATE]: (state, action) => {
    return INITIAL_STATE;
  }
});
