import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { authenticate } from 'store/api';

// Routing Components
import AuthSuccess from 'components/app/routing/auth-success';
import ProtectedRoute from 'components/app/routing/protected-route';
import PublicRoute from 'components/app/routing/public-route';
import Region from 'components/app/routing/region-route-switch';

// Application Pages
import IntlContainer from 'containers/intl/intl-container';
import NotFound from 'components/pages/errors/404';
import Unauthorized from 'containers/authorization/unauthorized-container';
import ProjectListPage from 'components/pages/project-list';
import AdminAccessPage from 'components/pages/admin-access';
import LandingPage from 'components/pages/landing-page';
import LoadingPage from 'components/pages/loading-page';
import Translation from 'components/pages/translation';
import MaintenanceMessagesPage from 'components/pages/maintenance-messages';
import SessionTimeoutModal from 'components/shared/session-timeout-modal';
import store from 'store/store';
import history from 'utils/history';
import CookieNotice from '../cookie-notice/cookie-notice';
import { FormattedMessage } from 'react-intl';

class App extends React.Component {
  state = {
    authenticated: false
  };

  authenticateUser = () => {
    authenticate().then(() => {
      this.setState({
        authenticated: true
      });
    });
  };

  componentDidUpdate() {

    var appendAbodeScript = true;
    var scriptData = window.ARGUS.ENV.GEOS.find(geo => geo.GEO_CODE && geo.GEO_CODE !== 'NONE');                  
    if(this.state.authenticated && document.querySelectorAll(`script[src="${scriptData.ONETRUST_URL}"]`).length <= 0)
    {
      const script = document.createElement("script");
      script.src = scriptData.ONETRUST_URL;
      script.type = "text/javascript"
      script.dataset.domainScript = scriptData.ONETRUST_DOMAIN;
      document.body.appendChild(script);
    }

    var scripts = document.getElementsByTagName('script');
    for( var i = 0 ; i < scripts.length ; i++)
    {
      if(scripts[i].src.indexOf(scriptData.ADOBE_URL) > 0 )
      {
        appendAbodeScript = false;
      }
    }

    if(appendAbodeScript)
    {
      const script = document.createElement("script");
      script.src = scriptData.ADOBE_URL;
      document.head.appendChild(script);
    }
  }
  
  render() {
    const { authenticated } = this.state;

    return (
      <Provider store={store}>
        <IntlContainer authenticated={authenticated}>
          <ConnectedRouter history={history}>
            <div className="app">
            <span className = "cookielink">
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              <FormattedMessage id ="global.footer.cookie-setting"/>
              </button>
            </span>
              {/* Azure AD token renewal iframe may cause issues with the session timeout modal */}
              {/* So we must ensure this component does not mount in an iframe*/}
              {window === window.parent && <SessionTimeoutModal />}

              <Switch>
                <PublicRoute
                  path="/region/:regionId"
                  component={Region}
                  authenticateFunction={this.authenticateUser}
                  authenticated={authenticated}
                />

                <PublicRoute exact path="/authsuccess" component={AuthSuccess} />
                <PublicRoute exact path="/unauthorized" component={Unauthorized} />

                <ProtectedRoute
                  exact
                  path="/"
                  component={ProjectListPage}
                  authenticateFunction={this.authenticateUser}
                  authenticated={authenticated}
                />

                <ProtectedRoute
                  exact
                  path="/project-list"
                  component={ProjectListPage}
                  authenticateFunction={this.authenticateUser}
                  authenticated={authenticated}
                />

                <ProtectedRoute
                  exact
                  path="/admin-access"
                  component={AdminAccessPage}
                  authenticateFunction={this.authenticateUser}
                  authenticated={authenticated}
                />

                <Redirect exact from="/project" to="/" />

                <ProtectedRoute
                  exact
                  path="/translation"
                  component={Translation}
                  authenticateFunction={this.authenticateUser}
                  authenticated={authenticated}
                />
                
                <ProtectedRoute
                  exact
                  path="/maintenance-messages"
                  component={MaintenanceMessagesPage}
                  authenticateFunction={this.authenticateUser}
                  authenticated={authenticated}
                />
                
                <ProtectedRoute
                  exact
                  path="/cookie-notice"
                  component={CookieNotice}
                  authenticateFunction={this.authenticateUser}
                  authenticated={authenticated}
                />
                
                <ProtectedRoute
                  exact
                  path="/loading"
                  component={LoadingPage}
                  authenticateFunction={this.authenticateUser}
                  authenticated={authenticated}
                />

                <ProtectedRoute
                  component={NotFound}
                  authenticateFunction={this.authenticateUser}
                  authenticated={authenticated}
                />
              </Switch>
            </div>
          </ConnectedRouter>
        </IntlContainer>
      </Provider>
    );
  }
}

export default App;
