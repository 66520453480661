import model from 'models/base-model';
import { ProjectItem, ParentItem, Tag, Reviewer, Template } from 'models/project-item';
import dateUtil from 'utils/dateUtil';

export class SearchCategory extends model({
  isLoading: false,
  isLoaded: false,
  isNullResults: false,
  error: null,
  categories: {
    documents: {
      data: []
    },
    extractions: {
      data: []
    },
    folders: {
      data: []
    },
    reviewers: {
      data: []
    },
    documentTags: {
      data: []
    },
    extractionFieldInstanceTags: {
      data: []
    },
    templates: {
      data: []
    }
  },
  query: '',
  payload: [],
  filters: [],
  trackingQuery: '',
  selectedCategory: '',
  sortOrder: '',
  sortBy: ''
}) {
  setLoading(state) {
    return this.merge({
      ...state,
      isLoading: true,
      isLoaded: false,
      error: null,
      categories: {
        ...state.categories
      }
    });
  }

  setError(state, error) {
    return this.merge({
      isLoading: false,
      isLoaded: false,
      error: error,
      categories: {
        ...state.categories
      }
    });
  }

  setQueryChange(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      error: null,
      query: action.payload
    });
  }

  setAdvancedSearchChange(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      error: null,
      filters: action.payload.filter,
      payload: action.payload.payload
    });
  }

  setQueryTrack(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      error: null,
      trackingQuery: action.payload
    });
  }

  setCategorySelected(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      error: null,
      selectedCategory: action.payload.key,
      selectedData: action.payload.data
    });
  }

  setSorting(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      error: null,
      sortOrder: action.payload.sortOrder,
      sortBy: action.payload.sortBy
    });
  }

  setLoaded(state, action) {
    // This is to ensure that the results are always displayed for the query that the user types in
    if (state.trackingQuery === action.payload.resolvedQuery) {
      return this.merge({
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        isNullResults:
          action.payload.documents.data.data.length <= 0 &&
          action.payload.extractions.data.data.length <= 0 &&
          action.payload.folders.data.data.length <= 0 &&
          action.payload.reviewers.data.data.length <= 0 &&
          action.payload.documentTags.data.data.length <= 0 &&
          action.payload.extractionFieldInstanceTags.data.data.length <= 0 &&
          action.payload.templates.data.data.length <= 0,
        categories: {
          ...state.categories,
          documents: action.payload.documents ? action.payload.documents.data : { data: [] },
          extractions: action.payload.extractions ? action.payload.extractions.data : { data: [] },
          folders: action.payload.folders ? action.payload.folders.data : { data: [] },
          reviewers: action.payload.reviewers ? action.payload.reviewers.data : { data: [] },
          documentTags: action.payload.documentTags ? action.payload.documentTags.data : { data: [] },
          extractionFieldInstanceTags: action.payload.extractionFieldInstanceTags
            ? action.payload.extractionFieldInstanceTags.data
            : { data: [] },
          templates: action.payload.templates ? action.payload.templates.data : { data: [] }
        }
      });
    }
  }
}

export class SearchResults extends ProjectItem {
  setLoading() {
    return this.merge({
      error: null,
      isLoaded: false,
      isLoading: true
    });
  }

  setCurrentFolderLoaded(state, data) {
    return SearchResults.fromData(data);
  }

  setDocumentsLoaded(state, data) {
    return this.merge({
      ...state,
      isLoaded: true,
      isLoading: false,
      children: data && data.data.length > 0 ? data.data.map(model => SearchResults.fromData({ data: model })) : [],
      pagination: data.pageInfo,
      parentHierarchy: data.parentHierarchy ? data.parentHierarchy : []
    });
  }

  setFoldersLoaded(state, data) {
    return this.merge({
      ...state,
      isLoaded: true,
      isLoading: false,
      children: data && data.data.length > 0 ? data.data.map(model => SearchResults.fromData({ data: model })) : [],
      pagination: data.pageInfo
    });
  }

  setAdvancedLoaded(state, data) {
    return this.merge({
      ...state,
      isLoaded: true,
      isLoading: false,
      children: data && data.data.length > 0 ? data.data.map(model => SearchResults.fromData({ data: model })) : [],
      pagination: data.pageInfo
    });
  }

  invalidate() {
    return this.merge({
      error: null,
      isLoaded: false,
      isLoading: false
    });
  }

  static fromData(model) {
    const data = model.data;
    const pagination = model.pageInfo;

    return new SearchResults({
      ...data,
      error: null,
      isLoaded: true,
      isLoading: false,
      children: data.children ? data.children.map(model => SearchResults.fromData({ data: model })) : [],
      lastUpdatedDate: dateUtil.fromServerDate(data.lastUpdatedDate),
      lastProcessedDate: dateUtil.fromServerDate(data.lastProcessedDate),
      pagination,
      parentHierarchy: data.parentHierarchy ? data.parentHierarchy.map(model => ParentItem.fromData(model)) : [],
      projectTemplates: data.projectTemplates ? data.projectTemplates.map(model => Template.fromData(model)) : [],
      reviewers: data.reviewers ? data.reviewers.map(model => Reviewer.fromData(model)) : [],
      tags: data.tags ? data.tags.map(model => Tag.fromData(model)) : []
    });
  }
}
