import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Button from 'components/shared/single-click-button';
import { getProjectUsers, setCurrentRegion, getCurrentRegion } from 'store/api';
import Icon from 'components/shared/icon';
import enhanceWithClickOutside from 'react-click-outside';
import Modal from 'components/shared/modal';
import Confirm from 'components/shared/confirm';
import history from 'utils/history';

import Permissions from 'permissions/permissions';
import socket, { rooms } from 'utils/socket';
import constants from 'utils/constants';

const MENU_OPTIONS = {
  CANCEL_ARCHIVE: 'CANCEL_ARCHIVE',
  CARRY: 'CARRY',
  COPY: 'COPY',
  DELETE: 'DELETE',
  ENABLE_SUPPORT: 'ENABLE_SUPPORT',
  FLAG_NON_CLIENT: 'FLAG_NON_CLIENT',
  LEGAL_HOLD: 'LEGAL_HOLD',
  REMOVE_HOLD: 'REMOVE_HOLD',
  REQ_ARCHIVE: 'REQ_ARCHIVE',
  RESTORE: 'RESTORE'
};

const MENU_MODALS = {
  CANCEL_ARCHIVE: 'CANCEL_ARCHIVE',
  CARRY: 'CARRY',
  COPY: 'COPY',
  DELETE: 'DELETE',
  ENABLE_SUPPORT: 'ENABLE_SUPPORT',
  LEGAL_HOLD: 'LEGAL_HOLD',
  REMOVE_HOLD: 'REMOVE_HOLD',
  REQ_ARCHIVE: 'REQ_ARCHIVE',
  RESTORE: 'RESTORE'
};

const PROJECT_STATUS = {
  ARCHIVED: 'archived',
  COPYING: 'copying',
  IN_CREATION: 'inCreation',
  PENDING_ARCHIVE: 'pendingArchive',
  SOFT_DELETE: 'softDelete',
  STARTED: 'started'
};

function MenuOption({ visibleOptions, menuOptions, ...props }) {
  return (
    <span>
      {props.disabled ? null : visibleOptions.every(o => o == false || o == null) ? null : (
        <Button size="icon" className={`icon-button menu__button-${props.viewer}`} onClick={props.onMenuClick}>
          <Icon name="special-menu" width={16} />
        </Button>
      )}

      {props.isOpen ? (
        <div className={`menu__container-${props.viewer}`} onClick={props.onClose}>
          {visibleOptions.map(option => menuOptions[option])}
        </div>
      ) : null}
    </span>
  );
}

MenuOption.propTypes = {
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  menuOptions: PropTypes.object,
  onClickOutside: PropTypes.func,
  onClose: PropTypes.func,
  onMenuClick: PropTypes.func,
  projectState: PropTypes.any,
  viewer: PropTypes.string,
  visibleOptions: PropTypes.array
};

// Common modal viewer for the different options from the dropdown
function CommonModal({ heading, body, leftBtn, rightBtn, checkboxCopy, ...props }) {
  return (
    <Confirm
      disableSubmit={props.disabled}
      onCancel={props.onClose}
      onSubmit={(e, params) => {
        e.preventDefault();
        props.onSubmit(e, params);
      }}
      title={<FormattedMessage id={heading} />}
      message={<FormattedMessage id={body} />}
      cancelText={<FormattedMessage id={leftBtn} />}
      confirmText={<FormattedMessage id={rightBtn} />}
      checkboxCopy={checkboxCopy && <FormattedMessage id={checkboxCopy} />}
    />
  );
}

CommonModal.propTypes = {
  body: PropTypes.string,
  checkboxCopy: PropTypes.any,
  disabled: PropTypes.bool,
  heading: PropTypes.string,
  leftBtn: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  rightBtn: PropTypes.string
};

function DeleteModal({ ...props }) {
  return (
    <CommonModal
      heading="menu.modal.delete-project"
      body="menu.modal.delete-body"
      leftBtn="common.cancel"
      rightBtn="common.delete"
      {...props}
    />
  );
}

function RestoreModal({ ...props }) {
  return (
    <CommonModal
      heading="menu.modal.restore-project"
      body="menu.modal.restore-body"
      leftBtn="common.no"
      rightBtn="common.yes"
      {...props}
    />
  );
}

function CancelArchiveModal({ ...props }) {
  return (
    <CommonModal
      heading="menu.modal.cancel-archive-project"
      body="menu.modal.cancel-archive-body"
      leftBtn="common.cancel"
      rightBtn="menu.modal.cancel-archive-project"
      {...props}
    />
  );
}

function CopyModal({ ...props }) {
  return (
    <CommonModal
      heading="menu.modal.copy-project"
      body="menu.modal.copy-body"
      leftBtn="common.no"
      rightBtn="common.yes"
      checkboxCopy="menu.modal.copy-checkbox-label"
      {...props}
    />
  );
}

function CarryForwardModal({ ...props }) {
  return (
    <CommonModal
      heading="menu.modal.carry-forward-project"
      body="menu.modal.carry-forward-body"
      leftBtn="common.no"
      rightBtn="common.yes"
      checkboxCopy="menu.modal.copy-checkbox-label"
      {...props}
    />
  );
}

function LegalHoldModal({ ...props }) {
  return (
    <CommonModal
      heading="menu.options.legal-hold"
      body="menu.modal.legal-hold"
      leftBtn="common.cancel"
      rightBtn="common.yes"
      {...props}
    />
  );
}

function LiftLegalHoldModal({ ...props }) {
  return (
    <CommonModal
      heading="menu.options.remove-legal-hold"
      body="menu.modal.remove-legal-hold"
      leftBtn="common.cancel"
      rightBtn="common.yes"
      {...props}
    />
  );
}

class ArchiveModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    projectDetails: PropTypes.any,
    projectId: PropTypes.any,
    users: PropTypes.any
  };

  state = {
    isLoading: false,
    checked: [],
    selected: null,
    users: null,
    enabled: false
  };

  componentDidMount() {
    const { projectId } = this.props;
    if (projectId) {
      this.setState({ isLoading: true });
      // Lets get the list of Owner and Creator of Project user, and set the first to selected to first Owner and Creator of Project user
      return getProjectUsers({ projectId }).then(res => {
        const data = res.data.filter(v => v.userDetail && v.role.roleId == constants.UserRoles.PROJECT_OWNER || v.role.roleId == constants.UserRoles.PROJECT_CREATOR);
        if (data && data.length > 0) {
          const firstId = data[0].userId;
          return this.setState({ users: data, checked: { [firstId]: true }, isLoading: false, enabled: true });
        }
        return this.setState({ isLoading: false, users: [], enabled: false });
      });
    }
  }

  onButtonClick = selected => {
    const { checked } = this.state;
    const id = selected.userId;
    if (checked[selected.userId]) {
      this.setState({ checked: [] });
    }
    this.setState({ checked: { [id]: true } });
  };

  render() {
    const { onClose, onSubmit } = this.props;
    const { users, checked, isLoading } = this.state;

    return (
      <Modal>
        <div className="menu__modal-content">
          <div className="menu__modal-top">
            <span className="menu__modal-title">
              <FormattedMessage id="menu.modal.archive-project" />
            </span>
            <Button size="icon" className="icon-button" onClick={onClose}>
              <Icon name="special-cross-black" width={14} />
            </Button>
          </div>
          <div className="menu__modal-middle">
            <span className="menu__modal-archive-body">
              <FormattedMessage id="menu.modal.archive-body2" />
            </span>
            {users && users.length <= 0 ? (
              <p>No Engagement Leader available</p>
            ) : (
              <div className="menu__modal-body">
                {isLoading ? (
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <Icon className="spinner spinner--centered" name="loader" width={50} />
                  </div>
                ) : (
                  users &&
                  users.map((user, index) => (
                    <div key={index} className="form-field--inline-label menu__radio-wrapper">
                      <Button size="icon" className="menu__radio" onClick={() => this.onButtonClick(user)}>
                        <Icon
                          name={
                            checked[user.userId] ? 'special-radiobutton-selected' : 'special-radiobutton-unselected'
                          }
                          width={18}
                        />
                      </Button>

                      <span className="menu__radio-text">{user.userDetail.displayName}</span>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
          <div className="menu__modal-footer">
            <Button className="btn btn-secondary" onClick={onClose}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button className="btn btn-primary" disabled={!this.state.enabled} onClick={() => onSubmit(checked)}>
              <FormattedMessage id="menu.modal.archive-button" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

class Menu extends Component {
  static propTypes = {
    archiveReqProject: PropTypes.func,
    cancelArchiveProject: PropTypes.func,
    carrySourceProject: PropTypes.func,
    copySourceProject: PropTypes.func,
    deleteProject: PropTypes.func,
    enableSupportAccess: PropTypes.func,
    initiateLegalHold: PropTypes.func,
    // Either table or tile view
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onMenuClick: PropTypes.func,
    projectDetails: PropTypes.object,
    projectState: PropTypes.any,
    restoreDeletedProject: PropTypes.func,
    selected: PropTypes.number,
    user: PropTypes.object,
    viewer: PropTypes.string,
    onCopied : PropTypes.func
  };

  state = {
    disabled: false,
    isArchive: false,
    isArchiveRequestor: false,
    isCancelArchive: false,
    isCarryForward: false,
    isCopy: false,
    isDelete: false,
    isLegalHold: false,
    isRemoveHold: false,
    isRestore: false,
    isSettingFlagNonClientService: false
  };  

  componentWillUnmount(){
    socket.off('update', this.updateSourceProject);
    socket.leave(rooms.project(this.props.selected));
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      setCurrentRegion(this.getProjectRegionString());
    }
  }

  handleClickOutside = () => {
    if (!this.props.isOpen) {
      return;
    }
    this.props.onClose();
  };

  getProjectRegionString = () => {
    const { geoCode, memberFirmCode, containerCode } = this.props.projectDetails;

    if (geoCode && memberFirmCode && containerCode) {
      return `${geoCode}_${memberFirmCode}_${containerCode}`;
    } else {
      return null;
    }
  };

  onUserChange = value => {
    this.setState({ selectedUser: value });
  };

  onDelete = () => {
    const { deleteProject, selected, projectDetails } = this.props;

    const projectRegion = {
      geoCode: projectDetails.geoCode,
      memberFirmCode: projectDetails.memberFirmCode,
      containerCode: projectDetails.containerCode
    };

    this.setState({ isDelete: false });
    return deleteProject({ selected, soft: Permissions.Project.canRestore(projectDetails, projectRegion) });
  };

  onRestore = () => {
    const { restoreDeletedProject, selected } = this.props;
    this.setState({ isRestore: false });
    return restoreDeletedProject({ projectId: selected });
  };

  onCopy = (e, includeDocuments) => {
    const { selected, copySourceProject } = this.props;
    this.setState({ isCopy: false });
    socket.join(rooms.project(this.props.selected))
    socket.on('update', this.updateSourceProject);
    return copySourceProject({ projectId: selected, includeDocuments });
  };

  updateSourceProject = msg => {   
    this.props.onCopied(msg.data.project);
  }

  onEnableSupportAccess = () => {
    const { selected, projectState } = this.props;

    history.push({
      pathname: `/region/${getCurrentRegion()}/project/${selected}/settings`,
      state: { grantingAccess: true, inCreation: projectState === PROJECT_STATUS.IN_CREATION }
    });
  };

  onCarry = (e, includeDocuments) => {
    const { selected, carrySourceProject } = this.props;
    this.setState({ isCarryForward: false });
    socket.join(rooms.project(this.props.selected))
    socket.on('update', this.updateSourceProject);
    return carrySourceProject({ projectId: selected, includeDocuments });
  };

  onRequestArchive = userId => {
    // Get the approver Id

    const approverId = Object.keys(userId)[0];
    const { archiveReqProject, selected } = this.props;

    this.setState({ isArchive: false });
    return archiveReqProject({
      data: { projectId: selected, approverId },
      update: { approverId, requestorId: this.props.user.userId }
    });
  };

  onCancelArchive = () => {
    const { cancelArchiveProject, selected } = this.props;
    this.setState({ isCancelArchive: false });
    return cancelArchiveProject({ projectId: selected });
  };

  onLegalHold = bool => {
    const { initiateLegalHold, selected } = this.props;
    this.setState({ isLegalHold: false, isRemoveHold: false });
    return initiateLegalHold({ projectId: selected, isLegalHold: bool });
  };

  onFlagNonClientService = () => {
    const { selected, flagNonClientService } = this.props;
    this.setState({ isSettingFlagNonClientService: true });
    return flagNonClientService({ projectId: selected, newValue: !this.props.projectDetails.isClientService }).then(a =>
      this.setState({ isSettingFlagNonClientService: false })
    );
  };

  render() {
    const { projectState, selected, projectDetails, user } = this.props;

    const projectRegion = {
      geoCode: projectDetails.geoCode,
      memberFirmCode: projectDetails.memberFirmCode,
      containerCode: projectDetails.containerCode
    };

    const menuOptions = {
      [MENU_OPTIONS.DELETE]: (
        <Button key="delete" className="menu__link" onClick={() => this.setState({ isDelete: true })}>
          <FormattedMessage id="menu.options.delete" />
        </Button>
      ),
      [MENU_OPTIONS.CARRY]: (
        <Button key="carry" className="menu__link" onClick={() => this.setState({ isCarryForward: true })}>
          <FormattedMessage id="menu.options.roll-forward" />
        </Button>
      ),
      [MENU_OPTIONS.COPY]: (
        <Button key="copy" className="menu__link" onClick={() => this.setState({ isCopy: true })}>
          <FormattedMessage id="menu.options.copy" />
        </Button>
      ),
      [MENU_OPTIONS.ENABLE_SUPPORT]: (
        <Button key="enable" className="menu__link" onClick={() => this.onEnableSupportAccess()}>
          {this.props.projectDetails.isAdminAccessEnabled ? (
            <FormattedMessage id="menu.options.disable-support" />
          ) : (
            <FormattedMessage id="menu.options.enable-support" />
          )}
        </Button>
      ),
      [MENU_OPTIONS.FLAG_NON_CLIENT]: (
        <Button
          key="flag-non-client"
          className={classNames('menu__link', { waiting: this.state.isSettingFlagNonClientService })}
          onClick={() => this.onFlagNonClientService()}
          disabled={this.state.isSettingFlagNonClientService}
        >
          {this.props.projectDetails.isClientService ? (
            <FormattedMessage id="menu.options.non-client-service" />
          ) : (
            <FormattedMessage id="menu.options.client-service" />
          )}
        </Button>
      ),
      [MENU_OPTIONS.REQ_ARCHIVE]: (
        <Button key="request" className="menu__link" onClick={() => this.setState({ isArchive: true })}>
          <FormattedMessage id="menu.options.archive-approve" />
        </Button>
      ),
      [MENU_OPTIONS.CANCEL_ARCHIVE]: (
        <Button key="cancel" className="menu__link" onClick={() => this.setState({ isCancelArchive: true })}>
          <FormattedMessage id="menu.options.archive-cancel" />
        </Button>
      ),
      [MENU_OPTIONS.RESTORE]: (
        <Button key="restore" className="menu__link" onClick={() => this.setState({ isRestore: true })}>
          <FormattedMessage id="menu.options.restore" />
        </Button>
      ),
      [MENU_OPTIONS.LEGAL_HOLD]: (
        <Button key="legal" className="menu__link" onClick={() => this.setState({ isLegalHold: true })}>
          <FormattedMessage id="menu.options.legal-hold" />
        </Button>
      ),
      [MENU_OPTIONS.REMOVE_HOLD]: (
        <Button key="legal" className="menu__link" onClick={() => this.setState({ isRemoveHold: true })}>
          <FormattedMessage id="menu.options.remove-legal-hold" />
        </Button>
      )
    };

    const menuModals = {
      [MENU_MODALS.REQ_ARCHIVE]: (
        <ArchiveModal
          onClose={() => this.setState({ isArchive: false })}
          onSubmit={id => this.onRequestArchive(id)}
          projectId={selected}
          projectDetails={projectDetails}
        />
      ),
      [MENU_MODALS.DELETE]: <DeleteModal onClose={() => this.setState({ isDelete: false })} onSubmit={this.onDelete} />,
      [MENU_MODALS.RESTORE]: (
        <RestoreModal onClose={() => this.setState({ isRestore: false })} onSubmit={this.onRestore} />
      ),
      [MENU_MODALS.CANCEL_ARCHIVE]: (
        <CancelArchiveModal onClose={() => this.setState({ isCancelArchive: false })} onSubmit={this.onCancelArchive} />
      ),
      [MENU_MODALS.COPY]: <CopyModal onClose={() => this.setState({ isCopy: false })} onSubmit={this.onCopy} />,
      [MENU_MODALS.CARRY]: (
        <CarryForwardModal onClose={() => this.setState({ isCarryForward: false })} onSubmit={this.onCarry} />
      ),
      [MENU_MODALS.LEGAL_HOLD]: (
        <LegalHoldModal onClose={() => this.setState({ isLegalHold: false })} onSubmit={() => this.onLegalHold(true)} />
      ),
      [MENU_MODALS.REMOVE_HOLD]: (
        <LiftLegalHoldModal
          onClose={() => this.setState({ isRemoveHold: false })}
          onSubmit={() => this.onLegalHold(false)}
        />
      )
    };

    const canDisableAccess = projectDetails.myRole
      ? projectDetails.myRole.isTemporaryUser && projectDetails.isAdminAccessEnabled
      : false;

    return (
      <div>
        <MenuOption
          visibleOptions={[
            Permissions.Project.canDelete(projectDetails, projectRegion) &&
              projectState !== PROJECT_STATUS.COPYING &&
              projectState !== PROJECT_STATUS.SOFT_DELETE &&
              !projectDetails.legalHold &&
              MENU_OPTIONS.DELETE,

            Permissions.Project.canRestore(projectDetails, projectRegion) &&
              projectState === PROJECT_STATUS.SOFT_DELETE &&
              MENU_OPTIONS.RESTORE,

            Permissions.Project.canCarryForward(projectDetails, projectRegion) &&
              projectState === PROJECT_STATUS.ARCHIVED && !user.isDisabled && user.isAccessActivated &&
              MENU_OPTIONS.CARRY,

            Permissions.Project.canCopy(projectDetails, projectRegion) &&
              projectState === PROJECT_STATUS.STARTED && !user.isDisabled && user.isAccessActivated &&
              MENU_OPTIONS.COPY,

            (Permissions.Project.canEnableSupportAccess(projectDetails) || canDisableAccess) &&
              (projectState === PROJECT_STATUS.STARTED || projectState === PROJECT_STATUS.IN_CREATION) &&
              MENU_OPTIONS.ENABLE_SUPPORT,

            Permissions.Project.canFlagNonClientService(projectRegion) && MENU_OPTIONS.FLAG_NON_CLIENT,

            Permissions.Project.canRequestArchive(projectDetails, projectRegion) &&
              projectState === PROJECT_STATUS.STARTED &&
              MENU_OPTIONS.REQ_ARCHIVE,

            Permissions.Project.canCancelArchiveRequest(projectDetails, user) &&
              projectState === PROJECT_STATUS.PENDING_ARCHIVE &&
              MENU_OPTIONS.CANCEL_ARCHIVE,

            Permissions.Project.canInitiateLegalHold(projectDetails, projectRegion) &&
              (projectState === PROJECT_STATUS.STARTED ||
                projectState === PROJECT_STATUS.PENDING_ARCHIVE ||
                projectState === PROJECT_STATUS.ARCHIVED) &&
              !projectDetails.legalHold &&
              MENU_OPTIONS.LEGAL_HOLD,

            Permissions.Project.canLiftLegalHold(projectDetails) && projectDetails.legalHold && MENU_OPTIONS.REMOVE_HOLD
          ]}
          menuOptions={menuOptions}
          disabled={
            projectState === PROJECT_STATUS.COPYING ||
            !Permissions.Project.canViewProjectDropdownMenu(
              {
                geoCode: projectDetails.geoCode,
                memberFirmCode: projectDetails.memberFirmCode,
                containerCode: projectDetails.containerCode
              },
              projectDetails
            )
          }
          {...this.props}
        />

        {this.state.isDelete ? menuModals[MENU_MODALS.DELETE] : null}
        {this.state.isArchive ? menuModals[MENU_MODALS.REQ_ARCHIVE] : null}
        {this.state.isCancelArchive ? menuModals[MENU_MODALS.CANCEL_ARCHIVE] : null}
        {this.state.isRestore ? menuModals[MENU_MODALS.RESTORE] : null}
        {this.state.isCopy ? menuModals[MENU_MODALS.COPY] : null}
        {this.state.isCarryForward ? menuModals[MENU_MODALS.CARRY] : null}
        {this.state.isLegalHold ? menuModals[MENU_MODALS.LEGAL_HOLD] : null}
        {this.state.isRemoveHold ? menuModals[MENU_MODALS.REMOVE_HOLD] : null}
      </div>
    );
  }
}

export default enhanceWithClickOutside(Menu);
