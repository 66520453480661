import { connect } from 'react-redux';

import {
  deleteProject,
  restoreDeletedProject,
  copySourceProject,
  carrySourceProject,
  archiveReqProject,
  cancelArchiveProject,
  initiateLegalHold,
  enableSupportAccess,
  flagNonClientService
} from 'store/project-list';
import Menu from 'components/project-list/list/menu/menu';

const mapStateToProps = ({ data: { projectList } }) => ({
  projectList
});

const mapDispatchToProps = {
  deleteProject,
  restoreDeletedProject,
  copySourceProject,
  carrySourceProject,
  archiveReqProject,
  cancelArchiveProject,
  initiateLegalHold,
  enableSupportAccess,
  flagNonClientService
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
