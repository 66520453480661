import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';
import classNames from 'classnames';
import download from 'downloadjs';
import moment from 'moment';

import ListOfReports from './list/list-of-reports';
import ScheduledReports from './list/scheduled-reports';
import ScheduleReportSidebar from './schedule-report-sidebar/schedule-report-sidebar';

const REPORTS_TABS = {
  LIST: 'LIST',
  SCHEDULED: 'SCHEDULED'
};

class Reports extends Component {
  static propTypes = {
    createReport: PropTypes.func,
    deleteReport: PropTypes.func,
    editReport: PropTypes.func,
    generateReport: PropTypes.func,
    loadReports: PropTypes.func,
    loadScheduledReports: PropTypes.func,
    reports: PropTypes.object,
    currentUser: PropTypes.object
  };

  state = {
    scheduleReportDetails: null,
    selectedReports: new Set(),
    currentTab: REPORTS_TABS.LIST
  };

  componentDidMount() {
    sessionStorage.setItem('active-reports-tab', 1);
  }

  // Checkbox all handlers
  onCheckAllChange = data => {
    const { selectedReports } = this.state;

    if (selectedReports.size === data.length) {
      this.setState({ selectedReports: new Set() });
    } else if (selectedReports.size < data.length && selectedReports.size > 0) {
      this.setState({ selectedReports: new Set() });
    } else {
      this.setState({ selectedReports: new Set(data.map(field => field)) });
    }
  };

  // Checkbox individual handlers
  onSelectChange = report => {
    const { selectedReports } = this.state;

    if (selectedReports.has(report)) {
      selectedReports.delete(report);
    } else {
      selectedReports.add(report);
    }

    this.setState({ selectedReports: selectedReports });
  };

  onGenerateReport = () => {
    const { selectedReports } = this.state;
    const { generateReport } = this.props;
    const selections = [...selectedReports];

    // Download all the user selected reports
    const promises = selections.map(value => {
      return generateReport(value.reportId).then(res => {
        download(res.data, `${value.reportName}_${moment().format()}.xlsx`);
      });
    });

    return Promise.all(promises).then(res => {
      this.setState({ selectedReports: new Set() });
    });
  };

  render() {
    const { reports, loadReports, loadScheduledReports, editReport, createReport, deleteReport } = this.props;
    const { currentTab, selectedReports, scheduleReportDetails, isSidebarOpen } = this.state;
    return (
      <div className="reports-page">
        <div className="reports-page__tabs">
          <h2 className="reports-page__title">
            <FormattedMessage id="reports-heading" />
          </h2>
          
          <div className="reports-page__buttons">
            <Button
              className={classNames(
                'reports-page__tab-button',
                currentTab === REPORTS_TABS.LIST && 'reports-page__tab-button--selected'
              )}
              onClick={() =>{ this.setState({ currentTab: REPORTS_TABS.LIST, selectedReports: new Set() })
                  sessionStorage.setItem('active-reports-tab', 1);
                }
              }
            >
              <FormattedMessage id="reports-tab.list" />
            </Button>
            <Button
              className={classNames(
                'reports-page__tab-button',
                currentTab === REPORTS_TABS.SCHEDULED && 'reports-page__tab-button--selected'
              )}
              onClick={() => { this.setState({ currentTab: REPORTS_TABS.SCHEDULED, selectedReports: new Set() })
                  sessionStorage.setItem('active-reports-tab', 2);
                }
              }
            >
              <FormattedMessage id="reports-tab.scheduled" />
            </Button>
            {selectedReports.size > 0 && currentTab === REPORTS_TABS.LIST ? (
              <Button
                className=" btn btn-primary reports-page__generate-button"
                size="large"
                onClick={this.onGenerateReport}
              >
                <FormattedMessage id="reports-tab.generate" />
              </Button>
            ) : null}
          </div>
        </div>
          
        {currentTab === REPORTS_TABS.LIST ? (
          <ListOfReports
            selectedReports={selectedReports}
            onCheckAllChange={this.onCheckAllChange}
            onSelectChange={this.onSelectChange}
            onSchedule={report => this.setState({ scheduleReportDetails: report, isSidebarOpen: true })}
            loadReports={loadReports}
            reports={reports}
          />
        ) : (
          <ScheduledReports
            selectedReports={selectedReports}
            onCheckAllChange={this.onCheckAllChange}
            onSelectChange={this.onSelectChange}
            onSchedule={report => this.setState({ scheduleReportDetails: report, isSidebarOpen: true })}
            loadScheduledReports={loadScheduledReports}
            reports={reports}
          />
        )}

        {isSidebarOpen ? (
          <ScheduleReportSidebar
            closeSidebar={() =>
              this.setState({ scheduleReportDetails: null, currentTab: REPORTS_TABS.SCHEDULED, isSidebarOpen: false })
            }
            editReport={editReport}
            createReport={createReport}
            deleteReport={deleteReport}
            data={scheduleReportDetails}
            onClose={() => this.setState({ scheduleReportDetails: null, isSidebarOpen: false })}
            tabViewer={currentTab}
          />
        ) : null}
      </div>
    );
  }
}

export default Reports;
