import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';

const LoadingOverlay = ({ id, text, diameter }) => (
  <div className="loading-overlay grey" id={id}>
    <Icon className="spinner" name="loader" width={diameter || 80} />
    {text === false ? null : <div className="loading-text">Loading...</div>}
  </div>
);

LoadingOverlay.propTypes = {
  diameter: PropTypes.number,
  id: PropTypes.string,
  text: PropTypes.bool
};

export default LoadingOverlay;
