import moment from 'moment';

class DateUtil {
  fromServerDate(dateStr) {
    return dateStr ? moment.utc(dateStr).local() : undefined;
  }

  formatTimestamp(momentDate) {
    if (!momentDate || !momentDate.format) return '';

    return momentDate.format('YYYY-MM-DD HH:mm:ss');
  }

  formatDateTime(momentDate) {
    if (!momentDate || !momentDate.format) return '';

    return momentDate.format('MMM D, YYYY h:mm A');
  }

  formatDate(momentDate) {
    if (!momentDate || !momentDate.format) return '';

    return momentDate.format('MMM D, YYYY');
  }

  formatDateShort(momentDate) {
    if (!momentDate || !momentDate.format) return '';

    return momentDate.format('YYYY-MM-DD');
  }

  formatTime(momentDate) {
    if (!momentDate || !momentDate.format) return '';

    return momentDate.format('HH:mm:ss');
  }

  toServerDate(momentDate) {
    if (!momentDate || !momentDate.format) return '';

    return momentDate.toISOString();
  }

  getElapsedTime(momentDate) {
    if (!momentDate || !momentDate.format) return '';

    if (!momentDate) {
      return null;
    }

    if (typeof momentDate === 'string') {
      return moment().diff(moment.utc(momentDate).local(), 'seconds');
    }

    return moment().diff(momentDate, 'seconds');
  }
}

export default new DateUtil();
