import { connect } from 'react-redux';

import ExtractionRecordTagsDropdown from 'components/extractions/extraction-record-tags/extraction-record-tags-dropdown';

import { addExtractionFieldRecordTag } from 'store/current-document';

const mapStateToProps = ({ data: { currentDocument } }) => ({ currentDocument });

const mapDispatchToProps = {
  addExtractionFieldRecordTag
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtractionRecordTagsDropdown);
