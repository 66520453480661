import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Header from 'containers/header/header';
import ExtractionOverview from 'containers/extraction-overview/extraction-overview-container';
import NotificationsContainer from 'containers/shared/notifications-container';

import Permissions from 'permissions/permissions';
import history from 'utils/history';

class ExtractionOverviewPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    if (!Permissions.Project.Analytics.canView()) {
      history.push('/');
    }
  }

  render() {
    const { projectId } = this.props.match.params;

    return (
      <div className="page">
        <Header {...this.props} currentPage="global.subheader.extraction" />
        <ExtractionOverview projectId={projectId} {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default ExtractionOverviewPage;
