import model from 'models/base-model';

export class UserProgress extends model({
  isLoading: false,
  isLoaded: false,
  error: false,
  users: []
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }

  /* ERROR */
  setError(error) {
    return this.merge({
      isLoading: false,
      error: error
    });
  }

  clear() {
    return new UserProgress();
  }

  /* LOADED */
  setLoaded(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      users: action.payload ?? []
    });
  }
}
