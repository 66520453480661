import model from 'models/base-model';
import { User } from 'models/user';
import dateUtil from 'utils/dateUtil';

export const ITEM_STATUSES = {
  //Item status/state 1 seems to be reserved for folders
  PREUPLOAD: 2,
  UPLOADED: 3,
  PROCESSED: 4,
  BADFILETYPE: 5,
  BLOBSTORAGEERROR: 6,
  PROCESSINGERROR: 7,
  SYSTEMERROR: 8,
  DELETED: 9,
  PROCESSING: 10,
  CONTENTPROCESSINGCOMPLETE: 11,
  PARTIALLYEXTRACTED: 12,
  ALLEXTRACTIONERROR: 13,
  PENDINGDELETION: 14
};

export const ITEM_TYPES = {
  FOLDER: 1,
  DOCUMENT: 2
};

export const TAG_TYPES = {
  SYSTEM_DUPLICATE: 1,
  USER: 2
};

export class ProjectItem extends model({
  children: null,
  error: null,
  extractionFieldInstanceDetails: null,
  isLoaded: false,
  isLoading: false,
  itemName: null,
  itemStateId: null,
  itemTypeId: null,
  lastUpdatedBy: null,
  lastUpdatedDate: null,
  lastProcessedDate: null,
  pagination: null,
  parentHierarchy: null,
  parentId: null,
  projectId: null,
  projectItemId: null,
  projectItemStatus: null,
  projectTemplate: null,
  projectTemplates: null,
  reviewers: null,
  tags: null,
  templateCategoryId:null,
  dateFormatPreference:null,
  autoDeleteDays: null
}) {
  setLoading() {
    return this.merge({
      error: null,
      isLoaded: false,
      isLoading: true
    });
  }

  setError(error) {
    return this.merge({
      error: error,
      isLoaded: true,
      isLoading: false
    });
  }

  invalidate() {
    return this.merge({
      error: null,
      isLoaded: false,
      isLoading: false
    });
  }

  setChildName({ projectItemId, itemName }) {
    const children = this.children.map(child => {
      if (child.projectItemId === projectItemId) {
        return child.merge({
          itemName
        });
      } else {
        return child;
      }
    });

    return this.merge({
      children: children
    });
  }

  deleteChildren(deletedChildren) {
    const children = this.children.filter(child => {
      return !deletedChildren.find(
        deletedChild =>
          parseInt(deletedChild.folderId, 10) === child.projectItemId ||
          parseInt(deletedChild.projectItemId, 10) === child.projectItemId
      );
    });

    return this.merge({
      children: children
    });
  }

  updateChildren(updatedChildren) {
    const children = this.children.map(child => {
      const updatedChild =
        updatedChildren.find(
          updatedChild =>
            updatedChild.projectItemId === child.projectItemId ||
            updatedChild.documentId === child.projectItemId ||
            updatedChild.folderId === child.projectItemId
        ) || {};
      return child.merge(updatedChild);
    });

    return this.merge({
      children: children
    });
  }

  addChildTag(projectItemId, tag) {
    var children = this.children;
    var child = children.find(currentChild => currentChild.projectItemId === projectItemId);
    if (child && child.tags.findIndex(currentTag => currentTag.tagId === tag.tagId) === -1) {
      child.tags.push(tag);
    }
    return this.merge({
      children: children
    });
  }

  removeChildTag(projectItemId, tagId) {
    var children = this.children;
    var child = children.find(currentChild => currentChild.projectItemId === projectItemId);
    if (child) {
      var tagIndex = child.tags.findIndex(currentTag => currentTag.tagId === tagId);
      if (tagIndex !== -1) {
        child.tags.splice(tagIndex, 1);
      }
    }
    return this.merge({
      children: children
    });
  }

  addChildReviewer(projectId, projectItemId, reviewer) {
    var children = this.children;
    var child = children.find(currentChild => currentChild.projectItemId === projectItemId);
    if (child && child.reviewers.findIndex(currentReviewer => currentReviewer.userId === reviewer.userId) === -1) {
      child.reviewers.push({
        projectId: projectId,
        projectItemId: projectItemId,
        userId: reviewer.userId,
        username: reviewer.displayName,
        user: reviewer
      });
    }
    return this.merge({
      children: children
    });
  }

  removeChildReviewer(projectItemId, userId) {
    var children = this.children;
    var child = children.find(currentChild => currentChild.projectItemId === projectItemId);
    if (child) {
      var reviewerIndex = child.reviewers.findIndex(currentReviewer => currentReviewer.userId === userId);
      if (reviewerIndex !== -1) {
        child.reviewers.splice(reviewerIndex, 1);
      }
    }
    return this.merge({
      children: children
    });
  }

  setLoaded(model) {
    return ProjectItem.fromData(model);
  }

  static fromData(model) {
    const data = model.data;
    const pagination = model.pageInfo;

    return new ProjectItem({
      ...data,
      error: null,
      isLoaded: true,
      isLoading: false,
      extractionFieldInstanceDetails: data.extractionFieldInstanceDetails
        ? data.extractionFieldInstanceDetails.map(model => ExtractionFieldInstanceDetails.fromData({ data: model }))
        : [],
      children: data.children ? data.children.map(model => ProjectItem.fromData({ data: model })) : [],
      lastUpdatedDate: dateUtil.fromServerDate(data.lastUpdatedDate),
      lastProcessedDate: dateUtil.fromServerDate(data.lastProcessedDate),
      pagination,
      parentHierarchy: data.parentHierarchy ? data.parentHierarchy.map(model => ParentItem.fromData(model)) : [],
      projectTemplates: data.projectTemplates ? data.projectTemplates.map(model => Template.fromData(model)) : [],
      reviewers: data.reviewers ? data.reviewers.map(model => Reviewer.fromData(model)) : [],
      tags: data.tags ? data.tags.map(model => Tag.fromData(model)) : [],
      templateCategoryId : data.templateCategoryID,
      dateformatpreference: data.dateFormatPreference,
      autoDeleteDays: data.autoDeleteDays
    });
  }
}

export class ParentItem extends model({
  itemTypeId: null,
  parentId: null,
  projectId: null,
  projectItemId: null
}) {
  static fromData(model) {
    return new ParentItem({
      ...model
    });
  }
}

export class Reviewer extends model({
  projectId: null,
  projectItemId: null,
  user: null,
  lastUpdatedDate: null,
  lastUpdatedBy: null
}) {
  static fromData(model) {
    return new Reviewer({
      ...model,
      user: User.fromData(model.user),
      lastUpdatedDate: dateUtil.fromServerDate(model.lastUpdatedDate)
    });
  }
}

export class Tag extends model({
  projectId: null,
  tagId: null,
  createdBy: null,
  tagName: null,
  lastUpdatedDate: null,
  lastUpdatedBy: null
}) {
  static fromData(model) {
    return new Tag({
      ...model,
      lastUpdatedDate: dateUtil.fromServerDate(model.lastUpdatedDate)
    });
  }
}

export class Template extends model({
  projectTemplateId: null,
  name: null,
  lastUpdatedDate: null,
  lastUpdatedBy: null
}) {
  static fromData(model) {
    return new Template({
      ...model,
      lastUpdatedDate: dateUtil.fromServerDate(model.lastUpdatedDate)
    });
  }
}

export class ExtractionFieldInstanceDetails extends model({
  extractionFieldId: null,
  extractionFieldName: null,
  accessType: null,
  isTrained: null,
  projectItemId: null,
  documentExtractionFieldInstanceId: null,
  extractFieldInstanceData: null,
  pageNumber: null,
  tagName: null
}) {
  static fromData(model) {
    return new ExtractionFieldInstanceDetails({
      ...model
    });
  }
}
