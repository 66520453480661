import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Checkbox from 'components/shared/form/checkbox';
import Modal from 'components/shared/modal';
import { Radio, RadioGroup } from 'components/shared/form/radio';

class ContainerSelectionModal extends Component {
  static propTypes = {
    cached: PropTypes.bool,
    containers: PropTypes.array,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    show: PropTypes.bool
  };

  static defaultProps = {
    cached: false
  };

  state = {
    selected: null,
    toBeCached: 'unchecked'
  };

  onSelect = selected => {
    this.setState({ selected });
  };

  onSubmit = () => {
    const { selected, toBeCached } = this.state;
    const { onSubmit } = this.props;

    onSubmit(selected.memberFirmCode, selected.containerCode, selected.countryCode, toBeCached === 'checked');
  };

  render() {
    const { selected, toBeCached } = this.state;
    const { show, onCancel, containers, cached } = this.props;
    const block = 'container-selection-modal';
    return (
      <div className={`${block}-wrapper`}>
        {show ? (
          <Modal id="containerSelectionModal">
            <div className="modal__content">
              <h3>
                <FormattedMessage id="container-selection-modal.title" />
              </h3>

              <p>
                <FormattedMessage id="container-selection-modal.body" />
              </p>

              <RadioGroup name="container-select" selectedValue={selected} onChange={this.onSelect}>
                {containers.map((container, index) => (
                  <div key={index} className={`form-field--inline-label ${block}__form-field`}>
                    <Radio
                      className={`${block}__radio`}
                      id={`${block}-option-${container.countryName}`}
                      value={container}
                    />
                    <label className={`${block}__radio-label`} htmlFor={`${block}-option-${container.countryName}`}>
                      {container.countryName}
                    </label>
                  </div>
                ))}
              </RadioGroup>

              {cached ? (
                <div className={`${block}__checkbox-wrapper`}>
                  <Checkbox
                    checked={toBeCached}
                    className={`${block}__checkbox`}
                    id="cache-selection-checkbox"
                    onChange={() => this.setState({ toBeCached: toBeCached === 'unchecked' ? 'checked' : 'unchecked' })}
                  />
                  <label htmlFor="cache-selection-checkbox" className={`${block}-label`}>
                    <FormattedMessage id="container-selection-modal.toBeCached" />
                  </label>
                </div>
              ) : null}

              <div className="modal__footer">
                <button className="btn btn-medium btn-primary" onClick={onCancel}>
                  <FormattedMessage id="common.cancel" />
                </button>
                <button className="btn btn-medium btn-secondary" disabled={selected === null} onClick={this.onSubmit}>
                  <FormattedMessage id="common.confirm" />
                </button>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default ContainerSelectionModal;
