import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/shared/icon';

/*
 * Checkbox
 * This is a three state checkbox. The checkbox state is controlled by the 'checked' prop
 * The state can be 'checked', 'unchecked' or 'indeterminate'
 * When the checkbox state changes, the onChange callback prop is called
 * onChange is called with two arguments, the id of the checkbox and the new state
 * The id prop should be unique across checkboxes on the page
 */
class Checkbox extends React.Component {
  static propTypes = {
    checked: PropTypes.oneOf(['checked', 'unchecked', 'indeterminate']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    width: PropTypes.number
  };

  static defaultProps = {
    width: 18
  };

  onClick = event => {
    const { disabled, id, checked, onChange } = this.props;

    if (!disabled) {
      onChange(id, checked === 'checked' || checked === 'indeterminate' ? 'unchecked' : 'checked');
    }
  };

  render() {
    const { id, checked, className, disabled, width, ...props } = this.props;
    let icon;

    if (checked === 'checked') {
      icon = disabled ? 'special-checkbox-selected-grey' : 'special-checkbox-selected';
    } else if (checked === 'indeterminate') {
      icon = disabled ? 'special-checkbox-indeterminated-grey' : 'special-checkbox-indeterminated';
    } else {
      icon = disabled ? 'special-checkbox-unselected-grey' : 'special-checkbox-unselected';
    }

    return (
      <button
        {...props}
        id={id}
        disabled={disabled}
        className={classNames('checkbox', className)}
        onClick={this.onClick}
      >
        <Icon name={icon} width={width} />
      </button>
    );
  }
}

export default Checkbox;
