import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';

class NotificationItem extends Component {
  static propTypes = {
    deleteNotification: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired
  };

  deleteSelf = () => {
    const { deleteNotification, notification } = this.props;
    deleteNotification(notification);
  };

  render() {
    const { notification } = this.props;

    return (
      <div className="notification-item">
        <div className="notification-item__message">
          {notification.intlKey ? (
            <FormattedMessage id={notification.intlKey} values={notification.intlProps} />
          ) : (
            notification.message
          )}
        </div>
        <Button size="icon" className="notification-item__delete" onClick={this.deleteSelf}>
          <Icon name="special-cross-white" width={14} height={14} />
        </Button>
      </div>
    );
  }
}

class Notifications extends Component {
  static propTypes = {
    deleteNotification: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    subscribeToGlobalErrors: PropTypes.func.isRequired,
    unSubscribeToGlobalErrors: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { subscribeToGlobalErrors } = this.props;
    subscribeToGlobalErrors();
  }

  componentWillUnmount() {
    const { unSubscribeToGlobalErrors } = this.props;
    unSubscribeToGlobalErrors();
  }

  render() {
    const { deleteNotification, notifications } = this.props;

    return (
      <div className="notifications">
        {notifications
          .reverse()
          .map((notification, i) => (
            <NotificationItem key={i} notification={notification} deleteNotification={deleteNotification} />
          ))}
      </div>
    );
  }
}

export default Notifications;
