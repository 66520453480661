import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Header from 'containers/header/header';
import CreateTemplateContainer from 'containers/create-template/create-template';
import NotificationsContainer from 'containers/shared/notifications-container';

import Permissions from 'permissions/permissions';
import history from 'utils/history';

class CreateTemplatePage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    if (!Permissions.Project.ExtractionField.canViewTab() || !(Permissions.Project.Template.canCreate() && !Permissions.Project._States.isReadOnly())) {
      history.push('/');
    }
  }

  render() {
    return (
      <div className="page">
        <Header {...this.props } currentPage="global.subheader.extraction" />
        <CreateTemplateContainer {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default CreateTemplatePage;
