import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';

class Modal extends Component {
  static propTypes = {
    children: PropTypes.node,
    id: PropTypes.any
  };

  state = {
    isSettingMenuOpen: false 
  }

    handleClickOutside = () => {
        this.setState({ isSettingMenuOpen: true });  
  };

  render() {
    return ReactDOM.createPortal(
      <div className="modal" id={this.props.id}>
        {this.props.children}
      </div>,
      document.getElementById('modal-root')
    );
  }
}

export default enhanceWithClickOutside(Modal);
