import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { getGridViewDropdownData } from 'store/api';
import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';

class GridDropdown extends Component {
  static propTypes = {
    onTemplateClick: PropTypes.func,
    readOnly: PropTypes.bool,
    selectedId: PropTypes.number,
    selectedName: PropTypes.string,
    gridViewList: PropTypes.array,
    errClassName: PropTypes.string,
    customGrid: PropTypes.bool,
    deselectGrid: PropTypes.func,
    defaultSelection: PropTypes.func,
    selectedTemplateId: PropTypes.number,
    newGridData: PropTypes.object,
    loadColumnOptionsFromGrid: PropTypes.func,
    updateGridName:PropTypes.func,
    updateGridID:PropTypes.func
  };

  state = {
    isMenuOpen: false,
    selectedGridId: this.props.selectedId,
    selectedGridName: this.props.selectedName,
    isCustomGrid: this.props.customGrid,
    gridData: [{ gridId: 0, gridName: 'Default' }]
  };

  onGridSelected = template => {

    this.setState({ selectedGridId: template.gridId });
    this.setState({ selectedGridName: template.gridName });
    this.props.updateGridName(template.gridName)
    this.props.updateGridID(template.gridId)
    this.props.deselectGrid();
    if (template.gridId === 0) {
      this.props.defaultSelection();
    } else {
      this.props.loadColumnOptionsFromGrid(template.gridId);
    }
  };
  componentWillReceiveProps() {
    let { selectedId, selectedName } = this.props;
    let { gridData,selectedGridName } = this.state;

    let selectedGridview = gridData.filter(a => a.gridId === selectedId && a.gridName === selectedName);

    if (selectedId !== 0 && selectedName !== 'Default' && selectedGridview.length === 0) {
      gridData.push({ gridId: selectedId, gridName: selectedName });
    }
    this.setState({ gridData: gridData });

  }


  componentDidMount() {
    const { projectId, selectedTemplateId } = this.props;
    const { gridData } = this.state;
    if(selectedTemplateId){
      getGridViewDropdownData(projectId, selectedTemplateId).then(response => {
        for (let r = 0; r < response.length; r++) {
          gridData.push({ gridId: response[r].id, gridName: response[r].name });
        }
      });
    }    
  }

  onMenuToggle = isMenuOpen => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  //get

  render() {
    const { gridViewList, selectedName, selectedId, errClassName, customGrid } = this.props;
    let { selectedGridName, selectedGridId, isMenuOpen, isCustomGrid, gridData } = this.state;
    const updateNameErrClass = errClassName ? errClassName : '';
    return (
      <MenuWrapper
        className={`template-dropdown__container`}
        onMenuToggle={this.onMenuToggle}
        onSelection={this.onGridSelected}
      >
        <MenuButton className="form-dropdown btn form-input-dropdown__button" tag="button" type="button">
          <span className="template-dropdown__text">
            {customGrid ? <FormattedMessage id="extraction-overview.grid-view-custom" /> : selectedName}
          </span>
          <Icon
            name="special-arrow-down-blue"
            width={14}
            height={8}
            rotate={isMenuOpen ? 180 : 0}
            className="btn form-input-dropdown__caret"
          />
        </MenuButton>
        <Menu className="template-dropdown__body-container">
          <div className="template-dropdown__scroll-area">
            {gridData &&
              gridData.map((g, index) => (
                <MenuItem
                  key={index}
                  value={g}
                  className={classNames(
                    'form-input-dropdown__item',
                    selectedId === g.gridId && 'form-input-dropdown__item--selected'
                  )}
                >
                  <span className="template-dropdown__textdropdown" title={g.gridName}>
                    {g.gridName}
                  </span>
                  <span className="template-dropdown__icon">
                    {customGrid === false && selectedId === g.gridId && (
                      <Icon name="special-checked-black" width={14} height={8} />
                    )}
                  </span>
                </MenuItem>
              ))}
          </div>
        </Menu>
      </MenuWrapper>
    );
  }
}

export default GridDropdown;
