import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Templates from 'containers/create-template/templates-container';

class CreateTemplate extends Component {
  static propTypes = {
    currentProject: PropTypes.object
  };

  render() {
    const { currentProject } = this.props;
    const BLOCK = 'template-creation-view';

    return (
      <div className={`${BLOCK}`}>
        <div className="main-section">
          <Templates project={currentProject} onChangeStep={this.onChangeStep} step={2} steps={[0, 1, 2]} />
        </div>
      </div>
    );
  }
}

export default CreateTemplate;
