import { createAction, createReducer } from 'utils/redux-utils';

//import * as api from 'store/api';

export const ACTIONS = {
  DOCUMENT_UNDO_PUSH: 'argus/ui/DOCUMENT_UNDO_PUSH',
  DOCUMENT_UNDO_POP: 'argus/ui/DOCUMENT_UNDO_POP',
  DOCUMENT_UNDO_CLEAR: 'argus/ui/DOCUMENT_UNDO_CLEAR'
};

export const docUndoPush = createAction(ACTIONS.DOCUMENT_UNDO_PUSH);
export const docUndoPop = createAction(ACTIONS.DOCUMENT_UNDO_POP);
export const docUndoClear = createAction(ACTIONS.DOCUMENT_UNDO_CLEAR);

export const pushUndoStack = data => dispatch => {
  return dispatch(docUndoPush(data));
};

export const popUndoStack = () => dispatch => {
  return dispatch(docUndoPop());
};

export const clearUndoStack = () => dispatch => {
  return dispatch(docUndoClear());
};

/*
 * Reducer
 */
export const INITIAL_STATE = {
  stack: []
};

export default createReducer(INITIAL_STATE, {
  [ACTIONS.DOCUMENT_UNDO_PUSH]: (state, action) => {
    const { stack } = state;

    return {
      stack: stack.concat(action.payload).slice(-3)
    };
  },

  [ACTIONS.DOCUMENT_UNDO_POP]: (state, action) => {
    const { stack } = state;

    const newStack = stack.slice(0, stack.length - 1);

    return {
      stack: newStack
    };
  },

  [ACTIONS.DOCUMENT_UNDO_CLEAR]: (state, action) => {
    return INITIAL_STATE;
  }
});
