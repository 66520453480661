import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { MAT_CATEGORIES } from 'components/project-creation/constants';

/*
* MAT Engagement Details
*/
const EngagementDetails = ({ data }) => {
  return (
    <div className="engagement-details">
      <div className="engagement-details__heading">
        <span className="engagement-details__heading-title">
          <FormattedMessage id="engagement-details.heading.title" />
        </span>
      </div>
      <div className="engagement-details__body">
        <div className="engagement-details__sub-body-left">
          {MAT_CATEGORIES.map(category => (
            category.value === 'engagementName' || category.value === 'externalEngagementId' || category.value === 'clientId' ?
              (<div key={category.value} className="engagement-details__fields">
                <span className="engagement-details__sub-heading">
                  <FormattedMessage id={`engagement-details.${category.value}`} />
                </span>
                <div className="engagement-details__value">{category.render(data)}</div>
              </div>) : ""
          ))}
        </div>
        <div className="engagement-details__sub-body-right">
          {MAT_CATEGORIES.map(category => (
            category.value === 'clientName' || category.value === 'fiscalEnd' || category.value === 'fiscalYearEnd' ?
              (<div key={category.value} className="engagement-details__fields">
                <span className="engagement-details__sub-heading">
                  <FormattedMessage id={`engagement-details.${category.value}`} />
                </span>
                <div className="engagement-details__value">{category.render(data)}</div>
              </div>) : ""
          ))}
        </div>
      </div>
    </div>
  );
};

EngagementDetails.propTypes = {
  data: PropTypes.object
};

export default EngagementDetails;
