import model from 'models/base-model';
import moment from 'moment';

var storedLanguageData = localStorage.getItem('languageData');

export class LanguageData extends model({
  isLoading: false,
  isLoaded: false,
  error: null,
  languageData: storedLanguageData ? JSON.parse(storedLanguageData) : {}
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }

  /* ERROR */
  setError(error) {
    return this.merge({
      isLoading: false,
      isLoaded: false,
      error: error
    });
  }

  /* LOADED */
  setLoaded(state, action) {
    var _languageData = Object.assign({}, this.languageData);
    const messages = processLanguage(action.payload.values);

    _languageData[action.payload.localeCode] = messages;

    localStorage.setItem('languageData', JSON.stringify(_languageData));

    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      languageData: _languageData
    });
  }
}

function processLanguage(values) {
  var _values = Object.assign({}, values);

  for (var key in _values) {
    _values[key] = _values[key].replace(/\$\{/g, '{');
  }

  _values.timestamp = moment().toISOString();

  return _values;
}
