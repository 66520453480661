import model from 'models/base-model';

import { getTransformedValues } from 'utils/data-utils';
import { flattenAddExtractionData } from 'components/extractions/helpers/add-extraction';
import { processTemplateExtractionFields } from 'components/extractions/helpers/extraction-picker';
import { Templates } from 'models/edit-extractions';

export class ProjectTemplates extends model({
  isGroupsLoading: false,
  isGroupExtLoading: false,
  isAllExtLoading: false,
  isTemplateExtLoading: false,
  isTemplatesLoading: false,
  failedExtraction: false,
  error: null,
  projectId: null,
  templateCategoryId: null,
  templateId: null,
  templateName: '', // Template name is not the same as selected name
  templates: null,
  groups: [],
  selectedGroup: null,
  allExtractions: [],
  templateExtractions: [],
  untitled: 'Enter the name of the template', // Fallback for when there is no untitled intl prop
  templateSuffix: 'Template' // Fallback for when there is no untitled intl prop
}) {
  /* LOADERS */
  setTemplatesLoading() {
    return this.merge({
      isTemplatesLoading: true,
      error: null
    });
  }

  setGroupsLoading() {
    return this.merge({
      isGroupsLoading: true,
      error: null
    });
  }

  setGroupsExtLoading() {
    return this.merge({
      isGroupExtLoading: true,
      error: null
    });
  }

  setAllExtLoading() {
    return this.merge({
      isAllExtLoading: true,
      error: null
    });
  }

  setTemplateExtLoading() {
    return this.merge({
      isTemplateExtLoading: true,
      error: null
    });
  }

  /* ERROR */
  setError(error) {
    return this.merge({
      error: error
    });
  }

  /* EXTRACTIONS LOADED */
  setGroupsLoaded(state, action) {
    return this.merge({
      ...state,
      isGroupsLoading: false,
      groups: action.payload ?? []
    });
  }

  setAllExtLoaded(state, action) {
    return this.merge({
      ...state,
      isAllExtLoading: false,
      allExtractions: flattenAddExtractionData(
        action.payload,
        state.templateExtractions?.length > 0 ? state.templateExtractions.map(v => v.isSelected && v.id) : []
      ),
      originalExtractionData: action.payload
    });
  }

  enableAllExtractons(state) {
    return this.merge({
      allExtractions: state.allExtractions.map(e => ({ ...e, disabled: false }))
    });
  }

  // When template specific extractions have loaded
  setTemplateExtLoaded(state, action) {
    let templateExtractions = processTemplateExtractionFields(action.payload.templates[0], action.payload.groups);
    templateExtractions.forEach(extractionField => (extractionField.isSelected = true));
    return this.merge({
      ...state,
      isTemplateExtLoading: false,
      templateExtractions: templateExtractions,
      allExtractions: state.originalExtractionData
        ? flattenAddExtractionData(
            state.originalExtractionData,
            templateExtractions.length > 0 ? templateExtractions.map(v => v.id) : []
          )
        : state.allExtractions
    });
  }

  setGroupsExtLoaded(state, action) {
    const templateExtractions =
      state.selectedGroup.Id !== 'Other' ? action.payload.map(value => this.cleanExtraction(value)) : [];

    return this.merge({
      ...state,
      isGroupExtLoading: false,
      error: null,
      templateExtractions: templateExtractions
    });
  }

  // When a user moves the position of extractions
  setMoved(state, action) {
    return this.merge({
      ...state,
      isTemplateExtLoading: false,
      error: null,
      templateExtractions: action.payload
    });
  }

  // If user has deleted the extractions
  setDeleted(state, action) {
    if (state.allExtractions.length > 0) {
      const deletedId = state.allExtractions
        .map((e, i) => (e.id === action.payload.extractionFieldId ? i : ''))
        .filter(String);
      // We have to update the group view so it now becomes enabled
      if (deletedId.length > 0) {
        deletedId.forEach(index => (state.allExtractions[index].disabled = false));
      }
    }

    return this.merge({
      ...state,
      isTemplateExtLoading: false,
      error: null,
      templateExtractions: state.templateExtractions.filter(v => v.id !== action.payload.extractionFieldId),
      allExtractions: state.allExtractions
    });
  }

  // If user select/deselect an extraction
  toggleSelected(state, action) {
    return this.merge({
      ...state,
      templateExtractions: state.templateExtractions.map(ext => {
        if (ext.id === action.payload) {
          ext.isSelected = !ext.isSelected;
        }
        return ext;
      }),
      allExtractions: state.allExtractions.map(ext => {
        if (ext.id === action.payload) {
          ext.disabled = !ext.disabled;
        }
        return ext;
      })
    });
  }

  cleanExtraction(data) {
    // If it's already clean, no need to re-clean
    if (data.trainingTarget) {
      return data;
    }
    return {
      id: data.extractionFieldId,
      failed: data.failedExtraction,
      group:
        data.groups.length > 0
          ? (data.extractionFieldGroupId
              ? data.groups.find(gr => gr.extractionFieldGroupId === data.extractionFieldGroupId)
              : data.groups[0]
            ).extractionFieldGroupName
          : null,
      groupMapping: data.extractionFieldDisplayOrderMappings,
      groupId:
        data.groups.length > 0
          ? (data.extractionFieldGroupId
              ? data.groups.find(gr => gr.extractionFieldGroupId === data.extractionFieldGroupId)
              : data.groups[0]
            ).extractionFieldGroupId
          : 0,
      name: data.extractionFieldName,
      description: data.extractionFieldDescription,
      color: data.colorIndex,
      trainingCount: data.reviewDocumentCount,
      trainingTarget: data.minDocumentsToBeConsideredTrained,
      isTrained: data.isTrained,
      AccessTypeId: data.accessTypeId,
      isAutomaticExtraction: data.isAutomaticExtraction,
      isSelected: data.isSelected || false
    };
  }

  // When a user adds an extraction => this is needed to improve performance when user is adding
  // A lot of extractions at once
  setAdded(state, action) {
    const data = getTransformedValues([...state.allExtractions], action.payload.data, 'id', this.cleanExtraction);
    const tempExt = data.addedElement.reduce(
      (acc, cur) => {
        let index = acc.findIndex(ext => ext.id === cur.id);
        if (index >= 0) {
          acc[index].isSelected = true;
        } else {
          cur.isSelected = true;
          acc.push(cur);
        }
        return acc;
      },
      [...state.templateExtractions]
    );
    return this.merge({
      ...state,
      templateExtractions: tempExt,
      allExtractions: data.original
    });
  }

  /* PROJECT TEMPLATES */
  setCreated(state, action) {
    return this.merge({
      ...state,
      error: null,
      selectedGroup: null,
      templateName: '',
      templateId: null,
      templateCategoryId: null
    });
  }

  setUpdated(state, action) {
    return this.merge({
      ...state,
      error: null,
      selectedGroup: null,
      templateName: '',
      templateId: null,
      templateCategoryId: null
    });
  }

  // When a user has selected a certain template
  setTempSelected(state, action) {
    return this.merge({
      ...state,
      isTemplatesLoading: false,
      error: null,
      selectedGroup: null,
      templateName: action.payload.name,
      templateId: action.payload.projectTemplateId,
      templateCategoryId: action.payload.templateCategoryId
    });
  }

  // When a user selects on a group based document type
  setSelectedGroup(state, action) {
    return this.merge({
      ...state,
      templateName:
        action.payload.Id === 'Other'
          ? action.payload.untitled
          : `${action.payload.name} ${action.payload.templateSuffix}`,
      templateCategoryId: action.payload.templateCategoryId,
      templateTypeID: action.payload.templateTypeID,
      selectedGroup: {
        name: action.payload.name,
        Id: action.payload.Id
      },
      untitled: action.payload.untitled,
      templateSuffix: action.payload.templateSuffix
    });
  }

  // When a user changes a template name
  setChangedName(state, action) {
    return this.merge({
      ...state,
      templateName: action.payload
    });
  }

  // Get data returned from the API
  setTemplatesLoaded(state, action) {
    return this.merge({
      ...state,
      isTemplatesLoading: false,
      selectedGroup: null,
      templateName: null,
      templateCategoryId: null,
      templateId: null,
      templates:
        action.payload && action.payload.length > 0
          ? action.payload.map(templates => Templates.fromData(templates))
          : []
    });
  }
}
