import { createAction, createReducer } from 'utils/redux-utils';
import { getAboutInfo } from 'store/api';

// About argus links, ie privacy, terms of use, etc...
export const ACTIONS = {
  ABOUT_ARGUS_LOADING: 'argus/ui/ABOUT_ARGUS_LOADING',
  ABOUT_ARGUS_LOADED: 'argus/ui/ABOUT_ARGUS_LOADED',
  ABOUT_ARGUS_ERROR: 'argus/ui/ABOUT_ARGUS_ERROR'
};

export const aboutArgusLoading = createAction(ACTIONS.ABOUT_ARGUS_LOADING);
export const aboutArgusLoaded = createAction(ACTIONS.ABOUT_ARGUS_LOADED);
export const aboutArgusError = createAction(ACTIONS.ABOUT_ARGUS_ERROR);

export const loadAboutInfo = () => dispatch => {
  dispatch(aboutArgusLoading());
  return getAboutInfo()
    .then(res => {
      dispatch(aboutArgusLoaded(res));
    })
    .catch(err => {
      dispatch(aboutArgusError(err));
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false
};

export default createReducer(INITIAL_STATE, {
  [ACTIONS.ABOUT_ARGUS_LOADING]: (state, action) => {
    return {
      isLoading: true,
      isLoaded: false
    };
  },

  [ACTIONS.ABOUT_ARGUS_LOADED]: (state, action) => {
    return {
      isLoading: false,
      isLoaded: true,
      data: action.payload.data
    };
  },

  [ACTIONS.ABOUT_ARGUS_ERROR]: (state, action) => {
    return {
      isLoading: false,
      isLoaded: false,
      error: action.payload
    };
  }
});
