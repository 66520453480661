import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/shared/single-click-button';
import Checkbox from 'components/shared/form/checkbox';
import Icon from 'components/shared/icon';

class TagsList extends Component {
  static propTypes = {
    onBackClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedTags: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired
  };

  state = {
    selectedTags: new Set(this.props.selectedTags)
  };

  toggleTagSelection = tag => {
    const id = tag.id;

    if (this.state.selectedTags.has(id)) {
      this.state.selectedTags.delete(id);
    } else {
      this.state.selectedTags.add(id);
    }

    this.setState({ selectedTags: this.state.selectedTags });
  };

  toggleAllTagsSelection = () => {
    const { tags } = this.props;

    if (this.state.selectedTags.size === tags.length) {
      this.setState({ selectedTags: new Set() });
    } else {
      const allIds = tags.map(tag => tag.id);
      this.setState({ selectedTags: new Set(allIds) });
    }
  };

  onSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit(Array.from(this.state.selectedTags.values()));
  };

  render() {
    const { onBackClick, onCloseClick, tags } = this.props;
    const { selectedTags } = this.state;

    let allSelectedStatus;
    if (tags.length === selectedTags.size) {
      allSelectedStatus = 'checked';
    } else if (selectedTags.size === 0) {
      allSelectedStatus = 'unchecked';
    } else {
      allSelectedStatus = 'indeterminate';
    }

    return (
      <div className="export-sidebar-tags-list">
        <div className="export-sidebar-tags-list__header">
          <Button size="icon" onClick={onBackClick} className="export-sidebar-tags-list__back-button icon-button">
            <Icon name="special-back" width={14} />
          </Button>
          <FormattedMessage id="export-sidebar.create.tags.title" />
          <Button size="icon" onClick={onCloseClick} className="export-sidebar-tags-list__close-button icon-button">
            <Icon name="special-cross" width={14} />
          </Button>
        </div>
        <div className="export-sidebar-tags-list__list">
          <div className="export-sidebar-tags-list__list-item" key={'all'}>
            <Checkbox
              checked={allSelectedStatus}
              className="export-sidebar-tags-list__checkbox"
              id="check-all"
              onChange={this.toggleAllTagsSelection}
            />
            <FormattedMessage id="export-sidebar.create.tags.all-tags" />
          </div>
          {tags.map(tag => (
            <div className="export-sidebar-tags-list__list-item" key={tag.id}>
              <Checkbox
                checked={selectedTags.has(tag.id) ? 'checked' : 'unchecked'}
                id={`export-sidebar-tags-list__checkbox-${tag.id}`}
                className="export-sidebar-tags-list__checkbox"
                onChange={this.toggleTagSelection.bind(this, tag)}
              />
              {tag.name}
            </div>
          ))}
        </div>
        <div className="export-sidebar-tags-list__footer">
          <Button type="button" className="btn btn-primary btn-no-margin" onClick={this.onSubmit}>
            <FormattedMessage id="common.submit" />
          </Button>
        </div>
      </div>
    );
  }
}

export default TagsList;
