import { connect } from 'react-redux';
import { getData } from 'store/tags/document-tags';
import Tile from 'components/tags/tile';

const mapStateToProps = ({ data: { documentTags } }) => ({ data: documentTags });
const mapDispatchToProps = { getData };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tile);
