import { persistSelectedCompareType } from 'store/compare-type';
import { AnalyticsMiddleware } from 'adobe-analytics/middleware';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import _ from 'lodash';
import thunk from 'redux-thunk';

// UI reducers
import compareType from 'store/compare-type';
import trainingRequest from 'store/training-request-type';
import fileBrowserColumnOptions from 'store/file-browser-column-options';
import fileBrowserSelectedItems from 'store/file-browser-selected-items';
import createToolbarOptionsReducer, { persistFileBrowserToolbarOptions } from 'store/file-browser-toolbar-options';
import locale from 'store/locale';
import notifications from 'store/notifications';
// Data reducers
import aboutArgus from 'store/about-argus';
import maintenanaceBannerMessage from 'store/maintenance-message-banner'
import activeComparisonSet from 'store/active-comparison-set';
import comparisonSets from 'store/comparison-sets';
import currentDocument from 'store/current-document';
import currentDocumentLayout from 'store/current-document-layout';
import currentProject from 'store/current-project';
import currentLanguage from 'store/intl/currentLanguage';
import currentUser from 'store/current-user';
import currentFolder from 'store/current-folder';
import currentSearchFolder from 'store/search/search-results';
import documentProcessing from 'store/review-status/document-processing';
import documentTags from 'store/tags/document-tags';
import documentTypeTags from 'store/tags/document-type-tags';
import documentViewerUndo from 'store/document-viewer-undo';
import editExtractions from 'store/edit-extractions';
import extractionFieldRecordTags from 'store/tags/extraction-field-record-tags';
import extractionOverview from 'store/extraction-overview';
import languages from 'store/intl/languages';
import languageData from 'store/intl/languageData';
import languageTags from 'store/tags/language-tags';
import ocrQualityTags from 'store/tags/ocr-quality-tags';
import projectList from 'store/project-list';
import translations from 'store/translations';
import projectMembers from 'store/project-members';
import projectStatus from 'store/review-status/project-status';
import projectTemplates from 'store/project-templates';
import templateTypes from 'store/project-creation/template-types';
import userProgress from 'store/review-status/user-progress';
import reports from 'store/reports';
import search from 'store/search/search-category';
import uploads from 'store/uploads';
import history from 'utils/history';
import loadFormat from 'store/intl/loadFormat';
import currentFormat from 'store/intl/currentFormat';
import loadDateFormat  from 'store/intl/loadDateFormat';
import currentDateFormat  from 'store/intl/currentDateFormat';

// Needed for redux dev tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    data: combineReducers({
      aboutArgus,
      maintenanaceBannerMessage,
      activeComparisonSet,
      comparisonSets,
      currentDocument,
      currentDocumentLayout,
      currentUser,
      currentLanguage,
      currentProject,
      currentSearchFolder,
      currentFolder,
      documentProcessing,
      documentTags,
      documentTypeTags,
      editExtractions,
      extractionFieldRecordTags,
      extractionOverview,
      languages,
      languageData,
      languageTags,
      ocrQualityTags,
      projectList,
      projectMembers,
      projectStatus,
      projectTemplates,
      templateTypes,
      translations,
      userProgress,
      reports,
      search,
      uploads,
      loadFormat,
      currentFormat,
      loadDateFormat,
      currentDateFormat
    }),
    ui: combineReducers({
      compareType,
      trainingRequest,
      documentViewerUndo,
      fileBrowserColumnOptions,
      fileBrowserSelectedItems,
      fileBrowserToolbarOptions: createToolbarOptionsReducer(),
      locale,
      notifications
    }),
    router: connectRouter(history)
  }),
  composeEnhancers(applyMiddleware(AnalyticsMiddleware, routerMiddleware(history), thunk))
);

store.subscribe(
  _.throttle(() => {
    persistFileBrowserToolbarOptions(store.getState());
    persistSelectedCompareType(store.getState());
  }, 1000)
);

export default store;
