import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// Tiles
import DocumentProcessingContainer from 'containers/review-status/tiles/document-processing-container.js';
import ProjectStatusContainer from 'containers/review-status/tiles/project-status-container.js';
import UserProgressContainer from 'containers/review-status/tiles/user-progress-container.js';

class ReviewStatus extends Component {
  static propTypes = {
    currentProject: PropTypes.object,
    projectId: PropTypes.any
  };

  render = () => {
    var { currentProject, projectId } = this.props;

    if (!projectId) return null;
    return (
      <div className="review-status-container">
        <div className="review-status-container__header-title">
          <h2>
            <FormattedMessage id="review-status.title" />
          </h2>
          <div className="review-status-container__header-subtitle">{currentProject.projectName}</div>
        </div>

        <div className="review-status-tiles">
          <ProjectStatusContainer projectId={projectId} />

          <div className="tile-row">
            <UserProgressContainer projectId={projectId} />
            <DocumentProcessingContainer projectId={projectId} />
          </div>
        </div>
      </div>
    );
  };
}

export default ReviewStatus;
