import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'components/shared/single-click-button';
import { getExtractionFieldsByCategoryID } from 'store/api';
import Icon from 'components/shared/icon';
import AddExtraction from '../add-extraction/add-extraction';
import ExtractionList from '../extraction-list/extraction-list';
import LoadingOverlay from 'components/shared/loading/light';
import { FormattedMessage } from 'react-intl';
import { flattenAddExtractionData } from 'components/extractions/helpers/add-extraction';
import Permissions from 'permissions/permissions';
import CreateExtraction from '../create-extraction/create-extraction';

class ExtractionPicker extends Component {
  static propTypes = {
    addExtractionField: PropTypes.func,
    clearUndoStack: PropTypes.func,
    closeFunction: PropTypes.func,
    createExtractionField: PropTypes.func,
    createExtractionFieldRecord: PropTypes.func,
    currentTemplate: PropTypes.any,
    data: PropTypes.any,
    documentId: PropTypes.any,
    documentTemplateCategoryId: PropTypes.number,
    extractionFieldGroups: PropTypes.array.isRequired,
    isShowingAddExtraction: PropTypes.bool,
    projectId: PropTypes.any,
    readOnly: PropTypes.bool,
    refreshFunction: PropTypes.func,
    selectedExtractionFieldIds: PropTypes.array.isRequired,
    showAddExtractionButton: PropTypes.bool,
    showHideAddExtraction: PropTypes.func.isRequired,
    onCreateExtractionSuccess: PropTypes.func,
    isShowingCreateExtraction: PropTypes.bool,
    showHideCreateExtraction:PropTypes.func.isRequired,
  };

  state = {
    addExtractionData: [],
    extractionFields: [], //Used to store API response of extraction fields
    loading: false,
  };

  // Basic prop and state check to ensure the component does not update unnecessarily due to its parent re rendering
  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = Object.keys(nextProps).some(key => nextProps[key] !== this.props[key]);
    const stateChanged = Object.keys(nextState).some(key => nextState[key] !== this.state[key]);
    return propsChanged || stateChanged;
  }

  // Show the add extraction component
  showAddExtraction = () => {
    const { showHideAddExtraction } = this.props;
    var tempCategoryId = this.props.currentTemplate.templateCategoryId
    showHideAddExtraction(true);
    this.refreshAddExtractionData(tempCategoryId);
  };

  // Hide the add extraction component
  hideAddExtraction = () => {
    const { showHideAddExtraction } = this.props;
    showHideAddExtraction(false);
  };

  // Show the Create extraction component
  showCreateExtraction = () => {
    const { showHideCreateExtraction } = this.props;
    showHideCreateExtraction(true);
  };

  // Hide the Create extraction component
  hideCreateExtraction = () => {
    const { showHideCreateExtraction } = this.props;
    showHideCreateExtraction(false);
  };

  onCreateExtractionFail = () => {
    const { showHideCreateExtraction } = this.props;
    showHideCreateExtraction(false);
    };

  // Get data for the add extraction component
  refreshAddExtractionData(tempCategoryId) {
    return getExtractionFieldsByCategoryID(tempCategoryId).then(response => {
      this.setState({
        extractionFields: response.data,
        addExtractionData: flattenAddExtractionData(response.data, this.props.selectedExtractionFieldIds)
      });
    });
  }

  // Create a new extraction field (also associate to template, and create extraction record)
  createExtraction = (name, description, efGroupId) => {
    const { currentTemplate, createExtractionField } = this.props;

    this.setState({ loading: true });
    return createExtractionField({
      name,
      description,
      efGroupId,
      templateId: currentTemplate.projectTemplateId,
    }).then(response => {
      this.setState({ loading: false });
      return response;
    });
  };

  // Add extraction to template and create extraction record
  addExtractions = fields => {
    //ids should always be an array with 1 element in this context
    const { currentTemplate, addExtractionField } = this.props;
    const { extractionFields } = this.state;

    this.setState({ loading: true });

    return addExtractionField({
      extractionField: {
        ...extractionFields.find(e => e.extractionFieldId === fields[0].id),
        extractionFieldGroupId: fields[0].groupId
      },
      templateId: currentTemplate.projectTemplateId
    }).then(() => {
      this.setState({ loading: false });
    });
  };

  createExtractionFieldRecord = extractionFieldId => {
    var { createExtractionFieldRecord } = this.props;
    this.setState({ loading: true });

    return createExtractionFieldRecord(extractionFieldId).then(() => {
      this.setState({ loading: false });
    });
  };

  onCreateExtractionSuccess = (...params) => {
    const { onCreateExtractionSuccess } = this.props;
    if (typeof onCreateExtractionSuccess === 'function') {
      onCreateExtractionSuccess(...params);
    }
  };


  closeFunction = () => {
    const { closeFunction } = this.props;
    closeFunction();
  };

  render() {
    var {
      clearUndoStack,
      closeFunction,
      currentTemplate,
      data,
      extractionFieldGroups,
      isShowingAddExtraction,
      readOnly,
      refreshFunction,
      projectId,
      showAddExtractionButton,
      documentTemplateCategoryId,
      isShowingCreateExtraction
    } = this.props;
    var { addExtractionData, loading } = this.state;
    const canCreateExtractionField = Permissions.Project.Template.TemplateExtractionField.canCreate();

    return (
      <div className="extraction-picker">
        <div className="toolbar">
          <h2>
            <FormattedMessage id="extraction-picker.title" />
          </h2>

          <Button size="icon" className="icon-button" onClick={this.closeFunction}>
            <Icon name="special-cross-black" width={20} height={20} />
          </Button>
        </div>

        <div className="scroll-area">
          {loading && <LoadingOverlay text={false} />}
          {!loading && data ? (
            <ExtractionList
              projectId={projectId}
              data={data}
              clearUndoStack={clearUndoStack}
              groupKey={'groupId'}
              openByDefault={true}
              selectFunction={this.createExtractionFieldRecord}
              pageViewer="DocumentPage"
              refreshFunction={refreshFunction}
              template={currentTemplate}
            />
          ) : null}
        </div>

        <div className="foot">
          {Permissions.Project.Template.TemplateExtractionField.canAssociate() && (
            <Button onClick={this.showAddExtraction}>
              <FormattedMessage id="extraction-picker.search.name" />
            </Button>
          )}
        </div>

        {isShowingAddExtraction && (
          <AddExtraction
            addExtractions={this.addExtractions}
            currentTemplateName={currentTemplate.name}
            data={addExtractionData}
            onAddExtractionsFail={() => {}}
            onAddExtractionsSuccess={() => {}}
            onClose={this.closeFunction}
            onHide={this.hideAddExtraction}
            pageViewer="DocumentPage"
            readOnly={readOnly}
            showConfirmations={true}
            extractionFieldGroups={extractionFieldGroups}
            documentTemplateCategoryId={documentTemplateCategoryId}
            currentTemplate={currentTemplate}
          />
        )}
        
        { canCreateExtractionField && !isShowingAddExtraction && (
        <div className="foot_Create">
          { canCreateExtractionField && !isShowingAddExtraction &&  (
            <Button onClick={this.showCreateExtraction}>
              <FormattedMessage id="extraction-picker.create.name" />
            </Button>
          )}
        </div>
        )}

        {isShowingCreateExtraction && (
          <CreateExtraction
          createExtraction={this.createExtraction}
          currentTemplateName={currentTemplate.name}
          onBackClick={this.hideCreateExtraction}
          onCancelClick={this.hideCreateExtraction}
          onClose={this.closeFunction}
          onCreateFail={this.onCreateExtractionFail}
          onCreateSuccess={this.onCreateExtractionSuccess}
          pageViewer="DocumentPage"
          showConfirmations={true}
          extractionFieldGroups={extractionFieldGroups}
          documentTemplateCategoryId={documentTemplateCategoryId}
          customDocTypeName={currentTemplate.customDocTypeName}
          projectId={projectId}
          projectTemplateId={currentTemplate.projectTemplateId}
        />
      )}
      </div>
    );
  }
}

export default ExtractionPicker;
