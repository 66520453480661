import model from 'models/base-model';

export class DocumentProcessing extends model({
  isLoading: false,
  isLoaded: false,
  error: false,
  data: []
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }

  /* ERROR */
  setError(error) {
    return this.merge({
      isLoading: false,
      error: error
    });
  }

  /* LOADED */
  setLoaded(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      data: fromApiModel(action.payload)
    });
  }

  clear() {
    return new DocumentProcessing();
  }
}

function fromApiModel(data) {
  const mapping = [
    {
      key: 'uploaded',
      ids: [2, 3],
      names: ['preUpload', 'uploaded'],
      count: 0
    },
    {
      key: 'processing',
      ids: [10, 11],
      names: ['processing', 'contentProcessingComplete'],
      count: 0
    },
    {
      key: 'complete',
      ids: [4],
      names: ['processed'],
      count: 0
    },
    {
      key: 'errorUploading',
      ids: [6],
      names: ['uploadError'],
      count: 0
    },
    {
      key: 'errorOCR',
      ids: [7],
      names: ['processingError'],
      count: 0
    }
  ];

  mapping.forEach(item => {
    item.names.forEach(name => {
      if (data?.hasOwnProperty(name)) {
        item.count += data[name].count;
      }
    });
  });

  return mapping;
}
