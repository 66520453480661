import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/shared/icon';

export class Radio extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.any
  };

  static contextTypes = {
    radioGroup: PropTypes.object
  };

  onChange = () => {
    const { onChange } = this.context.radioGroup;
    onChange(this.props.value);
  };

  // Account for values that have type object
  // We cant do simple equality comparison
  getIconValue = () => {
    const { value } = this.props;
    const { selectedValue } = this.context.radioGroup;

    if (typeof value === 'object') {
      return JSON.stringify(value) === JSON.stringify(selectedValue);
    }

    return value === selectedValue;
  };

  render() {
    const { className, id } = this.props;

    const { name, onChange, selectedValue } = this.context.radioGroup;

    const optional = {};
    const icon = this.getIconValue() ? 'special-radiobutton-selected' : 'special-radiobutton-unselected';

    if (selectedValue !== undefined) {
      optional.checked = this.getIconValue();
    }

    if (typeof onChange === 'function') {
      optional.onChange = this.onChange;
    }

    return (
      <div className={classNames('radio-button', className)}>
        <Icon name={icon} width={18} />
        <input {...this.props} type="radio" name={name} className="radio-button__input" id={id} {...optional} />
        <label htmlFor={id} className="radio-button__label" />
      </div>
    );
  }
}

export class RadioGroup extends React.Component {
  static propTypes = {
    Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    children: PropTypes.node.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    selectedValue: PropTypes.any
  };

  static childContextTypes = {
    radioGroup: PropTypes.object
  };

  static defaultProps = {
    Component: 'div'
  };

  getChildContext() {
    const { name, selectedValue, onChange } = this.props;
    return {
      radioGroup: {
        name,
        selectedValue,
        onChange
      }
    };
  }

  render() {
    const { Component, name, selectedValue, onChange, children, ...rest } = this.props;
    return <Component {...rest}>{children}</Component>;
  }
}
