import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import Icon from 'components/shared/icon';

// Custom input component with a calendar icon
export class CustomInput extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.any
  };
  render() {
    return (
      <div className="custom-date-input icon-button" onClick={this.props.onClick}>
        <input type="text" value={this.props.value} readOnly />
        <Icon name="special-calendar" className="date-picker" width={21} />
      </div>
    );
  }
}

// Argus date picker
class DatePicker extends React.Component {
  static propTypes = {
    customInput: PropTypes.node,
    onChange: PropTypes.func,
    selected: PropTypes.object
  };

  onChange = date => {
    this.props.onChange(date);
  };

  render() {
    return (
      <ReactDatePicker
        customInput={<CustomInput />}
        selected={this.props.selected}
        onChange={this.onChange}
        minDate={moment()}
      />
    );
  }
}

export default DatePicker;
