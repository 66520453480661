import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Icon from 'components/shared/icon';
import { getCurrentRegion, getHomeRegion } from 'store/api';

import Permissions from 'permissions/permissions';

const NavigationItem = ({ to, title, body, id, bg }) => (
  <Link
    to={to}
    className={classNames(
      'header__menu-navigation-item',
      `header__menu-navigation-item--${id}`,
      `header__menu-navigation-item--bg-${bg}`
    )}
  >
    <div className="header__menu-navigation-item-name" title={title}>
      {body}
    </div>
  </Link>
);

NavigationItem.propTypes = {
  bg: PropTypes.string,
  body: PropTypes.node,
  id: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string
};

class Menu extends Component {
  static propTypes = {
    currentProject: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    intl: PropTypes.any,
    isShown: PropTypes.bool,
    projectCreation: PropTypes.bool,
    projectId: PropTypes.string,
    toggleMenu: PropTypes.func.isRequired
  };

  render() {
    const { projectId, isShown, toggleMenu, currentUser, currentProject, projectCreation, intl } = this.props;

    const projectName = currentProject.isLoaded ? currentProject.projectName : '';

    const userName = currentUser.isLoaded ? (
      <FormattedMessage id="global.header.welcome-message" values={{ name: currentUser.firstName }} />
    ) : (
      ''
    );

    const global = !projectId || projectCreation;

    return (
      <div className={classNames('header__menu', isShown && 'header__menu--active')} onClick={toggleMenu}>
        <button className="header__menu-close-button">
          <Icon name="special-cross-white" width={16} />
        </button>
        <div className="header__menu-content">
          <div className="header__menu-navigation">
            <h3 className="header__menu-title" title={projectId ? projectName : userName}>
              {projectId ? projectName : userName}
            </h3>
            <div className="header__menu-navigation-items">
              {!global && Permissions.Project.Document.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${projectId}/folder/root`}
                  title={intl.formatMessage({ id: 'global.header.list-of-documents' })}
                  body={<FormattedMessage id="global.header.list-of-documents" />}
                  id="documents"
                  bg="1"
                />
              ) : null}

              {!global && Permissions.Project.Comparison.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${projectId}/compare`}
                  title={intl.formatMessage({ id: 'global.header.document-comparison' })}
                  body={<FormattedMessage id="global.header.document-comparison" />}
                  id="comparison"
                  bg="2"
                />
              ) : null}

              {!global && Permissions.Project.ExtractionField.canViewTab() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${projectId}/extractionfield`}
                  title={intl.formatMessage({ id: 'global.header.extraction-fields' })}
                  body={<FormattedMessage id="global.header.extraction-fields" />}
                  id="extraction-fields"
                  bg="3"
                />
              ) : null}

              {!global && Permissions.Project.Settings.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${projectId}/settings`}
                  title={intl.formatMessage({ id: 'global.header.settings' })}
                  body={<FormattedMessage id="global.header.settings" />}
                  id="settings"
                  bg="4"
                />
              ) : null}

              {!global && Permissions.Project.Visualization.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${projectId}/visuals`}
                  title={intl.formatMessage({ id: 'global.header.visuals' })}
                  body={<FormattedMessage id="global.header.visuals" />}
                  id="visuals"
                  bg="5"
                />
              ) : null}

              {!global && Permissions.Project.Analytics.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${projectId}/extraction-overview`}
                  title={intl.formatMessage({ id: 'global.header.extraction-overview' })}
                  body={<FormattedMessage id="global.header.extraction-overview" />}
                  id="extraction-overview"
                  bg="7"
                />
              ) : null}

              {!global && Permissions.Project.Analytics.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${projectId}/analytics`}
                  title={intl.formatMessage({ id: 'global.header.analytics' })}
                  body={<FormattedMessage id="global.header.analytics" />}
                  id="analytics"
                  bg="8"
                />
              ) : null}

              {!global && Permissions.Project.Tags.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${projectId}/tags`}
                  title={intl.formatMessage({ id: 'global.header.tags' })}
                  body={<FormattedMessage id="global.header.tags" />}
                  id="tags"
                  bg="3"
                />
              ) : null}

              {!global && Permissions.Project.ReviewStatus.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${projectId}/review-status`}
                  title={intl.formatMessage({ id: 'global.header.review-status' })}
                  body={<FormattedMessage id="global.header.review-status" />}
                  id="review-status"
                  bg="1"
                />
              ) : null}

              {global && Permissions.Global.User.canViewUserManagementLink() ? (
                <NavigationItem
                  to={`/region/${getHomeRegion()}/user-management`}
                  title={intl.formatMessage({ id: 'global.header.user-management' })}
                  body={<FormattedMessage id="global.header.user-management" />}
                  id="user-management"
                  bg="4"
                />
              ) : null}

              {global && Permissions.Global.ExtractionField.canViewQuickStudyPageLink() ? (
                <NavigationItem
                  to={`/region/${getHomeRegion()}/groups`}
                  title={intl.formatMessage({ id: 'global.header.manage-extraction-fields' })}
                  body={<FormattedMessage id="global.header.manage-extraction-fields" />}
                  id="manage-extraction-fields"
                  bg="3"
                />
              ) : null}

              <div className="header__menu-navigation-additional-items">
                {!global && Permissions.Global.User.canViewUserManagementLink() ? (
                  <Link
                    to={`/region/${getHomeRegion()}/user-management`}
                    className="header__menu-navigation-additional-item"
                  >
                    <Icon name="team-white" width={18} />
                    <FormattedMessage id="global.header.user-management" />
                  </Link>
                ) : null}

                {!global && Permissions.Global.ExtractionField.canViewQuickStudyPageLink() ? (
                  <Link to={`/region/${getHomeRegion()}/groups`} className="header__menu-navigation-additional-item">
                    <Icon name="team-white" width={18} />
                    <FormattedMessage id="global.header.manage-extraction-fields" />
                  </Link>
                ) : null}

                {Permissions.Global.canViewReports() ? (
                  <Link to={`/region/${getHomeRegion()}/reports`} className="header__menu-navigation-additional-item">
                    <Icon name="reports" width={18} />
                    <FormattedMessage id="global.header.reports" />
                  </Link>
                ) : null}
              </div>
            </div>
          </div>

          <div className="header__menu-recent-projects">
            <h3 className="header__menu-title">
              <FormattedMessage id="global.header.all-projects" />
            </h3>
            <div className="header__menu-recent-projects-list" />
            <Link className="header__menu-recent-projects-footer" to="/project-list">
              <FormattedMessage id="global.header.list-of-projects" />
              <Icon name="special-back-white" width={18} rotate={180} />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Menu);
