import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { PAGE_SIZE } from 'components/project-list/constants';
import CONSTANTS from 'utils/constants';
import emptySearchImage from 'images/empty_state_no_result_found.svg';
import EmptyState from 'components/file-browser/file-list/empty-state';
import emptyStateImage from 'images/empty_state_project.svg';
import Icon from 'components/shared/icon';
import List from './list/list';
import Pagination from 'components/shared/pagination/pagination';
import ToolBar from 'components/project-list/toolbar/toolbar';
import ContainerSelectionModal from 'components/project-list/container-selection/modal';
import { SORT_FUNCTIONS } from 'components/project-list/constants';

import Permissions from 'permissions/permissions';

class ProjectList extends Component {
  static propTypes = {
    currentProject: PropTypes.object,
    currentUser: PropTypes.object,
    history: PropTypes.object,
    loadProjectList: PropTypes.func.isRequired,
    projectList: PropTypes.object,
    tableOrTile : PropTypes.string,
    userMode :PropTypes.bool,
    sortKey : PropTypes.string,
    sortAsc : PropTypes.bool,
    loadTranslationsList: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func,
    isWorkspaceButtonHidden: PropTypes.bool,
    setshowHideWorkspace : PropTypes.func
  };

  state = {
    // Show either table or tile view
    //tableOrTile: null,
    //userMode: false, //Set to true to filter out projects that you are not part of (for admins)
    currentPage: 1,
    // sortKey: 'projectName',
    // sortAsc: true,
    isAllowed:
      this.props.currentUser && this.props.currentUser.accessibleFeatures
        ? this.props.currentUser.accessibleFeatures.reduce((obj, item) => {
            return {
              ...obj,
              [item['featureId']]: item
            };
          }, {})
        : {}     
    };
  

  componentDidMount() {
    const { isAllowed } = this.state;
    this.getTableOrTile();
    if (isAllowed.hasOwnProperty('1')) {
      this.props.loadTranslationsList();
    }
    return this.props.loadProjectList();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.projectList.isSearching !== this.props.projectList.isSearching) ||
    (this.props.userMode !== prevProps.userMode) || (prevState.containerCode !== sessionStorage.getItem(`selected-container`))){
      // This is necessary to reset the pagination when we're searching
      this.setState({ currentPage: 1 });   
      this.setState({containerCode : sessionStorage.getItem(`selected-container`)})
    }  
    if(!this.props.projectList.isLoading || this.props.projectList.projects ){
      
      if (this.props.projectList.projects?.filter(project => project.projectTypeId == CONSTANTS.ProjectTypes.WORKSPACE_PROJECT && project.createdBy == this.props.currentUser.userId).length > 0 
      || this.props.projectList.unfilteredProjects?.filter(project => project.projectTypeId == CONSTANTS.ProjectTypes.WORKSPACE_PROJECT && project.createdBy == this.props.currentUser.userId).length > 0 ){
        this.props.setshowHideWorkspace(false); 
      }
      else{
        this.props.setshowHideWorkspace(true);
      }
    }
  }

  onContainerSelected = (memberFirmCode, containerCode, countryCode) => {
    const { geoCode } = this.props.currentUser;
    return this.createProject(geoCode, memberFirmCode, containerCode, countryCode);
  };

  createProject = (geoCode, memberFirmCode, containerCode, countryCode) => {
    this.setState({ showContainerSelectionModal: false });

    const GEO_PATH = countryCode
      ? `${geoCode}_${memberFirmCode}_${containerCode}_${countryCode}`
      : `${geoCode}_${memberFirmCode}_${containerCode}`;

    return this.props.history.push({
      pathname: `/region/${GEO_PATH}/project/creation`
    });
  };

  onCreateProject = () => {
    const { geoCode, memberFirmCode, containerCode, memberFirmContainers } = this.props.currentUser;

    if (memberFirmContainers && memberFirmContainers.length > 1) {
      // Display member firm container selection modal
      this.setState({ showContainerSelectionModal: true });
    } else {
      // Normal case project creation
      return this.createProject(geoCode, memberFirmCode, containerCode);
    }
  };

  // changeSorting = sortingKey => {
  //   //const { sortKey, sortAsc } = this.state;
  //   const { sortKey, sortAsc } = this.props;
  //   const dir = sortingKey === sortKey ? !sortAsc : true;

  //   this.setState({
  //     sortKey: sortingKey,
  //     sortAsc: dir
  //   });
  // };

 changePage = pageNumber => {
    this.area.scrollTop = 0;
    this.setState({
      currentPage: pageNumber
    });
  };

  isActionRequired = project => {
    return Permissions.Project.canApproveArchive(project);
  };

  // Action required project section
  getActionRequiredProjects = () => {
    const { projectList } = this.props;
    return projectList.projects.filter(p => this.isActionRequired(p));
  };

  // Load the list of recent projects from localStorage
  getRecentProjects = projectList => {
    const { isAllowed } = this.state;
    const { currentUser, translations } = this.props;
    // Load the list from localStorage
    var cachedRecentProjects = [];
    var workspaceProjects = projectList.find(x => currentUser.userId == x.createdBy && x.projectTypeId == CONSTANTS.ProjectTypes.WORKSPACE_PROJECT ) || "";
    if (workspaceProjects)
      cachedRecentProjects.push(workspaceProjects);
    var latestTranslations = {
      translations: translations.translations.slice(Math.max(translations.translations.length - 5, 0))
    } 
    var regularCachedRecentProjects = JSON.parse(localStorage.getItem(`recentProjects-${currentUser.userId}`)) || [];
    regularCachedRecentProjects = regularCachedRecentProjects
      // Filter out any projects were accessed more than 7 days ago
      .filter(({ timestamp }) => !moment(timestamp).isBefore(moment().subtract(7, 'days')))
      // Map each of the cached project keys to one of the projects loaded from the API
      .map(({ projectKey: recentProjectKey }) => projectList.find(({ projectKey }) => projectKey === recentProjectKey))
      // Filter out any projects that can't be found
      .filter(project => !!project);
      cachedRecentProjects = cachedRecentProjects.concat(regularCachedRecentProjects)
      if(cachedRecentProjects.length > 3)
        cachedRecentProjects.pop();
      if (isAllowed.hasOwnProperty('1') && latestTranslations.translations.length !== 0) {
        cachedRecentProjects.push(latestTranslations); 
      }

    return cachedRecentProjects;
  };

  // multiColumnSort can sort a list of values by multiple columns in different orders
  multiColumnSort = (values, columns, orders) => {
    return values.slice().sort((a, b) => {
      return columns.reduce((compared, key, idx) => {
        // Stop when we have a non-zero comparison result (i.e: previous columns were not equal)
        if (compared !== 0) {
          return compared;
        }

        // Compare function
        return orders[idx] * SORT_FUNCTIONS[key](a[key], b[key]);
      }, 0);
    });
  };

  // Filter deleted projects for those who cannot view delete projects
  filterDeletedProjects = () => {
    const { projectList } = this.props;

    return projectList.projects.filter(
      project =>
        Permissions.Project.canViewDeletedProjects({
          geoCode: project.geoCode,
          memberFirmCode: project.memberFirmCode,
          containerCode: project.containerCode
        }) || project.projectStateId !== CONSTANTS.ProjectStateIds.softDelete
    );
  };

  // Normal section that can be organized by sorting keys
  getNormalProjects = () => {
    //const { sortKey, sortAsc } = this.state;
    const { sortKey, sortAsc } = this.props;
    const projects = this.filterDeletedProjects().filter(p => !this.isActionRequired(p));

    // The order in which to sort our columns (1 is asc, -1 is desc)
    const order = sortAsc ? [1, 1] : [-1, 1];

    return this.multiColumnSort(projects, [sortKey, 'projectName'], order);
  };

  // setTableOrTile = value => {
  //   const { currentUser } = this.props;
  //   if (currentUser) {
  //     localStorage.setItem(`project-list-display-mode-${currentUser.userId}`, JSON.stringify(value));
  //     this.setState({ tableOrTile: value });
  //   }
  // };

  getTableOrTile = () => {
    const { currentUser } = this.props;
    if (currentUser) {
      const value = JSON.parse(localStorage.getItem(`project-list-display-mode-${currentUser.userId}`));
      //if (value) this.setState({ tableOrTile: value });
    }
  };

  // onUserModeChange = userMode => {
  //   this.setState({ userMode });
  // };

  updateProjectStatusOnCopy = project => {
    return this.props.updateProjectState(project);
  };

  render() {
    const { projectList, currentUser, sortKey, userMode, loadProjectList, onSearchChange} = this.props;
    const { currentPage, showContainerSelectionModal } = this.state;

    if (projectList.error !== null && projectList.projects.length <= 0) {
      return <div className="project-list"> Server could not load projects from any specified regions </div>;
    }

    if (projectList.isLoading || !projectList.projects) {
      return (
        <div className="project-list">
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        </div>
      );
    }

    const userModeFilter = currentProject => {
      if (!userMode && Permissions.Global.canViewAdminContainerDropdown()) 
      {
        //return true
        return currentProject.containerCode == sessionStorage.getItem(`selected-container`);
      }
        return currentProject.myRole && currentProject.myRole !== null && currentProject.myRole.isTemporaryUser === false;  
    };

    const otherProjects = this.getNormalProjects().filter(userModeFilter);
    const actionRequiredProjects = this.getActionRequiredProjects().filter(userModeFilter);
    const recentProjects = this.getRecentProjects(otherProjects);
    const allProjects = otherProjects.slice(currentPage * PAGE_SIZE - PAGE_SIZE, currentPage * PAGE_SIZE);

    return (
      <div className="project-list">
        {/* /* <ToolBar
          onCreate={this.onCreateProject}
          showIcons={projectList.projects && projectList.projects.length > 0 ? true : false}
          onIconClick={this.setTableOrTile}
          iconValue={this.state.tableOrTile}
          onSortClick={this.changeSorting}
          sortValue={sortKey}
          loadState={projectList}
          onUserModeChange={this.onUserModeChange}
          userMode={userMode}
          currentUser={currentUser}
          loadProjectList={loadProjectList}
          onSearchChange={onSearchChange}
        />*/}

        <ContainerSelectionModal
          show={showContainerSelectionModal}
          containers={currentUser.memberFirmContainers}
          onCancel={() => this.setState({ showContainerSelectionModal: false })}
          onSubmit={this.onContainerSelected}
        />

        {projectList.projects &&
        !projectList.error &&
        (otherProjects.length > 0 || actionRequiredProjects.length > 0) ? (
          <div className="project-list__content">
            <div className="project-list__scroll-area" ref={area => (this.area = area)}>
              {/* Action Required Projects */}
              {actionRequiredProjects && actionRequiredProjects.length > 0 ? (
                <List
                  titleKey={'action-required'}
                  user={currentUser}
                  data={actionRequiredProjects}
                  toggleTable={this.props.tableOrTile === 'table' ? true : false}
                  onProjectCopy = {data => this.updateProjectStatusOnCopy(data)}
                  {...this.props}
                />
              ) : null}

              {/* Recent Projects */}
              {recentProjects && recentProjects.length > 0 ? (
                <List
                  titleKey={'recent-works'}
                  user={currentUser}
                  data={recentProjects}
                  toggleTable={this.props.tableOrTile === 'table' ? true : false}
                  onProjectCopy = {data => this.updateProjectStatusOnCopy(data)}
                  {...this.props}
                />
              ) : null}

              {/* All Projects */}
              {otherProjects.length > 0 ? (
                <List
                  user={currentUser}
                  data={allProjects}
                  toggleTable={this.props.tableOrTile === 'table' ? true : false}
                  onProjectCopy = {data => this.updateProjectStatusOnCopy(data)}
                  {...this.props}
                />
              ) : null}
            </div>
            <div className="project-list__footer">
              <Pagination
                onPageChange={this.changePage}
                pagination={{
                  pageNumber: currentPage,
                  pageSize: PAGE_SIZE,
                  totalPageCount: Math.ceil(otherProjects.length / PAGE_SIZE),
                  totalRecordCount: otherProjects.length
                }}
              />
            </div>
          </div>
        ) : !projectList.isEmptySearch ? (
          <div className="project-list__empty-state">
            <EmptyState
              title={<FormattedMessage id="project-list.empty-state.title" />}
              description={<FormattedMessage id="project-list.empty-state.description" />}
              img={emptyStateImage}
            />
          </div>
        ) : (
          <div className="project-list__empty-state">
            <EmptyState
              title={<FormattedMessage id="search-results.empty-state-title" />}
              description={<FormattedMessage id="search-results.empty-state-description" />}
              img={emptySearchImage}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ProjectList;
