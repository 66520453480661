import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { ITEM_STATUSES } from 'models/project-item';
import { Link } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactSlider from 'components/shared/react-slider/react-slider';

import { addNotification } from 'store/notifications.js';
import { getCurrentRegion } from 'store/api';
import Button from 'components/shared/single-click-button';
import CompareSidebarContainer from 'containers/file-browser/compare-sidebar-container';
import dateUtil from 'utils/dateUtil';
import Icon from 'components/shared/icon';
import Permissions from 'permissions/permissions';
import store from 'store/store';
import TagsPopover from 'components/file-browser/toolbar/tags-popover';
import ToolbarIcon from 'components/shared/toolbar-icon/toolbar-icon';

const TOOLBAR_OPTIONS = {
  TAG: 'TAG',
  COMPARE: 'COMPARE'
};

class VisualsHeader extends Component {
  static propTypes = {
    activeTemplate: PropTypes.object.isRequired,
    currentProject: PropTypes.object,
    intl: intlShape,
    lastUpdatedDate: PropTypes.object,
    projectId: PropTypes.string.isRequired,
    regenerateVisuals: PropTypes.func.isRequired,
    resetView: PropTypes.func.isRequired,
    searchFilteredDocuments: PropTypes.array.isRequired,
    searchText: PropTypes.string.isRequired,
    setSearchText: PropTypes.func.isRequired,
    setTemplate: PropTypes.func.isRequired,
    setThresholdRange: PropTypes.func.isRequired,
    templates: PropTypes.array.isRequired,
    thresholdRange: PropTypes.object.isRequired,
    visualsRegenerating: PropTypes.bool.isRequired
  };

  state = {
    activeToolbarOption: null
  };

  setActiveToolbarOption = activeToolbarOption => {
    this.setState({ activeToolbarOption });
  };

  clearActiveToolbarOption = () => this.setState({ activeToolbarOption: null });

  onSubmitCreateComparison = documentComparisonId => {
    const { projectId } = this.props;

    this.clearActiveToolbarOption();
    store.dispatch(
      addNotification({
        message: (
          <FormattedMessage
            id="compare.comparison-created-notification"
            values={{
              link: (
                <Link to={`/region/${getCurrentRegion()}/project/${projectId}/compare`}>
                  <FormattedMessage id="compare.comparison-created-notification.link-text" />
                </Link>
              )
            }}
          />
        ),
        clearOnPageChange: true,
        autohide: 10
      })
    );
  };

  onSubmitAddToComparison = selectedComparisonId => {
    const { projectId } = this.props;

    this.clearActiveToolbarOption();

    store.dispatch(
      addNotification({
        message: (
          <FormattedMessage
            id="compare.document-added-notification"
            values={{
              link: (
                <Link
                  to={`/region/${getCurrentRegion()}/project/${projectId}/compare${
                    selectedComparisonId ? `/${selectedComparisonId}` : ''
                  }`}
                >
                  <FormattedMessage id="compare.comparison-added-notification.link-text" />
                </Link>
              )
            }}
          />
        ),
        clearOnPageChange: true,
        autohide: 10
      })
    );
  };

  onThresholdChange = ([min, max]) => {
    this.props.setThresholdRange({ min: min / 100, max: max / 100 });
  };

  render() {
    const {
      currentProject,
      setTemplate,
      templates,
      searchFilteredDocuments,
      activeTemplate,
      intl,
      thresholdRange,
      resetView,
      searchText,
      setSearchText,
      regenerateVisuals,
      visualsRegenerating,
      lastUpdatedDate,
      projectId
    } = this.props;

    const { activeToolbarOption } = this.state;

    const searchPlaceholder = intl.formatMessage({ id: 'visuals.header.search-placeholder' });

    const sliderValue = [thresholdRange.min * 100, thresholdRange.max * 100];

    const docIdMap = {};
    searchFilteredDocuments.forEach(doc => (docIdMap[doc.id] = true));

    return (
      <div className="visuals-header visuals-header__root">
        <div className="visuals-header__header-title">
          <h2>
            <FormattedMessage id="visuals.header.title" />
          </h2>
          <div className="visuals-header__header-subtitle">{currentProject.projectName}</div>
        </div>
        <div>
          <MenuWrapper className="visuals-header__template-menu" onSelection={setTemplate}>
            <MenuButton className="form-dropdown visuals-header__template-menu-button">
              {activeTemplate.name} <Icon name="special-arrow-down" className="visuals-header__menu-arrow" width={10} />
            </MenuButton>
            <Menu className="visuals-header__template-menu-content">
              <ul>
                {templates.map(template => {
                  return (
                    <li key={template.projectTemplateId}>
                      <MenuItem value={template} className="form-dropdown__item visuals-header__template-menu-item">
                        {template.name}
                      </MenuItem>
                    </li>
                  );
                })}
              </ul>
            </Menu>
          </MenuWrapper>
        </div>
        <div className="visuals-header__search-container">
          <Autocomplete
            getItemValue={item => item.name}
            items={searchFilteredDocuments}
            inputProps={{ placeholder: searchPlaceholder, className: 'visuals-header__search-input' }}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            onSelect={(name, doc) => setSearchText(name)}
            menuStyle={{
              borderRadius: '3px',
              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
              background: 'rgba(255, 255, 255, 0)',
              position: 'fixed',
              overflow: 'auto',
              maxHeight: '50%'
            }}
            shouldItemRender={(item, value) => value && item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.id}
                style={{ background: isHighlighted ? '#EBEBEBBB' : '#ffffffBB' }}
                className="visuals-header__search-item"
              >
                {item.name}
              </div>
            )}
          />
          <Icon name="special-search" className="visuals-header__search-icon" width={10} />
        </div>
        <div className="visuals-header__threshold-wrapper">
          <span>
            <FormattedMessage id="visuals.header.threshold" />
          </span>
          <div className="visuals-header__threshold-container">
            <span className="visuals-header__threshold-value">{Math.floor(sliderValue[0])}%</span>
            <ReactSlider
              className="visuals-header__threshold"
              id="horizontal-2"
              value={sliderValue}
              minDistance={10}
              withBars
              numGuides={4}
              onChange={this.onThresholdChange}
              pearling={true}
            >
              <div className="visuals-header__threshold-handle">
                <div className="visuals-header__threshold-handle-inner" />
              </div>
              <div className="visuals-header__threshold-handle">
                <div className="visuals-header__threshold-handle-inner" />
              </div>
            </ReactSlider>
            <span className="visuals-header__threshold-value">{Math.floor(sliderValue[1])}%</span>
          </div>
        </div>
        <div>
          <Button
            title={intl.formatMessage({ id: 'visuals.header.reset-view' })}
            onClick={resetView}
            className="visuals-header__secondary-btn"
          >
            <FormattedMessage id="visuals.header.reset-view" />
          </Button>
        </div>
        <div className="visuals-header__divider" />
        {Permissions.Project.Visualization.canEdit() && !visualsRegenerating && (
          <ToolbarIcon
            onClick={() => regenerateVisuals()}
            iconName="spin"
            tooltipKey="visuals.header.regenerate-tooltip"
          />
        )}
        {!visualsRegenerating && lastUpdatedDate !== undefined && (
          <div className="visuals-header__regenerate-info">
            {lastUpdatedDate && (
              <div className="visuals-header__regenerate-sub-text">
                <FormattedMessage id="visuals.header.updated-on" />
              </div>
            )}
            {lastUpdatedDate && (
              <div className="visuals-header__regenerate-text">{dateUtil.formatDateTime(lastUpdatedDate)}</div>
            )}
          </div>
        )}
        {visualsRegenerating && (
          <Icon className="spinner visuals-header__regenerate-spinner" name="loader" width={36} />
        )}
        {visualsRegenerating && (
          <div className="visuals-header__regenerate-info">
            <div className="visuals-header__regenerate-text">
              <FormattedMessage id="visuals.header.regenerating-visuals" />
            </div>
            <div className="visuals-header__regenerate-sub-text">
              <FormattedMessage id="visuals.header.regenerating-visuals-sub-text" />
            </div>
          </div>
        )}

        {Permissions.Project.Visualization.canEdit() && searchFilteredDocuments.length > 0 && (
          <div className="visuals-header__tag-icon-wrapper">
            <ToolbarIcon
              iconName="tag"
              onClick={() => this.setActiveToolbarOption(TOOLBAR_OPTIONS.TAG)}
              tooltipKey="visuals.header.tag"
              active={activeToolbarOption === TOOLBAR_OPTIONS.TAG}
            />
            <div>
              {activeToolbarOption === TOOLBAR_OPTIONS.TAG && (
                <TagsPopover
                  onClose={this.clearActiveToolbarOption}
                  selectedItems={docIdMap}
                  projectId={projectId}
                  visualsPageMode={true}
                />
              )}
            </div>
          </div>
        )}
        {Permissions.Project.Visualization.canEdit() && searchFilteredDocuments.length > 0 && (
          <ToolbarIcon
            iconName="compare"
            onClick={() => this.setActiveToolbarOption(TOOLBAR_OPTIONS.COMPARE)}
            tooltipKey="visuals.header.compare"
            active={activeToolbarOption === TOOLBAR_OPTIONS.COMPARE}
          />
        )}
        {activeToolbarOption === TOOLBAR_OPTIONS.COMPARE && (
          <CompareSidebarContainer
            selectedItems={searchFilteredDocuments
              .filter(item => item.itemStateId === ITEM_STATUSES.PROCESSED)
              .map(item => ({
                ...item,
                projectItemId: item.id,
                itemName: item.name
              }))}
            onCloseClick={this.clearActiveToolbarOption}
            onSubmitCreateComparison={this.onSubmitCreateComparison}
            onSubmitAddToComparison={this.onSubmitAddToComparison}
            projectId={projectId}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(VisualsHeader);
