import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import EngagementDetails from 'components/project-creation/project-details/shared/engagement-details';
import NameInputField from 'components/project-creation/project-details/shared/name-input-field';
import MATInputField from 'components/project-creation/project-details/shared/mat-input-field';
import ClientInputField from 'components/project-creation/project-details/shared/client-input-field';
import enhanceWithClickOutside from 'react-click-outside';

import constants from 'utils/constants';
const { DateFormats } = constants;

class DateFormatTooltip extends Component {
  static propTypes = {
    showInnerTooltip: PropTypes.bool,
    closeInnerPopup: PropTypes.func
  };

  closeInnerPopup = () => {
    this.props.closeInnerPopup();
  };

  handleClickOutside = () => {
    this.closeInnerPopup();
  };

  render() {
    const { showInnerTooltip } = this.props;

    return showInnerTooltip ? (
      <div className="project-date-format-selection__Copy-Carry-tooltip">
        <div className="project-date-format-selection__tooltip-close">
        <Button className="icon-button" onClick={this.closeInnerPopup}>
          <Icon name="special-cross-black" width={14} />
        </Button>
        </div>
        <FormattedMessage id="project-details-project-date-format-selection.toottip-content" />
      </div>
    ) : null;
  }
}
const InnerTooltip = enhanceWithClickOutside(DateFormatTooltip);

class MatSearchForm extends Component {
  static propTypes = {
    allowEditing: PropTypes.bool,

    engagementInfo: PropTypes.any,
    intl: PropTypes.any,
    project: PropTypes.any,
    projectState: PropTypes.any,
    countryName: PropTypes.string,

    onEngagementNameChange: PropTypes.func,
    onClientNameChange: PropTypes.func,
    onProjectNameChange: PropTypes.func,

    setEngagementId: PropTypes.func,
    setEngagementInfo: PropTypes.func,
    setEngagementValidation: PropTypes.func,
    setClientId: PropTypes.func,
    setClientInfo: PropTypes.func,
    setClientValidation: PropTypes.func,

    setNameResolved: PropTypes.func,
    setNameValidation: PropTypes.func,

    updateProjectEngagement: PropTypes.func,
    updateProjectName: PropTypes.func,
    customHeader: PropTypes.node,
    onDateFormatChange: PropTypes.func,
    setWBSCodeResolved: PropTypes.func
  };
  state = {
    selectedDateFormat: 'MM/dd/yyyy'
  };

  componentDidMount = () =>{
    this.props.setWBSCodeResolved(true);
  }
  closeInnerPopup = () => {
    this.setState({ showInnertoolTip: false });
  };
  showInnertoolTip = () => {
    this.setState({ showInnertoolTip: true });
  };

  componentWillUnmount() {
    this.setState({ showInnertoolTip: false });
  }

  render = () => {
    const {
      allowEditing,
      countryName,
      engagementInfo,
      intl,
      project,
      projectState,
      onEngagementNameChange,
      onClientNameChange,
      onProjectNameChange,
      setEngagementId,
      setClientId,
      setEngagementInfo,
      setClientInfo,
      setEngagementValidation,
      setClientValidation,
      setNameResolved,
      setNameValidation,
      updateProjectEngagement,
      updateProjectName,
      customHeader,
      clientInfo,
      selectedProjectDateFormat,
      onDateFormatChange
    } = this.props;
    let { showInnertoolTip } = this.state;
    const selectedDateFormat = localStorage.getItem('userSelectedDateFormat');
    const dateFormat =
      project.projectId == null
        ? ''
        : selectedProjectDateFormat != null
        ? selectedProjectDateFormat
        : selectedDateFormat;
    const formats = DateFormats;
  
    return (
      <div className="project-details__container mat-search-form">
        <div className="project-details__body-left project-details__upper-height">
          <div className="project-details__heading">
            <span className="project-details__heading-title">
              <FormattedMessage id="project-details.heading.title" />
            </span>
            {customHeader}
          </div>

          <div className="project-details__content">
            <div className="project-details__content-left">
              <NameInputField
                allowEditing={allowEditing}
                checkValid={setNameValidation}
                intl={intl}
                project={project}
                projectState={projectState}
                onChange={onProjectNameChange}
                updateProjectName={updateProjectName}
                setNameResolved={setNameResolved}
              />
              <div className="project-details__MAT-Client">
                <ClientInputField
                  allowEditing={allowEditing}
                  checkValid={setClientValidation}
                  setClientInfo={setClientInfo}
                  intl={intl}
                  project={project}
                  projectState={projectState}
                  setClientId={setClientId}
                  onChange={onClientNameChange}
                  setEngagementInfo={setEngagementInfo}
                  setEngagementId={setEngagementId}
                />
              </div>

            </div>

            <div className="project-details__content-right">
              <div className="valid-textarea">
                <span className="valid-textarea__heading">
                  <FormattedMessage id="engagement-details.country" />
                </span>
                <div className="valid-textarea__uneditable">
                  <span className="valid-textarea__uneditable-text">{countryName}</span>
                </div>
              </div>

              <div className="project-details__MAT-engagement">
                <MATInputField
                  allowEditing={allowEditing}
                  checkValid={setEngagementValidation}
                  setEngagementInfo={setEngagementInfo}
                  intl={intl}
                  project={project}
                  projectState={projectState}
                  setEngagementId={setEngagementId}
                  onChange={onEngagementNameChange}
                  updateProjectEngagement={updateProjectEngagement}
                  clientInfo={clientInfo}
                  checkClientValid={setClientValidation}
                />
              </div>
            </div>
          </div>
        </div>

        {engagementInfo && (
          <div className="project-details__body-right project-details__lower-height">
            <EngagementDetails data={engagementInfo} />
          </div>
        )}
      </div>
    );
  };
}

export default MatSearchForm;
