import model from 'models/base-model';
import dateUtil from 'utils/dateUtil';
import { sortByKey } from 'utils/string-utils';

export default class Reports extends model({
  isReportsLoading: false,
  isScheduledLoading: false,
  regular: null,
  scheduled: null,
  error: null
}) {
  setReportsLoading() {
    return this.merge({
      isReportsLoading: true
    });
  }

  setScheduledLoading() {
    return this.merge({
      isScheduledLoading: true
    });
  }

  setError(error) {
    return this.merge({
      isReportsLoading: false,
      isScheduledLoading: false,
      error: error,
      regular: [],
      scheduled: []
    });
  }

  // Get data returned from the API
  setReportsLoaded(state, action) {
    return this.merge({
      ...state,
      isReportsLoading: false,
      regular: action.payload.map(value => Regular.fromData(value))
    });
  }

  setScheduledLoaded(state, action) {
    return this.merge({
      ...state,
      isScheduledLoading: false,
      scheduled: sortByKey(
        'reportName',
        action.payload.map(value => Scheduled.fromData(value))
      )
    });
  }
}

// Regular reports
export class Regular extends model({
  reportId: null,
  reportName: null,
  exportFileName: null,
  reportDescription: null
}) {
  static fromData(model) {
    return new Regular({
      reportId: model.reportId ? model.reportId : null,
      reportName: model.reportName ? model.reportName : null,
      exportFileName: model.exportFileName ? model.exportFileName : null,
      reportDescription: model.reportDescription ? model.reportDescription : null
    });
  }
}
// Scheduled reports
export class Scheduled extends model({
  scheduledReportId: null,
  recipients: [],
  reportId: null,
  reportName: null,
  reportDescription: null,
  frequencyInterval: null,
  frequencyIntervalUnits: null,
  startDate: null,
  lastRunDate: null,
  containerCode: null,
  memberFirmCode: null
}) {
  static fromData(model) {
    return new Scheduled({
      ...model,
      startDate: model.startDate ? dateUtil.fromServerDate(model.startDate) : null,
      lastRunDate: model.lastRunDate ? dateUtil.fromServerDate(model.lastRunDate) : null,
      recipients: model.recipients ?? []
    });
  }
}
