import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { addLocaleData } from 'react-intl';
import moment from 'moment';

import enErrors from 'containers/intl/messages/ui-errors.json';
import enMessages from 'containers/intl/messages/ui-general.json';

import * as localeData from './language-import.js';

for (var key in localeData) {
  addLocaleData([...localeData[key]]);
}

const LANGUAGE_CACHE_TIME_SECONDS = 300;

class _Intl extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    children: PropTypes.node,
    languageData: PropTypes.object,
    languages: PropTypes.object,
    loadLanguageData: PropTypes.func.isRequired,
    loadLanguages: PropTypes.func.isRequired,
    currentLanguage: PropTypes.object,
    setLanguage: PropTypes.func,
    locale: PropTypes.string.isRequired
  };

  componentDidMount() {
    const previousLocal = localStorage.getItem('language');
    if (previousLocal) this.props.setLanguage(JSON.parse(previousLocal));

    if (this.props.authenticated) {
      this.loadLanguages();
    }
  }

  componentDidUpdate(prevProps) {
    var { authenticated, languages, currentLanguage } = this.props;

    if (authenticated) {
      if (!prevProps.authenticated) this.loadLanguages();
      if (languages !== prevProps.languages) this.loadLanguageData();
      if (currentLanguage !== prevProps.currentLanguage) this.loadLanguageData();
    }
  }

  loadLanguages() {
    var { languages, loadLanguages } = this.props;

    if (languages && languages.timestamp) {
      var lastUpdate = moment(languages.timestamp);
      var now = moment();
      var dur = moment.duration(now.diff(lastUpdate));
      var sec = dur.asSeconds();

      if (sec > LANGUAGE_CACHE_TIME_SECONDS) {
        loadLanguages();
      }
    } else {
      loadLanguages();
    }
  }

  loadLanguageData() {
    var { loadLanguageData, currentLanguage, languageData } = this.props;

    if (
      languageData &&
      languageData.languageData[currentLanguage.localeCode] &&
      languageData.languageData[currentLanguage.localeCode].timestamp
    ) {
      var lastUpdate = moment(languageData.languageData[currentLanguage.localeCode].timestamp);
      var now = moment();
      var dur = moment.duration(now.diff(lastUpdate));
      var sec = dur.asSeconds();

      if (sec > LANGUAGE_CACHE_TIME_SECONDS) {
        loadLanguageData(currentLanguage.localeCode);
      }
    } else {
      loadLanguageData(currentLanguage.localeCode);
    }
  }

  getOverrides = (keys, language = 'en-US') => {
    var languageData = this.props;
    var overrides = {};

    keys.forEach(key => {
      overrides[key] = languageData[language] ? languageData[language][key] : enMessages[key] ? enMessages[key] : key;
    });

    return overrides;
  };

  render() {
    const { languageData, currentLanguage } = this.props;
    var messages = languageData.languageData[currentLanguage.localeCode];

    var englishOverride = this.getOverrides([
      'global.footer.copyright-text',
      'global.footer.extended-text',
      'global.footer.terms-link',
      'global.footer.privacy-link',
      'global.footer.privacyNotice-link',
      'global.footer.third-party',
      'global.footer.cookie-setting'
    ]);

    messages = Object.assign({}, enErrors, enMessages, messages, englishOverride);

    return (
      <IntlProvider key={currentLanguage.localeCode} locale={currentLanguage.localeCode} messages={messages}>
        {this.props.children}
      </IntlProvider>
    );
  }
}

export default _Intl;
