import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCK, TranslationType } from '../../constants';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import classNames from 'classnames';
import {
  Button as DropdownButton,
  Menu as Dropdown,
  MenuItem as DropdownItem,
  Wrapper as DropdownWrapper
} from 'react-aria-menubutton';
import Button from 'components/shared/single-click-button';

class LanguageSelector extends Component {
  static propTypes = {
    setSourceLanguage: PropTypes.func.isRequired,
    setTargetLanguage: PropTypes.func.isRequired,
    sourceLanguage: PropTypes.object,
    targetLanguage: PropTypes.object,
    translations: PropTypes.object.isRequired,
    setTranslationType: PropTypes.func,
    translationType: PropTypes.string
  };

  state = {
    showTranslationType : false,
    isTranslationTypeInfoOpen: false
  }

  componentDidMount() {
    const { sourceLanguage, targetLanguage, translations, setSourceLanguage, setTargetLanguage } = this.props;
    if (sourceLanguage && targetLanguage) {
      const srcLang = translations.languagePairs
        ? translations.languagePairs.find(language => language.sourceLanguage.id === sourceLanguage.sourceLanguage.id)
        : null;

      if (srcLang) {
        setSourceLanguage(srcLang);
        let trgLang = srcLang.targetLanguages.find(language => language.id === targetLanguage.id);
        if (trgLang) {
          setTargetLanguage(trgLang);
          this.enableTranslationType(trgLang);
        }
      } else {
        setSourceLanguage(null);
        setTargetLanguage(null);
      }
    } else {
      setSourceLanguage(null);
      setTargetLanguage(null);
    }
  }

  enableTranslationType(targetLanguageValue) {
    const { sourceLanguage, translations, setTranslationType } = this.props;
    var targetLang = translations.languagePairs.find(lang => lang.sourceLanguage.id === sourceLanguage.sourceLanguage.id).targetLanguages;
    if (targetLang.filter( x => x.name === targetLanguageValue.name).length > 1)
    {
      this.setState({showTranslationType : true});
      setTranslationType(TranslationType[0]);
    }
    else
    {
    this.setState({showTranslationType : false});
    setTranslationType('');
    }
  }

  onSourceLanguageSelect = (value, event) => {
    const { setSourceLanguage, setTranslationType } = this.props;
    event.stopPropagation();
    setSourceLanguage(value);
    this.setState({showTranslationType : false});
    setTranslationType('');
  };

  onTargetLanguageSelect = (value, event) => {
    const { setTargetLanguage } = this.props;
    event.stopPropagation();
    setTargetLanguage(value);
    this.enableTranslationType(value);
  };

  onLanguageTypeSelect = (value, event) => {
    const { setTranslationType } = this.props;
    event.stopPropagation();
    setTranslationType(value);
  };

  openTranslationTypeInfoModal = event =>{
    event.preventDefault();
    this.setState({ isTranslationTypeInfoOpen: true },()=>{
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu = () => {
    this.setState({ isTranslationTypeInfoOpen: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  renderTranslationTypeInfoModal = () => {
    const block = `${BLOCK}__language-selector`;
    return (
      <div className={`${block}__type-info`}>
        <div className={`${block}__type-info-top`}>
          <Button size="icon" className="icon-button" onClick={this.closeRoleInfoModal}>
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className={`${block}__type-info-bottom`}>
          <div>
            <span className={`${block}__type-info-bold`}>
              <FormattedMessage id="translation.language-selector-Generic" />
            </span>
            {' - '}
            <FormattedMessage id="translation.language-selector-Generic.info" />
          </div>
          <div>
            <span className={`${block}__type-info-bold`}>
              <FormattedMessage id="translation.language-selector-Adapted-Audit" />
            </span>
            {' - '}
            <FormattedMessage id="translation.language-selector-Adapted-Audit.info" />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { sourceLanguage, targetLanguage, translations, translationType } = this.props;
    const {showTranslationType, isTranslationTypeInfoOpen} = this.state;
    const block = `${BLOCK}__language-selector`;

    return (
      <div className={block}>
        <div>
          <span className={`${block}-label`}>
            <FormattedMessage id="global.header.language-select-label" />
          </span>
          <DropdownWrapper className={`${block}-wrapper`} onSelection={this.onSourceLanguageSelect}>
            <DropdownButton className={`${block}-button`}>
              <span
                className={classNames(`${block}-dropdown-text`, sourceLanguage && `${block}-dropdown-text--selected`)}
                title={sourceLanguage && sourceLanguage.sourceLanguage.name}
              >
                {sourceLanguage ? (
                  sourceLanguage.sourceLanguage.name
                ) : (
                  <FormattedMessage id="translation.language-selector-source" />
                )}
              </span>
              <Icon className={`${block}-dropdown-arrow`} name="special-arrow-down-transparent" width={16} />
            </DropdownButton>
            <Dropdown className={`${block}-dropdown`}>
              <ul>
                {translations.languagePairs
                  ? translations.languagePairs.map((language, index) => (
                      <li key={index}>
                        <DropdownItem className={`${block}-item`} text={language.sourceLanguage.name} value={language}>
                          {language.sourceLanguage.name}
                        </DropdownItem>
                      </li>
                    ))
                  : null}
              </ul>
            </Dropdown>
          </DropdownWrapper>
        </div>
        <Icon className={`${block}-arrow`} name="arrow-right" width={18} />
        <div>
          <span className={`${block}-label`}>
            <FormattedMessage id="translation.language-selector-target" />
          </span>
          <DropdownWrapper
            className={classNames(`${block}-wrapper`, !sourceLanguage && 'disabled')}
            onSelection={this.onTargetLanguageSelect}
          >
            <DropdownButton className={`${block}-button`} disabled={!sourceLanguage}>
              <span
                className={classNames(`${block}-dropdown-text`, targetLanguage && `${block}-dropdown-text--selected`)}
                title={targetLanguage && targetLanguage.name}
              >
                {targetLanguage ? targetLanguage.name : <FormattedMessage id="translation.select-language" />}
              </span>
              <Icon
                className={classNames(`${block}-dropdown-arrow`, !sourceLanguage && 'disabled')}
                name="special-arrow-down-transparent"
                width={16}
              />
            </DropdownButton>
            <Dropdown className={`${block}-dropdown`}>
              <ul>
                {sourceLanguage &&
                  sourceLanguage.targetLanguages ? Array.from(new Set(sourceLanguage.targetLanguages.map(s => s.id)))
                  .map(id => { return {id: id, name : sourceLanguage.targetLanguages.find(s=> s.id === id).name}})
                  .map((language, index) => (
                    <li key={index}>
                      <DropdownItem className={`${block}-item`} text={language.name} value={language}>
                        {language.name}
                      </DropdownItem>
                    </li>
                  )): null }
              </ul>
            </Dropdown>
          </DropdownWrapper>
        </div>
        {showTranslationType && <div className = {`${block}-type-info`}>
            <span className={`${block}-label`}>
              <FormattedMessage id="translation.language-selector-type" />
            </span>
            <Button size="icon" onClick={this.openTranslationTypeInfoModal}>
              <Icon name="special-info-outline" width={15} className = {`${block}-type-info-icon`}/>
            </Button>
            {isTranslationTypeInfoOpen && this.renderTranslationTypeInfoModal()}            
            <DropdownWrapper className={classNames(`${block}-wrapper`, !sourceLanguage && 'disabled')}
                onSelection={this.onLanguageTypeSelect}>
                <DropdownButton className={`${block}-button`} disabled={!sourceLanguage}>
                  <span
                    className={classNames(`${block}-dropdown-text`, `${block}-dropdown-text--selected`)}
                    title={translationType}
                  >
                    { translationType === "" ? <FormattedMessage id= {`translation.language-selector-${TranslationType[0]}`} /> : <FormattedMessage id= {`translation.language-selector-${translationType}`} />}
                  </span>
                  <Icon
                    className={classNames(`${block}-dropdown-arrow`, !sourceLanguage && 'disabled')}
                    name="special-arrow-down-transparent"
                    width={16}
                  />
                </DropdownButton>
                <Dropdown className={`${block}-dropdown`}>
                  <ul>
                    {TranslationType.map((type, index) => (
                        <li key={index}>
                          <DropdownItem className={`${block}-item`} text={type} value={type}>
                            {<FormattedMessage id= {`translation.language-selector-${type}`}/>}
                          </DropdownItem>
                        </li>
                      ))}
                  </ul>
                </Dropdown>
              </DropdownWrapper>
          </div>}
      </div>
    );
  }
}

export default LanguageSelector;
