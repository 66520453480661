import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropdown from 'components/shared/form/dropdown';
import moment from 'moment';

class YearPicker extends Component {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any
  };

  onChange = year => {
    const { onChange, value } = this.props;

    if (year === '-') return onChange(null);

    var currentMoment = value ? moment(value) : moment();

    return onChange(currentMoment.year(year).format('YYYY-MM-DDT00:00:01'));
  };

  render() {
    const { min = moment().year() - 2, max = moment().year() + 2, value } = this.props;

    var currentMoment = value ? moment(value) : moment();
    var currentYear = currentMoment.year();

    var years = ['-'].concat(
      Array(max - min + 1)
        .fill(0)
        .map((e, i) => i + min)
    );

    return (
      <Dropdown
        options={years}
        renderOption={option => option}
        selectedOption={value ? currentYear : '-'}
        onSelection={this.onChange}
      />
    );
  }
}

export default YearPicker;
