export const BLOCK = 'translations';

export const htmlParser = {
  boldUnderlinedStart: '<span class="bold-underlined">',
  boldUnderlinedEnd: ' </span>',
  boldStart: '<span class="bold">',
  boldEnd: '</span>'
};

// File selector
export const validFileExtensions = '.docx,.doc,.xlsx,.xls,.pptx,.ppt,.pdf,.txt,.xline';
export const MAX_AMOUNT_OF_FILES = 20;
export const MAX_FILE_SIZE = 26214400; //file size in bytes (25MB = 26214400 bytes )

export const ERROR_KEYS = {
  maxAmount: 'document-translation-notification.max-file-limit',
  maxSize: 'document-translation-notification.max-size-limit',
  fileFormat: 'document-translation-notification.file-extension-limit'
};

export const translateTextMaxCharacters = 1000;

export const UPLOAD_STATES = {
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  UPLOADING: 'UPLOADING'
};

export const TranslationType = ["Adapted-Audit", "Generic"]