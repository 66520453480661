import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as api from 'store/api';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Button from 'components/shared/single-click-button';
import 'react-dropdown/style.css';
import { Link } from 'react-router-dom';
import ProjectDateFormatSelection from 'components/project-creation/shared/project-date-fromat-selection';
import CONSTANTS from 'utils/constants';


function MovePopupConfirmation({ isConfirmationWindowOpen, closeMovePopupConfirmation,
   selectedFolder,to,projectId,validateDateFormatSelection,currentProject }) {
  let foldername = selectedFolder.itemName;
  const [showDateSelectionPopup, changePopupDisplay] = useState(false);

  function closeDateFormatPopUp() {
    changePopupDisplay(false);
   // this.setState({ showDateSelectionPopup: false });
  };
  function handleSuccessCallback(status) {
    if (status === 'success') {
      validateDateFormatSelection();      
    }
  };
  function validateDateFormatSelectionClick(e) {   
    const selectedFolderCategoryID = selectedFolder.templateCategoryID;
    const dateFormatPreference = selectedFolder.dateFormatPreference;
    if ((selectedFolderCategoryID == CONSTANTS.TemplateCategories.DETAIL_TESTING || selectedFolderCategoryID == CONSTANTS.TemplateCategories.NON_EDITABLE_DETAIL_TESTING)&&  (dateFormatPreference == null || dateFormatPreference == '') && ((CONSTANTS.RestrictDateFormatForSpecificContainer.indexOf(currentProject.containerCode) == -1 ))) {
      e.preventDefault();
      changePopupDisplay(true);

    } else {
      validateDateFormatSelection();
    }
  }

  return isConfirmationWindowOpen ? (
    <Modal>
      <div className="modal__content move-popup-confirmation-dialog">
        <div className="move-popup-confirmation-dialog__header">
          <span className="move-popup-confirmation-dialog__header-text">
            <FormattedMessage id="file-browser.move-documents" />
          </span>
          <Button type="button" size="icon" className="modal__close_x icon-button" onClick={closeMovePopupConfirmation}>
            <Icon name="special-cross-black" width={18} height={18} />
          </Button>
        </div>
        <br />
        <div className="move-popup-confirmation-dialog__content">
          <div className="move-popup-confirmation-dialog__content-main">
            <FormattedMessage id="file-browser.move-confirmationtext1" /> &nbsp;
            <span>{foldername}</span> &nbsp;
            <FormattedMessage id="file-browser.move-confirmationtextTemplateFolder" />&nbsp;
            <FormattedMessage id="file-browser.move-confirmationtextProcessed" />
            <span>&nbsp;{foldername}</span> &nbsp;
            <FormattedMessage id="file-browser.move-confirmationtextApplicableDateFormat" />
          </div>
          <br />
          <br />
          <div className="move-popup-confirmation-dialog__delete-buttons">
            <Button className="btn btn-secondary btn-medium" onClick={closeMovePopupConfirmation}>
              <FormattedMessage id="maintenance-message.delete-confirmation-cancel" />
            </Button>
            <Link >
              <Button className="btn btn-primary btn-medium" onClick={validateDateFormatSelectionClick}>
                <FormattedMessage id="file-browser.move-button" />
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <ProjectDateFormatSelection
          showProjectDateFormatSelection={showDateSelectionPopup}
          projectId={projectId}
          closeDateFormatPopUp={closeDateFormatPopUp}
          postSuccessCallback={status => handleSuccessCallback(status)}
          isMoveModel={true}
          currentFolder={selectedFolder}
        />
    </Modal>
  ) : null;
}

export default MovePopupConfirmation;
