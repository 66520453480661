import { connect } from 'react-redux';
import { addNotification } from 'store/notifications';
import { onSearchChange } from 'store/search/search-category';
import QuickStudy from 'components/quick-study/quick-study';

const mapStateToProps = ({ data: { currentUser, search } }) => ({currentUser,
  search
});

const mapDispatchToProps = {
  addNotification,
  onSearchChange
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickStudy);
