import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { debounce } from 'underscore';

import Icon from 'components/shared/icon';
import TagsPopover from 'components/file-browser/toolbar/tags-popover';
import DocumentBreadcrumbs from './breadcrumbs';
import NavigationButtons from './navigation-buttons';
import NavigationDropdown from './navigation-dropdown';
import ReviewButton from 'containers/document-viewer/toolbar/review-button-container';
import UndoButton from './undo-button';
import Constants from 'utils/constants';
import Button from 'components/shared/single-click-button';

import Permissions from 'permissions/permissions';
import {ITEM_STATUSES} from 'models/project-item';

class Toolbar extends Component {
  state = {
    editTags: false
  };

  static propTypes = {
    clearUndoStack: PropTypes.func,
    currentDocument: PropTypes.object,
    currentDocumentLayout: PropTypes.object,
    currentProject: PropTypes.object,
    documentStateErrors: PropTypes.any,
    documentViewerUndo: PropTypes.object,
    getCurrentDocument: PropTypes.func,
    getNextDocument: PropTypes.func,
    getPreviousDocument: PropTypes.func,
    popUndoStack: PropTypes.func,
    readOnly: PropTypes.bool,
    refreshData: PropTypes.func
  };

  toggleEditTags = debounce(() => {
    var { editTags } = this.state;
    this.setState({ editTags: !editTags });
  }, 16);

  render() {
    var {
      clearUndoStack,
      currentDocument,
      documentStateErrors,
      documentViewerUndo,
      getCurrentDocument,
      getNextDocument,
      getPreviousDocument,
      popUndoStack,
      readOnly,
      intl
    } = this.props;

    if (!currentDocument.isLoaded) return null;

    var { editTags } = this.state;

    var { documentId, projectId, reviewers, parentFolderDocumentCount } = currentDocument;

    return (
      <div className="document-viewer-toolbar">
        <div className="toolbar-flex-area">
          <div className="toolbar-group-left">
            <DocumentBreadcrumbs
              currentDocumentName={currentDocument.itemName}
              documentData={currentDocument}
              projectId={projectId}
              dropdown={<NavigationDropdown documentData={currentDocument} disabled={parentFolderDocumentCount <= 1} />}
            />

            <NavigationButtons
              disabled={parentFolderDocumentCount <= 1}
              documentId={documentId}
              getNextDocument={getNextDocument}
              getPreviousDocument={getPreviousDocument}
              projectId={projectId}
            />
          </div>

          <div className="toolbar-group-right">
            {Permissions.Project.Document.canTag() && !readOnly ? (
              <div className="bookmark">
                <UndoButton documentViewerUndo={documentViewerUndo} popUndoStack={popUndoStack} />
                <Button
                  size="icon"
                  className="icon-button"
                  onClick={this.toggleEditTags}
                  title={intl.formatMessage({ id: 'file-browser.toolbar.tags-option' })}              >
               
                    <Icon name="tag" width={20} height={20} />
                 
                </Button>

                {editTags ? (
                  <TagsPopover
                    currentFolder={{ children: [currentDocument] }}
                    onClose={this.toggleEditTags}
                    selectedItems={{ [documentId]: true }}
                    projectId={projectId.toString()}
                    tagsUpdated={() => {}}
                    invalidate={() => {
                      clearUndoStack();
                    }}
                  />
                ) : null}
              </div>
            ) : null}

            <ReviewButton
              reviewers={reviewers}
              refreshData={getCurrentDocument}
              documentId={documentId}
              projectId={projectId}
              readOnly={readOnly || !Permissions.Project.Document.canReview()}
              disabled={
                (documentStateErrors &&
                  documentStateErrors.indexOf(Constants.Document_Error_States.LAYOUT404) !== -1) ||
                (currentDocument && currentDocument.itemStateId !== ITEM_STATUSES.PROCESSED)
              }
              clearUndoStack={clearUndoStack}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Toolbar);
