import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Header from 'containers/header/header';
import NotificationsContainer from 'containers/shared/notifications-container';
import ReportsContainer from 'containers/reports/reports-container';

class ReportsPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    return (
      <div className="page">
        <Header {...this.props} fromReportsPage currentPage="global.subheader.admin-dashboard" />
        <ReportsContainer {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default ReportsPage;
