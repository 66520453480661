import React, { Component, useState ,useEffect} from 'react';

import { FormattedMessage } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import arguslogoImage from 'images/argus_title.svg';

import store from 'store/store';
import Permissions from 'permissions/permissions';

function SubheaderDropdown(props)
{

  const [isMenuOpen, changeMenuState] = useState(false);

  const [isAllowed, changeAllowedFeatures] = useState({});


  function isUserALlowed()
  {
    changeAllowedFeatures(()=>{
      return props.currentUser && props.currentUser.accessibleFeatures ?
      props.currentUser.accessibleFeatures.reduce((obj, item) => {
        return {
          ...obj,
          [item['featureId']]: item
        };
      }, {})
    : {}
    })

  }


  function toggleMenu()
  {
    changeMenuState((prevState) =>{
         return !prevState;
     });

     isUserALlowed();
  }

  function closePopup () {
    changeMenuState(() =>{
      return false;
     });
  }


    return(
        <div className="subheader-menu">
          <OutsideClickHandler
            onOutsideClick={() => {closePopup();}} >
        <div className="icon-container">
          <div className="icon-container_button">
        <Button onClick={toggleMenu}>
             {isMenuOpen && !props.fromUnauthorizedPage ? <Icon name="app-switcher-icon-blue" width={24} /> : <Icon name="app-switcher-icon" width={24} /> }
        </Button>
        </div>
          <div className="icon-container_span">
          <span>{props.currentPage ==="global.subheader.extraction" ? <FormattedMessage id="global.subheader.extraction" />:
                   (props.currentPage ==="global.subheader.translation" ? <FormattedMessage id="global.subheader.translation" /> :
                   (props.currentPage ==="global.subheader.admin-dashboard" ? <FormattedMessage id="global.subheader.admin-dashboard" /> : 
                   (props.currentPage ==="global.subheader.landing-page" ? <FormattedMessage id="global.subheader.landing-page" />: null)))
        }</span>
          </div>
          </div>

          {isMenuOpen ? (
          <div className="subheader-dropdown">
          <ul>
           {isAllowed.hasOwnProperty('0') &&(<li><a className={props.currentPage === "global.subheader.extraction"  ? "subheader-dropdown__selected": null} href='/project-list'><FormattedMessage id="global.subheader.extraction" /></a></li>)}
           {isAllowed.hasOwnProperty('1') && (<li><a className={props.currentPage ==="global.subheader.translation"? "subheader-dropdown__selected": null} href='/translation'><FormattedMessage id="global.subheader.translation" /></a></li>)}
           {Permissions.Global.canViewAdminAccess() && (<li><a className={props.currentPage ==="global.subheader.admin-dashboard"? "subheader-dropdown__selected": null} href='/admin-access'><FormattedMessage id="global.subheader.admin-dashboard" /></a></li>)}
          </ul>
          </div>
          ) :   
         null}
          </OutsideClickHandler>
        </div>
    )

}

export default SubheaderDropdown;