import { connect } from 'react-redux';

import { searchProjectList } from 'store/project-list';
import { onSearchChange } from 'store/search/search-category';
import ProjectListSearch from 'components/search/project-list-search/project-list-search';

const mapStateToProps = ({ data: { search, projectList } }) => ({
  projectList,
  search
});

const mapDispatchToProps = {
  searchProjectList,
  onSearchChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectListSearch);
