import { debounce } from 'underscore';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getClientSearchResults, getEngagementDetails } from 'store/api';
import ValidTextArea from 'components/project-creation/project-details/shared/valid-text-area';
import ClientDropdown from 'components/project-creation/project-details/shared/client-dropdown';

/*
 * Project Client input field
 * Performs checks/validation
 */
export default class ClientInputField extends Component {
  static propTypes = {
    allowEditing: PropTypes.bool,
    checkValid: PropTypes.func,
    intl: intlShape,
    onChange: PropTypes.func,
    project: PropTypes.any,
    projectState: PropTypes.object,
    setClientId: PropTypes.func,
    setClientInfo: PropTypes.func,
    setEngagementInfo: PropTypes.func,
    setEngagementId: PropTypes.func,
  };

  state = {
    clientName: '',
    error: null,
    isDropdownOpen: false,
    isEditing: true,
    isValid: false,
    projectInfo: null,
    searchResults: null,
    selectedId: null
  };

  componentDidMount() {
    const {
      project: { clientName, clientId, engagementId },
      projectState: { INPROGRESS, SETTINGS, ARCHIVE, COPY }
    } = this.props;

    if (INPROGRESS) {
      // Project creation in progress
      this.setState({ clientName });
      this.getEngagementInfo(engagementId);
    } else if (COPY) {
      // Project copy workflow
      if (!engagementId) {
        this.setState({ isValid: false });
      } else {
        this.setState({ clientName });
        this.getEngagementInfo(engagementId);
      }
    } else if (SETTINGS || ARCHIVE) {
      this.setState({ clientName, isEditing: false });
      this.getEngagementInfo(engagementId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkValid } = this.props;
    const { isValid } = this.state;
    if (prevState.isValid !== isValid) {
      checkValid(isValid);
    }
  }

  // Fetch the MAT engagement info
  getEngagementInfo = value => {
    const { setEngagementId } = this.props;

    if (!value) {
      this.setState({ isValid: true });
    }

    return getEngagementDetails(value).then(res => {
      setEngagementId(value);
      this.setState({
        isDropdownOpen: false,
        isEditing: !(this.props.projectState.SETTINGS || this.props.projectState.ARCHIVE),
        isValid: true,
        searchResults: null,
        clientName: res.data.clientName,
        selectedId: res.data.clientId
      });
      return res;
    });
  };

  // Get Client search results when user is typing
  getSearchResults = debounce(() => {
    const { intl } = this.props;
    const { clientName } = this.state;

    if (clientName.length <= 0) {
      return;
    }

    return getClientSearchResults(clientName).then(res => {
      if (res.data.length <= 0) {
        return this.setState({
          searchResults: null,
          isDropdownOpen: false,
          isValid: false,
          error: intl.formatMessage({ id: 'project-details.body.client-error' })
        });
      }
      return this.setState({ searchResults: res.data, isDropdownOpen: true, error: null });
    });
  }, 500);

  onClientClick = value => {
    const { projectState, setClientId, setClientInfo } = this.props;

    setClientId(value.clientId);

    this.setState({
      selectedId: value.clientId,
      searchResults: null,
      clientName: value.clientName,
      isEditing: !(projectState.SETTINGS || projectState.ARCHIVE),
      isValid: true,
      isDropdownOpen: false
    });

    setClientInfo(value);

    return {};
  };

  onEditClick = () => {
    this.setState({ isEditing: true, autoFocus: true });
  };

  onBlur = () => {
    const { clientName, isValid } = this.state;
    const {
      projectState: { SETTINGS },
      project
    } = this.props;
    // Save on click outside
    if (SETTINGS) {
      // If it's the same dont do anything
      if (clientName && clientName.length <= 0) {
        return this.setState({ clientName: project.clientName, isEditing: false });
      }
      if (isValid) {
        return this.setState({ isEditing: false });
      }
    }
  };

  onChange = clientName => {
    const { onChange } = this.props;

    this.setState({ clientName });
    onChange(clientName);

    if (clientName && clientName.length <= 0) {
      this.clearData();
    }
  };

  onKeyDown = e => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      this.clearData();
    }
  };

  clearData = () => {
    const { setClientInfo, setClientId, setEngagementInfo, setEngagementId } = this.props;

    setClientInfo(null);
    setClientId('');
    setEngagementInfo(null);
    setEngagementId('');

    return this.setState({
      isValid: false,
      searchResults: null,
      projectInfo: null,
      isDropdownOpen: false,
      error: null
    });
  };

  render() {
    const { intl, allowEditing } = this.props;
    const { clientName, error, isEditing, isDropdownOpen, searchResults, autoFocus } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <ValidTextArea
          autoFocus={autoFocus}
          errors={error}
          headingId="project-details.body.client"
          isEditing={isEditing}
          isOwner={allowEditing}
          maxLength={80}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onEditClick={this.onEditClick}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.getSearchResults}
          placeholder={intl.formatMessage({ id: 'project-details.body.client-placeholder' })}
          value={clientName}
          isAsteriskRequired = "true"
        />

        {isDropdownOpen && clientName.length > 0 && searchResults ? (
          <ClientDropdown
            data={searchResults}
            keyword={clientName}
            onClickOutside={() => this.setState({ isDropdownOpen: false })}
            onClientClick={this.onClientClick}
          />
        ) : null}
      </div>
    );
  }
}
