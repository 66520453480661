import React, { Component } from 'react';
import Button from 'components/shared/single-click-button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Sorting from 'components/shared/sorting/sorting';
import dateUtil from 'utils/dateUtil';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import EmptyState from 'components/file-browser/file-list/empty-state';
import emptyStateImage from 'images/empty_state_comp_01.svg';
import { getCurrentRegion } from 'store/api';
import { Link } from 'react-router-dom';

const REQUESTS_TABS = {
  PENDING_REQUESTS: 'PendingRequest',
  PENDING_REVIEW: 'PendingReview'
};

const LEARNING_STATUS = {
  NEVER_TRAINED: 0,
  IN_PROGRESS: 1,
  SUCCESS: 2,
  FAILED: 3
};

const TRAINING_STATUS = [
  'training-requests.training-status.never-trained',
  'training-requests.training-status.in-progress',
  'training-requests.training-status.success',
  'training-requests.training-status.failed'
];

class TrainingRequests extends Component {
  static propTypes = {
    loadTrainingRequestList: PropTypes.func,
    projectId: PropTypes.string,
    setTrainingRequestType: PropTypes.func,
    trainingRequest: PropTypes.object,
    currentUser: PropTypes.object
  };

  state = {
    sorting: {
      key: 'name',
      ascending: true
    },
    filtering: {
      key: undefined,
      ids: undefined,
      tagOptions: []
    },
    search: {
      key: undefined,
      query: undefined
    },
    isFiltering: false
  };

  componentDidMount() {
    const { loadTrainingRequestList, trainingRequest } = this.props;
    sessionStorage.setItem('active-trainingrequest-tab', trainingRequest.type);
    loadTrainingRequestList({
      trainingRequestType: trainingRequest.type
    }).then(response => this.setState({ isLoading: false }));
  }

  /////////////////////// SORTING FILTERING FUNCTIONS START ///////////////////////
  setSorting = (key, ascending) => {
    var { sorting } = this.state;
    sorting.key = key;
    sorting.ascending = ascending;
    this.setState({ sorting: sorting });
  };

  setSearch = (key, query) => {
    var { filtering, search } = this.state;
    filtering.key = undefined;
    filtering.ids = undefined;
    search.key = query.length > 0 ? key : undefined;
    search.query = query.length > 0 ? query : undefined;
    this.setState({ filtering: filtering, search: search, isFiltering: true });
  };

  setFiltering = (key, ids) => {
    var { filtering, search } = this.state;
    filtering.key = ids.length > 0 ? key : undefined;
    filtering.ids = ids.length > 0 ? ids : undefined;
    search.key = undefined;
    search.query = undefined;
    this.setState({ filtering: filtering, search: search, isFiltering: true });
  };
  /////////////////////// SORTING FILTERING FUNCTIONS END ///////////////////////

  rowItem = (pendingItem, index) => {
    const {
      extractionFieldId,
      extractionFieldName,
      dateSubmitted,
      dateInitiated,
      learningStatus,
      requestedUserName,
      requestedEmail
    } = pendingItem;
    const { trainingRequest } = this.props;
    return (
      <tr className="training-requests__row" key={index}>
        <td>{extractionFieldId}</td>
        <td className="training-requests__row-link">
          <Link
            className="training-requests__row-link"
            title={extractionFieldName}
            to={`/region/${getCurrentRegion()}/groups/all/extraction-fields/${extractionFieldId}`}
          >
            {extractionFieldName}
          </Link>
        </td>
        <td>{dateUtil.formatDate(dateUtil.fromServerDate(dateSubmitted))}</td>
        <td>{dateInitiated ? dateUtil.formatDate(dateUtil.fromServerDate(dateInitiated)) : '-'}</td>
        <td>
          <div
            className={classNames(
              trainingRequest.type === REQUESTS_TABS.PENDING_REVIEW &&
              learningStatus === LEARNING_STATUS.SUCCESS &&
              'training-requests__row-status-success',
              trainingRequest.type === REQUESTS_TABS.PENDING_REVIEW &&
              learningStatus === LEARNING_STATUS.FAILED &&
              'training-requests__row-status-failed'
            )}
          ></div>
          <FormattedMessage id={TRAINING_STATUS[learningStatus]} />
        </td>
        <td>
          <a className="training-requests__row-link" href={`mailto:${requestedEmail}`}>
            {requestedUserName}
          </a>
        </td>
      </tr>
    );
  };

  onPendingRequestsTabClick = () => {
    const { loadTrainingRequestList, setTrainingRequestType } = this.props;
    setTrainingRequestType(REQUESTS_TABS.PENDING_REQUESTS);
    this.setState({ isLoading: true });
    sessionStorage.setItem('active-trainingrequest-tab', REQUESTS_TABS.PENDING_REQUESTS);
    loadTrainingRequestList({
      trainingRequestType: REQUESTS_TABS.PENDING_REQUESTS
    }).then(response => this.setState({ isLoading: false }));
  };

  onPendingReviewTabClick = () => {
    const { loadTrainingRequestList, setTrainingRequestType } = this.props;
    setTrainingRequestType(REQUESTS_TABS.PENDING_REVIEW);
    this.setState({ isLoading: true });
    sessionStorage.setItem('active-trainingrequest-tab', REQUESTS_TABS.PENDING_REVIEW);
    loadTrainingRequestList({
      trainingRequestType: REQUESTS_TABS.PENDING_REVIEW
    }).then(response => this.setState({ isLoading: false }));
  };


  render() {
    const { sorting } = this.state;
    const { trainingRequest, currentUser } = this.props;

    return (
      <div className="training-requests">
        <div className="training-requests__header-container">
          <h2 className="training-requests__header">
            <FormattedMessage id="training-requests-heading" />
          </h2>
        </div>
        <div className="training-requests__tabs">
          <Button
            className={classNames(
              'training-requests__tab-button',
              trainingRequest.type === REQUESTS_TABS.PENDING_REQUESTS && 'training-requests__tab-button--selected'
            )}
            onClick={this.onPendingRequestsTabClick}
          >
            <FormattedMessage id="training-requests.tabs.pending-requests" />
          </Button>
          <Button
            className={classNames(
              'training-requests__tab-button',
              trainingRequest.type === REQUESTS_TABS.PENDING_REVIEW && 'training-requests__tab-button--selected'
            )}
            onClick={this.onPendingReviewTabClick}
          >
            <FormattedMessage id="training-requests.tabs.pending-review" />
          </Button>
        </div>
        {!trainingRequest.isLoading &&
          trainingRequest.trainingRequests &&
          trainingRequest.trainingRequests.length === 0 ? (
          <div className="training-requests__empty-state">
            <FormattedMessage
              id={
                trainingRequest.type === REQUESTS_TABS.PENDING_REQUESTS
                  ? 'training-requests.request-empty-state.description'
                  : 'training-requests.review-empty-state.description'
              }
            >
              {description => (
                <EmptyState
                  title={<FormattedMessage id="training-requests.empty.title" />}
                  description={description}
                  img={emptyStateImage}
                />
              )}
            </FormattedMessage>
          </div>
        ) : (
          <div className="training-requests__container">
            <table className="training-requests__compare-table">
              <thead>
                <tr className="taining-requests__headerRow">
                  <th>
                    <FormattedMessage id="training-requests.table-heading.id" />
                  </th>
                  <th>
                    <FormattedMessage id="training-requests.table-heading.name" />
                    <Sorting
                      sortFunction={ascending => this.setSorting('basename', ascending)}
                      sortDirection={sorting.key === 'basename' ? sorting.ascending : undefined}
                      filterFunction={ids => this.setFiltering('basename', ids)}
                    />
                  </th>
                  <th>
                    <FormattedMessage id="training-requests.table-heading.date-submitted" />
                    <Sorting
                      sortFunction={ascending => this.setSorting('basename', ascending)}
                      sortDirection={sorting.key === 'basename' ? sorting.ascending : undefined}
                      filterFunction={ids => this.setFiltering('basename', ids)}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={
                        trainingRequest.type === REQUESTS_TABS.PENDING_REQUESTS
                          ? 'training-requets.table-heading.last-training-date'
                          : 'training-requets.table-heading.date-completed'
                      }
                    />
                  </th>
                  <th className="training-requets__right-align-cell">
                    <FormattedMessage id="training-requets.table-heading.last-training-status" />
                  </th>
                  <th>
                    <FormattedMessage id="training-requets.table-heading.requestor-name" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {trainingRequest.isLoading ? (
                  <Icon className="spinner spinner--centered training-requests__loading" name="loader" width={80} />
                ) : (
                  trainingRequest.trainingRequests &&
                  trainingRequest.trainingRequests.map((item, index) => this.rowItem(item, index))
                )}
              </tbody>
            </table>

          </div>
        )}
      </div>
    );
  }
}

export default TrainingRequests;
