import { connect } from 'react-redux';

import { getDocumentResults } from 'store/search/search-results';
import { onSelectCategory } from 'store/search/search-category';

import AnalyticsChart from 'components/analytics/analytics-chart';

const mapStateToProps = ({ data: { currentProject, search } }) => ({
  currentProject,
  search
});

const mapDispatchToProps = { getDocumentResults, onSelectCategory };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsChart);
