import track from './track';
import updateDataLayer from './data-layer';
import TRACKING_ACTIONS from './actions';
import { statusTrack } from './utils';

/** A redux middleware component to see if we're tracking that action
 * and send to Adobe for tracking
 */
let DEFER = [];

export const AnalyticsMiddleware = store => next => action => {
  const returnValue = next(action);
  const state = store.getState();

  // Don't track un authenticated routes
  if (action.type === '@@router/LOCATION_CHANGE' && action.payload.location.pathname.includes('authsuccess')) {
    return returnValue;
  }

  // Always wait until user data is loaded, before tracking, since we need to send user info for every track
  if (!state.data.currentUser.isLoaded) {
    if (TRACKING_ACTIONS[action.type]) {
      DEFER.push({ type: action.type, payload: action.payload });
    }
    return returnValue;
  }

  if (DEFER.length > 0) {
    DEFER.forEach(event => {
      updateDataLayer(event.type, event.payload, state);
      track(event.type);
    });
    DEFER = [];
  }

  if (action.type === 'argus/ui/CURRENT_FOLDER_LOADED') {
    return statusTrack.initialize(action.payload.data, state);
  }

  if (TRACKING_ACTIONS[action.type]) {
    if (action.type === 'argus/ui/ITEMS_UPDATED') {
      // Don't track unrelated to document status item updates
      if (!action.payload[0].itemStateId) {
        return returnValue;
      }
      return statusTrack.checkStatus(action);
    }

    updateDataLayer(action.type, action.payload, state);
    track(action.type);
  }

  return returnValue;
};
