import { connect } from 'react-redux';

import {
  addProjectTemplate,
  changeTemplateName,
  clearTemplatesData,
  deleteExtraction,
  deleteProjectTemplate,
  duplicateProjectTemplate,
  loadTemplateExtraction,
  loadAllExtraction,
  loadProjectTemplates,
  moveExtraction,
  selectProjectTemplate,
  updateAllExtractions,
  updateProjectTemplate
} from 'store/edit-extractions';
import EditExtractions from 'components/edit-extractions/edit-extractions';
import { loadTypes } from 'store/project-creation/template-types';

const mapStateToProps = ({ data: { editExtractions, templateTypes, currentFolder  } }) => ({
  editExtractions,
  templateTypes,
  currentFolder
});

const mapDispatchToProps = {
  addProjectTemplate,
  changeTemplateName,
  clearTemplatesData,
  deleteExtraction,
  deleteProjectTemplate,
  duplicateProjectTemplate,
  loadTemplateExtraction,
  loadAllExtraction,
  loadProjectTemplates,
  moveExtraction,
  selectProjectTemplate,
  updateAllExtractions,
  updateProjectTemplate,
  loadTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(EditExtractions);
