import { connect } from 'react-redux';

import {
  loadReports,
  loadScheduledReports,
  editReport,
  createReport,
  deleteReport,
  generateReport
} from 'store/reports';
import Reports from 'components/reports/reports';

const mapStateToProps = ({ data: { reports , currentUser} }) => ({
  reports,
  currentUser
  
});

const mapDispatchToProps = {
  loadReports,
  loadScheduledReports,
  editReport,
  createReport,
  deleteReport,
  generateReport
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
