import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'underscore';
import SearchInputField from 'containers/search/search-inputfield-container';
import FileListSearch from 'containers/search/file-list-search/file-list-search-container';

/** Analytics page search bar */
class AnalyticsSearch extends Component {
  static propTypes = {
    fileListSearchProps: PropTypes.object,

    inputDisabled: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onSearchChange: PropTypes.func,
    search: PropTypes.object
  };

  componentDidMount() {
    if (this.props.search.query.length > 0) {
      this.props.onSearchChange('');
    }
  }

  onKeyUp = debounce(() => {
    this.onEnter();
  }, 500);

  onEnter = () => {
    this.props.onEnter();
  };

  render() {
    const { location, inputDisabled, onExit, fileListSearchProps } = this.props;

    return (
      <React.Fragment>
        <SearchInputField
          inputDisabled={inputDisabled}
          location={location}
          onEnter={this.onEnter}
          onExit={onExit}
          onKeyUp={this.onKeyUp}
        />
        <FileListSearch {...fileListSearchProps} className="hidden-search" />
      </React.Fragment>
    );
  }
}

export default AnalyticsSearch;
