import { FormattedMessage, FormattedNumber } from 'react-intl';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import { isNumeric } from 'utils/number-utils';
import { revertHistoryModel } from 'store/api';
import Button from 'components/shared/single-click-button';
import dateUtil from 'utils/dateUtil';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';

export const TRAINING_STATES = {
  PROCESSING: 1,
  COMPLETED: 2,
  ERROR: 3
};

const TRAINING_INSTANCE_STATE_IDS = {
  SUCCESS: 2,
  SINGLE_CHAR: 3,
  CONFLICT: 4
};

const ERROR_NAMES = {
  [TRAINING_INSTANCE_STATE_IDS.SINGLE_CHAR]: 'quick-study.extraction-field-details.history-tab.error-modal.single-char',
  [TRAINING_INSTANCE_STATE_IDS.CONFLICT]: 'quick-study.extraction-field-details.history-tab.error-modal.duplicate'
};

const TRAINING_STATE_NAMES = {
  [TRAINING_STATES.PROCESSING]: 'quick-study.extraction-field-details.history-tab.state.processing',
  [TRAINING_STATES.COMPLETED]: 'quick-study.extraction-field-details.history-tab.state.completed',
  [TRAINING_STATES.ERROR]: 'quick-study.extraction-field-details.history-tab.state.error'
};

const TRAINING_STATE_CLASS_NAMES = {
  [TRAINING_STATES.PROCESSING]: 'extraction-details-history__training-state--processing',
  [TRAINING_STATES.COMPLETED]: 'extraction-details-history__training-state--completed',
  [TRAINING_STATES.ERROR]: 'extraction-details-history__training-state--error'
};

/**
 * A single row of the history table
 */
class HistoryItem extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    onErrorIconClicked: PropTypes.func.isRequired,
    onRevertButtonClicked: PropTypes.func.isRequired,
    training: PropTypes.object,
    trainingInProgress: PropTypes.bool
  };

  /**
   * Handle a click on the revert icon
   */
  onRevertButtonClicked = () => {
    const { onRevertButtonClicked, training } = this.props;
    onRevertButtonClicked(training);
  };

  /**
   * Handle a click on the error icon
   */
  onErrorIconClicked = () => {
    const { onErrorIconClicked, training } = this.props;
    onErrorIconClicked(training);
  };

  render() {
    const { field, training, trainingInProgress } = this.props;

    return (
      <tr>
        <td className="data-table__td">
          {training.trainedDate && (
            <span>{dateUtil.formatDateTime(dateUtil.fromServerDate(training.trainedDate))} - </span>
          )}
          <span
            className={classNames(
              'extraction-details-history__training-state',
              TRAINING_STATE_CLASS_NAMES[training.trainingStateId]
            )}
          >
            <FormattedMessage id={TRAINING_STATE_NAMES[training.trainingStateId]} />
          </span>
        </td>
        {/* {!training.precision && !training.recall && !training.fScore ? (
          <td className="data-table__td " colSpan="3">
            <div className="extraction-details-history__too-few">
              <Icon name="special-error" width={16} />
              <FormattedMessage
                id="quick-study.extraction-field-details.history-tab.too-few"
                values={{ num: <FormattedNumber value={field.numberOfDocuments} /> }}
              />
            </div>
          </td>
        ) : ( */}
        <Fragment>
          <td className="data-table__td data-table__td--numeric">
            {isNumeric(training.precision) ? (
              <FormattedNumber value={training.precision} maximumFractionDigits={2} />
            ) : (
              '-'
            )}
          </td>
          <td className="data-table__td data-table__td--numeric">
            {isNumeric(training.recall) ? <FormattedNumber value={training.recall} maximumFractionDigits={2} /> : '-'}
          </td>
          <td className="data-table__td data-table__td--numeric">
            {isNumeric(training.fScore) ? <FormattedNumber value={training.fScore} maximumFractionDigits={2} /> : '-'}
          </td>
        </Fragment>
        {/* )} */}
        <td className="data-table__td data-table__td--numeric extraction-details-history__td--actions">
          {training.trainingInstances &&
            training.trainingInstances.some?.(({ trainingInstanceStateId }) => trainingInstanceStateId === 4) && (
              <Button
                size="icon"
                className="extraction-details-error-button"
                onClick={this.onErrorIconClicked}
                data-tip
                data-for={`extraction-details-error-button-${training.extractionFieldTrainingDetailId}`}
              >
                <Icon name="special-error-triangle" width={28} />
                <ReactTooltip
                  id={`extraction-details-error-button-${training.extractionFieldTrainingDetailId}`}
                  effect="solid"
                  place="left"
                >
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.error-tooltip" />
                </ReactTooltip>
              </Button>
            )}

          {field.externalExtractionFieldId !== training.externalExtractionFieldId &&
            training.trainingStateId === TRAINING_STATES.COMPLETED &&
            !trainingInProgress && (
              <Button
                size="icon"
                className="extraction-details-history__revert-button"
                onClick={this.onRevertButtonClicked}
                data-tip
                data-for={`extraction-details-history__revert-button-${training.extractionFieldTrainingDetailId}`}
              >
                <Icon name="special-backup" width={16} />
                <ReactTooltip
                  id={`extraction-details-history__revert-button-${training.extractionFieldTrainingDetailId}`}
                  effect="solid"
                  place="left"
                >
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.revert-tooltip" />
                </ReactTooltip>
              </Button>
            )}

          {field.externalExtractionFieldId !== training.externalExtractionFieldId &&
            training.trainingStateId === TRAINING_STATES.COMPLETED &&
            trainingInProgress && (
              <Button
                size="icon"
                className="extraction-details-history__revert-button"
                data-tip
                data-for={`extraction-details-history__revert-button-${training.extractionFieldTrainingDetailId}`}
              >
                <Icon name="backup" width={18} />
              </Button>
            )}
        </td>
      </tr>
    );
  }
}

/**
 * Main component of the history tab of the extraction field details page
 */
class History extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    training: PropTypes.array
  };

  state = {
    selectedTrainingForRevert: null,
    selectedTrainingForErrorInfo: null
  };

  /**
   * Handle a click on a revert button (show the revert modal)
   */
  onRevertButtonClicked = selectedTrainingForRevert => {
    this.setState({ selectedTrainingForRevert });
  };

  /**
   * Handle a click on an error icon (show the error info modal)
   */
  onErrorIconClicked = selectedTrainingForErrorInfo => {
    this.setState({ selectedTrainingForErrorInfo });
  };

  /**
   * Close the revert modal
   */
  cancelRevert = () => {
    this.setState({ selectedTrainingForRevert: null });
  };

  /**
   * Close the error info modal
   */
  closeErrorInfoModal = () => {
    this.setState({ selectedTrainingForErrorInfo: null });
  };

  /**
   * Submit a request to revert the training model
   */
  submitRevert = () => {
    const { selectedTrainingForRevert } = this.state;
    this.setState({ selectedTrainingForRevert: null });

    return revertHistoryModel({
      extractionFieldId: selectedTrainingForRevert.extractionFieldId,
      trainingDetailId: selectedTrainingForRevert.extractionFieldTrainingDetailId
    });
  };

  render() {
    const { field, training } = this.props;
    const { selectedTrainingForRevert, selectedTrainingForErrorInfo } = this.state;

    if (!Array.isArray(training)) {
      return (
        <div className="extraction-details__tab-content-section extraction-details-history">
          <Icon className="spinner" name="loader" width={80} />
        </div>
      );
    }

    // Split the training items into temporary and permanent since they are displayed in separate tables
    const temporaryTraining = training.filter(training => !training.isPermanent);
    const permanentTraining = training.filter(training => training.isPermanent);
    const trainingInProgress = training.some(t => t.trainingStateId === TRAINING_STATES.PROCESSING);

    const temporaryTrainingTable = (
      <div className="extraction-details-history__table-container">
        <FixedTable>
          <table className="extraction-details-history__table data-table">
            <thead>
              <tr>
                <FixedHeader className="data-table__th extraction-details-history__th--date">
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.training-date" />
                </FixedHeader>
                <FixedHeader className="data-table__th data-table__th--numeric">
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.precision" />
                </FixedHeader>
                <FixedHeader className="data-table__th data-table__th--numeric">
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.recall" />
                </FixedHeader>
                <FixedHeader className="data-table__th data-table__th--numeric">
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.f-score" />
                </FixedHeader>
                <FixedHeader className="data-table__th data-table__th--numeric" />
              </tr>
            </thead>
            <tbody>
              {temporaryTraining.map(training => (
                <HistoryItem
                  field={field}
                  training={training}
                  trainingInProgress={trainingInProgress}
                  key={training.extractionFieldTrainingDetailId}
                  onRevertButtonClicked={this.onRevertButtonClicked}
                  onErrorIconClicked={this.onErrorIconClicked}
                />
              ))}
            </tbody>
          </table>
        </FixedTable>
      </div>
    );

    const permanentTrainingTable = (
      <div className="extraction-details-history__table-container">
        <FixedTable>
          <table className="extraction-details-history__table data-table">
            <thead>
              <tr>
                <FixedHeader className="data-table__th extraction-details-history__th--date">
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.permanent-training-date" />
                </FixedHeader>
                <FixedHeader className="data-table__th data-table__th--numeric">
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.precision" />
                </FixedHeader>
                <FixedHeader className="data-table__th data-table__th--numeric">
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.recall" />
                </FixedHeader>
                <FixedHeader className="data-table__th data-table__th--numeric">
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.f-score" />
                </FixedHeader>
                <FixedHeader className="data-table__th data-table__th--numeric" />
              </tr>
            </thead>
            <tbody>
              {permanentTraining.map(training => (
                <HistoryItem
                  field={field}
                  training={training}
                  trainingInProgress={trainingInProgress}
                  key={training.extractionFieldTrainingDetailId}
                  onRevertButtonClicked={this.onRevertButtonClicked}
                  onErrorIconClicked={this.onErrorIconClicked}
                />
              ))}
            </tbody>
          </table>
        </FixedTable>
      </div>
    );

    return (
      <div className="extraction-details__tab-content-section extraction-details-history">
        {temporaryTraining.length ? temporaryTrainingTable : null}
        {permanentTraining.length ? permanentTrainingTable : null}
        {/* Revert modal */}
        {selectedTrainingForRevert && (
          <Modal>
            <div className="modal__content modal__content--small">
              <h3>
                <FormattedMessage id="quick-study.extraction-field-details.history-tab.revert-modal-title" />
              </h3>
              <p>
                <FormattedMessage
                  id="quick-study.extraction-field-details.history-tab.revert-modal-description"
                  values={{
                    date: dateUtil.formatDateTime(dateUtil.fromServerDate(selectedTrainingForRevert.trainedDate))
                  }}
                />
              </p>
              <div className="modal__footer">
                <Button
                  className="extraction-details-history__revert-cancel btn btn-secondary"
                  onClick={this.cancelRevert}
                >
                  <FormattedMessage id="common.cancel" />
                </Button>
                <Button
                  className="extraction-details-history__revert-submit btn btn-primary"
                  onClick={this.submitRevert}
                >
                  <FormattedMessage id="quick-study.extraction-field-details.history-tab.revert-modal-button" />
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {/* Error info modal */}
        {selectedTrainingForErrorInfo && (
          <Modal>
            <div className="modal__content extraction-details-history__error-modal-content">
              <h3>
                <FormattedMessage id="quick-study.extraction-field-details.history-tab.error-modal-title" />
              </h3>
              <p>
                <FormattedMessage id="quick-study.extraction-field-details.history-tab.error-modal-description" />
              </p>
              <div className="extraction-details-history__error-table-container">
                <FixedTable>
                  <table className="extraction-details-history__error-table data-table">
                    <thead>
                      <tr>
                        <FixedHeader className="data-table__th">
                          <FormattedMessage id="quick-study.extraction-field-details.history-tab.error-modal.header.error-type" />
                        </FixedHeader>
                        <FixedHeader className="data-table__th data-table__th--numeric">
                          <FormattedMessage id="quick-study.extraction-field-details.history-tab.error-modal.header.extraction-record" />
                        </FixedHeader>
                        <FixedHeader className="data-table__th data-table__th--numeric">
                          <FormattedMessage id="quick-study.extraction-field-details.history-tab.error-modal.header.document-id" />
                        </FixedHeader>
                        <FixedHeader className="data-table__th">
                          <FormattedMessage id="quick-study.extraction-field-details.history-tab.error-modal.header.document-name" />
                        </FixedHeader>
                        <FixedHeader className="data-table__th data-table__th--numeric">
                          <FormattedMessage id="quick-study.extraction-field-details.history-tab.error-modal.header.project-id" />
                        </FixedHeader>
                        <FixedHeader className="data-table__th">
                          <FormattedMessage id="quick-study.extraction-field-details.history-tab.error-modal.header.project-name" />
                        </FixedHeader>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedTrainingForErrorInfo.trainingInstances
                        .filter(
                          instance =>
                            instance.trainingInstanceStateId === TRAINING_INSTANCE_STATE_IDS.SINGLE_CHAR ||
                            instance.trainingInstanceStateId === TRAINING_INSTANCE_STATE_IDS.CONFLICT
                        )
                        .map((instance, index) => (
                          <tr key={index}>
                            <td className="data-table__td">
                              <FormattedMessage id={ERROR_NAMES[instance.trainingInstanceStateId]} />
                            </td>
                            <td className="data-table__td data-table__td--numeric">
                              {instance.documentExtractionFieldInstanceId}
                            </td>
                            <td className="data-table__td data-table__td--numeric">{instance.projectItemId}</td>
                            <td className="data-table__td" title={instance.name}>
                              {instance.name}
                            </td>
                            <td className="data-table__td data-table__td--numeric">{instance.projectId}</td>
                            <td className="data-table__td" title={instance.projectName}>
                              {instance.projectName}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </FixedTable>
              </div>
              <div className="modal__footer">
                <Button
                  className="extraction-details-history__revert-cancel btn btn-secondary"
                  onClick={this.closeErrorInfoModal}
                >
                  <FormattedMessage id="common.okay" />
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default History;
