import model from 'models/base-model';

import { getTransformedValues } from 'utils/data-utils';
import { flattenAddExtractionData } from 'components/extractions/helpers/add-extraction';
import { processTemplateExtractionFields } from 'components/extractions/helpers/extraction-picker';
import dateUtil from 'utils/dateUtil';

export class EditExtractions extends model({
  isAllExtLoading: false,
  isTemplateExtLoading: false,
  isTemplatesLoading: false,
  projectId: null,
  selectedId: null,
  selectedName: '',
  selectedTemplateCategoryId: null,
  templates: [],
  allExtractions: [],
  templateExtractions: [],
  untitled: 'Enter the name of the template', // Fallback for when there is no untitled intl prop
  templateSuffix: 'Template' // Fallback for when there is no untitled intl prop
}) {
  /* LOADERS */
  setTemplatesLoading() {
    return this.merge({
      isTemplatesLoading: true,
      error: null
    });
  }

  setAllExtLoading() {
    return this.merge({
      isAllExtLoading: true,
      error: null
    });
  }

  setTemplateExtLoading() {
    return this.merge({
      isTemplateExtLoading: true,
      error: null
    });
  }

  /* EXTRACTIONS LOADED */
  setAllExtLoaded(state, action) {
    return this.merge({
      ...state,
      isAllExtLoading: false,
      allExtractions: flattenAddExtractionData(
        action.payload,
        state.templateExtractions.length > 0 ? state.templateExtractions.map(v => v.id) : []
      )
    });
  }

  // When template specific extractions have loaded
  setTemplateExtLoaded(state, action) {
    return this.merge({
      ...state,
      isTemplateExtLoading: false,
      templateExtractions: processTemplateExtractionFields(action.payload.templates[0], action.payload.groups)
    });
  }

  // When a user moves the position of extractions
  setExtMoved(state, action) {
    return this.merge({
      ...state,
      templateExtractions: action.payload
    });
  }

  // If user has deleted the extractions
  setExtDeleted(state, action) {
    if (state.allExtractions.length > 0) {
      const deletedId = state.allExtractions
        .map((e, i) => (e.id === action.payload.extractionFieldId ? i : ''))
        .filter(String);
      // We have to update the group view so it now becomes enabled
      if (deletedId.length > 0) {
        deletedId.forEach(index => (state.allExtractions[index].disabled = false));
      }
    }

    return this.merge({
      ...state,
      isTemplateExtLoading: false,
      error: null,
      templateExtractions: state.templateExtractions.filter(v => v.id !== action.payload.extractionFieldId),
      allExtractions: state.allExtractions ?? []
    });
  }

  cleanExtraction(data) {
    // If it's already clean, no need to re-clean
    if (data.trainingTarget) {
      return data;
    }
    return {
      id: data.extractionFieldId,
      group:
        data.groups.length > 0
          ? (data.extractionFieldGroupId
              ? data.groups.find(gr => gr.extractionFieldGroupId === data.extractionFieldGroupId)
              : data.groups[0]
            ).extractionFieldGroupName
          : null,
      groupId:
        data.groups.length > 0
          ? (data.extractionFieldGroupId
              ? data.groups.find(gr => gr.extractionFieldGroupId === data.extractionFieldGroupId)
              : data.groups[0]
            ).extractionFieldGroupId
          : 0,
      name: data.extractionFieldName,
      description: data.extractionFieldDescription,
      color: data.colorIndex,
      trainingCount: data.reviewDocumentCount,
      trainingTarget: data.minDocumentsToBeConsideredTrained,
      isTrained: data.isTrained,
      AccessTypeId: data.accessTypeId,
      isAutomaticExtraction: data.isAutomaticExtraction
    };
  }

  // Update the all extractions when user clicks on adding a field
  setExtAllUpdate(state, action) {
    const data = getTransformedValues([...state.allExtractions], action.payload, 'id', this.cleanExtraction);

    return this.merge({
      ...state,
      allExtractions: data.original ?? [],
      templateExtractions: state.templateExtractions.concat(data.addedElement)
    });
  }

  // When a user adds an extraction => this is needed to improve performance when user is adding
  // A lot of extractions at once
  setExtAdded(state, action) {
    const data = getTransformedValues([...state.allExtractions], action.payload.data, 'id', this.cleanExtraction);

    return this.merge({
      ...state,
      templateExtractions: state.templateExtractions.concat(data.addedElement),
      allExtractions: data.original ?? []
    });
  }

  /* PROJECT TEMPLATES */
  setTempSelected(state, action) {
    return this.merge({
      ...state,
      selectedId: action.payload.projectTemplateId,
      selectedName: action.payload.name,
      selectedTemplateCategoryId: action.payload.templateCategoryId
    });
  }

  // Handle adding or updating a template
  setAddedOrUpdated(state, action) {
    const id = action.payload.templateId || action.payload.projectTemplateId;
    const name = action.payload.projectTemplateName || action.payload.name;

    const updatedIndex = state.templates.findIndex(template => template.projectTemplateId === id);
    
    if (updatedIndex >= 0) {
      state.templates[updatedIndex].name = name || action.payload.templateName;
      return this.merge({
        selectedName: name || action.payload.templateName,
        selectedTemplateCategoryId: action.payload.templateCategoryId
          ? action.payload.templateCategoryId
          : state.selectedTemplateCategoryId,
        selectedId: id,
        templates: [...state.templates]
      });
    }

    return this.merge({
      ...state,
      selectedId: id,
      selectedName: name,
      selectedTemplateCategoryId: action.payload.templateCategoryId
        ? action.payload.templateCategoryId
        : state.selectedTemplateCategoryId,
      templates: state.templates.concat({
        name: name,
        projectTemplateId: action.payload.projectTemplateId
      })
    });
  }

  // When a user duplicates a template
  setDuplicated(state, action) {
    return this.merge({
      ...state,
      selectedId: action.payload.projectTemplateId,
      selectedName: action.payload.name,
      selectedTemplateCategoryId: action.payload.templateCategoryId
    });
  }

  // When a user deletes a selected template
  setDeleted(state, action) {
    const filtered = state.templates.filter(v => v.projectTemplateId !== action.payload.projectTemplateId);
    if (state.selectedId === action.payload.projectTemplateId) {
      return this.merge({
        ...state,
        isTemplatesLoading: false,
        error: null,
        templates: filtered,
        selectedId: filtered.length > 0 ? filtered[0].projectTemplateId : null,
        selectedName: filtered.length > 0 ? filtered[0].name : null,
        selectedTemplateCategoryId: filtered.length > 0 ? filtered[0].templateCategoryId : null
      });
    } else {
      return this.merge({
        ...state,
        isTemplatesLoading: false,
        error: null,
        templates: filtered
      });
    }
  }

  // When a user changes a template name
  setChangedName(state, action) {
    return this.merge({
      ...state,
      selectedName: action.payload
    });
  }

  // Get data returned from the API
  setTemplatesLoaded(state, action) {
    const { data, defaultTemplate } = action.payload;

    let selectedTemplate = {
      projectTemplateId: null,
      name: null,
      templateCategoryId: null
    };

    if (data && data.length > 0) {
      selectedTemplate =
        data.find(({ projectTemplateId }) => projectTemplateId === defaultTemplate) ?? data[data.length - 1];
    }

    return this.merge({
      ...state,
      isTemplatesLoading: false,
      selectedId: selectedTemplate.projectTemplateId,
      selectedName: selectedTemplate.name,
      selectedTemplateCategoryId: selectedTemplate.templateCategoryId,
      templates: data && data.length > 0 ? data.map(templates => Templates.fromData(templates)) : []
    });
  }
}

export class Templates extends model({
  isLoading: false,
  error: null,
  lastUpdatedDate: null,
  lastUpdatedBy: null,
  createdDate: null,
  createdBy: null
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }

  setError(error) {
    return this.merge({
      isLoading: false,
      error: error
    });
  }

  // Get data returned from the API
  setLoaded(templates) {
    return Templates.fromData(templates);
  }

  static fromData(model) {
    return new Templates({
      ...model,
      isLoading: false,
      error: null,
      createdDate: dateUtil.fromServerDate(model.createdDate)
    });
  }
}
