import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'underscore';
import classNames from 'classnames';

// A button component that basically makes sure to wrap the onclick fn in
// a debounced one so it doesn't send multiple requests
export default class Button extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string
  };

  // Debounce function
  debouncedOnClick = e => {
    this.props.onClick && this.props.onClick(e);
  };

  onClick = debounce(e => this.debouncedOnClick(e), 300, { leading: true, trailing: false });

  buttonSize = () => {
    const { size } = this.props;

    if (size) {
      if (size === 'xsmall') {
        return 'btn-xsmall';
      }
      if (size === 'small') {
        return 'btn-small';
      }
      if (size === 'medium') {
        return 'btn-medium';
      }
      if (size === 'large') {
        return 'btn-large';
      }
      if (size === 'xlarge') {
        return 'btn-xlarge';
      }
      if (size === 'icon') {
        return 'btn-nopadding';
      }
    }

    return 'btn-medium';
  };

  render() {
    const buttonSize = this.buttonSize();

    return (
      <button {...this.props} className={classNames(this.props.className, buttonSize)} onClick={e => this.onClick(e)} />
    );
  }
}
