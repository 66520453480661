import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

import GlobalExtractionField from 'permissions/sections/global-extraction-field';
import GlobalUser from 'permissions/sections/global-user';

const GlobalPermissions = {
  ExtractionField: GlobalExtractionField,
  User: GlobalUser,

  canViewProjects: () => {
    return true;
  },

  canViewLogs: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_SYSTEM);
  },

  canViewTrainingRequests: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL);
  },

  canViewReports: () => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canViewAdminAccess: () => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) ||
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_SYSTEM)
    );
  },

  canToggleAdmin: () => {
    return (
      RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canViewAdminContainerDropdown: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) 
  },

  canViewGenAIBannerMessage: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_GENERAL) 
  },

  canHaveGlobalAdminAccess: () => {
    return RolesHelper.doesUserHaveRegionalRole('home', constants.UserRoles.ADMIN_SYSTEM);
  }
};

export default GlobalPermissions;
