import { connect } from 'react-redux';

import { assignTemplates } from 'store/current-folder';
import TemplatesPopover from 'components/file-browser/toolbar/templates-popover';

const mapStateToProps = ({ ui: { fileBrowserSelectedItems } }) => ({
  selectedItems: fileBrowserSelectedItems
});

const mapDispatchToProps = {
  assignTemplates
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesPopover);
