import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { importExtractionFieldModel } from 'store/api';
import ExtractionFieldBrowser from 'components/quick-study/extraction-field-browser/extraction-field-browser';
import GroupsBrowser from 'components/quick-study/groups-browser/groups-browser';
import socket, { rooms } from 'utils/socket';

const IMPORT_STATUSES = {
  SUCCESS: 0,
  FAILED: 1,
  PARTIAL: 2
};

/**
 * Quick Study
 * Top level component for the extraction field and groups admin pages
 * Relevant state is handled by the GroupsBrowser, ExtractionFieldBrowser and ExtractionFieldDetails pages
 */
export default class QuickStudy extends Component {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    extractionFieldId: PropTypes.string,
    groupId: PropTypes.string,
    onSearchChange: PropTypes.func
  };

  state = {
    searchView: false
  };

  isPendingImport = false;

  componentDidMount() {
    const { currentUser } = this.props;
    socket.on('extractionfield.import', this.onFieldsImported);
    socket.join(rooms.extractionFieldImports(currentUser.userId));
  }

  componentWillUnmount() {
    const { currentUser } = this.props;
    socket.off('extractionfield.import', this.onFieldsImported);
    socket.leave(rooms.extractionFieldImports(currentUser.userId));
  }

  /**
   * Handle field import success and error events
   */
  onFieldsImported = msg => {
    const { currentUser, addNotification } = this.props;
    if (msg.room !== rooms.extractionFieldImports(currentUser.userId)) {
      return;
    }

    // If we did not initiate an import, then ignore the message
    if (!this.isPendingImport) {
      return;
    }

    this.isPendingImport = false;

    if (msg.data.status !== IMPORT_STATUSES.SUCCESS) {
      addNotification({
        intlKey: 'quick-study.toolbar.import.error-message',
        clearOnPageChange: true
      });
    } else {
      addNotification({
        intlKey: 'quick-study.toolbar.import.success-message',
        clearOnPageChange: true
      });
    }
  };

  submitImport = data => {
    this.isPendingImport = true;
    return importExtractionFieldModel(data);
  };

  render() {
    const { currentUser, groupId, extractionFieldId } = this.props;
    const { searchView } = this.state;

    const condensed = !!extractionFieldId;

    return (
      <Fragment>
        {groupId ? (
          <ExtractionFieldBrowser
            className={classNames('quick-study__browser', condensed && 'quick-study__browser--condensed')}
            condensed={condensed}
            currentUser={currentUser}
            extractionFieldId={extractionFieldId}
            groupId={groupId}
            searchView={searchView}
            submitImport={this.submitImport}
            {...this.props}
          />
        ) : (
          <GroupsBrowser
            getSearch={bool => this.setState({ searchView: bool })}
            searchView={searchView}
            submitImport={this.submitImport}
            {...this.props}
          />
        )}
      </Fragment>
    );
  }
}
