import { connect } from 'react-redux';

import ExtractionRecordTags from 'components/extractions/extraction-record-tags/extraction-record-tags';

import { removeExtractionFieldRecordTag } from 'store/current-document';

const mapDispatchToProps = {
  removeExtractionFieldRecordTag
};

export default connect(null, mapDispatchToProps)(ExtractionRecordTags);
