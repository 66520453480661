import { createAction, createReducer } from 'utils/redux-utils';
import * as api from 'store/api';
import Reports from 'models/reports';

export const ACTIONS = {
  REGULAR_REPORTS_LOADING: 'argus/ui/REGULAR_REPORTS_LOADING',
  REGULAR_REPORTS_LOADED: 'argus/ui/REGULAR_REPORTS_LOADED',
  SCHEDULED_REPORTS_LOADING: 'argus/ui/SCHEDULED_REPORTS_LOADING',
  SCHEDULED_REPORTS_LOADED: 'argus/ui/SCHEDULED_REPORTS_LOADED',
  REPORTS_ERROR: 'argus/ui/REPORTS_ERROR'
};

export const regularReportsLoading = createAction(ACTIONS.REGULAR_REPORTS_LOADING);
export const regularReportsLoaded = createAction(ACTIONS.REGULAR_REPORTS_LOADED);
export const scheduledReportsLoading = createAction(ACTIONS.SCHEDULED_REPORTS_LOADING);
export const scheduledReportsLoaded = createAction(ACTIONS.SCHEDULED_REPORTS_LOADED);
export const reportsError = createAction(ACTIONS.REPORTS_ERROR);

export const loadReports = () => dispatch => {
  dispatch(regularReportsLoading());
  return api
    .getReports()
    .then(res => {
      dispatch(regularReportsLoaded(res.data));
    })
    .catch(err => {
      dispatch(reportsError(err));
    });
};

export const loadScheduledReports = () => dispatch => {
  dispatch(scheduledReportsLoading());
  return api
    .getScheduledReports()
    .then(res => {
      dispatch(scheduledReportsLoaded(res.data));
    })
    .catch(err => {
      dispatch(reportsError(err));
    });
};

export const createReport = payload => dispatch => {
  return api.createReport(payload).then(() => invalidate(dispatch));
};

export const editReport = (reportId, payload) => dispatch => {
  return api.editReport(reportId, payload).then(() => invalidate(dispatch));
};

export const deleteReport = reportId => dispatch => {
  return api.deleteReport(reportId).then(() => invalidate(dispatch));
};

export const generateReport = reportId => dispatch => {
  return api.generateReport(reportId);
};

const invalidate = dispatch => {
  return api
    .getScheduledReports()
    .then(res => {
      dispatch(scheduledReportsLoaded(res.data));
    })
    .catch(err => {
      dispatch(reportsError(err));
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new Reports();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.REGULAR_REPORTS_LOADING]: (state, action) => {
    return state.setReportsLoading();
  },

  [ACTIONS.REGULAR_REPORTS_LOADED]: (state, action) => {
    return state.setReportsLoaded(state, action);
  },

  [ACTIONS.SCHEDULED_REPORTS_LOADING]: (state, action) => {
    return state.setScheduledLoading();
  },

  [ACTIONS.SCHEDULED_REPORTS_LOADED]: (state, action) => {
    return state.setScheduledLoaded(state, action);
  },

  [ACTIONS.REPORTS_ERROR]: (state, action) => {
    return state.setError(action);
  }
});
