import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material ui default colors for avatar background color
/*const MATERIAL_PALETTE = [
  '#E53935',
  '#D81B60',
  '#8E24AA',
  '#5E35B1',
  '#3949AB',
  '#1E88E5',
  '#039BE5',
  '#00ACC1',
  '#00897B',
  '#43A047',
  '#7CB342',
  '#C0CA33',
  '#FDD835',
  '#FFB300',
  '#FB8C00',
  '#F4511E',
  '#6D4C41',
  '#757575',
  '#546E7A'
];*/

class Avatar extends Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    initials: PropTypes.string,
    size: PropTypes.number,
    src: PropTypes.string // use this prop if there's a profile image
  };

  static defaultProps = {
    size: 28
  };

  render() {
    const { className, initials, color, size, src } = this.props;

    if (src) {
      return (
        <div className={classNames('avatar', className)} style={{ width: size, height: size }}>
          <img alt="profile" className="avatar__image" src={src} />
        </div>
      );
    }

    return (
      <div
        className={classNames('avatar', className)}
        style={{
          // Get color prop or random color from material palette
          // MATERIAL_PALETTE[Math.floor(Math.random() * MATERIAL_PALETTE.length - 1)],
          backgroundColor: color || 'black',
          width: size,
          height: size
        }}
      >
        <span className="avatar__initials">{initials}</span>
      </div>
    );
  }
}

export default Avatar;
