import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import Constants from 'utils/constants';

import { addExtractionFieldToTemplate, createTemplate, getCurrentRegion } from 'store/api';
import { createExtractionFieldAndAddToTemplate } from 'store/api';
import AddExtraction from 'components/extractions/add-extraction/add-extraction';
import Icon from 'components/shared/icon';
import { Link } from 'react-router-dom';
import Permissions from 'permissions/permissions';
import TemplateViewer from './template-viewer/template-viewer';
import Button from 'components/shared/single-click-button';

class EditExtractions extends Component {
  static propTypes = {
    addProjectTemplate: PropTypes.func,
    changeTemplateName: PropTypes.func,
    clearTemplatesData: PropTypes.func,
    currentProject: PropTypes.object,
    deleteExtraction: PropTypes.func,
    deleteNotification: PropTypes.func,
    deleteProjectTemplate: PropTypes.func,
    duplicateProjectTemplate: PropTypes.func,
    editExtractions: PropTypes.object,
    history: PropTypes.object,
    intl: intlShape,
    loadAllExtraction: PropTypes.func,
    loadProjectTemplates: PropTypes.func,
    loadTemplateExtraction: PropTypes.func,
    loadTypes: PropTypes.func,
    moveExtraction: PropTypes.func,
    notifications: PropTypes.array,
    projectId: PropTypes.any,
    defaultTemplate: PropTypes.number,
    selectProjectTemplate: PropTypes.func,
    templateTypes: PropTypes.object,
    updateAllExtractions: PropTypes.func,
    updateProjectTemplate: PropTypes.func,
    currentFolder: PropTypes.object
  };

  state = {
    docTypeId: '',
    isLoading: false,
    isResolved: true,
    showAddExtraction: true,
    groupByTemplateType: false,
    createEditTemplateName: false,
    newTemplateName: '',
    addedExtractions: [],
    selectedTemplateType: null,
    templateNameUpdateErr: false
  };

  // Initial fetch of project templates
  componentDidMount() {
    const { projectId, loadProjectTemplates, loadTypes, defaultTemplate } = this.props;
    if (projectId) {
      this.setState({ isLoading: true });
      loadTypes();
      return loadProjectTemplates({ projectId }, defaultTemplate);
    }
  }

  componentDidUpdate(prevProps) {
    const { projectId, editExtractions, loadTemplateExtraction, loadAllExtraction } = this.props;
    if (editExtractions.selectedId == null)
    {
      editExtractions.selectedId = 0;
    }
    if (prevProps.editExtractions.selectedId !== editExtractions.selectedId) {
      this.setState({templateNameUpdateErr: false});
      return loadTemplateExtraction(projectId, editExtractions.selectedId)
        .then(() => loadAllExtraction())
        .then(() => this.setState({ isLoading: false }));
    }
  }

  componentWillUnmount() {
    this.props.clearTemplatesData();
  }

  // Create template to the server
  onCreate = () => {
    const {
      projectId,
      addProjectTemplate,
      intl: { formatMessage }
    } = this.props;
    return addProjectTemplate({
      projectId,
      templateName: formatMessage({ id: 'create-template.untitled' }),
      extractionFields: []
    });
  };

  // Delete specified template
  onTemplateDelete = (projectItemId) => {
    const { projectId, deleteProjectTemplate, editExtractions } = this.props;
    this.setState({ isLoading: true });
    return deleteProjectTemplate({ projectId, templateId: editExtractions.selectedId, projectItemId: projectItemId  }).then(res => {
      this.setState({ isLoading: false });
    });
  };

  getExtIds = data => {
    return data.map(value => {
      return {
        extractionFieldId: value.id,
        extractionFieldGroupId: value.groupId
      };
    });
  };

  // Update the existing to the server
  onUpdate = (nameOnly = false) => {
    const { projectId, updateProjectTemplate, editExtractions } = this.props;
    this.setState({templateNameUpdateErr: false});
    return updateProjectTemplate({
      projectId,
      templateName: editExtractions.selectedName,
      templateId: editExtractions.selectedId,
      extractionFields: nameOnly ? null : this.getExtIds(editExtractions.templateExtractions)
    }).catch((err) =>{
        if(err.toString().includes('409'))
          this.setState({templateNameUpdateErr: true})
        }
      );
  };

  onDuplicate = () => {
    const { projectId, duplicateProjectTemplate, editExtractions } = this.props;

    const templateTypeId = editExtractions.templateExtractions.length
      ? editExtractions.templateExtractions[0].templateTypeID
      : 0;

    return duplicateProjectTemplate({
      projectId,
      templateName: `${this.props.intl.formatMessage({
        id: 'edit-extractions.duplicate-template'
      })} ${editExtractions.selectedName}`,
      templateCategoryId: editExtractions.selectedTemplateCategoryId,
      extractionFields: this.getExtIds(editExtractions.templateExtractions),
      templateTypeID: templateTypeId
    });
  };

  // Delete specified extraction
  onExtractionDelete = extId => {
    const { projectId, deleteExtraction, editExtractions } = this.props;
    return deleteExtraction({
      projectId,
      templateId: editExtractions.selectedId,
      extractionFieldId: extId
    });
  };

  // Create a new extraction field
  createExtraction = (name, description) => {
    var { editExtractions, projectId } = this.props;
    this.setState({ isResolved: false });

    return createExtractionFieldAndAddToTemplate(name, description, editExtractions.selectedId, projectId);
  };

  getExtractions = () => {
    const { editExtractions } = this.props;
    const { addedExtractions, groupByTemplateType } = this.state;
    const docTypeId =
      editExtractions.templateExtractions.length && !groupByTemplateType
        ? editExtractions.templateExtractions[0].externalSystemId
        : null;
    const editExtractionByDocType =
      docTypeId === null
        ? editExtractions.allExtractions
        : editExtractions.allExtractions.filter(i => i.externalSystemId === docTypeId);
    if (!groupByTemplateType) {
      return editExtractionByDocType;
    }

    if (addedExtractions.length > 0) {
      const addedExtractionIds = addedExtractions.map(({ id }) => id);
      return editExtractionByDocType.map(extraction =>
        addedExtractionIds.includes(extraction.id)
          ? {
              ...extraction,
              disabled: true,
              selected: true
            }
          : {
              ...extraction,
              disabled: false
            }
      );
    }

    return editExtractionByDocType.map(extraction => ({ ...extraction, disabled: false }));
  };

  moveExtraction = (dragIndex, hoverIndex) => {
    const { addedExtractions } = this.state;
    const dragExtraction = addedExtractions[dragIndex];
    const updatedExtractions = [...addedExtractions];

    updatedExtractions.splice(dragIndex, 1);
    updatedExtractions.splice(hoverIndex, 0, dragExtraction);

    this.setState({ addedExtractions: updatedExtractions });
  };

  removeExtraction = extractionId => {
    const { addedExtractions } = this.state;
    this.setState({ addedExtractions: addedExtractions.filter(({ id }) => id !== extractionId) });
  };

  addToNewTemplate = selectedExtractions => {
    const { addedExtractions, selectedTemplateType } = this.state;
    const groupIds = selectedTemplateType.type.groups.map(({ extractionFieldGroupId }) => extractionFieldGroupId);

    const newTemplateName =
      selectedTemplateType.type.templateCategoryId === Constants.TemplateCategories.NON_DETAIL_TESTING
        ? selectedExtractions[0].group
        : selectedTemplateType.type.templateTypeDescription;

    this.setState({
      newTemplateName: newTemplateName,
      addedExtractions: [
        ...addedExtractions,
        ...selectedExtractions.filter(({ groupId }) => groupIds.includes(groupId))
      ]
    });
  };

  createNewTemplate = () => {
    const { projectId, history } = this.props;
    const { newTemplateName, addedExtractions, selectedTemplateType } = this.state;
    
    return createTemplate({
      projectId: projectId,
      templateName: newTemplateName,
      templateCategoryId: selectedTemplateType.type.templateCategoryId,
      templateTypeID: selectedTemplateType.type.templateTypeID,
      extractionFields: addedExtractions.map(({ id, groupId }) => ({
        extractionFieldId: id,
        extractionFieldGroupId: groupId
      }))
    }).then(response => {
     
      history.push(`/region/${getCurrentRegion()}/project/${projectId}/folder/${response.data.templateFolderId}`);
    });
  };

  // Handle create extraction success
  onCreateExtractionSuccess = extraction => {
    this.onAddExtractionsSuccess([extraction]);
  };

  // Handle create extraction error
  onCreateExtractionFail = () => {
    this.onAddExtractionsFail();
  };

  // Add extraction fields to the template
  addExtractions = fields => {
    var { editExtractions, projectId } = this.props;
    this.setState({ isResolved: false });

    return Promise.all(
      fields.map(field => addExtractionFieldToTemplate(projectId, editExtractions.selectedId, field.id, field.groupId))
    );
  };

  // Handle add extraction success
  onAddExtractionsSuccess = extractions => {
    const { updateAllExtractions } = this.props;

    updateAllExtractions(extractions);
    this.setState({ isResolved: true, docTypeId: extractions[0].externalSystemId });
  };

  // Handle add extraction fail
  onAddExtractionsFail = () => {
    this.setState({ isResolved: true });
  };

  onCreateNameChange = e => {
    this.setState({ newTemplateName: e.target.value });
  };

  // Api handler
  getHandlers = () => {
    return {
      onCreate: this.onCreate,
      onNameChange: e => this.props.changeTemplateName(e.target.value),
      onExtractionChange: extractions => this.props.moveExtraction(extractions),
      onTemplateClick: value => this.props.selectProjectTemplate(value),
      onTemplateDelete: id => this.onTemplateDelete(id),
      onTemplateDuplicate: this.onDuplicate,
      onExtractionDelete: id => this.onExtractionDelete(id),
      onUpdate: this.onUpdate,
      onGetTemplates: () => this.props.loadProjectTemplates({ projectId: this.props.projectId })
    };
  };

  getExtractionGroups = extractions => {
    return extractions.reduce((acc, cur) => {
      if (acc.find(ext => ext.groupId === cur.groupId) || cur.groupId === null) {
        return acc;
      } else {
        return acc.concat({
          groupName: cur.group,
          groupId: cur.groupId
        });
      }
    }, []);
  };

  render() {
    const { editExtractions, currentProject, templateTypes, projectId, currentFolder } = this.props;
    const {
      showAddExtraction,
      isLoading,
      groupByTemplateType,
      createEditTemplateName,
      newTemplateName,
      addedExtractions,
      selectedTemplateType
    } = this.state;
    const apiHandler = this.getHandlers();
    const readOnly = currentProject.STATES.ReadOnly() || !Permissions.Project.ExtractionField.canCreate();

    if (isLoading) {
      return <Icon className="spinner spinner--centered" name="loader" width={80} />;
    }
    return (
      <div className={`edit-extractions`}>
        <div className={`edit-extractions__toolbar`}>
          <h2 className={`edit-extractions__toolbar-title`} title={currentProject.projectName}>
            {currentProject.projectName}
          </h2>
          {!readOnly &&
            (groupByTemplateType ? (
              <Button
                className="btn btn-primary btn-medium create-template"
                onClick={() =>
                  this.setState({
                    groupByTemplateType: false,
                    createEditTemplateName: false,
                    newTemplateName: '',
                    addedExtractions: [],
                    selectedTemplateType: null
                  })
                }
              >
                <FormattedMessage id="edit-extractions.options.cancel" />
              </Button>
            ) : (
              <Link
                className="btn btn-primary btn-medium create-template"
                to={`/region/${getCurrentRegion()}/project/${projectId}/create-template`}
              >
                <FormattedMessage id="edit-extractions.options.create-new" />
              </Link>
            ))}
        </div>
        
        {editExtractions.templates.length > 0 && <div className={`edit-extractions__content`}>
          <div>
            {showAddExtraction && (
              <AddExtraction
                addExtractions={this.addExtractions}
                createExtraction={this.createExtraction}
                currentTemplateName={editExtractions.selectedName}
                data={this.getExtractions()}
                onAddExtractionsFail={this.onAddExtractionsFail}
                onAddExtractionsSuccess={this.onAddExtractionsSuccess}
                onCreateExtractionFail={this.onCreateExtractionFail}
                onCreateExtractionSuccess={this.onCreateExtractionSuccess}
                pageViewer="ExtractionPage"
                readOnly={readOnly}
                hideCreateNewExtractionField={true}
                groupByTemplateType={groupByTemplateType}
                templateTypes={templateTypes.types.templateTypes}
                addToNewTemplate={this.addToNewTemplate}
                onSelectTemplateType={selectedTemplateType =>
                  this.setState({ selectedTemplateType, addedExtractions: [], newTemplateName: '' })
                }
                documentTemplateCategoryId={editExtractions.selectedTemplateCategoryId}
                extractionFieldGroups={this.getExtractionGroups(editExtractions.templateExtractions)}
              />
            )}
          </div>

          <div className={`edit-extractions__template-viewer`}>
            <TemplateViewer
              api={apiHandler}
              currentProject={currentProject}
              currentFolder = {currentFolder}
              pageViewer="ExtractionPage"
              createNewTemplate={this.createNewTemplate}
              editExtractions={editExtractions}
              isResolved={this.state.isResolved}
              readOnly={readOnly}
              onEditTemplate={createEditTemplateName => this.setState({ createEditTemplateName })}
              createNew={createEditTemplateName}
              addedExtractions={addedExtractions}
              groupByTemplateType={groupByTemplateType}
              onCreateNameChange={this.onCreateNameChange}
              newTemplateName={newTemplateName}
              newTemplateMoveExtraction={this.moveExtraction}
              newTemplateRemoveExtraction={this.removeExtraction}
              selectedTemplateType={selectedTemplateType ? selectedTemplateType.type : null}
              templateNameUpdateErr={this.state.templateNameUpdateErr}
            />
          </div>
        </div>}
      </div>
      
    );
  }
}

export default injectIntl(EditExtractions);
