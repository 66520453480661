import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { hasError, ERROR_CODES } from 'utils/errors';
import { isProjectItemNameValid } from 'utils/validation';
import { ITEM_TYPE_ICONS } from 'components/file-browser/file-list/constants';
import { ITEM_TYPES } from 'models/project-item';
import Icon from 'components/shared/icon';

const getFileExtension = (fileName, itemTypeId) => {
  if (itemTypeId === ITEM_TYPES.FOLDER) {
    return '';
  }

  const result = /\.[0-9a-z]+$/i.exec(fileName);
  return result ? result[0] : '';
};

/*
 The name cell of the file list table

 Handles editing the name of the document or folder
*/
export default class NameCell extends Component {
  static propTypes = {
    invalidate: PropTypes.func,
    isSearchResultsPage: PropTypes.bool,
    itemName: PropTypes.string,
    itemStateId: PropTypes.number,
    itemTypeId: PropTypes.number,
    link: PropTypes.string,
    onNameClick: PropTypes.func,
    projectId: PropTypes.string.isRequired,
    projectItemId: PropTypes.number,
    readOnly: PropTypes.bool,
    renameItem: PropTypes.func.isRequired
  };

  inputElem = null;

  state = {
    error: null,
    inputValue: this.props.itemName.replace(getFileExtension(this.props.itemName, this.props.itemTypeId), ''),
    isEditing: false,
    isHovering: false
  };

  onMouseEnter = () => {
    this.setState({
      isHovering: true
    });
  };

  onMouseLeave = () => {
    this.setState({
      isHovering: false
    });
  };

  onEditClick = () => {
    this.setState({ isEditing: true, inputValue: this.props.itemName.replace(getFileExtension(this.props.itemName, this.props.itemTypeId), '')});
  };

  onInputChange = event => {
    const inputValue = event.target.value;
    this.setState({
      inputValue
    });

    if (isProjectItemNameValid(inputValue)) {
      if (this.state.error) {
        this.setState({ error: null });
      }
    } else {
      if (!this.state.error) {
        this.setState({ error: 'file-list.item-name-error' });
      }
    }
  };

  onBlur = () => {
    const { itemName, itemTypeId, projectItemId, projectId, renameItem, invalidate } = this.props;
    const { inputValue } = this.state;

    const newName = inputValue + getFileExtension(itemName, itemTypeId);

    // If name was not changed, ignore
    if (itemName === newName) {
      this.setState({ isEditing: false, error: null });
      return;
    }

    if (isProjectItemNameValid(inputValue)) {
      renameItem({
        newName,
        oldName: itemName,
        itemTypeId,
        projectItemId,
        projectId
      })
        .then(() => {
          this.setState({ isEditing: false, error: null });
          invalidate();          
        })
        .catch(error => {
          if (
            hasError(error, ERROR_CODES.DOCUMENT_NAME_DUPLICATE) ||
            hasError(error, ERROR_CODES.FOLDER_NAME_DUPLICATE)
          ) {
            this.setState({ error: 'file-list.item-duplicate-name-error' });
          } else {
            throw error;
          }
        });
        
    } else {
      this.setState({ error: 'file-list.item-name-error' });
    }
  };

  mountInput = elem => {
    if (!elem) {
      return;
    }

    this.inputElem = elem;
    this.inputElem.focus();
    document.execCommand('selectAll', false, null);
  };

  onKeyDown = event => {
    const { itemName } = this.props;

    if (event.key === 'Enter') {
      // Submit on Enter
      event.preventDefault();
      this.inputElem.blur();
    } else if (event.key === 'Escape') {
      // Reset on Esc
      this.setState({
        isEditing: false,
        error: null,
        inputValue: itemName.replace(getFileExtension(this.props.itemName, this.props.itemTypeId), '')
      });
    }
  };

  render() {
    const { itemTypeId, itemName, link, isSearchResultsPage, onNameClick, readOnly } = this.props;
    const { isHovering, isEditing, inputValue, error } = this.state;

    const extension = getFileExtension(itemName, itemTypeId);

    return (
      <div className="file-list__editable-cell" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {isEditing ? (
          <div className="file-list__name-editable-container">
            {itemTypeId === ITEM_TYPES.FOLDER ? (
              <Icon className="file-list__icon" name={ITEM_TYPE_ICONS[itemTypeId]} width={16} />
            ) : null}
            <div className="file-list__name-input-container">
              <div className={classNames('file-list__name-input', error && 'file-list__name-input--error')}>
                <input
                  type="text"
                  maxLength={itemTypeId === ITEM_TYPES.FOLDER ? '140' : 400 - extension.length}
                  ref={this.mountInput}
                  value={inputValue}
                  onChange={this.onInputChange}
                  onKeyDown={this.onKeyDown}
                  onBlur={this.onBlur}
                />
                <div className="file-list__name-extension">{extension}</div>
              </div>
              {error && (
                <div className="file-list__name-input-error">
                  <FormattedMessage id={error} />
                </div>
              )}
            </div>
          </div>
        ) : isSearchResultsPage ? (
          <div className="file-list__name" onClick={onNameClick} title={itemName}>
            {itemTypeId === ITEM_TYPES.FOLDER ? (
              <Icon className="file-list__icon" name={ITEM_TYPE_ICONS[itemTypeId]} width={16} />
            ) : null}
            <span>{itemName}</span>
          </div>
        ) : (
          <Link
            onClick={e => {
              e.stopPropagation();
            }}
            to={link}
            className="file-list__name"
            title={itemName}
            data-ignore-mouse-down
          >
            {itemTypeId === ITEM_TYPES.FOLDER ? (
              <Icon className="file-list__icon" name={ITEM_TYPE_ICONS[itemTypeId]} width={16} />
            ) : null}
            <span>{itemName}</span>
          </Link>
        )}
        {readOnly ? null : (
          <div className="file-list__edit-icon-container" data-ignore-mouse-down>
            {isHovering && !isEditing ? (
              <div className="file-list__edit-icon" onClick={this.onEditClick}>
                <Icon name="special-edit" width={24} />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}
