import { connect } from 'react-redux';
import { getData } from 'store/tags/language-tags';
import Tile from 'components/tags/tile';

const mapStateToProps = ({ data: { languageTags } }) => ({ data: languageTags });
const mapDispatchToProps = { getData };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tile);
