import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';

/** List of all the templates, when user has finished creating at least one template in the project creation section */
class TemplateList extends Component {
  static propTypes = {
    onCreateNewClick: PropTypes.func,
    onTemplateClick: PropTypes.func,
    templates: PropTypes.array
  };

  render() {
    const { onTemplateClick, onCreateNewClick, templates } = this.props;

    return (
      <div className="template-list__container">
        <div className="template-list__body-container">
          {templates &&
            templates.map((temp, index) => {
              return (
                <span key={index} className="template-list__item" onClick={value => onTemplateClick(temp)}>
                  <span className="template-list__item-title">{temp.name}</span>
                  <Icon className="template-list__item-icon" name="special-arrow-down-blue" width={12} rotate={-90} />
                </span>
              );
            })}
        </div>
        <div className="template-list__create-footer">
          <Button className="template-list__create-new" onClick={onCreateNewClick}>
            <FormattedMessage id="template-list.create-new" />
          </Button>
        </div>
      </div>
    );
  }
}

export default TemplateList;
