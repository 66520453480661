import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import enhanceWithClickOutside from 'react-click-outside';
import Button from 'components/shared/single-click-button';
import { userLogOut } from 'store/api';
import Icon from 'components/shared/icon';
import AccountSettingsPopup from 'containers/header/account-settings-popup';
import ReactTooltip from 'react-tooltip';
import * as api from 'store/api';

class AccountDropdown extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  state = {
    disabled: false,
    isMenuOpen: false,
    isSettingMenuOpen: false,
    isDsEnabled: false
  };

  handleClickOutside = () => {
    this.setState({ isMenuOpen: false });
  };
 
  onLogOut = () => {
    this.setState({ disabled: true });
    return userLogOut();
  };
 
  closePopup = () => {
    this.setState({ isMenuOpen: false });
  };
  saveFormat = () => {
    this.setState({ isMenuOpen: false });
  };
  closeSettingsPopup = () => {
    this.setState({ isSettingMenuOpen: false });
  };
  onChangeDSEnabled = () => {
    localStorage.setItem('IsDsEnabled', !this.state.isDsEnabled)
    this.setState({ isDsEnabled: !this.state.isDsEnabled });
  }

  render() {
    const { isMenuOpen, disabled, isSettingMenuOpen, isDsEnabled } = this.state;
    const { user } = this.props;

    return (
      <div className="account-dropdown">
        <Button
          size="icon"
          disabled={!user.isLoaded}
          className={`account-dropdown__wrapper btn btn-no-margin ${isMenuOpen ? 'active' : ''}`}
          onClick={() => this.setState({ isMenuOpen: !isMenuOpen, isSettingMenuOpen: false })}
          data-tip
          data-for="account-dropdown__name"
        >
          <ReactTooltip id="account-dropdown__name" effect="solid" place="bottom">
          { user.firstName && user.lastName ? user.firstName +' '+ user.lastName:"" }
          </ReactTooltip>
          <div className="account-dropdown__avatar">
            {user.isLoaded && !user.error ? user.firstName[0] + user.lastName[0] : null}
          </div>
        </Button>

        {isMenuOpen ? (
          <div className="account-dropdown__container">
            <div className="account-dropdown__credentials">
              <div className="account-dropdown__profile">{user.firstName[0] + user.lastName[0]}</div>
              <div className="account-dropdown__name">
              { user.firstName && user.lastName ? user.firstName +' '+ user.lastName:"" }
              </div>
            </div>

            <div
              className="account-dropdown__settings"
              onClick={() => this.setState({ isSettingMenuOpen: !isSettingMenuOpen, isMenuOpen: false })}
            >
              <span style={{ verticalAlign: 'middle' }}>
                <Icon name="special-cog" width={16} />
              </span>
              <span className="account-dropdown__settings-span">
                <FormattedMessage id="global.header.home-settings" />
              </span>
            </div>

            <div className="account-dropdown__logout">
              <span style={{ verticalAlign: 'middle' }}>
                <Icon name="special-logout" width={16} />
              </span>
              <Button
                className={`account-dropdown__logout-text ${disabled ? 'disabled' : ''}`}
                onClick={this.onLogOut}
                disabled={disabled}
              >
                <FormattedMessage id="global.header.logout" />
              </Button>
            </div>
          </div>
        ) : null}
        {isSettingMenuOpen && (
          <div className="account-dropdown__settings-Menu">
            <AccountSettingsPopup
              isOpen={this.state.isSettingMenuOpen}
              handleFormatSuccessSubmit={this.closeSettingsPopup}
              handleFormatErrorSubmit={this.closeSettingsPopup}
              closePopup={this.closeSettingsPopup}
              userId={user.userId}
              IsDsEnabled={localStorage.getItem('IsDsEnabled')}
              onChangeDSEnabled={this.onChangeDSEnabled}
              currentUser={user}
            />
          </div>
        )}
      </div>
    );
  }
}

export default enhanceWithClickOutside(AccountDropdown);
