import { connect } from 'react-redux';

import { onSearchChange } from 'store/search/search-category';
import AnalyticsSearch from 'components/search/analytics-search/analytics-search';

const mapStateToProps = ({ data: { search } }) => ({
  search
});

const mapDispatchToProps = {
  onSearchChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsSearch);
