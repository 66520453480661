import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BLOCK } from './constants';
import Icon from 'components/shared/icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContainerSelectionModal from 'components/project-list/container-selection/modal';
import Button from 'components/shared/single-click-button';
import history from 'utils/history';

import Permissions from 'permissions/permissions';

class ArgusExtraction extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    isAllowed: PropTypes.bool.isRequired
  };

  state = {
    showContainerSelectionModal: false
  };

  onContainerSelected = (memberFirmCode, containerCode, countryCode) => {
    this.setState({ showContainerSelectionModal: false });

    const { geoCode } = this.props.currentUser;
    return this.createProject(geoCode, memberFirmCode, containerCode, countryCode);
  };

  createProject = (geoCode, memberFirmCode, containerCode, countryCode) => {
    const GEO_PATH = countryCode
      ? `${geoCode}_${memberFirmCode}_${containerCode}_${countryCode}`
      : `${geoCode}_${memberFirmCode}_${containerCode}`;

    return history.push({
      pathname: `/region/${GEO_PATH}/project/creation`
    });
  };

  onCreateProject = () => {
    const { geoCode, memberFirmCode, containerCode, memberFirmContainers } = this.props.currentUser;
    const { showContainerSelectionModal } = this.state;

    if (memberFirmContainers && memberFirmContainers.length > 1 && !showContainerSelectionModal) {
      // Display member firm container selection modal
      this.setState({ showContainerSelectionModal: true });
    } else {
      // Normal case project creation
      return this.createProject(geoCode, memberFirmCode, containerCode);
    }
  };

  render() {
    const { isAllowed, currentUser } = this.props;
    const { showContainerSelectionModal } = this.state;
    return (
      <>
        <div className={`${BLOCK}__heading-wrapper`}>
          <Icon className="icon" name="argus-extraction" width={32} />
          <p className={`${BLOCK}__heading`}>
            <FormattedMessage id={`${BLOCK}.argus-extraction`} />
          </p>
        </div>
        <p className={`${BLOCK}__description`}>
          <FormattedMessage id={`${BLOCK}.argus-extraction-description`} />
        </p>
        {isAllowed && (
          <div className={`${BLOCK}__button-wrapper`}>
            <Link className="btn btn-primary btn-xlarge" to="/project-list">
              <FormattedMessage id={`${BLOCK}.argus-extraction-projects`} />
            </Link>
            <Button className="btn btn-secondary btn-xlarge" onClick={this.onCreateProject}>
              <FormattedMessage id={`${BLOCK}.argus-extraction-create`} />
            </Button>
          </div>
        )}
        {Permissions.Global.canViewAdminAccess() && (
          <div>
            <Link className={`${BLOCK}__link`} to="/admin-access">
              <FormattedMessage id={`${BLOCK}.argus-extraction-admin-access`} />
            </Link>
          </div>
        )}
        <ContainerSelectionModal
          show={showContainerSelectionModal}
          containers={currentUser.memberFirmContainers}
          onCancel={() => this.setState({ showContainerSelectionModal: false })}
          onSubmit={this.onContainerSelected}
        />
      </>
    );
  }
}
export default ArgusExtraction;
