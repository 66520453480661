import { connect } from 'react-redux';

import Overlay from 'components/document-viewer/document-renderer/overlay/overlay';

const mapStateToProps = ({ data: { currentDocument } }) => ({
  currentDocument
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overlay);
