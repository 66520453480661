import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';
import StatesHelper from 'permissions/helpers/states';

const ProjectExtractionFieldPermissions = {
  canCreate: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canRequestTraining: project => {
    return (
      (RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)) && 
      !StatesHelper.isProjectArchived(project)
    );
  },

  canTag: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canViewTab: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole('current', constants.UserRoles.ADMIN_GENERAL)
    );
  }
};

export default ProjectExtractionFieldPermissions;
