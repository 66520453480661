import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'underscore';

import drawingHelper from '../helpers/drawingHelper.js';
import {
  emphasizeHighlight,
  deemphasizeHighlight
} from 'components/document-viewer/document-renderer/helpers/emphasisHelper';
import Constants from 'utils/constants';

// The overlay component is the canvas wrapper that controls the drawing and user interaction with the canvas
// There are three primary types of elements that it handles:
//  -User selection
//  -Extraction field records
//  -Search result highlights
// Each of these sections requires processing from the raw values into drawable elements
// These conversions change single characters into contiguous lines that form normal-style text highlights
// These processed lines are then rendered with the renderHighlights function

class Overlay extends React.Component {
  static propTypes = {
    currentDocument: PropTypes.object,
    page: PropTypes.any,
    readOnly: PropTypes.bool,
    scale: PropTypes.any,
    searchResults: PropTypes.any,
    selection: PropTypes.any,
    currentSelection: PropTypes.any,
    selectionFunction: PropTypes.func,
    shiftKeyDown: PropTypes.bool,
    hideBookmark: PropTypes.bool
  };

  state = {
    emphasizedHighlight: null
  };

  MAX_SELECTION_OFFSET = 30;
  NEXT_WORD_DELTA = 1;

  componentDidMount() {
    //Listen for highlight emphasis calls from other components
    window.addEventListener('message', this.listenerFunction, false);

    this.processExtractionRanges();
    this.processSearchResults();
    this.processSelection();
  }

  componentDidUpdate(prevProps, prevState) {
    var { currentDocument, selection, searchResults, currentSelection } = this.props;
    var { emphasizedHighlight } = this.state;

    //Check for scale change or new highlights to render
    if (
      currentDocument.extractionFieldRecords &&
      currentDocument.extractionFieldRecords.length !== prevProps.currentDocument.extractionFieldRecords.length
    ) {
      this.processExtractionRanges();
    }

    //Emphasized record changed
    if (emphasizedHighlight !== prevState.emphasizedHighlight) {
      this.processExtractionRanges();
    }

    //New search results
    if (searchResults !== prevProps.searchResults) {
      this.processSearchResults();
    }

    //Selection was changed from outside of overlay
    if (selection !== prevProps.selection) {
      this.processSelection();
    }

    if (currentSelection !== prevProps.currentSelection) {
      this.renderOverlay();
    }
  }

  componentWillUnmount() {
    // This listens to event emitted from the emphasisHelper
    window.removeEventListener('message', this.listenerFunction, false);
  }

  listenerFunction = message => {
    if (this.overlayCanvas && message.data && message.data.emphasize_highlight !== undefined) {
      this.setState({ emphasizedHighlight: message.data.emphasize_highlight });
    }
  };

  clearCanvas = () => {
    var { width, height } = this.props.page;

    if (this.overlayCanvas) {
      var ctx = this.overlayCanvas.getContext('2d');
      ctx.canvas.width = width;
      ctx.canvas.height = height;
    }
  };

  renderOverlay() {
    // var { currentSelection } = this.props;
    var { processedSearchResults, processedExtractions, processedSelection } = this.state;

    this.clearCanvas();

    if (processedExtractions) {
      this.renderHighlights(processedExtractions);
    }

    if (processedSearchResults) {
      this.renderHighlights(processedSearchResults);
    }

    if (processedSelection) {
      this.renderHighlights([processedSelection]);
    }

    // if (
    //   currentSelection &&
    //   currentSelection !== null &&
    //   currentSelection.startingWord &&
    //   currentSelection.startingWord.start.page === this.props.page.pageNumber &&
    //   this.props.selection === null
    // ) {
    //   this.drawTextCursor();
    // }
  }

  processCharacterRangeAsTextLines = (characterRange, colorIndex, emphasis, bookends) => {
    if (characterRange && characterRange.length > 0) {
      var lines = [];
      var currentLine = {
        x1: characterRange[0].x1,
        x2: characterRange[0].x2,
        y1: characterRange[0].y1,
        y2: characterRange[0].y2
      };

      characterRange.forEach(ch => {
        var line_grouping_percentage = 0.5;
        var height = currentLine.y2 - currentLine.y1;

        if (
          ch.y1 >= currentLine.y1 - height * line_grouping_percentage &&
          ch.y2 <= currentLine.y2 + height * line_grouping_percentage
        ) {
          currentLine = {
            x1: currentLine.x1,
            x2: ch.x2,
            y1: currentLine.y1 <= ch.y1 ? currentLine.y1 : ch.y1,
            y2: currentLine.y2 >= ch.y2 ? currentLine.y2 : ch.y2
          };
        } else {
          lines.push(currentLine);
          currentLine = {
            x1: ch.x1,
            x2: ch.x2,
            y1: ch.y1,
            y2: ch.y2
          };
        }
      });

      lines.push(currentLine);

      return lines;
    }

    return [];
  };

  renderHighlights = highlights => {
    if (highlights)
      highlights.forEach(highlight => {
        highlight.lines.forEach(line => {
          var { x1, x2, y1, y2 } = line;
          drawingHelper.highlightRegion(
            x1,
            y1,
            x2 - x1,
            y2 - y1,
            highlight.color,
            this.overlayCanvas,
            highlight.emphasis
          );
        });

        if (highlight.bookends) {
          drawingHelper.textBookends(
            highlight.lines[0],
            highlight.lines[highlight.lines.length - 1],
            this.overlayCanvas
          );
        }
      });
  };

  processSelection = () => {
    var { selection } = this.props;
    var { characters } = this.props.page;

    if (selection === null) {
      this.setState({ processedSelection: undefined }, () => this.renderOverlay());
      // window.mousedown = false;
      return;
    }

    var firstIndex = characters.findIndex(ch => ch.i === selection.firstIndex);
    var secondIndex = characters.findIndex(ch => ch.i === selection.lastIndex);

    if (characters[0] && characters[0].u.length) {
      secondIndex = this.getSecondIndex(characters, selection.lastIndex);
      secondIndex = secondIndex === -2 ? -1 : secondIndex;
    }
    //These statements account for selections over the page break
    if (firstIndex === -1 && secondIndex !== -1)
      firstIndex = selection.firstIndex < selection.lastIndex ? 0 : characters.length - 1;
    if (firstIndex !== -1 && secondIndex === -1)
      secondIndex = selection.firstIndex < selection.lastIndex ? characters.length - 1 : 0;

    if (firstIndex !== -1 && secondIndex !== -1) {
      var lesser = firstIndex <= secondIndex ? firstIndex : secondIndex;
      var greater = firstIndex <= secondIndex ? secondIndex : firstIndex;
      var characterRange = characters.slice(lesser, greater + 1);
      var lines = this.processCharacterRangeAsTextLines(characterRange);

      this.setState(
        {
          processedSelection: {
            range: characterRange,
            lines: lines,
            color: false,
            emphasis: 'selection',
            bookends: true
          }
        },
        () => {
          this.renderOverlay();
        }
      );
    }
  };

  getSecondIndex = (characters, lastIndex) => {
    var findIndex = offset => characters.findIndex(ch => ch.i === lastIndex + offset) - 1;
    for (var i = 2; i <= 2 + this.NEXT_WORD_DELTA; i++) {
      const found = findIndex(i);
      if (found >= 0) {
        return found;
      }
    }
    return -1;
  };

  processExtractionRanges = () => {
    var { emphasizedHighlight } = this.state;
    var { currentDocument, page } = this.props;
    var { characters } = this.props.page;
    var processedExtractions = [];

    if (currentDocument.extractionFieldRecords && currentDocument.extractionFieldRecords.length > 0) {
      currentDocument.extractionFieldRecords.forEach(extractionRecord => {
        var firstIndex = characters.findIndex(ch => ch.i === extractionRecord.location[0]);
        var secondIndex = characters.findIndex(ch => ch.i === extractionRecord.location[1]);
        if (characters[0] && characters[0].u.length) {
          secondIndex = this.getSecondIndex(characters, extractionRecord.location[1]);
          secondIndex = secondIndex === -2 ? -1 : secondIndex;
        }

        //These statements account for extractions over the page break
        if (firstIndex === -1 && secondIndex !== -1) firstIndex = 0;
        if (firstIndex !== -1 && secondIndex === -1) secondIndex = characters.length - 1;

        //Account for middle pages in multipage extractions
        if (extractionRecord.location[0] < page.range.start && extractionRecord.location[1] > page.range.end) {
          firstIndex = 0;
          secondIndex = characters.length - 1;
        }

        if (firstIndex !== -1 && secondIndex !== -1) {
          var characterRange = characters.slice(firstIndex, secondIndex + 1);
          var lines = this.processCharacterRangeAsTextLines(characterRange);

          processedExtractions.push({
            range: characterRange,
            lines: lines,
            color: extractionRecord.colorIndex,
            emphasis:
              emphasizedHighlight && emphasizedHighlight === extractionRecord.extractionFieldRecordId
                ? 'underline'
                : false,
            record: extractionRecord
          });
        }
      });
    }

    this.setState({ processedExtractions: processedExtractions }, () => {
      this.renderOverlay();
    });
  };

  processSearchResults = () => {
    var { searchResults } = this.props;
    var { characters } = this.props.page;
    var processedSearchResults = [];

    if (searchResults && searchResults.length > 0) {
      searchResults.forEach(result => {
        var firstIndex = characters.findIndex(ch => ch.i === result.characters[0].i);
        var secondIndex = characters.findIndex(ch => ch.i === result.characters[result.characters.length - 1].i);

        if (characters[0] && characters[0].u.length > 1) {
          firstIndex = characters.findIndex(ch => ch.i === result.characters[0].start);
          secondIndex = this.getSecondIndex(characters, result.characters[result.characters.length - 1].end);
          secondIndex = secondIndex === -2 ? -1 : secondIndex;
        }

        //These statements account for extractions over the page break
        if (firstIndex === -1 && secondIndex !== -1) firstIndex = 0;
        if (firstIndex !== -1 && secondIndex === -1) secondIndex = characters.length - 1;

        if (firstIndex !== -1 && secondIndex !== -1) {
          var characterRange = characters.slice(firstIndex, secondIndex + 1);
          var lines = this.processCharacterRangeAsTextLines(characterRange);

          processedSearchResults.push({
            range: characterRange,
            lines: lines,
            color: result.emphasis ? 'search_active' : 'search_inactive',
            emphasis: false
          });
        }
      });
    }

    this.setState({ processedSearchResults: processedSearchResults }, () => {
      this.renderOverlay();
    });
  };

  getMouseCoordinates = e => {
    var { scale } = this.props;

    return {
      x: (e.clientX - this.overlayCanvas.getBoundingClientRect().left) / scale,
      y: (e.clientY - this.overlayCanvas.getBoundingClientRect().top) / scale
    };
  };

  getCharacterAtCoordinates = (x, y) => {
    // ---V3 - Preprocessed Ranges
    // Characters are grouped into a 2 dimensional array where each cell in the grid represents a 100px by 100px square of the document.
    // This heavily reduces the number of elements in each mouse character lookup.
    // A single character may exist in multiple cells in the grid as it can occur at the intersection of the 100x100 cells.
    // Processing is done inside of document-viewer layout model.

    var gx = Math.floor(x / 100); // gx = group for x
    var gy = Math.floor(y / 100); // gy = group for y
    var p = 5; // p = padding (allowing for user clicking inacurracy)

    if (this.props.page.gridMap.hasOwnProperty(gx) && this.props.page.gridMap[gx].hasOwnProperty(gy)) {
      for (var key in this.props.page.gridMap[gx][gy]) {
        var currentCharacter = this.props.page.gridMap[gx][gy][key];
        if (
          currentCharacter.x1 - p < x &&
          currentCharacter.x2 + p > x &&
          currentCharacter.y1 - p < y &&
          currentCharacter.y2 + p > y
        )
          return currentCharacter;
      }
    }

    return;
  };

  getExtractionFieldRecordAtCoordinates = throttle(coordinates => {
    var hit;

    this.state.processedExtractions.forEach(extraction => {
      extraction.lines.forEach(line => {
        if (
          line.x1 <= coordinates.x &&
          line.x2 >= coordinates.x &&
          line.y1 <= coordinates.y &&
          line.y2 >= coordinates.y
        ) {
          hit = extraction.record;
        }
      });
    });
    return hit;
  }, 100);

  emphasizeHighlight = extractionRecord => {
    if (extractionRecord) {
      this.debouncedEmphasizeHighlight(extractionRecord.extractionFieldRecordId, extractionRecord.extractionFieldId);
    } else if (!extractionRecord) {
      this.debouncedDeemphasizeHighlight();
    }
  };

  debouncedEmphasizeHighlight = throttle((recordId, fieldId) => {
    emphasizeHighlight(recordId, fieldId);
  }, 100);

  debouncedDeemphasizeHighlight = throttle(() => {
    deemphasizeHighlight();
  }, 100);

  /// REVISED FUNCTIONS START

  getDistance = (point1, point2) => {
    var dx = point1.x - point2.x;
    var dy = point1.y - point2.y;
    return Math.sqrt(dx * dx + dy * dy);
  };

  getClosestCharacter(x, y) {
    var closest = {
      min: null,
      index: null,
      character: null
    };

    this.props.page.characters.forEach((character, i) => {
      var middle = {
        x: character.x1 + (character.x2 - character.x1) / 2,
        y: character.y1 + (character.y2 - character.y1) / 2
      };

      var distance = this.getDistance({ x, y }, middle);

      if ((closest.min === null || distance < closest.min) && distance < this.MAX_SELECTION_OFFSET) {
        closest = {
          min: distance,
          character: character,
          page: this.props.page.pageNumber
        };
      }
    });

    return closest;
  }

  // Check if the coords are inside the word, or close to a word
  getClosestWord(x, y) {
    var closest = {
      min: null,
      index: null,
      character: null
    };

    this.props.page.characters.forEach((character, i) => {
      const isInside = character.x1 < x && x < character.x2 && character.y1 < y && y < character.y2;
      var distance = 0;

      if (!isInside) {
        distance = this.getMinDistance(character, { x, y });
      }

      if ((closest.min === null || distance < closest.min) && distance < this.MAX_SELECTION_OFFSET) {
        closest = {
          min: distance,
          character: character,
          page: this.props.page.pageNumber
        };
      }
    });

    return closest;
  }

  getMinDistance = (word, point) => {
    var dx = Math.max(word.x1 - point.x, 0, point.x - word.x2);
    var dy = Math.max(word.y1 - point.y, 0, point.y - word.y2);
    return Math.sqrt(dx * dx + dy * dy);
  };

  drawTextCursor = () => {
    drawingHelper.drawTextCursor(this.props.currentSelection, this.overlayCanvas);
  };

  /// REVISED FUNCTIONS END

  isBreak = unicode => {
    return Constants.TextSelectionBreakCharacters.indexOf(unicode) !== -1;
  };

  isWordBreak = word => {
    const characters = word.start.character.u;
    return (
      word.start.character.i === word.end.character.i &&
      Constants.TextSelectionBreakCharacters.indexOf(
        characters.length ? characters[characters.length - 1] : characters
      ) !== -1
    );
  };

  determineWord = character => {
    const { page } = this.props;

    if (!character || !character.character) {
      return null;
    }

    const characterCode = character.character.u;

    if (
      characterCode.length > 1 ||
      this.isBreak(characterCode.length ? characterCode[characterCode.length - 1] : characterCode)
    ) {
      return {
        start: character,
        end: character
      };
    }

    if (character.page === page.pageNumber) {
      var origin = page.characters.indexOf(character.character);
      var start = null;
      var end = null;
      var i = 1;

      while ((start === null || end === null) && i < 100) {
        if (start === null && origin - i >= 0) {
          const prevChar = page.characters[origin - i].u;
          if (prevChar.length > 1 || this.isBreak(prevChar.length ? prevChar[prevChar.length - 1] : prevChar)) {
            start = page.characters[origin - i + 1];
          }
        } else if (start === null) {
          start = page.characters[0];
        }

        if (end === null && origin + i < page.characters.length) {
          const nextChar = page.characters[origin + i].u;
          if (nextChar.length > 1 || this.isBreak(nextChar.length ? nextChar[nextChar.length - 1] : nextChar)) {
            end = page.characters[origin + i - 1];
          }
        } else if (end === null) {
          end = page.characters[page.characters.length - 1];
        }

        i++;
      }

      return {
        start: {
          min: character.min,
          character: start,
          page: character.page
        },
        end: {
          min: character.min,
          character: end,
          page: character.page
        }
      };
    }
  };

  _mouseDown = coordinates => {
    window.mousedown = true;

    var char = this.getClosestWord(coordinates.x, coordinates.y);
    var word = this.determineWord(char);

    if (!word || this.isWordBreak(word)) {
      this.props.selectionFunction({
        currentSelection: { startingWord: null, endingWord: null }
      });
    }

    var _currentSelection = {
      startingWord: word,
      endingWord: null
    };

    this.props.selectionFunction({
      currentSelection: _currentSelection
    });

    var matchingExtractionRecord = this.getExtractionFieldRecordAtCoordinates(coordinates);
    if (matchingExtractionRecord) {
      document
        .getElementById(`extraction-field-record-${matchingExtractionRecord.extractionFieldRecordId}`)
        .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  };

  _mouseMove = throttle(coordinates => {
    var { currentSelection, selectionFunction } = this.props;

    var matchingExtractionRecord = this.getExtractionFieldRecordAtCoordinates(coordinates);

    if (matchingExtractionRecord) {
      this.emphasizeHighlight(matchingExtractionRecord);
    } else {
      this.emphasizeHighlight();
    }

    if (window.mousedown) {
      var char = this.getClosestWord(coordinates.x, coordinates.y);
      var word = this.determineWord(char);

      if (!word || this.isWordBreak(word)) {
        return;
      }

      const startDefined = currentSelection.startingWord !== null;
      var _currentSelection = {
        startingWord: startDefined ? currentSelection.startingWord : word,
        endingWord: startDefined ? word : null
      };

      selectionFunction({
        currentSelection: _currentSelection
      });
    }
  }, 50);

  _mouseUp = e => {
    window.mousedown = false;
  };

  render() {
    var { hideBookmark} = this.props;
     
    return (
      <div> { !hideBookmark ? (
      <canvas
        className="overlay-canvas"
        onMouseDown={event => {
          var coordinates = this.getMouseCoordinates(event);
          this._mouseDown(coordinates);
        }}
        onMouseMove={event => {
          var coordinates = this.getMouseCoordinates(event);
          this._mouseMove(coordinates);
        }}
        onMouseUp={this._mouseUp}
        ref={canvas => (this.overlayCanvas = canvas)}
      />) : (
      <canvas
        className="overlay-canvas"
        ref={canvas => (this.overlayCanvas = canvas)}
      />
      )}
      </div>
    );
  }
}

export default Overlay;
