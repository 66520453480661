import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getTemplates} from 'store/api';

class DocumentProcessing extends Component {
  static propTypes = {
    clear: PropTypes.func,
    data: PropTypes.any,
    getData: PropTypes.func,
    projectId: PropTypes.any,
    getDocumentResults: PropTypes.func,
    onSelectCategory: PropTypes.func
  };

  componentDidMount() {
    var { getData, projectId } = this.props;
    getTemplates({ projectId }).then(response => {
      const data = response.data;
      if (response.data.length > 0 && getData)
      { 
        getData(projectId);
      }
    });
  }

  componentDidUpdate() {
    var { data, getData, projectId } = this.props;
    getTemplates({ projectId }).then(response => {
    if (response.data.length > 0 && data && !data.isLoading && !data.isLoaded && !data.error && data.error !== null) {
      getData(projectId);
    }
  });
  }

  componentWillUnmount() {
    this.props.clear();
  }

  onClick = statusItem => {
    if (statusItem && statusItem.ids.length > 0) {
      var { projectId, getDocumentResults, onSelectCategory } = this.props;
      onSelectCategory({ key: 'documentStateIds', data: statusItem.ids });
      getDocumentResults({
        projectId,
        pageNum: 1,
        documentStateIds: statusItem.ids
      });
    }
  };

  render = () => {
    var { projectId, data } = this.props;

    if (!projectId) return null;
    return (
      <div className="document-processing-status-tile review-status-tile">
        <h2>
          <FormattedMessage id={`review-status.document-processing-status.title`} />
        </h2>
        <div className="status-wrapper">
          {data.data.map((statusItem, index) => (
            <div className="status-row" key={`status-row-${statusItem.key}`} onClick={() => this.onClick(statusItem)}>
              <span className="status-name">
                <FormattedMessage id={`review-status.document-processing-status.${statusItem.key}`} />
              </span>
              <span className="status-count">{statusItem.count}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };
}

export default DocumentProcessing;
