import colorHelper from 'components/extractions/helpers/colorHelper.js';

var padding = 5;

var helper = {
  // Used to draw selection boxes on supplied canvas
  // Emphasis property takes a string and adds decoration to selections
  //  -outline: draws an outline around the selections
  //  -underline: adds an underline below the region (black)
  //  -selection: adds an underline below the region (color matching bookends, used for users active selection)
  highlightRegion(x, y, w, h, colorIndex, canvas, emphasis) {
    var color = colorIndex === false ? colorHelper.select.color : colorHelper.highlight_colors[colorIndex];

    var ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.fillRect(x, y - padding, w, h + 2 * padding);

    if (emphasis) {
      ctx.lineWidth = padding;
      if (emphasis === 'outline') {
        ctx.rect(x, y - padding, w, h + 2 * padding);
      } else if (emphasis === 'underline') {
        ctx.moveTo(x, y + h + padding);
        ctx.lineTo(x + w, y + h + padding);
      } else if (emphasis === 'selection') {
        ctx.strokeStyle = colorHelper.select.bookends;
        ctx.moveTo(x, y + h + padding);
        ctx.lineTo(x + w, y + h + padding);
      }
      ctx.stroke();
    }

    ctx.closePath();
  },

  drawTextCursor(selection, canvas) {
    const { start } = selection;

    var x = start.character.x1;
    var y = start.character.y1 - padding;
    var w = (start.character.y2 - start.character.y1) / 5;
    var h = start.character.y2 - start.character.y1 + padding * 2;

    var ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.fillStyle = colorHelper.select.bookends;

    ctx.fillRect(x, y - padding, w, h + padding * 2);
    ctx.arc(x + w / 2, y - w * 1.5, w * 1.5, 0, 2 * Math.PI);
    ctx.fill();
    ctx.arc(x + w / 2, y + h + w * 1.5, w * 1.5, 0, 2 * Math.PI);
    ctx.fill();

    ctx.closePath();
  },

  // Adds text handles to the start and end of a users highlighted region (decoration only)
  textBookends(firstElement, lastElement, canvas) {
    //Thickness of bookends is based on the height of the text on the appropriate element
    var w1 = (firstElement.y2 - firstElement.y1) / 5;
    var w2 = (lastElement.y2 - lastElement.y1) / 5;

    var ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.fillStyle = colorHelper.select.bookends;

    //First bookend
    ctx.fillRect(firstElement.x1 - w1, firstElement.y1 - padding, w1, firstElement.y2 - firstElement.y1 + padding * 2);
    ctx.arc(firstElement.x1 - w1 + w1 / 2, firstElement.y1 - w1 * 1.5, w1 * 1.5, 0, 2 * Math.PI);
    ctx.fill();

    //Last bookend
    ctx.fillRect(lastElement.x2, lastElement.y1 - padding, w2, lastElement.y2 - lastElement.y1 + padding * 2);
    ctx.arc(lastElement.x2 + w2 / 2, lastElement.y2 + w2 * 1.5, w2 * 1.5, 0, 2 * Math.PI);
    ctx.fill();

    ctx.closePath();
  }
};

export default helper;
