import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import SearchToolbar from 'containers/search/file-list-search/search-toolbar-container';
import Toolbar from 'containers/document-viewer/toolbar/toolbar-container';
import Icon from 'components/shared/icon';
import DocumentRenderer from 'containers/document-viewer/document-renderer/document-renderer-container';
import Constants from 'utils/constants';

class MainPanel extends Component {
  static propTypes = {
    currentDocument: PropTypes.object,
    currentDocumentLayout: PropTypes.object,
    currentProject: PropTypes.object,
    currentSearchFolder: PropTypes.object,
    currentSelection: PropTypes.any,
    documentData: PropTypes.any,
    documentStateErrors: PropTypes.any,
    focusFunction: PropTypes.func,
    getCurrentDocument: PropTypes.func,
    history: PropTypes.object,
    initialLocation: PropTypes.object,
    isMenuOpen: PropTypes.bool,
    openSearchResults: PropTypes.func,
    pages: PropTypes.any,
    readOnly: PropTypes.bool,
    refreshData: PropTypes.func,
    searchResults: PropTypes.any,
    selection: PropTypes.any,
    selectionFunction: PropTypes.func,
    updateMenu: PropTypes.func
  };

  state = {
    isSearchToolbar: false
  };

  componentDidMount() {
    const { currentSearchFolder } = this.props;
    if (currentSearchFolder.children && currentSearchFolder.children.length > 0) {
      this.setState({ isSearchToolbar: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentSearchFolder } = this.props;
    // Determine whether to display search toolbar or not
    if (nextProps.currentSearchFolder.children !== null) {
      if (
        currentSearchFolder.children !== nextProps.currentSearchFolder.children ||
        (currentSearchFolder.children && currentSearchFolder.children.length > 0) ||
        (nextProps.currentSearchFolder.children && nextProps.currentSearchFolder.children.length > 0)
      ) {
        return this.setState({ isSearchToolbar: true });
      }
    }

    return this.setState({ isSearchToolbar: false });
  }

  render() {
    var {
      currentDocument,
      currentDocumentLayout,
      getCurrentDocument,
      documentStateErrors,
      history,
      initialLocation,
      isMenuOpen,
      openSearchResults,
      readOnly,
      refreshData,
      searchResults,
      selection,
      currentSelection,
      selectionFunction,
      focusFunction,
      updateMenu
    } = this.props;

    var { isSearchToolbar } = this.state;

    return (
      <div className="document-viewer-panel main-panel">
        {currentDocument.isLoaded ? (
          isSearchToolbar ? (
            <SearchToolbar
              documentStateErrors={documentStateErrors}
              refreshData={getCurrentDocument}
              readOnly={readOnly}
              openSearchResults={openSearchResults}
            />
          ) : (
            <Toolbar refreshData={refreshData} readOnly={readOnly} documentStateErrors={documentStateErrors} />
          )
        ) : (
          <div className="ghost-toolbar" />
        )}

        {!currentDocument.isLoaded || (documentStateErrors && documentStateErrors.length > 0) ? (
          <div className={`document-errors ${currentDocumentLayout.isLoaded ? '' : 'full'}`}>
            {documentStateErrors.indexOf(Constants.Document_Error_States.DOCUMENTDELETED) !== -1 ? (
              <div className="error-message">
                <Icon name="special-error" width={120} />
                <p>
                  <FormattedMessage id="document-viewer.error-deleted" />
                </p>
              </div>
            ) : documentStateErrors.indexOf(Constants.Document_Error_States.UNPROCESSED) !== -1 ? (
              <div className="error-message">
                <Icon name="special-doc" width={120} />
                <p>
                  <FormattedMessage id="document-viewer.error-incomplete" />
                </p>
              </div>
            ) : documentStateErrors.indexOf(Constants.Document_Error_States.METADATA404) !== -1 ? (
              <div className="error-message">
                <Icon name="special-doc" width={120} />
                <p>
                  <FormattedMessage id="document-viewer.error-notfound" />
                </p>
              </div>
            ) : documentStateErrors.indexOf(Constants.Document_Error_States.LAYOUT404) !== -1 ? (
              <div className="error-message">
                <Icon name="table" width={120} />
                <p>
                  <FormattedMessage id="document-viewer.error-layout" />
                </p>
              </div>
            ) : (
              <Icon className="spinner spinner--centered" name="loader" width={80} />
            )}
          </div>
        ) : null}

        {currentDocumentLayout.isLoaded && currentDocumentLayout.layout.length > 0 ? (
          <DocumentRenderer
            history={history}
            initialLocation={initialLocation}
            readOnly={readOnly}
            searchResults={searchResults}
            selection={selection}
            currentSelection={currentSelection}
            selectionFunction={selectionFunction}
            focusFunction={focusFunction}
            isMenuOpen={isMenuOpen}
            updateMenu={updateMenu}
          />
        ) : null}

        {!(!currentDocument.isLoaded || documentStateErrors.length > 0) &&
        !(currentDocumentLayout.isLoaded && currentDocumentLayout.layout.length > 0) ? (
          <div className={`document-errors full`} />
        ) : null}
      </div>
    );
  }
}

export default MainPanel;
