import { connect } from 'react-redux';

import { loadComparisonSets } from 'store/comparison-sets';
import CompareSidebar from 'components/file-browser/compare-sidebar/compare-sidebar.js';

const mapStateToProps = ({ data: { comparisonSets } }) => ({
  comparisonSets
});

const mapDispatchToProps = {
  loadComparisonSets
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareSidebar);
