import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import dateUtil from 'utils/dateUtil';
import { omit } from 'underscore';

import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import UserAutocomplete from 'components/shared/user-autocomplete/user-autocomplete';
import Dropdown from 'components/shared/form/dropdown';
import NumericInput from 'components/shared/form/numeric-input';
import DatePicker from 'components/shared/date-picker/date-picker';
import enhanceWithClickOutside from 'react-click-outside';
import { getUsers } from 'store/api';

// Normal Block element within the sidebar
const Block = ({ data, node, heading }) => {
  return (
    <div className="schedule-report-sidebar__block">
      <span className="schedule-report-sidebar__block-heading">
        <FormattedMessage id={`schedule-report-sidebar.${heading}`} />
      </span>
      {node ? node : <span className="schedule-report-sidebar__block-text">{data}</span>}
    </div>
  );
};

Block.propTypes = {
  data: PropTypes.any,
  heading: PropTypes.string,
  node: PropTypes.node
};

// Email Recipients section
class EmailRecipients extends Component {
  static propTypes = {
    addEmail: PropTypes.func,
    deleteEmail: PropTypes.func,
    emails: PropTypes.array
  };
  state = {
    query: '',
    isEmailsOpen: false,
    isEmptyResults: false
  };

  handleClickOutside = () => {
    this.setState({ query: '', isEmailsOpen: false });
  };

  onEmailSelect = (val, item) => {
    this.props.addEmail(item);
    this.setState({ query: '', isEmailsOpen: false });
  };


  searchUsers = keyword => {
    return getUsers(keyword, true, true, true).then(res => {
      if (res.data.length <= 0) {
        this.setState({ isEmptyResults: true });
      } else {
        this.setState({ isEmptyResults: false });
      }

      return res.data;
    });
  };

  render() {
    const { emails } = this.props;
    const { isEmailsOpen, isEmptyResults, query } = this.state;
    return (
      <div className="schedule-report-sidebar__email">
        <div className="schedule-report-sidebar__email-list">
          <Button size="icon" onClick={() => this.setState({ isEmailsOpen: !isEmailsOpen })}>
            <Icon name="special-plus" width={18} size="icon" />
          </Button>
          {isEmailsOpen && (
            <div className="schedule-report-sidebar__email-popover popover">
              <span className="schedule-report-sidebar__email-popover-header">
                <FormattedMessage id="schedule-report-sidebar.email-search" />
              </span>
              <FormattedMessage id="schedule-report-sidebar.email-search-placeholder">
                {placeholder => (
                  <UserAutocomplete
                    keyword={query}
                    maxHeight="100px"
                    onChange={e => this.setState({ query: e.target.value })}
                    onSelect={this.onEmailSelect}
                    placeholder={placeholder}
                    searchUsers={this.searchUsers}
                    selectedUserName={query}
                  />
                )}
              </FormattedMessage>

              <span className="schedule-report-sidebar__email-popover-error">
                {isEmptyResults && <FormattedMessage id="schedule-report-sidebar.email-error" />}
              </span>
            </div>
          )}
          {emails.map((value, index) => {
            return (
              <div className="schedule-report-sidebar__email-item" key={index}>
                {value.email}
                <Button
                  className="schedule-report-sidebar__email-item-remove"
                  onClick={() => this.props.deleteEmail(value)}
                >
                  <Icon name="special-c-cross" width={16} height={16} />
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const EmailSection = enhanceWithClickOutside(EmailRecipients);

// Repeat Interval section
class RepeatInterval extends Component {
  static propTypes = {
    frequency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    intl: intlShape,
    onIntervalChange: PropTypes.func,
    onUnitChange: PropTypes.func,
    unit: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  render() {
    const { intl, frequency, unit } = this.props;
    const OPTIONS = [
      intl.formatMessage({ id: 'schedule-report-sidebar.interval.day' }),
      intl.formatMessage({ id: 'schedule-report-sidebar.interval.week' }),
      intl.formatMessage({ id: 'schedule-report-sidebar.interval.month' }),
      intl.formatMessage({ id: 'schedule-report-sidebar.interval.year' })
    ];

    return (
      <div className="schedule-report-sidebar__interval">
        <NumericInput
          className="schedule-report-sidebar__interval-range"
          value={frequency}
          onChange={value => this.props.onIntervalChange(value)}
          max={99}
          min={1}
        />
        <Dropdown
          className="schedule-report-sidebar__interval-dropdown"
          onSelection={option => this.props.onUnitChange(option)}
          options={OPTIONS}
          renderOption={option => option}
          selectedOption={unit}
        />
        <Button
          size="icon"
          className="icon-button"
          onClick={() => {
            this.props.onIntervalChange(1);
            this.props.onUnitChange(intl.formatMessage({ id: 'schedule-report-sidebar.interval.day' }));
          }}
        >
          <Icon name="special-cross" width={10} />
        </Button>
      </div>
    );
  }
}

const IntervalSection = injectIntl(RepeatInterval);

class ScheduleReportSidebar extends Component {
  static propTypes = {
    closeSidebar: PropTypes.func,
    createReport: PropTypes.func,
    data: PropTypes.object,
    deleteReport: PropTypes.func,
    editReport: PropTypes.func,
    intl: intlShape,
    onClose: PropTypes.func,
    tabViewer: PropTypes.string
  };

  state = {
    deleteDisable: false,
    disabled: true,
    emails: [],
    frequency: 1,
    unit: 1,
    reportDate: moment()
  };

  componentDidMount() {
    const { data } = this.props;

    this.setState({
      emails: data.recipients ? data.recipients : [],
      reportDate: data.startDate ? data.startDate : moment(),
      frequency: data.frequencyInterval ? data.frequencyInterval : 1,
      unit: data.frequencyIntervalUnits
        ? this.props.intl.formatMessage({
            id: `schedule-report-sidebar.interval.${this.getUnit(data.frequencyIntervalUnits)}`
          })
        : this.props.intl.formatMessage({
            id: `schedule-report-sidebar.interval.day`
          })
    });
  }

  // Determine whether the user can make a scheduled request
  componentDidUpdate(prevProps, prevState) {
    if (prevState.emails !== this.state.emails) {
      this.setState({ disabled: this.state.emails.length <= 0 });
    }
  }

  getUnit = unit => {
    if (unit === 1) {
      return 'day';
    } else if (unit === 2) {
      return 'week';
    } else if (unit === 3) {
      return 'month';
    } else if (unit === 4) {
      return 'year';
    }
    return 'day';
  };

  getServerNum = str => {
    if (str === 'Day') {
      return 1;
    } else if (str === 'Week') {
      return 2;
    } else if (str === 'Month') {
      return 3;
    } else if (str === 'Year') {
      return 4;
    }
    return 1;
  };

  // Organize data to send in to the api post/put request
  getPayload = () => {
    const { data } = this.props;
    const { frequency, unit, reportDate, emails } = this.state;

    return {
      reportId: data.reportId,
      frequencyInterval: frequency,
      frequencyIntervalUnits: this.getServerNum(unit),
      startDate: dateUtil.toServerDate(reportDate),
      recipients: emails.map(value => value.userId)
    };
  };

  onSave = () => {
    const { data, tabViewer, createReport, editReport, closeSidebar } = this.props;
    const createPayload = this.getPayload();
    const editPayload = omit(createPayload, 'reportId');

    this.setState({ disabled: true });

    if (tabViewer === 'LIST') {
      return createReport(createPayload).then(() => closeSidebar());
    }
    return editReport(data.scheduledReportId, editPayload).then(() => closeSidebar());
  };

  onDelete = () => {
    const { data, deleteReport, closeSidebar } = this.props;
    this.setState({ deleteDisable: true });
    return deleteReport(data.scheduledReportId)
      .then(() => closeSidebar())
      .catch(() => this.setState({ deleteDisable: false }));
  };

  addEmail = value => {
    const { emails } = this.state;

    // Ignore if this email was already added
    if (emails.find(user => user.userId === value.userId)) {
      return;
    }

    this.setState({ emails: emails.concat(value) });
  };

  deleteEmail = value => {
    const { emails } = this.state;
    this.setState({ emails: emails.filter(v => v.userId !== value.userId) });
  };

  handleClickOutside = () => {
    this.props.onClose();
  };

  render() {
    const { reportDate, frequency, unit, emails, disabled, deleteDisable } = this.state;
    const { onClose, data, tabViewer } = this.props;
    return (
      <div className="schedule-report-sidebar">
        <div className="schedule-report-sidebar__header">
          <h2 className="schedule-report-sidebar__title">
            {tabViewer === 'LIST' && <FormattedMessage id="schedule-report-sidebar.title-list" />}
            {tabViewer === 'SCHEDULED' && <FormattedMessage id="schedule-report-sidebar.title-scheduled" />}
          </h2>
          <Button size="icon" onClick={onClose} className="schedule-report-sidebar__close-button icon-button">
            <Icon name="special-cross" width={14} />
          </Button>
        </div>
        <div className="schedule-report-sidebar__content">
          <Block data={data.reportName} heading="name" />
          <Block data={data.reportDescription} heading="description" />
          <Block
            node={
              <IntervalSection
                frequency={frequency}
                unit={unit}
                onIntervalChange={value => this.setState({ frequency: value })}
                onUnitChange={value => this.setState({ unit: value })}
              />
            }
            heading="interval"
          />
          <Block
            node={<DatePicker selected={reportDate} onChange={date => this.setState({ reportDate: date })} />}
            heading="date"
          />
          <Block
            node={<EmailSection emails={emails} addEmail={this.addEmail} deleteEmail={this.deleteEmail} />}
            heading="email"
          />
        </div>
        <div className="schedule-report-sidebar__footer">
          {tabViewer === 'SCHEDULED' && (
            <Button className="btn btn-secondary" disabled={deleteDisable} onClick={this.onDelete}>
              <FormattedMessage id="common.delete" />
            </Button>
          )}
          <Button className="btn btn-primary btn-no-margin" disabled={disabled} onClick={this.onSave}>
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(ScheduleReportSidebar));
