import { FormattedMessage } from 'react-intl';
import React from 'react';

import { ITEM_TYPES, ITEM_STATUSES } from 'models/project-item';

export const ITEM_STATUS_NAMES = {
  [ITEM_STATUSES.PREUPLOAD]: <FormattedMessage id="file-list.item-status.uploaded" />,
  [ITEM_STATUSES.UPLOADED]: <FormattedMessage id="file-list.item-status.uploaded" />,
  [ITEM_STATUSES.PROCESSED]: <FormattedMessage id="file-list.item-status.complete" />,
  [ITEM_STATUSES.BADFILETYPE]: <FormattedMessage id="file-list.item-status.error-generic" />,
  [ITEM_STATUSES.BLOBSTORAGEERROR]: <FormattedMessage id="file-list.item-status.error-uploading" />,
  [ITEM_STATUSES.PROCESSINGERROR]: <FormattedMessage id="file-list.item-status.error-ocr" />,
  [ITEM_STATUSES.SYSTEMERROR]: <FormattedMessage id="file-list.item-status.error-generic" />,
  [ITEM_STATUSES.DELETED]: <FormattedMessage id="file-list.item-status.error-generic" />,
  [ITEM_STATUSES.PROCESSING]: <FormattedMessage id="file-list.item-status.processing" />,
  [ITEM_STATUSES.CONTENTPROCESSINGCOMPLETE]: <FormattedMessage id="file-list.item-status.processing" />,
  [ITEM_STATUSES.PARTIALLYEXTRACTED]: <FormattedMessage id="file-list.item-status.error-exracting" />,
  [ITEM_STATUSES.ALLEXTRACTIONERROR]: <FormattedMessage id="file-list.item-status.error-exracting" />,
  [ITEM_STATUSES.PENDINGDELETION]: <FormattedMessage id="file-list.item-status.pending-deletion" />
};

export const ITEM_STATUS_CLASSNAMES = {
  [ITEM_STATUSES.PREUPLOAD]: 'uploaded',
  [ITEM_STATUSES.UPLOADED]: 'uploaded',
  [ITEM_STATUSES.PROCESSED]: 'complete',
  [ITEM_STATUSES.BADFILETYPE]: 'error',
  [ITEM_STATUSES.BLOBSTORAGEERROR]: 'error',
  [ITEM_STATUSES.PROCESSINGERROR]: 'error',
  [ITEM_STATUSES.SYSTEMERROR]: 'error',
  [ITEM_STATUSES.DELETED]: 'error',
  [ITEM_STATUSES.PROCESSING]: 'processing',
  [ITEM_STATUSES.CONTENTPROCESSINGCOMPLETE]: 'processing',
  [ITEM_STATUSES.PARTIALLYEXTRACTED]: 'error',
  [ITEM_STATUSES.ALLEXTRACTIONERROR]: 'error',
  [ITEM_STATUSES.PENDINGDELETION]: 'error'
};

export const ITEM_TYPE_ICONS = {
  [ITEM_TYPES.FOLDER]: 'special-folder',
  [ITEM_TYPES.DOCUMENT]: 'special-doc'
};
