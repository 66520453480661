import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';

class DeleteModal extends Component {
  static propTypes = {
    children: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    singleMessages: PropTypes.bool
  };

  render() {
    const { children, onCancel, onSubmit, singleMessages } = this.props;

    return (
      <div className="modal__content modal__content--small delete-files-modal">
        <h3 className="delete-files-modal__title">
          <FormattedMessage id={`delete-file.modal.title${singleMessages ? '-single' : ''}`} />
        </h3>
        {children ? (
          children
        ) : (
          <p>
            <FormattedMessage id={`delete-file.modal.question${singleMessages ? '-single' : ''}`} />
          </p>
        )}
        <div className="modal__footer">
          <Button className="btn btn-secondary delete-files-modal__button" onClick={onCancel}>
            <FormattedMessage id="delete-file.modal.cancel-button" />
          </Button>
          <Button className="btn btn-primary delete-files-modal__button btn-no-margin" onClick={onSubmit}>
            <FormattedMessage id="delete-file.modal.delete-button" />
          </Button>
        </div>
      </div>
    );
  }
}

export default DeleteModal;
