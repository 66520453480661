import { connect } from 'react-redux';
import { loadLanguages } from 'store/intl/languages';
import { loadLanguageData } from 'store/intl/languageData';
import { setLanguage } from 'store/intl/currentLanguage';
import Intl from 'containers/intl/intl';

const mapStateToProps = ({ data: { languages, languageData, currentLanguage }, ui: { locale } }) => ({
  locale,
  languages,
  languageData,
  currentLanguage
});

const mapDispatchToProps = {
  loadLanguages,
  loadLanguageData,
  setLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Intl);
