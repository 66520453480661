import { Redirect } from 'react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CONSTANTS from 'utils/constants';

import UnknownErrorPage from 'components/pages/errors/generic-error';
import { DefaultLoader, ProjectLoader } from 'components/pages/loader';

/* A front-end middleware component that basically handles role-based authorization/redirection
 * For ex/ A project that has not been created yet cannot navigate to list of docs via url, etc
 */

class Authorized extends Component {
  static propTypes = {
    clearProjectData: PropTypes.func,
    component: PropTypes.any,
    currentProject: PropTypes.object,
    currentUser: PropTypes.object,
    getCurrentProject: PropTypes.func,
    getCurrentUser: PropTypes.func,
    location: PropTypes.object,
    match: PropTypes.object
  };

  componentDidMount() {
    const { currentUser, getCurrentUser, getCurrentProject, currentProject, clearProjectData } = this.props;
    const { projectId } = this.props.match.params;

    if (currentProject.STATES.Loaded() && !projectId) {
      // Clear off any remaining data when we're navigating to a page without any project details, ie homepage
      clearProjectData();
    }

    if (!currentUser.isLoaded && !currentUser.isLoading) {
      getCurrentUser();
    }

    if (projectId && !currentProject.isLoaded && !currentProject.isLoading) {
      getCurrentProject({ projectId });
    }

    if (currentUser.isLoaded && currentProject.isLoaded) {
      this.setRecentProject();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { getCurrentProject, getCurrentUser, clearProjectData } = this.props;
    const nextProjectId = nextProps.match.params.projectId;
    const nextRegionId = this.props.match.params.regionId;
    const { projectId, regionId } = this.props.match.params;

    if (projectId && !nextProjectId) {
      clearProjectData();
    }

    // When a user navigates to a url with a project id make sure we load the current project
    if (projectId !== nextProjectId && nextProjectId) {
      getCurrentProject({ projectId: nextProjectId });
    }

    // When a user navigates to a new region reload the current user
    if (regionId !== nextRegionId && nextRegionId) {
      getCurrentUser();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser, currentProject } = this.props;
    if (
      currentUser.isLoaded &&
      currentProject.isLoaded &&
      (!prevProps.currentUser.isLoaded || !prevProps.currentProject.isLoaded)
    ) {
      this.setRecentProject();
    }
  }

  // Cache this project in the user's recent projects list
  setRecentProject = () => {
    const { currentUser, currentProject } = this.props;

    // Project is in creation, projectType is workspace project and does not have a key yet, so ignore it
    if (!currentProject.projectKey || currentProject.projectTypeId == CONSTANTS.ProjectTypes.WORKSPACE_PROJECT && currentProject.createdBy ==  currentUser.userId ) {
      return;
    }

    // Get the recent projects from localStorage
    let recentProjects = JSON.parse(localStorage.getItem(`recentProjects-${currentUser.userId}`)) || [];

    // Remove the current project from the list of recent projects if it's already there and put it on the front of the list
    recentProjects = recentProjects.filter(({ projectKey }) => projectKey !== currentProject.projectKey);
    recentProjects.unshift({
      projectKey: currentProject.projectKey,
      timestamp: moment().valueOf()
    });

    // Limit the list to 3 items
    recentProjects = recentProjects.slice(0, 3);

    // Save the list to localStorage
    localStorage.setItem(`recentProjects-${currentUser.userId}`, JSON.stringify(recentProjects));
  };

  render() {
    const { projectId, documentId, compareId } = this.props.match.params;
    const { component: Component, currentProject, currentUser, location } = this.props;
    // Project creation pages
    // Todo: better handling of what pages to redirect to??
    const projectCreation = location.pathname.includes('creation');
    const projectFile = documentId || compareId || location.pathname.includes('folder');


    // When there is an error returned from the server
    if (currentProject.error || currentUser.error) {
      if (currentProject.error.response.status !== 404) {
        return <UnknownErrorPage />;
      }
    }

    if (location.pathname !== '/loading') {
      if (!currentUser.isLoaded || currentProject.isLoading) {
        return projectCreation ? (
          <ProjectLoader {...this.props} projectId={projectId} />
        ) : (
          <DefaultLoader {...this.props} projectId={projectId} />
        );
      }
    }

    // If a user navigates to a url that has a project Id
    if (projectId) {
      // We want to make sure data is loaded
      if (!currentProject.STATES.Loaded()) {
        return projectCreation ? (
          <ProjectLoader {...this.props} projectId={projectId} />
        ) : (
          <DefaultLoader {...this.props} projectId={projectId} />
        );
      }

      // Redirect users when they try to navigate to not allowed project urls
      if (
        projectFile &&
        (currentProject.STATES.Creation() || currentProject.STATES.Copy() || currentProject.STATES.CopyInCreation())
      ) {
        return <Redirect to="/project" />;
      }
    }

    // Return component when it's loaded !
    return <Component {...this.props} projectId={projectId} />;
  }
}

export default Authorized;
