import { connect } from 'react-redux';

import { updateProjectState, toggleSupportAccess } from 'store/current-project';
import {
  loadProjectMembers,
  addProjectMember,
  updateProjectMember,
  deleteProjectMember,
  clearProjectMember
} from 'store/project-members';
import TeamMembers from 'components/project-creation/team-members/team-members';

const mapStateToProps = ({ data: { projectMembers, currentProject } }) => ({
  projectMembers,
  currentProject
});

const mapDispatchToProps = {
  loadProjectMembers,
  addProjectMember,
  updateProjectMember,
  deleteProjectMember,
  updateProjectState,
  clearProjectMember,
  toggleSupportAccess
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembers);
