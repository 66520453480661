import model from 'models/base-model';
import moment from 'moment';

var storedLanguages = localStorage.getItem('languages');
var lastListUpdate = localStorage.getItem('language-list-last-updated');

export class Languages extends model({
  isLoading: false,
  isLoaded: false,
  error: null,
  languages: storedLanguages ? JSON.parse(storedLanguages) : [],
  timestamp: lastListUpdate
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      error: null
    });
  }
 
  /* ERROR */
  setError(error) {
    return this.merge({
      isLoading: false,
      isLoaded: false,
      error: error
    });
  }

  /* LOADED */
  setLoaded(state, action) {
    var timestamp = moment().toISOString();
    var languages = action.payload;

    localStorage.setItem('language-list-last-updated', timestamp);
    localStorage.setItem('languages', JSON.stringify(languages));

    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      languages: languages,
      timestamp: timestamp
    });
  }
}
