import Constants from 'utils/constants';

export function processTemplateExtractionFields(template, groups) {
  var processedFields = [];
  groups.forEach(group => {
    group.extractionFields.forEach(field =>
      processedFields.push({
        id: field.extractionFieldId,
        externalSystemId: field.externalSystemId,
        group: template.templateCategoryId == Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING && group.groupSystemName == Constants.CustomExtractionFieldGroups.GENAI_CUSTOM_GROUP_SYSTEMNAME ? 
          template.customDocTypeName : group.extractionFieldGroupName,
        groupId: group.extractionFieldGroupId,
        groupDisplayOrder: group.displayOrder,
        template: template.name,
        templateId: template.projectTemplateId,
        templateTypeID: template.templateTypeID,
        name: field.extractionFieldName,
        description: field.extractionFieldDescription,
        color: field.colorIndex,
        trainingCount: field.reviewDocumentCount,
        trainingTarget: field.minDocumentsToBeConsideredTrained,
        isTrained: field.isTrained,
        isAutomaticExtraction: field.isAutomaticExtraction,
        AccessTypeId: field.accessTypeId,
        isSelected: field.isSelected || false
      })
    );
  });
  return processedFields;
}

export function getExtractionFieldIds(fields) {
  var ids = [];
  if (!fields || fields.length === 0) return ids;
  fields.forEach(extraction => ids.push(extraction.id));
  return ids;
}

export function processGroupExtractions(data) {
  if (!data || data.length <= 0) return [];
  return data.map(value => {
    return {
      id: value.extractionFieldId,
      groupId: value.extractionFieldGroupId,
      name: value.extractionFieldName,
      color: value.colorIndex,
      trainingCount: value.reviewDocumentCount,
      trainingTarget: value.minDocumentsToBeConsideredTrained,
      isTrained: value.isTrained,
      isAutomaticExtraction: value.isAutomaticExtraction,
      AccessTypeId: value.accessTypeId,
      ...(value.externalSystemId && { externalSystemId: value.externalSystemId })
    };
  });
}
