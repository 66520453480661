import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { EXPORT_FORMATS, EXPORT_LAYOUTS } from 'components/file-browser/export-sidebar/constants';
import { ITEM_TYPES, ITEM_STATUSES } from 'models/project-item';
import { RadioGroup, Radio } from 'components/shared/form/radio';
import emptyStateImage from 'images/empty_state_comp_03.svg';
import Icon from 'components/shared/icon';
import Switch from 'components/shared/form/switch';
import TagsList from 'components/file-browser/export-sidebar/export-sidebar-tags-list';
import TemplatesList from 'components/file-browser/export-sidebar/export-sidebar-templates-list';
import Button from 'components/shared/single-click-button';

class Create extends Component {
  static propTypes = {
    currentFolder: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    format: PropTypes.number.isRequired,
    intl: intlShape,
    isDownloadPerDocument: PropTypes.bool.isRequired,
    layout: PropTypes.number.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onFormatChange: PropTypes.func.isRequired,
    onLayoutChange: PropTypes.func.isRequired,
    onQualityChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    quality: PropTypes.number.isRequired,
    selectedFieldsByTemplateId: PropTypes.object.isRequired,
    selectedItems: PropTypes.object.isRequired,
    selectedTags: PropTypes.array.isRequired,
    setSelectedFieldsByTemplateId: PropTypes.func.isRequired,
    setSelectedTags: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    templates: PropTypes.array.isRequired,
    toggleIsDownloadPerDocument: PropTypes.func.isRequired
  };

  state = {
    isShowingTagsList: false,
    isShowingTemplates: false
  };

  onSelectedTagsChange = selectedTags => {
    this.props.setSelectedTags(selectedTags);
    this.setState({
      isShowingTagsList: false
    });
  };

  onSelectedFieldsChange = selectedFieldsByTemplateId => {
    this.props.setSelectedFieldsByTemplateId(selectedFieldsByTemplateId);
    this.setState({
      isShowingTemplatesList: false
    });
  };

  toggleIsShowingTagsList = () => {
    const { isShowingTagsList } = this.state;
    this.setState({
      isShowingTagsList: !isShowingTagsList
    });
  };

  toggleIsShowingTemplatesList = () => {
    const { isShowingTemplatesList } = this.state;
    this.setState({
      isShowingTemplatesList: !isShowingTemplatesList
    });
  };

  get selectedItems() {
    const { currentFolder, selectedItems } = this.props;
    return currentFolder.children.filter(
      projectItem =>
        selectedItems[projectItem.projectItemId] &&
        (projectItem.itemTypeId === ITEM_TYPES.FOLDER || projectItem.itemStateId === ITEM_STATUSES.PROCESSED)
    );
  }

  onSubmit = event => {
    event.preventDefault();
    this.props.onSubmit();
  };

  renderFormatSection() {
    const { format, onFormatChange } = this.props;

    return (
      <div className="export-sidebar-create__section">
        <div className="export-sidebar-create__section-label">
          <FormattedMessage id="export-sidebar.create.format.label" />
        </div>
        <RadioGroup
          className="export-sidebar-create__radio-group"
          name="format-select"
          selectedValue={format}
          onChange={onFormatChange}
        >
          <div className="export-sidebar-create__radio-item">
            <Radio
              className="export-sidebar-create__radio-button"
              id="export-sidebar-create__radio-excel"
              value={EXPORT_FORMATS.EXCEL}
            />
            <label htmlFor="export-sidebar-create__radio-excel">
              <FormattedMessage id="export-sidebar.create.format.option.excel" />
            </label>
          </div>

          <div className="export-sidebar-create__radio-item">
            <Radio
              className="export-sidebar-create__radio-button"
              id="export-sidebar-create__radio-pdf"
              value={EXPORT_FORMATS.PDF}
            />
            <label htmlFor="export-sidebar-create__radio-pdf">
              <FormattedMessage id="export-sidebar.create.format.option.pdf" />
            </label>
          </div>

          <div className="export-sidebar-create__radio-item">
            <Radio
              className="export-sidebar-create__radio-button"
              id="export-sidebar-create__radio-word"
              value={EXPORT_FORMATS.WORD}
            />
            <label htmlFor="export-sidebar-create__radio-word">
              <FormattedMessage id="export-sidebar.create.format.option.word" />
            </label>
          </div>
        </RadioGroup>
      </div>
    );
  }

  renderLayoutSection() {
    const { format, layout, onLayoutChange } = this.props;

    return (
      <div className="export-sidebar-create__section">
        <div className="export-sidebar-create__section-label">
          <FormattedMessage id="export-sidebar.create.layout.label" />
        </div>
        <RadioGroup
          className="export-sidebar-create__radio-group"
          name="layout-select"
          selectedValue={layout}
          onChange={onLayoutChange}
        >
          <div className="export-sidebar-create__radio-item">
            <Radio
              className="export-sidebar-create__radio-button"
              id="export-sidebar-create__radio-row"
              value={EXPORT_LAYOUTS.ROW}
            />
            <label htmlFor="export-sidebar-create__radio-row">
              <FormattedMessage id="export-sidebar.create.layout.option.row" />
            </label>
          </div>

          <div className="export-sidebar-create__radio-item">
            <Radio
              className="export-sidebar-create__radio-button"
              id="export-sidebar-create__radio-column"
              value={EXPORT_LAYOUTS.COLUMN}
            />
            <label htmlFor="export-sidebar-create__radio-column">
              <FormattedMessage id="export-sidebar.create.layout.option.column" />
            </label>
          </div>

          {format === EXPORT_FORMATS.WORD && (
            <div className="export-sidebar-create__radio-item">
              <Radio
                className="export-sidebar-create__radio-button"
                id="export-sidebar-create__radio-table"
                value={EXPORT_LAYOUTS.TABLE}
              />
              <label htmlFor="export-sidebar-create__radio-table">
                <FormattedMessage id="export-sidebar.create.layout.option.table" />
              </label>
            </div>
          )}
        </RadioGroup>
      </div>
    );
  }

  /*
  Commented out until a later release of Argus.  See task 184392

  renderQualitySection() {
    const { quality, onQualityChange } = this.props;

    return (
      <div className="export-sidebar-create__section">
        <div className="export-sidebar-create__section-label">
          <FormattedMessage id="export-sidebar.create.quality.label" />
        </div>
        <RadioGroup
          className="export-sidebar-create__radio-group"
          name="layout-select"
          selectedValue={quality}
          onChange={onQualityChange}
        >
          <div className="export-sidebar-create__radio-item">
            <Radio
              className="export-sidebar-create__radio-button"
              id="export-sidebar-create__radio-high"
              value={EXPORT_QUALITY.HIGH}
            />
            <label htmlFor="export-sidebar-create__radio-high">
              <FormattedMessage id="export-sidebar.create.quality.option.high" />
            </label>
          </div>

          <div className="export-sidebar-create__radio-item">
            <Radio
              className="export-sidebar-create__radio-button"
              id="export-sidebar-create__radio-medium"
              value={EXPORT_QUALITY.MEDIUM}
            />
            <label htmlFor="export-sidebar-create__radio-medium">
              <FormattedMessage id="export-sidebar.create.quality.option.medium" />
            </label>
          </div>

          <div className="export-sidebar-create__radio-item">
            <Radio
              className="export-sidebar-create__radio-button"
              id="export-sidebar-create__radio-low"
              value={EXPORT_QUALITY.LOW}
            />
            <label htmlFor="export-sidebar-create__radio-low">
              <FormattedMessage id="export-sidebar.create.quality.option.low" />
            </label>
          </div>
        </RadioGroup>
      </div>
    );
  }
  */

  render() {
    const {
      format,
      onCloseClick,
      isDownloadPerDocument,
      selectedFieldsByTemplateId,
      selectedItems,
      selectedTags,
      tags,
      templates,
      toggleIsDownloadPerDocument
    } = this.props;

    const { isShowingTagsList, isShowingTemplatesList } = this.state;

    if (isShowingTagsList) {
      return (
        <TagsList
          onBackClick={this.toggleIsShowingTagsList}
          onCloseClick={onCloseClick}
          selectedItems={selectedItems}
          onSubmit={this.onSelectedTagsChange}
          selectedTags={selectedTags}
          tags={tags}
        />
      );
    }

    if (isShowingTemplatesList) {
      return (
        <TemplatesList
          onBackClick={this.toggleIsShowingTemplatesList}
          onCloseClick={onCloseClick}
          onSubmit={this.onSelectedFieldsChange}
          selectedFieldsByTemplateId={selectedFieldsByTemplateId}
          templates={templates}
        />
      );
    }

    if (!this.selectedItems.length) {
      return (
        <div className="export-sidebar-empty">
          <img className="export-sidebar-empty__img" alt="no selected items" src={emptyStateImage} />
          <h2 className="export-sidebar-empty__title">
            <FormattedMessage id="export-sidebar.no-selected-items.title" />
          </h2>
          <p className="export-sidebar-empty__description">
            <FormattedMessage id="export-sidebar.no-selected-items.description" />
          </p>
        </div>
      );
    }

    // Total number of selected extraction fields across all templates
    const numSelectedFields = Object.values(selectedFieldsByTemplateId).reduce(
      (sum, selectedExtractionFields) => sum + selectedExtractionFields.size,
      0
    );

    // Total number of extraction fields across all templates
    const numExtractionFields = templates.reduce((sum, template) => sum + template.extractionFields.length, 0);

    // Number of templates with all their fields selected
    const numFullySelectedTemplates = templates.filter(template => {
      const selectedExtractionFields = selectedFieldsByTemplateId[template.projectTemplateId];
      return (
        selectedExtractionFields &&
        selectedExtractionFields.size &&
        selectedExtractionFields.size === template.extractionFields.length
      );
    }).length;

    // Number of templates with none of their fields selected
    const numFullyUnSelectedTemplates = templates.filter(template => {
      const selectedExtractionFields = selectedFieldsByTemplateId[template.projectTemplateId];
      return !selectedExtractionFields || !selectedExtractionFields.size;
    }).length;

    const plurality = templates.length === 1 ? 'singular' : 'plural';

    return (
      <form className="export-sidebar-create" onSubmit={this.onSubmit}>
        <div className="export-sidebar-create__scroll-container">
          <div className="export-sidebar-create__section">
            <div className="export-sidebar-create__instructions">
              <div>
                <FormattedMessage id="export-sidebar.create.instruction.1" />
              </div>
              <div>
                <strong>
                  <FormattedMessage id="export-sidebar.create.instruction.2" />
                </strong>
              </div>
            </div>
          </div>
          {this.renderFormatSection()}
          {format === EXPORT_FORMATS.WORD && (
            <div className="export-sidebar-create__word-file-options">
              <FormattedMessage id="export-sidebar.create.one-file-per-doc" />
              <Switch
                isOn={isDownloadPerDocument}
                id={'export-sidebar-create__word-file-options-switch'}
                onChange={toggleIsDownloadPerDocument}
              />
            </div>
          )}
          {format === EXPORT_FORMATS.EXCEL && this.renderLayoutSection()}
          {/*
          Commented out until a later release of Argus.  See task 184392
          format === EXPORT_FORMATS.PDF && this.renderQualitySection()
          */}
          {format !== EXPORT_FORMATS.PDF && (
            <Button type="button" className="export-sidebar-create__section" onClick={this.toggleIsShowingTagsList}>
              <div className="export-sidebar-create__section-label">
                <FormattedMessage id="export-sidebar.create.tags.label" />
              </div>
              <div className="export-sidebar-create__tags">
                <div>
                  <FormattedMessage
                    id="export-sidebar.create.tags.text"
                    values={{ num: selectedTags.length, total: tags.length }}
                  />
                </div>
                <Icon name="special-arrow-up-blue" rotate={90} width={14} />
              </div>
            </Button>
          )}
          <Button type="button" className="export-sidebar-create__section" onClick={this.toggleIsShowingTemplatesList}>
            <div className="export-sidebar-create__section-label">
              <FormattedMessage id="export-sidebar.create.templates.label" />
            </div>
            <div className="export-sidebar-create__templates">
              <div>
                <FormattedMessage
                  id={
                    numFullySelectedTemplates === templates.length
                      ? `export-sidebar.create.templates.text1.${plurality}`
                      : numFullySelectedTemplates + numFullyUnSelectedTemplates === templates.length
                      ? `export-sidebar.create.templates.text2.${plurality}`
                      : `export-sidebar.create.templates.text3.${plurality}`
                  }
                  values={{
                    numFullySelectedTemplates,
                    numExtractions: numSelectedFields,
                    totalExtractions: numExtractionFields,
                    numTemplates: templates.length
                  }}
                />
              </div>
              <Icon name="special-arrow-up-blue" rotate={90} width={14} />
            </div>
          </Button>
        </div>
        <div className="export-sidebar-create__footer">
          <Button type="submit" className="btn btn-primary btn-no-margin" disabled={!numSelectedFields}>
            <FormattedMessage id="export-sidebar.create.export-button" />
          </Button>
        </div>
      </form>
    );
  }
}

export default injectIntl(Create);
