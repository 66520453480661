import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import { debounce } from 'underscore';
import { getDocumentPageBinary } from 'store/api';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import Permissions from 'permissions/permissions';
import classNames from 'classnames';
import { SELECTION_TYPE } from './constants';
import SampleNumberInputField from './sample-number/sample-number-input-field';

class DocumentSplitter extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number,
    currentProject: PropTypes.object.isRequired,
    documentId: PropTypes.number.isRequired,
    extractionFieldRecords: PropTypes.array.isRequired,
    getCurrentDocument: PropTypes.func,
    isMenuOpen: PropTypes.bool,
    organizeDocuments: PropTypes.func.isRequired,
    pageImageLoaded: PropTypes.bool,
    pageList: PropTypes.array.isRequired,
    projectId: PropTypes.number.isRequired,
    removeDocType: PropTypes.func.isRequired,
    sectionLoading: PropTypes.any,
    sections: PropTypes.array.isRequired,
    setExtractionFieldFilter: PropTypes.func.isRequired,
    updateMenu: PropTypes.func,
    updateSampleNumber: PropTypes.func.isRequired,
    clearUndoStack: PropTypes.func
  };

  state = {
    canOrganize: true,
    isThumbnailsLoading: false,
    pagesToOrganize: [],
    thumbnails: [],
    textCounter: 0
  };

  scrollArea = null;

  componentDidMount() {
    const { pageList, setExtractionFieldFilter, currentPage, sections } = this.props;
    this.setState({ thumbnails: pageList.map(({ pageNumber }) => ({ pageNumber: pageNumber, loaded: false })) }, () => {
      const selectedSection = sections
        ? sections.find(({ start, length }) => currentPage >= start && currentPage < start + length)
        : { start: 1, length: 1 };
      setExtractionFieldFilter({
        ...selectedSection,
        sectionId: 0,
        currentPage: currentPage,
        isPage: true
      });
      this.loadThumbnailsInViewport();
    });

    if (this.scrollArea) {
      this.scrollArea.addEventListener('scroll', this.scrollChange);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentPage,
      sections,
      setExtractionFieldFilter,
      pageList,
      documentId,
      extractionFieldRecords
    } = this.props;

    if (this.scrollArea) {
      this.loadThumbnailsInViewport();
      this.scrollArea.addEventListener('scroll', this.scrollChange);
    } else {
      this.loadThumbnailsInViewport();
    }

    if (prevProps.currentPage !== currentPage) {
      this.scrollIntoThumbnail();
      const selectedSection = sections
        ? sections.find(({ start, length }) => currentPage >= start && currentPage < start + length)
        : { start: 1, length: 1 };

      this.setState(
        {
          pagesToOrganize: [
            {
              length: 1,
              start: currentPage,
              type: SELECTION_TYPE.PAGE,
              sectionId: selectedSection.start,
              sectionLength: selectedSection.length,
              extractionFieldGroupIds: selectedSection.extractionFieldGroupIds
            }
          ]
        },
        () =>
          setExtractionFieldFilter({
            ...selectedSection,
            sectionId: selectedSection.start,
            currentPage: currentPage,
            isPage: true
          })
      );
    } else if (prevProps.extractionFieldRecords !== extractionFieldRecords) {
      const selectedSection = sections
        ? sections.find(({ start, length }) => currentPage >= start && currentPage < start + length)
        : { start: 1, length: 1 };
      setExtractionFieldFilter({
        ...selectedSection,
        sectionId: selectedSection.start,
        currentPage: currentPage,
        isPage: true
      });
    }

    if (prevProps.documentId !== documentId || prevProps.pageList.length !== pageList.length) {
      this.setState({ thumbnails: pageList.map(({ pageNumber }) => ({ pageNumber: pageNumber, loaded: false })) });
    }
  }

  scrollIntoThumbnail = () => {
    const { currentPage, isMenuOpen } = this.props;

    if (isMenuOpen) {
      document
        .getElementById(`thumbnail-${currentPage}`)
        .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      this.loadThumbnailsInViewport();
    }
  };

  loadThumbnailsInViewport = () => {
    const { thumbnails, isThumbnailsLoading } = this.state;

    if (!this.scrollArea || isThumbnailsLoading) return;
    const { projectId, documentId } = this.props;

    const scrollAreaBounds = this.scrollArea.getBoundingClientRect();
    const newThumbnails = [];
    for (let i = 0; i < thumbnails.length; i++) {
      const thumbnailPage = document.getElementById(`thumbnail-${i + 1}`);
      if (!thumbnails[i].loaded && thumbnailPage) {
        const page = thumbnailPage.getBoundingClientRect();
        if (
          (scrollAreaBounds.top < page.top && page.top < scrollAreaBounds.bottom) ||
          (scrollAreaBounds.top < page.bottom && page.bottom < scrollAreaBounds.bottom) ||
          (page.top < scrollAreaBounds.top && page.bottom > scrollAreaBounds.bottom)
        ) {
          newThumbnails.push(i + 1);
        }
      }
    }

    if (newThumbnails.length) {
      this.setState({ isThumbnailsLoading: true });
      Promise.all(
        newThumbnails.map(page =>
          getDocumentPageBinary(projectId, documentId, page, true).then(data => ({
            url: URL.createObjectURL(new Blob([data])),
            page
          }))
        )
      ).then(data =>
        this.setState(
          {
            thumbnails: thumbnails.map(page =>
              newThumbnails.includes(page.pageNumber)
                ? { ...page, loaded: true, url: data.find(response => response.page === page.pageNumber).url }
                : page
            ),
            isThumbnailsLoading: false
          },
          () => this.loadThumbnailsInViewport()
        )
      );
    }
  };

  scrollChange = debounce(event => {
    this.loadThumbnailsInViewport();
  }, 100);

  onOrganizeDocuments = method => {
    const { pagesToOrganize } = this.state;
    const { projectId, documentId, currentPage, organizeDocuments, setExtractionFieldFilter } = this.props;
    const length =
      method === SELECTION_TYPE.DOCUMENT
        ? pagesToOrganize.length
        : pagesToOrganize[pagesToOrganize.length - 1].start - pagesToOrganize[0].start + 1;

    const payload = {
      projectId: projectId,
      documentId: documentId,
      method: method,
      start: pagesToOrganize[0].start,
      length: length
    };

    organizeDocuments(payload).then(response =>
      this.setState(
        {
          pagesToOrganize: [
            {
              length: 1,
              start: currentPage,
              type: SELECTION_TYPE.PAGE,
              sectionId: payload.start,
              sectionLength: payload.length
            }
          ]
        },
        () => {
          const selectedSection = response.payload.sections
            ? response.payload.sections.find(
                ({ start, length }) => currentPage >= start && currentPage < start + length
              )
            : { start: 1, length: 1 };
          setExtractionFieldFilter({
            ...selectedSection,
            sectionId: selectedSection.start,
            currentPage: currentPage,
            isPage: false
          });
        }
      )
    );
  };

  handleClick = (section, event, type, pageNumber = 0) => {
    const { pagesToOrganize } = this.state;
    const { currentPage, setExtractionFieldFilter } = this.props;
    event.stopPropagation();

    let pageInfo = {
      start: type === SELECTION_TYPE.PAGE ? pageNumber : section.start,
      length: type === SELECTION_TYPE.PAGE ? 1 : section.length,
      type: type,
      isClassified: section.isClassified,
      extractionFieldGroupIds: section.extractionFieldGroupIds,
      ...(type === SELECTION_TYPE.PAGE && { sectionId: section.start, sectionLength: section.length })
    };

    if (event.ctrlKey && pagesToOrganize.length) {
      const canAppend = pagesToOrganize.every(page => page.type === type);
      const sameSection = pageInfo.sectionId === pagesToOrganize[0].sectionId;
      let pages = [pageInfo];
      if (canAppend && sameSection) {
        pages = !pagesToOrganize.find(page => page.start === pageInfo.start)
          ? pagesToOrganize.concat(pageInfo)
          : pagesToOrganize.filter(page => page.start !== pageInfo.start);
        pages.sort((a, b) => a.start - b.start);
      }
      const areContiguoussAndSameGroup = pages.reduce((acc, curr, index, temp) => {
        if (index === temp.length - 1) {
          return acc;
        }
        return (
          acc &&
          curr.start + curr.length === temp[index + 1].start &&
          curr.extractionFieldGroupIds[0] === temp[index + 1].extractionFieldGroupIds[0]
        );
      }, true);
      this.setState({ pagesToOrganize: pages }, () =>
        this.setState({ canOrganize: canAppend ? areContiguoussAndSameGroup : true })
      );
    } else {
      this.setState(
        {
          pagesToOrganize: [pageInfo]
        },
        () => this.setState({ canOrganize: true })
      );
      document
        .getElementById(`page-${type === SELECTION_TYPE.PAGE ? pageNumber : section.start}`)
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  renderSection = (section, block) => {
    const { pagesToOrganize, thumbnails } = this.state;
    let result = [];
    let index = section.start - 1;

    for (let i = index; i < index + section.length; i++) {
      if (thumbnails[i] && thumbnails[i].loaded) {
        result.push(
          <div id={`thumbnail-${i + 1}`} className={`${block}__thumbnail`} key={i}>
            <img
              onClick={event => this.handleClick(section, event, SELECTION_TYPE.PAGE, i + 1)}
              alt={`Page ${i + 1} thumbnail`}
              className={classNames({
                active:
                  pagesToOrganize.length &&
                  pagesToOrganize.find(page => page.start === i + 1 && page.type === SELECTION_TYPE.PAGE)
              })}
              src={thumbnails[i].url}
            ></img>
            <span>{i + 1}</span>
          </div>
        );
      } else {
        result.push(
          <div id={`thumbnail-${i + 1}`} className={`${block}__thumbnail ${block}__thumbnail--loading`} key={i}>
            <Icon className="spinner spinner--centered" name="loader" width={70} />
            <span>{i + 1}</span>
          </div>
        );
      }
    }
    return result;
  };

  handleMenuClick = () => {
    const { isMenuOpen, updateMenu } = this.props;
    if (!isMenuOpen) {
      this.scrollIntoThumbnail();
    }
    updateMenu(!isMenuOpen);
  };

  removeDocType = section => {
    const { getCurrentDocument, removeDocType, projectId, documentId, clearUndoStack } = this.props;
    const sectionStart = section.start;
    removeDocType(projectId, documentId, sectionStart).then(() => {
      clearUndoStack();
      getCurrentDocument(projectId, documentId)
    });
    
  };

  render() {
    const block = 'document-splitter';
    const { canOrganize, pagesToOrganize } = this.state;
    const {
      sections,
      projectId,
      documentId,
      currentProject,
      isMenuOpen,
      updateSampleNumber,
      sectionLoading
    } = this.props;

    let cantCombine,
      cantSplit = true;
    if (pagesToOrganize.length) {
      cantCombine =
        !(canOrganize && pagesToOrganize.every(page => page.type === SELECTION_TYPE.DOCUMENT)) ||
        pagesToOrganize.length === 1;
      cantSplit =
        !(canOrganize && pagesToOrganize.every(page => page.type === SELECTION_TYPE.PAGE)) ||
        pagesToOrganize.length === pagesToOrganize[0].sectionLength;
    }
    return (
      <div className={block}>
        {!isMenuOpen ? (
          <Button
            size="icon"
            className={`${block}__icon-button`}
            onClick={() => this.handleMenuClick()}
            data-tip
            data-for="document-splitter-button"
          >
            <Icon name="documents" width={18} />
            <ReactTooltip id="document-splitter-button" effect="solid" place="right">
              <FormattedMessage id="document-splitter-button" />
            </ReactTooltip>
          </Button>
        ) : (
          <div className={`${block}__menu`}>
            <div className={`${block}__top`}>
              <div className={`${block}__top-header`}>
                <h2 className={`${block}__top-title`}>
                  <FormattedMessage id="document-splitter-file-organizer" />
                </h2>
                <Button
                  size="icon"
                  className={`${block}__close-button icon-button`}
                  onClick={() => this.handleMenuClick()}
                  data-tip
                  data-for="document-splitter-close"
                >
                  <Icon name="special-cross-black" width={11} />
                  <ReactTooltip id="document-splitter-close" effect="solid" place="bottom">
                    <FormattedMessage id="document-splitter-close" />
                  </ReactTooltip>
                </Button>
              </div>
              {Permissions.Project.Document.canModify() &&
                !Permissions.Project._States.isProjectArchived(currentProject) && (
                  <div className={`${block}__top-options`}>
                    <Button
                      className={classNames(`${block}__top-button`, cantCombine && 'disabled')}
                      disabled={cantCombine}
                      onClick={() => this.onOrganizeDocuments(SELECTION_TYPE.DOCUMENT)}
                    >
                      <FormattedMessage id="document-splitter-combine" />
                    </Button>
                    <Button
                      className={classNames(`${block}__top-button`, cantSplit && 'disabled')}
                      disabled={cantSplit}
                      onClick={() => this.onOrganizeDocuments(SELECTION_TYPE.PAGE)}
                    >
                      <FormattedMessage id="document-splitter-split" />
                    </Button>
                  </div>
                )}
            </div>
            <div className={`${block}__thumbnail-wrapper`} ref={element => (this.scrollArea = element)}>
              {sections.map((section, index) => {
                return (
                  <div className={`${block}__section-wrapper`} key={index}>
                    <span
                      className={`${block}__section-name`}
                      onClick={event => this.handleClick(section, event, SELECTION_TYPE.DOCUMENT)}
                    >
                      {section.name}
                    </span>
                    {section.extractionFieldGroupIds.length !== 0 && (
                      <Button
                        size="icon"
                        className={`${block}__close-button`}
                        onClick={() => this.removeDocType(section)}
                        data-tip
                        data-for="document-splitter-remove"
                      >
                        <Icon name="special-cross-grey" width={8} />
                        <ReactTooltip id="document-splitter-remove" effect="solid" place="bottom">
                          <FormattedMessage id="document-splitter-remove" />
                        </ReactTooltip>
                      </Button>
                    )}
                    <div className={`${block}__sample-number`}>
                      <div className={`${block}__sample-number-title`}>
                        <FormattedMessage id="document-splitter-sample-number" />
                      </div>
                      <SampleNumberInputField
                        block={block}
                        section={section}
                        projectId={projectId}
                        documentId={documentId}
                        sampleNumber={section.sample !== null ? section.sample : ''}
                        sectionLoading={sectionLoading}
                        updateSampleNumber={updateSampleNumber}
                        readOnly={!Permissions.Project.Document.canModify() || currentProject.STATES.ReadOnly()}
                      ></SampleNumberInputField>
                    </div>
                    <div
                      className={classNames(`${block}__section section`, 'has-border document-section', {
                        active:
                          pagesToOrganize.length &&
                          pagesToOrganize.find(
                            page => page.start === section.start && page.type === SELECTION_TYPE.DOCUMENT
                          )
                      })}
                      onClick={event => this.handleClick(section, event, SELECTION_TYPE.DOCUMENT)}
                    >
                      {this.renderSection(section, block)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DocumentSplitter;
