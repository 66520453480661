import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from 'components/shared/icon';

import NotificationsContainer from 'containers/shared/notifications-container';

import { getHomeRegion, setCurrentRegion } from 'store/api';
import Permissions from 'permissions/permissions';
import history from 'utils/history';

class LoadingPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    setCurrentRegion(getHomeRegion());

    if (!Permissions.Global.canViewProjects()) {
      history.push('/unauthorized');
    }
  }

  render() {
    return (
      <div className="page">
        <Icon className="spinner spinner--centered" name="loader" width={80} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default LoadingPage;
