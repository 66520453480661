import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import Dropzone from 'dropzone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getCurrentRegion } from 'store/api';
import { ITEM_TYPES, ITEM_STATUSES } from 'models/project-item';
import { TOOLBAR_OPTIONS } from 'store/file-browser-toolbar-options';
import CompareSidebarContainer from 'containers/file-browser/compare-sidebar-container';
import CreateFolderModal from 'components/file-browser/create-folder-modal';
import EmptyState from 'components/file-browser/file-list/empty-state';
import emptyStateImage from 'images/empty_state_file_browser.svg';
import ExportSidebarContainer from 'containers/file-browser/export-sidebar-container';
import FileBrowserToolbarContainer from 'containers/shared/file-browser-toolbar-container';
import FileListContainer from 'containers/file-browser/file-list-container';
import ProgressLoader from 'components/shared/progress-loader';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Permissions from 'permissions/permissions';
import UploadButton from 'components/file-browser/upload-button/upload-button';
import UploadStatusContainer from 'containers/file-browser/upload-status-container';
import KiraProcessingPausedModal from 'components/shared/kira-processing-paused-modal';
import ProjectDateFormatSelection from 'components/project-creation/shared/project-date-fromat-selection';
import Constants from 'utils/constants';

export class FileBrowser extends Component {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    createFolder: PropTypes.func.isRequired,
    currentFolder: PropTypes.object.isRequired,
    currentFolderInvalidate: PropTypes.func,
    currentProject: PropTypes.object.isRequired,
    folderId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isUploadInProgress: PropTypes.bool,
    loadCurrentFolder: PropTypes.func.isRequired,
    location: PropTypes.object,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.object.isRequired,
    unsubscribeCurrentFolder: PropTypes.func.isRequired,
    upload: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.dropzoneElem = null;
    this.dragEnterElem = null;
    this.deleteTimeout = null;
  }

  state = {
    activeToolbarOption: null,
    currentPage: 1,
    filesToUpload: [],
    isCreateFolderModalShown: false,
    isDraggingFile: false,
    isPendingDeletion: false,
    isSearchResultsPage: false,
    isFiltering: false,
    KiraProcessingModalOpen : false,
    kiraTemplates : [],
    showDateSelectionPopup: false,
    filesArray : [],
  }

  componentDidMount() {
    const {
      folderId,
      loadCurrentFolder,
      projectId,
      currentProject,
      history,
      location: { state: locationState }
    } = this.props;
    const readOnly = currentProject.STATES.ReadOnly();
    const folderPageSizeUserSelection = localStorage.getItem(Constants.LocalStorageKeys.FILE_LIST_PAGE_SIZE);
    history.replace(); // clean location state

    let folderOptions = { folderId, projectId, forceFetch: true };

    if (folderPageSizeUserSelection) {
      folderOptions.pageSize = folderPageSizeUserSelection;
    }

    // Load the current folder
    loadCurrentFolder(folderOptions)
      .then(() => {
        if (this.getPendingDeleteItems().length > 0) {
          return this.setState({ isPendingDeletion: true });
        }
      })
      .catch(this.onCurrentFolderLoadError);

    if (readOnly) {
      return;
    }

    // Initialize dropzone for file upload by drag and drop
    const dropzone = new Dropzone(this.dropzoneElem, {
      autoProcessQueue: false,
      clickable: false,
      previewTemplate: '<span/>', // required but unused
      uploadMultiple: true,
      url: '/files' // required but unused
    });

    // Append a file to the list of files to upload
    // This gets called once per file when dragging multiple files
    dropzone.on('addedfile', file => {
      let dateFormatPreference = this.props.currentFolder?.dateFormatPreference;
      let templateCategoryID = this.props.currentFolder?.templateCategoryID;
      const isDateFormatSaved = dateFormatPreference  != null ? true : sessionStorage.getItem('isDateFormatSaved-'+this.props.currentFolder.projectItemId);
      if (Permissions.Project.Document.canUpload()) {
        if ((templateCategoryID == Constants.TemplateCategories.DETAIL_TESTING || templateCategoryID == Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING ) && (!isDateFormatSaved && (dateFormatPreference == null || dateFormatPreference == '')) &&  ((Constants.RestrictDateFormatForSpecificContainer.indexOf(currentProject.containerCode) == -1 ))) {
          const { filesArray } = this.state;
          this.setState({ showDateSelectionPopup: true });
          this.setState({
            filesArray: [file, ...filesArray]
          });
        } 
        else 
        {
          const { filesToUpload } = this.state;
          this.setState({
            filesToUpload: [file, ...filesToUpload]
          });
        }
      }
    });

    dropzone.on('dragenter', event => {
      this.dragEnterElem = event.target;
      if (event.dataTransfer.types.indexOf('Files') === -1) {
        return;
      }
      this.setState({ isDraggingFile: true });
    });

    dropzone.on('dragleave', event => {
      // If the drag leave event is on the last element that was entered,
      // mark dragging as done
      if (this.dragEnterElem === event.target) {
        this.setState({ isDraggingFile: false });
      }
    });

    dropzone.on('drop', event => {
      this.setState({ isDraggingFile: false });
    });

    window.addEventListener('scroll', this.onFileListScroll);

    if (locationState?.filesToUpload?.length > 0) {
      if (locationState.projectTemplateId) {
        this.uploadFiles(locationState.filesToUpload, locationState.projectTemplateId);
      } else {
        this.setState({ filesToUpload: locationState.filesToUpload });
      }
    }
  }

  componentWillUnmount() {
    this.props.unsubscribeCurrentFolder();
    window.removeEventListener('scroll', this.onFileListScroll);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const folderPageSizeUserSelection = localStorage.getItem(Constants.LocalStorageKeys.FILE_LIST_PAGE_SIZE);

    let options = {
      projectId: nextProps.projectId,
      folderId: nextProps.folderId,
      pageNum: nextState.currentPage,
      forceFetch:
        this.props.folderId !== nextProps.folderId ||
        this.state.currentPage !== nextState.currentPage ||
        this.state.filters !== nextState.filters
    };

    if (folderPageSizeUserSelection) {
      options.pageSize = folderPageSizeUserSelection;
    }

    if (nextState.filters) {
      options = Object.assign({}, options, nextState.filters);
    }

    nextProps.loadCurrentFolder(options).catch(this.onCurrentFolderLoadError);

    // If changing folder or project, ensure the active toolbar option is cleared
    if (nextProps.folderId !== this.props.folderId || nextProps.projectId !== this.props.projectId) {
      this.clearActiveToolbarOption();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentFolder } = this.props;
    if (currentFolder !== prevProps.currentFolder) {
      if (this.state.currentPage > 1 && !this.props.currentFolder.children.length) {
        // If we've just loaded a page other than 1, and recieved no children, switch to the previous page and try again
        this.onPageChange(this.state.currentPage - 1);
      }

      this.updatePendingState();
    }
  }
  closeDateFormatPopUp = () => {
    this.setState({ showDateSelectionPopup: false });
    this.setState({filesArray:[]});
  };
  handleSuccessCallback = status => {
    if (status === 'success') {
      sessionStorage.setItem('isDateFormatSaved-'+this.props.currentFolder.projectItemId, true);
    }
    if (status === 'progress') {
      const {filesToUpload,filesArray } = this.state;

      if (Permissions.Project.Document.canUpload()) {
        this.setState({
          filesToUpload: filesArray
        });
      }
    }
  };

  setActiveToolbarOption = option => {
    if(!this.state.KiraProcessingModalOpen)
    {
      this.setState({
        activeToolbarOption: option
      });
    }
  };

  clearActiveToolbarOption = () => {
    this.setActiveToolbarOption(null);
  };

  onSubmitCreateComparison = selectedComparisonId => {
    const { history, projectId } = this.props;
    this.clearActiveToolbarOption();
    history.push(`/region/${getCurrentRegion()}/project/${projectId}/compare`);
  };

  onCreateNewTemplate = () => {
    const { history, projectId, folderId } = this.props;

    history.push(`/region/${getCurrentRegion()}/project/${projectId}/create-template`, {
      folderId,
      filesToUpload: this.state.filesToUpload
    });
  };

  onSubmitAddToComparison = selectedComparisonId => {
    const { addNotification, projectId } = this.props;
    this.clearActiveToolbarOption();
    addNotification({
      message: (
        <FormattedMessage
          id="compare.document-added-notification"
          values={{
            link: (
              <Link
                to={`/region/${getCurrentRegion()}/project/${projectId}/compare${
                  selectedComparisonId ? `/${selectedComparisonId}` : ''
                }`}
              >
                <FormattedMessage id="compare.comparison-created-notification.link-text" />
              </Link>
            )
          }}
        />
      ),
      clearOnPageChange: true,
      autohide: 10
    });
  };

  uploadFiles = (files, templateId) => {
    const { projectId, folderId, upload } = this.props;

    files.forEach(file =>
      upload({
        file,
        name: file.name,
        parentId: folderId,
        path: file.fullPath || file.name,
        projectId,
        projectTemplateId: templateId
      })
    );
  };

  submitAssignTemplateModal = selectedTemplate => {
    const { filesToUpload } = this.state;
    const { folderId } = this.props;
    if(folderId != 'root') 
    this.uploadFiles(filesToUpload, selectedTemplate.projectTemplateId);

    this.setState({ filesToUpload: [] });
  };

  cancelAssignTemplateModal = () => {
    this.setState({ filesToUpload: [] });
  };

  submitCreateFolderModal = folderName => {
    const { createFolder, folderId, projectId } = this.props;
    return createFolder({ folderName, projectId, parentId: folderId });
  };

  setDropzoneElem = elem => {
    this.dropzoneElem = elem;
  };

  onFilesSelected = event => {
    // Upload each file
    this.setState({
      filesToUpload: Array.from(event.target.files)
    });

    // Reset the input value so that onChange will fire even if the user selects
    // the same files again
    event.target.value = null;
  };

  onPageChange = pageNum => {
    this.setState({ currentPage: pageNum });
  };

  onFilterChange = filterObject => {
    this.setState({ currentPage: 1, filters: filterObject });
  };

  onCurrentFolderLoadError = () => {
    const { history, projectId } = this.props;
    history.push(`/region/${getCurrentRegion()}/project/${projectId}/folder/root`);
  };

  getPendingDeleteItems = () => {
    const { currentFolder } = this.props;
    return (currentFolder.children || []).filter(item => item.itemStateId === ITEM_STATUSES.PENDINGDELETION);
  };

  // Determines whether to show visual/progress indicator of deleted files
  updatePendingState = () => {
    const items = this.getPendingDeleteItems();
    if (items.length > 0) {
      if (this.deleteTimeout) {
        return;
      }
      // If items are being deleted after 15 seconds wait time, display banner
      return (this.deleteTimeout = setTimeout(() => this.setState({ isPendingDeletion: true }), 15000));
    } else {
      if (this.deleteTimeout) {
        clearTimeout(this.deleteTimeout);
        this.deleteTimeout = null;
        this.setState({ isPendingDeletion: false });
      }
    }
  };

  pageSizeChangeFunction = newPageSize => {
    const { projectId, folderId, loadCurrentFolder } = this.props;
    const { filters } = this.state;

    let options = {
      projectId: projectId,
      folderId: folderId,
      pageNum: 1,
      forceFetch: true,
      pageSize: newPageSize
    };

    this.setState({ currentPage: options.pageNum });

    if (filters) {
      options = Object.assign({}, options, filters);
    }

    loadCurrentFolder(options).catch(this.onCurrentFolderLoadError);
  };

  goToEditTemplate = (templateId = '') => {
    const { history, projectId } = this.props;
    history.push(`/region/${getCurrentRegion()}/project/${projectId}/extractionfield/${templateId}`);
  };

  onUploadClick = ()=>
   {
    const {currentProject,currentFolder} = this.props;
    if(!currentProject.isDocumentProcessingEnabled && currentFolder.templateCategoryId === 0)
     {
      this.setState({ KiraProcessingModalOpen: true });
    }
  }

  openKiraProcessingModel = (option,destinationFolder)=> 
  {
    const {currentProject} = this.props;
    if(!currentProject.isDocumentProcessingEnabled)
     {
      this.setState(prevState => ({
        KiraProcessingModalOpen: true,
        activeToolbarOption : option,
        kiraTemplates :destinationFolder
      }));
    }
  }

  closeKiraProcessingPausedModal = () => 
  {
    const {currentProject} = this.props;
    if(!currentProject.isDocumentProcessingEnabled) 
    {
      this.setState(prevState => ({
        KiraProcessingModalOpen: false,
        activeToolbarOption : prevState.activeToolbarOption
      }));
    }
  }

  getKiraTemplates = () => 
  {
    let {kiraTemplates} = this.state;
    var kiraTemplateString = kiraTemplates.join(",");    
    return kiraTemplateString;
  }



  render() {
    const {
      currentFolder: { parentHierarchy, children, pagination },
      currentFolder,
      currentFolderInvalidate,
      currentProject,
      folderId,
      isUploadInProgress,
      projectId,
      selectedItems
    } = this.props;
    const {KiraProcessingModalOpen} = this.state;
    let {
      activeToolbarOption,
      filesToUpload,
      isDraggingFile,
      isSearchResultsPage,
      isFiltering,
      isPendingDeletion
    } = this.state;

    if (pagination) {
      pagination.pageSizeStorageKey = Constants.LocalStorageKeys.FILE_LIST_PAGE_SIZE;
      pagination.pageSizeOptions = [50, 100, 200, 500];
      pagination.pageSizeChangeFunction = this.pageSizeChangeFunction;
    }

    const isListEmpty = !children || (Array.isArray(children) && !children.length);
    const dropzoneClassName = classNames(
      'file-browser__dropzone',
      isDraggingFile && 'file-browser__dropzone--dragging'
    );
    
    const title = folderId != 'root' ? (
      <FormattedMessage id="file-browser.empty-state-title" />
    ) : <FormattedMessage id="file-browser.empty-state-Template-title" />;

    const description = folderId != 'root' ? (
      <FormattedMessage id="file-browser.empty-state-description" />
    ) : <FormattedMessage id="file-browser.empty-state-Template-description" />;

    return (
      <div className="file-browser">
        {isPendingDeletion ? (
          <div className="file-browser__delete-banner">
            <Icon name="special-info-outline" width={16} />
            <span className="file-browser__delete-banner-title">
              <FormattedMessage id="file-browser.delete-banner.title" />
            </span>
            <span className="file-browser__delete-banner-message">
              <FormattedMessage id="file-browser.delete-banner.message" />
            </span>
            <Icon className="spinner" name="loader" width={20} />
          </div>
        ) : null}
        <FileBrowserToolbarContainer
          activeToolbarOption={activeToolbarOption}
          folderId={folderId}
          currentFolder={currentFolder}
          invalidate={currentFolderInvalidate}
          isSearchResultsPage={isSearchResultsPage}
          onFilesSelected={this.onFilesSelected}
          parents={parentHierarchy}
          projectId={projectId}
          readOnly={currentProject.STATES.ReadOnly()}
          setActiveToolbarOption={this.setActiveToolbarOption}
          showUploadButton={!isListEmpty || isFiltering}
          goToEditTemplate={this.goToEditTemplate}
          onUploadClick={() => {this.onUploadClick()}}
            isKiraprocessingModelOpen = {KiraProcessingModalOpen}
            openKiraProcessingModel = {this.openKiraProcessingModel}
        />

        {!currentFolder.isLoaded && <ProgressLoader />}
        {KiraProcessingModalOpen  && filesToUpload.length > 0 && currentFolder.templateCategoryId === 0 && !currentProject.isDocumentProcessingEnabled &&    
         <KiraProcessingPausedModal closeKiraProcessingPausedModal={() =>{this.closeKiraProcessingPausedModal()}} templates={currentFolder.itemName}/>}

{KiraProcessingModalOpen  &&(activeToolbarOption === TOOLBAR_OPTIONS.REPROCESS) &&!currentProject.isDocumentProcessingEnabled
        &&  
         <KiraProcessingPausedModal closeKiraProcessingPausedModal={()=>{this.closeKiraProcessingPausedModal()}} templates={this.getKiraTemplates()}/>}

        <div className={dropzoneClassName} ref={this.setDropzoneElem}>
          {!!children && isListEmpty && !isFiltering ? (
            <EmptyState
              title={title}
              description={description}
              img={emptyStateImage}
            >
              {!currentProject.STATES.ReadOnly() && Permissions.Project.Document.canUpload() && folderId != 'root' ? (
                <UploadButton
                  onFilesSelected={this.onFilesSelected}
                  disabled={isUploadInProgress || filesToUpload.length > 0}
                  onClick={this.onUploadClick}
                  projectId={projectId}
                  currentFolder={currentFolder}
                  currentProject ={currentProject}
                />
              ) : null}
            </EmptyState>
          ) : (
            <FileListContainer
              currentFolder={currentFolder}
              projectId={projectId}
              onPageChange={this.onPageChange}
              onFilterChange={this.onFilterChange}
              pagination={pagination}
              isSearchResultsPage={false}
              invalidate={currentFolderInvalidate}
              readOnly={currentProject.STATES.ReadOnly()}
              loadSearchResults={() => {}}
              isFiltering={value => this.setState({ isFiltering: value })}
              currentProject={currentProject}
            />
          )}
        </div>

        <UploadStatusContainer />
        {activeToolbarOption === TOOLBAR_OPTIONS.EXPORT && (
          <ExportSidebarContainer
            onCloseClick={this.clearActiveToolbarOption}
            onSubmit={this.clearActiveToolbarOption}
            projectId={projectId}
            currentFolder={currentFolder}
          />
        )}
        {activeToolbarOption === TOOLBAR_OPTIONS.COMPARE && (
          <CompareSidebarContainer
            selectedItems={children.filter(
              projectItem =>
                selectedItems[projectItem.projectItemId] &&
                (projectItem.itemTypeId === ITEM_TYPES.FOLDER || projectItem.itemStateId === ITEM_STATUSES.PROCESSED)
            )}
            onCloseClick={this.clearActiveToolbarOption}
            onSubmitAddToComparison={this.onSubmitAddToComparison}
            onSubmitCreateComparison={this.onSubmitCreateComparison}
            projectId={projectId}
          />
        )}
        {activeToolbarOption === TOOLBAR_OPTIONS.CREATE_FOLDER && (
          <Modal>
            <CreateFolderModal onCancel={this.clearActiveToolbarOption} onSubmit={this.submitCreateFolderModal} />
          </Modal>
        )}
{filesToUpload.length > 0 && !KiraProcessingModalOpen && this.submitAssignTemplateModal(currentFolder.parentHierarchy.find(({projectItemId}) => projectItemId === currentFolder.projectItemId))?.projectTemplateId (
          // <AssignTemplateModal
          //   onCancel={this.cancelAssignTemplateModal}
          //   onSubmit={this.submitAssignTemplateModal}
          //   onCreateNewTemplate={this.onCreateNewTemplate}
          //   defaultTemplate={
          //     currentFolder.parentHierarchy.find(({ projectItemId }) => projectItemId === currentFolder.projectItemId)
          //       ?.projectTemplateId
          //   }
          //   projectId={projectId}
          // />
          
        )}
        <ProjectDateFormatSelection
          showProjectDateFormatSelection={this.state.showDateSelectionPopup}
          projectId={projectId}
          closeDateFormatPopUp={this.closeDateFormatPopUp}
          postSuccessCallback={status => this.handleSuccessCallback(status)}
          currentFolder={currentFolder}
        />
      </div>
    );
  }
}

export default withRouter(FileBrowser);
