import { createAction, createReducer } from 'utils/redux-utils';

import {
  getSearchResultsDocuments,
  getSearchResultsDocumentsByAssignee,
  getSearchResultsFolders,
  getFolder,
  createAdvancedSearch
} from 'store/api';

import { SearchResults } from 'models/search';

export const ACTIONS = {
  // Shallow results content inside a folder
  SHALLOW_RESULTS_CURRENT_FOLDER_LOADING: 'argus/ui/SHALLOW_RESULTS_CURRENT_FOLDER_LOADING',
  SHALLOW_RESULTS_CURRENT_FOLDER_LOADED: 'argus/ui/SHALLOW_RESULTS_CURRENT_FOLDER_LOADED',
  // Shallow results in documents & folders
  SHALLOW_RESULTS_DOCUMENTS_LOADING: 'argus/ui/SHALLOW_RESULTS_DOCUMENTS_LOADING',
  SHALLOW_RESULTS_DOCUMENTS_LOADED: 'argus/ui/SHALLOW_RESULTS_DOCUMENTS_LOADED',
  SHALLOW_RESULTS_FOLDERS_LOADING: 'argus/ui/SHALLOW_RESULTS_FOLDERS_LOADING',
  SHALLOW_RESULTS_FOLDERS_LOADED: 'argus/ui/SHALLOW_RESULTS_FOLDERS_LOADED',
  // When a user doesnt specify the category to search on, perform search globally
  GLOBAL_SEARCH_LOADING: 'argus/ui/GLOBAL_SEARCH_LOADING',
  GLOBAL_SEARCH_LOADED: 'argus/ui/GLOBAL_SEARCH_LOADED',
  // Advanced search results
  ADVANCED_RESULTS_LOADING: 'argus/ui/ADVANCED_RESULTS_LOADING',
  ADVANCED_RESULTS_LOADED: 'argus/ui/ADVANCED_RESULTS_LOADED',
  SEARCH_RESULTS_CLEAR: 'argus/ui/SEARCH_RESULTS_CLEAR',
  SEARCH_RESULTS_INVALIDATE: 'argus/ui/SEARCH_RESULTS_INVALIDATE'
};

// Search Results
export const shallowResultsCurrentFolderLoading = createAction(ACTIONS.SHALLOW_RESULTS_CURRENT_FOLDER_LOADING);
export const shallowResultsCurrentFolderLoaded = createAction(ACTIONS.SHALLOW_RESULTS_CURRENT_FOLDER_LOADED);
export const shallowSearchResultsDocumentsLoading = createAction(ACTIONS.SHALLOW_RESULTS_DOCUMENTS_LOADING);
export const shallowSearchResultsDocumentsLoaded = createAction(ACTIONS.SHALLOW_RESULTS_DOCUMENTS_LOADED);
export const shallowSearchResultsFoldersLoading = createAction(ACTIONS.SHALLOW_RESULTS_FOLDERS_LOADING);
export const shallowSearchResultsFoldersLoaded = createAction(ACTIONS.SHALLOW_RESULTS_FOLDERS_LOADED);
export const globalSearchLoading = createAction(ACTIONS.GLOBAL_SEARCH_LOADING);
export const globalSearchLoaded = createAction(ACTIONS.GLOBAL_SEARCH_LOADED);
export const advancedResultsLoading = createAction(ACTIONS.ADVANCED_RESULTS_LOADING);
export const advancedResultsLoaded = createAction(ACTIONS.ADVANCED_RESULTS_LOADED);

export const searchResultsClear = createAction(ACTIONS.SEARCH_RESULTS_CLEAR);
export const searchResultsInvalidate = createAction(ACTIONS.SEARCH_RESULTS_INVALIDATE);

// Search Results
// This is used when user clicks on a folder result returned from search folder api
export const getCurrentSearchFolder = data => dispatch => {
  dispatch(shallowResultsCurrentFolderLoading());
  return getFolder(data).then(response => dispatch(shallowResultsCurrentFolderLoaded(response)));
};

export const getDocumentResults = data => dispatch => {
  dispatch(shallowSearchResultsDocumentsLoading());

  if (data.assigneeUserId) {
    return getSearchResultsDocumentsByAssignee(data).then(res =>
      dispatch(shallowSearchResultsDocumentsLoaded(res.data))
    );
  } else {
    return getSearchResultsDocuments(data).then(res => dispatch(shallowSearchResultsDocumentsLoaded(res.data)));
  }
};

export const getFolderResults = data => dispatch => {
  dispatch(shallowSearchResultsFoldersLoading());
  return getSearchResultsFolders(data).then(res => dispatch(shallowSearchResultsFoldersLoaded(res.data)));
};

export const getAdvancedSearchResults = data => dispatch => {
  dispatch(advancedResultsLoading());
  return createAdvancedSearch(data).then(res => dispatch(advancedResultsLoaded(res)));
};

export const clearResultsData = data => dispatch => {
  dispatch(searchResultsClear());
};

export const resultsInvalidate = () => dispatch => {
  dispatch(searchResultsInvalidate());
};

export const INITIAL_STATE = new SearchResults();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.SHALLOW_RESULTS_CURRENT_FOLDER_LOADING]: (state, action) => {
    return state.setLoading(state, action.payload);
  },

  [ACTIONS.SHALLOW_RESULTS_CURRENT_FOLDER_LOADED]: (state, action) => {
    return state.setCurrentFolderLoaded(state, action.payload);
  },

  [ACTIONS.SHALLOW_RESULTS_DOCUMENTS_LOADING]: (state, action) => {
    return state.setLoading(state, action.payload);
  },

  [ACTIONS.SHALLOW_RESULTS_DOCUMENTS_LOADED]: (state, action) => {
    return state.setDocumentsLoaded(state, action.payload);
  },

  [ACTIONS.SHALLOW_RESULTS_FOLDERS_LOADING]: (state, action) => {
    return state.setLoading(state, action.payload);
  },

  [ACTIONS.SHALLOW_RESULTS_FOLDERS_LOADED]: (state, action) => {
    return state.setFoldersLoaded(state, action.payload);
  },

  [ACTIONS.GLOBAL_SEARCH_LOADING]: (state, action) => {
    return state.setLoading(state, action.payload);
  },

  [ACTIONS.GLOBAL_SEARCH_LOADED]: (state, action) => {
    return state.setGlobalLoaded(state, action.payload);
  },

  [ACTIONS.ADVANCED_RESULTS_LOADING]: (state, action) => {
    return state.setLoading(state, action.payload);
  },

  [ACTIONS.ADVANCED_RESULTS_LOADED]: (state, action) => {
    return state.setAdvancedLoaded(state, action.payload);
  },

  [ACTIONS.SEARCH_RESULTS_CLEAR]: (state, action) => {
    return INITIAL_STATE;
  },

  [ACTIONS.SEARCH_RESULTS_INVALIDATE]: (state, action) => {
    return state.invalidate();
  }
});
