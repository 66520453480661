import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';
import enhanceWithClickOutside from 'react-click-outside';
import { FormattedMessage } from 'react-intl';

class Tooltip extends Component {
  static propTypes = {
    buttons: PropTypes.any,
    closeFunction: PropTypes.func,
    confirmFunction: PropTypes.func,
    declineFunction: PropTypes.func,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className:PropTypes.any
  };

  componentDidMount() {
    if (this.element && this.element.scrollIntoView) {
      this.element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }

  handleClickOutside() {
    this.props.closeFunction();
  }

  render() {
    var { title, message, buttons } = this.props;
    var { closeFunction, confirmFunction, declineFunction,className } = this.props;

    return (
      <div className="tooltip" ref={e => (this.element = e)}>
        <div className="header">
          <h3>{title}</h3>
          <button className="icon-button" onClick={closeFunction}>
            <Icon name="special-cross-black" width={16} />
          </button>
        </div>
        <div className="body">{message}</div>
        {buttons ? (          
          <div className={`${className}__footer footer`}>
            <button className="btn btn-secondary" onClick={declineFunction}>
              <FormattedMessage id="tooltip.decline-button" />
            </button>
            <button className="btn btn-primary" onClick={confirmFunction}>
              <FormattedMessage id="tooltip.confirm-button" />
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default enhanceWithClickOutside(Tooltip);
