import { createAction, createReducer } from 'utils/redux-utils';
import { Document } from 'models/document-viewer/document';
import socket, { rooms } from 'utils/socket';
import store from 'store/store';

import { pushUndoStack, clearUndoStack } from 'store/document-viewer-undo';
import { getCurrentDocumentLayout } from 'store/current-document-layout';
//API Calls
import * as API from 'store/api';

export const ACTIONS = {
  CURRENT_DOCUMENT_LOADING: 'argus/ui/CURRENT_DOCUMENT_LOADING',
  CURRENT_DOCUMENT_LOADED: 'argus/ui/CURRENT_DOCUMENT_LOADED',
  CURRENT_DOCUMENT_ERROR: 'argus/ui/CURRENT_DOCUMENT_ERROR',
  CURRENT_DOCUMENT_DELETED: 'argus/ui/CURRENT_DOCUMENT_DELETED',
  CURRENT_DOCUMENT_CLEAR: 'argus/ui/CURRENT_DOCUMENT_CLEAR',
  CURRENT_DOCUMENT_TAG_ADD: 'argus/ui/CURRENT_DOCUMENT_TAG_ADD',
  CURRENT_DOCUMENT_TAG_REMOVE: 'argus/ui/CURRENT_DOCUMENT_TAG_REMOVE',
  CURRENT_DOCUMENT_REVIEWER_ADD: 'argus/ui/CURRENT_DOCUMENT_REVIEWER_ADD',
  CURRENT_DOCUMENT_REVIEWER_REMOVE: 'argus/ui/CURRENT_DOCUMENT_REVIEWER_REMOVE',
  CURRENT_DOCUMENT_EF_ADD: 'argus/ui/CURRENT_DOCUMENT_EF_ADD',
  CURRENT_DOCUMENT_EF_ALL_RECORDS_REMOVE: 'argus/ui/CURRENT_DOCUMENT_EF_ALL_RECORDS_REMOVE',
  CURRENT_DOCUMENT_EFR_ADD: 'argus/ui/CURRENT_DOCUMENT_EFR_ADD',
  CURRENT_DOCUMENT_EFR_EDIT: 'argus/ui/CURRENT_DOCUMENT_EFR_EDIT',
  CURRENT_DOCUMENT_EFR_REMOVE: 'argus/ui/CURRENT_DOCUMENT_EFR_REMOVE',
  CURRENT_DOCUMENT_EFR_TAG_ADD: 'argus/ui/CURRENT_DOCUMENT_EFR_TAG_ADD',
  CURRENT_DOCUMENT_EFR_TAG_REMOVE: 'argus/ui/CURRENT_DOCUMENT_EFR_TAG_REMOVE',
  CURRENT_DOCUMENT_ORGANIZE_FILES: 'argus/ui/CURRENT_DOCUMENT_ORGANIZE_FILES',
  CURRENT_DOCUMENT_SAMPLE_NUMBER_LOADING: 'argus/ui/CURRENT_DOCUMENT_SAMPLE_NUMBER_LOADING',
  CURRENT_DOCUMENT_SAMPLE_NUMBER_LOADED: 'argus/ui/CURRENT_DOCUMENT_SAMPLE_NUMBER_LOADED',
  CURRENT_DOCUMENT_SET_EF_FILTER: 'argus/ui/CURRENT_DOCUMENT_SET_EF_FILTER',
  CURRET_DOCUMENT_DOC_TYPE_REMOVE_LOADED: 'argus/ui/CURRET_DOCUMENT_DOC_TYPE_REMOVE_LOADED'
};

export const currentDocumentLoading = createAction(ACTIONS.CURRENT_DOCUMENT_LOADING);
export const currentDocumentLoaded = createAction(ACTIONS.CURRENT_DOCUMENT_LOADED);
export const currentDocumentError = createAction(ACTIONS.CURRENT_DOCUMENT_ERROR);
export const currentDocumentDeleted = createAction(ACTIONS.CURRENT_DOCUMENT_DELETED);
export const currentDocumentClear = createAction(ACTIONS.CURRENT_DOCUMENT_CLEAR);
export const currentDocumentTagAdd = createAction(ACTIONS.CURRENT_DOCUMENT_TAG_ADD);
export const currentDocumentTagRemove = createAction(ACTIONS.CURRENT_DOCUMENT_TAG_REMOVE);
export const currentDocumentReviewerAdd = createAction(ACTIONS.CURRENT_DOCUMENT_REVIEWER_ADD);
export const currentDocumentReviewerRemove = createAction(ACTIONS.CURRENT_DOCUMENT_REVIEWER_REMOVE);
export const currentDocumentEFAdd = createAction(ACTIONS.CURRENT_DOCUMENT_EF_ADD);
export const currentDocumentEFAllRecordsRemove = createAction(ACTIONS.CURRENT_DOCUMENT_EF_ALL_RECORDS_REMOVE);
export const currentDocumentEFRAdd = createAction(ACTIONS.CURRENT_DOCUMENT_EFR_ADD);
export const currentDocumentEFREdit = createAction(ACTIONS.CURRENT_DOCUMENT_EFR_EDIT);
export const currentDocumentEFRRemove = createAction(ACTIONS.CURRENT_DOCUMENT_EFR_REMOVE);
export const currentDocumentEFRTagAdd = createAction(ACTIONS.CURRENT_DOCUMENT_EFR_TAG_ADD);
export const currentDocumentEFRTagRemove = createAction(ACTIONS.CURRENT_DOCUMENT_EFR_TAG_REMOVE);
export const currentDocumentOrganizeFiles = createAction(ACTIONS.CURRENT_DOCUMENT_ORGANIZE_FILES);
export const currentDocumentSampleNumberLoading = createAction(ACTIONS.CURRENT_DOCUMENT_SAMPLE_NUMBER_LOADING);
export const currentDocumentSampleNumberLoaded = createAction(ACTIONS.CURRENT_DOCUMENT_SAMPLE_NUMBER_LOADED);
export const currentDocumentSetEFFilter = createAction(ACTIONS.CURRENT_DOCUMENT_SET_EF_FILTER);
export const removeDocTypeLoaded = createAction(ACTIONS.CURRET_DOCUMENT_DOC_TYPE_REMOVE_LOADED);

export const getCurrentDocument = (projectId, documentId) => dispatch => {
  dispatch(currentDocumentLoading());
  unsubscribe();

  return API.getDocument(projectId, documentId)
    .then(response => {
      dispatch(getCurrentDocumentLayout(response.data.projectId, response.data.documentId));
      dispatch(currentDocumentLoaded(response.data));
      dispatch(subscribe(projectId, documentId, response.data.projectTemplates[0].projectTemplateId));
    })
    .catch(error => {
      dispatch(currentDocumentError(error));
    });
};

export const getNextDocument = (projectId, documentId) => dispatch => {
  dispatch(currentDocumentLoading());
  unsubscribe();

  const request = API.getNextDocument(projectId, documentId);

  return request
    .then(response => {
      dispatch(getCurrentDocumentLayout(response.data.projectId, response.data.documentId));
      dispatch(currentDocumentLoaded(response.data));
      dispatch(clearUndoStack());
      dispatch(
        subscribe(
          response.data.projectId,
          response.data.documentId,
          response.data.projectTemplates[0].projectTemplateId
        )
      );
      return response;
    })
    .catch(error => {
      dispatch(currentDocumentError(error));
    });
};

export const getPreviousDocument = (projectId, documentId) => dispatch => {
  dispatch(currentDocumentLoading());
  unsubscribe();

  const request = API.getPreviousDocument(projectId, documentId);

  return request
    .then(response => {
      dispatch(getCurrentDocumentLayout(response.data.projectId, response.data.documentId));
      dispatch(currentDocumentLoaded(response.data));
      dispatch(clearUndoStack());
      dispatch(
        subscribe(
          response.data.projectId,
          response.data.documentId,
          response.data.projectTemplates[0].projectTemplateId
        )
      );
      return response;
    })
    .catch(error => {
      dispatch(currentDocumentError(error));
    });
};

export const refreshCurrentDocument = (projectId, documentId) => dispatch => {
  if (!projectId) projectId = store.getState().data.currentDocument.projectId;
  if (!documentId) documentId = store.getState().data.currentDocument.documentId;

  return API.getDocument(projectId, documentId)
    .then(response => {
      dispatch(currentDocumentLoaded(response.data));
    })
    .catch(error => {
      dispatch(currentDocumentError(error));
    });
};

export const clearCurrentDocument = () => dispatch => {
  dispatch(currentDocumentClear());
};

export const unsubscribeCurrentDocument = () => dispatch => {
  unsubscribe();
};

export const createDocumentTag = () => dispatch => {
  //TODO: Evaluate if this function is needed
};

export const removeDocumentTag = () => dispatch => {
  //TODO: Evaluate if this function is needed
};

export const markDocumentAsReviewed = (projectId, documentId, currentUser) => dispatch => {
  dispatch(
    currentDocumentReviewerAdd({
      projectId,
      projectItemId: documentId,
      user: currentUser,
      userId: currentUser.userId,
      username: currentUser.displayName
    })
  );
  return API.markDocumentAsReviewed(projectId, documentId, currentUser)
    .then(() => {
      dispatch(clearUndoStack());
    })
    .catch(error => {
      dispatch(
        currentDocumentReviewerRemove({
          projectId,
          projectItemId: documentId,
          user: currentUser,
          userId: currentUser.userId,
          username: currentUser.displayName
        })
      );
      throw error;
    });
};

export const markDocumentAsNotReviewed = (projectId, documentId, currentUser) => dispatch => {
  dispatch(
    currentDocumentReviewerRemove({
      projectId,
      projectItemId: documentId,
      user: currentUser,
      userId: currentUser.userId,
      username: currentUser.displayName
    })
  );
  return API.markDocumentAsNotReviewed(projectId, documentId, currentUser)
    .then(() => {
      dispatch(clearUndoStack());
    })
    .catch(error => {
      dispatch(
        currentDocumentReviewerAdd({
          projectId,
          projectItemId: documentId,
          user: currentUser,
          userId: currentUser.userId,
          username: currentUser.displayName
        })
      );
      throw error;
    });
};

//Create extraction field + associate extraction field to template + create extraction field recordsort
export const createExtractionField = ({
  projectId,
  documentId,
  templateId,
  extractionFieldName,
  extractionFieldDescription,
  extractionFieldGroupId = null,
  selection
}) => dispatch => {
  return API.combinedCreate({
    projectId: projectId,
    documentId: documentId,
    templateId: templateId,
    name: extractionFieldName,
    description: extractionFieldDescription,
    ...(extractionFieldGroupId && { extractionFieldGroupId: extractionFieldGroupId }),
    location: [selection.firstIndex, selection.lastIndex],
    text: selection.text,
    pageNumber: selection.page
  }).then(response => {
    response.data.record.data.documentId = documentId;
    response.data.record.data.extractFieldInstanceData = selection.text;

    dispatch(currentDocumentEFAdd(response.data.field));
    dispatch(currentDocumentEFRAdd(response.data.record.data));

    dispatch(
      pushUndoStack({
        type: 'ADD_RECORD',
        ids: {
          projectId,
          documentId,
          pageNumber: response.data.record.data.pageNumber,
          undoId: response.data.record.data.undoId
        }
      })
    );

    return response;
  });
};

//Associate extraction field to template + create extraction field recordsort
export const addExtractionField = ({
  projectId,
  documentId,
  templateId,
  extractionField,
  selection,
  groupId
}) => dispatch => {
  return API.combinedAssociate({
    projectId: projectId,
    documentId: documentId,
    templateId: templateId,
    extractionFieldId: extractionField.extractionFieldId,
    location: [selection.firstIndex, selection.lastIndex],
    text: selection.text,
    pageNumber: selection.page,
    extractionFieldGroupId: extractionField.extractionFieldGroupId
  }).then(response => {
    response.data.documentId = documentId;
    response.data.extractFieldInstanceData = selection.text;

    dispatch(currentDocumentEFAdd(extractionField));
    dispatch(currentDocumentEFRAdd(response.data));

    dispatch(
      pushUndoStack({
        type: 'ADD_RECORD',
        ids: { projectId, documentId, pageNumber: response.data.pageNumber, undoId: response.data.undoId }
      })
    );

    return response;
  });
};

export const createExtractionFieldRecord = (
  projectId,
  documentId,
  extractionFieldId,
  selection,
  isNewExtraction
) => dispatch => {
  return API.createExtractionFieldRecord(
    projectId,
    documentId,
    extractionFieldId,
    [selection.firstIndex, selection.lastIndex],
    selection.text,
    selection.page
  ).then(response => {
    dispatch(
      pushUndoStack({
        type: 'ADD_RECORD',
        ids: { projectId, documentId, pageNumber: response.data.pageNumber, undoId: response.data.undoId }
      })
    );

    //Adding documentId to response data to ensure that efr is being added to the right document in store
    response.data.documentId = documentId;
    response.data.extractFieldInstanceData = selection.text;

    dispatch(currentDocumentEFRAdd(response.data));
    return response;
  }).catch(error => error);
};

export const editExtractionFieldRecord = (
  projectId,
  documentId,
  extractionFieldId,
  extractionFieldRecordId,
  extractFieldInstanceData
) => dispatch => {
  return API.editExtractionFieldRecord(
    projectId,
    documentId,
    extractionFieldId,
    extractionFieldRecordId,
    extractFieldInstanceData
  ).then(response => {
    dispatch(currentDocumentEFREdit({ extractionFieldRecordId, extractFieldInstanceData }));
    return response;
  });
};

export const removeExtractionFieldRecord = (projectId, documentId, extractionFieldRecordId, pageNumber) => dispatch => {
  return API.deleteExtractionFieldRecord(projectId, documentId, extractionFieldRecordId).then(response => {
    dispatch(currentDocumentEFRRemove(extractionFieldRecordId));
    dispatch(
      pushUndoStack({
        type: 'DELETE_SINGLE',
        ids: {
          projectId,
          documentId,
          pageNumber,
          undoId: response.data.undoId
        }
      })
    );
    return response;
  });
};

export const removeAllExtractionFieldRecords = (projectId, documentId, extractionFieldId) => dispatch => {
  return API.deleteAllExtractionFieldRecordsForField(projectId, documentId, extractionFieldId).then(response => {
    dispatch(currentDocumentEFAllRecordsRemove({ extractionFieldId }));
    dispatch(
      pushUndoStack({
        type: 'DELETE_ALL',
        ids: { projectId, documentId, undoId: response.data.undoId }
      })
    );
    return response;
  });
};

export const addExtractionFieldRecordTag = (projectId, documentId, extractionFieldRecordId, tag) => dispatch => {
  dispatch(
    currentDocumentEFRTagAdd({
      extractionFieldRecordId,
      tag: tag
    })
  );
  API.addExtractionFieldRecordTag(projectId, documentId, extractionFieldRecordId, tag.tagId)
    .then(response => {
      dispatch(clearUndoStack());
      return response;
    })
    .catch(error => {
      dispatch(currentDocumentEFRTagRemove({ extractionFieldRecordId, tagId: tag.tagId }));
      throw error;
    });
};

export const removeExtractionFieldRecordTag = (projectId, documentId, extractionFieldRecordId, tag) => dispatch => {
  dispatch(currentDocumentEFRTagRemove({ extractionFieldRecordId, tagId: tag.tagId }));
  API.removeExtractionFieldRecordTag(projectId, documentId, extractionFieldRecordId, tag.tagId)
    .then(response => {
      dispatch(clearUndoStack());
      return response;
    })
    .catch(error => {
      currentDocumentEFRTagAdd({
        extractionFieldRecordId,
        tag: tag
      });
      throw error;
    });
};

export const organizeDocuments = ({ projectId, documentId, method, start, length }) => dispatch => {
  return API.organizeDocuments(projectId, documentId, method, start, length).then(({ data }) =>
    dispatch(currentDocumentOrganizeFiles(data))
  );
};

export const updateSampleNumber = ({ projectId, documentId, start, sampleNumber }) => dispatch => {
  dispatch(currentDocumentSampleNumberLoading(start));
  return API.updateSampleNumber(projectId, documentId, start, sampleNumber).then(({ data }) =>
    dispatch(currentDocumentSampleNumberLoaded(data))
  );
};

export const setExtractionFieldFilter = selectedSection => dispatch => {
  return dispatch(currentDocumentSetEFFilter(selectedSection));
};

export const removeDocType = (projectId, documentId, sectionStart) => dispatch => {
  return API.removeDocType(projectId, documentId, sectionStart).then(response => {
    dispatch(removeDocTypeLoaded(response.data));
  });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new Document();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.CURRENT_DOCUMENT_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.CURRENT_DOCUMENT_LOADED]: (state, action) => {
    return state.setLoaded(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_ERROR]: (state, action) => {
    return state.setError(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_DELETED]: (state, action) => {
    return state.setDeleted();
  },

  [ACTIONS.CURRENT_DOCUMENT_CLEAR]: (state, action) => {
    return state.clear();
  },

  [ACTIONS.CURRENT_DOCUMENT_TAG_ADD]: (state, action) => {
    return state.tagAdd(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_TAG_REMOVE]: (state, action) => {
    return state.tagRemove(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_REVIEWER_ADD]: (state, action) => {
    if (state.documentId === action.payload.projectItemId) {
      return state.reviewerAdd(action.payload);
    } else {
      return state;
    }
  },

  [ACTIONS.CURRENT_DOCUMENT_REVIEWER_REMOVE]: (state, action) => {
    if (state.documentId === action.payload.projectItemId) {
      return state.reviewerRemove(action.payload);
    } else {
      return state;
    }
  },

  [ACTIONS.CURRENT_DOCUMENT_EF_ADD]: (state, action) => {
    return state.efAdd(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_EF_ALL_RECORDS_REMOVE]: (state, action) => {
    return state.efAllRecordsRemove(action.payload.extractionFieldId);
  },

  [ACTIONS.CURRENT_DOCUMENT_EFR_ADD]: (state, action) => {
    return state.efrAdd(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_EFR_EDIT]: (state, action) => {
    return state.efrEdit(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_EFR_REMOVE]: (state, action) => {
    return state.efrRemove(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_EFR_TAG_ADD]: (state, action) => {
    return state.efrTagAdd(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_EFR_TAG_REMOVE]: (state, action) => {
    return state.efrTagRemove(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_ORGANIZE_FILES]: (state, action) => {
    return state.updateSections(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_SAMPLE_NUMBER_LOADED]: (state, action) => {
    return state.sampleNumberLoaded(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_SAMPLE_NUMBER_LOADING]: (state, action) => {
    return state.sampleNumberLoading(action.payload);
  },

  [ACTIONS.CURRENT_DOCUMENT_SET_EF_FILTER]: (state, action) => {
    return state.setEfFilter(action.payload);
  },

  [ACTIONS.CURRET_DOCUMENT_DOC_TYPE_REMOVE_LOADED]: (state, action) => {
    return state.DocTypeRemove(action.payload);
  }
});

/*
 * SOCKETS
 */
const { subscribe, unsubscribe } = (socket => {
  let documentRoom = null;
  let templateRoom = null;
  let onGeneric = null;
  let onDocumentDeleted = null;
  let onTagAdd = null;
  let onTagRemove = null;
  let onReviewerAdd = null;
  let onReviewerRemove = null;
  let onEFAllRecordsRemove = null;
  let onEFRAdded = null;
  let onEFRRemoved = null;
  let onEFRTagAdded = null;
  let onEFRTagRemoved = null;
  let onEFAdded = null;
  let onEFandEFRAdded = null;
  let onClassify = null;

  const subscribe = (projectId, documentId, projectTemplateId) => dispatch => {
    if (!projectId || !documentId || !projectTemplateId) {
      return;
    }

    const nextDocumentRoom = rooms.document(projectId, documentId);
    const nextTemplateRoom = rooms.templateExtractionFields(projectId, projectTemplateId);

    if (documentRoom !== nextDocumentRoom) {
      socket.leave(documentRoom);
      documentRoom = nextDocumentRoom;
      socket.join(documentRoom);
    }

    if (templateRoom !== nextTemplateRoom) {
      socket.leave(templateRoom);
      templateRoom = nextTemplateRoom;
      socket.join(templateRoom);
    }

    if (!onDocumentDeleted) {
      onDocumentDeleted = response => {
        dispatch(currentDocumentDeleted());
      };
      socket.on('document.delete', onDocumentDeleted);
    }

    // Generic socket messages are those that need to trigger a refresh
    if (!onGeneric) {
      onGeneric = response => {
        if (documentRoom || templateRoom) {
          dispatch(refreshCurrentDocument());
        }
      };
      socket.on('update', onGeneric);
      socket.on('delete', onGeneric);
      socket.on('document.template.changed', onGeneric);
      socket.on('document.template.updated', onGeneric);
      socket.on('extractionfield.recordsort.updated', onGeneric);
      socket.on('extractionfieldrecord.updated', onGeneric);
    }

    if (!onTagAdd) {
      onTagAdd = response => {
        dispatch(currentDocumentTagAdd(response.data.tag));
      };
      socket.on('document.tag.added', onTagAdd);
    }

    if (!onTagRemove) {
      onTagRemove = response => {
        dispatch(currentDocumentTagRemove(response.data));
      };
      socket.on('document.tag.removed', onTagRemove);
    }

    if (!onReviewerAdd) {
      onReviewerAdd = response => {
        dispatch(
          currentDocumentReviewerAdd({
            projectId: response.data.projectId,
            projectItemId: response.data.documentId,
            user: response.data.reviewer,
            userId: response.data.reviewer.userId,
            username: response.data.reviewer.displayName
          })
        );
      };
      socket.on('document.reviewer.added', onReviewerAdd);
    }

    if (!onReviewerRemove) {
      onReviewerRemove = response => {
        dispatch(
          currentDocumentReviewerRemove({
            projectId: response.data.projectId,
            projectItemId: response.data.documentId,
            userId: response.data.reviewerId
          })
        );
      };
      socket.on('document.reviewer.removed', onReviewerRemove);
    }

    if (!onEFAllRecordsRemove) {
      onEFAllRecordsRemove = response => {
        dispatch(currentDocumentEFAllRecordsRemove({ extractionFieldId: response.data.extractionFieldId }));
      };
      socket.on('all.extractionfieldrecords.deleted', onEFAllRecordsRemove);
    }

    if (!onEFRAdded) {
      onEFRAdded = response => {
        dispatch(currentDocumentEFRAdd(response.data.extractionFieldRecord));
      };
      socket.on('extractionfieldrecord.added', onEFRAdded);
    }

    if (!onEFRRemoved) {
      onEFRRemoved = response => {
        dispatch(currentDocumentEFRRemove(response.data.documentExtractionFieldInstanceId));
      };
      socket.on('extractionfieldrecord.removed', onEFRRemoved);
    }

    if (!onEFRTagAdded) {
      onEFRTagAdded = response => {
        dispatch(currentDocumentEFRTagAdd(response.data));
      };
      socket.on('extractionfieldrecord.tag.added', onEFRTagAdded);
    }

    if (!onEFRTagRemoved) {
      onEFRTagRemoved = response => {
        dispatch(currentDocumentEFRTagRemove(response.data));
      };
      socket.on('extractionfieldrecord.tag.removed', onEFRTagRemoved);
    }

    if (!onEFAdded) {
      onEFAdded = response => {
        if ((response.room = templateRoom)) {
          dispatch(currentDocumentEFAdd(response.data.extractionFieldDTO));
        }
      };
      socket.on('create', onEFAdded);
    }

    if (!onEFandEFRAdded) {
      onEFandEFRAdded = response => {
        const field = response.data.extractionFieldDTO;
        const record = response.data.extractionFieldRecord;

        record.documentId = response.data.documentId;

        dispatch(currentDocumentEFAdd(field));
        dispatch(currentDocumentEFRAdd(record));

        return response;
      };
      socket.on('extraction.field.and.record.added', onEFandEFRAdded);
    }

    if (!onClassify) {
      onClassify = response => {
        dispatch(currentDocumentOrganizeFiles(response.data));
        return response;
      };
      socket.on('document.sections.updated', onClassify);
    }
  };

  const unsubscribe = () => {
    socket.leave(documentRoom);
    socket.leave(templateRoom);
    documentRoom = null;
    templateRoom = null;

    if (onDocumentDeleted) {
      socket.off('document.deleted', onTagAdd);
      onDocumentDeleted = null;
    }

    if (onGeneric) {
      // do not leave these rooms as they overlap with other rooms
      // socket.off('update', onGeneric);
      // socket.off('create', onGeneric);
      // socket.off('delete', onGeneric);
      socket.off('document.template.changed', onGeneric);
      socket.off('document.template.updated', onGeneric);
      socket.off('extractionfield.recordsort.updated', onGeneric);
      socket.off('extractionfieldrecord.updated', onGeneric);
      onGeneric = null;
    }

    if (onTagAdd) {
      socket.off('document.tag.added', onTagAdd);
      onTagAdd = null;
    }

    if (onTagRemove) {
      socket.off('document.tag.removed', onTagRemove);
      onTagRemove = null;
    }

    if (onReviewerAdd) {
      socket.off('document.reviewer.added', onReviewerAdd);
      onReviewerAdd = null;
    }

    if (onReviewerRemove) {
      socket.off('document.reviewer.removed', onReviewerRemove);
      onReviewerRemove = null;
    }

    if (onEFAllRecordsRemove) {
      socket.off('all.extractionfieldrecords.deleted', onEFAllRecordsRemove);
      onEFAllRecordsRemove = null;
    }

    if (onEFRAdded) {
      socket.off('extractionfieldrecord.added', onEFRAdded);
      onEFRAdded = null;
    }

    if (onEFRRemoved) {
      socket.off('extractionfieldrecord.removed', onEFRRemoved);
      onEFRRemoved = null;
    }

    if (onEFRTagAdded) {
      socket.off('extractionfieldrecord.tag.added', onEFRTagAdded);
      onEFRTagAdded = null;
    }

    if (onEFRTagRemoved) {
      socket.off('extractionfieldrecord.tag.removed', onEFRTagRemoved);
      onEFRTagRemoved = null;
    }

    if (onEFAdded) {
      // do not leave this room as it overlaps with other rooms
      // socket.off('create', onEFAdded);
      onEFAdded = null;
    }

    if (onEFandEFRAdded) {
      socket.off('extraction.field.and.record.added', onEFandEFRAdded);
      onEFandEFRAdded = null;
    }

    if (onClassify) {
      socket.off('document.sections.updated', onClassify);
      onClassify = null;
    }
  };

  return {
    subscribe,
    unsubscribe
  };
})(socket);
