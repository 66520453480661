/** Redux actions in the applications to intercept and track  */
const TRACKING_ACTIONS = {
  '@@router/LOCATION_CHANGE': {
    type: '@@router/LOCATION_CHANGE',
    dcr: 'page_load'
  },
  'argus/ui/CREATE_CURRENT_PROJECT_LOADED': {
    type: 'argus/ui/CREATE_CURRENT_PROJECT_LOADED',
    dcr: 'create_project'
  },
  'argus/ui/ITEMS_UPDATED': {
    type: 'argus/ui/ITEMS_UPDATED',
    dcr: 'document_processed'
  }
};
export default TRACKING_ACTIONS;
