import model from 'models/base-model';

var currentDateFormatFromDB = localStorage.getItem('currentDateFormat');
export class CurrentDateFormat extends model({
  currentDateFormat: currentDateFormatFromDB ? currentDateFormatFromDB : ''
}) {
  currentSelectedDateFormat(state, action) {
    var currentDateFormat = action.payload;
    localStorage.setItem('currentDateFormat', currentDateFormat);

    return this.merge({
      ...state,
      currentDateFormat
    });
  }
}
