import { connect } from 'react-redux';
import ExportSidebar from 'components/extraction-overview/export-sidebar/export-sidebar';
import { loadProjectTemplates, setTemplate } from 'store/extraction-overview';

const mapStateToProps = ({ data: { currentFolder, currentProject, currentUser, extractionOverview } }) => ({
  currentProject,
  currentUser,
  currentFolder,
  selectedItems: extractionOverview.templateExtractions?.map(i => ({ projectItemId: i.projectItemId, name: i.name }))
});

const mapDispatchToProps = {
  loadProjectTemplates,
  setTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportSidebar);
