import { connect } from 'react-redux';
import TrainingRequests from 'components/training-requests/training-requests';
import { setTrainingRequestType, loadTrainingRequestList } from 'store/training-request-type';

const mapStateToProps = ({ ui: { trainingRequest } }) => ({
  trainingRequest
});

const mapDispatchToProps = {
  setTrainingRequestType,
  loadTrainingRequestList
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingRequests);
