import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import GenericDeleteModal from 'components/shared/delete-modal/delete-modal';

class DeleteModal extends Component {
  static propTypes = {
    currentFolder: PropTypes.object,
    deleteItems: PropTypes.func.isRequired,
    invalidate: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.object
  };

  onSubmit = () => {
    const { currentFolder, selectedItems, projectId, deleteItems, onClose, invalidate } = this.props;
    deleteItems({
      projectId,
      items: currentFolder.children.filter(projectItem => selectedItems[projectItem.projectItemId])
    })
      .then(() => invalidate && invalidate())
      .catch(invalidate);
    onClose();
  };

  render() {
    const { onClose } = this.props;

    return (
      <GenericDeleteModal onCancel={onClose} onSubmit={this.onSubmit}>
        <p class="delete-files-modal__description-line-1">
          <FormattedMessage id="delete-file.modal.question-extended" />
        </p>
        <p class="delete-files-modal__description-line-2">
          <FormattedMessage id="delete-file.modal.question-extended-second-line" />
        </p>
      </GenericDeleteModal>
    );
  }
}

export default DeleteModal;
