import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Header from 'containers/header/header';
import EditExtractionsContainer from 'containers/edit-extractions/edit-extractions-container';
import NotificationsContainer from 'containers/shared/notifications-container';

import Permissions from 'permissions/permissions';
import history from 'utils/history';

class EditExtractionsPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    if (!Permissions.Project.ExtractionField.canViewTab()) {
      history.push('/');
    }
  }

  render() {
    const { templateId } = this.props.match.params;

    return (
      <div className="page">
        <Header {...this.props} currentPage="global.subheader.extraction" />
        <EditExtractionsContainer {...this.props} defaultTemplate={isNaN(templateId) ? null : +templateId} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default EditExtractionsPage;
