import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as api from 'store/api';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Button from 'components/shared/single-click-button';
import 'react-dropdown/style.css';


function DeleteMaintenanceMessagePopup({
  isDeleteWindowOpen,
  closeMaintenanceDeletePopup,
  messageId,
  maintenanceMessages,
  loadMessages
}) {
  const [loading, changeLoadingStatus] = useState(true);
  let maintenanceObj = { };
  let timezone={Id:'AME',Name:'AME'};
function DeleteClicked() {

  for(let i=0;i<maintenanceMessages.length;i++){
    if(maintenanceMessages[i].messageId==messageId) {
     // maintenanceObj=maintenanceMessages[i];
      maintenanceObj.MessageId=Number(maintenanceMessages[i].messageId);
maintenanceObj.GeoCode=maintenanceMessages[i].geoCode;
maintenanceObj.MessageText=maintenanceMessages[i].messageText;
maintenanceObj.StartDate=new Date(maintenanceMessages[i].startDate);
maintenanceObj.EndDate=new Date(maintenanceMessages[i].endDate);
maintenanceObj.TimeZone=timezone;
    }
  } 

  api
  .deleteMaintenanceMessage(Number(messageId))
  .then(res => {
    changeLoadingStatus(false);
    loadMessages();
  })
  .catch(() => {
    changeLoadingStatus(false);
  });
  closeMaintenanceDeletePopup();
}

  return isDeleteWindowOpen ? (
    <Modal>
      <div className="modal__content delete-maintenance-messagese-dialog">
        <div className="delete-maintenance-messagese-dialog__header">
          <Icon name="confirmation-warning" width={18} height={18} />
          <span className="delete-maintenance-messagese-dialog__header-text">
            <FormattedMessage id="maintenance-message.delete-confirmation-header" />
          </span>
          <Button type="button" size="icon" className="modal__close_x icon-button" onClick={closeMaintenanceDeletePopup}>
            <Icon name="special-cross-black" width={18} height={18} />
          </Button>
        </div>

        <div className="delete-maintenance-messagese-dialog__content">
          <div className="delete-maintenance-messagese-dialog__content-main">         
          
              <FormattedMessage id="maintenance-message.delete-confirmation" />
            
          </div>
          <div className="delete-maintenance-messagese-dialog__delete-buttons">
            <Button className="btn btn-cancel delete-maintenance-messagese-dialog__cancel-button" onClick={closeMaintenanceDeletePopup}>
              <FormattedMessage id="maintenance-message.delete-confirmation-cancel" />
            </Button>
            <Button className="btn btn-delete delete-maintenance-messagese-dialog__delete-button" onClick={DeleteClicked}>
              <FormattedMessage id="maintenance-message.delete-confirmation-delete" />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  ) : null;
}

export default DeleteMaintenanceMessagePopup;
