import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { LanguageData } from 'models/intl/languageData';

export const ACTIONS = {
  // Extractions
  LANGUAGE_DATA_LOADING: 'argus/ui/LANGUAGE_DATA_LOADING',
  LANGUAGE_DATA_LOADED: 'argus/ui/LANGUAGE_DATA_LOADED',
  LANGUAGE_DATA_ERROR: 'argus/ui/LANGUAGE_DATA_ERROR'
};

export const languageDataLoading = createAction(ACTIONS.LANGUAGE_DATA_LOADING);
export const languageDataLoaded = createAction(ACTIONS.LANGUAGE_DATA_LOADED);
export const languageDataError = createAction(ACTIONS.LANGUAGE_DATA_ERROR);

export const loadLanguageData = localeCode => dispatch => {
  dispatch(languageDataLoading());
  return api
    .getLanguageData(localeCode)
    .then(res => {
      dispatch(languageDataLoaded(res.data));
    })
    .catch(error => {
      dispatch(languageDataError(error));
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new LanguageData();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.LANGUAGE_DATA_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.LANGUAGE_DATA_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [ACTIONS.LANGUAGE_DATA_ERROR]: (state, action) => {
    return state.setError(state, action);
  }
});
