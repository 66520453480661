import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import NotificationsContainer from 'containers/shared/notifications-container';
import QuickStudyContainer from 'containers/quick-study/quick-study-container';
import ProjectsLeftMenu from 'components/project-list/project-menu-bar/project-left-menu';
import Permissions from 'permissions/permissions';
import history from 'utils/history';

class QuickStudyPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    if (
      !Permissions.Global.ExtractionField.canViewQuickStudyPageLink() ||
      !Permissions.Global.ExtractionField.canViewQuickStudyPageRegion()
    ) {
      history.push('/');
    }
  }

  render() {
    const { groupId, extractionFieldId } = this.props.match.params;

    return (
      <div className="page">


        <QuickStudyContainer groupId={groupId} extractionFieldId={extractionFieldId} {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default QuickStudyPage;
