import { connect } from 'react-redux';

import { loadFormatData } from 'store/intl/loadFormat';
import { setFormat } from 'store/intl/currentFormat';
import FormatSelect from 'components/header/account-dropdown/format-select/format-select';

const mapStateToProps = ({ data: { loadFormat: { formats,isLoading },currentFormat:{currentFormat}}}) => ({
  formats,
  isLoading, 
  currentFormat
});

const mapDispatchToProps = {

  loadFormatData,
  setFormat
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormatSelect);
