import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { CurrentDateFormat } from 'models/intl/currentDateFormat';

export const ACTIONS = {
  CURRENT_DATE_FORMAT_SET: 'argus/ui/CURRENT_DATE_FORMAT_SET'
};

export const currentDateFormatSet = createAction(ACTIONS.CURRENT_DATE_FORMAT_SET);

export const setDateFormat = () => dispatch => {

  api.getUserSelectedDateFormat().then(res => {
    dispatch(currentDateFormatSet(res.userPreferencesString));
    return res.userPreferencesString;
  });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new CurrentDateFormat();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.CURRENT_DATE_FORMAT_SET]: (state, action) => {
    return state.currentSelectedDateFormat(state, action);
  }
});
