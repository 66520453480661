import { connect } from 'react-redux';

import ExportSidebar from 'components/file-browser/export-sidebar/export-sidebar';

const mapStateToProps = ({
  data: { currentFolder, currentProject, currentUser },
  ui: { fileBrowserSelectedItems }
}) => ({
  currentProject,
  currentUser,
  selectedItems: fileBrowserSelectedItems
});

export default connect(
  mapStateToProps,
  null
)(ExportSidebar);
