import { connect } from 'react-redux';

import { loadProjectList } from 'store/project-list';
import { loadTranslationsList } from 'store/translations';
import LandingPage from 'components/landing-page/landing-page';

const mapStateToProps = ({ data: { projectList, currentProject, translations, currentLanguage } }) => ({
  projectList,
  currentProject,
  translations,
  currentLanguage
});

const mapDispatchToProps = {
  loadProjectList,
  loadTranslationsList
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
