import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { CurrentFormat } from 'models/intl/currentFormat';

export const ACTIONS = {
  CURRENT_FORMAT_SET: 'argus/ui/CURRENT_FORMAT_SET'
};

export const currentFormatSet = createAction(ACTIONS.CURRENT_FORMAT_SET);

export const setFormat = () => dispatch => {

  api.getUserSelectedFormat().then(res => {
    dispatch(currentFormatSet(res.userPreferencesString));
    return res.userPreferencesString;
  });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new CurrentFormat();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.CURRENT_FORMAT_SET]: (state, action) => {
    return state.currentSelectedFormat(state, action);
  }
});
