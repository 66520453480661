import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { getCurrentProject, clearProjectData } from 'store/current-project';
import { getCurrentUser } from 'store/current-user';
import Authorization from 'components/app/authorization';

const mapStateToProps = ({ data: { currentUser, currentProject } }) => ({
  currentUser,
  currentProject
});

const mapDispatchToProps = {
  getCurrentUser,
  getCurrentProject,
  clearProjectData
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Authorization)
);
