import { createAction, createReducer } from 'utils/redux-utils';

export const ACTIONS = {
  SET_LOCALE: 'argus/ui/SET_LOCALE'
};

export const setLocale = createAction(ACTIONS.SET_LOCALE);

/*
 * Reducer
 */
export const INITIAL_STATE = 'en';

export default createReducer(INITIAL_STATE, {
  [ACTIONS.SET_LOCALE]: (state, action) => {
    return action.payload;
  }
});
