import RolesHelper from 'permissions/helpers/roles';
import constants from 'utils/constants';

const ProjectUserPermissions = {
  canAdd: (project, region) => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canEnableAccess: (project, region) => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canEditSelf: (project, region) => {
    return (
      !RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) &&
      (RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
        RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL))
    );
  },

  canDelete: (project, region) => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canEdit: (project, region) => {
    return (
      (RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL) )  &&
      project.projectTypeId != constants.ProjectTypes.WORKSPACE_PROJECT
    );
  },

  canView: (project, region) => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  }
};

export default ProjectUserPermissions;
