import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Confirm from 'components/shared/confirm';

/**
 * Export Extraction field / Export Group modal
 * Shows a confirmation message before allowing the user to export the selected groups / fields
 */
class ExportModal extends Component {
  static propTypes = {
    disableSubmit: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    isRequestPending: false
  };

  onSubmit = () => {
    const { onSubmit } = this.props;
    this.setState({ isRequestPending: true });
    onSubmit();
  };

  render() {
    const { disableSubmit, onCancel } = this.props;
    const { isRequestPending } = this.state;

    return (
      <Confirm
        disableSubmit={disableSubmit || isRequestPending}
        onCancel={onCancel}
        onSubmit={this.onSubmit}
        title={<FormattedMessage id="common.export" />}
        message={<FormattedMessage id="quick-study.toolbar.export.message" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        confirmText={<FormattedMessage id="common.export" />}
      />
    );
  }
}

export default ExportModal;
