import RolesHelper from 'permissions/helpers/roles';
import StatesHelper from 'permissions/helpers/states';
import constants from 'utils/constants';

import ProjectAnalytics from 'permissions/sections/project-analytics';
import ProjectComparison from 'permissions/sections/project-comparison';
import ProjectDocument from 'permissions/sections/project-document';
import ProjectExtractionField from 'permissions/sections/project-extraction-field';
import ProjectFolder from 'permissions/sections/project-folder';
import ProjectSettings from 'permissions/sections/project-settings';
import ProjectTemplate from 'permissions/sections/project-template';
import ProjectUser from 'permissions/sections/project-user';
import ProjectVisualization from 'permissions/sections/project-visualization';
import ProjectTags from 'permissions/sections/project-tags';
import ProjectReviewStatus from 'permissions/sections/project-review-status';

const ProjectPermissions = {
  Analytics: ProjectAnalytics,
  Comparison: ProjectComparison,
  Document: ProjectDocument,
  ExtractionField: ProjectExtractionField,
  Folder: ProjectFolder,
  ReviewStatus: ProjectReviewStatus,
  Settings: ProjectSettings,
  Tags: ProjectTags,
  Template: ProjectTemplate,
  User: ProjectUser,
  Visualization: ProjectVisualization,

  _States: {
    isReadOnly: project => {
      return StatesHelper.isProjectReadOnly(project);
    },
    isProjectArchived: project => {
      return StatesHelper.isProjectArchived(project);
    }
  },

  canViewApprovalBanner: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canViewProjectDropdownMenu: (region, project) => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canView: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canViewDeletedProjects: region => {
    return RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL);
  },

  canViewStatus: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canCreate: () => {
    return true;
  },

  canDelete: (project, region) => {
    return (project.projectTypeId != constants.ProjectTypes.WORKSPACE_PROJECT) && (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canRestore: (project, region) => {
    return RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL);
  },

  canCopy: (project, region)  => {
    return (project.projectTypeId != constants.ProjectTypes.WORKSPACE_PROJECT) && (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL) 
    );
  },

  canEnableSupportAccess: project =>
    RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
    RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR),

  canCarryForward: (project, region) => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canRequestArchive: (project, region) => {
    return (project.projectTypeId != constants.ProjectTypes.WORKSPACE_PROJECT) && (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canCancelArchiveRequest: (project, user) => {
    return RolesHelper.isUserArchiveRequestor(project, user);
  },

  canApproveArchive: (project, user) => {
    return RolesHelper.isUserSelectedApprover(project, user);
  },

  canRejectArchive: (project, user) => {
    return RolesHelper.isUserSelectedApprover(project, user);
  },

  canFlagNonClientService: (region) => {
    return RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
  },

  canInitiateLegalHold: (project, region) => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR) ||
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canLiftLegalHold: (region) => {
    return (
      RolesHelper.doesUserHaveRegionalRole(region, constants.UserRoles.ADMIN_GENERAL)
    );
  },

  canTag: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canViewTag: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canUndo: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  }
};

export default ProjectPermissions;
