import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'components/shared/single-click-button';
import Modal from 'components/shared/modal';

const MAX_NAME_LENGTH = 400;
const MAX_DESCRIPTION_LENGTH = 1000;

/**
 * Create Extraction field modal
 */
class CreateModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    name: '',
    description: ''
  };

  /**
   * Handle a change event on the name input
   */
  onNameChange = event => {
    const { value } = event.target;

    if (value.length <= MAX_NAME_LENGTH) {
      this.setState({ name: value });
    }
  };

  /**
   * Handle a change event on the description input
   */
  onDescriptionChange = event => {
    const { value } = event.target;

    if (value.length <= MAX_DESCRIPTION_LENGTH) {
      this.setState({ description: value });
    }
  };

  /**
   * Handle a submit event on the form
   */
  onSubmit = event => {
    const { onSubmit } = this.props;
    const { name, description } = this.state;

    event.preventDefault();
    onSubmit({ name, description });
  };

  render() {
    const { onClose } = this.props;
    const { name, description } = this.state;

    return (
      <Modal>
        <form className="modal__content modal__content--small" onSubmit={this.onSubmit}>
          <h3>
            <FormattedMessage id="quick-study.modal.create.title" />
          </h3>
          <div className="modal__body">
            <label className="modal__label">
              <FormattedMessage id="quick-study.modal.create.name-label" />
            </label>
            <input
              autoFocus
              className="modal__input"
              id="extraction-field-name"
              maxLength={MAX_NAME_LENGTH}
              onChange={this.onNameChange}
              placeholder=""
              type="text"
              value={name}
            />
            <label className="modal__label">
              <FormattedMessage id="quick-study.modal.create.description-label" />
            </label>
            <input
              className="modal__input"
              id="extraction-field-description"
              maxLength={MAX_DESCRIPTION_LENGTH}
              onChange={this.onDescriptionChange}
              placeholder=""
              type="text"
              value={description}
            />
          </div>
          <div className="modal__footer">
            <Button type="button" className="btn btn-secondary" onClick={onClose}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button type="submit" className="btn btn-primary" disabled={!name || !description}>
              <FormattedMessage id="common.create" />
            </Button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default CreateModal;
