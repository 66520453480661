import React from 'react';
import { FormattedDate } from 'react-intl';

export const STEPS = {
  PROJECT_DETAILS: 0,
  TEAM_MEMBERS: 1,
  WORKFLOWS: 2
};

export const DEFAULT_STEPS = [STEPS.PROJECT_DETAILS, STEPS.TEAM_MEMBERS, STEPS.WORKFLOWS];

export const SETTINGS_STEPS = [STEPS.PROJECT_DETAILS, STEPS.TEAM_MEMBERS];

export const STEP_NAMES = {
  [STEPS.PROJECT_DETAILS]: 'project-creations.progress-bar.step.project-details',
  [STEPS.TEAM_MEMBERS]: 'project-creations.progress-bar.step.team-members',
  [STEPS.WORKFLOWS]: 'project-creations.progress-bar.step.workflows'
};

export const MAT_CATEGORIES = [
  { value: 'engagementName', render: data => data.engagementName },
  { value: 'externalEngagementId', render: data => data.externalEngagementId },
  { value: 'clientId', render: data => data.clientId },
  { value: 'clientName', render: data => data.clientName },
  {
    value: 'fiscalEnd',
    render: data => <FormattedDate value={data.engagementFiscalYearEnd} month="numeric" day="numeric" />
  },
  {
    value: 'fiscalYearEnd',
    render: data => <FormattedDate value={data.engagementFiscalYearEnd} year="numeric" />
  }
];

export const MAT_ENGAGEMENT_CATEGORIES = [
  { value: 'engagementName', render: data => data.engagementName },
  { value: 'externalEngagementId', render: data => data.externalEngagementId }
];

export const CLIENT_CATEGORIES = [
  { value: 'clientName', render: data => data.clientName },
  { value: 'clientId', render: data => data.clientId },
  {
    value: 'fiscalEnd',
    render: data => <FormattedDate value={data.engagementFiscalYearEnd} month="numeric" day="numeric" />
  },
  {
    value: 'fiscalYearEnd',
    render: data => <FormattedDate value={data.engagementFiscalYearEnd} year="numeric" />
  }
];
