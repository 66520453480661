import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import { getIntlKeyForCode } from 'utils/errors';
import { UPLOAD_STATES } from 'models/uploads';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import { ERROR_CODES } from 'utils/errors';

class UploadStatusFile extends Component {
  static propTypes = {
    cancelUpload: PropTypes.func.isRequired,
    upload: PropTypes.object.isRequired
  };

  onCancelClick = () => {
    const { cancelUpload, upload } = this.props;

    cancelUpload({ id: upload.id });
  };

  render() {
    const {
      upload: { state, loaded, name, total, errorCode }
    } = this.props;

    const progressPercent = loaded ? (loaded * 100) / total : 0;
    const iconName =
      state === UPLOAD_STATES.CANCELLED || state === UPLOAD_STATES.FAILED ? 'special-error' : 'special-doc';

    const displaySize =
      total > 1024 ** 3
        ? `${(total / 1024 ** 3).toFixed(2)}GB`
        : total > 1024 ** 2
          ? `${(total / 1024 ** 2).toFixed(2)}MB`
          : `${(total / 1024).toFixed(2)}kB`;

    const errorMessage = getIntlKeyForCode(errorCode);

    return (
      <div className={classNames('upload-status-file', `upload-status-file--${state.toLowerCase()}`)}>
        <div className="upload-status-file__info" data-tip data-for="upload-status.error.tooltip">
          <Icon name={iconName} width={16} className="upload-status-file__icon" />
          {errorMessage && errorCode !== ERROR_CODES.DOCUMENT_INFECTED && (
            <ReactTooltip id="upload-status.error.tooltip" effect="solid" place="left">
              <FormattedMessage id={errorMessage} />
            </ReactTooltip>
          )}
          <div className="upload-status-file__name">
            {state === UPLOAD_STATES.FAILED ? (
              errorCode !== ERROR_CODES.DOCUMENT_INFECTED ?
              <span className="upload-status-file__name-span">
                <FormattedMessage id="upload-status.error" values={{ name }} />
              </span>:
              <div data-tip data-for="upload-status.failed.tooltip">
                {errorMessage && errorCode === ERROR_CODES.DOCUMENT_INFECTED && (
                  <ReactTooltip id="upload-status.failed.tooltip" effect="solid" place="left">
                    <FormattedMessage id={errorMessage} />
                  </ReactTooltip>
                )}
              <span className="upload-status-file__name-span">
                <FormattedMessage id="upload-status.failed" values={{ name }} />
              </span> 
              </div>
            ) : null}
            {state === UPLOAD_STATES.CANCELLED ? (
              <span className="upload-status-file__name-span">
                <FormattedMessage id="upload-status.cancelled" values={{ name }} />
              </span>
            ) : null}
            {state === UPLOAD_STATES.COMPLETED ? (
              <span className="upload-status-file__name-span">
                <FormattedMessage id="upload-status.completed" values={{ name }} />
              </span>
            ) : null}
            {state === UPLOAD_STATES.PENDING || state === UPLOAD_STATES.UPLOADING ? (
              <span className="upload-status-file__name-span">{name}</span>
            ) : null}
          </div>
          <div className="upload-status-file__size">&nbsp; {total ? displaySize : ''}</div>
          <Button
            className="btn upload-status-file__cancel"
            onClick={this.onCancelClick}
            data-tip
            data-for="upload-status.cancel-button.tooltip"
          >
            <Icon name="special-c-cross" width={18} />
            {(state === UPLOAD_STATES.UPLOADING || state === UPLOAD_STATES.PENDING) && (
              <ReactTooltip id="upload-status.cancel-button.tooltip" effect="solid" place="left">
                <FormattedMessage id="upload-status.cancel-button.tooltip" />
              </ReactTooltip>
            )}
          </Button>
        </div>
        <div className="upload-status-file__progress-bar">
          <div className="upload-status-file__progress-bar__bar" style={{ width: `${progressPercent}%` }} />
        </div>
      </div>
    );
  }
}

class UploadStatus extends Component {
  static propTypes = {
    cancelUpload: PropTypes.func.isRequired,
    uploads: PropTypes.object.isRequired
  };

  onCancelAllClick = () => {
    const { cancelUpload, uploads } = this.props;

    Object.keys(uploads).forEach(id => cancelUpload({ id: id }));
  };

  render() {
    const { cancelUpload, uploads } = this.props;

    const sortedUploads = Object.keys(uploads)
      .map(id => uploads[id])
      .sort((a, b) => a.timestamp - b.timestamp);

    const numUploads = Object.values(uploads).filter(upload =>
      [UPLOAD_STATES.PENDING, UPLOAD_STATES.UPLOADING].includes(upload.state)
    ).length;

    if (!sortedUploads.length) {
      return null;
    }

    return (
      <div className="upload-status popover">
        <div className="upload-status__header popover__section">
          <h3 className="upload-status__title">
            {numUploads ? (
              <FormattedMessage id="upload-status.title" values={{ numUploads }} />
            ) : (
              <FormattedMessage id="upload-status.recent-uploads" />
            )}
          </h3>
          <Button size="icon" className="btn btn-no-margin upload-status__clear-button" onClick={this.onCancelAllClick}>
            {numUploads ? <FormattedMessage id="upload-status.cancel-all" /> : <FormattedMessage id="common.close" />}
          </Button>
        </div>
        <div className="upload-status__body popover__section">
          {sortedUploads.map(upload => (
            <UploadStatusFile key={upload.id} upload={upload} cancelUpload={cancelUpload} />
          ))}
        </div>
      </div>
    );
  }
}

export default UploadStatus;
