import model from 'models/base-model';

export class TrainingRequests extends model({
  isLoading: false,
  error: null,
  trainingRequests: null,
  type: 'PendingRequest'
}) {
  setTrainingRequestType = (state, action) => {
    return this.merge({
      ...state,
      type: action.payload
    });
  };

  setLoading = () => {
    return this.merge({ isLoading: true, error: null });
  };

  setLoaded = (state, action) => {
    return this.merge({ ...state, isLoading: false, error: null, trainingRequests: action.payload });
  };
}
