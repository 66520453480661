import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { DocumentProcessing } from 'models/review-status/document-processing';

// used to hook onto clear call from search results store
import { ACTIONS as SearchResultsActions } from 'store/search/search-results';

export const ACTIONS = {
  DOCUMENT_PROCESSING_LOADING: 'argus/ui/DOCUMENT_PROCESSING_LOADING',
  DOCUMENT_PROCESSING_LOADED: 'argus/ui/DOCUMENT_PROCESSING_LOADED',
  DOCUMENT_PROCESSING_CLEAR: 'argus/ui/DOCUMENT_PROCESSING_CLEAR'
};

export const documentProcessingLoading = createAction(ACTIONS.DOCUMENT_PROCESSING_LOADING);
export const documentProcessingLoaded = createAction(ACTIONS.DOCUMENT_PROCESSING_LOADED);
export const documentProcessingClear = createAction(ACTIONS.DOCUMENT_PROCESSING_CLEAR);

export const getData = projectId => dispatch => {
  dispatch(documentProcessingLoading());
  return api.getDocumentProcessingStatus(projectId).then(res => {
    dispatch(documentProcessingLoaded(res.data));
  });
};

export const clear = () => dispatch => {
  dispatch(documentProcessingClear());
};

/*
 * Reducer
 */
export const INITIAL_STATE = new DocumentProcessing();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.DOCUMENT_PROCESSING_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.DOCUMENT_PROCESSING_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [ACTIONS.DOCUMENT_PROCESSING_CLEAR]: (state, action) => {
    return state.clear();
  },

  [SearchResultsActions.SEARCH_RESULTS_CLEAR]: (state, action) => {
    return state.clear();
  }
});
