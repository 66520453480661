import React from 'react';
import { debounce } from 'underscore';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import Page from 'containers/document-viewer/document-renderer/page-container';
import DocumentControls from './document-controls/document-controls';
import Minimap from './minimap/minimap';
import DocumentSplitter from './document-splitter/document-splitter';

class DocumentRenderer extends React.Component {
  initPromise = null;

  static propTypes = {
    currentDocument: PropTypes.object,
    currentDocumentLayout: PropTypes.object,
    currentProject: PropTypes.object,
    currentSelection: PropTypes.any,
    focusFunction: PropTypes.func,
    getCurrentDocument: PropTypes.func,
    history: PropTypes.object,
    initialLocation: PropTypes.object,
    isMenuOpen: PropTypes.bool,
    onClickOutside: PropTypes.func,
    organizeDocuments: PropTypes.func.isRequired,
    pushUndoStack: PropTypes.func,
    readOnly: PropTypes.bool,
    removeDocType: PropTypes.func,
    searchResults: PropTypes.any,
    selection: PropTypes.any,
    selectionFunction: PropTypes.func,
    setExtractionFieldFilter: PropTypes.func.isRequired,
    updateMenu: PropTypes.func,
    updateSampleNumber: PropTypes.func.isRequired,
    clearUndoStack: PropTypes.func
  };

  state = {
    currentPage: 1,
    shiftKeyDown: false,
    zoomLevel: 1.0,
    pageBoundaries: [],
    pagesInViewport: [],
    pageImageLoaded: false
  };

  handleClickOutside = () => {
    this.props.focusFunction(false);
  };

  scrollArea = null;
  PAGE_BUFFER_SIZE = 1;

  componentDidUpdate(prevProps) {
    const { documentId: docDidLoad } = this.props.currentDocument;
    const { documentId: docWasLoaded } = prevProps.currentDocument;
    if ((!docWasLoaded && docDidLoad) || docWasLoaded !== docDidLoad) {
      this.setLastVisitedPage();
    }
  }

  UNSAFE_componentWillMount() {
    //Listen for page change events emmited by other controls
    window.addEventListener('message', this.messageListener, false);

    //Add keylisteners
    window.addEventListener('keydown', this.keyListener);
    window.addEventListener('keyup', this.keyListener);

    var previousZoom = sessionStorage.getItem('zoomLevel');

    if (previousZoom && previousZoom !== this.state.zoomLevel) this.changeZoom(parseFloat(previousZoom));
  }

  componentDidMount() {
    this.scrollArea.addEventListener('scroll', this.scrollChange);

    this.initialize();
  }

  initialize = () => {
    setTimeout(() => {
      this.calculatePageBoundaries();
      this.setInitialPosition();
      this.calculatePagesInViewport();
    }, 200); //Slight delay added to ensure this fires after render completes (boundary calculation needs to be done after DOM is available)
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.zoomEventListener, false);
    window.removeEventListener('keydown', this.keyListener);
    window.removeEventListener('keyup', this.keyListener);
  }

  // Parse query string for page nums so we can scoll to it
  parseQueryPage = (page, totalPages) => {
    if (!parseInt(page, 10)) {
      return null;
    }
    return page > totalPages ? Math.min(totalPages, page) : page < 1 ? Math.max(1, page) : page;
  };

  messageListener = message => {
    if (message && message.data && message.data.document_page_change) {
      this.scrollToPage(message.data.document_page_change);
    }
  };

  keyListener = e => {
    this.setState({
      shiftKeyDown: e.shiftKey
    });
  };

  setInitialPosition = () => {
    const { initialLocation, currentDocumentLayout } = this.props;

    if (initialLocation.location) {
      this.scrollToPage(this.getPageNumber(initialLocation.location));
    } else if (initialLocation.page) {
      const pageNum = this.parseQueryPage(initialLocation.page, currentDocumentLayout.layout.length);
      this.scrollToPage(pageNum);
    } else {
      this.setLastVisitedPage();
    }
  };

  // Used to automatically return a previously opened document to the last viewed page
  setLastVisitedPage = () => {
    var { documentId } = this.props.currentDocument;
    var page = sessionStorage.getItem(`document-${documentId}-page`);
    this.scrollToPage(page);
  };

  getPageNumber = location => {
    var matchingPage = this.props.currentDocumentLayout.layout.find(
      page => location[0] >= page.range.start && location[0] < page.range.end
    );

    return matchingPage ? matchingPage.pageNumber : 0;
  };

  scrollToPage = i => {
    i = parseInt(i, 10);

    if (0 < i && i <= this.props.currentDocumentLayout.layout.length) {
      var element = document.getElementById(`page-${i}`);

      if (element && this.scrollArea) {
        var top = element.offsetTop - 100;
        this.scrollArea.scrollTop = top;
        this.scrollChange();
      }
    }
  };

  changeZoom = ratio => {
    sessionStorage.setItem('zoomLevel', ratio);
    this.setState({ zoomLevel: ratio }, () => {
      this.calculatePageBoundaries();
      this.setLastVisitedPage();
    });
  };

  scrollChange = debounce(event => {
    this.calculatePagesInViewport();
    this.calculateCurrentPage();
  }, 100);

  calculatePageBoundaries = () => {
    var pageBoundaries = [];
    var elements = [...document.getElementsByClassName('renderer-page')];

    elements.forEach(element => {
      pageBoundaries.push({
        top: element.offsetTop,
        bottom: element.offsetTop + element.offsetHeight
      });
    });

    this.setState({
      pageBoundaries: pageBoundaries
    });
  };

  calculatePagesInViewport = () => {
    if (!this.scrollArea) return;

    var scrollInfo = {
      scrollHeight: this.scrollArea.scrollHeight,
      offsetHeight: this.scrollArea.offsetHeight,
      scrollTop: this.scrollArea.scrollTop
    };

    var top = scrollInfo.scrollTop;
    var bottom = top + scrollInfo.offsetHeight;
    var { pageBoundaries } = this.state;
    var pagesInViewport = [];

    pageBoundaries.forEach((page, i) => {
      if (
        (top < page.top && page.top < bottom) ||
        (top < page.bottom && page.bottom < bottom) ||
        (page.top < top && page.bottom > bottom)
      ) {
        pagesInViewport.push({
          index: i,
          pageNumber: i + 1,
          page: page
        });
      }
    });

    this.setState({
      pagesInViewport: pagesInViewport
    });
  };

  calculateCurrentPage = () => {
    const { currentDocument } = this.props;
    const { pagesInViewport } = this.state;
    var scrollInfo = {
      scrollHeight: this.scrollArea.scrollHeight,
      offsetHeight: this.scrollArea.offsetHeight,
      scrollTop: this.scrollArea.scrollTop
    };

    var currentPageNumber = 0;
    var shortestDistance = Number.MAX_SAFE_INTEGER;
    var middleOfScrollArea = Math.round(scrollInfo.scrollTop + scrollInfo.offsetHeight / 2);

    pagesInViewport.forEach(page => {
      var middleOfPage = page.page.top + (page.page.bottom - page.page.top) / 2;
      var distance = Math.abs(middleOfScrollArea - middleOfPage);

      if (distance < shortestDistance) {
        shortestDistance = distance;
        currentPageNumber = page.index + 1; //Convert 0 index to page numbers
      }
    });

    sessionStorage.setItem(`document-${currentDocument.documentId}-page`, currentPageNumber);
    this.setState({ currentPage: currentPageNumber });
  };

  setPageImageLoaded = () => {
    this.setState({ pageImageLoaded: true });
  };

  render() {
    const { shiftKeyDown, zoomLevel, pagesInViewport, currentPage, pageImageLoaded } = this.state;

    const {
      currentDocument,
      currentDocumentLayout,
      currentProject,
      focusFunction,
      getCurrentDocument,
      searchResults,
      selection,
      currentSelection,
      selectionFunction,
      readOnly,
      pushUndoStack,
      organizeDocuments,
      updateSampleNumber,
      setExtractionFieldFilter,
      isMenuOpen,
      removeDocType,
      updateMenu,
      clearUndoStack
    } = this.props;

    return (
      <div
        className="document-renderer"
        ref={element => (this.scrollArea = element)}
        onClick={() => focusFunction(true)}
      >
        {currentDocumentLayout.isLoaded && currentDocument.isLoaded ? (
          <DocumentSplitter
            projectId={currentDocument.projectId}
            documentId={currentDocument.documentId}
            sections={currentDocument.sections}
            currentProject={currentProject}
            pageList={currentDocumentLayout.layout}
            currentPage={currentPage}
            organizeDocuments={organizeDocuments}
            updateSampleNumber={updateSampleNumber}
            setExtractionFieldFilter={setExtractionFieldFilter}
            sectionLoading={currentDocument.sectionLoading}
            pageImageLoaded={pageImageLoaded}
            isMenuOpen={isMenuOpen}
            updateMenu={updateMenu}
            extractionFieldRecords={currentDocument.extractionFieldRecords}
            removeDocType={removeDocType}
            getCurrentDocument={getCurrentDocument}
            clearUndoStack={clearUndoStack}
          />
        ) : null}
        {currentDocumentLayout.isLoaded
          ? currentDocumentLayout.layout.map((page, i) => {
              const showPage = pagesInViewport && pagesInViewport.some(p => p.index === i);
              return (
                <Page
                  hideBookmark ={false}
                  key={i + 1}
                  page={page}
                  pushUndoStack={pushUndoStack}
                  searchResults={searchResults}
                  // Only pass selection to pages that are shown
                  // Prents all pages from updating when the selection changes
                  selection={showPage ? selection : null}
                  currentSelection={showPage ? currentSelection : null}
                  selectionFunction={selectionFunction}
                  shiftKeyDown={shiftKeyDown}
                  showPage={showPage}
                  zoomLevel={zoomLevel}
                  readOnly={readOnly}
                  setPageImageLoaded={this.setPageImageLoaded}
                />
              );
            })
          : null}

        {currentDocumentLayout.isLoaded ? (
          <DocumentControls
            changePageFunction={this.scrollToPage}
            changeZoomFunction={this.changeZoom}
            currentPage={currentPage}
            numPages={currentDocumentLayout.layout.length}
            zoomLevel={zoomLevel}
          />
        ) : null}

        <Minimap
          zoomLevel={zoomLevel}
          scrollArea={this.scrollArea}
          extractionFieldRecords={currentDocument.extractionFieldRecords}
          layout={currentDocumentLayout.layout}
          characters={currentDocumentLayout.characters}
        />
      </div>
    );
  }
}

export default enhanceWithClickOutside(DocumentRenderer);
