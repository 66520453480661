import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as d3 from 'd3';

import setUtil from 'utils/setUtil';

export default class VisualsDocuments extends Component {
  static propTypes = {
    documents: PropTypes.array.isRequired,
    searchFilteredDocuments: PropTypes.array.isRequired,
    selectedDocumentIdSet: PropTypes.object,
    setAdditionalSelectedDocumentIdSet: PropTypes.func.isRequired,
    setSelectedDocumentIdSet: PropTypes.func.isRequired
  };

  colors = [
    '#00a3e0',
    '#012169',
    '#007680',
    '#00abab',
    '#046A38',
    '#43b02a',
    '#c4d600',
    '#f39200',
    '#E30613',
    '#0076A8',
    '#042070',
    '#0097A9',
    '#009A44',
    '#86BC25'
  ];
  margin = 20;

  d3State = {
    width: undefined,
    height: undefined,
    padding: undefined,
    minRadius: undefined,
    rootGroup: undefined,
    nodes: undefined,
    x: undefined,
    y: undefined,
    data: undefined,
    force: undefined,
    selectionOrigPoint: undefined,
    selectionRect: undefined,
    selectionElem: undefined
  };

  componentDidMount() {
    this.createChart();
    this.updateChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  getRadius = d => {
    return this.d3State.minRadius + this.d3State.minRadius * 0.3; //0.75 * d.size;
  };

  tick = e => {
    const alpha = e.alpha ** 0.3;
    this.d3State.nodes.each(this.moveTowardDataPosition(alpha));

    this.d3State.nodes.each(this.collide(alpha));

    this.d3State.nodes
      .attr('cx', d => {
        return d.x;
      })
      .attr('cy', d => {
        return d.y;
      });
  };

  moveTowardDataPosition = alpha => {
    return d => {
      d.x += (this.d3State.x(d.origX) - d.x) * 0.2 * alpha;
      d.y += (this.d3State.y(d.origY) - d.y) * 0.2 * alpha;
    };
  };

  // Resolve collisions between nodes.
  collide = alpha => {
    var quadtree = d3.geom.quadtree(this.d3State.data);
    return d => {
      var r = d.radius + this.getRadius(d) + this.d3State.padding * 4,
        nx1 = d.x - r,
        nx2 = d.x + r,
        ny1 = d.y - r,
        ny2 = d.y + r;
      quadtree.visit((quad, x1, y1, x2, y2) => {
        if (quad.point && quad.point !== d) {
          var x = d.x - quad.point.x,
            y = d.y - quad.point.y,
            l = Math.sqrt(x * x + y * y),
            r = d.radius + quad.point.radius + (d.cluster !== quad.point.cluster) * this.d3State.padding * 2;

          //If the 2 points are on top of each other this will break the tie
          if (l === 0) {
            l = 0.1;
            x += 0.01;
            y -= 0.01;
          }

          if (l < r) {
            l = ((l - r) / l) * alpha;
            d.x -= x *= l;
            d.y -= y *= l;
            quad.point.x += x;
            quad.point.y += y;
          }
        }
        return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
      });
    };
  };

  createChart = () => {
    const oldElem = document.querySelector('.visuals-documents__chart g');
    if (oldElem) {
      oldElem.parentNode.removeChild(oldElem);
    }

    const root = d3.select('.visuals-documents__chart');

    this.d3State.width = root[0][0].clientWidth;
    this.d3State.height = root[0][0].clientHeight;

    const avgSize = this.d3State.width + this.d3State.height / 2;
    this.d3State.padding = avgSize / 750; // separation between nodes
    this.d3State.minRadius = avgSize / 280;

    root.attr('viewBox', `0 0 ${this.d3State.width + this.margin * 2} ${this.d3State.height + this.margin * 2}`);
    this.d3State.rootGroup = root.append('g').attr('transform', `translate(${this.margin},${this.margin})`);

    this.d3State.selectionElem = root
      .append('rect')
      .attr(this.d3State.selectionRect)
      .classed('visuals-documents__selection-rect', true);

    var self = this;
    var dragBehavior = d3.behavior
      .drag()
      .on('drag', function() {
        self.dragMove(this);
      })
      .on('dragstart', function() {
        self.dragStart(this);
      })
      .on('dragend', function() {
        self.dragEnd();
      });
    root.call(dragBehavior);

    this.d3State.x = d3.scale
      .linear()
      .range([0, this.d3State.width])
      .domain([0, 1]);
    this.d3State.y = d3.scale
      .linear()
      .range([this.d3State.height, 0])
      .domain([0, 1]);

    this.d3State.force = d3.layout
      .force()
      .size([this.d3State.width, this.d3State.height])
      .on('tick', this.tick)
      .charge(-1)
      .gravity(0)
      .chargeDistance(2);
  };

  updateChart = () => {
    const { documents, selectedDocumentIdSet, setSelectedDocumentIdSet, searchFilteredDocuments } = this.props;

    const tooltip = d3.select('.visuals-documents__chart-tooltip');

    let prevDataMap = {};

    this.d3State.data && this.d3State.data.forEach(d => (prevDataMap[d.id] = d));

    const searchFilteredDocumentIds = new Set();
    searchFilteredDocuments.forEach(d => searchFilteredDocumentIds.add(d.id));

    this.d3State.data = documents.map(doc => {
      let minOpacity = searchFilteredDocumentIds.has(doc.id) ? 0.35 : 0.2;
      let maxOpacity = searchFilteredDocumentIds.has(doc.id) ? 1 : 0.7;
      let centralityMeasure = (1 - doc.distanceFromCenter) * 0.25 + doc.density * 0.75;
      let relOpacity = minOpacity + centralityMeasure * (maxOpacity - minOpacity);

      let color;

      if (selectedDocumentIdSet.has(doc.id)) {
        color = '#FFD500';
      } else if (searchFilteredDocumentIds.has(doc.id)) {
        color = this.colors[doc.cluster % this.colors.length];
      } else {
        color = '#808080';
      }
      const radius = this.getRadius(doc);

      let prevDoc = prevDataMap[doc.id];

      return {
        ...doc,
        origX: doc.x,
        origY: doc.y,
        x: prevDoc ? prevDoc.x : this.d3State.x(doc.x),
        y: prevDoc ? prevDoc.y : this.d3State.y(doc.y),
        color,
        opacity: relOpacity,
        radius
      };
    });

    this.d3State.nodes = this.d3State.rootGroup
      .selectAll('.visuals-documents__chart-dot')
      .data(this.d3State.data, d => d.id);

    this.d3State.nodes
      .enter()
      .append('circle')
      .attr('class', 'visuals-documents__chart-dot')
      .attr('r', d => {
        return this.getRadius(d);
      })
      .attr('cx', d => {
        return this.d3State.x(d.origX);
      })
      .attr('cy', d => {
        return this.d3State.y(d.origY);
      })
      .on('mouseover', d => {
        tooltip
          .transition()
          .duration(200)
          .style('opacity', 0.9);
        tooltip
          .text(d.name)
          .style('left', d3.event.pageX + 'px')
          .style('top', d3.event.pageY - 28 + 'px');
      })
      .on('mouseout', d => {
        tooltip
          .transition()
          .duration(500)
          .style('opacity', 0);
      })
      .on('click', d => {
        d3.event.stopPropagation();

        let selectedDocSet;

        if (d3.event.ctrlKey) {
          selectedDocSet = setUtil.immutableToggle(this.props.selectedDocumentIdSet, d.id);
        } else {
          if (this.props.selectedDocumentIdSet.has(d.id)) {
            selectedDocSet = new Set();
          } else {
            selectedDocSet = new Set([d.id]);
          }
        }

        setSelectedDocumentIdSet(selectedDocSet);
      })
      .style('fill', d => {
        return d.color;
      })
      .style('fill-opacity', d => {
        return d.opacity;
      });

    this.d3State.nodes.exit().remove();

    // Update the color for all nodes because the search text could have caused it to change
    this.d3State.nodes
      .transition()
      .duration(200)
      // .delay((d, i) => {
      //   const relX = d.x / this.d3State.width;
      //   return relX * 200;
      // })
      .style('fill', d => {
        return d.color;
      })
      .style('fill-opacity', d => {
        return d.opacity;
      });

    //Setting this causes the nodes to jump when they are overlapping.
    //this.d3State.force.nodes(this.d3State.data);
    this.d3State.force.start();
  };

  getRect = (point1, point2) => {
    var x = Math.min(point1[0], point2[0]);
    var y = Math.min(point1[1], point2[1]);
    var width = Math.abs(point1[0] - point2[0]);
    var height = Math.abs(point1[1] - point2[1]);
    return {
      x: x,
      y: y,
      width: width,
      height: height
    };
  };

  dragStart = context => {
    this.resetSelection();
    this.d3State.selectionOrigPoint = d3.mouse(context);

    this.d3State.addingToSelection = d3.event.sourceEvent.ctrlKey;
  };

  dragMove = context => {
    const newPoint = d3.mouse(context);

    if (newPoint[0] !== this.d3State.selectionOrigPoint[0] || newPoint[1] !== this.d3State.selectionOrigPoint[1]) {
      this.d3State.selectionRect = this.getRect(this.d3State.selectionOrigPoint, newPoint);
      this.d3State.selectionElem.attr(this.d3State.selectionRect);

      this.applySelection();
    }
  };

  dragEnd = () => {
    this.resetSelection();

    if (this.d3State.addingToSelection) {
      this.d3State.addingToSelection = false;
      this.props.setSelectedDocumentIdSet(this.props.selectedDocumentIdSet);
      this.props.setAdditionalSelectedDocumentIdSet(new Set());
    }
  };

  resetSelection = () => {
    this.d3State.selectionOrigPoint = [0, 0];

    this.d3State.selectionRect = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };

    this.d3State.selectionElem.attr(this.d3State.selectionRect);
  };

  applySelection = () => {
    const self = this;

    const selectedDocumentIds = new Set();

    d3.selectAll('.visuals-documents__chart-dot').each(function(docData, i) {
      if (
        // inner circle inside selection frame
        self.margin + docData.x - docData.radius >= self.d3State.selectionRect.x &&
        self.margin + docData.x + docData.radius <= self.d3State.selectionRect.x + self.d3State.selectionRect.width &&
        self.margin + docData.y - docData.radius >= self.d3State.selectionRect.y &&
        self.margin + docData.y + docData.radius <= self.d3State.selectionRect.y + self.d3State.selectionRect.height
      ) {
        selectedDocumentIds.add(docData.id);
      }
    });

    if (this.d3State.addingToSelection) {
      this.props.setAdditionalSelectedDocumentIdSet(selectedDocumentIds);
    } else {
      this.props.setSelectedDocumentIdSet(selectedDocumentIds);
    }
  };

  render() {
    return (
      <div className="visuals-documents visuals-documents__root">
        <div className="visuals-documents__chart-tooltip" />
        <h2 className="visuals-documents__title">
          <FormattedMessage id="visuals.documents.title" />
        </h2>
        <div className="visuals-documents__sub-text">
          <FormattedMessage id="visuals.documents.sub-text" />
        </div>
        <div className="visuals-documents__chart-container">
          <svg width="100%" height="100%" preserveAspectRatio="none" className="visuals-documents__chart" />
        </div>
      </div>
    );
  }
}
