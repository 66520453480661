import { chain } from 'underscore';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import { ITEM_TYPES, ITEM_STATUSES } from 'models/project-item';
import { Radio, RadioGroup } from 'components/shared/form/radio';
import { updateComparison, getFolder, getCurrentRegion } from 'store/api';
import CompareSidebarCreate from 'components/file-browser/compare-sidebar/compare-sidebar-create';
import comparisonsEmptyStateImage from 'images/empty_state_comp_02.svg';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import { COMPARISON_STATUSES } from 'models/comparison-sets';
import { addNotification } from 'store/notifications.js';
import store from 'store/store';

const COMPARE_TABS = {
  COMPARISONS: 'COMPARISONS',
  CREATE: 'CREATE'
};

class Comparisons extends Component {
  static propTypes = {
    comparedDocumentsList: PropTypes.array.isRequired,
    comparisons: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired
  };

  state = {
    selectedComparison: null,
    isProcessing: false
  };

  onComparisonChange = comparison => {
    this.setState({
      selectedComparison: comparison
    });
  };

  onSubmit = event => {
    const { selectedComparison } = this.state;
    const { comparedDocumentsList, onSubmit, projectId } = this.props;

    event.preventDefault();

    this.setState({ isProcessing: true });
    updateComparison({ projectId, comparison: selectedComparison, comparedDocuments: comparedDocumentsList })
      .then(response => {
        if (response.data.processingEntitiesCount) {
          store.dispatch(
            addNotification({
              message: <FormattedMessage id="compare.comparison-created-contains-processing" />,
              clearOnPageChange: true,
              autohide: 10
            })
          );
        }
        onSubmit(selectedComparison.comparisonId);
      })
      .then(() => {
        this.setState({ isProcessing: false });
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  render() {
    const { selectedComparison, isProcessing } = this.state;
    const { comparedDocumentsList, comparisons, projectId } = this.props;

    if (!comparisons.length) {
      return (
        <div className="compare-sidebar-empty">
          <img className="compare-sidebar-empty__img" alt="no selected documents" src={comparisonsEmptyStateImage} />
          <h2 className="compare-sidebar-empty__title">
            <FormattedMessage id="compare-sidebar.empty.title" />
          </h2>
          <p className="compare-sidebar-empty__description">
            <FormattedMessage id="compare-sidebar.empty.description-one" />
            <br />
            <FormattedMessage id="compare-sidebar.empty.description-two" />
          </p>
        </div>
      );
    }
    if (!comparedDocumentsList.length) {
      return (
        <div className="compare-sidebar-comparisons">
          {comparisons.map((comparison, index) => (
            <div className="compare-sidebar-comparisons__item" key={index}>
              <Link
                className="compare-sidebar-comparisons__item-name"
                title={comparison.comparisonName}
                to={`/region/${getCurrentRegion()}/project/${projectId}/compare/${comparison.comparisonId}`}
              >
                {comparison.comparisonName}
              </Link>
            </div>
          ))}
        </div>
      );
    }

    return (
      <form className="comparison-sidebar-form" id="comparison-sidebar-form" onSubmit={this.onSubmit}>
        <div className="comparison-sidebar-form__instructions">
          <p>
            <FormattedMessage id="compare-sidebar.comparisons-form.instructions" />
          </p>
        </div>
        <div className="comparison-sidebar-form__radio-group">
          <RadioGroup name="comparison-select" selectedValue={selectedComparison} onChange={this.onComparisonChange}>
            {comparisons
              .filter(comparison => comparison.comparisonStateId !== COMPARISON_STATUSES.ERROR)
              .map((comparison, index) => (
                <div key={index} className="form-field--inline-label comparison-sidebar-form__form-field">
                  <Radio
                    className="comparison-sidebar-form__radio"
                    id={`comparison-select-option-${comparison.comparisonId}`}
                    value={comparison}
                  />
                  <label
                    className="comparison-sidebar-form__radio-label"
                    htmlFor={`comparison-select-option-${comparison.comparisonId}`}
                  >
                    {comparison.comparisonName}
                  </label>
                </div>
              ))}
          </RadioGroup>
        </div>
        <div className="comparison-sidebar-form__footer">
          <Button
            type="submit"
            disabled={!selectedComparison || isProcessing}
            className="btn btn-primary btn-no-margin"
          >
            <FormattedMessage id="compare-sidebar.form.submit-button" />
          </Button>
        </div>
      </form>
    );
  }
}

class CompareSidebar extends Component {
  static propTypes = {
    comparisonSets: PropTypes.object.isRequired,
    loadComparisonSets: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onSubmitAddToComparison: PropTypes.func.isRequired,
    onSubmitCreateComparison: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.array.isRequired
  };

  state = {
    currentTab: COMPARE_TABS.COMPARISONS,
    comparedDocuments: {}
  };

  componentDidMount() {
    const { loadComparisonSets, projectId } = this.props;
    loadComparisonSets({ projectId });
    this.updateComparedDocuments();
  }

  componentDidUpdate(prevProps) {
    const { comparisonSets, loadComparisonSets, projectId, selectedItems } = this.props;

    if (!comparisonSets.isLoading && !comparisonSets.error && !comparisonSets.comparisons) {
      loadComparisonSets({ projectId });
    }

    if (prevProps.selectedItems !== selectedItems) {
      this.updateComparedDocuments();
    }
  }

  updateComparedDocuments = () => {
    const { projectId, selectedItems } = this.props;
    const { comparedDocuments } = this.state;

    const nextComparedDocuments = {};

    selectedItems.forEach(selectedItem => {
      const { projectItemId } = selectedItem;

      // If the entry already exists, use the existing version
      // Prevents documents from previously selected folders from disappearing when selecting additional folders
      if (comparedDocuments[projectItemId]) {
        nextComparedDocuments[projectItemId] = comparedDocuments[projectItemId];
      }

      if (selectedItem.itemTypeId === ITEM_TYPES.FOLDER) {
        // If the item is a folder, fetch all the documents in the folder
        getFolder({
          projectId,
          folderId: projectItemId,
          recursive: true
        }).then(response => {
          // Merge in the documents from the folder
          this.setState({
            comparedDocuments: {
              ...this.state.comparedDocuments,
              [projectItemId]: response.data.filter(item => item.itemStateId === ITEM_STATUSES.PROCESSED)
            }
          });
        });
      } else {
        nextComparedDocuments[projectItemId] = selectedItem;
      }
    });

    this.setState({
      comparedDocuments: nextComparedDocuments
    });
  };

  onComparisonsTabClick = () => {
    this.setState({
      currentTab: COMPARE_TABS.COMPARISONS
    });
  };

  onCreateTabClick = () => {
    this.setState({
      currentTab: COMPARE_TABS.CREATE
    });
  };

  onSubmitAddToComparison = data => {
    const { onSubmitAddToComparison } = this.props;
    onSubmitAddToComparison(data);
  };

  onSubmitCreateComparison = data => {
    const { onSubmitCreateComparison } = this.props;
    onSubmitCreateComparison(data);
  };

  render() {
    const { onCloseClick, comparisonSets, projectId } = this.props;
    const { comparedDocuments, currentTab } = this.state;

    const comparedDocumentsList = chain(Object.values(comparedDocuments))
      .flatten()
      .sort((a, b) => a.itemName.localeCompare(b.itemName))
      .value();

    if (comparisonSets.isLoading) {
      return (
        <div className="compare-sidebar">
          <div className="compare-sidebar-empty">
            <Icon className="spinner" name="loader" width={80} />
            <p className="compare-sidebar__loader-text">
              <FormattedMessage id="compare-sidebar.loader.text" />
            </p>
          </div>
        </div>
      );
    }

    if (!Array.isArray(comparisonSets.comparisons) || comparisonSets.error) {
      return null;
    }

    return (
      <div className="compare-sidebar">
        <div className="compare-sidebar__header">
          <h2 className="compare-sidebar__title">
            <FormattedMessage id="compare-sidebar.title" />
          </h2>
          <Button size="icon" onClick={onCloseClick} className="compare-sidebar__close-button icon-button">
            <Icon name="special-cross" width={14} />
          </Button>
        </div>
        <div className="compare-sidebar__tabs">
          <Button
            className={classNames(
              'compare-sidebar__tab-button',
              currentTab === COMPARE_TABS.COMPARISONS && 'compare-sidebar__tab-button--selected'
            )}
            onClick={this.onComparisonsTabClick}
          >
            <FormattedMessage id="compare-sidebar.tab.comparisons" />
          </Button>
          <Button
            className={classNames(
              'compare-sidebar__tab-button',
              currentTab === COMPARE_TABS.CREATE && 'compare-sidebar__tab-button--selected'
            )}
            onClick={this.onCreateTabClick}
          >
            <FormattedMessage id="compare-sidebar.tab.create-new" />
          </Button>
        </div>
        <div className="compare-sidebar__main">
          {currentTab === COMPARE_TABS.COMPARISONS && (
            <Comparisons
              comparedDocumentsList={comparedDocumentsList}
              comparisons={comparisonSets.comparisons}
              onSubmit={this.onSubmitAddToComparison}
              projectId={projectId}
            />
          )}
          {currentTab === COMPARE_TABS.CREATE && (
            <CompareSidebarCreate
              projectId={projectId}
              onSubmit={this.onSubmitCreateComparison}
              comparedDocumentsList={comparedDocumentsList}
            />
          )}
        </div>
      </div>
    );
  }
}

export default CompareSidebar;
