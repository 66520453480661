import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BLOCK } from './constants';
import CONSTANTS from 'utils/constants';
import Icon from 'components/shared/icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class ArgusTranslation extends React.Component {
  static propTypes = {
    isAllowed: PropTypes.bool.isRequired
  };
  render() {
    const { isAllowed } = this.props;
    return (
      <>
        <div className={`${BLOCK}__heading-wrapper`}>
          <Icon className="icon" name="argus-translation" width={32} />
          <p className={`${BLOCK}__heading`}>
            <FormattedMessage id={`${BLOCK}.argus-translation`} />
          </p>
        </div>
        <p className={`${BLOCK}__description`}>
          <FormattedMessage id={`${BLOCK}.argus-translation-description`} />
        </p>
        {isAllowed && (
          <div className={`${BLOCK}__button-wrapper`}>
            <Link className="btn btn-primary padding-sm btn-xlarge" to={'/translation'}>
              <FormattedMessage id={`${BLOCK}.argus-translation-projects`} />
            </Link>
            <Link
              className="btn btn-secondary btn-xlarge"
              to={{ pathname: '/translation', hash: `#${CONSTANTS.TRANSLATION_TABS[1]}` }}
            >
              <FormattedMessage id={`${BLOCK}.argus-translation-create`} />
            </Link>
          </div>
        )}
      </>
    );
  }
}
export default ArgusTranslation;
