import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';

import enhanceWithClickOutside from 'react-click-outside';
import { FormattedMessage } from 'react-intl';
import { TAG_TYPES } from 'models/project-item';
import { sortByKey } from 'utils/string-utils';

import { getProjectTags, createProjectTag } from 'store/api';

class ExtractionRecordTags extends Component {
  static propTypes = {
    addExtractionFieldRecordTag: PropTypes.func,
    clearUndoStack: PropTypes.func,
    closeFunction: PropTypes.func,
    documentId: PropTypes.number,
    extractionFieldRecordId: PropTypes.number,
    projectId: PropTypes.number
  };

  state = {
    disabled: true,
    projectTags: [],
    tagText: ''
  };

  componentDidMount = () => {
    this.refreshProjectTags();
  };

  handleClickOutside() {
    this.props.closeFunction();
  }

  refreshProjectTags = () => {
    var { projectId } = this.props;

    getProjectTags({ projectId }).then(response => {
      // Filter out system tags and only get user defined ones
      var filtered = response.data.tags.filter(({ tagTypeId }) => tagTypeId === TAG_TYPES.USER);

      this.setState({ projectTags: sortByKey('tagName', filtered) });
    });
  };

  tagTextChange = value => {
    this.setState({ tagText: value });

    if (value && value.length > 0) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  };

  addTag = tagName => {
    var {
      projectId,
      documentId,
      extractionFieldRecordId,
      closeFunction,
      addExtractionFieldRecordTag,
      clearUndoStack
    } = this.props;
    var { tagText, projectTags } = this.state;
    var text = tagName ? tagName : tagText;

    this.setState({ tagText: '' });

    if (text && text.length > 0) {
      // Check for duplicates
      var projectTag = projectTags
        .filter(({ tagTypeId }) => tagTypeId === TAG_TYPES.USER)
        .find(tag => tag.tagName === text.toLowerCase());

      closeFunction(true);
      if (projectTag) {
        addExtractionFieldRecordTag(projectId, documentId, extractionFieldRecordId, projectTag);
        clearUndoStack();
      } else {
        createProjectTag({ projectId, tagName: text })
          .then(resp => {
            addExtractionFieldRecordTag(projectId, documentId, extractionFieldRecordId, resp.data);
          })
          .then(clearUndoStack);
      }
    }
  };

  // When a user wishes to filter the tag
  getFilteredTags = () => {
    var { tagText, projectTags } = this.state;
    var filtered = [...projectTags];
    var query = tagText.toLowerCase().trim();

    return filtered.filter(tag => {
      return tag.tagName
        .toLowerCase()
        .trim()
        .includes(query);
    });
  };

  render() {
    var { closeFunction } = this.props;
    var { tagText, disabled } = this.state;
    var filteredTags = this.getFilteredTags();

    return (
      <div className="dropdown">
        <div className="extraction-record-tags__header">
          <h2 className="extraction-record-tags__title">
            <FormattedMessage id="file-browser.tags-popover.tags-label" />
          </h2>
          <Button onClick={closeFunction} className="btn btn-no-margin icon-button">
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>

        <form
          className="form"
          onSubmit={e => {
            e.preventDefault();
            this.addTag();
          }}
        >
          <div className="input-row">
            <FormattedMessage id="extraction-record-tags.dropdown.placeholder">
              {placeholder => (
                <input
                  type="text"
                  placeholder={placeholder}
                  onChange={e => this.tagTextChange(e.target.value)}
                  value={tagText}
                  maxLength={400}
                />
              )}
            </FormattedMessage>
          </div>
          <Button type="submit" className="btn btn-primary" disabled={disabled}>
            <FormattedMessage id="extraction-record-tags.dropdown.add-button" />
          </Button>
        </form>

        <div className="typeahead existing-tags">
          {filteredTags.map(tag => (
            <Button
              key={tag.tagId}
              className="existing-tag"
              onClick={() => {
                this.addTag(tag.tagName);
              }}
            >
              {tag.tagName}
            </Button>
          ))}
        </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(ExtractionRecordTags);
