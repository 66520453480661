import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import React, { Component } from 'react';

import { userLogOut } from 'store/api';
import Confirm from 'components/shared/confirm';
import constants from 'utils/constants';

const {
  SessionTimeout: { IDLE_WAIT_TIME_MS, IDLE_CHECK_INTERVAL_MS, WARNING_TIME_MS, WARNING_COUNTDOWN_INTERVAL_MS }
} = constants;

/* 
  SessionTimeoutModal
  Handles tracking idle time and timing out the session if it has been inactive for too long
*/
class SessionTimeoutModal extends Component {
  state = {
    isWarningShown: false,
    warningCountdownDisplay: ''
  };

  idleCheckInterval = null;
  warningCountdownStartTime = Date.now();
  warningCountdownInterval = null;

  componentDidMount() {
    // Start checking for idle time
    this.restartIdleTimeCheck();
    this.bindIdleResetListeners();
  }

  componentWillUnmount() {
    // Clear all intervals and listeners on unmount
    this.stopIdleTimeCheck();
    this.stopWarningCountdown();
    this.unbindIdleResetListeners();
  }

  bindIdleResetListeners = () => {
    // Reset the last active time on the following events which represent user actions
    window.addEventListener('touchstart', this.restartIdleTimeCheck);
    window.addEventListener('click', this.restartIdleTimeCheck);
    window.addEventListener('keypress', this.restartIdleTimeCheck);
    window.addEventListener('scroll', this.restartIdleTimeCheck, true);
    // Custom event to prevent session timeouts on long uploads
    window.addEventListener('argus.upload.progress', this.restartIdleTimeCheck, false);
  };

  unbindIdleResetListeners = () => {
    // Remove all the idle reset event listeners
    window.removeEventListener('touchstart', this.restartIdleTimeCheck);
    window.removeEventListener('click', this.restartIdleTimeCheck);
    window.removeEventListener('keypress', this.restartIdleTimeCheck);
    window.removeEventListener('scroll', this.restartIdleTimeCheck, true);
    window.removeEventListener('argus.upload.progress', this.restartIdleTimeCheck, false);
  };

  // Start the idle time check
  startIdleTimeCheck = () => {
    this.warningCountdownStartTime = Date.now() + IDLE_WAIT_TIME_MS;
    this.idleCheckInterval = setInterval(this.checkIdleTime, IDLE_CHECK_INTERVAL_MS);
  };

  // Clear the idle wait check and start it again
  restartIdleTimeCheck = () => {
    const { isWarningShown } = this.state;

    if (isWarningShown) return;

    this.stopIdleTimeCheck();
    this.startIdleTimeCheck();
  };

  // Stop the idle time check
  stopIdleTimeCheck = () => {
    clearInterval(this.idleCheckInterval);
  };

  // Check if the session has been idle for too long and show the session timeout warning
  checkIdleTime = () => {
    if (Date.now() >= this.warningCountdownStartTime) {
      this.showWarning();
    }
  };

  // Start the warning countdown
  startWarningCountdown = () => {
    this.updateCountdown();
    this.warningCountdownInterval = setInterval(this.updateCountdown, WARNING_COUNTDOWN_INTERVAL_MS);
  };

  // Stop the warning countdown
  stopWarningCountdown = () => {
    clearInterval(this.warningCountdownInterval);
  };

  // Update the warning countdown and logout if enough time has elapsed
  updateCountdown = () => {
    // Time left before session timeout
    const warningTimeLeft = this.warningCountdownStartTime + WARNING_TIME_MS - Date.now();

    if (warningTimeLeft <= 0) {
      // Session timeout
      this.setState({ warningCountdownDisplay: '00:00' });
      this.stopIdleTimeCheck();
      this.stopWarningCountdown();
      this.logout();
    } else {
      // Update the countdown display
      const warningCountdownDisplay = moment()
        .startOf('day')
        .add(warningTimeLeft, 'ms')
        .format('mm:ss');
      this.setState({ warningCountdownDisplay });
    }
  };

  // Show the warning modal and start the logout countdown
  showWarning = () => {
    this.stopIdleTimeCheck();
    this.startWarningCountdown();
    this.setState({ isWarningShown: true });
  };

  // Hide the warning modal and reset all timers
  hideWarning = () => {
    this.setState({ isWarningShown: false });
    this.stopWarningCountdown();
    this.restartIdleTimeCheck();
  };

  logout = () => {
    userLogOut();
  };

  logoutClick = () => {
    this.logout();
  };

  continueSessionClick = () => {
    this.hideWarning();
  };

  render() {
    const { isWarningShown, warningCountdownDisplay } = this.state;

    if (!isWarningShown) {
      return null;
    }

    return (
      <Confirm
        onCancel={this.logoutClick}
        onSubmit={this.continueSessionClick}
        title={<FormattedMessage id="global.session-timeout-modal.title" />}
        message={
          <FormattedMessage id="global.session-timeout-modal.message" values={{ countdown: warningCountdownDisplay }} />
        }
        cancelText={<FormattedMessage id="global.session-timeout-modal.logout" />}
        confirmText={<FormattedMessage id="global.session-timeout-modal.continue" />}
      />
    );
  }
}

export default SessionTimeoutModal;
