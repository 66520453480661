import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';

import Button from 'components/shared/single-click-button';
import { getCurrentRegion, getExtractionFieldProjects, trainExtractionField, setAutomaticExtraction } from 'store/api';
import Checkbox from 'components/shared/form/checkbox';
import Switch from 'components/shared/form/switch';
import Icon from 'components/shared/icon';

/**
 * A single row of the learning table
 */
class LearningTableRow extends Component {
  static propTypes = {
    isSelected: PropTypes.bool,
    project: PropTypes.object,
    selectedProjectChange: PropTypes.func.isRequired
  };

  /**
   * Handle a checkbox click
   */
  selectedProjectChange = () => {
    const { project, selectedProjectChange } = this.props;
    selectedProjectChange(project);
  };

  render() {
    const { project, isSelected } = this.props;

    return (
      <tr className={isSelected ? 'data-table__row-selected' : ''}>
        <td className="data-table__td data-table__td-checkbox">
          <Checkbox
            checked={isSelected ? 'checked' : 'unchecked'}
            id={`extraction-details-learning-checkbox-${project.projectId}`}
            className="data-table__checkbox"
            onChange={this.selectedProjectChange}
          />
        </td>
        <td className="data-table__td">
          <Link
            className="data-table__link"
            title={project.projectName}
            to={
              project.documentAccess === true
                ? `/region/${getCurrentRegion()}/project/${project.projectId}/folder/root`
                : `/region/${getCurrentRegion()}/project/${project.projectId}/settings`
            }
          >
            {project.projectName}
          </Link>
        </td>
        <td className="data-table__td data-table__td--numeric">
          <FormattedNumber value={project.totalDocuments} />
        </td>
        <td className="data-table__td data-table__td--numeric">
          <FormattedNumber value={project.totalNewDocuments} />
        </td>
        <td className="data-table__td data-table__td--numeric">
          <FormattedNumber value={project.totalExtractions} />
        </td>
        <td className="data-table__td data-table__td--numeric">
          <FormattedNumber value={project.totalNewExtractions} />
        </td>
      </tr>
    );
  }
}

/**
 * The learning tab of the extraction field details page
 */
class Learning extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    onTrainExtractionField: PropTypes.func.isRequired,
    training: PropTypes.array
  };

  state = {
    isAutomaticExtraction: this.props.field.isAutomaticExtraction,
    isPermanentModelSelected: false,
    isTraining: false,
    projects: null,
    selectedProjects: new Set()
  };

  componentDidMount() {
    this.componentDidUpdate({});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.field !== this.props.field) {
      this.getProjects();
      // Set isAutomaticExtraction separately since it can be user edited
      this.setState({ isAutomaticExtraction: this.props.field.isAutomaticExtraction });
    }
  }

  /**
   * Get the extraction field projects data
   */
  getProjects = () => {
    const { field } = this.props;
    getExtractionFieldProjects(field.extractionFieldId).then(response =>
      this.setState({
        projects: response.data ?? [],
        selectedProjects: new Set(response.data?.map(project => project.projectId))
      })
    );
  };

  /**
   * Toggle the selection of the permanent model row
   * This row is handled separate to the other rows because it not driven by project data
   */
  togglePermanentModelSelected = () => {
    this.setState({ isPermanentModelSelected: !this.state.isPermanentModelSelected });
  };

  /**
   * Toggle the selection of a row of the learning table
   */
  selectedProjectChange = project => {
    const id = project.projectId;

    if (this.state.selectedProjects.has(id)) {
      this.state.selectedProjects.delete(id);
    } else {
      this.state.selectedProjects.add(id);
    }

    this.setState({ selectedProjects: this.state.selectedProjects });
  };

  /**
   * Select or deselect all rows of the learning table. Note: also toggles the permenent model row
   */
  onCheckAllChange = () => {
    const { projects } = this.state;

    if (this.state.selectedProjects.size === projects.length) {
      this.setState({ selectedProjects: new Set(), isPermanentModelSelected: false });
    } else {
      const allIds = projects.map(project => project.projectId);
      this.setState({ selectedProjects: new Set(allIds), isPermanentModelSelected: true });
    }
  };

  /**
   * Toggle the isAutomatic switch and send an API request to toggle the isAutomatic state
   */
  setIsAutomatic = (id, isAutomaticExtraction) => {
    const { field } = this.props;

    this.setState({
      isAutomaticExtraction
    });
    setAutomaticExtraction({
      extractionFieldId: field.extractionFieldId,
      isAutomaticExtraction: isAutomaticExtraction
    }).catch(() => {
      this.setState({
        isAutomaticExtraction: field.isAutomaticExtraction
      });
    });
  };

  /**
   * Initiate a training request for this field with the selected projects and permanent model flag
   */
  trainExtractionField = () => {
    const { field, onTrainExtractionField } = this.props;
    const { selectedProjects, isPermanentModelSelected } = this.state;

    this.setState({ isTraining: true });
    trainExtractionField({
      includePermanent: isPermanentModelSelected,
      extractionFieldId: field.extractionFieldId,
      projectIds: Array.from(selectedProjects.values())
    })
      .then(() => {
        this.setState({ isTraining: false });
        onTrainExtractionField();
      })
      .catch(() => {
        this.setState({ isTraining: false });
      });
  };

  /**
   * Returns true if the training set has a permanent model
   * Determines whether to display a row for the permanent model in the training table
   */
  get hasPermanentModel() {
    const { training } = this.props;

    if (!Array.isArray(training)) {
      return false;
    }

    return !!training.some(training => training.isPermanent);
  }

  /**
   * Determines whether all rows of the table are selected or not
   * Returns 'checked' if all are selected, 'unchecked' if none are selected and 'indeterminate' otherwise
   * Takes the presence or absense of the permanent model row into account
   */
  get allSelectedStatus() {
    const { selectedProjects, projects, isPermanentModelSelected } = this.state;

    let allSelectedStatus;
    if (projects.length === selectedProjects.size && (this.hasPermanentModel ? isPermanentModelSelected : true)) {
      allSelectedStatus = 'checked';
    } else if (selectedProjects.size === 0 && (this.hasPermanentModel ? !isPermanentModelSelected : true)) {
      allSelectedStatus = 'unchecked';
    } else {
      allSelectedStatus = 'indeterminate';
    }

    return allSelectedStatus;
  }

  render() {
    const { field, training } = this.props;
    const { isAutomaticExtraction, selectedProjects, projects, isTraining, isPermanentModelSelected } = this.state;

    if (!Array.isArray(training) || !Array.isArray(projects)) {
      return (
        <div className="extraction-details__tab-content-section extraction-details-learning">
          <Icon className="spinner" name="loader" width={80} />
        </div>
      );
    }

    return (
      <div className="extraction-details__tab-content-section extraction-details-learning">
        <div className="extraction-details-learning__table-container">
          <FixedTable>
            <table className="extraction-details-learning__table data-table">
              <thead>
                <tr>
                  <FixedHeader className="data-table__th-checkbox">
                    <Checkbox
                      checked={this.allSelectedStatus}
                      className="data-table__checkbox"
                      id="check-all"
                      onChange={this.onCheckAllChange}
                      disabled={projects.length <= 0}
                    />
                  </FixedHeader>
                  <FixedHeader className="data-table__th learning-projects">
                    <FormattedMessage id="quick-study.extraction-field-details.learning-tab.projects-title" />
                  </FixedHeader>
                  <FixedHeader className="data-table__th data-table__th--numeric learning-documents">
                    <FormattedMessage id="quick-study.extraction-field-details.learning-tab.documents-title" />
                  </FixedHeader>
                  <FixedHeader className="data-table__th data-table__th--numeric learning-new-documents">
                    <FormattedMessage id="quick-study.extraction-field-details.learning-tab.new-documents-title" />
                  </FixedHeader>
                  <FixedHeader className="data-table__th data-table__th--numeric learning-extractions">
                    <FormattedMessage id="quick-study.extraction-field-details.learning-tab.extractions-title" />
                  </FixedHeader>
                  <FixedHeader className="data-table__th data-table__th--numeric learning-new-extractions">
                    <FormattedMessage id="quick-study.extraction-field-details.learning-tab.new-extractions-title" />
                  </FixedHeader>
                </tr>
              </thead>
              <tbody>
                {projects.map(project => (
                  <LearningTableRow
                    isSelected={selectedProjects.has(project.projectId)}
                    project={project}
                    key={project.projectId}
                    selectedProjectChange={this.selectedProjectChange}
                  />
                ))}
                {this.hasPermanentModel && (
                  <tr className={isPermanentModelSelected ? 'data-table__row-selected' : ''}>
                    <td className="data-table__td data-table__td-checkbox">
                      <Checkbox
                        checked={isPermanentModelSelected ? 'checked' : 'unchecked'}
                        id={`extraction-details-learning-checkbox-permanent-model`}
                        className="data-table__checkbox"
                        onChange={this.togglePermanentModelSelected}
                      />
                    </td>
                    <td colSpan="5" className="data-table__td">
                      <FormattedMessage id="quick-study.extraction-field-details.learning-tab.permanent-model" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </FixedTable>
        </div>
        <div className="extraction-details-learning__footer">
          <div className="extraction-details-learning__automatic-switch-container">
            <span>
              <FormattedMessage id="quick-study.extraction-field-details.learning-tab.automatic-extraction" />
            </span>
            <Switch
              isOn={isAutomaticExtraction}
              id={'extraction-details-learning-automatic-switch'}
              onChange={this.setIsAutomatic}
            />
          </div>
          <Button
            size="large"
            onClick={this.trainExtractionField}
            disabled={projects.length <= 0 || !selectedProjects.size || isTraining || field.isReadOnly}
            className="btn btn-primary btn-no-margin extraction-details-learning__learn-button"
          >
            <FormattedMessage id="quick-study.extraction-field-details.learning-tab.learn" />
          </Button>
        </div>
      </div>
    );
  }
}

export default Learning;
