import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

import Header from 'containers/header/header';
import NotificationsContainer from 'containers/shared/notifications-container';
import { withRouter } from 'react-router';
import * as api from 'store/api';
import { getHomeRegion } from 'store/api';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import Pagination from 'components/shared/pagination/pagination';
import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';
import dateUtil from 'utils/dateUtil';
import EditDetails from './edit-user-access-details';
import UserExtendedfeaturesSettingModel from './user-Extended-features-settings-model'
import constants from 'utils/constants';
import Permissions from 'permissions/permissions';
import history from 'utils/history';
import Button from 'components/shared/single-click-button';

const { Features } = constants;

class UserManagementPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    search: PropTypes.object,
    currentUser: PropTypes.object,
    onSearchChange: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.loadUsers = this.loadUsers.bind(this);
  }

  state = {
    loading: true,
    users: [],
    pageInfo: {},
    selectedUserIds: new Set(),
    pageNum: 1,
    searchParams: null,
    isDetailOpen: false,
    selectedUser: null,
    isExtendedfeaturesAccessModelOpen: false
  };

  closePopup = () => {
    this.setState({ isDetailOpen: false });
  };

  userIsGeneralAdmin = user => {
    const containerCode = sessionStorage.getItem(`selected-container`);
    const memberFirmCode = sessionStorage.getItem(`selected-memberfirm`);
    const adminAccessId= user.roles.find(role => role.roleId === constants.UserRoles.ADMIN_GENERAL && role.containerCode == containerCode && role.memberFirmCode == memberFirmCode);
    if(adminAccessId)
      return true;
    else
      return false;
    
  };

  userIsSystemAdmin = user => {
    return user.adminRoleId === 2;
  };

  UNSAFE_componentWillMount() {
    if (
      !Permissions.Global.User.canViewUserManagementLink() ||
      !Permissions.Global.User.canViewUserManagementPageRegion()
    ) {
      history.push('/');
    }
    this.loadUsers(1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.pageNum !== prevState.pageNum) {
      return this.loadUsers(this.state.pageNum);
    }
  }

  loadUsers(options) {
    const params = typeof options === 'number' ? { pageNum: options } : options;
    params.memberFirmOnly = true;
    api
      .getAllUsers(params)
      .then(res => {
        this.setState({ loading: false, users: res.data, pageInfo: res.pageInfo, searchParams: options });
      })
      .catch(() => {
        this.setState({ loading: false, users: [], pageInfo: {} });
      })
  }

  onUserSelect = user => {
    const { selectedUserIds } = this.state;
    if (selectedUserIds.has(user.userId)) {
      selectedUserIds.delete(user.userId);
    } else {
      selectedUserIds.add(user.userId);
    }

    this.setState({ selectedUserIds });
  };

  onAllUsersSelect = () => {
    const { selectedUserIds, users } = this.state;
    if (selectedUserIds.size) {
      this.setState({ selectedUserIds: new Set() });
    } else {
      users.forEach(user => {
        selectedUserIds.add(user.userId);
      });
      this.setState({ selectedUserIds });
    }
  };

  changePage = pageNumber => {
    this.area.scrollTop = 0;

    if (this.props.search.query.length > 0) {
      return this.onSearch(pageNumber);
    }
    return this.setState({ pageNum: pageNumber });
  };

  performUserAction = (user) => {
      this.setState({ isDetailOpen: true, selectedUser: user });    
  };

  reloadUpdatedUser = (userId, updatedUser) => {
    const newUsers = this.state.users.map(user => {
      if (user.userId === userId) {
        return api.adminUserFromApiFormat(updatedUser);
      } else {
        return user;
      }
    });

    this.setState({ users: newUsers });
  }

  // Header search utility functions
  onSearch = pageNum => {
    this.loadUsers({ pageNum, keyword: this.props.search.query });
  };

  openCloseExtendedfeaturesAccessModel = (value) =>{
    this.setState({ isExtendedfeaturesAccessModelOpen: value});
  };

  render() {
    const { currentUser } = this.props;
    const { loading, users, pageInfo, isDetailOpen, selectedUser, isExtendedfeaturesAccessModelOpen } = this.state;


    return (
      <div className="page user-management">
        <Header
          {...this.props}
          currentPage="global.subheader.admin-dashboard"
          fromUserManagementPage
          onEnter={() => this.onSearch(1)}
          onExit={() => {
            this.loadUsers({ pageNum: 1 });
            this.setState({ pageNum: 1 });
          }}
          pageChange={this.state.pageNum}
          loadUsers={this.loadUsers}
        />
        <div className="user-management__wrapper">
          <div className="user-management__header">
            <h1 className="user-management__title">
              <FormattedMessage id="user-management.title" />
            </h1>



            <div style={{ flex: '1 1 auto' }} />
            <div className="user-management__workspace-settings">
              <button onClick={() => this.openCloseExtendedfeaturesAccessModel(true)} className="btn btn-secondary btn-large">
                <FormattedMessage id="user-Extended-features.title" />
              </button>
            </div>
            <div className="user-management__activation-settings">
              <Link to={`/region/${getHomeRegion()}/activation-settings`} className="btn btn-secondary btn-large">
                <FormattedMessage id="user-activation.title" />
              </Link>
            </div>  
          </div>


          {!loading && (
            <div className="user-management__content" ref={area => (this.area = area)}>
              <table className="user-management__users-table">
                <thead>
                  <tr>
                    <th />
                    <th>
                      <FormattedMessage id="user-management.name" />
                    </th>
                    <th>
                      <FormattedMessage id="user-management.email" />
                    </th>
                    <th>
                      <FormattedMessage id="user-management.extraction" />
                    </th>
                    <th>
                      <FormattedMessage id="user-management.translation" />
                    </th>
                    <th>
                      <FormattedMessage id="user-management.country-manager-role" />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => (
                    <tr key={user.userId}>
                      <td />
                      <td>{user.preferredFullName}</td>
                      <td>{user.email}</td>
                      <td>{user && user.accessibleFeatures.filter(feature => feature.featureId === Features.Extraction).length > 0 ? (
                          <FormattedMessage id="user-management.activated.yes" />
                        ) : (
                          <FormattedMessage id="user-management.activated.no" />
                        )}
                      </td>
                      <td>
                        {user && user.accessibleFeatures.filter(feature => feature.featureId === Features.Translation).length > 0 ? (
                        <FormattedMessage id="user-management.activated.yes" />
                      ) : (
                        <FormattedMessage id="user-management.activated.no" />
                      )}
                      </td>
                      <td>
                        {user && user.accessibleFeatures.filter(feature => feature.featureId === Features.Extraction).length > 0  && this.userIsGeneralAdmin(user) ? (
                          <FormattedMessage id="user-management.activated.yes" />
                        ) : (
                          <FormattedMessage id="user-management.activated.no" />
                        )}
                      </td>
                      <td>
                        <Button
                          size="icon"
                          className={"user-management__menu-item"}
                          onClick={() => this.performUserAction(user)}
                        >
                          <Icon name="edit-profile-0" width={24}  />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {users.length === 0 ? (
                <div className="user-management__empty-state">
                  <FormattedMessage id="user-management.search-instruction" />
                </div>
              ) : null}
            </div>
          )}
          {isExtendedfeaturesAccessModelOpen && (
            <UserExtendedfeaturesSettingModel
              openCloseExtendedfeaturesAccessModel={this.openCloseExtendedfeaturesAccessModel}
              currentUser={currentUser}
            />
          )}
          {loading ? (
            <div className="absolute-center">
              <Icon className="spinner" name="loader" width={80} />
            </div>
          ) : null}
          <div className="user-management__pagination-container">
            {pageInfo && pageInfo.pageNumber && <Pagination onPageChange={this.changePage} pagination={pageInfo} />}
          </div>
          {isDetailOpen && (
            <EditDetails
              user={selectedUser}
              closePopup={this.closePopup}
              reloadUpdatedUser={this.reloadUpdatedUser} />
          )}
          <NotificationsContainer />
          <ReactTooltip effect="solid" />
        </div>
      </div>
    );
  }
}

export default withRouter(UserManagementPage);
