import model from 'models/base-model';

export class DocumentLayout extends model({
  // Control Properties
  isLoading: false,
  isLoaded: false,
  error: null,
  loadedPages: [],
  layout: null,
  characters: null
}) {
  setLoading() {
    return this.merge({
      error: null,
      isLoaded: false,
      isLoading: true
    });
  }

  setError(error) {
    return this.merge({
      error: error,
      isLoaded: false,
      isLoading: false
    });
  }

  invalidate() {
    return this.merge({
      error: null,
      isLoaded: false,
      isLoading: false
    });
  }

  //Called when the document layout metadata call is returned
  setLoaded(data) {
    return DocumentLayout.fromData(data);
  }

  //Expects metadata object
  static fromData(data) {
    var layout = data.slice(0);
    var loadedPages = this.loadedPages ? this.loadedPages.slice(0) : [];
    // var enhancedLayout = enhanceLayout(data);

    layout.forEach(layoutPage => {
      if (layoutPage.characters) {
        layoutPage.gridmap = generateGridmap(layoutPage);
        layoutPage.characterDataLoaded = true;
        if (loadedPages.indexOf(layoutPage.pageNumber) === -1) loadedPages.push(layoutPage.pageNumber);
      } else {
        layoutPage.gridmap = {};
        layoutPage.characterDataLoaded = false;
      }
    });

    var characters = getAllCharacters(layout);

    var documentLayout = new DocumentLayout({
      error: null,
      isLoaded: true,
      isLoading: false,
      layout: layout,
      loadedPages: loadedPages,
      characters: characters
    });

    return documentLayout;
  }

  //Lazy loads a specific page of character data into the model
  setPagesLoaded(pages) {
    var layout = this.layout.slice(0);
    var loadedPages = this.loadedPages ? this.loadedPages.slice(0) : [];

    pages.forEach(page => {
      var matchingIndex = layout.findIndex(p => p.pageNumber === page.pageNumber);
      var matchingPage = layout[matchingIndex];

      if (!matchingPage.characterDataLoaded) {
        layout[matchingIndex].characters = page.characters;
        layout[matchingIndex].characterDataLoaded = true;
        layout[matchingIndex].gridMap = generateGridmap(matchingPage);
        if (loadedPages.indexOf(page.pageNumber) === -1) loadedPages.push(page.pageNumber);
      }
    });

    var allCharacters = getAllCharacters(layout);

    return this.merge({
      layout: layout,
      loadedPages: loadedPages,
      characters: allCharacters
    });
  }
}

//Generates a more digestable object for highlight interaction for character data
function generateGridmap(page) {
  var gridMap = {};

  if (page.characters) {
    page.characters.forEach(character => {
      var gx1 = Math.floor(character.x1 / 100);
      var gx2 = Math.floor(character.x2 / 100);
      var gy1 = Math.floor(character.y1 / 100);
      var gy2 = Math.floor(character.y2 / 100);

      if (!gridMap[gx1]) gridMap[gx1] = {};
      if (!gridMap[gx1][gy1]) gridMap[gx1][gy1] = {};
      if (!gridMap[gx1][gy2]) gridMap[gx1][gy2] = {};

      if (!gridMap[gx2]) gridMap[gx2] = {};
      if (!gridMap[gx2][gy1]) gridMap[gx2][gy1] = {};
      if (!gridMap[gx2][gy2]) gridMap[gx2][gy2] = {};

      gridMap[gx1][gy1][character.i] = character;
      gridMap[gx1][gy2][character.i] = character;
      gridMap[gx2][gy1][character.i] = character;
      gridMap[gx2][gy2][character.i] = character;
    });
  }

  return gridMap;
}

//Creates a flat collection of all characters in a document (used for search)
function getAllCharacters(pages) {
  var allCharacters = [];

  pages.forEach((page, pageIndex) => {
    if (page.characters) {
      page.characters.forEach(character => {
        character.page = page.pageNumber;
        allCharacters.push(character);
      });
    }
  });

  return allCharacters;
}
