import { connect } from 'react-redux';

import {
  searchAll,
  onSearchChange,
  onAdvancedSearchChange,
  clearSearchData,
  onSortOrder
} from 'store/search/search-category';

import {
  clearResultsData,
  getCurrentSearchFolder,
  getDocumentResults,
  getFolderResults,
  getAdvancedSearchResults
} from 'store/search/search-results';
import SearchResults from 'components/search/file-list-search/search-results';
import { addNotification } from 'store/notifications.js';

const mapStateToProps = ({
  data: { search, currentSearchFolder, currentProject },
  ui: { fileBrowserSelectedItems },
  router
}) => ({
  search,
  currentSearchFolder,
  currentProject,
  router,
  selectedItems: fileBrowserSelectedItems
});

const mapDispatchToProps = {
  addNotification,
  searchAll,
  onSearchChange,
  onAdvancedSearchChange,
  clearSearchData,
  clearResultsData,
  getCurrentSearchFolder,
  getDocumentResults,
  getFolderResults,
  getAdvancedSearchResults,
  onSortOrder
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults);
