import { withRouter } from 'react-router';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdvancedSearch from 'components/search/file-list-search/advanced-search';
import CategoryContainer from 'components/search/file-list-search/category-container';
import ResultsContainer from 'components/search/file-list-search/results-container';

export class SearchDropdown extends Component {
  static propTypes = {
    autocomplete: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    clearResultsData: PropTypes.func,
    enableAdvanced: PropTypes.any,
    getCurrentSearchFolder: PropTypes.func,
    getDocumentResults: PropTypes.func,
    getFolderResults: PropTypes.func,
    goToAdvancedSearch: PropTypes.func,
    history: PropTypes.object,
    inputChange: PropTypes.func,
    inputValue: PropTypes.string,
    isAdvancedSearch: PropTypes.bool,
    match: PropTypes.object,
    onAdvancedSearch: PropTypes.func,
    onAdvancedSearchChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClickOutside: PropTypes.func,
    onReset: PropTypes.func,
    onSearch: PropTypes.func,
    onSearchAll: PropTypes.func,
    projectId: PropTypes.string
  };

  static defaultProps = {
    onClickOutside: () => {}
  };

  state = {
    selectedCategory: null,
    selectedResults: new Set()
  };

  // Making sure we clear out the view more section if a user makes a new search query
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { autocomplete } = this.props;

    if (autocomplete !== nextProps.autocomplete) {
      if (!nextProps.autocomplete.isLoaded) {
        this.setState({ selectedCategory: null });
      }
    }
  }

  handleClickOutside = () => {
    const { onClickOutside } = this.props;
    onClickOutside();
  };

  onViewMore = category => {
    this.setState({ selectedCategory: category, selectedResults: new Set() });
  };

  // When a user selects different results they want to filter on
  onCheckChange = result => {
    const { selectedResults } = this.state;

    if (selectedResults.has(result)) {
      selectedResults.delete(result);
    } else {
      selectedResults.add(result);
    }

    this.setState({ selectedResults });
  };

  // When a user wants to select all the options
  onCheckAllChange = results => {
    const { selectedResults } = this.state;

    if (selectedResults.size === results.length) {
      this.setState({ selectedResults: new Set() });
    } else {
      this.setState({ selectedResults: new Set(results) });
    }
  };

  // When a user clicks on a single document
  getSearchDocument = data => {
    const { projectId, getDocumentResults } = this.props;

    return getDocumentResults({ projectId, pageNum: 1, ...data });
  };

  // When a user clicks on a single folder
  getSearchFolder = data => {
    const { projectId, getCurrentSearchFolder } = this.props;

    return getCurrentSearchFolder({
      projectId,
      pageNum: 1,
      forceFetch: true,
      ...data
    });
  };

  getFoldersSelected = () => {
    const { projectId, getFolderResults } = this.props;
    const { selectedResults } = this.state;

    return getFolderResults({
      projectId,
      pageNum: 1,
      folderId: [...selectedResults]
    });
  };

  render() {
    const { selectedResults, selectedCategory } = this.state;
    const {
      autocomplete,
      goToAdvancedSearch,
      history,
      inputChange,
      inputValue,
      isAdvancedSearch,
      onAdvancedSearch,
      onAdvancedSearchChange,
      onBlur,
      onClickOutside,
      onReset,
      onSearch,
      onSearchAll,
      projectId
    } = this.props;

    if (isAdvancedSearch) {
      return (
        <div className="search-dropdown">
          <AdvancedSearch
            inputValue={inputValue}
            onAdvancedSearch={onAdvancedSearch}
            onAdvancedSearchChange={onAdvancedSearchChange}
            onReset={onReset}
          />
        </div>
      );
    }

    return (
      <div className="search-dropdown">
        <CategoryContainer
          getSearchDocument={this.getSearchDocument}
          getSearchFolder={this.getSearchFolder}
          history={history}
          inputChange={inputChange}
          onBlur={onBlur}
          onSearch={onSearch}
          onViewMore={this.onViewMore}
          projectId={projectId}
          results={autocomplete}
          showAdvancedSearch={goToAdvancedSearch}
        />
        {selectedCategory && (
          <ResultsContainer
            categoryId={selectedCategory}
            checked={selectedResults}
            getFoldersSelected={this.getFoldersSelected}
            getSearchDocument={this.getSearchDocument}
            getSearchFolder={this.getSearchFolder}
            history={history}
            onBlur={onClickOutside}
            onCheckAllChange={this.onCheckAllChange}
            onCheckChange={this.onCheckChange}
            onSearchAll={onSearchAll}
            projectId={projectId}
            results={autocomplete.categories}
          />
        )}
      </div>
    );
  }
}

export default withRouter(enhanceWithClickOutside(SearchDropdown));
