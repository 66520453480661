import { connect } from 'react-redux';

import Translation from 'components/translation/translation';
import {
  loadTranslationLanguagePairs,
  loadTranslationsList,
  loadTranslationListLocal,
  translateDocuments,
  translateText
} from 'store/translations';

const mapStateToProps = ({
  data: {
    translations,
    currentLanguage,
    currentUser,
    aboutArgus
  }
}) => ({
  translations,
  currentLanguage,
  currentUser,
  aboutArgus
});

const mapDispatchToProps = {
  loadTranslationLanguagePairs,
  loadTranslationsList,
  loadTranslationListLocal,
  translateDocuments,
  translateText
};

export default connect(mapStateToProps, mapDispatchToProps)(Translation);
