import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { UPLOAD_STATES } from 'models/uploads';
import Button from 'components/shared/single-click-button';
import UploadFile from './upload-file';

class UploadProgress extends Component {
  static propTypes = {
    clearUploads: PropTypes.func.isRequired,
    uploads: PropTypes.array.isRequired
  };

  render() {
    const { clearUploads, uploads } = this.props;

    const numUploads = Object.values(uploads).filter(upload =>
      [UPLOAD_STATES.PENDING, UPLOAD_STATES.UPLOADING].includes(upload.state)
    ).length;

    if (!uploads.length) {
      return null;
    }

    return (
      <div className="upload-status popover no-footer">
        <div className="upload-status__header popover__section">
          <h3 className="upload-status__title">
            {numUploads ? (
              <FormattedMessage id="upload-status.title" values={{ numUploads }} />
            ) : (
              <FormattedMessage id="upload-status.recent-uploads" />
            )}
          </h3>
          <Button size="icon" className="btn btn-no-margin upload-status__clear-button" onClick={clearUploads}>
            <FormattedMessage id="common.close" />
          </Button>
        </div>
        <div className="upload-status__body popover__section">
          {uploads.map(upload => (
            <UploadFile key={upload.id} upload={upload} cancelUpload={() => {}} />
          ))}
        </div>
      </div>
    );
  }
}

export default UploadProgress;
