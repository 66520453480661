import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/shared/icon';
import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import Avatar from 'components/shared/avatar';
import Menu from 'containers/project-list/menu-container';
import dateUtil from 'utils/dateUtil';
import CONSTANTS from 'utils/constants';
import Button from 'components/shared/single-click-button';

// Table View Headings
const TABLE_HEADERS = [
  'Name',
  'Region',
  'Client Name',
  'WBS Code',
  'Status',
  'Creator',
  'Creation Date',
  'Last Edit Date'
];

function TranslationQueueTable(translations) {
  const getTranslationStatus = translation => {
    const status = Object.keys(CONSTANTS.TranslationStatus).find(
      key => CONSTANTS.TranslationStatus[key] === translation.translationStateId
    );

    return translation.translationStateId ? status : 'NoStatus';
  };

  return (
    <div className="table-view-translation-queue">
      <div className="table-view-translation-queue__header-first-line-wrapper">
        <span
          className="table-view-translation-queue__header-text"
          title={<FormattedMessage id="argus.translation.queue" />}
        >
          <FormattedMessage id="argus.translation.queue" />
        </span>
        <span className="table-view-translation-queue__button-wrapper">
          <Link to="translation">
            <span className="table-view-translation-queue__button-text">
              <FormattedMessage id="translation.translate" />
            </span>
          </Link>
        </span>
        <span className="table-view-translation-queue__button-wrapper">
          <Link to="translation#translation-queue">
            <span className="table-view-translation-queue__button-text">
              <FormattedMessage id="table-view.translation.navigation.all" />
            </span>
          </Link>
        </span>
      </div>
      <FixedTable className="table-view-translation-queue">
        <table className="table-view-translation-queue__table">
          <thead>
            <tr>
              <FixedHeader className="table-view-translation-queue__first">
                <div className="table-view__heading-text">
                  <FormattedMessage id="table-view.heading.name" />
                </div>
              </FixedHeader>
              <FixedHeader className="table-view-translation-queue__first">
                <div className="table-view__heading-text">
                  <FormattedMessage id="table-view.heading.status" />
                </div>
              </FixedHeader>
              <FixedHeader> </FixedHeader>
            </tr>
          </thead>
          <tbody>
            {translations.translations &&
              translations.translations.map((t, index) => {
                return (
                  <tr key={index}>
                    <td
                      className="table-view__body-client"
                      title={t.sourceFileName ? (t.sourceFileName.length > 31 ? t.sourceFileName : null) : null}
                    >
                      {t.sourceFileName}
                    </td>
                    <td className="table-view__body-status">
                      <div className={`table-view__body-status-icon ${getTranslationStatus(t)}`} />
                      <FormattedMessage id={`translation-queue.status.${getTranslationStatus(t)}`} />
                    </td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </FixedTable>
    </div>
  );
}

TranslationQueueTable.propTypes = {
  translations: PropTypes.any,
  TranslationQueueCardStatus: PropTypes.any
};

export default class TableView extends Component {
  static propTypes = {
    // Project List data loaded from reducer
    creator: PropTypes.any,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isMenuOpen: PropTypes.array,
    isOwner: PropTypes.any,
    linkClassname: PropTypes.any,
    linkTo: PropTypes.any,
    onDeselect: PropTypes.func,
    onSelect: PropTypes.func,
    status: PropTypes.any,
    user: PropTypes.object,
    titleKey: PropTypes.any
  };

  constructor(props) {
    super(props);
    // This will give us ref to the cell that needs a title on hover
    this.nameRefs = [];
    this.MATRefs = [];
    this.state = {
      // Determine whether to show the full name on hover or not
      isOverflowed: []
    };
  }

  // Overflow ellipsis checker so we know how to display long project names
  checkOverflow = (ref, hoveredIndex) => {
    const isOverflowed = this[ref][hoveredIndex].scrollWidth > this[ref][hoveredIndex].clientWidth;

    if (isOverflowed !== this.state.isOverflowed) {
      this.setState({ isOverflowed: { [hoveredIndex]: isOverflowed } });
    }
  };

  getTranslationStatus = translation => {
    const status = Object.keys(CONSTANTS.TranslationStatus).find(
      key => CONSTANTS.TranslationStatus[key] === translation.translationStateId
    );

    return translation.translationStateId ? status : 'NoStatus';
  };

  getTranslationQueueCardStatus = translations => {
    var translationsStatus = translations.map(t => this.getTranslationStatus(t));

    if (translations.every(s => s.translationStateId === 2)) return 'inCreation';
    if (translations.some(s => s.translationStateId === 4)) return 'legalHold';
    if (translations.every(s => s.translationStateId === 5)) return 'started';
    if (translations.some(s => s.translationStateId === 7 || s.translationStateId === 6)) return 'failed';
  };

  render() {
    const { isOverflowed } = this.state;
    const {
      data,
      isMenuOpen,
      onDeselect,
      onSelect,
      user,
      status,
      linkClassname,
      creator,
      linkTo,
      onUpdateStatus,
      titleKey
    } = this.props;
    return (
      <div>
        {data &&
          data.map((project, index) => {
            if (project.translations) {
              return (
                <TranslationQueueTable
                  translations={project.translations}
                  TranslationQueueCardStatus={this.getTranslationQueueCardStatus(project.translations)}
                />
              );
            }
          })}
        <div className="table-view">
          {titleKey ? (
            <div className="table-view__header-first-line-wrapper">
              <span className="table-view__header-text" title={<FormattedMessage id="list.heading.projects" />}>
                <FormattedMessage id="list.heading.projects" />
              </span>
            </div>
          ) : null}
          <FixedTable className="table-view">
            <table className="table-view__table">
              <thead>
                <tr>
                  {TABLE_HEADERS.map((heading, index) => {
                    return (
                      <FixedHeader className={`table-view__heading ${heading}`} key={index}>
                        <div className="table-view__heading-text">
                          {/* Represents the full heading name without case or spaces */}
                          <FormattedMessage id={`table-view.heading.${heading.toLowerCase().replace(/\s/g, '')}`} />
                        </div>
                      </FixedHeader>
                    );
                  })}
                  <th />
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((project, index) => {
                    const globalCode = `${project.geoCode}_${project.memberFirmCode}_${project.containerCode}`;
                    const projectStatus = status(project);
                    const linkClass = linkClassname(project);
                    const projectCreator = creator(project.creator, 'table');
                    const projectLink = linkTo(project, globalCode);
                    const projectUID = `${project.geoCode}-${project.memberFirmCode}-${project.containerCode}-${project.projectId}`;

                    if (!project.translations) {
                      return (
                        <tr key={index}>
                          <td
                            ref={element => (this.nameRefs[index] = element)}
                            title={isOverflowed[index] ? project.projectName : ''}
                            onMouseEnter={() => this.checkOverflow('nameRefs', index)}
                            onMouseLeave={() => this.setState({ isOverflowed: { [index]: false } })}
                          >
                            <Link
                              className={`table-view__body-name ${projectStatus ? projectStatus : 'grey'} ${linkClass}`}
                              to={projectLink}
                            >
                              {project.isAdminAccessEnabled && <Icon className="icon" name="shield" width={16} />}
                              {project.projectName || 'Untitled Name'}
                            </Link>
                          </td>
                          <td className="table-view__body-region">{project.label ? project.label : project.geoCode}</td>
                          <td className="table-view__body-client">{project.clientName ? project.clientName : ''}</td>
                          <td
                            className="table-view__body-wbs"
                            ref={element => (this.MATRefs[index] = element)}
                            title={isOverflowed[index] ? (project.engagementName ? project.engagementName : '') : ''}
                            onMouseEnter={() => this.checkOverflow('MATRefs', index)}
                            onMouseLeave={() => this.setState({ isOverflowed: { [index]: false } })}
                          >
                            {project.engagementName ? project.engagementName : ''}
                          </td>
                          <td className="table-view__body-status">
                            {project.legalHold ? (
                              <span className="tile-view__card-legalhold">
                                <Icon name="Flag-SPECIAL" width={14} />
                              </span>
                            ) : (
                              ''
                            )}
                            <div className={`table-view__body-status-icon ${projectStatus}`} />
                            <FormattedMessage id={`project-state.${projectStatus}`} />
                          </td>
                          <td className="table-view__body-creator">
                            <Avatar initials={projectCreator.initials} />
                            <span
                              data-tip
                              data-for="tile-view.card-creator-role.tooltip"
                              className="table-view__body-creator-text"
                            >
                              {projectCreator.displayName}
                            </span>
                          </td>

                          <td className="table-view__body-creation">
                            {project.createdDate ? dateUtil.formatDate(project.createdDate) : '-'}
                          </td>
                          <td className="table-view__body-lastedit">
                            {project.lastUpdatedDate ? dateUtil.formatDate(project.lastUpdatedDate) : '-'}
                          </td>
                          <td className="table-view__body-move">
                            <Menu
                              viewer="table-view"
                              projectState={projectStatus}
                              onClose={() => onDeselect(projectUID)}
                              onMenuClick={() => onSelect(projectUID)}
                              isOpen={isMenuOpen[projectUID]}
                              user={user}
                              selected={project.projectId}
                              projectDetails={project}
                              onCopied={data => onUpdateStatus(data)}
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </FixedTable>
        </div>
      </div>
    );
  }
}
