import { connect } from 'react-redux';

import Bookmarks from 'components/document-viewer/document-renderer/bookmarks/bookmarks';
import { getCurrentDocument, removeExtractionFieldRecord } from 'store/current-document';

const mapStateToProps = ({ data: { currentDocument, currentDocumentLayout } }) => ({
  currentDocument,
  currentDocumentLayout
});

const mapDispatchToProps = {
  getCurrentDocument,
  removeExtractionFieldRecord
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookmarks);
