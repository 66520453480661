import model from 'models/base-model';
import dateUtil from 'utils/dateUtil';
import { Project } from 'models/project';
import { flatten } from 'underscore';
import constants from 'utils/constants';

export class ProjectList extends model({
  isLoading: false,
  isPartialLoading: false,
  isLoaded: false,
  error: null,
  partialError: null,
  isSearching: false,
  isEmptySearch: false,
  projects: null
}) {
  setLoading(state) {
    return this.merge({
      ...state,
      isLoading: true,
      isLoaded: false,
      error: null,
      unfilteredProjects: null,
      projects: null,
      partialError: null
    });
  }

  setError(state, action) {
    const errLength = action.payload.err.length;

    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      isPartialLoading: false,
      error:
        state.partialError && state.partialError.length === errLength
          ? action.payload
          : state.partialError
          ? null
          : action.payload,
      projects: state.projects && state.projects.length > 0 ? state.projects : [],
      unfilteredProjects:
        state.unfilteredProjects && state.unfilteredProjects.length > 0 ? state.unfilteredProjects : []
    });
  }

  setPartialError(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      partialError: state.partialError ? state.partialError.concat(action.payload.geo) : [].concat(action.payload.geo),
      projects: state.projects && state.projects.length > 0 ? state.projects : []
    });
  }

  setPartialLoad(state, action) {
    const data = action.payload.data.map(project => Project.fromData(project));

    const getProjects = p => (p && p.length > 0 ? p.concat(data) : [].concat(data));
    return this.merge({
      isLoading: false,
      isPartialLoading: true,
      isLoaded: true,
      error: null,
      unfilteredProjects: getProjects(state.unfilteredProjects),
      projects: getProjects(state.projects)
    });
  }

  // Get data returned from the API
  setLoaded(state, action) {
    const allProjects = flatten(action.payload.map(geoProject => geoProject.data.data.map(p => Project.fromData(p))));
    
    return this.merge({
      ...state,
      isLoading: false,
      isPartialLoading: false,
      isLoaded: true,
      isSearching: false,
      error: null,
      unfilteredProjects: allProjects,
      projects: allProjects
    });
  }

  // Filter projects based on search query
  setSearched(state, action) {
    const projects = [...state.unfilteredProjects];
    const query = action.payload
      .toString()
      .toLowerCase()
      .trim();
    const searchKeys = ['projectName', 'clientName', 'engagementName'];
    const filteredProjects = projects.filter(project => {
      // Make sure the props we're filtering based on is non null value
      const nonNullKeys = searchKeys.filter(key => project[key] !== null);
      const creator = project.creator.userDetail.displayName !== null ? project.creator.userDetail.displayName : '';
      return (
        nonNullKeys.some(key =>
          String(project[key])
            .toLowerCase()
            .includes(query)
        ) || creator.toLowerCase().includes(query)
      );
    });

    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      isSearching: query.length > 0 ? true : false,
      error: null,
      isEmptySearch: filteredProjects.length <= 0 ? true : false,
      projects: query.length > 0 ? filteredProjects : state.unfilteredProjects
    });
  }

  setSoftDeleted(state, action) {
    const updateSoftDeleted = data =>
      data.map(item => {
        if (item.projectId === action.payload.projectId) {
          return {
            ...item,
            projectStateId: 4
          };
        }
        return item;
      });
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      unfilteredProjects: updateSoftDeleted(state.unfilteredProjects),
      projects: updateSoftDeleted(state.projects)
    });
  }

  setDeleted(state, action) {
    const updateDeleted = data => data.filter(item => item.projectId !== action.payload.projectId);

    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      unfilteredProjects: updateDeleted(state.unfilteredProjects),
      projects: updateDeleted(state.projects)
    });
  }

  setFlagClientService(state, action) {
    const updateClientService = data =>
      data.map(item => {
        if (item.projectId === action.payload.projectId) {
          return {
            ...item,
            isClientService: action.payload.newValue
          };
        }
        return item;
      });
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      unfilteredProjects: updateClientService(state.unfilteredProjects),
      projects: updateClientService(state.projects)
    });
  }

  setRestored(state, action) {
    const updateRestored = data =>
      data.map(item => {
        if (item.projectId !== action.payload.data.projectId) {
          // This isn't the item we care about - keep it as-is
          return item;
        }
        // Otherwise, this is the one we want - return an updated value for ADMIN only
        return {
          ...action.payload.res.data,
          lastUpdatedDate: dateUtil.fromServerDate(action.payload.res.data.lastUpdatedDate),
          createdDate: dateUtil.fromServerDate(action.payload.res.data.createdDate)
        };
      });
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      unfilteredProjects: updateRestored(state.unfilteredProjects),
      projects: updateRestored(state.projects)
    });
  }

  setCopied(state, action) {
    const copied = Project.fromData(action.payload.res.data);
    copied.myRole = { roleId : constants.UserRoles.PROJECT_CREATOR };
    const updateCopied = data =>
      data.map(item => {
        if (item.projectId !== action.payload.original) {
          // This isn't the item we care about - keep it as-is
          return item;
        }
        if (item.projectId === copied.projectId) {
          // This isn't the item we care about - keep it as-is
          return{ 
            ...item,
          projectStateId: 8
          }
        }
        // Otherwise, this is the one we want - return an updated value for ADMIN only
        return {
          ...item,
          //projectStateId: 8
        };
      });
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      unfilteredProjects: updateCopied(state.unfilteredProjects.concat(copied)),
      projects: updateCopied(state.projects.concat(copied))
    });
  }

  setProjectUpdatedStatus(state,action){
    const sourceProject = action;
    const updateCopied = data =>
      data.map(item => {
        if (item.projectId !== action.payload.projectId) {
          // This isn't the item we care about - keep it as-is
          return item;
        }
        // Otherwise, this is the one we want - return an updated value for ADMIN only
        if(item.geoCode !== action.payload.geoCode)
        {
          return item;
        }
        return {
          ...item,
          projectStateId: action.payload.projectStateId
        };
      });
    return this.merge({
      ...state,
      projects: updateCopied(state.projects),
      unfilteredProjects: updateCopied(state.unfilteredProjects)
    });

  }

  setEnabledAccess(state, action) {
    const updateEnabledAccess = data =>
      data.map(item => {
        if (item.projectId === action.payload.projectId) {
          item.isAdminAccessEnabled = action.payload.allowAccess;
        }
        return item;
      });
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      unfilteredProjects: updateEnabledAccess(state.unfilteredProjects),
      projects: updateEnabledAccess(state.projects)
    });
  }

  setArchived(state, action) {
    const approverId = action.payload.update.approverId;
    const updateArchived = data =>
      data.map(item => {
        if (item.projectId !== action.payload.data.projectId) {
          // This isn't the item we care about - keep it as-is
          return item;
        }
        // Otherwise, this is the one we want - return an updated value for ADMIN only
        return {
          ...item,
          archivalRequest: {
            requestorId: action.payload.update.requestorId,
            approverIdList: state.archivalRequest
              ? state.archivalRequest.approverIdList.push(parseInt(approverId, 10))
              : [].concat(approverId)
          },
          projectStateId: 6
        };
      });
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      unfilteredProjects: updateArchived(state.unfilteredProjects),
      projects: updateArchived(state.projects)
    });
  }

  setCancelArchived(state, action) {
    const updateCancelArchived = data =>
      data.map(item => {
        if (item.projectId === action.payload.original) {
          return { ...item, projectStateId: 2 };
        }
        return item;
      });
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      unfilteredProjects: updateCancelArchived(state.unfilteredProjects),
      projects: updateCancelArchived(state.projects)
    });
  }

  setCarried(state, action) {
    const carried = Project.fromData(action.payload.res.data);
    carried.myRole = { roleId : constants.UserRoles.PROJECT_CREATOR };
    const updateCarried = data =>
      data.map(item => {
        if (item.projectId === action.payload.original) {
          return { ...item, projectStateId: 8 };
        }
        return item;
      });
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      unfilteredProjects: updateCarried(state.unfilteredProjects.concat(carried)),
      projects: updateCarried(state.projects.concat(carried))
    });
  }

  setLegalHold(state, action) {
    const updateLegalHold = data =>
      data.map(item => {
        if (item.projectId === action.payload.projectId) {
          return { ...item, legalHold: action.payload.isLegalHold };
        }
        return item;
      });
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      projects: updateLegalHold(state.projects),
      unfilteredProjects: updateLegalHold(state.unfilteredProjects)
    });
  }
}
