import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';

import enhanceWithClickOutside from 'react-click-outside';
import { debounce } from 'underscore';

/** Highlighter for when the keyword matches a word in the paragraph */
const Highlighted = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span className="compare-search__search-item-last">{text}</span>;
  }

  const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = String(text).split(regex);
  return (
    <span className="compare-search__search-item-last">
      {parts
        .filter(part => part)
        .map((part, i) =>
          regex.test(part) ? (
            <span className="compare-search__highlight" key={i}>
              {part}
            </span>
          ) : (
            <span className="compare-search__search-item-last" key={i}>
              {part}
            </span>
          )
        )}
    </span>
  );
};

Highlighted.propTypes = {
  highlight: PropTypes.any,
  text: PropTypes.any
};

// Dropdown functional component when user starts typing
class CompareDropdown extends Component {
  static propTypes = {
    data: PropTypes.any,
    onClickOutside: PropTypes.func,
    onItemClick: PropTypes.func,
    query: PropTypes.string
  };

  render() {
    const { data, onItemClick, query } = this.props;
    return (
      <div className="search-dropdown">
        <div className="compare-search__left-side">
          {data &&
            data.length > 0 &&
            data.map((value, index) => {
              return (
                <span className="compare-search__search-item" key={index} onClick={() => onItemClick(index)}>
                  <Highlighted text={value.text} highlight={query} />
                  <span className="compare-search__arrow-right">
                    <Icon name="special-arrow-right" width={12} />
                  </span>
                </span>
              );
            })}
        </div>
      </div>
    );
  }
}

/** Main compare search component */
class CompareSearch extends Component {
  static propTypes = {
    compareData: PropTypes.shape({
      base: PropTypes.array,
      compare: PropTypes.array
    }),
    compareSetEmphasis: PropTypes.func,
    compareSetSearch: PropTypes.func,
    match: PropTypes.object,
    onSearchChange: PropTypes.func,
    search: PropTypes.object
  };

  state = {
    compareData: [],
    isDropdownOpen: false,
    searchResults: [],
    emphasis: 0,
    query: ''
  };

  // Set sentence length of matched results
  SENTENCE_LENGTH = 220;

  // When a user received new data
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.compareData.compare !== nextProps.compareData.compare) {
      this.getBaseAndCompareData(nextProps.compareData);
    }
  }

  getBaseAndCompareData = compareData => {
    const { match } = this.props;

    // Update search results based on whether we have a second compare document or not
    if (match.params.compareId && match.params.documentId && compareData.compare && compareData.compare.length > 0) {
      // If we're already searching, make sure to refresh the search results
      if (this.state.query.trim().length > 1) {
        this.getSearchAdjacentResults(compareData.base.concat(compareData.compare));
      }
      return this.setState({ compareData: compareData.base.concat(compareData.compare), emphasis: 0 });
    }

    // Otherwise just update if user deselects from compare document
    if (this.state.query.trim().length > 1) {
      this.getSearchAdjacentResults(compareData.base);
    }
    return this.setState({ compareData: compareData.base, emphasis: 0 });
  };

  onChange = value => {
    this.setState({ query: value });

    // Show dropdown when user starts typing
    if (value && value.length > 1) {
      this.setState({ isDropdownOpen: true });
    } else {
      this.setState({ isDropdownOpen: false, searchResults: [], emphasis: 0 });
    }
  };

  onBlur = () => {
    if (this.state.query.length <= 0) {
      this.onExit();
    }
  };

  onExit = () => {
    this.props.compareSetSearch({ data: [], emphasis: 0, query: '' });
    this.setState({ query: '', isDropdownOpen: false, searchResults: [], emphasis: 0 });
  };

  onFocus = () => {
    if (this.state.query.length > 1) {
      this.setState({ isDropdownOpen: true });
    }
  };

  handleClickOutside = () => {
    if (this.state.query.length > 1) {
      this.setState({ isDropdownOpen: false });
    }
  };

  cleanData = array => {
    return array.map(value => value.contextualData.replace(/\r\n/g, '')).join('');
  };

  // A helper function that allows you to find the search query's neighboring words
  findNeighbors = (idx, string) => {
    const divider = (this.SENTENCE_LENGTH - this.state.query.length) / 2;
    let prev = Math.max(0, idx - divider + 70);
    let next = Math.min(string.length, idx + divider);

    return string.slice(prev, idx) + string.slice(idx, next);
  };

  // Find where the search string occurs in the compare data array
  // And map it to be readable
  findSearchIndexes = array => {
    const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    const regex = new RegExp(`(${escapeRegExp(this.state.query)})`, 'gi');
    const data = this.cleanData(array);

    let match,
      indices = [];
    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(data))) {
      indices.push(match.index);
    }

    return indices;
  };

  // Map results so it's easily readable by function dropdown component
  mapSearches = array => {
    const data = this.cleanData(array);
    const indices = this.findSearchIndexes(array);

    return indices.map(value => {
      return {
        scrollIndex: value,
        text: this.findNeighbors(value, data)
      };
    });
  };

  // Get search results with their word neighbors
  getSearchAdjacentResults = array => {
    this.setState({ searchResults: this.mapSearches(array) });
    this.props.compareSetSearch({ data: this.mapSearches(array), emphasis: 0, query: this.state.query });
  };

  onKeyUp = debounce(() => {
    if (this.state.query.trim().length > 1) {
      this.getSearchAdjacentResults(this.state.compareData);
    }
  }, 500);

  // Emphasis helpers when a user selects a certain matched search result
  setEmphasis = index => {
    this.setState({ emphasis: index, isDropdownOpen: false });

    this.props.compareSetSearch({ data: this.state.searchResults, emphasis: index, query: this.state.query });
    this.props.compareSetEmphasis(index);
  };

  setEmphasisNext = () => {
    const { emphasis, searchResults } = this.state;
    this.setEmphasis(emphasis + 1 < searchResults.length ? emphasis + 1 : 0);
  };

  setEmphasisPrevious = () => {
    const { emphasis, searchResults } = this.state;
    this.setEmphasis(emphasis - 1 >= 0 ? emphasis - 1 : searchResults.length - 1);
  };

  render() {
    const { isDropdownOpen, searchResults, emphasis, query } = this.state;
    const { compareData } = this.props;

    return (
      <div className="search-bar-wrapper">
        <div className="search-bar__input-field">
          <input
            name="search"
            id="search"
            className="search-bar__input"
            disabled={!compareData || !compareData.base}
            value={query}
            onChange={e => this.onChange(e.target.value)}
            onKeyUp={this.onKeyUp}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          {searchResults.length > 0 ? (
            <div className="compare-search__result-controls">
              <span className="compare-search__result-number">
                <FormattedMessage
                  id="search-results.controls"
                  values={{
                    current: emphasis + 1 > searchResults.length ? 1 : emphasis + 1,
                    total: searchResults.length
                  }}
                />
              </span>
              <Button size="icon" onClick={this.setEmphasisPrevious}>
                <Icon name="special-arrow-right" width={12} rotate={180} />
              </Button>
              <Button size="icon" onClick={this.setEmphasisNext}>
                <Icon name="special-arrow-right" width={12} />
              </Button>
              <Button size="icon" className="icon-button" onClick={this.onExit}>
                <Icon name="special-cross-black" width={12} />
              </Button>
            </div>
          ) : null}
        </div>
        <div className="search-bar__button-wrapper">
          <Button size="icon" className="search-bar__input-search" disabled={query.length <= 1} onClick={this.onKeyUp}>
            <Icon name="special-search-gray" width={13} />
          </Button>
        </div>
        {isDropdownOpen ? (
          <CompareDropdown
            data={searchResults}
            query={query}
            onItemClick={index => {
              this.setState({ isDropdownOpen: false });
              this.setEmphasis(index);
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default enhanceWithClickOutside(CompareSearch);
