import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import Dropdown from 'components/shared/form/dropdown';
import { getExtractionFieldValidation } from 'store/api';

const FILTER_CRITERIA = {
  ALL_EXTRACTIONS: 0,
  TRUE_HITS: 1,
  FALSE_HITS: 2,
  MISSES: 3
};

const FILTER_OPTIONS = [
  {
    validationType: FILTER_CRITERIA.ALL_EXTRACTIONS,
    name: <FormattedMessage id="quick-study.extraction-field-details.measures-tab.all-extractions" />
  },
  {
    validationType: FILTER_CRITERIA.MISSES,
    name: <FormattedMessage id="quick-study.extraction-field-details.measures-tab.misses" />
  },
  {
    validationType: FILTER_CRITERIA.FALSE_HITS,
    name: <FormattedMessage id="quick-study.extraction-field-details.measures-tab.false-hits" />
  },
  {
    validationType: FILTER_CRITERIA.TRUE_HITS,
    name: <FormattedMessage id="quick-study.extraction-field-details.measures-tab.true-hits" />
  }
];

/**
 * A single item in the measures table
 */
class MeasuresItem extends Component {
  static propTypes = {
    measure: PropTypes.object
  };

  render() {
    const { measure } = this.props;

    return (
      <tr>
        <td className="data-table__td">
          {measure.documentAccess ? (
            <Link
              to={`/region/${measure.geoCode}_${measure.memberFirmCode}_${measure.containerCode}/project/${
                measure.projectId
              }/document/${measure.documentId}?location[]=${measure.location[0]}&location[]=${measure.location[1]}`}
              className="data-table__link"
              disabled
            >
              {measure.documentName}
            </Link>
          ) : (
            <span>{measure.documentName}</span>
          )}

          <div className="extraction-details-measures__project-name">{measure.projectName}</div>
        </td>
        <td className="data-table__td data-table__td--no-break">{measure.extractFieldInstanceData}</td>
      </tr>
    );
  }
}

/**
 * Main component for the measures tab of the extraction field details page
 */
class Measures extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired
  };

  state = {
    filterBy: FILTER_OPTIONS[0],
    validation: []
  };

  componentDidMount() {
    this.getValidation();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.field !== this.props.field) {
      this.getValidation();
    }
  }

  /**
   * Fetch the data for this component
   */
  getValidation = () => {
    const { field } = this.props;
    getExtractionFieldValidation(field.extractionFieldId).then(response =>
      this.setState({
        validation: response.data || []
      })
    );
  };

  /**
   * Set the filter criteria
   */
  setFilterBy = filterBy => {
    this.setState({ filterBy });
  };

  renderFilterOption = option => option.name;

  render() {
    const { filterBy, validation } = this.state;

    const measures =
      filterBy.validationType === FILTER_CRITERIA.ALL_EXTRACTIONS
        ? validation
        : validation.filter(measure => measure.validationType === filterBy.validationType);

    return (
      <div className="extraction-details__tab-content-section extraction-details-measures">
        <div className="extraction-details-measures__table-container">
          <FixedTable>
            <table className="extraction-details-measures__table data-table">
              <thead>
                <tr>
                  <FixedHeader className="data-table__th extraction-details-measures__name-header">
                    <div>
                      <FormattedMessage id="quick-study.extraction-field-details.measures-tab.name" />
                    </div>
                    <Dropdown
                      options={FILTER_OPTIONS}
                      selectedOption={filterBy}
                      onSelection={this.setFilterBy}
                      renderOption={this.renderFilterOption}
                    />
                  </FixedHeader>
                  <FixedHeader className="data-table__th">
                    <FormattedMessage id="quick-study.extraction-field-details.measures-tab.annotation" />
                  </FixedHeader>
                </tr>
              </thead>
              <tbody>{measures.map((measure, index) => <MeasuresItem measure={measure} key={index} />)}</tbody>
            </table>
          </FixedTable>
        </div>
      </div>
    );
  }
}

export default Measures;
