import io from 'socket.io-client';

import { isNumeric } from 'utils/number-utils';

export const rooms = {
  user(userId) {
    return `users/${userId}`;
  },
  globalExports(userId) {
    return `users/${userId}/exports`;
  },
  exports(projectId, userId) {
    return `projects/${projectId}/users/${userId}/exports`;
  },
  project(projectId) {
    return `projects/${projectId}`;
  },
  folder(projectId, folderId) {
    return `projects/${projectId}/folders/${folderId}`;
  },
  document(projectId, documentId) {
    return `projects/${projectId}/documents/${documentId}`;
  },
  templates(projectId) {
    return `projects/${projectId}/templates`;
  },
  templateExtractionFields(projectId, templateId) {
    return `projects/${projectId}/templates/${templateId}/extractionfields`;
  },
  extractionField(extractionFieldId) {
    return `extractionFields/${extractionFieldId}`;
  },
  extractionFields() {
    return `extractionFields`;
  },
  extractionFieldGroups() {
    return `extractionFieldGroups`;
  },
  extractionFieldImports(userId) {
    return `extractionFields/${userId}/import`;
  },
  comparisons(projectId) {
    return `projects/${projectId}/documentcomparisons`;
  },
  comparison(projectId, comparisonId) {
    return `projects/${projectId}/documentcomparisons/${comparisonId}`;
  },
  extractionFieldComparisons(projectId) {
    return `projects/${projectId}/extractionfieldcomparisons`;
  },
  extractionFieldComparison(projectId, comparisonId) {
    return `projects/${projectId}/extractionfieldcomparisons/${comparisonId}`;
  },
  visuals(projectId) {
    return `projects/${projectId}/visuals`;
  },
  translationStatus(userId) {
    return `users/${userId}/contentTranslations`;
  }
};

export class Socket {
  // User access token from Azure AD
  accessToken = null;

  // If set, initiate a connection once an access token is set
  autoConnect = false;

  // Socket connection is establised
  isConnected = false;

  // Queue of callbacks to run once authenticated and connected
  readyStateCallbacks = [];

  // Socket object
  socket = null;

  // URL to connect to
  url = null;

  // Container code
  containerCode = null;

  // Member firm code
  memberFirmCode = null;

  // Rooms this socket has joined
  rooms = new Map();

  constructor(config) {
    Object.assign(this, config);

    if (this.autoConnect) {
      this.tryReconnect();
    }
  }

  // Inititate the socket connection
  tryConnect() {
    // Not ready to connect
    if (!this.url || !this.accessToken) {
      return false;
    }

    this.socket = io.connect(this.url, {
      //withCredentials: true,
      transports: ['websocket'],
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${this.accessToken}`
          }
        }
      }
    });
    this.socket.on('connect', () => {
      this.isConnected = true;
      this.tryRunCallbacks();
    });

    this.socket.on('connect_error', (err) => {
      // the reason of the error, for example "xhr poll error"
      console.log(err.message);
    
      // some additional description, for example the status code of the initial HTTP response
      console.log(err.description);
    
      // some additional context, for example the XMLHttpRequest object
      console.log(err.context);
    });

    return true;
  }

  tryReconnect() {
    // If already connected disconnect first
    if (this.isConnected) {
      this.disconnect();
    }

    this.tryConnect();
  }

  disconnect() {
    if (!this.socket) {
      return;
    }

    this.socket.close();
    this.rooms = new Map();
  }

  setRegion(url, containerCode, memberFirmCode) {
    this.containerCode = containerCode;
    this.memberFirmCode = memberFirmCode;

    if (this.url === url + '/argus') {
      return;
    }

    this.url = url + '/argus';

    if (this.autoConnect) {
      this.tryReconnect();
    }
  }

  setAccessToken(accessToken) {
    if (this.accessToken === accessToken) {
      return;
    }

    this.accessToken = accessToken;

    if (this.autoConnect) {
      this.tryReconnect();
    }
  }

  on(event, callback) {
    this.whenReady(() => this.socket.on(event, callback));
  }

  off(event, callback) {
    this.whenReady(() => this.socket.off(event, callback));
  }

  join(room) {
    this.whenReady(() => {
      const refs = this.rooms.get(room);

      // If the room has already been joined, just increment the reference counter
      if (isNumeric(refs)) {
        this.rooms.set(room, refs + 1);
        return;
      }

      // If the room has not been joined, set refs to 1 and join
      this.rooms.set(room, 1);
      this.socket.emit('join', {
        accessToken: this.accessToken,
        //room: `${this.containerCode}/${this.memberFirmCode}/${room}` // TODO: uncomment for globalization
        room
      });
    });
  }

  leave(room) {
    this.whenReady(() => {
      let refs = this.rooms.get(room);

      // Don't leave a room before joining it
      if (!isNumeric(refs)) {
        return;
      }

      // Decrement the reference counter
      if (refs > 0) {
        refs = refs - 1;
        this.rooms.set(room, refs);
      }

      // If there's no more references, leave the room
      if (refs === 0) {
        this.rooms.delete(room);
        this.socket.emit('leave', {
          accessToken: this.accessToken,
          //room: `${this.containerCode}/${this.memberFirmCode}/${room}` // TODO: uncomment for globalization
          room
        });
      }
    });
  }

  /*
    If not yet connected and authenticated, queue the callback until ready
    Otherwise invoke the callback immediately
  */
  whenReady(callback) {
    if (this.isConnected && this.accessToken) {
      callback();
    } else {
      this.readyStateCallbacks.push(callback);
    }
  }

  /*
    After connecting and authenticating, run all pending callbacks
  */
  tryRunCallbacks() {
    if (this.isConnected && this.accessToken) {
      while (this.readyStateCallbacks.length) {
        this.readyStateCallbacks.shift()();
      }
    }
  }
}

const socket = new Socket({
  autoConnect: true
});

export default socket;
