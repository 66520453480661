import { debounce } from 'underscore';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { hasError, ERROR_CODES } from 'utils/errors';
import { isProjectItemNameValid } from 'utils/validation';
import ValidTextArea from 'components/project-creation/project-details/shared/valid-text-area';

import { validateProjectName, validateProjectNameWithId } from 'store/api';
import constants from 'utils/constants';

/*
 * Project name input field
 * Performs checks/validation
 */
export default class NameInputField extends Component {
  static propTypes = {
    allowEditing: PropTypes.bool,
    checkValid: PropTypes.func,
    intl: intlShape,
    onChange: PropTypes.func,
    project: PropTypes.any,
    projectState: PropTypes.object,
    setNameResolved: PropTypes.func,
    updateProjectName: PropTypes.func
  };

  state = {
    nameValue: '',
    nameError: null,
    isEditing: true,
    isValid: false
  };

  componentDidMount() {
    const {
      project: { projectName, projectTypeId},
      projectState: { NEW, INPROGRESS, SETTINGS, ARCHIVE, COPY }
    } = this.props;

    if (NEW && projectTypeId == constants.ProjectTypes.WORKSPACE_PROJECT) {
      return this.setState({ nameValue: projectName, isValid: false , isEditing: false});  
    }

    if (INPROGRESS || COPY) {
      this.props.setNameResolved(true);
      return this.setState({ nameValue: projectName, isValid: false }, () => {
        this.onValidate();
      });
    }

    if (SETTINGS || ARCHIVE) {
      return this.setState({ nameValue: projectName, isValid: true, isEditing: false });
    }
  }

  // Send updates to the parent when the name input field is valid
  componentDidUpdate(prevProps, prevState) {
    if (prevState.isValid !== this.state.isValid) {
      this.props.checkValid(this.state.isValid);
    }
  }

  // update name to api function
  saveName = value => {
    const {
      project,
      projectState: { SETTINGS }
    } = this.props;

    return this.props
      .updateProjectName({
        projectId: project.projectId,
        projectName: value
      })
      .then(() => {
        this.setState({
          isEditing: !SETTINGS,
          isValid: true,
          nameError: null
        });
      })
      .catch(err => {
        if (hasError(err, ERROR_CODES.PROJECT_DUPLICATENAME)) {
          this.setState({
            isValid: false,
            isEditing: true,
            nameError: this.props.intl.formatMessage({ id: 'project-details.body.duplicate-error' })
          });
        } else if (hasError(err, ERROR_CODES.PROJECT_INVALID) || hasError(err, ERROR_CODES.INPUT_INVALID)) {
          this.setState({
            isValid: false,
            isEditing: true,
            nameError:
              this.props.intl.formatMessage({ id: 'project-details.body.name-error' }) + '<, >, :, ", /, \\, |, ?, or *'
          });
        }
        throw err;
      });
  };

  onEditClick = () => {
    this.setState({ isEditing: true });
  };

  onBlur = () => {
    const { nameValue } = this.state;

    const {
      projectState: { SETTINGS, COPY },
      project
    } = this.props;
    // Save on click outside
    if (SETTINGS) {
      // If it's the same dont do anything
      if (project.projectName === nameValue) {
        return this.setState({ nameValue: project.projectName, nameError: null, isValid: true, isEditing: false });
      }

      if (nameValue && !isProjectItemNameValid(nameValue)) {
        return this.setState({
          isValid: false,
          nameError:
            this.props.intl.formatMessage({ id: 'project-details.body.name-error' }) + '<, >, :, ", /, \\, |, ?, or *'
        });
      }

      return this.saveName(nameValue);
    }

    if (COPY) {
      return this.saveName(nameValue);
    }
  };

  onChange = nameValue => {
    this.setState({ nameValue });
    this.props.onChange(nameValue);
    this.props.setNameResolved(false);

    if (nameValue && nameValue.length <= 0) {
      return this.setState({ isValid: false });
    }
  };

  // Name validator function, depends on whether we have a project or not
  nameValidateAPI = name => {
    const { project } = this.props;
    if (!project.projectId) {
      return validateProjectName({ name });
    }
    return validateProjectNameWithId({ name, projectId: project.projectId });
  };

  nameValidator = name => {
    if (name && !isProjectItemNameValid(name)) {
      return this.setState({
        isValid: false,
        nameError:
          this.props.intl.formatMessage({ id: 'project-details.body.name-error' }) + '<, >, :, ", /, \\, |, ?, or *'
      });
    }

    return this.nameValidateAPI(name).then(res => {
      if (name !== this.state.nameValue) {
        // old request
        return;
      }

      if (!res.isNonduplicateName) {
        return this.setState({
          isValid: false,
          nameError: this.props.intl.formatMessage({ id: 'project-details.body.duplicate-error' })
        });
      }

      if (!res.isValidLength || !res.isValidFormat) {
        return this.setState({
          isValid: false,
          nameError:
            this.props.intl.formatMessage({ id: 'project-details.body.name-error' }) + '<, >, :, ", /, \\, |, ?, or *'
        });
      }

      this.props.setNameResolved(true);
      return this.setState({ nameError: null, isValid: true });
    });
  };

  // Validate on key up
  onValidate = debounce(() => {
    const { nameValue } = this.state;

    const {
      projectState: { SETTINGS }
    } = this.props;

    if (!SETTINGS && nameValue && nameValue.length > 0) {
      return this.nameValidator(nameValue);
    }
  }, 500);

  render() {
    const { intl, allowEditing } = this.props;
    const { nameValue, nameError, isEditing, isValid } = this.state;
    return ( 
      <ValidTextArea
        autoFocus={true}
        value={nameValue}
        headingId="project-details.body.name"
        onChange={this.onChange}
        placeholder={intl.formatMessage({ id: 'project-details.body.name-placeholder' })}
        maxLength={400}
        isEditing={isEditing}
        onEditClick={this.onEditClick}
        onBlur={this.onBlur}
        onKeyUp={this.onValidate}
        isOwner={allowEditing}
        isTextValid={isValid}
        errors={nameError}
        isAsteriskRequired = "true"
      />
    );
  }
}
