import { connect } from 'react-redux';

import { clearSearchData } from 'store/search/search-category';
import {
  getCurrentSearchFolder,
  getDocumentResults,
  getFolderResults,
  clearResultsData
} from 'store/search/search-results';
import SearchDropdown from 'components/search/file-list-search/search-dropdown';

const mapStateToProps = ({ data: { currentSearchFolder, search } }) => ({
  currentSearchFolder,
  search
});

const mapDispatchToProps = {
  getDocumentResults,
  getFolderResults,
  clearResultsData,
  clearSearchData,
  getCurrentSearchFolder
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDropdown);
