import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { LoadFormat } from 'models/intl/loadFormat';

export const ACTIONS = {
  // Extractions
  FORMATS_LOADING: 'argus/ui/FORMATS_LOADING',
  FORMATS_LOADED: 'argus/ui/FORMATS_LOADED',
  FORMATS_ERROR: 'argus/ui/FORMATS_ERROR'
};

export const formatsLoading = createAction(ACTIONS.FORMATS_LOADING);
export const formatsLoaded = createAction(ACTIONS.FORMATS_LOADED);
export const formatsError = createAction(ACTIONS.FORMATS_ERROR);

export const loadFormatData = () => dispatch => {
  dispatch(formatsLoading());
  return api
    .getFormats()
    .then(res => {
      dispatch(formatsLoaded(res.data.value));
    })
    .catch(error => {
      dispatch(formatsError(error));
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new LoadFormat();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.FORMATS_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.FORMATS_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [ACTIONS.FORMATS_ERROR]: (state, action) => {
    return state.setError(state, action);
  }
});
