import { connect } from 'react-redux';

import { loadComparisonSets } from 'store/comparison-sets';
import { setCompareType } from 'store/compare-type';
import CompareBrowser from 'components/compare-browser/compare-browser';

const mapStateToProps = ({ data: { comparisonSets, currentProject }, ui: { compareType } }) => ({
  compareType,
  comparisonSets,
  currentProject
});

const mapDispatchToProps = {
  loadComparisonSets,
  setCompareType
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareBrowser);
