import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import {
  createExtractionFieldExport,
  deleteExtractionField,
  getCurrentRegion,
  getExtractionFieldDetails,
  makeExtractionModelPermanent,
  publishExtractionField,
  updateExtractionField
} from 'store/api';
import { getExtractionFieldHistory } from 'store/api';
import { hasError, ERROR_CODES } from 'utils/errors';
import { isNumeric } from 'utils/number-utils';
import { sortByKey } from 'utils/string-utils';
import Button from 'components/shared/single-click-button';
import ComplicatedIcon from 'components/extractions/complicated-icon/complicated-icon';
import constants from 'utils/constants';
import EditableText from 'components/shared/form/editable-text';
import GroupsAndUsers from 'components/quick-study/details/groups-and-users';
import History from 'components/quick-study/details/history';
import Icon from 'components/shared/icon';
import Learning from 'components/quick-study/details/learning';
import Measures from 'components/quick-study/details/measures';
import Permissions from 'permissions/permissions';
import socket, { rooms } from 'utils/socket';
import Toolbar, { TOOLBAR_OPTIONS } from 'components/quick-study/toolbar/toolbar';

const { ExtractionAccessTypeIds } = constants;

const TABS = {
  LEARNING: 'LEARNING',
  GROUPS: 'GROUPS',
  MEASURES: 'MEASURES',
  HISTORY: 'HISTORY'
};

/**
 * Extraction field details summary section
 */
export const Summary = ({ field, fieldDescription, isAdmin, onChangeDescription, makeExtractionModelPermanent }) => (
  <div className="extraction-summary">
    {/* Description section */}
    <div className="extraction-summary__section">
      <h3 className="extraction-summary__section-heading">
        <FormattedMessage id="quick-study.extraction-summary.description" />
      </h3>
      <p className="extraction-summary__description">
        <EditableText
          disableEdit={!Permissions.Global.ExtractionField.canRename(field)}
          onSubmit={onChangeDescription}
          resize="vertical"
          rows="4"
          tag="textarea"
          maxLength="1000"
          value={fieldDescription}
        />
      </p>
    </div>
    {/* Accuracy measures section */}
    {isAdmin && (
      <div className="extraction-summary__section">
        <h3 className="extraction-summary__section-heading">
          <FormattedMessage id="quick-study.extraction-summary.accuracy-measures" />
        </h3>
        <div className="extraction-summary__attributes-list">
          <div className="extraction-summary__attribute">
            <div className="extraction-summary__attribute-label">
              <FormattedMessage id="quick-study.extraction-summary.precision" />
            </div>
            <div className="extraction-summary__attribute-value">
              {isNumeric(field.precision) ? <FormattedNumber value={field.precision} maximumFractionDigits={2} /> : '-'}
            </div>
          </div>
          <div className="extraction-summary__attribute">
            <div className="extraction-summary__attribute-label">
              <FormattedMessage id="quick-study.extraction-summary.recall" />
            </div>
            <div className="extraction-summary__attribute-value">
              {isNumeric(field.recall) ? <FormattedNumber value={field.recall} maximumFractionDigits={2} /> : '-'}
            </div>
          </div>
          <div className="extraction-summary__attribute">
            <div className="extraction-summary__attribute-label">
              <FormattedMessage id="quick-study.extraction-summary.f-score" />
            </div>
            <div className="extraction-summary__attribute-value">
              {isNumeric(field.fScore) ? <FormattedNumber value={field.fScore} maximumFractionDigits={2} /> : '-'}
            </div>
          </div>
        </div>
        {field.isTrained && (
          <button onClick={makeExtractionModelPermanent} className="btn btn-secondary btn-small">
            <FormattedMessage id="quick-study.extraction-field-details.learning-tab.make-permanent" />
          </button>
        )}
      </div>
    )}
    {/* Summary section (examples, misses, false hits, true hits) */}
    {isAdmin && (
      <div className="extraction-summary__section">
        <h3 className="extraction-summary__section-heading">
          <FormattedMessage id="quick-study.extraction-summary.summary" />
        </h3>
        <div className="extraction-summary__attributes-list">
          <div className="extraction-summary__attribute">
            <div className="extraction-summary__attribute-label extraction-summary__attribute-label--highlighted">
              <FormattedMessage id="quick-study.extraction-summary.examples" />
            </div>
            <div className="extraction-summary__attribute-value">
              {isNumeric(field.numberOfDocuments) ? <FormattedNumber value={field.numberOfDocuments} /> : '-'}
            </div>
          </div>
          <div className="extraction-summary__attribute">
            <div className="extraction-summary__attribute-label extraction-summary__attribute-label--highlighted">
              <FormattedMessage id="quick-study.extraction-summary.misses" />
            </div>
            <div className="extraction-summary__attribute-value">
              {isNumeric(field.numberOfMisses) ? <FormattedNumber value={field.numberOfMisses} /> : '-'}
            </div>
          </div>
          <div className="extraction-summary__attribute">
            <div className="extraction-summary__attribute-label extraction-summary__attribute-label--highlighted">
              <FormattedMessage id="quick-study.extraction-summary.false-hits" />
            </div>
            <div className="extraction-summary__attribute-value">
              {isNumeric(field.numberOfFalseHit) ? <FormattedNumber value={field.numberOfFalseHit} /> : '-'}
            </div>
          </div>
          <div className="extraction-summary__attribute">
            <div className="extraction-summary__attribute-label extraction-summary__attribute-label--highlighted">
              <FormattedMessage id="quick-study.extraction-summary.true-hits" />
            </div>
            <div className="extraction-summary__attribute-value">
              {isNumeric(field.numberOfTrueHit) ? <FormattedNumber value={field.numberOfTrueHit} /> : '-'}
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

Summary.propTypes = {
  field: PropTypes.object.isRequired,
  fieldDescription: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  makeExtractionModelPermanent: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired
};

/**
 * Extraction field details tabs
 */
export class Tabs extends Component {
  static propTypes = {
    allowedTabs: PropTypes.object.isRequired,
    currentTab: PropTypes.string,
    onTabClick: PropTypes.func.isRequired
  };

  render() {
    const { currentTab, onTabClick, allowedTabs } = this.props;

    // If the user is not allowed to see any of the tabs then return null
    if (Object.values(allowedTabs).filter(value => !!value).length <= 1) {
      return null;
    }

    return (
      <div className="extraction-details__tab-section">
        <div className="extraction-details__tabs">
          {/* Learning tab */}
          {allowedTabs[TABS.LEARNING] && (
            <Button
              className={classNames(
                'extraction-details__tabs-button',
                currentTab === TABS.LEARNING && 'extraction-details__tabs-button--selected'
              )}
              onClick={() => onTabClick(TABS.LEARNING)}
            >
              <FormattedMessage id="quick-study.extraction-field-details.tab.learning" />
            </Button>
          )}
          {/* Groups tab */}
          {allowedTabs[TABS.GROUPS] && (
            <Button
              className={classNames(
                'extraction-details__tabs-button',
                currentTab === TABS.GROUPS && 'extraction-details__tabs-button--selected'
              )}
              onClick={() => onTabClick(TABS.GROUPS)}
            >
              <FormattedMessage id="quick-study.extraction-field-details.tab.groups" />
            </Button>
          )}
          {/* Measures tab */}
          {allowedTabs[TABS.MEASURES] && (
            <Button
              className={classNames(
                'extraction-details__tabs-button',
                currentTab === TABS.MEASURES && 'extraction-details__tabs-button--selected'
              )}
              onClick={() => onTabClick(TABS.MEASURES)}
            >
              <FormattedMessage id="quick-study.extraction-field-details.tab.measures" />
            </Button>
          )}
        </div>
        <div className="extraction-details__tab-section-history">
          {/* History tab */}
          {allowedTabs[TABS.HISTORY] && (
            <Button
              size="icon"
              className={classNames(
                'btn btn-no-margin toolbar__icon-button icon-button',
                currentTab === TABS.HISTORY && 'toolbar__icon-button--active'
              )}
              data-tip
              data-for="quick-study.toolbar.history.tooltip"
              onClick={() => onTabClick(TABS.HISTORY)}
            >
              <Icon className="toolbar__inactive-icon" name="backup" width={20} />
              <Icon className="toolbar__active-icon" name="backup" width={20} />
              <ReactTooltip id="quick-study.toolbar.history.tooltip" effect="solid" place="bottom">
                <FormattedMessage id="quick-study.toolbar.history.tooltip" />
              </ReactTooltip>
            </Button>
          )}
        </div>
      </div>
    );
  }
}

/**
 * Extraction field details main component
 */
export class ExtractionFieldDetails extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    extractionFieldId: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    reloadExtractionFields: PropTypes.func.isRequired
  };

  state = {
    currentTab: null,
    field: null,
    fieldDescription: '',
    fieldName: '',
    training: null
  };

  componentDidMount() {
    this.componentDidUpdate({}, {});

    socket.join(rooms.extractionFieldGroups());

    // XF updates
    socket.on('extractionfield.history.update', this.onTrainingHistoryUpdated);
    socket.on('extractionfield.training.revert', this.onTrainingHistoryUpdated);
    socket.on('extractionfield.publish', this.onIsPublishedUpdate);
    socket.on('extractionfield.autoextract.update', this.onAutoextractUpdate);
    socket.on('extractionfield.updated', this.onFieldUpdated);
    socket.on('extractionfield.removed', this.onFieldDeleted);

    // XF user updates
    socket.on('extractionfield.user.added', this.onUserAdded);
    socket.on('extractionfield.user.updated', this.onUserUpdated);
    socket.on('extractionfield.user.removed', this.onUserRemoved);

    // XF group updates
    socket.on('extractionfield.group.added', this.onGroupAdded);
    socket.on('extractionfield.group.removed', this.onGroupRemoved);
    socket.on('extractionfieldgroup.group.added', this.onGroupAddedToGroup);
    socket.on('extractionfieldGroup.user.added', this.onGroupSharedWithUser);
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, extractionFieldId, groupId, reloadExtractionFields } = this.props;
    const { field, currentTab } = this.state;

    // When selecting a different field from the sidebar
    // Reload the data, leave the current room and join the new room
    if (prevProps.extractionFieldId !== extractionFieldId) {
      this.getExtractionFieldDetails();
      this.getHistory();
      socket.leave(rooms.extractionField(prevProps.extractionFieldId));
      socket.join(rooms.extractionField(extractionFieldId));
      this.setCurrentTab(TABS.LEARNING);
    }

    // When a field is loaded, check if we have access to the current tab for that field
    if (prevState.field !== field && field && !this.allowedTabs[currentTab]) {
      const allowedTabs = Object.entries(this.allowedTabs).find(([key, value]) => !!value);
      if (allowedTabs && allowedTabs.length) {
        // Set the current tab to the first allowed tab
        this.setState({ currentTab: allowedTabs[0] });
      } else {
        // If the user is not allowed to see any tabs, push them back to the list page
        history.push(`/region/${getCurrentRegion()}/groups/${groupId}/extraction-fields`);
        reloadExtractionFields();
      }
    }
  }

  componentWillUnmount() {
    const { extractionFieldId } = this.props;

    socket.leave(rooms.extractionField(extractionFieldId));
    socket.leave(rooms.extractionFieldGroups());

    socket.off('extractionfield.history.update', this.onTrainingHistoryUpdated);
    socket.off('extractionfield.training.revert', this.onTrainingHistoryUpdated);
    socket.off('extractionfield.publish', this.onIsPublishedUpdate);
    socket.off('extractionfield.autoextract.update', this.onAutoextractUpdate);
    socket.off('extractionfield.updated', this.onFieldUpdated);
    socket.off('extractionfield.removed', this.onFieldDeleted);
    socket.off('extractionfield.user.added', this.onUserAdded);
    socket.off('extractionfield.user.updated', this.onUserUpdated);
    socket.off('extractionfield.user.removed', this.onUserRemoved);
    socket.off('extractionfield.group.added', this.onGroupAdded);
    socket.off('extractionfield.group.removed', this.onGroupRemoved);
    socket.off('extractionfieldgroup.group.added', this.onGroupAddedToGroup);
  }

  /**
   * Change the currently visible tab
   */
  setCurrentTab = tab => {
    this.setState({
      currentTab: tab
    });
  };

  /**
   * Determine which tabs the user is allowed to see
   */
  get allowedTabs() {
    const { field } = this.state;
    return {
      LEARNING: Permissions.Global.ExtractionField.canViewLearningTab(field),
      GROUPS: Permissions.Global.ExtractionField.canViewGroupsAndUsersTab(field),
      MEASURES: Permissions.Global.ExtractionField.canViewMeasuresTab(field),
      HISTORY: Permissions.Global.ExtractionField.canViewHistoryTab(field)
    };
  }

  /**
   * Get the data for this page
   */
  getExtractionFieldDetails = () => {
    const { history, groupId } = this.props;

    getExtractionFieldDetails(this.props.extractionFieldId)
      .then(response => {
        this.setState({
          field: {
            ...response.data,
            groups: sortByKey('extractionFieldGroupName', response.data.groups),
            users: sortByKey('lastName', response.data.users)
          },
          // Save the name and description separately because they can be edited by the user
          fieldName: response.data.extractionFieldName,
          fieldDescription: response.data.extractionFieldDescription
        });
      })
      .catch(error => {
        // If something goes wrong, push back to the list page
        if (hasError(error, ERROR_CODES.XF_INVALID)) {
          history.push(`/region/${getCurrentRegion()}/groups/${groupId}/extraction-fields`);
        }
      });
  };

  /**
   * Get the extraction field training history
   */
  getHistory = () => {
    const { extractionFieldId } = this.props;
    getExtractionFieldHistory(extractionFieldId).then(response => {
      this.setState({ training: response.data });
    });
  };

  /**
   * Delete the extraction field
   */
  deleteExtractionField = () => {
    const { history, groupId } = this.props;
    const { field } = this.state;
    return deleteExtractionField(field.extractionFieldId).then(() => {
      history.push(`/region/${getCurrentRegion()}/groups/${groupId}/extraction-fields`);
    });
  };

  /**
   * Update the field name and/or description
   */
  updateExtractionField = () => {
    const { field, fieldName, fieldDescription } = this.state;
    updateExtractionField({
      extractionFieldId: field.extractionFieldId,
      extractionFieldName: fieldName,
      extractionFieldDescription: fieldDescription
    }).catch(() => {
      this.setState({
        fieldName: field.extractionFieldName,
        fieldDescription: field.extractionFieldDescription
      });
    });
  };

  /**
   * Publish or unpublish the extraction field
   */
  publishExtractionField = () => {
    const { field } = this.state;

    publishExtractionField({
      extractionFieldId: field.extractionFieldId,
      publish: field.accessTypeId !== ExtractionAccessTypeIds.public
    });

    this.setState({
      field: {
        ...field,
        accessTypeId:
          field.accessTypeId === ExtractionAccessTypeIds.public
            ? ExtractionAccessTypeIds.private
            : ExtractionAccessTypeIds.public
      }
    });
  };

  /**
   * Submit the export modal
   */
  submitExport = () => {
    const { field } = this.state;
    return createExtractionFieldExport([field.extractionFieldId]);
  };

  /**
   * Make the extraction field model permanent
   */
  makeExtractionModelPermanent = () => {
    const { field } = this.state;

    makeExtractionModelPermanent({ extractionFieldId: field.extractionFieldId }).then(() => {
      this.getExtractionFieldDetails();
      this.getHistory();
      this.setCurrentTab(TABS.HISTORY);
    });
  };

  /**
   * Handle a field updated event
   * Update the groups, users, name and description
   */
  onFieldUpdated = response => {
    const { field } = this.state;
    const updatedField = response.data;
    if (field.extractionFieldId === updatedField.extractionFieldId) {
      this.setState({
        field: {
          ...field,
          groups: sortByKey('response.data', field.groups),
          users: sortByKey('lastName', field.users),
          extractionFieldName: updatedField.extractionFieldName,
          extractionFieldDescription: updatedField.extractionFieldDescription
        },
        fieldName: field.extractionFieldName,
        fieldDescription: field.extractionFieldDescription
      });
    }
  };

  /**
   * Handle a field deleted event
   */
  onFieldDeleted = response => {
    const { history, groupId } = this.props;
    const { field } = this.state;
    const deletedFieldId = response.data.extractionFieldId;

    // Only respond if the event is for this field
    if (field.extractionFieldId === deletedFieldId) {
      history.push(`/region/${getCurrentRegion()}/groups/${groupId}/extraction-fields`);
    }
  };

  /**
   * After training the field go to the history tab and refresh the data
   */
  onTrainExtractionField = () => {
    this.setCurrentTab(TABS.HISTORY);
    this.getHistory();
  };

  /**
   * Handle the field name being changed
   */
  onChangeName = fieldName => {
    if (!fieldName) {
      return;
    }

    this.setState(
      {
        fieldName
      },
      this.updateExtractionField
    );
  };

  /**
   * Handle the field description being changed
   */
  onChangeDescription = fieldDescription => {
    if (!fieldDescription) {
      return;
    }

    this.setState(
      {
        fieldDescription
      },
      this.updateExtractionField
    );
  };

  /**
   * Handle a training history update
   */
  onTrainingHistoryUpdated = msg => {
    const { extractionFieldId } = this.props;

    if (msg.room !== rooms.extractionField(extractionFieldId)) {
      return;
    }

    this.getExtractionFieldDetails();
    this.getHistory();
  };

  /**
   * Handle a user added to the field
   */
  onUserAdded = msg => {
    const { field } = this.state;
    const {
      data: { userDetail }
    } = msg;

    if (msg.data.extractionFieldId !== field.extractionFieldId) {
      return;
    }

    this.setState({
      field: {
        ...field,
        users: sortByKey('lastName', [userDetail, ...field.users])
      }
    });
  };

  /**
   * Handle a user updated (usually for role change)
   */
  onUserUpdated = msg => {
    const { field } = this.state;
    const {
      data: { userDetail }
    } = msg;

    if (msg.data.extractionFieldId !== field.extractionFieldId) {
      return;
    }

    this.setState({
      field: {
        ...field,
        users: field.users.map(user => {
          return user.userId === userDetail.userId ? userDetail : user;
        })
      }
    });
  };

  /**
   * Handle a user removed from the field
   */
  onUserRemoved = msg => {
    const { field } = this.state;
    const { data } = msg;

    if (msg.data.extractionFieldId !== field.extractionFieldId) {
      return;
    }

    this.setState({
      field: {
        ...field,
        users: field.users.filter(user => user.userId !== data.userId)
      }
    });
  };

  /**
   * Handle this field being added to a group (group added to field.groups)
   */
  onGroupAdded = msg => {
    const { field } = this.state;
    const { data } = msg;

    if (msg.data.extractionFieldId !== field.extractionFieldId) {
      return;
    }

    this.setState({
      field: {
        ...field,
        groups: sortByKey('extractionFieldGroupName', [
          {
            extractionFieldGroupId: data.extractionFieldGroupId,
            extractionFieldGroupName: data.extractionFieldGroupName
          },
          ...field.groups
        ])
      }
    });
  };

  /**
   * Handle this field being removed from a group (group removed from field.groups)
   */
  onGroupRemoved = msg => {
    const { field } = this.state;
    const { data } = msg;

    if (msg.data.extractionFieldId !== field.extractionFieldId) {
      return;
    }

    this.setState({
      field: {
        ...field,
        groups: field.groups.filter(group => group.extractionFieldGroupId !== data.extractionFieldGroupId)
      }
    });
  };

  /**
   * Handle a group that this field is a member of being added to another group
   */
  onGroupAddedToGroup = msg => {
    const { field } = this.state;
    const { data } = msg;

    const group = data.extractionFieldGroup;
    const sourceGroupId = data.extractionFieldGroupIdSource;

    // Ignore if the source group is not present in field.groups
    if (!field.groups.find(group => group.extractionFieldGroupId === sourceGroupId)) {
      return;
    }

    this.setState({
      field: {
        ...field,
        groups: sortByKey('extractionFieldGroupName', [group, ...field.groups])
      }
    });
  };

  /**
   * Handle a group that this field is a member of being shared with a user
   */
  onGroupSharedWithUser = msg => {
    const { field } = this.state;
    const {
      data: { extractionFieldGroupId }
    } = msg;

    // Ignore if the source group is not present in field.groups
    if (!field.groups.find(group => group.extractionFieldGroupId === extractionFieldGroupId)) {
      return;
    }

    this.getExtractionFieldDetails();
  };

  onAutoextractUpdate = msg => {
    const { field } = this.state;
    const { data } = msg;

    if (msg.data.extractionFieldId !== field.extractionFieldId) {
      return;
    }

    this.setState({
      field: {
        ...field,
        isAutomaticExtraction: data.isAutomaticExtraction
      },
      isAutomaticExtraction: data.isAutomaticExtraction
    });
  };

  /**
   * Handle this field being published or unpublished
   */
  onIsPublishedUpdate = msg => {
    const { extractionFieldId } = this.props;

    if (msg.room !== rooms.extractionField(extractionFieldId)) {
      return;
    }

    this.getExtractionFieldDetails();
  };

  render() {
    const { currentUser, groupId } = this.props;
    const { field, fieldName, fieldDescription, training } = this.state;

    if (!field || currentUser.isLoading || !currentUser.isLoaded) {
      return (
        <div className="quick-study__details extraction-details">
          <Icon className="spinner" name="loader" width={80} />
        </div>
      );
    }

    const toolbarOptions = [];
    if (Permissions.Global.ExtractionField.canDelete(field)) {
      if (!field.isReadOnly) {
        toolbarOptions.push(TOOLBAR_OPTIONS.DELETE);
      }
    }
    if (Permissions.Global.ExtractionField.canEdit()) {
      toolbarOptions.push(TOOLBAR_OPTIONS.EXPORT);
    }

    return (
      <div className="quick-study__details extraction-details">
        <div className="extraction-details__header">
          <div className="extraction-details__title-container">
            <ComplicatedIcon
              disableInteraction
              extractionId={field.fieldId}
              accessTypeId={field.accessTypeId}
              isTrained={field.isTrained}
              isAutomaticExtraction={field.isAutomaticExtraction}
              trainingCount={field.reviewDocumentCount}
              trainingTarget={field.minDocumentsToBeConsideredTrained}
            />
            <h2 className="extraction-details__title">
              {field.extractionFieldId}{' '}
              <EditableText
                value={fieldName}
                onSubmit={this.onChangeName}
                disableEdit={!Permissions.Global.ExtractionField.canRename(field)}
              />
              {Permissions.Global.ExtractionField.canViewVendorTag() ? (
                <div className="extraction-details__vendor-tag">
                  {field.externalSystemId == constants.ExternalSystemIds.ABBY ? (
                    <FormattedMessage id="quick-study.extraction-field-list.vendor.abby" />
                    ) : field.externalSystemId == constants.ExternalSystemIds.KIRA ? (
                      <FormattedMessage id="quick-study.extraction-field-list.vendor.kira" />
                    ) : <FormattedMessage id="quick-study.extraction-field-list.vendor.genai" />  }
                </div>
              ) : null}
            </h2>
          </div>
          <Toolbar
            visibleOptions={toolbarOptions}
            selectedFields={[field]}
            onSubmitDeleteModal={this.deleteExtractionField}
            onSubmitExportModal={this.submitExport}
          />
          <div className="extraction-details__close-icon-button-container icon-button">
            <Link
              className="btn btn-no-margin toolbar__icon-button"
              to={`/region/${getCurrentRegion()}/groups/${groupId}/extraction-fields`}
              data-tip
              data-for="quick-study.toolbar.close.tooltip"
            >
              <Icon className="toolbar__inactive-icon" name="cross" width={20} />
              <Icon className="toolbar__active-icon" name="cross" width={20} />
              <ReactTooltip id="quick-study.toolbar.close.tooltip" effect="solid" place="bottom">
                <FormattedMessage id="quick-study.toolbar.close.tooltip" />
              </ReactTooltip>
            </Link>
          </div>
        </div>
        <Summary
          field={field}
          fieldDescription={fieldDescription}
          onChangeDescription={this.onChangeDescription}
          makeExtractionModelPermanent={this.makeExtractionModelPermanent}
          isAdmin={Permissions.Global.ExtractionField.canEdit()}
        />

        <Tabs currentTab={this.state.currentTab} onTabClick={this.setCurrentTab} allowedTabs={this.allowedTabs} />

        {/* Learning tab */}
        {this.state.currentTab === TABS.LEARNING ? (
          <Learning field={field} onTrainExtractionField={this.onTrainExtractionField} training={training} />
        ) : null}
        {/* Groups and users tab */}
        {this.state.currentTab === TABS.GROUPS ? (
          <GroupsAndUsers
            currentUser={currentUser}
            field={field}
            isAdmin={Permissions.Global.ExtractionField.canEdit(field)}
            onFieldUpdated={this.getExtractionFieldDetails}
            publishExtractionField={this.publishExtractionField}
          />
        ) : null}
        {/* Measures tab */}
        {this.state.currentTab === TABS.MEASURES ? <Measures field={field} /> : null}
        {/* History tab */}
        {this.state.currentTab === TABS.HISTORY ? <History field={field} training={training} /> : null}
      </div>
    );
  }
}

export default withRouter(ExtractionFieldDetails);
