import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import { TOOLBAR_OPTIONS, TOOLBAR_LABELS, TOOLBAR_ICONS } from 'store/file-browser-toolbar-options';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';

import Permissions from 'permissions/permissions';

class CurrentFolderTemplate extends Component {
  static propTypes = {
    templateName: PropTypes.string,
    templateId: PropTypes.number,
    onEditTemplate: PropTypes.func,
    readOnly: PropTypes.bool
  };

  onEdit = () => {
    this.props.onEditTemplate(this.props.templateId);
  };

  render() {
    const { templateName, templateId, onEditTemplate, readOnly } = this.props;
    const editable = Permissions.Project.Folder.canEdit() && !readOnly && !!templateId && !!onEditTemplate;

    if (!templateName) {
      return null;
    }

    return (
      <div
        data-tip
        data-for={`tooltip-${TOOLBAR_LABELS[TOOLBAR_OPTIONS.TEMPLATE_CURRENT_FOLDER_LABEL]}`}
        data-place="bottom"
        className={classNames('current-folder-template', { editable: editable })}
      >
        <span>
          <span className="label">
            <FormattedMessage id={TOOLBAR_LABELS[TOOLBAR_OPTIONS.TEMPLATE_CURRENT_FOLDER_LABEL]} />:{' '}
          </span>
          <span className="template-name" onClick={editable ? this.onEdit : undefined}>
            {templateName}
          </span>
        </span>
        {editable && (
          <Button size="icon" className="btn" onClick={this.onEdit}>
            <Icon
              className="file-browser-toolbar_icon"
              name={TOOLBAR_ICONS[TOOLBAR_OPTIONS.EDIT_CURRENT_FOLDER_TEMPLATE]}
              width={24}
            />
          </Button>
        )}
        <ReactTooltip
          id={`tooltip-${TOOLBAR_LABELS[TOOLBAR_OPTIONS.TEMPLATE_CURRENT_FOLDER_LABEL]}`}
          effect="solid"
          place="bottom"
        >
          <FormattedMessage id={TOOLBAR_LABELS[TOOLBAR_OPTIONS.TEMPLATE_CURRENT_FOLDER_DESCRIPTION]} />
        </ReactTooltip>
      </div>
    );
  }
}

export default CurrentFolderTemplate;
