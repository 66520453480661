import { connect } from 'react-redux';
import { getDocumentResults } from 'store/search/search-results';
import { onSelectCategory } from 'store/search/search-category';
import Tags from 'components/tags/tags';

const mapStateToProps = ({ data: { currentProject } }) => ({
  currentProject
});

const mapDispatchToProps = { getDocumentResults, onSelectCategory };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tags);
