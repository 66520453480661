import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { getIntlKeyForCode } from 'utils/errors';
import Icon from 'components/shared/icon';
import { UPLOAD_STATES } from '../../constants';
import { ERROR_CODES } from '../../../../utils/errors';

class UploadFile extends Component {
  static propTypes = {
    cancelUpload: PropTypes.func.isRequired,
    upload: PropTypes.object.isRequired
  };

  onCancelClick = () => {
    const { cancelUpload, upload } = this.props;

    cancelUpload({ id: upload.id });
  };

  render() {
    const {
      upload: { state, loaded, name, total, errorCode }
    } = this.props;

    const progressPercent = loaded ? (loaded * 100) / total : 0;
    const iconName =
      state === UPLOAD_STATES.CANCELLED || state === UPLOAD_STATES.FAILED ? 'special-error' : 'special-doc';

    const displaySize =
      total > 1024 ** 3
        ? `${(total / 1024 ** 3).toFixed(2)}GB`
        : total > 1024 ** 2
        ? `${(total / 1024 ** 2).toFixed(2)}MB`
        : `${(total / 1024).toFixed(2)}kB`;

    const errorMessage = getIntlKeyForCode(errorCode);

    return (
      <div className={classNames('upload-status-file', `upload-status-file--${state.toLowerCase()}`)}>
        <div className="upload-status-file__info" data-tip data-for="upload-status.error.tooltip">
          <Icon name={iconName} width={16} className="upload-status-file__icon" />
          {errorMessage && errorCode !== ERROR_CODES.DOCUMENT_INFECTED && (
            <ReactTooltip id="upload-status.error.tooltip" effect="solid" place="left">
              <FormattedMessage id={errorMessage} />
            </ReactTooltip>
          )}
          <div className="upload-status-file__name">
            {state === UPLOAD_STATES.FAILED ? (
              errorCode !== ERROR_CODES.DOCUMENT_INFECTED ?
              <span className="upload-status-file__name-span">
                <FormattedMessage id="upload-status.error" values={{ name }} />
              </span>:
              <div data-tip data-for="upload-status.failed.tooltip">
              {errorMessage && errorCode === ERROR_CODES.DOCUMENT_INFECTED && (
                <ReactTooltip id="upload-status.failed.tooltip" effect="solid" place="left">
                  <FormattedMessage id={errorMessage} />
                </ReactTooltip>
              )}
              <span className="upload-status-file__name-span">
              <FormattedMessage id="upload-status.failed" values={{ name }} />
            </span>
            </div>
            ) : null}
            {state === UPLOAD_STATES.CANCELLED ? (
              <span className="upload-status-file__name-span">
                <FormattedMessage id="upload-status.cancelled" values={{ name }} />
              </span>
            ) : null}
            {state === UPLOAD_STATES.COMPLETED ? (
              <span className="upload-status-file__name-span">
                <FormattedMessage id="upload-status.completed" values={{ name }} />
              </span>
            ) : null}
            {state === UPLOAD_STATES.PENDING || state === UPLOAD_STATES.UPLOADING ? (
              <span className="upload-status-file__name-span">{name}</span>
            ) : null}
          </div>
          <div className="upload-status-file__size">&nbsp; {total ? displaySize : ''}</div>
        </div>
        <div className="upload-status-file__progress-bar">
          <div className="upload-status-file__progress-bar__bar" style={{ width: `${progressPercent}%` }} />
        </div>
      </div>
    );
  }
}
export default UploadFile;
