import { connect } from 'react-redux';

import SidePanel from 'components/document-viewer/panels/side-panel';
import { clearUndoStack } from 'store/document-viewer-undo';

const mapStateToProps = ({ data: { currentDocument, currentDocumentLayout, currentProject } }) => ({
  currentDocument,
  currentDocumentLayout,
  currentProject
});

const mapDispatchToProps = {
  clearUndoStack
};

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
