import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component } from 'react';
import Button from 'components/shared/single-click-button';
import { validateGridViewName } from 'store/api';
import { injectIntl, intlShape } from 'react-intl';

class SaveGridView extends Component {
  static propTypes = {
    showSavedGridView: PropTypes.bool,
    closeSaveGridPopup: PropTypes.func,
    applySaveGridPopup: PropTypes.func,
    projectId: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
  };

  state = {
    gridName: '',
    isDuplicate: false,
    isDisabled: true
  };

  handleClickOutside(event) {
    const { closeSaveGridPopup } = this.props;
    closeSaveGridPopup();
  }

  gridViewNameOnChange = name => {
    const { projectId } = this.props;
    this.setState({ gridName: name });

    if (name.length < 1) {
      this.setState({ isDisabled: true });
    } else {
      this.setState({ isDisabled: false });
    }

    validateGridViewName(name, projectId).then(response => {
      const { isNonduplicateName, isValidFormat, isValidLength } = response;
      if (!isNonduplicateName) {
        this.setState({ isDuplicate: true });
      } else {
        this.setState({ isDuplicate: false });
      }
    });
  };
  applySaveGridPopup = () => {
    const { closeSaveGridPopup } = this.props;
    closeSaveGridPopup();
    this.props.applySaveGridPopup(this.state.gridName);
  };



  render() {
    let { showSavedGridView, closeSaveGridPopup, applySaveGridPopup,intl } = this.props;
    let { isDuplicate, isDisabled } = this.state;
    let {placeholder}=<FormattedMessage id="extraction-overview.type-grid-name-here" />
    return showSavedGridView ? (
      <div>
        <div className="save-grid-view popover">
          <div className="save-grid-view__title">
            <FormattedMessage id="extraction-overview.save-grid-view" />
          </div>
          <div className="save-grid-view__text">
            <FormattedMessage id="extraction-overview.grid-name" />
          </div>
          <div className="save-grid-view__textbox">
            <input
              className={`save-grid-view__message-text-box-${isDuplicate ? 'inValid' : ''}`}
              placeholder={intl.formatMessage({ id: 'extraction-overview.type-grid-name-here' })}
              autoFocus
              onChange={e => {
                this.gridViewNameOnChange(e.target.value);
              }}
              type="text"
              maxLength="250"
            />
          </div>
          <div className="save-grid-view__validation">
            {isDuplicate ? <FormattedMessage id="save-grid-view.duplicate-name" /> : null}
          </div>

          <div className="save-grid-view__buttons">
            <Button className="btn btn-primary extraction-overview__header-button" onClick={closeSaveGridPopup}>
              <FormattedMessage id="extraction-overview.saveGrid-cancel" />
            </Button>
            {isDisabled || isDuplicate ? (
              <Button
                className="btn btn-primary extraction-overview__header-button"
                disabled
                onClick={this.applySaveGridPopup}
              >
                <FormattedMessage id="extraction-overview.saveGridapply" />
              </Button>
            ) : (
              <Button className="btn btn-primary extraction-overview__header-button" onClick={this.applySaveGridPopup}>
                <FormattedMessage id="extraction-overview.saveGridapply" />
              </Button>
            )}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default injectIntl(enhanceWithClickOutside(SaveGridView));