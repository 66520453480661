import { connect } from 'react-redux';

import { addOptionToToolbar } from 'store/file-browser-toolbar-options';
import { fileBrowserDeselectAllItems } from 'store/file-browser-selected-items';

import Toolbar from 'components/file-browser/toolbar/toolbar';

const mapStateToProps = ({
  data: { currentProject },
  ui: { fileBrowserToolbarOptions, fileBrowserSelectedItems }
}) => ({ currentProject, fileBrowserToolbarOptions, fileBrowserSelectedItems });

const mapDispatchToProps = {
  addOptionToToolbar,
  fileBrowserDeselectAllItems
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
