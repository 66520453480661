import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import TextArea from 'components/shared/form/textarea';
import { addNotification } from 'store/notifications.js';
import store from 'store/store';
import Constants from 'utils/constants';
import Dropdown from 'components/shared/form/dropdown';
import { saveProjectTemplateDocType } from 'store/api';

class CreateExtraction extends Component {
  static propTypes = {
    createExtraction: PropTypes.func,
    currentTemplateName: PropTypes.string,
    extractionFieldGroups: PropTypes.array.isRequired,
    intl: intlShape.isRequired,
    onBackClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    onClose: PropTypes.func,
    onCreateFail: PropTypes.func,
    onCreateSuccess: PropTypes.func,
    pageViewer: PropTypes.string, // Specifies which page it's being used for reusability purposes
    showConfirmations: PropTypes.bool,
    documentTemplateCategoryId: PropTypes.number,
    customDocTypeName: PropTypes.string,
    projectId: PropTypes.number,
    projectTemplateId: PropTypes.number
  };

  state = {
    description: '',
    extractionFieldGroup: this.props.extractionFieldGroups.length === 1 ? this.props.extractionFieldGroups[0] : null,
    isResponsePending: false,
    isShowingConfirmationModal: false,
    name: '',
    trainingTargetDocumentsNewField: 30,
    customDocType: this.props.customDocTypeName,
    disableCustomDocTypeUpdate: false
  };

  static defaultProps = {
    onClose: () => {},
  };

  static defaultProps = {
    onClose: () => {},
  };

  nameTextChange = value => {
    this.setState({ name: value });
  };

  descriptionTextChange = value => {
    this.setState({ description: value });
  };

  docTypeTextChange = value => {
    this.setState({ customDocType : value})
  }

  showArgusTrainingModal = () => {
    const { trainingTargetDocumentsNewField } = this.state;
    window.postMessage({ showArgusTrainingModal: true, trainingTargetDocumentsNewField }, window.location.href);
  };

  onCreateClick = () => {
    const { showConfirmations } = this.props;
    if (showConfirmations) {
      this.setState({ isShowingConfirmationModal: true });
    } else {
      this.createExtraction();
    }
  };

  createExtraction = () => {
    const { createExtraction, projectId, projectTemplateId, documentTemplateCategoryId, extractionFieldGroups, customDocTypeName } = this.props;
    const { name, description, extractionFieldGroup, customDocType } = this.state;

    this.setState({ isResponsePending: true });
    if(documentTemplateCategoryId === Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING){
      if(customDocType != customDocTypeName)
        saveProjectTemplateDocType(projectId, projectTemplateId, customDocType);
      return createExtraction(name, description, extractionFieldGroups.filter(x => x.displayOrder == 1)[0] ? extractionFieldGroups.filter(x => x.displayOrder == 1)[0].groupId : null)
        .then(this.onCreateSuccess)
        .catch(this.onCreateFail);
    }
    else{
      return createExtraction(name, description, extractionFieldGroup ? extractionFieldGroup.groupId : null)
        .then(this.onCreateSuccess)
        .catch(this.onCreateFail);
    }      
  };

  onCreateSuccess = response => {
    var { onCreateSuccess, showConfirmations } = this.props;

    this.setState(
      {
        isShowingConfirmationModal: false,
        isResponsePending: false,
        trainingTargetDocumentsNewField: response?.data?.field?.minDocumentsToBeConsideredTrained
      },
    );
      showConfirmations && this.showCreateExtractionNotification();
      onCreateSuccess(response.data);
  };

  onCreateFail = error => {
    var { onCreateFail } = this.props;
    this.setState({ isShowingConfirmationModal: false, isResponsePending: false });
    onCreateFail();
  };

  // Closing the component
  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  showCreateExtractionNotification = () => {
    const documentTemplateCategoryId = this.props.documentTemplateCategoryId;
    const message = (
      typeof(documentTemplateCategoryId) !== 'undefined' && documentTemplateCategoryId == 0 ?
      (<div>
        <FormattedMessage id="create-extraction.notification.message" />
        <div>
          <Button onClick={this.showArgusTrainingModal}>
            <FormattedMessage id="create-extraction.notification.link-text" />
          </Button>
        </div>
      </div>):
      <div>
        <FormattedMessage id="create-extraction-abby.notification.message" />
      </div>
    );
    
    store.dispatch(addNotification({ message, clearOnPageChange: true, autohide: 10 }));
  };

  renderToolbar = () => {
    const { onBackClick, onCloseClick } = this.props;
    return (
      <div className="toolbar">
        <Button className="icon-button" onClick={onBackClick}>
          <Icon name="special-back" width={20} height={20} />
        </Button>
        <h2>
          <FormattedMessage id="create-extraction.create.title" />
        </h2>
        <Button className="icon-button" onClick={this.onClose}>
          <Icon name="special-cross-black" width={20} height={20} />
        </Button>
      </div>
    );
  };

  renderConfirmationModal = disableSubmitButton => {
    const { name } = this.state;
    const { currentTemplateName } = this.props;

    return (
      <Modal id="create-extraction-confirmation-modal">
        <div className="modal__content">
          <h3>
            <FormattedMessage id="create-extraction.modal.title" />
          </h3>
          <p>
            <FormattedMessage
              id="create-extraction.modal.question"
              values={{ name, templateName: currentTemplateName }}
            />
          </p>
          <div className="modal__footer">
            <Button className="btn btn-secondary" onClick={() => this.setState({ isShowingConfirmationModal: false })}>
              <FormattedMessage id="create-extraction.modal.cancel-button" />
            </Button>
            <Button className="btn btn-primary" disabled={disableSubmitButton} onClick={this.createExtraction}>
              <FormattedMessage id="create-extraction.modal.confirm-button" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { onBackClick, onCancelClick, onCloseClick, pageViewer, intl, extractionFieldGroups, documentTemplateCategoryId } = this.props;
    const { name, description, isShowingConfirmationModal, isResponsePending, extractionFieldGroup, customDocType, disableCustomDocTypeUpdate } = this.state;

    const validExtractionFieldGroup = documentTemplateCategoryId === Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING && customDocType ? true : extractionFieldGroups.length ? extractionFieldGroup : true;

    const disableSubmitButton =
      isResponsePending ||
      !(description && description.length <= 1000 && name && name.length <= 400 && validExtractionFieldGroup);

    return (
      <div className={`create-extraction ${pageViewer}`}>
        {/* Toolbar for extraction page and project page */}
        {(pageViewer === 'ExtractionPage' || pageViewer === 'ProjectPage') && (
          <div className={`create-extraction ${pageViewer}__toolbar`}>
            <Button className="icon-button" onClick={onBackClick}>
              <Icon name="special-back" width={16} />
            </Button>
            <span>
              <FormattedMessage id="create-extraction.extraction-page.title" />
            </span>
          </div>
        )}

        {/* Toolbar for document page and project page */}
        {pageViewer === 'DocumentPage' && (
          <div className="toolbar">
            <Button className="icon-button" onClick={onBackClick}>
              <Icon name="special-back" width={20} />
            </Button>
            <h2>
              <FormattedMessage id="create-extraction.create.title" />
            </h2>
            <Button className="icon-button" onClick={this.onClose}>
              <Icon name="special-cross-black" width={20} />
            </Button>
          </div>
        )}

        {/* Name field */}
        <div className={`create-extraction ${pageViewer}__wrapper`}>
          <div className={`create-extraction ${pageViewer}__input`}>
            <span className={`create-extraction ${pageViewer}__input-title`}>
              <FormattedMessage id="create-extraction.create.name" />
            </span>
            <FormattedMessage id="create-extraction.create.name-placeholder">
              {placeholder => (
                <TextArea
                  className={`create-extraction ${pageViewer}__name`}
                  id="create-extraction-name"
                  placeholder={placeholder}
                  onChange={value => this.nameTextChange(value)}
                  value={name}
                  maxLength="400"
                />
              )}
            </FormattedMessage>
          </div>

          {/* Description field */}
          <div className={`create-extraction ${pageViewer}__input`}>
            <span className={`create-extraction ${pageViewer}__input-title`}>
              <FormattedMessage id="create-extraction.create.definition" />
            </span>
            <FormattedMessage id="create-extraction.create.definition-placeholder">
              {placeholder => (
                <TextArea
                  className={`create-extraction ${pageViewer}__description`}
                  id="create-extraction-description"
                  placeholder={placeholder}
                  onChange={value => this.descriptionTextChange(value)}
                  value={description}
                  maxLength="1000"
                />
              )}
            </FormattedMessage>
          </div>

          {/* EF group dropdown  */}
          {extractionFieldGroups.length ? (
            <div className={`create-extraction ${pageViewer}__input`}>
              <span className={`create-extraction ${pageViewer}__input-title`}>
                <FormattedMessage id="create-extraction.create.ef-label" />
              </span>
              {documentTemplateCategoryId && documentTemplateCategoryId == Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING ?
                <FormattedMessage id="create-extraction.create.ef-label">
                {placeholder => (
                  <TextArea
                    className={`create-extraction ${pageViewer}__description`}
                    id="create-extraction.create.ef-label"
                    placeholder={placeholder}
                    onChange={value => this.docTypeTextChange(value)}
                    value={customDocType}
                    maxLength="400"                  
                    disabled={disableCustomDocTypeUpdate}
                  />)} 
                </FormattedMessage>
                :
              <Dropdown
                defaultMessage={intl.formatMessage({ id: 'create-extraction.create.ef-group-placeholder' })}
                onSelection={option => this.setState({ extractionFieldGroup: option })}
                selectedOption={extractionFieldGroup}
                options={extractionFieldGroups}
                renderOption={selectedOption => <span>{selectedOption.groupName}</span>}
              /> }
            </div>
          ) : null}
        </div>

        <div className={`create-extraction ${pageViewer}__footer`}>
          {/* Cancel button */}
          <Button
            className={`create-extraction ${pageViewer}__cancel-button btn btn-secondary`}
            id="create-extraction-cancel"
            onClick={onCancelClick}
          >
            <FormattedMessage id="create-extraction.cancel-button" />
          </Button>

          {/* Create button */}
          <Button
            className={`create-extraction ${pageViewer}__create-button btn btn-primary`}
            id="create-extraction-create"
            disabled={disableSubmitButton}
            onClick={this.onCreateClick}
          >
            <FormattedMessage id="create-extraction.create-button" />
          </Button>
        </div>

        {isShowingConfirmationModal ? this.renderConfirmationModal(disableSubmitButton) : null}
      </div>
    );
  }
}

export default injectIntl(CreateExtraction);
