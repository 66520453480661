import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class EmptyState extends Component {
  static propTypes = {
    children: PropTypes.node,
    customClass: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    img: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
  };

  render() {
    const { children, customClass, description, img, title } = this.props;

    return (
      <div className={classNames('file-browser-empty-state', customClass)}>
        <img className="file-browser-empty-state__img" alt="empty document list" src={img} />
        <h2 className="file-browser-empty-state__title">{title}</h2>
        <p className="file-browser-empty-state__description">{description}</p>
        {children}
      </div>
    );
  }
}

export default EmptyState;
