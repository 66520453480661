import { connect } from 'react-redux';

import { loadCurrentFolder } from 'store/current-folder';
import CreateCompareSidebar from 'components/shared/create-compare-sidebar/create-compare-sidebar';

const mapStateToProps = ({ data: { currentFolder } }) => ({
  currentFolder
});

const mapDispatchToProps = {
  loadCurrentFolder
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompareSidebar);
