import { connect } from 'react-redux';

import { loadTypes } from 'store/project-creation/template-types';

import {
  loadGroups,
  loadGroupExtraction,
  loadTemplateExtraction,
  loadAllExtraction,
  loadProjectTemplates,
  createProjectTemplate,
  selectGroup,
  updateProjectTemplate,
  deleteExtraction,
  moveExtraction,
  addExtraction,
  changeTemplateName,
  selectProjectTemplate,
  clearTemplatesData,
  selectExtraction,
  enableExtractons
} from 'store/project-templates';

import { updateProjectState } from 'store/current-project';
import Templates from 'components/create-template/templates/templates';

const mapStateToProps = ({ data: { projectTemplates, templateTypes } }) => ({
  projectTemplates,
  templateTypes
});

const mapDispatchToProps = {
  loadTypes,
  loadGroups,
  loadGroupExtraction,
  loadTemplateExtraction,
  loadAllExtraction,
  loadProjectTemplates,
  createProjectTemplate,
  selectGroup,
  updateProjectTemplate,
  deleteExtraction,
  moveExtraction,
  addExtraction,
  changeTemplateName,
  selectProjectTemplate,
  clearTemplatesData,
  updateProjectState,
  selectExtraction,
  enableExtractons
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
