import { createAction, createReducer } from 'utils/redux-utils';
import { getMaintenanceBannerMessages } from 'store/api';


export const ACTIONS = {
  MAINTENANCE_BANNERMESSAGES_LOADING: 'argus/ui/MAINTENANCE_BANNERMESSAGES_LOADING',
  MAINTENANCE_BANNERMESSAGES_LOADED: 'argus/ui/MAINTENANCE_BANNERMESSAGES_LOADED',
  MAINTENANCE_BANNERMESSAGES_ERROR: 'argus/ui/MAINTENANCE_BANNERMESSAGES_ERROR'
};

export const maintenanaceBannerMessageLoading = createAction(ACTIONS.MAINTENANCE_BANNERMESSAGES_LOADING);
export const maintenanaceBannerMessageLoaded = createAction(ACTIONS.MAINTENANCE_BANNERMESSAGES_LOADED);
export const maintenanaceBannerMessageError = createAction(ACTIONS.MAINTENANCE_BANNERMESSAGES_ERROR);

export const loadMaintenanceBannerMessage = () => dispatch => {
  dispatch(maintenanaceBannerMessageLoading());
  return getMaintenanceBannerMessages()
    .then(res => {
      dispatch(maintenanaceBannerMessageLoaded(res));
    })
    .catch(err => {
      dispatch(maintenanaceBannerMessageError(err));
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false
};

export default createReducer(INITIAL_STATE, {
  [ACTIONS.MAINTENANCE_BANNERMESSAGES_LOADING]: (state, action) => {
    return {
      isLoading: true,
      isLoaded: false
    };
  },

  [ACTIONS.MAINTENANCE_BANNERMESSAGES_LOADED]: (state, action) => {
    return {
      isLoading: false,
      isLoaded: true,
      data: action.payload.data
    };
  },

  [ACTIONS.MAINTENANCE_BANNERMESSAGES_ERROR]: (state, action) => {
    return {
      isLoading: false,
      isLoaded: false,
      error: action.payload
    };
  }
});
