import { connect } from 'react-redux';

import { deleteItems } from 'store/current-folder';
import DeleteModal from 'components/file-browser/toolbar/delete-modal';

const mapStateToProps = ({ ui: { fileBrowserSelectedItems } }) => ({
  selectedItems: fileBrowserSelectedItems
});

const mapDispatchToProps = {
  deleteItems
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
