import React, { Component, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Permissions from 'permissions/permissions';

import { getCurrentRegion, getHomeRegion } from 'store/api';
import Button from 'components/shared/single-click-button';
import { useLocation } from 'react-router-dom';
import Icon from 'components/shared/icon';
import Scrollbars from '../../shared/scrollbars/scrollbars';
import CONSTANTS from 'utils/constants';
import OutsideClickHandler from 'react-outside-click-handler';

var All_TABS = {
  ALLPROJECTS: 'project-list',
  USERMANAGEMENT: 'user-management',
  PROJECTDETAILS: 'project-details',
  TEAMMEMBERS: 'team-members',
  WORKFLOWS: 'create-template',
  EXTRACTIONFIELDS: 'extractionfield',
  EXTRACTIONOVERVIEW: 'extraction-overview',
  ANALYTICSCHAT: 'analytics',
  VISUALS: 'visuals',
  TAGS: 'tags',
  REVIEWSTATUS: 'review-status',
  COMPARE: 'compare',
  COUNTRYMANAGERDASHBOARD: 'admin-access',
  MANAGEEXTRACTIONFIELDS: 'groups',
  REPORTS: 'reports',
  ROOT: 'folder',
  MAINTENANCEMESSAGE: 'maintenance-messages',
  TRANSLATEDOCUMENT: 'translate-document',
  TRANSLATETEXT: 'translate-text',
  TRANSLATIONQUEUE: 'translation-queue'
};

const NavigationItem = ({ title, to, icon, width, selectionTitle, isMenuExpanded }) => {
  let curTab = All_TABS.ALLPROJECTS;
  let currentTab = All_TABS.ALLPROJECTS;

  const OnMenuSelected = selectionTitle => {
    let location = useLocation();
    var overallPath = location.pathname.split('/');

    currentTab = overallPath[overallPath.length - 1];

    if (currentTab != All_TABS[selectionTitle]) {
      curTab = overallPath[overallPath.length - 2];
      if (curTab != All_TABS[selectionTitle]) {
        curTab = overallPath[overallPath.length - 3];
        if (curTab != All_TABS[selectionTitle]) {
          curTab = overallPath[overallPath.length - 4];
        }
      }
    } else {
      curTab = currentTab;
    }
  };
  return (
    <Link
      to={to}
      onClick={OnMenuSelected(selectionTitle)}
      className={
        curTab == All_TABS[selectionTitle]
          ? 'project-left-menu__menu-navigation-icon project-left-menu__tab-button--selected'
          : 'project-left-menu__menu-navigation-icon project-left-menu__tab-button'
      }
    >
      <Button size="icon" data-tip
         className={
          curTab == All_TABS[selectionTitle]
            ? 'project-left-menu__icon-button--selected'
            : 'project-left-menu__icon-button'
        }
      data-for={`project-left-menu_menu-navigation-item.tooltip.${title}`}>
        <Icon name={`${icon}`} width={width} />
        {!isMenuExpanded ? (
          <ReactTooltip id={`project-left-menu_menu-navigation-item.tooltip.${title}`} effect="solid" place="right">
            <FormattedMessage id={`project-left-menu_menu-navigation-item.tooltip-${title}`} />
          </ReactTooltip>
        ) : null}
      </Button>
      {isMenuExpanded ? (
        <span className="project-left-menu__item-Name">         
          <FormattedMessage id={`project-left-menu_menu-navigation-item.tooltip-${title}`} />
        </span>
      ) : null}
    </Link>
  );
};

const TranslationNavigationItem = ({
  title,
  to,
  icon,
  width,
  selectionTitle,
  selectedTab,
  setSelectedTab,
  isMenuExpanded
}) => {
  const OnMenuSelected = selectionTitle => {
    switch (selectionTitle) {
      case 'TRANSLATEDOCUMENT':
        return setSelectedTab(0);
      case 'TRANSLATETEXT':
        return setSelectedTab(1);
      case 'TRANSLATIONQUEUE':
        return setSelectedTab(2);
    }
  };

  return (
    <Link to={to}
    onClick={() => OnMenuSelected(selectionTitle)}
    className={
      CONSTANTS.TRANSLATION_TABS[selectedTab] == All_TABS[selectionTitle]
        ? `project-left-menu__menu-navigation-icon project-left-menu__translation-Tab-button project-left-menu__translation-Tab-button--selected project-left-menu__translation-left-menu-icon-${icon}`
        : `project-left-menu__menu-navigation-icon project-left-menu__translation-Tab-button project-left-menu__translation-left-menu-icon-${icon}`
    }
    >
      <Button size="icon" data-tip 
      className={
        CONSTANTS.TRANSLATION_TABS[selectedTab] == All_TABS[selectionTitle]
        ? 'project-left-menu__translation-icon-button--selected'
        : 'project-left-menu__translation-icon-button'
       }
      data-for={`translation-left-menu_menu-navigation-item.tooltip.${title}`}>
        <Icon name={`${icon}`} width={width} />
        {!isMenuExpanded ? (
        <ReactTooltip id={`translation-left-menu_menu-navigation-item.tooltip.${title}`} effect="solid" place="right">
          <FormattedMessage id={`translation-left-menu_menu-navigation-item.tooltip-${title}`} />
        </ReactTooltip>):null}
      </Button>
      {isMenuExpanded ? (
        <span className="project-left-menu__translation-Item-Name">
          <FormattedMessage id={`translation-left-menu_menu-navigation-item.tooltip-${title}`} />
        </span>
      ) : null}
    </Link>
  );
};

function ProjectsLeftMenu({ currentProject, selectedTab, setSelectedTab }) {
  let translationMenu = false;

  let [isMenuExpanded, isMenuOpen] = useState(false);

  const projectCreation = currentProject.STATES.Creation();
  const global = currentProject.projectId === null || !currentProject.projectId || projectCreation;
  let location = useLocation();
  if (location.pathname.includes('translation')) {
    translationMenu = true;
  }



  return (
    <div className="project-left-menu__project">
      <div className="project-left-menu__menubar">
        {!isMenuExpanded ? (
          <Button className="project-left-menu__menuIcon icon-button" onClick={() => isMenuOpen(true)} size="icon">
            <Icon name="project-menu" width={18} />
          </Button>
        ) : (
            <OutsideClickHandler
            >
          <Button className="project-left-menu__menuIcon icon-button" 
          onClick={() => isMenuOpen(false)}
          size="icon">
            <Icon name="special-cross-black" width={18} />
          </Button>
          </OutsideClickHandler>
        )}
      </div>
      <Scrollbars
        autoHide
        className={`project-left-menu__menu-border project-left-menu__menu${
          !isMenuExpanded ? '-collapsed' : '-expanded'
        }`}
      >
        <OutsideClickHandler
          onOutsideClick={() => {            
            isMenuOpen(false);
          }}
        >
          {!translationMenu ? (
            <div className={`project-left-menu__selector${global ? '-global' : '-project'}`}>
              <NavigationItem
                to={`/project-list`}
                title="ALLPROJECTS"
                selectionTitle="ALLPROJECTS"
                icon="satchel-0"
                width={24}
                isMenuExpanded={isMenuExpanded}
              />
              {!global && Permissions.Project.Settings.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/project-details`}
                  title="PROJDETAILS"
                  selectionTitle="PROJECTDETAILS"
                  icon="preferences-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.Settings.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/team-members`}
                  title="TEAMMEMBERS"
                  selectionTitle="TEAMMEMBERS"
                  icon="users-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.Template.canCreate() && !Permissions.Project._States.isReadOnly() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/create-template`}
                  title="WORKFLOWS"
                  selectionTitle="WORKFLOWS"
                  icon="plot-graph-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.Document.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/folder/root`}
                  title="ROOT"
                  selectionTitle="ROOT"
                  icon="documents2-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.ExtractionField.canViewTab() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/extractionfield`}
                  title="EXTRACTIONFIELDS"
                  selectionTitle="EXTRACTIONFIELDS"
                  icon="input-field-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.Analytics.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/extraction-overview`}
                  title="EXTRACTIONOVERVIEW"
                  selectionTitle="EXTRACTIONOVERVIEW"
                  icon="document-in-hand-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.Analytics.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/analytics`}
                  title="ANALYTICSCHAT"
                  selectionTitle="ANALYTICSCHAT"
                  icon="bar-chart2-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.Visualization.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/visuals`}
                  title="VISUALS"
                  selectionTitle="VISUALS"
                  icon="visible-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.Tags.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/tags`}
                  title="TAGS"
                  selectionTitle="TAGS"
                  icon="tag-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.ReviewStatus.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/review-status`}
                  title="REVIEWSTATUS"
                  selectionTitle="REVIEWSTATUS"
                  icon="pencil-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global && Permissions.Project.Comparison.canView() ? (
                <NavigationItem
                  to={`/region/${getCurrentRegion()}/project/${currentProject.projectId}/compare`}
                  title="COMPARE"
                  selectionTitle="COMPARE"
                  icon="compare-documents-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {!global ? <div className="project-left-menu__collapsed-menu-seperator" /> : null}
              {Permissions.Global.canViewAdminAccess() ? (
                <NavigationItem
                  to={`/admin-access`}
                  title={
                    Permissions.Global.canHaveGlobalAdminAccess() ? 'GLOBALMANAGERDASHBOARD' : 'COUNTRYMANAGERDASHBOARD'
                  }
                  selectionTitle="COUNTRYMANAGERDASHBOARD"
                  icon="globe-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {Permissions.Global.User.canViewUserManagementLink() ? (
                <NavigationItem
                  to={`/region/${getHomeRegion()}/user-management`}
                  title="USERMANAGEMENT"
                  selectionTitle="USERMANAGEMENT"
                  icon="edit-profile-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {Permissions.Global.ExtractionField.canViewQuickStudyPageLink() ? (
                <NavigationItem
                  to={`/region/${getHomeRegion()}/groups`}
                  title="MANAGEEXTRACTIONFIELDS"
                  selectionTitle="MANAGEEXTRACTIONFIELDS"
                  icon="route-3-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
              {Permissions.Global.canViewReports() ? (
                <NavigationItem
                  to={`/region/${getHomeRegion()}/reports`}
                  title="REPORTS"
                  selectionTitle="REPORTS"
                  icon="document1-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}

              {Permissions.Global.User.canViewMaintenanceMessagesLink() ? (
                <NavigationItem
                  to={`/maintenance-messages`}
                  title="MAINTENANCEMESSAGE"
                  selectionTitle="MAINTENANCEMESSAGE"
                  icon="commenting-0"
                  width={24}
                  isMenuExpanded={isMenuExpanded}
                />
              ) : null}
            </div>
          ) : (
            <div className="project-left-menu__selector-global">
              <TranslationNavigationItem
                to="translation#translate-document"
                title="TRANSLATEDOCUMENT"
                selectionTitle="TRANSLATEDOCUMENT"
                icon="file-doc"
                width={18}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                location={location}
                isMenuExpanded={isMenuExpanded}
              />
              <TranslationNavigationItem
                to="translation#translate-text"
                title="TRANSLATETEXT"
                selectionTitle="TRANSLATETEXT"
                icon="document1-0"
                width={28}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                location={location}
                isMenuExpanded={isMenuExpanded}
              />
              <TranslationNavigationItem
                to="translation#translation-queue"
                title="TRANSLATIONQUEUE"
                selectionTitle="TRANSLATIONQUEUE"
                icon="documents"
                width={20}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                location={location}
                isMenuExpanded={isMenuExpanded}
              />
            </div>
          )}
        </OutsideClickHandler>
      </Scrollbars>
    </div>
  );
  // }
}

export default ProjectsLeftMenu;
