import { withRouter } from 'react-router';
import Header from 'containers/header/header';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import moment from 'moment';

import * as api from 'store/api';
import AnalyticsChart from 'containers/analytics/analytics-chart-container';
import AnalyticsHeader from 'components/analytics/analytics-header';
import dateUtil from 'utils/dateUtil';
import EmptyState from 'components/file-browser/file-list/empty-state';
import ExportSidebarContainer from 'containers/shared/export-sidebar-container';
import LoadingOverlay from 'components/shared/loading/light';

import emptyStateImage from 'images/empty_state_comp_01.svg';
import emptySearchImage from 'images/empty_state_no_result_found.svg';

class AnalyticsPage extends Component {
  static propTypes = {
    currentProject: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    projectId: PropTypes.string.isRequired,
    search: PropTypes.object.isRequired
  };

  state = {
    docReports: undefined,
    extractionsByTemplate: undefined,
    isExportSidebarShown: false,
    isSearching: false,
    loading: true,
    selectedTemplate: undefined,
    sortAscending: true,
    showDocsWithNoRecords: false,
    templates: undefined,
    visibleExtractionIdsSet: new Set()
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    Promise.all([this.loadAnalyticsReport(), this.loadTemplates()])
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(error => {
        console.error('Failed to load analytics chart data', error);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedTemplate, extractionsByTemplate } = this.state;
    if (
      selectedTemplate &&
      extractionsByTemplate &&
      (prevState.selectedTemplate !== selectedTemplate || prevState.extractionsByTemplate !== extractionsByTemplate)
    ) {
      const currentVisibleExtractions = this.getCurrentlyVisibleExtractions();
      this.setVisibleExtractionIdsSet(
        currentVisibleExtractions
          ? new Set(currentVisibleExtractions)
          : this.getAllExtractionIdsSet(selectedTemplate, extractionsByTemplate)
      );
      const sortAscending = this.getSortOrder();
      this.setSortOrder(sortAscending === undefined ? true : sortAscending);
    }
  }

  getCurrentlyVisibleExtractions = () => {
    const visibleExtractionIdsSet = this.getVisibleExtractionIdsSet();
    const currentExtractions = this.getExtractions();
    return (visibleExtractionIdsSet
      ? currentExtractions.filter(extraction => {
          const storedEf = visibleExtractionIdsSet.filter(
            ({ extractionFieldId }) => extractionFieldId === extraction.extractionFieldId
          )[0];
          return storedEf ? storedEf.isVisible : true;
        })
      : visibleExtractionIdsSet
    ).map(({ extractionFieldId }) => extractionFieldId);
  };

  loadAnalyticsReport = () => {
    const { projectId } = this.props;
    return api.getAnalyticsReport({ projectId }).then(docReports => {
      this.setState({ docReports });
    });
  };

  loadTemplates = () => {
    const { projectId } = this.props;
    return api.getTemplates({ projectId }).then(this.onLoadTemplatesSuccess);
  };

  onLoadTemplatesSuccess = templateData => {
    const { projectId } = this.props;
    const templates = templateData.data;
    let storedTemplate = Number(sessionStorage.getItem('selected-template'));
    let storedTemplateProject = Number(sessionStorage.getItem('selected-template-projectId')); 
    var currentTemplate = templates[0];;
    if(storedTemplate && storedTemplateProject && projectId == storedTemplateProject)
    {
      currentTemplate = templates.find(template => template.projectTemplateId == storedTemplate);
    }
    this.setState({ templates, selectedTemplate: currentTemplate });
    return this.loadTemplateDetails(templates).then(this.onLoadTemplateDetailsSuccess);
  };

  loadTemplateDetails = templates => {
    const { projectId } = this.props;
    return Promise.all(templates.map(t => api.getProjectTemplateExtractionFields(projectId, t.projectTemplateId)));
  };

  onLoadTemplateDetailsSuccess = templateDetailsResponses => {
    const extractionsByTemplate = new Map();
    // Clean up responses by their corresponding template id and XFs
    templateDetailsResponses.forEach(({ data: templateDetails }) => {
      const extractions = [];
      extractionsByTemplate.set(templateDetails.templates[0].projectTemplateId, extractions);
      templateDetails.fields.forEach(extraction => extractions.push(extraction));
    });
    this.setState({ extractionsByTemplate });
  };

  selectTemplate = selectedTemplate => {
    const {projectId} = this.props;
    sessionStorage.setItem('selected-template',selectedTemplate.projectTemplateId);
    sessionStorage.setItem('selected-template-name',selectedTemplate.name);
    sessionStorage.setItem('selected-template-projectId',projectId);
    this.setState({ selectedTemplate });
  };

  getAllExtractionIdsSet = (selectedTemplate, extractionsByTemplate) => {
    const extractionIds = extractionsByTemplate.get(selectedTemplate.projectTemplateId).map(e => e.extractionFieldId);
    return new Set(extractionIds);
  };

  getVisibleExtractionIdsSet = () => {
    const { currentProject } = this.props;
    const { selectedTemplate } = this.state;
    const { projectTemplateId } = selectedTemplate;
    const localStoreResult = sessionStorage.getItem('analyticsColumnOptions');
    const analyticsColumnOptions = JSON.parse(!!localStoreResult ? localStoreResult : 'null') || {};
    const visibleExtractionsByTemplateId = analyticsColumnOptions[currentProject.projectKey] || {};
    return visibleExtractionsByTemplateId[projectTemplateId] || [];
  };

  setVisibleExtractionIdsSet = visibleExtractionIdsSet => {
    const { currentProject } = this.props;
    const { selectedTemplate } = this.state;
    if (typeof selectedTemplate == 'undefined'){return;}
    const { projectTemplateId } = selectedTemplate;

    const extractions = this.getExtractions().map(({ extractionFieldId }) => {
      return {
        extractionFieldId,
        isVisible: visibleExtractionIdsSet.size ? visibleExtractionIdsSet.has(extractionFieldId) : true
      };
    });
    const localStoreResult = sessionStorage.getItem('analyticsColumnOptions');
    const analyticsColumnOptions = JSON.parse(!!localStoreResult ? localStoreResult : 'null') || {};
    const visibleExtractionsByTemplateId = analyticsColumnOptions[currentProject.projectKey] || {};
    visibleExtractionsByTemplateId[projectTemplateId] = extractions;
    analyticsColumnOptions[currentProject.projectKey] = visibleExtractionsByTemplateId;
    sessionStorage.setItem('analyticsColumnOptions', JSON.stringify(analyticsColumnOptions));

    const currentVisibleExtractions = extractions
      .filter(({ isVisible }) => isVisible)
      .map(({ extractionFieldId }) => extractionFieldId);

    this.setState({
      visibleExtractionIdsSet: new Set(currentVisibleExtractions)
    });
  };

  getSortOrder() {
    const { currentProject } = this.props;
    const { selectedTemplate } = this.state;
    if( typeof selectedTemplate == 'undefined'){return;}
    const { projectTemplateId } = selectedTemplate;

    const analyticsSortOrder = JSON.parse(sessionStorage.getItem('analyticsSortOrder')) || {};
    const sortOrderByTemplateId = analyticsSortOrder[currentProject.projectKey] || {};
    return sortOrderByTemplateId[projectTemplateId];
  }

  setSortOrder = sortAscending => {
    const { currentProject } = this.props;
    const { selectedTemplate } = this.state;
    if( typeof selectedTemplate == 'undefined'){return;}
    const { projectTemplateId } = selectedTemplate;

    const analyticsSortOrder = JSON.parse(sessionStorage.getItem('analyticsSortOrder')) || {};
    const sortOrderByTemplateId = analyticsSortOrder[currentProject.projectKey] || {};
    sortOrderByTemplateId[projectTemplateId] = sortAscending;
    analyticsSortOrder[currentProject.projectKey] = sortOrderByTemplateId;
    sessionStorage.setItem('analyticsSortOrder', JSON.stringify(analyticsSortOrder));

    this.setState({ sortAscending });
  };

  onExportClick = () => {
    const { currentProject, projectId, search, intl } = this.props;
    const { extractionsByTemplate, visibleExtractionIdsSet, selectedTemplate, sortAscending } = this.state;
    if(typeof selectedTemplate == 'undefined'){
      return;
    }

    const hiddenExtractionFields = extractionsByTemplate
      .get(selectedTemplate.projectTemplateId)
      .filter(xf => !visibleExtractionIdsSet.has(xf.extractionFieldId))
      .map(xf => xf.extractionFieldId);

    const currentDate = moment();

    this.setState({ isExportProcessing: true });

    // Payload to send when we're exporting the XFs
    const payload = {
      projectId,
      exportOptions: {
        exportType: 4,
        exportFileName: `${currentProject.projectName}_${intl.formatMessage({
          id: 'analytics.chart.fileName'
        })}_${selectedTemplate.name}__${dateUtil.formatTimestamp(currentDate)}`,
        exportOption: 0,
        templates: [{ projectTemplateId: selectedTemplate.projectTemplateId}],
        exportAnalyticsReport: {
          extractionFieldListIds: hiddenExtractionFields,
          sortingOrder: sortAscending ? 2 : 3,
          isNonWorkingExtactionField: false,
          documentFilter: search.query
        }
      }
    };

    api.createExport(payload).then(() => {
      this.setState({ isExportSidebarShown: true });
    });
  };

  closeExportSidebar = () => {
    this.setState({ isExportSidebarShown: false });
  };

  onSearch = () => {
    const { search } = this.props;
    if (search.query.length > 0) {
      this.setState({ isSearching: true });
    }
  };

  getExtractions = () => {
    const { extractionsByTemplate, selectedTemplate } = this.state;
    if( typeof selectedTemplate == 'undefined'){return;}

    return extractionsByTemplate && selectedTemplate
      ? extractionsByTemplate.get(selectedTemplate.projectTemplateId)
      : [];
  };

  /* If the showDocsWithNoRecords flag is on
   *  Documents should be displayed if any of the following:
   *  - Doesn't have extraction field records at all.
   *  - Doesn't have extraction field records for the Extraction fields
   *  that are displayed in the chart.
   */
  shouldDisplayDocument = (doc, filteredExtractions) => {
    const hasExtractionRecords = doc.extractionRecords;
    if (hasExtractionRecords) {
      const hasVisibleEFR = filteredExtractions.some(ef => {
        const docHasEF = doc.extractionRecords.hasOwnProperty(ef.id);
        const docHasEFR = docHasEF && doc.extractionRecords[ef.id] > 0;
        return docHasEFR;
      });
      return !hasVisibleEFR;
    }
    return !hasExtractionRecords;
  };

  getFilteredDocReports = filteredExtractions => {
    const { docReports, selectedTemplate, sortAscending, isSearching, showDocsWithNoRecords } = this.state;
    if( typeof selectedTemplate == 'undefined'){return;}

    // When a user selects a different template we have to filter the documents
    return (docReports || [])
      .filter(doc => {
        return (
          (selectedTemplate ? doc.projectTemplateId === selectedTemplate.projectTemplateId : true) &&
          (showDocsWithNoRecords ? this.shouldDisplayDocument(doc, filteredExtractions) : true)
        );
      })
      .filter(doc => {
        return isSearching
          ? doc.name
              .toLowerCase()
              .trim()
              .includes(this.props.search.query.toLowerCase().trim())
          : true;
      })
      .sort(function(a, b) {
        let reverseMultiplier = sortAscending ? 1 : -1;
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase()) * reverseMultiplier;
      });
  };

  onShowDocsWithNoRecords = (name, checked) => {
    this.setState({ showDocsWithNoRecords: checked === 'checked' });
  };

  render() {
    const { currentProject, projectId } = this.props;

    const {
      isExportSidebarShown,
      isSearching,
      loading,
      selectedTemplate,
      templates,
      visibleExtractionIdsSet,
      showDocsWithNoRecords
    } = this.state;

    const extractions = this.getExtractions();
    const filteredExtractions = extractions?.filter(e => visibleExtractionIdsSet.has(e.extractionFieldId));
    const filteredDocReports = this.getFilteredDocReports(filteredExtractions);

    let content;

    if (loading) {
      content = null;
    } else if (isSearching && !filteredDocReports?.length) {
      content = (
        <div className="analytics-page__empty-state">
          <EmptyState
            title={<FormattedMessage id="search-results.empty-state-title" />}
            description={<FormattedMessage id="search-results.empty-state-description" />}
            img={emptySearchImage}
          />
        </div>
      );
    } else if (!loading && !filteredDocReports?.length) {
      if (showDocsWithNoRecords) {
        content = (
          <div className="analytics-page__empty-state">
            <EmptyState
              title={<FormattedMessage id="analytics.empty-state-title" />}
              description={<FormattedMessage id="analytics.empty-state-description-no-extractions" />}
              img={emptyStateImage}
            />
          </div>
        );
      } else {
        content = (
          <div className="analytics-page__empty-state">
            <EmptyState
              title={<FormattedMessage id="analytics.empty-state-title" />}
              description={<FormattedMessage id="analytics.empty-state-description" />}
              img={emptyStateImage}
            />
          </div>
        );
      }
    } else {
      content = (
        <div className="analytics-page__chart-wrapper">
          <AnalyticsChart
            extractions={filteredExtractions}
            docReports={filteredDocReports}
            projectId={projectId}
            projectTemplateId={selectedTemplate.projectTemplateId}
          />
        </div>
      );
    }

    return (
      <div className="page analytics-page">
        <Header {...this.props} currentPage="global.subheader.extraction" onEnter={this.onSearch} onExit={() => this.setState({ isSearching: false })} />
        {loading && <LoadingOverlay className="analytics__loading" text={false} />}
        {loading && <div style={{ flexGrow: 1 }} />}
        <div className="analytics-page__content">
          {!loading && (
            <AnalyticsHeader
              currentProject={currentProject}
              extractions={extractions}
              onExportClick={this.onExportClick}
              selectedTemplate={selectedTemplate? selectedTemplate :null}
              selectTemplate={this.selectTemplate}
              setSortAscending={this.setSortOrder}
              setVisibleExtractionIdsSet={this.setVisibleExtractionIdsSet}
              templates={templates}
              visibleExtractionIdsSet={visibleExtractionIdsSet}
              onShowDocsWithNoRecords={this.onShowDocsWithNoRecords}
              showDocsWithNoRecords={showDocsWithNoRecords}
            />
          )}

          {content}

          {isExportSidebarShown && (
            <ExportSidebarContainer
              onCloseClick={this.closeExportSidebar}
              projectId={projectId}
              exportType="AnalyticsReport"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(AnalyticsPage));
