import { chain } from 'underscore';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { createComparison, validateComparisonName } from 'store/api';
import { FormattedMessage } from 'react-intl';
import { Radio, RadioGroup } from 'components/shared/form/radio';
import { withRouter } from 'react-router';
import createEmptyStateImage from 'images/empty_state_comp_03.svg';
import Button from 'components/shared/single-click-button';
import ValidatedTextField from 'components/shared/form/validated-text-field';
import { addNotification } from 'store/notifications.js';
import store from 'store/store';

class CompareSidebarCreate extends Component {
  static propTypes = {
    comparedDocumentsList: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired
  };

  state = {
    baseDocument: null,
    comparisonName: '',
    isProcessing: false,
    isFormValid: false,
    isDuplicated: false,
    compareType: 'document'
  };

  componentDidUpdate(prevProps) {
    const { comparedDocumentsList } = this.props;
    const { baseDocument, comparisonName } = this.state;
    // Need to ensure that the chosen baseDocument is still in the list of
    // compared documents.
    if (baseDocument && comparedDocumentsList.length && comparedDocumentsList !== prevProps.comparedDocumentsList) {
      if (
        !chain(comparedDocumentsList)
          .findWhere({ projectItemId: baseDocument.projectItemId })
          .value()
      ) {
        this.setState({
          baseDocument: null,
          comparisonName: this.nameFieldRef.current.state.isDirty ? comparisonName : ''
        });
      }
    }
  }

  onComparisonNameChange = newValue => {
    this.setState({
      isDuplicated: false,
      comparisonName: newValue
    });
  };

  onComparisonNameBlur = event => {
    const { baseDocument, comparisonName } = this.state;

    if (!comparisonName || comparisonName.length === 0) {
      this.setState({
        comparisonName: baseDocument ? baseDocument.itemName : ''
      });
    }
  };

  onBaseDocumentChange = value => {
    const { comparisonName } = this.state;
    this.setState({
      comparisonName: this.nameFieldRef.current.state.isDirty ? comparisonName : value.itemName,
      baseDocument: value
    });
  };

  onSubmit = event => {
    const { comparisonName, baseDocument } = this.state;
    const { comparedDocumentsList, projectId, onSubmit } = this.props;

    event.preventDefault();

    this.setState({ isProcessing: true });
    createComparison({
      baseEntityId: baseDocument.projectItemId,
      comparisonName,
      documentIds: comparedDocumentsList
        .map(document => document.projectItemId)
        .filter(projectItemId => projectItemId !== baseDocument.projectItemId),
      projectId
    })
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.processingEntitiesCount) {
          store.dispatch(
            addNotification({
              message: <FormattedMessage id="compare.comparison-created-contains-processing" />,
              clearOnPageChange: true,
              autohide: 10
            })
          );
        }
        onSubmit(response.data.documentComparisonId);
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  setFormRef = elem => {
    this.formElem = elem;
  };

  nameFieldRef = React.createRef();

  validateForm = () => {
    if (this.formElem) {
      let isFormValid = this.formElem.checkValidity ? this.formElem.checkValidity() : true;
      const { projectId } = this.props;
      const { comparisonName } = this.state;
      if (isFormValid) {
        validateComparisonName({ projectId, comparisonName }).then(response => {
          const { isNonduplicateName, isValidFormat, isValidLength } = response.data;
          const isDuplicated = !isNonduplicateName;
          isFormValid = isFormValid && isValidFormat && isValidLength && isNonduplicateName;
          this.setState({ isFormValid, isDuplicated });
        });
      } else {
        this.setState({ isFormValid });
      }
    }
  };

  render() {
    const { comparedDocumentsList } = this.props;
    const { baseDocument, comparisonName, isProcessing, isFormValid, isDuplicated } = this.state;

    if (!comparedDocumentsList.length) {
      return (
        <div className="compare-sidebar-empty">
          <img className="compare-sidebar-empty__img" alt="no selected documents" src={createEmptyStateImage} />
          <h2 className="compare-sidebar-empty__title">
            <FormattedMessage id="compare-sidebar.empty-selected.title" />
          </h2>
          <p className="compare-sidebar-empty__description">
            <FormattedMessage id="compare-sidebar.empty-selected.description-one" />
            <br />
            <FormattedMessage id="compare-sidebar.empty-selected.description-two" />
          </p>
        </div>
      );
    }

    return (
      <form
        className="comparison-sidebar-form"
        id="comparison-sidebar-form"
        ref={this.setFormRef}
        onSubmit={this.onSubmit}
      >
        <div className="comparison-sidebar-form__instructions">
          <p>
            <FormattedMessage id="compare-sidebar.create-form.instructions" />
          </p>
        </div>
        <ValidatedTextField
          className="comparison-sidebar-form__name-input"
          id="comparison-sidebar-form__name-input"
          debounce={true}
          errors={
            !isFormValid && <FormattedMessage id={`compare-sidebar.form.name-${isDuplicated ? 'error' : 'empty'}`} />
          }
          isValid={!isFormValid && !isDuplicated}
          headingId="guided-template.template-name"
          maxLength="140"
          onChange={this.onComparisonNameChange}
          onValid={this.validateForm}
          value={comparisonName}
          ref={this.nameFieldRef}
        />
        <div className="comparison-sidebar-form__radio-group">
          <RadioGroup name="document-select" selectedValue={baseDocument} onChange={this.onBaseDocumentChange}>
            {comparedDocumentsList.map((document, index) => (
              <div key={index} className="form-field--inline-label comparison-sidebar-form__form-field">
                <Radio
                  className="comparison-sidebar-form__radio"
                  id={`document-select-option-${document.projectItemId}`}
                  value={document}
                />
                <label
                  className="comparison-sidebar-form__radio-label"
                  htmlFor={`document-select-option-${document.projectItemId}`}
                >
                  {document.itemName}
                </label>
              </div>
            ))}
          </RadioGroup>
        </div>
        <div className="comparison-sidebar-form__footer">
          <Button
            type="submit"
            disabled={!baseDocument || !isFormValid || comparedDocumentsList.length <= 1 || isProcessing}
            className="btn btn-primary btn-no-margin"
          >
            <FormattedMessage id="compare-sidebar.form.submit-button" />
          </Button>
        </div>
      </form>
    );
  }
}

export default withRouter(CompareSidebarCreate);
