import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import history from 'utils/history';
import EmptyState from 'components/file-browser/file-list/empty-state';
import emptyAccessDeniedImage from 'images/empty_state_access_denied.svg';
import logoImage from 'images/deloitte_logo_black.svg';
import OmniaLogo from 'images/Omnia.svg';
import SubheaderDropdown from 'components/header//subheader-dropdown/subheader-dropdown';

class Unauthorized extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    intl: PropTypes.any
  };

  componentDidMount() {
    if (this.props.currentUser) {
      history.push('/project');
    }
  }

  componentDidUpdate() {
    if (this.props.currentUser) {
      history.push('/project');
    }
  }

  link = (
    <a
      href="https://deloitteus.service-now.com/com.glideapp.servicecatalog_cat_item_view.do?v=1&sysparm_id=60a79f374f4bb600f7a9cf5d0210c75d&sysparm_link_parent=5d6c1a7c4f263200f7a9cf5d0210c769&sysparm_catalog=e0d08b13c3330100c8b837659bba8fb4&sysparm_catalog_view=catalog_default&sysparm_view=text_search"
      aria-label={this.props.intl.formatMessage({ id: 'error-page.unauthorized.description.aria-label' })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="error-page.unauthorized.description.link-label" />
    </a>
  );

  render() {
   const omniaUrl ='https://omnia.deloitte.com/';

    return (
      <div className="page">
        <div className="header">
          <img src={logoImage} className="header__deloitte-logo" alt="deloitte" />
          <a href={omniaUrl} rel="noopener noreferrer" target="_blank">
            <img src={OmniaLogo} className="header__omnia-logo" alt="Omnia" />
          </a>          
           </div>

        <div className="subheader-container">
          <div className ="header__subheader">
          <div className="header__display-text">
              <span>
                <FormattedMessage id="global.subheader.document-AI" />
                &nbsp;
              </span>
              <span className="header__argus-text">
               (<FormattedMessage id="global.subheader.landing-page" />)
              </span>
            </div>            
          </div>
        </div>
        <EmptyState
          title={<FormattedMessage id="error-page.unauthorized.title" />}
          description={
            <FormattedMessage
              id="error-page.unauthorized.description"
              values={{
                link: this.link
              }}
            />
          }
          img={emptyAccessDeniedImage}
        />
      </div>
    );
  }
}

export default injectIntl(Unauthorized);
