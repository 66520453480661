import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { CurrentLanguage } from 'models/intl/currentLanguage';

export const ACTIONS = {
  CURRENT_LANGUAGE_SET: 'argus/ui/CURRENT_LANGUAGE_SET'
};

export const currentLanguageSet = createAction(ACTIONS.CURRENT_LANGUAGE_SET);

export const setLanguage = language => dispatch => {
  api.setAxiosLanguageHeaders(language.localeCode);
  dispatch(currentLanguageSet(language));
  localStorage.setItem('language', JSON.stringify(language));
  return language;
};

/*
 * Reducer
 */
export const INITIAL_STATE = new CurrentLanguage();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.CURRENT_LANGUAGE_SET]: (state, action) => {
    return state.setLanguage(state, action);
  }
});
