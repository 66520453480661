import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { COLUMN_OPTIONS, DEFAULT_COLUMN_ROOTSHOW, COLUMN_OPTIONS_DOCSHOW, DEFAULT_COLUMN_ROOTSHOWForProjects } from 'store/file-browser-column-options';
import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import {
  getFolderFilterReviewerOptions,
  getFolderFilterAssigneeOptions,
  getFolderFilterTagOptions,
  getFolderFilterTemplateOptions
} from 'store/api';
import { ITEM_TYPES, ITEM_STATUSES } from 'models/project-item';
import Button from 'components/shared/single-click-button';
import Checkbox from 'components/shared/form/checkbox';
import ColumnOptions from 'components/file-browser/file-list/column-options';
import FileListRow from 'components/file-browser/file-list/file-list-row';
import Sorting from 'components/shared/sorting/sorting';
import withDragDropContext from 'utils/dnd-mouse-context';
import DragHelper from 'components/file-browser/file-list/drag-helper';
import constants from 'utils/constants';

/* Not currently used since select all functionality is not implemented */
export const SelectAllPopover = enhanceWithClickOutside(
  class InnerSelectAllPopover extends React.Component {
    static propTypes = {
      onClick: PropTypes.func.isRequired,
      onClose: PropTypes.func.isRequired
    };

    handleClickOutside = () => {
      this.props.onClose();
    };
    render() {
      return (
        <Button className="file-list__check-all-popover popover" onClick={this.props.onClick}>
          Select all files across all pages
        </Button>
      );
    }
  }
);

/*
  The main component for the file list table
*/
class FileList extends Component {
  static propTypes = {
    columnOptions: PropTypes.object,
    currentFolder: PropTypes.object.isRequired,
    deselectAllItems: PropTypes.func.isRequired,
    deselectItem: PropTypes.func.isRequired,
    getColumnOptions: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    invalidate: PropTypes.func,
    isFiltering: PropTypes.func,
    isSearchResultsPage: PropTypes.bool.isRequired,
    loadSearchResults: PropTypes.func,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    mouseDownItem: PropTypes.func.isRequired,
    moveItems: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func,
    onNameClick: PropTypes.func,
    onPageChange: PropTypes.func,
    pagination: PropTypes.object,
    projectId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    renameItem: PropTypes.func.isRequired,
    selectAllItems: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    selectedItems: PropTypes.object.isRequired,
    updateColumnOptions: PropTypes.func.isRequired,
    currentProject: PropTypes.object
  };

  state = {
    expandedItemId: null,
    isSelectAllPopoverOpen: false,
    isDraggingValidItem: false,
    sorting: {
      key: 'name',
      ascending: true
    },
    filtering: {
      key: undefined,
      ids: undefined
    },
    search: {
      key: undefined,
      query: undefined
    }
  };

  constructor() {
    super();
    this.scrollInterval = null;
    this.scrollElem = null;
  }

  componentDidMount() {
    const { getColumnOptions, columnOptions } = this.props;

    if (!columnOptions) {
      getColumnOptions();
    }
  }

  componentDidUpdate = prevProps => {};

  componentWillUnmount() {
    const { deselectAllItems } = this.props;
    deselectAllItems();
  }

  /////////////////////// SORTING FILTERING FUNCTIONS START ///////////////////////
  reloadCurrentFolder() {
    var { onFilterChange, projectId, currentFolder, isSearchResultsPage, loadSearchResults } = this.props;
    var { filtering, sorting, search } = this.state;

    var folderOptions = {
      projectId: projectId,
      folderId: currentFolder.projectItemId === 0 ? 'root' : currentFolder.projectItemId,
      pageNum: 1,
      forceFetch: true
    };

    var filterOptions = {};

    if (sorting && sorting.key) {
      filterOptions.sortBy = sorting.key;
      filterOptions.sortOrder = sorting.ascending ? 'ascending' : 'descending';
    }

    if (filtering && filtering.key) {
      filterOptions.filterBy = filtering.key;
      filterOptions.filterIds = filtering.ids;
    }

    if (search && search.key) {
      filterOptions.filterBy = search.key;
      filterOptions.filterString = search.query;
    }

    return !isSearchResultsPage
      ? onFilterChange(filterOptions)
      : loadSearchResults(Object.assign(folderOptions, filterOptions));
  }

  setSorting = (key, ascending) => {
    var { sorting } = this.state;
    sorting.key = key;
    sorting.ascending = ascending;
    this.setState({ sorting: sorting }, () => this.reloadCurrentFolder());
  };

  setSearch = (key, query) => {
    var { filtering, search } = this.state;
    filtering.key = undefined;
    filtering.ids = undefined;
    search.key = query.length > 0 ? key : undefined;
    search.query = query.length > 0 ? query : undefined;
    this.props.isFiltering(true);
    this.setState({ filtering: filtering, search: search }, () => this.reloadCurrentFolder());
  };

  setFiltering = (key, ids) => {
    var { filtering, search } = this.state;
    filtering.key = ids.length > 0 ? key : undefined;
    filtering.ids = ids.length > 0 ? ids : undefined;
    search.key = undefined;
    search.query = undefined;
    this.props.isFiltering(true);
    this.setState({ filtering: filtering, search: search }, () => this.reloadCurrentFolder());
  };

  getProjectStatus = options => {
    const GROUPED_ITEM_STATUSES = [
      { key: 'uploaded', id: [ITEM_STATUSES.PREUPLOAD, ITEM_STATUSES.UPLOADED] },
      { key: 'processing', id: [ITEM_STATUSES.PROCESSING, ITEM_STATUSES.CONTENTPROCESSINGCOMPLETE] },
      { key: 'complete', id: [ITEM_STATUSES.PROCESSED] },
      { key: 'pending-deletion', id: [ITEM_STATUSES.PENDINGDELETION] },
      { key: 'error-uploading', id: [ITEM_STATUSES.BLOBSTORAGEERROR] },
      { key: 'error-ocr', id: [ITEM_STATUSES.PROCESSINGERROR] },
      { key: 'error-extracting', id: [ITEM_STATUSES.PARTIALLYEXTRACTED, ITEM_STATUSES.ALLEXTRACTIONERROR] }
    ];

    return Promise.resolve({ data: GROUPED_ITEM_STATUSES });
  };
  /////////////////////// SORTING FILTERING FUNCTIONS END ///////////////////////

  onCheckAllChange = (name, checked) => {
    const { selectAllItems, deselectAllItems, currentFolder } = this.props;

    if (checked === 'checked') {
      var map = {};
      currentFolder.children.forEach(child => {
        map[child.projectItemId] = false;
      });

      selectAllItems(map);
    } else {
      deselectAllItems();
    }
  };

  onRowMouseDown = projectItemId => {
    this.props.mouseDownItem(projectItemId);
  };

  setIsDraggingValidItem = isItemValid => {
    this.setState({ isDraggingValidItem: isItemValid });
  };

  moveItems = to => {
    const { projectId, moveItems, invalidate } = this.props;
    const itemIds = this.getSelectedItemIds().filter(projectItemId => projectItemId !== to.projectItemId);

    if (to.itemTypeId !== ITEM_TYPES.FOLDER || !itemIds.length) {
      return;
    }

    moveItems({
      projectId: parseInt(projectId, 10),
      folderId: to.projectItemId,
      itemIds
    })
      .then(() => invalidate())
      .catch(() => invalidate());
  };

  expandItem = itemId => {
    this.setState({
      expandedItemId: itemId
    });
  };

  collapseExpandedRows = itemId => {
    this.setState({
      expandedItemId: null
    });
  };

  getSelectedItemIds = () => {
    const { selectedItems } = this.props;
    return Object.entries(selectedItems)
      .filter(([key, value]) => value)
      .map(([key, value]) => parseInt(key, 10));
  };

  toggleSelectAllPopover = () => {
    this.setState({
      isSelectAllPopoverOpen: !this.state.isSelectAllPopoverOpen
    });
  };

  globalSelectAllItems = () => {
    const { selectAllItems } = this.props;

    selectAllItems();
    this.setState({
      isSelectAllPopoverOpen: false
    });
  };

  // Uses the position of the drag monitor to scroll the container if
  // dragging near the top or the bottom of the list
  scrollContainerIfNeeded = monitor => {
    if (!this.scrollElem) {
      return;
    }

    const thresholdDistance = 25; // Distance from the top or bottom to start scrolling
    const mulitplier = 0.2; // Multiplier to control the scroll speed

    const itemOffset = monitor.getClientOffset();
    const containerRect = this.scrollElem.getBoundingClientRect();

    if (!itemOffset) {
      return;
    }

    const distanceFromTop = itemOffset.y - containerRect.top - thresholdDistance;
    const distanceFromBottom = containerRect.bottom - itemOffset.y - thresholdDistance;

    if (distanceFromTop < 0 && this.scrollElem) {
      this.scrollElem.scrollTop = this.scrollElem.scrollTop - Math.abs(distanceFromTop * mulitplier);
    }

    if (distanceFromBottom < 0 && this.scrollElem) {
      this.scrollElem.scrollTop = this.scrollElem.scrollTop + Math.abs(distanceFromBottom * mulitplier);
    }
  };

  // On drag start, start checking if the container should scroll
  itemDragStart = monitor => {
    this.scrollInterval = setInterval(() => {
      this.scrollContainerIfNeeded(monitor);
    }, 1000 / 60);
  };

  // On drag end, stop checking if the container should scroll
  itemDragEnd = () => {
    clearInterval(this.scrollInterval);
  };

  setScrollRef = elem => {
    this.scrollElem = elem;
  };

  render() {
    const {
      columnOptions,
      currentFolder,
      deselectItem,
      onPageChange,
      pagination,
      projectId,
      renameItem,
      selectedItems,
      selectItem,
      updateColumnOptions,
      onNameClick,
      isSearchResultsPage,
      invalidate,
      readOnly,
      currentProject
    } = this.props;
    const { expandedItemId, sorting, filtering, search } = this.state;
    let defaultRootColumnsToShow = null;
    if(!isSearchResultsPage)
      defaultRootColumnsToShow = currentProject.projectTypeId == constants.ProjectTypes.WORKSPACE_PROJECT ?
        DEFAULT_COLUMN_ROOTSHOW : DEFAULT_COLUMN_ROOTSHOWForProjects;
    if (!Array.isArray(currentFolder.children) || !columnOptions) {
      return null;
    }
           
    const checkAllState = Object.keys(selectedItems).some(id => selectedItems[id])
      ? Object.keys(selectedItems).every(id => selectedItems[id])
        ? 'checked'
        : 'indeterminate'
      : 'unchecked';

    const TABLE_HEADERS = {
      [COLUMN_OPTIONS.NAME]: (
        <FixedHeader className="file-list__th-name" key={COLUMN_OPTIONS.NAME}>
          <div className="file-list__column-title">
            <FormattedMessage id="file-list.heading.name" />
            {isSearchResultsPage ? (
              <Sorting
                sortFunction={ascending => this.setSorting('name', ascending)}
                sortDirection={sorting.key === 'name' ? sorting.ascending : undefined}
              />
            ) : (
              <Sorting
                searchFunction={query => this.setSearch('name', query)}
                searchQuery={search.key === 'name' ? search.query : undefined}
                sortFunction={ascending => this.setSorting('name', ascending)}
                sortDirection={sorting.key === 'name' ? sorting.ascending : undefined}
              />
            )}
          </div>
        </FixedHeader>
      ),
      [COLUMN_OPTIONS.ID]: (
        <FixedHeader className="file-list__th-id" key={COLUMN_OPTIONS.ID}>
          <div className="file-list__column-title">
            <FormattedMessage id="file-list.heading.id" />
          </div>
        </FixedHeader>
      ),
      [COLUMN_OPTIONS.PAGES]: (
        <FixedHeader  className="file-list__th-page" key={COLUMN_OPTIONS.PAGES}>          
          <div className="file-list__column-title">
            <FormattedMessage id="file-list.heading.pages" />
          </div>
        </FixedHeader>
      ),
      [COLUMN_OPTIONS.ASSIGNEES]: (
        <FixedHeader key={COLUMN_OPTIONS.ASSIGNEES}>       
            <div className="file-list__column-title">
              <FormattedMessage id="file-list.heading.assignees" />
              {isSearchResultsPage ? null : (
                <Sorting
                  sortFunction={ascending => this.setSorting('assignees', ascending)}
                  sortDirection={sorting.key === 'assignees' ? sorting.ascending : undefined}
                  filterFunction={ids => this.setFiltering('assignees', ids)}
                  getFilterOptions={() => {
                    return getFolderFilterAssigneeOptions({
                      projectId: projectId,
                      folderId: currentFolder.projectItemId === 0 ? 'root' : currentFolder.projectItemId
                    });
                  }}
                  filters={filtering.key === 'assignees' ? filtering.ids : undefined}
                />
              )}
            </div> 
        </FixedHeader>
      ),
      [COLUMN_OPTIONS.REVIEWERS]: (
        <FixedHeader key={COLUMN_OPTIONS.REVIEWERS}>        
          <div className="file-list__column-title">
            <FormattedMessage id="file-list.heading.reviewers" />
            {isSearchResultsPage ? null : (
              <Sorting
                sortFunction={ascending => this.setSorting('reviewers', ascending)}
                sortDirection={sorting.key === 'reviewers' ? sorting.ascending : undefined}
                filterFunction={ids => this.setFiltering('reviewers', ids)}
                getFilterOptions={() => {
                  return getFolderFilterReviewerOptions({
                    projectId: projectId,
                    folderId: currentFolder.projectItemId === 0 ? 'root' : currentFolder.projectItemId
                  });
                }}
                filters={filtering.key === 'reviewers' ? filtering.ids : undefined}
              />
            )}
          </div>
        </FixedHeader>
      ),
      [COLUMN_OPTIONS.TAGS]: (
        <FixedHeader key={COLUMN_OPTIONS.TAGS}>       
          <div className="file-list__column-title">
            <FormattedMessage id="file-list.heading.tags" />
            {isSearchResultsPage ? null : (
              <Sorting
                sortFunction={ascending => this.setSorting('tags', ascending)}
                sortDirection={sorting.key === 'tags' ? sorting.ascending : undefined}
                filterFunction={ids => this.setFiltering('tags', ids)}
                getFilterOptions={() => {
                  return getFolderFilterTagOptions({
                    projectId: projectId,
                    folderId: currentFolder.projectItemId === 0 ? 'root' : currentFolder.projectItemId
                  });
                }}
                filters={filtering.key === 'tags' ? filtering.ids : undefined}
              />
            )}
          </div>
        </FixedHeader>
      ),
      [COLUMN_OPTIONS.TEMPLATE]: (
        <FixedHeader key={COLUMN_OPTIONS.TEMPLATE}>           
          <div className="file-list__column-title">
            <FormattedMessage id="file-list.heading.template" />
            {isSearchResultsPage ? null : (
              <Sorting
                sortFunction={ascending => this.setSorting('templates', ascending)}
                sortDirection={sorting.key === 'templates' ? sorting.ascending : undefined}
                filterFunction={ids => this.setFiltering('templates', ids)}
                getFilterOptions={() => {
                  return getFolderFilterTemplateOptions({
                    projectId: projectId,
                    folderId: currentFolder.projectItemId === 0 ? 'root' : currentFolder.projectItemId
                  });
                }}
                filters={filtering.key === 'templates' ? filtering.ids : undefined}
              />
            )}
          </div>
        </FixedHeader>
      ),
      [COLUMN_OPTIONS.STATUS]: (
        <FixedHeader key={COLUMN_OPTIONS.STATUS}>          
          <div className="file-list__column-title">
            <div className="file-list__status-icon" />
            <FormattedMessage id="file-list.heading.status" />
            {isSearchResultsPage ? null : (
              <Sorting
                sortFunction={ascending => this.setSorting('status', ascending)}
                sortDirection={sorting.key === 'status' ? sorting.ascending : undefined}
                sortKeyAscending="sorting.ascending"
                sortKeyDescending="sorting.descending"
                filterFunction={ids => this.setFiltering('statuses', ids)}
                getFilterOptions={this.getProjectStatus}
                filters={filtering.key === 'statuses' ? filtering.ids : undefined}
              />
            )}
          </div>
        </FixedHeader>
      ),
      [COLUMN_OPTIONS.DATEFORMATPREFERENCE]: (
        <FixedHeader  className="file-list__th-dateformat" key={COLUMN_OPTIONS.DATEFORMATPREFERENCE}>
      
          <div className="file-list__column-title">
            <span>
              <FormattedMessage id="file-list.heading.date-format" />
            </span>
          </div>
        </FixedHeader>
      ),
      [COLUMN_OPTIONS.PROCESSED_DATE]: (
        <FixedHeader  className={currentFolder.projectItemId != 0 || currentProject.projectTypeId == constants.ProjectTypes.WORKSPACE_PROJECT ?
          "file-list__th-processed-date" : "file-list__th-lastupdated-date"} key={COLUMN_OPTIONS.PROCESSED_DATE}>

          <div className="file-list__column-title">
            <span>
              <FormattedMessage id={currentFolder.projectItemId == 0 ? "file-list.heading.lastupdated-date" : "file-list.heading.processed-date"} />
              {isSearchResultsPage ? null : (
                <Sorting
                  sortFunction={ascending => this.setSorting('processeddate', ascending)}
                  sortDirection={sorting.key === 'processeddate' ? sorting.ascending : undefined}
                  sortKeyAscending="sorting.ascending"
                  sortKeyDescending="sorting.descending"
                />
              )}
            </span>
          </div>
        </FixedHeader>
      ),
      
      [COLUMN_OPTIONS.AUTO_DELETE]:  (        

        <FixedHeader  className="file-list__th-autodelete" key={COLUMN_OPTIONS.AUTO_DELETE}>
    
          <div className="file-list__column-title">
            <span>
              <FormattedMessage id="file-list.heading.auto-delete" />
              {isSearchResultsPage || currentProject?.projectTypeId != constants.ProjectTypes.WORKSPACE_PROJECT ? null : (
                <Sorting
                  sortFunction={ascending => this.setSorting('AUTO_DELETE', ascending)}
                  sortDirection={sorting.key === 'AUTO_DELETE' ? sorting.ascending : undefined}
                  sortKeyAscending="sorting.ascending"
                  sortKeyDescending="sorting.descending"
                />
              )}
            </span>
          </div>
        </FixedHeader>
      )
    };

    return (
      <FixedTable
        className="file-list"
        setScrollRef={this.setScrollRef}
        onPageChange={onPageChange}
        pagination={pagination}
      >
        <table className="file-list__table">
          <thead>
            <tr>
              <FixedHeader className="file-list__th-checkbox">
                <div className="file-list__check-all-container">
                  <Checkbox
                    checked={checkAllState}
                    className="file-list__checkbox"
                    id="check-all"
                    onChange={this.onCheckAllChange}
                  />
                  {/* Commenting the check all popover since the functionality is not implemented */}
                  {/* <div className="file-list__check-all-popover-container">
                    <Button className="file-list__check-all-popover-button" onClick={this.toggleSelectAllPopover}>
                      {isSelectAllPopoverOpen ? <span>&#9650;</span> : <span>&#9660;</span>}
                    </Button>
                    {isSelectAllPopoverOpen && (
                      <SelectAllPopover onClick={this.globalSelectAllItems} onClose={this.toggleSelectAllPopover} />
                    )}
                  </div> */}
                </div>
              </FixedHeader>
              {columnOptions.order
                .filter(option => columnOptions.visibility[option])
                .map(option => currentFolder.projectItemId == 0 && defaultRootColumnsToShow.indexOf(option) != -1 ?
                TABLE_HEADERS[option] : currentFolder.projectItemId != 0 && COLUMN_OPTIONS_DOCSHOW.indexOf(option) != -1 && TABLE_HEADERS[option])}
              <FixedHeader className="file-list__th-options">
                <ColumnOptions
                  order={columnOptions.order}
                  updateColumnOptions={updateColumnOptions}
                  visibility={columnOptions.visibility}
                  projectItemId={currentFolder.projectItemId}
                  defaultRootColumnsToShow={defaultRootColumnsToShow}
                />
              </FixedHeader>
            </tr>
          </thead>
          <tbody>
            {currentFolder.children.map((projectItem, i) => (
              <FileListRow
                collapseExpandedRows={this.collapseExpandedRows}
                columnOptions={columnOptions}
                currentFolder={currentFolder}
                deselectItem={deselectItem}
                expand={this.expandItem.bind(this, projectItem.projectItemId)}
                invalidate={invalidate}
                isDraggingValidItem={this.state.isDraggingValidItem}
                isExpanded={expandedItemId === projectItem.projectItemId}
                isSearchResultsPage={isSearchResultsPage}
                isSelected={selectedItems[projectItem.projectItemId]}
                itemDragEnd={this.itemDragEnd}
                itemDragStart={this.itemDragStart}
                key={projectItem.projectItemId}
                moveItems={this.moveItems}
                onNameClick={onNameClick}
                onRowMouseDown={this.onRowMouseDown}
                projectId={projectId}
                projectItem={projectItem}
                readOnly={readOnly}
                renameItem={renameItem}
                selectItem={selectItem}
                setIsDraggingValidItem={this.setIsDraggingValidItem}
                currentProject={currentProject}
              />
            ))}
          </tbody>
        </table>
        {readOnly ? null : <DragHelper getSelectedItemIds={this.getSelectedItemIds} />}
      </FixedTable>
    );
  }
}

export default withRouter(withDragDropContext(FileList));
