import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdvancedSearchItem from 'components/search/file-list-search/advanced-search-filter-item';
import Button from 'components/shared/single-click-button';

import {
  SEARCH_CATEGORIES,
  SEARCH_MODIFIERS,
  SEARCH_RECORD_MODIFIERS,
  TAG_TYPE_MODIFIERS
} from 'components/search/constants';

// Advanced Search Component
export default class AdvancedSearch extends Component {
  static propTypes = {
    inputValue: PropTypes.string,
    onAdvancedSearch: PropTypes.func,
    onAdvancedSearchChange: PropTypes.func,
    onReset: PropTypes.func
  };

  state = {
    searchString: '',
    counter: 1, // Counts how many advanced search filters to render
    queryValues: [] // The query values for each row of the advanced search
  };

  componentDidMount() {
    const { inputValue } = this.props;

    // Initialize search row
    if (inputValue && inputValue.length) {
      this.setState({ queryValues: this.updateData(0, 'searchString', inputValue) });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onAdvancedSearchChange } = this.props;
    const { counter, queryValues } = this.state;

    if (counter !== prevState.counter || queryValues !== prevState.queryValues) {
      onAdvancedSearchChange({ filter: queryValues, payload: this.getAdvancedSearchPayload() });
      this.onAddRow();
    }
  }

  // Add additional row
  onAddRow = () => {
    const { queryValues, counter } = this.state;
    const previousQuery = queryValues[counter - 1];

    const shouldAddRow =
      previousQuery &&
      previousQuery['category'] &&
      (previousQuery['include'] || previousQuery['record']) &&
      previousQuery['searchString'] &&
      previousQuery['searchString'].length;

    if (shouldAddRow) {
      this.setState({ counter: counter + 1 });
    }
  };

  onRowDelete = index => {
    const { counter, queryValues } = this.state;

    return this.setState({
      counter: counter - 1,
      queryValues: queryValues.filter((_, i) => i !== index)
    });
  };

  // Update a query key/value at a certain index
  updateData = (index, key, value) => {
    const { queryValues } = this.state;
    queryValues[index] = { ...queryValues[index], [key]: value };

    // Pre populate the next field with 'include' or 'has records for'
    if (key === 'category' && value === SEARCH_CATEGORIES.EXTRACTION_FIELD_NAME) {
      queryValues[index].record = SEARCH_RECORD_MODIFIERS.INCLUDE;
    } else if (key === 'category' && value === SEARCH_CATEGORIES.TAG) {
      queryValues[index].tagType = TAG_TYPE_MODIFIERS.DOCUMENT;
      queryValues[index].include = SEARCH_MODIFIERS.INCLUDE;
    } else if (key === 'category') {
      queryValues[index].include = SEARCH_MODIFIERS.INCLUDE;
    }

    return [...queryValues];
  };

  onAndOr = (index, option) => {
    this.setState({ queryValues: this.updateData(index, 'andOr', option) });
  };

  onCategorySelect = (index, option) => {
    this.setState({ queryValues: this.updateData(index, 'category', option) });
  };

  onTagTypeSelect = (index, option) => {
    this.setState({ queryValues: this.updateData(index, 'tagType', option) });
  };

  onIncludeExclude = (index, option) => {
    this.setState({ queryValues: this.updateData(index, 'include', option) });
  };

  onRecord = (index, option) => {
    this.setState({ queryValues: this.updateData(index, 'record', option) });
  };

  onSearchStringChange = (index, value) => {
    this.setState({ queryValues: this.updateData(index, 'searchString', value) });
  };

  // Format the query into a payload for advanced search
  getAdvancedSearchPayload = () => {
    const { queryValues } = this.state;

    return {
      filters: queryValues.map(query => ({
        andOrId: query.andOr || '',
        fieldTypeId: query.tagType || query.category || '',
        includeExcludeId: query.include || query.record || '',
        keyword: query.searchString || this.props.inputValue || ''
      }))
    };
  };

  // Handle a click on the search button
  onSearchClick = () => {
    const { onAdvancedSearch, onAdvancedSearchChange } = this.props;
    const { queryValues } = this.state;

    onAdvancedSearchChange({ filter: queryValues, payload: this.getAdvancedSearchPayload() });
    onAdvancedSearch(this.getAdvancedSearchPayload());
  };

  // Handle a click on the reset button
  onResetClick = () => {
    this.setState({ queryValues: [], counter: 1 });
  };

  // Render the rows
  renderSearchRows = () => {
    const { counter, queryValues } = this.state;
    const rows = [];

    for (let index = 0; index < counter; index++) {
      rows.push(
        <AdvancedSearchItem
          counter={counter}
          index={index}
          inputValue={this.props.inputValue}
          isFirstItem={index === 0}
          key={index}
          onAndOr={this.onAndOr}
          onCancel={this.onRowDelete}
          onCategorySelect={this.onCategorySelect}
          onIncludeExclude={this.onIncludeExclude}
          onQueryChange={this.onSearchStringChange}
          onRecord={this.onRecord}
          onTagTypeSelect={this.onTagTypeSelect}
          values={queryValues}
        />
      );
    }

    return rows;
  };

  render() {
    const { queryValues } = this.state;

    const isSearchButtonEnabled =
      queryValues[0] &&
      queryValues[0]['category'] &&
      (queryValues[0]['include'] || queryValues[0]['record']) &&
      (queryValues[0]['searchString'] || this.props.inputValue.length > 0);

    return (
      <div className="advanced-search">
        <div className="advanced-search__content">{this.renderSearchRows()}</div>
        <div className="advanced-search__footer">
          {/* Reset Button */}
          <Button className="search-category__advanced-search-button btn" onClick={this.onResetClick}>
            <FormattedMessage id="search.dropdown.reset-button" />
          </Button>
          {/* Search Button */}
          <Button className="btn btn-primary" disabled={!isSearchButtonEnabled} onClick={this.onSearchClick}>
            <FormattedMessage id="search.dropdown.shallow-search" />
          </Button>
        </div>
      </div>
    );
  }
}
