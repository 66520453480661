import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import enhanceWithClickOutside from 'react-click-outside';

import Button from 'components/shared/single-click-button';
import Modal from 'components/shared/modal';
import Icon from 'components/shared/icon';

// Change role and remove role menu component
export class TeamListMenu extends Component {
  static propTypes = {
    disableRoleChange: PropTypes.bool,
    member: PropTypes.object,
    onChangeRoleClick: PropTypes.func,
    onMenuButtonClick: PropTypes.func,
    onRemoveClick: PropTypes.func,
    projectState: PropTypes.object
  };

  state = {
    isOpen: false,
    showConfirmationModal: false
  };

  handleClickOutside = () => {
    this.setState({ isOpen: false });
  };

  onMenuButtonClick = () => {
    const { onMenuButtonClick, member } = this.props;
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
    onMenuButtonClick(member);
  };

  renderConfirmationModal = disableSubmitButton => {
    return (
      <Modal id="create-extraction-confirmation-modal">
        <div className="modal__content">
          <h3>
            <FormattedMessage id="team-list.menu-remove.confirmation.title" />
          </h3>
          <p>
            <FormattedMessage id="team-list.menu-remove.confirmation.message" />
          </p>
          <div className="modal__footer">
            <Button className="btn btn-secondary" onClick={() => this.setState({ showConfirmationModal: false })}>
              <FormattedMessage id="team-list.menu-remove.confirmation.cancel" />
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.props.onRemoveClick();
                this.setState({ showConfirmationModal: false });
              }}
            >
              <FormattedMessage id="team-list.menu-remove.confirmation.confirm" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { isOpen, showConfirmationModal } = this.state;
    const { onChangeRoleClick, onRemoveClick, disableRoleChange, projectState } = this.props;

    return (
      <div>
        <Button size="icon" className="icon-button" onClick={this.onMenuButtonClick}>
          <Icon name="special-menu" width={16} />
        </Button>

        {showConfirmationModal ? this.renderConfirmationModal() : null}

        {isOpen ? (
          <div className="team-list__menu" onClick={this.handleClickOutside}>
            {disableRoleChange ? null : (
              <Button className="team-list__menu-item" onClick={onChangeRoleClick}>
                <FormattedMessage id="team-list.menu-change" />
              </Button>
            )}

            <Button
              className="team-list__menu-item"
              onClick={() =>
                !projectState.SETTINGS && !projectState.COPY
                  ? onRemoveClick()
                  : this.setState({
                      showConfirmationModal: true
                    })
              }
            >
              <FormattedMessage id="team-list.menu-remove" />
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default enhanceWithClickOutside(TeamListMenu);
