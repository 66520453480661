import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import * as api from 'store/api';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Button from 'components/shared/single-click-button';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import DateTimePicker from 'react-datetime-picker';

function CreateMaintenanceMessagePopup({
  isOpen,
  closeMaintenancePopup,
  loadMessages,
  GeoCode,
  MessageText,
  StartDate,
  EndDate,
  TimeZone
}) {
  const [loading, changeLoadingStatus] = useState(true);
  const [timeZones, setTimezone] = useState([]);
  const [selectedRegion, onRegionChange] = useState();
  const [selectedTimezoneId, onTimeZoneIdChange] = useState('');
  const [selectedTimezoneName, onTimeZoneNameChange] = useState('');
  const [timezoneOffset, onTimezoneOffSet] = useState('');
  const [maintenanceMessage, maintenanceMessageText] = useState();
  const [startDate, startDateOnChange] = useState(new Date());
  const [endDate, endDateOnChange] = useState(startDate);
  const [disableButton, disableButtonFunc] = useState(true);
  const [dateValidation, dateValidationFailed] = useState(false);
  const [startDateValidation, startDateValidationFailed] = useState(false);
  const [endDateValidation, endDateValidationFailed] = useState(false);
  const buttonRef = useRef(0);
  let selectedTimezoneObj = {};
  let timezoneId;
  let timezoneName;
  let userSelectedTimezoneOffset;

  useEffect(() => {
    loadTimezones();
  }, []);

  const loadTimezones = () => {
    localStorage.removeItem('region');
    localStorage.removeItem('timezoneId');
    localStorage.removeItem('message');
    localStorage.removeItem('startDateValidationCheck');
    localStorage.removeItem('dateValidationCheck');
    localStorage.removeItem('endDateValidationCheck');

    api
      .getTimeZones()
      .then(res => {
        changeLoadingStatus(false);
        setTimezone(res.data);
      })
      .catch(() => {
        changeLoadingStatus(false);
        setTimezone([]);
      });
  };

  const options = [
    { value: 1, label: 'AME' },
    { value: 2, label: 'APA' },
    { value: 3, label: 'EMA' }
  ];

  function startDateOnChangeEvent(e) {
    startDateOnChange(e);
    if(!validateDateTime(e)){
      localStorage.setItem('startDateValidationCheck',true);
      return disableButtonFunc(true);
    } else {
      localStorage.removeItem('startDateValidationCheck');
    }

    if (e < new Date()) {
      startDateValidationFailed(true);
      localStorage.setItem('startDateValidationCheck',true);
    } else {
      localStorage.removeItem('startDateValidationCheck');
      startDateValidationFailed(false);
    }
    
    if (e >= endDate) {
      dateValidationFailed(true);
      localStorage.setItem('dateValidationCheck',true);
    } else {
      dateValidationFailed(false);
      localStorage.removeItem('dateValidationCheck');
    }
    handleValidationInAddClick();
  }

  function endDateOnChangeEvent(e) {
    endDateOnChange(e);
    if(!validateDateTime(e)){
      localStorage.setItem('endDateValidationCheck',true);
      return disableButtonFunc(true);
    } else {
      localStorage.removeItem('endDateValidationCheck');
    }

    if (e < new Date()) {
      endDateValidationFailed(true);
      localStorage.setItem('endDateValidationCheck',true);
    } else {
      localStorage.removeItem('endDateValidationCheck');
      endDateValidationFailed(false);
    }
    
    if (e <= startDate) {
      dateValidationFailed(true);
      localStorage.setItem('dateValidationCheck',true);
    } else {
      dateValidationFailed(false);
      localStorage.removeItem('dateValidationCheck');
    }
    handleValidationInAddClick();
  } 

  function validateDateTime(input) {
    let isValid = false;
    let inputMomentObj = new Date(input);
    let validStartDate = new Date('01/01/1999 00:00:00');
    let validEndDate = new Date('01/01/2999 00:00:00')
    if(validStartDate < inputMomentObj && validEndDate > inputMomentObj)
      isValid = true;
      
    return isValid;
  }

  function onRegionDropdownChange(e) {
    onRegionChange(e);
    localStorage.setItem('region', e);
    handleValidationInAddClick();
  }

  function maintenanceMessageTextOnChange(e) {
    maintenanceMessageText(e);
    localStorage.setItem('message', e);
    handleValidationInAddClick();
  }
  function onTimeZoneOnChangeEvent(e) {
    selectedTimezoneObj = timeZones.filter(a => a.id === e.Id);
    timezoneId = selectedTimezoneObj[0].id;
    timezoneName = selectedTimezoneObj[0].name;
    userSelectedTimezoneOffset =selectedTimezoneObj[0].offsetValue;
    onTimeZoneIdChange(timezoneId);
    onTimeZoneNameChange(timezoneName);
    onTimezoneOffSet(userSelectedTimezoneOffset);
    localStorage.setItem('timezoneId', timezoneId);
    handleValidationInAddClick();
  }

  function handleValidationInAddClick() {
    let userSeletedRegion = localStorage.getItem('region');
    let userSeletedTimezone = localStorage.getItem('timezoneId');
    let message = localStorage.getItem('message');
    let endDateValidationCheck = localStorage.getItem('endDateValidationCheck');
    let startDateValidationCheck = localStorage.getItem('startDateValidationCheck');
    let dateValidationCheck = localStorage.getItem('dateValidationCheck');

    if (
      userSeletedRegion !== null &&     
      message !== null && message !== '' &&
      startDate !== null &&
      endDate !== null &&
      userSeletedTimezone !== null &&
      endDateValidationCheck == null && 
      startDateValidationCheck == null &&
      dateValidationCheck == null 
    ) {
      disableButtonFunc(false);
    } else {
      disableButtonFunc(true);
    }
  }

  let maintenanceObj = {
    MessageId: 0,
    GeoCode: selectedRegion,
    MessageText: maintenanceMessage,
    StartDate: startDate != null ? startDate.toString() : null,
    EndDate: endDate != null ? endDate.toString() : null,
    StartDateString:  startDate != null ? startDate.toString() : null,
    EndDateString: endDate != null ? endDate.toString() : null,
    UTCStartDate: startDate,
    UTCEndDate: endDate,
    TimeZone: { Id: selectedTimezoneId, Name: selectedTimezoneName, OffsetValue: timezoneOffset }
  };
  
  function onSubmit(event) {
    disableButtonFunc(true);  
    if (maintenanceObj.UTCStartDate < maintenanceObj.UTCEndDate) {
      handleValidationInAddClick();
      localStorage.removeItem('region');
      localStorage.removeItem('timezoneId');
      localStorage.removeItem('message');
      buttonRef.current++
      if(buttonRef.current < 2){
        api
          .saveMaintenanceMessages(maintenanceObj)
          .then(res => {
            changeLoadingStatus(true);
            closeMaintenancePopup();
            loadMessages();
          })
          .catch(() => {
            changeLoadingStatus(true);
            closeMaintenancePopup();
            loadMessages();
          });
        }
    } else {
      dateValidationFailed(true);
    }
  }

  return isOpen ? (
    <Modal>
      <div  className="modal__content create-maintenance-messagese-dialog">
        <div className="create-maintenance-messagese-dialog__header">
          <h4>
            <FormattedMessage id="maintenance-message.add-new-message" />
          </h4>
          <Button type="button" size="icon" className="modal__close_x icon-button" onClick={closeMaintenancePopup}>
            <Icon name="special-cross-black" width={18} height={18} />
          </Button>
        </div>
        <div className="create-maintenance-messagese-dialog__content">
          <div className="create-maintenance-messagese-dialog__region-section">
            <div>
              <span className="create-maintenance-messagese-dialog__required-field">*</span>
              <FormattedMessage id="maintenance-message.region" />
            </div>
            <div className="create-maintenance-messagese-dialog__template-type-drop-down">
              <select
                className="create-maintenance-messagese-dialog__template-type-dropdown"
                onChange={e => {
                  onRegionDropdownChange(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Select
                </option>
                {options?.map(item => (
                  <option key={item.value} value={item.label}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          <div className="create-maintenance-messagese-dialog__message-section">
            <div>
              <span className="create-maintenance-messagese-dialog__required-field">*</span>
              <FormattedMessage id="maintenance-message.Message-text" />
            </div>
            <div>
              <input
                className="create-maintenance-messagese-dialog__message-tex-box"
                placeholder="Type message"
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                onChange={e => {
                  maintenanceMessageTextOnChange(e.target.value);
                }}
                type="text"
                maxLength="250"
              />
            </div>
          </div>
          <br />
          <div className="create-maintenance-messagese-dialog__date-section">
            <div className="create-maintenance-messagese-dialog__start-date">
              <div className="create-maintenance-messagese-dialog__start-date-lable">
                <span className="create-maintenance-messagese-dialog__required-field">*</span>
                <FormattedMessage id="maintenance-message.Start-date" />
              </div>

              {(dateValidation ||  startDateValidation || endDateValidation) ? (
                <div className="create-maintenance-messagese-dialog__date-picker-error">
                  <DateTimePicker
                    minDate={new Date()}
                    closeWidgets={true}
                    onChange={e=>startDateOnChangeEvent(e)}
                    value={startDate}
                  />
                </div>
              ) : (
                <div className="create-maintenance-messagese-dialog__date-picker">
                  <DateTimePicker
                    minDate={new Date()}
                    closeWidgets={true}
                    onChange={startDateOnChangeEvent}
                    value={startDate}
                  />
                </div>
              )}
            </div>
            <div className="create-maintenance-messagese-dialog__end-date">
              <div className="create-maintenance-messagese-dialog__start-date-lable">
                <span className="create-maintenance-messagese-dialog__required-field">*</span>
                <FormattedMessage id="maintenance-message.End-date" />
              </div>

              {(dateValidation || startDateValidation || endDateValidation) ? (
                <div className="create-maintenance-messagese-dialog__date-picker-error">
                  <DateTimePicker minDate={new Date()} onChange={endDateOnChangeEvent} value={endDate} />
                </div>
              ) : (
                <div className="create-maintenance-messagese-dialog__date-picker">
                  <DateTimePicker minDate={new Date()} onChange={endDateOnChangeEvent} value={endDate} />
                </div>
              )}
            </div>
          </div>
          <div className="create-maintenance-messagese-dialog__error-validation-div">
            {dateValidation && (
              <div className="create-maintenance-messagese-dialog__error-validation">
                <FormattedMessage id="maintenance-message.Datevalidation" />
              </div>
            )}
            {(startDateValidation || endDateValidation) && (
              <div className="create-maintenance-messagese-dialog__error-validation">
                <FormattedMessage id="maintenance-message.StartEndDatevalidation" />
              </div>
            )}
          </div>
        
          <div className="create-maintenance-messagese-dialog__timezone-section">
            <div>
              <span className="create-maintenance-messagese-dialog__required-field">*</span>
              <FormattedMessage id="maintenance-message.Timezone" />
            </div>
            <div className="create-maintenance-messagese-dialog__template-type-dropdown-div">
              <select
                className="create-maintenance-messagese-dialog__template-type-dropdown"
                placeholder="Select"
                onChange={e => onTimeZoneOnChangeEvent({ Id: e.target.value, Name: '' })}
              >
                <option value="" disabled selected>
                  Select
                </option>
                {timeZones?.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div></div>
          </div>
        </div>

        <div className="create-maintenance-messagese-dialog__footer">
          <Button className="btn btn-secondary" onClick={closeMaintenancePopup}>
            <FormattedMessage id="global.header.format-cancel" />
          </Button>
          <Button onClick={onSubmit} disabled={disableButton} className="btn btn-primary">
            Add
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
}

export default CreateMaintenanceMessagePopup;
