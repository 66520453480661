import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withDragDropContext from 'utils/dnd-mouse-context';
import Constants from 'utils/constants';

import Icon from 'components/shared/icon';
import { Extraction } from './extraction';

class TemplateExtractionsGroupList extends Component {
  static propTypes = {
    extractions: PropTypes.any.isRequired,
    ignoreSort: PropTypes.bool,
    isGroupExtLoading: PropTypes.bool.isRequired,
    onCheckboxClick: PropTypes.func,
    onExtractionChange: PropTypes.func
  };

  state = {
    openGroups: {}
  };

  getGroupedExtractions = () => {
    var {
      extractions: { templateExtractions }
    } = this.props;

    var extractionList = [];
    templateExtractions.forEach(extraction => {
      if (extraction.groupId === null) {
        extraction.groupId = 0;
      }

      if (extraction.groupId !== null) {
        if (!extractionList[extraction.groupId]) {
          extractionList[extraction.groupId] = {
            extractions: [],
            title: extraction.group ? extraction.group : extractionList.selectedName,
            displayOrder: extraction.groupDisplayOrder
          };
        }

        extractionList[extraction.groupId].extractions.push({
          ...extraction,
          displayOrder: extraction.groupMapping
            ? extraction.groupMapping.find(gm => gm.extractionFieldGroupId === extraction.groupId)
                ?.extractionFieldDisplayOrder
            : 0
        });
      }
    });
    return extractionList;
  };

  getAccordion = (group, key) => {
    var { openGroups } = this.state;
    var {
      onCheckboxClick,
      extractions: { templateCategoryId, templateExtractions }
    } = this.props;
    
    const extractionIds = templateExtractions.map(extraction => extraction.id);

    var state = openGroups[key] !== undefined ? openGroups[key] : true;
    
    var extractionElements = group.extractions.map((extraction, index) => (
      <Extraction
        key={`${extraction.id}-${index}`}
        index={extractionIds.indexOf(extraction.id)}
        extraction={extraction}
        moveExtraction={this.moveExtraction}
        onCheckboxClick={() => onCheckboxClick(extraction.id)}
        readOnly={templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING}
        templateCategoryId={templateCategoryId}
      />
    ));

    return (
      <div className={`accordion ${state ? 'open' : ''}`} key={key}>
        <div className="title">
          <div
            className="wrapper"
            onClick={e => {
              e.stopPropagation();
              openGroups[key] = !state;
              this.setState({ openGroups: openGroups });
            }}
          >
            <h3>{group.title}</h3>
            <span className="icons">
              <Icon name="special-arrow-down-blue" width={20} height={20} rotate={state ? 180 : 0} />
            </span>
          </div>
        </div>
        {state ? extractionElements : null}
      </div>
    );
  };

  sortByDisplayOrder = groupedExtractions => {
    for (var i in groupedExtractions) {
      groupedExtractions[i].extractions.sort((ef1, ef2) => ef1.displayOrder - ef2.displayOrder);
    }

    return groupedExtractions.sort((g1, g2) => g1.displayOrder - g2.displayOrder);
  };

  getAccordions = () => {
    const { ignoreSort } = this.props;
    var accordions = [];
    var groupedExtractions = ignoreSort
      ? this.getGroupedExtractions()
      : this.sortByDisplayOrder(this.getGroupedExtractions());
    
    for (var i in groupedExtractions) {
      accordions.push(this.getAccordion(groupedExtractions[i], i));
    }

    return accordions;
  };

  moveExtraction = (extractionIndex, hoverIndex) => {
    const {
      extractions: { templateExtractions },
      onExtractionChange
    } = this.props;
    const dragExtraction = templateExtractions[extractionIndex];
    const updatedExtractions = [...templateExtractions];
    updatedExtractions.splice(extractionIndex, 1);
    updatedExtractions.splice(hoverIndex, 0, dragExtraction);
    onExtractionChange(updatedExtractions);
  };

  render() {
    const { isGroupExtLoading } = this.props;

    return (
      <div className={`group-list extraction-list`}>
        {isGroupExtLoading ? (
          <Icon className={`group-list__spinner spinner spinner--centered`} name="loader" width={80} />
        ) : (
          this.getAccordions()
        )}
      </div>
    );
  }
}

export default withDragDropContext(TemplateExtractionsGroupList);
