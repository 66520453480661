import translationKeys from 'containers/intl/messages/ui-errors.json';

export const ERROR_CODES = {
  DEFAULT_USER_PREFERENCES: 'Default.User.Preferences',
  DOCUMENT_INVALID: 'Document.Invalid',
  DOCUMENT_TYPE_INVALID: 'Document.Type.Invalid',
  DOCUMENT_NAME_DUPLICATE: 'Document.Name.Duplicate',
  ERROR_UNEXPECTED: 'Error.Unexpected',
  ERROR_PAUSED_PROCESSING: 'Error.ReprocessingDisabled',
  FILE_FORMAT_NOT_SUPPORTED: 'File.Format.Not.Supported',
  FILE_NOT_FOUND: 'File.Not.Found',
  FOLDER_DUPLICATE: 'Folder.Duplicate',
  FOLDER_NAME_DUPLICATE: 'Folder.Name.Duplicate',
  FOLDERFILE_NAME_INVALID: 'FolderFile.Name.Invalid',
  INPUT_INVALID: 'Input.Invalid',
  INVALID_NAME: 'Invalid.Name',
  INVALID_ROLE: 'Invalid.Role',
  MAX_SIZE_EXCEEDED: 'Max.Size.Exceeded',
  NO_RECORDS_FOUND: 'No.Records.Found',
  NOT_FOUND_ERROR: 'Not.Found.Error',
  PROJECT_DUPLICATENAME: 'Project.DuplicateName',
  PROJECT_INVALID: 'Project.Invalid',
  TAG_INVALID: 'Tag.Invalid',
  TEMPLATE_INVALID: 'Template.Invalid',
  UNAUTHORIZED: 'Unauthorized',
  USER_TYPE_INVALID: 'User.Type.Invalid',
  XF_INVALID: 'Xf.Invalid',
  XFGROUP_INVALID: 'XfGroup.Invalid',
  XFGROUP_NAME_DUPLICATE: 'ExtractionFieldGroup.Name.Duplicate',
  XFGROUP_NOTFOUND: 'XfGroup.NotFound',
  DOCUMENT_INFECTED: 'Document.Infected',
};

// These errors are handled at the component level rather than globally, so don't show a global notification for them
export const NON_GLOBAL_ERRORS = [
  ERROR_CODES.ERROR_PAUSED_PROCESSING,
  ERROR_CODES.FOLDER_DUPLICATE,
  ERROR_CODES.DOCUMENT_NAME_DUPLICATE,
  ERROR_CODES.FOLDER_NAME_DUPLICATE,
  ERROR_CODES.PROJECT_DUPLICATENAME,
  ERROR_CODES.PROJECT_INVALID,
  ERROR_CODES.INPUT_INVALID
];

// Takes a standard API error response and returns the messages
export const getMessages = error =>
  (error && error.response && error.response.data && error.response.data.messages) || [];

// Takes a standard API error response and returns true if the response contains an given error code
export const hasError = (error, errorCode) => {
  const messages = getMessages(error);
  if (messages) {
    return messages.find(message => message.code === errorCode);
  }

  return false;
};

// Takes a standard API error response and returns true if the message list has a global error
export const hasGlobalError = error => {
  const messages = getMessages(error);
  return messages.some(message => !NON_GLOBAL_ERRORS.includes(message.code));
};

// Takes a standard API error response and returns a list of i18n keys for all the error messages
export const getErrorIntlKeys = error => {
  const messages = getMessages(error);
  if (messages) {
    return messages.map(message => getIntlKeyForCode(message.code)).filter(key => key);
  }

  return [];
};

// Takes a standard API error response and returns a list of i18n keys for the global errors
export const getGlobalErrorIntlKeys = error => {
  const messages = getMessages(error);
  if (messages) {
    return messages
      .filter(message => !NON_GLOBAL_ERRORS.includes(message.code))
      .map(message => getIntlKeyForCode(message.code))
      .filter(key => key);
  }

  return [];
};

export const getIntlKeyForCode = errorCode => {
  return (errorCode && translationKeys['global.error.' + errorCode] && `global.error.${errorCode}`) || null;
};
