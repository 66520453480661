import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCK } from '../../constants';
import Checkbox from 'components/shared/form/checkbox';
import Button from 'components/shared/single-click-button';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import ContainerSelectionModal from 'components/project-list/container-selection/modal';
import { htmlParser } from '../../constants';
import Icon from 'components/shared/icon';

class TranslationDisclaimer extends Component {
  static propTypes = {
    canTranslate: PropTypes.bool.isRequired,
    currentUser: PropTypes.object,
    customClass: PropTypes.string,
    disclaimerFor: PropTypes.string.isRequired,
    disclaimerKeys: PropTypes.array.isRequired,
    haveAcceptedTerms: PropTypes.string.isRequired,
    hideButton: PropTypes.bool,
    intl: intlShape.isRequired,
    onTermsAcceptance: PropTypes.func,
    onTranslate: PropTypes.func,
    setUploadingFiles: PropTypes.func,
    uploadingFiles: PropTypes.bool
  };

  state = {
    showContainerSelectionModal: false
  };

  onContainerSelected = (memberFirmCode, containerCode, countryCode, toBeCached) => {
    const { currentUser } = this.props;

    this.setState({ showContainerSelectionModal: false });

    if (toBeCached) {
      localStorage.setItem(
        `preferred-container-${currentUser.userId}`,
        JSON.stringify({ memberFirmCode: memberFirmCode, containerCode: containerCode })
      );
    }

    return this.translate(memberFirmCode, containerCode);
  };

  handleButtonClick = () => {
    const {
      currentUser: { memberFirmContainers, memberFirmCode, containerCode, userId },
      disclaimerFor,
      onTranslate,
      setUploadingFiles
    } = this.props;
    const { showContainerSelectionModal } = this.state;

    if (disclaimerFor === 'text') {
      onTranslate();
    } else {
      const preferredContainer = JSON.parse(localStorage.getItem(`preferred-container-${userId}`)) || false;

      setUploadingFiles(true);

      if (preferredContainer) {
        return this.translate(preferredContainer.memberFirmCode, preferredContainer.containerCode);
      }

      if (memberFirmContainers && memberFirmContainers.length > 1 && !showContainerSelectionModal) {
        // Display member firm container selection modal
        this.setState({ showContainerSelectionModal: true });
      } else {
        return this.translate(memberFirmCode, containerCode);
      }
    }
  };

  translate = (memberFirmCode, containerCode) => {
    const { onTranslate } = this.props;
    return onTranslate(memberFirmCode, containerCode);
  };

  render() {
    const {
      canTranslate,
      disclaimerKeys,
      hideButton,
      currentUser,
      customClass,
      haveAcceptedTerms,
      intl: { formatMessage },
      onTermsAcceptance,
      uploadingFiles,
      setUploadingFiles
    } = this.props;
    const block = `${BLOCK}__disclaimer`;

    const { showContainerSelectionModal } = this.state;

    const terms = formatMessage(
      { id: 'translation.terms-and-conditions' },
      { boldStart: htmlParser.boldStart, boldEnd: htmlParser.boldEnd }
    );
    return (
      <div className={classNames(block, customClass)}>
        {disclaimerKeys.map((key, index) => {
          const msg = formatMessage(
            { id: key },
            {
              boldUnderlinedStart: htmlParser.boldUnderlinedStart,
              boldUnderlinedEnd: htmlParser.boldUnderlinedEnd,
              boldStart: htmlParser.boldStart,
              boldEnd: htmlParser.boldEnd
            }
          );
          return <p className={`${block}-parragraph`} key={index}>{msg}</p>;
        })}

        {hideButton ? null : (
          <div>
            <div className={`${block}-checkbox-wrapper`}>
              <Checkbox
                checked={haveAcceptedTerms}
                className={`${block}-checkbox`}
                id="accept-terms-and-conditions"
                onChange={() => onTermsAcceptance(haveAcceptedTerms === 'unchecked' ? 'checked' : 'unchecked')}
              />
              <label htmlFor="accept-terms-and-conditions" className={`${block}-label`}>
                <FormattedMessage id="translation.terms-and-conditions-1" />{' '}<span class="bold"><FormattedMessage id="translation.terms-and-conditions-2" /></span>{' '}
                <FormattedMessage id="translation.terms-and-conditions-3" />
              </label>
            </div>
            <Button
              className={`btn btn-primary ${block}-translate-button`}
              disabled={!canTranslate || uploadingFiles}
              onClick={this.handleButtonClick}
            >
              {!uploadingFiles ? (
                <FormattedMessage id="translation.translate" />
              ) : (
                <Icon className="spinner spinner--centered" name="spinner" width={18} />
              )}
            </Button>
            <ContainerSelectionModal
              show={showContainerSelectionModal}
              containers={currentUser.memberFirmContainers}
              onCancel={() => this.setState({ showContainerSelectionModal: false }, () => setUploadingFiles(false))}
              onSubmit={this.onContainerSelected}
              cached
            />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(TranslationDisclaimer);
