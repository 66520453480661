import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import DocumentDownload from 'components/shared/document-download';
import Icon from 'components/shared/icon';
import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import Button from 'components/shared/single-click-button';

import { getCurrentBaseAdminUrl } from 'store/api';

class ListOfReports extends Component {
  static propTypes = {
    loadReports: PropTypes.func,
    onCheckAllChange: PropTypes.func,
    onSchedule: PropTypes.func,
    onSelectChange: PropTypes.func,
    reports: PropTypes.object,
    selectedReports: PropTypes.any
  };

  state = {
    data: null,
    pageNum: 1,
    loadingDocument: false
  };

  componentDidMount() {
    this.componentDidUpdate({});
  }

  componentDidUpdate(prevProps) {
    const { reports, loadReports } = this.props;

    // Load reports if needed
    if (!reports.isReportsLoading && !reports.regular) {
      return loadReports();
    }
  }

  getExportFileName = fileName => {
    const timestamp = moment.utc().format('YYYY-MM-DDTHH_mm_ss');
    return fileName.replace(/##TIMESTAMP##/gi, timestamp);
  };

  onPageChange = pageNum => {
    this.setState({ pageNum });
  };

  render() {
    const { onSchedule, reports } = this.props;
    const { loadingDocument } = this.state;

    if (!reports.regular|| reports.isReportsLoading) {
      return (
        <div className="reports-page__loading">
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        </div>
      );
    }

    return (
      <div className="reports-page__content">
        <FixedTable className="reports-page__list">
          <table>
            <thead>
              <tr>
                <FixedHeader className="reports-page__row-checkbox" />

                <FixedHeader className="reports-page__row-id">
                  <FormattedMessage id="reports-table.id" />
                </FixedHeader>

                <FixedHeader className="reports-page__row-name">
                  <FormattedMessage id="reports-table.name" />
                </FixedHeader>

                <FixedHeader className="reports-page__row-description">
                  <FormattedMessage id="reports-table.description" />
                </FixedHeader>
                <th className="reports-page__row-icon" />
              </tr>
            </thead>
            <tbody>
              {reports.regular.map((report, index) => {
                var reportList_id = index + 1;
                var BASE_URL = getCurrentBaseAdminUrl();
                return (
                  <tr key={index} className="reports-page__row">
                    <td className="reports-page__row-checkbox" />
                    <td className="reports-page__row-id">{reportList_id} </td>
                    <td className="reports-page__row-name"> {report.reportName} </td>
                    <td className="reports-page__row-description" title={report.reportDescription}>
                      {report.reportDescription}
                    </td>
                    <td className="reports-page__row-icon">
                      <div className="reports-page__row-icon-container">
                        {loadingDocument === report.reportId ? (
                          <Icon className="spinner" name="loader" width={25} />
                        ) : (
                          <DocumentDownload
                            className="reports-page__download-link icon-button"
                            linkUrl={`${BASE_URL}/reports/${report.reportId}/binary`}
                            linkBody={<Icon name="export" width={20} />}
                            target="_self"
                            title="Export Report"
                            documentFilename={this.getExportFileName(report.exportFileName)}
                            documentLoading={() => this.setState({ loadingDocument: report.reportId })}
                            documentLoaded={() => this.setState({ loadingDocument: false })}
                          />
                        )}
                        <Button 
                          className="icon-button"
                          onClick={() => onSchedule(report)} size="icon">
                          <Icon name="special-calendar" width={23} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FixedTable>
      </div>
    );
  }
}

export default ListOfReports;
