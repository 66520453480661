import React from 'react';
import { authenticateSuccess } from 'store/api';

class AuthSuccess extends React.Component {
  componentDidMount() {
    authenticateSuccess();
  }

  render() {
    return null;
  }
}

export default AuthSuccess;
