import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { Languages } from 'models/intl/languages';

export const ACTIONS = {
  // Extractions
  LANGUAGES_LOADING: 'argus/ui/LANGUAGES_LOADING',
  LANGUAGES_LOADED: 'argus/ui/LANGUAGES_LOADED',
  LANGUAGES_ERROR: 'argus/ui/LANGUAGES_ERROR'
};

export const languagesLoading = createAction(ACTIONS.LANGUAGES_LOADING);
export const languagesLoaded = createAction(ACTIONS.LANGUAGES_LOADED);
export const languagesError = createAction(ACTIONS.LANGUAGES_ERROR);

export const loadLanguages = () => dispatch => {
  dispatch(languagesLoading());
  return api
    .getLanguages()
    .then(res => {
      dispatch(languagesLoaded(res.data));
    })
    .catch(error => {
      dispatch(languagesError(error));
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new Languages();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.LANGUAGES_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.LANGUAGES_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [ACTIONS.LANGUAGES_ERROR]: (state, action) => {
    return state.setError(state, action);
  }
});
