import { createAction, createReducer } from 'utils/redux-utils';

import {
  getSearchDocuments,
  getSearchExtractions,
  getSearchFolders,
  getSearchReviewers,
  getSearchDocumentTags,
  getSearchExtractionFieldInstanceTags,
  getSearchTemplates,
  createAdvancedSearch
} from 'store/api';
import { SearchCategory } from 'models/search';

export const ACTIONS = {
  ADVANCED_SEARCH_CHANGE: 'argus/ui/ADVANCED_SEARCH_CHANGE',
  SEARCH_QUERY_CHANGE: 'argus/ui/SEARCH_QUERY_CHANGE',
  // Shallow search refers to search that is NOT advanced
  SHALLOW_SEARCH_LOADING: 'argus/ui/SHALLOW_SEARCH_LOADING',
  SHALLOW_SEARCH_LOADED: 'argus/ui/SHALLOW_SEARCH_LOADED',
  SHALLOW_SEARCH_ERROR: 'argus/ui/SHALLOW_SEARCH_ERROR',
  CLEAR_SEARCH_DATA: 'argus/ui/CLEAR_SEARCH_DATA',
  // Advanced Search
  CREATE_ADVANCED_SEARCH: 'argus/ui/CREATE_ADVANCED_SEARCH',
  // Track current query, so that we know which results to display from debouncing
  TRACK_CURRENT_QUERY: 'argus/ui/TRACK_CURRENT_QUERY',
  // Set the selected search category so we know which API call to refresh
  SET_SELECT_CATEGORY: 'argus/ui/SET_SELECT_CATEGORY',
  // Set sort order of search results
  SET_SORT_ORDER: 'argus/ui/SET_SORT_ORDER'
};
// Search Query
export const advancedSearchQueryChange = createAction(ACTIONS.ADVANCED_SEARCH_CHANGE);
export const searchQueryChange = createAction(ACTIONS.SEARCH_QUERY_CHANGE);
export const trackQuery = createAction(ACTIONS.TRACK_CURRENT_QUERY);
export const setSelectCategory = createAction(ACTIONS.SET_SELECT_CATEGORY);
export const setSortOrder = createAction(ACTIONS.SET_SORT_ORDER);

// Search Categories
export const shallowSearchLoading = createAction(ACTIONS.SHALLOW_SEARCH_LOADING);
export const shallowSearchLoaded = createAction(ACTIONS.SHALLOW_SEARCH_LOADED);
export const shallowSearchError = createAction(ACTIONS.SHALLOW_SEARCH_ERROR);
export const searchDataClear = createAction(ACTIONS.CLEAR_SEARCH_DATA);

export const postAdvancedSearch = createAction(ACTIONS.CREATE_ADVANCED_SEARCH);

export const searchAll = data => dispatch => {
  dispatch(shallowSearchLoading());
  // Set tracking query so we know that the results that gets resolved matches the tracking query the user typed in
  dispatch(trackQuery(data.keyword));
  return Promise.all([
    getSearchDocuments(data),
    getSearchExtractions(data),
    getSearchFolders(data),
    getSearchReviewers(data),
    getSearchDocumentTags(data),
    getSearchExtractionFieldInstanceTags(data),
    getSearchTemplates(data)
  ])
    .then(([documents, extractions, folders, reviewers, documentTags, extractionFieldInstanceTags, templates]) =>
      dispatch(
        shallowSearchLoaded({
          documents,
          extractions,
          folders,
          reviewers,
          documentTags,
          extractionFieldInstanceTags,
          templates,
          resolvedQuery: data.keyword
        })
      )
    )
    .catch(err => dispatch(shallowSearchError(err)));
};

export const onSearchChange = data => dispatch => {
  dispatch(searchQueryChange(data));
};

export const onAdvancedSearchChange = data => dispatch => {
  dispatch(advancedSearchQueryChange(data));
};

export const onAdvancedSearch = data => dispatch => {
  return createAdvancedSearch(data).then(res => dispatch(postAdvancedSearch(res)));
};

export const onSelectCategory = data => dispatch => {
  return dispatch(setSelectCategory(data));
};

export const onSortOrder = data => dispatch => {
  return dispatch(setSortOrder(data));
};

export const clearSearchData = () => dispatch => {
  return dispatch(searchDataClear());
};

/*
 * Reducer
 */
export const INITIAL_STATE = new SearchCategory();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.SHALLOW_SEARCH_LOADING]: (state, action) => {
    return state.setLoading(state);
  },

  [ACTIONS.SHALLOW_SEARCH_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [ACTIONS.SHALLOW_SEARCH_ERROR]: (state, action) => {
    return state.setError(state, action.payload);
  },

  [ACTIONS.SEARCH_QUERY_CHANGE]: (state, action) => {
    return state.setQueryChange(state, action);
  },

  [ACTIONS.ADVANCED_SEARCH_CHANGE]: (state, action) => {
    return state.setAdvancedSearchChange(state, action);
  },

  [ACTIONS.TRACK_CURRENT_QUERY]: (state, action) => {
    return state.setQueryTrack(state, action);
  },

  [ACTIONS.SET_SELECT_CATEGORY]: (state, action) => {
    return state.setCategorySelected(state, action);
  },

  [ACTIONS.SET_SORT_ORDER]: (state, action) => {
    return state.setSorting(state, action);
  },

  [ACTIONS.CLEAR_SEARCH_DATA]: (state, action) => {
    return INITIAL_STATE;
  }
});
