import { getProject, getDocument, getLocation, cleanEmail } from './utils';

/** Digtial data layer to update and send to analytics  */
window.digitalData = window.digitalData || {};

const getDataLayer = ({ pageName, pathname, email }) => {
  return {
    meta: {
      projectId: 'default',
      clientId: 'default',
      documentId: 'default',
      documentStatus: 'default'
    },
    page: {
      pageInfo: { pageName: pageName },
      category: { primaryCategory: pathname }
    },
    user: [
      {
        profile: [
          {
            profileInfo: { userName: email }
          }
        ]
      }
    ]
  };
};

class DataLayer {
  constructor() {
    this._data = getDataLayer({});
  }

  // Assign a fresh data layer to this data with specified merge props
  set(mergeProps) {
    this._data = getDataLayer(mergeProps);
  }

  // Clear existing digital data
  clear() {
    window.digitalData = getDataLayer({});
  }

  // Merge in props to existing data layer
  merge(...props) {
    Object.assign(this._data, ...props);
  }

  // Update the global digital data to this data
  update() {
    window.digitalData = this._data;
  }
}

const dl = new DataLayer();
// Update digital data based on the event action and paylaod
const updateDataLayer = (eventType, eventPayload, reduxState) => {
  const user = reduxState.data.currentUser.email;
  const page = getLocation(reduxState.router.location.pathname, reduxState.router.location);

  // User info and location will always be available on the data layer
  dl.merge({ user: [{ profile: [{ profileInfo: { userName: user } }] }] });
  dl.merge({
    page: { pageInfo: { pageName: page.pageName }, category: { primaryCategory: page.pathname } }
  });

  // When a new project is loaded, track it
  if (eventType === 'argus/ui/CREATE_CURRENT_PROJECT_LOADED') {
    dl.merge({ meta: getProject(eventPayload) });
  }

  // When a document is processed, track it
  if (eventType === 'argus/ui/ITEMS_UPDATED') {
    dl.merge({ meta: Object.assign(getProject(reduxState.data.currentProject), getDocument(eventPayload)) });
  }

  return dl.update();
};

export default updateDataLayer;
