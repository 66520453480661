import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import Checkbox from 'components/shared/form/checkbox';
import enhanceWithClickOutside from 'react-click-outside';
import { debounce } from 'underscore';
import Button from 'components/shared/single-click-button';
import { FormattedMessage, injectIntl } from 'react-intl';

class ExtractionOverviewFilter extends React.Component {
  _isMounted = false;

  static propTypes = {
    intl: PropTypes.any,
    filterFunction: PropTypes.func, // Returns an array of ids of filters selected, or an empty array for all
    filterOptions: PropTypes.any,
    filters: PropTypes.any,
    getFilterOptions: PropTypes.any,
    searchFunction: PropTypes.func, // Alternate if no filter function is provided, returns string of users entry
    searchQuery: PropTypes.any,
    toggleButton: PropTypes.func // Callback for when user clicks the arrow we can perform an action, ie refetch filter options
  };

  state = {
    allFilters: true,
    allFiltersArray: [],
    filteredFilters: [],
    filterText: '',
    showDropdown: false
  };

  componentDidMount = () => {
    this._isMounted = true;
    this.setUpFilters();
  };

  componentDidUpdate = (prevProps, prevState) => {
    // Reset state after closing the dropdown
    if (prevState.showDropdown && !this.state.showDropdown) {
      this.setState({
        allFilters: true,
        allFiltersArray: [],
        filteredFilters: [],
        filterText: ''
      });
    }

    if (this.props.filterOptions !== prevProps.filterOptions || this.props.filters !== prevProps.filters)
      this.setUpFilters();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClickOutside() {
    this.toggleDropdown(false);
  }

  setUpFilters = () => {
    const { getFilterOptions, filterFunction, intl } = this.props;

    if (!getFilterOptions || !filterFunction) return;

    this.props.getFilterOptions().then(filterOptions => {
      if (!this._isMounted) return;

      const { filters } = this.props;
      if (!filterOptions) return;

      filterOptions.data.forEach(filter => {
        filter.selected = !filters || filters.length === 0 || this.containsFilter(filters, filter.id);
        if (filter.key) {
          filter.translatedName = `${filter.key}`;
        }
      });

      this.setState({
        allFilters: filterOptions.data.every(filter => filter.selected),
        allFiltersArray: filterOptions.data,
        filteredFilters: filterOptions.data
      });
    });
  };

  containsFilter(filters, targetFilter) {
    var found = false;

    filters.forEach(filter => {
      if (JSON.stringify(filter) === JSON.stringify(targetFilter)) found = true;
    });

    return found;
  }

  filterFilters = value =>
    this.state.allFiltersArray
      .slice(0, this.state.allFiltersArray.length)
      .filter(f =>
        f.key
          ? f.translatedName.toLowerCase().indexOf(value.toLowerCase()) !== -1
          : f.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );

  toggleFilter = id => {
    var { allFilters, filteredFilters } = this.state;

    filteredFilters.forEach(filter => {
      if (filter.id.toString() === id.toString()) filter.selected = !filter.selected;
    });

    var all = filteredFilters.reduce((a, c) => a && c.selected, true) && allFilters.length > 1;

    this.setState({ allFilters: all, filteredFilters: filteredFilters });
  };

  toggleAllFilters = () => {
    var { allFilters, filteredFilters } = this.state;

    filteredFilters.forEach(filter => {
      filter.selected = !allFilters;
    });

    this.setState({ allFilters: !allFilters, filteredFilters: filteredFilters });
  };

  applyFilters = () => {
    var selectedFilters = [];

    if (!this.state.allFilters || this.state.filterText !== '') {
      this.state.filteredFilters.forEach(filter => {
        if (filter.selected) selectedFilters.push(filter.id);
      });
    }

    this.props.filterFunction(selectedFilters);
    this.setState({ showDropdown: false });
  };

  toggleDropdown = debounce(
    override => {
      // If override is undefined, toggle based on state
      var { showDropdown } = this.state;
      // Callback if we want to perform some action (like refetch filter options)
      if (!showDropdown && override === undefined) this.setUpFilters();
      if (!showDropdown) this.props.toggleButton && this.props.toggleButton();
      this.setState({ showDropdown: override === true || override === false ? override : !showDropdown });
    },
    16,
    true
  );

  clearFilters = event => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.filters && this.props.filters.length > 0) {
      this.props.filterFunction([]);
    }

    if (this.props.searchQuery && this.props.searchQuery.length > 0) {
      this.props.searchFunction('');
    }

    this.setState({ showDropdown: false });
  };

  render() {
    var { showDropdown, filteredFilters, allFilters, filterText } = this.state;
    var { filterFunction, filters, intl } = this.props;
    var { searchQuery } = this.props;

    return (
      <div className="generic-sorting-component">
        <Button className="toggle-dropdown icon-button" onClick={() => this.toggleDropdown()}>
          { showDropdown ? (
            <Icon name="advanced-search" width={16} />
          ) : (
            <Icon name="advanced-search" width={16} />
          )}

          {(filters && filters.length > 0) || (searchQuery && searchQuery.length > 0) ? (
            <span>
              <FormattedMessage id="sorting.clear">
                {title => (
                  <span className="remove" onClick={this.clearFilters} title={title}>
                    <Icon name="special-c-cross" width={16} />
                  </span>
                )}
              </FormattedMessage>
            </span>
          ) : null}
        </Button>

        {showDropdown ? (
          <div className="sorting-dropdown" style={{marginTop: "33px", maxWidth: "400px"}}>
            {/* Filter Section */}
            {filterFunction ? (
              <div className="sorting-section">
                <label>
                  <FormattedMessage id="sorting.filters" />
                </label>
                <div className="input-wrapper">
                  <FormattedMessage id="sorting.search">
                    {placeholder => (
                      <input
                        type="text"
                        value={filterText}
                        onChange={e => {
                          this.setState({
                            filterText: e.target.value,
                            filteredFilters: this.filterFilters(e.target.value)
                          });
                        }}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </div>

                <div className="filters-wrapper">
                  <div className="filters">
                    {filteredFilters && filteredFilters.length > 0 ? (
                      <div className="filter">
                        <Checkbox
                          id={''}
                          checked={allFilters ? 'checked' : 'unchecked'}
                          onChange={this.toggleAllFilters}
                        />
                        <FormattedMessage id="sorting.all" />
                      </div>
                    ) : null}

                    {filteredFilters.map((filter, k) => (
                      <div className="filter" key={k} title={filter.name} data-tip data-for={`${filter.key}`}>
                        <Checkbox
                          id={filter.id.toString()}
                          checked={filter.selected ? 'checked' : 'unchecked'}
                          onChange={this.toggleFilter}
                        />
                        <span title={filter.key ? filter.key : filter.name} className="editable-text__value">
                          {filter.key ? filter.key : filter.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="input-wrapper">
                  <Button
                    title={intl.formatMessage({ id: 'common.cancel' })}
                    size="xsmall"
                    className="btn btn-secondary"
                    onClick={() => this.setState({ showDropdown: false })}
                  >
                    <FormattedMessage id="common.cancel" />
                  </Button>
                  <Button
                    title={intl.formatMessage({ id: 'common.apply' })}
                    size="xsmall"
                    className="btn btn-primary btn-no-margin"
                    onClick={this.applyFilters}
                  >
                    <FormattedMessage id="common.apply" />
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(ExtractionOverviewFilter));
