import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import enhanceWithClickOutside from 'react-click-outside';
import { FormattedMessage, injectIntl } from 'react-intl';

import Permissions from 'permissions/permissions';

class ExtractionFieldDropdown extends Component {
  static propTypes = {
    documentId: PropTypes.any,
    extractionField: PropTypes.any,
    intl: PropTypes.any,
    onCreateComparison: PropTypes.func,
    projectId: PropTypes.any,
    pushUndoStack: PropTypes.func,
    readOnly: PropTypes.bool,
    refreshData: PropTypes.func,
    removeAllExtractionFieldRecords: PropTypes.func
  };

  state = {
    expanded: false,
    showRemoveAllModal: false
  };

  handleClickOutside() {
    this.setState({ expanded: false });
  }

  removeAllRecordsFunction = () => {
    var { projectId, documentId, extractionField, removeAllExtractionFieldRecords } = this.props;
    this.setState({ showRemoveAllModal: false });

    removeAllExtractionFieldRecords(projectId, documentId, extractionField.id).then(response => {
      this.setState({ expanded: false, showRemoveAllModal: false });
    });
  };

  onCreateComparison = () => {
    const { onCreateComparison, extractionField } = this.props;
    onCreateComparison(extractionField);
  };

  render() {
    var { readOnly, intl } = this.props;

    return (
      <div className="extraction-field-menu">
        {readOnly ||
        !Permissions.Project.Comparison.canCreate() ||
        !Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canDelete() ? (
          <button disabled />
        ) : (
          <Button
            size="icon"
            disabled={readOnly}
            className="icon-button"
            onClick={() =>
              this.setState({
                expanded: !this.state.expanded
              })
            }
          >
            <Icon name="special-menu" width={16} />
          </Button>
        )}

        <div className={`dropdown ${this.state.expanded ? 'expand' : ''}`}>
          <div>
            {Permissions.Project.Comparison.canCreate() && (
              <Button
                title={intl.formatMessage({ id: 'extraction-field-dropdown.compare-extraction-records.button' })}
                onClick={this.onCreateComparison}
              >
                <FormattedMessage id="extraction-field-dropdown.compare-extraction-records.button" />
              </Button>
            )}
            {Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canDelete() ? (
              <Button
                title={intl.formatMessage({ id: 'extraction-field-dropdown.remove.button' })}
                onClick={() => {
                  this.setState({ showRemoveAllModal: true });
                }}
              >
                <FormattedMessage id="extraction-field-dropdown.remove.button" />
              </Button>
            ) : null}
          </div>
        </div>

        {this.state.showRemoveAllModal ? (
          <Modal id="remove-all-extraction-records-modal">
            <div className="modal__content">
              <h3>
                <FormattedMessage id="extraction-field-dropdown.remove.modal.title" />
              </h3>
              <p>
                <FormattedMessage id="extraction-field-dropdown.remove.modal.question" />
              </p>
              <div className="modal__footer">
                <Button className="btn btn-secondary" onClick={() => this.setState({ showRemoveAllModal: false })}>
                  <FormattedMessage id="extraction-field-dropdown.remove.modal.cancel-button" />
                </Button>
                <Button className="btn btn-primary" onClick={this.removeAllRecordsFunction}>
                  <FormattedMessage id="extraction-field-dropdown.remove.modal.confirm-button" />
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(ExtractionFieldDropdown));
