import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';
import classNames from 'classnames';
import Icon from 'components/shared/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Dropdown extends Component {
  static propTypes = {
    alwaysShowDefaultMessage: PropTypes.bool,
    className: PropTypes.string,
    customStyle: PropTypes.string,
    defaultMessage: PropTypes.any.isRequired,
    onBlur: PropTypes.func,
    onSelection: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    renderOption: PropTypes.func.isRequired,
    selectedOption: PropTypes.any
  };

  static defaultProps = {
    defaultMessage: '',
    renderOption: option => option
  };

  state = {
    isMenuOpen: false
  };

  onMenuToggle = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  render() {
    const {
      alwaysShowDefaultMessage,
      className,
      options,
      selectedOption,
      renderOption,
      onSelection,
      onBlur,
      defaultMessage,
      customStyle
    } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <MenuWrapper
        className={classNames('form-input-dropdown', className)}
        onMenuToggle={this.onMenuToggle}
        onSelection={option => onSelection(option)}
      >
        <MenuButton
          className={classNames('form-dropdown btn form-input-dropdown__button', customStyle)}
          onBlur={onBlur}
          tag="button"
          type="button"
        >
          {selectedOption && !alwaysShowDefaultMessage ? (
            renderOption(selectedOption)
          ) : (
            <span className={classNames('form-input-dropdown__placeholder', customStyle)}>{defaultMessage}</span>
          )}
          <Icon
            name="special-arrow-up-blue"
            width={14}
            height={8}
            rotate={isMenuOpen ? 0 : 180}
            className="btn form-input-dropdown__caret"
          />
        </MenuButton>
        <Menu className={classNames('form-input-dropdown__menu', customStyle)}>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option}
              className={classNames(
                'form-dropdown__item',
                'form-input-dropdown__item',
                selectedOption === option && 'form-input-dropdown__item--selected'
              )}
            >
              {renderOption(option)}
              {selectedOption === option && <Icon name="special-checked-black" width={14} height={8} />}
            </MenuItem>
          ))}
        </Menu>
      </MenuWrapper>
    );
  }
}
