import React, { Component } from 'react';
import logoImage from 'images/deloitte_logo_black.svg';
import OmniaLogo from 'images/Omnia.svg';
import { getCurrentBaseUrl } from 'store/api';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DocumentDownload from 'components/shared/document-download';
import { getAboutInfo } from 'store/api';
import { getHomeRegion, setCurrentRegion } from 'store/api';
import SubheaderDropdown from 'components/header//subheader-dropdown/subheader-dropdown';

class CookieNotice extends Component {
    static propTypes = {
        aboutArgus: PropTypes.object,
        currentLanguage: PropTypes.object,
        loadAboutInfo: PropTypes.func
    };

    state = {
        data: {}
    }

    UNSAFE_componentWillMount() {
        setCurrentRegion(getHomeRegion());
    }

    componentWillMount() {
        getAboutInfo()
            .then(res => {
                this.setState({ data: res.data });
            })
            .catch(err => {
                this.setState({ data: err });
            });
    };

    render() {
        const omniaUrl = 'https://omnia.deloitte.com/';
        const {aboutArgus, currentLanguage} = this.props;
        const privacyNoticeLink =(<DocumentDownload
            linkUrl={
                this.state?.data && this.state?.data.footerInfo
                    ? `${getCurrentBaseUrl()}/${this.state?.data.footerInfo.privacy}`
                    : ''
            }
            className="footer__link"
            linkBody={<FormattedMessage id="global.footer.privacyNotice-link" />}
            target="_blank"
        />)

        const aboutCookie = (
            <a href="http://www.aboutcookies.org/" className="footer__link" rel="noopener noreferrer" target="_blank">
            http://www.aboutcookies.org/
          </a>
        )

        return (
            <div>
                <div className = "header">
                    <img src = {logoImage} className = "header__deloitte-logo" alt = "deloitte" />
                    <a href = {omniaUrl} rel = "noopener noreferrer" target = "_blank">
                        <img src = {OmniaLogo} className = "header__omnia-logo" alt = "Omnia" />
                    </a>
                </div>
                <div className = "subheader-container">
                    <div className = "subheader">
                        <SubheaderDropdown {...this.props} currentPage = "global.subheader.landing-page" />
                    </div>
                </div>
                <div className = "content">
                    <h2 className = "title"><FormattedMessage id="cookie-notice.title"/></h2>
                    <button id = "cookiesetting" className = "optanon-show-settings">
                        <FormattedMessage id="cookie-notice.Manage-cookie-reference"/> 
                    </button>
                    <p className = "revision"><FormattedMessage id="cookie-notice.last-revision"/> </p>
                    <h4><FormattedMessage id="cookie-notice.question.what"/></h4>
                    <p>
                        <FormattedMessage id="cookie-notice.answer.what" values ={{privacyNoticeLink}}/>
                         </p>
                    <h4><FormattedMessage id = "cookie-notice.question.why"/></h4>
                    <p><FormattedMessage id = "cookie-notice.answer.why.first-paragraph"/></p>
                    <ul className = "bulletpoint">
                        <li><FormattedHTMLMessage id = "cookie-notice.answer.why.first-bulletpoint"/></li>
                        <li><FormattedHTMLMessage id = "cookie-notice.answer.why.second-bulletpoint"/></li>
                        <li><FormattedHTMLMessage id = "cookie-notice.answer.why.third-bulletpoint"/></li>
                        <li><FormattedHTMLMessage id = "cookie-notice.answer.why.fourth-bulletpoint"/></li>
                        <li><FormattedHTMLMessage id = "cookie-notice.answer.why.fifth-bulletpoint"/></li>
                    </ul>
                    <p><FormattedMessage id = "cookie-notice.answer.why.last-paragraph"/>
                    </p>
                    <h4><FormattedMessage id = "cookie-notice.question.how-long"/></h4>
                    <p><FormattedMessage id = "cookie-notice.answer.how-long"/></p>
                    <h4><FormattedMessage id = "cookie-notice.question.how-to-control"/></h4>
                    <p>
                        <FormattedMessage id = "cookie-notice.answer.how-to-control.first-paragraph"/><br/>
                        <FormattedMessage id = "cookie-notice.answer.how-to-control.second-paragraph"/><br/>
                        <FormattedMessage id = "cookie-notice.answer.how-to-control.third-paragraph" values = {{aboutCookie}}/><br/>
                        <FormattedMessage id = "cookie-notice.answer.how-to-control.fourth-paragraph"/><br/>
                    </p>
                    <h4><FormattedMessage id = "cookie-notice.question.email-communication"/></h4>
                    <p><FormattedMessage id = "cookie-notice.answer.email-communication"/></p>
                    <h4><FormattedMessage id = "cookie-notice.question.changes"/></h4>
                    <p>
                        <FormattedMessage id = "cookie-notice.answer.changes.first-paragraph"/><br/>
                        <FormattedMessage id = "cookie-notice.answer.changes.last-paragraph"/>
                    </p>
                </div>

            </div>
        );
    }
}

export default CookieNotice;