import { createAction, createReducer } from 'utils/redux-utils';
import { getTrainingRequestsList } from 'store/api';
import { TrainingRequests } from 'models/training-requests';

export const ACTIONS = {
  SET_TRAINING_REQUEST_TYPE: 'argus/ui/SET_TRAINING_REQUEST_TYPE',
  SET_TRAINING_REQUEST_LOADING: 'argus/ui/SET_TRAINING_REQUEST_LOADING',
  SET_TRAINING_REQUEST_LOADED: 'argus/ui/SET_TRAINING_REQUEST_LOADED'
};

export const setTrainingRequest = createAction(ACTIONS.SET_TRAINING_REQUEST_TYPE);
export const setTrainingRequestLoading = createAction(ACTIONS.SET_TRAINING_REQUEST_LOADING);
export const setTrainingRequestLoaded = createAction(ACTIONS.SET_TRAINING_REQUEST_LOADED);

export const setTrainingRequestType = trainingRequestType => dispatch => {
  dispatch(setTrainingRequest(trainingRequestType));
};

export const loadTrainingRequestList = trainingRequestType => dispatch => {
  dispatch(setTrainingRequestLoading());
  return getTrainingRequestsList(trainingRequestType).then(response =>
    dispatch(setTrainingRequestLoaded(response.data))
  );
};

/*
 * Reducer
 */
export const INITIAL_STATE = new TrainingRequests();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.SET_TRAINING_REQUEST_TYPE]: (state, action) => {
    return state.setTrainingRequestType(state, action);
  },
  [ACTIONS.SET_TRAINING_REQUEST_LOADING]: (state, action) => {
    return state.setLoading();
  },
  [ACTIONS.SET_TRAINING_REQUEST_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  }
});
