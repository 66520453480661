import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';
import Constants from 'utils/constants';
import Tooltip from '../tooltip/tooltip';
import Button from 'components/shared/single-click-button';
import { injectIntl, intlShape } from 'react-intl';
import { startExtractionFieldTraining } from 'store/api';
import store from 'store/store';
import { addNotification } from 'store/notifications.js';
import Permissions from 'permissions/permissions';
import { FormattedMessage } from 'react-intl';

class ComplicatedIcon extends Component {
  static propTypes = {
    accessTypeId: PropTypes.number,
    disableInteraction: PropTypes.bool,
    extractionId: PropTypes.any,
    projectId: PropTypes.any,
    iconSize: PropTypes.number,
    intl: intlShape.isRequired,
    isTrained: PropTypes.any,
    isAutomaticExtraction: PropTypes.any,
    refreshFunction: PropTypes.func,
    trainingCount: PropTypes.number,
    trainingTarget: PropTypes.number,
    templateCategoryId: PropTypes.number
  };

  static defaultProps = {
    iconSize: 30
  };

  state = {
    open: false
  };

  toggleFunction = e => {
    var { open } = this.state;
    var { disableInteraction } = this.props;

    if (disableInteraction) {
      return;
    }

    this.setState({ open: !open });
  };

  closeFunction = e => {
    this.setState({ open: false });
  };

  confirmFunction = e => {
    const { extractionId, projectId } = this.props;
    startExtractionFieldTraining(extractionId, [projectId]).then(resp => {
      this.props.refreshFunction();
      this.showEFTrainingSuccessModal();
    });
    this.setState({ open: false });
  };

  showEFTrainingSuccessModal = () => {
    const message = (
      <FormattedMessage id="complicated-icon.private-untrained.ef-training.success-message.description" />
    );
    store.dispatch(addNotification({ message, clearOnPageChange: true, autohide: 15 }));
  };

  isPublic = type => {
    return this.props.accessTypeId === Constants.ExtractionAccessTypeIds.public;
  };

  getProperties = () => {
    var { isTrained, isAutomaticExtraction, trainingCount, trainingTarget, intl, templateCategoryId } = this.props;
    
    var trainingDiff = trainingTarget - trainingCount;
    var isPublic = this.isPublic();
    var canRequestTraining = Permissions.Project.ExtractionField.canRequestTraining();
    
    if (isPublic && isTrained && isAutomaticExtraction) {
      //Public Trained Field
      return {
        state: 1,
        title: intl.formatMessage({ id: 'complicated-icon.public-trained.title' }),
        tooltip: intl.formatMessage({ id: 'complicated-icon.public-trained.title' }),
        message: intl.formatMessage({ id: 'complicated-icon.public-trained.message' }),
        icon: 'public-trained',
        buttons: false
      };
    } else if (isPublic && (!isTrained || (isTrained && !isAutomaticExtraction))) {
      //Public Untrained Field
      return {
        state: 2,
        title: intl.formatMessage({ id: 'complicated-icon.public-untrained.title' }),
        tooltip: intl.formatMessage({ id: 'complicated-icon.public-untrained.title' }),
        message: intl.formatMessage({ id: 'complicated-icon.public-untrained.message' }),
        icon: 'public-grey',
        buttons: false
      };
    } else if (!isPublic && isTrained && isAutomaticExtraction) {
      //Private Trained Field
      return {
        state: 3,
        title: intl.formatMessage({ id: 'complicated-icon.private-trained.title' }),
        tooltip: intl.formatMessage({ id: 'complicated-icon.private-trained.title' }),
        message: (typeof(templateCategoryId) !== 'undefined' && templateCategoryId != Constants.TemplateCategories.NON_EDITABLE_GENAI_DETAIL_TESTING && templateCategoryId!=Constants.TemplateCategories.NON_EDITABLE_GENAI_NONDETAIL_TESTING
            && templateCategoryId != Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING) ? canRequestTraining
          ? intl.formatMessage({ id: 'complicated-icon.private-trained.message' })
          : intl.formatMessage({ id: 'complicated-icon.private-trained-restricted.message' }) : null,
        icon: 'private-trained',
        buttons: canRequestTraining
      };
    } else if (!isPublic && (!isTrained || (isTrained && !isAutomaticExtraction)) && trainingCount >= trainingTarget) {
      //Private Field ready for training
      return {
        state: 4,
        title: intl.formatMessage({ id: 'complicated-icon.private-untrained.title' }),
        tooltip: intl.formatMessage({ id: 'complicated-icon.private-untrained.title' }),
        message: typeof(templateCategoryId) !== 'undefined' && templateCategoryId == 0 ? canRequestTraining
          ? intl.formatMessage({ id: 'complicated-icon.private-untrained-ready.message' })
          : intl.formatMessage({ id: 'complicated-icon.private-untrained-restricted.message' }) : null,
        icon: 'private-untrained-20',
        buttons: canRequestTraining
      };
    } else if (!isPublic && (!isTrained || (isTrained && !isAutomaticExtraction))) {
      //Private Untrained Field
      return {
        state: 5,
        title: intl.formatMessage({ id: 'complicated-icon.private-untrained.title' }),
        tooltip: intl.formatMessage({ id: 'complicated-icon.private-untrained.title' }),
        message: typeof(templateCategoryId) !== 'undefined' && templateCategoryId == 0 ? canRequestTraining
          ? intl.formatMessage({ id: 'complicated-icon.private-untrained.message' }, { trainingTarget, trainingDiff })
          : intl.formatMessage({ id: 'complicated-icon.private-untrained-restricted.message' }) : null,
        icon: 'private-untrained-' + Math.floor((trainingCount / trainingTarget) * 20),
        buttons: false
      };
    }
  };

  render() {
    var { open } = this.state;
    
    var properties = this.getProperties();

    return (
      <div className="complicated-icon" onClick={e => e.stopPropagation()}>
        <Button
          size="icon"
          className={`icon ${properties.icon}`}
          onClick={this.toggleFunction}
          title={properties.title}
        >
          <Icon name={properties.icon} width={this.props.iconSize} />
        </Button>

        {open && properties.message != null ? (
          <Tooltip
            buttons={properties.buttons}
            title={properties.title}
            message={properties.message}
            closeFunction={this.closeFunction}
            declineFunction={this.closeFunction}
            confirmFunction={this.confirmFunction}
            className='complication-icon'
          />
        ) : null}
      </div>
    );
  }
}

export default injectIntl(ComplicatedIcon);
