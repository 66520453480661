import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import React, { Component, Fragment } from 'react';

import {
  addExtractionFieldToGroup,
  addUserToExtractionField,
  removeExtractionFieldFromGroup,
  removeUserFromExtractionField,
  updateExtractionFieldUser,
  getUsers
} from 'store/api';
import { ROLES, ROLE_DISPLAY_NAMES } from 'models/user';
import AddGroupPopover from 'components/quick-study/details/add-group-popover';
import Button from 'components/shared/single-click-button';
import ChangeRoleModal from 'components/quick-study/details/change-role-modal';
import constants from 'utils/constants';
import Dropdown from 'components/shared/form/dropdown';
import Icon from 'components/shared/icon';
import Permissions from 'permissions/permissions';
import UserAutocomplete from 'components/shared/user-autocomplete/user-autocomplete';

const { ExtractionAccessTypeIds } = constants;

/**
 * Role dropdown component
 */
class InnerRoleDropdown extends React.Component {
  static propTypes = {
    intl: intlShape,
    onRoleChange: PropTypes.func.isRequired,
    role: PropTypes.number.isRequired
  };

  render() {
    const { intl, role, onRoleChange } = this.props;

    return (
      <Dropdown
        options={[ROLES.EXTRACTION_FIELD_OWNER, ROLES.EXTRACTION_FIELD_CONTRIBUTOR]}
        renderOption={roleId => <FormattedMessage id={ROLE_DISPLAY_NAMES[roleId]} />}
        selectedOption={role}
        onSelection={onRoleChange}
        defaultMessage={intl.formatMessage({
          id: 'quick-study.extraction-field-details.groups-tab.role-placeholder'
        })}
      />
    );
  }
}

const RoleDropdown = injectIntl(InnerRoleDropdown);

/**
 * Popup menu for showing the 'change role' and 'remove user' options
 */
class InnerUserOptionsMenu extends Component {
  static propTypes = {
    onRemove: PropTypes.func.isRequired,
    onRoleChange: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  state = {
    isOpen: false
  };

  /**
   * Close the popup when clicking outside
   */
  handleClickOutside = () => {
    this.setState({ isOpen: false });
  };

  /**
   * Select the remove user option
   */
  onRemove = () => {
    const { onRemove, user } = this.props;
    onRemove(user);
  };

  /**
   * Select the role change option
   */
  onRoleChange = () => {
    const { onRoleChange, user } = this.props;
    onRoleChange(user);
  };

  render() {
    const { isOpen } = this.state;

    return (
      <div className="users-list__menu-wrapper">
        <Button
          size="icon"
          className="icon-button"
          onClick={() => {
            this.setState({ isOpen: !isOpen });
          }}
        >
          <Icon name="special-menu" width={16} />
        </Button>
        {isOpen ? (
          <div className="users-list__menu" onClick={this.handleClickOutside}>
            <Button className="users-list__menu-item" onClick={this.onRoleChange}>
              <FormattedMessage id="quick-study.extraction-field-details.groups-tab.change-role" />
            </Button>
            <Button className="users-list__menu-item" onClick={this.onRemove}>
              <FormattedMessage id="quick-study.extraction-field-details.groups-tab.remove" />
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

const UserOptionsMenu = enhanceWithClickOutside(InnerUserOptionsMenu);

/**
 * Wrapper for the user autocomplete component
 */
class UserSearch extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    selectedUser: PropTypes.object,
    users: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired])
  };

  state = {
    value: ''
  };

  componentDidUpdate(prevProps) {
    const { selectedUser } = this.props;
    if (prevProps.selectedUser !== selectedUser && !selectedUser) {
      this.setState({
        value: ''
      });
    }
  }

  onChange = event => {
    const { onSelect, onChange } = this.props;
    const value = event.target.value;
    onChange();
    this.setState({ value: event.target.value });
    if (!value) {
      onSelect(null);
    }
  };

  onSelect = (value, user) => {
    const { onSelect } = this.props;
    this.setState({ value });
    onSelect(user);
  };

  searchUsers = keyword => {
    return getUsers(keyword, true).then(res => res.data);
  };

  render() {
    const { value } = this.state;
    return (
      <UserAutocomplete
        keyword={value}
        memberFirmOnly={true}
        onChange={this.onChange}
        onSelect={this.onSelect}
        searchUsers={this.searchUsers}
        selectedUserName={value}
      />
    );
  }
}

/**
 * Main Component for the Groups and Users tab
 */
class GroupsAndUsers extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    intl: intlShape,
    isAdmin: PropTypes.bool,
    onFieldUpdated: PropTypes.func.isRequired,
    publishExtractionField: PropTypes.func.isRequired
  };

  state = {
    isAddGroupPopoverVisible: false,
    isValidMember: false,
    selectedRole: null,
    selectedUser: null,
    selectedUserForUpdate: null,
    pendingGroupRemovals: new Set()
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.field !== prevProps.field &&
      this.props.field.extractionFieldId !== prevProps.field.extractionFieldId
    ) {
      // Need to reset some state when the field changes.
      this.setState({
        selectedRole: null,
        selectedUser: null
      });
    }
  }

  /**
   * Show the add group popover
   */
  showAddGroupPopover = () => {
    this.setState({ isAddGroupPopoverVisible: true });
  };

  /**
   * Hide the add group popover
   */
  hideAddGroupPopover = () => {
    this.setState({ isAddGroupPopoverVisible: false });
  };

  /**
   * Handle a role change
   */
  onRoleSelected = role => {
    this.setState({ selectedRole: role });
  };

  /**
   * Handle a user selection from the user autocomplete
   */
  onUserSelected = user => {
    const { field } = this.props;

    this.setState({
      selectedUser: user,
      // The selected user is only valid if not already added to the extraction field
      isValidMember: !!user && !field.users.find(existingUser => existingUser.userId === user.userId)
    });
  };

  /**
   * Handle a click on the add user button
   */
  onAddUserClick = event => {
    const { field, onFieldUpdated } = this.props;
    event.preventDefault();

    addUserToExtractionField({
      extractionFieldId: field.extractionFieldId,
      userId: this.state.selectedUser.userId,
      roleId: this.state.selectedRole
    }).then(response => {
      // Reset state and re fetch data after adding a user
      this.setState({
        selectedRole: null,
        selectedUser: null,
        isValidMember: false
      });
      onFieldUpdated();
    });
  };

  /**
   * Add this field to a group
   */
  addFieldToGroup = group => {
    const { field, onFieldUpdated } = this.props;
    this.hideAddGroupPopover();

    if (!field.groups.find(({ extractionFieldGroupId }) => extractionFieldGroupId === group.extractionFieldGroupId)) {
      addExtractionFieldToGroup({
        extractionFieldId: field.extractionFieldId,
        groupId: group.extractionFieldGroupId
      }).then(onFieldUpdated);
    }
  };

  /**
   * Remove this field from a group
   */
  removeFieldFromGroup = group => {
    const { field, onFieldUpdated } = this.props;
    const { pendingGroupRemovals } = this.state;

    pendingGroupRemovals.add(group.extractionFieldGroupId);
    this.setState({ pendingGroupRemovals });

    removeExtractionFieldFromGroup({
      extractionFieldId: field.extractionFieldId,
      groupId: group.extractionFieldGroupId
    }).then(response => {
      pendingGroupRemovals.delete(group.extractionFieldGroupId);
      this.setState({ pendingGroupRemovals });
      onFieldUpdated(response);
    });
  };

  /**
   * Remove a user from the field
   */
  removeUser = user => {
    const { field, onFieldUpdated } = this.props;

    removeUserFromExtractionField({
      extractionFieldId: field.extractionFieldId,
      userId: user.userId
    }).then(onFieldUpdated);
  };

  /**
   * Show the change role modal
   */
  showRoleChangeModal = user => {
    this.setState({ selectedUserForUpdate: user });
  };

  /**
   * Submit the change role modal
   */
  submitChangeRoleModal = (user, roleId) => {
    const { field, onFieldUpdated } = this.props;
    this.setState({
      selectedUserForUpdate: null
    });
    updateExtractionFieldUser({
      extractionFieldId: field.extractionFieldId,
      userId: user.userId,
      roleId
    }).then(onFieldUpdated);
  };

  /**
   * Close the change role modal
   */
  closeChangeRoleModal = () => {
    this.setState({
      selectedUserForUpdate: null
    });
  };

  render() {
    const { currentUser, field, intl, isAdmin, publishExtractionField } = this.props;
    const {
      isAddGroupPopoverVisible,
      isValidMember,
      selectedRole,
      selectedUser,
      selectedUserForUpdate,
      users,
      pendingGroupRemovals
    } = this.state;

    return (
      <div className="extraction-details__tab-content-section groups-and-users">
        {/* Groups section */}
        <div className="groups-and-users__groups-section">
          <h3>
            <FormattedMessage id="quick-study.extraction-field-details.groups-tab.groups-title" />
          </h3>
          <div className="groups-and-users__groups-list-container">
            {field.externalSystemId !== constants.ExternalSystemIds.ABBY && (
              <div className="groups-and-users__add-group">
                {Permissions.Global.ExtractionField.canAddRemoveGroup(field) && (
                  <Button size="icon" className="groups-and-users__add-group-button" onClick={this.showAddGroupPopover}>
                    <Icon name="special-plus" width={18} />
                  </Button>
                )}
                {isAddGroupPopoverVisible && (
                  <AddGroupPopover
                    onClose={this.hideAddGroupPopover}
                    onSubmit={this.addFieldToGroup}
                    expectedExternalSystemId={field.externalSystemId}
                  />
                )}
              </div>
            )}
            <div className="groups-and-users__groups-list">
              {field.groups.map(group => (
                <div className="groups-and-users__add-groups-list-item" key={group.extractionFieldGroupId}>
                  <span>{group.extractionFieldGroupName}</span>
                  {field.externalSystemId !== constants.ExternalSystemIds.ABBY &&
                    Permissions.Global.ExtractionField.canAddRemoveGroup(field) && (
                      <Button
                        size="icon"
                        className="groups-and-users__remove-group-button"
                        onClick={() => {
                          this.removeFieldFromGroup(group);
                        }}
                        disabled={pendingGroupRemovals.has(group.extractionFieldGroupId)}
                      >
                        <Icon name="special-c-cross" width={18} />
                      </Button>
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Users section (not visible for public fields) */}
        {field.accessTypeId !== ExtractionAccessTypeIds.public && (
          <div className="groups-and-users__users-section">
            <h3>
              <FormattedMessage id="quick-study.extraction-field-details.groups-tab.users-title" />
            </h3>
            {Permissions.Global.ExtractionField.canAddRemoveUser(field) && (
              <form className="groups-and-users__add-user-form">
                <div className="groups-and-users__add-user-form-field form-field">
                  <label>
                    <FormattedMessage id="quick-study.extraction-field-details.groups-tab.search-label" />
                  </label>
                  <UserSearch
                    key={field.extractionFieldId}
                    selectedUser={selectedUser}
                    onSelect={this.onUserSelected}
                    users={users}
                    onChange={() => this.setState({ isValidMember: false })}
                  />
                </div>
                <div className="groups-and-users__add-user-form-field form-field">
                  <label>
                    <FormattedMessage id="quick-study.extraction-field-details.groups-tab.role-label" />
                  </label>
                  <Dropdown
                    options={[ROLES.EXTRACTION_FIELD_OWNER, ROLES.EXTRACTION_FIELD_CONTRIBUTOR]}
                    renderOption={roleId => <FormattedMessage id={ROLE_DISPLAY_NAMES[roleId]} />}
                    selectedOption={selectedRole}
                    onSelection={this.onRoleSelected}
                    defaultMessage={intl.formatMessage({
                      id: 'quick-study.extraction-field-details.groups-tab.role-placeholder'
                    })}
                  />
                </div>
                <div className="groups-and-users__add-user-form-field form-field">
                  <Button
                    className="btn btn-primary"
                    disabled={!selectedUser || !selectedRole || !isValidMember}
                    onClick={this.onAddUserClick}
                    type="submit"
                  >
                    <FormattedMessage id="quick-study.extraction-field-details.groups-tab.add-button" />
                  </Button>
                </div>
              </form>
            )}
            <table className="users-list">
              <thead>
                <tr className="users-list__tr">
                  <th className="users-list__th users-list__th-name">
                    <FormattedMessage id="quick-study.extraction-field-details.groups-tab.users-list.name" />
                  </th>
                  <th className="users-list__th users-list__th-email">
                    <FormattedMessage id="quick-study.extraction-field-details.groups-tab.users-list.email" />
                  </th>
                  <th className="users-list__th users-list__th-role">
                    <FormattedMessage id="quick-study.extraction-field-details.groups-tab.users-list.role" />
                  </th>
                  <th className="users-list__th users-list__th-menu" />
                </tr>
              </thead>
              <tbody>
                {field.users.map((user, index) => {
                  return (
                    <tr key={user.userId}>
                      <td className="users-list__td users-list__td-name">
                        <div className="users-list__name">
                          <Icon name="assign-act" width={28} />
                          {user.preferredFullName}
                        </div>
                      </td>
                      <td className="users-list__td users-list__td-email">{user.email}</td>
                      <td className="users-list__td users-list__td-role">
                        {user.roles.map((role, index) => (
                          <Fragment key={index}>
                            <FormattedMessage id={ROLE_DISPLAY_NAMES[role.roleId] || 'common.unknown'} />
                            {index !== user.roles.length - 1 && <span>{', '}</span>}
                          </Fragment>
                        ))}
                      </td>
                      <td className="users-list__td users-list__td-menu">
                        {Permissions.Global.ExtractionField.canAddRemoveUser(field) &&
                          currentUser.userId !== user.userId && (
                            <UserOptionsMenu
                              onRemove={this.removeUser}
                              onRoleChange={this.showRoleChangeModal}
                              user={user}
                            />
                          )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {isAdmin && !field.isReadOnly && (
          <div className="groups-and-users__footer">
            <Button onClick={publishExtractionField} className="btn btn-primary groups-and-users__publish-button">
              {field.accessTypeId === ExtractionAccessTypeIds.public ? (
                <FormattedMessage id="quick-study.extraction-field-details.groups-tab.unpublish" />
              ) : (
                <FormattedMessage id="quick-study.extraction-field-details.groups-tab.publish" />
              )}
            </Button>
          </div>
        )}
        {selectedUserForUpdate && (
          <ChangeRoleModal
            dropdown={RoleDropdown}
            onClose={this.closeChangeRoleModal}
            onSubmit={this.submitChangeRoleModal}
            user={selectedUserForUpdate}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(GroupsAndUsers);
