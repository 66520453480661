import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CONSTANTS from 'utils/constants';

import TableView from './table-view/table-view';
import TileView from './tile-view/tile-view';

import Permissions from 'permissions/permissions';

export default class List extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    // Show either table or tile
    titleKey: PropTypes.any,
    toggleTable: PropTypes.bool,
    user: PropTypes.object,
    users: PropTypes.object,
    onProjectCopy: PropTypes.func
  };

  state = {
    selectedId: null,
    isMenuOpen: []
  };

  componentDidMount() {}

  onSelect = Id => {
    const { isMenuOpen } = this.state;
    isMenuOpen[Id] = !isMenuOpen[Id];
    this.setState({ selectedId: Id, isMenuOpen });
  };

  onDeselect = Id => {
    const { isMenuOpen } = this.state;
    isMenuOpen[Id] = false;
    this.setState({ selectedId: null, isMenuOpen });
  };

  // Get Project Status
  getProjectStatus = project => {
    const status = Object.keys(CONSTANTS.ProjectStateIds).find(
      key => CONSTANTS.ProjectStateIds[key] === project.projectStateId
    );

    return project.projectStateId ? status : 'NoStatus';
  };

  // Get creator display details
  getCreator = (creator, viewer) => {
    const { user } = this.props;
    if (creator && creator.userDetail && creator.userDetail.lastName) {
      return {
        initials: creator.userDetail.firstName[0] + creator.userDetail.lastName[0],
        displayName:
          viewer === 'tile'
            ? creator.userDetail.displayName
            : creator.userDetail.lastName + ', ' + creator.userDetail.firstName,
        email: creator.userDetail.email,
        preferredFullName: creator.userDetail.preferredFullName
      };
    }
    return {
      initials: user.firstName[0] + user.lastName[0],
      displayName: viewer === 'tile' ? user.displayName : user.lastName + ', ' + user.firstName,
      email: user.email
    };
  };

  // Project link class name for enabling and disabling links in project card
  getProjectLinkClassname = project => {
    const status = this.getProjectStatus(project);

    if (
      status === 'softDelete' ||
      status === 'copying' ||
      (status === 'inCreation' && !Permissions.Project.Settings.canEditCreationState(project))
    ) {
      return `link-disabled`;
    }

    // status === 'pendingArchive' || status === 'archived' || status === 'started'
    return ``;
  };

  getLinkTo = (project, globalCode) => {
    const status = this.getProjectStatus(project);
    var pathname = '';

    const projectRegion = {
      geoCode: project.geoCode,
      memberFirmCode: project.memberFirmCode,
      containerCode: project.containerCode
    };

    if (status === 'started' || status === 'pendingArchive' || status === 'archived') {
      if (Permissions.Project.Document.canView(project)) {
        pathname = `/region/${globalCode}/project/${project.projectId}/folder/root`;
      } else if (Permissions.Project.Settings.canView(project, projectRegion)) {
        pathname = `/region/${globalCode}/project/${project.projectId}/settings`;
      }
    } else if (status === 'inCreation') {
      pathname =
        project.sourceProjectId !== null
          ? `/region/${globalCode}/project/${project.projectId}/copy`
          : `/region/${globalCode}/project/${project.projectId}/creation`;
    }

    return {
      pathname: pathname
    };
  };

  UpdateProjectStatus = project => {
    return this.props.onProjectCopy(project);
  };

  render() {
    const { toggleTable, data, user, titleKey } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <div className="list">
        <div className="list__recent-projects">
          <FormattedMessage id={titleKey ? `list.heading.${titleKey}` : `list.heading.all-projects`} />
        </div>
        <div className={`list__list-viewer ${toggleTable ? 'table' : ''}`}>
          {toggleTable ? (
            <TableView
              data={data}
              isMenuOpen={isMenuOpen}
              onDeselect={this.onDeselect}
              onSelect={Id => this.onSelect(Id)}
              user={user}
              status={this.getProjectStatus}
              linkClassname={this.getProjectLinkClassname}
              creator={this.getCreator}
              linkTo={this.getLinkTo}
              onUpdateStatus={data => this.UpdateProjectStatus(data)}
              titleKey={titleKey}
            />
          ) : (
            <TileView
              data={data}
              isMenuOpen={isMenuOpen}
              onDeselect={this.onDeselect}
              onSelect={Id => this.onSelect(Id)}
              user={user}
              status={this.getProjectStatus}
              linkClassname={this.getProjectLinkClassname}
              creator={this.getCreator}
              linkTo={this.getLinkTo}
              onUpdateStatus={data => this.UpdateProjectStatus(data)}
            />
          )}
        </div>
      </div>
    );
  }
}
