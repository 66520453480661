import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';

class FormatSelect extends Component {
  state = {
    userChangedFormat: ''
  };

  componentDidMount() {
    this.props.setFormat();
    this.props.loadFormatData();
    this.props.formats.map(e => {
      if (e.IsDefault) {
        localStorage.setItem('defaultFormat', e.CultureCode);
      }
    });
  }

  static propTypes = {
    selectedFormat: PropTypes.string
  };

  formatSelected = e => {
    this.setState({
      userChangedFormat: e.currentTarget.value
    });
    this.props.handleSuccessSubmit(e.currentTarget.value);
    localStorage.setItem('userSelectedFormat', e.currentTarget.value);
  };

  render() {
    const { formats, isLoading, currentFormat } = this.props;

    const items = formats.map(e => {
      if (currentFormat !== null && currentFormat !== "null") {
        return (
          
          <div className="format-select__formatsDisplayed" key={e.CultureCode}>
            {e.CultureCode === currentFormat ? (
              <input type="radio" id="group1" name="radio" value={e.CultureCode} onChange={this.formatSelected} defaultChecked />
            ) : (
              <input type="radio" id="group1" name="radio" value={e.CultureCode} onChange={this.formatSelected} />
            )}
             <span className="format-select__DisplayText">
              {e.DisplayText}
              {e.IsDefault && ' ('}
              {e.IsDefault && <FormattedMessage id="global.header.help-default" />}
              {e.IsDefault && ')'}
            </span>
            <br />
          </div>
          
        );
      } else {
        return (
          
          <div className="format-select__formatsDisplayed"  key={e.CultureCode}>
            {e.IsDefault ? (
              <input type="radio" id="group1" name="radio" value={e.CultureCode} onChange={this.formatSelected} defaultChecked />
            ) : (
              <input type="radio" id="group1" name="radio" value={e.CultureCode} onChange={this.formatSelected} />
            )}
             <span className="format-select__DisplayText">
              {e.DisplayText}
              {e.IsDefault && ' ('}
              {e.IsDefault && <FormattedMessage id="global.header.help-default" />}
              {e.IsDefault && ')'}
            </span>
            <br />
          </div>
          
        );
      }
    });

    return (
      
      <div className="format-select">
        <div className="label">
          <FormattedMessage id="global.header.select-format" />
        </div>
        {isLoading ? (
          <Icon className="spinner spinner--centered" name="loader" width={50} />
        ) : (
          <div className="format-select__selectors">{items}</div>
        )}
      </div>
    
    );
  }
}
export default FormatSelect;
