import { createAction, createReducer } from 'utils/redux-utils';

import { ACTIONS as PROJECT_ACTIONS } from 'store/current-project';
import { ComparisonSets } from 'models/comparison-sets';
import * as api from 'store/api';
import socket, { rooms } from 'utils/socket';

export const ACTIONS = {
  COMPARISON_SETS_LOADING: 'argus/ui/COMPARISON_SETS_LOADING',
  COMPARISON_SETS_LOADED: 'argus/ui/COMPARISON_SETS_LOADED',
  COMPARISON_SET_UPDATED: 'argus/ui/COMPARISON_SET_UPDATED',
  COMPARISON_SET_DELETED: 'argus/ui/COMPARISON_SET_DELETED',
  EXTRACTION_FIELD_COMPARISON_SETS_LOADING: 'argus/ui/EXTRACTION_FIELD_COMPARISON_SETS_LOADING',
  EXTRACTION_FIELD_COMPARISON_SETS_LOADED: 'argus/ui/EXTRACTION_FIELD_COMPARISON_SETS_LOADED',
  EXTRACTION_FIELD_COMPARISON_SET_UPDATED: 'argus/ui/EXTRACTION_FIELD_COMPARISON_SET_UPDATED',
  EXTRACTION_FIELD_COMPARISON_SET_DELETED: 'argus/ui/EXTRACTION_FIELD_COMPARISON_SET_DELETED'
};

export const comparisonSetsLoading = createAction(ACTIONS.COMPARISON_SETS_LOADING);
export const comparisonSetsLoaded = createAction(ACTIONS.COMPARISON_SETS_LOADED);
export const comparisonSetUpdated = createAction(ACTIONS.COMPARISON_SET_UPDATED);
export const comparisonSetDeleted = createAction(ACTIONS.COMPARISON_SET_DELETED);
export const extractionFieldComparisonSetUpdated = createAction(ACTIONS.EXTRACTION_FIELD_COMPARISON_SET_UPDATED);

// Contains the logic for subscribing/unsubscribing to updates from the socket
export const { subscribe, unsubscribe } = (socket => {
  let documentRoom = null;
  let extractionFieldRoom = null;
  let onComparisonDocumentRemoved = null;
  let onComparisonRecordRemoved = null;
  let onComparisonUpdated = null;
  let onExtractionFieldComparisonUpdated = null;
  let onComparisonDeleted = null;

  const subscribe = projectId => dispatch => {
    if (!projectId) {
      return;
    }

    const documentComparisonRoom = rooms.comparisons(projectId);
    const extractionFieldComparisonRoom = rooms.extractionFieldComparisons(projectId);

    if (documentRoom !== documentComparisonRoom || extractionFieldRoom !== extractionFieldComparisonRoom) {
      socket.leave(documentRoom);
      socket.leave(extractionFieldRoom);
      documentRoom = documentComparisonRoom;
      extractionFieldRoom = extractionFieldComparisonRoom;
      socket.join(documentComparisonRoom);
      socket.join(extractionFieldComparisonRoom);
    }

    // Handle event when a document is removed from a comparison
    if (!onComparisonDocumentRemoved) {
      onComparisonDocumentRemoved = response => {
        dispatch(comparisonSetUpdated(response.data));
      };

      socket.on('document.comparisons.removed', onComparisonDocumentRemoved);
    }

    // Handle event when a document is removed from a comparison
    if (!onComparisonRecordRemoved) {
      onComparisonRecordRemoved = response => {
        dispatch(extractionFieldComparisonSetUpdated(response.data));
      };

      socket.on('extractionfield.comparisons.record.removed', onComparisonRecordRemoved);
    }

    // Handle a document comparison update
    if (!onComparisonUpdated) {
      onComparisonUpdated = response => {
        dispatch(comparisonSetUpdated(response.data));
      };

      socket.on('document.comparisons.update', onComparisonUpdated);
    }

    // Handle an extraction field record comparison update
    if (!onExtractionFieldComparisonUpdated) {
      onExtractionFieldComparisonUpdated = response => {
        dispatch(extractionFieldComparisonSetUpdated(response.data));
      };

      socket.on('extractionfield.comparisons.update', onExtractionFieldComparisonUpdated);
    }

    // Handle a comparison being deleted
    if (!onComparisonDeleted) {
      onComparisonDeleted = response => {
        dispatch(comparisonSetDeleted(response.data));
      };

      socket.on('comparisons.removed', onComparisonDeleted);
    }
  };

  const unsubscribe = () => {
    socket.leave(documentRoom);
    socket.leave(extractionFieldRoom);
    documentRoom = null;
    extractionFieldRoom = null;

    if (onComparisonDocumentRemoved) {
      socket.off('document.comparisons.removed', onComparisonDocumentRemoved);
      onComparisonDocumentRemoved = null;
    }

    if (onComparisonRecordRemoved) {
      socket.off('extractionfield.comparisons.record.removed', onComparisonRecordRemoved);
      onComparisonRecordRemoved = null;
    }

    if (onComparisonUpdated) {
      socket.off('document.comparisons.update', onComparisonUpdated);
      onComparisonUpdated = null;
    }

    if (onExtractionFieldComparisonUpdated) {
      socket.off('extractionfield.comparisons.record.update', onExtractionFieldComparisonUpdated);
      onExtractionFieldComparisonUpdated = null;
    }

    if (onComparisonDeleted) {
      socket.off('comparisons.removed', onComparisonDeleted);
      onComparisonDeleted = null;
    }
  };

  return {
    subscribe,
    unsubscribe
  };
})(socket);

export const loadComparisonSets = ({
  projectId,
  pageNum,
  sortBy,
  sortOrder,
  filterBy,
  filterString,
  filterIds,
  compareType = 'document'
}) => dispatch => {
  dispatch(subscribe(projectId));
  dispatch(comparisonSetsLoading());
  return loadComparisonSetsRecursive({
    projectId,
    pageNum,
    sortBy,
    sortOrder,
    filterBy,
    filterString,
    filterIds,
    compareType
  }).then(response => dispatch(comparisonSetsLoaded(response)));
};

//If the page has no items on it and it is not the first page then load the previous page.
const loadComparisonSetsRecursive = ({
  projectId,
  pageNum,
  sortBy,
  sortOrder,
  filterBy,
  filterString,
  filterIds,
  compareType
}) => {
  return api
    .getComparisonSets({ projectId, pageNum, sortBy, sortOrder, filterBy, filterString, filterIds, compareType })
    .then(response => {
      if (response.data && response.data.length === 0 && response.pageInfo && response.pageInfo.pageNumber > 1) {
        return loadComparisonSetsRecursive({
          projectId,
          pageNum: pageNum - 1,
          sortBy,
          sortOrder,
          filterBy,
          filterString,
          filterIds,
          compareType
        });
      } else {
        return response;
      }
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new ComparisonSets();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.COMPARISON_SETS_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.EXTRACTION_FIELD_COMPARISON_SETS_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.COMPARISON_SETS_LOADED]: (state, action) => {
    return state.setLoaded(action.payload);
  },

  [ACTIONS.EXTRACTION_FIELD_COMPARISON_SETS_LOADED]: (state, action) => {
    return state.setLoaded(action.payload);
  },

  [ACTIONS.COMPARISON_SET_UPDATED]: (state, action) => {
    return state.updateComparison(action.payload);
  },

  [ACTIONS.EXTRACTION_FIELD_COMPARISON_SET_UPDATED]: (state, action) => {
    return state.updateComparison(action.payload);
  },

  [ACTIONS.COMPARISON_SET_DELETED]: (state, action) => {
    return state.deleteComparison(action.payload);
  },

  [ACTIONS.EXTRACTION_FIELD_COMPARISON_SET_DELETED]: (state, action) => {
    return state.deleteComparison(action.payload);
  },

  [PROJECT_ACTIONS.CLEAR_PROJECT_DATA]: (state, action) => {
    return INITIAL_STATE;
  }
});
