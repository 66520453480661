import React from 'react';
import PropTypes from 'prop-types';

import ColorHelper from 'components/extractions/helpers/colorHelper';
import Icon from 'components/shared/icon';
import {
  emphasizeHighlight,
  deemphasizeHighlight
} from 'components/document-viewer/document-renderer/helpers/emphasisHelper';
import Button from 'components/shared/single-click-button';

import Permissions from 'permissions/permissions';

class Bookmarks extends React.Component {
  static propTypes = {
    currentDocument: PropTypes.object,
    currentDocumentLayout: PropTypes.object,
    page: PropTypes.any,
    pushUndoStack: PropTypes.func,
    readOnly: PropTypes.bool,
    removeExtractionFieldRecord: PropTypes.func,
    scale: PropTypes.any
  };

  //Clustering distance controls the minimum spacing (in pixels) between two bookmark
  // elements before they are combined into one grouped element
  CLUSTERING_DISTANCE = 20;

  clusterExtractions = () => {
    var { page, scale } = this.props;
    var { extractionFieldRecords } = this.props.currentDocument;

    var pageRecords = extractionFieldRecords.filter(
      e => e.location[0] >= page.range.start && e.location[0] <= page.range.end
    );

    var clusters = {};
    var clusterArray = [];

    pageRecords.forEach(record => {
      var top = page.characters.find(c => c.i === record.location[0]);
      if (top) {
        top = top.y1 * scale - 5;

        if (!clusters.hasOwnProperty(top)) {
          clusters[top] = {
            top: top,
            records: []
          };
        }

        clusters[top].records.push(record);
      }
    });

    for (var key in clusters) {
      clusterArray.push(clusters[key]);
    }

    return this.groupCloseClusters(clusterArray);
  };

  groupCloseClusters = clusters => {
    var groupedClusters = [];

    clusters.forEach((cluster, i) => {
      var appended = false;

      //Attempt to append current cluster to another cluster if it is within the clustering distance
      if (groupedClusters.length > 0) {
        groupedClusters.forEach((groupedCluster, j) => {
          if (
            cluster.top < groupedCluster.top + this.CLUSTERING_DISTANCE &&
            cluster.top > groupedCluster.top - this.CLUSTERING_DISTANCE
          ) {
            if (!appended) {
              groupedCluster.records = groupedCluster.records.concat(cluster.records);
              appended = true;
            }
          }
        });
      }

      if (!appended) {
        groupedClusters.push(cluster);
      }
    });

    return groupedClusters;
  };

  scrollFunction = id => {
    document
      .getElementById(`extraction-field-record-${id}`)
      .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
  };

  hoverFunction = (extraction, enter) => {
    deemphasizeHighlight();

    if (enter) {
      emphasizeHighlight(extraction.extractionFieldRecordId, extraction.extractionFieldId);
    }
  };

  removeFunction = extractionFieldRecord => {
    var { removeExtractionFieldRecord } = this.props;
    var { projectId, documentId } = this.props.currentDocument;

    removeExtractionFieldRecord(
      projectId,
      documentId,
      extractionFieldRecord.extractionFieldRecordId,
      extractionFieldRecord.pageNumber
    );
  };

  render() {
    var clusters = this.clusterExtractions();
    var { readOnly } = this.props;

    return (
      <div className="bookmarks">
        {clusters.map((cluster, i) => {
          var idClasses = cluster.records.map(r => `extraction-bookmark-${r.extractionFieldRecordId}`);

          return (
            <div key={i} style={{ top: cluster.top }} className="cluster">
              <div
                className={`highlight extraction-bookmark ${idClasses[0]}`}
                onClick={() => this.scrollFunction(cluster.records[0].extractionFieldRecordId)}
                onMouseEnter={() => this.hoverFunction(cluster.records[0], true)}
                onMouseLeave={() => this.hoverFunction(cluster.records[0], false)}
              >
                <span
                  className="color-marker"
                  style={{ background: ColorHelper.highlight_colors[cluster.records[0].colorIndex] }}
                />

                <span className="name" title={cluster.records[0].extractionFieldName}>
                  {cluster.records[0].extractionFieldName}
                </span>

                {Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canDelete() && !readOnly ? (
                  <Button className="remove-extraction icon-button" onClick={() => this.removeFunction(cluster.records[0])}>
                    <Icon name="special-cross-black" width={10} height={10} />
                  </Button>
                ) : null}

                {cluster.records.length > 1 ? (
                  <span className={`extra ${idClasses.slice(1, idClasses.length).join(' ')}`}>
                    +{cluster.records.length - 1}
                    <div className="tooltip">
                      {cluster.records.slice(1, cluster.records.length).map((additionalExtractionFieldRecord, j) => (
                        <div
                          className="row"
                          key={j}
                          onClick={e => {
                            e.stopPropagation();
                            this.scrollFunction(additionalExtractionFieldRecord.extractionFieldRecordId);
                          }}
                          onMouseEnter={() => this.hoverFunction(additionalExtractionFieldRecord, true)}
                          onMouseLeave={() => this.hoverFunction(additionalExtractionFieldRecord, false)}
                        >
                          <span
                            className="color-marker"
                            style={{
                              background: ColorHelper.highlight_colors[additionalExtractionFieldRecord.colorIndex]
                            }}
                          />

                          <span className="text">{additionalExtractionFieldRecord.extractionFieldName}</span>

                          {Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canDelete() &&
                          !readOnly ? (
                            <Button
                              className="remove-extra-extraction"
                              onClick={e => {
                                e.stopPropagation();
                                this.removeFunction(additionalExtractionFieldRecord);
                              }}
                            >
                              <Icon name="special-cross-white" width={10} height={10} />
                            </Button>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Bookmarks;
