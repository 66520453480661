import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import DocumentViewer from 'containers/document-viewer/document-viewer-container';
import NotificationsContainer from 'containers/shared/notifications-container';

import Permissions from 'permissions/permissions';
import history from 'utils/history';

class DocumentViewerPage extends Component {
  static propTypes = {
    match: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    if (!Permissions.Project.Document.canView()) {
      history.push('/');
    }
  }

  render() {
    const { documentId, projectId } = this.props.match.params;

    return (
      <div className="document-viewer-page">
        <DocumentViewer documentId={parseInt(documentId, 10)} projectId={parseInt(projectId, 10)} {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default DocumentViewerPage;
