import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import ExtractionOverviewHeader from 'components/extraction-overview/extraction-overview-header';
import ExtractionOverviewGrid from 'components/extraction-overview/extraction-overview-grid';
import ExtractionOverviewDocumentPanelContainer from 'containers/extraction-overview/extraction-overview-document-viewer-container'
import classNames from 'classnames';
import { BLOCK } from './constants';
import { ITEM_STATUSES } from 'models/project-item';
import Permissions from 'permissions/permissions';

class ExtractionOverview extends Component {
  static propTypes = {
    currentFolder: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    extractionOverview: PropTypes.object.isRequired,
    loadCurrentFolder: PropTypes.func.isRequired,
    loadProjectTemplates: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    setTemplate: PropTypes.func.isRequired,
    unsubscribeCurrentFolder: PropTypes.func.isRequired,
    updateExtractionOverviewColumnOptions: PropTypes.func.isRequired
  };

  state = {
    isPendingDeletion: false,
    customizedVisibility:{},
    isReadOnly:false,
    currentDocumentId: 0,
    currentPageNumber:1,
    isDocumentToggleSwitchOnOff:false
  };

  componentDidMount() {
    const { loadProjectTemplates, projectId } = this.props;
    loadProjectTemplates({ projectId });
    var isDocumentToggleSession = sessionStorage.getItem('isDocumentToggleSwitchOnOff') === "true" ? true : false;
    this.setState({isReadOnly:Permissions.Project._States.isReadOnly(), isDocumentToggleSwitchOnOff: isDocumentToggleSession});
  }

  componentWillUnmount() {
    this.props.unsubscribeCurrentFolder();
    window.removeEventListener('scroll', this.onFileListScroll);
  }
  customizedVisibilityfunc = (v) => {
    this.setState({customizedVisibility:v})
      }
  getPendingDeleteItems = () => {
    const { currentFolder } = this.props;
    return (currentFolder.children || []).filter(item => item.itemStateId === ITEM_STATUSES.PENDINGDELETION);
  };

  openDocument = (documentId, pageNumber) =>{
    this.setState({currentDocumentId: documentId, currentPageNumber: pageNumber});
  }
  
  changeDocumentToggle  = () => {
    const documentToggleValue = this.state.isDocumentToggleSwitchOnOff;
    this.setState({isDocumentToggleSwitchOnOff : !documentToggleValue});
    sessionStorage.setItem('isDocumentToggleSwitchOnOff', !documentToggleValue);
  };
  render() {
    const {
      currentProject,
      extractionOverview,
      projectId,
      setTemplate,
      currentFolder,
      updateExtractionOverviewColumnOptions
    } = this.props;
    const {currentDocumentId, currentPageNumber, isDocumentToggleSwitchOnOff} = this.state;
    
    return extractionOverview.isTemplatesLoading ? (
      <Icon className="spinner spinner--centered" name="loader" width={80} />
    ) : (
      <div
        className={classNames(
          'page',
          BLOCK,
          (currentProject.projectStateId === 6 || currentProject.projectStateId === 7) && 'has-top-bar'
        )}
      >
        <ExtractionOverviewHeader
          currentProject={currentProject}
          extractionOverview={extractionOverview}
          setTemplate={setTemplate}
          projectId={projectId}
          currentFolder={currentFolder}
          updateColumnOptions={updateExtractionOverviewColumnOptions}
          customizedVisibility={v=>this.customizedVisibilityfunc(v)}
          isReadOnly={this.state.isReadOnly}
          isDocumentToggleSwitchOnOff={isDocumentToggleSwitchOnOff}
          changeDocumentToggle={this.changeDocumentToggle}
        />
        {extractionOverview.templateExtractionFields?.length ? (
          <div className="extraction-overview__body">
            <ExtractionOverviewGrid
              projectId={projectId}
              projectTemplateTypeId={extractionOverview.selectedTemplate.projectTemplateId}
              templateExtractions={extractionOverview.templateExtractions}
              templateExtractionFields={extractionOverview.templateExtractionFields}
              templateExtractionGroups={extractionOverview.templateExtractionGroups}
              columnOptions={extractionOverview.columnOptions}
              updateColumnOptions={updateExtractionOverviewColumnOptions}
              setTemplate={setTemplate}
              extractionOverview={extractionOverview}
              customizedVisibility={this.state.customizedVisibility}
              openDocument={this.openDocument}
              isDocumentToggleSwitchOnOff={isDocumentToggleSwitchOnOff}
            />
            {currentDocumentId && isDocumentToggleSwitchOnOff? 
            (<ExtractionOverviewDocumentPanelContainer
              projectId={projectId}
              documentId={currentDocumentId != 0 && currentDocumentId}
              pageNumber={currentPageNumber}
            />
            ) : null}
          </div>
          
        ) : null}
      </div>
    );
  }
}

export default ExtractionOverview;
