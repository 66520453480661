import { createAction, createReducer } from 'utils/redux-utils';

import * as api from 'store/api';
import { LoadDateFormat } from 'models/intl/loadDateFormat';

export const ACTIONS = {
  // Extractions
  DATE_FORMATS_LOADING: 'argus/ui/DATE_FORMATS_LOADING',
  DATE_FORMATS_LOADED: 'argus/ui/DATE_FORMATS_LOADED',
  DATE_FORMATS_ERROR: 'argus/ui/DATE_FORMATS_ERROR'
};

export const DateformatsLoading = createAction(ACTIONS.DATE_FORMATS_LOADING);
export const DateformatsLoaded = createAction(ACTIONS.DATE_FORMATS_LOADED);
export const DateformatsError = createAction(ACTIONS.DATE_FORMATS_ERROR);

export const loadDateFormat = () => dispatch => {
  dispatch(DateformatsLoading());
  return api
    .getDateFormats()
    .then(res => {
      dispatch(DateformatsLoaded(res.data.value));
    })
    .catch(error => {
      dispatch(DateformatsError(error));
    });
};

/*
 * Reducer
 */
export const INITIAL_STATE = new LoadDateFormat();

export default createReducer(INITIAL_STATE, {
  [ACTIONS.DATE_FORMATS_LOADING]: (state, action) => {
    return state.setLoading();
  },

  [ACTIONS.DATE_FORMATS_LOADED]: (state, action) => {
    return state.setLoaded(state, action);
  },

  [ACTIONS.DATE_FORMATS_ERROR]: (state, action) => {
    return state.setError(state, action);
  }
});
