import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropdown from 'components/shared/form/dropdown';
import ToolbarIcon from 'components/shared/toolbar-icon/toolbar-icon';
import { FormattedMessage } from 'react-intl';
import AnalyticsSettings from 'components/analytics/analytics-settings';
import Checkbox from 'components/shared/form/checkbox';

class AnalyticsHeader extends Component {
  static propTypes = {
    currentProject: PropTypes.object,
    extractions: PropTypes.array.isRequired,
    onExportClick: PropTypes.func.isRequired,
    onShowDocsWithNoRecords: PropTypes.func.isRequired,
    selectTemplate: PropTypes.func.isRequired,
    selectedTemplate: PropTypes.object.isRequired,
    setSortAscending: PropTypes.func.isRequired,
    setVisibleExtractionIdsSet: PropTypes.func.isRequired,
    showDocsWithNoRecords: PropTypes.bool.isRequired,
    templates: PropTypes.array.isRequired,
    visibleExtractionIdsSet: PropTypes.object.isRequired
  };

  state = {
    showSettingsPopup: false
  };

  openSettings = () => {
    this.setState({ showSettingsPopup: true });
  };

  cancelSettings = () => {
    this.setState({ showSettingsPopup: false });
  };

  applySettings = visibleExtractionIdsSet => {
    this.setState({ showSettingsPopup: false });
    this.props.setVisibleExtractionIdsSet(visibleExtractionIdsSet);
  };

  render() {
    const {
      currentProject,
      extractions,
      onExportClick,
      onShowDocsWithNoRecords,
      selectTemplate,
      selectedTemplate,
      setSortAscending,
      showDocsWithNoRecords,
      templates,
      visibleExtractionIdsSet
    } = this.props;
    const { showSettingsPopup } = this.state;

    const checkboxState = showDocsWithNoRecords ? 'checked' : 'unchecked';

    return (
      <div className="analytics-header">
        <div className="analytics-header__heading">
          <h2>
            <FormattedMessage id="analytics.analytics-chart" />
          </h2>
          <div className="this">{currentProject.projectName}</div>
        </div>
        <Dropdown
          className="analytics-header__template-dropdown"
          selectedOption={selectedTemplate}
          options={templates}
          renderOption={template => (
            <span className="analytics-header__template-dropdown-text" title={template.name}>
              {template.name}
            </span>
          )}
          onSelection={selectTemplate}
        />
        <div className="analytics-header__show-docs-with-no-records">
          <Checkbox
            checked={checkboxState}
            className="analytics-header__show-docs-with-no-records-checkbox"
            id="show-docs-with-no-records-checkbox"
            onChange={onShowDocsWithNoRecords}
          />
          <label htmlFor="show-docs-with-no-records-checkbox" className="analytics-header__no-records-label">
            <FormattedMessage id="analytics.show-docs-with-no-records" />
          </label>
        </div>
        <div className="analytics-header__flex-spacer" />
        <div className="analytics-header__settings-container">
          {showSettingsPopup ? (
            <AnalyticsSettings
              apply={this.applySettings}
              cancel={this.cancelSettings}
              extractions={extractions}
              visibleExtractionIdsSet={visibleExtractionIdsSet}
              setSortAscending={setSortAscending}
            />
          ) : null}
          <ToolbarIcon
            onClick={this.openSettings}
            iconName="special-cog"
            tooltipKey="analytics.settings-tooltip"
            active={showSettingsPopup}
          />
        </div>

        <button className="btn btn-primary btn-large btn-no-margin" onClick={onExportClick}>
          <FormattedMessage id="analytics.export" />
        </button>
      </div>
    );
  }
}

export default AnalyticsHeader;
