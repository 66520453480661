import { connect } from 'react-redux';

import ReviewButton from 'components/document-viewer/toolbar/review-button';
import { markDocumentAsReviewed, markDocumentAsNotReviewed } from 'store/current-document';

const mapStateToProps = ({ data: { currentUser } }) => ({
  currentUser
});

const mapDispatchToProps = {
  markDocumentAsReviewed,
  markDocumentAsNotReviewed
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewButton);
