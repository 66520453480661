import React, { memo, useEffect, useState } from 'react';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';

function MaintenanceMessageBanner({ maintenanceBannerMessages, currentUser }) {
    const [maintenanceMessages, changemaintenanceMessages] = useState(JSON.parse(maintenanceBannerMessages));
    useEffect(() => {
        getMessages();
    }, []);
    
    const getMessages = () => {
        let messagesToLoad = maintenanceMessages;
        let msg = [];
        let recentClosedMaintenanceMessages = JSON.parse(localStorage.getItem(`closedMaintenanceMessage-${currentUser.userId}`)) || [];
        let updateClosedMessages = [];
        let messageIds = messagesToLoad.map(element => element.messageId);
        messagesToLoad.forEach(function (obj) {
            if (recentClosedMaintenanceMessages.indexOf(obj.messageId) == -1) {
                msg.push(obj)
            };
        });
        recentClosedMaintenanceMessages.forEach(function(elem){
            if (messageIds.indexOf(elem) !== -1) {
                updateClosedMessages.push(elem);
            };
        });
        if(currentUser.userId != null)
            localStorage.setItem(`closedMaintenanceMessage-${currentUser.userId}`, JSON.stringify(updateClosedMessages));
        changemaintenanceMessages(msg);
    }

    const onCloseClick = (id) => {
        let messages = maintenanceMessages.filter(m => m.messageId !== id)
        changemaintenanceMessages(messages);
        let recentClosedMaintenanceMessages = JSON.parse(localStorage.getItem(`closedMaintenanceMessage-${currentUser.userId}`)) || [];
        recentClosedMaintenanceMessages.push(id);
        localStorage.setItem(`closedMaintenanceMessage-${currentUser.userId}`, JSON.stringify(recentClosedMaintenanceMessages));
     

    }
    return (maintenanceMessages.length > 0 && currentUser?.userId != null && 
        <div className="maintenance-message-banner">
            {maintenanceMessages.map(message => {
                return (<div className="maintenance-message-banner__message maintenance-message-banner__message-left" key={message.messageId}>
                    <span className="maintenance-message-banner__message-Icon">
                        <Icon name="warning-circle" width={16} />
                    </span>
                    <span className="maintenance-message-banner__message-text">
                        {message.messageText}
                    </span>
                    <Button
                        size="icon"
                        className="maintenance-message-banner__message-close icon-button"
                        onClick={() => onCloseClick(message.messageId)}
                    >
                        <Icon name="special-cross-black" width={10} />
                    </Button>

                </div>
                )
            })}
        </div>
    )
}

export default MaintenanceMessageBanner;