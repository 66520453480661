import { connect } from 'react-redux';
import AdminDashboard from 'components/admin-dashboard/admin-dashboard';
import { setTrainingRequestType } from 'store/training-request-type';
import { loadProjectList } from 'store/project-list';
import { onSearchChange } from 'store/search/search-category';

const mapStateToProps = ({ data: { currentProject, currentUser } }) => ({
  currentProject, currentUser
});

const mapDispatchToProps = {
  setTrainingRequestType,loadProjectList,onSearchChange
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
