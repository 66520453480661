import React, { Component, useState, useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Permissions from 'permissions/permissions';
import { Link } from 'react-router-dom';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import ToolBar from 'components/project-list/toolbar/toolbar';
import ContainerSelectionModal from 'components/project-list/container-selection/modal';
import constants from 'utils/constants';
import * as api from 'store/api';
import {
  createProject ,
  createProjectManually,
} from 'store/api';
import { constant } from 'underscore';

const LANDINGPAGE_PATHS = {
  0: '/',
  1: '/translation',
  2: '/admin-access'
};

function LandingPageTabs(props) {

  const [selectedTab, setSelectedTab] = useState(0);
  const {fromProjectListPage, fromTranslationPage} = props;
  const [tableOrTile, setTableOrTile] = useState(null);
  const [userMode, setUserMode] = useState(false);
  const [sortKey, setSortKey] = useState('projectName');
  const [sortAsc, setSortAsc] = useState(true);
  const [showContainerSelectionModal, setConstainerSelectionModel] = useState(false);
  const [translationLoaded, setTranslationsListLoaded] = useState(false);
  const [projectType, setprojectType] = useState(constants.ProjectTypes.EXTRACTION_PROJECT);
 
  const { location, translations, currentUser, loadTranslationsList, isWorkspaceButtonHidden} = props;
  let currentTab = 0;

  useEffect(() => {
    currentTab = Object.values(LANDINGPAGE_PATHS).indexOf(location.pathname);
    if ((location.pathname.includes('groups') && Permissions.Global.canViewAdminContainerDropdown())  || location.pathname.includes('training-requests') || (location.pathname.includes('extraction-fields') && Permissions.Global.canViewAdminContainerDropdown())
      || location.pathname.includes('reports') || location.pathname.includes('user-management') || location.pathname.includes('maintenance-messages') || location.pathname.includes('activation-settings'))
      currentTab = 2;
    if (currentTab >= 0)
      setSelectedTab(currentTab);

    const value = JSON.parse(localStorage.getItem(`project-list-display-mode-${props.currentUser.userId}`));
    setTableOrTile(value);

    if(currentUser?.accessibleFeatures && !fromProjectListPage  && currentUser.accessibleFeatures.filter(feature => feature.featureId === 1).length > 0){
      if(!translationLoaded){
        loadTranslationsList();
        setTranslationsListLoaded(true);
      }
    }
      

  });

  const getCurrentContainer = () => {
    var container = sessionStorage.getItem('selected-container');  
    getCurrentMemberFirmCode(); 
    getContainerGEO(); 
    if (container)
      return container;
    else {
      sessionStorage.setItem(`selected-container`, props.currentUser.containerCode);
      return props.currentUser.containerCode;
    }    
  }

  const getCurrentCountryCode = (countryCode) => {
    const { memberFirmContainers } = props.currentUser;
    if (memberFirmContainers != null) {
      memberFirmContainers.forEach(mfc => {
        if (mfc.countryCode != null ) {   
          countryCode = mfc.countryCode    
          return countryCode    
        }
      });
    } else {
      //If no country code is specified, get first matching country name for region code combo
      memberFirmContainers.forEach(mfc => {
        if (mfc.memberFirmCode != null && mfc.containerCode != null) {
          countryCode = mfc.countryCode    
          return countryCode 
        }
      });
    }  
    return countryCode 
  }

  const getCurrentMemberFirmCode= () => {
    var memberfirm = sessionStorage.getItem('selected-memberfirm');
    if (memberfirm)
      return memberfirm;
    else {
      sessionStorage.setItem(`selected-memberfirm`, props.currentUser.memberFirmCode);
      return props.currentUser.memberFirmCode;
    }
  }

  const getContainerGEO = () => {
    var selectedGeo = sessionStorage.getItem('selected-GEOCode');
    if (selectedGeo)
      return selectedGeo;
    else {
      sessionStorage.setItem(`selected-GEOCode`, props.currentUser.geoCode);
      return props.currentUser.geoCode;
    }
  }

  const onContainerChange = (value) => {
    var adminContainer = JSON.parse(value);
    sessionStorage.setItem(`selected-container`, adminContainer.containerCode);
    sessionStorage.setItem(`selected-memberfirm`, adminContainer.memberFirmCode);
    sessionStorage.setItem(`selected-GEOCode`, adminContainer.geoCode);

    if (props.loadProjectList && fromProjectListPage)
      props.loadProjectList();
    if (props.loadUsers && location.pathname.includes('user-management'))
      props.loadUsers({ pageNum: 1 });
    if (location.pathname.includes('reports')) {
      if (sessionStorage.getItem('active-reports-tab') === "1" && props.loadReports)
        props.loadReports();
      else if (sessionStorage.getItem('active-reports-tab') === "2" && props.loadScheduledReports)
        props.loadScheduledReports();
    }
    if (location.pathname.includes('extraction-fields') && props.loadExtractionFields)
      props.loadExtractionFields({
        pageNum: 1,
        filterBy: 'name',
        filterString: ''
      });
    if (location.pathname.includes('groups') && props.getExtractionFieldGroups)
      props.getExtractionFieldGroups({ pageNum: 1 });
    if (location.pathname.includes('training-requests') && props.loadTrainingRequestList)
      props.loadTrainingRequestList({ trainingRequestType: sessionStorage.getItem('active-trainingrequest-tab') });
    if(location.pathname.includes('activation-settings') && props.getOrganizationCodes && props.getEnabledOrganizationCodes){
      props.getOrganizationCodes();
      props.getEnabledOrganizationCodes();
    }

    props.onSearchChange('');
  }

  const onContainerSelected = (memberFirmCode, containerCode, countryCode) => {
    const { geoCode } = props.currentUser;
    if(projectType == constants.ProjectTypes.WORKSPACE_PROJECT){
      // MyWorkspace creation
      return createWorkspace(geoCode, memberFirmCode, containerCode, countryCode);
    }
    else if (projectType == constants.ProjectTypes.EXTRACTION_PROJECT)
    {
    return createProject(geoCode, memberFirmCode, containerCode, countryCode);
    }
  };

  const createWorkspace = (geoCode, memberFirmCode, containerCode, countryCode) =>
  {
    countryCode = getCurrentCountryCode(countryCode);
    const engagementId = '';
    const projectName = constants.MyWorkspace;
    const projectTypeId = constants.ProjectTypes.WORKSPACE_PROJECT
    const GEO_PATH = countryCode
    ? `${geoCode}_${memberFirmCode}_${containerCode}_${countryCode}`
    : `${geoCode}_${memberFirmCode}_${containerCode}`;
    setConstainerSelectionModel(false);
    api.createProject({ projectName, countryCode, engagementId, projectTypeId}).then(res => {
    return props.history.push({
      pathname: `/region/${GEO_PATH}/project/${res.data.projectId}/creation`
    });
  })
  };

  const createProject = (geoCode, memberFirmCode, containerCode, countryCode) => {
     //this.setState({ showContainerSelectionModal: false });
    setConstainerSelectionModel(false);
    const GEO_PATH = countryCode
      ? `${geoCode}_${memberFirmCode}_${containerCode}_${countryCode}`
      : `${geoCode}_${memberFirmCode}_${containerCode}`;

    return props.history.push({
      pathname: `/region/${GEO_PATH}/project/creation`
    }); 
  };

  const onCreateProject = (projectType) => {
    const { geoCode, memberFirmCode, containerCode, memberFirmContainers } = props.currentUser;
    setprojectType(projectType);
    if (memberFirmContainers && memberFirmContainers.length > 1) {
      // Display member firm container selection modal
      setConstainerSelectionModel(true);
    } else {
      if(projectType == constants.ProjectTypes.WORKSPACE_PROJECT){
        // MyWorkspace creation
        return createWorkspace(geoCode, memberFirmCode, containerCode);
      }
      else if (projectType == constants.ProjectTypes.EXTRACTION_PROJECT)
      {
      // Normal case project creation
      return createProject(geoCode, memberFirmCode, containerCode);
      }
    }
  };

  const onUserModeChange = userModeValue => {
    setUserMode(userModeValue);
    props.onUserModeChange(userModeValue);
  };


  const changeSorting = sortingKey => {
    //const { sortKey, sortAsc } = this.state;
    const dir = sortingKey === sortKey ? !sortAsc : true;
    // this.setState({
    //   sortKey: sortingKey,
    //   sortAsc: dir
    // });
    setSortKey(sortingKey);
    setSortAsc(dir);
    props.onSortingChange({ key: sortingKey, asc: dir });
  };

  const setTableOrTileView = value => {
    const { currentUser } = props;
    if (currentUser) {
      localStorage.setItem(`project-list-display-mode-${currentUser.userId}`, JSON.stringify(value));
      setTableOrTile(value);
      props.setTableOrTileView(value);
    }
  };

  return (
    <div id="landing-page-tabs-container">
      <div className="landing-page-tabs">
        <Link
          to={{ pathname: '/' }}
          key="0"
          className={classNames(`landing-page-tabs__tab-button`, selectedTab === 0 && `landing-page-tabs__tab-button--selected`)}
          onClick={() => setSelectedTab(0)}
        >
          <FormattedMessage id="global.landing-page-tab.extraction" />
        </Link>

        <Link
          to={{ pathname: '/translation' }}
          key="1"
          className={classNames(`landing-page-tabs__tab-button`, selectedTab === 1 && `landing-page-tabs__tab-button--selected`)}
          onClick={() => setSelectedTab(1)}
        >
          <FormattedMessage id="global.landing-page-tab.translation" />
          {translations.translations.length > 99 ? (
            <div className={`landing-page-tabs__translation-status-icon-long`}>
              <div className={`landing-page-tabs__translation-status-count`}>{translations.translations.length}</div>
            </div>
          ) : (
            <div className={`landing-page-tabs__translation-status-icon`}>
              <div className={`landing-page-tabs__translation-status-count`}>{translations.translations.length}</div>
            </div>
          )}
        </Link>

        {Permissions.Global.canViewAdminAccess() && <Link
          to={{ pathname: '/admin-access' }}
          key="2"
          className={classNames(`landing-page-tabs__tab-button`, selectedTab === 2 && `landing-page-tabs__tab-button--selected`)}
          onClick={() => setSelectedTab(2)}
        >
          {Permissions.Global.canHaveGlobalAdminAccess() ? <FormattedMessage id="global.landing-page-tab.global-manager-dashboard" /> :
            <FormattedMessage id="global.landing-page-tab.country-manager-dashboard" />}
        </Link>}

        {(fromProjectListPage && (currentUser && currentUser.accessibleFeatures.filter(feature => feature.featureId === 0).length > 0)) ?
          (<div className="landing-page-tabs__project-list-toolbar-wrapper">
            <ToolBar
              onCreate={onCreateProject}
              showIcons={true}
              onIconClick={setTableOrTileView}
              iconValue={tableOrTile}
              onSortClick={changeSorting}
              sortValue={sortKey}
              loadState={{}}
              onUserModeChange={onUserModeChange}
              userMode={userMode}
              onContainerChange={onContainerChange}
              getCurrentContainer={getCurrentContainer}
              currentUser={props.currentUser}
              getCurrentMemberFirmCode={getCurrentMemberFirmCode}
              getContainerGEO={getContainerGEO}
              isWorkspaceButtonHidden = {isWorkspaceButtonHidden}
            />
          </div>) :
          (Permissions.Global.canViewAdminContainerDropdown() && (selectedTab === 2) && (!location.pathname.includes('admin-access'))) && <div className="admin-dashboard__container-dropdown">
            <select onChange={(e) => { onContainerChange(e.target.value) }}>
              {props.currentUser?.adminContainers?.map(
                (item) => <option key={item.containerCode} value={JSON.stringify(item)} selected={item.containerCode === getCurrentContainer() ? true : false}>{item.containerName}</option>
              )}
            </select>
          </div>
        }
      </div>
      <ContainerSelectionModal
        show={showContainerSelectionModal}
        containers={props.currentUser.memberFirmContainers}
        onCancel={() => setConstainerSelectionModel(false)}
        onSubmit={onContainerSelected}
      />

    </div>
  )

}

export default LandingPageTabs;