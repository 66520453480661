import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import Authorized from 'containers/authorization/authorization-container';
import { setCompareType } from 'store/compare-type';

class ProtectedRoute extends React.Component {
  static propTypes = {
    authenticateFunction: PropTypes.func,
    authenticated: PropTypes.bool,
    component: PropTypes.any,
    path: PropTypes.any,
    setCompareType: PropTypes.func
  };

  componentDidMount() {
    this.props.authenticateFunction();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.path) {
      if (
        this.props.path.includes('compare/:compareId/:documentId') &&
        nextProps.path.includes('document/:documentId')
      ) {
        return;
      }
      if (!nextProps.path.includes('compare')) {
        this.props.setCompareType('document');
      }
    }
  }

  conditionalRender = props => {
    const { component: Component } = this.props;

    if (this.props.authenticated) {
      return <Authorized component={Component} {...props} />;
    } else {
      return null;
    }
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return <Route {...rest} render={this.conditionalRender} />;
  }
}

const mapDispatchToProps = {
  setCompareType
};

export default connect(null, mapDispatchToProps)(ProtectedRoute);
