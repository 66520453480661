import constants from 'utils/constants';
import RolesHelper from 'permissions/helpers/roles';
import StatesHelper from 'permissions/helpers/states';

const ProjectVisualizationPermissions = {
  canView: project => {
    return (
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_VISITOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
      RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR)
    );
  },

  canEdit: project => {
    return (
      !StatesHelper.isProjectReadOnly(project) &&
      (RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CONTRIBUTOR) ||
        RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_OWNER) ||
        RolesHelper.doesProjectContainRole(project, constants.UserRoles.PROJECT_CREATOR))
    );
  }
};

export default ProjectVisualizationPermissions;
