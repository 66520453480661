import { ITEM_TYPES, TAG_TYPES } from 'models/project-item';
//import AuthContext from 'adal-angular';
import axios from 'axios';
import dateUtil from 'utils/dateUtil';
import EventTarget from 'utils/event-target';
import moment from 'moment';
import socket from 'utils/socket';
import Permissions from 'permissions/permissions';
import { cleanQuery } from 'utils/string-utils';
import { AuthenticationResult } from "@azure/msal-common";
import { PublicClientApplication } from "@azure/msal-browser";

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_CACHE_CONTROL = 'no-store';

const PROJECT_ITEM_TYPE_NAMES = {
  [ITEM_TYPES.FOLDER]: 'folders',
  [ITEM_TYPES.DOCUMENT]: 'documents'
};


export const eventTarget = new EventTarget();

let accessToken = "";
// Define the msal object for authentication
const scopes = [window.ARGUS.ENV.AD_CLIENT + "/.default"];//['openid']
const port = window.location.port ? `:${window.location.port}` : "";
const authority = `${window.ARGUS.ENV.AD_INSTANCE}/${window.ARGUS.ENV.AD_TENANT}/`;
//const redirectUri = `${window.location.protocol}//${window.location.hostname}${port}/`;
const redirectUri = `${window.location.protocol}//${window.location.hostname}${port}/authsuccess`;

const handleLoginResponse = async () => {
  let tokenResponse = await msalInstance.handleRedirectPromise();
  let accountObj;

  if (tokenResponse) {
    accountObj = tokenResponse.account;
  } else {
    accountObj = msalInstance.getAllAccounts()[0];
  }

  if (accountObj && tokenResponse) {
  } else if (accountObj) {
    try {
      tokenResponse = await msalInstance.acquireTokenSilent({
        account: msalInstance.getAllAccounts()[0],
        scopes,
      });
    } catch (err) {
      await msalInstance.acquireTokenRedirect({ scopes });
    }
  } else {
    await msalInstance.loginRedirect();
  }

  return tokenResponse;
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// API Initialization and Authentication STARTS ///////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

var initializeRegionalAPI = geo => {
  var newAPI = {
    geoCode: geo.GEO_CODE,
    api: axios.create({
      baseURL: geo.API_BASE_URL
    }),
    socketURL: geo.HUB_BASE_URL,
    label: geo.LABEL,
    geo: geo,
    flags: {
      authenticated: false
    },
    region: null
  };

  newAPI.api.defaults.timeout = 0;
  newAPI.api.defaults.headers.common['X-LANG-CTX'] = 'en-US';

  msalInstance = new PublicClientApplication({
    auth: {
      clientId: window.ARGUS.ENV.AD_CLIENT,
      authority: authority,
      redirectUri: redirectUri,
      knownAuthorities: [authority],
    },
    cache: {
      cacheLocation: "memoryStorage",
      storeAuthStateInCookie: true,
    },
  });

  // newAPI.api.interceptors.request.use(
  //   async (config) => {
  //     if (accessToken === "") {
  //       const scopeKey = [geo.AD_AUD + "/.default"];
  //       const tokenResponse = await msalInstance.acquireTokenSilent({
  //         account: msalInstance.getAllAccounts()[0],
  //         scopes: scopeKey,
  //       });
  //       accessToken = tokenResponse ? tokenResponse.accessToken : "";
  //       config.headers.Authorization = `Bearer ${accessToken}`;
  //       socket.setAccessToken(accessToken);
  //     }
  
  //     // config.headers.Authorization = `Bearer ${accessToken}`;
  //     // socket.setAccessToken(accessToken);
  //     return config;
  //   },
  //   (err) => Promise.reject(err)
  // );

  // Interceptor for catching errors when response type is blob
  newAPI.api.interceptors.response.use(undefined, err => {
    const { response, request } = err;

    if (
      request.responseType === 'blob' &&
      response.data instanceof Blob &&
      response.data.type &&
      response.data.type.toLowerCase().indexOf('json' !== -1)
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            response.data = JSON.parse(reader.result);
            resolve(Promise.reject(err));
          } catch (error) {
            reject(error);
          }
        };

        reader.onerror = () => {
          reject(err);
        };

        reader.readAsText(response.data);
      });
    }

    throw err;
  });

  // Interceptor for catching 401s and handling token refresh
  newAPI.api.interceptors.response.use(undefined, err => {
    const { response } = err;

    if (response && response.status === 401 && response.config && !response.config.__isRetryRequest) {
      response.config.__isRetryRequest = true;
      
      return new Promise(async (resolve, reject) => {
        const tokenResponse = await msalInstance.acquireTokenSilent({
          account: msalInstance.getAllAccounts()[0],
          scopes: [geo.AD_AUD + "/.default"]
        });
    
        if (tokenResponse) {
          accessToken = tokenResponse.accessToken;
          newAPI.api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          newAPI.flags.authenticated = true;
          newAPI.token = accessToken;
          err.config.headers.Authorization = `Bearer ${accessToken}`;
          socket.setAccessToken(accessToken);
          axios(err.config).then(resolve, reject);
        }
        resolve(tokenResponse ? true : false);
      });
    }

    if (response) {
      eventTarget.dispatchEvent(new CustomEvent('error', { detail: err }));
    }

    throw err;
  });

  return newAPI;
};



// Log in if not already authenticated, otherwise try to acquire a token
export function authenticate() {
  return new Promise(async (resolve, reject) => {
    // If loading in an iframe, don't try to authenticate
    // See https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/FAQs
    // Azure AD loads the app in an iframe during the token refresh process, we don't want this
    // iframe version of the app to authenticate and start making requests.
    if (window !== window.parent) {
      return;
    }

    // If we are not logged in, redirect to the login page
     const tokenResponse = await handleLoginResponse();

    if (APIS && APIS.length > 0) {
      authenticateAPIS(APIS[0], 0, () => {
        setInitialRegion();
        resolve();
        //resolve(tokenResponse ? true : false);
      });
    }
  });
}

// Recursive function to chain API token aquire
function authenticateAPIS(API, currentIndex, callback) {
  if (currentIndex >= APIS.length) {
    callback();
    return;
  }

  if (!API.flags.authenticated) {
    return new Promise(async (resolve, reject) => {
      const tokenResponse = await msalInstance.acquireTokenSilent({
        account: msalInstance.getAllAccounts()[0],
        scopes: [API.geo.AD_AUD + "/.default"]
      });
      if (tokenResponse) {
        API.token = tokenResponse ? tokenResponse.accessToken : "";
        API.flags.authenticated = true;
      }
    
      authenticateAPIS(APIS[currentIndex + 1], currentIndex + 1, callback);
    });
    
  
    
  } else {
    authenticateAPIS(APIS[currentIndex + 1], currentIndex + 1, callback);
  }
}

// Handle authentication success
export function authenticateSuccess() {
  msalInstance.handleRedirectPromise().then(handleLoginResponse);
}

// Handle logging out
export function userLogOut() {
  sessionStorage.clear();
  api
    .post(`/users/me/logout`).then(res =>{
      if(res.status)
        msalInstance.logoutRedirect({
          account: msalInstance.getAllAccounts()[0],
        })
    });
}

// Ensure logged out from aad (in response from socket logout event)
export function authContextLogout() {
  authContext.logOut();
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// API Initialization and Authentication ENDS ////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// API Globalization is controlled by 3 properties
// Geo:  This maps to the URL for the regional API that we connect to
// Memember Firm:  This gets set as a header to help us connect to the correct database
// Container:  This also gets set as a header to connect to the correct database

export function setAxiosLanguageHeaders(localeCode) {
  APIS.forEach(API => {
    API.api.defaults.headers.common['X-LANG-CTX'] = localeCode;
  });
}

// Initialize APIs for each GEO
export const APIS = window.ARGUS.ENV.GEOS
  // Filter out the disabled GEOs
  .filter(
    geo =>
      geo.GEO_CODE &&
      geo.GEO_CODE !== 'NONE' &&
      geo.API_BASE_URL &&
      geo.API_BASE_URL !== 'NONE' &&
      geo.HUB_BASE_URL &&
      geo.HUB_BASE_URL !== 'NONE'
  )
  .map(geo => initializeRegionalAPI(geo));

var authContext;
var msalInstance;
var homeAPI = null;
var currentAPI = null;
var crossGEOAPI = null;
var api = null;

setInitialRegion();

function setInitialRegion() {
  if (!currentAPI || (currentAPI && currentAPI.region === null)) {
    const currGeoCode = window.ARGUS.ENV.CURRENT_GEO;

    var apiIndex = currGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === currGeoCode) : 0;
    apiIndex = apiIndex > -1 ? apiIndex : 0;

    if (APIS[apiIndex].token) {
      APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
      socket.setAccessToken(APIS[apiIndex].token);
    }

    currentAPI = APIS[apiIndex];
    api = currentAPI.api;
  } else if (currentAPI && currentAPI.token) {
    currentAPI.api.defaults.headers.common['Authorization'] = `Bearer ${currentAPI.token}`;
    socket.setAccessToken(currentAPI.token);
    api = currentAPI.api;
  }
}

// Home Region is used for some pages UI such as project creation, and admin pages
function setHomeRegion(geoCode, memberFirmCode, containerCode) {
  homeAPI = {
    api: APIS.filter(API => API.geoCode === geoCode),
    region: {
      geoCode: geoCode,
      memberFirmCode: memberFirmCode,
      containerCode: containerCode
    },

    //TODO Remove references to these direct properties in the application
    geoCode: geoCode,
    memberFirmCode: memberFirmCode,
    containerCode: containerCode
  };
}

// Used to set current region based on region code
// Region codes are GEOCODE_MEMBERFIRMCODE_CONTAINERCODE  eg. AME_US_US
export function setCurrentRegion(combinedCode) {
  var codes = combinedCode.split('_');

  if (codes.length === 3 || codes.length === 4) {
    var targetRegion =
      codes.length === 3
        ? {
            geoCode: codes[0],
            memberFirmCode: codes[1],
            containerCode: codes[2]
          }
        : {
            geoCode: codes[0],
            memberFirmCode: codes[1],
            containerCode: codes[2],
            countryCode: codes[3]
          };

    APIS.forEach(API => {
      if (API.geoCode === targetRegion.geoCode) {
        API.region = targetRegion;

        API.api.defaults.headers.common['Authorization'] = `Bearer ${API.token}`;
        API.api.defaults.headers.common['X-MF-CTX'] = targetRegion.memberFirmCode;
        API.api.defaults.headers.common['X-CNTR-CTX'] = targetRegion.containerCode;
        API.api.defaults.headers.common['Cache-Control'] = DEFAULT_CACHE_CONTROL;

        //Set socket server
        socket.setRegion(API.geo.HUB_BASE_URL, codes[2], codes[1]);
        socket.setAccessToken(API.token);

        //Set API
        currentAPI = API;
        api = currentAPI.api; // API is a convenience so that all of the API calls below can use it directly
      }
    });
  }
}

export function getCurrentApiInfo() {
  return currentAPI.region;
}

export function getCurrentBaseAdminUrl() {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  adminapi.defaults.headers.common['X-ADMCNTR-CTX'] = containerCode;
  return adminapi.defaults.baseURL;
}

export function getCurrentBaseUrl() {
  return currentAPI.region
    ? window.ARGUS.ENV.GEOS.filter(geo => geo.GEO_CODE === currentAPI.region.geoCode)[0].API_BASE_URL
    : '';
}

export function getCurrentGeoCode() {
  return currentAPI.region !== null ? currentAPI.region.geoCode : '';
}

export function getCurrentRegion() {
  return currentAPI.region !== null
    ? `${currentAPI.region.geoCode}_${currentAPI.region.memberFirmCode}_${currentAPI.region.containerCode}`
    : '';
}

export function getHomeRegion() {
  return homeAPI !== null ? `${homeAPI.geoCode}_${homeAPI.memberFirmCode}_${homeAPI.containerCode}` : '';
}

export function getCurrentAPI() {
  return currentAPI.region;
}

/********************************************************************************

Localization (Translation)

********************************************************************************/

export function getLanguages() {
  return api.get('/translations/languages').then(response => {
    return response.data;
  });
}

export function getLanguageData(localeCode) {
  return api.get(`/translations/languages/${localeCode}`).then(response => {
    return response.data;
  });
}

/********************************************************************************

User

********************************************************************************/

export function getCurrentUser() {
  return api.get('/users/me').then(response => {
    var { geoCode, memberFirmCode, containerCode } = response.data.data;
    setHomeRegion(geoCode, memberFirmCode, containerCode);
    return response.data;
  });
}

export function getUser({ userId }) {
  return api.get(`/users/${userId}`, null, true).then(response => response.data);
}

export function getUsers(keyword, memberFirmOnly = false, adminOnly = false, adminContainer = false) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
  {
    return adminapi
    .post(`users/get?memberfirmonly=${memberFirmOnly}&adminOnly=${adminOnly}&adminContainer=${adminContainer}`,
    {keyword:keyword}, 
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
  }

  else
  {
    return api
    .post(`users/get?memberfirmonly=${memberFirmOnly}&adminOnly=${adminOnly}`,{keyword:keyword})
    .then(response => response.data);
  }  
}

export function getDocumentListColumnOptions() {
  return api.get('/users/me/preferences/documentListPage').then(response => response.data);
}

export function setDocumentListColumnOptions(columnOptions) {
  return api.put('/users/me/preferences/documentListPage', columnOptions).then(response => response.data);
}

export function getExtractionOverviewColumnOptions() {
  return api.get('/users/me/preferences/extractionOverviewPage').then(response => response.data);
}

export function setExtractionOverviewColumnOptions(columnOptions) {
  return api.put('/users/me/preferences/extractionOverviewPage', columnOptions).then(response => response.data);
}
export function saveGridView(projectId,templateId,extObj) {
  let  extractionFieldOverviewId=extObj.extractionFieldOverviewId;
   let extractionFieldOverviewName=extObj.extractionFieldOverviewName;
   let fields=extObj.fields
 
  return api.post(`/projects/${projectId}/templates/${templateId}/extractionfieldoverview`,
   {extractionFieldOverviewId,extractionFieldOverviewName,fields}).then(response => response.data);
}

export function deleteGridView(projectId,templateId,gridId) { 
  return api.delete(`/projects/${projectId}/templates/${templateId}/extractionfieldoverview/${gridId}`).then(response => response.data);
}



export function validateGridViewName(name,projectId) {
  return api.get(`/projects/${projectId}/validateoverviewname?extractionOverviewName=${name}`).then(response => response.data);
}

export function getGridViewDropdownData(projectId,templateId) {
 return api.get(`/projects/${projectId}/templates/${templateId}/extractionOverviewNames`).then(response => response.data);
}

export function getSavedExtractionField(projectId,templateId,gridId) {
  return api.get(`/projects/${projectId}/templates/${templateId}/extractionFieldOverview/${gridId}`).then(response => response.data);
 }
 



/********************************************************************************

Current Folder and File Upload

********************************************************************************/
export function getFolder({
  folderId = 'root',
  foldersFirst = true,
  includeFolders = true,
  pageNum = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  projectId,
  recursive = false,
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = '',
  filterIds = [],
  filterKiraFolders = false
} = {}) {
  var payload = {
    params: {
      options: `recursive:${recursive};flatten:${recursive};includefolders:${includeFolders}`,
      pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};foldersfirst:${foldersFirst};sortorder:${sortOrder}`,
      filterKiraFolders : filterKiraFolders
    }
  };

  // Filter Array
  if (filterBy.length > 0 && filterIds.length > 0) {
    payload.params.filter = `fieldname:${filterBy};ids:${filterIds.join()}`;
  }

  // Search Filter
  if (filterBy.length > 0 && filterString.length > 0) {
    payload.params.filter = `fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }

  return api.get(`projects/${projectId}/folders/${folderId}`, payload).then(response => response.data);
}

export function getFolderSummary({ folderId = 'root', projectId } = {}) {
  return api.get(`projects/${projectId}/folders/${folderId}/summary`).then(response => response.data.data);
}

export function getFolderFilterTagOptions({ folderId = 'root', projectId } = {}) {
  return api.get(`projects/${projectId}/folders/${folderId}/filters/tags`).then(response => response.data);
}

export function getFolderFilterTemplateOptions({ folderId = 'root', projectId } = {}) {
  return api.get(`projects/${projectId}/folders/${folderId}/filters/templates`).then(response => response.data);
}

export function getFolderFilterReviewerOptions({ folderId = 'root', projectId } = {}) {
  return api.get(`projects/${projectId}/folders/${folderId}/filters/reviewers`).then(response => response.data);
}

export function getFolderFilterAssigneeOptions({ folderId = 'root', projectId } = {}) {
  return api.get(`projects/${projectId}/folders/${folderId}/filters/assignees`).then(response => response.data);
}

export function moveItems({ folderId, projectId, projectItemsIdList, templateId }) {  
  return api.put(`/projects/${projectId}/documents/${folderId}/movedocuments`, {      
      Items:projectItemsIdList.map(items => items.projectItemId) 
    })
    .then(response => response.data);
}

export function renameItem({ projectId, itemName, itemTypeId, projectItemId }) {
  return api
    .put(`projects/${projectId}/${PROJECT_ITEM_TYPE_NAMES[itemTypeId]}/${projectItemId}?options=action:rename`, {
      itemName
    })
    .then(response => response.data);
}

export function reprocessItem({ projectId, items = [], templates }) {
  return api
    .put(`/projects/${projectId}/documents/batchreprocess`, { Items: items, templates })
    .then(response => response.data);
}

export function getItemTemplates({ projectId, items = [], templateID }) {
  
  return api
    .post(`projects/${projectId}/templates/get`, {
      Items: Array.isArray(items) ? items : [items]      
    },
    {
      params: {
        templateID: templateID
      }
    })
    .then(response => response.data)
    .catch(response => response.data);
}

export function assignTemplates({ projectId, templateId, items = [] }) {
  return Promise.all(
    items.map(projectItem => {
      return api
        .put(
          `projects/${projectId}/${PROJECT_ITEM_TYPE_NAMES[projectItem.itemTypeId]}/${
            projectItem.projectItemId
          }/templates/${templateId}`
        )
        .then(response => response.data);
    })
  );
}

export function getProjectItemAssignments({ projectId, projectItemIds = [] }) {
  return api
    .post(`projects/${projectId}/assignments/get`, { Items: projectItemIds })
    .then(response => response.data)
    .catch(response => response.data);
}

export function createProjectItemAssignments({ projectId, projectItemIds = [], userId }) {
  return api
    .post(
      `projects/${projectId}/assignments`,
      { Items: projectItemIds },
      {
        params: {
          globalPersonId: userId
        }
      }
    )
    .then(response => response.data)
    .catch(response => response.data);
}

export function deleteProjectItemAssignments({ projectId, projectItemIds = [], userId }) {
  return api
    .post(
      `projects/${projectId}/assignments/delete`,
      { Items: projectItemIds },
      {
        params: {
          globalPersonId: userId
        }
      }
    )
    .then(response => response.data)
    .catch(response => response.data);
}

// Individual delete
// Todo: change this to single api call
export function deleteItems({ projectId, items = [] }) {
  return Promise.all(
    items.map(projectItem => {
      return api
        .delete(`projects/${projectId}/${PROJECT_ITEM_TYPE_NAMES[projectItem.itemTypeId]}/${projectItem.projectItemId}`)
        .then(response => response);
    })
  );
}

// Batch delete job
export function deleteAllItems({ projectId, ids = [] }) {
  return api.post(`projects/${projectId}/documents/batchdelete`, { Items: ids }).then(response => response);
}

export function upload({
  cancelToken,
  file,
  onCancel,
  onFail,
  onProgress,
  onSuccess,
  parentId,
  path,
  projectId,
  projectTemplateId
}) {
  const formData = new FormData();
  formData.append('path', path);
  formData.append('parentId', parentId);
  formData.append('file', file);
  formData.append('projectTemplateId', projectTemplateId);

    return api
  .post(`projects/${projectId}/documents`,formData,{
    cancelToken,
    onUploadProgress: onProgress
  })
  .then(response => {
    onSuccess(response.data.data);
  })
  .catch(thrown => {
    if (axios.isCancel(thrown)) {
      onCancel(thrown);
    } else {
      onFail(thrown);
    }
  });

}

export function createFolder({ folderName, projectId, parentId }) {
  const payload = {
    projectId: parseInt(projectId, 10),
    projectItemId: 0,
    itemTypeId: 1,
    itemName: folderName,
    projectItemStatus: '',
    projectTemplate: '',
    parentId: folderIdToApiFormat(parentId),
    itemStateId: 0,
    reviewers: [],
    tags: [],
    projectTemplates: [],
    lastUpdatedDate: dateUtil.toServerDate(moment()),
    lastUpdatedBy: 0,
    createdDate: dateUtil.toServerDate(moment()),
    createdBy: 0
  };

  return api.post(`projects/${projectId}/folders`, payload).then(response => response.data);
}

function folderIdToApiFormat(folderId) {
  if (folderId === 'root') {
    return null;
  } else {
    return parseInt(folderId, 10);
  }
}

/********************************************************************************

Export

********************************************************************************/

export function getExports({ projectId, exportType }) {
  return api.get(`projects/${projectId}/exports`, { params: { exportType } }).then(response => response.data);
}

/*
 Example payload for createExport

{
  exportFileName = "Export-1"
  exportStateDate = "2018-02-21 10:11:47.2833333 +00:00"
  exportOption: '0' (0 = excel; 1 = pdf; 2 = word),
  layout: '0' (0 = row, 1 = column),
  tagsOptions: '0' (0 = all; 1 = documenttag; 2 = extractionfieldtag),
  templates: [
    {
      projectTemplateId: 9,
      extractionFields: [
        {
          extractionFieldId: 28
        }
      ]
    }
  ],
  items: [
    {
      projectItemId: '1',
      itemTypeId: '1' (1 = folder; 2 = document)
    }
  ]
};
*/
export function createExport({ projectId, exportOptions }) {
  return api.post(`projects/${projectId}/exports`, exportOptions).then(response => response.data);
}

export function deleteExport({ projectId, exportId }) {
  return api.delete(`projects/${projectId}/exports/${exportId}`).then(response => response.data);
}

export function getExportBinary({ projectId, exportId }) {
   return api.get(`projects/${projectId}/exports/${exportId}/binary`,{ responseType: 'blob' });
}

export function getExportToken({ projectId, exportId }) {
  return api.get(`projects/${projectId}/exports/${exportId}/token`).then(response => response.data);
}

/********************************************************************************

Tags

********************************************************************************/

export function getProjectTags({ projectId }) {
  return api.post(`projects/${projectId}/tags/get`).then(response => response.data);
}

export function createProjectTag({ projectId, tagName, tagTypeId = TAG_TYPES.USER }) {
  return api.post(`projects/${projectId}/tags`, { tagName, tagTypeId }).then(response => response.data);
}

export function getItemTags({ projectId, items = [] }) {
  return api
    .post(`/projects/${projectId}/tags/get`, { Items: items })
    .then(response => response.data)
    .catch(response => response.data);
}

export function addItemTag({ projectId, items = [], tag }) {
  return api
    .post(`/projects/${projectId}/tags/${tag.tagId}/projectitems/batchassign`, {
      Items: items
    })
    .then(response => response.data)
    .catch(response => response.data);
}

export function removeItemTag({ projectId, items = [], tag }) {
  return api
    .post(`/projects/${projectId}/tags/${tag.tagId}/projectitems/batchdelete`, { Items: items })
    .then(response => response.data)
    .catch(response => response.data);
}

export function addExtractionFieldRecordTag(projectId, documentId, extractionFieldRecordId, tagId) {
  return api
    .post(
      `projects/${projectId}/documents/${documentId}/extractionfieldrecords/${extractionFieldRecordId}/tags/${tagId}`,
      {}
    )
    .then(response => response.data);
}

export function removeExtractionFieldRecordTag(projectId, documentId, extractionFieldRecordId, tagId) {
  return api
    .delete(
      `projects/${projectId}/documents/${documentId}/extractionfieldrecords/${extractionFieldRecordId}/tags/${tagId}`
    )
    .then(response => response.data);
}

export function organizeDocuments(projectId, documentId, method, start, length) {
  const payload = {
    start: start,
    length: length
  };
  return api
    .request({ url: `projects/${projectId}/documents/${documentId}/organize`, method: method, data: payload })
    .then(response => response.data);
}

export function updateSampleNumber(projectId, documentId, start, sampleNumber) {
  const payload = {
    sample: sampleNumber
  };
  return api
    .put(`projects/${projectId}/documents/${documentId}/organize/${start}`, payload)
    .then(response => response.data);
}

export function removeDocType(projectId, documentId, sectionStart) {
  return api
    .delete(`projects/${projectId}/documents/${documentId}/organize/${sectionStart}`)
    .then(response => response.data);
}

/********************************************************************************

Document Viewer and Extraction Fields

********************************************************************************/

export function getDocument(projectId, documentId) {
  return api.get(`projects/${projectId}/documents/${documentId}`).then(response => response.data);
}

export function getDocumentLayout(projectId, documentId) {
  return api.get(`projects/${projectId}/documents/${documentId}/layout`).then(response => response.data);
}

export function getDocumentLayoutMetadata(projectId, documentId) {
  return api.get(`projects/${projectId}/documents/${documentId}/layout/metadata`).then(response => response.data);
}

export function getDocumentCharacters(projectId, documentId) {
  return api.get(`projects/${projectId}/documents/${documentId}/layout/data`).then(response => response.data);
}

export function getDocumentPageCharacters(projectId, documentId, pageNumber) {
  return api
    .get(`projects/${projectId}/documents/${documentId}/layout/data?start=${pageNumber}&count=1`)
    .then(response => response.data);
}

export function getDocumentBinary(projectId, documentId) {
  
    return api
    .get(`projects/${projectId}/documents/${documentId}/binary`,{responseType: 'arraybuffer'})
    .then(response => response.data);
}

export function getDocumentPageBinary(projectId, documentId, pageNumber, thumbnail = false) {
  
    return api
    .get(`projects/${projectId}/documents/${documentId}/binary/${pageNumber}${thumbnail ? '?thumbnail=true' : ''}`
    ,{
      responseType: 'arraybuffer'
    })
    .then(response => response.data);
}

export function getNextDocument(projectId, documentId) {
  return api.get(`projects/${projectId}/documents/${documentId}/next`).then(response => response.data);
}

export function getPreviousDocument(projectId, documentId) {
  return api.get(`projects/${projectId}/documents/${documentId}/prev`).then(response => response.data);
}

export function getDocumentsInFolder(projectId, folderId, pageNum, pageSize) {
  return api
    .get(`projects/${projectId}/folders/${folderId}`, {
      params: {
        options: `recursive:false;flatten:false;includefolders:false`,
        pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:name;foldersfirst:false;sortorder:ascending`
      }
    })
    .then(response => response.data.data);
}

export function markDocumentAsReviewed(projectId, documentId) {
  return api.post(`projects/${projectId}/documents/${documentId}/review`).then(response => response.data.data);
}

export function markDocumentAsNotReviewed(projectId, documentId) {
  return api.delete(`projects/${projectId}/documents/${documentId}/review`).then(response => response.data.data);
}

export function markAllDocumentsAsReviewed({ projectId, documentIds = [] }) {
  return api
    .post(`projects/${projectId}/documents/batchreview`, { Items: documentIds })
    .then(response => response.data);
}

export function getExtractionFields() {  
  return api.get(`extractionfields?includepublicfields=true`).then(response => response.data);   
}

export function getExtractionFieldsByCategoryID(templateCategoryID) {  
  return api.get(`extractionfields?templateCategoryID=${templateCategoryID}&includepublicfields=true`).then(response => response.data);   
}

export function startExtractionFieldTraining(extractionFieldId, projectIds) {
  return api
    .post(`trainingrequests/${extractionFieldId}/trainrequest`, { ProjectIds: projectIds })
    .then(response => response.data);
}

export function getProjectExtractionFields(projectId) {
  return api.get(`projects/${projectId}/extractionsummary`).then(response => response.data);
}

export function getProjectTemplateExtractionFields(projectId, templateId) {
  return api.get(`projects/${projectId}/templates/${templateId}/extractionfields`).then(response => {
    response.data.projectId = projectId;
    response.data.templateId = templateId;
    return response.data;
  });
}

export function createExtractionField(name, description) {
  const payload = {
    extractionFieldName: name,
    extractionFieldDescription: description
  };
  
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

  if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
  {
    return adminapi.post(`extractionfields`, payload,
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }).then(response => response.data);
  }
  else
  {
    return api.post(`extractionfields`, payload).then(response => response.data);
  }
  
}

export function createProjectExtractionField(projectId, name, description) {
  const payload = {
    extractionFieldName: name,
    extractionFieldDescription: description
  };

  return api.post(`projects/${projectId}/extractionfields`, payload).then(response => response.data);
}

export function createExtractionFieldAndAddToTemplate(name, description, templateId, projectId) {
  const payload = {
    extractionFieldName: name,
    extractionFieldDescription: description
  };

  return api
    .post(`projects/${projectId}/templates/${templateId}/extractionfields`, payload)
    .then(response => response.data);
}

export function addExtractionFieldToTemplate(projectId, templateId, extractionFieldId, groupId) {
  const groupMapping = groupId ? `/groups/${groupId}` : '';
  return api
    .post(`projects/${projectId}/templates/${templateId}/extractionfields/${extractionFieldId}${groupMapping}`)
    .then(response => response.data);
}

//6.0.1 Create extraction field, associate to template, and create extraction field record
export function combinedCreate({
  name,
  description,
  templateId,
  projectId,
  documentId,
  location,
  text,
  pageNumber,
  extractionFieldGroupId = null
}) {
  const payload = {
    templateId: templateId,
    documentId: documentId,
    extractionFieldName: name,
    extractionFieldDescription: description,
    ...(extractionFieldGroupId && { extractionFieldGroupId: extractionFieldGroupId }),
    location: location.sort((a, b) => (a < b ? -1 : b < a ? 1 : 0)),
    extractFieldInstanceData: text,
    pageNumber: pageNumber
  };

  return api.post(`projects/${projectId}/extractionfieldrecords/create`, payload).then(response => response.data);
}

//6.0.1 Associate existing extraction field to template, and create extraction field record
export function combinedAssociate({
  extractionFieldId,
  templateId,
  projectId,
  documentId,
  location,
  text,
  pageNumber,
  extractionFieldGroupId
}) {
  const payload = {
    templateId: templateId,
    documentId: documentId,
    extractionFieldId: extractionFieldId,
    location: location.sort((a, b) => (a < b ? -1 : b < a ? 1 : 0)),
    extractFieldInstanceData: text,
    pageNumber: pageNumber,
    extractionFieldGroupId: extractionFieldGroupId
  };

  return api.post(`projects/${projectId}/extractionfieldrecords/create`, payload).then(response => response.data);
}

export function createExtractionFieldRecord(projectId, documentId, extractionFieldId, location, text, page) {
  const payload = {
    extractionFieldId: extractionFieldId,
    location: location.sort((a, b) => (a < b ? -1 : b < a ? 1 : 0)),
    extractFieldInstanceData: text,
    pageNumber: page
  };

  return api
    .post(`projects/${projectId}/documents/${documentId}/extractionfieldrecords`, payload)
    .then(response => response.data);
}

export function editExtractionFieldRecord(projectId, documentId, fieldId, recordId, text) {
  return api
    .put(`projects/${projectId}/documents/${documentId}/extractionfieldrecords/${recordId}`, {
      extractFieldInstanceData: text,
      extractionFieldId: fieldId
    })
    .then(response => response.data);
}

export function deleteExtractionFieldRecord(projectId, documentId, recordId) {
  return api
    .delete(`projects/${projectId}/documents/${documentId}/extractionfieldrecords/${recordId}`)
    .then(response => response.data);
}

export function deleteAllExtractionFieldRecordsForField(projectId, documentId, fieldId) {
  return api
    .delete(`projects/${projectId}/documents/${documentId}/extractionfields/${fieldId}`)
    .then(response => response.data);
}

export function moveExtractionRecord(projectId, documentId, fieldId, ids) {
  return api
    .put(`projects/${projectId}/documents/${documentId}/extractionfields/${fieldId}/recorddisplayorder`, {
      extractionFieldRecordIds: ids
    })
    .then(response => response.data);
}

export function undoDocumentActions(undoId) {
  return api.post(`undo/${undoId}`).then(response => response.data);
}

/********************************************************************************

Groups Admin

********************************************************************************/

export function getExtractionFieldGroups({
  pageNum = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = '',
  fields = 'Exclude:extractionfields'
} = {}) {
  let filter;
  // Search Filter
  if (filterBy.length > 0 && filterString.length > 0) {
    filter = `fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
 
if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
  .get('extractionfields/groups', {
    params: {
      pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
      filter,
      fields
    },
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  })
  .then(response => response.data);
}
else
{return api
  .get('extractionfields/groups', {
    params: {
      pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
      filter,
      fields
    }
  })
  .then(response => response.data);

}
  
}

export function createExtractionFieldGroup({ name }) {

  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .post('extractionfields/groups', {
      extractionFieldGroupName: name
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }
    )
    .then(response => response.data);
}
else
{
  return api
    .post('extractionfields/groups', {
      extractionFieldGroupName: name
    }
    )
    .then(response => response.data);
}
  
}

export function updateExtractionFieldGroup({ groupId, groupName }) {

  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .put(`extractionfields/groups/${groupId}`, {
      extractionFieldGroupName: groupName
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
}
else
{
  return api
    .put(`extractionfields/groups/${groupId}`, {
      extractionFieldGroupName: groupName
    })
    .then(response => response.data);
}
  
}

export function getAllExtractionFields({
  pageNum = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = ''
} = {}) {
  let filter;
  // Search Filter
  if (filterBy.length > 0 && filterString.length > 0) {
    filter = `fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }

  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
  .get(`extractionfields/`, {
    params: {
      pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
      filter
    },
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  })
  .then(response => response.data);
}
else
{
  return api
  .get(`extractionfields/`, {
    params: {
      pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
      filter
    }
  })
  .then(response => response.data);
}

 
}

export function getExtractionFieldsForGroup(
  groupId,
  {
    pageNum = 1,
    pageSize = DEFAULT_PAGE_SIZE,
    sortBy = 'name',
    sortOrder = 'ascending',
    filterBy = '',
    filterString = ''
  } = {}
) {
  let filter;
  // Search Filter
  if (filterBy.length > 0 && filterString.length > 0) {
    filter = `fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }

  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  
if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .get(`extractionfields/groups/${groupId}`, {
      params: {
        pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
        filter
      },
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }

    })
    .then(response => response.data);
}
else
{
  return api
    .get(`extractionfields/groups/${groupId}`, {
      params: {
        pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
        filter
      }
    })
    .then(response => response.data);
}  
}

export function deleteExtractionFieldGroup(groupId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.delete(`extractionfields/groups/${groupId}`,{
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.delete(`extractionfields/groups/${groupId}`).then(response => response.data);
  
}
  
}

export function addGroupToGroup({ fromGroupId, toGroupId }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .post(`extractionfields/groups/${fromGroupId}`, {
      extractionFieldGroupId: toGroupId
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
  
    })
    .then(response => response.data);
}
else
{
  return api
    .post(`extractionfields/groups/${fromGroupId}`, {
      extractionFieldGroupId: toGroupId
    })
    .then(response => response.data);
}
  
}

export function addUserToGroup({ groupId, userId, roleId }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .post(`extractionfields/groups/${groupId}/users`, {
      userId,
      roles: [{ roleId }]
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
}
else
{
  return api
    .post(`extractionfields/groups/${groupId}/users`, {
      userId,
      roles: [{ roleId }]
    })
    .then(response => response.data);
}
  
}

export function addExtractionFieldToGroup({ extractionFieldId, groupId }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .post(`extractionfields/${extractionFieldId}/groups`, {
      extractionFieldGroupId: groupId
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
}
else
{
  return api
    .post(`extractionfields/${extractionFieldId}/groups`, {
      extractionFieldGroupId: groupId
    })
    .then(response => response.data);
}
  
}

export function removeExtractionFieldFromGroup({ extractionFieldId, groupId }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.delete(`extractionfields/${extractionFieldId}/groups/${groupId}`,{
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.delete(`extractionfields/${extractionFieldId}/groups/${groupId}`).then(response => response.data);
}
  
}

export function addUserToExtractionField({ extractionFieldId, userId, roleId }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .post(`extractionfields/${extractionFieldId}/users`, {
      userId,
      roles: [{ roleId }]
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
}
else
{
  return api
    .post(`extractionfields/${extractionFieldId}/users`, {
      userId,
      roles: [{ roleId }]
    })
    .then(response => response.data);
}
  
}

export function removeUserFromExtractionField({ extractionFieldId, userId }) {
  
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.delete(`extractionfields/${extractionFieldId}/users/${userId}`,{
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.delete(`extractionfields/${extractionFieldId}/users/${userId}`).then(response => response.data);
}
}

export function updateExtractionFieldUser({ extractionFieldId, userId, roleId }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .put(`extractionfields/${extractionFieldId}/users`, {
      userId,
      roles: [{ roleId }]
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
}
else
{
  return api
    .put(`extractionfields/${extractionFieldId}/users`, {
      userId,
      roles: [{ roleId }]
    })
    .then(response => response.data);
}
  
}

export function getExtractionFieldDetails(extractionFieldId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.get(`extractionfields/${extractionFieldId}`,{
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.get(`extractionfields/${extractionFieldId}`).then(response => response.data);
}
  
}

export function deleteExtractionField(extractionFieldId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.delete(`extractionfields/${extractionFieldId}`,{
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.delete(`extractionfields/${extractionFieldId}`).then(response => response.data);
}
  
}

export function getExtractionFieldProjects(extractionFieldId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.get(`extractionfields/${extractionFieldId}/projects`,
  {
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.get(`extractionfields/${extractionFieldId}/projects`).then(response => response.data);
}  
}

export function getExtractionFieldHistory(extractionFieldId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.get(`extractionfields/${extractionFieldId}/history`,
  {
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.get(`extractionfields/${extractionFieldId}/history`).then(response => response.data);
}  
}

export function updateExtractionField({ extractionFieldId, extractionFieldName, extractionFieldDescription }) {
  const payload = extractionFieldDescription
    ? {
        extractionFieldName,
        extractionFieldDescription
      }
    : {
        extractionFieldName,
        updateNameOnly: true
      };
      const containerCode =  sessionStorage.getItem('selected-container');
      const memberfirm =  sessionStorage.getItem('selected-memberfirm');
      const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');
    
      var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
      apiIndex = apiIndex > -1 ? apiIndex : 0;
    
      if (APIS[apiIndex].token) {
        APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
        socket.setAccessToken(APIS[apiIndex].token);
      }
    
      var adminapi = APIS[apiIndex].api;
      adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

    if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
    {
      return adminapi.put(`extractionfields/${extractionFieldId}`, payload,
      {
        headers: {
          ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
        }
      }).then(response => response.data);
    }
    else
    {
      return api.put(`extractionfields/${extractionFieldId}`, payload).then(response => response.data);
    }
  
}

export function trainExtractionFields({ extractionFieldIds, isGroup }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .put(`extractionfields/train`, {
      extractionFields: extractionFieldIds,
      isGroup
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
}
else
{
  return api
    .put(`extractionfields/train`, {
      extractionFields: extractionFieldIds,
      isGroup
    })
    .then(response => response.data);
}  
}

export function trainExtractionField({ extractionFieldId, projectIds, includePermanent }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .put(`extractionfields/${extractionFieldId}/train`, {
      includePermanent,
      extractionFieldProjects: projectIds.map(projectId => ({
        projectId
      }))
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }
    )
    .then(response => response.data);
}
else
{
  return api
    .put(`extractionfields/${extractionFieldId}/train`, {
      includePermanent,
      extractionFieldProjects: projectIds.map(projectId => ({
        projectId
      }))
    }
    )
    .then(response => response.data);
}  
}

export function getExtractionFieldValidation(extractionFieldId) {
  const containerCode =  sessionStorage.getItem('selected-container'); 
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.get(`extractionfields/${extractionFieldId}/validation`,
  {
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.get(`extractionfields/${extractionFieldId}/validation`).then(response => response.data);
}
  
}

export function importExtractionFieldModel({ groupId, file }) {
  const formData = new FormData();
  formData.append('file', file);

  if (groupId) {
    formData.append('extractionfieldgroupid', groupId);
  }

  return api.post(`extractionfields/imports`,formData);
  
}

export function createExtractionFieldExport(ids) {
  const currentDate = moment();
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .post('extractionfields/exports', {
      exportEntityIDs: ids,
      exportFileName: `ExtractionField ${dateUtil.formatTimestamp(currentDate)}`,
      exportType: 3,
      fileExtension: '.zip'
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
}
else
{
  return api
    .post('extractionfields/exports', {
      exportEntityIDs: ids,
      exportFileName: `ExtractionField ${dateUtil.formatTimestamp(currentDate)}`,
      exportType: 3,
      fileExtension: '.zip'
    })
    .then(response => response.data);
}  
}

export function createExtractionFieldGroupExport(ids) {
  const currentDate = moment();
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .post('extractionfields/groups/exports', {
      exportEntityIDs: ids,
      exportFileName: `ExtractionField ${dateUtil.formatTimestamp(currentDate)}`,
      exportType: 3,
      fileExtension: '.zip'
    },
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
}
else
{
  return api
    .post('extractionfields/groups/exports', {
      exportEntityIDs: ids,
      exportFileName: `ExtractionField ${dateUtil.formatTimestamp(currentDate)}`,
      exportType: 3,
      fileExtension: '.zip'
    })
    .then(response => response.data);
}
}

export function getExtractionFieldExports() {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.get(`extractionfields/exports`,
  {
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.get(`extractionfields/exports`).then(response => response.data);
}
  
}

export function deleteExtractionFieldExport(exportDetailId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.delete(`extractionfields/exports/${exportDetailId}`,
  {
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.delete(`extractionfields/exports/${exportDetailId}`).then(response => response.data);
}
  
}

export function getExtractionFieldExportBinary(exportDetailId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
   return adminapi.get(`extractionfields/exports/${exportDetailId}/binary`,{ headers: {
    ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
  }, responseType: 'blob', });
}
else
{
   return api.get(`extractionfields/exports/${exportDetailId}/binary`,{ responseType: 'blob' });
}
  
}

export function getExtractionFieldExportToken(exportDetailId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.get(`extractionfields/exports/${exportDetailId}/token`,
  {
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.get(`extractionfields/exports/${exportDetailId}/token`).then(response => response.data);
}
  
}

export function revertHistoryModel({ extractionFieldId, trainingDetailId }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.put(`extractionfields/${extractionFieldId}/revert/${trainingDetailId}`,null,
  {
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.put(`extractionfields/${extractionFieldId}/revert/${trainingDetailId}`).then(response => response.data);
}
  
}

export function setAutomaticExtraction({ extractionFieldId, isAutomaticExtraction }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi
    .put(`extractionfields/${extractionFieldId}/autoextract/${isAutomaticExtraction}`,null,
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(response => response.data);
}
else
{
  return api
    .put(`extractionfields/${extractionFieldId}/autoextract/${isAutomaticExtraction}`)
    .then(response => response.data);
}
  
}

export function publishExtractionField({ extractionFieldId, publish }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.put(`extractionfields/${extractionFieldId}/publish/${publish}`,null,
  {
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.put(`extractionfields/${extractionFieldId}/publish/${publish}`).then(response => response.data);
}
  
}

export function makeExtractionModelPermanent({ extractionFieldId }) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
{
  return adminapi.put(`extractionfields/${extractionFieldId}/markpermanent`,null,
  {
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(response => response.data);
}
else
{
  return api.put(`extractionfields/${extractionFieldId}/markpermanent`).then(response => response.data);
} 
}

export function getTrainingRequestsSummary() {
  const containerCode =  sessionStorage.getItem('selected-container');  
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');
 
  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

  if(containerCode != null)
  {
    return adminapi.get(`trainingrequests/summary`, {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }).then(response => response.data);
  }
  else
  {
    return api.get(`trainingrequests/summary`).then(response => response.data);
  }
  
}

export function getTrainingRequestsList({
  trainingRequestType = '',
  pageNum = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = ''
} = {}) {
  let filter;
  // Search Filter
  if (filterBy.length > 0 && filterString.length > 0) {
    filter = `fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');
 
  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

  if(containerCode != null){
    return adminapi
      .get(`trainingrequests/list`, {
        params: {
          trainingRequestType: `${trainingRequestType}`,
          pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
          filter
        },
        headers: {
          ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
        }
      })
      .then(response => response.data);
  }
  else{
    return adminapi
      .get(`trainingrequests/list`, {
        params: {
          trainingRequestType: `${trainingRequestType}`,
          pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
          filter
        }
      })
      .then(response => response.data);
  }
}

/********************************************************************************

Comparisons

********************************************************************************/

export function getComparisonSets({
  projectId,
  pageNum,
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = '',
  filterIds = [],
  compareType = 'document'
}) {
  var payload = {
    params: {
      compareType: compareType
    }
  };

  // Pagination
  if (pageNum !== undefined) {
    payload.params.pagination = `pagenum:${pageNum};pagesize:${DEFAULT_PAGE_SIZE};sortby:${sortBy};sortorder:${sortOrder}`;
  }

  // Filter Array
  if (filterBy.length > 0 && filterIds.length > 0) {
    payload.params.filter = `fieldname:${filterBy};ids:${filterIds.join()}`;
  }

  // Search Filter
  if (filterBy.length > 0 && filterString.length > 0) {
    payload.params.filter = `fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }

  return api.get(`projects/${projectId}/comparisons`, payload).then(response => response.data);
}

export function getComparisonSetFilterBaseDocumentOptions({ projectId, compareType = 'document' } = {}) {
  return api
    .get(`projects/${projectId}/comparisons/filters/basenames?compareType=${compareType}`)
    .then(response => response.data);
}

export function getComparisonSetDetails({ projectId, compareId, compareType = 'document' }) {
  return api
    .get(`projects/${projectId}/comparisons/${compareId}?compareType=${compareType}`)
    .then(response => response.data);
}

export function deleteComparisonSet({ projectId, compareId, compareType = 'document' }) {
  return api
    .delete(`projects/${projectId}/comparisons/${compareId}?compareType=${compareType}`)
    .then(response => response.data);
}

export function removeDocumentFromComparison({ projectId, compareId, documentId, compareType = 'document' }) {
  return api
    .delete(`projects/${projectId}/comparisons/${compareId}/${documentId}?compareType=${compareType}`)
    .then(response => response.data);
}

export function getComparisonBaseDocument({ projectId, compareId, compareType = 'document' }) {
  return api
    .get(`projects/${projectId}/comparisons/${compareId}/base?compareType=${compareType}`)
    .then(response => response.data);
}

export function getComparisonSummary({ projectId, compareId, compareType = 'document' }) {
  return api
    .get(`projects/${projectId}/comparisons/${compareId}/summary?compareType=${compareType}`)
    .then(response => response.data);
}

export function getComparisonDocument({ projectId, compareId, documentId, compareType = 'document' }) {
  return api
    .get(`projects/${projectId}/comparisons/${compareId}/${documentId}?compareType=${compareType}`)
    .then(response => response.data);
}

export function validateComparisonName({ projectId, comparisonName, compareType = 'document' }) {
  return api
    .get(`projects/${projectId}/comparisons/validatename?comparisonname=${comparisonName}&compareType=${compareType}`)
    .then(response => response.data);
}

export function createComparison({
  projectId,
  comparisonName,
  baseEntityId,
  documentIds = [],
  compareType = 'document'
}) {
  const payload = {
    baseEntityId: baseEntityId,
    comparisonName: comparisonName,
    comparisonDetails: documentIds.map(id => ({
      compareEntity: {
        entityId: id
      }
    })),
    projectId
  };

  return api
    .post(`projects/${projectId}/comparisons?compareType=${compareType}`, payload)
    .then(response => response.data);
}

export function updateComparison({ projectId, comparison, comparedDocuments, compareType = 'document' }) {
  const existingCompareDocuments = {
    [comparison.baseEntityId]: true
  };
  comparison.comparisonDetails.forEach(details => {
    existingCompareDocuments[details.compareEntity.referenceId] = true;
  });

  const payload = {
    ...comparison,
    comparisonDetails: [
      ...comparison.comparisonDetails,
      ...comparedDocuments
        // Don't send documents that are already in the comparisonDetails list
        .filter(document => !existingCompareDocuments[document.projectItemId])
        .map(document => ({
          compareEntity: {
            entityId: document.projectItemId
          }
        }))
    ]
  };

  return api
    .put(`projects/${projectId}/comparisons?compareType=${compareType}`, payload)
    .then(response => response.data);
}

/********************************************************************************

Project List

********************************************************************************/
export function getProjects(geo) {
  geo.api.defaults.headers.common['Authorization'] = `Bearer ${geo.token}`;
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  geo.api.defaults.headers.common['X-MF-CTX'] = memberfirm;

if(containerCode != null)
{
  return geo.api.get(`projects`,{
    headers: {
      ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
    }
  }).then(r => {
    r.data.data.forEach(p => {
      // Add server label for list of documents page
      p.geoCode = geo.geoCode;
      p.label = geo.geoCode;
    });
    return r;
  });
}

else
{
  return geo.api.get(`projects`).then(r => {
    r.data.data.forEach(p => {
     // Add server label for list of documents page
    p.geoCode = geo.geoCode;
    p.label = geo.geoCode;
    });
    return r;
  });
}
}

export function getProject({ projectId }) {  
  return api.get(`projects/${projectId}`).then(response => response.data);
}

export function deleteProject({ projectId }) {
  return api.delete(`projects/${projectId}`).then(response => response.data);
}

export function enableSupportAccess({ projectId, allowAccess }) {
  return api.put(`projects/${projectId}/projectaccess`, { allowAccess: allowAccess }).then(response => response.data);
}

export function restoreProject({ projectId }) {
  return api.post(`projects/${projectId}/undelete`).then(response => response.data);
}

export function createProject({ projectName, countryCode, engagementId, projectTypeId }) {
  return api.post(`projects`, { projectName, countryCode, engagementId, projectTypeId }).then(response => response.data);
}

export function saveDefaultProjectDateFormat(projectId) {
  return api.get(`projects/${projectId}/defaultdateformat`).then(response => response.data);
}

export function saveProjectTemplateDocType(projectId, templateId, docTypeName) {
  return api.put(`projects/${projectId}/templates/${templateId}/docType`, { DocTypeName : docTypeName }).then(response => response.data);
}

export function createProjectManually({
  projectName,
  countryCode,
  engagementName,
  externalEngagementId,
  clientName,
  clientId,
  fiscalYearEnd,
  sourceType,
  wbsCode,
  projectTypeId 
}) {
  return api
    .post(`projects`, {
      projectName,
      countryCode,
      engagementName,
      externalEngagementId,
      clientName,
      clientId,
      fiscalYearEnd,
      sourceType,
      wbsCode,
      projectTypeId 
    })
    .then(response => response.data);
}

export function copyProject({ projectId, includeDocuments }) {
  return api.post(`projects/${projectId}/copy?excludeDocuments=${!includeDocuments}`).then(response => response.data);
}

export function carryProject({ projectId, includeDocuments }) {
  return api
    .post(`projects/${projectId}/carryforward?excludeDocuments=${!includeDocuments}`)
    .then(response => response.data);
}

export function changeFlagNonClientService({ projectId, isClientService }) {
  return api
    .put(`projects/${projectId}/isClientService`, { isClientService: isClientService })
    .then(response => response.data);
}

export function archiveRequestProject({ projectId, approverId }) {
  return api.post(`projects/${projectId}/archive`, { approverId }).then(response => response.data);
}

export function archiveCancelRequest({ projectId }) {
  return api.delete(`projects/${projectId}/archive`).then(response => response.data);
}

export function respondArchiveRequest({ projectId, isApprove }) {
  return api.put(`projects/${projectId}/archive?approve=${isApprove}`).then(response => response.data);
}

export function updateProject({ projectId, projectStateId, projectName, engagementId }) {
  const payload = {
    projectStateId,
    projectName,
    engagementId
  };
  return api.put(`projects/${projectId}`, payload).then(response => response.data);
}

export function legalHoldProject({ projectId, isLegalHold }) {
  return api.put(`projects/${projectId}/legalhold`, { legalHold: isLegalHold }).then(response => response.data);
}

export function updateProjectName({ projectId, projectName }) {
  const payload = {
    projectName
  };
  return api.put(`projects/${projectId}/projectname`, payload).then(response => response.data);
}

export function updateProjectEngagementManually({
  projectId,
  projectName,  
  engagementName,
  externalEngagementId,
  clientName,
  clientId,
  fiscalYearEnd,
  countryCode,
  sourceType,
  wbsCode
}) {
  const payload = {
    projectName,
    engagementName,
    externalEngagementId,
    clientName,
    clientId,
    fiscalYearEnd,
    countryCode,
    sourceType,
    wbsCode
  };
  return api.put(`projects/${projectId}`, payload).then(response => response.data);
}

export function updateProjectEngagement({ projectId, engagementId }) {
  const payload = {
    engagementId
  };
  return api.put(`projects/${projectId}/engagementid`, payload).then(response => response.data);
}

export function updateEngagementManually({
  projectId,
  engagementId,
  engagementName,
  externalEngagementId,
  clientName,
  clientId,
  fiscalYearEnd,
  wbsCode
}) {
  const payload = {
    projectId,
    engagementName,
    externalEngagementId,
    clientName,
    clientId,
    fiscalYearEnd,
    wbsCode
  };
  return api.put(`engagements/${engagementId}`, payload).then(response => response.data);
}

export function updateProjectState({ projectId, projectStateId }) {
  const payload = {
    projectStateId
  };
  return api.put(`projects/${projectId}/projectstateid`, payload).then(response => response.data);
}

/********************************************************************************

Translations

********************************************************************************/

export function getTranslations() {
  return api.get('content/translations').then(response => response.data);
}

export function getLanguagePairs() {
  return api.get('content/translations/languagepairs').then(response => response.data);
}

export function deleteTranslation(translationId, memberFirmCode, containerCode) {
  return api
    .delete(`content/translations/${translationId}`, {
      headers: {
        ...(containerCode && { 'X-CNTR-CTX': containerCode }),
        ...(memberFirmCode && { 'X-MF-CTX': memberFirmCode })
      }
    })
    .then(response => response.data);
}

export function translateDocuments({
  sourceLanguageId,
  targetLanguageId,
  translationType,
  document,
  onUploadProgress,
  onUploadError,
  memberFirmCode,
  containerCode
}) {
  const formData = new FormData();
  formData.append(`file`, document);

  api.defaults.headers.common['X-MF-CTX'] = memberFirmCode;
  api.defaults.headers.common['X-CNTR-CTX'] = containerCode;

  return api
  .post(`content/translations?sourceLanguageId=${sourceLanguageId}&targetLanguageId=${targetLanguageId}&translationType=${translationType}`,formData, {onUploadProgress: onUploadProgress})
  .then(response => response.data)
  .catch(onUploadError);

}

export function translateText({ sourceLanguageId, targetLanguageId,translationType, text }) {
  const payload = {
    sourceLanguageId: sourceLanguageId,
    targetLanguageId: targetLanguageId,
    translationType: translationType,
    text: text
  };
  return api.post('content/translations/text', payload).then(response => response.data);
}

/********************************************************************************

Edit Extractions & Project Creation Template View

********************************************************************************/

export function createTemplate({ projectId, templateName, templateCategoryId, templateTypeID, extractionFields }) {
  const payload = {
    name: templateName,
    templateCategoryId,
    templateTypeID,
    extractionFields
  };
  return api.post(`projects/${projectId}/templates`, payload).then(response => response.data);
}

export function getTemplates({ projectId }) {
  return api.post(`projects/${projectId}/templates/get`).then(response => response.data);
}

export function getTemplateExtractions({ projectId, templateId }) {
  return api
    .get(`projects/${projectId}/templates/${templateId}/extractionfieldoverview`)
    .then(response => response.data);
}

export function deleteExtraction({ projectId, templateId, extractionFieldId }) {
  return api
    .delete(`projects/${projectId}/templates/${templateId}/extractionfields/${extractionFieldId}`)
    .then(response => response.data);
}

export function getProjectCreationTemplateTypes() {
  return api.get(`templatetypes`).then(response => response.data);
}

export function getProjectCreationExtractionGroups() {
  return api
    .get(`extractionfields/groups?options=accesstype:public&fields=exclude:ExtractionFields`)
    .then(response => response.data);
}

export function getProjectCreationExtractionForGroup(groupId) {
  return api.get(`extractionfields?groupId=${groupId}&includePublicFields=true`).then(response => response.data);
}

export function deleteTemplate( projectId, templateId ) {
  return api
    .delete(`projects/${projectId}/templates/${templateId}`)
    .then(response => response.data);
}

export function updateTemplate({ projectId, templateId, templateName, extractionFields }) {
  const payload = {
    name: templateName,
    extractionFields: extractionFields
  };
  return api.put(`projects/${projectId}/templates/${templateId}`, payload).then(response => response.data);
}


export function updateProjectDatePreference(projectId,formatSelected) {
  const payload = {
    DateFormatPreference: formatSelected
  };
  return api.put(`projects/${projectId}/dateformat`, payload).then(response => response.data);
}

export function updateTemplateDatePreference(projectId, templateId, formatSelected) {
  const payload = {
    DateFormatPreference: formatSelected
  };
  return api.put(`projects/${projectId}/templates/${templateId}/dateformat`, payload).then(response => response.data);
}
/********************************************************************************

Project Details View

********************************************************************************/
export function getProjectNameValidation() {
  return api.get(`projects/nameValidationRules`).then(response => response.data);
}

export function validateProjectName({ name }) {
  return api.get(`projects/validatename?projectname=${name}`).then(response => response.data);
}

export function validateProjectNameWithId({ name, projectId }) {
  return api.get(`projects/${projectId}/validatename?projectname=${name}`).then(response => response.data);
}

export function getAllEngagements() {
  return api.get(`engagements`).then(response => response.data);
}

export function getEngagementDetails(engagementId) {
  return api.get(`engagements/${engagementId}`).then(response => response.data);
}

export function getEngagementSearchResults(keyword, clientId) {
  return api.get(`engagements/all/${clientId}?keyword=${keyword}`).then(response => response.data);
}

export function getClientSearchResults(keyword) {
  return api.get(`engagements/clients?keyword=${keyword}`).then(response => response.data);
}

/********************************************************************************

Team Members View

********************************************************************************/
export function getProjectUsers({ projectId }) {
  return api.get(`projects/${projectId}/users`).then(response => response.data);
}

export function addProjectUser({ projectId, userId, roleId, roleName }) {
  const payload = {
    projectId,
    userId: userId,
    role: {
      roleId: roleId,
      roleDisplayName: roleName
    },
    userDetail: {
      userId: userId
    }
  };
  return api.post(`projects/${projectId}/users`, payload).then(response => response.data);
}

export function updateProjectUser({ projectId, userId, roleId, roleName }) {
  const payload = {
    projectId,
    userId: userId,
    role: {
      roleId: roleId,
      roleDisplayName: roleName
    },
    userDetail: {
      userId: userId
    }
  };
  return api.put(`projects/${projectId}/users/${userId}`, payload).then(response => response.data);
}

export function deleteProjectUser({ projectId, userId }) {
  return api.delete(`projects/${projectId}/users/${userId}`).then(response => response.data);
}

/********************************************************************************

 Visuals Page

 ********************************************************************************/

export function getClusterDocuments(projectId) {
  return api.get(`projects/${projectId}/clusters`).then(response => response.data.data);
}

export function processCluster(projectId) {
  return api.post(`projects/${projectId}/clusters/process`).then(response => response.data);
}

/********************************************************************************

Search Page

 ********************************************************************************/
export function getSearchDocuments({ projectId, keyword, pageNum }) {
  return api.get(
    `projects/${projectId}/search/documents?keyword=${keyword}&pagination=pagenum:${pageNum}%3Bpagesize:100`
  );
}

export function getSearchExtractions({ projectId, keyword, pageNum }) {
  return api.get(
    `projects/${projectId}/search/extractionfields?keyword=${keyword}&pagination=pagenum:${pageNum}%3Bpagesize:100`
  );
}

export function getSearchFolders({ projectId, keyword, pageNum }) {
  return api.get(
    `projects/${projectId}/search/folders?keyword=${keyword}&pagination=pagenum:${pageNum}%3Bpagesize:100`
  );
}

export function getSearchReviewers({ projectId, keyword, pageNum }) {
  return api.get(
    `projects/${projectId}/search/reviewers?keyword=${keyword}&pagination=pagenum:${pageNum}%3Bpagesize:100`
  );
}

export function getSearchDocumentTags({ projectId, keyword, pageNum }) {
  return api.get(
    `projects/${projectId}/search/documenttags?keyword=${keyword}&pagination=pagenum:${pageNum}%3Bpagesize:100`
  );
}

export function getSearchExtractionFieldInstanceTags({ projectId, keyword, pageNum }) {
  return api.get(
    `projects/${projectId}/search/extractionfieldinstancetags?keyword=${keyword}&pagination=pagenum:${pageNum}%3Bpagesize:100`
  );
}

export function getSearchTemplates({ projectId, keyword, pageNum }) {
  return api.get(
    `projects/${projectId}/search/templates?keyword=${keyword}&pagination=pagenum:${pageNum}%3Bpagesize:100`
  );
}

export function createAdvancedSearch({
  payload,
  pageNum = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  projectId,
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = ''
} = {}) {
  let filter = '';
  // Search Filter
  if (filterBy.length > 0 && filterString.length > 0) {
    filter = `&filter=fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }

  return api
    .post(
      `projects/${projectId}/search/advanced?pagination=pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}${filter}`,
      payload
    )
    .then(response => response.data);
}

export function getSearchResultsDocuments({
  projectId,
  pageNum = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  documentTagId = [],
  extractionFieldInstanceTagId = [],
  documentId = [],
  documentStateIds = [],
  extractionFieldId = [],
  extractionFieldIds = [],
  userId = [],
  reviewerUserIds = [],
  templateId = [],
  templateIds = [],
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = '',
  isReviewed = null,
  isAssigned = null
} = {}) {
  // Helpers
  let convertToArray = v => {
    return Array.isArray(v) ? v : [v];
  };

  let getCombinedArray = (a, b) => {
    const _a = convertToArray(a);
    const _b = convertToArray(b);
    return _a.concat(_b);
  };

  // Search Filter
  let filter;
  if (filterBy.length > 0 && filterString.length > 0) {
    filter = `fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }

  // Query String
  const query = {
    params: {
      pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
      filter
    }
  };

  // Combine / convert search values to api formats
  const _documentIds = convertToArray(documentId);
  const _extractionFieldIds = getCombinedArray(extractionFieldIds, extractionFieldId);
  const _reviewerUserIds = getCombinedArray(reviewerUserIds, userId);
  const _documentTagIds = convertToArray(documentTagId);
  const _extractionFieldInstanceTagIds = convertToArray(extractionFieldInstanceTagId);
  const _templateIds = getCombinedArray(templateIds, templateId);
  const _documentStateIds = convertToArray(documentStateIds);

  // Post Body
  const body = {
    DocumentIds: _documentIds,
    ExtractionFieldIds: _extractionFieldIds,
    ReviewerUserIds: _reviewerUserIds,
    DocumentTagIds: _documentTagIds,
    ExtractionFieldInstanceTagIds: _extractionFieldInstanceTagIds,
    TemplateIds: _templateIds,
    DocumentStateIds: _documentStateIds,
    IsReviewed: isReviewed,
    IsAssigned: isAssigned
  };

  return api.post(`projects/${projectId}/search/results/get`, body, query);
}

export function getSearchResultsDocumentsByAssignee({
  assigneeUserId, //In this API call this value needs to be a user ID (required) + at least 1 other filter.
  projectId,
  pageNum = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  documentTagId = [],
  extractionFieldInstanceTagId = [],
  documentId = [],
  documentStateIds = [],
  extractionFieldId = [],
  userId = [],
  reviewerUserIds = [],
  templateId = [],
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = '',
  isReviewed = null,
  isAssigned = null
} = {}) {
  // Helpers
  let convertToArray = v => {
    return Array.isArray(v) ? v : [v];
  };

  let getCombinedArray = (a, b) => {
    const _a = convertToArray(a);
    const _b = convertToArray(b);
    return _a.concat(_b);
  };

  // Search Filter
  let filter;
  if (filterBy.length > 0 && filterString.length > 0) {
    filter = `fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }

  // Query String
  const query = {
    params: {
      pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
      filter
    }
  };

  // Combine / convert search values to api formats
  const _documentIds = convertToArray(documentId);
  const _extractionFieldIds = convertToArray(extractionFieldId);
  const _reviewerUserIds = getCombinedArray(reviewerUserIds, userId);
  const _documentTagIds = convertToArray(documentTagId);
  const _extractionFieldInstanceTagIds = convertToArray(extractionFieldInstanceTagId);
  const _templateIds = convertToArray(templateId);
  // const _documentStateIds = convertToArray(documentStateIds);

  // Post Body
  const body = {
    AssigneeUserId: assigneeUserId,
    DocumentIds: _documentIds,
    ExtractionFieldIds: _extractionFieldIds,
    ReviewerUserIds: _reviewerUserIds,
    DocumentTagIds: _documentTagIds,
    ExtractionFieldInstanceTagIds: _extractionFieldInstanceTagIds,
    TemplateIds: _templateIds,
    DocumentStateIds: documentStateIds,
    IsReviewed: isReviewed,
    IsAssigned: isAssigned
  };

  return api.post(`projects/${projectId}/search/resultsbyassignee/get`, body, query);
}

export function getSearchResultsFolders({
  projectId,
  pageNum = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  folderId = {},
  sortBy = 'name',
  sortOrder = 'ascending',
  filterBy = '',
  filterString = ''
} = {}) {
  let filter;
  // Search Filter
  if (filterBy.length > 0 && filterString.length > 0) {
    filter = `fieldname:${filterBy};text:${cleanQuery(filterString)}`;
  }
  const payload = {
    params: {
      pagination: `pagenum:${pageNum};pagesize:${pageSize};sortby:${sortBy};sortorder:${sortOrder}`,
      filter
    }
  };

  const body = {
    Items: Array.isArray(folderId) ? folderId : [folderId]
  };

  return api.post(`projects/${projectId}/folders/get`, body, payload);
}

/********************************************************************************

 Analytics chart

 ********************************************************************************/

export function getAnalyticsReport({ projectId }) {
  return api.get(`projects/${projectId}/reports`).then(response => response.data.data);
}

export function getExtractionRecordMetadata({ projectId, documentId, extractionFieldId }) {
  return api
    .get(`projects/${projectId}/documents/${documentId}/extractionfields/${extractionFieldId}`)
    .then(response => response.data);
}

/********************************************************************************

 User Management

 ********************************************************************************/

 export function getAllUsers({ pageNum = 1, keyword = '', memberFirmOnly = false } = {}) {
  let searchPayload = '';

  if (keyword.length > 0) {
    searchPayload = `keyword=${keyword}`;
  }
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  if(containerCode != null && Permissions.Global.canViewAdminContainerDropdown())
  {
    return adminapi
    .post(
      `users/get?memberfirmonly=${memberFirmOnly}&returnDisabledUser=true&pagination=pagenum:${pageNum};pagesize:${DEFAULT_PAGE_SIZE}`,    
      {keyword:keyword}  
    ,      
      {
        headers: {
          ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
        }
      })
    .then(response => {
      let data = response.data.data.map(user => adminUserFromApiFormat(user));

      return { data, pageInfo: response.data.pageInfo };
    });

  }
  else
  {

    return api
    .post(
      `users/get?memberfirmonly=${memberFirmOnly}&returnDisabledUser=true&pagination=pagenum:${pageNum};pagesize:${DEFAULT_PAGE_SIZE}`,
      {keyword:keyword}  
   )
    .then(response => {
      let data = response.data.data.map(user => adminUserFromApiFormat(user));

      return { data, pageInfo: response.data.pageInfo };
    });

  }
 
}

export function adminUserFromApiFormat(apiUser) {
  return {
    ...apiUser,
    lastUpdatedDate: dateUtil.fromServerDate(apiUser.lastUpdatedDate),
    createdDate: dateUtil.fromServerDate(apiUser.createdDate),
    accessActivatedDate: dateUtil.fromServerDate(apiUser.accessActivatedDate)
  };
}

export const ADMIN_USER_ACTIONS = {
  Activate: 0,
  Deactivate: 1,
  Enable: 2,
  Disable: 3,
  GrantSystemAdminAccess: 4,
  GrantGeneralAdminAccess: 5,
  RemoveAdminAccess: 6
};

export function adminUpdateUser(userId, action) {
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

  return adminapi.put(`users/${userId}`, { action }).then(res => res.data.data);
}

export function updateUserAccess(userId, updateUserObj) {
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;

  return adminapi.put(`users/${userId}`, { 
      IsExtractionAccess: updateUserObj.IsExtractionAccess,
      IsTranslationAccess: updateUserObj.IsTranslationAccess, 
      IsAdminAccess : updateUserObj.IsAdminAccess,    
      ServicenowTicketNumber: updateUserObj.ServicenowTicketNumber
  }).then(res => res.data.data);
}

export function getOrganizationCodes(memberFirmCode) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  adminapi.defaults.headers.common['X-ADMCNTR-CTX'] = containerCode;
  
  return adminapi.get(`memberfirm/${memberfirm}/organizationtypes`).then(res => res.data.data);
}

export function getEnabledOrganizationCodes(memberFirmCode) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  adminapi.defaults.headers.common['X-ADMCNTR-CTX'] = containerCode;
  return adminapi.get(`memberfirm/${memberfirm}/organizations`).then(res => res.data.data);
}

export function enableOrganizationCode(organization) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  adminapi.defaults.headers.common['X-ADMCNTR-CTX'] = containerCode;

  return adminapi
    .post(`memberfirm/${memberfirm}/organizations`, {
      containerCode: containerCode,
      organizationCode: organization.organizationCode
    })
    .then(res => res.data.data);
}

export function disableOrganizationCode(organization) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  adminapi.defaults.headers.common['X-ADMCNTR-CTX'] = containerCode;
  return adminapi
    .delete(
        `memberfirm/${memberfirm}/organizations/${organization.organizationCode}/containers/${containerCode}`
    )
    .then(res => res.data.data);
}

/********************************************************************************

About and Privacy Links

 ********************************************************************************/

export function getAboutInfo() {
  return api.get(`/about`).then(res => res.data);
}

export function getHelpDocument(document, selectedLanguage) {
  return api.get(document, {
    responseType: 'blob',
    headers: {
      'X-LANG-CTX': selectedLanguage
    }
  });
}

export function postNewRating(userId, rating, feedback, page, userEmail) {
  return api.post('/about/rating', {
    globalPersonID: userId,
    rating: rating,
    feedback: feedback,
    PageMetaData: page,
    EmailID: userEmail
  });
}

/********************************************************************************

Reports

********************************************************************************/
export function getReports() {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  if(containerCode != null){
    return adminapi.get(`reports`,
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }
   ).then(res => res.data);
  }
  else
    return api.get(`reports`).then(res => res.data);
}

export function getScheduledReports() {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberFirmCode =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberFirmCode;

  if(containerCode != null){
    return adminapi.get(`reports/scheduled`,
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }
   ).then(res => res.data);
  }
  else
    return api.get(`reports/scheduled`).then(res => res.data);
}

export function getReport(reportId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberFirmCode =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberFirmCode;

  if(containerCode != null){
    return adminapi.get(`reports/${reportId}`,
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }
   ).then(res => res.data);
  }
  else
    return api.get(`reports/${reportId}`).then(res => res.data);
}

export function deleteReport(reportId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberFirmCode =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberFirmCode;

  if(containerCode != null){
    return adminapi.delete(`reports/scheduled/${reportId}`,
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }
   ).then(res => res.data);
  }
  else 
    return api.delete(`reports/scheduled/${reportId}`).then(res => res.data);
}

export function editReport(reportId, reportData) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberFirmCode =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberFirmCode;

  if(containerCode != null){
    return adminapi.put(`reports/scheduled/${reportId}`,reportData,
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }
   ).then(res => res.data);
  }
  else
    return api.put(`reports/scheduled/${reportId}`, reportData).then(res => res.data);
}

export function createReport(reportData) {
   const containerCode =  sessionStorage.getItem('selected-container');
  const memberFirmCode =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberFirmCode;
  if(containerCode != null){
    return adminapi.post(`reports/scheduled`,reportData,
    {
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    }
   ).then(res => res.data);
  }
  else
    return api.post(`reports/scheduled`, reportData).then(res => res.data);
}

export function generateReport(reportId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberFirmCode =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberFirmCode;
  if(containerCode != null){
   
    return adminapi.get(`reports/${reportId}/binary`,{responseType: 'blob',
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    });
    
  }
  else
  return api.get(`reports/${reportId}/binary`,{responseType: 'blob'  });
    
}

export function getReportDownloadToken(reportId) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  adminapi.defaults.headers.common['X-ADMCNTR-CTX'] = containerCode;
  return adminapi.get(`reports/${reportId}/token`).then(res => res.data);
}

export function downloadDocument(document, headers = null) {
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  if(containerCode != null && !document.includes("translations") && !document.includes("projects")){
    return adminapi
    .get(document, {
      responseType: 'blob',
      headers: {
        ...(containerCode && { 'X-ADMCNTR-CTX': containerCode })
      }
    })
    .then(res => {
      const file = new Blob([res.data], { type: res.headers['content-type'] });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    });
  }
  else{
    return api
    .get(document, {
      responseType: 'blob',
      ...(headers && { headers })
    })
    .then(res => {
      const file = new Blob([res.data], { type: res.headers['content-type'] });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    });
  }
  
}

/********************************************************************************

Tags Page

********************************************************************************/

export function getDocumentTags(projectId) {
  return api.get(`projects/${projectId}/tags/documentcounts`).then(res => res.data);
}

export function getDocumentTypeTags(projectId) {
  return api
    .get(`projects/${projectId}/tags/documentcounts?tagtype=systemdocumentclassification`)
    .then(res => res.data);
}

export function getOCRQualityTags(projectId) {
  return api.get(`projects/${projectId}/tags/documentcounts?tagtype=systemocrquality`).then(res => res.data);
}

export function getLanguageTags(projectId) {
  return api.get(`projects/${projectId}/tags/documentcounts?tagtype=systemdocumentlanguage`).then(res => res.data);
}

export function getExtractionFieldRecordTags(projectId) {
  return api.get(`projects/${projectId}/tags/extractionfieldrecordcounts`).then(res => res.data);
}

/********************************************************************************

Review Status Page

********************************************************************************/

export function getDocumentProcessingStatus(projectId) {
  return api.get(`projects/${projectId}/status/documentprocessing`).then(res => res.data);
}

export function getProjectStatus(projectId) {
  return api.get(`projects/${projectId}/status/documentprogress`).then(res => res.data);
}

export function getUserProgress(projectId) {
  return api.get(`projects/${projectId}/status/usersprogress`).then(res => res.data);
}


export function getUserSelectedFormat() {
  return api.get('/users/me/preferences/numberFormatPreference').then(response => {
    return response.data;
  });
}

export function getUserSelectedDateFormat() {
  return api.get('/users/me/preferences/dateFormatPreference').then(response => {
    return response.data;
  });
}

export function getDataSnipperEnableforUser() {
  return api.get('/users/me/preferences/enableDataSnipperForUser').then(response => {
    return response.data;
  });
}

export function getExtendedFeaturesforContainer(isFromAdmin) {
  const containerCode = sessionStorage.getItem('selected-container');
  const memberfirm = sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode = sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  if (isFromAdmin == true) {
    var adminapi = APIS[apiIndex].api;
    adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
    adminapi.defaults.headers.common['X-ADMCNTR-CTX'] = containerCode;

    return adminapi.get(`memberfirm/${memberfirm}/extendedfeaturesactivation?isFromAdmin=${isFromAdmin}`).then(res => res.data.data);
  } 
  else {
    return api.get(`memberfirm/${memberfirm}/extendedfeaturesactivation?isFromAdmin=${isFromAdmin}`).then(res => res.data.data);
  }
}

export function postExtendedFeaturesforContainer(IsWorkspaceEnabled, IsDataSnipperEnabled){
  const containerCode =  sessionStorage.getItem('selected-container');
  const memberfirm =  sessionStorage.getItem('selected-memberfirm');
  const selectedGeoCode =  sessionStorage.getItem('selected-GEOCode');

  var apiIndex = selectedGeoCode ? APIS.findIndex(currAPI => currAPI.geoCode === selectedGeoCode) : 0;
  apiIndex = apiIndex > -1 ? apiIndex : 0;

  if (APIS[apiIndex].token) {
    APIS[apiIndex].api.defaults.headers.common['Authorization'] = `Bearer ${APIS[apiIndex].token}`;
    socket.setAccessToken(APIS[apiIndex].token);
  }

  var adminapi = APIS[apiIndex].api;
  adminapi.defaults.headers.common['X-MF-CTX'] = memberfirm;
  adminapi.defaults.headers.common['X-ADMCNTR-CTX'] = containerCode;
  return adminapi.post(`/memberfirm/${memberfirm}/extendedfeaturesactivation`, {
    IsWorkspaceEnabled: IsWorkspaceEnabled,
    IsDataSnipperEnabled: IsDataSnipperEnabled
  })
}


export function getFormats() { 
  return api.get('/settings/23').then(response => {
    return response.data;
  });
}

export function getDateFormats() { 
  return api.get('/settings/28').then(response => {
    return response.data;
  });
}

export function postPrefferedFormat(PreferenceName, userSelectedFormat, userId) {

  return api.post('/users/me/preferences/', {
    PreferenceName: PreferenceName,
    CurrentPreference: userSelectedFormat,
    UserId: userId
  });
}

export function postPrefferedDateFormat(PreferenceName, userSelectedDateFormat, userId) {

  return api.post('/users/me/preferences/', {
    PreferenceName: PreferenceName,
    CurrentPreference: userSelectedDateFormat,
    UserId: userId
  });
}

export function postDsEnabledForUser(PreferenceName, IsDsEnabled, userId){
  return api.post('/users/me/preferences/', {
  PreferenceName: PreferenceName,
  CurrentPreference: IsDsEnabled,
  UserId: userId
  })
}

export function getMaintenanceMessages() {
  return api.get('/maintenanceMessages').then(response => {
    return response.data;
  });
}

export function getMaintenanceBannerMessages() {
  return api.get(`/maintenanceMessages/banner`).then(response => {
    return response.data;
  });
}

 export function getTimeZones() {

  return api.get('/maintenanceMessages/timezones').then(response => {
    return response.data;
  });
 }
 export function saveMaintenanceMessages(maintenanceObj) {  

  return api.post(`/maintenanceMessages/InsertMaintenanceMessage`,maintenanceObj).then(res => res.data);
}

export function updateMaintenanceMessages(messageId,maintenanceObj) {
  return api.put(`/maintenanceMessages/updateMessage/${messageId}`,maintenanceObj).then(res => res.data);
}

export function deleteMaintenanceMessage(messageId) {
 
  return api.delete(`/maintenanceMessages/${messageId}`).then(res => res.data);
}

