import { FormattedMessage, FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import Dropdown from 'components/shared/form/dropdown';

export default class Pagination extends Component {
  static propTypes = {
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      pageSizeStorageKey: PropTypes.string,
      pageSizeOptions: PropTypes.array,
      pageSizeChangeFunction: PropTypes.func,
      pageNumber: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      totalPageCount: PropTypes.number.isRequired,
      totalRecordCount: PropTypes.number.isRequired
    }).isRequired
  };

  onPrevPageClick = () => {
    this.props.onPageChange(this.props.pagination.pageNumber - 1);
  };

  onNextPageClick = () => {
    this.props.onPageChange(this.props.pagination.pageNumber + 1);
  };

  onPageSizeChange = selection => {
    const {
      pagination: { pageSizeStorageKey, pageSizeChangeFunction }
    } = this.props;

    localStorage.setItem(pageSizeStorageKey, selection);
    pageSizeChangeFunction(selection);
  };

  render() {
    const {
      pagination: { pageSizeStorageKey, pageSizeOptions, pageNumber, pageSize, totalPageCount, totalRecordCount }
    } = this.props;

    if (!totalRecordCount) {
      return null;
    }

    const userSelection = localStorage.getItem(pageSizeStorageKey);
    const userPageSize = userSelection ? parseInt(userSelection, 10) : null;
    const calculatedPageStart = (pageNumber - 1) * pageSize + 1;
    const currentPageStart = calculatedPageStart < totalRecordCount ? calculatedPageStart : 1;
    const currentPageEnd = Math.min(pageNumber * pageSize, totalRecordCount);

    return (
      <div className="pagination">
        <div className="pagination__page-info">
          <FormattedNumber value={currentPageStart} /> -{' '}
          {pageSizeStorageKey && pageSizeOptions ? (
            <Dropdown
              alwaysShowDefaultMessage={true}
              className="pagination__page-info__dropdown"
              defaultMessage={currentPageEnd}
              onSelection={this.onPageSizeChange}
              options={pageSizeOptions}
              renderOption={option => <span className="pagination__page-info__dropdown__option">{option}</span>}
              selectedOption={userPageSize}
            />
          ) : (
            <FormattedNumber value={currentPageEnd} />
          )}{' '}
          <FormattedMessage id="common.of" />{' '}
          <strong>
            <FormattedNumber value={totalRecordCount} />
          </strong>
        </div>
        <Button className="pagination__nav-button" disabled={pageNumber === 1} onClick={this.onPrevPageClick}>
          <Icon name="special-arrow-right" width={10} height={14} rotate={180} />
        </Button>
        <Button
          className="pagination__nav-button"
          disabled={pageNumber === totalPageCount}
          onClick={this.onNextPageClick}
        >
          <Icon name="special-arrow-right" width={10} height={14} />
        </Button>
      </div>
    );
  }
}
