import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router';
import { setCurrentRegion } from 'store/api';

// Routing components
import ProtectedRoute from 'components/app/routing/protected-route';

// Application pages
import CompareBrowserPage from 'components/pages/compare-browser';
import ComparePage from 'components/pages/compare';
import DocumentViewer from 'components/pages/document-viewer';
import EditExtractionsPage from 'components/pages/edit-extractions';
import FileBrowserPage from 'components/pages/file-browser';
import ProjectCreationPage from 'components/pages/project-creation';
import QuickStudyPage from 'components/pages/quick-study';
import UserManagementPage from 'containers/user-management/user-management-container';
import UserActivationSettingsPage from 'components/user-management/user-activation-settings-page';
import VisualsPage from 'components/pages/visuals';
import AnalyticsPage from 'components/pages/analytics';
import ReportsPage from 'components/pages/reports';
import TagsPage from 'components/pages/tags';
import ReviewStatusPage from 'components/pages/review-status';
import TrainingRequestsPage from 'components/pages/training-requests';
import ExtractionOverviewPage from 'components/pages/extraction-overview';
import CreateTemplatePage from 'components/pages/create-template';

// Region component wraps all routes that contain API region/geo codes to ensure
// the correct api is set before the route is rendered
class Region extends React.Component {
  static propTypes = {
    authenticateFunction: PropTypes.func,
    authenticated: PropTypes.bool,
    match: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    setCurrentRegion(this.props.match.params.regionId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currUrlRegion = this.props.match.params.regionId;
    const nextUrlRegion = nextProps.match.params.regionId;

    if (currUrlRegion !== nextUrlRegion) {
      setCurrentRegion(nextUrlRegion);
    }
  }

  render() {
    const { authenticateFunction, authenticated } = this.props;

    return (
      <Switch>
        {/* Edit Extractions Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/extractionfield/:templateId?"
          component={EditExtractionsPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Project Creation Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId?/creation"
          component={ProjectCreationPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Project Settings Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/settings"
          component={ProjectCreationPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Project Settings Page for project details */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/project-details"
          component={ProjectCreationPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Project Settings Page for Team members page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/team-members"
          component={ProjectCreationPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Project Copy Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/copy"
          component={ProjectCreationPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Create Template Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/create-template"
          component={CreateTemplatePage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* File List Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/folder/:folderId"
          component={FileBrowserPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Document Viewer Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/document/:documentId"
          component={DocumentViewer}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Comparison Browser Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/compare"
          component={CompareBrowserPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Visuals Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/visuals"
          component={VisualsPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Tags Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/tags"
          component={TagsPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Review Status Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/review-status"
          component={ReviewStatusPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Extraction Overview Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/extraction-overview"
          component={ExtractionOverviewPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Analytics Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/analytics"
          component={AnalyticsPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* User Management Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/user-management"
          component={UserManagementPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* User Activation Settings Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/activation-settings"
          component={UserActivationSettingsPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Reports Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/reports"
          component={ReportsPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Comparison Page */}
        <ProtectedRoute
          exact
          path="/region/:regionId/project/:projectId/compare/:compareId/:documentId?"
          component={ComparePage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Admin Pages */}
        <ProtectedRoute
          exact
          path="/region/:regionId/groups"
          component={QuickStudyPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />
        <ProtectedRoute
          exact
          path="/region/:regionId/groups/:groupId/extraction-fields"
          component={QuickStudyPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />
        <ProtectedRoute
          exact
          path="/region/:regionId/groups/:groupId/extraction-fields/:extractionFieldId"
          component={QuickStudyPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />

        {/* Training Requests Page*/}
        <ProtectedRoute
          exact
          path="/region/:regionId/training-requests"
          component={TrainingRequestsPage}
          authenticateFunction={authenticateFunction}
          authenticated={authenticated}
        />
      </Switch>
    );
  }
}

export default Region;
