import { connect } from 'react-redux';

import ExtractionRecordList from 'components/extractions/extraction-record-list/extraction-record-list';

import { removeExtractionFieldRecord, editExtractionFieldRecord } from 'store/current-document';
import { setCompareType } from 'store/compare-type';

const mapDispatchToProps = {
  setCompareType,
  editExtractionFieldRecord,
  removeExtractionFieldRecord
};

export default connect(null, mapDispatchToProps)(ExtractionRecordList);
