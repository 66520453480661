import { DragSource, DropTarget } from 'react-dnd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import { COLUMN_OPTIONS, COLUMN_OPTION_DEFAULTS, DEFAULT_COLUMN_ROOTSHOW, COLUMN_OPTIONS_DOCSHOW,
  DEFAULT_COLUMN_ORDER, DEFAULT_COLUMN_ROOTSHOWForProjects } from 'store/file-browser-column-options';
import Checkbox from 'components/shared/form/checkbox';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import constants from 'utils/constants';

const cardSource = {
  beginDrag(props) {
    return {
      option: props.option,
      index: props.index
    };
  }
};

// See https://github.com/react-dnd/react-dnd/blob/master/examples/04%20Sortable/Simple/Card.js#L24
const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Perform the action
    props.moveOption(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

export const Option = DropTarget('column-option', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(
  DragSource('column-option', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(
    class InnerOption extends Component {
      static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        displayName: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        isChecked: PropTypes.bool.isRequired,
        isDragging: PropTypes.bool.isRequired,
        moveOption: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        option: PropTypes.string.isRequired
      };

      onCheckboxChange = () => {
        const { onChange, option } = this.props;
        onChange(option);
      };

      render() {
        const { connectDragSource, connectDropTarget, displayName, option, isChecked, isDragging, index } = this.props;

        return connectDropTarget(
          <div
            className={classNames('column-options__option', isDragging && 'column-options__option--dragging')}
            key={index}
          >
            {connectDragSource(
              <button className="column-options__option-handle">
                <Icon width={8} height={12} name="handle" />
              </button>
            )}
            <Checkbox
              checked={isChecked ? 'checked' : 'unchecked'}
              className="column-options__option-checkbox"
              disabled={COLUMN_OPTION_DEFAULTS[option].alwaysVisible}
              id={`check-${option}`}
              onChange={this.onCheckboxChange}
            />
            <label className="column-options__option-label" htmlFor={`check-${option}`}>
              <FormattedMessage id={displayName} />
            </label>
          </div>
        );
      }
    }
  )
);

export class ColumnOptions extends Component {
  static propTypes = {
    order: PropTypes.array.isRequired,
    updateColumnOptions: PropTypes.func.isRequired,
    visibility: PropTypes.object.isRequired,
    projectItemId: PropTypes.any,
    defaultRootColumnsToShow: PropTypes.any
  };

  state = {
    order: this.props.order,
    visibility: this.props.visibility,
    isMenuOpen: false
  };

  openMenu = () => {
    const { isMenuOpen } = this.state;

    if (isMenuOpen) {
      this.onCancel();
    } else {
      this.setState({ isMenuOpen: true });
    }
  };

  onCancel = () => {
    const { order, visibility } = this.props;
    this.setState({ order, visibility, isMenuOpen: false });
  };

  onApply = () => {
    const { updateColumnOptions } = this.props;
    const { order, visibility } = this.state;
    this.setState({ isMenuOpen: false });
    updateColumnOptions({ order, visibility });
  };

  onCheckboxChange = option => {
    const { visibility } = this.state;

    this.setState({
      visibility: {
        ...visibility,
        [option]: !visibility[option]
      }
    });
  };

  handleClickOutside = () => {
    this.onCancel();
  };

  moveOption = (dragIndex, hoverIndex) => {
    const { order } = this.state;
    const dragOption = order[dragIndex];
    const updatedOptions = [...order];

    updatedOptions.splice(dragIndex, 1);
    updatedOptions.splice(hoverIndex, 0, dragOption);

    this.setState({
      order: updatedOptions
    });
  };

  render() {
    const { order, visibility, isMenuOpen } = this.state;
    const { intl, projectItemId, defaultRootColumnsToShow } = this.props;

    return (
      <div className="column-options">
        <Button
          size="icon"
          onClick={this.openMenu}
          className="btn file-browser-toolbar__control icon-button"
          data-tip
          data-for="file-browser.column-options.tooltip"
        >
          <Icon width={18} name="special-cog" />
          <ReactTooltip id="file-browser.column-options.tooltip" effect="solid" place="top">
            <FormattedMessage id="file-browser.column-options.tooltip" />
          </ReactTooltip>
        </Button>
        {isMenuOpen && (
          <div className="column-options__content column-options__document-viewer popover">
            <h3 className="column-options__title">
              <FormattedMessage id="file-browser.column-options.title" />
            </h3>
            <div className="column-options__options-list popover__section">
              {projectItemId == 0 ? order.map((option, index) => (
                defaultRootColumnsToShow.indexOf(option) != -1 &&
                (<Option
                  displayName={option == COLUMN_OPTIONS.PROCESSED_DATE ?
                    COLUMN_OPTION_DEFAULTS[COLUMN_OPTIONS.LASTUPDATED_DATE].displayName : COLUMN_OPTION_DEFAULTS[option].displayName}
                  index={index}
                  isChecked={!!visibility[option]}
                  key={option}
                  onChange={this.onCheckboxChange}
                  moveOption={this.moveOption}
                  option={option}
                />)
              )) : 
              order.map((option, index) => (
                COLUMN_OPTIONS_DOCSHOW.indexOf(option) != -1 &&
                (<Option
                  displayName={COLUMN_OPTION_DEFAULTS[option].displayName}
                  index={index}
                  isChecked={!!visibility[option]}
                  key={option}
                  onChange={this.onCheckboxChange}
                  moveOption={this.moveOption}
                  option={option}
                />)
              ))}
            </div>
            <div className="column-options__footer">
              <Button
                title={intl.formatMessage({ id: 'common.cancel' })}
                size="xsmall"
                className="btn btn-secondary"
                onClick={this.onCancel}
                tabIndex="0"
              >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                title={intl.formatMessage({ id: 'common.apply' })}
                size="xsmall"
                className="btn btn-primary btn-no-margin"
                onClick={this.onApply}
                tabIndex="0"
              >
                <FormattedMessage id="common.apply" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(enhanceWithClickOutside(ColumnOptions));
