import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Icon from 'components/shared/icon';

export default class EditableText extends Component {
  static propTypes = {
    disableEdit: PropTypes.bool,
    maxLength: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    renderValue: PropTypes.func,
    resize: PropTypes.string.isRequired,
    rows: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  };

  static defaultProps = {
    maxLength: '400',
    resize: 'none',
    rows: '1',
    tag: 'input'
  };

  state = {
    inputValue: this.props.value,
    isEditing: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        inputValue: this.props.value
      });
    }
  }

  onMouseEnter = () => {
    this.setState({
      isHovering: true
    });
  };

  onMouseLeave = () => {
    this.setState({
      isHovering: false
    });
  };

  onEditClick = () => {
    this.setState({ isEditing: true });
  };

  onInputChange = event => {
    const inputValue = event.target.value;
    this.setState({
      inputValue
    });
  };

  onBlur = () => {
    const { onSubmit } = this.props;
    const { inputValue } = this.state;

    onSubmit(inputValue);
    this.setState({ isEditing: false });
  };

  mountInput = elem => {
    if (!elem) {
      return;
    }

    this.inputElem = elem;
    this.inputElem.focus();
    document.execCommand('selectAll', false, null);
  };

  onKeyDown = event => {
    if (event.key === 'Enter') {
      // Submit on Enter
      event.preventDefault();
      this.inputElem.blur();
    } else if (event.key === 'Escape') {
      // Reset on Esc
      this.setState({
        isEditing: false,
        inputValue: this.props.value
      });
    }
  };

  render() {
    const { disableEdit, value, resize, rows, tag: TagName, maxLength, renderValue } = this.props;
    const { inputValue, isEditing } = this.state;

    return (
      <span className="editable-text">
        {isEditing ? (
          <TagName
            className="editable-text__input"
            type="text"
            maxLength={maxLength}
            ref={this.mountInput}
            value={inputValue}
            onChange={this.onInputChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            rows={rows}
            style={{ resize: resize }}
          />
        ) : renderValue ? (
          renderValue(value)
        ) : (
          <span title={value} className="editable-text__value">
            {value}
          </span>
        )}

        {!isEditing &&
          !disableEdit && (
            <button className="editable-text__button" onClick={this.onEditClick}>
              <Icon name="special-edit" width={24} />
            </button>
          )}
      </span>
    );
  }
}
