import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/icon';
import Button from 'components/shared/single-click-button';
import OutsideClickHandler from 'react-outside-click-handler';
import Modal from 'components/shared/modal';
import ReactTooltip from 'react-tooltip';
import { updateUserAccess } from 'store/api';
import constants from 'utils/constants';
const { Features } = constants;

function EditDetails({ user, closePopup, reloadUpdatedUser }) {
    const containerCode = sessionStorage.getItem(`selected-container`);
    const memberFirmCode = sessionStorage.getItem(`selected-memberfirm`);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [extractionAccess, setExtractionAccess] = useState(user.accessibleFeatures.filter(x => x.featureId == Features.Extraction).length > 0 ? true : false);
    const [translationAccess, setTranslationAccess] = useState(user.accessibleFeatures.filter(x => x.featureId == Features.Translation).length > 0 ? true : false);
    const [isGeneralAdmin, setGeneralAmin] = useState(user.accessibleFeatures.filter(x => x.featureId == Features.Extraction).length > 0 && (user.roles.find(role => role.roleId === constants.UserRoles.ADMIN_GENERAL && role.containerCode == containerCode && role.memberFirmCode == memberFirmCode)) ? true : false);
    const [enteredServiceNow, setEnteredServiceNow] = useState(user.serviceNowTicketNumber == null ? '' : user.serviceNowTicketNumber);
   
    const serviceNowChangeHandler = (event) => {
        setSaveButtonDisabled(false);
        setEnteredServiceNow(event);
    }
    
    const escape = (htmlStr) => {
            return htmlStr.currentTarget.value.replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#39;");     
    }

    const unEscape = (htmlStr) => {
        htmlStr = htmlStr.replace(/&lt;/g , "<");	 
        htmlStr = htmlStr.replace(/&gt;/g , ">");     
        htmlStr = htmlStr.replace(/&quot;/g , "\"");  
        htmlStr = htmlStr.replace(/&#39;/g , "\'");   
        htmlStr = htmlStr.replace(/&amp;/g , "&");
        return htmlStr;
    }

    const saveUserDetails = () =>{
        setSaveButtonDisabled(true);
        var userObj = {
            IsExtractionAccess: extractionAccess,
            IsTranslationAccess: translationAccess,
            IsAdminAccess: isGeneralAdmin,
            ServicenowTicketNumber: unEscape(enteredServiceNow)
        }
        updateUserAccess(user.userId, userObj).then(updatedUsr =>{            
            reloadUpdatedUser(user.userId, updatedUsr);
            closePopup();
        }).catch(()=>{
            closePopup();    
        });
    }

    return (
        <Modal>
            <OutsideClickHandler
                onOutsideClick={() => {
                    closePopup();
                }}
            >
                <div className="modal__content create-edit-user-dialog">
                    <div className="create-edit-user-dialog__header">
                        <FormattedMessage id="user-management.Edit-user" />
                        <Button type="button" size="icon" className="modal__close_x icon-button" onClick={closePopup}>
                            <Icon name="special-cross-black" width={18} height={18} />
                        </Button>
                    </div>
                    <div className="create-edit-user-dialog__content">
                        <div className='create-edit-user-dialog__body-firt-last-name'>
                            <div className="create-edit-user-dialog__body-firt-last-name-left">
                                <span className="create-edit-user-dialog__edit-user-label" >
                                    <FormattedMessage id="user-management-edit-user.firstName" />
                                </span>
                                <br />
                                <input
                                    className="create-edit-user-dialog__message-tex-box"
                                    placeholder="First Name"
                                    value={user.firstName}
                                    type="text"
                                />
                                <br />
                            </div>
                            <div className="create-edit-user-dialog__body-firt-last-name-right">
                                <span className="create-edit-user-dialog__edit-user-label">
                                    <FormattedMessage id="user-management-edit-user.lastName" />
                                </span>
                                <br />
                                <input
                                    className="create-edit-user-dialog__message-tex-box"
                                    placeholder="Last Name"
                                    value={user.lastName}
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className='create-edit-user-dialog__valid-textarea-email'>
                            <span className="create-edit-user-dialog__edit-user-label">
                                <FormattedMessage id="user-management-edit-user.email" />
                            </span>
                            <br />
                            <input
                                className="create-edit-user-dialog__message-tex-box-function-email"
                                placeholder="Email"
                                value={user.email}
                                type="text"
                            />
                        </div>
                        <div className='create-edit-user-dialog__valid-textarea-function'>
                            <span className="create-edit-user-dialog__edit-user-label">
                                <FormattedMessage id="user-management-edit-user.function" />
                            </span>
                            <br />
                            <input
                                className="create-edit-user-dialog__message-tex-box-function-email"
                                placeholder="Function"
                                value={user.organizationCode}
                                type="text"
                            />
                        </div>
                        <div className='create-edit-user-dialog__extraction'>
                            <div className='create-edit-user-dialog__edit-user-label'>
                                <FormattedMessage id="global.header.settings.preferred-access.extraction" />
                                <div className='edit-user-format-select__formats-displayed'>
                                    <input
                                        type="radio"
                                        id="group1"
                                        name="radio"
                                        value="Yes"
                                        checked={ extractionAccess ? true : false }
                                        onClick={() =>{ setExtractionAccess(true);
                                            setSaveButtonDisabled(false);
                                        }}
                                    />
                                    <span className="edit-user-format-select__radio-button-text">
                                        <FormattedMessage id="common.yes"/>
                                    </span>
                                    <input
                                        type="radio"
                                        id="group1"
                                        name="radio"
                                        value="No"
                                        checked={ !extractionAccess ? true : false }
                                        onClick={() => { setExtractionAccess(false);
                                            setGeneralAmin(false);
                                            setSaveButtonDisabled(false);  
                                        }}
                                        style={{ marginLeft: '30px' }}
                                    />
                                    <span className="edit-user-format-select__radio-button-text">
                                        <FormattedMessage id="common.no"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='create-edit-user-dialog__translation'>
                            <div className='create-edit-user-dialog__edit-user-label'>
                                <FormattedMessage id="global.header.settings.preferred-access-translation" />
                                <div className='edit-user-format-select__formats-displayed'>
                                    <input
                                        type="radio"
                                        id="group1"
                                        name="radio1"
                                        onClick={() => {
                                            setTranslationAccess(true);
                                            setSaveButtonDisabled(false);
                                        }}
                                        checked = { translationAccess ? true : false}
                                        value="Yes"
                                    />
                                    <span className="edit-user-format-select__radio-button-text">
                                        <FormattedMessage id="common.yes"/>
                                    </span>
                                    <input
                                        type="radio"
                                        id="group1"
                                        name="radio1"
                                        value="No"
                                        onClick={ () => {
                                            setTranslationAccess(false);
                                            setSaveButtonDisabled(false);
                                        }}
                                        checked = { !translationAccess ? true : false }
                                        style={{ marginLeft: '30px' }}
                                    />
                                    <span className="edit-user-format-select__radio-button-text">
                                        <FormattedMessage id="common.no"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='create-edit-user-dialog__body-CM-servicenowticket'>
                            <div className='create-edit-user-dialog__body-CM-servicenowticket-left'>
                                <div className='edit-user-format-select '>
                                    <div className='create-edit-user-dialog__edit-user-label'>
                                        <FormattedMessage id="global.header.settings.preferred-country-manager" />
                                        <span data-tip data-for="user-management.edit-user-details-cm-tooltip" data-multiline  style={{ marginLeft: '8px' }}>
                                            <Icon name="light-bulb-green" width={16}/>
                                            <ReactTooltip class = 'create-edit-user-dialog__CM-tooltip'id="user-management.edit-user-details-cm-tooltip" data-multiline effect="solid" place="top">
                                                <FormattedMessage id="user-management.edit-user-details-CMAccess-tooltip" />
                                            </ReactTooltip>
                                        </span>
                                        <br />
                                        <div className='edit-user-format-select__formats-displayed'>
                                            <input
                                                type="radio"
                                                id="group1"
                                                name="radio2"
                                                checked= {extractionAccess && isGeneralAdmin == true ? true : false}
                                                onClick={ () =>{if(extractionAccess){
                                                        setGeneralAmin(true);
                                                        setSaveButtonDisabled(false);
                                                    }
                                                }}
                                                value="Yes"
                                            />
                                            <span className="edit-user-format-select__radio-button-text">
                                                <FormattedMessage id="common.yes"/>
                                            </span>
                                            <input
                                                type="radio"
                                                id="group1"
                                                name="radio2"
                                                value="No"
                                                checked={!isGeneralAdmin || !extractionAccess ? true : false}
                                                onClick={() => {setGeneralAmin(false);
                                                    setSaveButtonDisabled(false);
                                                }}
                                                style={{ marginLeft: '30px' }}
                                            />
                                            <span className="edit-user-format-select__radio-button-text">
                                                <FormattedMessage id="common.no"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='create-edit-user-dialog__body-CM-servicenowticket-right'>
                                <div className='create-edit-user-dialog__edit-user-label'>
                                    <FormattedMessage id="global.header.settings.preferred-Servicenow-ticket" />
                                </div>
                                <div style={{ marginTop: '3px' }}>
                                    <input
                                        className="create-edit-user-dialog__body-CM-servicenowticket-textbox"
                                        value={unEscape(enteredServiceNow)}
                                        onChange={e => {serviceNowChangeHandler(escape(e))}}
                                        placeholder="Enter number"
                                        type="text"
                                        maxLength="20"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-edit-user-dialog__footer">
                        <Button className="btn btn-secondary" onClick={closePopup} >
                            <FormattedMessage id="global.header.format-cancel" />
                        </Button>
                        <Button className="btn btn-primary" disabled={saveButtonDisabled} onClick={saveUserDetails}>
                            <FormattedMessage id="global.header.format-save" />
                        </Button>
                    </div>
                </div>
            </OutsideClickHandler>
        </Modal >
    )
}
export default (EditDetails)