import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Icon from 'components/shared/icon';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import Button from 'components/shared/single-click-button';

class ToolbarIcon extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    iconName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    tooltipKey: PropTypes.string.isRequired
  };

  render() {
    const { active, onClick, iconName, tooltipKey } = this.props;

    const tooltipId = iconName + '-' + tooltipKey;

    return (
      <div className="toolbar-icon toolbar-icon__button-container">        
        <Button className={'toolbar-icon__icon-button toolbar-icon__special-icon special-icon'+iconName} onClick={onClick} data-tip data-for={tooltipId}>
          {active !== true && <Icon className={'icon-button-inactive-icon icon-button-'+iconName} name={iconName} width={20} />}
          {active === true && <Icon className={'icon-button-active-icon icon-button-'+iconName} name={iconName} width={20} />}
          <ReactTooltip id={tooltipId} effect="solid" place="bottom">
            <FormattedMessage id={tooltipKey} />
          </ReactTooltip>
        </Button>
      </div>
    );
  }
}

export default ToolbarIcon;
