import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';
import Button from 'components/shared/single-click-button';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { getCurrentRegion } from 'store/api';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import * as api from 'store/api';
import Header from 'containers/header/header';
import AddToCompareSidebarContainer from 'containers/shared/add-to-compare-sidebar-container';
import ExportCompareModal from 'components/compare-browser/export-compare-modal';
import ExportSidebarContainer from 'containers/shared/export-sidebar-container';
import Icon from 'components/shared/icon';
import socket, { rooms } from 'utils/socket';
import { withRouter } from 'react-router';
import { addNotification } from 'store/notifications.js';
import store from 'store/store';
import dateUtil from 'utils/dateUtil';
import { COMPARISON_STATUSES } from 'models/comparison-sets';

import Permissions from 'permissions/permissions';

const COMPARE_TYPES = {
  DOCUMENTS: 'document',
  EXTRACTION_FIELDS: 'extractionfield'
};

class Compare extends Component {
  static propTypes = {
    activeComparisonSet: PropTypes.object,
    compareId: PropTypes.string.isRequired,
    compareType: PropTypes.string.isRequired,
    comparisonSets: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    documentId: PropTypes.string,
    history: PropTypes.object,
    intl: intlShape.isRequired,
    loadActiveComparisonSet: PropTypes.func.isRequired,
    loadComparisonSets: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    search: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.counter = 0;
    this.highlight = {};
    this.state = {
      filteredDocIdSet: null,
      isAddToCompareSidebarShown: false,
      searchResults: { emphasis: 0, data: [], query: '' },
      isExportModalShown: false,
      isExportProcessing: false,
      isExportSidebarShown: false,
      reprocessingCompare: false,
      selectedChange: null
    };
  }

  componentDidMount() {
    const { loadActiveComparisonSet, loadComparisonSets, projectId, compareId, documentId, compareType } = this.props;

    if (compareType === COMPARE_TYPES.DOCUMENTS) {
      socket.join(rooms.comparisons(projectId));
      socket.join(rooms.comparison(projectId, compareId));
      socket.on('document.comparison.update', this.handleComparisonUpdate);
      socket.on('document.comparisons.removed', this.handleComparisonDocumentRemoved);
    } else {
      socket.join(rooms.extractionFieldComparisons(projectId));
      socket.join(rooms.extractionFieldComparison(projectId, compareId));
      socket.on('extractionfield.comparison.update', this.handleComparisonUpdate);
      socket.on('extractionfield.comparisons.record.removed', this.handleComparisonDocumentRemoved);
    }
    socket.on('comparisons.removed', this.handleComparisonDeleted);

    loadActiveComparisonSet({ projectId, compareId, compareType });
    loadComparisonSets({ projectId, compareType });
    if (documentId) {
      this.loadCompareDocument(documentId);
    }
  }

  componentWillUnmount() {
    const { compareId, projectId } = this.props;
    socket.leave(rooms.comparisons(projectId));
    socket.leave(rooms.comparison(projectId, compareId));
    socket.off('comparisons.removed', this.handleComparisonDeleted);
    socket.off('document.comparisons.removed', this.handleComparisonDocumentRemoved);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.documentId !== newProps.documentId) {
      if (newProps.documentId) {
        this.loadCompareDocument(newProps.documentId);
      } else {
        this.setState({ compareDocument: undefined });
      }
    }

    if (this.props.compareId !== newProps.compareId) {
      newProps.loadActiveComparisonSet({
        projectId: newProps.projectId,
        compareId: newProps.compareId,
        compareType: this.props.compareType
      });
      socket.leave(rooms.comparison(newProps.projectId, this.props.compareId));
      socket.join(rooms.comparison(newProps.projectId, newProps.compareId));
    }

    if (newProps.activeComparisonSet.error) {
      this.props.history.push(`/region/${getCurrentRegion()}/project/${newProps.projectId}/compare`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.search !== this.props.search) {
      return false;
    }
    return true;
  }

  loadCompareDocument(documentId) {
    const { compareId, projectId, compareType } = this.props;

    api.getComparisonDocument({ compareId, documentId, projectId, compareType }).then(response => {
      this.setState({ compareDocument: response.data });
    });
  }

  isInvalidEvent = msg => {
    const { projectId, compareId, compareType } = this.props;

    if (compareType === COMPARE_TYPES.DOCUMENTS) {
      return msg.room !== rooms.comparison(projectId, compareId);
    } else {
      return msg.room !== rooms.extractionFieldComparison(projectId, compareId);
    }
  };

  handleComparisonDocumentRemoved = msg => {
    const { history, projectId, compareId, documentId, intl } = this.props;

    // If the event is not referencing the current comparison-set, ignore it
    if (this.isInvalidEvent(msg)) {
      return;
    }

    // If the current document was removed
    if (msg.data.referenceId === parseInt(documentId, 10)) {
      history.push(`/region/${getCurrentRegion()}/project/${projectId}/compare/${compareId}`);
    }

    const { itemName } = msg.data;

    let docName = itemName.includes('#')
      ? `${itemName.substring(0, itemName.length - 2)} - ${intl.formatMessage({
          id: 'compare-comparisons-summary.record'
        })} ${itemName.substring(itemName.length - 1)}`
      : itemName;

    store.dispatch(
      addNotification({
        message: <FormattedMessage id="compare.document-removed-notification" values={{ docName: docName }} />,
        clearOnPageChange: true,
        autohide: 10
      })
    );
  };

  handleComparisonDeleted = msg => {
    const { history, projectId } = this.props;

    // If the event is not referencing the current comparison-set, ignore it
    if (msg.room !== rooms.comparisons(projectId)) {
      return;
    }

    history.push(`/region/${getCurrentRegion()}/project/${projectId}/compare`);

    store.dispatch(
      addNotification({
        message: <FormattedMessage id="compare.comparison-removed-notification" />,
        clearOnPageChange: true,
        autohide: 10
      })
    );
  };

  handleComparisonUpdate = msg => {
    const { loadActiveComparisonSet, projectId, compareId, compareType } = this.props;

    // If the event is not referencing the current comparison-set, ignore it
    if (this.isInvalidEvent(msg)) {
      return;
    }

    if (msg.data.comparisonStateId === COMPARISON_STATUSES.IN_PROGRESS) {
      this.setState({ reprocessingCompare: true });
    } else if (
      msg.data.comparisonStateId === COMPARISON_STATUSES.COMPLETED &&
      this.state.reprocessingCompare === true
    ) {
      loadActiveComparisonSet({ projectId, compareId, compareType });
      this.setState({ reprocessingCompare: false });
    }
  };

  getCompareDocumentIndex = () => {
    const { documentId } = this.props;
    const filteredCompareDocs = this.getFilteredCompareDocs();

    return filteredCompareDocs.findIndex(doc => doc.referenceId === parseInt(documentId, 10));
  };

  getNextCompareDocumentId = () => {
    const filteredCompareDocs = this.getFilteredCompareDocs();
    const docIndex = (this.getCompareDocumentIndex() + 1) % filteredCompareDocs.length;

    return filteredCompareDocs[docIndex].referenceId;
  };

  getPrevCompareDocumentId = () => {
    const filteredCompareDocs = this.getFilteredCompareDocs();

    let docIndex = this.getCompareDocumentIndex() - 1;
    if (docIndex < 0) {
      docIndex = filteredCompareDocs.length - 1;
    }

    return filteredCompareDocs[docIndex].referenceId;
  };

  getBaseDocumentChangeType(change) {
    //If there are deletings and edits, return edit
    if (change.edits.length) {
      return 'edit';
    } else if (change.deletes.length) {
      return 'delete';
    } else if (change.adds.length) {
      return 'add';
    } else {
      return undefined;
    }
  }

  getClassnames = (changeType, section, index) => {
    const { selectedChange } = this.state;
    if (selectedChange === index) {
      if (changeType === 'add') {
        return 'compare__text-selected compare__text-selected-added';
      }
      return 'compare__text-selected';
    }
    return changeType
      ? `compare__base-text-${changeType} compare__change-intensity-${this.getChangeIntensity(section)}`
      : '';
  };

  getBaseDocumentContent = baseDocument => {
    return baseDocument.map((section, index) => {
      const changeType = this.getBaseDocumentChangeType(section.changes);
      const context = section.contextualData.replace(/\r\n/g, '\r\n\n');

      if (changeType) {
        const lines = context.replace(/\r/g, '').split(/\n/);

        //Return back str if i is not the last index in arr
        let strIfNotLast = function(str, arr, i) {
          return i !== arr.length - 1 ? str : '';
        };

        return lines.map((line, lineI) => {
          const words = line.split(/\s+/);
          const classNames = this.getClassnames(changeType, section, index);

          return (
            <span
              key={`${index}-${lineI}`}
              className={`${(changeType === 'add' ? classNames : index, 'compare__text-sentence')} `}
            >
              {words.map((word, wordI) => (
                <span
                  onClick={e => {
                    e.stopPropagation();
                    this.filterDocumentsOnChange(section.changes);
                    this.setState({ selectedChange: index });
                  }}
                  data-tip={this.getSectionHoverText(section)}
                  key={`${index}-${lineI}-${wordI}`}
                >
                  {changeType === 'add' ? (
                    <span className={classNames}>+</span>
                  ) : (
                    this.getSearchHighlights(
                      index,
                      word + strIfNotLast(' ', words, wordI),
                      this.state.searchResults.query,
                      classNames
                    )
                  )}
                </span>
              ))}
              {strIfNotLast('\n', lines, lineI)}
            </span>
          );
        });
      } else {
        return this.getSearchHighlights(index, context, this.state.searchResults.query, index);
      }
    });
  };

  getCompareDocument = compareDocument => {
    return compareDocument.map((section, index) => {
      let classNames = `
      ${section.changes.adds.length ? 'compare__text-add' : ''}
      ${section.changes.deletes.length ? 'compare__text-delete' : ''}
    `;
      const context = section.contextualData.replace(/\r\n/g, '\r\n\n');
      return (
        <span key={index}>{this.getSearchHighlights(index, context, this.state.searchResults.query, classNames)}</span>
      );
    });
  };

  // Decide which words to highlight in text
  getSearchHighlights = (idx, text, highlight, classNames) => {
    const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');

    const { searchResults } = this.state;
    var parts;

    if (highlight && highlight !== '') {
      parts = String(text).split(regex);
    } else {
      parts = [text];
    }

    return (
      <span key={idx} className={idx}>
        {parts
          .filter(part => part)
          .map((part, i) => {
            if (regex.test(part) && highlight.length > 1) {
              this.counter++;
              return (
                <span
                  ref={el => (this.highlight[this.counter] = el)}
                  id={'highlight' + this.counter}
                  className={`compare__base-text-search ${this.counter - 1 === searchResults.emphasis ? 'active' : ''}`}
                  key={i}
                >
                  {part}
                </span>
              );
            }

            return (
              <span className={classNames} key={i}>
                {part}
              </span>
            );
          })}
      </span>
    );
  };

  // Scroll to the active search index
  scrollToEmphasis = index => {
    let el = document.getElementById('highlight' + (index + 1));

    el.scrollIntoView();
  };

  filterDocumentsOnChange(changes) {
    let changedIds = [...changes.adds, ...changes.deletes, ...changes.edits];
    this.setState({ filteredDocIdSet: new Set(changedIds) });
  }

  getSectionHoverText = section => {
    var count = this.getNumChanges(section);
    var type = this.getBaseDocumentChangeType(section.changes);
    var key = `compare-document.hover-text.${type}${count > 1 ? '.plural' : ''}`;
    return this.props.intl.formatMessage(
      {
        id: key
      },
      { count: count }
    );
  };

  getChangeIntensity(section) {
    const numDocs = this.props.activeComparisonSet.comparison.comparisonDetails.length;
    const numChanges = this.getNumChanges(section);

    return Math.ceil((numChanges / numDocs) * 4);
  }

  getNumChanges(section) {
    return section.changes.edits.length + section.changes.adds.length + section.changes.deletes.length;
  }

  getFilteredCompareDocs = () => {
    const { activeComparisonSet } = this.props;
    const { filteredDocIdSet } = this.state;

    let filteredCompareDocs = [...activeComparisonSet.comparison.comparisonDetails];
    if (filteredDocIdSet) {
      filteredCompareDocs = filteredCompareDocs.filter(doc => filteredDocIdSet.has(doc.referenceId));
    }

    return filteredCompareDocs;
  };

  getFilteredSummaryDetails = () => {
    const { activeComparisonSet } = this.props;
    const { filteredDocIdSet } = this.state;

    let filteredComparisonDetails = [...activeComparisonSet.comparisonSummary];

    filteredComparisonDetails.forEach((element, index) => {
      element.displayOrder = activeComparisonSet.comparison.comparisonDetails[index].displayOrder;
    });

    if (filteredDocIdSet) {
      filteredComparisonDetails = filteredComparisonDetails.filter(detail => filteredDocIdSet.has(detail.documentId));
    }

    return filteredComparisonDetails;
  };

  onCloseAddToCompareClick = () => {
    this.setState({ isAddToCompareSidebarShown: false });
  };

  onSubmitAddToCompareClick = selectedItems => {
    const { activeComparisonSet, projectId } = this.props;

    this.setState({ isAddToCompareSidebarShown: false, reprocessingCompare: true });

    api.updateComparison({
      projectId,
      comparison: activeComparisonSet.comparison,
      comparedDocuments: selectedItems
    });
  };

  cancelExportModal = () => {
    this.setState({ isExportModalShown: false });
  };

  submitExportModal = () => {
    const { projectId, currentProject, compareId } = this.props;

    const currentDate = moment();

    this.setState({ isExportProcessing: true });

    api
      .createExport({
        projectId,
        exportOptions: {
          exportType: 2,
          exportFileName: `${currentProject.projectName}_comparisons_${dateUtil.formatTimestamp(currentDate)}`,
          comparisons: [
            {
              comparisonId: compareId
            }
          ]
        }
      })
      .then(() => {
        this.setState({
          isExportModalShown: false,
          isExportSidebarShown: true,
          isExportProcessing: false
        });
      })
      .catch(() => {
        this.setState({
          isExportModalShown: false,
          isExportProcessing: false
        });
      });
  };

  onExportClick = () => {
    this.setState({ isExportModalShown: true });
  };

  closeExportSidebar = () => {
    this.setState({ isExportSidebarShown: false });
  };

  removeDocument = projectItemId => {
    const { compareId, projectId, history, activeComparisonSet, compareType } = this.props;
    api
      .removeDocumentFromComparison({ compareId, documentId: projectItemId, projectId, compareType })
      .catch(() => this.setState({ reprocessingCompare: false }));

    if (activeComparisonSet.comparison.comparisonDetails.length === 1) {
      return history.push(`/region/${getCurrentRegion()}/project/${projectId}/compare`);
    } else {
      this.setState({ reprocessingCompare: true });
    }
  };

  displayRecordName = (name, displayOrder = '') => {
    const { compareType, intl } = this.props;
    return compareType === COMPARE_TYPES.EXTRACTION_FIELDS
      ? `${name} - ${intl.formatMessage({ id: 'compare-comparisons-summary.record' })} ${displayOrder}`
      : name;
  };

  render() {
    const {
      activeComparisonSet,
      compareId,
      compareType,
      comparisonSets,
      currentProject,
      documentId,
      projectId,
      intl
    } = this.props;

    const {
      compareDocument,
      isAddToCompareSidebarShown,
      isExportModalShown,
      isExportProcessing,
      isExportSidebarShown,
      reprocessingCompare
    } = this.state;

    const readOnly = currentProject.STATES.ReadOnly();

    if (activeComparisonSet.isLoading || reprocessingCompare) {
      return (
        <div className="page">
          <Header
            {...this.props}
            inputDisabled
            compareData={{
              base: [],
              compare: []
            }}
          />
          <Icon className="spinner spinner--centered" name="loader" width={80} />
        </div>
      );
    }

    if (activeComparisonSet.lastUpdatedDate === null || !currentProject.isLoaded) {
      return '';
    }

    let activeDocument = null;
    if (documentId !== undefined && activeComparisonSet.comparison) {
      activeDocument = activeComparisonSet.comparison.comparisonDetails.find(
        d => d.referenceId === parseInt(documentId, 10)
      );
    }
    const filteredCompareDocs = this.getFilteredCompareDocs();
    const filteredSummaryDetails = this.getFilteredSummaryDetails();
    return (
      <div className="compare-page">
        <Header
          {...this.props}
          currentPage="global.subheader.extraction"
          compareData={{
            base: activeComparisonSet.baseDocument,
            compare: this.state.compareDocument
          }}
          compareSetSearch={results => {
            this.counter = 0;
            this.setState({ searchResults: results });
          }}
          compareSetEmphasis={this.scrollToEmphasis}
        />
        <div className={`compare compare__root ${readOnly ? 'readOnly' : ''}`}>
          <div className="compare__header">
            <div className="compare__header-left-panel">
              <div className="compare__header-panel-title">
                <h2>
                  {compareType === COMPARE_TYPES.DOCUMENTS ? (
                    <FormattedMessage id="compare-document.title" />
                  ) : (
                    <FormattedMessage id="compare-extraction-field-record.title" />
                  )}
                </h2>
              </div>

              <div className="compare__header-panel-breadcrumbs">
                <div className="compare__header-panel-item">
                  <div className="subtitle">{currentProject.projectName}</div>
                </div>
                <div className="compare__header-panel-item">&gt;</div>

                <div className="compare__header-panel-item">
                  <Link
                    to={`/region/${getCurrentRegion()}/project/${projectId}/compare/`}
                    title={intl.formatMessage({ id: 'compare-document.title' })}
                  >
                    {compareType === COMPARE_TYPES.DOCUMENTS ? (
                      <FormattedMessage id="compare-document.header.title" />
                    ) : (
                      <FormattedMessage id="compare-extraction-field-record.header.title" />
                    )}
                  </Link>{' '}
                </div>
                <div className="compare__header-panel-item">&gt;</div>

                {!activeComparisonSet.isLoading && !comparisonSets.isLoading && (
                  <MenuWrapper className="compare__header-panel-item compare__compare-menu" onSelection={() => {}}>
                    <MenuButton
                      className="form-dropdown compare__compare-menu-button"
                      title={activeComparisonSet.comparison.comparisonName}
                    >
                      {activeComparisonSet.comparison.comparisonName} <Icon name="special-arrow-down" width={10} />
                    </MenuButton>
                    <Menu className="compare__compare-menu-content">
                      <ul>
                        {comparisonSets.comparisons.map(comparison => (
                          <li key={comparison.comparisonId}>
                            <Link
                              to={`/region/${getCurrentRegion()}/project/${projectId}/compare/${
                                comparison.comparisonId
                              }`}
                            >
                              <MenuItem
                                value={comparison}
                                className="form-dropdown__item compare__compare-menu-item"
                                title={comparison.comparisonName}
                              >
                                {comparison.comparisonName}
                              </MenuItem>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Menu>
                  </MenuWrapper>
                )}
              </div>
            </div>
            <div className="compare__header-right-panel">
              {filteredCompareDocs.length > 0 && activeDocument && (
                <MenuWrapper className="compare__header-panel-item icon-button compare__compare-menu" onSelection={() => {}}>
                  <MenuButton
                    className="compare__compare-menu-button"
                    title={this.displayRecordName(activeDocument.compareEntity.itemName, activeDocument.displayOrder)}
                  >
                    {this.displayRecordName(activeDocument.compareEntity.itemName, activeDocument.displayOrder)}
                    <Icon name="special-arrow-down" width={10} />
                  </MenuButton>
                  <Menu className="compare__compare-menu-content">
                    <ul>
                      <li key={'all'}>
                        <Link
                          to={`/region/${getCurrentRegion()}/project/${projectId}/compare/${
                            activeComparisonSet.comparison.comparisonId
                          }`}
                        >
                          <MenuItem value={'all'} className="compare__compare-menu-item">
                            <FormattedMessage id="compare-document-list.all-documents" />
                          </MenuItem>
                        </Link>
                      </li>
                      {filteredCompareDocs.map(doc => (
                        <li key={doc.referenceId}>
                          <Link
                            to={`/region/${getCurrentRegion()}/project/${projectId}/compare/${
                              activeComparisonSet.comparison.comparisonId
                            }/${doc.referenceId}`}
                            title={this.displayRecordName(doc.compareEntity.itemName, doc.displayOrder)}
                          >
                            <MenuItem value={doc.projectItemId} className="compare__compare-menu-item">
                              {this.displayRecordName(doc.compareEntity.itemName, doc.displayOrder)}
                            </MenuItem>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Menu>
                </MenuWrapper>
              )}

              {documentId !== undefined && (
                <div className="compare__header-panel-item icon-button">
                  {filteredCompareDocs.length > 1 && (
                    <Link
                      to={`/region/${getCurrentRegion()}/project/${projectId}/compare/${compareId}/${this.getPrevCompareDocumentId()}`}
                    >
                      <Icon className="compare__header-icon" name="arrowleft-black" width={28} />
                    </Link>
                  )}
                  {filteredCompareDocs.length <= 1 && (
                    <Icon className="compare__header-icon" name="arrowleft-grey" width={28} />
                  )}
                </div>
              )}
              {documentId !== undefined && (
                <div className="compare__header-panel-item icon-button">
                  {filteredCompareDocs.length > 1 && (
                    <Link
                      to={`/region/${getCurrentRegion()}/project/${projectId}/compare/${compareId}/${this.getNextCompareDocumentId()}`}
                    >
                      <Icon className="compare__header-icon" name="arrowright-black" width={28} />
                    </Link>
                  )}
                  {filteredCompareDocs.length <= 1 && (
                    <Icon className="compare__header-icon" name="arrowright-grey" width={28} />
                  )}
                </div>
              )}
              {documentId !== undefined && <div className="compare__header-panel-item compare__header-seperator" />}

              {compareType === COMPARE_TYPES.DOCUMENTS && Permissions.Project.Comparison.canEdit() && !readOnly ? (
                <Button
                  size="icon"
                  className="compare__header-panel-item btn toolbar__icon-button icon-button"
                  onClick={() => this.setState({ isAddToCompareSidebarShown: true })}
                  data-tip
                  data-for="compare-browser.toolbar.add.tooltip"
                >
                  <Icon className="toolbar__inactive-icon" name="plus" width={20} />
                  <Icon className="toolbar__active-icon" name="plus" width={20} />
                  <ReactTooltip id="compare-browser.toolbar.add.tooltip" effect="solid" place="bottom">
                    <FormattedMessage id="compare-browser.toolbar.add.tooltip" />
                  </ReactTooltip>
                </Button>
              ) : null}

              {compareType === COMPARE_TYPES.DOCUMENTS && Permissions.Project.Comparison.canExport() ? (
                <Button
                  size="icon"
                  className="compare__header-panel-item btn icon-button toolbar__icon-button"
                  data-tip
                  data-for="compare-browser.toolbar.export.tooltip"
                  onClick={this.onExportClick}
                >
                  <Icon className="toolbar__inactive-icon" name="export" width={20} />
                  <Icon className="toolbar__active-icon" name="export" width={20} />
                  <ReactTooltip id="compare-browser.toolbar.export.tooltip" effect="solid" place="bottom">
                    <FormattedMessage id="compare-browser.toolbar.export.tooltip" />
                  </ReactTooltip>
                </Button>
              ) : null}
            </div>
          </div>
          {activeComparisonSet.baseDocument !== undefined && (
            <div className="compare__content">
              <div className="compare__base-document">
                <div className="compare__base-document-header">{activeComparisonSet.comparison.baseEntityName}</div>
                <div
                  className="compare__base-document-content"
                  onClick={() => this.setState({ filteredDocIdSet: null })}
                >
                  {this.getBaseDocumentContent(activeComparisonSet.baseDocument)}
                  {compareType === COMPARE_TYPES.EXTRACTION_FIELDS && (
                    <Link
                      className="page-number"
                      to={`/region/${getCurrentRegion()}/project/${projectId}/document/${
                        activeComparisonSet.comparison.baseDocumentReferenceId
                      }?page=${activeComparisonSet.comparison.pageNumber}`}
                    >
                      <FormattedMessage id="common.page" />
                      {` ${activeComparisonSet.comparison.pageNumber}/${activeComparisonSet.comparison.pageCount}`}
                    </Link>
                  )}
                </div>
              </div>
              {!documentId &&
                (activeComparisonSet.comparisonSummary ? (
                  <div className="compare__comparisons-summary-wrapper">
                    <table className="compare__comparisons-summary">
                      <thead>
                        <tr>
                          <th className="compare__comparisons-summary__header compare__comparisons-summary__header__title">
                            <FormattedMessage id="compare-document-list.title" />
                          </th>
                          <th className="compare__comparisons-summary__header compare__comparisons-summary__header__total">
                            <FormattedMessage id="compare-comparisons-summary.total" />
                          </th>
                          <th className="compare__comparisons-summary__header compare__comparisons-summary__header__delete">
                            <FormattedMessage id="compare-comparisons-summary.delete" />
                          </th>
                          <th className="compare__comparisons-summary__header compare__comparisons-summary__header__add">
                            <FormattedMessage id="compare-comparisons-summary.add" />
                          </th>
                          <th className="compare__comparisons-summary__header compare__comparisons-summary__header__edit">
                            <span className="text">
                              <FormattedMessage id="compare-comparisons-summary.edit" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSummaryDetails.map(docDetails => (
                          <tr key={docDetails.documentId}>
                            <td className="compare__comparisons-summary-item compare__comparisons-summary-item__name">
                              <Link
                                to={`/region/${getCurrentRegion()}/project/${projectId}/compare/${compareId}/${
                                  docDetails.documentId
                                }`}
                              >
                                {this.displayRecordName(docDetails.documentName, docDetails.displayOrder)}
                              </Link>
                              {Permissions.Project.Comparison.canEdit() && !readOnly ? (
                                <span
                                  className="compare__remove-document"
                                  onClick={e => this.removeDocument(docDetails.documentId)}
                                >
                                  ✕
                                </span>
                              ) : null}
                            </td>
                            <td className="compare__comparisons-summary-item compare__comparisons-summary-item__total">
                              {docDetails.totalChanges === null ? '-' : docDetails.totalChanges}
                            </td>
                            <td className="compare__comparisons-summary-item compare__comparisons-summary-item__delete">
                              {docDetails.deletes === null ? '-' : docDetails.deletes}
                            </td>
                            <td className="compare__comparisons-summary-item compare__comparisons-summary-item__add">
                              {docDetails.adds === null ? '-' : docDetails.adds}
                            </td>
                            <td className="compare__comparisons-summary-item compare__comparisons-summary-item__edit">
                              {docDetails.edits === null ? '-' : docDetails.edits}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="compare__document-list">
                    <div className="compare__document-list-content">
                      <div className="compare__document-list-title">
                        <FormattedMessage id="compare-document-list.title" />
                      </div>
                      {filteredCompareDocs.map(docDetails => {
                        return (
                          <div className="compare__document-list-item" key={docDetails.projectItemId}>
                            <Link
                              to={`/region/${getCurrentRegion()}/project/${projectId}/compare/${compareId}/${
                                docDetails.projectItemId
                              }`}
                            >
                              {docDetails.compareEntity.itemName}
                            </Link>
                            {Permissions.Project.Comparison.canEdit() ? (
                              <span
                                className="compare__remove-document"
                                onClick={e => this.removeDocument(docDetails.projectItemId)}
                              >
                                ✕
                              </span>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
              {!!documentId && (
                <div className="compare__compare-document">
                  <div className="compare__compare-document-content">
                    {!!compareDocument && this.getCompareDocument(compareDocument)}
                    {compareType === COMPARE_TYPES.EXTRACTION_FIELDS && (
                      <Link
                        className="page-number"
                        to={`/region/${getCurrentRegion()}/project/${projectId}/document/${
                          activeDocument.documentReferenceId
                        }?page=${activeDocument.pageNumber}`}
                      >
                        <FormattedMessage id="common.page" />
                        {` ${activeDocument.pageNumber}/${activeDocument.pageCount}`}
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {isAddToCompareSidebarShown && (
            <AddToCompareSidebarContainer
              projectId={projectId}
              onClose={this.onCloseAddToCompareClick}
              onSubmit={this.onSubmitAddToCompareClick}
            />
          )}
          {isExportModalShown && (
            <ExportCompareModal
              onCancel={this.cancelExportModal}
              onSubmit={this.submitExportModal}
              disableSubmit={isExportProcessing}
            />
          )}
          {isExportSidebarShown && (
            <ExportSidebarContainer
              onCloseClick={this.closeExportSidebar}
              projectId={projectId}
              exportType="Comparison"
            />
          )}
        </div>
        <ReactTooltip effect="solid" place="bottom" offset={{ bottom: 10, right: 20 }} />
      </div>
    );
  }
}

export default withRouter(injectIntl(Compare));
