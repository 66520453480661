import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { processTemplateExtractionFields } from 'components/extractions/helpers/extraction-picker';

import ExtractionRecordList from 'containers/extractions/extraction-record-list';
import ExtractionPicker from 'components/extractions/extraction-picker/extraction-picker';
import Permissions from 'permissions/permissions';
import { getExtractionFieldIds } from 'components/extractions/helpers/extraction-picker';

class SidePanel extends Component {
  static propTypes = {
    addExtractionField: PropTypes.func,
    clearUndoStack: PropTypes.func,
    closeFunction: PropTypes.func,
    createExtractionField: PropTypes.func,
    createExtractionFieldRecord: PropTypes.func,
    currentDocument: PropTypes.object,
    currentDocumentLayout: PropTypes.object,
    currentProject: PropTypes.object,
    documentViewerUndo: PropTypes.object,
    hasSelection: PropTypes.bool,
    isMenuOpen: PropTypes.bool,
    readOnly: PropTypes.bool,
    onCreateExtractionSuccess: PropTypes.func
  };

  state = {
    extractionPickerData: undefined,
    isShowingAddExtraction: false,
    isShowingCreateExtraction: false
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.hasSelection && !this.props.hasSelection) {
      this.showHideAddExtraction(false);
      this.showHideCreateExtraction(false);
    }
  };

  showHideAddExtraction = show => {
    this.setState({ isShowingAddExtraction: show });
  };

  closeFunction = () => {
    this.props.closeFunction();
  };

  showHideCreateExtraction = showCreateExtraction => {
    this.setState({ isShowingCreateExtraction: showCreateExtraction });
  };

  refreshFunction = () => {};

  createExtractionField = params => {
    this.showHideCreateExtraction(false);
    return this.props.createExtractionField(params);
  };

  addExtractionField = params => {
    this.showHideAddExtraction(false);
    return this.props.addExtractionField(params);
  };

  onCreateExtractionSuccess = (...params) => {
    const { onCreateExtractionSuccess } = this.props;

    if (typeof onCreateExtractionSuccess === 'function') {
      onCreateExtractionSuccess(...params);   
    }
  };

  render() {
    const { currentDocument, currentDocumentLayout } = this.props;

    if (currentDocument.isLoaded && currentDocumentLayout.isLoaded && currentDocument.projectTemplates) {
      const { isShowingAddExtraction, isShowingCreateExtraction  } = this.state;
      const {
        currentProject,
        hasSelection,
        readOnly,
        documentViewerUndo,
        clearUndoStack,
        createExtractionFieldRecord,
        isMenuOpen
      } = this.props;

      const { projectId } = currentProject;
      const {
        documentId,
        extractionFields,
        extractionFieldRecords,
        extractionFieldGroups,
        projectTemplates
      } = currentDocument;
      const { layout } = currentDocumentLayout;

      const currentTemplate = currentDocument.projectTemplates[0];

      const groups = extractionFieldGroups.map(group => ({
        extractionFieldGroupName: group.groupName,
        extractionFieldGroupId: group.groupId,
        displayOrder: group.displayOrder,
        groupSystemName: group.groupSystemName,
        extractionFields: extractionFields.filter(field => field.extractionFieldGroupId === group.groupId)
      }));
      const ungroupedExtractionFields = extractionFields.filter(field => !field.extractionFieldGroupId);

      if (ungroupedExtractionFields.length > 0) {
        groups.push({
          extractionFieldGroupName: projectTemplates.length > 0 ? projectTemplates[0].name : 'Other',
          extractionFieldGroupId: 0,
          displayOrder: 0,
          extractionFields: ungroupedExtractionFields
        });
      }

      const enhancedData = currentDocument.filterById.extractionFieldGroupIds.length
        ? processTemplateExtractionFields(currentTemplate, groups).filter(ef =>
            currentDocument.filterById.extractionFieldGroupIds.includes(ef.groupId)
          )
        : processTemplateExtractionFields(currentTemplate, groups);

      const filteredEFRecords = extractionFieldRecords.filter(
        efr =>
          efr.pageNumber >= currentDocument.filterById.selectedSection &&
          efr.pageNumber < currentDocument.filterById.selectedSection + currentDocument.filterById.sectionLength
      );

      const showExtractionPicker =
        hasSelection && Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canCreate();

      return (
        <div className="document-viewer-panel side-panel">
          {!readOnly && (
            <div style={{ visibility: showExtractionPicker ? 'visible' : 'hidden' }}>
              <ExtractionPicker
                isShowingAddExtraction={isShowingAddExtraction}
                showHideAddExtraction={this.showHideAddExtraction}
                clearUndoStack={clearUndoStack}
                closeFunction={this.closeFunction}
                createExtractionField={this.createExtractionField}
                addExtractionField={this.addExtractionField}
                createExtractionFieldRecord={createExtractionFieldRecord}
                currentTemplate={currentTemplate}
                data={enhancedData}
                selectedExtractionFieldIds={getExtractionFieldIds(
                  processTemplateExtractionFields(currentTemplate, groups)
                )}
                documentId={documentId}
                projectId={projectId}
                readOnly={readOnly}
                refreshFunction={this.refreshFunction}
                extractionFieldGroups={
                  currentDocument.filterById.extractionFieldGroupIds.length
                    ? extractionFieldGroups.filter(group =>
                        currentDocument.filterById.extractionFieldGroupIds.includes(group.groupId)
                      )
                    : extractionFieldGroups
                }
                showAddExtractionButton={currentDocument.filterById.isSelectedSectionClassified}
                documentTemplateCategoryId={currentTemplate.templateCategoryId}
                onCreateExtractionSuccess={this.onCreateExtractionSuccess}
                isShowingCreateExtraction={isShowingCreateExtraction}
                showHideCreateExtraction={this.showHideCreateExtraction}
              />
            </div>
          )}

          <ExtractionRecordList
            clearUndoStack={clearUndoStack}
            documentId={documentId}
            data={enhancedData}
            extractionFields={extractionFields}
            extractionFieldRecords={filteredEFRecords}
            pageCount={layout.length}
            pages={layout}
            projectId={projectId}
            refreshData={this.refreshFunction}
            template={currentTemplate}
            readOnly={readOnly}
            documentViewerUndo={documentViewerUndo}
          />
        </div>
      );
    } else {
      return (
        <div className="document-viewer-panel side-panel">
          <div className="ghost-toolbar" />
          <div className="ghost-extractions">
            {[...Array(20)].map((a, i) => (
              <div className="ghost-extraction" key={i}>
                <span className="circle" />
                <span className="text" />
                <span className="icon" />
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
}

export default SidePanel;
