import { connect } from 'react-redux';

import { cancelUpload } from 'store/uploads';
import UploadStatus from 'components/file-browser/upload-status/upload-status';

const mapStateToProps = ({ data: { uploads } }) => ({
  uploads
});

const mapDispatchToProps = {
  cancelUpload
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadStatus);
