import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';
import Checkbox from 'components/shared/form/checkbox';
import SelectBaseCompare from 'components/shared/create-compare-sidebar/select-base-compare';
import { ITEM_TYPES } from 'models/project-item';
import { getFolder } from 'store/api';
import { FormattedMessage } from 'react-intl';
import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';
import emptyStateImage from 'images/empty_state_file_browser.svg';
import { ITEM_STATUSES } from 'models/project-item';
import { addNotification } from 'store/notifications.js';
import store from 'store/store';
import Button from 'components/shared/single-click-button';
import Pagination from 'components/shared/pagination/pagination';
import LoadingOverlay from 'components/shared/loading/light';

const ACTIVE_STEP = {
  SELECT_FILES: 1,
  SELECT_BASE_DOCUMENT: 2
};

export default class CreateCompareSidebar extends Component {
  static propTypes = {
    currentFolder: PropTypes.object.isRequired,
    loadCurrentFolder: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired
  };

  state = {
    selectedFileIds: new Set(),
    activeStep: ACTIVE_STEP.SELECT_FILES,
    selectedFiles: [],
    allChildren: [],
    folderId: null
  };

  SubmitSelectedFiles = () => {
    const { projectId } = this.props;

    const loadItemPromises = Array.from(this.state.selectedFileIds)
      .map(id => this.state.allChildren.find(c => c.projectItemId === id))
      .map(item => {
        if (item.itemTypeId === ITEM_TYPES.FOLDER) {
          return getFolder({
            projectId,
            folderId: item.projectItemId,
            recursive: true
          }).then(r => r.data);
        } else {
          return Promise.resolve([item]);
        }
      });

    Promise.all(loadItemPromises).then(filesArrays => {
      const files = [].concat(...filesArrays).filter(item => item.itemStateId === ITEM_STATUSES.PROCESSED);

      if (files.length < 2) {
        store.dispatch(
          addNotification({
            message: <FormattedMessage id="compare-browser.create-form.not-enough-files-error" />,
            clearOnPageChange: true,
            autohide: 10
          })
        );
      } else {
        this.setState({ activeStep: ACTIVE_STEP.SELECT_BASE_DOCUMENT, selectedFiles: files });
      }
    });
  };

  createComparison = ({ comparisonName, baseDocument }) => {
    const { selectedFiles } = this.state;
    this.props.onSubmit({ comparisonName, baseDocument, selectedFiles });
  };

  componentDidMount() {
    const { loadCurrentFolder, projectId } = this.props;

    //Load the root folder
    loadCurrentFolder({ projectId, forceFetch: true });
  }

  componentDidUpdate(prevProps) {
    const { currentFolder } = this.props;

    if (currentFolder.children !== prevProps.currentFolder.children) {
      var { allChildren } = this.state;

      currentFolder.children.forEach(child => {
        if (!allChildren.some(cac => cac.projectItemId === child.projectItemId)) {
          allChildren.push(child);
        }
      });

      this.setState({ allChildren });
    }
  }

  navigateToFolder = folderId => {
    this.props.loadCurrentFolder({
      projectId: this.props.projectId,
      folderId,
      forceFetch: true
    });
    this.setState({ folderId: folderId });
  };

  toggleSelectedFile(projectItem) {
    if (this.state.selectedFileIds.has(projectItem.projectItemId)) {
      this.state.selectedFileIds.delete(projectItem.projectItemId);
    } else {
      this.state.selectedFileIds.add(projectItem.projectItemId);
    }

    this.setState({ selectedFileIds: this.state.selectedFileIds });
  }

  onBackClick = () => {
    this.setState({ activeStep: ACTIVE_STEP.SELECT_FILES });
  };

  onPageChange = pageNum => {
    const { loadCurrentFolder, projectId } = this.props;
    const { folderId } = this.state;

    var params = {
      projectId,
      pageNum,
      forceFetch: true
    };

    if (folderId !== null) {
      params.folderId = folderId;
    }

    loadCurrentFolder(params);
  };

  selectedOnPageCount = () => {
    var { currentFolder } = this.props;
    var { selectedFileIds } = this.state;
    var children = currentFolder.children || [];

    return children.filter(child => selectedFileIds.has(child.projectItemId)).length;
  };

  selectAllItemsOnPage = addItem => {
    var { currentFolder } = this.props;
    var { selectedFileIds } = this.state;
    var children = currentFolder.children || [];

    children.forEach(child => {
      if (addItem) {
        if (
          !selectedFileIds.has(child.projectItemId) &&
          (child.itemStateId === ITEM_STATUSES.PROCESSED || child.itemTypeId === ITEM_TYPES.FOLDER)
        ) {
          selectedFileIds.add(child.projectItemId);
        }
      } else {
        if (selectedFileIds.has(child.projectItemId)) {
          selectedFileIds.delete(child.projectItemId);
        }
      }
    });

    this.setState({ selectedFileIds });
  };

  isAsSelectedAsPossible = () => {
    const { currentFolder } = this.props;
    const childItems = currentFolder.children || [];

    return (
      childItems.filter(
        child => child.itemStateId === ITEM_STATUSES.PROCESSED || child.itemTypeId === ITEM_TYPES.FOLDER
      ).length === this.selectedOnPageCount()
    );
  };

  render() {
    const { onClose, currentFolder, projectId } = this.props;
    const { selectedFileIds, activeStep, selectedFiles } = this.state;

    const childItems = currentFolder.children || [];

    let breadcrumbs = [];
    if (currentFolder && currentFolder.parentHierarchy) {
      breadcrumbs = currentFolder.parentHierarchy.slice(1, currentFolder.parentHierarchy.length).reverse();
    }

    var selectedOnPageCount = this.selectedOnPageCount();

    return (
      <div>
        <div className="create-compare-sidebar__overlay" />
        <div className="create-compare-sidebar">
          <div className="create-compare-sidebar__header">
            {activeStep === ACTIVE_STEP.SELECT_BASE_DOCUMENT && (
              <Button size="icon" onClick={this.onBackClick} className="create-compare-sidebar__back-button">
                <Icon name="special-back" width={14} />
              </Button>
            )}
            <span>
              <FormattedMessage id="compare-browser.create-form.title" />
            </span>
            <Button size="icon" onClick={onClose} className="create-compare-sidebar__close-button icon-button">
              <Icon name="special-cross" width={14} />
            </Button>
          </div>
          {activeStep === ACTIVE_STEP.SELECT_FILES && (
            <div className="create-compare-sidebar__select-files">
              <div className="create-compare-sidebar__breadcrumbs">
                <div
                  className={
                    'create-compare-sidebar__breadcrumb-home ' +
                    (currentFolder.itemName ? 'create-compare-sidebar__breadcrumb-home-clickable' : '')
                  }
                  onClick={() => this.navigateToFolder('root')}
                >
                  <FormattedMessage id="compare-browser.create-form.breadcrumb-root" />
                </div>
                {!!currentFolder.itemName && (
                  <div className="create-compare-sidebar__breadcrumb-angle-bracket">&gt;</div>
                )}
                {breadcrumbs.length > 0 && (
                  <div className="create-compare-sidebar__breadcrumbs-dropdown-container">
                    <MenuWrapper
                      className="create-compare-sidebar__breadcrumb-menu"
                      onSelection={this.navigateToFolder}
                    >
                      <MenuButton className="form-dropdown create-compare-sidebar__breadcrumb-menu-button">
                        ...
                      </MenuButton>
                      <Menu className="create-compare-sidebar__breadcrumb-menu-content">
                        <ul>
                          {breadcrumbs.map(folder => {
                            return (
                              <li key={folder.projectItemId}>
                                <MenuItem
                                  value={folder.projectItemId}
                                  className="form-dropdown__item create-compare-sidebar__breadcrumb-menu-item"
                                >
                                  {folder.itemName}
                                </MenuItem>
                              </li>
                            );
                          })}
                        </ul>
                      </Menu>
                    </MenuWrapper>
                  </div>
                )}
                {breadcrumbs.length > 0 && <div className="create-compare-sidebar__breadcrumb-angle-bracket">&gt;</div>}
                <div className="create-compare-sidebar__breadcrumb-current-folder">{currentFolder.itemName}</div>
              </div>
              <div className="create-compare-sidebar__content">
                {currentFolder.isLoading ? <LoadingOverlay text={false} /> : null}

                {childItems && childItems.length > 0 ? (
                  <div className="list-header">
                    <Checkbox
                      id={`file-checkbox-all`}
                      onChange={() => {
                        this.selectAllItemsOnPage(!this.isAsSelectedAsPossible());
                      }}
                      checked={
                        selectedOnPageCount === 0
                          ? 'unchecked'
                          : this.isAsSelectedAsPossible()
                            ? 'checked'
                            : 'indeterminate'
                      }
                      className="add-to-compare-sidebar__item-checkbox"
                    />
                    <FormattedMessage id="file-list.heading.name" />
                  </div>
                ) : null}

                <div className="scroll-area">
                  <ul className="create-compare-sidebar__project-item-list">
                    {childItems.map(item => (
                      <li key={item.projectItemId} className="create-compare-sidebar__project-item">
                        {item.itemTypeId === ITEM_TYPES.FOLDER && (
                          <div className="create-compare-sidebar__folder">
                            <Checkbox
                              id={`folder-checkbox-${item.projectItemId}`}
                              onChange={() => this.toggleSelectedFile(item)}
                              checked={selectedFileIds.has(item.projectItemId) ? 'checked' : 'unchecked'}
                              className="create-compare-sidebar__item-checkbox"
                            />

                            <Icon name="special-folder" width={18} className="create-compare-sidebar__folder-icon" />

                            <span
                              className="create-compare-sidebar__folder-name"
                              onClick={() => this.navigateToFolder(item.projectItemId)}
                              title={item && item.itemName && item.itemName.length > 30 ? item.itemName : ''}
                            >
                              {item.itemName}
                            </span>
                          </div>
                        )}
                        {item.itemTypeId === ITEM_TYPES.DOCUMENT && (
                          <div
                            className="create-compare-sidebar__file"
                            onClick={() => {
                              if (item.itemStateId === ITEM_STATUSES.PROCESSED) this.toggleSelectedFile(item);
                            }}
                          >
                            {item.itemStateId === ITEM_STATUSES.PROCESSED ? (
                              <Checkbox
                                id={`file-checkbox-${item.projectItemId}`}
                                onChange={() => {}}
                                checked={selectedFileIds.has(item.projectItemId) ? 'checked' : 'unchecked'}
                                className="create-compare-sidebar__item-checkbox"
                              />
                            ) : (
                              <div className="create-compare-sidebar__item-checkbox__placeholder" />
                            )}
                            <span title={item && item.itemName && item.itemName.length > 30 ? item.itemName : ''}>
                              {item.itemName}
                            </span>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>

                {currentFolder && currentFolder.pagination !== null ? (
                  <Pagination onPageChange={this.onPageChange} pagination={currentFolder.pagination} />
                ) : null}

                {currentFolder.isLoaded &&
                  childItems.length === 0 && (
                    <div className="create-compare-sidebar__empty-folder-container">
                      <img
                        className="create-compare-sidebar__empty-folder-img"
                        alt="empty folder"
                        src={emptyStateImage}
                      />
                      <h2>
                        <FormattedMessage id="compare-browser.create-form.empty-folder-title" />
                      </h2>
                      <div>
                        <FormattedMessage id="compare-browser.create-form.empty-folder-msg" />
                      </div>
                    </div>
                  )}
              </div>
              <div className="create-compare-sidebar__footer">
                <Button
                  disabled={selectedFileIds.size === 0}
                  className="btn btn-primary btn-no-margin"
                  onClick={() => this.SubmitSelectedFiles()}
                >
                  <FormattedMessage id="common.next" />
                </Button>
              </div>
            </div>
          )}
          {activeStep === ACTIVE_STEP.SELECT_BASE_DOCUMENT && (
            <div className="create-compare-sidebar__select-base">
              <SelectBaseCompare
                comparedDocumentsList={selectedFiles}
                onSubmit={this.createComparison}
                projectId={projectId}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
