import model from 'models/base-model';

export const ROLES = {
  ADMIN_GENERAL: 1,
  ADMIN_SYSTEM: 2,
  ADMIN_ACCESS: 3,
  PROJECT_VISITOR: 4,
  PROJECT_CONTRIBUTOR: 5,
  PROJECT_OWNER: 6,
  // PROJECT_PPMD: 7,  //PPMD role was removed in favour of ppd flag on user
  PROJECT_CREATOR: 8,
  EXTRACTION_FIELD_OWNER: 9,
  EXTRACTION_FIELD_CONTRIBUTOR: 10
};

export const ROLE_DISPLAY_NAMES = {
  [ROLES.EXTRACTION_FIELD_OWNER]: 'global.roles.owner',
  [ROLES.EXTRACTION_FIELD_CONTRIBUTOR]: 'global.roles.contributor'
};

export class Role extends model({
  containerCode: null,
  geoCode: null,
  memberFirmCode: null,
  roleId: null
}) {
  static fromData(model) {
    return new Role({
      ...model
    });
  }
}

export class User extends model({
  isLoading: false,
  isLoaded: false,
  error: null,
  displayName: null,
  email: null,
  externalSystemId: null,
  firstName: null,
  isAccessActivated: null,
  isDisabled: null,
  lastName: null,
  lastUpdatedDate: null,
  lastUpdatedBy: null,
  roles: null,
  userId: null,
  geoCode: null,
  memberFirmCode: null,
  containerCode: null,
  adminContainers:[]
}) {
  setLoading() {
    return new User({
      error: null,
      isLoaded: false,
      isLoading: true
    });
  }

  setError(error) {
    return this.merge({
      error: error,
      isLoaded: false,
      isLoading: false
    });
  }

  invalidate() {
    return this.merge({
      error: null,
      isLoaded: false,
      isLoading: false
    });
  }

  setLoaded(data) {
    return User.fromData(data);
  }

  static fromData(model) {
    return new User({
      error: null,
      isLoaded: true,
      isLoading: false,
      ...model,
      roles: model ? model.roles.map(role => new Role(role)) : [],
      internalUpdateTimestamp: new Date().getTime()
    });
  }
}
