import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import Footer from 'containers/footer/footer';
import Header from 'containers/header/header';
import ProjectListContainer from 'containers/project-list/project-list-container';
import NotificationsContainer from 'containers/shared/notifications-container';
import AdminDashboardContainer from 'containers/admin-dashboard/admin-dashboard-container';
import ProjectsLeftMenu from 'components/project-list/project-menu-bar/project-left-menu';
import { FormattedMessage } from 'react-intl';
import emptyAccessDeniedImage from 'images/empty_state_access_denied.svg';
import { getHomeRegion, setCurrentRegion } from 'store/api';
import Permissions from 'permissions/permissions';
import history from 'utils/history';
import { thisExpression } from '@babel/types';
import EmptyState from 'components/file-browser/file-list/empty-state';

class ProjectListPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    projectList: PropTypes.object,
    currentProject: PropTypes.object,
    currentUser: PropTypes.object
  };

  state = {
    // Show either table or tile view
    tableOrTile: null,
    userMode: false, //Set to true to filter out projects that you are not part of (for admins)
    sortKey: 'projectName',
    sortAsc: true,
    isWorkspaceButtonHidden : false
  };

  componentDidMount() {
    this.getTableOrTile();
  }

  UNSAFE_componentWillMount() {
    setCurrentRegion(getHomeRegion());

    if (!Permissions.Global.canViewProjects()) {
      history.push('/unauthorized');
    }
  }

  renderAdminDashboard() {
    if (Permissions.Global.User.canviewAdminDashboard()) {
      return <AdminDashboardContainer {...this.props} />;
    }
  }



  onSortingChange =(sortValue) =>
  {
     this.setState({
      sortKey : sortValue.key,
      sortAsc : sortValue.asc
     })
  } 
  

 onUserModeChange =(userModeValue) =>
 {
  this.setState({
    userMode : userModeValue
   })
 }

 setTableOrTileView =(value) =>
 {
  const { currentUser } = this.props;
  if (currentUser) {
    
    if (value) this.setState({ tableOrTile: value });
  }
 }

 getTableOrTile = () => {
  const { currentUser } = this.props;
  if (currentUser) {
    const value = JSON.parse(localStorage.getItem(`project-list-display-mode-${currentUser.userId}`));
    if (value) this.setState({ tableOrTile: value });
  }
};

setshowHideWorkspace = (value) =>{ 
  this.setState({
    isWorkspaceButtonHidden : value
  })
}


  render() {

    const {tableOrTile ,userMode , sortKey, sortAsc, isWorkspaceButtonHidden} = this.state;
    
    
    return (
      <div className="page">
            <Header {...this.props} fromProjectListPage currentPage="global.subheader.extraction" 
            onSortingChange={this.onSortingChange} 
            onUserModeChange={this.onUserModeChange}
            setTableOrTileView ={this.setTableOrTileView}
            isWorkspaceButtonHidden = {isWorkspaceButtonHidden}
            />
        
        {this.props.currentUser && this.props.currentUser.accessibleFeatures.filter(feature => feature.featureId === 0).length > 0 ? 
           (<Fragment>
              <ProjectsLeftMenu {...this.props} />
              <ProjectListContainer {...this.props} userMode={userMode} sortKey ={sortKey}  sortAsc={sortAsc} tableOrTile={tableOrTile} setshowHideWorkspace ={this.setshowHideWorkspace}/>
              <Footer extended />
            </Fragment>)
             :
          <EmptyState
          title={<FormattedMessage id="error-page.unauthorized.title" />}
          description={
            <FormattedMessage
              id="error-page.unauthorized.description"
              values={{
                link: this.link
              }}
            />
          }
          img={emptyAccessDeniedImage}
        /> } 
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default ProjectListPage;
