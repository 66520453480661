import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import { FixedTable, FixedHeader } from 'components/shared/fixed-table';
import Avatar from 'components/shared/avatar';
import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Permissions from 'permissions/permissions';
import RoleDropdown from 'components/project-creation/team-members/role-dropdown';
import TeamListMenu from 'components/project-creation/team-members/team-list-menu';
import Pagination from 'components/shared/pagination/pagination';

class TeamList extends Component {
  static propTypes = {
    onChangeProjectMemberRole: PropTypes.func,
    onRemoveProjectMember: PropTypes.func,
    onRoleModalClose: PropTypes.func,
    onSelectProjectMember: PropTypes.func,
    onSubmitRoleUpdate: PropTypes.func,
    projectMembers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    projectState: PropTypes.object,
    readOnly: PropTypes.bool,
    role: PropTypes.object,
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    user: PropTypes.object
  };

  state = {
    isRoleInfoOpen: false,
    isRoleModalOpen: false,
    currentPage: 1
  };

  roleKeys = {
    4: 'read-only',
    5: 'contributor',
    6: 'owner',
    7: 'owner',
    8: 'owner'
  };

  PAGE_SIZE = 50;

  changePage = pageNumber => {
    this.setState({ currentPage: pageNumber });
  };

  openRoleInfoModal = event => {
    event.preventDefault();
    this.setState({ isRoleInfoOpen: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeRoleInfoModal = () => this.setState({ isRoleInfoOpen: false });

  toggleRoleInfoModal = () => this.setState({ isRoleInfoOpen: !this.state.isRoleInfoOpen });

  openRoleModal = () => this.setState({ isRoleModalOpen: true });

  closeRoleModal = () => {
    const { onRoleModalClose } = this.props;
    this.setState({ isRoleModalOpen: false });
    onRoleModalClose();
  };

  submitRoleUpdate = () => {
    const { onSubmitRoleUpdate } = this.props;
    this.setState({ isRoleModalOpen: false });
    onSubmitRoleUpdate();
  };

  closeMenu = () => {
    this.setState({ isRoleInfoOpen: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  // Data clean up for table layout
  cleanUpData = () => {
    const { projectMembers } = this.props;
    if (!projectMembers.isLoading && !projectMembers.members) {
      return null;
    }

    return projectMembers.members.reduce((acc, cur) => {
      if (!cur.role.isTemporaryUser) {
        acc.push({
          userId: cur.userDetail.userId,
          roleId: cur.role.roleId,
          name: cur.userDetail.displayName,
          preferredFullName: cur.userDetail.preferredFullName,
          initials: cur.userDetail.lastName ? cur.userDetail.firstName[0] + cur.userDetail.lastName[0] : 'AA',
          email: cur.userDetail.email,
          title: cur.userDetail.title,
          role: this.roleKeys.hasOwnProperty(cur.role.roleId)
            ? this.roleKeys[cur.role.roleId]
            : cur.role.roleDisplayName
            ? cur.role.displayName
            : 'roles.no-role'
        });
      }
      return acc;
    }, []);
  };

  // Render the team list menu
  renderTeamListMenu = member => {
    const {
      user: currentUser,
      projectMembers,
      projectState,
      onRemoveProjectMember,
      onSelectProjectMember,
      readOnly
    } = this.props;

    if (
      readOnly ||
      // User cannot edit or remove themselves
      member.userId === currentUser.userId ||
      !Permissions.Project.User.canEdit('current', 'current') ||
      !Permissions.Project.User.canDelete('current', 'current')
    ) {
      return null;
    }

    return (
      <TeamListMenu
        member={member}
        onRemoveClick={onRemoveProjectMember}
        onChangeRoleClick={this.openRoleModal}
        onMenuButtonClick={onSelectProjectMember}
        projectState={projectState}
      />
    );
  };

  renderRoleInfoModal = () => {
    return (
      <div className="team-list__role-info">
        <div className="team-list__role-info-top">
          <span className="team-list__role-info-title">
            <FormattedMessage id="team-list.role-info.title" />
          </span>
          <Button size="icon" className="icon-button" onClick={this.closeRoleInfoModal}>
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className="team-list__role-info-bottom">
          <div>
            <span className="team-list__role-info-bold">
              <FormattedMessage id="team-members.role-read" />
            </span>
            {' - '}
            <FormattedMessage id="team-list.role-info.read" />
          </div>
          <div>
            <span className="team-list__role-info-bold">
              <FormattedMessage id="team-members.role-contributor" />
            </span>
            {' - '}
            <FormattedMessage id="team-list.role-info.contributor" />
          </div>
          <div>
            <span className="team-list__role-info-bold">
              <FormattedMessage id="team-members.role-owner" />
            </span>
            {' - '}
            <FormattedMessage id="team-list.role-info.owner" />
          </div>
        </div>
      </div>
    );
  };

  renderRoleModal = () => {
    const { onChangeProjectMemberRole, selected, role } = this.props;

    return (
      <Modal id="team-list-confirmation-modal">
        <div className="team-list__modal-content">
          <div className="team-list__role-info-top">
            <span className="team-list__role-info-title">
              <FormattedMessage id="team-list.modal.change-role" />
            </span>
            <Button size="icon" onClick={this.closeRoleModal}>
              <Icon className="icon-button" name="special-cross-black" width={14} />
            </Button>
          </div>
          <div className="team-list__modal-middle">
            <div className="team-list__body-name">
              <Avatar initials={selected.initials} />
              <span className="table-view__body-creator-text">{selected.name}</span>
            </div>
            <div>
              <span className="team-members__search-name">
                <FormattedMessage id="team-members.search-role" />
              </span>
              <RoleDropdown key="team-members" onRoleChange={onChangeProjectMemberRole} role={role} />
            </div>
          </div>
          <div className="team-list__modal-footer">
            <Button className="btn btn-secondary" onClick={this.closeRoleModal}>
              <FormattedMessage id="team-list.modal.cancel-button" />
            </Button>
            <Button className="btn btn-primary" onClick={this.submitRoleUpdate} disabled={!role}>
              <FormattedMessage id="team-list.modal.submit-button" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { isRoleInfoOpen, isRoleModalOpen, currentPage } = this.state;
    const members = this.cleanUpData();

    return (
      <React.Fragment>
        <div className="team-list">
          <FixedTable>
            <div className="team-list__divider">
              <div className="team-list__table-shift">
                <table>
                  <thead>
                    <tr>
                      <FixedHeader className="team-list__heading">
                        <FormattedMessage id="team-list.heading.name" />
                      </FixedHeader>
                      <FixedHeader className="team-list__heading">
                        <FormattedMessage id="team-list.heading.email" />
                      </FixedHeader>
                      <FixedHeader className="team-list__heading">
                        <FormattedMessage id="team-list.heading.title" />
                      </FixedHeader>
                      <FixedHeader className="team-list__heading">
                        <FormattedMessage id="team-list.heading.role" />

                        <Button size="icon" onClick={this.openRoleInfoModal}>
                          {isRoleInfoOpen ? (
                            <Icon name="light-bulb-blue" width={16} />
                          ) : (
                            <Icon name="light-bulb" width={16} />
                          )}
                        </Button>
                        {isRoleInfoOpen && this.renderRoleInfoModal()}
                      </FixedHeader>
                      <FixedHeader />
                    </tr>
                  </thead>
                  <tbody>
                    {members &&
                      members
                        .slice(currentPage * this.PAGE_SIZE - this.PAGE_SIZE, currentPage * this.PAGE_SIZE)
                        .map((memberData, index) => (
                          <tr key={index}>
                            <td className="team-list__body-name">
                              <Avatar initials={memberData.initials} />
                              <div className="team-list__body-name-creator">
                                {memberData.preferredFullName ? (
                                  memberData.preferredFullName
                                ) : (
                                  <FormattedMessage id="team-list.names.no-name" />
                                )}
                              </div>
                            </td>
                            <td className="team-list__body-email">
                              {memberData.email ? (
                                memberData.email
                              ) : (
                                <FormattedMessage id="team-list.emails.no-email" />
                              )}
                            </td>
                            <td className="team-list__body-title">
                              {memberData.title ? (
                                memberData.title
                              ) : (
                                <FormattedMessage id="team-list.titles.no-title" />
                              )}
                            </td>
                            <td className="team-list__body-role">
                              <span
                                className="team-list__body-role-text"
                                data-tip
                                data-for={`team-list.body-role-tooltip-${memberData.role}`}
                              >
                                <FormattedMessage id={'team-list.roles.' + memberData.role} />
                              </span>
                              <ReactTooltip
                                className="team-list__tooltip"
                                id={`team-list.body-role-tooltip-${memberData.role}`}
                                effect="solid"
                                place="bottom"
                              >
                                <p className="team-list__tooltip-paragraph" style={{ whiteSpace: 'pre' }}>
                                  <FormattedMessage
                                    id={`team-list.body-role-tooltip.roles.${memberData.role}`}
                                    values={{ lb: '\r\n' }}
                                  />
                                </p>
                              </ReactTooltip>
                            </td>
                            <td className="team-list__body-options">{this.renderTeamListMenu(memberData)}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </FixedTable>

          {isRoleModalOpen && this.renderRoleModal()}
        </div>

        {members ? (
          <div className="team-members__footer">
            <Pagination
              onPageChange={this.changePage}
              pagination={{
                pageNumber: currentPage,
                pageSize: this.PAGE_SIZE,
                totalPageCount: Math.ceil(members.length / this.PAGE_SIZE),
                totalRecordCount: members.length
              }}
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default TeamList;
