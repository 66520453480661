import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import UserAutocomplete from 'components/shared/user-autocomplete/user-autocomplete';
import RoleDropdown from 'components/project-creation/team-members/role-dropdown';
import { getUsers } from 'store/api';

function searchUsers(keyword) {
  return getUsers(keyword).then(res => res.data);
}

// Search user component to add to role list
function SearchMembers({
  disabled,
  keyword,
  onAdd,
  onKeyDown,
  onMemberChange,
  onMemberSelect,
  onRoleChange,
  role,
  value,
  visibleOptions
}) {
  return (
    <div className="team-members__search-container">
      <div className="team-members__name-wrapper">
        <span className="team-members__search-name">
          <FormattedMessage id="team-members.search-title" />
        </span>
        <div className="team-members__search-name-border">
          <UserAutocomplete
            keyword={keyword}
            onChange={onMemberChange}
            onKeyDown={onKeyDown}
            onSelect={onMemberSelect}
            searchUsers={searchUsers}
            selectedUserName={value}
            teammembersPage={true}
          />
          <span className="team-members__search-name-searchIcon">
            <Icon name="search-omnia" width={12} />
          </span>
        </div>
      </div>
      <div className="team-members__role-wrapper">
        <span className="team-members__search-name">
          <FormattedMessage id="team-members.search-role" />
        </span>
        <div className="team-members__role-wrapper__second-row">
          <RoleDropdown key="team-select" onRoleChange={onRoleChange} role={role} visibleOptions={visibleOptions} />
          <div className="team-members__add-wrapper">
            <Button className="btn btn-no-margin btn-primary" disabled={disabled} onClick={onAdd}>
              <FormattedMessage id="guided-template.add-to-empty-template" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

SearchMembers.propTypes = {
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  keyword: PropTypes.string,
  onAdd: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onMemberChange: PropTypes.func,
  onMemberSelect: PropTypes.func,
  onRoleChange: PropTypes.func,
  role: PropTypes.object,
  users: PropTypes.any,
  value: PropTypes.string,
  visibleOptions: PropTypes.array
};

export default SearchMembers;
