import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Header from 'containers/header/header';
import CompareBrowserContainer from 'containers/compare-browser/compare-browser-container';
import NotificationsContainer from 'containers/shared/notifications-container';

import Permissions from 'permissions/permissions';
import history from 'utils/history';

class CompareBrowserPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    if (!Permissions.Project.Comparison.canView()) {
      history.push('/');
    }
  }

  render() {
    return (
      <div className="page">
        <Header {...this.props} currentPage="global.subheader.extraction" />
        <CompareBrowserContainer {...this.props} />
        <NotificationsContainer />
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default CompareBrowserPage;
