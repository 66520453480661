import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/shared/single-click-button';
import Icon from 'components/shared/icon';
import TextArea from 'components/shared/form/textarea';
import ExtractionRecordTags from 'containers/extractions/extraction-record-tags';

import { DragSource, DropTarget } from 'react-dnd';
import withDragDropContext from 'utils/dnd-mouse-context';

import Permissions from 'permissions/permissions';

import {
  emphasizeHighlight,
  deemphasizeHighlight
} from 'components/document-viewer/document-renderer/helpers/emphasisHelper';

// Begin drag and drop functionality for extraction records
const cardSource = {
  beginDrag(props) {
    return {
      extractionFieldRecord: props.extractionFieldRecord,
      index: props.index
    };
  },
  endDrag(props, monitor, component) {
    // This is a good place to call some Flux action
    return props.finishDrag ? props.finishDrag() : {};
  },
  isDragging(props, monitor) {
    return props.extractionFieldRecord === monitor.getItem().extractionFieldRecord;
  }
};

// See https://github.com/react-dnd/react-dnd/blob/master/examples/04%20Sortable/Simple/Card.js#L24
const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Perform the action
    props.moveRecord(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

// Connect to drag and drop context
const ExtractionRecord = DropTarget('extraction-rec', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(
  DragSource('extraction-rec', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(function InnerExtraction({
    isDragging,
    connectDragSource,
    connectDropTarget,
    changeTextBox,
    extractionFieldRecord,
    extractionGroup,
    goToPage,
    groupIndex,
    index,
    isFinished,
    isFirstItem,
    moveRecord,
    onExtractionDelete,
    saveExtractionRecord,
    hoverFunction,
    projectId,
    documentId,
    refreshData,
    finishDrag,
    readOnly,
    pageCount,
    clearUndoStack
  }) {
    return connectDropTarget(
      <div
        className={`extraction-record ${isDragging ? 'dragged' : ''}`}
        id={`extraction-field-record-${extractionFieldRecord.extractionFieldRecordId}`}
        onMouseEnter={() =>
          hoverFunction(extractionFieldRecord.extractionFieldRecordId, extractionFieldRecord.extractionFieldId, true)
        }
        onMouseLeave={() =>
          hoverFunction(extractionFieldRecord.extractionFieldRecordId, extractionFieldRecord.extractionFieldId, false)
        }
        key={index}
      >
        <div className="textarea">
          {connectDragSource(
            !Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canEdit() || readOnly || isFirstItem ? (
              <div className="card-spacer" />
            ) : (
              <button className="card-drag">
                <Icon width={8} height={12} name="handle" />
              </button>
            )
          )}

          <TextArea
            onChange={e => {
              changeTextBox(extractionGroup.id, extractionFieldRecord.extractionFieldRecordId, e);
            }}
            onBlur={() => saveExtractionRecord(extractionFieldRecord)}
            id={extractionFieldRecord.extractionFieldRecordId}
            value={extractionFieldRecord.extractFieldInstanceData}
            disabled={readOnly || !Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canEdit()}
            maxHeight={200}
            isDragging={isFinished}
          />

          {Permissions.Project.Document.ExtractionField.ExtractionFieldRecord.canDelete() && !readOnly ? (
            <div className="remove-extraction">
              <Button
                size="icon"
                className="icon-button"
                onClick={() =>
                  onExtractionDelete(extractionFieldRecord.extractionFieldRecordId, extractionFieldRecord.pageNumber)
                }
              >
                <Icon name="special-cross-black" width={10} height={10} />
              </Button>
            </div>
          ) : null}
        </div>

        <div className="bottom-row">
          <ExtractionRecordTags
            projectId={projectId}
            documentId={documentId}
            extractionFieldRecordId={extractionFieldRecord.extractionFieldRecordId}
            refreshFunction={refreshData}
            tags={extractionFieldRecord.tags}
            readOnly={readOnly}
            clearUndoStack={clearUndoStack}
          />

          <div className="page-number" onClick={() => goToPage(extractionFieldRecord.pageNumber)}>
            <FormattedMessage id="common.page" />
            {extractionFieldRecord.pageNumber}/{pageCount}
          </div>
        </div>
      </div>
    );
  })
);

class ExtractionRecordItems extends Component {
  static propTypes = {
    changeTextBox: PropTypes.func,
    clearUndoStack: PropTypes.func,
    documentId: PropTypes.any,
    finishDrag: PropTypes.func,
    group: PropTypes.any,
    isFinished: PropTypes.bool,
    list: PropTypes.array,
    onDelete: PropTypes.func,
    onExtractionMove: PropTypes.func,
    onSave: PropTypes.func,
    pageCount: PropTypes.any,
    pages: PropTypes.any,
    projectId: PropTypes.any,
    readOnly: PropTypes.any,
    refreshData: PropTypes.func
  };

  hoverFunction = (recordId, fieldId, enter) => {
    deemphasizeHighlight();

    if (enter) {
      emphasizeHighlight(recordId, fieldId);
    }
  };

  goToPage = page => {
    window.postMessage({ document_page_change: page }, window.location.href);
  };

  // Method for handling moving functionality
  moveExtraction = (dragIndex, hoverIndex) => {
    const { list, group } = this.props;
    const dragExtraction = list[dragIndex];

    list.splice(dragIndex, 1);
    list.splice(hoverIndex, 0, dragExtraction);

    this.props.onExtractionMove(list, group.id);
  };

  render() {
    const {
      group,
      changeTextBox,
      onDelete,
      onSave,
      pageCount,
      projectId,
      documentId,
      refreshData,
      finishDrag,
      readOnly,
      isFinished,
      list,
      clearUndoStack
    } = this.props;

    return (
      <div className="extraction-records">
        {list &&
          list.map((ext, index) => (
            <ExtractionRecord
              key={index}
              index={index}
              changeTextBox={changeTextBox}
              extractionFieldRecord={ext}
              extractionGroup={group}
              goToPage={this.goToPage}
              groupIndex={group.id}
              moveRecord={this.moveExtraction}
              onExtractionDelete={onDelete}
              saveExtractionRecord={onSave}
              hoverFunction={this.hoverFunction}
              projectId={projectId}
              documentId={documentId}
              refreshData={refreshData}
              finishDrag={finishDrag}
              isFinished={isFinished}
              readOnly={readOnly}
              isFirstItem={list && list.length === 1 ? true : false}
              pageCount={pageCount}
              clearUndoStack={clearUndoStack}
            />
          ))}
      </div>
    );
  }
}

export default withDragDropContext(ExtractionRecordItems);
