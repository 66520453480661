import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';

import Checkbox from 'components/shared/form/checkbox';
import Icon from 'components/shared/icon';
import ComplicatedIcon from 'components/extractions/complicated-icon/complicated-icon';
import Button from 'components/shared/single-click-button';

class TemplatesList extends Component {
  static propTypes = {
    selectedFieldsByTemplateId: PropTypes.object.isRequired,
    setSelectedFields: PropTypes.func.isRequired,
    showTrainingProgress: PropTypes.bool,
    templates: PropTypes.array.isRequired
  };

  state = {
    expandedTemplates: {}
  };

  toggleTemplateExpandedState = templateId => {
    this.setState({
      expandedTemplates: {
        ...this.state.expandedTemplates,
        [templateId]: !this.state.expandedTemplates[templateId]
      }
    });
  };

  toggleAllFieldsInTemplate = (template, checked) => {
    const { selectedFieldsByTemplateId, setSelectedFields } = this.props;
    const selectedExtractionFields = selectedFieldsByTemplateId[template.projectTemplateId];

    template.extractionFields.forEach(field => {
      if (checked === 'checked') {
        selectedExtractionFields.add(field.extractionFieldId);
      } else {
        selectedExtractionFields.delete(field.extractionFieldId);
      }
    });

    setSelectedFields(selectedFieldsByTemplateId);
  };

  toggleExtractionFieldSelection = (template, extractionFieldId) => {
    const { selectedFieldsByTemplateId, setSelectedFields } = this.props;
    const selectedExtractionFields = selectedFieldsByTemplateId[template.projectTemplateId];

    if (selectedExtractionFields.has(extractionFieldId)) {
      selectedExtractionFields.delete(extractionFieldId);
    } else {
      selectedExtractionFields.add(extractionFieldId);
    }

    setSelectedFields(selectedFieldsByTemplateId);
  };

  render() {
    const { expandedTemplates } = this.state;
    const { selectedFieldsByTemplateId, showTrainingProgress, templates } = this.props;

    return (
      <div className="templates-list">
        {templates.map(template => {
          const templateId = template.projectTemplateId;
          const fields = template.extractionFields || [];
          const isExpanded = expandedTemplates[templateId];
          const selectedFields = selectedFieldsByTemplateId[templateId] || new Set();

          const checkAllState = !selectedFields.size
            ? 'unchecked'
            : selectedFields.size === fields.length
              ? 'checked'
              : 'indeterminate';

          return (
            <Fragment key={templateId}>
              <div className="templates-list__item">
                <Checkbox
                  checked={checkAllState}
                  id={`templates-list__checkbox-${templateId}`}
                  className="templates-list__checkbox"
                  onChange={(id, checked) => {
                    this.toggleAllFieldsInTemplate(template, checked);
                  }}
                  disabled={!fields.length}
                />
                <div className="templates-list__item-name">{template.name}</div>
                {!!fields.length && (
                  <Button
                    className="templates-list__expand-button"
                    onClick={() => this.toggleTemplateExpandedState(templateId)}
                  >
                    <Icon name="special-arrow-down-blue" width={18} rotate={isExpanded ? 180 : 0} />
                  </Button>
                )}
              </div>
              {isExpanded &&
                fields.map(field => {
                  return (
                    <div key={field.extractionFieldId} className="templates-list__item templates-list__item--indent">
                      <Checkbox
                        checked={selectedFields.has(field.extractionFieldId) ? 'checked' : 'unchecked'}
                        id={`templates-list__checkbox-${templateId}-${field.extractionFieldId}`}
                        className="templates-list__checkbox"
                        onChange={() => {
                          this.toggleExtractionFieldSelection(template, field.extractionFieldId);
                        }}
                      />
                      <div className="templates-list__item-name">{field.extractionFieldName}</div>
                      {showTrainingProgress && (
                        <ComplicatedIcon
                          accessTypeId={field.accessTypeId}
                          disableInteraction
                          extractionId={field.extractionFieldId}
                          iconSize={22}
                          isTrained={field.isTrained}
                          isAutomaticExtraction={field.isAutomaticExtraction}
                          trainingCount={field.numberOfDocuments}
                          trainingTarget={field.minDocumentsToBeConsideredTrained}
                          templateCategoryId={template.templateCategoryId}
                        />
                      )}
                    </div>
                  );
                })}
            </Fragment>
          );
        })}
      </div>
    );
  }
}

export default TemplatesList;
