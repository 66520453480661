import { DragLayer } from 'react-dnd';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ITEM_TYPE_ICONS } from 'components/file-browser/file-list/constants';
import { ITEM_TYPES } from 'models/project-item';
import Icon from 'components/shared/icon';

/*
  Placeholder that is shown when a row is being dragged.

  This component is wrapped with react dnd to become a 'DragLayer'
*/
class DragHelper extends PureComponent {
  static propTypes = {
    dragHelper: PropTypes.object,
    getSelectedItemIds: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    readOnly: PropTypes.bool
  };

  render() {
    const { isDragging, dragHelper: { currentOffset, item, itemType }, getSelectedItemIds, readOnly } = this.props;
    const selectedItemIds = getSelectedItemIds();

    if (!isDragging || !currentOffset || itemType !== 'file-list-row') {
      return null;
    }

    return (
      <div className="file-list__drag-overlay">
        {readOnly ? null : (
          <div className="file-list__drag-helper" style={{ top: currentOffset.y, left: currentOffset.x }}>
            <Icon
              className="file-list__icon"
              name={
                selectedItemIds.length > 1
                  ? ITEM_TYPE_ICONS[ITEM_TYPES.DOCUMENT]
                  : ITEM_TYPE_ICONS[item.projectItem.itemTypeId]
              }
              width={16}
            />
            <span>{selectedItemIds.length > 1 ? `${selectedItemIds.length} items` : item.projectItem.itemName}</span>
          </div>
        )}
      </div>
    );
  }
}

export default DragLayer(monitor => {
  const distanceTravelled = monitor.getDifferenceFromInitialOffset();
  const hasPastThresholdDistance = Math.abs(distanceTravelled ? distanceTravelled.y : 0) > 8;

  return {
    dragHelper: {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getClientOffset()
    },
    isDragging: monitor.isDragging() && hasPastThresholdDistance
  };
})(DragHelper);
