import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropdown from 'components/shared/form/dropdown';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button from 'components/shared/single-click-button';
import CreateExtraction from '../create-extraction/create-extraction';
import ExtractionList from '../extraction-list/extraction-list';
import Icon from 'components/shared/icon';
import Modal from 'components/shared/modal';
import Constants from 'utils/constants';

import Permissions from 'permissions/permissions';
import { debounce } from 'underscore';

class AddExtraction extends Component {
  static propTypes = {
    addExtractionClicked: PropTypes.bool,
    addExtractions: PropTypes.func.isRequired,
    addToNewTemplate: PropTypes.func,
    changeAddExtractionClicked: PropTypes.func,
    changeIsAddExtractionButtonDisabled: PropTypes.func,
    currentTemplateName: PropTypes.any,
    data: PropTypes.any,
    documentTemplateCategoryId: PropTypes.number,
    extractionFieldGroups: PropTypes.array,
    fromCreateTemplateView: PropTypes.bool,
    groupByTemplateType: PropTypes.bool,
    intl: intlShape.isRequired,
    isAddExtractionButtonDisabled: PropTypes.bool,
    onAddExtractionsFail: PropTypes.func.isRequired,
    onAddExtractionsSuccess: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onHide: PropTypes.func,
    onSelectTemplateType: PropTypes.func,
    pageViewer: PropTypes.string.isRequired, // Specifies which page it's being used for reusability purposes
    readOnly: PropTypes.bool,
    showConfirmations: PropTypes.bool,
    templateTypes: PropTypes.array,
    currentTemplate: PropTypes.object
  };

  state = {
    extractionFieldGroup:
      this.props.extractionFieldGroups && this.props.extractionFieldGroups.length === 1
        ? this.props.extractionFieldGroups[0]
        : null,
    filter: '',
    isResponsePending: false,
    selectedExtractions: [],
    showAddExtractionConfirmation: false,
  };

  static defaultProps = {
    onClose: () => {},
    onHide: () => {},
    readOnly: false,
  };

  componentDidUpdate(prevProps) {
    const {
      groupByTemplateType,
      addExtractionClicked,
      isAddExtractionButtonDisabled,
      changeIsAddExtractionButtonDisabled,
      fromCreateTemplateView
    } = this.props;
    const { isResponsePending, selectedExtractions } = this.state;

    if (prevProps.groupByTemplateType !== groupByTemplateType) {
      this.setState({ selectedExtractions: [], filter: '' });
    }

    if (addExtractionClicked && !prevProps.addExtractionClicked) {
      this.onAddButtonClick();
    }

    const isButtonDisabled = isResponsePending || !selectedExtractions.length;
    if (isAddExtractionButtonDisabled !== isButtonDisabled && fromCreateTemplateView) {
      changeIsAddExtractionButtonDisabled(isButtonDisabled);
    }
  }

  // Filter the data by the search term (if any)
  getFilteredData = () => {
    var { filter } = this.state;
    var { data } = this.props;

    if (!data || !data.length || !filter) {
      return [...data];
    }

    return data.filter(
      ({ group, name }) =>
        (group !== null && group.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
        (name !== null && name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
    );
  };

  // Set the filter from the search input value
  onTextChange = debounce(value => {
    this.setState({ filter: value });
  }, 500);

  // Closing the component
  onClose = () => {
    const { onClose } = this.props;
    this.setState({ filter: '' });
    onClose();
  };

  // Hiding the component
  onHide = () => {
    const { onHide } = this.props;
    this.setState({ filter: '' });
    onHide();
  }; 

  // When a user selects an extraction field within group
  onExtractionSelect = ext => {
    const { selectedExtractions } = this.state;
    const { pageViewer } = this.props;
    const allowMultiSelect = pageViewer !== 'DocumentPage';
    const isSelected = selectedExtractions.some(e => e.id === ext.id);

    if (isSelected) {
      // Deselect if already selected
      this.setState({ selectedExtractions: selectedExtractions.filter(e => e.id !== ext.id) });
    } else {
      // Select if not already selected
      this.setState({ selectedExtractions: allowMultiSelect ? [...selectedExtractions, ext] : [ext] });
    }
  };

  // When a user selects all the extraction fields
  onCheckAll = (allIds, title) => {
    const { selectedExtractions } = this.state;

    const isSelected = allIds[title].some(item => selectedExtractions.find(e => e.id === item.id));

    if (isSelected) {
      // Deselect all if some are already selected
      return this.setState({
        selectedExtractions: selectedExtractions.filter(e => !allIds[title].find(({ id }) => e.id === id))
      });
    } else {
      // Select all if none already selected
      return this.setState({ selectedExtractions: [...selectedExtractions, ...allIds[title]] });
    }
  };

  // Handle a click on the add button
  onAddButtonClick = () => {

    const { showConfirmations, changeAddExtractionClicked, fromCreateTemplateView} = this.props;
    const { selectedExtractions } = this.state;

    if (changeAddExtractionClicked && fromCreateTemplateView) {
      changeAddExtractionClicked(false);
    }

    if (showConfirmations || !selectedExtractions[0].groupId) {
      this.setState({ showAddExtractionConfirmation: true });
    } else {
        this.addExtractions();
    }
  };

  // Add extractions
  addExtractions = () => {
    var { addExtractions, documentTemplateCategoryId } = this.props;
    var { selectedExtractions, extractionFieldGroup } = this.state;

    this.setState({ showAddExtractionConfirmation: false, isResponsePending: true, extractionFieldGroup: null });

    return addExtractions(
      Array.from(
        new Set(
          selectedExtractions.map(({ id, groupId }) => {
            return {
              id,
              groupId:  
               groupId 
                ? groupId
                : extractionFieldGroup != null ? extractionFieldGroup.groupId : groupId
              ? groupId
              : extractionFieldGroup.groupId
            };
          })
        )
      )
    )
      .then(() =>
        this.onAddExtractionsSuccess(
          selectedExtractions.map(extraction =>
            extraction.groupId ? extraction : { ...extraction, groupId: extractionFieldGroup.groupId }
          )
        )
      )
      .catch(this.onAddExtractionsFail);
  };

  // Handle add extraction success
  onAddExtractionsSuccess = extractions => {
    var { onAddExtractionsSuccess } = this.props;
    this.setState({ filter: '', selectedExtractions: [], isResponsePending: false });
    onAddExtractionsSuccess(extractions);
  };

  // Handle add extraction fail
  onAddExtractionsFail = () => {
    var { onAddExtractionsFail } = this.props;
    this.setState({ isResponsePending: false });
    onAddExtractionsFail();
  };

  // Render the add extraction confirmation modal
  renderConfirmationModal = () => {
    const { currentTemplateName, extractionFieldGroups, intl, documentTemplateCategoryId, currentTemplate } = this.props;
    const { extractionFieldGroup, selectedExtractions } = this.state;
    if(documentTemplateCategoryId == Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING && currentTemplate){
      if(extractionFieldGroup && extractionFieldGroup?.groupSystemName == Constants.CustomExtractionFieldGroups.GENAI_CUSTOM_GROUP_SYSTEMNAME)
        extractionFieldGroup.groupName = currentTemplate.customDocTypeName

      if(extractionFieldGroups.length > 1 && extractionFieldGroups.filter(x => x.groupSystemName ==  Constants.CustomExtractionFieldGroups.GENAI_CUSTOM_GROUP_SYSTEMNAME).length > 0)
        extractionFieldGroups.filter(x => x.groupSystemName ==  Constants.CustomExtractionFieldGroups.GENAI_CUSTOM_GROUP_SYSTEMNAME)[0].groupName = currentTemplate.customDocTypeName
    }
      
    return (
      <Modal>
        <div className="modal__content">
          <h3>
            <FormattedMessage id="add-extraction.modal.title" />
          </h3>
          <p>
            {selectedExtractions.length === 1 ? (
              <FormattedMessage
                id="add-extraction.modal.question"
                values={{
                  extractionName: selectedExtractions[0].name,
                  templateName: currentTemplateName
                }}
              />
            ) : (
              <FormattedMessage
                id="add-extraction.modal.question.multiple"
                values={{
                  extractionNames: selectedExtractions
                    .map(extraction => extraction.name)
                    .join(', ')
                    .replace(/,(?=[^,]*$)/, ' and'),
                  templateName: currentTemplateName
                }}
              />
            )}
          </p>
          {/* EF group dropdown  */}
          {(!selectedExtractions[0].groupId ||
            documentTemplateCategoryId !== Constants.TemplateCategories.NON_DETAIL_TESTING && 
            documentTemplateCategoryId !== Constants.TemplateCategories.NON_EDITABLE_GENAI_NONDETAIL_TESTING &&
            documentTemplateCategoryId !== Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING) && (
            <>
              <p className={`add-extraction__input-label`}>
                <FormattedMessage id="add-extraction.ef-group" />
              </p>
              <div className={`add-extraction__input`}>
                <p className={`add-extraction__input-header`}>
                  <FormattedMessage id="add-extraction.ef-group-label" />
                </p>
                <Dropdown
                  defaultMessage={intl.formatMessage({ id: 'add-extraction.ef-group-placeholder' })}
                  onSelection={option => this.setState({ extractionFieldGroup: option })}
                  selectedOption={extractionFieldGroup}
                  options={extractionFieldGroups}
                  renderOption={selectedOption => <span>{selectedOption.groupName}</span>}
                />
              </div>
            </>
          )}
          <div className="modal__footer">
            <Button
              className="btn btn-secondary"
              onClick={() =>
                this.setState({
                  showAddExtractionConfirmation: false,
                  extractionFieldGroup:
                    extractionFieldGroups && extractionFieldGroups.length === 1 ? extractionFieldGroups[0] : null
                })
              }
            >
              <FormattedMessage id="add-extraction.modal.cancel-button" />
            </Button>
            <Button
              className="btn btn-primary"
              disabled={selectedExtractions[0].groupId ? false : !extractionFieldGroup}
              onClick={this.addExtractions}
            >
              <FormattedMessage id="add-extraction.modal.confirm-button" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  onSelectType = selectedType => {
    const { onSelectTemplateType, data } = this.props;
    this.setState(
      {
        selectedExtractions:
          selectedType.type.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING
            ? data.filter(ext => selectedType.extractionFieldIds.includes(ext.id))
            : []
      },
      () => onSelectTemplateType(selectedType)
    );
  };

  render() {
    const {
      pageViewer,
      readOnly,
      groupByTemplateType,
      templateTypes,
      addToNewTemplate,
      fromCreateTemplateView,
      documentTemplateCategoryId
    } = this.props;

    const {
      filter,
      isResponsePending,
      selectedExtractions,
      showAddExtractionConfirmation,
    } = this.state;

    const openByDefault = filter && filter.length && filter.length > 0;
    const isButtonDisabled = isResponsePending || !selectedExtractions.length;
    const canCreateExtractionField = Permissions.Project.Template.TemplateExtractionField.canCreate();
    const filteredData = this.getFilteredData();

    return (
      <div className={`add-extraction ${pageViewer}`}>
        {/* Project page toolbar */}
        {pageViewer === 'ProjectPage' && (
          <div className={`add-extraction ${pageViewer}__toolbar`}>
            {!fromCreateTemplateView && (
              <Button className="icon-button" onClick={this.onHide}>
                <Icon name="special-back" width={16} />
              </Button>
            )}
            <span>
              <FormattedMessage id="add-extraction.add.title" />
            </span>
          </div>
        )}
        {/* Document page toolbar */}
        {pageViewer === 'DocumentPage' && (
          <div className="toolbar">
            <Button className="icon-button" onClick={this.onClose}>
              <Icon name="special-back" width={20} height={20} />
            </Button>
            <h2>
              <FormattedMessage id="search-extraction.add.title" />
            </h2>
            <Button className="icon-button" onClick={this.onClose}>
              <Icon name="special-cross-black" width={20} height={20} />
            </Button>
          </div>
        )}
        {/* Main content */}
        <div className={`add-extraction ${pageViewer}__content`}>
          <div className={`add-extraction ${pageViewer}__filter`}>
            <div className={`add-extraction ${pageViewer}__filter-title`}>
              <FormattedMessage id="add-extraction.choose.title" />
            </div>
            {!groupByTemplateType && (
              <FormattedMessage id="add-extraction.search-placeholder">
                {placeholder => (
                  <input
                    id="add-extraction-filter-input"
                    className="add-extraction__filter-input"
                    type="text"
                    placeholder={placeholder}
                    onChange={e => this.onTextChange(e.target.value)}
                    defaultValue={this.filter}
                  />
                )}
              </FormattedMessage>
            )}
          </div>
          <div className={`add-extraction ${pageViewer}__scroll-area`}>
            {filteredData && filteredData.length > 0 && (
              <FormattedMessage id="add-extraction.extraction-list.name">
                {nullGroupName => (
                  <ExtractionList
                    data={filteredData}
                    groupKey={'groupId'}
                    openByDefault={openByDefault}
                    selectedExtractions={selectedExtractions}
                    showCheckboxes={true}
                    nullGroupName={nullGroupName}
                    pageViewer={pageViewer}
                    onExtractionSelect={this.onExtractionSelect}
                    onCheckAll={this.onCheckAll}
                    groupByTemplateType={groupByTemplateType}
                    templateTypes={templateTypes}
                    readOnly={readOnly}
                    onSelectTemplateType={this.onSelectType}
                    templateCategoryId={documentTemplateCategoryId}
                  />
                )}
              </FormattedMessage>
            )}
          </div>

          {/* Render the footer */}
          {readOnly ? null : (
            <div className={`add-extraction ${pageViewer}__footer`}>
              {/* Add button */}
              {groupByTemplateType ? (
                <Button
                  id="add-extraction-create-button"
                  disabled={selectedExtractions.length === 0}
                  className={`add-extraction ${pageViewer}__add-button btn btn-primary`}
                  onClick={() => {
                    addToNewTemplate(selectedExtractions);
                    this.setState({ selectedExtractions: [] });
                  }}
                >
                  <FormattedMessage id="add-extraction.add-button" />
                </Button>
              ) : fromCreateTemplateView ? null : (
                <Button
                  id="add-extraction-add-button"
                  disabled={isButtonDisabled}
                  className={`add-extraction ${pageViewer}__add-button btn btn-primary`}
                  onClick={this.onAddButtonClick}
                >
                  <FormattedMessage id="add-extraction.add-button" />
                </Button>
              )}
            </div>
          )}
        </div>
        {/* Render the confirmation modal */}
        {showAddExtractionConfirmation && !isButtonDisabled && this.renderConfirmationModal()}
      </div>
    );
  }
}

export default injectIntl(AddExtraction);
