import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { markAllDocumentsAsReviewed } from 'store/api';
import enhanceWithClickOutside from 'react-click-outside';
import Icon from 'components/shared/icon';
import { ITEM_TYPES, ITEM_STATUSES } from 'models/project-item';
import Button from 'components/shared/single-click-button';

class ReviewPopover extends Component {
  static propTypes = {
    currentFolder: PropTypes.object.isRequired,
    invalidate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    selectedItems: PropTypes.object.isRequired
  };

  state = {
    isProcessing: false
  };

  get selectedItems() {
    const { currentFolder, selectedItems } = this.props;
    return currentFolder.children
      .filter(projectItem => selectedItems[projectItem.projectItemId])
      .filter(item => item.itemTypeId === ITEM_TYPES.DOCUMENT && item.itemStateId === ITEM_STATUSES.PROCESSED);
  }

  handleClickOutside(event) {
    const { onClose } = this.props;
    onClose();
  }

  onSubmit = event => {
    const { projectId, onClose, invalidate } = this.props;
    const selectedItems = this.selectedItems;

    this.setState({
      isProcessing: true
    });

    return markAllDocumentsAsReviewed({ projectId, documentIds: selectedItems.map(i => i.projectItemId) })
      .then(() => {
        this.setState({
          isProcessing: false
        });
        invalidate();
        onClose();
      })
      .catch(() => {
        this.setState({
          isProcessing: false
        });
        invalidate();
      });
  };

  render() {
    const { onClose } = this.props;
    const { isProcessing } = this.state;
    const selectedItems = this.selectedItems;

    return (
      <div className="review-popover popover">
        <div className="review-popover__header popover__header">
          <h3 className="review-popover__title popover__title">
            <FormattedMessage id="file-browser.review-popover.title" />
          </h3>
          <Button onClick={onClose} className="btn btn-no-margin icon-button">
            <Icon name="special-cross-black" width={14} />
          </Button>
        </div>
        <div className="review-popover__body popover__section">
          {selectedItems.length ? (
            <FormattedMessage id="file-browser.review-popover.message" values={{ numReviewed: selectedItems.length }} />
          ) : (
            <FormattedMessage id="file-browser.review-popover.no-files-message" />
          )}
        </div>
        <div className="popover__footer">
          <Button size="xsmall" onClick={onClose} className="btn btn-secondary">
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="xsmall"
            onClick={this.onSubmit}
            disabled={!selectedItems.length || isProcessing}
            className="btn btn-primary btn-no-margin"
          >
            <FormattedMessage id="common.confirm" />
          </Button>
        </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(ReviewPopover);
