import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/shared/icon';

//TODO: Cleanup these roles
const roles = [{ ids: [4], key: 'read-only' }, { ids: [5], key: 'contributor' }, { ids: [6, 7, 8], key: 'owner' }];

// Role dropdown selector
export default class RoleDropdown extends Component {
  static propTypes = {
    onRoleChange: PropTypes.func,
    role: PropTypes.object,
    visibleOptions: PropTypes.array
  };

  state = {
    isMenuOpen: false
  };

  onMenuToggle = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  onRoleChange = role => {
    const { onRoleChange } = this.props;
    onRoleChange(role);
  };

  render() {
    const { role, visibleOptions } = this.props;

    var filteredRoles = !visibleOptions ? roles : roles.filter(r => visibleOptions.indexOf(r.key) !== -1);

    return (
      <MenuWrapper
        className="team-members__role-dropdown"
        onMenuToggle={this.onMenuToggle}
        onSelection={this.onRoleChange}
      >
        <MenuButton className="form-dropdown btn team-members__role-dropdown-button" tag="button" type="button">
          {role ? (
            <FormattedMessage id={`team-members.roles.${role.key}`} />
          ) : (
            <span className="team-members__role-dropdown-placeholder">
              <FormattedMessage id="team-members.role-select" />
            </span>
          )}
          <Icon
            className="team-members__role-dropdown-icon"
            name="special-arrow-down-blue"
            width={14}
            height={8}
            rotate={this.state.isMenuOpen ? 180 : 0}
          />
        </MenuButton>
        <Menu className="team-members__role-dropdown-body">
          {filteredRoles.map((currentRole, i) => {
            var selected = role && currentRole.key === role.key;
            return (
              <MenuItem
                key={`role-dropdown.${i}`}
                className={`form-dropdown__item team-members__role-dropdown-item ${selected ? 'selected' : ''}`}
                value={currentRole}
              >
                <FormattedMessage id={`team-members.roles.${currentRole.key}`} />
                {selected ? (
                  <Icon
                    className="team-members__role-dropdown-item-icon"
                    name="special-checked-black"
                    width={14}
                    height={8}
                  />
                ) : null}
              </MenuItem>
            );
          })}
        </Menu>
      </MenuWrapper>
    );
  }
}
