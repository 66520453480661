export const EXPORT_FORMATS = {
  EXCEL: 0
  // PDF: 1,
  // WORD: 2
};

export const EXPORT_LAYOUTS = {
  // ROW: 0,
  COLUMN: 1
  // TABLE: 2
};

export const EXPORT_QUALITY = {
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3
};
