import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/icon';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { Button as MenuButton, Menu, MenuItem, Wrapper as MenuWrapper } from 'react-aria-menubutton';

class TemplateDropdown extends Component {
  static propTypes = {
    onTemplateClick: PropTypes.func,
    readOnly: PropTypes.bool,
    selectedId: PropTypes.number,
    selectedName: PropTypes.string,
    templateList: PropTypes.array,
    errClassName: PropTypes.string
  };

  state = {
    isMenuOpen: false
  };

  onTemplateSelected = template => {
    // Navigates user to the selected template
    return this.props.onTemplateClick(template);
  };

  onMenuToggle = isMenuOpen => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  render() {
    const { templateList, selectedName, selectedId, errClassName } = this.props;
    const updateNameErrClass = errClassName ? errClassName : '';
    return (
      <MenuWrapper
        className={`template-dropdown__container ${updateNameErrClass}`}
        onMenuToggle={this.onMenuToggle}
        onSelection={this.onTemplateSelected}
      >
        <MenuButton className="form-dropdown btn form-input-dropdown__button" tag="button" type="button">
          <span className="template-dropdown__text" title={selectedName}>
            {selectedName ? selectedName : ''}
          </span>
          <Icon
            name="special-arrow-down-blue"
            width={14}
            height={8}
            rotate={this.state.isMenuOpen ? 180 : 0}
            className="btn form-input-dropdown__caret"
          />
        </MenuButton>
        <Menu className="template-dropdown__body-container">
          <div className="template-dropdown__scroll-area">
            {templateList &&
              templateList.map((template, index) => (
                <MenuItem
                  key={index}
                  value={template}
                  className={classNames(
                    'form-input-dropdown__item',
                    selectedId === template.projectTemplateId && 'form-input-dropdown__item--selected'
                  )}
                >
                  <span className="template-dropdown__textdropdown" title={template.name}>
                    {template.name}
                  </span>
                  <span className="template-dropdown__icon">
                    {selectedId === template.projectTemplateId && (
                      <Icon name="special-checked-black" width={14} height={8} />
                    )}
                  </span>
                </MenuItem>
              ))}
          </div>
        </Menu>
      </MenuWrapper>
    );
  }
}

export default TemplateDropdown;
