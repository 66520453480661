import { debounce } from 'underscore';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getEngagementSearchResults, getEngagementDetails } from 'store/api';
import ValidTextArea from 'components/project-creation/project-details/shared/valid-text-area';
import EngagementDropdown from 'components/project-creation/project-details/shared/engagement-dropdown';

/*
 * Project MAT input field
 * Performs checks/validation
 */
export default class MATInputField extends Component {
  static propTypes = {
    allowEditing: PropTypes.bool,
    checkValid: PropTypes.func,
    intl: intlShape,
    onChange: PropTypes.func,
    project: PropTypes.any,
    projectState: PropTypes.object,
    setEngagementId: PropTypes.func,
    setEngagementInfo: PropTypes.func,
    updateProjectEngagement: PropTypes.func,
    clientInfo: PropTypes.object,
    checkClientValid: PropTypes.func
  };

  state = {
    engagementName: '',
    error: null,
    isDropdownOpen: false,
    isEditing: true,
    isValid: false,
    projectInfo: null,
    searchResults: null,
    selectedId: null
  };

  componentDidMount() {
    const {
      project: { engagementName, engagementId },
      projectState: { INPROGRESS, SETTINGS, ARCHIVE, COPY }
    } = this.props;

    if (INPROGRESS) {
      // Project creation in progress
      this.setState({ engagementName });
      this.getEngagementInfo(engagementId);
    } else if (COPY) {
      // Project copy workflow
      if (!engagementId) {
        this.setState({ isValid: false });
      } else {
        this.setState({ engagementName });
        this.getEngagementInfo(engagementId);
      }
    } else if (SETTINGS || ARCHIVE) {
      // Project settings or archive project
      this.setState({ engagementName, isEditing: false });
      this.getEngagementInfo(engagementId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkValid, clientInfo, checkClientValid, setEngagementInfo } = this.props;
    const { isValid, engagementName } = this.state;

    if(clientInfo == null && engagementName!= null || (prevProps.clientInfo?.clientId != clientInfo?.clientId) ){
      this.setState({selectedId: ''});
      this.setState({engagementName: null});
      setEngagementInfo(null);
      checkValid(false);
    }

    if (prevState.isValid !== isValid) {
      checkValid(isValid);
      checkClientValid(isValid);
      
    }
  }

  // Fetch the MAT engagement info
  getEngagementInfo = value => {
    const { setEngagementId } = this.props;

    if (!value) {
      this.setState({ isValid: true });
    }

    return getEngagementDetails(value).then(res => {
      setEngagementId(value);
      this.setState({
        isDropdownOpen: false,
        isEditing: !(this.props.projectState.SETTINGS || this.props.projectState.ARCHIVE),
        isValid: true,
        searchResults: null,
        engagementName: res.data.engagementName,
        selectedId: value
      });
      return res;
    });
  };

  // Get MAT search results when user is typing
  getSearchResults = debounce(() => {
    const { intl, clientInfo } = this.props;
    const { engagementName } = this.state;

    if (engagementName.length <= 0 || (!clientInfo && !clientInfo.clientId)) {
      return;
    }

    return getEngagementSearchResults(engagementName, clientInfo.clientId).then(res => {

      if (res.data.length <= 0) {
        return this.setState({
          searchResults: null,
          isDropdownOpen: false,
          isValid: false,
          error: intl.formatMessage({ id: 'project-details.body.engagement-error' })
        });
      }
      return this.setState({ searchResults: res.data, isDropdownOpen: true, error: null });
    });
  }, 500);

  onMATClick = value => {
    const { projectState, setEngagementId, setEngagementInfo, updateProjectEngagement, clientInfo,checkValid } = this.props;
    value.clientId = clientInfo.clientId;
    value.clientName = clientInfo.clientName;
    value.engagementFiscalYearEnd = clientInfo.engagementFiscalYearEnd;
    setEngagementId(value.engagementId);

    this.setState({
      selectedId: value.engagementId,
      searchResults: null,
      engagementName: value.engagementName,
      isEditing: !(projectState.SETTINGS || projectState.ARCHIVE),
      isValid: true,
      isDropdownOpen: false
    });
    checkValid(true);
    setEngagementInfo(value);

    return projectState.SETTINGS ? updateProjectEngagement(value) : {};
  };

  onEditClick = () => {
    this.setState({ isEditing: true, autoFocus: true });
  };

  onBlur = () => {
    const { engagementName, isValid } = this.state;
    const {
      projectState: { SETTINGS },
      project
    } = this.props;
    // Save on click outside
    if (SETTINGS) {
      // If it's the same dont do anything
      if (engagementName && engagementName.length <= 0) {
        return this.setState({ engagementName: project.engagementName, isEditing: false });
      }
      if (isValid) {
        return this.setState({ isEditing: false });
      }
    }
  };

  onChange = engagementName => {
    const { onChange } = this.props;

    this.setState({ engagementName });
    onChange(engagementName);

    if (engagementName && engagementName.length <= 0) {
      this.clearData();
    }
  };

  onKeyDown = e => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      this.clearData();
    }
  };

  clearData = () => {
    const { setEngagementInfo, setEngagementId } = this.props;

    setEngagementInfo(null);
    setEngagementId('');

    return this.setState({
      isValid: false,
      searchResults: null,
      projectInfo: null,
      isDropdownOpen: false,
      error: null
    });
  };

  render() {
    const { intl, allowEditing, clientInfo } = this.props;
    const { engagementName, error, isEditing, isDropdownOpen, searchResults, autoFocus } = this.state;

    return (
      <div style={{ position: 'relative'}}>
        <ValidTextArea
          autoFocus={autoFocus}
          errors={error}
          headingId="project-details.body.engagement"
          isEditing={isEditing}
          isOwner={allowEditing}
          maxLength={80}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onEditClick={this.onEditClick}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.getSearchResults}
          placeholder={intl.formatMessage({ id: 'project-details.body.engagement-placeholder' })}
          value={clientInfo != null ? engagementName : ''}
          clientInfo={clientInfo}
          isAsteriskRequired = "true"
        />

        {isDropdownOpen && engagementName.length > 0 && searchResults ? (
          <EngagementDropdown
            data={searchResults}
            keyword={engagementName}
            onClickOutside={() => this.setState({ isDropdownOpen: false })}
            onMATClick={this.onMATClick}
          />
        ) : null}
      </div>
    );
  }
}
