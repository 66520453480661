import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Icon from 'components/shared/icon';
import ComplicatedIcon from '../complicated-icon/complicated-icon';
import ColorHelper from '../helpers/colorHelper';
import ExtractionFieldDropdown from 'containers/extractions/extraction-field-dropdown';
import ReactTooltip from 'react-tooltip';
import ExtractionRecordItems from './extraction-record-item';
import * as api from 'store/api';
import history from 'utils/history';
import { getCurrentRegion } from 'store/api';

class ExtractionRecordList extends Component {
  static propTypes = {
    clearUndoStack: PropTypes.func,
    data: PropTypes.array,
    documentId: PropTypes.any,
    editExtractionFieldRecord: PropTypes.func,
    extractionFieldRecords: PropTypes.any,
    extractionFields: PropTypes.any,
    intl: PropTypes.any,
    pageCount: PropTypes.number,
    pages: PropTypes.any,
    projectId: PropTypes.any,
    pushUndoStack: PropTypes.func,
    readOnly: PropTypes.bool,
    refreshData: PropTypes.func,
    removeExtractionFieldRecord: PropTypes.func,
    setCompareType: PropTypes.func,
    template: PropTypes.any
  };

  state = {
    updatedRecord: {},
    finishDrag: false,
    extractionField: null,
    extractionFieldRecords: [],
    openGroups: []
  };

  saveRecordTimeout = {};

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate = (prevProps, prevState) => {
    var { extractionFieldRecords, extractionFields } = this.props;

    if (
      extractionFieldRecords !== prevProps.extractionFieldRecords ||
      extractionFields !== prevProps.extractionFields
    ) {
      this.initialize();
    }
  };

  initialize = () => {
    this.setState({
      extractionFieldRecords: JSON.parse(JSON.stringify(this.props.extractionFieldRecords))
    });
  };

  getGroupedExtractions = () => {
    var { data } = this.props;
    var { extractionFieldRecords } = this.state;
    var extractions = [];

    data.forEach(extraction => {
      var currentField = Object.assign({}, extraction);
      currentField.records = extractionFieldRecords.filter(efr => efr.extractionFieldId === currentField.id);

      if (currentField.groupId === null) {
        currentField.groupId = 0;
      }

      if (currentField.groupId !== null) {
        if (!extractions[currentField.groupId]) {
          extractions[currentField.groupId] = {
            extractions: [],
            title: extraction.group ? extraction.group : extractions.selectedName,
            displayOrder: extraction.groupDisplayOrder
          };
        }
        extractions[currentField.groupId].extractions.push(currentField);
      }
    });

    return extractions;
  };

  //Used for display mode 0 (grouped by field)
  getRecordsByField = () => {
    var { extractionFields } = this.props;
    var { extractionFieldRecords } = this.state;
    var fields = [];

    extractionFields.forEach(ef => {
      var currentField = Object.assign({}, ef);
      currentField.records = [];

      extractionFieldRecords.forEach(efr => {
        if (efr.extractionFieldId === currentField.extractionFieldId) {
          currentField.records.push(efr);
        }

        //Temporary sort order (used while dragging is in progress)
        currentField.records.sort((a, b) => {
          if (a.sortOrder && b.sortOrder) return a.sortOrder - b.sortOrder;
          return 0;
        });
      });

      fields.push(currentField);
    });

    return fields;
  };

  //Used for display mode 1 (ungrouped, ordered by record position)
  getRecordsByPosition = () => {
    var { extractionFields } = this.props;
    var extractionFieldRecords = this.state.extractionFieldRecords.slice(0);
    var records = [];

    extractionFieldRecords.sort((a, b) => {
      var diff = a.pageNumber - b.pageNumber;
      if (diff === 0) return a.location[0] - b.location[0];
      return diff;
    });

    //Add each record in order to the list (wrapped in the corresponding field)
    extractionFieldRecords.forEach(efr => {
      extractionFields.forEach(ef => {
        if (efr.extractionFieldId === ef.extractionFieldId) {
          var currentField = Object.assign({}, ef);
          currentField.records = [];
          currentField.records.push(efr);
          records.push(currentField);
        }
      });
    });

    //Display empty groups at the bottom of the list
    extractionFields.forEach(ef => {
      if (!records.some(record => record.extractionFieldId === ef.extractionFieldId)) {
        var currentField = Object.assign({}, ef);
        currentField.records = [];
        records.push(currentField);
      }
    });

    return records;
  };

  removeRecordFunction = (recordId, pageNum) => {
    var { projectId, documentId, removeExtractionFieldRecord } = this.props;

    removeExtractionFieldRecord(projectId, documentId, recordId, pageNum);
  };

  changeTextBox = (fieldId, recordId, value) => {
    var { extractionFieldRecords } = this.state;

    extractionFieldRecords.forEach(record => {
      if (record.extractionFieldRecordId === recordId) {
        record.extractFieldInstanceData = value;
        record.dirty = true;

        //Autosave logic
        clearTimeout(this.saveRecordTimeout[record.extractionFieldRecordId]);
        this.saveRecordTimeout[record.extractionFieldRecordId] = setTimeout(() => {
          this.saveExtractionRecord(record);
        }, 5000);
      }
    });

    this.setState({
      extractionFieldRecords
    });
  };

  saveExtractionRecord = record => {
    var { projectId, documentId, editExtractionFieldRecord } = this.props;
    const { extractionFieldRecords } = this.state;
    clearTimeout(this.saveRecordTimeout[record.extractionFieldRecordId]);
    if (record.dirty) {
      if (record.extractFieldInstanceData !== '') {
        editExtractionFieldRecord(
          projectId,
          documentId,
          record.extractionFieldId,
          record.extractionFieldRecordId,
          record.extractFieldInstanceData
        );
      } else {
        const cleanRecord = this.props.extractionFieldRecords.find(efr => efr.extractionFieldRecordId === record.extractionFieldRecordId);
        extractionFieldRecords.forEach(record2 => {
          if (record.extractionFieldRecordId === record2.extractionFieldRecordId) {
            record.extractFieldInstanceData = cleanRecord.extractFieldInstanceData;
          }
        });
        this.setState({ extractionFieldRecords });
      }
    }
  };

  // Save moved ids to state
  moveExtractionRecord = (records, fieldId) => {
    var extractionFieldRecords = this.state.extractionFieldRecords.slice(0);

    records.forEach((record, index) => {
      extractionFieldRecords.forEach(efr => {
        if (record.extractionFieldRecordId === efr.extractionFieldRecordId) {
          efr.sortOrder = index + 1;
        }
      });
    });

    this.setState({
      extractionFieldRecords,
      draggingExtractionFieldRecords: records,
      draggingFieldId: fieldId
    });
  };

  // When user finishes dragging, save the id order to DB
  finishDrag = ids => {
    var { draggingFieldId, draggingExtractionFieldRecords } = this.state;
    var { projectId, documentId, clearUndoStack } = this.props;
    var recordIds = draggingExtractionFieldRecords.map(v => v.extractionFieldRecordId);

    clearUndoStack();

    return api.moveExtractionRecord(projectId, documentId, draggingFieldId, recordIds);
  };

  createExtractionRecordComparison = extractionField => {
    const { projectId, setCompareType } = this.props;

    api
      .createComparison({
        baseEntityId: extractionField.records[0].extractionFieldRecordId,
        projectId,
        compareType: 'extractionfield'
      })
      .then(response => {
        setCompareType('extractionfield');
        history.push({
          pathname: `/region/${getCurrentRegion()}/project/${projectId}/compare`,
          state: { extractionField: extractionField }
        });
      });
  };

  getExtraction = (extractionField, k) => {
    var { documentId, projectId, refreshData, pageCount, readOnly, pages, pushUndoStack, clearUndoStack,template } = this.props;

    return (
      <div
        id={`extraction-field-${extractionField.id}`}
        className="extraction-field"
        key={k + '-' + extractionField.id}
      >
        <div className="header-row">
          <h3>
            <span
              className="color-marker"
              style={{ background: ColorHelper.highlight_colors[extractionField.color] }}
            />
            <span data-tip data-for={`extraction-field.tooltip.${extractionField.id}.${k}`}>
              {extractionField.name}
            </span>

            {extractionField.failed ? (
              <div className="extraction-field-errors">
                <Icon name="special-error" width={5} height={12} />
                <span>
                  <FormattedMessage id="extraction-field-dropdown.error" />
                </span>
              </div>
            ) : null}
            <span className="spacer">
              <ReactTooltip
                id={`extraction-field.tooltip.${extractionField.id}.${k}`}
                className="template-extraction__tooltip"
                effect="solid"
                place="right"
                data-multiline
                delayShow={1300}
                delayHide={500}
              >
                <p className="template-extraction__tooltip-paragraph">{extractionField.name}</p>

                <p className="template-extraction__tooltip-description">
                  <FormattedMessage id="template-extractions.tooltip.description" />
                </p>

                {extractionField.description && extractionField.description.length > 400 ? (
                  <div className="template-extraction__tooltip-box">
                    <p className="template-extraction__tooltip-paragraph">{extractionField.description}</p>
                  </div>
                ) : (
                  <p className="template-extraction__tooltip-paragraph">{extractionField.description}</p>
                )}
              </ReactTooltip>
            </span>
          </h3>
          <div className={`icon-wrapper ${extractionField.records.length === 0 ? 'empty' : ''}`}>
            <ComplicatedIcon
              projectId={projectId}
              extractionId={extractionField.id}
              accessTypeId={extractionField.AccessTypeId}
              isTrained={extractionField.isTrained}
              isAutomaticExtraction={extractionField.isAutomaticExtraction}
              refreshFunction={refreshData}
              trainingCount={extractionField.trainingCount}
              trainingTarget={extractionField.trainingTarget}
              templateCategoryId = {template.templateCategoryId}
            />
          </div>

          {extractionField.records.length === 0 ? null : (
            <ExtractionFieldDropdown
              extractionField={extractionField}
              documentId={documentId}
              projectId={projectId}
              pushUndoStack={pushUndoStack}
              refreshData={refreshData}
              readOnly={readOnly}
              onCreateComparison={this.createExtractionRecordComparison}
            />
          )}
        </div>

        <ExtractionRecordItems
          clearUndoStack={clearUndoStack}
          changeTextBox={this.changeTextBox}
          documentId={documentId}
          finishDrag={() => {
            this.finishDrag();
            this.setState({ finishDrag: true });
          }}
          isFinished={this.state.finishDrag}
          group={extractionField}
          list={extractionField.records}
          onDelete={this.removeRecordFunction}
          onExtractionMove={this.moveExtractionRecord}
          onSave={this.saveExtractionRecord}
          pageCount={pageCount}
          pages={pages}
          projectId={projectId}
          readOnly={readOnly}
          refreshData={refreshData}
        />
      </div>
    );
  };

  getAccordion = (group, key) => {
    const { openGroups } = this.state;
    var state = openGroups[key] !== undefined ? openGroups[key] : true;
    var extractionElements = group.extractions.map(this.getExtraction);
    return (
      <div className={`accordion ${state ? 'open' : ''}`} key={key}>
        <div className="title">
          <div
            className="wrapper"
            onClick={e => {
              e.stopPropagation();
              openGroups[key] = !state;
              this.setState({ openGroups: openGroups });
            }}
          >
            <h3>{group.title}</h3>
            <span className="icons">
              <Icon name="special-arrow-down-blue" width={20} height={20} rotate={state ? 180 : 0} />
            </span>
          </div>
        </div>
        {state ? extractionElements : null}
      </div>
    );
  };

  sortByDisplayOrder = groupedExtractions => {
    for (var i in groupedExtractions) {
      groupedExtractions[i].extractions.sort((ef1, ef2) => ef1.displayOrder - ef2.displayOrder);
    }

    return groupedExtractions.sort((g1, g2) => g1.displayOrder - g2.displayOrder);
  };

  getAccordions = () => {
    var accordions = [];
    var groupedExtractions = this.sortByDisplayOrder(this.getGroupedExtractions());
    for (var i in groupedExtractions) {
      accordions.push(this.getAccordion(groupedExtractions[i], i));
    }

    return accordions;
  };

  render() {
    var { template } = this.props;

    return (
      <div className="extraction-record-list">
        <div className="toolbar">
          <div className="template-dropdown">
            <div className="template-title" title={template.name}>
              {template.templateTypeID ? (
                <FormattedMessage id={`project-creation-templateType_${template.templateTypeID}`} />
              ) : (
                template.templateTypeName
              )}
            </div>
          </div>
        </div>
        <div className="extraction-field-list extraction-list">{this.getAccordions()} </div>
      </div>
    );
  }
}

export default injectIntl(ExtractionRecordList);
