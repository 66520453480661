import model from 'models/base-model';
import dateUtil from 'utils/dateUtil';
import CONSTANTS from 'utils/constants';

export class Project extends model({
  isLoading: false,
  isLoaded: false,
  error: null,
  projectId: null,
  projectKey: null,
  projectName: null,
  projectStateId: null,
  engagementId: null,
  containerCode:null,
  lastUpdatedDate: null,
  lastUpdatedBy: null,
  createdDate: null,
  createdBy: null
}) {
  setLoading() {
    return this.merge({
      isLoading: true,
      isLoaded: false,
      error: null
    });
  }

  setError(error) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      error: error,
      projects: null
    });
  }

  // Get data returned from the API
  setLoaded(project) {
    return Project.fromData(project);
  }

  setCreated(state, project) {
    return this.merge({
      isLoaded: true
    });
  }

  STATES = {
    Archived: () => {
      return this.projectStateId === CONSTANTS.ProjectStateIds.archived;
    },
    Copy: () => {
      return this.projectStateId === CONSTANTS.ProjectStateIds.copying;
    },
    Creation: () => {
      return this.projectStateId === CONSTANTS.ProjectStateIds.inCreation;
    },
    CopyInCreation: () => {
      return this.projectStateId === CONSTANTS.ProjectStateIds.copying && this.sourceProjectId !== null;
    },
    InProgress: () => {
      //This means creation is in progress
      return this.projectStateId === CONSTANTS.ProjectStateIds.inCreation && this.sourceProjectId === null;
    },
    Loaded: () => {
      return this.projectId ? true : false;
    },
    ReadOnly: () => {
      return (
        this.projectStateId === CONSTANTS.ProjectStateIds.pendingArchive ||
        this.projectStateId === CONSTANTS.ProjectStateIds.archived
      );
    },
    Started: () => {
      return this.projectStateId === CONSTANTS.ProjectStateIds.started;
    }
  };

  setUpdated(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      projectName: action.payload.projectName ? action.payload.projectName : null,
      projectStateId: action.payload.projectStateId ? action.payload.projectStateId : null,
      engagementId: action.payload.engagementId ? action.payload.engagementId : null
    });
  }

  setUpdatedName(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      projectName: action.payload.projectName ? action.payload.projectName : null
    });
  }

  setUpdatedEngagement(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      engagementId: action.payload.engagementId ? action.payload.engagementId : null,
      engagementName: action.payload.engagementName ? action.payload.engagementName : null
    });
  }

  setUpdatedState(state, action) {
    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      projectStateId: action.payload.projectStateId ? action.payload.projectStateId : null
    });
  }

  setUpdatedRole(state, action) {
    const { data, updateMyRole } = action.payload;

    const myRole = updateMyRole
      ? {
          ...state.myRole,
          roleId: data.roleId
        }
      : state.myRole;

    return this.merge({
      ...state,
      isLoading: false,
      isLoaded: true,
      myRole,
      users: state.users.map(user => {
        return user.userId === data.userId
          ? {
              ...user,
              role: {
                ...user.role,
                roleId: data.roleId
              }
            }
          : user;
      })
    });
  }

  socketUpdate(model) {
    return this.merge({
      projectName: model.projectName,
      projectStateId: model.projectStateId,
      engagementId: model.engagementId,
      legalHold: model.legalHold
    });
  }

  toggleSupportAccess(state, action) {
    return this.merge({
      ...state,
      isAdminAccessEnabled: action.payload.allowAccess
    });
  }

  static fromData(model) {
    return new Project({
      ...model,
      isLoading: false,
      isLoaded: true,
      error: null,
      lastUpdatedDate: model.lastUpdatedDate ? dateUtil.fromServerDate(model.lastUpdatedDate) : null,
      createdDate: model.createdDate ? dateUtil.fromServerDate(model.createdDate) : null
    });
  }
}
