import { createAction, createReducer } from 'utils/redux-utils';

export const ACTIONS = {
  SET_COMPARE_TYPE: 'argus/ui/SET_COMPARE_TYPE'
};

export const setCompareType = createAction(ACTIONS.SET_COMPARE_TYPE);

/*
 * Reducer
 */
export const INITIAL_STATE = sessionStorage.getItem('compareType')
  ? JSON.parse(sessionStorage.getItem('compareType'))
  : 'document';

export default createReducer(INITIAL_STATE, {
  [ACTIONS.SET_COMPARE_TYPE]: (state, action) => {
    return action.payload;
  }
});

export function persistSelectedCompareType({ ui: { compareType } }) {
  sessionStorage.setItem('compareType', JSON.stringify(compareType));
}
