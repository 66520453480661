import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getCurrentRegion } from 'store/api';
import Icon from 'components/shared/icon';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import Button from 'components/shared/single-click-button';

export const NavigationButtons = injectIntl(({ disabled, onPrev, onNext, intl }) => {
  return (
    <div className="document-navigation-buttons">
      <Button
        size="icon"
        disabled={disabled}
        className="previous-document icon-button"
        onClick={onPrev}
        title={intl.formatMessage({ id: 'common.previous' })}
      >
        <Icon name="arrowleft-black" width={28} height={28} />
      </Button>

      <Button
        size="icon"
        disabled={disabled}
        className="next-document icon-button"
        onClick={onNext}
        title={intl.formatMessage({ id: 'common.next' })}
      >
        <Icon name="arrowright-black" width={30} height={30} />
      </Button>
    </div>
  );
});

NavigationButtons.propTypes = {
  disabled: PropTypes.bool,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export class DocumentButtons extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    documentId: PropTypes.any,
    getNextDocument: PropTypes.func,
    getPreviousDocument: PropTypes.func,
    history: PropTypes.object,
    projectId: PropTypes.any
  };

  previousDocumentClick = () => {
    var { projectId, documentId, getPreviousDocument } = this.props;
    getPreviousDocument(projectId, documentId).then(response => {
      this.props.history.push(
        `/region/${getCurrentRegion()}/project/${projectId}/document/${response.data.documentId}`
      );
    });
  };

  nextDocumentClick = () => {
    var { projectId, documentId, getNextDocument } = this.props;
    getNextDocument(projectId, documentId).then(response => {
      this.props.history.push(
        `/region/${getCurrentRegion()}/project/${projectId}/document/${response.data.documentId}`
      );
    });
  };

  render() {
    var { disabled } = this.props;
    return (
      <NavigationButtons disabled={disabled} onPrev={this.previousDocumentClick} onNext={this.nextDocumentClick} />
    );
  }
}

export default withRouter(DocumentButtons);
