import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/shared/form/dropdown';
import { FormattedMessage } from 'react-intl';
import { Radio, RadioGroup } from 'components/shared/form/radio';
import Constants from 'utils/constants';
import TemplateTypeAI from './template-type-ai';

/** List of all the templates, when user has finished creating at least one template in the project creation section */
class TemplateType extends Component {
  static propTypes = {
    changeSelectedType: PropTypes.func,

    submitFunction: PropTypes.func,
    templateTypes: PropTypes.object,
    templateTypeGroups: PropTypes.array
  };

  state = {
    selectedType: null,
    workflowAccountGroup:
      this.props.templateTypeGroups && this.props.templateTypeGroups.length === 1
        ? this.props.templateTypeGroups[0]
        : null
  };

  selectType = type => {
    const { changeSelectedType } = this.props;
    this.setState({ selectedType: type });
    changeSelectedType(type);
  };

  compareTemplatesOrder = (template1, template2) => {
    return template1.displayOrder - template2.displayOrder;
  };

  render() {
    const { submitFunction, templateTypes, templateTypeGroups } = this.props;
    const { selectedType, workflowAccountGroup } = this.state;

    return (
      <div className="template-type">
        <h3 className="template-type__title">
          <FormattedMessage id="template-type.documentAI.title" />
        </h3>
        <RadioGroup
          name="template-type__radio-group"
          selectedValue={selectedType}
          onChange={this.selectType}
          className="template-type__container"
        >
          <div className="template-type__accelerated-workflows">
            <h4 className="template-type__sub-title">
              <FormattedMessage id="template-type.accelarated-workflow-title" />
            </h4>
            <p>
              <FormattedMessage id="template-type.accelarated-workflow-description" />
            </p>
            {templateTypes && templateTypes.isLoaded && (
              <div className="template-type__text-area">
                <div className="template-type__heading">
                  <FormattedMessage id="template-type.account-type-header" />
                </div>
                <div className="template-type__drop-down">
                  <Dropdown
                    defaultMessage={<FormattedMessage id="template-type.account-type-dropdown" />}
                    onSelection={option => this.setState({ workflowAccountGroup: option }, () => this.selectType(null))}
                    selectedOption={workflowAccountGroup}
                    options={templateTypeGroups}
                    renderOption={selectedOption => (
                      <span>
                        {<FormattedMessage id={`project-creation-AccountType_${selectedOption.templateTypeGroupID}`} />}
                      </span>
                    )}
                  />
                </div>
              </div>
            )}
            {workflowAccountGroup != null && (
              <div className="template-type__heading">
                <FormattedMessage id="template-type.account-sub-type-results" />
              </div>
            )}
            <div className="template-type__templatebox">
              {workflowAccountGroup != null
                ? templateTypes &&
                  templateTypes.isLoaded &&
                  templateTypes.types.templateTypes
                    .filter(
                      index =>
                        index.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING &&
                        index.templateTypeGroupID[0] === workflowAccountGroup.templateTypeGroupID
                    )
                    .sort(this.compareTemplatesOrder)
                    .map((type, index) => {
                      if (
                        templateTypes.types.templateTypes.filter(
                          index =>
                            index.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING &&
                            index.templateTypeGroupID[0] === workflowAccountGroup.templateTypeGroupID
                        ).length === 1 &&
                        selectedType === null
                      ) {
                        this.selectType(type);
                      }
                      return (
                        <div key={index} className="template-type__row">
                          <Radio
                            className="template-type__radio"
                            id={`templateType-${type.templateTypeID}`}
                            value={type}
                          />
                          <label className="template-type__label" htmlFor={`templateType-${type.templateTypeID}`}>
                            {type.templateTypeID ? (
                              <span>
                                <FormattedMessage id={`project-creation-templateType_${type.templateTypeID}`} />
                                {type.trainedExtractionFieldCount ? ` (${type.trainedExtractionFieldCount})` : ' (0)'}
                              </span>
                            ) : (
                              type.templateTypeName
                            )}
                          </label>
                        </div>
                      );
                    })
                : null}
            </div>
            {templateTypes &&
            templateTypes.isLoaded &&
            (templateTypes.types.templateTypes.filter(t => t.templateCategoryId === Constants.TemplateCategories.NON_EDITABLE_GENAI_DETAIL_TESTING ||
              t.templateCategoryId ===
                Constants.TemplateCategories.NON_EDITABLE_GENAI_NONDETAIL_TESTING || t.templateCategoryId === Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING)).length > 0 ? (
              <TemplateTypeAI templateTypes={templateTypes} />
            ) : null}
          </div>
          <div>
            <h4 className="template-type__sub-title">
              <FormattedMessage id="template-type.own-workflow-title" />
            </h4>
            <p>
              <FormattedMessage id="template-type.own-workflow-description" />
            </p>
            {templateTypes &&
              templateTypes.isLoaded &&
              templateTypes.types.templateTypes
                .filter(
                  index =>
                    index.templateCategoryId !== Constants.TemplateCategories.NON_EDITABLE_DETAIL_TESTING &&
                    index.templateCategoryId !== Constants.TemplateCategories.NON_EDITABLE_GENAI_DETAIL_TESTING &&
                    index.templateCategoryId !== Constants.TemplateCategories.NON_EDITABLE_GENAI_NONDETAIL_TESTING &&
                    index.templateCategoryId !== Constants.TemplateCategories.Custom_GENAI_NONDETAIL_TESTING
                )
                .sort(this.compareTemplatesOrder)
                .map((type, index) => (
                  <div key={index} className="template-type__row">
                    <Radio className="template-type__radio" id={`templateType-${type.templateTypeID}`} value={type} />
                    <label className="template-type__label" htmlFor={`templateType-${type.templateTypeID}`}>
                      {type.templateTypeID ? (
                        <span>
                          <FormattedMessage id={`project-creation-templateType_${type.templateTypeID}`} />
                          {type.trainedExtractionFieldCount ? ` (${type.trainedExtractionFieldCount})` : ' (0)'}
                        </span>
                      ) : (
                        type.templateTypeName
                      )}
                    </label>
                  </div>
                ))}
          </div>
        </RadioGroup>
      </div>
    );
  }
}

export default TemplateType;
