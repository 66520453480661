import { connect } from 'react-redux';
import { getData } from 'store/tags/document-type-tags';
import Tile from 'components/tags/tile';

const mapStateToProps = ({ data: { documentTypeTags } }) => ({ data: documentTypeTags });
const mapDispatchToProps = { getData };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tile);
